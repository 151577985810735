import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {LocalizedComponent} from './components/localized-component';

import {LoginService} from '../auth/services/login.service';
import {NgbActiveModal, NgbModal, NgbModalOptions, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {PubSubService} from './pub-sub.service';

@Component({
               selector: 'app-generic-modal',
               template: '<ng-content></ng-content>'
           })
export class GenericModalComponent<T> extends LocalizedComponent implements OnInit {

    public loginGuarded = true;
    public entity: T;
    public mode = 'add';

    static open(modalService: NgbModal, content, element: any = null, size: String = 'sm'): Promise<any> {
        const o: NgbModalOptions = {
            size    : size === 'sm' ? 'sm' : ((size === null || size === undefined) ? undefined : 'lg'),
            backdrop: 'static',
            keyboard: false
        };
        const r: NgbModalRef     = modalService.open(content, o);
        (r.componentInstance as GenericModalComponent<any>).open(element);
        return r.result;
    }


    constructor(
                
                public loginService: LoginService,
                public activeModal: NgbActiveModal,
                public cd: ChangeDetectorRef,
                public pubsub: PubSubService) {
        super( cd);
        this.pubsub.loginStateChange.subscribe(() => {
            if (loginService.isLoggedIn() === false) {
                if (this.loginGuarded) {
                    this.activeModal.dismiss('loggedOut');
                }
            }
        });
    }


    isAdd() {
        return this.mode === 'add';
    }

    isEdit() {
        return this.mode === 'edit';
    }

    public initEntity(src: T): T {
        throw new Error('method not overridden!');
    }

    public open(element: T = null) {
        if (element === null || element === undefined) {
            this.mode = 'add';
        } else {
            this.mode = 'edit';
        }

        this.entity = this.initEntity(element);

    }

    cancel() {
        this.activeModal.dismiss('cancel');
    }

    ok() {
        this.activeModal.close(this.entity);
    }


    ngOnInit() {
    }

}
