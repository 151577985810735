<data-table
        [selectable]="false"
        [data]="anreden.asDataTableRows()"
>
    <column [field]="'anrede'" [label]="_('Anrede')"></column>
    <column [field]="'briefanrede'" [label]="_('Briefanrede')"></column>
    <tableaction [label]="t('Anrede anlegen')" [icon]="'icofont icofont-ui-add'" (perform)="addAnrede()"></tableaction>
    <rowaction [label]="t('Anrede bearbeiten')"
               [icon]="'icofont icofont-ui-edit'"
               (perform)="editAnrede($event)"
    ></rowaction>
    <rowaction [label]="t('Anrede löschen')"
               [icon]="'icofont icofont-ui-delete'"
               [cmd]="'delete'"
               (perform)="deleteAnrede($event)"
    ></rowaction>
</data-table>
<div class="row">
    <div class="col-6"></div>
    <div class="col-6 text-right">
        <app-paginator
                [total]="anreden.total"
                [page]="anreden.page"
                [perpage]="anreden.size"
                (pageChange)="updateAnreden(true,$event)"
                (pageCountChange)="updateAnredenPageCount($event)"
        ></app-paginator>
    </div>
</div>
