import {ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ProdukteService} from './produkte.service';
import {ProdukteComponent} from './produkte.component';
import {ProduktCellFilterComponent} from './produkt-cell-filter.component';
import {ProduktCellRendererComponent} from './produkt-cell-renderer.component';
import {ProduktFieldComponent} from './produkt-field.component';
import {ProduktCellEditorComponent} from './produkt-cell-editor.component';
import {ProduktEditorComponent} from './produkte/editor/produkt-editor.component';
import {ProduktGruppeEditorComponent} from './produktgruppen/editor/produkt-gruppe-editor.component';
import {ProduktGruppeFieldComponent} from './produkt-gruppe-field.component';
import {ProduktGruppeInlineFieldComponent} from './produkt-gruppe-inline-field.component';
import {ProduktGruppeSelectorComponent} from './produkt-gruppe-selector.component';
import {ProduktInfoComponent} from './produkte/display/produkt-info.component';
import {ProduktInlineFieldComponent} from './produkt-inline-field.component';
import {ProduktQuickInfoComponent} from './produkte/display/produkt-quick-info.component';
import {ProduktSelectorComponent} from './produkt-selector.component';
import {TabProdukteComponent} from './produkte/tab-produkte.component';
import {TabProduktGruppenComponent} from './produktgruppen/tab-produkt-gruppen.component';
import {ArtikelEditorComponent} from './artikel/artikel-editor.component';
import {TabArtikelComponent} from './artikel/tab-artikel.component';
import {TabArtikelGruppenComponent} from './artikelgruppen/tab-artikel-gruppen.component';
import {EinheitEditorComponent} from './einheiten/einheit-editor.component';
import {TabEinheitenComponent} from './einheiten/tab-einheiten.component';
import {MengeCellRendererComponent} from './menge-cell-renderer.component';
import {ArtikelArtFieldComponent} from './artikel-art-field.component';
import {ArtikelGruppeInlineFieldComponent} from './artikel-gruppe-inline-field.component';
import {ArtikelGruppeFieldComponent} from './artikel-gruppe-field.component';
import {EinheitFieldComponent} from './einheit-field.component';
import {ArtikelFieldComponent} from './artikel-field.component';
import {ArtikelArtCellRendererComponent} from './artikel-art-cell-renderer.component';
import {ArtikelCellRendererComponent} from './artikel-cell-renderer.component';
import {ArtikelArtCellFilterComponent} from './artikel-art-cell-filter.component';

import {l10nConfig} from '../../l10nconfig';
import {CommonsModule} from '../commons/commons.module';
import {AuthModule} from '../auth/auth.module';
import {EinheitCellRendererComponent} from './einheit-cell-renderer.component';
import { ProduktElementRendererComponent } from './produkt-element-renderer.component';
import { ArtikelInlineFieldComponent } from './artikel-inline-field.component';

@NgModule({
              imports     : [
                  CommonModule,
                  CommonsModule,
                  AuthModule
              ],
              declarations: [
                  ArtikelArtCellFilterComponent,
                  ArtikelArtCellRendererComponent,
                  ArtikelArtFieldComponent,
                  ArtikelCellRendererComponent,
                  ArtikelEditorComponent,
                  ArtikelFieldComponent,
                  ArtikelGruppeFieldComponent,
                  ArtikelGruppeInlineFieldComponent,
                  EinheitEditorComponent,
                  EinheitFieldComponent,
                  MengeCellRendererComponent,
                  ProduktCellEditorComponent,
                  ProduktCellFilterComponent,
                  ProduktCellRendererComponent,
                  ProdukteComponent,
                  ProduktEditorComponent,
                  ProduktFieldComponent,
                  ProduktGruppeEditorComponent,
                  ProduktGruppeFieldComponent,
                  ProduktGruppeInlineFieldComponent,
                  ProduktGruppeSelectorComponent,
                  ProduktInfoComponent,
                  ProduktInlineFieldComponent,
                  ProduktQuickInfoComponent,
                  ProduktSelectorComponent,
                  TabArtikelComponent,
                  TabArtikelGruppenComponent,
                  TabEinheitenComponent,
                  TabProdukteComponent,
                  TabProduktGruppenComponent,
                  EinheitCellRendererComponent,
                  ProduktElementRendererComponent,
                  ArtikelInlineFieldComponent
              ],
              exports: [
                  ArtikelArtCellFilterComponent,
                  ArtikelArtCellRendererComponent,
                  ArtikelArtFieldComponent,
                  ArtikelCellRendererComponent,
                  ArtikelEditorComponent,
                  ArtikelFieldComponent,
                  ArtikelGruppeFieldComponent,
                  ArtikelGruppeInlineFieldComponent,
                  EinheitEditorComponent,
                  EinheitFieldComponent,
                  MengeCellRendererComponent,
                  ProduktCellEditorComponent,
                  ProduktCellFilterComponent,
                  ProduktCellRendererComponent,
                  ProdukteComponent,
                  ProduktEditorComponent,
                  ProduktFieldComponent,
                  ProduktGruppeEditorComponent,
                  ProduktGruppeFieldComponent,
                  ProduktGruppeInlineFieldComponent,
                  ProduktGruppeSelectorComponent,
                  ProduktInfoComponent,
                  ProduktInlineFieldComponent,
                  ProduktQuickInfoComponent,
                  ProduktSelectorComponent,
                  TabArtikelComponent,
                  TabArtikelGruppenComponent,
                  TabEinheitenComponent,
                  TabProdukteComponent,
                  TabProduktGruppenComponent,
                  EinheitCellRendererComponent,
                  ProduktElementRendererComponent
              ],
    entryComponents: [
        ArtikelArtCellFilterComponent,
        ArtikelArtCellRendererComponent,
        ArtikelArtFieldComponent,
        ArtikelCellRendererComponent,
        ArtikelEditorComponent,
        ArtikelFieldComponent,
        ArtikelGruppeFieldComponent,
        ArtikelGruppeInlineFieldComponent,
        EinheitEditorComponent,
        EinheitFieldComponent,
        MengeCellRendererComponent,
        ProduktCellEditorComponent,
        ProduktCellFilterComponent,
        ProduktCellRendererComponent,
        ProdukteComponent,
        ProduktEditorComponent,
        ProduktFieldComponent,
        ProduktGruppeEditorComponent,
        ProduktGruppeFieldComponent,
        ProduktGruppeInlineFieldComponent,
        ProduktGruppeSelectorComponent,
        ProduktInfoComponent,
        ProduktInlineFieldComponent,
        ProduktQuickInfoComponent,
        ProduktSelectorComponent,
        TabArtikelComponent,
        TabArtikelGruppenComponent,
        TabEinheitenComponent,
        TabProdukteComponent,
        TabProduktGruppenComponent,
        EinheitCellRendererComponent,
        ProduktElementRendererComponent
    ]

          })
export class ProductsModule {

}
