import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {take} from 'rxjs/operators';
import {LoginService} from '../../../auth/services/login.service';
import {IListResult} from '../../../commons/list-result';
import {ILieferschein} from './ilieferschein';
import {Observable} from 'rxjs';
import {SimpleLieferscheinFilter} from './simple-lieferschein-filter';
import {ResultMetaSortImpl} from '../../../commons/result-meta';
import {Lieferschein} from './lieferschein';
import {IActionResponse} from '../../../commons/action-response';
import {SimpleFahrzeugFilter} from '../../../customers/fahrzeuge/common/simple-fahrzeug-filter';

@Injectable({
                providedIn: 'root'
            })
export class LieferscheinService {

    constructor(
        public http: HttpClient,
        public loginService: LoginService) {
    }

    list(max = 0,
         offset = 0,
         sort: ResultMetaSortImpl[] = [],
         filter: SimpleLieferscheinFilter = null,
         searchString = ''
    ): Observable<IListResult<ILieferschein>> {
        let url = '/api/lieferscheine';

        url = url.concat('?')
            .concat('max=' + encodeURIComponent('' + max))
            .concat('&')
            .concat('offset=' + encodeURIComponent('' + offset));

        if (filter !== null) {
            if (filter.hasValues()) {
                url = url + '&' + filter.asURL();
            }
        }

        if (searchString !== null && searchString !== undefined && ('' + searchString).trim() !== '') {
            url = url + '&q=' + encodeURIComponent(('' + searchString).trim());
        }
        if (sort !== undefined && sort !== null && sort.length > 0) {
            let p = 0;
            sort.forEach(it => {
                url = url.concat('&sort.' + p + '=' + encodeURIComponent(it.field + '|' + it.direction));
            });
        }


        return this.http
            .get<IListResult<ILieferschein>>(
                url,
                {
                    headers: this.loginService.getHttpHeaders()
                }
            ).pipe(take(1))
    }

    store(lieferschein: Lieferschein, insert = false): Observable<IActionResponse> {

        if (!insert && lieferschein.id !== null && lieferschein.id > 0) {
            return this.http
                .put<IActionResponse>(
                    '/api/lieferscheine/' + encodeURIComponent('' + lieferschein.id),
                    JSON.stringify(lieferschein),
                    {
                        headers: this.loginService.getHttpHeaders()
                    }
                ).pipe(take(1));
        }
        return this.http
            .post<IActionResponse>(
                '/api/lieferscheine',
                JSON.stringify(lieferschein),
                {
                    headers: this.loginService.getHttpHeaders()
                }
            ).pipe(take(1));
    }

    reIndexLieferscheine(): Observable<IActionResponse> {
        return this.http
            .post<IActionResponse>(
                '/api/lieferscheine/_reindex',
                {},
                {
                    headers: this.loginService.getHttpHeaders()
                }
            ).pipe(take(1));
    }

    exportData(type = 'csv', filter: SimpleLieferscheinFilter = null, searchQuery = '', exporttype = 0): Observable<Blob> {
        let url = '/api/lieferscheine.' + encodeURIComponent('' + type);

        url = url.concat('?max=-1')
            .concat('&offset=0')
            .concat('&sort.0=' + encodeURIComponent('datum|asc'));
        url = url.concat('&exporttype=' + encodeURIComponent('' + exporttype));


        if (filter !== null) {
            if (filter.hasValues()) {
                url = url + '&' + filter.asURL();
            }
        }
        if (searchQuery !== null && searchQuery !== undefined && searchQuery !== '') {
            url = url.concat('&q=' + encodeURIComponent(searchQuery));
        }

        return this.http
            .get(
                url,
                {
                    headers: this.loginService.getHttpHeaders(false),
                    responseType: 'blob'
                }
            ).pipe(take(1));
    }

    public delete(lieferschein: Lieferschein):Observable<IActionResponse> {
         if ( lieferschein.id !== null && lieferschein.id > 0) {
            return this.http
                .delete<IActionResponse>(
                    '/api/lieferscheine/' + encodeURIComponent('' + lieferschein.id),
                    {
                        headers: this.loginService.getHttpHeaders()
                    }
                ).pipe(take(1));
        }
    }
}
