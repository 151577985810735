import {MESelectComponent} from './meselect.component';

export class MESelectSearchEvent {
    queryString: string;
    component: MESelectComponent;


    constructor(queryString: string, component: MESelectComponent) {
        this.queryString = queryString;
        this.component = component;
    }
}
