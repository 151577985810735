<data-table [selectable]="false" [data]="laender.asDataTableRows()" >
  <column [field]="'laendercode'" [label]="t('Code')"></column>
  <column [field]="'bezeichnung'" [label]="t('Bezeichnung')"></column>
  <column [field]="'defaultLanguage'" [label]="t('Standard-Sprache')"></column>
  <column [field]="'defaultCurrency'" [label]="t('Standard-Währung')"></column>
</data-table>
<div class="row">
  <div class="col-6"></div>
  <div class="col-6 text-right">
    <app-paginator
            [total]="laender.total"
            [page]="laender.page"
            [perpage]="laender.size"
            (pageChange)="updateLaender(true,$event)"
            (pageCountChange)="updateLaenderPageCount($event)"
    ></app-paginator>
  </div>
</div>
