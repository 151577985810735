import {MEVersion} from './meversion';

export class MEVersionTool {

    static isSameOrBetter(v1: MEVersion, v2: MEVersion): boolean {
        if ((v1 === null && v2 === null) || (v1 === v2)) {
            return true;
        }

        if (v1 === null && v2 !== null) {
            return false
        }

        if (v1 !== null && v2 === null) {
            return true
        }


        return v1.isGreaterThanEquals(v2)

    }


    static isSameOrBetterByString(v1: string, v2: string): boolean {
        if ((v1 === null && v2 === null) || (v1 === v2)) {
            return true;
        }

        if (v1 === null && v2 !== null) {
            return false
        }

        if (v1 !== null && v2 === null) {
            return true
        }

        return this.isSameOrBetter(new MEVersion(v1), new MEVersion(v2))
    }


}
