import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {NgbActiveModal, NgbModal, NgbModalOptions, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {ProduktGruppe} from '../common/produkt-gruppe';

import {ProdukteService} from '../../produkte.service';

import {LocalizedComponent} from '../../../commons/components/localized-component';
import {Produkt} from '../../produkte/common/produkt';
import {AddOnClickEvent} from '../../../commons/dynamic-form/add-on-click-event';
import {ToastrService} from 'ngx-toastr';

@Component({
    selector: 'app-produkt-gruppe-editor',
    templateUrl: './produkt-gruppe-editor.component.html',
    styleUrls: ['./produkt-gruppe-editor.component.css']
})
export class ProduktGruppeEditorComponent extends LocalizedComponent implements OnInit {

    dlgTitle = 'Produktgruppe {{ nummer }} bearbeiten';

    mode: any = 'new';
    entity: ProduktGruppe = new ProduktGruppe();


    static open(modalService: NgbModal, produktgruppe?: ProduktGruppe): Promise<any> {
        const o: NgbModalOptions = {
            size: 'lg',
            backdrop: 'static'
        };
        const r: NgbModalRef = modalService.open(ProduktGruppeEditorComponent, o);
        (r.componentInstance as ProduktGruppeEditorComponent).open(produktgruppe);
        return r.result;
    }

    constructor(

                public activeModal: NgbActiveModal,
                public produkteService: ProdukteService,
                public cd: ChangeDetectorRef,
                public toastr: ToastrService) {
        super( cd);
    }

    open(produktgruppe?: ProduktGruppe) {
        if ((produktgruppe === null) || (produktgruppe === undefined)) {
            this.entity = new ProduktGruppe();
            this.mode = 'new';
        } else {
            this.entity = ProduktGruppe.fromResult(produktgruppe);

            this.mode = 'edit';
        }
    }

    isAdd(): boolean {
        return this.mode === 'new';
    }

    isEdit(): boolean {
        return this.mode === 'edit';
    }

    ngOnInit() {
    }

    cancel() {
        this.activeModal.close();
    }

    addOnClick(event: AddOnClickEvent) {
        if ('produktGruppeNummer' === event.component.id) {
            if (event.addon === 0) {
                this.produkteService
                    .findFreeProduktGruppeNum()
                    .subscribe(
                        resp => {
                            if (resp.success) {
                                event.component.setValue(resp.params.nummer as number);
                            } else {
                                this.toastr.error(this.t('Es konnte keine freie Nummer ermittelt werden.'));
                            }
                        },
                        () => {
                            this.toastr.error(this.t('Es konnte keine freie Nummer ermittelt werden.'));
                        }
                    );
            }
        }
    }


    save() {
        if (this.canSave()) {
            this.produkteService
                .storeProduktgruppe(
                    this.entity, this.isAdd()
                )
                .subscribe(
                    resp => {
                        if (resp.success) {
                            this.activeModal.close();
                        } else {
                            this.toastr.error(this.t('Beim Speichern der Produktgruppendaten ist ein Fehler aufgetreten.'));
                        }
                    },
                    () => {
                        this.toastr.error(this.t('Beim Speichern der Produktgruppendaten ist ein Fehler aufgetreten.'));
                    }
                );
        }
    }

    canSave(): boolean {
        if (
            (this.entity.nummer === null) ||
            (this.entity.nummer === undefined) ||
            isNaN(parseInt('' + this.entity.nummer, 10)) ||
            (this.entity.nummer < 1)
        ) {
            return false;
        }

        return true;
    }

    updateProdukte(produkte: Produkt[]) {
        this.entity.produkte = produkte;
    }


}
