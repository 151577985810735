import {Geraet} from './geraet';
import {GenericFilter} from '../../commons/generic-filter';
import {DeviceType} from './device-type';
import {EDeviceType} from './edevice-type.enum';

export class SimpleGeraetFilter extends GenericFilter<Geraet> {
    fields = {
        deviceType: function (v) {
            return DeviceType.byValue(v);
        }
    };


    deviceType: EDeviceType = null;

    clear(): void {
        super.clear();
    }

    asURL(): string {
        const u = [];

        if (this.deviceType !== null && this.deviceType !== undefined) {
            u.push('filter.deviceType=' + encodeURIComponent('' + this.deviceType));

        }

        return u.join('&');

    }

    hasValues(): boolean {
        if (this.deviceType !== null && this.deviceType !== undefined) {
            return true;
        }
        return super.hasValues();
    }
}
