<app-login-alert>

    <app-area-heading
            [icon]="'far fa-car'"
            title="{{ _('Innenaufträge') }}"
    ></app-area-heading>

    <div class="card">
        <div class="card-body">
            <div class="card-text">
                <data-table
                        [selectable]="false"
                        [data]="fahrzeuge.asDataTableRows()"
                        [orders]="fahrzeuge.order"
                        [filter]="fahrzeuge.simpleFilter"
                        (sortChanged)="sortChanged($event)"
                        (filterChanged)="filterChanged($event)"
                        [rowActionsAsButtons]="true"
                        [showFilterArea]="false"
                        #fahrzeugListe
                >
                    <column
                            [field]="'kunde'"
                            [label]="_('Kunde')"
                            [renderer]="kundeR"
                            [filterable]="false"
                            [sortable]="true"
                    ></column>
                    <column
                            *ngIf="false"
                            [field]="'kundennummer'"
                            [label]="_('Kundennummer')"
                            [filterable]="false"
                            [cfg]="{showDots:false}"
                    ></column>
                    <column
                            [field]="'kfzKennzeichen'"
                            [label]="_('KFZ-Kennzeichen')"
                            type="text"
                            [filterable]="false"
                            [sortable]="true"></column>

                    <column
                            [field]="'nummer'"
                            [label]="_('Innenauftrag')"
                            type="integer"
                            [filterable]="false"
                            [sortable]="true"
                            [cfg]="{showDots:false}"
                    ></column>
                    <column
                            [field]="'buchungskreis'"
                            [label]="_('Buchungskreis')"
                            type="text"
                            [filterable]="false"
                            [sortable]="true"></column>
                    <column
                            [field]="'kostenstelle'"
                            [label]="_('Kostenstelle')"
                            [renderer]="kostenstelleR"
                            [filterable]="false"
                            [sortable]="true"></column>
                    <column
                            [field]="'ausweise~1'"
                            [label]="_('RFID-Chip')"
                            [filterable]="false"
                            [renderer]="ausweiseR"
                            [sortable]="false"
                            [cfg]="{ausweisArt:['CHIPTRANSPONDER']}"
                    ></column>
                    <column
                            [field]="'ausweise~2'"
                            [label]="_('Zusatzkarten')"
                            [filterable]="false"
                            [renderer]="ausweiseR"
                            [cfg]="{ausweisArt:['MAGSTRIPE']}"
                            [sortable]="false"></column>
                    <column
                            [field]="'kmStand'"
                            [label]="_('km/h-Stand')"
                            [filterable]="false"
                            [sortable]="false"
                    ></column>

                    <tableaction
                            *hasRole="['ROLE_ADMIN','ROLE_PROJECT_ADMIN','ROLE_BILLING','ROLE_FAHRZEUGE_WRITABLE']"
                            [label]="t('Innenauftrag anlegen')"
                            [icon]="'icofont icofont-ui-add'"
                            (perform)="newFahrzeug()"
                    ></tableaction>
                     <tableaction
                            [label]="_('Export')"
                            [icon]="'icofont icofont-download-alt'"
                            [type]="'dropdown'"
                    >
                        <list-option
                                *hasRole="['ROLE_ADMIN','ROLE_PROJECT_ADMIN','ROLE_BILLING','ROLE_FAHRZEUGE_WRITABLE','ROLE_FAHRZEUGE']"
                                [label]="_('Innenauftragsliste .xlsx')"
                                [icon]="'far fa-file-excel'"
                                (perform)="downloadEntries('xlsx',12001)"
                        ></list-option>
                    </tableaction>
                    <tableaction
                            *hasRole="['ROLE_ADMIN','ROLE_PROJECT_ADMIN']"
                            [label]="t('Werkzeuge')"
                            [icon]="'icofont icofont-tools'"
                            [type]="'dropdown'"

                    >
                       <list-option
                            *hasRole="['ROLE_ADMIN','ROLE_PROJECT_ADMIN']"
                            [label]="t('Innenaufträge synchronisieren')"
                            [icon]="'icofont icofont-cloud-refresh'"
                            (perform)="syncFahrzeuge()"
                    ></list-option>
                        <list-option
                                [label]="t('Innenaufträge indizieren')"
                                [icon]="'icofont icofont-cloud-refresh'"
                                (perform)="reindexFahrzeuge()"
                        ></list-option>
                    </tableaction>

                    <rowaction
                            *hasRole="['ROLE_ADMIN','ROLE_PROJECT_ADMIN','ROLE_FAHRZEUGE_WRITABLE']"
                            [label]="_('Bearbeiten')"
                            [icon]="'icofont icofont-ui-edit'"
                            (perform)="editFahrzeug($event)"
                    ></rowaction>

                    <data-table-table-extra-header>
                        <app-paginator
                                [total]="fahrzeuge.total"
                                [page]="fahrzeuge.page"
                                [perpage]="fahrzeuge.size"
                                (pageChange)="updateFahrzeuge(true,$event)"
                                (pageCountChange)="updateFahrzeugePageCount($event)"
                                [showSearch]="true"
                                (queryChange)="fahrzeugeQueryChange($event)"
                                [showFilterButton]="true"
                                [datatable]="fahrzeugListe"
                                (onFilterToggle)="fahrzeugFilterToggle($event)"
                                [filterDialog]="filterDialog"
                        ></app-paginator>
                    </data-table-table-extra-header>
                    <data-table-filter-area>
                        <app-fahrzeug-filter-dialog
                                #filterDialog
                                (filterChanged)="fahrzeugQueryChange2($event)"
                        ></app-fahrzeug-filter-dialog>
                    </data-table-filter-area>

                </data-table>

            </div>
        </div>
    </div>

</app-login-alert>