import {ChangeDetectorRef, Injectable, OnDestroy} from '@angular/core';
import {
    L10nDatePipe,
    L10nDateTimeFormatOptions,
    L10nIntlService,
    L10nLocale,
    L10nNumberFormatOptions,
    L10nNumberPipe,
    L10nTranslationService
} from 'angular-l10n';
import * as moment from 'moment';
import {appConfig} from '../../../app.version';
import {MEAppInjector} from '../../../shared/meapp-injector';
import {METool} from '../../../shared/metool';
import {SubscriptionHelper} from '../../../shared/subscription-helper';
import {LoginService} from '../../auth/services/login.service';
import {BytesPipe} from '../bytes.pipe';
import {noop} from '../noop';
import {ShortenPipe} from '../shorten.pipe';
import {SprachenService} from '../sprachen/common/sprachen.service';
import CryptoES from "crypto-es";

@Injectable()
export class LocalizedComponent implements OnDestroy {

    static MISSING_VALUE = 'KEY.MISSING';
    public appConfig = appConfig;
    private __lc_subs: SubscriptionHelper;

    constructor(
        public cd: ChangeDetectorRef
    ) {

        this.__lc_subs = new SubscriptionHelper();
        this.__lc_subs.addSubscription(this.translation.onChange().subscribe(() => {
            this.cd.markForCheck();
        }));

    }

    public get translation(): L10nTranslationService {
        return <L10nTranslationService>MEAppInjector.get(L10nTranslationService);
    }

    public get intl(): L10nIntlService {
        return <L10nIntlService>MEAppInjector.get(L10nIntlService);
    }

    get _locale(): L10nLocale {
        if (METool.isDefined(this.translation)) {
            return this.translation.getLocale();
        }
        return {language: 'de'};
    }

    get _language(): string {
        if (METool.isDefined(this._locale)) {
            return this._locale.language;
        }
        return 'de';
    }

    ngOnDestroy() {
        if (METool.isDefined(this.__lc_subs)) {
            this.__lc_subs.unsubscribe();
        }
    }

    public t(okey: string, args?: any): string {
        let key = okey.replace('\n', '###BR###');
        key = key.replace(/\s+/gi, ' ');
        key = key.trim();

        let t = this.translation.translate(key, args);

        if (t === '' || t === null || (t === key && key.length > 25 && this._language !== 'de')) {
            t = LocalizedComponent.MISSING_VALUE;
        }
        if (t === LocalizedComponent.MISSING_VALUE) {
            this.inform(key, key);
            const skey = '' + CryptoES.MD5(key).toString();
            t = this.translation.translate(skey, args);
            if (t === LocalizedComponent.MISSING_VALUE) {
                this.inform(key, skey);
                t = okey;
            }
        }

        t = t.replace('###BR###', '\n');

        if (args !== undefined && args !== null) {
            t = this.handleArgs(t, args);
        }

        return t;
    }

    public _(key: string, args?: any): string {
        return this.t(key, args);
    }

    public inform(key: string, skey: string) {
        const sprachenService: SprachenService = <SprachenService>MEAppInjector.injector().get(SprachenService);
        if (METool.isDefined(sprachenService)) {
            if (METool.isDefined(this._language)) {
                sprachenService.reportMissingPhrase(
                    this.translation.getLocale().language,
                    skey,
                    window.location.href,
                    key
                );
            }
        }
    }

    public fDate(val: Date, fmt: string, useMoment = false) {
        if (val == null) {
            return '';
        }
        if (!moment(val).isValid()) {
            return '';
        }
        if (useMoment) {
            return moment(val).format(fmt);
        }

        const p = new L10nDatePipe(this.intl);
        const o: L10nDateTimeFormatOptions = {};
        if (fmt === 'full') {
            o.dateStyle = 'full';
            o.timeStyle = 'full';
        }
        if (fmt === 'long') {
            o.dateStyle = 'long';
            o.timeStyle = 'long';
        }
        if (fmt === 'medium') {
            o.dateStyle = 'medium';
            o.timeStyle = 'medium';
        }
        if (fmt === 'short') {
            o.dateStyle = 'short';
            o.timeStyle = 'short';
        }
        return p.transform(val, this._language, o);
    }

    public fNum(val: any, digitfmt: string, isCurrency = false, currency: string = null) {
        if (METool.isNullOrUndefined(val)) {
            return '';
        }
        if (METool.isNaN(val)) {
            return val;
        }

        const p = new L10nNumberPipe(this.intl);
        const o: L10nNumberFormatOptions = {digits: digitfmt};
        if (isCurrency) {
            o.style = 'currency';
            if (!METool.isNullOrUndefined(currency)) {
                o.currency = currency;
            }
        }
        return p.transform(val, this._language, o);
    }

    public fCurrency(val: any, currency: any, symbol: any = 'symbol', digitfmt: string = '1.2-2') {
        return this.fNum(val, digitfmt, true, currency);
    }

    public shorten(val: string, length: number) {
        const p = new ShortenPipe();
        return p.transform(val, length);
    }

    public bytes(val: number, decimals?: number) {
        const p = new BytesPipe();
        return p.transform(val, decimals);
    }

    public fPan(pan: string) {
        const re = /.*B([0-9]+)D.*/;
        const r = re.exec(pan);
        if (r && Array.isArray(r)) {
            return r[1];
        }
        return pan;
    }

    selectLanguage(language: string) {
        this.translation.setLocale({language: language}).then(noop, noop);
        moment().locale(language);
        const loginService = MEAppInjector.injector().get(LoginService);
        if (!METool.isNullOrUndefined(loginService)) {
            loginService.setPreferedLanguage(language);
        }
        this.cd.markForCheck();

    }

    protected handleArgs(value, args) {
        const TEMPLATE_REGEXP: RegExp = /{{\s?([^{}\s]*)\s?}}/g;
        return value.replace(TEMPLATE_REGEXP, function (substring, parsedKey) {
            const replacer = (args[parsedKey]);
            return typeof replacer !== 'undefined' ? replacer : substring;
        });

    }

}
