import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {NgbActiveModal, NgbModal, NgbModalOptions, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {Ausweis} from '../common/ausweis';

import {AusweisService} from '../common/ausweis.service';

import {Kontingent} from '../../limits/common/kontingent';
import {Kunde} from '../../kunden/common/kunde';
import {Subscription} from 'rxjs';
import {LocalizedComponent} from '../../../commons/components/localized-component';
import {PubSubService} from '../../../commons/pub-sub.service';
import {AddOnClickEvent} from '../../../commons/dynamic-form/add-on-click-event';
import {ToastrService} from 'ngx-toastr';
import {LoginService} from '../../../auth/services/login.service';
import {ActionResponse, IActionResponse} from '../../../commons/action-response';
import {Issuer} from '../../../commons/issuers/common/issuer';
import {appConfig} from '../../../../app.version';
import {Fahrzeug} from '../../fahrzeuge/common/fahrzeug';
import {ConfirmDialogComponent} from '../../../commons/confirm-dialog/confirm-dialog.component';

@Component({
    selector: 'app-ausweis-editor',
    templateUrl: './ausweis-editor.component.html',
    styleUrls: ['./ausweis-editor.component.css']
})
export class AusweisEditorComponent extends LocalizedComponent implements OnInit, OnDestroy {

    appConfig = appConfig;

    sub: Subscription = null;

    get dlgTitle() {
        if (this.isNew()) {
            return this._('Ausweis anlegen');
        }
        return this._('Karte {{ nummer }} bearbeiten', {nummer: this.entity.nummer});
    }

    pin1 = '';
    message = '';
    pin2 = '';
    canSavePin = false;
    mode: any = 'new';
    entity: Ausweis = new Ausweis();

    static open(modalService: NgbModal, ausweis?: Ausweis, kunde?: Kunde): Promise<any> {
        const o: NgbModalOptions = {
            size: 'lg',
            backdrop: 'static'
        };
        const r: NgbModalRef = modalService.open(AusweisEditorComponent, o);
        (r.componentInstance as AusweisEditorComponent).open(ausweis, kunde);
        return r.result;
    }

    constructor(

                public activeModal: NgbActiveModal,
                public ausweisService: AusweisService,
                public toastr: ToastrService,
                public pubsub: PubSubService,
                public loginService: LoginService,
                public cd: ChangeDetectorRef,
                public modalService: NgbModal
    ) {
        super( cd);
    }

    open(ausweis?: Ausweis, kunde?: Kunde) {
        if ((ausweis === null) || (ausweis === undefined)) {
            this.entity = new Ausweis();
            if (kunde !== undefined) {
                this.entity.kunde = kunde;
            }
            this.mode = 'new';
        } else {
            this.entity = Ausweis.fromResult(ausweis);
            this.mode = 'edit';
        }
        if (this.entity.artikelGruppen === null) {
            this.entity.artikelGruppen = [];
        }
        while (this.entity.artikelGruppen.length < 2) {
            this.entity.artikelGruppen.push(null);
        }
        if (this.entity.kontingent === null || this.entity.kontingent === undefined) {
            this.entity.kontingent = new Kontingent();
        }
        this.pin1 = this.entity.pin;

    }

    isNew(): boolean {
        return this.mode === 'new';
    }

    isEdit(): boolean {
        return this.mode === 'edit';
    }

    ngOnInit() {
        this.sub = this.pubsub.loginError.subscribe(
            () => {
                this.cancel();
            }
        );
    }

    ngOnDestroy() {
        if (this.sub !== null) {
            this.sub.unsubscribe();
            this.sub = null;
        }
        super.ngOnDestroy();
    }

    cancel() {
        this.activeModal.close();
    }

    addOnClick(event: AddOnClickEvent) {
        if ('nummer-field' === event.component.id) {
            if (event.addon === 0) {
                this.ausweisService
                    .findFreeNum(this.entity.kunde)
                    .subscribe(
                        resp => {
                            if (resp.success) {
                                event.component.setValue(resp.params.nummer as number);
                            } else {
                                this.toastr.error(this.t('Es konnte keine freie Nummer ermittelt werden.'));
                            }
                        },
                        () => {
                            this.toastr.error(this.t('Es konnte keine freie Nummer ermittelt werden.'));
                        }
                    );
            }
        } else if ('pan-field' === event.component.id) {
            if (event.addon === 0) {
                this.ausweisService
                    .identify(this.entity.pan)
                    .subscribe(
                        (resp: IActionResponse) => {
                            if (resp.success) {
                                const ar = ActionResponse.fromRawActionResponse(resp);
                                this.entity.nummer = ar.params.nummer;
                                this.entity.issuer = Issuer.fromResult(ar.params.issuer);
                                this.cd.markForCheck();
                            } else {
                                this.toastr.error(this.t('Es konnte weder Herausgeber noch Nummer bestimmt werden.'));


                            }
                        },
                        () => {
                            this.toastr.error(this.t('Es konnte weder Herausgeber noch Nummer bestimmt werden.'));

                        }
                    );
            }
        }
    }


    save() {
        if (this.canSave()) {
            this.ausweisService
                .store(
                    this.entity, this.isNew()
                )
                .subscribe(
                    resp => {
                        if (resp.success) {
                            this.toastr.success(this.t('Die Ausweisdaten wurden erfolgreich gespeichert.'));
                            this.activeModal.close();
                        } else {
                            this.toastr.error(this.t('Beim Speichern der Ausweisdaten ist ein Fehler aufgetreten.'));
                        }
                    },
                    () => {
                        this.toastr.error(this.t('Beim Speichern der Ausweisdaten ist ein Fehler aufgetreten.'));
                    }
                );
        }
    }

    canSave(): boolean {
        return !((this.entity.nummer === null) ||
            (this.entity.nummer === undefined) ||
            isNaN(parseInt('' + this.entity.nummer, 10)) ||
            (this.entity.nummer < 1));


    }

    updateBegrenzungen(bg) {
        this.entity.begrenzungen = bg;
    }

    savePin() {
        if (this.canSavePin) {
            this.ausweisService
                .setPIN(
                    this.entity, this.pin1
                )
                .subscribe(
                    resp => {
                        if (resp.success) {
                            this.toastr.success(this.t('Die PIN wurde festgelegt.'));
                        } else {
                            this.toastr.error(this.t('Beim Speichern der PIN ist ein Fehler aufgetreten.'));
                        }
                    },
                    () => {
                        this.toastr.error(this.t('Beim Speichern der PIN ist ein Fehler aufgetreten.'));
                    }
                );
        }
    }

    setPin(w: number, pin: string) {
        if (pin === null || pin === undefined) {
            return;
        }

        this.pin1 = pin.trim();
        this.pin2 = pin.trim();

        this.canSavePin = false;
        this.message = '';

        if (!(this.pin1 === null || this.pin1 === undefined || this.pin1.trim() === '')) {

            if (this.pin1.length !== 4) {
                this.message = this._('Die PIN hat ungültige Länge.');
            } else if (this.pin1.match(/(0000|9999)/i)) {
                this.message = this._('Bitte wählen Sie eine andere PIN.');
            } else if (!this.pin1.match(/^[0-9]+$/i)) {
                this.message = this._('Die PIN enthält ungültige Zeichen.');
            } else if (this.pin1 !== this.pin2) {
                this.message = this._('PIN und PIN-Wiederholung stimmen nicht überein.');
            } else {
                this.canSavePin = true;
            }
        }

    }

    showNachverfolgung(): boolean {
        if (!this.isEdit()) {
            return false;
        }
        if (!this.loginService.hasOneOfRoles(['ROLE_ADMIN', 'ROLE_PROJECT_ADMIN', 'ROLE_ADM'])) {
            return false;
        }
        if (this.entity.id === null || this.entity.id === undefined) {
            return false;
        }
        return true;
    }

    setFahrzeug(fz: Fahrzeug) {
        this.entity.fahrzeug = fz;
        if (this.entity.fahrzeug.standort !== null && this.entity.fahrzeug.standort !== undefined) {
            this.entity.standort = this.entity.fahrzeug.standort;
        }
        this.cd.markForCheck();
    }

    canDelete() {
        if (!this.isEdit()) {
            return false;
        }
        if (this.loginService.hasOneOfRoles(['ROLE_ADMIN', 'ROLE_PROJECT_ADMIN', 'ROLE_AUSWEISE_WRITABLE'])) {
            return true;
        }
    }

    remove() {
        if (this.isEdit()) {
            ConfirmDialogComponent
                .display(
                    this.modalService,
                    this._('Ausweis löschen'),
                    this._('Soll dieser Ausweis wirklich gelöscht werden? Hinweis: Ausweise werden nur gelöscht, wenn ' +
                        'diese keiner Transaktion oder Kartenanfrage zugeordnet sind!'),
                    this._('Jetzt löschen'),
                    'icofont icofont-trash'
                ).result.then(() => {
                this.ausweisService.removeAusweis(this.entity).subscribe(
                    (i: IActionResponse) => {
                        const ar = ActionResponse.fromRawActionResponse(i);
                        if (ar.success) {
                            this.toastr.success(this._('Der Ausweis wurde gelöscht'));
                            this.cancel();
                        } else {
                            this.toastr.error(this._(ar.params.message), this._('Fehler beim Löschen des Ausweises'));
                        }
                    },
                    () => {
                        this.toastr.error(this._('Fehler beim Löschen des Ausweises'));
                    }
                );
            });
        }
    }

    isWifiCard() {
        try {
            if (this.entity.issuer.bezeichnung.toLowerCase().indexOf('wifi') >= 0) {
                return true;
            }
        } catch (ignored) {

        }
        return false;
    }

}
