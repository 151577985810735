<data-table
        [selectable]="false"
        [data]="preise.asDataTableRows()"
        [orders]="preise.order"
        [filter]="preise.simpleFilter"
        (sortChanged)="sortChanged($event)"
        (filterChanged)="filterChanged($event)"
        [rowActionsAsButtons]="true"
        [showFilterArea]="false"
        #preisListe

>
    <column [field]="'station'"
            [label]="t('Station')"
            [renderer]="stationR"
            [sortable]="true"
            [filterable]="false"
    ></column>
    <column [field]="'terminal'"
            [label]="t('Terminal')"
            [renderer]="terminalR"
            [sortable]="true"
            [filterable]="false"
    ></column>
    <column [field]="'produkt'"
            [label]="t('Produkt')"
            [renderer]="produktR"
            [sortable]="true"
            [filterable]="false"
    ></column>
    <column
            [field]="'wert'"
            [label]="t('Preis')"
            [renderer]="preiswert"
            [cfg]="{unitField:'einheit',currencyField:'waehrung'}"
            [sortable]="true"
    ></column>
    <column
            [field]="'istBrutto'"
            [label]="_('Brutto?')"
            [type]="'boolean'"
            [sortable]="true"
    ></column>
    <column
            [field]="'gueltigAb'"
            [label]="_('Preis gültig ab')"
            [type]="'Date'"
            [cfg]="{format:'medium'}"
            [sortable]="true"
    ></column>

    <tableaction
            [label]="t('Stations-Preise stellen')"
            [icon]="'icofont icofont-ui-add'"
            (perform)="newPreis()"
    ></tableaction>
    <tableaction
            *hasRole="['ROLE_ADMIN','ROLE_PROJECT_ADMIN','ROLE_BILLING','ROLE_TRANSAKTIONEN']"

            [label]="_('Export')"
            [icon]="'icofont icofont-download-alt'"
            [type]="'dropdown'"
    >
        <list-option
                *hasRole="['ROLE_ADMIN','ROLE_PROJECT_ADMIN','ROLE_BILLING']"
                [label]="_('Preise CSV')"
                [icon]="'far fa-file'"
                (perform)="downloadPreise('csv',0)"
        ></list-option>
        <list-option
                *hasRole="['ROLE_ADMIN','ROLE_PROJECT_ADMIN','ROLE_BILLING']"
                [label]="_('Preise .xlsx')"
                [icon]="'far fa-file-excel'"
                (perform)="downloadPreise('xlsx',0)"
        ></list-option>
    </tableaction>
    <tableaction
            *hasRole="['ROLE_ADMIN','ROLE_PROJECT_ADMIN']"
            [label]="t('Werkzeuge')"
            [icon]="'icofont icofont-tools'"
            [type]="'dropdown'"

    >
        <list-option
                *hasRole="['ROLE_ADMIN','ROLE_PROJECT_ADMIN']"
                [label]="t('Preise indizieren')"
                [icon]="'icofont icofont-cloud-refresh'"
                (perform)="reindexPreise()"
        ></list-option>
    </tableaction>


    <data-table-table-extra-header>
        <app-paginator
                [total]="preise.total"
                [page]="preise.page"
                [perpage]="preise.size"
                (pageChange)="updatePreise(true,$event)"
                (pageCountChange)="updatePreisePageCount($event)"
                [showSearch]="true"
                (queryChange)="preiseQueryChange($event)"
                [showFilterButton]="true"
                [datatable]="preisListe"
                (onFilterToggle)="preiseFilterToggle($event)"
                [filterDialog]="filterDialog"
        ></app-paginator>

    </data-table-table-extra-header>
    <data-table-filter-area>
        <app-preise-filter-dialog
                #filterDialog
                (filterChanged)="preiseQueryChange2($event)"
        ></app-preise-filter-dialog>
    </data-table-filter-area>
</data-table>
