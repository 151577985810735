import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {Fahrzeug} from '../common/fahrzeug';

import {LocalizedComponent} from '../../../commons/components/localized-component';

@Component({
               selector   : 'app-fahrzeug-quick-info',
               templateUrl: './fahrzeug-quick-info.component.html',
               styleUrls  : [ './fahrzeug-quick-info.component.css' ]
           })
export class FahrzeugQuickInfoComponent extends LocalizedComponent {

    _fahrzeug: Fahrzeug = null;

    @Input()
    set fahrzeug(value: Fahrzeug) {
        this._fahrzeug = Fahrzeug.fromResult(value);
    }

    @Input() showLabel        = true;
    @Input() info_position = 'right';

    getTitle() {
        if (this._fahrzeug !== null) {
            return this._(' Fahrzeug {{ nummer }} ', {nummer: this._fahrzeug.nummer});
        }
        return '';
    }

    constructor(
                
                public cd:ChangeDetectorRef) {
        super(cd);
    }

}
