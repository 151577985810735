<div class="modal-header">
    <h5 class="modal-title">{{ getDlgtitle() }}</h5>

    <button type="button" class="close" aria-label="Close" (click)="cancel()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body d-flex justify-content-between">

    <div class="w-40">
        <h4>{{_('Belegdaten')}}</h4>
        <date-time-field [label]="_('Zeitpunkt')"
                         [(value)]="entity.zeitpunkt"
                         [hasSeconds]="false"
                         [nullable]="false"
        ></date-time-field>


        <station-field [label]="_('Station')"
                       [value]="entity.station"
                       (valueChange)="setStation($event)"
                       [nullable]="false"></station-field>
        <currency-field [label]="_('Währung')"
                        [value]="entity.waehrung"
                        (valueChange)="setWaehrung($event)"
                        [nullable]="false"
        ></currency-field>
        <num-field [label]="_('Umrechnungskurs')"
                   [(value)]="entity.kursWert"
        ></num-field>

        <num-field [label]="_('Belegnummer')"
                   [(value)]="entity.belegnummer"
        ></num-field>

        <ausweis-field [label]="_('verwendete Karte')"
                       [value]="entity.ausweis"
                       [nullable]="false"
                       (valueChange)="setAusweis($event)"
        ></ausweis-field>
        <text-field [label]="_('Pan')"
                    [(value)]="entity.pan"
        ></text-field>

    </div>
    <div class="w-55">
        <h4>{{_('Positionen')}}</h4>
        <div class="pos-container">
            <div *ngFor="let d of entity.details;let idx=index"
                 class="card w-99 mb-1"
            >
                <div class="card-header p-2">{{ posLabel(d.pos) }}</div>
                <div class="card-text p-2">
                    <div class="d-flex justify-content-between">
                        <div class="w-20">
                            <small>{{_('Produkt')}}</small>
                            <br/>
                            <produkt-inline-field
                                    [nullable]="false"
                                    [(value)]="d.produkt"
                                    [label]="_('Produkt')"
                            ></produkt-inline-field>
                        </div>
                        <div class="w-15">
                            <small>{{_('Menge')}}</small>
                            <br/>
                            <num-field
                                    [labelwidth]="0"
                                    [value]="d.menge"
                                    (valueChange)="setMenge(idx,$event)"
                            ></num-field>
                        </div>
                        <div class="w-15">
                            <small>{{_('Einzelpreis')}}</small>
                            <br/>
                            <num-field
                                    *ngIf="!nettoErfassung"
                                    [labelwidth]="0"
                                    [value]="d.b_epreis"
                                    (valueChange)="setBEpreis(idx,$event)"
                            ></num-field>
                            <num-field
                                    *ngIf="nettoErfassung"
                                    [labelwidth]="0"
                                    [value]="d.epreis"
                                    (valueChange)="setEpreis(idx,$event)"
                            ></num-field>
                            <div *ngIf="euroDetails" class="border border-light">
                                <num-field
                                        *ngIf="!nettoErfassung"
                                        [labelwidth]="0"
                                        [value]="d.b_epreis_euro"
                                        (valueChange)="setBEpreisEuro(idx,$event)"
                                ></num-field>
                                <num-field
                                        *ngIf="nettoErfassung"
                                        [labelwidth]="0"
                                        [value]="d.epreis_euro"
                                        (valueChange)="setEpreisEuro(idx,$event)"
                                ></num-field>
                            </div>

                        </div>
                        <div class="w-15">
                            <small>{{_('Gesamtpreis')}}</small>
                            <br/>
                            <num-field
                                    *ngIf="!nettoErfassung"
                                    [labelwidth]="0"
                                    [value]="d.b_preis"
                                    (valueChange)="setBPreis(idx,$event)"
                            ></num-field>
                            <num-field
                                    *ngIf="nettoErfassung"
                                    [labelwidth]="0"
                                    [value]="d.preis"
                                    (valueChange)="setPreis(idx,$event)"
                            ></num-field>
                            <div *ngIf="euroDetails" class="border border-light">
                                <num-field
                                        *ngIf="!nettoErfassung"
                                        [labelwidth]="0"
                                        [value]="d.b_preis_euro"
                                        (valueChange)="setBPreisEuro(idx,$event)"
                                ></num-field>
                                <num-field
                                        *ngIf="nettoErfassung"
                                        [labelwidth]="0"
                                        [value]="d.preis_euro"
                                        (valueChange)="setPreisEuro(idx,$event)"
                                ></num-field>
                            </div>
                        </div>
                        <div class="w-15">
                            <small>{{_('MwSt-Satz')}}</small>
                            <br/>
                            <num-field
                                    [labelwidth]="0"
                                    [value]="d.mwst_satz"
                                    (valueChange)="setMwStSatz(idx,$event)"
                            ></num-field>
                        </div>
                        <div class="w-15">
                            <small>{{_('MwSt-Betrag')}}</small>
                            <br/>
                            <num-field
                                    [labelwidth]="0"
                                    [value]="d.mwst"
                                    (valueChange)="setMwSt(idx,$event)"
                            ></num-field>
                            <div *ngIf="euroDetails" class="border border-light">
                                <num-field
                                        [labelwidth]="0"
                                        [value]="d.mwst_euro"
                                        (valueChange)="setMwStEuro(idx,$event)"
                                ></num-field>
                            </div>
                        </div>

                    </div>
                    <div class="d-flex justify-content-between" *ngIf="posDetails">
                        <div class="w-60">
                            <text-field
                                    [label]="_('Verwendungszweck')"
                                    [value]="d.zweck"
                                    [labelwidth]="4"
                                    (valueChange)="setZweck(idx,$event)"
                            ></text-field>
                        </div>
                        <div class="w-35">
                            <num-field
                                    [label]="_('Belegnummer')"
                                    [labelwidth]="5"
                                    [value]="d.belegnummer"
                                    (valueChange)="setbelegnummer(idx,$event)"
                            ></num-field>
                        </div>
                    </div>
                </div>
                <div class="card-footer p-2 d-flex justify-content-between">
                    <div>
                        <button class="btn btn-sm" (click)="recalcDetail(idx)"><i class="far fa-calculator"></i> {{_('Berechnen')}}</button>
                    </div>
                    <div>
                        <button class="btn btn-sm" (click)="deleteDetail(idx)"><i class="far fa-minus"></i>{{_('Entfernen')}}</button>
                        <button
                                *ngIf="idx===(entity.details.length-1)"
                                class="btn btn-sm" (click)="addNachbuchungDetail()"><i class="far fa-plus"></i> {{_('Anfügen')}}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>
<div class="modal-footer d-flex justify-content-between">
    <div class="w-40 d-flex justify-content-between">

        <div class="text-nowrap">
            <div class="float-left w-20">
                <boolean-field
                        [(value)]="nettoErfassung"
                        [labelwidth]="0"
                        [label]="_('netto-Erfassung')"
                        title="{{_('Beträge werden als netto-Wert erfasst')}}"
                ></boolean-field>
            </div>
            <div class="text-nowrap p-2">
                {{ _('netto-Erfassung') }}
            </div>
        </div>

        <div class="text-nowrap">
            <div class="float-left w-20">
                <boolean-field
                        [(value)]="euroDetails"
                        [labelwidth]="0"
                        [label]="_('EURO-Werte zeigen')"
                        title="{{_('Referenz-Euro-Werte zeigen und bearbeiten')}}"
                ></boolean-field>
            </div>
            <div class="text-nowrap p-2">
                {{ _('EURO-Werte zeigen') }}
            </div>
        </div>

        <div class="text-nowrap">
            <div class="float-left w-20">
                <boolean-field
                        [(value)]="posDetails"
                        [labelwidth]="0"
                        [label]="_('Zusatzinformationen')"
                        title="{{_('Zusatzinformationen erfassen')}}"
                ></boolean-field>
            </div>
            <div class="text-nowrap p-2">
                {{ _('Zusatzinformationen') }}
            </div>
        </div>


    </div>
    <div>
        <div class="text-nowrap verbuchen">
            <div class="float-left w-20">
                <boolean-field
                        [(value)]="entity.transfer"
                        [labelwidth]="0"
                        [label]="_('Nachbuchung verbuchen')"
                        title="{{_('Nacherfassung automatisch verbuchen')}}"
                ></boolean-field>
            </div>
            <div class="text-nowrap p-2">
                {{ _('Nacherfassung verarbeiten') }}
            </div>
        </div>

        <button type="button" class="btn btn-secondary" (click)="cancel()">
            <i class="icofont icofont-close"></i> {{ _('Abbrechen') }}
        </button>
        <button type="button"
                class="btn btn-primary"
                (click)="save()"
                [class.disabled]="!canSave()"
                [disabled]="!canSave()"
        >
            <i class="icofont icofont-save"></i>
            {{ _('Angaben speichern')}}
        </button>
    </div>
</div>

