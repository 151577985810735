<div class="modal-header">
    <h5 class="modal-title">{{ dlgTitle }}</h5>

    <button type="button" class="close" aria-label="Close" (click)="cancel()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">

    <app-tabset>
        <app-pane [title]="_('Daten')">
            <num-field [label]="_('Nummer')"
                       id="numfield"
                       [(value)]="entity.nummer"
                       [enableaddon]="true"
                       [addonlabel]="[_('freie Nummer ermitteln')]"
                       [addonicon]="['icofont icofont-search']"
                       (addonClick)="addonClick($event)"
            ></num-field>
            <text-field [label]="_('Bezeichnung')"
                        [(value)]="entity.bezeichnung"
            ></text-field>
            <tank-field [label]="_('Tank')"
                        [(value)]="entity.tank"
                        [station]="station"
            ></tank-field>
            <num-field
                    [label]="_('Volumen')"
                    id="volumenfield"
                    [(value)]="entity.volumen"
                    [enableaddon]="true"
                    [addonlabel]="[_('vom Tank übernehmen')]"
                    [addonicon]="['icofont icofont-copy']"
                    (addonClick)="addonClick($event)"
            ></num-field>
            <boolean-field [label]="_('Sonde aktiv?')"
                           [(value)]="entity.enabled"
            ></boolean-field>
            <num-field [label]="_('Tankserver-ID')"
                       [(value)]="entity.tankserverId"
                       *hasRole="['ROLE_ADMIN']"
            ></num-field>
        </app-pane>
        <app-pane [title]="_('Hardware')">
            <boolean-field [label]="_('Höhenerfassung')"
                           [(value)]="entity.hasHoehe"
            ></boolean-field>
            <boolean-field [label]="_('Sumpf-Höhenerfassung')"
                           [(value)]="entity.hasSumpf"
            ></boolean-field>
            <num-field [label]="_('max. Höhe')"
                       [(value)]="entity.maxHoehe"
                       *ngIf="entity.hasHoehe || entity.hasSumpf"
            ></num-field>
            <boolean-field [label]="_('Temperaturerfassung')"
                           [(value)]="entity.hasTemperatur"
            ></boolean-field>
            <num-field [label]="_('min. Temperatur')"
                       [(value)]="entity.minTemperatur"
                       *ngIf="entity.hasTemperatur"
            ></num-field>
            <num-field [label]="_('max. Temperatur')"
                       [(value)]="entity.maxTemperatur"
                       *ngIf="entity.hasTemperatur"
            ></num-field>
        </app-pane>
        <app-pane [title]="_('Meldungen')">
            <num-field [label]="_('Warnmenge')"
                       [(value)]="entity.warnmenge"
                       id="warnmengefield"
                       [enableaddon]="true"
                       [addonlabel]="[_('Warnmenge berechnen (=10% vom Volumen)')]"
                       [addonicon]="['icofont icofont-calculator']"
                       (addonClick)="addonClick($event)"
            ></num-field>
            <num-field [label]="_('Meldung-Mindestabstand in h')"
                       [value]="entity.hoursBetweenWarnings"
            ></num-field>
            <date-time-field [label]="_('Letzte Meldung')"
                             [disabled]="true"
                             [value]="entity.lastWarning"
                             *ngIf="entity.lastWarning!==undefined && entity.lastWarning!==null"
            ></date-time-field>
            <text-field [label]="_('Warnmails an')"
                        [multiline]="true"
                        [rows]="5"
                        [(value)]="entity.warnMailReceipients"
                        title="{{_('mehrere Emailadressen mit Leerstelle, <Enter> oder Komma trennen')}}"
            ></text-field>
        </app-pane>
    </app-tabset>

</div>
<div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="cancel()">
        <i class="icofont icofont-close"></i> {{ _('Schließen') }}
    </button>
    <button type="button" class="btn btn-primary" (click)="save()">
        <i class="icofont icofont-save"></i> {{ _('Speichern')}}
    </button>
</div>

