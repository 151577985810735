<meselect
        id="{{ id }}"
        [idField]="'id'"
        [items]="kostenstellen?.data"
        [asMatrix]="false"
        [placeholder]="label"
        (itemSelected)="doSelect($event)"
        (needsSearch)="doSearch($event)"
        [small]="small"
        [nullable]="nullable"
        [disabled]="disabled"
>
</meselect>