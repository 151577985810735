import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {PubSubService} from '../pub-sub.service';
import {Subscription} from 'rxjs';
import {KitchenTimer} from '../kitchen-timer';

@Component({
    selector: 'app-loading-indicator',
    templateUrl: './loading-indicator.component.html',
    styleUrls: ['./loading-indicator.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoadingIndicatorComponent implements OnInit, OnDestroy {

    sub1: Subscription = null;
    sub2: Subscription = null;

    @Input() loading = 0;

    helper: KitchenTimer = new KitchenTimer(15000);

    constructor(public pubsub: PubSubService,
                public changeDetectorRef: ChangeDetectorRef) {
        this.helper.subscribe(
            () => {
                this.doneLoading();
            }
        );

        this.sub1 = this.pubsub.beforeRequest.subscribe(
            () => {
                setTimeout(() => this.doLoading(), 1);
            }
        );
        this.sub2 = this.pubsub.afterRequest.subscribe(
            () => {
                setTimeout(() => this.doneLoading(), 1);

            }
        );
    }

    ngOnInit() {
        this.doneLoading();
    }

    ngOnDestroy() {
        if (this.sub1 !== null) {
            this.sub1.unsubscribe();
            this.sub1 = null;
        }
        if (this.sub2 !== null) {
            this.sub2.unsubscribe();
            this.sub2 = null;
        }
    }

    isLoading() {
        return this.loading > 0;
    }

    doLoading() {
        this.loading++;
        if (this.changeDetectorRef) {
            this.changeDetectorRef.detectChanges();
        }
        this.helper.start();
    }

    doneLoading() {
        this.loading = (this.loading < 1) ? 0 : this.loading - 1;
        if (this.changeDetectorRef) {
            this.changeDetectorRef.detectChanges();
        }
        if (this.loading > 0) {
            this.helper.start();
        } else {
            this.helper.stop();
        }
    }

}
