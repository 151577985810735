import {GenericFilter} from '../../../commons/generic-filter';
import {Person} from './person';
import {IPerson} from './iperson';
import {IAusweis} from '../../ausweise/common/iausweis';
import {IKunde} from '../../kunden/common/ikunde';

export class SimplePersonFilter extends GenericFilter<Person> implements IPerson {
    id: number             = null;
    nummer: number         = null;
    name: string           = null;
    personalNummer: string = null;
    kostenstelle: string   = null;
    ausweis: IAusweis      = null;
    kunde: IKunde          = null;
    dateCreated: any       = null;
    lastUpdated: any       = null;
    lastEdited: any        = null;
    tankserverID: number   = null;
}
