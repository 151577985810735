import {GenericFilter} from '../../../commons/generic-filter';
import {KundeAudit} from './kunde-audit';
import {IKundeAudit} from './ikunde-audit';
import {IBenutzer} from '../../../auth/common/ibenutzer';

export class SimpleKundeAuditFilter extends GenericFilter<KundeAudit> implements IKundeAudit {
    id: number = null;
    benutzer: IBenutzer = null;
    dateCreated: any = null;
    info: string = null;
    uname: string = null;
}
