import {IBenutzer} from './ibenutzer';
import {ConverterTool} from '../../../shared/converter-tool';
import {IKunde} from '../../customers/kunden/common/ikunde';
import {Kunde} from '../../customers/kunden/common/kunde';
import {Station} from '../../stationen/common/station';
import {Fahrzeug} from '../../customers/fahrzeuge/common/fahrzeug';
import {Ausweis} from '../../customers/ausweise/common/ausweis';
import {Person} from '../../customers/personen/common/person';

export class Benutzer implements IBenutzer {
    messageCount = 0;
    accountLocked = true;
    kunde: IKunde = null;
    lastLogin: Date = null;
    lastPasswordChange: Date = null;
    dateCreated: Date = null;
    lastUpdated: Date = null;
    username = '';
    password = '';
    permissions: string[] = [];
    fullname = '';
    id = 0;
    hidden = false;
    email = '';
    emailsEnabled = false;
    stationen: Station[] = [];
    settings: any = {};
    stationsWorkMode = 0;
    telegramUsername = '';
    ausweise: Ausweis[] = [];
    fahrzeuge: Fahrzeug[] = [];
    personen: Person[] = [];
    newPW = '';
    newPW2 = '';
    ownEntitiesOnly = 0;
    public preferedLanguage: any;


    static fromResult(entity: IBenutzer): Benutzer {
        if ((entity === null) || (entity === undefined)) {
            return null;
        }

        const l: Benutzer = new Benutzer();
        for (const k of Object.keys(l)) {
            if (k === 'dateCreated') {
                l.dateCreated = ConverterTool.toDate(entity.dateCreated);
            } else if (k === 'lastUpdated') {
                l.lastUpdated = ConverterTool.toDate(entity.lastUpdated);
            } else if (k === 'lastPasswordChange') {
                l.lastPasswordChange = ConverterTool.toDate(entity.lastPasswordChange);
            } else if (k === 'lastLogin') {
                l.lastLogin = ConverterTool.toDate(entity.lastLogin);
            } else if (k === 'kunde') {
                l.kunde = Kunde.fromResult(entity.kunde);
            } else if (k === 'accountLocked') {
                l.accountLocked = ConverterTool.toBoolean(entity.accountLocked);
            } else if (k === 'hidden') {
                l.hidden = ConverterTool.toBoolean(entity.hidden);
            } else if (k === 'emailsEnabled') {
                l.emailsEnabled = ConverterTool.toBoolean(entity.emailsEnabled);
            } else if (k === 'stationen') {
                l.stationen = [];
                if (Array.isArray(entity.stationen)) {
                    entity.stationen.forEach((st: any) => {
                        const s = Station.fromResult(st);
                        if (s !== null) {
                            l.stationen.push(s);
                        }
                    });
                }
                } else if (k === 'ausweise') {
                l.ausweise = [];
                if (Array.isArray(entity.ausweise)) {
                    entity.ausweise.forEach((st: any) => {
                        const s = Ausweis.fromResult(st);
                        if (s !== null) {
                            l.ausweise.push(s);
                        }
                    });
                }
            } else if (k === 'fahrzeuge') {
                l.fahrzeuge = [];
                if (Array.isArray(entity.fahrzeuge)) {
                    entity.fahrzeuge.forEach((st: any) => {
                        const s = Fahrzeug.fromResult(st);
                        if (s !== null) {
                            l.fahrzeuge.push(s);
                        }
                    });
                }
            } else if (k === 'personen') {
                l.personen = [];
                if (Array.isArray(entity.personen)) {
                    entity.personen.forEach((st: any) => {
                        const s = Person.fromResult(st);
                        if (s !== null) {
                            l.personen.push(s);
                        }
                    });
                }
            } else if (k === 'settings') {
                if (entity.settings !== null && entity.settings !== undefined) {
                    l.settings = {...entity.settings};
                }
            } else {
                l[k] = (entity[k] !== undefined) ? entity[k] : '';
            }
        }

        l.messageCount = parseInt('' + l.messageCount, 10);
        if (isNaN(l.messageCount)) {
            l.messageCount = 0;
        }

        return l;
    }


    constructor() {
    }


}
