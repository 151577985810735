import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from '@angular/core';

import {LocalizedComponent} from '../../commons/components/localized-component';
import {SysInfoService} from '../../commons/sysinfo/services/sys-info.service';

@Component({
               selector       : 'app-transaktionen',
               templateUrl    : './transaktionen.component.html',
               styleUrls      : ['./transaktionen.component.css'],
               changeDetection: ChangeDetectionStrategy.OnPush
           })
export class TransaktionenComponent extends LocalizedComponent implements OnInit {

    development = false;

    constructor(
                
                public sysInfo: SysInfoService,
                public cd: ChangeDetectorRef
    ) {
        super( cd);
    }

    ngOnInit(): void {
        this.development = this.sysInfo.isDevelopmentMode;
        this.sysInfo.updateDocumentTitle(this._('Transaktionen'));
        this.cd.markForCheck();

    }

}
