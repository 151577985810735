import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {LocalizedComponent} from '../commons/components/localized-component';

import {DataTableRow} from '../commons/data-table/data-table-row';
import {DataTableComponent} from '../commons/data-table/data-table.component';
import {Produkt} from './produkte/common/produkt';

@Component({
    selector: 'produkt-selector',
    template: `
        <div class="form-group row">
            <label class="col-{{ labelwidth }} col-form-label" *ngIf="labelwidth>0">
                {{ label }}
            </label>
            <div class="col-{{ 12-labelwidth }}">

                <div class="card">
                    <div class="card-body">
                        <div class="card-header clearfix">

                            <div class="float-right">
                                <button class="btn btn-default btn-sm"
                                        [class.disabled]="checkAddDisabled()"
                                        title="{{ _('gewähltes Produkt in Liste übernehmen') }}"
                                        (click)="add()"
                                >
                                    <i class="icofont icofont-ui-add"></i>
                                </button>
                                <button class="btn btn-default btn-sm"
                                        [class.disabled]="checkDelDisabled()"
                                        title="{{ _('gewähltes Produkt aus Liste entfernen') }}"
                                        (click)="removeSelected()"
                                >
                                    <i class="icofont icofont-ui-delete"></i>
                                </button>
                            </div>
                            <produkt-field
                                    [labelwidth]="0"
                                    [label]="_('Produkt wählen')"
                                    [(value)]="currentProdukt"
                                    [small]="true"
                                    [nullable]="false"
                            ></produkt-field>
                        </div>
                        <div class="card-text">
                            <data-table
                                    [data]="produkte"
                                    [selectable]="true"
                                    [singleselect]="true"
                            >
                                <column [label]="_('Nummer')" [field]="'nummer'" [type]="'Integer'"></column>
                                <column [label]="_('Bezeichnung')" [field]="'bezeichnung'"></column>
                            </data-table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    `,
    styles: [`
        :host .card-header {
            padding: 0.35rem;
        }

        :host .card-header > div:first-child {
            padding-left: 0.35rem;
        }

        :host .card-header > produkt-field {
            min-width: 175px;
        }

        :host .card-header ::ng-deep .form-group {
            margin: 0 !important;
        }

        :host .card-header ::ng-deep .form-group .col-12 {
            padding: 0px;
        }

        :host data-table {
            margin: 0 !important;
            font-size: 0.8rem;
        }

        :host data-table ::ng-deep .column-nummer {
            width: 50px;
        }

        :host data-table ::ng-deep td {
            padding: 0.35rem !important;
        }

    `]
})
export class ProduktSelectorComponent extends LocalizedComponent implements OnInit {


    @Input() label = 'Produkte';
    @Input() labelwidth = 3;


    produkte: DataTableRow<Produkt>[] = [];
    currentProdukt: Produkt = null;

    @ViewChild(DataTableComponent, {static: true}) dataTable: DataTableComponent;

    upd() {
        if (this.dataTable !== null && this.dataTable !== undefined) {
            this.dataTable.updateRenderers();
        }
    }


    @Input()
    set value(v: Produkt[]) {
        v.forEach(e => {
            this.addElementToList(e, true);
        });
        this.produkte.forEach(z => {
            let found = false;
            v.forEach(e => {
                if (e.id == (z.data as Produkt).id) {
                    found = true;
                }
            });
            if (!found) {
                this.removeElementFromList(z.data, true);
            }
        })
    }

    get value(): Produkt[] {
        let v: Produkt[] = [];
        this.produkte.forEach(z => {
            v.push(z.data as Produkt);
        });
        return v;
    }

    @Output() valueChanged: EventEmitter<Produkt[]> = new EventEmitter<Produkt[]>();


    constructor(
        public cd: ChangeDetectorRef) {
        super(cd);
    }

    ngOnInit() {
        this.upd()
    }

    isElementInList(elem: Produkt): number {
        let position = -1;
        this.produkte.forEach(
            (z, index) => {
                if ((z.data as Produkt).id == elem.id) {
                    position = index;
                }
            }
        );
        return position;
    }

    addElementToList(elem: Produkt, suppressEvent = false) {
        if (elem === null) {
            return;
        }
        if (elem === undefined) {
            return;
        }
        if (this.isElementInList(elem) < 0) {
            this.produkte.push(new DataTableRow<Produkt>(elem));
            this.upd();
            if (!suppressEvent) {
                this.valueChanged.emit(this.value);
            }
        }
    }

    removeElementFromList(elem: Produkt, suppressEvent = false) {
        let pos = this.isElementInList(elem);
        if (pos >= 0) {
            this.produkte.splice(pos, 1);
            this.upd();
            if (!suppressEvent) {
                this.valueChanged.emit(this.value);
            }
        }
    }

    add() {
        if ((this.currentProdukt !== null) && (this.currentProdukt !== undefined)) {
            this.addElementToList(this.currentProdukt)
        }
    }

    checkAddDisabled(): boolean {
        let r = (this.currentProdukt === null) || (this.currentProdukt === undefined);
        if (r) {
            return r;
        }
        r = (this.isElementInList(this.currentProdukt) >= 0);
        return r;
    }

    checkDelDisabled(): boolean {
        let e = true;

        this.produkte.forEach(z => {
            if (z.isSelected()) {
                e = false;
            }
        });
        return e;
    }

    removeSelected() {
        this.produkte.forEach(z => {
            if (z.isSelected()) {
                this.removeElementFromList(z.data);
            }
        });
    }


}
