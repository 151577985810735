import {Component, EventEmitter, Input, NgZone, OnInit, Output} from '@angular/core';
import {Issuer} from '../issuers/common/issuer';

@Component({
               selector: 'issuer-field',
               template: `
                   <div class="form-group row">
                       <label for="{{ id }}" class="col-{{ labelwidth }} col-form-label" *ngIf="labelwidth>0">
                           {{ label }}
                       </label>
                       <div class="col-{{ 12-labelwidth }}">
                           <issuer-inline-field
                                   id="{{ id }}"
                                   [label]="label"
                                   [value]="_value"
                                   (valueChange)="doSelect($event)"
                                   [small]="small"
                                   [nullable]="nullable"
                                   [disabled]="disabled"
                           ></issuer-inline-field>
                       </div>
                   </div>

               `,
               styles  : [ `` ]
           })
export class IssuerFieldComponent implements OnInit {

    @Input() nullable = true;

    @Input() small          = false;
    public _value: Issuer                   = null;
    @Input() label           = 'Issuerfeld';
    @Input() id                 = '';
    @Input() labelwidth = 3;
    @Input() disabled = false;

    @Input()
    set value(v: Issuer) {

        if (this._value !== v) {
            this._value = v;
            if (!this.disabled) {
                this.valueChange.emit(v);
            }
        }
    }

    @Output() valueChange = new EventEmitter<Issuer>();


    get value(): Issuer {
        return this._value;
    }

    constructor(public _ngZone: NgZone) {
    }

    ngOnInit() {
    }

    doSelect(issuer: Issuer) {
        this._value = issuer;
        if (!this.disabled) {
            this.valueChange.emit(issuer);
        }
    }


}
