import {Ausweis} from '../../ausweise/common/ausweis';
import {IFahrzeug} from './ifahrzeug';
import {IAusweis} from '../../ausweise/common/iausweis';
import {ConverterTool} from '../../../../shared/converter-tool';
import {Produkt} from '../../../products/produkte/common/produkt';
import {Kunde} from '../../kunden/common/kunde';
import {Kostenstelle} from '../../kostenstellen/common/kostenstelle';
import {ELaufzeitErfassung} from './elaufzeit-erfassung.enum';
import {LaufzeitErfassung} from './laufzeit-erfassung';
import {Station} from '../../../stationen/common/station';


export class Fahrzeug implements IFahrzeug {
    tankserverID = 0;
    id = 0;
    nummer = 0;
    bezeichnung = '';
    kfzKennzeichen = '';
    kostenstelle: Kostenstelle = null;
    tankVolumen = 0;
    kraftstoff: Produkt = null;
    kmStand = 0;
    ausweise: Ausweis[] = [];
    kunde: Kunde = null;
    dateCreated: Date = null;
    lastUpdated: Date = null;
    lastEdited: Date = null;
    buchungskreis = '';
    info = '';
    werksnummer = '';
    laufzeitErfassung: ELaufzeitErfassung = ELaufzeitErfassung.UNKNOWN;
    standort: Station = null;
    kmAbfrage = false;
    maxKmDifferenz = 0;


    static fromResult(entity: IFahrzeug): Fahrzeug {
        if ((entity === null) || (entity === undefined)) {
            return null;
        }

        const l: Fahrzeug = new Fahrzeug();
        for (const k of Object.keys(l)) {
            if (k === 'dateCreated') {
                l.dateCreated = ConverterTool.toDate(entity.dateCreated);
            } else if (k === 'lastUpdated') {
                l.lastUpdated = ConverterTool.toDate(entity.lastUpdated);
            } else if (k === 'lastEdited') {
                l.lastEdited = ConverterTool.toDate(entity.lastEdited);
            } else if (k === 'kunde') {
                l.kunde = Kunde.fromResult(entity.kunde);
            } else if (k === 'kraftstoff') {
                l.kraftstoff = Produkt.fromResult(entity.kraftstoff);
            } else if (k === 'kostenstelle') {
                l.kostenstelle = Kostenstelle.fromResult(entity.kostenstelle);
            } else if (k === 'ausweise') {
                l.ausweise = [];
                if (Array.isArray(entity.ausweise)) {
                    entity.ausweise.forEach((a: IAusweis) => {
                        const aw = Ausweis.fromResult(a);
                        if (aw != null) {
                            l.ausweise.push(aw);
                        }
                    });
                }
            } else if (k === 'laufzeitErfassung') {
                l.laufzeitErfassung = LaufzeitErfassung.byValue(entity.laufzeitErfassung);
            } else if (k === 'standort') {
                l.standort = Station.fromResult(entity.standort);
            } else if (k === 'kmAbfrage') {
                l.kmAbfrage = ConverterTool.toBoolean(entity.kmAbfrage);
            } else {
                l[k] = (entity[k] !== undefined) ? entity[k] : '';
            }
        }

        if (l.kfzKennzeichen === 'null' || l.kfzKennzeichen === null) {
            l.kfzKennzeichen = '';
        }

        if (l.info === 'null' || l.info === null) {
            l.info = '';
        }

        if (l.bezeichnung === 'null' || l.bezeichnung === null) {
            l.bezeichnung = '';
        }



        return l;
    }

    get kundennummer() {
        if (this.kunde !== null && this.kunde !== undefined) {
            return this.kunde.kundennummer;
        }
        return '';
    }


    get ausweise1() {
        const aw: Ausweis[] = [];
        if (this.ausweise !== null && this.ausweise !== undefined && Array.isArray(this.ausweise)) {
            this.ausweise.forEach((a: Ausweis) => {
                if (a.issuer.nummer < 10) {
                    aw.push(a);
                }
            });
        }
        console.log(aw);
        return aw;
    }

    get ausweise2() {
        const aw: Ausweis[] = [];
        if (this.ausweise !== null && this.ausweise !== undefined && Array.isArray(this.ausweise)) {
            this.ausweise.forEach((a: Ausweis) => {
                if (a.issuer.nummer > 10) {
                    aw.push(a);
                }
            });
        }
        console.log(aw);
        return aw;
    }

    constructor() {
    }


}
