import {Component, Injector} from '@angular/core';
import {IAusweis} from './common/iausweis';
import {DataTableRow} from '../../commons/data-table/data-table-row';
import {DataTableColumnDirective} from '../../commons/data-table/data-table-column.directive';
import {Kunde} from '../kunden/common/kunde';

@Component({
               selector: 'ausweis-cell-renderer',
               template: `
                   <app-ausweis-quick-info
                           [ausweis]="cell"
                           [info_position]="info_position"
                           [showLabel]="true"
                   ></app-ausweis-quick-info>
               `
           })
export class AusweisCellRendererComponent {

    info_position = 'right';
    cell: IAusweis = null;
    row: DataTableRow<any> = null;
    column: DataTableColumnDirective = null;

    constructor(public injector: Injector) {

        this.cell = this.injector.get('cell');
        this.row = this.injector.get('row');
        this.column = this.injector.get('column');

        if (this.column !== null && this.column !== undefined) {
            if ((this.column.cfg !== null) && (this.column.cfg !== undefined)) {
                if (this.column.cfg.info_position !== null) {
                    this.info_position = this.column.cfg.info_position;
                }
            }
        }

        if (this.cell !== null && this.cell !== undefined) {
            if (this.cell.kunde == null || this.cell.kunde === undefined) {
                if (this.row.data.kunde !== undefined && this.row.data.kunde !== null) {
                    this.cell.kunde = this.row.data.kunde as Kunde;
                }
            }
        }
    }


}
