import {Component,  ElementRef, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
               selector: 'app-pane',
               template: `
                   <div id="{{ id }}">
                       <ng-content></ng-content>
                   </div>
               `
           })
export class PaneComponent implements OnInit {

    @Input() title: string;
    @Input() id: string;
    @Input() disabled               = false;
    @Output() activated: EventEmitter<PaneComponent>   = new EventEmitter<PaneComponent>();
    @Output() deactivated: EventEmitter<PaneComponent> = new EventEmitter<PaneComponent>();

    public _active = false;

    public set active(value: boolean) {
        if (this._active !== value) {
            this._active = value;
            if (value) {
                this.elementRef.nativeElement.style.display = 'block';
                this.activated.emit(this);
            } else {
                this.elementRef.nativeElement.style.display = 'none';
                this.deactivated.emit(this);
            }
        }
    }

    public get active(): boolean {
        return this._active;
    }

    constructor(private elementRef: ElementRef) {
        this.elementRef.nativeElement.style.display = 'none';
    }

    ngOnInit() {
        if ((this.id === undefined) || (this.id === null) || (this.id === '')) {
            this.id = this.title + ('.' + Math.random());
        }

    }

}
