<div class="modal-header">
    <h5 class="modal-title" *ngIf="isAdd()">{{ _('Sprache anlegen') }}</h5>
    <h5 class="modal-title" *ngIf="isEdit()">{{ _('Sprache bearbeiten') }}</h5>
    <button type="button" class="close" aria-label="Close" (click)="cancel()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="alert alert-info">
        {{ _('Änderungen an den Sprachdefinitionen wirken sich erst nach einem kompletten Neuladen der Anwendung aus') }}
    </div>

    <text-field [label]="_('Code')" [(value)]="entity.code"></text-field>
    <text-field [label]="_('Bezeichnung')" [(value)]="entity.bezeichnung"></text-field>
    <flag-field [label]="_('Flagge')" [(value)]="entity.flagge"></flag-field>

</div>
<div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="cancel()">
        <i class="icofont icofont-close"></i> {{ _('Abbrechen') }}
    </button>
    <button type="button" class="btn btn-primary" (click)="save()">
        <i class="icofont icofont-save"></i>
        {{ _('Änderungen sichern') }}
    </button>
</div>
