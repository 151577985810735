<div class="modal-header">
    <h5 class="modal-title" *ngIf="isAdd()">{{ _('Produkt anlegen') }}</h5>
    <h5 class="modal-title" *ngIf="isEdit()">{{ _('Produkt bearbeiten') }}</h5>
    <button type="button" class="close" aria-label="Close" (click)="cancel()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">

    <artikel-field [label]="_('Referenz-Artikel')"
                   [value]="entity.artikel"
                   (valueChange)="selectArtikel($event)"
                   id="referenzArtikel"
    ></artikel-field>

    <num-field
            [label]="_('Nummer')"
            [(value)]="entity.nummer"
            [addonicon]="['icofont icofont-search','icofont icofont-copy']"
            [addonlabel]="[_('freie Nummer suchen'),_('Nummer vom Referenzartikel übernehmen')]"
            [enableaddon]="true"
            (addonClick)="addOnClick($event)"
            id="produktNummer"
    ></num-field>
    <text-field
            [label]="_('Bezeichnung')"
            [(value)]="entity.bezeichnung"
            id="produktBezeichnung"
            [addonicon]="['icofont icofont-copy']"
            [addonlabel]="[_('Bezeichnung vom Referenzartikel übernehmen')]"
            [enableaddon]="true"
            (addonClick)="addOnClick($event)"
    ></text-field>
    <num-field
            [label]="_('Sorten-Code')"
            [(value)]="entity.sortenCode"
    ></num-field>

</div>
<div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="cancel()">
        <i class="icofont icofont-close"></i> {{ _('Abbrechen') }}
    </button>
    <button type="button" class="btn btn-primary" (click)="save()">
        <i class="icofont icofont-save"></i>
        {{ _('Änderungen sichern') }}
    </button>
</div>
