import {CommonList} from '../../../commons/common-list';
import {Person} from './person';
import {SimplePersonFilter} from './simple-person-filter';
import {IListResult} from '../../../commons/list-result';
import {IPerson} from './iperson';
import {ResultMetaSortImpl} from '../../../commons/result-meta';

export class PersonList extends CommonList<Person> {
    public simpleFilter: SimplePersonFilter = new SimplePersonFilter();

    static fromListResult(res: IListResult<IPerson>): PersonList {
        let newList: PersonList = new PersonList();
        newList.clear();
        newList.simpleFilter.clear();

        if (res.meta) {
            newList.total  = res.meta.total || 0;
            newList.offset = res.meta.offset || 0;
            newList.size   = res.meta.size || 0;
            newList.page   = res.meta.page || 0;
            if (res.meta.order) {
                newList.order = [];
                for (let x of res.meta.order) {
                    newList.order.push(
                        new ResultMetaSortImpl(
                            x.field || 'nummer',
                            x.direction || 'asc'
                        )
                    );
                }
            }
        }
        if (res.data) {
            for (let row of res.data) {
                newList.add(Person.fromResult(row));
            }
        }
        if (res.simpleFilter) {
            let d: Person = new Person();
            for (let k of Object.keys(d)) {
                newList.simpleFilter[ k ] = (res.simpleFilter[ k ] !== undefined) ? res.simpleFilter[ k ] : null;
            }
        }

        return newList;

    }
}
