import {ChangeDetectorRef, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {IAusweis} from '../common/iausweis';
import {AusweisAuditList} from '../common/ausweis-audit-list';

import {AusweisService} from '../common/ausweis.service';

import {LocalizedComponent} from '../../../commons/components/localized-component';
import {KitchenTimer} from '../../../commons/kitchen-timer';
import {NewPageCountEvent} from '../../../commons/paginator/paginator.component';
import {ToastrService} from 'ngx-toastr';
import {IListResult} from '../../../commons/list-result';
import {IAusweisAudit} from '../common/iausweis-audit';
import {LoginService} from '../../../auth/services/login.service';

@Component({
    selector: 'app-tab-ausweis-audit',
    templateUrl: './tab-ausweis-audit.component.html',
    styleUrls: ['./tab-ausweis-audit.component.css']
})
export class TabAusweisAuditComponent extends LocalizedComponent implements OnInit, OnDestroy {
    _ausweis: IAusweis = null;
    public ausweisAudit: AusweisAuditList = new AusweisAuditList();
    ausweisAuditTimer: KitchenTimer;

    @Input()
    set ausweis(ausweis: IAusweis) {
        this._ausweis = ausweis;
        this.updateAusweisAudit(true, 0);
    }

    constructor(
                
                public ausweisService: AusweisService,
                public loginService: LoginService,
                public toastr: ToastrService,
                public cd: ChangeDetectorRef) {
        super( cd);

        this.ausweisAudit = new AusweisAuditList();
        this.ausweisAudit.size = this.loginService.getPageSize('ausweisAudit');
        this.ausweisAuditTimer = new KitchenTimer();
        this.ausweisAuditTimer
            .subscribe(
                () => {
                    if (this._ausweis !== null && this._ausweis !== undefined && this._ausweis.id > 0) {
                        this.ausweisService
                            .listAudit(
                                this._ausweis,
                                this.ausweisAudit.size,
                                this.ausweisAudit.calcOffset(),
                                this.ausweisAudit.order,
                                this.ausweisAudit.simpleFilter,
                                this.ausweisAudit.getQuery()
                            )
                            .subscribe(
                                (l: IListResult<IAusweisAudit>) => {
                                    this.ausweisAudit.populateFromListResult(l);
                                },
                                (e) => {
                                    this.toastr.error(this._('Die Liste der Angaben zur Änderungsverfolgung konnte nicht geladen werden.'));
                                    this.ausweisAudit.clear();
                                }
                            );
                    }
                }
            );

    }

    ngOnInit() {
        this.updateAusweisAudit();
    }

    ngOnDestroy(): void {
        this.ausweisAuditTimer.stop();
    }

    updateAusweisAudit(force = false, page?: number) {
        if ((page !== undefined) && (page != null)) {
            if (page !== this.ausweisAudit.page) {
                this.ausweisAudit.page = page;
                force = true;
            }
        }
        this.ausweisAuditTimer.start(force);
    }

    /**
     * ändert die Anzahl der Datensätze/Seite und lädt die Liste neu
     * @param n
     */
    updateAusweisAuditPageCount(n: NewPageCountEvent) {
        if (this.ausweisAudit.size !== n.itemsPerPage) {
            this.ausweisAudit.size = n.itemsPerPage;
            this.loginService.setPageSize('ausweisAudit', n.itemsPerPage);
        }
        this.updateAusweisAudit(true, n.currentPage);
    }


}
