import {Component, Injector} from '@angular/core';
import {CommonCellRenderer} from '../../data-table/renderer/common-cell-renderer';
import {IWaehrung} from '../iwaehrung';
import {DataTableColumnDirective} from '../../data-table/data-table-column.directive';
import {DataTableRow} from '../../data-table/data-table-row';

@Component({
    selector: 'app-waehrung-cell-renderer',
    templateUrl: './waehrung-cell-renderer.component.html',
    styleUrls: ['./waehrung-cell-renderer.component.scss']
})
export class WaehrungCellRendererComponent extends CommonCellRenderer {

    cell: IWaehrung = null;
    row: DataTableRow<any> = null;
    column: DataTableColumnDirective = null;
    cfg: any = {nullIsAny: false};


    constructor(public injector: Injector) {
        super();
        this.cell = this.injector.get('cell');
        this.row = this.injector.get('row');
        this.column = this.injector.get('column');
         if (this.column !== null && this.column !== undefined) {
            if (this.column.cfg !== null && this.column.cfg !== undefined) {
                this.cfg = {...this.cfg, ...this.column.cfg};
            }
        }
    }

}
