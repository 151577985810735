import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CommonsModule} from '../commons/commons.module';
import {CustomersModule} from '../customers/customers.module';
import {AuthModule} from '../auth/auth.module';
import {HttpClientModule} from '@angular/common/http';
import {FormsModule} from '@angular/forms';
import {l10nConfig} from '../../l10nconfig';
import {SysSettingsComponent} from './syssettings.component';

const components = [
    SysSettingsComponent
]

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        HttpClientModule,
        AuthModule,
        CommonsModule,
        CustomersModule
    ],
    declarations: [
        ... components
    ],
    exports: [
        ... components
    ],
    entryComponents: [
        ... components
    ]

})
export class SystemSettingsModule {
}
