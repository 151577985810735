<app-login-alert>
    <app-area-heading
            [icon]="'far fa-address-book'"
            title="{{ _('Kunden') }}"
    ></app-area-heading>

    <div class="card">
        <div class="card-body">
            <div class="card-text">
                <data-table
                        *ngIf="isBrowsing()"
                        [selectable]="true"
                        [data]="kunden.asDataTableRows()"
                        [orders]="kunden.order"
                        [filter]="kunden.simpleFilter"
                        (sortChanged)="sortChanged($event)"
                        [singleselect]="true"
                        [containerHeight]="kundenContainerHeight"
                        [hideCheckboxes]="true"
                        (rowSelected)="rowSelected($event)"
                        [disabled]="isEditing()"
                        #kundenListe
                >

                    <column
                            [field]="'kundennummer'"
                            [label]="_('Kundennummer')"
                            type="text"
                            [filterable]="false"
                            [sortable]="true"></column>
                    <column
                            [field]="'bezeichnung'"
                            [label]="_('Kunde')"
                            type="text"
                            [filterable]="false"
                            [sortable]="true"></column>
                    <column
                            [field]="'land'"
                            [label]="_('Land')"
                            [renderer]="landR"
                            [filterable]="false"
                            [sortable]="true"
                    ></column>
                    <column
                            [field]="'plz'"
                            [label]="_('PLZ')"
                            type="text"
                            [filterable]="false"
                            [sortable]="true"></column>
                    <column
                            [field]="'ort'"
                            [label]="_('Ort')"
                            [filterable]="false"
                            [sortable]="true"></column>
                    <column
                            [field]="'kontingent'"
                            [label]="_('verfügbares Limit')"
                            [renderer]="kontingentCellRenderer"
                            [sortable]="false"
                    ></column>
                    <column
                            [field]="'begrenzungen'"
                            [label]="_('Freigaben')"
                            [renderer]="begrenzungenCellRenderer"
                            [sortable]="false"
                    ></column>

                    <rowaction
                            *hasRole="['ROLE_ADMIN']"
                            [label]="_('Sync')"
                            [icon]="'icofont icofont-cloud-refresh'"
                            (perform)="syncKunde($event)"
                    ></rowaction>

                    <tableaction
                            *hasRole="['ROLE_ADMIN','ROLE_PROJECT_ADMIN']"
                            [label]="t('Kunde anlegen')"
                            [icon]="'icofont icofont-ui-add'"
                            (perform)="newKunde()"
                    ></tableaction>
                    <tableaction
                            *hasRole="['ROLE_ADMIN','ROLE_PROJECT_ADMIN']"
                            [label]="t('Kunden synchronisieren')"
                            [icon]="'icofont icofont-cloud-refresh'"
                            (perform)="syncCustomers()"
                    ></tableaction>
                    <tableaction
                            *hasRole="['ROLE_ADMIN','ROLE_PROJECT_ADMIN']"
                            [label]="t('Kunden & Karten aus Ratio')"
                            [title]="t('Änderungen aus Ratio importieren')"
                            [icon]="'icofont icofont-cloud-download'"
                            (perform)="syncRatioKunden()"
                    ></tableaction>

                    <tableaction
                            *hasRole="['ROLE_ADMIN','ROLE_PROJECT_ADMIN']"
                            [label]="t('Kunden indizieren')"
                            [icon]="'icofont icofont-cloud-refresh'"
                            (perform)="reindexKunden()"
                    ></tableaction>
                    <data-table-table-extra-header>
                        <app-paginator
                                *ngIf="isBrowsing()"
                                [total]="kunden.total"
                                [page]="kunden.page"
                                [perpage]="kunden.size"
                                (pageChange)="updateKunden(true,$event)"
                                (pageCountChange)="updateKundenPageCount($event)"
                                [showSearch]="true"
                                (queryChange)="kundenQueryChange($event)"
                                [query]="kunden.searchString"
                                [queryFieldVisible]="true"
                        ></app-paginator>
                    </data-table-table-extra-header>

                </data-table>

                <div *ngIf="((hasSelection && entity!==null)||isAdding())"
                     id="details"
                >
                    <div class="d-flex justify-content-between"
                         style="background-color: #F4F4F4;margin-bottom: 4px; padding: 2px;">
                        <div><h4>{{entity.bezeichnung}}</h4></div>
                        <div>
                            <button class="btn btn-secondary btn-sm" *ngIf="isBrowsing()" (click)="editKunde()">
                                <i class="icofont icofont-edit"></i> {{_('Bearbeiten')}}
                            </button>
                            <button class="btn btn-secondary btn-sm"
                                    *ngIf="isEditing()"
                                    [class.disabled]="!canSave()"
                                    [disabled]="!canSave()"
                                    (click)="save()">
                                <i class="icofont icofont-save"></i> {{_('Änderungen speichern')}}
                            </button>
                            <button class="btn btn-secondary btn-sm" *ngIf="isEditing()" (click)="cancel()">
                                <i class="icofont icofont-close"></i> {{_('Abbrechen')}}
                            </button>
                        </div>
                    </div>
                    <app-tabset>
                        <app-pane [title]="_('Allgemein')">
                            <div *ngIf="isEditing()">
                                <text-field [label]="_('Kundennummer')" [(value)]="entity.kundennummer"></text-field>
                                <text-field [label]="_('Bezeichnung')" [(value)]="entity.bezeichnung"></text-field>
                                <anrede-field [label]="_('Anrede')" [(value)]="entity.anrede"></anrede-field>
                                <text-field [label]="_('Firma/Unternehmen')" [(value)]="entity.firma"></text-field>
                                <div class="row">
                                    <div class="col-6">
                                        <text-field [label]="_('Vorname')" [(value)]="entity.vorname"></text-field>
                                    </div>
                                    <div class="col-6">
                                        <text-field [label]="_('Nachname')" [(value)]="entity.nachname"></text-field>
                                    </div>
                                </div>
                                <text-field [label]="_('Zusatz')" [(value)]="entity.zusatz"></text-field>
                                <text-field [label]="_('Anschrift')" [(value)]="entity.anschrift1"></text-field>
                                <text-field [label]="_('Anschrift Zeile 2')" [(value)]="entity.anschrift2"></text-field>
                                <div class="row">
                                    <div class="col-3">
                                        <country-field [label]="_('Land')" [(value)]="entity.land"></country-field>
                                    </div>
                                    <div class="col-3">
                                        <text-field [label]="_('PLZ')" [(value)]="entity.plz"></text-field>
                                    </div>
                                    <div class="col-6">
                                        <text-field [label]="_('Ort')" [labelwidth]="2"
                                                    [(value)]="entity.ort"></text-field>
                                    </div>
                                </div>
                                <text-field [label]="_('Telefon')" [(value)]="entity.telefon"></text-field>
                                <text-field [label]="_('Telefax')" [(value)]="entity.telefax"></text-field>
                                <text-field [label]="_('Mobiltelefon')" [(value)]="entity.mobil"></text-field>
                                <text-field [label]="_('Email')" [(value)]="entity.email"></text-field>
                                <preisgruppe-field
                                        [label]="_('Preisgruppe')"
                                        [(value)]="entity.preisgruppe"
                                        [disabled]="!canEdit('preisgruppe')"
                                ></preisgruppe-field>
                                <benutzer-field
                                        [filter]="'permissions:ROLE_ADM'"
                                        [label]="'Kundenbetreuer'"
                                        [(value)]="entity.betreuer"
                                        [disabled]="!canEdit('betreuer')"
                                ></benutzer-field>

                            </div>
                            <div *ngIf="isBrowsing()">
                                <table class="table">
                                    <tr>
                                        <th scope="row" class="w-20">{{_('Kundennummer')}}</th>
                                        <td>{{entity.kundennummer}}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row" class="w-20">{{_('Bezeichnung')}}</th>
                                        <td>{{entity.bezeichnung}}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row" class="w-20">{{_('Anrede')}}</th>
                                        <td>
                                            <app-anrede-display [value]="entity.anrede"></app-anrede-display>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th scope="row" class="w-20">{{_('Firma/Unternehmen')}}</th>
                                        <td>{{entity.firma}}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row" class="w-20">{{_('Vor- und Zuname')}}</th>
                                        <td>{{entity.vorname}} {{entity.nachname}}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row" class="w-20">{{_('Zusatz')}}</th>
                                        <td>{{entity.zusatz}}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row" class="w-20">{{_('Anschrift')}}</th>
                                        <td>
                                            {{entity.anschrift1}} <br *ngIf="entity.anschrift1!==''"/>
                                            {{entity.anschrift2}}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th scope="row" class="w-20">{{_('Land, Plz, Ort')}}</th>
                                        <td>
                                            <app-country-quick-info [land]="entity.land"></app-country-quick-info>
                                            {{entity.plz}}
                                            {{entity.ort}}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th scope="row" class="w-20">{{_('Telefon')}}</th>
                                        <td>{{entity.telefon}}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row" class="w-20">{{_('Telefax')}}</th>
                                        <td>{{entity.telefax}}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row" class="w-20">{{_('Mobiltelefon')}}</th>
                                        <td>{{entity.mobil}}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row" class="w-20">{{_('Email')}}</th>
                                        <td>
                                            <a href="mailto:{{entity.email}}" target="_blank">{{entity.email}}</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th scope="row" class="w-20"> {{ _('Preisgruppe') }}</th>
                                        <td>
                                            <div *ngIf="entity.preisgruppe!==null && entity.preisgruppe!==undefined">
                                                {{ entity.preisgruppe.title }}
                                            </div>
                                        </td>
                                    ></tr>
                                    <tr>
                                        <th scope="row" class="w-20"> {{ _('Kundenbetreuer') }}</th>
                                        <td>
                                            <div *ngIf="entity.betreuer!==null && entity.betreuer!==undefined">
                                                {{ entity.betreuer.fullname }} <span class="badge badge-default">{{ entity.betreuer.username }}</span>
                                            </div>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </app-pane>
                        <app-pane [title]="_('Billing')">
                            <div *ngIf="isEditing()">
                                <text-field [label]="_('FiBu-Konto')" [(value)]="entity.fibuKonto"></text-field>
                                <text-field [label]="_('Kostenstelle')" [(value)]="entity.kostenstelle"></text-field>
                                <text-field [label]="_('IBAN')" [(value)]="entity.iban"></text-field>
                                <text-field [label]="_('BIC')" [(value)]="entity.bic"></text-field>
                                <text-field [label]="_('Institut')" [(value)]="entity.bank"></text-field>
                            </div>
                            <div *ngIf="isBrowsing()">
                                <table class="table">
                                    <tr>
                                        <th scope="row" class="w-20">{{_('FiBu-Konto')}}</th>
                                        <td>{{entity.fibuKonto}}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row" class="w-20">{{_('Kostenstelle')}}</th>
                                        <td>{{entity.kostenstelle}}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row" class="w-20">{{_('IBAN')}}</th>
                                        <td>{{entity.iban}}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row" class="w-20">{{_('BIC')}}</th>
                                        <td>{{entity.bic}}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row" class="w-20">{{_('Institut')}}</th>
                                        <td>{{entity.bank}}</td>
                                    </tr>
                                </table>
                            </div>
                        </app-pane>
                        <app-pane [title]="_('Rechnungen')" *ngIf="canEdit('rechnungen')">
                            <div class="alert alert-info" *ngIf="isAdding()">
                                {{ _('Bitte den Kundendatensatz zunächst speichern.') }}
                            </div>
                            <data-table
                                    *ngIf="!isAdding()"
                                    [selectable]="false"
                                    [data]="rechnungen.asDataTableRows()"
                                    [orders]="rechnungen.order"
                                    [filter]="rechnungen.simpleFilter"
                                    (sortChanged)="rechnungenSortChanged($event)"
                                    [rowActionsAsButtons]="true"
                                    [tableActionsAsButtons]="true"
                            >
                                <tableaction
                                        [label]="_('Rechnung einbuchen')"
                                        [icon]="'icofont icofont-ui-add'"
                                        (perform)="newInvoice()"
                                        *hasRole="['ROLE_ADMIN','ROLE_PROJECT_ADMIN','ROLE_BILLING']"
                                ></tableaction>

                                <column [field]="'rechnungNummer'"
                                        [label]="_('Rechnungsnummer')"
                                        [sortable]="true"
                                ></column>
                                <column
                                        [field]="'rechnungDatum'"
                                        [label]="_('Datum')"
                                        type="Date"
                                        [cfg]="{format:'shortDate'}"
                                        [sortable]="true"></column>
                                <column
                                        [field]="'summe'"
                                        [label]="_('Endbetrag')"
                                        [renderer]="preiswert"
                                        [cfg]="{fdigits:2,round:true,currency:'EUR'}"
                                        [sortable]="true"></column>

                                <column
                                        [field]="'attachments'"
                                        [label]="_('Dokumente')"
                                        [renderer]="attachmentsR"
                                        [sortable]="true"></column>

                                <rowaction
                                        [label]="_('Details')"
                                        [icon]="'far fa-info-circle'"
                                        [title]="_('Details anzeigen')"
                                        (perform)="showDetails($event)"
                                ></rowaction>
                                <rowaction
                                        [label]="_('Bearbeiten')"
                                        [icon]="'far fa-pencil'"
                                        [title]="_('Rechnung bearbeiten')"
                                        (perform)="editRechnung($event)"
                                        *hasRole="['ROLE_ADMIN','ROLE_PROJECT_ADMIN','ROLE_BILLING']"
                                ></rowaction>

                                <data-table-table-extra-header>
                                    <app-paginator
                                            [total]="rechnungen.total"
                                            [page]="rechnungen.page"
                                            [perpage]="rechnungen.size"
                                            (pageChange)="updateRechnungen(true,$event)"
                                            (pageCountChange)="updateRechnungenPageCount($event)"
                                    ></app-paginator>
                                </data-table-table-extra-header>

                            </data-table>

                        </app-pane>
                        <app-pane [title]="_('Berechtigungen')" *ngIf="canEdit('berechtigungen')">
                            <div *ngIf="isEditing()">
                                <boolean-field [label]="_('Kunde ist aktiv')"
                                               [(value)]="entity.enabled"></boolean-field>
                                <boolean-field [label]="_('Kunde darf sich einloggen')"
                                               [(value)]="entity.canLogin"></boolean-field>
                                <div class="alert alert-danger"
                                     *ngIf="entity.canLogin && !realCanLogin()"
                                >
                                    {{ _('Bitte geben Sie eine gültige Kundennummer an,'+
                                    ' um dem Kunden ein Login zu ermöglichen!') }}
                                </div>
                                <text-field
                                        *ngIf="realCanLogin()"
                                        [label]="_('Login-Kennung')"
                                        [value]="'K-'+entity.kundennummer"
                                        [disabled]="true"
                                ></text-field>
                                <password-field
                                        *ngIf="realCanLogin()"
                                        [label]="_('Login-Passwort')"
                                        [(value)]="entity.login_password"
                                ></password-field>
                                <div
                                        class="alert alert-info"
                                        *ngIf="realCanLogin() && isAdding()"
                                >
                                    {{ _('Ein Login ist nur möglich, wenn Sie hier ein Kennwort festlegen.') }}
                                </div>
                                <div
                                        class="alert alert-info"
                                        *ngIf="realCanLogin() && !isAdding()"
                                >
                                    {{ _('Das Passwort-Feld bitte nur ausfüllen,'+
                                    ' wenn Sie das Login-Kennwort ändern wollen.') }}
                                </div>
                            </div>
                            <div *ngIf="isBrowsing()">
                                <table class="table">
                                    <tr>
                                        <th scope="row" class="w-20">{{_('Kunde ist aktiv')}}</th>
                                        <td>
                                            <i class="far"
                                               [class.fa-check-square]="entity.enabled"
                                               [class.fa-square]="!entity.enabled"
                                            ></i>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th scope="row" class="w-20">{{_('Kunde darf sich einloggen')}}</th>
                                        <td>
                                            <i class="far"
                                               [class.fa-check-square]="entity.canLogin"
                                               [class.fa-square]="!entity.canLogin"
                                            ></i>
                                        </td>
                                    </tr>
                                    <tr *ngIf="realCanLogin()">
                                        <th scope="row" class="w-20">{{_('Login-Kennung')}}</th>
                                        <td>
                                            {{ 'K-'+entity.kundennummer }}
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </app-pane>
                        <app-pane [title]="_('Limitierungen')">
                            <app-kontingent-editor
                                    [disabled]="isBrowsing() || !canEdit('limits')"
                                    [(value)]="entity.kontingent"
                                    [kunde]="entity"
                            ></app-kontingent-editor>
                        </app-pane>

                        <app-pane [title]="_('Freigaben')">
                            <app-freigabe-editor
                                    [disabled]="isBrowsing()"
                                    [mode]="'customer'"
                                    [value]="entity.begrenzungen"
                                    (valueChange)="updateBegrenzungen($event)"
                            ></app-freigabe-editor>
                        </app-pane>
                        <app-pane [title]="_('Karten')">
                            <div class="karten">
                                <div class="alert alert-info" *ngIf="isAdding()">
                                    {{ _('Bitte den Kundendatensatz zunächst speichern.') }}
                                </div>

                                <data-table
                                        *ngIf="!isAdding()"
                                        [selectable]="false"
                                        [data]="ausweise.asDataTableRows()"
                                        [orders]="ausweise.order"
                                        [filter]="ausweise.simpleFilter"
                                        (sortChanged)="ausweiseSortChanged($event)"
                                        [rowActionsAsButtons]="true"
                                >
                                    <column
                                            [field]="'nummer'"
                                            [label]="_('Kartennummer')"
                                            type="integer"
                                            [sortable]="false"></column>
                                    <column
                                            [field]="'adminSperre'"
                                            [label]="_('Adminsperre')"
                                            type="Boolean"
                                            [sortable]="false"></column>
                                    <column
                                            [field]="'kundenSperre'"
                                            [label]="_('Kundensperre')"
                                            type="Boolean"
                                            [sortable]="false"></column>

                                    <column
                                            [field]="'kfzKennzeichen'"
                                            [label]="_('KFZ-Kennzeichen')"
                                            type="text"
                                            [sortable]="false"></column>
                                    <column
                                            [field]="'kontingent'"
                                            [label]="_('verfügbares Limit')"
                                            [renderer]="kontingentCellRenderer"
                                            [sortable]="false"
                                    ></column>
                                    <column
                                            [field]="'begrenzungen'"
                                            [label]="_('Freigaben')"
                                            [renderer]="begrenzungenCellRenderer"
                                            [sortable]="false"
                                    ></column>
                                    <tableaction
                                            [label]="t('Karte anlegen')"
                                            [icon]="'icofont icofont-ui-add'"
                                            (perform)="newAusweis()"
                                    ></tableaction>

                                    <rowaction
                                            [label]="_('Bearbeiten')"
                                            [icon]="'icofont icofont-ui-edit'"
                                            (perform)="editAusweis($event)"
                                            [title]="_('Ausweis bearbeiten')"
                                    ></rowaction>
                                    <rowaction
                                            *hasRole="['ROLE_ADMIN']"
                                            [label]="_('Sync')"
                                            [icon]="'icofont icofont-cloud-refresh'"
                                            (perform)="syncAusweis($event)"
                                    ></rowaction>
                                    <data-table-table-extra-header>
                                        <app-paginator
                                                [total]="ausweise.total"
                                                [page]="ausweise.page"
                                                [perpage]="ausweise.size"
                                                (pageChange)="updateAusweise(true,$event)"
                                                (pageCountChange)="updateAusweisePageCount($event)"
                                                [showSearch]="true"
                                                (queryChange)="ausweiseQueryChange($event)"
                                                [queryFieldVisible]="true"
                                                [query]="ausweise.searchString"
                                                #ausweisePaginator
                                        ></app-paginator>
                                    </data-table-table-extra-header>

                                </data-table>
                            </div>
                        </app-pane>
                        <app-pane [title]="_('Sonstiges')" *ngIf="canEdit('others')">
                            <div *ngIf="isEditing()">
                                <multi-line-field *ngIf="canEdit('notes')" [label]="_('Notizen')" [(value)]="entity.notizen"></multi-line-field>
                                <num-field [label]="_('Tankserver-ID')"
                                           [(value)]="entity.tankserverID"
                                ></num-field>
                                <num-field [label]="_('Ratio-ID')"
                                           [(value)]="entity.ratioID"
                                ></num-field>
                                <date-time-field [label]="_('angelegt')"
                                                 [value]="entity.dateCreated"
                                                 disabled="true"></date-time-field>
                                <date-time-field [label]="_('zuletzt bearbeitet')"
                                                 [value]="entity.lastEdited"
                                                 disabled="true"></date-time-field>
                                <date-time-field [label]="_('zuletzt geändert')"
                                                 [value]="entity.lastUpdated"
                                                 disabled="true"></date-time-field>
                            </div>
                            <div *ngIf="isBrowsing()">
                                <table class="table">
                                    <tr  *ngIf="canEdit('notes')">
                                        <th scope="row" class="w-20">{{_('Notizen')}}</th>
                                        <td>{{entity.notizen}}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row" class="w-20">{{_('Tankserver-ID')}}</th>
                                        <td>{{entity.tankserverID}}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row" class="w-20">{{_('Ratio-ID')}}</th>
                                        <td>{{entity.ratioID}}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row" class="w-20">{{_('angelegt')}}</th>
                                        <td>{{entity.dateCreated}}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row" class="w-20">{{_('zuletzt bearbeitet')}}</th>
                                        <td>{{entity.lastEdited}}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row" class="w-20">{{_('zuletzt geändert')}}</th>
                                        <td>{{entity.lastUpdated}}</td>
                                    </tr>
                                </table>
                            </div>
                        </app-pane>
                        <app-pane [title]="_('Nachverfolgung')" *hasRole="['ROLE_ADMIN','ROLE_PROJECT_ADMIN','ROLE_ADM']">
                            <app-tab-kunde-audit [kunde]="entity"></app-tab-kunde-audit>
                        </app-pane>
                    </app-tabset>

                </div>
            </div>
        </div>
    </div>


</app-login-alert>