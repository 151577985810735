<div class="modal-header">
    <h5 class="modal-title" *ngIf="mode==='ausweis'">{{ _('Kartensalden zurücksetzen') }}</h5>
    <h5 class="modal-title" *ngIf="mode==='kunde'">{{ _('Kundensalden zurücksetzen') }}</h5>
    <button type="button" class="close" aria-label="Close" (click)="cancel()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div *ngIf="mode==='ausweis'">
        {{_('Sollen die aktuellen Salden dieser Karte wirklich resettet werden?')}}<br/>
        {{_('Das vom Kartenherausgeber eingestellte Kundenlimit bleibt unverändert aktiv. ' +
        'Die Karte kann nach einem Reset erneut im Rahmen der eingestellten Limitierungen verwendet werden')}}
        <br/>
        <br/>
        <boolean-field [label]="_('Total-Saldo ebenfalls resetten')" [(value)]="resetPrepaid"></boolean-field>
    </div>
    <div *ngIf="mode==='kunde'">
        {{_('Sollen die aktuellen Salden dieses Kunden wirklich resettet werden?')}}<br/>
        {{_('Das vom Kunden eingestellte jeweilige Kartenlimit bleibt unverändert aktiv. ' +
        'Die Karten des Kunden können nach einem Reset erneut im Rahmen der ' +
        'eingestellten Limitierungen verwendet werden')}}
        <br/>
        <br/>
        <boolean-field [label]="_('Prepaid-Saldo ebenfalls resetten')" [(value)]="resetPrepaid"></boolean-field>
        <br/>
        <div class="alert alert-danger" *ngIf="resetPrepaid">
            {{ _('Achtung! Das Resetten des Prepaidlimits ermöglicht dem Kunden erneut das VOLLSTÄNDIGE ' +
            'Ausschöpfen des eingestellten Kreditrahmens! Es besteht die Gefahr von Vermögensschäden ' +
            'infolge von Zahlungsausfällen!') }}
        </div>
    </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="cancel()">
        <i class="icofont icofont-close"></i> {{_('Abbrechen')}}
    </button>
    <button type="button" class="btn btn-primary" (click)="ok()">
        <i class="icofont icofont-recycle"></i>
        {{ _('Ja, Salden jetzt resetten') }}
    </button>
</div>
