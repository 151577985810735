import {Component, EventEmitter, Input, NgZone, OnInit, Output} from "@angular/core";
import CryptoES from "crypto-es";

@Component({
    selector: "time-field",
    template: `
        <div class="form-group row">
            <label class="col-{{ labelwidth }} col-form-label" *ngIf="labelwidth>0">
                {{ label }}
            </label>
            <div class="col-{{ 12-labelwidth }}">


                <medate-time-picker
                        #rangeStartInput
                        [ngModel]="_value"
                        (ngModelChange)="doSelect($event)"
                        [inputVisible]="true"
                        [disabled]="false"
                        [panelRight]="false"
                        [showFooter]="false"
                        [hasSeconds]="false"
                        [dateOnly]="false"
                        [timeOnly]="true"
                        [name]="'filter1'+label"
                        [size]="size"
                        [displayFormat]="displayFormat"
                ></medate-time-picker>


            </div>
        </div>

    `,
    styles: [`

        :host .addon button:first-child {
            border-left: none;
            padding-left: 5px;
            padding-right: 5px;
        }

    `]
})
export class TimeFieldComponent implements OnInit {

    size = null;
    @Input() displayFormat: string = "HH:mm";
    @Input() label: string = "Timefeld";
    @Input() id: string = "";
    @Input() labelwidth: number = 3;
    @Output() valueChange = new EventEmitter<Date>();

    constructor(public _ngZone: NgZone) {
    }

    _value: Date = null;

    get value(): Date {
        return this._value;
    }

    @Input()
    set value(v: Date) {

        if (this._value != v) {
            this._value = v;
            this.valueChange.emit(v);
        }
    }

    ngOnInit() {
        if (this.id === "") {
            this.id = "tf" + CryptoES.MD5("textfield-" + this.label + Math.random() + "-" + Math.random()).toString();
        }
    }


    doSelect(date: Date) {
        this._value = date;
        this.valueChange.emit(date);
    }


    ngAfterViewInit() {
    }


}
