import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {Preisgruppe} from '../common/preisgruppe';
import {NgbActiveModal, NgbModal, NgbModalOptions, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {LocalizedComponent} from '../../commons/components/localized-component';

import {ToastrService} from 'ngx-toastr';
import {PreiseService} from '../services/preise.service';
import {EPreisgruppeArt} from '../common/epreisgruppe-art.enum';
import {PreisgruppeSetting} from '../common/preisgruppe-setting';
import {ProduktCellRendererComponent} from '../../products/produkt-cell-renderer.component';
import {ProduktCellEditorComponent} from '../../products/produkt-cell-editor.component';
import {DataTableComponent, DataTableRowEditCancelled, DataTableSaveRecord} from '../../commons/data-table/data-table.component';
import {WaehrungCellRendererComponent} from '../../commons/waehrungen/waehrung-cell-renderer/waehrung-cell-renderer.component';
import {WaehrungCellEditorComponent} from '../../commons/waehrungen/waehrung-cell-editor/waehrung-cell-editor.component';
import {IPreisgruppeSetting} from '../common/ipreisgruppe-setting';
import {TextCellEditorComponent} from '../../commons/data-table/editors/text-cell-editor.component';
import {RowActionParams} from '../../commons/data-table/row-action-params';

@Component({
    selector: 'app-preisgruppe-editor',
    templateUrl: './preisgruppe-editor.component.html',
    styleUrls: ['./preisgruppe-editor.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PreisgruppeEditorComponent extends LocalizedComponent implements OnInit {
    entity = new Preisgruppe();
    mode: any = 'new';
    newSetting: PreisgruppeSetting = null;
    produktR = ProduktCellRendererComponent;
    produktE = ProduktCellEditorComponent;
    waehrungR = WaehrungCellRendererComponent;
    waehrungE = WaehrungCellEditorComponent;
    mengeE = TextCellEditorComponent;
    nId = -1;

    @ViewChild('settingsTable',{static: true}) settingsTable: DataTableComponent;


    static open(modalService: NgbModal, preisgruppe?: Preisgruppe): Promise<any> {
        const o: NgbModalOptions = {
            /* size: 'lg',*/
            backdrop: 'static'
        };
        const r: NgbModalRef = modalService.open(PreisgruppeEditorComponent, o);
        (r.componentInstance as PreisgruppeEditorComponent).open(preisgruppe);
        return r.result;
    }


    constructor(
                
                public activeModal: NgbActiveModal,
                public toastr: ToastrService,
                public preiseService: PreiseService,
                public cd: ChangeDetectorRef
    ) {
        super( cd);
    }


    open(preisgruppe?: Preisgruppe) {
        if ((preisgruppe === null) || (preisgruppe === undefined)) {
            this.entity = new Preisgruppe();
            this.mode = 'new';
        } else {
            this.entity = Preisgruppe.fromResult(preisgruppe);
            this.mode = 'edit';
        }
        this.nId = -1;
        this.cd.markForCheck();
    }

    isAdd() {
        return this.mode === 'new';
    }

    isEdit() {
        return this.mode === 'edit';
    }

    ngOnInit() {
    }

    cancel() {
        this.activeModal.dismiss();
    }

    save() {
        this.preiseService
            .storePreisgruppe(this.entity, this.isAdd())
            .subscribe(
                r => {
                    if (r.success) {
                        this.toastr.success(this._('Preisgruppe erfolgreich gespeichert'));
                        this.activeModal.close(this.entity);
                    } else {
                        this.toastr.error(this.t('Fehler beim Sichern der Preisgruppe'));
                    }
                }
            );
    }

    changeArt($event: EPreisgruppeArt) {
        this.entity.gruppeArt = $event;
        this.cd.markForCheck();
    }

    delete() {
        this.preiseService
            .removePreisgruppe(this.entity)
            .subscribe(
                r => {
                    if (r.success) {
                        this.toastr.success(this._('Preisgruppe erfolgreich gelöscht'));
                        this.activeModal.close(this.entity);
                    } else {
                        this.toastr.error(this.t('Fehler beim Löschen der Preisgruppe'));
                    }
                }
            );
    }

    addEntry() {
        this.newSetting = new PreisgruppeSetting();
        this.nId--;
        this.newSetting.id = this.nId;
        this.settingsTable.showEditor();
    }

    editCancelled(event: DataTableRowEditCancelled) {
        event.row.hideEditor();
    }

    saveEntry(event: DataTableSaveRecord) {
         if (event.dataTable === this.settingsTable) {
            let found = false;
            const r: IPreisgruppeSetting = event.record;
            r.wert = parseFloat(('' + r.wert).replace(/[,]/g, '.'));
            console.log(r);
            this.entity.settings.forEach((s, idx) => {
                if (s.id === r.id) {
                    this.entity.settings[idx] = PreisgruppeSetting.fromResult(r);
                    found = true;
                }
            });

             if (!found) {
                 this.entity.settings.push(PreisgruppeSetting.fromResult(r));
             }

             this.entity.createDT();
             console.log(this.entity);
             event.dataTable.data = this.entity.settingsAsDataTableRows();
             event.dataTable.cancelNew();

             this.cd.markForCheck();
        }
    }

    editEntry($event: RowActionParams<any>) {
        $event.row.displayEditor();
    }

    removeEntry($event: any) {
        //
    }
}
