import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {Transaktion} from '../common/transaktion';
import {NgbActiveModal, NgbModal, NgbModalOptions, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';

import {LocalizedComponent} from '../../../commons/components/localized-component';
import {PersonService} from '../../../customers/personen/common/person.service';
import {FahrzeugService} from '../../../customers/fahrzeuge/common/fahrzeug.service';
import {Waehrung} from '../../../commons/waehrungen/waehrung';
import {ProduktCellRendererComponent} from '../../../products/produkt-cell-renderer.component';
import {TankCellRendererComponent} from '../../../stationen/tank-cell-renderer.component';
import {ExtendedZapfpunktRendererComponent} from '../../../stationen/extended-zapfpunkt-renderer.component';
import {PreisWertCellRendererComponent} from '../../../commons/data-table/renderer/preis-wert-cell-renderer.component';
import {MengeCellRendererComponent} from '../../../products/menge-cell-renderer.component';
import {ToastrService} from 'ngx-toastr';
import {Fahrzeug} from '../../../customers/fahrzeuge/common/fahrzeug';

@Component({
               selector   : 'app-transaktion-details',
               templateUrl: './transaktion-details.component.html',
               styleUrls  : ['./transaktion-details.component.css']
           })
export class TransaktionDetailsComponent extends LocalizedComponent implements OnInit {

    produktR = ProduktCellRendererComponent;
    tankR = TankCellRendererComponent;
    zapfpunktR = ExtendedZapfpunktRendererComponent;
    preiswert = PreisWertCellRendererComponent;
    mengeR = MengeCellRendererComponent;

    showBrutto = false;
    entity: Transaktion = new Transaktion();
    mode: any = 'new';

    static open(modalService: NgbModal, transaktion: Transaktion): Promise<any> {
        const o: NgbModalOptions = {
            size    : 'lg',
            backdrop: 'static'
        };
        const r: NgbModalRef = modalService.open(TransaktionDetailsComponent, o);
        (r.componentInstance as TransaktionDetailsComponent).open(transaktion);
        return r.result;
    }


    constructor(

                public activeModal: NgbActiveModal,
                public toastr: ToastrService,
                public fahrzeugService: FahrzeugService,
                public personService: PersonService,
                public cd: ChangeDetectorRef) {
        super( cd);
    }


    open(transaktion?: Transaktion) {
        if ((transaktion === null) || (transaktion === undefined)) {
            this.entity = new Transaktion();
        } else {
            this.entity = Transaktion.fromResult(transaktion);
            if (this.entity.fahrzeug !== null && this.entity.fahrzeug !== undefined) {
                this.fahrzeugService
                    .load(this.entity.fahrzeug.id)
                    .subscribe(
                        f => {
                            this.entity.fahrzeug = Fahrzeug.fromResult(f);
                        },
                        () => {

                        }
                    );
            }
            if (this.entity.person !== null && this.entity.person !== undefined) {
                this.personService
                    .load(this.entity.person.id)
                    .subscribe(
                        f => {
                            this.entity.person = f;
                        },
                        () => {

                        }
                    );
            }
        }
        if (this.entity.waehrung === undefined || this.entity.waehrung === null) {
            this.entity.waehrung = new Waehrung();
            this.entity.waehrung.code = 'EUR';
        }
    }

    ngOnInit() {

    }

    cancel() {
        this.activeModal.dismiss();
    }

    hasAusweis1(): boolean {
        if ((this.entity.ausweiskennung1 !== undefined) &&
            (this.entity.ausweiskennung1 !== null) &&
            (this.entity.ausweiskennung1 !== '')) {
            return true;
        }
        if ((this.entity.ausweis1 !== undefined) &&
            (this.entity.ausweis1 !== null)) {
            return true;
        }
        return false;
    }

    hasAusweis2(): boolean {
        if ((this.entity.ausweiskennung2 !== undefined) &&
            (this.entity.ausweiskennung2 !== null) &&
            (this.entity.ausweiskennung2 !== '')) {
            return true;
        }
        if ((this.entity.ausweis2 !== undefined) &&
            (this.entity.ausweis2 !== null)) {
            return true;
        }
        return false;
    }
}
