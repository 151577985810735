import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {LoggedInGuard} from '../auth/directives/logged-in-guard';
import {SucheComponent} from './suche/suche.component';

const routes: Routes = [
    {
        path       : '',
        component  : SucheComponent,
        canActivate: [LoggedInGuard],
        pathMatch  : 'full'
    }

];

@NgModule({
              imports: [RouterModule.forChild(routes)],
              exports: [RouterModule]
          })
export class SuperSearchRoutingModule {
}
