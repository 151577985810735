import {Terminal} from '../../stationen/terminals/common/terminal';
import {ConverterTool} from '../../../shared/converter-tool';

export class MEVideoControlSettings {

    ip = '192.168.16.';
    port = 2081;
    ssh_port = 14322;
    ssh_user = 'root';
    ssh_password = '';
    terminal: Terminal = null;
    wifi_last_activated: Date = null;
    wifi_ssid = 'ME-VIDEOCONTROL';
    wifi_key = '123456789';
    img_text_left = '';
    reachable: boolean = null;
    last_state: Date = null;
    state_cpu_temp = 0;
    state_mem_free = 0;
    state_mem_total = 0;
    state_mem_used = 0;
    state_rootfs_disk_free = 0;
    state_rootfs_disk_total = 0;
    state_rootfs_disk_used = 0;
    state_sdcard_disk_free = 0;
    state_sdcard_disk_total = 0;
    state_sdcard_disk_used = 0;
    state_swap_free = 0;
    state_swap_total = 0;
    state_swap_used = 0;
    state_system_date:Date=null;

    static fromRAW(st: any): MEVideoControlSettings {
        if (st === null || st === undefined) {
            return null;
        }
        const l = new MEVideoControlSettings();
        for (const k of Object.keys(st)) {
            if (k === 'terminal') {
                l.terminal = Terminal.fromResult(st.terminal || null);
            } else if (k === 'wifi_last_activated') {
                l.wifi_last_activated = ConverterTool.toDate(st.wifi_last_activated);
            } else if (k === 'reachable') {
                l.reachable = ConverterTool.toBoolean(st.reachable);
            } else if (k === 'last_state') {
                l.last_state = ConverterTool.toDate(st.last_state);
            } else if (k === 'state_system_date') {
                l.state_system_date = ConverterTool.toDate(st.state_system_date);
            } else if (k.startsWith('state_')) {
                if (st[k] === null || st[k] === '' || st[k] === 'null') {
                    l[k] = null;
                } else {
                    l[k] = parseInt(st[k], 10);
                    if (k === 'state_cpu_temp') {
                        l[k] = l[k] / 1000;
                    }
                }
            } else if (st[k] !== undefined) {
                l[k] = (st[k] !== undefined) ? st[k] : null;
            }
        }
        return l;
    }
}
