<app-login-alert>

    <app-area-heading
            [icon]="'icofont icofont-user'"
            title="{{ _('Benutzer') }}"
    ></app-area-heading>

    <div class="card" *hasRole="['ROLE_ADMIN','ROLE_PROJECT_ADMIN']">
        <div class="card-body">
            <div class="card-text">
                <data-table
                        [selectable]="false"
                        [data]="benutzer.asDataTableRows()"
                        [orders]="benutzer.order"
                        [filter]="benutzer.simpleFilter"
                        (sortChanged)="sortChanged($event)"
                        (filterChanged)="filterChanged($event)"
                        [rowActionsAsButtons]="true"
                >
                    <column
                            [field]="'id'"
                            [label]="_('ID')"
                            type="integer"
                            [sortable]="true"
                    ></column>
                    <column [label]="_('Benutzerkennung')"
                            [field]="'username'"
                            [sortable]="true"
                    ></column>
                    <column [label]="_('Name')"
                            [field]="'fullname'"
                            [sortable]="true"
                    ></column>
                    <column
                            [field]="'kunde'"
                            [label]="_('Kunde')"
                            [renderer]="kundeR"
                            [sortable]="true"
                    ></column>
                    <column
                            [field]="'accountLocked'"
                            [label]="_('gesperrt?')"
                            type="boolean"
                            [sortable]="true"
                    ></column>
                    <column
                            [field]="'hidden'"
                            [label]="_('verborgen?')"
                            type="boolean"
                            [sortable]="true"
                            *appIsAdmin
                    ></column>
                    <column
                            [field]="'lastLogin'"
                            [label]="_('letzter Login')"
                            type="Date"
                            [sortable]="true"
                    ></column>
                    <column
                            [field]="'permissions'"
                            [label]="_('Berechtigungen')"
                            [renderer]="permissionsRenderer"
                    ></column>


                    <tableaction
                            [label]="t('Benutzer anlegen')"
                            [icon]="'icofont icofont-ui-add'"
                            (perform)="newBenutzer()"
                    ></tableaction>
                    <tableaction
                            *hasRole="['ROLE_ADMIN','ROLE_PROJECT_ADMIN']"
                            [label]="t('Benutzer indizieren')"
                            [icon]="'icofont icofont-cloud-refresh'"
                            (perform)="reindexUsers()"
                    ></tableaction>


                    <rowaction
                            [label]="_('Bearbeiten')"
                            [title]="_('Bearbeiten')"
                            [icon]="'icofont icofont-ui-edit'"
                            (perform)="editBenutzer($event)"
                            (checkEnabled)="checkUser('edit',$event)"
                    ></rowaction>
                    <rowaction
                            [label]="_('Zugang sperren')"
                            [title]="_('Zugang sperren')"
                            [icon]="'icofont icofont-lock'"
                            (perform)="lockBenutzer($event)"
                            (checkEnabled)="checkUser('lock',$event)"
                    ></rowaction>
                    <rowaction
                            [label]="_('Zugang entsperren')"
                            [title]="_('Zugang entsperren')"
                            [icon]="'icofont icofont-unlock'"
                            (perform)="unlockBenutzer($event)"
                            (checkEnabled)="checkUser('unlock',$event)"
                    ></rowaction>
                    <rowaction
                            [label]="_('Löschen')"
                            [title]="_('Löschen')"
                            [icon]="'icofont icofont-ui-delete'"
                            (perform)="deleteBenutzer($event)"
                            (checkEnabled)="checkUser('delete',$event)"
                    ></rowaction>
                    <data-table-table-extra-header>
                        <app-paginator
                                [total]="benutzer.total"
                                [page]="benutzer.page"
                                [perpage]="benutzer.size"
                                (pageChange)="updateBenutzer(true,$event)"
                                (pageCountChange)="updateBenutzerPageCount($event)"
                                [showSearch]="true"
                                (queryChange)="benutzerQueryChange($event)"
                        ></app-paginator>
                    </data-table-table-extra-header>
                </data-table>
            </div>
        </div>
    </div>

</app-login-alert>
