import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {LocalizedComponent} from '../../../commons/components/localized-component';

import {TransaktionList} from '../common/transaktion-list';
import {TransaktionService} from '../common/transaktion.service';
import {Subscription} from 'rxjs';
import {IListResult} from '../../../commons/list-result';
import {ITransaktion} from '../common/itransaktion';
import {SysInfoService} from '../../../commons/sysinfo/services/sys-info.service';

@Component({
    selector: 'app-dashboard-last-transactions-block',
    templateUrl: './dashboard-last-transactions-block.component.html',
    styleUrls: ['./dashboard-last-transactions-block.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DashboardLastTransactionsBlockComponent extends LocalizedComponent implements OnInit, OnDestroy {

    public transaktionen: TransaktionList = new TransaktionList();
    subs: Subscription[] = [];

    constructor(
                
                public cd: ChangeDetectorRef,
                public transaktionService: TransaktionService,
                public sysInfoService: SysInfoService
    ) {
        super( cd);
    }

    ngOnInit() {
        this.subs.push(
            this.transaktionen.onUpdateRequired.subscribe(
                (l: TransaktionList) => {
                    this.transaktionService.list(
                        3, 0
                    ).subscribe(
                        (r: IListResult<ITransaktion>) => {
                            l.populateFromListResult(r);
                            this.cd.markForCheck();
                        }
                    );
                }
            )
        );
        this.transaktionen.reload();
    }

    ngOnDestroy() {
        this.subs.forEach((s: Subscription) => {
            s.unsubscribe();
        });
        this.subs = [];
        super.ngOnDestroy();
    }


}
