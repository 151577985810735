import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dtfahrzeug-nummer-renderer',
  templateUrl: './dtfahrzeug-nummer-renderer.component.html',
  styleUrls: ['./dtfahrzeug-nummer-renderer.component.scss']
})
export class DTFahrzeugNummerRendererComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
