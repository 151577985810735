import {EAusweisArt} from './eausweis-art';

export class AusweisArt {

    static getIcon(ausweisArt: EAusweisArt) {
        let icon = 'icofont icofont-id-card';
        if (ausweisArt !== null) {
            switch (ausweisArt) {
                case EAusweisArt.MAGSTRIPE:
                    icon = 'icofont icofont-credit-card';
                    break;
                case EAusweisArt.SMARTCARD:
                    icon = 'icofont icofont-micro-chip';
                    break;
                case EAusweisArt.BTTRANSPONDER:
                    icon = 'icofont icofont-wifi';
                    break;
                case EAusweisArt.PHONE:
                    icon = 'icofont icofont-smart-phone';
                    break;
                case EAusweisArt.HEAVY_TAG:
                    icon = 'icofont icofont-truck-alt';
                    break;
                case EAusweisArt.CHIPTRANSPONDER:
                    icon = 'me-icon me-icon-rfid-token';
                    break;
                case EAusweisArt.VIRTUAL:
                    icon = 'far fa-head-vr';
                    break;
            }
        }
        return icon;
    }

    static fromString(value: string): EAusweisArt {
        value = ('' + value).toUpperCase().trim();

        if (value === 'UNKNOWN') {
            return EAusweisArt.UNKNOWN;
        } else if (value === 'MAGSTRIPE') {
            return EAusweisArt.MAGSTRIPE;
        } else if (value === 'SMARTCARD') {
            return EAusweisArt.SMARTCARD;
        } else if (value === 'PHONE') {
            return EAusweisArt.PHONE;
        } else if (value === 'BTTRANSPONDER') {
            return EAusweisArt.BTTRANSPONDER;
        } else if (value === 'CHIPTRANSPONDER') {
            return EAusweisArt.CHIPTRANSPONDER;
        } else if (value === 'HEAVY_TAG') {
            return EAusweisArt.HEAVY_TAG;
        } else if (value === 'VIRTUAL') {
            return EAusweisArt.VIRTUAL;
        } else if (!isNaN(parseInt(value, 10))) {
            if (parseInt(value, 10) >= 0 && parseInt(value, 10) < 8) {
                return parseInt(value, 10);
            }
        }
        return EAusweisArt.UNKNOWN;
    }

    static isChiptransponder(value: any) {
        return AusweisArt.fromString(value) === EAusweisArt.CHIPTRANSPONDER;
    }

    static isMagstripe(value: any) {
        return AusweisArt.fromString(value) === EAusweisArt.MAGSTRIPE;
    }
    static isVirtual(value: any) {
        return AusweisArt.fromString(value) === EAusweisArt.VIRTUAL;
    }

    static isOneOf(what: any, values: any[]) {
        const w = AusweisArt.fromString(what);
        let r = false;
        if (Array.isArray(values)) {
            values.forEach( (v) => {
                v = AusweisArt.fromString(v);
                if (w === v) {
                    r = true;
                }
            });
        }
        return r;
    }



}
