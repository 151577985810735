import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Router} from '@angular/router';

import {ToastrService} from 'ngx-toastr';
import {LocalizedComponent} from '../../commons/components/localized-component';
import {ISonde} from '../sonden/isonde';
import {SondenBuchungenDialogComponent} from '../sonden/sonden-buchungen-dialog/sonden-buchungen-dialog.component';
import {noop} from '../../commons/noop';
import {Sonde} from '../sonden/sonde';
import {StationenService} from '../common/stationen.service';

@Component({
  selector: 'app-sonde-dashboard-display',
  templateUrl: './sonde-dashboard-display.component.html',
  styleUrls: ['./sonde-dashboard-display.component.scss']   ,
  changeDetection: ChangeDetectionStrategy.OnPush

})
export class SondeDashboardDisplayComponent extends LocalizedComponent implements OnInit {

    @Input() sondeId = 0;

    sonde: Sonde = null;

    get zeitpunkt() {
        if (!this.isValid()) {
            return '';
        }
        return this.fDate(this.sonde.letzteBuchung.zeitpunkt, 'medium');
    }

    get bezeichnung(): string {
        if (!this.isValid()) {
            return '';
        }
        if (this.sonde.bezeichnung !== null && this.sonde.bezeichnung !== undefined && this.sonde.bezeichnung.trim() !== '') {
            return this.sonde.bezeichnung;
        }
        return ('Sonde ' + this.sonde.nummer).trim();
    }

    get stand(): string {
        if (!this.isValid()) {
            return '';
        }
        const v =  this.sonde.letzteBuchung.menge;
        return this.fNum(v, '1.0-0') + ' l';
    }

    constructor(
                public sondeService: StationenService,
                public cd: ChangeDetectorRef,
                public modalService: NgbModal,
                public router: Router,
                public toastr: ToastrService
    ) {
        super( cd);
    }

    isValid() {
        return this.sondeId > 0 && this.sonde !== null && this.sonde !== undefined && this.sonde.letzteBuchung !== null && this.sonde.letzteBuchung !== undefined;
    }

    update() {
        this.sondeService
            .loadSonde(null, this.sondeId)
            .subscribe(
                (so: ISonde) => {
                    this.sonde = Sonde.fromResult(so);
                    this.cd.markForCheck();
                },
                () => {
                    this.sonde = null;
                    this.cd.markForCheck();
                }
            );

    }

    ngOnInit() {
        this.update();
    }

    gotoSonden() {
        this.router.navigate(['sonden']);
    }

    showPeilungen() {
        this.sondeService.loadSonde(null, this.sondeId).subscribe(
            (s: ISonde) => {

              SondenBuchungenDialogComponent.open(this.modalService, s)
                    .then(
                        noop, noop
                    );
            },
            () => {
              this.toastr.error(this._('Sonde nicht gefunden'), this._( 'Zu dieser Sonde wurden keine Daten gefunden'));
            });
    }

  getClasses() {
      if (!this.isValid()) {
        return 'gray';
      }

      if (this.sonde.letzteBuchung.menge < this.sonde.warnmenge) {
        return 'red';
      }

    return 'green';
  }
}
