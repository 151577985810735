<app-login-alert>

    <app-area-heading
            [icon]="'me-icon me-icon-sonde'"
            title="{{ _('Tanküberwachung') }}"
    ></app-area-heading>


    <app-tabcard>
        <app-pane title="{{ _('aktive Sonden') }}">
            <app-tab-aktive-sonden></app-tab-aktive-sonden>
        </app-pane>
    </app-tabcard>


</app-login-alert>