<data-table
        [selectable]="false"
        [data]="kostenstellen.asDataTableRows()"
>
  <column [field]="'kostenstelle'" [label]="_('Kostenstelle')"></column>
  <column [field]="'info'" [label]="_('Informationen')"></column>
  <column [field]="'buchungskreis'" [label]="_('Buchungskreis')"></column>
  <tableaction [label]="t('Kostenstelle anlegen')" [icon]="'icofont icofont-ui-add'" (perform)="addKostenstelle()"></tableaction>
  <tableaction
          *hasRole="['ROLE_ADMIN','ROLE_PROJECT_ADMIN']"
          [label]="t('Werkzeuge')"
          [icon]="'icofont icofont-tools'"
          [type]="'dropdown'"
  >
    <list-option
            [label]="t('Kostenstellen indizieren')"
            [icon]="'icofont icofont-cloud-refresh'"
            (perform)="reindexKostenstellen()"
    ></list-option>
  </tableaction>

  <rowaction [label]="t('Kostenstelle bearbeiten')"
             [icon]="'icofont icofont-ui-edit'"
             (perform)="editKostenstelle($event)"
  ></rowaction>
  <rowaction [label]="t('Kostenstelle löschen')"
             [icon]="'icofont icofont-ui-delete'"
             [cmd]="'delete'"
             (perform)="deleteKostenstelle($event)"
  ></rowaction>
</data-table>
<div class="row">
  <div class="col-6"></div>
  <div class="col-6 text-right">
    <app-paginator
            [total]="kostenstellen.total"
            [page]="kostenstellen.page"
            [perpage]="kostenstellen.size"
            (pageChange)="updateKostenstellen(true,$event)"
            (pageCountChange)="updateKostenstellenPageCount($event)"
    ></app-paginator>
  </div>
</div>
