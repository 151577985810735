import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

import {AddOnClickEvent} from './add-on-click-event';
import CryptoES from "crypto-es";

@Component({
    selector: 'text-field',
    template: `
        <div class="form-group row">
            <label for="{{ id }}"
                   class="col-{{ labelwidth }} col-form-label"
                   *ngIf="labelwidth>0">
                {{ label }}
            </label>
            <div class="col-{{ 12-labelwidth }}">
                <div class="d-flex"
                     [class.input-group]="hasAddon()"
                     [class.input-group-sm]="hasAddon() && small">
                    <input
                            *ngIf="!multiline"
                            class="form-control"
                            [class.form-control-sm]="small"
                            type="text"
                            id="{{ id }}"
                            [value]="value"
                            (input)="update($event)"
                            placeholder="{{ label }}"
                            [attr.disabled]="disabled?true:null"
                            [class.disabled]="disabled"

                    >
                    <textarea
                            *ngIf="multiline"
                            class="form-control"
                            [class.form-control-sm]="small"
                            id="{{ id }}"
                            [value]="value"
                            (input)="update($event)"
                            placeholder="{{ label }}"
                            [attr.disabled]="disabled?true:null"
                            [class.disabled]="disabled"
                            [rows]="rows"
                            [cols]="cols"
                            [class.float-left]="nullable && !disabled"
                    ></textarea>
                    <span class="input-group-btn add-on" *ngIf="nullable && !disabled">
        <button class="btn btn-secondary"
                [class.btn-sm]="small"
                type="button"
                (click)="clear()">
            <i class="icofont icofont-eraser"></i>
        </button>
    </span>
                    <span class="input-group-btn addon"
                          *ngFor="let ai of addonIcons;let idx=index">
                        <button
                                class="btn btn-secondary addon-btn-{{ idx }}"
                                [class.btn-sm]="small"
                                type="button"
                                title="{{ _addOnLabels[idx] }}"
                                (click)="aClick(idx)">
                    <i class="{{ ai }}"></i>
            </button>
        </span>

                </div>
            </div>
        </div>

    `,
    styles: [`
        :host .addon button:first-child {
            border-left: none;
            padding-left: 5px;
            padding-right: 5px;
        }

    `]
})
export class TextFieldComponent implements OnInit {

    public _value = '';
    public _icons: string[] = [];
    @Input() cols = 80;
    @Input() rows = 3;
    @Input() label = 'Textfeld';
    @Input() multiline = false;
    @Input() nullable = false;

    @Input()
    set addonicon(v: any) {
        this._icons = [];
        if (Array.isArray(v)) {
            v.forEach(i => {
                this._icons.push(i);
            });
        } else {
            this._icons.push(v);
        }
        while (this._addOnLabels.length < this._icons.length) {
            this._addOnLabels.push('');
        }
    }


    get addonicon(): any {
        if (this._icons.length < 1) {
            return '';
        } else if (this._icons.length < 2) {
            return this._icons[0];
        }
        return this._icons;
    }


    _addOnLabels: string[] = [];

    @Input()
    set addonlabel(v: any) {
        this._addOnLabels = [];
        if (Array.isArray(v)) {
            v.forEach(i => {
                this._addOnLabels.push(i);
            });
        } else {
            this._addOnLabels.push(v);
        }
        while (this._addOnLabels.length < this._icons.length) {
            this._addOnLabels.push('');
        }
    }


    @Input() enableaddon = false;
    @Input() id = '';
    @Input() labelwidth = 3;
    @Input() disabled = false;
    @Input() small = false;

    @Input()
    set value(v: string) {
        if (this._value !== v) {
            this._value = v;
            if (!this.disabled) {
                this.valueChange.emit(v);
            }
        }
    }

    @Output() valueChange = new EventEmitter<string>();
    @Output() addonClick = new EventEmitter<AddOnClickEvent>();

    get value(): string {
        return this._value;
    }

    constructor() {
    }

    ngOnInit() {
        if (this.id === '') {
            this.id = 'tf' + CryptoES.MD5('textfield-' + this.label + Math.random() + '-' + Math.random()).toString();
        }
    }


    update(event) {
        if (!this.disabled) {
            this.value = event.target.value;
        }
    }

    hasAddon(): boolean {
        return !this.disabled && this.enableaddon && (this._icons.length > 0);
    }

    get addonIcons(): any[] {
        if (this.hasAddon()) {
            return this._icons;
        }
        return [];
    }

    aClick(i?: any) {
        const e: AddOnClickEvent = new AddOnClickEvent();
        e.component = this;
        e.addon = i;
        this.addonClick.emit(e);
    }

    clear() {
        if (!this.disabled) {
            this.value = '';
        }
    }

}
