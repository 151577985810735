<dashboard-info-block
        *ngIf="_kunde!==null && _kunde!==undefined"
        [title]="_('Prepaid Restguthaben')"
        [value]="restguthaben"
        [icon]="'icofont icofont-coins'"
        [subtext]="stand"
        [classes]="'green'"
>
  <button class="btn btn-sm btn-outline-secondary m-1"
          (click)="refreshLimit()">
    <i class="far fa-sync fa-fw"
       [class.fa-spin]="isLoading"></i>
    <span class="sr-only">Refresh</span>
  </button>
</dashboard-info-block>