import {Component, Input, OnInit} from '@angular/core';
import {Land} from '../common/land';

@Component({
  selector: 'app-country-quick-info',
  templateUrl: './country-quick-info.component.html'
})
export class CountryQuickInfoComponent implements OnInit {

  _land: Land = null;

  @Input()
  set land(value: Land) {
    this._land = Land.fromResult(value);
  }

  @Input() showLabel = true;

  constructor() { }

  ngOnInit() {
  }

  getIcon() {
    if (this._land !== null && this._land !== undefined) {
      return this._land.laendercode.toLowerCase();
    }
    return 'unknown';
  }

  getName() {
    if (this._land !== null && this._land !== undefined) {
      return this._land.bezeichnung;
    }
    return 'unknown';

  }
}
