import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import CryptoES from "crypto-es";


@Component({
    selector: 'multi-line-field',
    template: `
        <div class="form-group row">
            <label for="{{ id }}" class="col-{{ labelwidth }} col-form-label" *ngIf="labelwidth>0">
                {{ label }}
            </label>
            <div class="col-{{ 12-labelwidth }}">
    <textarea
            cols="{{ cols }}"
            rows="{{ rows }}"
            class="form-control"
            id="{{ id }}"
            [value]="value"
            (input)="value=$event.target.value"
            placeholder="{{ label }}"
    >
    </textarea>
            </div>
        </div>
    `
})
export class MultiLineFieldComponent implements OnInit {
    public _value = '';

    @Input() label = 'Textfeld';
    @Input() id = '';
    @Input() labelwidth = 3;
    @Input() cols = 50;
    @Input() rows = 5;


    @Input()
    set value(v: string) {
        if (this._value !== v) {
            this._value = v;
            this.valueChange.emit(v);
        }
    }

    @Output() valueChange = new EventEmitter<string>();


    get value(): string {
        return this._value;
    }

    constructor() {
    }

    ngOnInit() {
        if (this.id === '') {
            this.id = 'tf' + CryptoES.MD5('textfield-' + this.label + Math.random() + '-' + Math.random()).toString();
        }
    }

}
