import {Component, ElementRef, Input, ViewChild} from '@angular/core';

@Component({
               selector: 'memulti-select-item',
               template: '<div #contentWrapper><ng-content></ng-content></div>'
           })
export class MEMultiSelectItemComponent {

    @Input() value: any        = null;
    @Input() title     = '';
    @Input() selected = false;
    @ViewChild('contentWrapper', {static: true}) content: ElementRef;

    @Input() implies: any  = null;
    @Input() rulesOut: any = null;

    constructor() {
    }

    public isSelected() {
        return !!this.selected;
    }

    public getContent() {
        if (this.content !== null && this.content !== undefined && this.content.nativeElement !== null && this.content.nativeElement !== undefined) {
            return this.content.nativeElement.innerHTML;
        }
        return undefined;
    }

    public doSelect(): boolean {
        if (!this.selected) {
            this.selected = true;
            return true;
        }
        return false;
    }

    public doUnSelect(): boolean {
        if (this.selected) {
            this.selected = false;
            return true;
        }
        return false;
    }


}
