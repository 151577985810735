import {IPreisgruppeSetting} from './ipreisgruppe-setting';
import {Produkt} from '../../products/produkte/common/produkt';
import {Waehrung} from '../../commons/waehrungen/waehrung';

export class PreisgruppeSetting implements IPreisgruppeSetting {
    id = 0;
    produkt: Produkt = null;
    waehrung: Waehrung = null;
    wert = 0;

    static fromResult(entity: IPreisgruppeSetting): PreisgruppeSetting {
        if ((entity === null) || (entity === undefined)) {
            return null;
        }

        const l = new PreisgruppeSetting();
        for (const k of Object.keys(l)) {
            if (k === 'waehrung') {
                l.waehrung = Waehrung.fromResult(entity.waehrung);
            } else if (k === 'produkt') {
                l.produkt = Produkt.fromResult(entity.produkt);
            } else {
                l[k] = (entity[k] !== undefined) ? entity[k] : '';
            }
        }
        return l;
    }


    constructor() {
    }
}
