<table class="table" *ngIf="device.isMELoragateway()">
    <tr>
        <th class="w-30" scope="row">{{ _('IP Adresse') }}</th>
        <td>{{ device.settings.ip }}</td>
    </tr>
    <tr>
        <th class="w-30" scope="row">{{ _('Port') }}</th>
        <td>{{ device.settings.port }}</td>
    </tr>
    <tr>
        <th class="w-30" scope="row">{{ _('SSH-Port') }}</th>
        <td>{{ device.settings.ssh_port }}</td>
    </tr>
    <tr>
        <th class="w-30" scope="row">{{ _('Terminal') }}</th>
        <td>
            <app-terminal-quick-info
                    [terminal]="device.settings.terminal"></app-terminal-quick-info>
        </td>
    </tr>
    <tr>
        <th class="w-30" scope="row">{{ _('MAC-Adresse') }}</th>
        <td>{{ device.settings.mac }}</td>
    </tr>
    <tr>
        <th class="w-30" scope="row">{{ _('Kunde-ID') }}</th>
        <td>{{ device.settings.customer_id }}</td>
    </tr>
    <tr *hasRole="['ROLE_ADMIN','ROLE_PROJECT_ADMIN']">
        <td colspan="2" class="buttons">
            <button class="btn btn-secondary" (click)="sendAction('sendConfig')">{{ _('Konfiguration senden') }}
            </button>
            <button class="btn btn-secondary" (click)="sendAction('discovery')">{{ _('Geräte suchen') }}
            </button>
        </td>
    </tr>
</table>
