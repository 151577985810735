import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {Kunde} from '../kunden/common/kunde';
import {LoginService} from '../../auth/services/login.service';
import {KundenService} from '../kunden/common/kunden.service';
import {Router} from '@angular/router';

@Component({
    selector: 'app-dashboard-limit-block',
    templateUrl: './dashboard-limit-block.component.html',
    styleUrls: ['./dashboard-limit-block.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DashboardLimitBlockComponent implements OnInit {

    _kunde: Kunde = null;
    isLoading = false;

    @Input() destination = '';

    @Input()
    set kunde(kd: Kunde) {
        if (kd !== this._kunde) {

            this._kunde = kd;
            this.cd.markForCheck();
        }
    }

    constructor(public cd: ChangeDetectorRef,
                public kundeService: KundenService,
                public router: Router
    ) {

    }

    ngOnInit() {

    }

    refreshLimit() {
        this.isLoading = true;
        this.cd.markForCheck();
        this.kundeService
            .requestLimits(this._kunde)
            .subscribe(
                (kd: Kunde) => {
                    this.isLoading = false;
                    if (kd !== null) {
                        this._kunde = kd;
                    }
                    this.cd.markForCheck();
                },
                () => {
                    this.isLoading = false;
                    this.cd.markForCheck();
                }
            );


    }


    gotoArea() {
        this.router.navigate([this.destination]);
    }
}
