<div class="card d-flex flex-row justify-content-between">
    <div class="p-2 w-50">
        <div class="table-responsive">
            <table class="table">
                <thead>
                <tr>
                    <th>{{_('Bezeichnung')}}</th>
                    <th class="text-right">{{_('Größe')}}</th>
                    <th style="width: 50px;"></th>
                </tr>
                </thead>
                <tr *ngFor="let at of _attachments">
                    <td>
                        <i [class]="at.getIcon()"></i> {{ shorten(at.name,35) }}
                    </td>
                    <td class="text-right">
                        <span *ngIf="at.size>0">
                            {{bytes(at.size)}}
                        </span>
                        <span *ngIf="at.size<1">
                            {{_('unbekannt')}}
                        </span>
                    </td>
                    <td style="width: 50px;" class="text-right">
                        <div class="btn-group btn-group-sm"
                             role="group" aria-label="Optionen">
                            <button type="button"
                                    class="btn btn-secondary"
                                    title="{{_('Anhang entfernen')}}"
                                    (click)="deleteAnhang(at)"
                            ><i class="icofont icofont-ui-delete"></i></button>
                            <button type="button" class="btn btn-secondary"
                                    title="{{_('Anhang herunterladen')}}"
                                    (click)="downloadAnhang(at)"
                            ><i
                                    class="icofont icofont-download-alt"></i></button>
                        </div>
                    </td>
                </tr>
            </table>
        </div>
    </div>
    <div class="p-2 w-50">
        <h4 class="card-title">{{ _('Anhang hinzufügen') }}</h4>
        <div class="card-text">
            <app-tabset>
                <app-pane [title]="_('Datei')" (activated)="setFile()">
                    <input
                            type="file"
                            (change)="fileSelect($event.target.files)"
                            placeholder="{{ _('Datei auswählen')}}"
                    />
                    <div class="text-center"><em>{{_('oder')}}</em></div>
                    <div fileDropZone class="my-drop-zone p-4"
                         (gotFiles)="fileSelect($event)"
                    >
                        {{ _('Datei hier ablegen') }}
                    </div>
                    <div class="info p-2 d-flex flex-row justify-content-start" *ngIf="currentAttachment.file!==null">
                        <div>{{ _('ausgewählt:') }}&nbsp;</div>
                        <div title="{{ currentAttachment?.file?.name }}"><em>{{ shorten(currentAttachment?.file?.name ,45) }}</em></div>
                    </div>
                </app-pane>
                <app-pane [title]="_('URL')" (activated)="setURL()">
                    <text-field [label]="_('URL')"
                                [value]="currentAttachment.url"
                                (valueChange)="setURL($event)"
                    ></text-field>
                </app-pane>
            </app-tabset>

            <text-field [label]="_('Bezeichnung')" [(value)]="currentAttachment.name"></text-field>
            <text-field [label]="_('Dateiname')" [(value)]="currentAttachment.filename"></text-field>
            <num-field [label]="_('Dateigröße')" [(value)]="currentAttachment.size"></num-field>
            <file-type-field [label]="_('Dateityp')" [(value)]="currentAttachment.type"></file-type-field>
            <button
                    type="button"
                    class="btn btn-primary"
                    (click)="addAttachment()"
                    [disabled]="!canAdd()"
            >
                <i class="far fa-arrow-left"></i> {{ _("Anhang hinzufügen") }}
            </button>
        </div>

    </div>
</div>