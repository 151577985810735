import {HashLocationStrategy, LocationStrategy} from '@angular/common';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {APP_INITIALIZER, ErrorHandler, Injector, NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {Router} from '@angular/router';
import {ServiceWorkerModule} from '@angular/service-worker';
import {NgbModule, NgbPaginationConfig} from '@ng-bootstrap/ng-bootstrap';
import {L10nIntlModule, L10nLoader, L10nTranslationModule} from 'angular-l10n';

import * as moment from 'moment';
import {ToastrModule} from 'ngx-toastr';

import {environment} from '../environments/environment';
import {AppRoutingModule} from './app-routing.module';


import {AppComponent} from './app.component';
import {DashboardControlGuard} from './application/dashboard/dashboard-control-guard';

import {DashboardComponent} from './application/dashboard/dashboard.component';
import {NavComponent} from './application/nav/nav.component';
import {SidebarTogglerComponent} from './application/sidebar/sidebar-toggler.component';
import {SidebarComponent} from './application/sidebar/sidebar.component';
import {HttpTranslationLoader} from './http-translation-loader';

import {initL10n, l10nConfig} from './l10nconfig';
import {AuthModule} from './modules/auth/auth.module';
import {BillingModule} from './modules/billing/billing.module';
import {CommonsModule} from './modules/commons/commons.module';
import {HttpClientInterceptor} from './modules/commons/http-client-interceptor';
import {PubSubService} from './modules/commons/pub-sub.service';
import {CustomersModule} from './modules/customers/customers.module';
import {DevicesModule} from './modules/devices/devices.module';
import {MessagingModule} from './modules/messaging/messaging.module';
import {MEVideoControlModule} from './modules/mevideo-control/mevideo-control.module';
import {PricingModule} from './modules/pricing/pricing.module';
import {ProductsModule} from './modules/products/products.module';
import {StationenModule} from './modules/stationen/stationen.module';
import {SuperSearchModule} from './modules/super-search/super-search.module';
import {SVGToolsModule} from './modules/svgtools/svgtools.module';
import {SystemSettingsModule} from './modules/system-settings/system-settings.module';
import {UserControlsModule} from './modules/user-controls/user-controls.module';
import {UsersModule} from './modules/users/users.module';
import {MEAppInjector} from './shared/meapp-injector';
import {MEErrorHandler} from './shared/meerror-handler';


@NgModule({
              declarations: [
                  AppComponent,
                  DashboardComponent,
                  NavComponent,
                  SidebarComponent,
                  SidebarTogglerComponent
              ],
              imports     : [
                  BrowserAnimationsModule,
                  BrowserModule,

                  FormsModule,
                  HttpClientModule,
                  ServiceWorkerModule.register('/ngsw-worker.js', {enabled: environment.production}),
                  L10nTranslationModule.forRoot(l10nConfig,
                                                {
                                                    translationLoader: HttpTranslationLoader
                                                }),
                  L10nIntlModule,
                  ToastrModule.forRoot({
                                           closeButton: true
                                       }),
                  NgbModule,

                  CommonsModule,
                  AppRoutingModule,
                  AuthModule,
                  UsersModule,
                  ProductsModule,
                  CustomersModule,
                  MessagingModule,
                  BillingModule,
                  StationenModule,
                  PricingModule,
                  SuperSearchModule,
                  UserControlsModule,
                  SystemSettingsModule,
                  MEVideoControlModule,
                  DevicesModule,
                  SVGToolsModule
              ],
              exports     : [
                  AuthModule,
                  CommonsModule,
                  UsersModule,
                  CustomersModule,
                  MessagingModule,
                  ProductsModule,
                  BillingModule,
                  StationenModule,
                  NgbModule,
                  ToastrModule,
                  PricingModule,
                  SuperSearchModule,
                  UserControlsModule,
                  DevicesModule,
                  SVGToolsModule

              ],
              providers   : [
                  {
                      provide   : APP_INITIALIZER,
                      useFactory: MEAppInjector.initInjector,
                      deps      : [Injector],
                      multi     : true
                  },
                  {
                      provide : ErrorHandler,
                      useClass: MEErrorHandler
                  },
                  {
                      provide : LocationStrategy,
                      useClass: HashLocationStrategy
                  },
                  {
                      provide : HTTP_INTERCEPTORS,
                      useClass: HttpClientInterceptor,
                      deps    : [PubSubService, Router],
                      multi   : true
                  },
                  {
                      provide   : APP_INITIALIZER,
                      useFactory: initL10n,
                      deps      : [L10nLoader],
                      multi     : true
                  },
                  DashboardControlGuard
              ],
              bootstrap   : [AppComponent]
          })
export class AppModule {
    constructor(private pgConfig: NgbPaginationConfig, public injector: Injector
                /*,
                private locale: LocaleService,
                private translation: TranslationService,
                private http: HttpClient,
                private l10Loader: L10nLoader,
                @Inject(TRANSLATION_CONFIG) private translationConfig: TranslationConfig,
                 @Inject(LOCALE_CONFIG) private localeConfig: LocaleConfig */
    ) {
        MEAppInjector.init(injector);


        moment.locale('de');

        pgConfig.size = 'sm';
        pgConfig.boundaryLinks = true;
        pgConfig.ellipses = true;
        pgConfig.maxSize = 5;

        /*
        this.http.get<IListResult<ISprache>>(
            '/resources/locales.json',
            {
                headers: {
                    'Content-Type': 'application/json'
                }
            }
        ).subscribe(
            (res) => {

                res.data.forEach((s) => {
                    if (s.code !== 'de') {
                        this.localeConfig.languages.push({code: s.code, dir: 'ltr'});
                    }
                });

                this.l10Loader.load();
            },
            () => {
                this.l10Loader.load();
            }
        );

    }
         */

    }
}
