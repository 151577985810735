import {ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DiagrammLineComponent} from './diagramm-line.component';
import { DiagrammBalkenComponent } from './diagramm-balken.component';

const components = [
    DiagrammLineComponent,
    DiagrammBalkenComponent
];
const services = [];

@NgModule({
    declarations: [
        ...components
    ],
    exports: [
        ...components
    ],
    entryComponents: [
        ...components
    ],
    imports: [
        CommonModule
    ]
})
export class SVGToolsModule {

}
