import {AfterViewInit, Component, EventEmitter, Input, NgZone, OnInit, Output, ViewChild} from '@angular/core';

import {Einheit} from './einheiten/einheit';
import {EinheitList} from './einheiten/einheit-list';
import {ProdukteService} from './produkte.service';
import {SimpleEinheitFilter} from './einheiten/simple-einheit-filter';
import {
    MEHighlitePipe,
    MESelectComponent,
    MESelectItemMatcherCallback,
    MESelectItemTextCallback
} from '../commons/dynamic-form/me-select/meselect.component';
import {MESelectItemFormatterCallback} from '../commons/dynamic-form/me-select/meselect-item-formatter-callback';
import {IListResult} from '../commons/list-result';
import {IEinheit} from './einheiten/ieinheit';
import CryptoES from "crypto-es";

@Component({
    selector: 'einheit-field',
    template: `
        <div class="form-group row">
            <label for="{{ id }}" class="col-{{ labelwidth }} col-form-label">
                {{ label }}
            </label>
            <div class="col-{{ 12-labelwidth }}">

                <meselect
                        id="{{ id }}"
                        [idField]="'einheit'"
                        [items]="einheiten.data"
                        [placeholder]="label"
                        (itemSelected)="doSelect($event)"
                        [nullable]="nullable"
                >

                </meselect>

            </div>
        </div>
    `,
    styles: [`
    `]
})
export class EinheitFieldComponent implements OnInit, AfterViewInit {

    @Input() nullable = true;

    @ViewChild(MESelectComponent, {static: true}) einheitMESelect: MESelectComponent;

    public einheiten: EinheitList = new EinheitList();
    @Input() label = 'Textfeld';
    @Input() id = '';
    @Input() labelwidth = 3;
    @Input() maxDisplayEinheiten = 400;
    @Output() valueChange = new EventEmitter<Einheit>();

    constructor(public produkteService: ProdukteService,
                public _ngZone: NgZone) {
        this.produkteService
            .listEinheiten(this.maxDisplayEinheiten, 0)
            .subscribe(
                (c: IListResult<IEinheit>) => {
                    this.einheiten.populateFromListResult(c);
                }
            );
    }

    public _value: Einheit = null;

    get value(): Einheit {
        return this._value;
    }

    @Input('value')
    set value(v: Einheit) {

        if (this._value !== v) {
            this._value = v;
            this.valueChange.emit(v);
        }
    }

    ngOnInit() {
        if (this.id === '') {
            this.id = 'tf' + CryptoES.MD5('textfield-' + this.label + Math.random() + '-' + Math.random()).toString();
        }
    }

    ngAfterViewInit(): void {
        this.einheitMESelect.onFormat = this.itemFormat;
        this.einheitMESelect.onGetText = this.itemText;
        this.einheitMESelect.onMatch = this.itemMatcher;
        this.einheitMESelect.value = this._value;
    }

    doSearch(value) {
        const lf: SimpleEinheitFilter = new SimpleEinheitFilter();

        if (value.trim() !== '') {
            lf.bezeichnung = value.trim();
            lf.symbol = value.trim();
            lf._operations['bezeichnung'] = 'OR';
            lf._operations['symbol'] = 'OR';
        }

        this
            .produkteService
            .listEinheiten(this.maxDisplayEinheiten, 0, [], lf)
            .subscribe(
                (c: IListResult<IEinheit>) => {
                    this.einheiten.populateFromListResult(c);
                }
            );
    }

    doSelect(einheit: Einheit) {
        this._value = einheit;
        this.valueChange.emit(einheit);
    }

    public itemFormat: MESelectItemFormatterCallback<Einheit> = (item: Einheit, search) => {
        if (item === null) {
            return '';
        }
        return MEHighlitePipe.transformString(item.bezeichnung, search) +
            ' <em>[' + MEHighlitePipe.transformString(item.symbol, search) + ']</em>';
    }

    public itemText: MESelectItemTextCallback<Einheit> = (item: Einheit) => {
        if (item === null) {
            return '';
        }
        return item.bezeichnung + ' [' + item.symbol + ']';
    }

    public itemMatcher: MESelectItemMatcherCallback<Einheit> = (item: Einheit, search) => {
        if (!search) {
            return true;
        }
        if (search.trim() === '') {
            return true;
        }
        if (!item) {
            return false;
        }
        const x = item.bezeichnung + ' ' + item.symbol;

        return (x.toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) >= 0);
    }


}
