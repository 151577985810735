import {AfterViewInit, Component, EventEmitter, Input, NgZone, OnInit, Output, ViewChild} from '@angular/core';

import {Artikel} from './artikel/artikel';
import {ArtikelList} from './artikel/artikel-list';
import {MESelectComponent} from '../commons/dynamic-form/me-select/meselect.component';
import CryptoES from "crypto-es";

@Component({
    selector: 'artikel-field',
    template: `
        <div class="form-group row">
            <label for="{{ id }}" class="col-{{ labelwidth }} col-form-label">
                {{ label }}
            </label>
            <div class="col-{{ 12-labelwidth }}">
                <artikel-inline-field
                        id="{{ id }}"
                        [value]="_value"
                        (valueChange)="doSelect($event)"
                        [small]="small"
                        [label]="label"
                        [nullable]="nullable"
                ></artikel-inline-field>
            </div>
        </div>
    `
})
export class ArtikelFieldComponent implements OnInit, AfterViewInit {
    @Input() nullable = true;
    @ViewChild(MESelectComponent, {static: true}) artikelMESelect: MESelectComponent;
    artikel: ArtikelList = new ArtikelList();
    @Input() label = 'Textfeld';
    @Input() id = '';
    @Input() labelwidth = 3;
    @Input() maxDisplayArtikel = 50;
    @Input() small = false;
    @Output() valueChange = new EventEmitter<Artikel>();

    constructor(public _ngZone: NgZone) {
    }

    _value: Artikel = null;

    get value(): Artikel {
        return this._value;
    }

    @Input('value')
    set value(v: Artikel) {

        if (this._value !== v) {
            this._value = v;
            this.valueChange.emit(v);
        }
    }

    ngAfterViewInit(): void {

    }

    ngOnInit() {
        if (this.id === '') {
            this.id = 'tf' + CryptoES.MD5('textfield-' + this.label + Math.random() + '-' + Math.random()).toString();
        }
    }

    doSelect(artikel: Artikel) {
        this._value = artikel;
        this.valueChange.emit(artikel);
    }

}
