<div class="modal-header">
    <h5 class="modal-title">{{ _('Übersetzung bearbeiten') }}</h5>
    <button type="button" class="close" aria-label="Close" (click)="cancel()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="alert alert-danger small">
        {{ _('Änderungen an den Übersetzungen wirken sich erst nach einem kompletten Neuladen der Anwendung aus') }}
    </div>

    <table class="table">
        <tr>
            <td valign="top" class="w-20"><strong>{{ _('Original-Text') }}</strong></td>
            <td valign="top"> <div class="alert alert-success small">{{ entity.original }}</div></td>
        </tr>
        <tr>
            <td valign="top" class="w-20"><strong>{{ _('Übersetzungen') }}</strong>
                <br/><br/>
                <div class="alert alert-info small">
                    {{ _('Bitte achten Sie darauf, dass Platzhalter in geschweiften Klammern { und } in den ' +
                    'Übersetzungen unverändert übernommen, also selbst nicht übersetzt, werden. Zeilenumbrüche werden nicht übernommen!') }}
                </div>
            </td>
            <td valign="top">
                <table class="table">
                    <tr *ngFor="let t of entity.translations">
                        <th scope="row" d class="w-25">
                            <i class="flag-icon flag-icon-{{ t.language_flag }} flag-icon-squared"></i>
                            {{ t.language_desc }}:
                        </th>
                        <td>
                            <multi-line-field [labelwidth]="0"
                                              [label]="''"
                                              [rows]="3"
                                              [cols]="60"
                                              [(value)]="t.translation"></multi-line-field>
                        </td>
                    </tr>
                </table>

            </td>
        </tr>
    </table>
</div>

<div class="modal-footer">

    <button type="button" class="btn btn-secondary" (click)="cancel()">
        <i class="icofont icofont-close"></i> {{ _('Abbrechen') }}
    </button>
    <button type="button" class="btn btn-primary" (click)="save()">
        <i class="icofont icofont-save"></i>
        {{ _('Änderungen sichern') }}
    </button>
</div>
