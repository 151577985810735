import {IEinheit} from './ieinheit';

export class Einheit implements IEinheit {
    id = 0;
    bezeichnung = '';
    symbol = '';

    static fromResult(entity: IEinheit): Einheit {
        if (entity === null || entity === undefined) {
            return null;
        }
        const l: Einheit = new Einheit();
        for (let k of Object.keys(l)) {
            l[k] = (entity[k] !== undefined) ? entity[k] : '';
        }
        return l;
    }


    constructor() {
    }
}
