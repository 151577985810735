<me-info-tool
        [title]="getTitle()"
        title-icon="far fa-car"
        [position]="info_position"
        *ngIf="_fahrzeug!==null">
    <app-fahrzeug-info [fahrzeug]="_fahrzeug"></app-fahrzeug-info>
</me-info-tool>
<div *ngIf="showLabel && _fahrzeug!==null && _fahrzeug!==undefined "
     title="{{ _fahrzeug.bezeichnung }}"
     style="display:inline-block;">
    <i class="far fa-car"></i>
    {{ _fahrzeug.kfzKennzeichen }}
    <span class="badge badge-default">{{ _fahrzeug.nummer }}</span>
</div>
