import {ChangeDetectionStrategy, ChangeDetectorRef, Component, ViewContainerRef} from '@angular/core';

import {LocalizedComponent} from './modules/commons/components/localized-component';
import {Benutzer} from './modules/auth/common/benutzer';
import {LoginService} from './modules/auth/services/login.service';
import {ToastrService} from 'ngx-toastr';
import {PubSubService} from './modules/commons/pub-sub.service';


@Component({
               selector       : 'app',
               templateUrl    : './app.component.html',
               styleUrls      : [ './app.component.css' ],
               changeDetection: ChangeDetectionStrategy.Default

           })
export class AppComponent extends LocalizedComponent {
    isLoggedIn = false;
    username   = '';
    adminMessage = '';

    constructor(public toastr: ToastrService,
                vRef: ViewContainerRef,
                public loginService: LoginService,
                
                
                public cd: ChangeDetectorRef,
                public pubsub: PubSubService
    ) {

        super( cd);

        this.pubsub.adminSystemMessageChange.subscribe((msg: string) => {
            if (msg === null || msg === undefined) {
                msg = '';
            }
            this.adminMessage = msg;
            this.cd.markForCheck();
        });
       // this.toastr.setRootViewContainerRef(vRef);

        this.pubsub.loginStateChange.subscribe(
            (benutzer: Benutzer) => {
                this.isLoggedIn = this.loginService.isLoggedIn();
                this.username = this.loginService.getUsername();
                this.cd.markForCheck();
            }
        );

    }


    hasAdminMessage() {
        if (this.loginService.hasOneOfRoles(['ROLE_ADMIN', 'ROLE_PROJECT_ADMIN', 'ROLE_BILLING', 'ROLE_ADM'])) {
            return this.adminMessage.trim() !== '';
        }
        return false;
    }


}
