<app-tabset>
  <app-pane [title]="_('Stationen')">
    <element-list [elements]="filter.stationen"
                  #stationen
                  [renderer]="stationRenderer"
                  (onClearAll)="filter.clearStationen()"
                  (onRemoveElement)="filter.removeStation($event)"
    >
      <div class="header w-50">
        <station-field [label]="_('Station hinzufügen')"
                       (valueChange)="filter.addStation($event)"
        ></station-field>
      </div>
    </element-list>
  </app-pane>

  <app-pane [title]="_('Produkte')">
    <element-list [elements]="filter.products"
                  #produkte
                  [renderer]="produktRenderer"
                  (onClearAll)="filter.clearProducts()"
                  (onRemoveElement)="filter.removeProduct($event)"
    >
      <div class="header w-50">
        <produkt-field [label]="_('Produkt hinzufügen')"
                       (valueChange)="filter.addProduct($event)"
        ></produkt-field>
      </div>
    </element-list>
  </app-pane>
  <app-pane [title]="_('Sonstiges')">
    <boolean-field [label]="_('nur Sonden unterhalb der Warnschwelle')" [(value)]="warnOnly" ></boolean-field>
  </app-pane>

  <app-pane [title]="_('Freitext')">
    <div>
      {{ _('Hier können Sie beliebige Suchanfragen formulieren:')}}
    </div>
    <text-field [label]="_('Suchen nach')"
                [labelwidth]="2"
                [(value)]="filter.freitext"
                [nullable]="true"
                [multiline]="true"></text-field>
  </app-pane>

</app-tabset>
