import * as moment from 'moment';
import {IWaehrung} from './iwaehrung';


export class Waehrung implements IWaehrung {
    iso4217num = '';
    rate = 0;
    lastRateUpdate: Date = null;
    code = '';
    bezeichnung = '';
    symbol = '';

    static fromResult(entity: IWaehrung): Waehrung {
        if ((entity === null) || (entity === undefined)) {
            return null;
        }

        let l: Waehrung = new Waehrung();
        for (let k of Object.keys(l)) {
            if (k === 'lastRateUpdate') {
                l[k] = moment('' + entity.lastRateUpdate, 'YYYY-MM-DDTHH:mm:ssZ').toDate();
            } else {
                l[k] = (entity[k] !== undefined) ? entity[k] : '';
            }
        }
        return l;
    }


    constructor(code= '',
                bezeichnung= '',
                symbol= ''
                ) {
        this.code = code;
        this.bezeichnung = bezeichnung;
        this.symbol = symbol;
    }
}
