<limit-block
        *ngIf="_kunde!==null && _kunde!==undefined"
        [kunde]="_kunde"
>
  <button class="btn btn-sm btn-outline-secondary m-1"
          (click)="refreshLimit()">
    <i class="far fa-sync fa-fw"
       [class.fa-spin]="isLoading"></i>
    <span class="sr-only">Refresh</span>
  </button>
</limit-block>
