import {INachbuchungDetail} from './inachbuchung-detail';
import {IProdukt} from '../../../products/produkte/common/iprodukt';
import {Produkt} from '../../../products/produkte/common/produkt';
import {ConverterTool} from '../../../../shared/converter-tool';

export class NachbuchungDetail implements INachbuchungDetail {
    id = 0;
    pos = 0;
    produkt: IProdukt = null;
    zweck = '';
    menge = 0;
    epreis = 0;
    preis = 0;
    b_preis = 0;
    b_epreis = 0;
    mwst = 0;
    kurs = 1;
    epreis_euro = 0;
    preis_euro = 0;
    b_epreis_euro = 0;
    b_preis_euro = 0;
    mwst_euro = 0;
    mwst_satz = 0;
    kostenstelle = '';
    belegnummer = 0;
    dateCreated: Date = null;
    transferred: Date = null;
    booked: Date = null;
    lastUpdated: Date = null;

     static fromResult(entity: INachbuchungDetail): NachbuchungDetail {
        if ((entity === null) || (entity === undefined)) {
            return null;
        }

        let l = new NachbuchungDetail();
        for (let k of Object.keys(l)) {
            if (k === 'produkt') {
                l.produkt = Produkt.fromResult(entity.produkt)
            } else if (k === 'dateCreated') {
                l.dateCreated = ConverterTool.toDate(entity.dateCreated);
          /*  }else if (k==='waehrung') {
                l.waehrung = Waehrung.fromResult(entity.waehrung)
            }else if (k==='station') {
                l.station = Station.fromResult(entity.station);
            }else if (k==="terminal") {
                l.terminal = Terminal.fromResult(entity.terminal);
            }else if (k==="details"){
                l.details=[];
                if(Array.isArray(entity.details)){
                    entity.details.forEach((d)=>{
                        l.details.push(Nachb)
                    })
                }*/
            } else {
                l[k] = (entity[k] !== undefined) ? entity[k] : '';
            }
        }

        return l;
    }
}
