import {EventEmitter} from '@angular/core';


export class KitchenTimer extends EventEmitter<any> {

    public timeoutFunc = null;
    public tout = 700;
    public fired = false;
    public meta: any = {};

    constructor(timeout = 700) {
        super();
        this.setTimeout(timeout);
    }

    public setTimeout(timeout: number) {
        this.tout = timeout;
    }

    public fire() {
        this.fired = true;
        this.emit();
        this.timeoutFunc = null;

    }


    public start(force = false) {
        this.stop();
        if (force) {
            this.fire();
            return;
        }
        this.timeoutFunc = setTimeout(() => {
            this.fire();
            this.timeoutFunc = null;
        }, this.tout);
    }

    public stop() {
        if (this.timeoutFunc !== null) {
            clearTimeout(this.timeoutFunc);
            this.timeoutFunc = null;
        }
    }

    public rewind() {
        this.stop();
        this.start();
    }

    public end() {
        if (this.timeoutFunc !== null) {
            clearTimeout(this.timeoutFunc);
            this.timeoutFunc = null;
            this.fire();
        }
    }

    public reset() {
        this.stop();
        this.fired = false;
    }

    public wasFired(): boolean {
        return this.fired;
    }

    public fireWhenNotBefore(force = false) {
        if (!this.wasFired()) {
            this.start(force);
        }
    }

}
