<div *ngIf="hasToolbar()"
     class="table-toolbar d-flex justify-content-between"
>
    <ul class="nav"
        *ngIf="(tabledata!==null) &&
         (tabledata!==undefined) && (cols.length>0) && hasTableActions() && tableActionToolbar()"
    >
        <li *ngFor="let a of tableactions">
            <button class="btn btn-secondary"
                    type="button"
                    (click)="a.performAction()"
                    title="{{ a.title }}"
                    *ngIf="a.isNormalAction()">
                <i class="{{ a.icon }}" *ngIf="a.hasIcon()"></i> {{a.label }}
            </button>
            <div class="dropdown" *ngIf="a.isDropdown() && a.hasOptions()">
                <button class="btn btn-secondary dropdown-toggle"
                        type="button" id="dropdownMenuButton"
                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <i class="{{ a.icon }}" *ngIf="a.hasIcon()"></i> {{a.label }}
                </button>
                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                    <a class="dropdown-item"
                       *ngFor="let o of a.listOptions"
                       (click)="o.performAction()"
                    >
                        <i class="{{ o.icon }}" *ngIf="o.hasIcon()"></i> {{o.label }}
                    </a>
                </div>
            </div>
            <ng-content select="a.id" *ngIf="a.isDropdown"></ng-content>
        </li>
        <li class="" *ngIf="extraTableActions!==null && extraTableActions!==undefined && extraTableActions.length>0">
            <ng-content select="data-table-extra-table-action"></ng-content>
        </li>
    </ul>
    <div
            *ngIf="!((tabledata!==null) && (tabledata!==undefined) && (cols.length>0) && hasTableActions() && tableActionToolbar()) && hasTableActionContainers()"
    >&nbsp;
    </div>
    <div *ngIf="hasTableActionContainers()" class="tableActionContainer">
        <ng-content select="data-table-table-extra-header"></ng-content>
    </div>
</div>
<div class="filter-container"
     *ngIf="showFilterArea && (filterArea!==null  && filterArea!==undefined && filterArea.length>0)">
    <div class="header d-flex justify-content-between">
        <h6>{{ filterLabel }}</h6>
        <a (click)="toggleFilter()">
            <i class="icofont"
               [class.icofont-expand-alt]="filterCollapsed"
               [class.icofont-collapse]="!filterCollapsed"></i>
        </a>
    </div>
    <div class="content" *ngIf="!filterCollapsed">
        <ng-content select="data-table-filter-area"></ng-content>
    </div>
</div>
<div class="table-container" *ngIf="(tabledata!==null) && (tabledata!==undefined) && (cols.length>0)"
     [ngStyle]="extraContainerStyle">
    <div [class.table-responsive]="responsive">
        <table class="table" [class.table-hover]="selectable">
            <thead>
            <tr class="dt-header-row">
                <th *ngIf="showFirstColumn()" class="select-box-column">
                    <a (click)="selectAll()" *ngIf="selectable && !singleselect &&!hideCheckboxes">
                        <i class="far"
                           [class.fa-check-square]="!allRowsSelected()"
                           [class.fa-square]="allRowsSelected()"
                        ></i>
                    </a>
                </th>
                <th *ngFor="let c of columns"
                    class="{{ c.extraClasses() }} column-{{c.field.toLowerCase().replace('~','-')}}  {{ c.type.toLowerCase() }}-column"
                    [class.hidden]="!c.visible"
                >
                    {{c.displayLabel()}}
                    <div *ngIf="c.isSortable()">
                        <sort-buttons
                                [field]="c.field"
                                [orders]="orders"
                                (onClick)="onSortChanged(c.field,$event)"
                        ></sort-buttons>
                    </div>
                </th>
                <th class="actions" *ngIf="hasActionsColumn()">
                    <div class="dropdown" *ngIf="hasTableActions() && !tableActionToolbar()">
                        <a class="menu-dots" data-toggle="dropdown" aria-haspopup="true"
                           aria-expanded="false">
                            <i class="far fa-ellipsis-v"></i>
                        </a>
                        <div class="dropdown-menu dropdown-menu-right">
                            <a *ngFor="let a of tableactions"
                               class="dropdown-item"
                               title="{{ a.title }}"
                               (click)="a.performAction()">
                                <i class="{{ a.icon }}" *ngIf="a.hasIcon()"></i> {{ a.label }}
                            </a>
                        </div>
                    </div>

                </th>
            </tr>
            </thead>
            <tbody>
            <tr *ngIf="showInsertRow" class="dt-insert-row">
                <td *ngIf="showFirstColumn()" class="select-box-column">
                    <i class="far fa-plus"></i>
                </td>
                <td *ngFor="let c of columns"
                    class="{{ c.extraClasses() }} column-{{c.field.toLowerCase().replace('~','-')}}  {{ c.type.toLowerCase() }}-column"
                    [class.hidden]="!c.visible"

                >
                    <cell-editor [componentData]="c.getEditor(_newEntity[c.field],self)"></cell-editor>
                </td>
                <td class="actions">
                    <div class="btn-group btn-group-sm" role="group">
                        <button type="button" class="btn btn-sm btn-primary" (click)="saveNew()"><i
                                class="icofont icofont-save"></i></button>
                        <button type="button" class="btn btn-sm btn-secondary" (click)="cancelNew()"><i
                                class="icofont icofont-close"></i></button>
                    </div>
                </td>

            </tr>
            <tr *ngFor="let row of tabledata;let rowIndex=index;"
                class="dt-body-row {{ getExtraRowClasses(row) }}"
                [class.row-selectable]="selectable && !row.showEditor"
                [class.selected]="selectable && row.selected && !row.showEditor"
            >
                <td *ngIf="showFirstColumn()" class="select-box-column">
                    <a (click)="toggleSelect(row)" *ngIf="selectable && !row.showEditor && !hideCheckboxes">
                        <i class="far"
                           [class.fa-check-square]="row.selected"
                           [class.fa-square]="!row.selected"
                        ></i>
                    </a>
                    <i class="far fa-edit" *ngIf="row.showEditor"></i>
                </td>
                <td *ngFor="let c of columns"
                    class="{{ c.extraClasses() }} column-{{c.field.toLowerCase().replace('~','-')}}  {{ c.type.toLowerCase() }}-column"
                    (click)="toggleSelect(row)"
                    [title]="c.displayTitle(row)"
                    [class.hidden]="!c.visible"

                >
                    <ng-container *ngIf="c.hasTemplate()">
                        <ng-container *ngTemplateOutlet="c.templateRef;context:{it:row.data,row:row,column:c}"></ng-container>
                    </ng-container>
                    <ng-container *ngIf="!c.hasTemplate()">
                    <cell-renderer
                            [componentData]="row.renderers[c.field]"
                            *ngIf="!row.showEditor || !c.isEditable()"
                    ></cell-renderer>
                    <cell-editor
                            [componentData]="c.getEditor(row.data[c.field],self,row)"
                            *ngIf="row.showEditor && c.isEditable()"
                    ></cell-editor>
                    </ng-container>
                </td>
                <th class="actions" *ngIf="hasActionsColumn()">
                    <div class="dropdown" *ngIf="hasRowActions() && !row.showEditor && !rowActionsAsButtons">
                        <a class="menu-dots" data-toggle="dropdown" aria-haspopup="true"
                           aria-expanded="false">
                            <i class="far fa-ellipsis-v"></i>
                        </a>

                        <ul class="dropdown-menu dropdown-menu-right">
                            <li *ngFor="let a of rowactions"
                                class=""
                                [class.dropdown-item]="!a.isSeparator()"
                                [class.dropdown-divider]="a.isSeparator()"
                                [class.disabled]="row.isActionDisabled(a)"
                                [class.hide]="a.isHidden(row)"
                            >
                                <a
                                        (click)="a.performAction(row)"
                                        *ngIf="!a.isSeparator()"
                                        title="{{ a.title }}"
                                >
                                    <i class="{{ a.icon }}" *ngIf="a.hasIcon()"></i> {{ a.label }}
                                </a>
                            </li>
                        </ul>

                    </div>

                    <ul class="nav navbar" role="group"
                        *ngIf="hasRowActions() && !row.showEditor && rowActionsAsButtons">
                        <li class="nav-item" *ngFor="let a of rowactions;let aIndex=index">
                            <button
                                    *ngIf="a.isNormalAction()"
                                    type="button"
                                    class="btn btn-sm btn-secondary"
                                    [class.hide]="a.isSeparator() || a.isHidden(row)"
                                    [class.disabled]="row.isActionDisabled(a)"
                                    (click)="a.performAction(row)"
                                    title="{{ a.title }}"
                            >
                                <i class="{{ a.icon }}" *ngIf="a.hasIcon() && !a.isSeparator()"></i>
                                <span *ngIf="a.showLabel && !a.isSeparator()"> {{ a.label }}</span>
                            </button>

                            <div class="dropdown"
                                 *ngIf="a.isDropdown() && a.hasOptions()"
                            >
                                <button class="btn btn-secondary dropdown-toggle"
                                        type="button"
                                        id="{{ 'raDd'+rowIndex+'-'+aIndex }}"
                                        title="{{ a.title }}"

                                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <i class="{{ a.icon }}" *ngIf="a.hasIcon()"></i>
                                    <span *ngIf="a.showLabel">{{a.label }}</span>
                                </button>
                                <ul class="dropdown-menu"
                                    [attr.aria-labelledby]="'raDd'+rowIndex+'-'+aIndex"
                                    [class.dropdown-menu-right]="a.alignRight"
                                >
                                    <li *ngFor="let o of a.listOptions">
                                        <a class="dropdown-item"
                                           *ngIf="o.isNormalAction()"
                                           (click)="o.performAction(row)"
                                           [class.hide]="o.isSeparator() || o.isHidden(row)"
                                           [class.disabled]="row.isActionDisabled(o)"
                                           title="{{ o.title }}"
                                        >
                                            <i class="{{ o.icon }}" *ngIf="o.hasIcon()"></i> {{o.label }}
                                        </a>
                                        <div class="dropdown-divider" *ngIf="o.isSeparator()"></div>
                                    </li>
                                </ul>
                            </div>
                        </li>
                    </ul>


                    <div class="btn-group btn-group-sm" role="group" *ngIf="row.showEditor">
                        <button type="button" class="btn btn-sm btn-primary" (click)="saveRow(row)"><i
                                class="icofont icofont-save"></i></button>
                        <button type="button" class="btn btn-sm btn-secondary" (click)="cancelRow(row)"><i
                                class="icofont icofont-close"></i></button>
                    </div>

                </th>

            </tr>
            </tbody>
            <tfoot>
            <tr *ngIf="isFilterable()" class="dt-filter-row">
                <th *ngIf="showFirstColumn()" class="select-box-column">
                    <i class="icofont icofont-filter"></i>
                </th>
                <th *ngFor="let c of columns"
                    class="{{ c.extraClasses() }} column-{{c.field.toLowerCase().replace('~','-')}} {{ c.type.toLowerCase() }}-column"
                    [class.hidden]="!c.visible"

                >
                    <cell-filter [componentData]="c.getFilter(filter,self)"></cell-filter>
                </th>
                <th class="actions" *ngIf="hasActionsColumn()">
                    <i class="icofont icofont-filter"></i>
                </th>
            </tr>
            </tfoot>
        </table>
    </div>
    <div class="dt-footer">
        <ng-content select="data-table-footer"></ng-content>
    </div>
</div>