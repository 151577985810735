import {ChangeDetectorRef, Component, Input} from '@angular/core';
import {Fahrzeug} from '../common/fahrzeug';

import {FahrzeugService} from '../common/fahrzeug.service';
import {LocalizedComponent} from '../../../commons/components/localized-component';

@Component({
    selector: 'app-fahrzeug-info',
    templateUrl: './fahrzeug-info.component.html',
    styleUrls: ['./fahrzeug-info.component.css']
})
export class FahrzeugInfoComponent extends LocalizedComponent {
    _fahrzeug: Fahrzeug = null;

    @Input()
    set fahrzeug(value: Fahrzeug) {
        this._fahrzeug = Fahrzeug.fromResult(value);
        if (this._fahrzeug !== null && this._fahrzeug !== undefined) {
            this.fahrzeugService.load(this._fahrzeug.id).subscribe(f => {
                this._fahrzeug = Fahrzeug.fromResult(f);
            });
        }
    }

    constructor(
                
                public fahrzeugService: FahrzeugService,
                public cd:ChangeDetectorRef) {
        super(cd);
    }

}
