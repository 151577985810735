import {Component, EventEmitter, Input, NgZone, OnInit, Output, ViewChild} from '@angular/core';
import {
    MEHighlitePipe,
    MESelectComponent,
    MESelectItemMatcherCallback,
    MESelectItemTextCallback
} from '../commons/dynamic-form/me-select/meselect.component';
import {ZapfsaeuleList} from './saeulen/common/zapfsaeule-list';
import {Zapfsaeule} from './saeulen/common/zapfsaeule';
import {Station} from './common/station';
import {StationenService} from './common/stationen.service';

import {MESelectItemFormatterCallback} from '../commons/dynamic-form/me-select/meselect-item-formatter-callback';
import {IListResult} from '../commons/list-result';
import {IZapfsaeule} from './saeulen/common/izapfsaeule';
import {MESelectSearchEvent} from '../commons/dynamic-form/me-select/meselect-search-event';
import CryptoES from "crypto-es";

@Component({
    selector: 'zapfsaeule-field',
    template: `
        <div class="form-group row">
            <label for="{{ id }}" class="col-{{ labelwidth }} col-form-label" *ngIf="labelwidth>0">
                {{ label }}
            </label>
            <div class="col-{{ 12-labelwidth }}">
                <meselect
                        id="{{ id }}"
                        [idField]="'id'"
                        [items]="saeulen?.data"
                        [asMatrix]="false"
                        [placeholder]="label"
                        (itemSelected)="doSelect($event)"
                        (needsSearch)="doSearch($event)"
                        [small]="small"
                        [nullable]="nullable"
                >

                </meselect>

            </div>
        </div>

    `,
    styles: [`
        :host .addon button:first-child {
            border-left: none;
            padding: 0 5px;
        }

    `]
})
export class ZapfsaeuleFieldComponent implements OnInit {
    @Input() nullable = true;
    @ViewChild(MESelectComponent, {static: true}) saeulenMESelect: MESelectComponent;
    @Input() small = false;

    public saeulen: ZapfsaeuleList = new ZapfsaeuleList();
    @Input() label = 'Textfeld';
    @Input() id = '';
    @Input() labelwidth = 3;
    @Input() maxDisplaySaeulen = 50;

    @Input() station: Station = null;
    @Output() valueChange = new EventEmitter<Zapfsaeule>();

    constructor(public stationenService: StationenService,
                public _ngZone: NgZone) {
    }

    public _value: Zapfsaeule = null;

    get value(): Zapfsaeule {
        return this._value;
    }

    @Input()
    set value(v: Zapfsaeule) {

        if (this._value !== v) {
            this._value = v;
            this.valueChange.emit(v);
        }
    }

    ngOnInit() {
        this.doSearch(new MESelectSearchEvent('', this.saeulenMESelect));


        this.saeulenMESelect.onFormat = this.itemFormat;
        this.saeulenMESelect.onGetText = this.itemText;
        this.saeulenMESelect.onMatch = this.itemMatcher;
        this.saeulenMESelect.value = this._value;
        if (this.id === '') {
            this.id = 'tf' + CryptoES.MD5('textfield-' + this.label + Math.random() + '-' + Math.random()).toString();
        }
    }

    doSearch(value: MESelectSearchEvent) {


        this
            .stationenService
            .listSaeulen(this.station, this.maxDisplaySaeulen, 0, [], null, (value === null || value === undefined) ? '' : value.queryString.trim())
            .subscribe(
                (c: IListResult<IZapfsaeule>) => {
                    this.saeulen.populateFromListResult(c);
                }
            );
    }

    doSelect(saeule: Zapfsaeule) {
        this._value = saeule;
        this.valueChange.emit(saeule);
    }

    public itemFormat: MESelectItemFormatterCallback<Zapfsaeule> = (item: Zapfsaeule, search) => {
        if (item === null) {
            return '';
        }
        return '<i class="me-icon me-icon-zapfsaeule"></i> ' +
            MEHighlitePipe.transformString(item.bezeichnung, search) +
            ' <span class=\'badge-default badge\'>' +
            MEHighlitePipe.transformString('' + item.nummer, search) +
            '</span>';
    };

    public itemText: MESelectItemTextCallback<Zapfsaeule> = (item: Zapfsaeule) => {
        if (item === null) {
            return '';
        }
        return item.bezeichnung + ' [' + item.nummer + ']';
    };

    public itemMatcher: MESelectItemMatcherCallback<Zapfsaeule> = (item: Zapfsaeule, search) => {
        if (!search) {
            return true;
        }
        if (search.trim() === '') {
            return true;
        }
        if (!item) {
            return false;
        }
        const x = item.bezeichnung + ' ' + item.nummer;

        return (x.toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) >= 0);
    };


}
