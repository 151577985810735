import {Component, Injector, OnInit} from '@angular/core';
import {CommonCellRenderer} from '../../../commons/data-table/renderer/common-cell-renderer';
import {ISondenBuchung} from '../isonden-buchung';
import {DataTableRow} from '../../../commons/data-table/data-table-row';
import {DataTableColumnDirective} from '../../../commons/data-table/data-table-column.directive';

@Component({
    selector: 'app-dtsonden-buchung-cell-renderer',
    template: `
        <app-sonden-buchung-quick-info *ngIf="isValid()" [sondenBuchung]="cell" [cfg]="cfg"></app-sonden-buchung-quick-info>
    `
})
export class DTSondenBuchungCellRendererComponent extends CommonCellRenderer {
    cell: ISondenBuchung = null;
    row: DataTableRow<any> = null;
    column: DataTableColumnDirective = null;
    cfg: any = null;

    constructor(public injector: Injector) {
        super();
        this.cell = this.injector.get('cell');
        this.row = this.injector.get('row');
        this.column = this.injector.get('column');
        if (this.column !== null && this.column !== undefined) {
            this.cfg = this.column.cfg;
        }
    }

    isValid() {
        return (this.cell !== undefined) &&
            (this.cell !== null) &&
            (this.cell.id != null) &&
            (this.cell.id !== undefined) &&
            (this.cell.id > 0);
    }

}
