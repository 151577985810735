import {AfterContentInit, ChangeDetectorRef, Component, ContentChildren, EventEmitter, Input, OnInit, Output, QueryList} from '@angular/core';
import {DataTableRow} from './data-table-row';
import {DataTableAction} from './data-table-action';
import {DataTableRowAction} from './data-table-row-action';
import {CellFilterChangedCallback} from './filters/cell-filter.component';
import {CellEditorAddOnClickCallback, CellEditorChangedCallback, IEditorComponent} from './editors/cell-editor.component';
import {DataTableTableExtraHeaderComponent} from './data-table-table-extra-header.component';
import {DataTableExtraTableActionComponent} from './data-table-extra-table-action.component';
import {DataTableColumnDirective} from './data-table-column.directive';
import {ResultMetaSortImpl} from '../result-meta';
import {ISimpleFilter} from '../simple-filter';
import {TDTGetExtraRowClassesCallback} from './types';
import {DataTableFilterAreaComponent} from './data-table-filter-area.component';


export class DataTableSortChanged {
    field: string;
    direction: string;


    constructor(field: string, direction: string) {
        this.field = field;
        this.direction = direction;
    }
}

export class DataTableFilterChanged {
    field: string;
    value: any;


    constructor(field: string, value: any) {
        this.field = field;
        this.value = value;
    }
}

export class DataTableEditorAddOnClick {
    field: string;
    addon = 0;
    component: any;
    dataTable: DataTableComponent;


    constructor(field: string, addon: number, component: any, dataTable: DataTableComponent) {
        this.field = field;
        this.addon = addon;
        this.component = component;
        this.dataTable = dataTable;
    }
}


export class DataTableSaveRecord {
    record: any;
    dataTable: DataTableComponent;
    isNew: boolean;


    constructor(record: any, dataTable: DataTableComponent, isNew: boolean) {
        this.record = record;
        this.dataTable = dataTable;
        this.isNew = isNew;
    }
}

export class DataTableRowEditCancelled {

    dataTable: DataTableComponent;
    row: DataTableRow<any>;


    constructor(dataTable: DataTableComponent, row: DataTableRow<any>) {
        this.dataTable = dataTable;
        this.row = row;
    }
}

export class DataTableSelectionChanged {
    dataTable: DataTableComponent;

    constructor(dataTable: DataTableComponent) {
        this.dataTable = dataTable;
    }
}

export class DataTableRowSelected<T> {
    row: DataTableRow<T>;
    dataTable: DataTableComponent;

    constructor(dataTable: DataTableComponent, row: DataTableRow<T>) {
        this.dataTable = dataTable;
        this.row = row;
    }
}


@Component({
    selector: 'data-table',
    templateUrl: './data-table.component.html',
    styleUrls: ['./data-table.component.scss']

})
export class DataTableComponent implements OnInit, AfterContentInit {

    inited = false;

    self: DataTableComponent = this;
    tabledata: DataTableRow<any>[] = [];
    _newEntity: any = {};
    extraContainerStyle = {};
    public columns: DataTableColumnDirective[] = [];

    @Input() showFilterArea = false;

    @Input()
    set data(d: DataTableRow<any>[]) {
        this.tabledata = d;
        this.reinit();
        this.cd.markForCheck();
    }

    @Input()
    set containerHeight(value: number) {
        if ((value === null) || (value === undefined) || isNaN(parseInt('' + value, 10)) || (value < 1)) {
            this.extraContainerStyle = {'overflow': 'auto'};
        } else {
            this.extraContainerStyle = {'max-height.px': value, 'overflow-y': 'scroll'};
        }
    }

    @Input() responsive = false;
    @Input() selectable = true;
    @Input() singleselect = false;
    @Input() hideCheckboxes = false;
    @Input() orders: ResultMetaSortImpl[] = [];
    @Input() filter: ISimpleFilter<any> = null;
    @Input() disabled = false;

    @Input() filterLabel = 'Filter-Optionen';
    filterCollapsed = false;


    @Input()
    set newEntity(v: any) {
        this._newEntity = v;
        if (this.showInsertRow) {

            this.newEntityChange.emit(v);
        }
    }

    get newEntity(): any {
        return this._newEntity;
    }

    @Output() newEntityChange = new EventEmitter<any>();

    @Input() showInsertRow = false;
    @Input() rowActionsAsButtons = false;
    @Input() tableActionsAsButtons = true;

    @ContentChildren(DataTableColumnDirective) cols: QueryList<DataTableColumnDirective>;
    @ContentChildren(DataTableAction) tableactions: QueryList<DataTableAction>;
    @ContentChildren(DataTableRowAction) rowactions: QueryList<DataTableRowAction>;
    @ContentChildren(DataTableTableExtraHeaderComponent) extraActionContainers: QueryList<DataTableTableExtraHeaderComponent>;
    @ContentChildren(DataTableExtraTableActionComponent) extraTableActions: QueryList<DataTableExtraTableActionComponent>;
    @ContentChildren(DataTableFilterAreaComponent) filterArea: QueryList<DataTableFilterAreaComponent>;

    @Output() sortChanged = new EventEmitter<DataTableSortChanged>();
    @Output() filterChanged = new EventEmitter<DataTableFilterChanged>();
    @Output() editorAddonClicked = new EventEmitter<DataTableEditorAddOnClick>();
    @Output() saveRecord = new EventEmitter<DataTableSaveRecord>();
    @Output() rowEditCancelled = new EventEmitter<DataTableRowEditCancelled>();
    @Output() selectionChanged = new EventEmitter<DataTableSelectionChanged>();
    @Output() rowSelected = new EventEmitter<DataTableRowSelected<any>>();

    @Input()
    public extraRowClasses: TDTGetExtraRowClassesCallback<any> = (row) => {
        return '';
    }

    constructor(private cd: ChangeDetectorRef) {
        this.self = this;
        this.inited = false;
    }

    updateRenderers() {
        if (this.tabledata !== undefined) {
            if (this.tabledata !== null) {
                if (Array.isArray(this.tabledata)) {

                    this.tabledata.forEach((r: DataTableRow<any>) => {
                        this.columns.forEach((c) => {
                            if (r.renderers === null || r.renderers === undefined) {
                                r.renderers = {};
                            }
                             const f = c.fieldName;
                             r.renderers[c.field] = c.getRenderer(r.data[f], r);
                        });
                    });
                    this.cd.markForCheck();
                }
            }
        }
    }

    ngOnInit() {
        this.reinit();
    }

    ngAfterContentInit() {
        this.inited = true;
        this.reinit();
    }

    reinit() {
        if (!this.inited) {
            return;
        }
        if (this.cols != null) {
            this.columns = this.cols.toArray();
        } else {
            this.columns = [];
        }
        this.updateRenderers();
        this.updateflags();
        this.cd.markForCheck();
        this.cd.detectChanges();
    }

    updateflags() {

        if (this.tabledata !== undefined) {
            if (this.tabledata !== null) {
                if (Array.isArray(this.tabledata)) {
                    if (this.rowactions !== undefined) {
                        if (this.rowactions !== null) {
                            this.tabledata.forEach(
                                d => {
                                    this.rowactions.forEach(a => {
                                        a.checkIfEnabled(d);
                                        if (a.listOptions !== null && a.listOptions !== undefined && Array.isArray(a.listOptions)) {
                                            a.listOptions.forEach(o => {
                                                o.checkIfEnabled(d);
                                            });
                                        }
                                    });
                                }
                            );
                        }
                    }
                }
            }
        }
        this.cd.markForCheck();
    }

    showFirstColumn(): boolean {
        return this.showInsertRow || (this.selectable && !this.hideCheckboxes) || this.isFilterable();
    }

    hasSelectedRows(): boolean {
        let r = false;
        this.tabledata.forEach(row => {
            r = r || row.selected;
        });
        return r;
    }

    allRowsSelected(): boolean {
        let r = true;
        this.tabledata.forEach(row => {
            if (!row.selected) {
                r = false;
            }
        });
        return r;
    }

    isFilterable(): boolean {
        let r = false;
        if ((this.filter !== undefined) && (this.filter !== null)) {
            this.cols.forEach(c => {
                r = r || c.filterable;
            });
        }
        return r;
    }

    selectAll() {
        if (!this.disabled) {
            let sc = false;
            if (this.allRowsSelected()) {
                this.tabledata.forEach(row => {
                    if (row.isSelected()) {
                        row.unselect();
                        sc = true;
                    }
                });
            } else {
                this.tabledata.forEach(row => {
                    if (!row.isSelected()) {
                        row.select();
                        sc = true;
                        this.rowSelected.emit(new DataTableRowSelected(this.self, row));
                    }
                });
            }
            if (sc) {
                this.selectionChanged.emit(new DataTableSelectionChanged(this.self));
            }
        }
    }

    hasTableActions(): boolean {
        return ((this.tableactions !== null) && (this.tableactions !== undefined) && (this.tableactions.length > 0)) ||
            ((this.extraTableActions !== null) &&
                (this.extraTableActions !== undefined) &&
                (this.extraTableActions.length > 0));

    }

    hasRowActions(): boolean {
        return (this.rowactions !== null) && (this.rowactions !== undefined) && (this.rowactions.length > 0);
    }

    hasActionsColumn(): boolean {
        return this.hasActions() || this.showInsertRow;
    }

    hasActions(): boolean {
        return this.hasTableActions() || this.hasRowActions();
    }

    tableActionToolbar(): boolean {
        return (this.tableActionsAsButtons === true) || (('' + this.tableActionsAsButtons).toLowerCase() === 'true');
    }

    toggleSelect(row: DataTableRow<any>) {
        if (!this.disabled) {
            let sc = false;
            let skip = false;
            if (this.singleselect) {
                this.tabledata.forEach(r => {
                    if (r.isSelected()) {
                        if (r !== row) {
                            r.unselect();
                            sc = true;
                        } else {
                            r.unselect();
                            skip = true;
                        }
                    }
                });
                if (!skip) {
                    row.select();
                    this.rowSelected.emit(new DataTableRowSelected(this.self, row));
                } else {
                    this.rowSelected.emit(null);
                }
            } else {
                sc = true;
                row.toggleSelect();
                if (row.isSelected()) {
                    this.rowSelected.emit(new DataTableRowSelected(this.self, row));
                }
            }
            if (sc) {
                this.selectionChanged.emit(new DataTableSelectionChanged(this.self));
            }
        }
        this.cd.markForCheck();
    }

    onSortChanged(field: string, dir: string) {
        this.sortChanged.emit(new DataTableSortChanged(field, dir));
    }

    public onFilterChanged: CellFilterChangedCallback<any> = (field, value) => {
        this.filterChanged.emit(
            new DataTableFilterChanged(field, value)
        );
    }

    public onEditorChanged: CellEditorChangedCallback<any> = (field, value, component: IEditorComponent) => {
        if ((value !== null) || (value !== undefined)) {
            if (component.dataType === 'number') {
                value = parseInt('' + value, 10);
                if (isNaN(value as number)) {
                    value = 0;
                }
                if ((component.row === null) || (component.row === undefined)) {
                    this._newEntity[field] = value as number;
                } else {
                    component.row.data[field] = value as number;
                }
                return;
            } else if (component.dataType === 'text') {
                value = '' + value;
                if ((component.row === null) || (component.row === undefined)) {
                    this._newEntity[field] = value as string;
                } else {
                    component.row.data[field] = value as string;
                }
                return;
            } else if (component.dataType === 'boolean') {
                value = !!value;
                if ((component.row === null) || (component.row === undefined)) {
                    this._newEntity[field] = value as boolean;
                } else {
                    component.row.data[field] = value as boolean;
                }
                return;
            }
        }
        if ((component.row === null) || (component.row === undefined)) {
            this._newEntity[field] = value;
        } else {
            component.row.data[field] = value;
        }
    }


    public onEditorAddOnClicked: CellEditorAddOnClickCallback = (field, addon, component: IEditorComponent) => {
        this.editorAddonClicked.emit(new DataTableEditorAddOnClick(
            field,
            addon,
            component,
            this.self
        ));
    }


    saveNew(): void {
        this.saveRecord.emit(new DataTableSaveRecord(this._newEntity, this.self, true));
    }

    saveRow(row: DataTableRow<any>): void {
        this.saveRecord.emit(new DataTableSaveRecord(row.data, this.self, false));
    }

    cancelNew() {
        this.showInsertRow = false;
    }

    showEditor() {
        this.showInsertRow = true;
    }

    cancelRow(row: DataTableRow<any>): void {
        this.rowEditCancelled.emit(new DataTableRowEditCancelled(this.self, row));
    }

    unselectAll() {
        if (!this.disabled) {
            let sc = false;
            if ((this.tabledata !== undefined) && (this.tabledata !== null)) {
                this.tabledata.forEach(r => {
                        if (r.isSelected()) {
                            sc = true;
                            r.unselect();
                        }
                    }
                );
            }
            if (sc) {
                this.selectionChanged.emit(new DataTableSelectionChanged(this));
            }
        }
    }

    selectFirst() {
        if (!this.disabled) {
            if ((this.tabledata !== undefined) && (this.tabledata !== null)) {
                if (this.singleselect) {
                    this.unselectAll();
                }
                if (this.tabledata.length > 0) {
                    this.tabledata[0].select();
                    this.rowSelected.emit(new DataTableRowSelected(this, this.tabledata[0]));
                }
            }
        }
    }

    hasTableActionContainers(): boolean {
        if ((this.extraActionContainers !== null) &&
            (this.extraActionContainers !== undefined) &&
            (this.extraActionContainers.length > 0)
        ) {
            return true;
        }
        return false;
    }

    hasToolbar(): boolean {
        if ((this.tabledata !== null) &&
            (this.tabledata !== undefined) &&
            (this.cols !== null) &&
            (this.cols !== undefined) &&
            (this.cols.length > 0) &&
            this.hasTableActions() &&
            this.tableActionToolbar()) {
            return true;
        }
        if (this.hasTableActionContainers()) {
            return true;
        }

        return false;
    }

    getExtraRowClasses(row: DataTableRow<any>) {
        if (this !== undefined && this !== null) {
            if (this.extraRowClasses !== undefined && this.extraRowClasses !== null) {
                return this.extraRowClasses(row);
            }
        }
        return '';
    }

    toggleFilter() {
        this.filterCollapsed = !this.filterCollapsed;
        this.cd.markForCheck();
    }
}
