import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {NgbActiveModal, NgbModal, NgbModalOptions, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {Fahrzeug} from '../common/fahrzeug';

import {FahrzeugService} from '../common/fahrzeug.service';

import {LocalizedComponent} from '../../../commons/components/localized-component';
import {AddOnClickEvent} from '../../../commons/dynamic-form/add-on-click-event';
import {ToastrService} from 'ngx-toastr';
import {Ausweis} from '../../ausweise/common/ausweis';
import {ELaufzeitErfassung} from '../common/elaufzeit-erfassung.enum';
import {LoginService} from '../../../auth/services/login.service';
import {ConfirmDialogComponent} from '../../../commons/confirm-dialog/confirm-dialog.component';
import {ActionResponse, IActionResponse} from '../../../commons/action-response';

@Component({
    selector: 'app-fahrzeug-editor',
    templateUrl: './fahrzeug-editor.component.html',
    styleUrls: ['./fahrzeug-editor.component.css']
})
export class FahrzeugEditorComponent extends LocalizedComponent implements OnInit {

    get dlgTitle() {
        if (this.isNew()) {
            return this._('Innenauftrag anlegen');
        }
        return this._('Innenauftrag {{ name }} bearbeiten', {name: this.entity.bezeichnung});
    }

    mode: any = 'new';
    entity: Fahrzeug = new Fahrzeug();

    static open(modalService: NgbModal, fahrzeug?: Fahrzeug): Promise<any> {
        const o: NgbModalOptions = {
            size: 'lg',
            backdrop: 'static'
        };
        const r: NgbModalRef = modalService.open(FahrzeugEditorComponent, o);
        (r.componentInstance as FahrzeugEditorComponent).open(fahrzeug);
        return r.result;
    }

    constructor(

                public activeModal: NgbActiveModal,
                public fahrzeugService: FahrzeugService,
                public toastr: ToastrService,
                public cd: ChangeDetectorRef,
                public loginService: LoginService,
                public modalService: NgbModal
    ) {
        super( cd);
    }

    open(fahrzeug?: Fahrzeug) {
        if ((fahrzeug === null) || (fahrzeug === undefined)) {
            this.entity = new Fahrzeug();
            this.mode = 'new';
        } else {
            this.entity = Fahrzeug.fromResult(fahrzeug);
            this.mode = 'edit';
        }
    }

    isNew(): boolean {
        return this.mode === 'new';
    }

    isEdit(): boolean {
        return this.mode === 'edit';
    }

    ngOnInit() {
    }

    cancel() {
        this.activeModal.close();
    }

    addOnClick(event: AddOnClickEvent) {
        if ('nummer-field' === event.component.id) {
            if (event.addon === 0) {
                this.fahrzeugService
                    .findFreeNum(this.entity.kunde)
                    .subscribe(
                        resp => {
                            if (resp.success) {
                                event.component.setValue(resp.params.nummer as number);
                            } else {
                                this.toastr.error(this.t('Es konnte keine freie Nummer ermittelt werden.'));
                            }
                        },
                        () => {
                            this.toastr.error(this.t('Es konnte keine freie Nummer ermittelt werden.'));
                        }
                    );
            }
        }
    }


    save() {
        if (this.canSave()) {
            this.fahrzeugService
                .store(
                    this.entity, this.isNew()
                )
                .subscribe(
                    resp => {
                        if (resp.success) {
                            this.activeModal.close();
                        } else {
                            this.toastr.error(this.t('Beim Speichern der Fahrzeugdaten ist ein Fehler aufgetreten.'));
                        }
                    },
                    () => {
                        this.toastr.error(this.t('Beim Speichern der Fahrzeugdaten ist ein Fehler aufgetreten.'));
                    }
                );
        }
    }

    canSave(): boolean {
        if (
            (this.entity.nummer === null) ||
            (this.entity.nummer === undefined) ||
            isNaN(parseInt('' + this.entity.nummer, 10)) ||
            (this.entity.nummer < 1)
        ) {
            return false;
        }

        return true;
    }

    updateAusweise($event: Ausweis[]) {
        this.entity.ausweise = $event;
    }

    isKilometer() {
        return parseInt('' + this.entity.laufzeitErfassung, 10) === parseInt('' + ELaufzeitErfassung.KILOMETER, 10);
    }

    isBetriebsstunden() {
        return parseInt('' + this.entity.laufzeitErfassung, 10) === parseInt('' + ELaufzeitErfassung.BETRIEBSSTUNDEN, 10);
    }

    canDelete() {
        if (!this.isEdit()) {
            return false;
        }
        if (this.loginService.hasOneOfRoles(['ROLE_ADMIN', 'ROLE_PROJECT_ADMIN', 'ROLE_FAHRZEUGE_WRITABLE'])) {
            return true;
        }
    }

     remove() {
        if (this.isEdit()) {
            ConfirmDialogComponent
                .display(
                    this.modalService,
                    this._('Innenauftrag löschen'),
                    this._('Soll dieser Innenauftrags wirklich gelöscht werden? ' +
                        'Hinweis: Innenaufträge werden nur gelöscht, wenn ' +
                        'diese keiner Transaktion oder Kartenanfrage zugeordnet sind!'),
                    this._('Jetzt löschen'),
                    'icofont icofont-trash'
                ).result.then(() => {
                this.fahrzeugService
                    .removeFahrzeug(this.entity)
                    .subscribe(
                    (i: IActionResponse) => {
                        const ar = ActionResponse.fromRawActionResponse(i);
                        if (ar.success) {
                            this.toastr.success(this._('Der Innenauftrag wurde gelöscht'));
                            this.cancel();
                        } else {
                            this.toastr.error(this._(ar.params.message), this._('Fehler beim Löschen des Innenauftrages'));
                        }
                    },
                    () => {
                        this.toastr.error(this._('Fehler beim Löschen des Innenauftrages'));
                    }
                );
            });
        }
    }

}
