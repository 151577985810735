<data-table
        [selectable]="false"
        [data]="produktgruppen.asDataTableRows()"
        [orders]="produktgruppen.order"
        [filter]="produktgruppen.simpleFilter"
        (sortChanged)="sortChanged($event)"
        (filterChanged)="filterChanged($event)"

>
  <column [field]="'nummer'" [label]="t('Nummer')" [type]="'integer'" [filterable]="false"></column>
  <column [field]="'bezeichnung'" [label]="t('Bezeichnung')" [sortable]="true" [filterable]="false"></column>
  <column [field]="'numprods'" [label]="t('enthaltene Produkte')" [type]="'Integer'" [filterable]="false"></column>
  <tableaction
          [label]="t('Produktgruppe anlegen')"
          [icon]="'icofont icofont-ui-add'"
          (perform)="createProduktgruppe()"
  ></tableaction>
  <rowaction
          [label]="_('Produktgruppe bearbeiten')"
          [icon]="'icofont icofont-ui-edit'"
          (perform)="editProduktgruppe($event)"
  ></rowaction>

</data-table>

<div class="row">
  <div class="col-6"></div>
  <div class="col-6 text-right">
    <app-paginator
            [total]="produktgruppen.total"
            [page]="produktgruppen.page"
            [perpage]="produktgruppen.size"
            (pageChange)="updateProduktgruppen(true,$event)"
            (pageCountChange)="updateProduktgruppenPageCount($event)"
    ></app-paginator>
  </div>
</div>

