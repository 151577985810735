import {IPreisgruppe} from './ipreisgruppe';
import {EPreisgruppeArt} from './epreisgruppe-art.enum';
import {PreisgruppeSetting} from './preisgruppe-setting';
import {DataTableRow} from '../../commons/data-table/data-table-row';

export class Preisgruppe implements IPreisgruppe {

    gruppeArt: EPreisgruppeArt = EPreisgruppeArt.UNKNOWN;
    id = 0;
    title = '';
    settings: PreisgruppeSetting[] = [];

    settingsDT: DataTableRow<PreisgruppeSetting>[] = [];


    static fromResult(entity: IPreisgruppe): Preisgruppe {
        if ((entity === null) || (entity === undefined)) {
            return null;
        }

        const l = new Preisgruppe();
        for (const k of Object.keys(l)) {
            if (k === 'gruppeArt') {
                const a: any = entity.gruppeArt;
                if (a === 'UNKNOWN') {
                    l.gruppeArt = EPreisgruppeArt.UNKNOWN;
                } else if (a === 'UNCHANGED') {
                    l.gruppeArt = EPreisgruppeArt.UNCHANGED;
                } else if (a === 'FACTOR') {
                    l.gruppeArt = EPreisgruppeArt.FACTOR;
                } else if (a === 'FESTLEGUNG') {
                    l.gruppeArt = EPreisgruppeArt.FESTLEGUNG;
                } else if (a === 'ZUSCHLAG') {
                    l.gruppeArt = EPreisgruppeArt.ZUSCHLAG;

                } else {
                    l.gruppeArt = a;
                }
            } else if (k === 'settings') {
                l.settings = [];
                if (entity.settings !== null && entity.settings !== undefined && Array.isArray(entity.settings)) {
                    entity.settings.forEach((s) => {
                        const st = PreisgruppeSetting.fromResult(s);
                        if (st !== null && st !== undefined) {
                            l.settings.push(st);
                        }
                    });
                }
            } else {
                l[k] = (entity[k] !== undefined) ? entity[k] : '';
            }
        }
        l.createDT();
        return l;
    }

    constructor() {
    }

    isFactor() {
        if (this.gruppeArt === undefined || this.gruppeArt === null) {
            return false;
        }
        return parseInt('' + this.gruppeArt, 10) === parseInt('' + EPreisgruppeArt.FACTOR, 10);
    }

    isFestlegung() {
        if (this.gruppeArt === undefined || this.gruppeArt === null) {
            return false;
        }
        return parseInt('' + this.gruppeArt, 10) === parseInt('' + EPreisgruppeArt.FESTLEGUNG, 10);
    }

    isZuschlag() {
        if (this.gruppeArt === undefined || this.gruppeArt === null) {
            return false;
        }
        return parseInt('' + this.gruppeArt, 10) === parseInt('' + EPreisgruppeArt.ZUSCHLAG, 10);
    }

    public settingsAsDataTableRows(): DataTableRow<PreisgruppeSetting>[] {
        return this.settingsDT;
    }


    needsValue() {
        return (
            this.isFactor() ||
            this.isFestlegung() ||
            this.isZuschlag()
        );
    }

    needsCurrency() {
        return (
            this.isFestlegung() ||
            this.isZuschlag()
        );
    }

    needsSettings() {
        return this.isFactor() ||
            this.isFestlegung() ||
            this.isZuschlag();
    }

    createDT() {
        this.settingsDT = [];
        this.settings.forEach((s) => {
            this.settingsDT.push(new DataTableRow<PreisgruppeSetting>(s));
        });
    }

}
