import {AfterViewInit, Component, EventEmitter, Input, NgZone, OnInit, Output, ViewChild} from '@angular/core';
import {FilterCallback} from '../commons/dynamic-form/FilterCallback';

import {MESelectItemFormatterCallback} from '../commons/dynamic-form/me-select/meselect-item-formatter-callback';
import {
    MEHighlitePipe,
    MESelectComponent,
    MESelectItemMatcherCallback,
    MESelectItemTextCallback
} from '../commons/dynamic-form/me-select/meselect.component';
import {Person} from './personen/common/person';
import {PersonList} from './personen/common/person-list';
import {SimplePersonFilter} from './personen/common/simple-person-filter';
import {CommonList} from '../commons/common-list';
import {GenericFilter} from '../commons/generic-filter';
import {PersonService} from './personen/common/person.service';
import {IListResult} from '../commons/list-result';
import {IPerson} from './personen/common/iperson';
import {MESelectSearchEvent} from '../commons/dynamic-form/me-select/meselect-search-event';
import CryptoES from "crypto-es";

@Component({
    selector: 'person-inline-field',
    template: `
        <meselect
                id="{{ id }}"
                [idField]="'id'"
                [items]="personen?.data"
                [asMatrix]="false"
                [placeholder]="label"
                (itemSelected)="doSelect($event)"
                (needsSearch)="doSearch($event)"
                [small]="small"
                [nullable]="nullable"
        >
        </meselect>
    `,
    styles: [`
        :host ::ng-deep meselect .red {
            color: red;
        }

        :host ::ng-deep meselect .orange {
            color: orange;
        }
    `]
})
export class PersonInlineFieldComponent implements OnInit, AfterViewInit {

    @ViewChild(MESelectComponent, {static: true}) personenMESelect: MESelectComponent;
    @Input() small = false;
    @Input() nullable = true;

    personen: PersonList = new PersonList();

    _value: Person = null;

    @Input() label = 'PersonFeld';
    @Input() id = '';
    @Input() maxDisplayPersonNum = 50;

    @Input('value')
    set value(v: Person) {

        if (this._value !== v) {
            this._value = v;
            if ((this.personenMESelect != null) && (this.personenMESelect !== undefined)) {
                this.personenMESelect.value = v;
            }
            this.valueChange.emit(v);
        }
    }

    @Output() valueChange = new EventEmitter<Person>();


    get value(): Person {
        return this._value;
    }

    constructor(public personService: PersonService,
                public _ngZone: NgZone) {
    }

    public search: FilterCallback<Person> = (filter: GenericFilter<Person>,
                                             list: CommonList<Person>,
                                             maxDisplayItems: number,
                                             queryString: string,
                                             cfg: any,
                                             selectComponent: MESelectComponent
    ) => {
        queryString = ('' + queryString).trim();
        let q = '';
        if (queryString !== '') {
            if (isNaN(parseInt(queryString, 10))) {
                q = '(*' + queryString + '*) OR' +
                    '(' + queryString + ')';
            } else {
                q = '(nummer:' + parseInt(queryString, 10) + '^10) OR ' +
                    '(nummer:' + parseInt(queryString, 10) + '*^5) OR ' +
                    '(*' + queryString + '*) OR' +
                    '(' + queryString + ')';
            }
        }
        this.personService
            .list(
                maxDisplayItems,
                0,
                [],
                filter as SimplePersonFilter,
                q
            )
            .subscribe(
                (c: IListResult<IPerson>) => {
                    list.populateFromListResult(c);
                    if (selectComponent !== null && selectComponent !== undefined) {
                        selectComponent.items = list.data;
                        selectComponent.cd.markForCheck();
                    }
                }
            );
    };

    ngOnInit() {
        if (this.id === '') {
            this.id = 'tf' + CryptoES.MD5('personfield-' + this.label + Math.random() + '-' + Math.random()).toString();
        }
        this.doSearch(new MESelectSearchEvent('', this.personenMESelect));
    }

    doSearch(value: MESelectSearchEvent) {
        this.search(null, this.personen, this.maxDisplayPersonNum, value.queryString, null, value.component);
    }

    doSelect(person: Person) {
        this._value = person;
        this.valueChange.emit(person);
    }

    public itemFormat: MESelectItemFormatterCallback<Person> = (item: Person, search) => {
        if (item === null || item === undefined) {
            return '';
        }

        const icon = 'icofont icofont-user';

        return '<i class="' + icon + '"></i> ' +
            MEHighlitePipe.transformString(item.name, search) +
            ' <span class="badge badge-default">' +
            MEHighlitePipe.transformString('' + item.nummer, search) +
            '</span>';
    };

    public itemText: MESelectItemTextCallback<Person> = (item: Person) => {
        if (item === null || item === undefined) {
            return '';
        }
        return item.name + ' [' + item.nummer + ']';
    };

    public itemMatcher: MESelectItemMatcherCallback<Person> = (item: Person, search) => {
        if (!search) {
            return true;
        }
        if (search.trim() === '') {
            return true;
        }
        if (!item) {
            return false;
        }
        const x = item.name + ' ' + item.nummer;

        return (x.toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) >= 0);
    };

    ngAfterViewInit(): void {
        if (this.personenMESelect !== null && this.personenMESelect !== undefined) {
            this.personenMESelect.onFormat = this.itemFormat;
            this.personenMESelect.onGetText = this.itemText;
            this.personenMESelect.onMatch = this.itemMatcher;
            this.personenMESelect.value = this._value;
        }
    }


}
