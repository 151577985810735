import {EFileType} from './efile-type';

export class FileType {

    static getIcon(fileType: EFileType): string {

        let icon = 'icofont icofont-file-file';

        switch (fileType) {
            case EFileType.PDF:
                icon = 'icofont icofont icofont-file-pdf';
                break;
            case EFileType.PRESENTATION:
                icon = 'icofont icofont icofont-file-presentation';
                break;
            case EFileType.SPREADSHEET:
                icon = 'icofont icofont icofont-file-speadsheet';
                break;
            case EFileType.TEXTDOCUMENT:
                icon = 'icofont icofont icofont-file-text';
                break;
            case EFileType.XML:
                icon = 'icofont icofont icofont-file-code';
                break;


        }
        return icon;
    }
}
