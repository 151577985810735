import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ResultMetaSortImpl} from '../result-meta';

@Component({
    selector: 'sort-buttons',
    styles: [`
        :host {
            font-size: 0.8em;
        }

        i {
            cursor: pointer;
        }

        i.marked {
            color: inherit;
        }

        i.unmarked {
            color: #EEEEEE;
        }

        i:hover {
            color: #5bc0de;
        }
    `],
    template: `
        <i class="icofont icofont-square-up" [class.unmarked]="direction!='asc'" [class.marked]="direction=='asc'" (click)="up()"></i>
        <i class="icofont icofont-square-down" [class.unmarked]="direction!='desc'" [class.marked]="direction=='desc'" (click)="down()"></i>
    `

})
export class SortButtonsComponent implements OnInit {

    @Input() field = '';

    @Input()
    set orders(o: ResultMetaSortImpl[]) {
        this.direction = '';
        o.forEach(it => {
            if (it.field === this.field) {
                this.direction = it.direction;
            }
        });
    }

    direction = '';
    @Output() onClick = new EventEmitter<string>();


    constructor() {
    }

    ngOnInit() {
    }


    up() {
        this.direction = 'asc';
        this.onClick.emit(this.direction);
    }

    down() {
        this.direction = 'desc';
        this.onClick.emit(this.direction);
    }

}
