<div class="modal-header">
  <h5 class="modal-title">{{ _('Nacherfassung-Details') }}</h5>
  <button type="button" class="close" aria-label="Close" (click)="cancel()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">

  <app-tabset>
    <app-pane [title]="_('Belegdaten')">
      <table class="table">
        <tr>
          <th scope="row" class="w-20">{{ _('Zeitpunkt')}}</th>
          <td>{{ fDate(entity.zeitpunkt,'medium') }}</td>
        </tr>
        <tr>
          <th scope="row" class="w-20">{{ _('Belegnummer') }}</th>
          <td>{{ entity.belegnummer }}</td>
        </tr>
      </table>
    </app-pane>
    <app-pane [title]="_('Positionen')">
      <data-table
              [selectable]="false"
              [data]="entity.detailRows()"
      >
        <column
                [field]="'pos'"
                [label]="_('Position')"
                type="Integer"
        ></column>
        <column
                [field]="'produkt'"
                [label]="_('Produkt')"
                [renderer]="produktR"
        ></column>
        <column
                [field]="'menge'"
                [label]="_('Menge')"
                [renderer]="mengeR"
                [cfg]="{ produktField:'produkt' }"
        ></column>
        <column
                [field]="'epreis'"
                [label]="_('Einzelpreis')"
                [renderer]="preiswert"
                [cfg]="{ produktField:'produkt' ,currency:entity.waehrung.code }"
        ></column>
        <column
                [field]="'preis'"
                [label]="_('Preis')"
                [renderer]="preiswert"
                [cfg]="{ produktField:'produkt',currency:entity.waehrung.code }"
        ></column>
        <column
                [field]="'b_epreis'"
                [label]="_('Einzelpreis (brutto)')"
                [renderer]="preiswert"
                [cfg]="{ produktField:'produkt',currency:entity.waehrung.code }"
        ></column>
        <column
                [field]="'b_preis'"
                [label]="_('Preis (brutto)')"
                [renderer]="preiswert"
                [cfg]="{ produktField:'produkt',currency:entity.waehrung.code }"
        ></column>

      </data-table>
    </app-pane>
    <app-pane
            [title]="_('Ausweis')"
    >
      <table class="table">
        <tr>
          <th scope="row">{{ _('Ausweiskennung') }}</th>
          <td>
            {{ entity.pan }}
          </td>
        </tr>
        <tr *ngIf="entity.ausweis!==undefined && entity.ausweis!==null">
          <th scope="row">{{ _('Ausweis') }}</th>
          <td>
            <app-ausweis-display
                    [ausweis]="entity.ausweis"
            ></app-ausweis-display>
          </td>

        </tr>
      </table>
    </app-pane>
    <app-pane
            [title]="_('Station')"
            *ngIf="entity.station!==undefined && entity.station!==null"
    >
      <app-station-display
              [station]="entity.station"
      ></app-station-display>
    </app-pane>
    <app-pane [title]="_('Terminal')" *ngIf="false && entity.terminal!==undefined && entity.terminal!==null">
      <app-terminal-display [terminal]="entity.terminal"></app-terminal-display>
    </app-pane>
    <app-pane [title]="_('Kunde')" *ngIf="entity.ausweis!==null && entity.ausweis!==undefined && entity.ausweis.kunde!==null && entity.ausweis.kunde!==undefined">
      <app-kunde-display [kunde]="entity.ausweis"></app-kunde-display>

    </app-pane>


  </app-tabset>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-secondary" (click)="cancel()">
    <i class="icofont icofont-close"></i> {{ _('Schließen') }}
  </button>
</div>
