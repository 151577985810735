import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {SimpleRechnungFilter} from './simple-rechnung-filter';
import {HttpClient} from '@angular/common/http';
import {LoginService} from '../../../auth/services/login.service';
import {IRechnung} from './irechnung';
import {Rechnung} from './rechnung';
import {ResultMetaSortImpl} from '../../../commons/result-meta';
import {IListResult} from '../../../commons/list-result';
import {IActionResponse} from '../../../commons/action-response';

@Injectable({
                providedIn: 'root'
            })
export class RechnungService {

    constructor(public httpClient: HttpClient,
                public loginService: LoginService) {
    }

    list(max = 0,
         offset = 0,
         sort: ResultMetaSortImpl[] = [],
         filter: SimpleRechnungFilter = null): Observable<IListResult<IRechnung>> {
        let url = '/api/rechnungen';

        url = url.concat('?')
            .concat('max=' + encodeURIComponent('' + max))
            .concat('&')
            .concat('offset=' + encodeURIComponent('' + offset));

        if (filter !== null) {
            if (filter.hasValues()) {
                url = url + '&' + filter.asURL();
            }
        }

        if (sort !== undefined && sort !== null && sort.length > 0) {
            const p = 0;
            sort.forEach(it => {
                url = url.concat('&sort.' + p + '=' + encodeURIComponent(it.field + '|' + it.direction));
            });
        }


        return this.httpClient
            .get<IListResult<IRechnung>>(
                url,
                {headers: this.loginService.getHttpHeaders()}
            );
    }


    store(rechnung: Rechnung, insert = false): Observable<IActionResponse> {
        if (!insert && rechnung.id !== null && rechnung.id > 0) {
            return this.httpClient
                .put<IActionResponse>(
                    '/api/rechnungen/' + encodeURIComponent('' + rechnung.id),
                    JSON.stringify(rechnung),
                    {headers: this.loginService.getHttpHeaders()}
                );
        }
        return this.httpClient
            .post<IActionResponse>(
                '/api/rechnungen',
                JSON.stringify(rechnung),
                {headers: this.loginService.getHttpHeaders()}
            );

    }

    countRechnungen(unreadOnly = false): Observable<IActionResponse> {
        let uri = '/api/rechnungen/_count';
        if (unreadOnly) {
            uri = uri + '?unread';
        }
        return this.httpClient
            .get<IActionResponse>(
                uri,
                {headers: this.loginService.getHttpHeaders()}
            );
    }

    findFreeNum(): Observable<IActionResponse> {
        const url = '/api/rechnungen/_freeNum';
        return this.httpClient
            .post<IActionResponse>(
                url,
                {},
                {
                    headers: this.loginService.getHttpHeaders()
                });

    }

}
