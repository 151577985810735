import {AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Output, ViewChild} from '@angular/core';
import {IFilterDialog} from '../../../../commons/data-table/ifilter-dialog';
import {LocalizedComponent} from '../../../../commons/components/localized-component';
import {SondenFilter} from './sonden-filter';
import {StationElementRendererComponent} from '../../../station-element-renderer/station-element-renderer.component';

import {ElementListComponent} from '../../../../commons/element-list/element-list.component';
import {ProduktElementRendererComponent} from '../../../../products/produkt-element-renderer.component';
import {SondenFilterChangedEvent} from './sonden-filter-changed-event';

@Component({
    selector: 'app-sonden-filter-dialog',
    templateUrl: './sonden-filter-dialog.component.html',
    styleUrls: ['./sonden-filter-dialog.component.scss']
})
export class SondenFilterDialogComponent extends LocalizedComponent implements IFilterDialog, AfterViewInit {
    stationRenderer = StationElementRendererComponent;
    produktRenderer = ProduktElementRendererComponent;

    filter: SondenFilter = new SondenFilter();

    private _warnOnly = false;


    get warnOnly(): boolean {
        return this._warnOnly;
    }

    set warnOnly(value: boolean) {
        if (this._warnOnly !== value) {
            this._warnOnly = value;
            this.markChanged();
        }
    }

    @Output() filterChanged: EventEmitter<SondenFilterChangedEvent> = new EventEmitter<SondenFilterChangedEvent>();
    @ViewChild('stationen',{static: true}) stationen: ElementListComponent;
    @ViewChild('produkte',{static: true}) produkte: ElementListComponent;

    constructor(
        
        
        public cd: ChangeDetectorRef
    ) {
        super( cd);
        this.filter.filterChanged.subscribe(
            () => {
                if (this.stationen !== null && this.stationen !== undefined) {
                    this.stationen.elements = this.filter.stationen;
                }
                if (this.produkte !== null && this.produkte !== undefined) {
                    this.produkte.elements = this.filter.products;
                }
                this.markChanged();
            }
        );
    }

    ngAfterViewInit() {
        this.markChanged();
    }

    getQuery(): string {
        return this.filter.getQuery();
    }

    markChanged(visble = true): void {
        this.cd.markForCheck();
        if (visble) {
            this.filterChanged.emit(new SondenFilterChangedEvent(this.getQuery(), this.warnOnly));
        } else {
            this.filterChanged.emit(new SondenFilterChangedEvent('', false));
        }
    }


}
