import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {Kunde} from '../../kunden/common/kunde';
import {NgbActiveModal, NgbModal, NgbModalOptions, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';

import {Ausweis} from '../common/ausweis';
import {AusweisService} from '../common/ausweis.service';
import {Kontingent} from '../../limits/common/kontingent';

import {LocalizedComponent} from '../../../commons/components/localized-component';
import {AddOnClickEvent} from '../../../commons/dynamic-form/add-on-click-event';
import {ToastrService} from 'ngx-toastr';

@Component({
    selector: 'app-kunden-ausweis-editor',
    templateUrl: './kunden-ausweis-editor.component.html',
    styleUrls: ['./kunden-ausweis-editor.component.css']
})
export class KundenAusweisEditorComponent extends LocalizedComponent implements OnInit {

    get dlgTitle() {
        return this._('Karte {{ nummer }} bearbeiten', {nummer: this.entity.nummer});
    }

    message = '';
    pin1 = '';
    pin2 = '';
    canSavePin = false;
    mode: any = 'new';
    entity: Ausweis = new Ausweis();

    static open(modalService: NgbModal, ausweis?: Ausweis): Promise<any> {
        const o: NgbModalOptions = {
            size: 'lg',
            backdrop: 'static'
        };
        const r: NgbModalRef = modalService.open(KundenAusweisEditorComponent, o);
        (r.componentInstance as KundenAusweisEditorComponent).open(ausweis, ausweis.kunde);
        return r.result;
    }

    constructor(

                public activeModal: NgbActiveModal,
                public ausweisService: AusweisService,
                public toastr: ToastrService,
                public cd: ChangeDetectorRef) {
        super( cd);
    }

    open(ausweis?: Ausweis, kunde?: Kunde) {
        if ((ausweis === null) || (ausweis === undefined)) {
            this.entity = new Ausweis();
            if (kunde !== undefined) {
                this.entity.kunde = kunde;
            }
            this.mode = 'new';
        } else {
            this.entity = Ausweis.fromResult(ausweis);
            this.mode = 'edit';
        }
        if (this.entity.artikelGruppen === null) {
            this.entity.artikelGruppen = [];
        }
        while (this.entity.artikelGruppen.length < 2) {
            this.entity.artikelGruppen.push(null);
        }
        if (this.entity.kontingent === null || this.entity.kontingent === undefined) {
            this.entity.kontingent = new Kontingent();
        }
        this.pin1 = this.entity.pin;
    }

    isNew(): boolean {
        return this.mode === 'new';
    }

    isEdit(): boolean {
        return this.mode === 'edit';
    }

    ngOnInit() {
    }

    cancel() {
        this.activeModal.close();
    }

    addOnClick(event: AddOnClickEvent) {
    }

isWifiCard() {
        try {
            if (this.entity.issuer.bezeichnung.toLowerCase().indexOf('wifi') >= 0) {
                return true;
            }
        } catch (ignored) {

        }
        return false;
    }
    save() {
        if (this.canSave()) {
            this.ausweisService
                .storeOptions(
                    this.entity
                )
                .subscribe(
                    resp => {
                        if (resp.success) {
                            // this.activeModal.close();
                            this.toastr.success(this.t('Die Kartendaten wurden soeben erfolgreich gespeichert.'));
                        } else {
                            this.toastr.error(this.t('Beim Speichern der Kartendaten ist ein Fehler aufgetreten.'));
                        }
                    },
                    () => {
                        this.toastr.error(this.t('Beim Speichern der Kartendaten ist ein Fehler aufgetreten.'));
                    }
                );
        }
    }

    canSave(): boolean {
        if (
            (this.entity.nummer === null) ||
            (this.entity.nummer === undefined) ||
            isNaN(parseInt('' + this.entity.nummer, 10)) ||
            (this.entity.nummer < 1)
        ) {
            return false;
        }

        return true;
    }

    updateBegrenzungen(bg) {
        this.entity.begrenzungen = bg;
    }


    savePin() {
        if (this.canSavePin) {
            this.ausweisService
                .setPIN(
                    this.entity, this.pin1
                )
                .subscribe(
                    resp => {
                        if (resp.success) {
                            this.toastr.success(this.t('Die PIN wurde festgelegt.'));
                        } else {
                            this.toastr.error(this.t('Beim Speichern der PIN ist ein Fehler aufgetreten.'));
                        }
                    },
                    () => {
                        this.toastr.error(this.t('Beim Speichern der PIN ist ein Fehler aufgetreten.'));
                    }
                );
        }
    }

    setPin(w: number, pin: string) {
        if (pin === null || pin === undefined) {
            return;
        }

        this.pin1 = pin.trim();
        this.pin2 = pin.trim();

        this.canSavePin = false;
        this.message = '';

        if (!(this.pin1 === null || this.pin1 === undefined || this.pin1.trim() === '')) {

            if (this.pin1.length !== 4) {
                this.message = this._('Die PIN hat ungültige Länge.');
            } else if (this.pin1.match(/(0000|9999)/i)) {
                this.message = this._('Bitte wählen Sie eine andere PIN.');
            } else if (!this.pin1.match(/^[0-9]+$/i)) {
                this.message = this._('Die PIN enthält ungültige Zeichen.');
            } else if (this.pin1 !== this.pin2) {
                this.message = this._('PIN und PIN-Wiederholung stimmen nicht überein.');
            } else {
                this.canSavePin = true;
            }
        }

    }

}
