import {Rechnung} from './rechnung';
import {IRechnung} from './irechnung';
import {GenericFilter} from '../../../commons/generic-filter';
import {IWaehrung} from '../../../commons/waehrungen/iwaehrung';
import {IAttachment} from '../../attachments/common/iattachment';
import {IKunde} from '../../../customers/kunden/common/ikunde';

export class SimpleRechnungFilter extends GenericFilter<Rechnung> implements IRechnung {
    waehrung: IWaehrung = null;
    readDatum: any = null;
    id: number = null;
    nummer: number = null;
    rechnungNummer: string = null;
    rechnungDatum: any = null;
    dueDatum: any = null;
    paidDatum: any = null;
    kunde: IKunde = null;
    summe: number = null;
    status: any = null;
    dateCreated: any = null;
    lastUpdated: any = null;
    lastEdited: any = null;
    attachments: IAttachment[] = null;

    public encodeField(fieldname: string): string {
        if (fieldname === 'kunde') {
            if (this.kunde !== null) {
                if (this.kunde.id !== null && this.kunde.id !== undefined && this.kunde.id > 0) {
                    return 'filter.kunde=' + encodeURIComponent('' + this.kunde.id);
                }
            }
        }
        return super.encodeField(fieldname);
    }
}
