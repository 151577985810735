import {Component, Injector, OnInit} from '@angular/core';
import {CellEditorChangedCallback, IEditorComponent} from '../commons/data-table/editors/cell-editor.component';
import {DataTableRow} from '../commons/data-table/data-table-row';
import {Terminal} from './terminals/common/terminal';
import {DataTableColumnDirective} from '../commons/data-table/data-table-column.directive';
import {Station} from './common/station';

@Component({
    selector: 'app-terminal-cell-editor',
    styles: [`
        :host ::ng-deep terminal-field .form-group {
            margin-bottom: 0;
        }
    `],
    template: `
        <terminal-field
                [label]="column.label"
                [labelwidth]="0"
                [value]="value"
                (valueChange)="valueChange($event)"
                [small]="true"
                [station]="station"
        ></terminal-field>
    `
})
export class TerminalCellEditorComponent implements IEditorComponent, OnInit {

    row: DataTableRow<any>;

    public dataType = 'Terminal';

    value: Terminal = null;
    column: DataTableColumnDirective = null;

    station: Station = null;

    constructor(public injector: Injector) {
        this.value = this.injector.get('value', null);
        this.column = this.injector.get('column');
        this.row = this.injector.get('row');
        this.editorChanged = this.injector.get('onChange');
        if ((this.column !== null) && (this.column !== undefined)) {
            if ((this.column.cfg !== null) && (this.column.cfg !== undefined)) {
                if ((this.column.cfg.station !== null) && (this.column.cfg.station !== undefined)) {
                    this.station = this.column.cfg.station as Station;
                }
            }
        }

    }

    editorChanged: CellEditorChangedCallback<Terminal> = (field2, val, c) => {
    };

    ngOnInit() {
    }

    valueChange(event: any) {
        this.value = event as Terminal;
        this.editorChanged(this.column.field, this.value, this);
    }


}
