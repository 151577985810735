import {IAusweis} from '../../ausweise/common/iausweis';
import {Fahrzeug} from './fahrzeug';
import {IFahrzeug} from './ifahrzeug';
import {GenericFilter} from '../../../commons/generic-filter';
import {IProdukt} from '../../../products/produkte/common/iprodukt';
import {IKunde} from '../../kunden/common/ikunde';
import {IKostenstelle} from '../../kostenstellen/common/ikostenstelle';
import {IStation} from '../../../stationen/common/istation';

export class SimpleFahrzeugFilter extends GenericFilter<Fahrzeug> implements IFahrzeug {
    lastEdited: any = null;
    tankserverID: number = null;
    id: number = null;
    nummer: number = null;
    bezeichnung: string = null;
    kfzKennzeichen: string = null;
    kostenstelle: IKostenstelle = null;
    tankVolumen: number = null;
    kraftstoff: IProdukt = null;
    kmStand: number = null;
    ausweise: IAusweis[] = null;
    kunde: IKunde = null;
    dateCreated: any = null;
    lastUpdated: any = null;
    buchungskreis: string = null;
    info: string = null;
    werksnummer: string = null;
    laufzeitErfassung: any = null;
    standort: IStation = null;
    kmAbfrage: boolean = null;
    maxKmDifferenz: number = null;
}
