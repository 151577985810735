<div class="card">
    <div class="card-body" *ngIf="_ausweis!==null && _ausweis!==undefined">
        <h4 class="card-title">{{ ausweisLabel }}</h4>
        <div class="card-text"
             [class.gesperrt]="_ausweis.kundenSperre || _ausweis.adminSperre"
             [class.admin-gesperrt]="_ausweis.adminSperre"
             [class.kunde-gesperrt]="_ausweis.kundenSperre && !_ausweis.adminSperre"
        >
            <table class="table">
                <tr>
                    <th scope="row">{{_('Nummer')}}</th>
                    <td>{{ _ausweis.nummer }}</td>
                    <th scope="row">{{_('Kennung/Pan')}}</th>
                    <td>{{ _ausweis.pan }}</td>
                </tr>
                <tr>
                    <th scope="row">{{_('Ausweis-Art')}}</th>
                    <td *ngIf="_ausweis.ausweisArt==0">{{ _('Unbekannter Typ/Sonstige') }}</td>
                    <td *ngIf="_ausweis.ausweisArt==1">{{ _('Magnetstreifen-Karte') }}</td>
                    <td *ngIf="_ausweis.ausweisArt==2">{{ _('Smartcard') }}</td>
                    <td *ngIf="_ausweis.ausweisArt==3">{{ _('Chip-Transponder') }}</td>
                    <td *ngIf="_ausweis.ausweisArt==4">{{ _('BT/Funk Transponder') }}</td>
                    <td *ngIf="_ausweis.ausweisArt==5">{{ _('Mobiltelefon-App') }}</td>
                    <td *ngIf="_ausweis.ausweisArt==6">{{ _('ME Heavy Tag') }}</td>
                    <th scope="row">{{_('Herausgeber')}}</th>
                    <td>{{ _ausweis.issuer.bezeichnung }} ({{ _ausweis.issuer.nummer }})</td>
                </tr>
                <tr>
                    <th scope="row">{{ _('im System angelegt') }}</th>
                    <td>{{fDate( _ausweis.dateCreated ,'medium' )}}</td>
                    <th scope="row">{{ _('zuletzt bearbeitet') }}</th>
                    <td>{{fDate( _ausweis.lastEdited ,'medium' )}}</td>
                </tr>
                <tr>
                    <th scope="row">{{ _('gültig ab') }}</th>
                    <td *ngIf="_ausweis.gueltigAb!==null">{{fDate( _ausweis.gueltigAb ,'medium' )}}
                    </td>
                    <td *ngIf="_ausweis.gueltigAb==null">{{fDate( _ausweis.dateCreated ,'medium'
                        )}}
                    </td>
                    <th scope="row">{{ _('gültig bis') }}</th>
                    <td *ngIf="_ausweis.gueltigBis!==null">{{fDate( _ausweis.gueltigBis ,'medium'
                        )}}
                    </td>
                    <td *ngIf="_ausweis.gueltigBis==null">{{ _('ohne Begrenzung') }}</td>
                </tr>
                <tr
                        *ngIf="(_ausweis.assignedToDriver!==undefined && _ausweis.assignedToDriver!==null && _ausweis.assignedToDriver)||
               (_ausweis.assignedToVehicle!==undefined && _ausweis.assignedToVehicle!==null && _ausweis.assignedToVehicle)">
                    <th scope="row">{{_('Zuordnungen')}}</th>
                    <td colspan="3">
                        <span class="badge badge-default"
                              *ngIf="(_ausweis.assignedToDriver!==undefined && _ausweis.assignedToDriver!==null && _ausweis.assignedToDriver)">
                            {{ _('Fahrer/Person') }}
                        </span>
                        <span class="badge badge-default"
                              *ngIf="(_ausweis.assignedToVehicle!==undefined && _ausweis.assignedToVehicle!==null &&  _ausweis.assignedToVehicle)">
                            {{ _('Fahrzeug') }}
                        </span>
                    </td>
                </tr>
                <tr *ngIf="(_ausweis.adminSperre!==null && _ausweis.adminSperre!==undefined && _ausweis.adminSperre) ||
(_ausweis.kundenSperre!==null && _ausweis.kundenSperre!==undefined && _ausweis.kundenSperre)
">
                    <th scope="row">{{ _('Sperrkennzeichen') }}</th>
                    <td colspan="3">
                <span class="badge badge-danger"
                      *ngIf="(_ausweis.adminSperre!==null && _ausweis.adminSperre!==undefined && _ausweis.adminSperre)"
                >
            {{ _('administrativ gesperrt') }}
                </span>
                        <span class="badge badge-warning"
                              *ngIf="(_ausweis.kundenSperre!==null && _ausweis.kundenSperre!==undefined && _ausweis.kundenSperre)"
                        >
            {{ _('vom Kunden gesperrt') }}
                </span>
                    </td>
                </tr>
                <tr>
                    <th scope="row">{{ _('Laufleistung') }}</th>
                    <td >
                        <span class="badge badge-default"
                              *ngIf="_ausweis.kmEingabe!==undefined && _ausweis.kmEingabe!==null && _ausweis.kmEingabe"
                        >
                            {{_('km-Stand abfragen')}}
                        </span>
                        <span class="badge badge-default"
                              *ngIf="_ausweis.kmUeberwachung!==undefined && _ausweis.kmUeberwachung!==null && _ausweis.kmUeberwachung"
                        >
                            {{_('km-Stand überwachen')}}
                        </span>
                        <span class="badge badge-default"
                              *ngIf="_ausweis.maxKMDifferenz!==undefined && _ausweis.maxKMDifferenz!==null && _ausweis.maxKMDifferenz>0"
                        >
                            {{maxKmLabel}}
                        </span>
                    </td>
                    <th scope="row">{{ _('Tankinhalt') }}</th>
                    <td >
                        <span class="badge badge-default"
                              *ngIf="_ausweis.maxVolumen!==undefined && _ausweis.maxVolumen!==null && _ausweis.maxVolumen>0"
                        >
                            {{maxVolumenLabel}}
                        </span>

                    </td>
                </tr>
                <tr>
                    <th scope="row">Produktfreigaben</th>
                    <td colspan="3">
                        <span *ngFor="let p of _ausweis.produktFreigaben">
                            {{ p.bezeichnung }}
                        </span>
                    </td>
                </tr>
                <tr>
                    <th scope="row">{{ _('Sonstiges') }}</th>
                    <td colspan="3">
                        <span class="badge badge-default"
                              *ngIf="_ausweis.zusatzEingabe!==undefined && _ausweis.zusatzEingabe!==null && _ausweis.zusatzEingabe">
                            {{ _('Eingabe weiterer Informationen notwendig') }}
                        </span>
                        <span class="badge badge-default"
                              *ngIf="_ausweis.zusatzAusweisNotwendig!==undefined && _ausweis.zusatzAusweisNotwendig!==null && _ausweis.zusatzAusweisNotwendig">
                            {{ _('weiterer Ausweis erforderlich') }}
                        </span>
                        <span class="badge badge-default"
                              *ngIf="_ausweis.kostenstelle!==undefined && _ausweis.kostenstelle!==null && _ausweis.kostenstelle">
                            {{ kostenstelleLabel }}
                        </span>

                    </td>
                </tr>
                <tr>
                    <th scope="row">{{ _('Infos') }}</th>
                    <td colspan="3">
                        {{ _ausweis.info }}
                    </td>
                </tr>
            </table>



        </div>
    </div>
</div>