import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {Fahrzeug} from '../common/fahrzeug';

import {LocalizedComponent} from '../../../commons/components/localized-component';

@Component({
  selector: 'app-fahrzeug-kennzeichen-quick-info',
  templateUrl: './fahrzeug-kennzeichen-quick-info.component.html',
  styleUrls: ['./fahrzeug-kennzeichen-quick-info.component.css']
})
export class FahrzeugKennzeichenQuickInfoComponent extends LocalizedComponent implements OnInit {

    _fahrzeug: Fahrzeug = null;

    @Input()
    set fahrzeug(value: Fahrzeug) {
        this._fahrzeug = Fahrzeug.fromResult(value);
    }

    @Input() showLabel        = true;
    @Input() info_position = 'right';

    getTitle() {
        if (this._fahrzeug !== null) {
            return this._(' Fahrzeug {{ nummer }} ', {nummer: this._fahrzeug.nummer});
        }
        return '';
    }

    constructor(

                public cd:ChangeDetectorRef) {
        super(cd);
    }

    ngOnInit() {
    }


}
