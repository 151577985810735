import {ChangeDetectionStrategy, Component, Injector, OnInit} from '@angular/core';
import {DataTableColumnDirective} from '../commons/data-table/data-table-column.directive';
import {DataTableRow} from '../commons/data-table/data-table-row';
import {Person} from './personen/common/person';

@Component({
               selector       : 'person-cell-renderer',
               template       : `
                   <app-person-quick-info
                           *ngIf="cell!==undefined && cell!==null"
                           [person]="cell"
                           [showLabel]="true"
                           [info_position]="info_position"
                   >
                   </app-person-quick-info>
               `,
               changeDetection: ChangeDetectionStrategy.OnPush
           })
export class PersonCellRendererComponent {
    info_position = 'right';

    cell: Person           = null;
    row: DataTableRow<any>  = null;
    column: DataTableColumnDirective = null;


    constructor(public injector: Injector) {

        this.cell   = this.injector.get('cell');
        this.row    = this.injector.get('row');
        this.column = this.injector.get('column');

        if (this.column !== null && this.column !== undefined) {
            if ((this.column.cfg !== null) && (this.column.cfg !== undefined)) {
                if (this.column.cfg.info_position !== null) {
                    this.info_position = this.column.cfg.info_position;
                }
            }
        }

    }

}
