import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';

import {ProduktGruppeList} from './produktgruppen/common/produkt-gruppe-list';
import {ProduktGruppe} from './produktgruppen/common/produkt-gruppe';
import {ProdukteService} from './produkte.service';
import {
    MEHighlitePipe,
    MESelectComponent,
    MESelectItemMatcherCallback,
    MESelectItemTextCallback
} from '../commons/dynamic-form/me-select/meselect.component';
import {FilterCallback} from '../commons/dynamic-form/FilterCallback';
import {GenericFilter} from '../commons/generic-filter';
import {CommonList} from '../commons/common-list';
import {MESelectItemFormatterCallback} from '../commons/dynamic-form/me-select/meselect-item-formatter-callback';
import {SimpleProduktGruppeFilter} from './produktgruppen/common/simple-produkt-gruppe-filter';
import {IListResult} from '../commons/list-result';
import {IProduktGruppe} from './produktgruppen/common/iprodukt-gruppe';
import {MESelectSearchEvent} from '../commons/dynamic-form/me-select/meselect-search-event';
import CryptoES from "crypto-es";

@Component({
    selector: 'produkt-gruppe-inline-field',
    template: `
        <meselect
                id="{{ id }}"
                [idField]="'id'"
                [items]="produktGruppen?.data"
                [asMatrix]="false"
                [placeholder]="label"
                (itemSelected)="doSelect($event)"
                (needsSearch)="doSearch($event)"
                [small]="small"
                [nullable]="nullable"
                [onFormat]="itemFormat"
                [onGetText]="itemText"
                [onMatch]="itemMatcher"
        >
        </meselect>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProduktGruppeInlineFieldComponent implements OnInit, AfterViewInit {

    @Input() nullable = true;

    @ViewChild(MESelectComponent, {static: true}) produktGruppenMESelect: MESelectComponent;
    @Input() small = false;

    produktGruppen: ProduktGruppeList = new ProduktGruppeList();
    @Input() label = 'ProduktGruppefeld';
    @Input() id = '';
    @Input() maxDisplayGruppen = 50;
    @Output() valueChange = new EventEmitter<ProduktGruppe>();

    constructor(public produkteService: ProdukteService,
                public cd: ChangeDetectorRef) {
    }

    _value: ProduktGruppe = null;

    get value(): ProduktGruppe {
        return this._value;
    }

    @Input('value')
    set value(v: ProduktGruppe) {

        if (this._value !== v) {
            this._value = v;
            if ((this.produktGruppenMESelect != null) && (this.produktGruppenMESelect !== undefined)) {
                this.produktGruppenMESelect.value = v;
            }
            this.valueChange.emit(v);
            this.cd.markForCheck();
        }
    }

    public search: FilterCallback<ProduktGruppe> = (
        filter: GenericFilter<ProduktGruppe>,
        list: CommonList<ProduktGruppe>,
        maxDisplayItems: number,
        queryString: string,
        cfg: any,
        selectComponent: MESelectComponent
    ) => {
        queryString = ('' + queryString).trim();
        let q = '';
        if (queryString !== '') {
            if (isNaN(parseInt(queryString, 10))) {
                q = '(*' + queryString + '*) OR' +
                    '(' + queryString + ')';
            } else {
                q = '(nummer:' + parseInt(queryString, 10) + '^10) OR ' +
                    '(nummer:' + parseInt(queryString, 10) + '*^5) OR ' +
                    '(*' + queryString + '*) OR' +
                    '(' + queryString + ')';
            }
        }
        this
            .produkteService
            .listProduktGruppen(
                maxDisplayItems,
                0,
                [],
                filter as SimpleProduktGruppeFilter,
                q
            )
            .subscribe(
                (c: IListResult<IProduktGruppe>) => {
                    list
                        .populateFromListResult(c);
                    if (selectComponent !== null && selectComponent !== undefined) {
                        selectComponent.items = list.data;
                        selectComponent.cd.markForCheck();
                    }
                }
            );
    }

    ngOnInit() {
        this.doSearch(new MESelectSearchEvent('', this.produktGruppenMESelect));
    }

    ngAfterViewInit(): void {
        this.produktGruppenMESelect.onFormat = this.itemFormat;
        this.produktGruppenMESelect.onGetText = this.itemText;
        this.produktGruppenMESelect.onMatch = this.itemMatcher;
        this.produktGruppenMESelect.value = this._value;
        if (this.id === '') {
            this.id = 'tf' + CryptoES.MD5('textfield-' + this.label + Math.random() + '-' + Math.random()).toString();
        }
    }

    doSearch(value: MESelectSearchEvent) {
        this.search(null, this.produktGruppen, this.maxDisplayGruppen, value.queryString, null, value.component);
    }

    doSelect(produktGruppe
                 :
                 ProduktGruppe
    ) {
        this._value = produktGruppe;
        this.valueChange.emit(produktGruppe);
    }

    public itemFormat: MESelectItemFormatterCallback<ProduktGruppe> = (item: ProduktGruppe, search) => {
        if (item == null) {
            return '';
        }
        return MEHighlitePipe.transformString(item.bezeichnung, search) +
            ' <span class=\'badge badge-default\'>' + MEHighlitePipe.transformString('' + item.nummer, search) + '</span>';
    }

    public itemText: MESelectItemTextCallback<ProduktGruppe> = (item: ProduktGruppe) => {
        if (item == null) {
            return '';
        }
        return item.bezeichnung + ' [' + item.nummer + ']';
    }

    public itemMatcher: MESelectItemMatcherCallback<ProduktGruppe> = (item: ProduktGruppe, search) => {
        if (!search) {
            return true;
        }
        if (search.trim() === '') {
            return true;
        }
        if (!item) {
            return false;
        }
        const x = item.bezeichnung + ' ' + item.nummer;

        return (x.toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) >= 0);
    }


}
