import {ChangeDetectorRef, Component, Input, OnDestroy, OnInit} from '@angular/core';

import {LocalizedComponent} from '../../commons/components/localized-component';
import {Kunde} from '../kunden/common/kunde';

@Component({
    selector: 'limit-block',
    templateUrl: './limit-block.component.html',
    styleUrls: ['./limit-block.component.css']
})
export class LimitBlockComponent extends LocalizedComponent implements OnInit, OnDestroy {
    get stand() {
        return this._('Stand: {{ zeitpunkt }}', {zeitpunkt: this.zp});
    }

    public get msg() {
        return this._('Sollten Sie Einwände gegen die hier aufgeführten Werte haben, bitten wir Sie, <br />' +
            'uns dies per E-Mail an ' +
            '<a href="mailto:dts@dieseltankstelle.at?subject=Einwände%20bzgl.%20Kundenlimits%20Kunde%20{{kundennummer}}" ' +
            'target="_blank">dts@dieseltankstelle.at</a>' +
            ' mitzuteilen.', {kundennummer: this._kunde.kundennummer});
    }

    _kunde: Kunde = null;
    zp = '';

    @Input()
    set kunde(kd: Kunde) {
        this._kunde = kd;
        this.zp = '';
        if (this._kunde !== null && this._kunde !== undefined) {
            if (this._kunde.kontingent != null) {
                this.zp = this.fDate(this._kunde.kontingent.letzteSaldoAktualisierung, 'LLL',true);
            }
        }
    }

    get kunde() {
        return this._kunde;
    }

    @Input() icon = 'icofont icofont-coins';
    @Input() title = this.t('Ihre aktuellen Limitierungen');
    @Input() classes = 'green';
    @Input() subtext = '';


    constructor(

                public cd: ChangeDetectorRef) {
        super( cd);
    }

    ngOnInit() {

    }

    ngOnDestroy() {

    }


}
