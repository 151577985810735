import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {LocalizedComponent} from '../commons/components/localized-component';
import {Station} from '../stationen/common/station';
import {Sonde} from '../stationen/sonden/sonde';

import {DataTableComponent} from '../commons/data-table/data-table.component';
import {DataTableRow} from '../commons/data-table/data-table-row';

@Component({
    selector: 'sonden-selector',
    template: `
        <div class="card">
            <div class="card-body">
                <div class="card-header clearfix">

                    <div class="float-right">
                        <button class="btn btn-default btn-sm"
                                [class.disabled]="checkAddDisabled()"
                                title="{{ _('gewählte Sonde in Liste übernehmen') }}"
                                (click)="add()"
                        >
                            <i class="icofont icofont-ui-add"></i>
                        </button>
                        <button class="btn btn-default btn-sm"
                                [class.disabled]="checkDelDisabled()"
                                title="{{ _('gewählte Sonde aus Liste entfernen') }}"
                                (click)="removeSelected()"
                        >
                            <i class="icofont icofont-ui-delete"></i>
                        </button>
                    </div>
                    <sonde-field
                            [labelwidth]="0"
                            [label]="_('Sonde wählen')"
                            [station]="station"
                            [(value)]="currentSonde"
                            [small]="true"
                            [nullable]="false"
                    ></sonde-field>
                </div>
                <div class="card-text">
                    <data-table
                            [data]="sonden"
                            [selectable]="true"
                            [singleselect]="true"
                            #sondenTable
                    >
                        <column [label]="_('Nummer')" [field]="'nummer'" [type]="'Integer'"></column>
                        <column [label]="_('Bezeichnung')" [field]="'bezeichnung'"></column>
                    </data-table>
                </div>
            </div>
        </div>
    `,
    styles: [`
        :host .card-header {
            padding: 0.35rem;
        }

        :host .card-header > div:first-child {
            padding-left: 0.35rem;
        }

        :host .card-header > zapfsaeule-field {
            min-width: 175px;
        }

        :host .card-header ::ng-deep .form-group {
            margin: 0 !important;
        }

        :host .card-header ::ng-deep .form-group .col-12 {
            padding: 0px;
        }

        :host data-table {
            margin: 0 !important;
            font-size: 0.8rem;
        }

        :host data-table ::ng-deep .column-nummer {
            width: 50px;
        }

        :host data-table ::ng-deep td {
            padding: 0.35rem !important;
        }

    `]
})
export class SondenSelectorComponent extends LocalizedComponent implements OnInit {

    @Input() station: Station = new Station();
    @ViewChild('sondenTable', {static: true}) sondenTable: DataTableComponent;

    sonden: DataTableRow<Sonde>[] = [];
    currentSonde: Sonde = null;
    @Output() valueChanged: EventEmitter<Sonde[]> = new EventEmitter<Sonde[]>();

    constructor(
        public cd: ChangeDetectorRef) {
        super(cd);
    }

    get value(): Sonde[] {
        const v: Sonde[] = [];
        this.sonden.forEach(z => {
            v.push(Sonde.fromResult(z.data as Sonde));
        });
        return v;
    }

    @Input()
    set value(v: Sonde[]) {
        v.forEach(e => {
            this.addElementToList(e, true);
        });
        this.sonden.forEach(z => {
            let found = false;
            v.forEach(e => {
                if (e.id === (z.data as Sonde).id) {
                    found = true;
                }
            });
            if (!found) {
                this.removeElementFromList(z.data, true);
            }
        });
    }

    ngOnInit() {
    }

    isElementInList(elem: Sonde): number {
        let position = -1;
        this.sonden.forEach(
            (z, index) => {
                if ((z.data as Sonde).id === elem.id) {
                    position = index;
                }
            }
        );
        return position;
    }

    addElementToList(elem: Sonde, suppressEvent = false) {
        if (elem === null) {
            return;
        }
        if (elem === undefined) {
            return;
        }
        if (this.isElementInList(elem) < 0) {
            this.sonden.push(new DataTableRow<Sonde>(Sonde.fromResult(elem)));
            if (this.sondenTable !== null && this.sondenTable !== undefined) {
                this.sondenTable.data = this.sonden;
            }
            if (!suppressEvent) {
                this.valueChanged.emit(this.value);
            }
        }
    }

    removeElementFromList(elem: Sonde, suppressEvent = false) {
        const pos = this.isElementInList(elem);
        if (pos >= 0) {
            this.sonden.splice(pos, 1);
            if (this.sondenTable !== null && this.sondenTable !== undefined) {
                this.sondenTable.data = this.sonden;
            }
            if (!suppressEvent) {
                this.valueChanged.emit(this.value);
            }
        }
    }

    add() {
        if ((this.currentSonde !== null) && (this.currentSonde !== undefined)) {
            this.addElementToList(this.currentSonde);
        }
    }

    checkAddDisabled(): boolean {
        let r = (this.currentSonde === null) || (this.currentSonde === undefined);
        if (r) {
            return r;
        }
        r = (this.isElementInList(this.currentSonde) >= 0);
        return r;
    }

    checkDelDisabled(): boolean {
        let e = true;

        this.sonden.forEach(z => {
            if (z.isSelected()) {
                e = false;
            }
        });
        return e;
    }

    removeSelected() {
        this.sonden.forEach(z => {
            if (z.isSelected()) {
                this.removeElementFromList(z.data);
            }
        });
    }


}
