<ng-container *ngIf="redosPending>0">
    <div class="alert alert-danger">
        <strong>Achtung!</strong>
        Es laufen noch Neubewertungen von Transaktionen.
        In der Warteschlange befinden sich momentan
        <strong>{{redosPending}}</strong> Transaktionen zur Verarbeitung.
    </div>
</ng-container>

<data-table
        [selectable]="false"
        [data]="transaktionen.asDataTableRows()"
        [orders]="transaktionen.order"
        [filter]="transaktionen.simpleFilter"
        (sortChanged)="sortChanged($event)"
        (filterChanged)="filterChanged($event)"
        [rowActionsAsButtons]="true"
        [showFilterArea]="false"
        [extraRowClasses]="transaktionenRowClassGetter"
        #transaktionenListe
>
    <tableaction
            *hasRole="['ROLE_ADMIN','ROLE_PROJECT_ADMIN','ROLE_BILLING','ROLE_TRANSAKTIONEN_WRITABLE']"
            [label]="_('Import')"
            [icon]="'icofont icofont-upload-alt'"
            (perform)="importTransactions()"
    ></tableaction>
    <tableaction
                            *hasRole="['ROLE_ADMIN','ROLE_PROJECT_ADMIN','ROLE_BILLING','ROLE_TRANSAKTIONEN']"

            [label]="_('Export')"
            [icon]="'icofont icofont-download-alt'"
            [type]="'dropdown'"
    >
        <list-option
                *hasRole="['ROLE_ADMIN','ROLE_PROJECT_ADMIN','ROLE_BILLING']"
                [label]="_('Verbrauchsbuchungen CSV')"
                [icon]="'far fa-file'"
                (perform)="downloadTransaktionen('csv',12001)"
        ></list-option>
        <list-option
                *hasRole="['ROLE_ADMIN','ROLE_PROJECT_ADMIN','ROLE_BILLING']"
                [label]="_('Verbrauchsbuchungen .xlsx')"
                [icon]="'far fa-file-excel'"
                (perform)="downloadTransaktionen('xlsx',12001)"
        ></list-option>
        <list-option
                *hasRole="['ROLE_ADMIN','ROLE_PROJECT_ADMIN','ROLE_BILLING']"
                [label]="_('Verbrauchsbuchungen fixed')"
                [icon]="'far fa-file'"
                (perform)="downloadTransaktionen('dat',12001)"
        ></list-option>
        <list-option
                *hasRole="['ROLE_ADMIN','ROLE_PROJECT_ADMIN','ROLE_BILLING','ROLE_TRANSAKTIONEN']"
                [label]="_('Transaktionen .xlsx')"
                [icon]="'far fa-file-excel'"
                (perform)="downloadTransaktionen('xlsx',12002)"
        ></list-option>

    </tableaction>
    <tableaction
            *hasRole="['ROLE_ADMIN','ROLE_PROJECT_ADMIN','ROLE_BILLING','ROLE_PRICING','ROLE_TRANSAKTIONEN_WRITABLE']"
            [label]="t('Werkzeuge')"
            [icon]="'icofont icofont-tools'"
            [type]="'dropdown'"

    >
        <list-option
                *hasRole="['ROLE_ADMIN','ROLE_PROJECT_ADMIN']"
                [label]="t('Transaktionen indizieren')"
                [icon]="'icofont icofont-cloud-refresh'"
                (perform)="reindexTransaktionen()"
        ></list-option>
        <list-option
                *hasRole="['ROLE_ADMIN','ROLE_PROJECT_ADMIN','ROLE_BILLING','ROLE_PRICING','ROLE_TRANSAKTIONEN_WRITABLE']"
                [label]="t('Transaktionen neu bewerten')"
                [icon]="'icofont icofont-calculator'"
                (perform)="redoTransaktionen()"
        ></list-option>
    </tableaction>

    <ng-template #zpunkt let-it="it">
        <div  *ngFor="let it of typed(it)">
            <div class="text-nowrap mb-2 fw-bold" style="font-size: .9rem">
                {{fDate(it.zeitpunkt,'medium')}}
            </div>
            <div class="text-nowrap">
                Beleg {{ it.belegnummer}}
            </div>
        </div>
    </ng-template>
    <column
            [field]="'zeitpunkt'"
            [label]="_('Zeitpunkt')"
            [templateRef]="zpunkt"
            [sortable]="true"></column>

    <ng-template #station let-it="it">
        <div  *ngFor="let it of typed(it)" class="text-nowrap fw-bold" style="font-size: .9rem">
            <app-station-quick-info [station]="it.station"></app-station-quick-info>
        </div>
    </ng-template>
    <column
            [field]="'station'"
            [label]="_('Station')"
            [templateRef]="station"
            [sortable]="true"></column>


    <ng-template #innenAuftrag let-it="it">
        <div class="text-nowrap" *ngFor="let it of typed(it)">
            <div class="d-flex justify-content-between align-items-start mb-2">
                <div class="fw-bold" style="max-width: 450px">
            <app-fahrzeug-quick-info [fahrzeug]="it.fahrzeug"></app-fahrzeug-quick-info>
                </div>
                <div class="ms-2 ml-2 d-flex justify-content-end align-items-start">
                    <div class="me-1 mr-1 text-nowrap">{{fNum(it.kmstand,'1.0-0')}}</div>
                    <div *ngIf="it?.fahrzeug?.laufzeitErfassung==='KILOMETER' || it?.fahrzeug?.laufzeitErfassung===1">km</div>
                    <div *ngIf="it?.fahrzeug?.laufzeitErfassung==='BETRIEBSTUNDEN' || it?.fahrzeug?.laufzeitErfassung===2">h</div>
                </div>
            </div>
            <div *hasRole="['ROLE_ADMIN','ROLE_PROJECT_ADMIN','ROLE_BILLING','ROLE_ADM','ROLE_KUNDEN']">
                <app-kunde-quick-info [kunde]="it.kunde"></app-kunde-quick-info>
            </div>
        </div>
    </ng-template>

    <column
            [field]="'fahrzeug'"
            [label]="_('Innenauftrag')"
           [templateRef]="innenAuftrag"
            [sortable]="false"></column>

    <ng-template #ausweis let-it="it">
        <div class="text-nowrap" *ngFor="let it of typed(it)">
            <div class="fw-bold mb-2" style="font-size: .9rem;">
                <app-ausweis-quick-info [ausweis]="it.ausweis1"></app-ausweis-quick-info>
            </div>
            <div *ngIf="it.kostenstelle!==null && it.kostenstelle!==undefined" class="d-flex justify-content-between align-items-start">
                <div>
                Kostenstelle:
                </div>
                <div>
                    <app-kostenstelle-quick-info [kostenstelle]="it.kostenstelle"></app-kostenstelle-quick-info>
                </div>
            </div>
        </div>

    </ng-template>

    <column
            [field]="'ausweis1'"
            [label]="_('Ausweis')"
            [templateRef]="ausweis"
            [sortable]="false"></column>

    <column
            [field]="'details'"
            [label]="_('Produkt & Menge')"
            [renderer]="prdCellRenderer"
            [sortable]="false"
    ></column>
    <ng-template #betrag let-it="it">
        <div class="text-nowrap" *ngFor="let it of typed(it)">
            <div class="text-nowrap text-end text-right fw-bold mb-2" style="font-size: .9rem;"
                 *ngIf="it.betrag_euro!==null && it.betrag_euro!==undefined && it.betrag_euro!==''">
                {{ fNum(it.betrag_euro,'1.3-3') }} &euro;
            </div>
            <div *ngIf="it.markedForRedo" class="text-danger text-end text-right">
                <i class="far fa-icon fa-exclamation-triangle"></i>  wird neu berechnet
            </div>
        </div>

    </ng-template>
    <column [field]="'betrag_euro'"
            [label]="_('Betrag')"
            [templateRef]="betrag"
            [sortable]="false"
    ></column>

    <rowaction
            *hasRole="['ROLE_ADMIN','ROLE_BILLING','ROLE_PROJECT_ADMIN','ROLE_TRANSAKTIONEN_WRITABLE']"
            [label]="_('Bearbeiten')"
            [icon]="'icofont icofont-ui-edit'"
            [title]="_('Transaktion bearbeiten')"
            (perform)="editTransaktion($event)"
    ></rowaction>

    <rowaction
            *hasRole="['ROLE_ADMIN','ROLE_BILLING','ROLE_PROJECT_ADMIN','ROLE_TRANSAKTIONEN_WRITABLE']"
            [label]="_('Details')"
            [icon]="'far fa-info-circle'"
            [title]="_('Details anzeigen')"
            (perform)="showDetails($event)"
    ></rowaction>

    <rowaction
            *hasRole="['ROLE_ADMIN','ROLE_BILLING','ROLE_PROJECT_ADMIN','ROLE_CUSTOMER','ROLE_ADM','ROLE_TRANSAKTIONEN']"
            [label]="_('Lieferschein')"
            [icon]="'far fa-print'"
            [title]="_('Lieferschein drucken')"
            (perform)="showLieferschein($event)"
    ></rowaction>


    <data-table-table-extra-header>
        <app-paginator
                [total]="transaktionen.total"
                [page]="transaktionen.page"
                [perpage]="transaktionen.size"
                (pageChange)="updateTransaktionen(true,$event)"
                (pageCountChange)="updateTransaktionenPageCount($event)"
                [showSearch]="true"
                (queryChange)="transaktionenQueryChange($event)"
                [showFilterButton]="true"
                [datatable]="transaktionenListe"
                (filterToggled)="transaktionenFilterToggle($event)"
                [filterDialog]="filterDialog"
        ></app-paginator>
    </data-table-table-extra-header>


    <data-table-filter-area>
        <app-transaktionen-filterdialog
                #filterDialog
                (filterChanged)="transaktionenQueryChange2($event)"
        ></app-transaktionen-filterdialog>
    </data-table-filter-area>
</data-table>
