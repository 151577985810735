import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {LocalizedComponent} from '../../commons/components/localized-component';

import {Geraet} from '../common/geraet';
import {EDeviceType} from '../common/edevice-type.enum';

@Component({
    selector: 'app-device-coin-acceptor-unit-details-editor',
    templateUrl: './device-coin-acceptor-unit-details-editor.component.html',
    styleUrls: ['./device-coin-acceptor-unit-details-editor.component.scss']
})
export class DeviceCoinAcceptorUnitDetailsEditorComponent extends LocalizedComponent implements OnInit {

    private _device: Geraet = null;

    @Input()
    set device(dev: Geraet) {
        if (this._device !== dev) {
            this._device = dev;
            this.cd.markForCheck();
        }
    }


    get device(): Geraet {
        return this._device;
    }

    @Output()
    onAction: EventEmitter<string> = new EventEmitter<string>();

    dTgateway = EDeviceType.MELORAGATEWAY;

    constructor(
                
                public cd: ChangeDetectorRef) {
        super( cd);
    }

    ngOnInit() {
    }

    sendAction(action: string) {
        this.onAction.emit(action);
    }

    addCoins() {
        this._device.settings.coinset.push([0, 0]);
        this.cd.markForCheck();
    }


    removeCoins(c: number) {
        alert(c);
            this._device.settings.coinset.splice(c, 1);
        this.cd.markForCheck();


    }
}
