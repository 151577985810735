<div class="modal-header">
    <h5 class="modal-title">{{ dlgTitle}}</h5>

    <button type="button" class="close" aria-label="Close" (click)="cancel()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">

    <app-tabset>
        <app-pane [title]="_('Stammdaten')">
            <num-field id="nummer-field"
                       [label]="_('Intern-Auftrag')"
                       [(value)]="entity.nummer"
                       [addonicon]="['icofont icofont-search']"
                       [addonlabel]="[_('freie Nummer suchen')]"
                       [enableaddon]="true"
                       (addonClick)="addOnClick($event)"
            ></num-field>
            <text-field [label]="_('Bezeichnung')" [(value)]="entity.bezeichnung"></text-field>
            <text-field [label]="_('User2')" [(value)]="entity.info"></text-field>
            <text-field [label]="_('KFZ-Kennzeichen')" [(value)]="entity.kfzKennzeichen"></text-field>
            <laufzeiterfassung-field [label]="_('Laufzeit-Erfassung')"
                                     [(value)]="entity.laufzeitErfassung"></laufzeiterfassung-field>
            <num-field *ngIf="isKilometer()" [label]="_('km-Stand')" [(value)]="entity.kmStand"></num-field>
            <num-field *ngIf="isBetriebsstunden()" [label]="_('Betriebsstunden')"
                       [(value)]="entity.kmStand"></num-field>
        </app-pane>
        <app-pane [title]="_('Tank')">
            <num-field [label]="_('Tankvolumen')" [(value)]="entity.tankVolumen"></num-field>
            <produkt-field [label]="_('Kraftstoff')" [(value)]="entity.kraftstoff"></produkt-field>
        </app-pane>
        <app-pane [title]="_('Überwachung')">

            <boolean-field [label]="_('km/Betriebsstunden-Stand abfragen')" [(value)]="entity.kmAbfrage"></boolean-field>
            <num-field [label]="_('aktueller km/Betriebsstunden-Stand')" [(value)]="entity.kmStand"></num-field>
            <num-field [label]="_('Maximale Differenz (km bzw. h)')" [(value)]="entity.maxKmDifferenz"></num-field>
            <div class="alert alert-info">
                {{ _('Hinweis: Die hier getätigten Einstellungen gelten zusätzlich' +
                'zu den Einstellungen des verwendeten Ausweises!')}}
            </div>
        </app-pane>
        <app-pane [title]="_('Zuordnungen')">
            <app-tabset>
                <app-pane [title]="_('Ausweise')">
                    <ausweis-selector
                            [label]="_('Ausweise')"
                            [value]="entity.ausweise"
                            (valueChanged)="updateAusweise($event)"
                    ></ausweis-selector>
                </app-pane>
                <app-pane
                        [title]="_('Andere')"
                >
                    <kunde-field [label]="_('Kunde')" [(value)]="entity.kunde"></kunde-field>
                    <kostenstelle-field [label]="_('Kostenstelle')"
                                        [(value)]="entity.kostenstelle"></kostenstelle-field>
                    <station-field [label]="_('Standort')" [(value)]="entity.standort"></station-field>
                    <text-field [label]="_('Werknummer')" [(value)]="entity.werksnummer"></text-field>
                    <text-field [label]="_('Buchungskreis')" [(value)]="entity.buchungskreis"></text-field>
                    <num-field *hasRole="['ROLE_ADMIN']" [label]="_('Tankserver-ID')"
                               [(value)]="entity.tankserverID"></num-field>
                </app-pane>
            </app-tabset>
        </app-pane>
        <app-pane [title]="_('Sonstiges')">
            <date-time-field [label]="_('angelegt')"
                             [value]="entity.dateCreated"
                             disabled="true"></date-time-field>
            <date-time-field [label]="_('zuletzt geändert')" [value]="entity.lastUpdated"
                             disabled="true"></date-time-field>
        </app-pane>


    </app-tabset>

</div>
<div class="modal-footer d-flex justify-content-between">
    <div>
 <button type="button" class="btn btn-danger" (click)="remove()" *ngIf="canDelete()">
        <i class="icofont icofont-trash"></i> {{ _('Löschen') }}
    </button>
    </div>
    <div>
        <button type="button" class="btn btn-secondary" (click)="cancel()">
            <i class="icofont icofont-close"></i> {{ _('Abbrechen') }}
        </button>
        <button type="button"
                class="btn btn-primary"
                (click)="save()"
                [class.disabled]="!canSave()"
                [disabled]="!canSave()"
        >
            <i class="icofont icofont-save"></i>
            {{ _('Änderungen speichern')}}
        </button>
    </div>
</div>

