import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import {
    MEHighlitePipe,
    MESelectComponent,
    MESelectItemMatcherCallback,
    MESelectItemTextCallback
} from '../../commons/dynamic-form/me-select/meselect.component';
import {MESelectItemFormatterCallback} from '../../commons/dynamic-form/me-select/meselect-item-formatter-callback';

import {FilterCallback} from '../../commons/dynamic-form/FilterCallback';
import {GenericFilter} from '../../commons/generic-filter';
import {IListResult} from '../../commons/list-result';
import {CommonList} from '../../commons/common-list';
import {BenutzerList} from '../../auth/common/benutzer-list';
import {Benutzer} from '../../auth/common/benutzer';
import {SimpleBenutzerFilter} from '../../auth/common/simple-benutzer-filter';
import {IBenutzer} from '../../auth/common/ibenutzer';
import {LoginService} from '../../auth/services/login.service';
import {MESelectSearchEvent} from '../../commons/dynamic-form/me-select/meselect-search-event';
import CryptoES from 'crypto-es';

@Component({
    selector: 'benutzer-inline-field',
    template: `
        <meselect
                id="{{ id }}"
                [idField]="'id'"
                [items]="benutzer.data"
                [asMatrix]="false"
                [placeholder]="label"
                (itemSelected)="doSelect($event)"
                (needsSearch)="doSearch($event)"
                [small]="small"
                [nullable]="nullable"
                [disabled]="disabled"
        >
        </meselect>
    `,
    styles: [`
        :host ::ng-deep meselect .red {
            color: red;
        }

        :host ::ng-deep meselect .orange {
            color: orange;
        }
    `],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class BenutzerInlineFieldComponent implements OnInit, AfterViewInit {

    @ViewChild(MESelectComponent, {static: false}) benutzerMESelect: MESelectComponent;
    @Input() small = false;
    @Input() nullable = true;

    benutzer: BenutzerList = new BenutzerList();
    @Input() label = 'BenutzerFeld';
    @Input() id = '';
    @Input() maxDisplayNum = 50;
    @Input() filter = '';
    @Input() disabled = false;
    @Output() valueChange = new EventEmitter<Benutzer>();

    constructor(public benutzerService: LoginService,
                public cd: ChangeDetectorRef) {
    }

    _value: Benutzer = null;

    get value(): Benutzer {
        return this._value;
    }

    @Input()
    set value(v: Benutzer) {

        if (this._value !== v) {
            this._value = v;
            if ((this.benutzerMESelect != null) && (this.benutzerMESelect !== undefined)) {
                this.benutzerMESelect.value = v;
            }
            this.valueChange.emit(v);
            this.cd.markForCheck();
        }
    }

    public search: FilterCallback<Benutzer> = (filter: GenericFilter<Benutzer>,
                                               list: CommonList<Benutzer>,
                                               maxDisplayItems: number,
                                               queryString: string,
                                               cfg: any,
                                               selectComponent: MESelectComponent
    ) => {

        const f: string[] = [];
        if (this.filter !== '') {
            f.push('(' + this.filter + ')');
        }

        queryString = ('' + queryString).trim();
        if (queryString !== '') {
            const qu = '(*' + queryString + '*) OR' +
                '(' + queryString + ')';
            f.push('( ' + qu + ')');
        }

        const q = f.join(' AND ');

        this.benutzerService
            .list(maxDisplayItems,
                0,
                [],
                filter as SimpleBenutzerFilter,
                q
            )
            .subscribe(
                (c: IListResult<IBenutzer>) => {
                    list.populateFromListResult(c);
                    this.cd.markForCheck();
                    if (selectComponent !== null && selectComponent !== undefined) {
                        selectComponent.items = list.data;
                        selectComponent.cd.markForCheck();
                    }
                }
            );
    }

    ngOnInit() {
        if (this.id === '') {
            this.id = 'tf' + CryptoES.MD5('benutzerfield-' + this.label + Math.random() + '-' + Math.random()).toString();
        }
        this.doSearch(new MESelectSearchEvent('', this.benutzerMESelect));
    }

    ngAfterViewInit(): void {
        this.benutzerMESelect.onFormat = this.itemFormat;
        this.benutzerMESelect.onGetText = this.itemText;
        this.benutzerMESelect.onMatch = this.itemMatcher;
        this.benutzerMESelect.value = this._value;
    }

    doSearch(value: MESelectSearchEvent) {
        this.search(null, this.benutzer, this.maxDisplayNum, value.queryString, null, value.component);
    }

    doSelect(benutzer: Benutzer) {
        this._value = benutzer;
        this.valueChange.emit(benutzer);
        this.cd.markForCheck();
    }

    public itemFormat: MESelectItemFormatterCallback<Benutzer> = (item: Benutzer, search) => {
        if (item === null || item === undefined) {
            return '';
        }

        let icon = 'icofont icofont-user';
        if (item.accountLocked) {
            icon = icon + 'red';
        }

        return '<i class="' + icon + '"></i> ' +
            MEHighlitePipe.transformString(item.fullname, search) +
            ' <span class="badge badge-default">' +
            MEHighlitePipe.transformString('' + item.username, search) +
            '</span>';
    }

    public itemText: MESelectItemTextCallback<Benutzer> = (item: Benutzer) => {
        if (item === null || item === undefined) {
            return '';
        }
        return item.fullname + ' [' + item.username + ']';
    }

    public itemMatcher: MESelectItemMatcherCallback<Benutzer> = (item: Benutzer, search) => {
        if (!search) {
            return true;
        }
        if (search.trim() === '') {
            return true;
        }
        if (!item) {
            return false;
        }
        const x = item.fullname + ' ' + item.username;

        return (x.toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) >= 0);
    }


}
