import {ISimpleFilter} from './simple-filter';

export class GenericFilter<T> implements ISimpleFilter<T> {
    public fields=undefined;

    public _operations = {};

    public clear() {
        Object.keys(this).forEach(k => {
            this[ k ] = null;
        });
    }

    public encodeField(fieldname: string): string {
        return 'filter.' + encodeURIComponent(fieldname)
               + '=' + encodeURIComponent('' + this[ fieldname ]);
    }


    public asURL(): string {
        let url = '';

        Object.keys(this).forEach(k => {
            if ((this[ k ] !== null) && (this[ k ] !== undefined) && (this[ k ] !== '') && (k !== '_operations')) {
                url += (url !== '') ? '&' : '';
                url += this.encodeField(k);
            }
        });

        Object.keys(this._operations).forEach(k => {
            if ((this._operations[ k ] !== null) && (this._operations[ k ] !== undefined) && (this._operations[ k ] !== '')) {
                url += (url !== '') ? '&' : '';
                url += 'operation.' + encodeURIComponent(k) + '=' + encodeURIComponent('' + this._operations[ k ]);
            }
        });

        return url;
    }

    public hasValues(): boolean {
        let r = false;
        Object.keys(this).forEach(k => {
            if ((this[ k ] !== null) && (this[ k ] !== undefined) && (this[ k ] !== '')) {
                r = true;
            }
        });

        return r;
    }


    public copyFrom(filter: ISimpleFilter<T>): void {
        this.clear();
        Object.keys(filter).forEach(k => {
                                        this[ k ] = filter[ k ];
                                    }
        );
    }
}
