import {ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {KitchenTimer} from '../../../commons/kitchen-timer';
import {SondeList} from '../sonde-list';
import {LandCellRendererComponent} from '../../../commons/data-table/renderer/land-cell-renderer.component';
import {LocalizedComponent} from '../../../commons/components/localized-component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

import {StationenService} from '../../common/stationen.service';

import {ISonde} from '../isonde';
import {IListResult} from '../../../commons/list-result';
import {NewPageCountEvent} from '../../../commons/paginator/paginator.component';
import {TankCellRendererComponent} from '../../tank-cell-renderer.component';
import {StationCellRendererComponent} from '../../station-cell-renderer.component';
import {LiterCellRendererComponent} from '../../../commons/components/liter-cell-renderer.component';
import {SysInfoService} from '../../../commons/sysinfo/services/sys-info.service';
import {ToastrService} from 'ngx-toastr';
import {DTSondenBuchungCellRendererComponent} from '../dtsonden-buchung-cell-renderer/dtsonden-buchung-cell-renderer.component';
import {SondenBuchungenDialogComponent} from '../sonden-buchungen-dialog/sonden-buchungen-dialog.component';
import {noop} from '../../../commons/noop';
import {SondeEditorComponent} from '../sonde-editor/sonde-editor.component';
import {DataTableComponent} from '../../../commons/data-table/data-table.component';
import {TransaktionenFilterdialogComponent} from '../../../billing/transaktionen/transaktionen-filterdialog/transaktionen-filterdialog.component';
import {SondenFilterChangedEvent} from './sonden-filter-dialog/sonden-filter-changed-event';
import {Sonde} from '../sonde';
import {DataTableRow} from '../../../commons/data-table/data-table-row';
import {TDTGetExtraRowClassesCallback} from '../../../commons/data-table/types';
import {LoginService} from '../../../auth/services/login.service';
import {RowActionParams} from '../../../commons/data-table/row-action-params';
import * as moment from 'moment';

@Component({
    selector: 'app-tab-aktive-sonden',
    templateUrl: './tab-aktive-sonden.component.html',
    styleUrls: ['./tab-aktive-sonden.component.scss']
})
export class TabAktiveSondenComponent extends LocalizedComponent implements OnInit {
    landR = LandCellRendererComponent;
    tankR = TankCellRendererComponent;
    stationR = StationCellRendererComponent;
    literR = LiterCellRendererComponent;
    buchungR = DTSondenBuchungCellRendererComponent;

    @ViewChild('sondenListe',{static: true}) sondenListe: DataTableComponent;
    @ViewChild('filterDialog',{static: true}) filterDialog: TransaktionenFilterdialogComponent;

    sonden: SondeList = new SondeList();
    public tsTimer: KitchenTimer;
    sondenRowClassGetter: TDTGetExtraRowClassesCallback<Sonde> = (row: DataTableRow<Sonde>) => {
        if (
            row !== null &&
            row !== undefined &&
            row.data !== null &&
            row.data !== undefined &&
            row.data.letzteBuchung !== null &&
            row.data.letzteBuchung !== undefined
        ) {
            if (row.data.letzteBuchung.zeitpunkt.getTime() < moment().subtract(3, 'days').toDate().getTime()) {
                return 'sonde-too-old';
            }
            return 'sonde-' + (row.data.letzteBuchung.menge <= row.data.warnmenge ? 'warn' : 'regular');
        }
        return '';
    }

    constructor(

                public stationenService: StationenService,
                public toastr: ToastrService,
                public sysInfoService: SysInfoService,
                public modalService: NgbModal,
                public cd: ChangeDetectorRef,
                public loginService: LoginService
    ) {
        super( cd);

        this.sonden.size = this.loginService.getPageSize('aktive_sonden');


        this.tsTimer = new KitchenTimer();
        this.tsTimer.subscribe(
            () => {
                this.sonden.simpleFilter.aktiveOnly = true;
                this.stationenService
                    .listSonden(
                        null,
                        this.sonden.size,
                        this.sonden.calcOffset(),
                        this.sonden.order,
                        this.sonden.simpleFilter,
                        this.sonden.getQuery()
                    )
                    .subscribe(
                        (l: IListResult<ISonde>) => {
                            this.sonden.populateFromListResult(l);
                        },
                        () => {
                            this.toastr.error(this._('Die Liste der aktiven Sonden konnte nicht geladen werden.'));
                            this.sonden.clear();
                        }
                    );
            }
        );
    }

    ngOnInit() {
        if (!this.tsTimer.wasFired()) {
            this.updateSonden();
        }
    }

    /**
     * lädt die Stationenliste neu
     * @param force - sofort neuladen
     * @param page - Seitennummer ändern
     */
    updateSonden(force = false, page?: number) {
        if ((page !== undefined) && (page != null)) {
            if (page !== this.sonden.page) {
                this.sonden.page = page;
                force = true;
            }
        }
        this.tsTimer.start(force);
    }

    /**
     * ändert die Anzahl der Datensätze/Seite und lädt die Liste neu
     * @param n
     */
    updateSondenPageCount(n: NewPageCountEvent) {
        if (this.sonden.size !== n.itemsPerPage) {
            this.sonden.size = n.itemsPerPage;
            this.loginService.setPageSize('aktive_sonden', n.itemsPerPage);
        }
        this.updateSonden(true, n.currentPage);
    }

    sondenQueryChange(value: string) {
        if (value !== this.sonden.searchString) {
            this.sonden.searchString = value;
            this.updateSonden(false, 1);
        }
    }

    showPeilungen($event: RowActionParams<ISonde>) {
        SondenBuchungenDialogComponent.open(this.modalService, $event.row.data).then(noop, noop);
    }

    editSonde(event: RowActionParams<ISonde>) {
        SondeEditorComponent.open(this.modalService, event.row.data.station, event.row.data).then(() => {
            this.updateSonden();
        });
    }

    sondenQueryChange2(value: SondenFilterChangedEvent) {
        if (value.queryString !== this.sonden.searchString2 || value.warnOnly !== this.sonden.simpleFilter.warnOnly) {
            this.sonden.searchString2 = value.queryString;
            this.sonden.simpleFilter.warnOnly = value.warnOnly;
            this.updateSonden(false, 1);
        }
    }

    sondenFilterToggle(visible: boolean) {
        if (!visible) {
            this.sondenQueryChange2(new SondenFilterChangedEvent('', false));
        }
    }

    reindexSonden() {
        this.stationenService
            .reindexSonden()
            .subscribe(
                () => {
                    this.toastr.success(this._('Der Indizierungsvorgang wurde angestoßen.'));
                },
                () => {
                    this.toastr.error(this._('Die Indizierungsvorgang konnte nicht angestoßen werden.'));
                }
            );
    }
}
