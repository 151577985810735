import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {Ausweis} from '../common/ausweis';

import {EAusweisArt} from '../common/eausweis-art';
import {LocalizedComponent} from '../../../commons/components/localized-component';
import {Router} from '@angular/router';
import {AusweisEditorComponent} from '../editor/ausweis-editor.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {KundenAusweisEditorComponent} from '../editor/kunden-ausweis-editor.component';
import {LoginService} from '../../../auth/services/login.service';
import {AusweisService} from '../common/ausweis.service';
import {IAusweis} from '../common/iausweis';
import {AusweisArt} from '../common/ausweis-art';

@Component({
    selector: 'app-ausweis-quick-info',
    templateUrl: './ausweis-quick-info.component.html',
    styleUrls: ['./ausweis-quick-info.component.css']
})
export class AusweisQuickInfoComponent extends LocalizedComponent implements OnInit {

    _ausweis: Ausweis = null;

    @Input() displayLink = true;

    @Input()
    set ausweis(value: Ausweis) {
        this._ausweis = Ausweis.fromResult(value);
    }

    @Input() info_position = 'right';
    @Input() showLabel = true;

    getIcon() {
        if (this._ausweis !== null) {
            return AusweisArt.getIcon(this._ausweis.ausweisArt);
        }
        return '';
    }

    getTitle() {
        if (this._ausweis !== null) {
            return this._(' Ausweis {{ nummer }} ', {nummer: this._ausweis.nummer});
        }
        return '';
    }

    constructor(
                
                public router: Router,
                public modalService: NgbModal,
                public cd: ChangeDetectorRef,
                public loginService: LoginService,
                public ausweisService: AusweisService
    ) {
        super( cd);
    }

    ngOnInit() {
    }


    gotoAusweis() {
        if (this._ausweis !== null && this._ausweis !== undefined) {
            this.router.navigate(
                ['kunden'],
                {
                    queryParams: {
                        k: '_kunde_id:' + this._ausweis.kunde.id,
                        a: '_ausweis_id:' + this._ausweis.id
                    }
                }
            );
        }
    }

    editAusweis() {
        if (this._ausweis !== null && this._ausweis !== undefined) {
            this.ausweisService.loadAusweis(this._ausweis.id).subscribe(
                (a: IAusweis) => {
                    if (this.loginService.isCustomer() || this.loginService.hasOneOfRoles(['ROLE_ADM'])) {
                        KundenAusweisEditorComponent
                            .open(this.modalService, Ausweis.fromResult(a))
                            .then(
                                () => {
                                },
                                () => {
                                }
                            );

                    } else if (this.loginService.hasOneOfRoles(['ROLE_ADMIN', 'ROLE_BILLING', 'ROLE_PROJECT_ADMIN'])) {
                        AusweisEditorComponent
                            .open(this.modalService, Ausweis.fromResult(a))
                            .then(
                                () => {
                                },
                                () => {
                                }
                            );
                    }
                });
        }
    }


    hasLink() {
        if (!this.displayLink) {
            return false;
        }
        return this.loginService.hasOneOfRoles(['ROLE_ADMIN', 'ROLE_PROJECT_ADMIN', 'ROLE_BILLING']);
    }
}
