import {DateTime} from 'luxon';
import {EventEmitter} from '@angular/core';

export class SondenBuchungenFilter {
    _timeFrom: Date = null;
    _timeUntil: Date = null;

    filterChanged: EventEmitter<void> = new EventEmitter<void>();


    get timeFrom(): Date {
        return this._timeFrom;
    }

    set timeFrom(value: Date) {
        if (value !== this._timeFrom) {
            this._timeFrom = value;
            this.filterChanged.emit();
        }
    }

    get timeUntil(): Date {
        return this._timeUntil;
    }

    set timeUntil(value: Date) {
        if (value !== this._timeUntil) {
            this._timeUntil = value;
            this.filterChanged.emit();
        }
    }


    constructor() {
    }

    thisMonth() {
        this._timeFrom = DateTime.local().startOf('month').toJSDate();
        this._timeUntil = DateTime.local().endOf('month').toJSDate();
        this.filterChanged.emit();
    }

    lastMonth() {
        this._timeFrom = DateTime.local().startOf('month').minus({months: 1}).toJSDate();
        this._timeUntil = DateTime.local().startOf('month').minus({months: 1}).endOf('month').toJSDate();
        this.filterChanged.emit();
    }

    today() {
        this._timeFrom = DateTime.local().startOf('day').toJSDate();
        this._timeUntil = DateTime.local().endOf('day').toJSDate();
        this.filterChanged.emit();
    }

    lastSevenDays() {
        this._timeFrom = DateTime.local().startOf('day').minus({days: 7}).toJSDate();
        this._timeUntil = DateTime.local().endOf('day').toJSDate();
        this.filterChanged.emit();
    }

    lastThreeDays() {
        this._timeFrom = DateTime.local().startOf('day').minus({days: 3}).toJSDate();
        this._timeUntil = DateTime.local().endOf('day').toJSDate();
        this.filterChanged.emit();
    }

    yesterday() {
        this._timeFrom = DateTime.local().startOf('day').minus({days: 1}).toJSDate();
        this._timeUntil = DateTime.local().startOf('day').minus({days: 1}).endOf('day').toJSDate();
        this.filterChanged.emit();
    }

    thisWeek() {
        this._timeFrom = DateTime.local().startOf('week').toJSDate();
        this._timeUntil = DateTime.local().endOf('week').toJSDate();
        this.filterChanged.emit();
    }

    lastWeek() {
        this._timeFrom = DateTime.local().startOf('week').minus({weeks: 1}).toJSDate();
        this._timeUntil = DateTime.local().startOf('week').minus({weeks: 1}).endOf('week').toJSDate();
        this.filterChanged.emit();
    }


}
