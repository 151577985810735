<data-table [selectable]="false" [data]="currencies.asDataTableRows()">
    <column [field]="'code'" [label]="t('Code')"></column>
    <column [field]="'bezeichnung'" [label]="t('Bezeichnung')"></column>
    <column [field]="'symbol'" [label]="t('Symbol')"></column>
    <column [field]="'rate'" [label]="t('Kurs')" [type]="'Decimal'" [cfg]="{fdigits:5}"></column>
    <column [field]="'lastRateUpdate'" [label]="t('Stand vom')" [type]="'Date'" [cfg]="{format:'medium'}"></column>
</data-table>
<div class="row">
    <div class="col-6"></div>
    <div class="col-6 text-right">
        <app-paginator
                [total]="currencies.total"
                [page]="currencies.page"
                [perpage]="currencies.size"
                (pageChange)="updateCurrencies(true,$event)"
                (pageCountChange)="updateCurrencyPageCount($event)"
        ></app-paginator>
    </div>
</div>
