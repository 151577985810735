<div *ngIf="device.isMECoinAcceptorUnit()">
    <geraet-field
            [label]="_('LoRa-Gateway')"
            [(value)]="device.settings.gateway"
            [station]="device.station"
            [deviceType]="dTgateway"
    ></geraet-field>
    <produkt-field [label]="_('Produkt')" [(value)]="device.settings.produkt"></produkt-field>
    <boolean-field [label]="_('Buchungen auf Münzen aufsplitten')"
                   [(value)]="device.settings.split"
                   [flip]="true"
                   [labelwidth]="11"
    ></boolean-field>
    <ausweis-field
            [label]="_('Ausweis')"
            [(value)]="device.settings.ausweis"
    ></ausweis-field>
    <kostenstelle-field
            [label]="_('Kostenstelle')"
            [(value)]="device.settings.kostenstelle"
    ></kostenstelle-field>
    <h4>{{_('Münzwerte')}}</h4>
    <table class="w-100 table-condensed">
        <thead>
        <tr>
            <th>Münzkanal</th>
            <th>Wert</th>
            <th></th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let c of device.settings.coinset;let idx=index">
            <td>
                <num-field [labelwidth]="0" [(value)]="c[0]"></num-field>
            </td>
            <td>
                <num-field [labelwidth]="0" [(value)]="c[1]"></num-field>
            </td>
            <td class="text-nowrap">
                <button class="btn btn-secondary" (click)="removeCoins(idx)">{{ _('Zeile löschen') }}</button>
            </td>
        </tr>
        <tr>
            <td class="text-nowrap">
                <button class="btn btn-secondary" (click)="addCoins()">{{ _('Zeile hinzufügen') }}</button>
            </td>
        </tr>
        </tbody>
    </table>

</div>
