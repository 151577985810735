import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {SimplePersonFilter} from './simple-person-filter';
import {LoginService} from '../../../auth/services/login.service';
import {Person} from './person';
import {IPerson} from './iperson';
import {ResultMetaSortImpl} from '../../../commons/result-meta';
import {IListResult} from '../../../commons/list-result';
import {IActionResponse} from '../../../commons/action-response';
import {IKunde} from '../../kunden/common/ikunde';
import {HttpClient} from '@angular/common/http';

@Injectable({
                providedIn: 'root'
            })
export class PersonService {

    constructor(public http: HttpClient,
                public loginService: LoginService
    ) {

    }

    load(personId: number): Observable<IPerson> {
        return this.http
            .get<IPerson>(
                '/api/personen/' + encodeURIComponent('' + personId),
                {headers: this.loginService.getHttpHeaders()}
            );
    }

    list(max = 0,
         offset = 0,
         sort: ResultMetaSortImpl[] = [],
         filter: SimplePersonFilter = null,
         queryString = null
    ): Observable<IListResult<IPerson>> {
        let url = '/api/personen';

        url = url.concat('?')
            .concat('max=' + encodeURIComponent('' + max))
            .concat('&')
            .concat('offset=' + encodeURIComponent('' + offset));

        if (filter != null) {
            if (filter.hasValues()) {
                url = url + '&' + filter.asURL();
            }
        }

        if (queryString !== undefined && queryString !== null && ('' + queryString).trim() !== '') {
            url = url + '&q=' + encodeURIComponent(queryString);
        }

        if (sort !== undefined && sort !== null && sort.length > 0) {
            const p = 0;
            sort.forEach(it => {
                url = url.concat('&sort.' + p + '=' + encodeURIComponent(it.field + '|' + it.direction));
            });
        }


        return this.http
            .get<IListResult<IPerson>>(
                url,
                {headers: this.loginService.getHttpHeaders()}
            );

    }


    findFreeNum(kunde: IKunde = null): Observable<IActionResponse> {
        let url = '/api/personen/_freeNum';
        if (kunde != null && kunde !== undefined) {
            url = url + '?kunde=' + encodeURIComponent('' + kunde.id);
        }
        return this.http
            .post<IActionResponse>(
                url,
                {},
                {headers: this.loginService.getHttpHeaders()}
            );

    }

    store(person: Person, insert = false): Observable<IActionResponse> {
        if (!insert && person.id != null && person.id > 0) {
            return this.http
                .put<IActionResponse>(
                    '/api/personen/' + encodeURIComponent('' + person.id),
                    JSON.stringify(person),
                    {headers: this.loginService.getHttpHeaders()}
                );
        }
        return this.http
            .post<IActionResponse>(
                '/api/personen',
                JSON.stringify(person),
                {headers: this.loginService.getHttpHeaders()}
            );

    }


}
