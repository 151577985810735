<div class="modal-header">
    <h5 class="modal-title" *ngIf="isAdd()">{{ _("Preisgruppe anlegen") }}</h5>
    <h5 class="modal-title" *ngIf="isEdit()">{{ _("Preisgruppe ändern") }}</h5>
    <button type="button" class="close" aria-label="Close" (click)="cancel()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">

    <text-field
            [label]="_('Bezeichnung')"
            [(value)]="entity.title"
    ></text-field>
    <preisgruppe-art-field
            [label]="_('Art')"
            [value]="entity.gruppeArt"
            (valueChange)="changeArt($event)"
    ></preisgruppe-art-field>
    <div class="alert alert-info" *ngIf="entity.needsSettings()">
        {{ _('Die hier aufgeführten Parameter Produkt und ggf. Währung funktionieren bei der ' +
        'Bestimmung der Preisgruppe als Filter. Ist einer dieser Parameter nicht ausgefüllt, gilt der ' +
        'Eintrag für alle Produkte bzw. Währungen. Es wird nur der erste passende Eintrag gewertet. ' +
        'Die Reihenfolge der Vergleiche ist Produkt + Währung => Produkt + alle Währungen => ' +
        'alle Produkte + Währung => alle Produkte + alle Währungen. Alle Preisanpassungen kommen ' +
        'auf den netto-Einzelpreis zur Anwendung.') }}

    </div>
    <data-table *ngIf="entity.needsSettings()"
                [selectable]="false"
                [data]="entity.settingsAsDataTableRows()"
                [(newEntity)]="newSetting"
                (saveRecord)="saveEntry($event)"
                (rowEditCancelled)="editCancelled($event)"
                [rowActionsAsButtons]="true"
                #settingsTable
    >
        <column
                [field]="'produkt'"
                [label]="t('Produkt')"
                [renderer]="produktR"
                [editor]="produktE"
                [editable]="true"
                [cfg]="{nullIsAny:true}"
        ></column>
        <column
                [field]="'waehrung'"
                *ngIf="entity.needsCurrency()"
                [label]="t('Währung')"
                [editable]="true"
                [renderer]="waehrungR"
                [editor]="waehrungE"
                [cfg]="{nullIsAny:true}"

        ></column>
        <column
                *ngIf="entity.needsValue()"
                [field]="'wert'"
                [label]="t('Wert/Betrag')"
                [type]="'number'"
                [editor]="mengeE"
                [editable]="true"
        ></column>
        <tableaction
                *hasRole="['ROLE_ADMIN','ROLE_PROJECT_ADMIN','ROLE_BILLING']"
                [label]="t('Eintrag hinzufügen')"
                [icon]="'icofont icofont-ui-add'"
                (perform)="addEntry()"
        ></tableaction>
        <rowaction
                *hasRole="['ROLE_ADMIN','ROLE_PROJECT_ADMIN','ROLE_BILLING']"
                [label]="_('Bearbeiten')"
                [icon]="'icofont icofont-ui-edit'"
                (perform)="editEntry($event)"
        ></rowaction>
        <rowaction
                *hasRole="['ROLE_ADMIN','ROLE_PROJECT_ADMIN','ROLE_BILLING']"
                [label]="_('Löschen')"
                [icon]="'icofont icofont-ui-delete'"
                (perform)="removeEntry($event)"
        ></rowaction>

    </data-table>


</div>
<div class="modal-footer d-flex justify-content-between">
    <div>
        <button type="button" class="btn btn-danger" *ngIf="isEdit()" (click)="delete()">
            <i class="icofont icofont-ui-delete"></i>
            {{ _('Preisgruppe löschen')}}
        </button>
    </div>
    <div>
        <button type="button" class="btn btn-secondary" (click)="cancel()">
            <i class="icofont icofont-close"></i> {{ _('Abbrechen') }}
        </button>
        <button type="button" class="btn btn-primary" (click)="save()">
            <i class="icofont icofont-save"></i>
            {{ _('Preisgruppe speichern')}}
        </button>
    </div>
</div>

