import {ChangeDetectorRef, Component, Injector, OnInit} from '@angular/core';
import {DataTableColumnDirective} from '../commons/data-table/data-table-column.directive';
import {DataTableRow} from '../commons/data-table/data-table-row';
import {LocalizedCellRenderer} from '../commons/data-table/renderer/common-cell-renderer';
import {Einheit} from './einheiten/einheit';
import {Produkt} from './produkte/common/produkt';

@Component({
               selector: 'menge-cell-renderer',
               template: `{{ val }} {{ addon }}`
           })
export class MengeCellRendererComponent extends LocalizedCellRenderer implements OnInit {

    digitInfo = '1.3-3';
    digits = 3;

    do_round = false;


    cell: number = null;
    row: DataTableRow<any> = null;
    column: DataTableColumnDirective = null;

    addon = '';

    get val() {
        if (this.cell == null) {
            return '';
        }
        return this.fNum(this.cell, this.digitInfo);
    }

    constructor(public injector: Injector,
                public cd: ChangeDetectorRef) {
        super(cd);
        this.cell = this.injector.get('cell');
        this.row = this.injector.get('row');
        this.column = this.injector.get('column');

        if ((this.column !== null) && (this.column !== undefined)) {
            const cfg = this.column.cfg;
            if ((cfg !== null) && (cfg !== undefined)) {
                if ((cfg.fdigits !== undefined) && (cfg.fdigits !== null)) {
                    const fdigits: number = parseInt(cfg.fdigits, 10);
                    if (!isNaN(fdigits)) {
                        this.digits = fdigits;
                        this.digitInfo = '1.' + fdigits + '-' + fdigits;
                    }
                }

                if ((cfg.round !== undefined) && (cfg.round !== null)) {
                    this.do_round = !!cfg.round;
                }

                if (this.row !== null && this.row !== undefined) {

                    if ((cfg.produktField !== undefined) && (cfg.produktField !== null) && (cfg.produktField !== '')) {
                        if ((this.row.data[ cfg.produktField ] !== undefined) && (this.row.data[ cfg.produktField ] !== null)) {
                            const f: Produkt = this.row.data[ cfg.produktField ] as Produkt;
                            if ((f.einheit !== undefined) && (f.einheit !== null)) {
                                if ((f.einheit.symbol !== undefined) && (f.einheit.symbol !== null)) {
                                    this.addon = f.einheit.symbol;
                                }
                            }
                        }

                    }


                    if ((cfg.unitField !== undefined) && (cfg.unitField !== null) && (cfg.unitField !== '')) {
                        if ((this.row.data[ cfg.unitField ] !== undefined) && (this.row.data[ cfg.unitField ] !== null)) {
                            const f: Einheit = this.row.data[ cfg.unitField ] as Einheit;
                            if ((f.symbol !== undefined) && (f.symbol !== null)) {
                                this.addon = f.symbol;
                            }
                        }
                    }
                }
            }
        }

        if (this.do_round) {
            this.cell = Math.round(this.cell * Math.pow(this.digits, 10)) / Math.pow(this.digits, 10);
        }

    }

    public XtraClasses(): string {
        return 'menge-column';
    }


}
