import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

import {LimitsEditorComponent} from '../limits-editor/limits-editor.component';
import {LocalizedComponent} from '../../../../commons/components/localized-component';
import {Begrenzung} from '../../common/begrenzung';
import {ELimitType} from '../../common/elimit-type';

@Component({
               selector   : 'app-limit-editor',
               templateUrl: './limit-editor.component.html',
               styleUrls  : ['./limit-editor.component.css']
           })
export class LimitEditorComponent extends LocalizedComponent implements OnInit {


    @Input() value: Begrenzung = null;
    @Output() valueChanged: EventEmitter<Begrenzung> = new EventEmitter<Begrenzung>();

    @Input() limitsEditor: LimitsEditorComponent = null;
    @Input() disabled = false;

    constructor(  public cd: ChangeDetectorRef) {
        super( cd);
    }

    ngOnInit() {
    }

    changeType(type: ELimitType) {
        if (this.value === null || this.value === undefined) {
            this.value = new Begrenzung();
        }
        if (this.value.type !== type) {
            this.value.type = type;
            this.valueChanged.emit(this.value);
            if (this.limitsEditor !== null && this.limitsEditor !== undefined) {
                this.limitsEditor.doSort();
            }
        }
    }

    setInfo(info) {
        if (this.value === null || this.value === undefined) {
            this.value = new Begrenzung();
        }
        this.value.info = info;
        this.valueChanged.emit(this.value);
    }

    setEnabled(enabled) {
        if (this.value === null || this.value === undefined) {
            this.value = new Begrenzung();
        }
        this.value.enabled = enabled;
        this.valueChanged.emit(this.value);
    }

    setCountries(countries) {
        if (this.value === null || this.value === undefined) {
            this.value = new Begrenzung();
        }
        this.value.laender = countries;
        this.valueChanged.emit(this.value);

    }

    setStationen(stationen) {
        if (this.value === null || this.value === undefined) {
            this.value = new Begrenzung();
        }
        this.value.stationen = stationen;
        this.valueChanged.emit(this.value);
    }

    setValidFrom(validFrom) {
        if (this.value === null || this.value === undefined) {
            this.value = new Begrenzung();
        }
        this.value.validFrom = validFrom;
        this.valueChanged.emit(this.value);
    }

    setDuration(duration) {
        if (this.value === null || this.value === undefined) {
            this.value = new Begrenzung();
        }
        this.value.duration = duration;
        this.valueChanged.emit(this.value);
    }

    setMonday(v) {
        if (this.value === null || this.value === undefined) {
            this.value = new Begrenzung();
        }
        this.value.monday = v;
        this.valueChanged.emit(this.value);

    }

    setTuesday(v) {
        if (this.value === null || this.value === undefined) {
            this.value = new Begrenzung();
        }
        this.value.tuesday = v;
        this.valueChanged.emit(this.value);

    }

    setWednesday(v) {
        if (this.value === null || this.value === undefined) {
            this.value = new Begrenzung();
        }
        this.value.wednesday = v;
        this.valueChanged.emit(this.value);

    }

    setThirsday(v) {
        if (this.value === null || this.value === undefined) {
            this.value = new Begrenzung();
        }
        this.value.thirsday = v;
        this.valueChanged.emit(this.value);

    }

    setFriday(v) {
        if (this.value === null || this.value === undefined) {
            this.value = new Begrenzung();
        }
        this.value.friday = v;
        this.valueChanged.emit(this.value);

    }

    setSaturday(v) {
        if (this.value === null || this.value === undefined) {
            this.value = new Begrenzung();
        }
        this.value.saturday = v;
        this.valueChanged.emit(this.value);

    }

    setSunday(v) {
        if (this.value === null || this.value === undefined) {
            this.value = new Begrenzung();
        }
        this.value.sunday = v;
        this.valueChanged.emit(this.value);

    }

    setRejectAlways(v) {
        if (this.value === null || this.value === undefined) {
            this.value = new Begrenzung();
        }
        this.value.rejectAlways = v;
        this.valueChanged.emit(this.value);

    }

    setAmount(v) {
        if (this.value === null || this.value === undefined) {
            this.value = new Begrenzung();
        }
        this.value.amount = v;
        this.valueChanged.emit(this.value);
    }

    setVolume(v) {
        if (this.value === null || this.value === undefined) {
            this.value = new Begrenzung();
        }
        this.value.volume = v;
        this.valueChanged.emit(this.value);
    }

    setLimitArt(v) {
        if (this.value === null || this.value === undefined) {
            this.value = new Begrenzung();
        }
        this.value.limitArt = v;
        this.valueChanged.emit(this.value);

    }

}
