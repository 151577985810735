import {ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CommonsModule} from '../commons/commons.module';

import {DtskundenComponent} from './dtskunden/dtskunden.component';
import {KundeDisplayComponent} from './kunden/display/kunde-display.component';
import {KundeInfoComponent} from './kunden/display/kunde-info.component';
import {TabKundeAuditComponent} from './kunden/tab-kunde-audit/tab-kunde-audit.component';
import {KundeSelfEditorComponent} from './kunden/kunde-self-editor/kunde-self-editor.component';
import {KundendatenBlockComponent} from './kundendaten-block/kundendaten-block.component';
import {KundenAusweisEditorComponent} from './ausweise/editor/kunden-ausweis-editor.component';
import {KundeQuickInfoComponent} from './kunden/display/kunde-quick-info.component';
import {KundeEditorComponent} from './kunden/kunde-editor/kunde-editor.component';
import {KundenComponent} from './kunden/kunden.component';
import {TabAusweisAuditComponent} from './ausweise/tab-ausweis-audit/tab-ausweis-audit.component';
import {PersonenComponent} from './personen/personen.component';
import {FreigabeEditorComponent} from './limits/editor/freigabe-editor/freigabe-editor.component';
import {SaldenResetConfirmDialogComponent} from './limits/editor/kontingent-editor/salden-reset-confirm-dialog/salden-reset-confirm-dialog.component';
import {KontingenteCellRendererComponent} from './limits/renderer/kontingente-cell-renderer/kontingente-cell-renderer.component';
import {DTFahrzeugKfzkennzeichenRendererComponent} from './fahrzeuge/renderer/dtfahrzeug-kfzkennzeichen-renderer.component';
import {FahrzeugKennzeichenQuickInfoComponent} from './fahrzeuge/display/fahrzeug-kennzeichen-quick-info.component';
import {FahrzeugKennzeichenDisplayComponent} from './fahrzeuge/display/fahrzeug-kennzeichen-display.component';
import {PinDialogComponent} from './ausweise/pin-dialog/pin-dialog.component';
import {KontingentEditorComponent} from './limits/editor/kontingent-editor/kontingent-editor.component';
import {AusweisEditorComponent} from './ausweise/editor/ausweis-editor.component';
import {PersonInfoComponent} from './personen/display/person-info.component';
import {PersonEditorComponent} from './personen/editor/person-editor.component';
import {FahrzeugInfoComponent} from './fahrzeuge/display/fahrzeug-info.component';
import {PersonQuickInfoComponent} from './personen/display/person-quick-info.component';
import {FahrzeugQuickInfoComponent} from './fahrzeuge/display/fahrzeug-quick-info.component';
import {AusweisQuickInfoComponent} from './ausweise/display/ausweis-quick-info.component';
import {FahrzeugDisplayComponent} from './fahrzeuge/display/fahrzeug-display.component';
import {PersonDisplayComponent} from './personen/display/person-display.component';
import {AusweisInfoComponent} from './ausweise/display/ausweis-info.component';
import {AusweisDisplayComponent} from './ausweise/display/ausweis-display.component';
import {FahrzeugEditorComponent} from './fahrzeuge/fahrzeug-editor/fahrzeug-editor.component';
import {FahrzeugeComponent} from './fahrzeuge/fahrzeuge.component';
import {AusweiseComponent} from './ausweise/ausweise.component';
import {KundenService} from './kunden/common/kunden.service';
import {PersonService} from './personen/common/person.service';
import {AusweisService} from './ausweise/common/ausweis.service';
import {FahrzeugService} from './fahrzeuge/common/fahrzeug.service';
import {FahrzeugInlineFieldComponent} from './fahrzeug-inline-field.component';
import {FahrzeugFieldComponent} from './fahrzeug-field.component';
import {PersonFieldComponent} from './person-field.component';
import {PersonInlineFieldComponent} from './person-inline-field.component';
import {LimitArtFieldComponent} from './limit-art-field.component';
import {AusweisArtFieldComponent} from './ausweis-art-field.component';
import {KundeFieldComponent} from './kunde-field.component';
import {KundeInlineFieldComponent} from './kunde-inline-field.component';
import {AusweisFieldComponent} from './ausweis-field.component';
import {AusweisInlineFieldComponent} from './ausweis-inline-field.component';
import {LimitTypeFieldComponent} from './limit-type-field.component';
import {LimitBlockComponent} from './limit-block/limit-block.component';
import {KundeCellRendererComponent} from './kunden/kunde-cell-renderer.component';
import {FahrzeugCellRendererComponent} from './fahrzeuge/fahrzeug-cell-renderer.component';
import {AusweisCellRendererComponent} from './ausweise/ausweis-cell-renderer.component';
import {KundeCellFilterComponent} from './kunden/kunde-cell-filter.component';
import {PersonCellRendererComponent} from './person-cell-renderer.component';
import {AuthModule} from '../auth/auth.module';
import {ProductsModule} from '../products/products.module';
import {StationenModule} from '../stationen/stationen.module';
import {DashboardLimitBlockComponent} from './dashboard-limit-block/dashboard-limit-block.component';
import {DashboardPrepaidBlockComponent} from './dashboard-prepaid-block/dashboard-prepaid-block.component';
import {DashboardAusweiseBlockComponent} from './dashboard-ausweise-block/dashboard-ausweise-block.component';
import {DashboardKundenBlockComponent} from './dashboard-kunden-block/dashboard-kunden-block.component';
import {PricingModule} from '../pricing/pricing.module';
import {AusweisSelectorComponent} from './ausweis-selector/ausweis-selector.component';
import {DashboardFahrzeugeBlockComponent} from './dashboard-fahrzeuge-block/dashboard-fahrzeuge-block.component';
import {CustomerelementRendererComponent} from './customerelement-renderer.component';
import {AusweisElementRendererComponent} from './ausweis-element-renderer.component';
import {FahrzeugElementRendererComponent} from './fahrzeug-element-renderer.component';
import {PersonElementRendererComponent} from './person-element-renderer.component';
import {BegrenzungenCellRendererComponent} from './limits/renderer/begrenzungen-cell-renderer/begrenzungen-cell-renderer.component';
import {UserControlsModule} from '../user-controls/user-controls.module';
import {KostenstellenComponent} from './kostenstellen/kostenstellen/kostenstellen.component';
import {KostenstelleService} from './kostenstellen/kostenstelle.service';
import {TabKostenstellenComponent} from './kostenstellen/tab-kostenstellen/tab-kostenstellen.component';
import {KostenstellenEditorComponent} from './kostenstellen/kostenstellen-editor/kostenstellen-editor.component';
import {KostenstelleInlineFieldComponent} from './kostenstellen/kostenstelle-inline-field/kostenstelle-inline-field.component';
import {KostenstelleFieldComponent} from './kostenstellen/kostenstelle-field/kostenstelle-field.component';
import {DTKostenstelleRendererComponent} from './kostenstellen/dtkostenstelle-renderer/dtkostenstelle-renderer.component';
import {KostenstelleQuickInfoComponent} from './kostenstellen/kostenstelle-quick-info/kostenstelle-quick-info.component';
import {AusweiseRendererComponent} from './ausweise/display/ausweise-renderer/ausweise-renderer.component';
import {DTFahrzeugNummerRendererComponent} from './fahrzeuge/renderer/dtfahrzeug-nummer-renderer/dtfahrzeug-nummer-renderer.component';
import {FahrzeugNummerQuickInfoComponent} from './fahrzeuge/display/fahrzeug-nummer-quick-info/fahrzeug-nummer-quick-info.component';
import {LaufzeiterfassungFieldComponent} from './laufzeiterfassung-field.component';
import {KundeFilterDialogComponent} from './kunden/kunde-filter-dialog/kunde-filter-dialog.component';
import { FahrzeugFilterDialogComponent } from './fahrzeuge/fahrzeug-filter-dialog/fahrzeug-filter-dialog.component';
import { AusweisFilterDialogComponent } from './ausweise/ausweis-filter-dialog/ausweis-filter-dialog.component';
import {FahrzeugSelectorComponent} from './fahrzeug-selector/fahrzeug-selector.component';
import {PersonSelectorComponent} from './person-selector/person-selector.component';
import {LimitEditorComponent} from './limits/editor/limit-editor/limit-editor.component';
import {LimitsEditorComponent} from './limits/editor/limits-editor/limits-editor.component';

const components = [
    KundenComponent,
    KundeEditorComponent,
    KundeQuickInfoComponent,
    KundeInfoComponent,
    KundeDisplayComponent,
    DtskundenComponent,
    KundenAusweisEditorComponent,
    KundendatenBlockComponent,
    KundeSelfEditorComponent,
    TabKundeAuditComponent,
    TabAusweisAuditComponent,
    FahrzeugeComponent,
    PersonenComponent,
    AusweiseComponent,
    FahrzeugEditorComponent,
    AusweisDisplayComponent,
    AusweisInfoComponent,
    PersonDisplayComponent,
    FahrzeugDisplayComponent,
    AusweisQuickInfoComponent,
    FahrzeugQuickInfoComponent,
    PersonQuickInfoComponent,
    FahrzeugInfoComponent,
    PersonEditorComponent,
    PersonInfoComponent,
    AusweisEditorComponent,
    KontingentEditorComponent,
    PinDialogComponent,
    FahrzeugKennzeichenDisplayComponent,
    FahrzeugKennzeichenQuickInfoComponent,
    DTFahrzeugKfzkennzeichenRendererComponent,
    KontingenteCellRendererComponent,
    SaldenResetConfirmDialogComponent,
    FreigabeEditorComponent,
    LimitArtFieldComponent,
    PersonInlineFieldComponent,
    PersonFieldComponent,
    FahrzeugInlineFieldComponent,
    FahrzeugFieldComponent,
    AusweisInlineFieldComponent,
    AusweisFieldComponent,
    KundeInlineFieldComponent,
    KundeFieldComponent,
    AusweisArtFieldComponent,
    LimitTypeFieldComponent,
    LimitBlockComponent,
    KundeCellRendererComponent,
    FahrzeugCellRendererComponent,
    AusweisCellRendererComponent,
    KundeCellFilterComponent,
    PersonCellRendererComponent,
    DashboardLimitBlockComponent,
    DashboardPrepaidBlockComponent,
    DashboardAusweiseBlockComponent,
    DashboardKundenBlockComponent,
    BegrenzungenCellRendererComponent,
    AusweisSelectorComponent,
    DashboardFahrzeugeBlockComponent,
    CustomerelementRendererComponent,
    AusweisElementRendererComponent,
    FahrzeugElementRendererComponent,
    PersonElementRendererComponent,
    KostenstellenComponent,
    TabKostenstellenComponent,
    KostenstellenEditorComponent,
    KostenstelleInlineFieldComponent,
    KostenstelleFieldComponent,
    DTKostenstelleRendererComponent,
    KostenstelleQuickInfoComponent,
    AusweiseRendererComponent,
    DTFahrzeugNummerRendererComponent,
    FahrzeugNummerQuickInfoComponent,
    LaufzeiterfassungFieldComponent,
    KundeFilterDialogComponent,
    FahrzeugFilterDialogComponent,
    AusweisFilterDialogComponent,
    FahrzeugSelectorComponent,
    PersonSelectorComponent,
    LimitEditorComponent,
    LimitsEditorComponent
];

@NgModule({
    imports: [
        CommonModule,
        CommonsModule,
        AuthModule,
        ProductsModule,
        StationenModule,
        PricingModule,
        UserControlsModule
    ],
    declarations: [
        ...components
    ],
    exports: [
        ...components
    ],
    entryComponents: [
        ...components
    ]

})
export class CustomersModule {
}
