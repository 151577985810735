import {IGeraet} from './igeraet';
import {EDeviceType} from './edevice-type.enum';
import {DeviceType} from './device-type';
import {MEVideoControlSettings} from './mevideo-control-settings';
import {GenericDeviceSettings} from './generic-device-settings';
import {Station} from '../../stationen/common/station';
import {MELoraGatewaySettings} from './melora-gateway-settings';
import {MECoinAcceptorUnitSettings} from './mecoin-acceptor-unit-settings';
import {StaubsaugerSettings} from './staubsauger-settings';

export class Geraet implements IGeraet {
    bezeichnung = '';
    id = -1;
    nummer = 0;
    settings: any = {};
    typ: EDeviceType = EDeviceType.UNKNOWN;
    station: Station = null;

    static fromResult(entity: IGeraet): Geraet {
        if (entity === null || entity === undefined) {
            return null;
        }
        const l = new Geraet();
        let st = null;
        for (const k of Object.keys(l)) {
            if (k === 'typ') {
                l.typ = DeviceType.byValue(entity.typ);
            } else if (k === 'settings') {
                st = entity.settings;
            } else if (k === 'station') {
                l.station = Station.fromResult(entity.station);
            } else {
                l[k] = (entity[k] !== undefined) ? entity[k] : '';
            }
        }

        switch (l.typ) {
            case EDeviceType.MEVIDEOCONTROL:
                l.settings = MEVideoControlSettings.fromRAW(st);
                break;
            case EDeviceType.MELORAGATEWAY:
                l.settings = MELoraGatewaySettings.fromRAW(st);
                break;
            case EDeviceType.MECOINACCEPTORUNIT:
                l.settings = MECoinAcceptorUnitSettings.fromRAW(st);
                break;
            case EDeviceType.VACUUMCLEANER:
                l.settings = StaubsaugerSettings.fromRAW(st);
                break;
            default:
                l.settings = GenericDeviceSettings.fromRAW(st);
        }
        return l;
    }

    static byType(type: EDeviceType) {
        const g = new Geraet();
        g.typ = DeviceType.byValue(type);
        switch (g.typ) {
            case EDeviceType.MEVIDEOCONTROL:
                g.settings = new MEVideoControlSettings();
                break;
            case EDeviceType.MELORAGATEWAY:
                g.settings = new MELoraGatewaySettings();
                break;
            case EDeviceType.MECOINACCEPTORUNIT:
                g.settings = new MECoinAcceptorUnitSettings();
                break;
            case EDeviceType.VACUUMCLEANER:
                g.settings = new StaubsaugerSettings();
                break;
            default:
                g.settings = new GenericDeviceSettings();
        }
        return g;
    }

    constructor() {
    }


    isMEVideoControl() {
        return DeviceType.byValue(this.typ) === EDeviceType.MEVIDEOCONTROL;
    }

    isMELoragateway() {
        return DeviceType.byValue(this.typ) === EDeviceType.MELORAGATEWAY;
    }

    isMECoinAcceptorUnit() {
        return DeviceType.byValue(this.typ) === EDeviceType.MECOINACCEPTORUNIT;
    }

    isVacuumcleaner() {
        return DeviceType.byValue(this.typ) === EDeviceType.VACUUMCLEANER;
    }

}
