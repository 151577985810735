import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {Produkt} from '../common/produkt';

import {LocalizedComponent} from '../../../commons/components/localized-component';

@Component({
    selector: 'app-produkt-quick-info',
    templateUrl: './produkt-quick-info.component.html',
    styleUrls: ['./produkt-quick-info.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProduktQuickInfoComponent extends LocalizedComponent implements OnInit {

    private _produkt: Produkt = null;

    @Input()
    set produkt(value: Produkt) {
        this._produkt = Produkt.fromResult(value);
        this.cd.markForCheck();
    }


    get produkt(): Produkt {
        return this._produkt;
    }

    private _cfg: any = {nullIsAny: false};

    get cfg(): any {
        return this._cfg;
    }

    @Input()
    set cfg(value: any) {
        if (value != null) {
            this._cfg = {...this._cfg, ...value};
            this.cd.markForCheck();
        }
    }

    @Input() showLabel = true;


    get label() {
        if (!this.isValid()) {
            return '';
        }
        if (this._produkt === null || this._produkt === undefined) {
            if (this._cfg.nullIsAny) {
                return this._('alle Produkte');
            } else {
                return this._('kein Produkt');
            }
        }
        return this._produkt.bezeichnung;
    }


    constructor(  public cd: ChangeDetectorRef) {
        super( cd);
    }

    ngOnInit() {
    }

    isValid() {
        if (this._produkt !== undefined && this._produkt !== null) {
            return true;
        }
        if (this._cfg !== undefined && this._cfg !== null) {
            return this._cfg.nullIsAny;
        }
        return false;
    }

}
