<app-login-alert>


    <app-area-heading
            [icon]="'me-icon me-icon-station'"
            title="{{ _('Stationen') }}"
    ></app-area-heading>


    <app-tabcard>

        <!--app-pane title="{{ 'Stationen.karte' | translate:lang }}">
    Leaflet!
        </app-pane-->
        <app-pane title="{{ _('Liste') }}">

            <data-table #stationenListe (filterChanged)="filterChanged($event)"
                        (sortChanged)="sortChanged($event)"

                        [data]="stationen.asDataTableRows()"
                        [filter]="stationen.simpleFilter"
                        [orders]="stationen.order"
                        [rowActionsAsButtons]="true"
                        [selectable]="false"
                        [showFilterArea]="false"

            >
                <column [field]="'nummer'" [label]="t('Nummer')" type="integer"></column>
                <column [field]="'bezeichnung'" [label]="t('Bezeichnung')"></column>
                <column [field]="'anschrift'" [label]="t('Anschrift')"></column>
                <column [field]="'plz'" [label]="t('PLZ')"></column>
                <column [field]="'ort'" [label]="t('Ort')"></column>
                <column [field]="'land'" [label]="t('Land')" [renderer]="landR"></column>
                <column [cfg]="{label:t('Webseite aufrufen')}"
                        [field]="'url'"
                        [label]="t('Webadresse')"
                        type="URL"
                ></column>
                <column *appIsProjectAdmin [field]="'hidden'" [label]="t('Verborgen')" type="boolean"></column>

                <tableaction
                        (perform)="createStation()"
                        *hasRole="['ROLE_ADMIN','ROLE_PROJECT_ADMIN']"
                        [icon]="'icofont icofont-ui-add'"
                        [label]="t('Station anlegen')"
                ></tableaction>

                <tableaction
                        *hasRole="['ROLE_ADMIN']"
                        [icon]="'icofont icofont-tools'"
                        [label]="_('Werkzeuge')"
                        [type]="'dropdown'"
                >
                    <list-option
                            (perform)="syncStationen()"
                            [icon]="'icofont icofont-cloud-download'"
                            [label]="_('Stationen synchronisieren')"
                    ></list-option>
                    <list-option
                            (perform)="reindexStationen()"
                            [icon]="'icofont icofont-cloud-download'"
                            [label]="_('Stationen indizieren')"
                    ></list-option>
                    <list-option
                            (perform)="syncRatioStationen()"
                            *ngIf="appConfig.hasRatio"
                            [icon]="'icofont icofont-cloud-download'"
                            [label]="_('Stationen aus Ratio')"
                    ></list-option>
                </tableaction>


                <rowaction
                        (checkEnabled)="checkEnabled('station',$event)"
                        (perform)="editStation($event)"
                        *hasRole="['ROLE_ADMIN','ROLE_PROJECT_ADMIN']"
                        [icon]="'icofont icofont-ui-edit'"
                        [label]="_('Bearbeiten')"
                        [title]="_('Stationsdaten bearbeiten')"
                ></rowaction>
                <rowaction
                        *hasRole="['ROLE_ADMIN','ROLE_PROJECT_ADMIN','ROLE_BESTAND']"
                        [icon]="'me-icon me-icon-station'"
                        [label]="_('Organisation')"
                        [showLabel]="false"
                        [type]="'dropdown'"
                >
                    <list-option
                            (checkEnabled)="checkEnabled('station',$event)"
                            (perform)="editTanks($event)"
                            *hasRole="['ROLE_ADMIN','ROLE_PROJECT_ADMIN','ROLE_BESTAND']"
                            [icon]="'me-icon me-icon-tank'"
                            [label]="_('Tanks')"
                            [title]="_('vorhandene Tanks verwalten')"
                    ></list-option>
                    <list-option
                            (checkEnabled)="checkEnabled('station',$event)"
                            (perform)="editSonden($event)"
                            *hasRole="['ROLE_ADMIN','ROLE_PROJECT_ADMIN','ROLE_BESTAND']"
                            [icon]="'me-icon me-icon-sonde'"
                            [label]="_('Sonden')"
                            [title]="_('angeschlossene Sonden verwalten')"
                    ></list-option>
                    <list-option
                            (checkEnabled)="checkEnabled('station',$event)"
                            (perform)="editSaeulen($event)"
                            *hasRole="['ROLE_ADMIN','ROLE_PROJECT_ADMIN']"
                            [icon]="'me-icon me-icon-zapfsaeule'"
                            [label]="_('Säulen &amp; Zapfpunkte')"
                            [title]="_('Säulen und Zapfpunkte konfigurieren')"
                    ></list-option>
                    <list-option
                            (checkEnabled)="checkEnabled('station',$event)"
                            (perform)="editTerminals($event)"
                            *hasRole="['ROLE_ADMIN','ROLE_PROJECT_ADMIN']"
                            [icon]="'me-icon me-icon-automat'"
                            [label]="_('Terminals')"
                            [title]="_('Terminals und Kassen konfigurieren')"
                    ></list-option>
                    <list-option
                            (checkEnabled)="checkEnabled('station',$event)"
                            (perform)="editPeriphery($event)"
                            *hasRole="['ROLE_ADMIN','ROLE_PROJECT_ADMIN']"
                            [icon]="'icofont icofont-micro-chip'"
                            [label]="_('weitere Geräte & Anlagen')"
                            [title]="_('weitere Geräte und Anlagen konfiguieren')"
                    ></list-option>
                </rowaction>

                <data-table-table-extra-header>
                    <app-paginator
                            (onFilterToggle)="stationenFilterToggle($event)"
                            (pageChange)="updateStationen(true,$event)"
                            (pageCountChange)="updateStationenPageCount($event)"
                            (queryChange)="stationenQueryChange($event)"
                            [datatable]="stationenListe"
                            [filterDialog]="filterDialog"
                            [page]="stationen.page"
                            [perpage]="stationen.size"
                            [showFilterButton]="true"
                            [showSearch]="true"
                            [total]="stationen.total"
                    ></app-paginator>

                </data-table-table-extra-header>


                <data-table-filter-area>
                    <app-stationen-filter-dialog
                            #filterDialog
                            (filterChanged)="stationenQueryChange2($event)"
                    ></app-stationen-filter-dialog>
                </data-table-filter-area>
            </data-table>

        </app-pane>
    </app-tabcard>


</app-login-alert>
