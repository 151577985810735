import {DateTime} from 'luxon';
import {ChangeDetectorRef, EventEmitter} from '@angular/core';
import {Station} from '../../../stationen/common/station';
import {Kunde} from '../../../customers/kunden/common/kunde';
import {Fahrzeug} from '../../../customers/fahrzeuge/common/fahrzeug';
import {Ausweis} from '../../../customers/ausweise/common/ausweis';
import {Produkt} from '../../../products/produkte/common/produkt';
import {Land} from '../../../commons/laender/common/land';
import {ElementListComponent} from '../../../commons/element-list/element-list.component';


export class TransaktionenFilter {




    public filterChanged: EventEmitter<void> = new EventEmitter<void>();


    private _useTimeRange = false;
    private _timeFrom: Date = DateTime.local().toJSDate();
    private _timeUntil: Date = DateTime.local().toJSDate();

    private _stationen: Station[] = [];
    private _customers: Kunde[] = [];
    private _vehicles: Fahrzeug[] = [];
    private _cards: Ausweis[] = [];
    private _products: Produkt[] = [];
    private _countries: Land[] = [];
    private _freitext = '';

    get countries(): Land[] {
        return this._countries;
    }

    set countries(value: Land[]) {
        if (value !== this._countries) {
            this._countries = value;
            this.filterChanged.emit();
            this.refresh(null);
        }
    }

    get freitext(): string {
        return this._freitext;
    }

    set freitext(value: string) {
        if (value !== this._freitext) {
            this._freitext = value;
            this.filterChanged.emit();
            this.refresh(null);
        }
    }

    get products(): Produkt[] {
        return this._products;
    }

    set products(value: Produkt[]) {
        if (value !== this._products) {
            this._products = value;
            this.filterChanged.emit();
            this.refresh(null);
        }
    }

    get vehicles(): Fahrzeug[] {
        return this._vehicles;
    }

    set vehicles(value: Fahrzeug[]) {
        if (value !== this._vehicles) {
            this._vehicles = value;
            this.filterChanged.emit();
            this.refresh(null);
        }
    }

    get cards(): Ausweis[] {
        return this._cards;
    }

    set cards(value: Ausweis[]) {
        if (value !== this._cards) {
            this._cards = value;
            this.filterChanged.emit();
            this.refresh(null);
        }
    }


    get customers(): Kunde[] {
        return this._customers;
    }

    set customers(value: Kunde[]) {
        if (value !== this._customers) {
            this._customers = value;
            this.filterChanged.emit();
            this.refresh(null);
        }
    }

    get stationen(): Station[] {
        return this._stationen;
    }

    set stationen(value: Station[]) {
        if (value !== this._stationen) {
            this._stationen = value;
            this.filterChanged.emit();
            this.refresh(null);
        }
    }

    get useTimeRange(): boolean {
        return this._useTimeRange;
    }

    set useTimeRange(value: boolean) {
        if (value !== this._useTimeRange) {
            this._useTimeRange = value;
            this.filterChanged.emit();
            this.refresh(null);
        }
    }

    get timeFrom(): Date {
        return this._timeFrom;
    }

    set timeFrom(value: Date) {
        if (value !== this._timeFrom) {
            this._timeFrom = value;
            this.filterChanged.emit();
            this.refresh(null);
        }
    }

    get timeUntil(): Date {
        return this._timeUntil;
    }

    set timeUntil(value: Date) {
        if (value !== this._timeUntil) {
            this._timeUntil = value;
            this.filterChanged.emit();
            this.refresh(null);
        }
    }

    constructor(public cd: ChangeDetectorRef) {
    }

    addStation(station: Station, stationen: ElementListComponent) {
        if (station != null && station !== undefined) {
            if (!Array.isArray(this._stationen)) {
                this._stationen = [];
            }
            let found = false;
            this._stationen.forEach((s: Station) => {
                if (s.id === station.id) {
                    found = true;
                }
            });
            if (!found) {
                this._stationen.push(station);
                this.filterChanged.emit();
            }
            this.refresh(stationen);
        }
    }

    getQuery(): string {
        const parts: string[] = [];
        if (this._useTimeRange) {

            const a = DateTime.fromJSDate(this.timeFrom).toMillis();
            const b = DateTime.fromJSDate(this.timeUntil).toMillis();

            parts.push('( zeitpunkt: [' + a + ' TO ' + b + ' ] )');
        }
        if (this._stationen !== null && this._stationen !== undefined && Array.isArray(this._stationen)) {
            const c: string[] = [];
            this._stationen.forEach((s: Station) => {
                c.push('((_station_id:' + s.nummer + ') OR (station:' + s.nummer + '))');
            });
            if (c.length > 0) {
                parts.push('( ' + c.join(' OR ') + ' )');
            }
        }
        if (this._customers !== null && this._customers !== undefined && Array.isArray(this._customers)) {
            const c: string[] = [];
            this._customers.forEach((s: Kunde) => {
                c.push('((_kunde_id:' + s.id + ') OR (kunde:' + s.kundennummer + '))');
            });
            if (c.length > 0) {
                parts.push('( ' + c.join(' OR ') + ' )');
            }
        }
        if (this._cards !== null && this._cards !== undefined && Array.isArray(this._cards)) {
            const c: string[] = [];
            this._cards.forEach((s: Ausweis) => {
                c.push('((_ausweis_id:' + s.id + ') OR (ausweis:' + s.nummer + '))');
            });

            if (c.length > 0) {
                parts.push('( ' + c.join(' OR ') + ' )');
            }
        }
        if (this._countries !== null && this._countries !== undefined && Array.isArray(this._countries)) {
            const c: string[] = [];

            this._countries.forEach((s: Land) => {
                c.push('((_land_id:' + s.id + ') OR (land:' + s.laendercode + '))');
            });

            if (c.length > 0) {
                parts.push('( ' + c.join(' OR ') + ' )');
            }
        }
        if (this._vehicles !== null && this._vehicles !== undefined && Array.isArray(this._vehicles)) {
            const c: string[] = [];

            this._vehicles.forEach((s: Fahrzeug) => {
                c.push('((_fahrzeug_id:' + s.id + ') OR (fahrzeug:' + s.nummer + '))');
            });

            if (c.length > 0) {
                parts.push('( ' + c.join(' OR ') + ' )');
            }
        }
        if (this._products !== null && this._products !== undefined && Array.isArray(this._products)) {
            const c: string[] = [];

            this._products.forEach((s: Produkt) => {
                c.push('((_produkt_id:' + s.id + ') OR (produktnummer:' + s.nummer + '))');
            });

            if (c.length > 0) {
                parts.push('( ' + c.join(' OR ') + ' )');
            }
        }

        if (this._freitext !== null && this._freitext !== undefined && this._freitext.trim() !== '') {
            parts.push('(' + this._freitext + ')');
        }


        if (parts.length < 1) {
            return '';
        }
        return parts.join(' AND ');
    }


    thisMonth() {
        this._timeFrom = DateTime.local().startOf('month').toJSDate();
        this._timeUntil = DateTime.local().endOf('month').toJSDate();
        this.filterChanged.emit();
        this.refresh(null);
    }

    lastMonth() {
        this._timeFrom = DateTime.local().startOf('month').minus({months: 1}).toJSDate();
        this._timeUntil = DateTime.local().startOf('month').minus({months: 1}).endOf('month').toJSDate();
        this.filterChanged.emit();
        this.refresh(null);
    }

    today() {
        this._timeFrom = DateTime.local().startOf('day').toJSDate();
        this._timeUntil = DateTime.local().endOf('day').toJSDate();
        this.filterChanged.emit();
        this.refresh(null);
    }

    lastSevenDays() {
        this._timeFrom = DateTime.local().startOf('day').minus({days: 7}).toJSDate();
        this._timeUntil = DateTime.local().endOf('day').toJSDate();
        this.filterChanged.emit();
        this.refresh(null);
    }

    lastThreeDays() {
        this._timeFrom = DateTime.local().startOf('day').minus({days: 3}).toJSDate();
        this._timeUntil = DateTime.local().endOf('day').toJSDate();
        this.filterChanged.emit();
        this.refresh(null);
    }

    yesterday() {
        this._timeFrom = DateTime.local().startOf('day').minus({days: 1}).toJSDate();
        this._timeUntil = DateTime.local().startOf('day').minus({days: 1}).endOf('day').toJSDate();
        this.filterChanged.emit();
        this.refresh(null);
    }

    thisWeek() {
        this._timeFrom = DateTime.local().startOf('week').toJSDate();
        this._timeUntil = DateTime.local().endOf('week').toJSDate();
        this.filterChanged.emit();
        this.refresh(null);
    }

    lastWeek() {
        this._timeFrom = DateTime.local().startOf('week').minus({weeks: 1}).toJSDate();
        this._timeUntil = DateTime.local().startOf('week').minus({weeks: 1}).endOf('week').toJSDate();
        this.filterChanged.emit();
        this.refresh(null);
    }

    clearStationen(stationen: ElementListComponent) {
        this.stationen = [];
        this.refresh(stationen);
    }

    removeStation(station: Station, stationen: ElementListComponent) {
        let found = false;
        this._stationen.forEach((s, idx) => {
            if (s.id === station.id) {
                this._stationen.splice(idx, 1);
                found = true;
            }
        });
        if (found) {
            this.filterChanged.emit();
        }
        this.refresh(stationen);
    }

    clearCustomers(ec: ElementListComponent) {
        this.customers = [];
        this.refresh(ec);
    }

    addCustomer(kunde: Kunde, ec: ElementListComponent) {
        if (kunde != null && kunde !== undefined) {
            if (!Array.isArray(this._customers)) {
                this._customers = [];
            }
            let found = false;
            this._customers.forEach((s: Kunde) => {
                if (s.id === kunde.id) {
                    found = true;
                }
            });
            if (!found) {
                this._customers.push(kunde);
                this.filterChanged.emit();
            }
            this.refresh(ec);
        }
    }

    removeCustomer(kunde: Kunde, ec: ElementListComponent) {
        let found = false;
        this._customers.forEach((s, idx) => {
            if (s.id === kunde.id) {
                this._customers.splice(idx, 1);
                found = true;
            }
        });
        if (found) {
            this.filterChanged.emit();
        }
        this.refresh(ec);
    }


    clearCards(ec: ElementListComponent) {
        this.cards = [];
        this.refresh(ec);
    }

    addCard(card: Ausweis, ec: ElementListComponent) {
        if (card != null && card !== undefined) {
            if (!Array.isArray(this._cards)) {
                this._cards = [];
            }
            let found = false;
            this._cards.forEach((s: Ausweis) => {
                if (s.id === card.id) {
                    found = true;
                }
            });
            if (!found) {
                this._cards.push(card);
                this.filterChanged.emit();
            }
            this.refresh(ec);
        }
    }

    removeCard(card: Ausweis, ec: ElementListComponent) {
        let found = false;
        this._cards.forEach((s, idx) => {
            if (s.id === card.id) {
                this._cards.splice(idx, 1);
                found = true;
            }
        });
        if (found) {
            this.filterChanged.emit();
        }
        this.refresh(ec);
    }


    clearVehicles(ec: ElementListComponent) {
        this.vehicles = [];
        this.refresh(ec);
    }

    addVehicle(car: Fahrzeug, ec: ElementListComponent) {
        if (car != null && car !== undefined) {
            if (!Array.isArray(this._vehicles)) {
                this._vehicles = [];
            }
            let found = false;
            this._vehicles.forEach((s: Fahrzeug) => {
                if (s.id === car.id) {
                    found = true;
                }
            });
            if (!found) {
                this._vehicles.push(car);
                this.filterChanged.emit();
            }
            this.refresh(ec);
        }
    }

    removeVehicle(car: Fahrzeug, ec: ElementListComponent) {
        let found = false;
        this._vehicles.forEach((s, idx) => {
            if (s.id === car.id) {
                this._vehicles.splice(idx, 1);
                found = true;
            }
        });
        if (found) {
            this.filterChanged.emit();
        }
        this.refresh(ec);
    }

    clearProducts(ec: ElementListComponent) {
        this.products = [];
        this.refresh(ec);
    }

    addProduct(produkt: Produkt, ec: ElementListComponent) {
        if (produkt != null && produkt !== undefined) {
            if (!Array.isArray(this._products)) {
                this._products = [];
            }
            let found = false;
            this._products.forEach((s: Produkt) => {
                if (s.id === produkt.id) {
                    found = true;
                }
            });
            if (!found) {
                this._products.push(produkt);
                this.filterChanged.emit();
            }
            this.refresh(ec);
        }
    }

    removeProduct(produkt: Produkt, ec: ElementListComponent) {
        let found = false;
        this._products.forEach((s, idx) => {
            if (s.id === produkt.id) {
                this._products.splice(idx, 1);
                found = true;
            }
        });
        if (found) {
            this.filterChanged.emit();
        }
        this.refresh(ec);
    }

    clearCountries(ec: ElementListComponent) {
        this.countries = [];
        this.refresh(ec);
    }

    addCountry(land: Land, laender: ElementListComponent) {
        if (land != null && land !== undefined) {
            if (!Array.isArray(this._countries)) {
                this._countries = [];
            }
            let found = false;
            this._countries.forEach((s: Land) => {
                if (s.laendercode === land.laendercode) {
                    found = true;
                }
            });
            if (!found) {
                this._countries.push(land);
                this.filterChanged.emit();
            }
            this.refresh(laender);
        }
    }

    removeCountry(land: Land, ec: ElementListComponent) {
        let found = false;
        this._countries.forEach((s, idx) => {
            if (s.laendercode === land.laendercode) {
                this._countries.splice(idx, 1);
                found = true;
            }
        });
        if (found) {
            this.filterChanged.emit();
        }
        this.refresh(ec);
    }


    private refresh(ec: ElementListComponent) {
        if (this.cd !== null && this.cd !== undefined) {
            this.cd.markForCheck();
        }
        if (ec !== null && ec !== undefined) {
            ec.cd.markForCheck();
        }
    }
}
