<table cellspacing="2" cellpadding="2"
       *ngIf="_ausweis!==null && _ausweis!==undefined">
    <tr *ngIf="_ausweis.nummer>0">
        <td>{{_('Nummer')}}</td>
        <td>{{ _ausweis.nummer }}</td>
    </tr>
    <tr *ngIf="_ausweis.pan!==undefined && _ausweis.pan!==null && _ausweis.pan!=''">
        <td>{{_('Kennung/Pan')}}</td>
        <td>{{ _ausweis.pan }}</td>
    </tr>
    <tr *ngIf="_ausweis.ausweisArt!==undefined && _ausweis.ausweisArt!==null">
        <td>{{_('Ausweis-Art')}}</td>
        <td *ngIf="_ausweis.ausweisArt==0">{{ _('Unbekannter Typ/Sonstige') }}</td>
        <td *ngIf="_ausweis.ausweisArt==1">{{ _('Magnetstreifen-Karte') }}</td>
        <td *ngIf="_ausweis.ausweisArt==2">{{ _('Smartcard') }}</td>
        <td *ngIf="_ausweis.ausweisArt==3">{{ _('Chip-Transponder') }}</td>
        <td *ngIf="_ausweis.ausweisArt==4">{{ _('BT/Funk Transponder') }}</td>
        <td *ngIf="_ausweis.ausweisArt==5">{{ _('Mobiltelefon-App') }}</td>
        <td *ngIf="_ausweis.ausweisArt==6">{{ _('ME Heavy Tag') }}</td>
    </tr>
    <tr *ngIf="_ausweis.issuer!==undefined && _ausweis.issuer!==null">
        <td>{{_('Herausgeber')}}</td>
        <td>{{ _ausweis.issuer.bezeichnung }} ({{ _ausweis.issuer.nummer }})</td>
    </tr>
    <tr *ngIf="(_ausweis.assignedToDriver!==undefined && _ausweis.assignedToDriver!==null && _ausweis.assignedToDriver)||
               (_ausweis.assignedToVehicle!==undefined && _ausweis.assignedToVehicle!==null && _ausweis.assignedToVehicle)">
        <td>{{_('Zuordnungen')}}</td>
        <td >
            <span class="badge badge-default"
                  *ngIf="(_ausweis.assignedToDriver!==undefined && _ausweis.assignedToDriver!==null && _ausweis.assignedToDriver)">
                {{ _('Fahrer/Person') }}
            </span>
            <span class="badge badge-default"
                  *ngIf="(_ausweis.assignedToVehicle!==undefined && _ausweis.assignedToVehicle!==null &&  _ausweis.assignedToVehicle)">
                {{ _('Fahrzeug') }}
            </span>
        </td>
    </tr>
    <tr *ngIf="(_ausweis.adminSperre!==null && _ausweis.adminSperre!==undefined && _ausweis.adminSperre) ||
(_ausweis.kundenSperre!==null && _ausweis.kundenSperre!==undefined && _ausweis.kundenSperre)
">
        <td>{{ _('Sperrkennzeichen') }}</td>
        <td>
                <span class="badge badge-danger"
                      *ngIf="(_ausweis.adminSperre!==null && _ausweis.adminSperre!==undefined && _ausweis.adminSperre)"
                >
            {{ _('administrativ gesperrt') }}
                </span>
            <span class="badge badge-warning"
                  *ngIf="(_ausweis.kundenSperre!==null && _ausweis.kundenSperre!==undefined && _ausweis.kundenSperre)"
            >
            {{ _('vom Kunden gesperrt') }}
                </span>
        </td>
    </tr>
</table>
