import {HttpErrorResponse, HttpEvent, HttpEventType, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable, throwError} from 'rxjs';
import {PubSubService} from './pub-sub.service';
import {Router} from '@angular/router';
import {catchError, debounceTime, tap} from 'rxjs/operators';

@Injectable()
export class HttpClientInterceptor implements HttpInterceptor {


    constructor(public pubSub: PubSubService,
                public router: Router) {

    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {


        try {
            this.pubSub.beforeRequest.emit('beforeRequestEvent');
        } catch (e) {
        }

        return next.handle(req).pipe(
            debounceTime(400),
            tap(
                (event: HttpEvent<any>) => {

                    if (event.type === HttpEventType.Response) {

                        try {
                            this.pubSub.afterRequest.emit('afterRequestEvent');
                        } catch (e) {
                        }
                    }
                },
                (err: any) => {

                    try {
                        this.pubSub.afterRequest.emit('afterRequestEvent');
                    } catch (e) {
                    }

                    if (err instanceof HttpErrorResponse) {
                        if ((err.status === 401 || err.status === 403)) {
                            try {
                                this.pubSub.loginError.emit('loginErrorEvent');
                            } catch (e) {
                            }
                            try {
                                this.router.navigate(['login']);
                            } catch (e) {
                            }
                        }
                    }
                }),
            catchError(e => {
                try {
                    this.pubSub.afterRequest.emit('afterRequestEvent');
                } catch (ignored) {
                }
                return throwError(e);
            })
        );
    }

}
