import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {RouterModule, Routes} from '@angular/router';
import {TransaktionenComponent} from './transaktionen/transaktionen.component';

const routes: Routes = [
    {
      path: '',
        component: TransaktionenComponent
    }
];

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [
      RouterModule
  ]
})
export class BillingRoutingModule { }
