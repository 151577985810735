import {Subject} from 'rxjs';
import {Injectable} from '@angular/core';
import {Benutzer} from '../auth/common/benutzer';

export class RequestEventEmitter extends Subject<string> {
    constructor() {
        super();
    }

    emit(value: string = null) {
        super.next(value);
    }
}

export class ResponseEventEmitter extends Subject<string> {
    constructor() {
        super();
    }

    emit(value: string = null) {
        super.next(value);
    }
}


export class GenericEventEmitter extends Subject<any> {
    constructor() {
        super();
    }

    emit(value: any = null) {
        super.next(value);
    }
}

export class MessageChangeEventEmitter extends Subject<any> {
    constructor() {
        super();
    }

    emit(value: any = null) {
        super.next(value);
    }
}

export class LoginStateChangedEmitter extends Subject<Benutzer> {

    constructor() {
        super();
    }

    emit(value: Benutzer = null) {
        super.next(value);
    }
}

export class StateChangedEmitter extends Subject<boolean> {

    constructor() {
        super();
    }

    emit(value: boolean = false) {
        super.next(value);
    }
}

export class MessageEventEmitter extends Subject<string> {
    constructor() {
        super();
    }

    emit(value: string = null) {
        super.next(value);
    }
}


@Injectable({
                providedIn: 'root'
            })
export class PubSubService {
    beforeRequest: RequestEventEmitter = new RequestEventEmitter();
    afterRequest: ResponseEventEmitter = new RequestEventEmitter();
    loginError: GenericEventEmitter = new GenericEventEmitter();
    messageChange: MessageChangeEventEmitter = new MessageChangeEventEmitter();
    loginStateChange: LoginStateChangedEmitter = new LoginStateChangedEmitter();
    onlineStateChange: StateChangedEmitter = new StateChangedEmitter();
    loginMessageChange: MessageEventEmitter = new MessageEventEmitter();
    adminSystemMessageChange: MessageEventEmitter = new MessageEventEmitter();
    developmentModeChange: StateChangedEmitter = new StateChangedEmitter();
    sysInfoChange: GenericEventEmitter = new GenericEventEmitter();
    modulesChange: GenericEventEmitter = new GenericEventEmitter();

    constructor() {
    }
}
