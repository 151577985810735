import {ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {LocalizedComponent} from '../components/localized-component';

import {DataTableComponent} from '../data-table/data-table.component';
import {IFilterDialog} from '../data-table/ifilter-dialog';
import {KitchenTimer} from '../kitchen-timer';

export class NewPageCountEvent {
    itemsPerPage = 0;
    currentPage = 0;
}

@Component({
    selector: 'app-paginator',
    templateUrl: './paginator.component.html',
    styleUrls: ['./paginator.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PaginatorComponent extends LocalizedComponent implements OnInit {

    @ViewChild('sFeld', {static: false}) sFeld: ElementRef;

    limitPages = 5;

    totalItems = 0;
    itemsPerPage = 10;
    pagecounts: number[] = [10, 20, 25, 50, 100];
    pages: number[] = [];
    currentpage = 1;
    totalPages = 0;
    private _queryFieldVisible = false;
    private _query = '';

    sTimer: KitchenTimer;

    get queryFieldVisible(): boolean {
        return this._queryFieldVisible;
    }

    @Input()
    set queryFieldVisible(value: boolean) {
        this._queryFieldVisible = value;
        this.cd.markForCheck();
    }

    get query(): string {
        return this._query;
    }

    @Input()
    set query(value: string) {
        this._query = value;
        this.cd.markForCheck();
    }

    @Input() showSearch = false;
    @Input() showFilterButton = false;
    @Input() filterDialog: IFilterDialog = null;
    @Input() datatable: DataTableComponent = null;
    @Output() filterToggled: EventEmitter<boolean> = new EventEmitter<boolean>();

    @Input()
    set perpage(value: number) {
        this.itemsPerPage = value;
        this.recalcPages();
    }

    get perpage(): number {
        return this.itemsPerPage;
    }

    @Input()
    set page(value: number) {
        this.currentpage = value;
        this.recalcPages();
    }

    @Input()
    set total(value: number) {
        this.totalItems = value;
        this.recalcPages();
    }

    @Output() pageChange = new EventEmitter<number>();
    @Output() pageCountChange = new EventEmitter<NewPageCountEvent>();
    @Output() queryChange = new EventEmitter<string>();

    constructor(

                public cd: ChangeDetectorRef
    ) {
        super( cd);
        this.sTimer = new KitchenTimer();
        this.sTimer.subscribe(() => {
            if (this.sFeld !== null && this.sFeld !== undefined) {
                const value = (this.sFeld.nativeElement as HTMLInputElement).value;
                this.queryChange.emit(value);
            }
        });
    }

    gopage(value) {
        this.currentpage = value;
        this.pageChange.emit(this.currentpage);
        this.cd.markForCheck();
    }


    ngOnInit() {
    }

    recalcPages() {
        if ((this.totalItems < 1) || (this.itemsPerPage < 1)) {
            this.totalPages = 0;
        } else if ((this.totalItems % this.itemsPerPage) === 0) {
            this.totalPages = Math.floor(this.totalItems / this.itemsPerPage);
        } else {
            this.totalPages = Math.floor(this.totalItems / this.itemsPerPage) + 1;
        }

        if (this.currentpage > this.totalPages) {
            this.currentpage = this.totalPages;
        }

        if (this.totalPages < 2) {
            this.currentpage = 1;
            this.pages = [1];
        } else if (this.totalPages < (this.limitPages + 1)) {
            this.pages = [];
            for (let i = 1; i < (this.limitPages + 1); i++) {
                if (i <= this.totalPages) {
                    this.pages.push(i);
                }
            }
        } else {
            this.pages = [];
            if (this.currentpage < ((this.limitPages + 1) / 2)) {
                for (let i = 1; i < (this.limitPages + 1); i++) {
                    if (i <= this.totalPages) {
                        this.pages.push(i);
                    }
                }
                this.pages.push(-4);
                this.pages.push(-5);
            } else if (this.currentpage > (this.totalPages - ((this.limitPages + 1) / 2))) {
                this.pages.push(-2);
                this.pages.push(-3);
                for (let i = this.totalPages - this.limitPages; i <= this.totalPages; i++) {
                    if (i <= this.totalPages) {
                        this.pages.push(i);
                    }
                }
            } else {
                this.pages.push(-2);
                this.pages.push(-3);
                for (let i = this.currentpage - ((this.limitPages + 1) / 2);
                     i <=
                     this.currentpage +
                     ((this.limitPages + 1) / 2);
                     i++) {
                    if (i <= this.totalPages) {
                        this.pages.push(i);
                    }
                }
                this.pages.push(-4);
                this.pages.push(-5);
            }
        }
        this.cd.markForCheck();

    }

    pagesChanged(newPages: number) {
        if (newPages < 1) {
            newPages = 1;
        }
        const pos = this.itemsPerPage * this.currentpage;
        this.itemsPerPage = newPages;
        this.currentpage = Math.floor(pos / newPages) + 1;
        this.recalcPages();
        const event = new NewPageCountEvent();
        event.itemsPerPage = this.itemsPerPage;
        event.currentPage = this.currentpage;
        this.pageCountChange.emit(event);
        this.cd.markForCheck();
    }

    editSearch(event: KeyboardEvent) {
        let value = '';
        if (event.target !== undefined && event.target !== null) {
            value = (<HTMLInputElement>event.target).value;
        } else if (event.target !== undefined && event.target !== null) {
            value = (<HTMLInputElement>event.srcElement).value;
        }
        if (event.keyCode === 13) {
            this.queryChange.emit(value);
        } else {
            this.queryChange.emit(value + event.key);
        }
        // event.preventDefault();
        this.cd.markForCheck();
    }

    qChange(event: Event) {
        let value = '';
        if (event.target !== undefined && event.target !== null) {
            value = (<HTMLInputElement>event.target).value;
        } else if (event.target !== undefined && event.target !== null) {
            value = (<HTMLInputElement>event.srcElement).value;
        }
        this.queryChange.emit(value);
        // event.preventDefault();
        this.cd.markForCheck();
    }

    qSet(value = '') {
        this.queryChange.emit(value);
        this.cd.markForCheck();
    }

    toggleQuery() {
        if (this._queryFieldVisible) {
            this.qSet('');
            this._queryFieldVisible = false;
        } else {
            this._queryFieldVisible = true;
        }
        this.cd.markForCheck();
    }

    toggleFilter() {
        if (this.datatable !== null && this.datatable !== undefined) {
            this.datatable.showFilterArea = !this.datatable.showFilterArea;
            if (this.filterDialog !== null && this.filterDialog !== undefined) {
                this.filterDialog.markChanged(this.datatable.showFilterArea);
            }
            this.filterToggled.emit(this.datatable.showFilterArea);
        } else {
            this.filterToggled.emit(null);
            if (this.filterDialog !== null && this.filterDialog !== undefined) {
                this.filterDialog.markChanged(null);
            }
        }
        this.cd.markForCheck();
    }

    onKeyUp($event) {
        this.sTimer.start();
    }
}
