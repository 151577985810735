import {Component, EventEmitter, Input, NgZone, OnInit, Output} from '@angular/core';
import {Station} from '../stationen/common/station';

@Component({
    selector: 'station-field',
    template: `
        <div class="form-group row">
            <label for="{{ id }}" class="col-{{ labelwidth }} col-form-label" *ngIf="labelwidth>0">
                {{ label }}
            </label>
            <div class="col-{{ 12-labelwidth }}">
                <station-inline-field
                        id="{{ id }}"
                        [value]="_value"
                        (valueChange)="doSelect($event)"
                        [small]="small"
                        [label]="label"
                        [nullable]="nullable"
                ></station-inline-field>
            </div>
        </div>
    `,
    styles: [`:host .addon button:first-child {
        border-left: none;
        padding-left: 5px;
        padding-right: 5px;
    }
    `]
})
export class StationFieldComponent implements OnInit {

    @Input() nullable = true;

    @Input() small = false;
    @Input() label = 'Textfeld';
    @Input() id = '';
    @Input() labelwidth = 3;
    @Output() valueChange = new EventEmitter<Station>();

    constructor(public _ngZone: NgZone) {
    }

    public _value: Station = null;

    get value(): Station {
        return this._value;
    }

    @Input()
    set value(v: Station) {

        if (this._value !== v) {
            this._value = v;
            this.valueChange.emit(v);
        }
    }

    ngOnInit(): void {
    }


    doSelect(station: Station) {
        this._value = station;
        this.valueChange.emit(station);
    }


}
