import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'spalte',
  templateUrl: './spalte.component.html',
  styleUrls: ['./spalte.component.css']
})
export class SpalteComponent implements OnInit {

  @Input() breite:number=12;

  constructor() { }

  ngOnInit() {
  }

}
