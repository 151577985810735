import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Injector} from '@angular/core';
import {Ausweis} from './ausweise/common/ausweis';
import {AusweisArt} from './ausweise/common/ausweis-art';

@Component({
    selector: 'app-ausweis-element-renderer',
    template: `
        <div *ngIf="value!=null && value!==undefined">
            <i class="{{getIcon()}}"></i>
            {{getInfo()}}
            <span class="badge badge-default">{{value.nummer}}</span>
        </div>
    `,
    styles: [`
        :host div {
            display: inline-block;
        }
    `],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AusweisElementRendererComponent {


    private _value: Ausweis = null;


    get value(): Ausweis {
        return this._value;
    }

    set value(value: Ausweis) {
        this._value = value;
        this.cd.markForCheck();
    }

    constructor(public injector: Injector,
                public cd: ChangeDetectorRef
    ) {
        this.value = this.injector.get('value');
    }

    getIcon() {
        if (this._value !== null && this._value !== undefined) {
            return AusweisArt.getIcon(this._value.ausweisArt);
        }
        return 'd-none';
    }


    getInfo() {
        if (this._value === null || this._value === undefined) {
            return '';
        }
        if (this._value.info !== null && this._value.info !== 'null' && this._value.info.trim() !== '') {
            return this._value.info;
        }
        return this._value.pan;
    }
}
