import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import {
    MEHighlitePipe,
    MESelectComponent,
    MESelectItemMatcherCallback,
    MESelectItemTextCallback
} from '../../commons/dynamic-form/me-select/meselect.component';
import {PreisgruppeList} from '../common/preisgruppe-list';
import {Preisgruppe} from '../common/preisgruppe';
import {PreiseService} from '../services/preise.service';
import {FilterCallback} from '../../commons/dynamic-form/FilterCallback';
import {GenericFilter} from '../../commons/generic-filter';
import {CommonList} from '../../commons/common-list';
import {SimplePreisgruppeFilter} from '../common/simple-preisgruppe-filter';
import {IPreisgruppe} from '../common/ipreisgruppe';
import {IListResult} from '../../commons/list-result';

import {MESelectItemFormatterCallback} from '../../commons/dynamic-form/me-select/meselect-item-formatter-callback';
import {MESelectSearchEvent} from '../../commons/dynamic-form/me-select/meselect-search-event';
import CryptoES from "crypto-es";

@Component({
    selector: 'preisgruppe-inline-field',
    template: `
        <meselect
                id="{{ id }}"
                [idField]="'id'"
                [items]="preisgruppen.data"
                [asMatrix]="false"
                [placeholder]="label"
                (itemSelected)="doSelect($event)"
                (needsSearch)="doSearch($event)"
                [small]="small"
                [nullable]="nullable"
                [disabled]="disabled"
                [onFormat]="itemFormat"
                [onGetText]="itemText"
                [onMatch]="itemMatcher"
        >
        </meselect>
    `,
    styles: [`
        :host ::ng-deep meselect .red {
            color: red;
        }

        :host ::ng-deep meselect .orange {
            color: orange;
        }
    `],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PreisgruppeInlineFieldComponent implements AfterViewInit, OnInit {
    @ViewChild(MESelectComponent, {static: true}) preisgruppenMESelect: MESelectComponent;
    @Input() small = false;
    @Input() nullable = true;

    preisgruppen: PreisgruppeList = new PreisgruppeList();
    @Input() label = 'PreisgruppeFeld';
    @Input() id = '';
    @Input() maxDisplayPreisgruppeNum = 50;
    @Input() disabled = false;
    @Output() valueChange = new EventEmitter<Preisgruppe>();

    constructor(public preiseService: PreiseService,
                public cd: ChangeDetectorRef
    ) {
    }

    _value: Preisgruppe = null;

    get value(): Preisgruppe {
        return this._value;
    }

    @Input()
    set value(v: Preisgruppe) {

        if (this._value !== v) {
            this._value = v;
            if ((this.preisgruppenMESelect !== null) && (this.preisgruppenMESelect !== undefined)) {
                this.preisgruppenMESelect.value = v;
            }
            this.valueChange.emit(v);
        }
    }

    public search: FilterCallback<Preisgruppe> = (filter: GenericFilter<Preisgruppe>,
                                                  list: CommonList<Preisgruppe>,
                                                  maxDisplayItems: number,
                                                  queryString: string,
                                                  cfg: any,
                                                  selectComponent: MESelectComponent
    ) => {
        queryString = ('' + queryString).trim();
        let q = '';
        if (queryString !== '') {
            q = '(*' + queryString + '*) OR' +
                '(' + queryString + ')';
        }
        this.preiseService
            .listPreisgruppen(maxDisplayItems,
                0,
                [],
                filter as SimplePreisgruppeFilter,
                q
            )
            .subscribe(
                (c: IListResult<IPreisgruppe>) => {
                    list.populateFromListResult(c);
                    if (selectComponent !== null && selectComponent !== undefined) {
                        selectComponent.items = list.data;
                        selectComponent.cd.markForCheck();
                    }
                }
            );
    };

    ngOnInit() {
        if (this.preisgruppenMESelect !== null && this.preisgruppenMESelect !== undefined) {
            this.preisgruppenMESelect.onFormat = this.itemFormat;
            this.preisgruppenMESelect.onGetText = this.itemText;
            this.preisgruppenMESelect.onMatch = this.itemMatcher;
            this.preisgruppenMESelect.value = this._value;
        }
        if (this.id === '') {
            this.id = 'tf' + CryptoES.MD5('preisgruppefield-' + this.label + Math.random() + '-' + Math.random()).toString();
        }
        this.doSearch(new MESelectSearchEvent('', this.preisgruppenMESelect));
    }

    doSearch(value: MESelectSearchEvent) {
        this.search(null, this.preisgruppen, this.maxDisplayPreisgruppeNum, value.queryString, null, value.component);
    }

    doSelect(preisgruppe: Preisgruppe) {
        this._value = preisgruppe;
        this.valueChange.emit(preisgruppe);
        this.markChanged();
    }

    markChanged() {
        if (this.cd !== null && this.cd !== undefined) {
            this.cd.markForCheck();
        }
    }

    public itemFormat: MESelectItemFormatterCallback<Preisgruppe> = (item: Preisgruppe, search) => {
        if (item === null) {
            return '';
        }

        return MEHighlitePipe.transformString(item.title, search);
    };

    public itemText: MESelectItemTextCallback<Preisgruppe> = (item: Preisgruppe) => {
        if (item === null) {
            return '';
        }
        return item.title;
    };

    public itemMatcher: MESelectItemMatcherCallback<Preisgruppe> = (item: Preisgruppe, search) => {
        if (!search) {
            return true;
        }
        if (search.trim() === '') {
            return true;
        }
        if (!item) {
            return false;
        }
        const x = item.title;

        return (x.toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) >= 0);
    };

    isValid() {
        return (this.preisgruppen !== null && this.preisgruppen !== undefined && this.preisgruppen.data !== null && this.preisgruppen.data !== undefined);
    }

    ngAfterViewInit(): void {
        if (this.preisgruppenMESelect !== null && this.preisgruppenMESelect !== undefined) {
            this.preisgruppenMESelect.onFormat = this.itemFormat;
            this.preisgruppenMESelect.onGetText = this.itemText;
            this.preisgruppenMESelect.onMatch = this.itemMatcher;
            this.preisgruppenMESelect.value = this._value;
        }

    }

}
