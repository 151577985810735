import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {Ausweis} from '../common/ausweis';

import {LocalizedComponent} from '../../../commons/components/localized-component';
import {Issuer} from '../../../commons/issuers/common/issuer';

@Component({
    selector: 'app-ausweis-display',
    templateUrl: './ausweis-display.component.html',
    styleUrls: ['./ausweis-display.component.css']
})
export class AusweisDisplayComponent extends LocalizedComponent implements OnInit {

    get maxKmLabel() {
        return this._('max {{ differenz }} km Abstand erlaubt', {differenz: this._ausweis.maxKMDifferenz});
    }

    get maxVolumenLabel() {
        return this._('max {{ menge }} Liter pro Vorgang', {menge: this._ausweis.maxVolumen});
    }

    get kostenstelleLabel() {
        return this._('Kostenstelle: {{ kostenstelle }}', {kostenstelle: this._ausweis.kostenstelle});
    }

    get ausweisLabel() {
        return this._('Ausweis {{ nummer }}', {nummer: this._ausweis.nummer});
    }

    public _ausweis = null;

    @Input()
    set ausweis(value: Ausweis) {
        this._ausweis = Ausweis.fromResult(value);
        if (this._ausweis !== null) {
            if (this._ausweis.issuer === null || this._ausweis.issuer === undefined) {
                this._ausweis.issuer = new Issuer();
                this._ausweis.issuer.bezeichnung = this._('unbekannter Herausgeber');
            }
        }
    }

    constructor(  public cd: ChangeDetectorRef) {
        super( cd);
    }

    ngOnInit() {
    }

}
