import {Component, OnInit, Input, ChangeDetectorRef} from '@angular/core';

import {LocalizedComponent} from '../../components/localized-component';
import {Issuer} from '../common/issuer';

@Component({
  selector: 'app-issuer-quick-info',
  templateUrl: './issuer-quick-info.component.html',
  styleUrls: ['./issuer-quick-info.component.css']
})
export class IssuerQuickInfoComponent extends LocalizedComponent implements OnInit {

    _issuer: Issuer = null;

    @Input()
    set issuer(value: Issuer) {
        this._issuer = Issuer.fromResult(value);
    }

    @Input() info_position  = 'right';
    @Input() showLabel = true;

    getIcon(): string {
        return '';
    }

    getTitle() {
        if (this._issuer !== null) {
            return this._(' Herausgeber {{ nummer }} ', {nummer: this._issuer.nummer});
        }
        return '';
    }

    constructor( public cd:ChangeDetectorRef) {
        super(cd);
    }

    ngOnInit() {
    }


}
