import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {EPreisgruppeArt} from '../common/epreisgruppe-art.enum';
import {IOption, NullOption} from '../../commons/dynamic-form/select-field/ioption';
import {LocalizedComponent} from '../../commons/components/localized-component';


import {PreisgruppeArt} from '../common/preisgruppe-art';
import CryptoES from "crypto-es";

@Component({
    selector: 'preisgruppe-art-field',
    template: `
        <div class="form-group row">
            <label for="{{ id }}" class="col-{{ labelwidth }} col-form-label">
                {{ label }}
            </label>
            <div class="col-{{ 12-labelwidth }}">
                <select
                        id="{{ id }}"
                        class="form-control"
                        [value]="value"
                        (input)="setValue($event.target.value)"
                        title="{{ label }}"
                        [disabled]="disabled"
                        [class.disabled]="disabled"
                >
                    <option
                            *ngFor="let o of options"
                            value="{{ o.value }}"
                    >
                        <i [class]="'fa-fw '+o.icon"></i>
                        {{ o.label }}
                    </option>
                </select>
            </div>
        </div>
    `
})
export class PreisgruppeArtFieldComponent extends LocalizedComponent implements OnInit {
    @Input() label = 'PreisgruppeArtFeld';
    @Input() id = '';
    @Input() labelwidth = 3;
    @Input() nullable = false;
    @Input() disabled = false;
    options: IOption[] = [];
    @Output() valueChange = new EventEmitter<EPreisgruppeArt>();

    constructor(
        public cd: ChangeDetectorRef) {
        super(cd);
        this.options = PreisgruppeArt.options((key) => {
            return this.t(key);
        });

    }

    public _value: EPreisgruppeArt = EPreisgruppeArt.UNKNOWN;

    get value(): EPreisgruppeArt {
        return this._value;
    }

    @Input()
    set value(v: EPreisgruppeArt) {
        if (this._value !== v) {
            this._value = v;
            if (!this.disabled) {
                this.valueChange.emit(v);
            }
        }
    }

    ngOnInit() {
        if (this.nullable) {
            this.options.unshift(NullOption);
        }
        if (this.id === '') {
            this.id = 'tf' + CryptoES.MD5('PreisgruppeArtfield-' + this.label + Math.random() + '-' + Math.random()).toString();
        }
    }


    setValue(n: any) {
        this.value = n;
    }

}
