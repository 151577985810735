import {Directive, Input} from '@angular/core';

@Directive({
  selector: 'formfield'
})
export class FormFieldDirective {

  @Input() type = 'text';
  @Input() field = '';
  @Input() label = '';

  constructor() { }

}
