import {ChangeDetectorRef, Component, NgZone, OnInit} from '@angular/core';

import {RechnungList} from './common/rechnung-list';
import {RechnungService} from './common/rechnung.service';
import {RechnungEditorComponent} from './editor/rechnung-editor.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {RechnungInfoPopupComponent} from './display/infopopup/rechnung-info-popup.component';
import {Rechnung} from './common/rechnung';
import {LocalizedComponent} from '../../commons/components/localized-component';
import {DataTableFilterChanged, DataTableSortChanged} from '../../commons/data-table/data-table.component';
import {NewPageCountEvent} from '../../commons/paginator/paginator.component';
import {PreisWertCellRendererComponent} from '../../commons/data-table/renderer/preis-wert-cell-renderer.component';
import {KitchenTimer} from '../../commons/kitchen-timer';
import {ResultMetaSortImpl} from '../../commons/result-meta';
import {KundeCellRendererComponent} from '../../customers/kunden/kunde-cell-renderer.component';
import {RechnungStatusCellRendererComponent} from './rechnung-status-cell-renderer.component';
import {AttachmentsCellRendererComponent} from '../attachments/controls/attachments-cell-renderer.component';
import {SysInfoService} from '../../commons/sysinfo/services/sys-info.service';
import {ToastrService} from 'ngx-toastr';
import {IListResult} from '../../commons/list-result';
import {IRechnung} from './common/irechnung';
import {LoginService} from '../../auth/services/login.service';
import {RowActionParams} from '../../commons/data-table/row-action-params';

@Component({
               selector   : 'app-rechnungen',
               templateUrl: './rechnungen.component.html',
               styleUrls  : [ './rechnungen.component.css' ]
           })
export class RechnungenComponent extends LocalizedComponent implements OnInit {

    public preiswert                     = PreisWertCellRendererComponent;
    kundeR = KundeCellRendererComponent;
    reStR = RechnungStatusCellRendererComponent;
    attachmentsR = AttachmentsCellRendererComponent;

           rechnungen: RechnungList      = new RechnungList();
           rechnungenTimer: KitchenTimer = new KitchenTimer();

    constructor(

                public rechnungService: RechnungService,
                public toastr: ToastrService,
                public zone: NgZone,
                public modalService: NgbModal,
                public sysInfoService: SysInfoService,
                public loginService: LoginService,
                public cd: ChangeDetectorRef) {
        super( cd);
        this.rechnungen.size = this.loginService.getPageSize('rechnungen');
        this.rechnungenTimer = new KitchenTimer();
        this.rechnungenTimer
            .subscribe(
                () => {
                    this.rechnungService
                        .list(this.rechnungen.size,
                              this.rechnungen.calcOffset(),
                              this.rechnungen.order,
                              this.rechnungen.simpleFilter
                        ).subscribe(
                        (l: IListResult<IRechnung>) => {
                            this.rechnungen.populateFromListResult(l);
                        },
                        (e) => {
                            this.toastr.error(this._('Die Liste der Rechnungen konnte nicht geladen werden.'));
                            this.rechnungen.clear();
                        }
                    );
                }
            );
    }

    ngOnInit() {
        this.updateRechnungen();
        this.sysInfoService.updateDocumentTitle(this._('Rechnungen'));
    }


    updateRechnungen(force = false, page?: number) {
        if ((page !== undefined) && (page !== null)) {
            if (page !== this.rechnungen.page) {
                this.rechnungen.page = page;
                force                = true;
            }
        }
        this.rechnungenTimer.start(force);
    }

    updateRechnungenPageCount(n: NewPageCountEvent) {
        if (this.rechnungen.size !== n.itemsPerPage) {
            this.rechnungen.size = n.itemsPerPage;
            this.loginService.setPageSize('rechnungen', n.itemsPerPage);
        }
        this.updateRechnungen(true, n.currentPage);
    }


    sortChanged(e: DataTableSortChanged) {
        this.rechnungen.order = [
            new ResultMetaSortImpl(e.field, e.direction)
        ];
        this.updateRechnungen();
    }

    filterChanged(e: DataTableFilterChanged) {
        this.zone.run(() => {
            this.rechnungen.simpleFilter[ e.field ] = e.value;
            this.updateRechnungen();
        });
    }

    showDetails(row: RowActionParams<Rechnung>) {
        RechnungInfoPopupComponent
            .open(this.modalService, row.row.data as Rechnung)
            .then(() => {
                this.updateRechnungen();
            }, () => {
                this.updateRechnungen();
            })
        ;
    }

    newInvoice() {
        RechnungEditorComponent
            .open(this.modalService)
            .then(() => {
                this.updateRechnungen();
            }, () => {
                this.updateRechnungen();
            });

    }

    editRechnung(row: RowActionParams<Rechnung>) {
        RechnungEditorComponent
            .open(this.modalService, row.row.data as Rechnung)
            .then(() => {
                this.updateRechnungen();
            }, () => {
                this.updateRechnungen();
            });
    }


}
