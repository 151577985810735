import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'data-table-extra-table-action',
    template: '<ng-content></ng-content>'
})
export class DataTableExtraTableActionComponent implements OnInit {

    constructor() {
    }

    ngOnInit() {
    }

}
