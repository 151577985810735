import {DateTime} from "luxon";
import {Station} from '../../../stationen/common/station';
import {Kunde} from '../../../customers/kunden/common/kunde';
import {Ausweis} from '../../../customers/ausweise/common/ausweis';
import {Land} from '../../../commons/laender/common/land';
import {Fahrzeug} from '../../../customers/fahrzeuge/common/fahrzeug';
import {Produkt} from '../../../products/produkte/common/produkt';
import {EventEmitter} from '@angular/core';

export class TankbestandFilter {
        public filterChanged: EventEmitter<void> = new EventEmitter<void>();

     private _useTimeRange = false;
    private _timeFrom: Date = DateTime.local().toJSDate();
    private _timeUntil: Date = DateTime.local().toJSDate();

        private _stationen: Station[] = [];
    private _products: Produkt[] = [];
    private _freitext = '';

      get freitext(): string {
        return this._freitext;
    }

    set freitext(value: string) {
        if (value !== this._freitext) {
            this._freitext = value;
            this.filterChanged.emit();
        }
    }

  get products(): Produkt[] {
        return this._products;
    }

    set products(value: Produkt[]) {
        if (value !== this._products) {
            this._products = value;
            this.filterChanged.emit();
        }
    }
    get stationen(): Station[] {
        return this._stationen;
    }

    set stationen(value: Station[]) {
        if (value !== this._stationen) {
            this._stationen = value;
            this.filterChanged.emit();
        }
    }

    get useTimeRange(): boolean {
        return this._useTimeRange;
    }

    set useTimeRange(value: boolean) {
        if (value !== this._useTimeRange) {
            this._useTimeRange = value;
            this.filterChanged.emit();
        }
    }

    get timeFrom(): Date {
        return this._timeFrom;
    }

    set timeFrom(value: Date) {
        if (value !== this._timeFrom) {
            this._timeFrom = value;
            this.filterChanged.emit();
        }
    }

    get timeUntil(): Date {
        return this._timeUntil;
    }

    set timeUntil(value: Date) {
        if (value !== this._timeUntil) {
            this._timeUntil = value;
            this.filterChanged.emit();
        }
    }

    constructor() {

    }

    getQuery(): string {
        const parts: string[] = [];
        if (this._useTimeRange) {

            const a = DateTime.fromJSDate(this.timeFrom).toMillis();
            const b = DateTime.fromJSDate(this.timeUntil).toMillis();

            parts.push('( zeitpunkt: [' + a + ' TO ' + b + ' ] )');
        }
        if (this._stationen !== null && this._stationen !== undefined && Array.isArray(this._stationen)) {
            const c: string[] = [];
            this._stationen.forEach((s: Station) => {
                c.push('((_station_id:' + s.nummer + ') OR (station:' + s.nummer + '))');
            });

            if (c.length > 0) {
                parts.push('( ' + c.join(' OR ') + ' )');
            }
        }
        if (this._products !== null && this._products !== undefined && Array.isArray(this._products)) {
            const c: string[] = [];
            this._products.forEach((s: Produkt) => {
                c.push('((_produkt_id:' + s.id + ') OR (produktnummer:' + s.nummer + '))');
            });

            if (c.length > 0) {
                parts.push('( ' + c.join(' OR ') + ' )');
            }
        }

        if (this._freitext !== null && this._freitext !== undefined && this._freitext.trim() !== '') {
            parts.push('(' + this._freitext + ')');
        }


        if (parts.length < 1) {
            return '';
        }
        return parts.join(' AND ');
    }


    thisMonth() {
        this._timeFrom = DateTime.local().startOf('month').toJSDate();
        this._timeUntil = DateTime.local().endOf('month').toJSDate();
        this.filterChanged.emit();
    }

    lastMonth() {
        this._timeFrom = DateTime.local().startOf('month').minus({months: 1}).toJSDate();
        this._timeUntil = DateTime.local().startOf('month').minus({months: 1}).endOf('month').toJSDate();
        this.filterChanged.emit();
    }

    today() {
        this._timeFrom = DateTime.local().startOf('day').toJSDate();
        this._timeUntil = DateTime.local().endOf('day').toJSDate();
        this.filterChanged.emit();
    }

    lastSevenDays() {
        this._timeFrom = DateTime.local().startOf('day').minus({days: 7}).toJSDate();
        this._timeUntil = DateTime.local().endOf('day').toJSDate();
        this.filterChanged.emit();
    }

    lastThreeDays() {
        this._timeFrom = DateTime.local().startOf('day').minus({days: 3}).toJSDate();
        this._timeUntil = DateTime.local().endOf('day').toJSDate();
        this.filterChanged.emit();
    }

    yesterday() {
        this._timeFrom = DateTime.local().startOf('day').minus({days: 1}).toJSDate();
        this._timeUntil = DateTime.local().startOf('day').minus({days: 1}).endOf('day').toJSDate();
        this.filterChanged.emit();
    }

    thisWeek() {
        this._timeFrom = DateTime.local().startOf('week').toJSDate();
        this._timeUntil = DateTime.local().endOf('week').toJSDate();
        this.filterChanged.emit();
    }

    lastWeek() {
        this._timeFrom = DateTime.local().startOf('week').minus({weeks: 1}).toJSDate();
        this._timeUntil = DateTime.local().startOf('week').minus({weeks: 1}).endOf('week').toJSDate();
        this.filterChanged.emit();
    }

    clearStationen() {
        this.stationen = [];
    }

    addStation(station: Station) {
        if (station != null && station !== undefined) {
            if (!Array.isArray(this._stationen)) {
                this._stationen = [];
            }
            let found = false;
            this._stationen.forEach((s: Station) => {
                if (s.id === station.id) {
                    found = true;
                }
            });
            if (!found) {
                this._stationen.push(station);
                this.filterChanged.emit();
            }
        }
    }

    removeStation(station: Station) {
        let found = false;
        this._stationen.forEach((s, idx) => {
            if (s.id === station.id) {
                this._stationen.splice(idx, 1);
                found = true;
            }
        });
        if (found) {
            this.filterChanged.emit();
        }
    }

    clearProducts() {
        this.products = [];
    }

    addProduct(produkt: Produkt) {
        if (produkt != null && produkt !== undefined) {
            if (!Array.isArray(this._products)) {
                this._products = [];
            }
            let found = false;
            this._products.forEach((s: Produkt) => {
                if (s.id === produkt.id) {
                    found = true;
                }
            });
            if (!found) {
                this._products.push(produkt);
                this.filterChanged.emit();
            }
        }
    }

    removeProduct(produkt: Produkt) {
        let found = false;
        this._products.forEach((s, idx) => {
            if (s.id === produkt.id) {
                this._products.splice(idx, 1);
                found = true;
            }
        });
        if (found) {
            this.filterChanged.emit();
        }
    }

}
