import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {LocalizedComponent} from '../../commons/components/localized-component';

import {Geraet} from '../common/geraet';

@Component({
    selector: 'app-device-video-control-details',
    templateUrl: './device-video-control-details.component.html',
    styleUrls: ['./device-video-control-details.component.scss']
})
export class DeviceVideoControlDetailsComponent extends LocalizedComponent implements OnInit {

    private _device: Geraet = null;

    @Input()
    set device(dev: Geraet) {
        if (this._device !== dev) {
            this._device = dev;
            this.cd.markForCheck();
        }
    }


    get device(): Geraet {
        return this._device;
    }

    @Output()
    onAction: EventEmitter<string> = new EventEmitter<string>();


    constructor(

                public cd: ChangeDetectorRef) {
        super( cd);
    }

    ngOnInit() {
    }

    sendAction(action: string) {
        this.onAction.emit(action);
    }

    sendConfig() {

    }
}
