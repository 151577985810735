<app-navigation></app-navigation>

<main id="wrapper">

    <app-sidebar *appIsLoggedIn id="sidebar-wrapper"></app-sidebar>

    <div id="page-content-wrapper">
        <div class="container-fluid">
            <div class="row">
                <div class="col-lg-12">
                    <div *ngIf="hasAdminMessage()" class="alert alert-info mb-3">{{ adminMessage }}</div>
                    <router-outlet></router-outlet>
                </div>
            </div>
        </div>
    </div>

</main>
