import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    NgZone,
    OnInit,
    Output
} from '@angular/core';
import CryptoES from "crypto-es";


@Component({
    selector: 'date-time-field',
    template: `
                   <div class="form-group row">
                       <label class="col-{{ labelwidth }} col-form-label" *ngIf="labelwidth>0">
                           {{ label }}
                       </label>
                       <div class="col-{{ 12-labelwidth }}">


                           <medate-time-picker
                                   #rangeStartInput
                                   [ngModel]="_value"
                                   (ngModelChange)="doSelect($event)"
                                   [inputVisible]="true"
                                   [disabled]="disabled"
                                   [panelRight]="false"
                                   [showFooter]="false"
                                   [hasSeconds]="hasSeconds"
                                   [dateOnly]="dateOnly"
                                   [timeOnly]="timeOnly"
                                   [name]="'filter1'+label"
                                   [size]="size"
                                   [displayFormat]="displayFormat"
                                   [nullable]="nullable"
                           ></medate-time-picker>


                       </div>
                   </div>

               `,
    styles: [`

                              :host .addon button:first-child {
                                  border-left: none;
                                  padding-left: 5px;
                                  padding-right: 5px;
                              }

                          `],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DateTimeFieldComponent implements OnInit {

    size = null;

    public _value: Date = null;

    @Input() displayFormat = 'LLL';

    @Input() label = 'Textfeld';
    @Input() id = '';
    @Input() labelwidth = 3;

    @Input() dateOnly = false;
    @Input() timeOnly = false;
    @Input() disabled = false;
    @Input() hasSeconds = false;
    @Input() nullable = true;


    @Input()
    set value(v: Date) {

        if (this._value !== v) {
            this._value = v;
            this.valueChange.emit(v);
        }
        this.cd.markForCheck();
    }

    @Output() valueChange = new EventEmitter<Date>();


    get value(): Date {
        return this._value;
    }

    constructor(public _ngZone: NgZone, private cd: ChangeDetectorRef) {
    }

    ngOnInit() {
        if (this.id === '') {
            this.id = 'tf' + CryptoES.MD5('textfield-' + this.label + Math.random() + '-' + Math.random()).toString();
        }
        this.cd.markForCheck();
    }


    doSelect(date: Date) {
        this._value = date;
        this.valueChange.emit(date);
        this.cd.markForCheck();
    }


}
