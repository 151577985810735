import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output
} from '@angular/core';
import {LocalizedComponent} from '../commons/components/localized-component';
import {ELaufzeitErfassung} from './fahrzeuge/common/elaufzeit-erfassung.enum';
import {LaufzeitErfassung} from './fahrzeuge/common/laufzeit-erfassung';
import {IOption, NullOption} from '../commons/dynamic-form/select-field/ioption';
import CryptoES from "crypto-es";


@Component({
    selector: 'laufzeiterfassung-field',
    template: `
        <div class="form-group row">
            <label for="{{ id }}" class="col-{{ labelwidth }} col-form-label">
                {{ label }}
            </label>
            <div class="col-{{ 12-labelwidth }}">

                <select
                        id="{{ id }}"
                        class="form-control"
                        [value]="value"
                        (input)="updateValue($event)"
                        title="{{ label }}"
                >
                    <option
                            *ngFor="let o of options"
                            value="{{ o.value }}"
                    >{{ o.label }}
                    </option>
                </select>
            </div>
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush

})
export class LaufzeiterfassungFieldComponent extends LocalizedComponent implements OnInit {
    @Input('label') label = 'Laufzeiterfassung-Feld';
    @Input('id') id = '';
    @Input('labelwidth') labelwidth = 3;
    @Input('nullable') nullable = false;
    options: IOption[] = LaufzeitErfassung.options((key) => {
        return this.t(key);
    });
    @Output() valueChange = new EventEmitter<ELaufzeitErfassung>();

    constructor(
        public cd: ChangeDetectorRef) {
        super(cd);
    }

    public _value: ELaufzeitErfassung = ELaufzeitErfassung.UNKNOWN;

    get value(): ELaufzeitErfassung {
        return this._value;
    }

    @Input('value')
    set value(v: ELaufzeitErfassung) {
        if (this._value !== v) {
            this._value = v;
            this.valueChange.emit(v);
            this.cd.markForCheck();
        }
    }

    ngOnInit() {
        if (this.nullable) {
            this.options.unshift(NullOption);
        }
        if (this.id === '') {
            this.id = 'tf' + CryptoES.MD5('laufzeiterfassung-' + this.label + Math.random() + '-' + Math.random()).toString();
        }
        this.cd.markForCheck();
    }

    updateValue($event: any) {
        this.value = $event.target.value;
    }

}
