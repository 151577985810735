<div class="text-center"
     style="position:fixed;top:45px;left: 0;right:0;bottom:0;height: 100%;display: flex;align-items: center;justify-content: center;">
    <div class="card logincard">
        <h4 class="card-header">{{ _("Login erforderlich") }}</h4>
        <div class="card-body">

            <div class="card-text">

                <ngb-alert type="warning" [dismissible]="false" class="text-left" *ngIf="hasLoginMessage()">
                    <span [innerHTML]='_(loginMessage)'></span>
                </ngb-alert>


                <ngb-alert type="info" [dismissible]="false">
                    <span [innerHTML]='_("Um fortzufahren, müssen Sie sich zunächst am System anmelden.")'></span>
                </ngb-alert>
                <form #loginForm="ngForm">
                    <div>
                        <div class="form-group">
                            <div class="input-group" title='{{ _("Benutzername") }}'>
                                <div class="input-group-prepend" id="user-addon1">
                                     <span class="input-group-text">
                                    <i class="icofont icofont-ui-user"></i>
                                     </span>
                                </div>
                                <input
                                        aria-describedby="user-addon1"
                                        type="text"
                                        class="form-control"
                                        id="userNameInput"
                                        placeholder='{{ _("Benutzername") }}...'
                                        [(ngModel)]="benutzer.username"
                                        name="username"
                                        (keypress)="unamekp($event)"
                                >
                            </div>
                        </div>
                        <div class="form-group">

                            <div class="input-group" title='{{ _("Kennwort") }}'>
                                <div class="input-group-prepend" id="pw-addon1">
                                     <span class="input-group-text">
                                    <i class="icofont icofont-ui-password"></i>
                                     </span>
                                </div>
                                <input type="password" class="form-control" aria-describedby="pw-addon1"
                                       id="passwordInput"
                                       placeholder='{{ _("Kennwort") }}...'
                                       [(ngModel)]="benutzer.password"
                                       name="password"
                                       (keypress)="loginkp($event)"
                       #pwinput

                                >
                            </div>
                        </div>
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" name="rememberme" [(ngModel)]="rememberme"
                                   id="rememberme">
                            <label class="form-check-label" for="rememberme">
                                {{ _("beim nächsten Aufruf automatisch einloggen")}}
                            </label>
                        </div>
                        <div class="d-flex justify-content-between mt-3">
                            <div class="text-left">
                                <a class="btn btn-outline-light" (click)="selectLanguage('de')">
                                    <span class="flag-icon flag-icon-de flag-icon-squared"></span>
                                </a>
                                <a class="btn btn-outline-light" (click)="selectLanguage('us')" *ngIf="false">
                                    <span class="flag-icon flag-icon-us flag-icon-squared"></span>
                                </a>
                                <a class="btn btn-outline-light" (click)="selectLanguage('pl')" *ngIf="false">
                                    <span class="flag-icon flag-icon-pl flag-icon-squared"></span>
                                </a>
                                <a class="btn btn-outline-light" (click)="selectLanguage('tr')" *ngIf="false">
                                    <span class="flag-icon flag-icon-tr flag-icon-squared"></span>
                                </a>
                            </div>
                        <div class="text-right">
                            <button type="button" class="btn btn-primary" [disabled]="submitted" (click)="doLogin()">
                                <i class="icofont icofont-login"></i> {{ _("Anmelden") }}
                            </button>
                        </div>
                    </div>
                    </div>

                </form>
            </div>
        </div>
    </div>
</div>

