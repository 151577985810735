import {CommonList} from '../common-list';
import {Anrede} from './anrede';
import {SimpleAnredeFilter} from './simple-anrede-filter';
import {IListResult} from '../list-result';
import {IAnrede} from './ianrede';
import {ResultMetaSortImpl} from '../result-meta';

export class AnredeList extends CommonList<Anrede> {
    public simpleFilter: SimpleAnredeFilter = new SimpleAnredeFilter();

    static fromListResult(res: IListResult<IAnrede>): AnredeList {
        const newList: AnredeList = new AnredeList();
        newList.clear();
        newList.simpleFilter.clear();

        if (res.meta) {
            newList.total = res.meta.total || 0;
            newList.offset = res.meta.offset || 0;
            newList.size = res.meta.size || 0;
            newList.page = res.meta.page || 0;
            if (res.meta.order) {
                newList.order = [];
                for (const x of res.meta.order) {
                    newList.order.push(
                        new ResultMetaSortImpl(
                            x.field || 'anrede',
                            x.direction || 'asc'
                        )
                    );
                }
            }
        }
        if (res.data) {
            for (const row of res.data) {
                newList.add(Anrede.fromResult(row));
            }
        }
        if (res.simpleFilter) {
            const d: Anrede = new Anrede();
            for (const k of Object.keys(d)) {
                newList.simpleFilter[k] = (res.simpleFilter[k] !== undefined) ? res.simpleFilter[k] : null;
            }
        }

        return newList;

    }


}
