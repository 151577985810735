import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output
} from '@angular/core';
import {ProduktGruppe} from './produktgruppen/common/produkt-gruppe';
import {ProdukteService} from './produkte.service';

@Component({
               selector       : 'produkt-gruppe-field',
               template       : `
                   <div class="form-group row">
                       <label for="{{ id }}" class="col-{{ labelwidth }} col-form-label" *ngIf="labelwidth>0">
                           {{ label }}
                       </label>
                       <div class="col-{{ 12-labelwidth }}">
                           <produkt-gruppe-inline-field
                                   id="{{ id }}"
                                   [label]="label"
                                   [value]="_value"
                                   (valueChange)="doSelect($event)"
                                   [small]="small"
                                   [nullable]="nullable"
                           ></produkt-gruppe-inline-field>
                       </div>
                   </div>
               `,
               changeDetection: ChangeDetectionStrategy.OnPush
           })
export class ProduktGruppeFieldComponent implements OnInit {
    @Input() nullable = true;
    @Input() small = false;
    public _value: ProduktGruppe = null;
    @Input() label = 'Produktgruppefeld';
    @Input() id = '';
    @Input() labelwidth = 3;

    @Input()
    set value(v: ProduktGruppe) {
        if (this._value !== v) {
            this._value = v;
            this.valueChange.emit(v);
            this.cd.markForCheck()
        }
    }

    @Output() valueChange = new EventEmitter<ProduktGruppe>();


    get value(): ProduktGruppe {
        return this._value;
    }

    constructor(public produkteService: ProdukteService, public cd: ChangeDetectorRef) {
    }

    ngOnInit() {
    }

    doSelect(produktGruppe: ProduktGruppe) {
        this._value = produktGruppe;
        this.valueChange.emit(produktGruppe);
        this.cd.markForCheck();
    }


}
