<app-login-alert>


    <app-area-heading
            [icon]="'icofont-food-basket'"
            title="{{ _('Produkte') }}"
    ></app-area-heading>


    <app-tabcard>
        <app-pane title="{{ _('systemweite Einheiten') }}" *appIsAdmin>
            <app-tab-einheiten></app-tab-einheiten>
        </app-pane>

        <app-pane title="{{ _('systemweite Artikel') }}" *appIsAdmin>
            <app-tab-artikel></app-tab-artikel>
        </app-pane>
        <app-pane [title]="_('Artikelgruppen')" *appIsProjectAdmin>
            <app-tab-artikel-gruppen></app-tab-artikel-gruppen>
        </app-pane>
        <app-pane [title]="_('Produkte')" *appIsProjectAdmin>
            <app-tab-produkte></app-tab-produkte>
        </app-pane>
        <app-pane [title]="_('Produktgruppen')" *appIsAdmin>
            <app-tab-produkt-gruppen></app-tab-produkt-gruppen>
        </app-pane>
    </app-tabcard>


</app-login-alert>