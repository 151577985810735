import {ChangeDetectorRef, Component, NgZone, OnInit, ViewChild} from '@angular/core';
import {LocalizedComponent} from '../../commons/components/localized-component';
import {PreisEditorComponent} from '../preis-editor/preis-editor.component';
import {IPreis} from '../common/ipreis';
import {ResultMetaSortImpl} from '../../commons/result-meta';

import {NewPageCountEvent} from '../../commons/paginator/paginator.component';
import {TerminalCellRendererComponent} from '../../stationen/terminal-cell-renderer.component';
import {ToastrService} from 'ngx-toastr';
import {PreisWertCellRendererComponent} from '../../commons/data-table/renderer/preis-wert-cell-renderer.component';
import {PreiseService} from '../services/preise.service';
import {ProduktCellRendererComponent} from '../../products/produkt-cell-renderer.component';
import {KitchenTimer} from '../../commons/kitchen-timer';
import {DataTableComponent, DataTableFilterChanged, DataTableSortChanged} from '../../commons/data-table/data-table.component';
import {StationCellRendererComponent} from '../../stationen/station-cell-renderer.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {SysInfoService} from '../../commons/sysinfo/services/sys-info.service';
import {IListResult} from '../../commons/list-result';
import {PreisList} from '../common/preis-list';
import {TransaktionenFilterdialogComponent} from '../../billing/transaktionen/transaktionen-filterdialog/transaktionen-filterdialog.component';
import {PreiseFilterDialogComponent} from './preise-filter-dialog/preise-filter-dialog.component';
import {LoginService} from '../../auth/services/login.service';
import {ConfirmDialogComponent} from '../../commons/confirm-dialog/confirm-dialog.component';
import {noop} from '../../commons/noop';
import * as FileSaver from 'file-saver';

@Component({
    selector: 'app-tab-preise',
    templateUrl: './tab-preise.component.html',
    styleUrls: ['./tab-preise.component.scss']
})
export class TabPreiseComponent extends LocalizedComponent implements OnInit {

    preiswert = PreisWertCellRendererComponent;
    stationR = StationCellRendererComponent;
    terminalR = TerminalCellRendererComponent;
    produktR = ProduktCellRendererComponent;

    preise: PreisList = new PreisList();
    public preiseTimer: KitchenTimer;

    @ViewChild('preisListe',{static: true}) preisListe: DataTableComponent;
    @ViewChild('filterDialog',{static: true}) filterDialog: PreiseFilterDialogComponent;

    constructor(
                
                public preiseService: PreiseService,
                public toastr: ToastrService,
                public modalService: NgbModal,
                public zone: NgZone,
                public sysInfoService: SysInfoService,
                public cd: ChangeDetectorRef,
                public loginService: LoginService
    ) {

        super( cd);

        this.preise.size = this.loginService.getPageSize('preise');

        this.preiseTimer = new KitchenTimer();
        this.preiseTimer.subscribe(
            () => {
                this.preiseService
                    .list(
                        this.preise.size,
                        this.preise.calcOffset(),
                        this.preise.order,
                        this.preise.simpleFilter,
                        this.preise.getQuery()
                    )
                    .subscribe(
                        (l: IListResult<IPreis>) => {
                            this.preise.populateFromListResult(l);
                        },
                        (e) => {
                            this.toastr.error(this._('Die Liste der Preise konnte nicht geladen werden.'));
                            this.preise.clear();
                        }
                    );
            }
        );
    }

    /**
     *
     */
    ngOnInit() {
        this.updatePreise();
    }

    /**
     * lädt die Stationenliste neu
     * @param force - sofort neuladen
     * @param page - Seitennummer ändern
     */
    updatePreise(force = false, page?: number) {
        if ((page !== undefined) && (page != null)) {
            if (page !== this.preise.page) {
                this.preise.page = page;
                force = true;
            }
        }
        this.preiseTimer.start(force);
    }

    /**
     * ändert die Anzahl der Datensätze/Seite und lädt die Liste neu
     * @param n
     */
    updatePreisePageCount(n: NewPageCountEvent) {
        if (this.preise.size !== n.itemsPerPage) {
            this.preise.size = n.itemsPerPage;
            this.loginService.setPageSize('preise', n.itemsPerPage);
        }
        this.updatePreise(true, n.currentPage);
    }

    newPreis() {
        PreisEditorComponent
            .open(this.modalService)
            .then(
                e => {
                    this.updatePreise(true);
                },
                () => {
                    this.updatePreise(true);
                }
            );
    }

    sortChanged(e: DataTableSortChanged) {
        this.preise.order = [
            new ResultMetaSortImpl(e.field, e.direction)
        ];
        this.updatePreise();
    }

    filterChanged(e: DataTableFilterChanged) {
        this.zone.run(() => {
            this.preise.simpleFilter[e.field] = e.value;
            this.updatePreise();
        });
    }

    reindexPreise() {
        this.preiseService
            .reIndexPreise()
            .subscribe(
                () => {
                    this.toastr.success(this._('Der Indizierungsvorgang wurde angestoßen.'));
                },
                () => {
                    this.toastr.error(this._('Die Indizierungsvorgang konnte nicht angestoßen werden.'));
                }
            );
    }

    preiseQueryChange(value: string) {
         if (value !== this.preise.searchString) {
            this.preise.searchString = value;
            this.updatePreise(false, 1);
        }
    }


    preiseFilterToggle(visible: boolean) {
         if (!visible) {
            this.preiseQueryChange2('');
        }
    }

    preiseQueryChange2(value: string) {
       if (value !== this.preise.searchString2) {
            this.preise.searchString2 = value;
            this.updatePreise(false, 1);
        }
    }

    downloadPreise(type = 'csv', exporttype = 0) {
 if (this.preise.total < 1) {
            this.toastr.error(this._(
                'Es werden keine Datensätze angezeigt, daher gibt es auch nichts zum Herunterladen.'));
        } else if (this.preise.total < 500) {
            this.realDlPreise(type, exporttype);
        } else if (this.preise.total > 25000) {
            this.toastr.error(
                this._('Sie wollen {{ anzahl }} Datensätze herunterladen. Da es erfahrungsgemäß ' +
                    'hierbei zu Downloadabbrüchen seitens des Webbrowsers kommt, bitten wir Sie,' +
                    'die Ergebnismenge zunächst einzugrenzen.', {anzahl: this.preise.total}),
                this._('Aktion nicht möglich'),
                {
                    timeOut: 15000
                }
            );
        } else {
            ConfirmDialogComponent
                .display(
                    this.modalService,
                    this._('Download durchführen?'),
                    this._('Sie wollen {{ anzahl }} Datensätze herunterladen. Dies wird unter Umständen ' +
                        'eine längere Zeit dauern und je nach Browser zu einem Verbindungsabbruch führen. <br /><br /> ' +
                        'Wir empfehlen, die Liste zunnächst weiter einzuschränken.',
                        {anzahl: this.preise.total}
                    ),
                    this._('Download trotzdem durchführen'),
                    'icofont icofont-download-alt'
                )
                .result
                .then(
                    () => {
                        this.realDlPreise(type, exporttype);
                    },
                    noop
                );
        }

}

    private realDlPreise(type: string, exporttype: number) {

        this.toastr
            .info(this._('Download wird angefordert. Bitte einen Moment Geduld...'),
                this._('Aktion wird ausgeführt...'),
                {
                    timeOut: 15000
                }
            )
            .onShown
            .subscribe(
                () => {
                    this.preiseService.exportData(
                        type,
                        this.preise.simpleFilter,
                        this.preise.getQuery(),
                        exporttype
                    ).subscribe(
                        (response: Blob) => {
                            FileSaver.saveAs(response, this._('preise.' + type));
                        },
                        (e) => {
                            this.toastr.error(this._('Die Liste der Preise konnte nicht exportiert werden.'));
                        }
                    );
                });


    }
}
