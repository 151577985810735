import {Component, ComponentFactoryResolver, Input, ReflectiveInjector, ViewContainerRef} from '@angular/core';

@Component({
    styles: [
        ':host { display: none; width:0; height: 0;}'
    ],
    selector: 'paginator-filter-dialog-renderer',
    template: ''
})
export class PaginatorFilterDialogRendererComponent {
    currentRenderer = null;

    @Input()
    set componentData(data: { component: any, inputs: any }) {
        if (!data) {
            return;
        }
        console.log(data);
        let inputProviders = Object.keys(data.inputs).map((inputName) => {
            return {provide: inputName, useValue: data.inputs[inputName]};
        });
        let resolvedInputs = ReflectiveInjector.resolve(inputProviders);
        let injector = ReflectiveInjector.fromResolvedProviders(resolvedInputs, this._view.injector);
        let factory = this.resolver.resolveComponentFactory(data.component);
        let renderer = factory.create(injector);
        this._view.insert(renderer.hostView, 0);
        if (this.currentRenderer) {
            this.currentRenderer.destroy();
        }
        this.currentRenderer = renderer;
    }

    constructor(public resolver: ComponentFactoryResolver, public _view: ViewContainerRef) {

    }
}
