import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input} from '@angular/core';
import {LocalizedComponent} from '../../commons/components/localized-component';


@Component({
    selector: 'app-permissions-quick-info',
    template: `
        <div class="d-flex justify-content-start flex-wrap" *ngIf="isValid()">
            <div *ngFor="let p of permissions"
                 [class.d-none]="!isVisible(p)"
            >
                <span class='mr-2' [ngClass]="getClasses(p)" *ngIf="isVisible(p)">{{ getLabel(p) }}</span>
            </div>
        </div>
    `, changeDetection: ChangeDetectionStrategy.OnPush
})
export class PermissionsQuickInfoComponent extends LocalizedComponent {

    private _permissions: string[] = [];


    get permissions(): string[] {
        return this._permissions;
    }

    @Input()
    set permissions(value: string[]) {
        this._permissions = value;
        this.cd.markForCheck();
    }

    constructor(
        
        
        public cd: ChangeDetectorRef
    ) {
        super( cd);
    }

    isValid() {
        return this._permissions !== null && this._permissions !== undefined && Array.isArray(this._permissions) && this._permissions.length > 0;
    }

    isVisible(p: string) {
        return true;
    }

    getLabel(p: string) {
        p = p.toUpperCase();
        if (p === 'ROLE_USER') {
            return this._('Benutzer-Login');
        }
        if (p === 'ROLE_PRICING') {
            return this._('Preispflege');
        }
        if (p === 'ROLE_BESTAND') {
            return this._('Bestandsübersicht');
        }
        if (p === 'ROLE_AUSWEISE') {
            return this._('Ausweise');
        }
        if (p === 'ROLE_AUSWEISE_WRITABLE') {
            return this._('Ausweise (Schreibzugriff)');
        }
        if (p === 'ROLE_KARTENANFRAGEN') {
            return this._('Kartenanfragen');
        }
        if (p === 'ROLE_TRANSAKTIONEN') {
            return this._('Transaktionen');
        }
        if (p === 'ROLE_BESTAND_WRITABLE') {
            return this._('Bestandspflege (Schreibzugriff)');
        }
        if (p === 'ROLE_GLUKOSEPORTAL_USER') {
            return this._('Glukose-Portal-Account');
        }
        if (p === 'ROLE_ADMIN') {
            return this._('Voll-Administrator');
        }
        if (p === 'ROLE_PROJECT_ADMIN') {
            return this._('Projekt-Administrator');
        }
        if (p === 'ROLE_VIDEOCONTROL') {
            return this._('ME-Videocontrol');
        }
        if (p === 'ROLE_BILLING') {
            return this._('Buchhaltung');
        }
        if (p === 'ROLE_FAHRZEUGE') {
            return this._('Innenaufträge');
        }
        if (p === 'ROLE_KUNDEN') {
            return this._('Kunden');
        }
        if (p === 'ROLE_TRANSAKTIONEN_WRITABLE') {
            return this._('Transaktionen (Schreibzugriff)');
        }
        if (p === 'ROLE_FAHRZEUGE_WRITABLE') {
            return this._('Innenaufträge (Schreibzugriff)');
        }
        if (p === 'ROLE_KUNDEN_WRITABLE') {
            return this._('Kunden (Schreibzugriff)');
        }
        if (p === 'ROLE_AUSWEISE_CHANGE_PIN') {
            return this._('Ausweise (PIN-Änderung)');
        }

        if (p === 'BETRIEBSRAT_MODE') {
            return this._('Betriebsrat');
        }
        if (p === 'NO_DASHBOARD') {
            return this._('kein Dashboard');
        }
        return p;
    }

    getClasses(p: string) {
        const c = ['badge'];

        if (p === 'ROLE_ADMIN') {
            c.push('badge-danger');
        } else if (p === 'ROLE_PROJECT_ADMIN') {
            c.push('badge-danger');
        } else {
            c.push('badge-role');
        }

        return c;
    }
}
