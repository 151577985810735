import {ELaufzeitErfassung} from './elaufzeit-erfassung.enum';
import {IOption, Option} from '../../../commons/dynamic-form/select-field/ioption';
import {METranslationCallback} from '../../../commons/metranslation-callback';

export class LaufzeitErfassung {

    static options(translateFunc: METranslationCallback = null): IOption[] {
        const options: IOption[] = [
            Option.option(
                ELaufzeitErfassung.UNKNOWN,
                'unbekannte Laufzeiterfassung',
                LaufzeitErfassung.getIcon(ELaufzeitErfassung.UNKNOWN)
            ),
            Option.option(
                ELaufzeitErfassung.KILOMETER,
                'Kilometer',
                LaufzeitErfassung.getIcon(ELaufzeitErfassung.KILOMETER)
            ),
            Option.option(
                ELaufzeitErfassung.BETRIEBSSTUNDEN,
                'Betriebsstunden',
                LaufzeitErfassung.getIcon(ELaufzeitErfassung.BETRIEBSSTUNDEN)
            )
        ];

        if (translateFunc !== null && translateFunc !== undefined) {
            options.forEach((v: IOption, idx) => {
                options[idx].label = translateFunc(v.label);
            });
        }
        return options;

    }

    static getIcon(laufzeitErfassung: ELaufzeitErfassung): string {
        return '';
    }

    static byValue(laufzeitErfassung: any): ELaufzeitErfassung {

        if (laufzeitErfassung === null) {
            return null;
        }

        if (laufzeitErfassung === ELaufzeitErfassung.UNKNOWN) {
            return ELaufzeitErfassung.UNKNOWN;
        }
        if (laufzeitErfassung === ELaufzeitErfassung.KILOMETER) {
            return ELaufzeitErfassung.KILOMETER;
        }
        if (laufzeitErfassung === ELaufzeitErfassung.BETRIEBSSTUNDEN) {
            return ELaufzeitErfassung.BETRIEBSSTUNDEN;
        }

        if ('UNKNOWN' === ('' + laufzeitErfassung).toUpperCase()) {
            return ELaufzeitErfassung.UNKNOWN;
        }
        if ('KILOMETER' === ('' + laufzeitErfassung).toUpperCase()) {
            return ELaufzeitErfassung.KILOMETER;
        }
        if ('BETRIEBSSTUNDEN' === ('' + laufzeitErfassung).toUpperCase()) {
            return ELaufzeitErfassung.BETRIEBSSTUNDEN;
        }

        if (0 === parseInt('' + laufzeitErfassung, 10)) {
            return ELaufzeitErfassung.UNKNOWN;
        }
        if (1 === parseInt('' + laufzeitErfassung, 10)) {
            return ELaufzeitErfassung.KILOMETER;
        }
        if (2 === parseInt('' + laufzeitErfassung, 10)) {
            return ELaufzeitErfassung.BETRIEBSSTUNDEN;
        }



        return ELaufzeitErfassung.UNKNOWN;
    }
}
