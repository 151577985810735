import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
               selector: 'meduration-field',
               template: `
                   <div class="d-flex flex-row flex-nowrap">
                       <div class="p-1" *ngIf="!hideDays">
                           <div class="input-group">
                               <input class="form-control"
                                      type="number" [value]="_days" min="0" size="5"
                                      (input)="updateDays($event)"
                                      [disabled]="disabled"
                                      [class.disabled]="disabled"
                               >
                               <div class="input-group-append">d</div>
                           </div>
                       </div>
                       <div class="p-1" *ngIf="!hideHours">
                           <div class="input-group">
                               <input class="form-control"
                                      type="number" [value]="_hours" min="0" max="23" size="5"
                                      (input)="updateHours($event)"
                                      [disabled]="disabled"
                                      [class.disabled]="disabled"
                               >
                               <div class="input-group-append"> <span class="input-group-text">h</span></div>
                           </div>
                       </div>
                       <div class="p-1" *ngIf="!hideMinutes">
                           <div class="input-group">
                               <input class="form-control" type="number" [value]="_minutes" min="0" max="59" size="5"
                                      (input)="updateMinutes($event)"
                                      [disabled]="disabled"
                                      [class.disabled]="disabled"

                               >
                               <div class="input-group-append"> <span class="input-group-text">m</span></div>
                           </div>
                       </div>
                       <div class="p-1" *ngIf="!hideSeconds">
                           <div class="input-group">
                               <input class="form-control" type="number" [value]="_seconds" min="0" max="59" size="5"
                                      (input)="updateSeconds($event)"
                                      [disabled]="disabled"
                                      [class.disabled]="disabled"

                               >
                               <div class="input-group-append"> <span class="input-group-text">s</span></div>
                           </div>
                       </div>
                       <button class="btn btn-secondary btn-sm" (click)="nullify()" *ngIf="nullable && !disabled"><i
                               class="icofont icofont-eraser"></i></button>
                   </div>
               `,
               styles  : [
                       `
                       input[type=number] {
                           min-width: 60px;
                       }

                       button {
                           padding: 0 0.5rem;
                           max-height: 38px;
                           margin-top: 3px;
                       }

                       `
               ]
           })
export class MEDurationFieldComponent implements OnInit {

    _value = 0;
    _seconds = 0;
    _days = 0;
    _minutes = 0;
    _hours = 0;

    @Input() nullable = true;
    @Input() disabled = false;

    @Input() hideSeconds = false;
    @Input() hideMinutes = false;
    @Input() hideHours = false;
    @Input() hideDays = false;

    get value(): number {
        return this._value;
    }

    @Input()
    set value(v: number) {
        if (v === undefined) {
            v = null;
        }
        if (v === null) {
            if (!this.nullable) {
                v = 0;
            }
        } else {
            v = parseInt('' + v, 10);
            if (isNaN(v)) {
                v = 0;
            }
        }
        if (v !== this._value) {
            this._value = v;
            if (v === null) {
                this._days = null;
                this._minutes = null;
                this._seconds = null;
                this._hours = null;
            } else {
                this._days = Math.floor(v / (24 * 60 * 60));
                this._hours = Math.floor((v - (this._days * 24 * 60 * 60)) / (60 * 60));
                this._minutes = Math.floor((v - (this._days * 24 * 60 * 60) - (this._hours * 60 * 60)) / 60);
                this._seconds = v - (this._days * 24 * 60 * 60) - (this._hours * 60 * 60) - (this._minutes * 60);
            }
            if (!this.disabled) {
                this.valueChange.emit(this._value);
            }
        }

    }

    @Output() valueChange: EventEmitter<number> = new EventEmitter<number>();

    constructor() {
    }

    ngOnInit() {
    }

    public updateMinutes(e) {
        let v: number = parseInt('' + e.target.value, 10);
        if (isNaN(v)) {
            v = 0;
        }
        if (this._minutes !== v) {
            this._minutes = v;
            if (this._minutes < 0) {
                this._minutes = 0;
            }
            if (this._minutes > 59) {
                this._minutes = 59;
            }
            this.value = (this._days === null ? 0 : this._days) * 24 * 60 * 60 +
                         (this._hours === null ? 0 : this._hours) * 60 * 60 +
                         (this._minutes === null ? 0 : this._minutes) * 60 +
                         (this._seconds === null ? 0 : this._seconds);
        }
    }

    public updateSeconds(e) {
        let v: number = parseInt('' + e.target.value, 10);
        if (isNaN(v)) {
            v = 0;
        }
        if (this._seconds !== v) {
            this._seconds = v;
            if (this._seconds < 0) {
                this._seconds = 0;
            }
            if (this._seconds > 59) {
                this._seconds = 59;
            }
            this.value = (this._days === null ? 0 : this._days) * 24 * 60 * 60 +
                         (this._hours === null ? 0 : this._hours) * 60 * 60 +
                         (this._minutes === null ? 0 : this._minutes) * 60 +
                         (this._seconds === null ? 0 : this._seconds);

        }
    }

    public updateHours(e) {
        let v: number = parseInt('' + e.target.value, 10);
        if (isNaN(v)) {
            v = 0;
        }
        if (this._hours !== v) {
            this._hours = v;
            if (this._hours < 0) {
                this._hours = 0;
            }
            if (this._hours > 23) {
                this._hours = 23;
            }

            this.value = (this._days === null ? 0 : this._days) * 24 * 60 * 60 +
                         (this._hours === null ? 0 : this._hours) * 60 * 60 +
                         (this._minutes === null ? 0 : this._minutes) * 60 +
                         (this._seconds === null ? 0 : this._seconds);

        }
    }

    public updateDays(e) {
        let v: number = parseInt('' + e.target.value, 10);
        if (isNaN(v)) {
            v = 0;
        }
        if (this._days !== v) {
            this._days = v;
            if (this._days < 0) {
                this._days = 0;
            }
            this.value = (this._days === null ? 0 : this._days) * 24 * 60 * 60 +
                         (this._hours === null ? 0 : this._hours) * 60 * 60 +
                         (this._minutes === null ? 0 : this._minutes) * 60 +
                         (this._seconds === null ? 0 : this._seconds);

        }
    }

    nullify() {
        if (this.nullable) {
            this.value = null;
        }
    }


}
