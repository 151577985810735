<table class="table" *ngIf="device.isMECoinAcceptorUnit()">
    <tr>
        <th class="w-20" scope="row">{{ _('MAC-Adresse') }}</th>
        <td>{{ device.settings.mac }}</td>
        <th class="w-20" scope="row">{{ _('Kunde-ID') }}</th>
        <td>{{ device.settings.customer_id }}</td>
    </tr>
    <tr>
        <th class="w-20" scope="row">{{ _('Gateway-MAC-Adresse') }}</th>
        <td>{{ device.settings.gateway_mac }}</td>
        <th class="w-20" scope="row">{{ _('LoRa-Gateway') }}</th>
        <td>
            <div *ngIf="device.settings.gateway!==null && device.settings.gateway!==undefined">
                {{device.settings.gateway.bezeichnung}} <span class="badge badge-default">{{device.settings.gateway.nummer}}</span>
            </div>
        </td>
    </tr>
    <tr>
        <th class="w-20" scope="row">{{ _('zuletzt gefunden') }}</th>
        <td colspan="3">{{ fDate(device.settings.last_discovered,'medium') }}</td>
    </tr>
    <tr>
        <th class="w-20" scope="row">{{ _('Hardware-Version') }}</th>
        <td>{{ device.settings.hardware }}</td>
        <th class="w-20" scope="row">{{ _('Firmware-Version') }}</th>
        <td>{{ device.settings.firmware }}</td>
    </tr>
    <tr>
        <th class="w-20" scope="row">{{ _('letzter RSSI-Wert') }}</th>
        <td>{{ device.settings.rssi }}</td>
        <th class="w-20" scope="row">{{ _('letzter SNR-Wert') }}</th>
        <td>{{ device.settings.snr}}</td>
    </tr>
    <tr>
        <th class="w-20" scope="row">{{_('Produkt')}}</th>
        <td><app-produkt-quick-info
                *ngIf="device.settings.produkt!==null && device.settings.produkt!==undefined"
                [produkt]="device.settings.produkt"
        ></app-produkt-quick-info></td>
        <th class="w-20" scope="row">{{_('aufteilen?')}}</th>
        <td>
            <span *ngIf="device.settings.split">{{_('Transaktionen werden aufgeteilt')}}</span>
            <span *ngIf="!device.settings.split">{{_('Transaktionen werden zusammengefasst')}}</span>
        </td>
    </tr>
    <tr>
        <th class="w-20" scope="row">{{_('Ausweis')}}</th>
        <td><app-ausweis-quick-info
                *ngIf="device.settings.ausweis!==null && device.settings.ausweis!==undefined"
                [ausweis]="device.settings.ausweis"
        ></app-ausweis-quick-info></td>
        <th class="w-20" scope="row">{{_('Kostenstelle')}}</th>
        <td><app-kostenstelle-quick-info
                *ngIf="device.settings.kostenstelle!==null && device.settings.kostenstelle!==undefined"
                [kostenstelle]="device.settings.kostenstelle"
        ></app-kostenstelle-quick-info></td>

    </tr>
    <tr>
        <td colspan="4">
            <table class="table-borderless" *ngIf="device.settings.coinset.length>0">
                <tr>
                <th class="w-20" scope="row">{{_('Kanal:')}}</th>
                <td class="text-right text-nowrap" *ngFor="let c of device.settings.coinset">{{ fNum(c[0],'1.0-0') }}</td>
                </tr>
                <tr>
                    <th class="w-20 text-nowrap" scope="row">{{_('Münz-Wert:')}}</th>
                    <td class="text-right text-nowrap" *ngFor="let c of device.settings.coinset">{{ fNum(c[1],'1.2-2') }}</td>
                </tr>
            </table>
            <div class="alert alert-warning" *ngIf="device.settings.coinset.length<1">
                {{ _('Es wurden noch keine Münzwerte hinterlegt') }}
            </div>
        </td>
    </tr>

    <tr *hasRole="['ROLE_ADMIN','ROLE_PROJECT_ADMIN']">
        <td colspan="2" class="buttons">
            <button
                    class="btn"
                    [class.btn-primary]="!device.settings.pairing_ok"
                    [class.btn-secondary]="device.settings.pairing_ok"
                    (click)="sendAction('pairing')"
                    *ngIf="showPairing()"
            >
                {{ _('Pairing durchführen') }}
            </button>
        </td>
    </tr>
</table>
