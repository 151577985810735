<div class="modal-header">
    <h5 class="modal-title">{{ _('Einstellungen ändern') }}</h5>

    <button type="button" class="close" aria-label="Close" (click)="cancel()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">

    <app-tabset>
        <app-pane [title]="_('Freigaben')">
            <app-freigabe-editor
                    [mode]="'customer'"
                    [value]="currentKunde.begrenzungen"
                    (valueChange)="updateBegrenzungen($event)"
            ></app-freigabe-editor>
        </app-pane>
    </app-tabset>

</div>
<div class="modal-footer">

    <button type="button" class="btn btn-secondary" (click)="cancel()">
        <i class="icofont icofont-close"></i> {{ _('Abbrechen') }}
    </button>
    <button type="button"
            class="btn btn-primary"
            (click)="save()"
    >
        <i class="icofont icofont-save"></i>
        {{ _('Änderungen speichern')}}
    </button>
</div>

