import {SimpleRechnungFilter} from './simple-rechnung-filter';
import {Rechnung} from './rechnung';
import {IRechnung} from './irechnung';
import {CommonList} from '../../../commons/common-list';
import {IListResult} from '../../../commons/list-result';
import {ResultMetaSortImpl} from '../../../commons/result-meta';

export class RechnungList extends CommonList<Rechnung> {
    public simpleFilter: SimpleRechnungFilter = new SimpleRechnungFilter();

    static fromListResult(res: IListResult<IRechnung>): RechnungList {
        let newList: RechnungList = new RechnungList();
        newList.clear();
        newList.simpleFilter.clear();

        if (res.meta) {
            newList.total  = res.meta.total || 0;
            newList.offset = res.meta.offset || 0;
            newList.size   = res.meta.size || 0;
            newList.page   = res.meta.page || 0;
            if (res.meta.order) {
                newList.order = [];
                for (let x of res.meta.order) {
                    newList.order.push(
                        new ResultMetaSortImpl(
                            x.field || 'rechnungDatum',
                            x.direction || 'desc'
                        )
                    );
                }
            }
        }
        if (res.data) {
            for (let row of res.data) {
                newList.add(Rechnung.fromResult(row));
            }
        }
        if (res.simpleFilter) {
            let d: Rechnung = new Rechnung();
            for (let k of Object.keys(d)) {
                newList.simpleFilter[k] = (res.simpleFilter[k] !== undefined) ? res.simpleFilter[k] : null;
            }
        }

        return newList;


    }
}