import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {LocalizedComponent} from '../../../commons/components/localized-component';
import {Transaktion} from '../common/transaktion';


@Component({
               selector   : 'app-transaktion-lieferschein',
               templateUrl: './transaktion-lieferschein.component.html',
               styleUrls  : ['./transaktion-lieferschein.component.scss']
           })
export class TransaktionLieferscheinComponent extends LocalizedComponent implements OnInit {


    entity: Transaktion = new Transaktion();

    @Input()
    set transaktion(t: Transaktion) {
        this.entity = Transaktion.fromResult(t);
    }

    constructor(
        public cd: ChangeDetectorRef
    ) {
        super(cd);
    }

    ngOnInit() {
    }

    currencyIsNotEURO() {

        return this.getCurrency() !== 'EUR';

    }

    private getCurrency() {
        if (this.entity.waehrung !== null && this.entity.waehrung !== null) {
            return this.entity.waehrung.code;
        }

        if (this.entity.station === null || this.entity.station === undefined) {
            return '';
        }

        if (this.entity.station.currency === null || this.entity.station.currency === undefined) {
            return '';
        }

        return this.entity.station.currency.code;

    }

    currencyIsEURO() {
        return this.getCurrency() === 'EUR';
    }
}
