import {Injectable} from '@angular/core';
import {SimpleFahrzeugFilter} from './simple-fahrzeug-filter';
import {Observable} from 'rxjs';
import {IFahrzeug} from './ifahrzeug';
import {Fahrzeug} from './fahrzeug';
import {LoginService} from '../../../auth/services/login.service';
import {ResultMetaSortImpl} from '../../../commons/result-meta';
import {IListResult} from '../../../commons/list-result';
import {IKunde} from '../../kunden/common/ikunde';
import {IActionResponse} from '../../../commons/action-response';
import {HttpClient} from '@angular/common/http';

@Injectable({
                providedIn: 'root'
            })
export class FahrzeugService {

    constructor(public httpClient: HttpClient,
                public loginService: LoginService) {

    }

    list(max = 0,
         offset = 0,
         sort: ResultMetaSortImpl[] = [],
         filter: SimpleFahrzeugFilter = null,
         searchQuery = ''
    ): Observable<IListResult<IFahrzeug>> {
        let url = '/api/fahrzeuge';

        url = url.concat('?')
            .concat('max=' + encodeURIComponent('' + max))
            .concat('&')
            .concat('offset=' + encodeURIComponent('' + offset));

        if (filter != null) {
            if (filter.hasValues()) {
                url = url + '&' + filter.asURL();
            }
        }

        if ((searchQuery !== null) && (searchQuery !== undefined) && (searchQuery !== '')) {
            url = url + '&q=' + encodeURIComponent(searchQuery);
        }

        if (sort !== undefined && sort !== null && sort.length > 0) {
            const p = 0;
            sort.forEach(it => {
                url = url.concat('&sort.' + p + '=' + encodeURIComponent(it.field + '|' + it.direction));
            });
        }


        return this.httpClient
            .get<IListResult<IFahrzeug>>(
                url,
                {
                    headers: this.loginService.getHttpHeaders()
                }
            );
    }


    findFreeNum(kunde: IKunde = null): Observable<IActionResponse> {
        let url = '/api/fahrzeuge/_freeNum';
        if (kunde != null && kunde !== undefined) {
            url = url + '?kunde=' + encodeURIComponent('' + kunde.id);
        }
        return this.httpClient
            .post<IActionResponse>(
                url,
                {},
                {headers: this.loginService.getHttpHeaders()}
            );
    }

    store(fahrzeug: Fahrzeug, insert = false): Observable<IActionResponse> {
        if (!insert && fahrzeug.id != null && fahrzeug.id > 0) {
            return this.httpClient
                .put<IActionResponse>(
                    '/api/fahrzeuge/' + encodeURIComponent('' + fahrzeug.id),
                    JSON.stringify(fahrzeug),
                    {headers: this.loginService.getHttpHeaders()}
                );
        }
        return this.httpClient
            .post<IActionResponse>(
                '/api/fahrzeuge',
                JSON.stringify(fahrzeug),
                {headers: this.loginService.getHttpHeaders()}
            );

    }

    load(fahrzeugId: number): Observable<IFahrzeug> {
        return this.httpClient
            .get<IFahrzeug>(
                '/api/fahrzeuge/' + encodeURIComponent('' + fahrzeugId),
                {headers: this.loginService.getHttpHeaders()}
            );
    }

    reindexFahrzeuge(): Observable<IActionResponse> {
        return this.httpClient
            .post<IActionResponse>(
                '/api/fahrzeuge/_reindex',
                {},
                {
                    headers: this.loginService.getHttpHeaders()
                }
            );
    }

    countFahrzeuge(): Observable<IActionResponse> {
        return this.httpClient
            .get<IActionResponse>(
                '/api/fahrzeuge/_count',
                {headers: this.loginService.getHttpHeaders()}
            );

    }

    exportData(type = 'csv', filter: SimpleFahrzeugFilter = null, searchQuery = '', exporttype = 0): Observable<Blob> {
        let url = '/api/fahrzeuge.' + encodeURIComponent('' + type);

        url = url.concat('?max=-1')
            .concat('&offset=0')
            .concat('&sort.0=' + encodeURIComponent('nummer|asc'));
        url = url.concat('&exporttype=' + encodeURIComponent('' + exporttype));


        if (filter !== null) {
            if (filter.hasValues()) {
                url = url + '&' + filter.asURL();
            }
        }
        if (searchQuery !== null && searchQuery !== undefined && searchQuery !== '') {
            url = url.concat('&q=' + encodeURIComponent(searchQuery));
        }

        return this.httpClient
            .get(
                url,
                {
                    headers: this.loginService.getHttpHeaders(false),
                    responseType: 'blob'
                }
            );
    }

    syncFahrzeuge(): Observable<IActionResponse> {
       return this.httpClient
            .post<IActionResponse>(
                '/api/fahrzeuge/_sync',
                {},
                {headers: this.loginService.getHttpHeaders()}
            );
    }

    removeFahrzeug(entity: Fahrzeug): Observable<IActionResponse> {
        return this.httpClient
            .delete<IActionResponse>(
                '/api/fahrzeuge/' + encodeURIComponent('' + entity.id),
                {headers: this.loginService.getHttpHeaders()}
            );
    }
}
