import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {LocalizedComponent} from '../commons/components/localized-component';
import {IOption, NullOption} from '../commons/dynamic-form/select-field/ioption';


import {EStationArt} from './common/estation-art';
import {StationArt} from './common/station-art';
import CryptoES from "crypto-es";

@Component({
    selector: 'station-art-field',
    template: `
        <div class="form-group row">
            <label for="{{ id }}" class="col-{{ labelwidth }} col-form-label">
                {{ label }}
            </label>
            <div class="col-{{ 12-labelwidth }}">

                <select
                        id="{{ id }}"
                        class="form-control"
                        [value]="value"
                        (input)="updateValue($event)"
                        title="{{ label }}"
                >
                    <option
                            *ngFor="let o of options"
                            value="{{ o.value }}"
                    >{{ o.label }}
                    </option>
                </select>
            </div>
        </div>
    `
})
export class StationArtFieldComponent extends LocalizedComponent implements OnInit {

    @Input('label') label = 'Station-Art-Feld';
    @Input('id') id = '';
    @Input('labelwidth') labelwidth = 3;
    @Input('nullable') nullable = false;

    options: IOption[] = StationArt.options((key) => {
        return this.t(key);
    });
    @Output() valueChange = new EventEmitter<EStationArt>();

    constructor(
        public cd: ChangeDetectorRef) {
        super(cd);
    }

    public _value: EStationArt = EStationArt.UNKNOWN;

    get value(): EStationArt {
        return this._value;
    }

    @Input('value')
    set value(v: EStationArt) {
        if (this._value !== v) {
            this._value = v;
            this.valueChange.emit(v);
        }
    }

    ngOnInit() {
        if (this.nullable) {
            this.options.unshift(NullOption);
        }
        if (this.id === '') {
            this.id = 'tf' + CryptoES.MD5('stationartfield-' + this.label + Math.random() + '-' + Math.random()).toString();
        }
    }

    updateValue($event: any) {
        this.value = $event.target.value;
    }

}
