import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Value} from '../../../svgtools/value';
import {LocalizedComponent} from '../../../commons/components/localized-component';

import {DateTime} from 'luxon';
import {Sonde} from '../sonde';
import {Station} from '../../common/station';
import {Tick} from '../../../svgtools/tick';
import {Point} from '../../../svgtools/point';
import {Serie} from '../../../svgtools/serie';
import {DiagrammTool} from '../../../svgtools/diagramm-tool';


@Component({
    selector: '[app-diagramm-background]',
    template: `
        <svg:defs>
            <svg:filter id="f2" x="0" y="0" width="200%" height="200%">
                <svg:feOffset result="offOut" in="SourceAlpha" dx="8" dy="8"/>
                <svg:feGaussianBlur result="blurOut" in="offOut" stdDeviation="10"/>
                <svg:feBlend in="SourceGraphic" in2="blurOut" mode="normal"/>
            </svg:filter>
            <svg:g>
                <svg:path id="thermometer"
                          style="stroke-width:0.09765625;"
                          d="m 17.968719,37.5 c 0,3.02031 -2.448438,5.46875 -5.46875,5.46875 -3.0203125,0
                             -5.46875,-2.44844 -5.46875,-5.46875 0,-2.18145 1.2775391,-4.06396 3.125,-4.9418
                             V 14.84375 c 0,-1.29443 1.049316,-2.34375 2.34375,-2.34375 1.294433,0 2.34375,1.04932
                             2.34375,2.34375 V 32.5582 c 1.847461,0.87784 3.125,2.76035 3.125,4.9418 z m 3.90625,-8.26689
                             c 1.944531,2.20341 3.125,5.09707 3.125,8.26689 0,6.90391 -5.595899,12.5 -12.5,12.5 -0.0292,0
                             -0.05957,-1e-4 -0.08877,-2.9e-4 C 5.5457698,49.95205 -0.03489431,44.30039 1.6428334e-4,37.43486
                             0.01617991,34.29053 1.1938167,31.42148 3.124969,29.23311 V 9.375 c 0,-5.17764 4.1973633,-9.375
                             9.375,-9.375 5.177636,0 9.375,4.19736 9.375,9.375 z M 21.093719,37.5 c 0,-3.62959 -2.065625,-5.58936
                             -3.125,-6.78975 V 9.375 c 0,-3.01543 -2.453321,-5.46875 -5.46875,-5.46875 -3.0155273,0 -5.46875,2.45332
                             -5.46875,5.46875 V 30.71025 C 5.9592464,31.925 3.924676,33.8627 3.9063167,37.45479 3.8821957,42.1707
                             7.7237971,46.06074 12.43825,46.09355 l 0.06172,2e-4 c 4.738672,0 8.59375,-3.85518 8.593749,-8.59375 z"></svg:path>
                <svg:path id="tint"
                          style="stroke-width:0.09765625;"
                          d="M 20.041016,2.15723 C 19.649414,0.73535 18.418945,0 17.18457,0 15.978516,0 14.768555,0.70312
                             14.333984,2.15723 9.7666016,17.56348 0,21.75 0,32.6084 0,42.22168 7.6875,50 17.1875,50 26.6875,50
                             34.375,42.22168 34.375,32.6084 34.375,21.69531 24.629883,17.63379 20.041016,2.15723 Z
                             M 17.1875,45.3125 c -6.892578,0 -12.5,-5.69922 -12.5,-12.7041 0,-4.23145 2.0185547,-7.12403
                             5.0742188,-11.50293 2.3583982,-3.37988 5.1738282,-7.41406 7.4257812,-12.93555 2.260742,5.54981
                             5.080078,9.58008 7.441406,12.95508 3.045899,4.35352 5.058594,7.22949 5.058594,11.48437 0,7.00391
                             -5.607422,12.70313 -12.5,12.70313 z m 1.5625,-6.25 c -4.308594,0 -7.8125,-3.50488 -7.8125,-7.8125
                             0,-0.86328 -0.699219,-1.5625 -1.5625,-1.5625 -0.8632812,0-1.5625,0.69922 -1.5625,1.5625 0,6.03027
                             4.907227,10.9375 10.9375,10.9375 0.863281,0 1.5625,-0.69922 1.5625,-1.5625 0,-0.86328
                             -0.699219,-1.5625 -1.5625,-1.5625 z"></svg:path>
                <svg:path id="arrowh"
                          style="stroke-width:0.09765625;"
                          d="m 20.516061,37.985378 h -6.936537 v -25.97076 h 6.936537 c 1.044045,0 1.566898,-1.2623
                             0.828615,-2.00049 L 11.673857,0.3432075 c -0.457618,-0.45761 -1.199612,-0.45761 -1.657328,0
                             L 0.34571034,10.014128 c -0.738283,0.73828 -0.215431,2.00049 0.82861396,2.00049 h 6.93644
                             v 25.97076 h -6.936537 c -1.04404496,0 -1.56689796,1.2623 -0.82861496,2.00049
                             l 9.67081966,9.67092 c 0.457618,0.45761 1.199611,0.45761 1.657327,0 l 9.67082,-9.67092
                             c 0.738282,-0.73819 0.21543,-2.00049 -0.828518,-2.00049 z"></svg:path>
            </svg:g>
        </svg:defs>
        <svg:g *ngIf="isValid()">
            <svg:g *ngIf="hasTemperatur">
                <svg:rect [attr.x]="tempArea[0].x"
                          [attr.y]="tempArea[0].y"
                          [attr.width]="tempArea[1].x-tempArea[0].x"
                          [attr.height]="tempArea[1].y-tempArea[0].y"
                          fill="#fafafa"

                />
                <svg:use xlink:href="#thermometer"
                         [attr.x]="tempArea[0].x-95"
                         [attr.y]="((tempArea[1].y+tempArea[0].y)/2)-25"
                         width="25"
                         height="50"
                         fill="#CCCCCC"
                />
                <svg:line [attr.x1]="tempArea[0].x"
                          [attr.y1]="nullTemp"
                          [attr.x2]="tempArea[1].x"
                          [attr.y2]="nullTemp"
                          style="stroke:rgb(200,200,200);stroke-width:1px;"/>
                <svg:text [attr.x]="tempArea[0].x-5"
                          [attr.y]="nullTemp+2"
                          text-anchor="end"
                          style="font-style:normal;font-weight:normal;font-size:10.58333302px;line-height:1.25;font-family:sans-serif;letter-spacing:0px;word-spacing:0px;fill:#AAAAAA;fill-opacity:1;stroke:none;stroke-width:0.26458332"
                >0°
                </svg:text>
                <svg:text [attr.x]="tempArea[0].x-5"
                          [attr.y]="tempArea[0].y+2"
                          text-anchor="end"
                          style="font-style:normal;font-weight:normal;font-size:10.58333302px;line-height:1.25;font-family:sans-serif;letter-spacing:0px;word-spacing:0px;fill:#AAAAAA;fill-opacity:1;stroke:none;stroke-width:0.26458332"
                >{{ fNum(maxTemp ,'1.0-0') }}°
                </svg:text>
                <svg:text [attr.x]="tempArea[0].x-5"
                          [attr.y]="tempArea[1].y+2"
                          text-anchor="end"
                          style="font-style:normal;font-weight:normal;font-size:10.58333302px;line-height:1.25;font-family:sans-serif;letter-spacing:0px;word-spacing:0px;fill:#AAAAAA;fill-opacity:1;stroke:none;stroke-width:0.26458332"
                >{{ fNum(minTemp ,'1.0-0') }}°
                </svg:text>

            </svg:g>

            <svg:rect [attr.x]="area[0].x"
                      [attr.y]="area[0].y"
                      [attr.width]="area[1].x-area[0].x"
                      [attr.height]="area[1].y-area[0].y"
                      fill="#fafafa"

            />

            <svg:g>
                <svg:use xlink:href="#tint"
                         [attr.x]="area[0].x-100"
                         [attr.y]="((area[1].y+area[0].y)/2)-25"
                         width="35"
                         height="50"
                         fill="#CCCCCC"
                />
                <svg:text [attr.x]="area[0].x-5"
                          [attr.y]="area[1].y+2"
                          text-anchor="end"
                          style="font-style:normal;font-weight:normal;font-size:10.58333302px;line-height:1.25;font-family:sans-serif;letter-spacing:0px;word-spacing:0px;fill:#AAAAAA;fill-opacity:1;stroke:none;stroke-width:0.26458332"
                >{{ fNum(0 ,'1.0-0')}} l
                </svg:text>
                <svg:line [attr.x1]="area[0].x"
                          [attr.y1]="(area[0].y+area[1].y)/2"
                          [attr.x2]="area[1].x"
                          [attr.y2]="(area[0].y+area[1].y)/2"
                          style="stroke:rgb(200,200,200);stroke-width:1px;"/>
                <svg:line [attr.x1]="area[0].x"
                          [attr.y1]="getY(0.75)"
                          [attr.x2]="area[1].x"
                          [attr.y2]="getY(0.75)"
                          style="stroke:rgb(225,225,225);stroke-width:1px;"/>
                <svg:line [attr.x1]="area[0].x"
                          [attr.y1]="getY(0.25)"
                          [attr.x2]="area[1].x"
                          [attr.y2]="getY(0.25)"
                          style="stroke:rgb(225,225,225);stroke-width:1px;"/>

                <svg:text [attr.x]="area[0].x-5"
                          [attr.y]="getY(0.25)+2"
                          text-anchor="end"
                          style="font-style:normal;font-weight:normal;font-size:10.58333302px;line-height:1.25;font-family:sans-serif;letter-spacing:0px;word-spacing:0px;fill:#AAAAAA;fill-opacity:1;stroke:none;stroke-width:0.26458332"
                >{{ fNum( (volumen * 0.25) ,'1.0-0') }} l
                </svg:text>


                <svg:text [attr.x]="area[0].x-5"
                          [attr.y]="(area[0].y+area[1].y)/2+2"
                          text-anchor="end"
                          style="font-style:normal;font-weight:normal;font-size:10.58333302px;line-height:1.25;font-family:sans-serif;letter-spacing:0px;word-spacing:0px;fill:#AAAAAA;fill-opacity:1;stroke:none;stroke-width:0.26458332"
                >{{ fNum( volumen / 2 ,'1.0-0') }} l
                </svg:text>

                <svg:text [attr.x]="area[0].x-5"
                          [attr.y]="getY(0.75)+2"
                          text-anchor="end"
                          style="font-style:normal;font-weight:normal;font-size:10.58333302px;line-height:1.25;font-family:sans-serif;letter-spacing:0px;word-spacing:0px;fill:#AAAAAA;fill-opacity:1;stroke:none;stroke-width:0.26458332"
                >{{ fNum( (volumen * 0.75),'1.0-0') }} l
                </svg:text>


                <svg:text [attr.x]="area[0].x-5"
                          [attr.y]="area[0].y+2"
                          text-anchor="end"
                          style="font-style:normal;font-weight:normal;font-size:10.58333302px;line-height:1.25;font-family:sans-serif;letter-spacing:0px;word-spacing:0px;fill:#AAAAAA;fill-opacity:1;stroke:none;stroke-width:0.26458332"
                >{{ fNum(volumen ,'1.0-0') }} l
                </svg:text>
            </svg:g>
            <svg:g *ngIf="(hasHoehe || hasSumpf)&&(maxHoehe>0)">

                <svg:use xlink:href="#arrowh"
                         [attr.x]="area[1].x+90"
                         [attr.y]="((area[1].y+area[0].y)/2)-25"
                         width="35"
                         height="50"
                         fill="#CCCCCC"
                />

                <svg:text [attr.x]="area[1].x+60"
                          [attr.y]="getY(0.25)+2"
                          text-anchor="end"
                          style="font-style:normal;font-weight:normal;font-size:10.58333302px;line-height:1.25;font-family:sans-serif;letter-spacing:0px;word-spacing:0px;fill:#AAAAAA;fill-opacity:1;stroke:none;stroke-width:0.26458332"
                >{{ fNum(maxHoehe * 0.25,'1.0-0')}} mm
                </svg:text>


                <svg:text [attr.x]="area[1].x+60"
                          [attr.y]="(area[0].y+area[1].y)/2+2"
                          text-anchor="end"
                          style="font-style:normal;font-weight:normal;font-size:10.58333302px;line-height:1.25;font-family:sans-serif;letter-spacing:0px;word-spacing:0px;fill:#AAAAAA;fill-opacity:1;stroke:none;stroke-width:0.26458332"
                >{{ fNum(maxHoehe / 2,'1.0-0')}} mm
                </svg:text>

                <svg:text [attr.x]="area[1].x+60"
                          [attr.y]="getY(0.75)+2"
                          text-anchor="end"
                          style="font-style:normal;font-weight:normal;font-size:10.58333302px;line-height:1.25;font-family:sans-serif;letter-spacing:0px;word-spacing:0px;fill:#AAAAAA;fill-opacity:1;stroke:none;stroke-width:0.26458332"
                >{{ fNum(maxHoehe * 0.75,'1.0-0') }} mm
                </svg:text>


                <svg:text [attr.x]="area[1].x+60"
                          [attr.y]="area[0].y+2"
                          text-anchor="end"
                          style="font-style:normal;font-weight:normal;font-size:10.58333302px;line-height:1.25;font-family:sans-serif;letter-spacing:0px;word-spacing:0px;fill:#AAAAAA;fill-opacity:1;stroke:none;stroke-width:0.26458332"
                >{{ fNum(maxHoehe ,'1.0-0')}} mm
                </svg:text>

                <svg:text [attr.x]="area[1].x+60"
                          [attr.y]="area[1].y"
                          text-anchor="end"
                          style="font-style:normal;font-weight:normal;font-size:10.58333302px;line-height:1.25;font-family:sans-serif;letter-spacing:0px;word-spacing:0px;fill:#AAAAAA;fill-opacity:1;stroke:none;stroke-width:0.26458332"
                >{{ fNum(0,'1.0-0')}} mm
                </svg:text>
            </svg:g>
        </svg:g>

        <svg:g>
            <svg:g *ngIf="dayTicks.length>0">
                <svg:rect [attr.x]="area[0].x"
                          [attr.y]="getY(0)+5"
                          [attr.width]="getX(1)-getX(0)"
                          height="4"
                          fill="#008800"
                          opacity="0.4"
                ></svg:rect>
                <svg:line *ngFor="let tick of dayTicks"
                          id="day-tick-{{tick.pos}}"
                          [attr.x1]="tick.pos"
                          [attr.y1]="getY(0)+5"
                          [attr.x2]="tick.pos"
                          [attr.y2]="getY(0)+9"
                          style="stroke:rgb(255,255,255);stroke-width:2px;"
                />
            </svg:g>
            <svg:line [attr.x1]="area[0].x"
                      [attr.y1]="getY(0)+12"
                      [attr.x2]="area[1].x"
                      [attr.y2]="getY(0)+12"
                      style="stroke:rgb(125,125,125);stroke-width:1px;"/>

            <svg:g *ngFor="let tick of ticks">
                <svg:line
                        *ngIf="tick.pos>getX(0) && tick.pos<getX(1)"
                        [attr.x1]="tick.pos"
                        [attr.y1]="getY(1)"
                        [attr.x2]="tick.pos"
                        [attr.y2]="getY(0)"
                        style="stroke:rgb(235,235,235);stroke-width:1px;"/>
                <svg:line
                        *ngIf="hasTemperatur && tick.pos>getX(0) && tick.pos<getX(1)"
                        [attr.x1]="tick.pos"
                        [attr.y1]="tempArea[0].y"
                        [attr.x2]="tick.pos"
                        [attr.y2]="tempArea[1].y"
                        style="stroke:rgb(235,235,235);stroke-width:1px;"/>

                <svg:line
                        [attr.x1]="tick.pos"
                        [attr.y1]="getY(0)+12"
                        [attr.x2]="tick.pos"
                        [attr.y2]="getY(0)+17"
                        style="stroke:rgb(125,125,125);stroke-width:1px;"/>
                <svg:text
                        *ngFor="let lb of tick.labels;let idx=index"
                        [attr.x]="tick.pos"
                        [attr.y]="getY(0)+30+(idx*11)"
                        text-anchor="middle"
                        style="font-style:normal;font-weight:normal;
                               font-size:10.58333302px;line-height:1.25;
                               font-family:sans-serif;letter-spacing:0px;
                               word-spacing:0px;fill:#AAAAAA;fill-opacity:1;
                               stroke:none;stroke-width:0.26458332"
                >{{ lb }}
                </svg:text>
            </svg:g>
        </svg:g>


        <svg:g *ngIf="series.length>0 && sonde!==null && sonde!==undefined">

<svg:text x="1500" y="60"
                          text-anchor="middle"
                          style="font-style:normal;font-weight:bold;font-size:28px;
                          line-height:1.25;font-family:sans-serif;letter-spacing:0px;word-spacing:0px;fill:#333333;
                          fill-opacity:1;stroke:none;stroke-width:0.26458332"
                >{{ sonde.bezeichnung }}
                </svg:text>
            <svg:text x="1500" y="90"
                      text-anchor="middle"
                      style="font-style:normal;font-weight:bold;font-size:12px;
                          line-height:1.25;font-family:sans-serif;letter-spacing:0px;word-spacing:0px;fill:#333333;
                          fill-opacity:1;stroke:none;stroke-width:0.26458332"
            >{{ station.bezeichnung }}
            </svg:text>


             <svg:g>
                    <svg:rect
                            x="1450"
                            [attr.y]="455-series.length*25"
                            width="145"
                            [attr.height]="series.length*25+16"
                            fill="#222222"
                            opacity="0.15"
                    ></svg:rect>
                    <svg:g *ngFor="let s of series;let idx=index">
                        <svg:circle
                                [attr.cx]="1470"
                                [attr.cy]="460-series.length*25+idx*25 +18"
                                r="8"
                                fill="#FFFFFF"
                                [attr.stroke]="s.color"
                                style="stroke-width:2px;cursor: pointer;"
                                (click)="seriesClick(s)"
                        />

                        <svg:text
                                [attr.x]="1485"
                                [attr.y]="460-series.length*25+idx*25 +23"
                                text-anchor="start"
                                style="font-style:normal;font-weight:bold;
                                font-size:15px;line-height:1.25;
                                font-family:sans-serif;letter-spacing:0px;
                                word-spacing:0px;fill:#444444;stroke:none;stroke-width:0.26458332"
                                [style.fill-opacity]="s.visible?1:0.2"
                        >{{ s.name }} </svg:text>
                    </svg:g>
                </svg:g>


        </svg:g>

    `

})
export class DiagrammBackgroundComponent extends LocalizedComponent implements OnInit {

    ticks: Tick[] = [];
    dayTicks: Tick[] = [];

    nullTemp = 0;

    private _series: Serie[] = [];

    private _station: Station = null;

    @Output()
    serieClicked: EventEmitter<Serie> = new EventEmitter<Serie>();


    get station(): Station {
        return this._station;
    }

    @Input()
    set station(value: Station) {
        this._station = value;
        this.update();
    }

    private _sonde: Sonde = null;


    get sonde(): Sonde {
        return this._sonde;
    }

@Input()
set sonde(value: Sonde) {
        this._sonde = value;
        this.update();
    }

    get series(): Serie[] {
        return this._series;
    }

    @Input()
    set series(value: Serie[]) {
        this._series = value;
        this.update();
    }

    private _minX: any = null;
    private _maxX: any = null;


    get minX(): any {
        return this._minX;
    }

    @Input()
    set minX(value: any) {
        this._minX = value;
        this.update();
    }

    get maxX(): any {
        return this._maxX;
    }

    @Input()
    set maxX(value: any) {
        this._maxX = value;
        this.update();
    }

    private _minTemp = -30;
    private _maxTemp = 80;

    get minTemp(): number {
        return this._minTemp;
    }

    @Input()
    set minTemp(value: number) {
        this._minTemp = value;
        this.update();
    }

    get maxTemp(): number {
        return this._maxTemp;
    }

    @Input()
    set maxTemp(value: number) {
        this._maxTemp = value;
        this.update();
    }

    private _hasTemperatur = true;


    get hasTemperatur(): boolean {
        return this._hasTemperatur;
    }

    @Input()
    set hasTemperatur(value: boolean) {
        this._hasTemperatur = value;
        this.update();
    }

    private _area: Point[] = [];

    get area(): Point[] {
        return this._area;
    }

    @Input()
    set area(value: Point[]) {
        this._area = value;
        this.update();
    }

    private _tempArea: Point[] = [];


    get tempArea(): Point[] {
        return this._tempArea;
    }

    @Input()
    set tempArea(value: Point[]) {
        this._tempArea = value;
        this.update();
    }


    private _volumen = 10000;


    get volumen(): number {
        return this._volumen;
    }

    @Input()
    set volumen(value: number) {
        this._volumen = value;
        this.update();
    }

    private _hasHoehe = true;
    private _maxHoehe = 2000;
    private _hasSumpf = false;


    get hasSumpf(): boolean {
        return this._hasSumpf;
    }

    @Input()
    set hasSumpf(value: boolean) {
        this._hasSumpf = value;
        this.update();
    }

    get hasHoehe(): boolean {
        return this._hasHoehe;
    }

    @Input()
    set hasHoehe(value: boolean) {
        this._hasHoehe = value;
        this.update();
    }


    get maxHoehe(): number {
        return this._maxHoehe;
    }

    @Input()
    set maxHoehe(value: number) {
        this._maxHoehe = value;
        this.update();
    }

    constructor(  public cd: ChangeDetectorRef) {
        super( cd);
    }

    ngOnInit() {
    }

    update() {
        this.ticks = [];
        this.dayTicks = [];

        if (!this.isValid()) {
            return 0;
        }

        this.nullTemp = DiagrammTool.cy(DiagrammTool.calcY(new Value(0, 0), 120, this._maxTemp, this._minTemp), 360);
        const wd = this._area[1].x - this._area[0].x;

        const mx = (this.maxX instanceof Date) ? DateTime.fromJSDate(this.maxX).toMillis() : parseFloat('' + this.maxX);
        const mix = (this.minX instanceof Date) ? DateTime.fromJSDate(this.minX).toMillis() : parseFloat('' + this.minX);
        const dx = mx - mix;
        let lastX = null;
        for (let i = 0; i < 11; i++) {
            const fc = i / 10;
            const v = (mix + dx * fc);
            let label = '' + v;
            if (this.minX instanceof Date) {
                const d1 = DateTime.fromJSDate(this.minX);
                const d2 = DateTime.fromJSDate(this.maxX);

                if (i === 0 || i === 10 || DateTime.fromMillis(v).get('day') !== DateTime.fromMillis(lastX).get('day')) {
                    label = DateTime.fromMillis(v).toLocaleString(DateTime.DATE_MED) +
                        ',' +
                        DateTime.fromMillis(v).toLocaleString(DateTime.TIME_24_SIMPLE);
                } else if (DateTime.fromMillis(v).diff(DateTime.fromMillis(lastX), ['days', 'hours']).hours > 2) {
                    label = DateTime.fromMillis(v).toLocaleString(DateTime.TIME_24_SIMPLE);
                } else {
                    label = DateTime.fromMillis(v).toLocaleString(DateTime.TIME_24_WITH_SECONDS);
                }
            }

            this.ticks.push(new Tick(this.getX(fc), label.split(/,/g)));
            lastX = v;
        }
        if ((this.minX instanceof Date) && (this.maxX instanceof Date)) {

            const d1 = DateTime.fromJSDate(this.minX);
            const d2 = DateTime.fromJSDate(this.maxX);

            let d = d1.startOf('day');
            if (d.toMillis() < d1.toMillis()) {
                d = d1.endOf('day');
            }
            while (d.toMillis() < d2.toMillis()) {
                const xp = DiagrammTool.calcX(new Value(d.toJSDate(), 0), wd, this.maxX, this.minX) + this._area[0].x;
                this.dayTicks.push(new Tick(xp, [d.plus({minutes: 1}).toLocaleString(DateTime.DATE_FULL)]));
                d = d.plus({hours: 3}).endOf('day');
            }

        }


        this.cd.markForCheck();
    }

    isValid() {
        if (this._area.length < 2) {
            return false;
        }
        if (this.hasTemperatur && this._tempArea.length < 2) {
            return false;
        }
        return true;
    }

    getY(factor = 1) {
        if (this._area.length < 2) {
            return 0;
        }

        let dy = this._area[1].y - this._area[0].y;

        dy = dy * factor;

        return this._area[1].y - dy;
    }

    getX(factor = 1) {
        if (this._area.length < 2) {
            return 0;
        }
        let dx = this._area[1].x - this._area[0].x;

        dx = dx * factor;

        return this._area[0].x + dx;
    }


    mover(w, t, e) {
        console.log(w, t, e);
    }

    seriesClick(s: Serie) {
        if (s !== null && s !== undefined) {
         this.serieClicked.emit(s);
        }
    }
}
