import {ISprache} from './isprache';

export class Sprache implements ISprache {

    code: string;
    bezeichnung: string;
    flagge: string;

    static fromResult(entity: ISprache): Sprache {
        if ((entity === null) || (entity === undefined)) {
            return null;
        }

        let l = new Sprache();
        for (let k of Object.keys(l)) {
            l[k] = (entity[k] !== undefined) ? entity[k] : '';
        }
        return l;
    }


    constructor(code = '', bezeichnung = '', flagge = '') {
        this.code = code;
        this.bezeichnung = bezeichnung;
        this.flagge = flagge;
    }


}
