export class GenericDeviceSettings {

    static fromRAW(st: any): GenericDeviceSettings {
        if (st === null || st === undefined) {
            return null;
        }
        const l = new GenericDeviceSettings();
        for (const k of Object.keys(l)) {
            if (st[k] !== undefined) {
                l[k] = (st[k] !== undefined) ? st[k] : null;
            }
        }
        return l;
    }
}
