import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {LocalizedComponent} from '../../commons/components/localized-component';
import {LoginService} from '../../auth/services/login.service';
import {NgbActiveModal, NgbModal, NgbModalOptions, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';


import {PasswordChangeRecord} from '../../auth/common/password-change-record';
import {ToastrService} from 'ngx-toastr';

@Component({
    selector: 'app-password-change-dialog',
    templateUrl: './password-change-dialog.component.html',
    styleUrls: ['./password-change-dialog.component.css']
})
export class PasswordChangeDialogComponent extends LocalizedComponent implements OnInit {

    entity: PasswordChangeRecord = new PasswordChangeRecord();

    static open(modalService: NgbModal): Promise<any> {
        const o: NgbModalOptions = {
            backdrop: 'static',
            keyboard: false
        };
        const r: NgbModalRef = modalService.open(PasswordChangeDialogComponent, o);
        (r.componentInstance as PasswordChangeDialogComponent).open();
        return r.result;
    }

    constructor(
                
                public activeModal: NgbActiveModal,
                public toastr: ToastrService,
                public loginService: LoginService,
                public cd: ChangeDetectorRef
    ) {
        super( cd);
    }

    ngOnInit() {
    }


    open() {
        this.entity.clear();
    }

    cancel() {
        this.activeModal.dismiss('cancel');
    }

    save() {
        this.loginService
            .doPwChange(this.entity)
            .subscribe(r => {
                    if (r.success) {
                        this.activeModal.close(this.entity);
                    } else {
                        this.toastr.error(this._('Fehler beim Ändern des Kennwortes'));
                    }
                },
                () => {
                    this.toastr.error(this._('Fehler beim Ändern des Kennwortes'));
                }
            );
    }

}
