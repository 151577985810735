import {RemoteList} from '../../commons/remote-list';
import {SondenBuchung} from './sonden-buchung';
import {SimpleSondenBuchungFilter} from './simple-sonden-buchung-filter';
import {ISondenBuchung} from './isonden-buchung';
import {IListResult} from '../../commons/list-result';

export class SondenBuchungList extends RemoteList<SondenBuchung> {
      public simpleFilter = new SimpleSondenBuchungFilter();

    constructor(autorun = false, timeout = 400) {
        super(autorun, timeout);
    }

    static fromListResult(res: IListResult<ISondenBuchung>): SondenBuchungList {
        const newList = new SondenBuchungList();

        newList.populateFromListResult(res, true);

        return newList;
    }

    public convertEntity(entity: any): any {
        return SondenBuchung.fromResult(entity);
    }

    public populateFromListResult(res: IListResult<ISondenBuchung>, clearFilter = true): void {
        this.doPopulateFromListResult(res, 'zeitpunkt', 'desc', clearFilter);
    }
}
