import {AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Output, ViewChild} from '@angular/core';

import {AusweisElementRendererComponent} from '../../../customers/ausweis-element-renderer.component';
import {StationElementRendererComponent} from '../../../stationen/station-element-renderer/station-element-renderer.component';
import {CustomerelementRendererComponent} from '../../../customers/customerelement-renderer.component';
import {ElementListComponent} from '../../../commons/element-list/element-list.component';
import {LocalizedComponent} from '../../../commons/components/localized-component';
import {IFilterDialog} from '../../../commons/data-table/ifilter-dialog';
import {KartenanfragenFilter} from './kartenanfragen-filter';
import {SprachenService} from '../../../commons/sprachen/common/sprachen.service';
import {CountryElementRendererComponent} from '../../../commons/laender/country-element-renderer/country-element-renderer.component';
import {Land} from '../../../commons/laender/common/land';

@Component({
    selector: 'app-kartenanfragen-filterdialog',
    templateUrl: './kartenanfragen-filterdialog.component.html',
    styleUrls: ['./kartenanfragen-filterdialog.component.scss']
})
export class KartenanfragenFilterdialogComponent extends LocalizedComponent implements IFilterDialog, AfterViewInit {
    stationRenderer = StationElementRendererComponent;
    customerRenderer = CustomerelementRendererComponent;
    ausweisRenderer = AusweisElementRendererComponent;
    countryRenderer = CountryElementRendererComponent;

    filter = new KartenanfragenFilter();

    @Output() filterChanged: EventEmitter<string> = new EventEmitter<string>();

    @ViewChild('stationen', {static: false}) stationen: ElementListComponent;
    @ViewChild('customers', {static: false}) customers: ElementListComponent;
    @ViewChild('ausweise', {static: false}) ausweise: ElementListComponent;
    @ViewChild('laender', {static: false}) laender: ElementListComponent;

    constructor(


        public cd: ChangeDetectorRef,
        public sprachenService: SprachenService
    ) {
        super( cd);
        this.filter.filterChanged.subscribe(
            () => {
                if (this.stationen !== null && this.stationen !== undefined) {
                    this.stationen.elements = this.filter.stationen;
                }
                if (this.customers !== null && this.customers !== undefined) {
                    this.customers.elements = this.filter.customers;
                }
                if (this.ausweise !== null && this.ausweise !== undefined) {
                    this.ausweise.elements = this.filter.cards;
                }
                if (this.laender !== null && this.laender !== undefined) {
                    this.laender.elements = this.filter.countries;
                }
                this.markChanged();
            }
        );
    }

    ngAfterViewInit() {
        this.filter.lastThreeDays();
        this.filter.useTimeRange = false;
        this.markChanged();
    }

    getQuery(): string {
        return this.filter.getQuery();
    }

    markChanged(visble = true): void {
        this.cd.markForCheck();
        if (visble) {
            this.filterChanged.emit(this.getQuery());
        } else {
            this.filterChanged.emit('');
        }
    }

    land_at() {
        this.filter.clearCountries();
        this.sprachenService.Oesterreich().then((l: Land) => {
            this.filter.addCountry(l);
        });
    }

    land_atde() {
        this.filter.clearCountries();
        this.sprachenService.Oesterreich().then((l: Land) => {
            this.filter.addCountry(l);
        });
        this.sprachenService.Deutschland().then((l: Land) => {
            this.filter.addCountry(l);
        });
    }

    land_de() {
        this.filter.clearCountries();
        this.sprachenService.Deutschland().then((l: Land) => {
            this.filter.addCountry(l);
        });
    }
}
