import {IStation} from '../../stationen/common/istation';
import {IProdukt} from '../../products/produkte/common/iprodukt';
import {IEinheit} from '../../products/einheiten/ieinheit';
import {Preis} from './preis';
import {IPreis} from './ipreis';
import {GenericFilter} from '../../commons/generic-filter';
import {ITerminal} from '../../stationen/terminals/common/iterminal';
import {IWaehrung} from '../../commons/waehrungen/iwaehrung';

export class SimplePreisFilter extends GenericFilter<Preis> implements IPreis {
    einheit: IEinheit = null;
    id: number = null;
    station: IStation = null;
    terminal: ITerminal = null;
    produkt: IProdukt = null;
    waehrung: IWaehrung = null;
    gueltigAb: Date = null;
    wert: number = null;
    istBrutto: boolean = null;


    public asURL(): string {
        let url = '';


        Object.keys(this).forEach(k => {
            if ((this[k] !== null) && (this[k] !== undefined) && (this[k] !== '') && (k !== '_operations')) {
                url += (url !== '') ? '&' : '';
                url += 'filter.' + encodeURIComponent(k) + '=';
                if (k === 'station') {
                    url += encodeURIComponent('' + this.station.id);
                } else if (k === 'terminal') {
                    url += encodeURIComponent('' + this.terminal.id);
                } else if (k === 'produkt') {
                    url += encodeURIComponent('' + this.produkt.id);
                } else {
                    url += encodeURIComponent('' + this[k]);
                }
            }
        });

        Object.keys(this._operations).forEach(k => {
            if ((this._operations[k] !== null) && (this._operations[k] !== undefined) && (this._operations[k] !== '')) {
                url += (url !== '') ? '&' : '';
                url += 'operation.' + encodeURIComponent(k) + '=' + encodeURIComponent('' + this._operations[k]);
            }
        });

        return url;
    }
}
