import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {LoginService} from '../../auth/services/login.service';
import {Geraet} from './geraet';
import {Observable} from 'rxjs';
import {IActionResponse} from '../../commons/action-response';
import {IStation} from '../../stationen/common/istation';
import {SimpleGeraetFilter} from './simple-geraet-filter';
import {ResultMetaSortImpl} from '../../commons/result-meta';
import {IGeraet} from './igeraet';
import {IListResult} from '../../commons/list-result';

@Injectable({
    providedIn: 'root'
})
export class DeviceService {

    constructor(
        public http: HttpClient,
        public authService: LoginService
    ) {

    }

    listDevices(station: IStation,
                max = 0,
                offset = 0,
                sort: ResultMetaSortImpl[] = [],
                filter: SimpleGeraetFilter = null,
                searchQuery = ''): Observable<IListResult<IGeraet>> {
        let url = '';
        if (station === null || station === undefined || station.id === null || station.id === undefined || station.id < 1) {
             url = '/api/devices';
        } else {
            url = '/api/stationen/' + encodeURIComponent('' + station.id) + '/devices';
        }
        url = url.concat('?')
            .concat('max=' + encodeURIComponent('' + max))
            .concat('&')
            .concat('offset=' + encodeURIComponent('' + offset));


        if (filter !== null) {
            if (filter.hasValues()) {
                url = url + '&' + filter.asURL();
            }
        }


        if ((searchQuery !== null) && (searchQuery !== undefined) && (searchQuery !== '')) {
            url = url + '&q=' + encodeURIComponent(searchQuery);
        }

        if (sort !== undefined && sort !== null && sort.length > 0) {
            const p = 0;
            sort.forEach(it => {
                url = url.concat('&sort.' + p + '=' + encodeURIComponent(it.field + '|' + it.direction));
            });
        }


        return this.http
            .get<IListResult<IGeraet>>(
                url,
                {
                    headers: this.authService.getHttpHeaders()
                }
            );
    }

    storeDevice(station: IStation, device: Geraet, forceInsert = false): Observable<IActionResponse> {
        if (device === null || device === undefined) {
            return null;
        }
        if (station === null || station === undefined || station.id === null || station.id === undefined || station.id < 1) {
            return null;
        }
        let url = '/api/stationen/' + encodeURIComponent('' + station.id) + '/devices';
        if (forceInsert || device.id === null || device.id === undefined || device.id < 1) {
            return this.http
                .post<IActionResponse>(
                    url,
                    device,
                    {
                        headers: this.authService.getHttpHeaders()
                    }
                );
        }
        url = url + '/' + encodeURIComponent('' + device.id);

        return this.http
            .put<IActionResponse>(
                url,
                device,
                {
                    headers: this.authService.getHttpHeaders()
                }
            );

    }


    removeDevice(station: IStation, device: IGeraet): Observable<IActionResponse> {
        if (device === null || device === undefined || device.id === null || device.id === undefined || device.id < 1) {
            return null;
        }
        if (station === null || station === undefined || station.id === null || station.id === undefined || station.id < 1) {
            return null;
        }
        const url = '/api/stationen/' + encodeURIComponent('' + station.id) + '/devices/' + encodeURIComponent('' + device.id);

        return this.http
            .delete<IActionResponse>(
                url,
                {
                    headers: this.authService.getHttpHeaders()
                }
            );
    }

    enableMEVCWifi(station: IStation, device: IGeraet): Observable<IActionResponse> {
        if (device === null || device === undefined || device.id === null || device.id === undefined || device.id < 1) {
            return null;
        }
        if (station === null || station === undefined || station.id === null || station.id === undefined || station.id < 1) {
            return null;
        }
        const url = '/api/stationen/' + encodeURIComponent('' + station.id) + '/devices/' + encodeURIComponent('' + device.id) + '/_activateCamAccess';

        return this.http
            .post<IActionResponse>(
                url,
                {},
                {
                    headers: this.authService.getHttpHeaders()
                }
            );

    }

    disableMEVCWifi(station: IStation, device: IGeraet): Observable<IActionResponse> {
        if (device === null || device === undefined || device.id === null || device.id === undefined || device.id < 1) {
            return null;
        }
        if (station === null || station === undefined || station.id === null || station.id === undefined || station.id < 1) {
            return null;
        }
        const url = '/api/stationen/' + encodeURIComponent('' + station.id) + '/devices/' + encodeURIComponent('' + device.id) + '/_deactivateCamAccess';

        return this.http
            .post<IActionResponse>(
                url,
                {},
                {
                    headers: this.authService.getHttpHeaders()
                }
            );

    }


    rebootMEVC(station: IStation, device: IGeraet): Observable<IActionResponse> {
        if (device === null || device === undefined || device.id === null || device.id === undefined || device.id < 1) {
            return null;
        }
        if (station === null || station === undefined || station.id === null || station.id === undefined || station.id < 1) {
            return null;
        }
        const url = '/api/stationen/' + encodeURIComponent('' + station.id) + '/devices/' + encodeURIComponent('' + device.id) + '/_rebootMEVCBox';

        return this.http
            .post<IActionResponse>(
                url,
                {},
                {
                    headers: this.authService.getHttpHeaders()
                }
            );


    }

    createMEVCSnapshot(station: IStation, device: IGeraet): Observable<IActionResponse> {
        if (device === null || device === undefined || device.id === null || device.id === undefined || device.id < 1) {
            return null;
        }
        if (station === null || station === undefined || station.id === null || station.id === undefined || station.id < 1) {
            return null;
        }
        const url = '/api/stationen/' + encodeURIComponent('' + station.id) + '/devices/' + encodeURIComponent('' + device.id) + '/_snapshotMEVCBox';

        return this.http
            .post<IActionResponse>(
                url,
                {},
                {
                    headers: this.authService.getHttpHeaders()
                }
            );

    }

    sendMEVCConfig(station: IStation, device: IGeraet): Observable<IActionResponse> {
        if (device === null || device === undefined || device.id === null || device.id === undefined || device.id < 1) {
            return null;
        }
        if (station === null || station === undefined || station.id === null || station.id === undefined || station.id < 1) {
            return null;
        }
        const url = '/api/stationen/' + encodeURIComponent('' + station.id) + '/devices/' + encodeURIComponent('' + device.id) + '/_sendConfigToMEVCBox';

        return this.http
            .post<IActionResponse>(
                url,
                {},
                {
                    headers: this.authService.getHttpHeaders()
                }
            );


    }

    sendMELoraGatewayConfig(station: IStation, device: IGeraet): Observable<IActionResponse> {
        if (device === null || device === undefined || device.id === null || device.id === undefined || device.id < 1) {
            return null;
        }
        if (station === null || station === undefined || station.id === null || station.id === undefined || station.id < 1) {
            return null;
        }
        const url = '/api/stationen/' + encodeURIComponent('' + station.id) + '/devices/' + encodeURIComponent('' + device.id) + '/_sendConfigToMELoraGateway';

        return this.http
            .post<IActionResponse>(
                url,
                {},
                {
                    headers: this.authService.getHttpHeaders()
                }
            );


    }

    sendMELoraGatewayDiscoveryRequest(station: IStation, device: IGeraet): Observable<IActionResponse> {
        if (device === null || device === undefined || device.id === null || device.id === undefined || device.id < 1) {
            return null;
        }
        if (station === null || station === undefined || station.id === null || station.id === undefined || station.id < 1) {
            return null;
        }
        const url = '/api/stationen/' + encodeURIComponent('' + station.id) + '/devices/' + encodeURIComponent('' + device.id) + '/_sendMELoraGatewayDiscoveryRequest';

        return this.http
            .post<IActionResponse>(
                url,
                {},
                {
                    headers: this.authService.getHttpHeaders()
                }
            );
    }


    sendMELoraGatewayPairingRequest(station: IStation, device: IGeraet): Observable<IActionResponse> {
        if (device === null || device === undefined || device.id === null || device.id === undefined || device.id < 1) {
            return null;
        }
        if (station === null || station === undefined || station.id === null || station.id === undefined || station.id < 1) {
            return null;
        }
        const url = '/api/stationen/' + encodeURIComponent('' + station.id) + '/devices/' + encodeURIComponent('' + device.id) + '/_sendMELoraGatewayPairingRequest';

        return this.http
            .post<IActionResponse>(
                url,
                {},
                {
                    headers: this.authService.getHttpHeaders()
                }
            );
    }

    checkSystemState(station: IStation, device: IGeraet): Observable<IActionResponse> {
        if (device === null || device === undefined || device.id === null || device.id === undefined || device.id < 1) {
            return null;
        }
        if (station === null || station === undefined || station.id === null || station.id === undefined || station.id < 1) {
            return null;
        }
        const url = '/api/stationen/' + encodeURIComponent('' + station.id) + '/devices/' + encodeURIComponent('' + device.id) + '/_checkSystemState';

        return this.http
            .post<IActionResponse>(
                url,
                {},
                {
                    headers: this.authService.getHttpHeaders()
                }
            );
    }
}
