import {Land} from '../../../commons/laender/common/land';
import {Station} from '../../../stationen/common/station';
import {Kunde} from '../common/kunde';
import {ChangeDetectorRef, EventEmitter} from '@angular/core';
import {ElementListComponent} from '../../../commons/element-list/element-list.component';

export class KundenFilter {
    public filterChanged: EventEmitter<void> = new EventEmitter<void>();
    private _customers: Kunde[] = [];
    private _countries: Land[] = [];
    private _stationen: Station[] = [];

    private _freitext = '';

    get countries(): Land[] {
        return this._countries;
    }

    set countries(value: Land[]) {
        if (value !== this._countries) {
            this._countries = value;
            this.filterChanged.emit();
            this.refresh(null);
        }
    }

    get freitext(): string {
        return this._freitext;
    }

    set freitext(value: string) {
        if (value !== this._freitext) {
            this._freitext = value;
            this.filterChanged.emit();
            this.refresh(null);
        }
    }

    get customers(): Kunde[] {
        return this._customers;
    }

    set customers(value: Kunde[]) {
        if (value !== this._customers) {
            this._customers = value;
            this.filterChanged.emit();
            this.refresh(null);
        }
    }

    get stationen(): Station[] {
        return this._stationen;
    }

    set stationen(value: Station[]) {
        if (value !== this._stationen) {
            this._stationen = value;
            this.filterChanged.emit();
            this.refresh(null);
        }
    }

    constructor(public cd: ChangeDetectorRef) {
    }

    private refresh(ec: ElementListComponent) {
        if (this.cd !== null && this.cd !== undefined) {
            this.cd.markForCheck();
        }
        if (ec !== null && ec !== undefined) {
            ec.cd.markForCheck();
        }
    }

    getQuery(): string {
        const parts: string[] = [];
        if (this._stationen !== null && this._stationen !== undefined && Array.isArray(this._stationen)) {
            const c: string[] = [];
            this._stationen.forEach((s: Station) => {
                c.push('((_station_id:' + s.nummer + ') OR (station:' + s.nummer + '))');
            });
            if (c.length > 0) {
                parts.push('( ' + c.join(' OR ') + ' )');
            }
        }
        if (this._customers !== null && this._customers !== undefined && Array.isArray(this._customers)) {
            const c: string[] = [];
            this._customers.forEach((s: Kunde) => {
                c.push('((_kunde_id:' + s.id + ') OR (kunde:' + s.nummer + '))');
            });
            if (c.length > 0) {
                parts.push('( ' + c.join(' OR ') + ' )');
            }
        }
        if (this._countries !== null && this._countries !== undefined && Array.isArray(this._countries)) {
            const c: string[] = [];
            this._countries.forEach((s: Land) => {
                c.push('((_land_id:' + s.id + ') OR (land:' + s.laendercode + '))');
            });
            if (c.length > 0) {
                parts.push('( ' + c.join(' OR ') + ' )');
            }
        }
        if (this._freitext !== null && this._freitext !== undefined && this._freitext.trim() !== '') {
            parts.push('(' + this._freitext + ')');
        }


        if (parts.length < 1) {
            return '';
        }
        return parts.join(' AND ');
    }

    clearStationen(ec: ElementListComponent) {
        this.stationen = [];
        this.refresh(ec);
    }

    addStation(station: Station, ec: ElementListComponent) {
        if (station != null && station !== undefined) {
            if (!Array.isArray(this._stationen)) {
                this._stationen = [];
            }
            let found = false;
            this._stationen.forEach((s: Station) => {
                if (s.id === station.id) {
                    found = true;
                }
            });
            if (!found) {
                this._stationen.push(station);
                this.filterChanged.emit();
            }
            this.refresh(ec);
        }
    }

    removeStation(station: Station, ec: ElementListComponent) {
        let found = false;
        this._stationen.forEach((s, idx) => {
            if (s.id === station.id) {
                this._stationen.splice(idx, 1);
                found = true;
            }
        });
        if (found) {
            this.filterChanged.emit();
        }
        this.refresh(ec);
    }

    clearCustomers(ec: ElementListComponent) {
        this.customers = [];
        this.refresh(ec);
    }

    addCustomer(kunde: Kunde, ec: ElementListComponent) {
        if (kunde != null && kunde !== undefined) {
            if (!Array.isArray(this._customers)) {
                this._customers = [];
            }
            let found = false;
            this._customers.forEach((s: Kunde) => {
                if (s.id === kunde.id) {
                    found = true;
                }
            });
            if (!found) {
                this._customers.push(kunde);
                this.filterChanged.emit();
            }
            this.refresh(ec);
        }
    }

    removeCustomer(kunde: Kunde, ec: ElementListComponent) {
        let found = false;
        this._customers.forEach((s, idx) => {
            if (s.id === kunde.id) {
                this._customers.splice(idx, 1);
                found = true;
            }
        });
        if (found) {
            this.filterChanged.emit();
        }
        this.refresh(ec);
    }

    clearCountries(ec: ElementListComponent) {
        this.countries = [];
        this.refresh(ec);
    }

    addCountry(land: Land, ec: ElementListComponent) {
        if (land != null && land !== undefined) {
            if (!Array.isArray(this._countries)) {
                this._countries = [];
            }
            let found = false;
            this._countries.forEach((s: Land) => {
                if (s.laendercode === land.laendercode) {
                    found = true;
                }
            });
            if (!found) {
                this._countries.push(land);
                this.filterChanged.emit();
            }
            this.refresh(ec);
        }
    }

    removeCountry(land: Land, ec: ElementListComponent) {
        let found = false;
        this._countries.forEach((s, idx) => {
            if (s.laendercode === land.laendercode) {
                this._countries.splice(idx, 1);
                found = true;
            }
        });
        if (found) {
            this.filterChanged.emit();
        }
        this.refresh(ec);
    }

}
