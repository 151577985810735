import {Nachbuchung} from './nachbuchung';
import {INachbuchung} from './inachbuchung';
import {INachbuchungDetail} from './inachbuchung-detail';
import {IStation} from '../../../stationen/common/istation';
import {GenericFilter} from '../../../commons/generic-filter';
import {IAusweis} from '../../../customers/ausweise/common/iausweis';
import {IWaehrung} from '../../../commons/waehrungen/iwaehrung';
import {ITerminal} from '../../../stationen/terminals/common/iterminal';

export class SimpleNachbuchungFilter extends GenericFilter<Nachbuchung> implements INachbuchung {
    id: number = null;
    nummer: number = null;
    ausweis: IAusweis = null;
    zeitpunkt: any = null;
    belegnummer: number = null;
    waehrung: IWaehrung = null;
    pan: string = null;
    station: IStation = null;
    terminal: ITerminal = null;
    details: INachbuchungDetail[] = null;
    dateCreated: any = null;
    lastUpdated: any = null;
    betrag: number = null;
    b_betrag: number = null;
    mwst: number = null;
    kursWert: number = null;
    betrag_euro: number = null;
    b_betrag_euro: number = null;
    positionen: number = null;
    transfer: boolean = null;
    verbucht: boolean = null;
}
