import {ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

import {
    TransaktionenImportDefinition
} from '../common/transaktionen-import-definition';
import {TransaktionService} from '../common/transaktion.service';
import {GenericModalComponent} from '../../../commons/generic-modal.component';
import {LoginService} from '../../../auth/services/login.service';
import {DataTableComponent} from '../../../commons/data-table/data-table.component';
import {StationRewrite} from '../common/station-rewrite';
import {EImportFileType} from '../common/eimport-file-type';
import {TerminalCellRendererComponent} from '../../../stationen/terminal-cell-renderer.component';
import {ToastrService} from 'ngx-toastr';
import {PubSubService} from '../../../commons/pub-sub.service';
import {RowActionParams} from '../../../commons/data-table/row-action-params';

@Component({
               selector   : 'app-transaktionen-importer',
               templateUrl: './transaktionen-importer.component.html',
               styleUrls  : [ './transaktionen-importer.component.scss' ]
           })
export class TransaktionenImporterComponent extends GenericModalComponent<TransaktionenImportDefinition> {

    terminalR=TerminalCellRendererComponent;


    newRewrite: StationRewrite = null;
    @ViewChild('stationRewritesTable',{static: true}) stationRewritesTable: DataTableComponent;


    public initEntity(src: TransaktionenImportDefinition): TransaktionenImportDefinition {
        return new TransaktionenImportDefinition();
    }

    constructor(

                public loginService: LoginService,
                public activeModal: NgbActiveModal,
                public transaktionService: TransaktionService,
                public toastr: ToastrService,
                public cd: ChangeDetectorRef,
                public pubsub: PubSubService
    ) {
        super( loginService, activeModal, cd, pubsub);
    }


    canImport(): boolean {
        if (this.entity === null || this.entity === undefined) {
            return false;
        }
        if (this.entity.file === null || this.entity.file === undefined) {
            return false;
        }
        if (this.entity.type === EImportFileType.UNKNOWN) {
            return false;
        }
        return true;
    }

    public fileSelect(e: FileList): void {
        if (e.length > 0) {

            const f: File = e.item(0);

            this.entity.file = f;
        }
    }

    doImport() {
        if (this.canImport()) {
            this.transaktionService
                .uploadImport(this.entity)
                .subscribe(
                    (r) => {
                        if (r.success) {
                            this.toastr.info(this._('Der Importvorgang wurde gestartet und läuft nun im Hintergrund weiter. ' +
                                'Sie können nun weiter arbeiten und auch dieses Fenster schließen. Je nach Dateigröße nimmt der ' +
                                'eigentliche Vorgang noch einige Zeit in Anspruch...'));
                            this.ok();
                        } else {
                            this.toastr.error(this._('Fehler beim Importieren der Transaktionsdaten.'));
                        }

                    },
                    () => {
                        this.toastr.error(this._('Fehler beim Importieren der Transaktionsdaten.'));
                    }
                );
        }
    }

    addRewrite() {
        this.newRewrite             = new StationRewrite();
        this.newRewrite.source       = '';
        this.newRewrite.destination = null;
        this.stationRewritesTable.showEditor();
    }

    saveRewrite(evt: any) {
        this.entity.addStationRewrite(this.newRewrite);
        this.stationRewritesTable.cancelNew();
        this.stationRewritesTable.data = this.entity._stationsRewrites;
    }

    deleteRewrite(row: RowActionParams<StationRewrite>) {
        this.entity.removeStationsRewrite(row.row.data);
        this.stationRewritesTable.data = this.entity._stationsRewrites;
    }

}
