import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {TransaktionService} from '../common/transaktion.service';
import {ITransaktionenStatsRow} from '../common/itransaktionen-stats-row';
import {SysInfoService} from '../../../commons/sysinfo/services/sys-info.service';
import {Subscription} from 'rxjs';
import {LocalizedComponent} from '../../../commons/components/localized-component';

import {PubSubService} from '../../../commons/pub-sub.service';

@Component({
    selector: 'app-dashboard-stats-block',
    templateUrl: './dashboard-stats-block.component.html',
    styleUrls: ['./dashboard-stats-block.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DashboardStatsBlockComponent extends LocalizedComponent implements OnInit, OnDestroy {

    subs: Subscription[] = [];
    stats: ITransaktionenStatsRow[] = null;
    prefersBrutto = false;

    get umsatzLabel() {
        if (this.prefersBrutto) {
            return this._('Umsatz (brutto)');
        }
        return this._('Umsatz');
    }

    constructor(


        public transaktionService: TransaktionService,
        public sysInfoService: SysInfoService,
        public cd: ChangeDetectorRef,
        public pubSubService: PubSubService
    ) {
        super( cd);
        this.prefersBrutto = this.sysInfoService.hasModule('prefers_brutto');
    }

    loadStats() {
        this.prefersBrutto = this.sysInfoService.hasModule('prefers_brutto');
        this.transaktionService.loadStats().subscribe(
            (ar: ITransaktionenStatsRow[]) => {
                this.stats = ar;
                this.cd.markForCheck();
            },
            () => {
                this.stats = null;
                this.cd.markForCheck();
            }
        );
    }

    ngOnInit() {
        this.loadStats();
        this.subs.push(
            this.pubSubService.sysInfoChange.subscribe(
                () => {
                    this.prefersBrutto = this.sysInfoService.hasModule('prefers_brutto');
                    this.cd.markForCheck();
                }
            ));
    }

    ngOnDestroy(): void {
        this.subs.forEach((s: Subscription) => {
            s.unsubscribe();
        });
        this.subs = [];
    }


    hasStats() {
    return this.sysInfoService.seesTransactions() &&
        this.stats !== null && this.stats !== undefined && Array.isArray(this.stats) && this.stats.length > 0;
    }

    getUmsatz(thisMonth = true) {
        let u = 0;
        if (this.hasStats()) {
            this.stats.forEach((st: ITransaktionenStatsRow) => {
                if (thisMonth) {
                    if (this.prefersBrutto) {
                        u = u + st.sumTransaktionThisMonthBrutto;
                    } else {
                        u = u + st.sumTransaktionThisMonth;
                    }
                } else {
                    if (this.prefersBrutto) {
                        u = u + st.sumTransaktionLastMonthBrutto;
                    } else {
                        u = u + st.sumTransaktionLastMonth;
                    }
                }
            });
        }
        return u;
    }

    getTransactions(thisMonth = true) {
        let t = 0;
        if (this.hasStats()) {
            this.stats.forEach((st: ITransaktionenStatsRow) => {
                if (thisMonth) {
                    t = t + st.countTransaktionThisMonth;
                } else {
                    t = t + st.countTransaktionLastMonth;
                }
            });
        }
        return t;
    }

    getKgs(thisMonth = true) {
        let t = 0;
        if (this.hasStats()) {
            this.stats.forEach((st: ITransaktionenStatsRow) => {
                if (thisMonth) {
                    t = t + st.kgThisMonth;
                } else {
                    t = t + st.kgLastMonth;
                }
            });
        }
        return t;
    }

    getLiters(thisMonth = true) {
        let t = 0;
        if (this.hasStats()) {
            this.stats.forEach((st: ITransaktionenStatsRow) => {
                if (thisMonth) {
                    t = t + st.literThisMonth;
                } else {
                    t = t + st.literLastMonth;
                }
            });
        }
        return t;
    }

    getTrendClasses(what: string) {
        const classes = [];
        let lm = 0;
        let tm = 0;
        if (what === 'umsatz') {
            tm = this.getUmsatz();
            lm = this.getUmsatz(false);
        } else if (what === 'transaktionen') {
            tm = this.getTransactions();
            lm = this.getTransactions(false);
        } else if (what === 'kgs') {
            tm = this.getKgs();
            lm = this.getKgs(false);
        } else if (what === 'l') {
            tm = this.getLiters();
            lm = this.getLiters(false);
        }


        if (tm > lm) {
            classes.push('fa-chevron-circle-up');
            classes.push('text-success');
        } else if (tm < lm) {
            classes.push('fa-chevron-circle-down');
            classes.push('text-danger');
        } else {
            classes.push('fa-chevron-circle-right');
            classes.push('text-muted');
        }

        return classes;
    }
}
