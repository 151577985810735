<data-table
        [selectable]="false"
        [data]="artikelgruppen.asDataTableRows()"
        [orders]="artikelgruppen.order"
        [filter]="artikelgruppen.simpleFilter"
        (sortChanged)="sortChanged($event)"
        (filterChanged)="filterChanged($event)"

>
    <column [field]="'bezeichnung'" [label]="t('Bezeichnung')" [sortable]="true" [filterable]="false"></column>

    <data-table-table-extra-header *ngIf="false">
        <app-paginator
                [total]="artikelgruppen.total"
                [page]="artikelgruppen.page"
                [perpage]="artikelgruppen.size"
                (pageChange)="updateArtikelgruppen(true,$event)"
                (pageCountChange)="updateArtikelgruppenPageCount($event)"
        ></app-paginator>

    </data-table-table-extra-header>
</data-table>

