export class Tick {
    pos = 0;
    labels: string[] = [''];


    constructor(pos: number, labels: string[]) {
        this.pos = pos;
        this.labels = labels;

    }
}