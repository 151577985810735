<app-login-alert>

  <app-area-heading
          [icon]="'far fa-users'"
          title="{{ _('Personen') }}"
  ></app-area-heading>

  <div class="card">
    <div class="card-body">
      <div class="card-text">
        <data-table
                [selectable]="false"
                [data]="personen.asDataTableRows()"
                [orders]="personen.order"
                [filter]="personen.simpleFilter"
                (sortChanged)="sortChanged($event)"
                (filterChanged)="filterChanged($event)"
        >
          <column
                  [field]="'nummer'"
                  [label]="_('Nummer')"
                  type="integer"
                  [filterable]="true"
                  [sortable]="true"></column>
          <column
                  [field]="'kunde'"
                  [label]="_('Kunde')"
                  [renderer]="kundeR"
                  [filterable]="true"
                  [sortable]="true"
          ></column>
          <column
                  [field]="'name'"
                  [label]="_('Name/Bezeichnung')"
                  type="text"
                  [filterable]="true"
                  [sortable]="true"></column>
          <column
                  [field]="'personalnummer'"
                  [label]="_('Personalnummer')"
                  type="text"
                  [filterable]="true"
                  [sortable]="true"></column>
          <column
                  [field]="'ausweis'"
                  [label]="_('Ausweis')"
                  [renderer]="ausweisR"
                  [cfg]="{info_position:'left'}"
                  [filterable]="false"
                  [sortable]="true"></column>

          <tableaction
                  [label]="t('Person anlegen')"
                  [icon]="'icofont icofont-ui-add'"
                  (perform)="newPerson()"
          ></tableaction>

          <rowaction
                  [label]="_('Bearbeiten')"
                  [icon]="'icofont icofont-ui-edit'"
                  (perform)="editPerson($event)"
          ></rowaction>

        </data-table>

        <div class="row">
          <div class="col-6"></div>
          <div class="col-6 text-right">
            <app-paginator
                    [total]="personen.total"
                    [page]="personen.page"
                    [perpage]="personen.size"
                    (pageChange)="updatePersonen(true,$event)"
                    (pageCountChange)="updatePersonenPageCount($event)"
            ></app-paginator>
          </div>
        </div>

      </div>
    </div>
  </div>

</app-login-alert>