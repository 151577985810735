<div class="modal-header">
  <h5 class="modal-title">{{ msg_title }}</h5>
  <button type="button" class="close" aria-label="Close" (click)="cancel()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">


  <data-table [selectable]="false"
                  [data]="terminals.asDataTableRows()"
                  #terminalsTable
  >

    <column [field]="'nummer'" [label]="t('Nummer')" [type]="'Integer'"></column>
    <column [field]="'bezeichnung'" [label]="t('Bezeichnung')"></column>
    <column [field]="'geeicht'" [label]="t('geeichte Anlage')" [type]="'Boolean'"></column>
    <column [field]="'numSonden'" [label]="t('# Sonden')" [type]="'Integer'"></column>
    <column [field]="'numSaeulen'" [label]="t('# Zapfsäulen')" [type]="'Integer'"></column>
    <column [field]="'ifsfEnabled'" [label]="t('IFSF aktiv')" [type]="'Boolean'" *hasRole="['ROLE_ADMIN','ROLE_PROJECT_ADMIN']"></column>

    <tableaction
            *appHasRight="'ROLE_PROJECT_ADMIN'"
            [label]="t('Terminal anlegen')"
            [icon]="'icofont icofont-ui-add'"
            (perform)="addTerminal()"
    ></tableaction>
    <rowaction
            *appHasRight="'ROLE_PROJECT_ADMIN'"
            [label]="_('Terminal bearbeiten')"
            [icon]="'icofont icofont-ui-edit'"
            (perform)="editTerminal($event)"
    ></rowaction>
    <rowaction
            *appHasRight="'ROLE_PROJECT_ADMIN'"
            [label]="_('Terminal löschen')"
            [icon]="'icofont icofont-ui-delete'"
            (perform)="removeTerminal($event)"
    ></rowaction>

  </data-table>

  <div class="row">
    <div class="col-6"></div>
    <div class="col-6 text-right">
      <app-paginator
              [total]="terminals.total"
              [page]="terminals.page"
              [perpage]="terminals.size"
              (pageChange)="updateTerminals(true,$event)"
              (pageCountChange)="updateTerminals($event)"
      ></app-paginator>
    </div>
  </div>


</div>
<div class="modal-footer">
  <button type="button" class="btn btn-secondary" (click)="cancel()">
    <i class="icofont icofont-close"></i> {{ _('Schließen') }}
  </button>
</div>
