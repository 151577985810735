 <form class="form-inline">
    <input class="form-control"
           type="search"
           placeholder="{{_('Suche...')}}"
           [(ngModel)]="query"
           name="searchBox"
           (keypress)="kp($event)"
    >
    <button class="btn btn-outline" type="submit" (click)="doSearch()">
      <i class="far fa-search"></i>
    </button>
  </form>