import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {AttachmentUpload} from './attachment-upload';
import {Attachment} from './attachment';
import {LoginService} from '../../../auth/services/login.service';
import {IActionResponse} from '../../../commons/action-response';
import {HttpClient} from '@angular/common/http';

@Injectable({
                providedIn: 'root'
            })
export class AttachmentService {

    constructor(public http: HttpClient,
                public loginService: LoginService) {

    }


    uploadAttachment(upload: AttachmentUpload): Observable<IActionResponse> {
        const url = '/api/attachments';
        const formData: FormData = new FormData();

        if (upload.file === null || upload.file === undefined) {
            formData.append('furl', upload.url);
        } else {
            formData.append('fdata', upload.file, upload.file.name);
        }
        formData.append('fsize', '' + upload.size);
        formData.append('fname', upload.filename);
        formData.append('name', upload.name);
        formData.append('ftype', '' + upload.type);


        const headers = this.loginService.getHttpHeaders(false);
        headers.append('Accept', 'application/json');

        return this
            .http
            .post<IActionResponse>(url, formData, {headers: headers});

    }

    downloadAttachment(attachment: Attachment): Observable<Blob> {
        return this.http
            .get(
                attachment.getURI(),
                {
                    headers: this.loginService.getHttpHeaders(false),
                    responseType: 'blob'
                }
            );
    }

}
