<div class="modal-header">
    <h5 class="modal-title">{{ _('Transaktionsdetails') }}</h5>
    <button type="button" class="close" aria-label="Close" (click)="cancel()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">

    <app-tabset>
        <app-pane [title]="_('Identifikation')">
            <table class="table">
                <tr>
                    <th scope="row" class="w-20">{{ _('Zeitpunkt')}}</th>
                    <td>{{ fDate(entity.zeitpunkt,'medium') }}</td>
                </tr>
                <tr>
                    <th scope="row" class="w-20">{{ _('Belegnummer') }}</th>
                    <td>{{ entity.belegnummer }}</td>
                </tr>
                <tr>
                    <th scope="row" class="w-20">{{ _('ISO-Belegnummer')}}</th>
                    <td>{{ entity.ISOBelegnummer }}</td>
                </tr>
                <tr>
                    <th scope="row" class="w-20">{{ _('Referenz-ID')}}</th>
                    <td>{{ entity.referenzID }}</td>
                </tr>
                <tr>
                    <th scope="row" class="w-20">{{ _('UUID')}}</th>
                    <td>{{ entity.guid }}</td>
                </tr>
            </table>
        </app-pane>
        <app-pane [title]="_('Positionen')">
            <data-table
                    [selectable]="false"
                    [data]="entity.detailRows()"
            >
                <column
                        [field]="'pos'"
                        [label]="_('Position')"
                        type="Integer"
                ></column>
                <column
                        [field]="'produkt'"
                        [label]="_('Produkt')"
                        [renderer]="produktR"
                ></column>
                <column
                        *ngIf="false"
                        [field]="'tank'"
                        [label]="_('Tank')"
                        [renderer]="tankR"
                ></column>
                <column
                        *ngIf="false"
                        [field]="'zapfpunkt'"
                        [label]="_('Zapfpunkt')"
                        [renderer]="zapfpunktR"
                ></column>
                <column
                        [field]="'zweck'"
                        [label]="_('Info')"
                ></column>
                <column
                        [field]="'menge'"
                        [label]="_('Menge')"
                        [renderer]="mengeR"
                        [cfg]="{ produktField:'produkt' }"
                ></column>
                <column
                        [field]="'epreis'"
                        [label]="_('Einzelpreis')"
                        [renderer]="preiswert"
                        [cfg]="{ produktField:'produkt' ,currency:'EUR' }"
                ></column>
                <column
                        [field]="'preis'"
                        [label]="_('Preis')"
                        [renderer]="preiswert"
                        [cfg]="{ currency:'EUR',fdigits:2 }"
                ></column>
                <column
                        [field]="'b_epreis'"
                        [label]="_('Einzelpreis (brutto)')"
                        [renderer]="preiswert"
                        [cfg]="{ produktField:'produkt',currency:'EUR' }"
                ></column>
                <column
                        [field]="'b_preis'"
                        [label]="_('Preis (brutto)')"
                        [renderer]="preiswert"
                        [cfg]="{ currency:'EUR',fdigits:2 }"
                ></column>

            </data-table>
            <div class="row">
                <div class="col-6">
                </div>
                <div class="col-6">
                </div>
            </div>
        </app-pane>
        <app-pane
                [title]="_('Ausweis')"
                *ngIf="hasAusweis1()"
        >
            <table class="table">
                <tr>
                    <th scope="row">{{ _('gesendete Ausweiskennung') }}</th>
                    <td>
                        {{ entity.ausweiskennung1 }}
                    </td>
                </tr>
                <tr *ngIf="entity.ausweis1!==undefined && entity.ausweis1!==null">
                    <th scope="row">{{ _('Ausweis') }}</th>
                    <td>
                        <app-ausweis-display
                                [ausweis]="entity.ausweis1"
                        ></app-ausweis-display>
                    </td>

                </tr>
            </table>
        </app-pane>
        <app-pane
                [title]="_('Ausweis 2')"
                *ngIf="hasAusweis2()"
        >
            <table class="table">
                <tr>
                    <th scope="row">{{ _('gesendete Ausweiskennung') }}</th>
                    <td>
                        {{ entity.ausweiskennung2 }}
                    </td>
                </tr>
                <tr *ngIf="entity.ausweis2!==undefined && entity.ausweis2!==null">
                    <th scope="row">{{ _('Ausweis') }}</th>
                    <td>
                        <app-ausweis-display
                                [ausweis]="entity.ausweis2"
                        ></app-ausweis-display>
                    </td>

                </tr>
            </table>
        </app-pane>
        <app-pane [title]="_('Fahrzeug')" *ngIf="entity.fahrzeug!==null && entity.fahrzeug!==undefined">
            <app-fahrzeug-display [fahrzeug]="entity.fahrzeug"></app-fahrzeug-display>
        </app-pane>
        <app-pane [title]="_('Person/Fahrer')" *ngIf="entity.person!==null && entity.person!==undefined">
            <app-person-display [person]="entity.person"></app-person-display>
        </app-pane>
        <app-pane
                [title]="_('Station')"
                *ngIf="entity.station!==undefined && entity.station!==null"
        >
            <app-station-display
                    [station]="entity.station"
            ></app-station-display>
        </app-pane>
        <app-pane [title]="_('Terminal')" *ngIf="false && entity.terminal!==undefined && entity.terminal!==null">
            <app-terminal-display [terminal]="entity.terminal"></app-terminal-display>
        </app-pane>
        <app-pane [title]="_('Kunde')" *ngIf="entity.kunde!==null && entity.kunde!==undefined">
            <app-kunde-display [kunde]="entity.kunde"></app-kunde-display>

        </app-pane>


    </app-tabset>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="cancel()">
        <i class="icofont icofont-close"></i> {{ _('Schließen') }}
    </button>
</div>
