import {Component, Injector, OnInit} from '@angular/core';
import {IAusweis} from '../../common/iausweis';
import {DataTableRow} from '../../../../commons/data-table/data-table-row';
import {DataTableColumnDirective} from '../../../../commons/data-table/data-table-column.directive';
import {Ausweis} from '../../common/ausweis';
import {AusweisArt} from '../../common/ausweis-art';

@Component({
    selector: 'app-ausweise-renderer',
    templateUrl: './ausweise-renderer.component.html',
    styleUrls: ['./ausweise-renderer.component.scss']
})
export class AusweiseRendererComponent {

    info_position = 'right';
    cell: IAusweis[] = null;
    row: DataTableRow<any> = null;
    column: DataTableColumnDirective = null;
    ausweisArt: string[] = [];


    get ausweise(): Ausweis[] {
        const a: Ausweis[] = [];
        if (this.cell !== null && this.cell !== undefined && Array.isArray(this.cell)) {
            this.cell.forEach((aw: IAusweis) => {
                const ausweis = Ausweis.fromResult(aw);
                if (this.ausweisArt == null || !Array.isArray(this.ausweisArt) || this.ausweisArt.length < 1) {
                    a.push(ausweis);
                } else {
                    if (AusweisArt.isOneOf(ausweis.ausweisArt, this.ausweisArt)) {
                        a.push(ausweis);
                    }
                }
            });
        }
        return a;
    }

    constructor(public injector: Injector) {

        this.cell = this.injector.get('cell');
        this.row = this.injector.get('row');
        this.column = this.injector.get('column');

        if (this.column !== null && this.column !== undefined) {
            if ((this.column.cfg !== null) && (this.column.cfg !== undefined)) {
                if (this.column.cfg.info_position !== null) {
                    this.info_position = this.column.cfg.info_position;
                }
                if (this.column.cfg.ausweisArt !== null && Array.isArray(this.column.cfg.ausweisArt)) {
                    this.ausweisArt = this.column.cfg.ausweisArt;
                }
            }
        }

    }
}
