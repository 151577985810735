import {ChangeDetectorRef, Component, OnInit} from '@angular/core';

import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

import {IssuerEditorComponent} from './editor/issuer-editor.component';
import {LocalizedComponent} from '../components/localized-component';
import {IssuerList} from './common/issuer-list';
import {KitchenTimer} from '../kitchen-timer';
import {SystemSettingsService} from '../system-settings.service';
import {Issuer} from './common/issuer';
import {ConfirmDialogComponent} from '../confirm-dialog/confirm-dialog.component';
import {NewPageCountEvent} from '../paginator/paginator.component';
import {ToastrService} from 'ngx-toastr';
import {IListResult} from '../list-result';
import {IIssuer} from './common/iissuer';
import {RowActionParams} from '../data-table/row-action-params';

@Component({
  selector: 'app-tab-issuer',
  templateUrl: './tab-issuer.component.html',
  styleUrls: ['./tab-issuer.component.css']
})
export class TabIssuerComponent extends LocalizedComponent implements OnInit {

  issuers: IssuerList = new IssuerList();
  public issuersTimer: KitchenTimer;

  constructor(

              public systemsettingsService: SystemSettingsService,
              public toastr: ToastrService,
              public modalService: NgbModal,
              public cd: ChangeDetectorRef
  ) {
    super( cd);
    this.issuersTimer = new KitchenTimer();
    this.issuersTimer.subscribe(() => {
      this.systemsettingsService
          .listIssuers(
              this.issuers.size,
              this.issuers.calcOffset(),
              this.issuers.order,
              this.issuers.simpleFilter
          )
          .subscribe(
              (s: IListResult<IIssuer>) => {
                this.issuers.populateFromListResult(s);
              },
              () => {
                this.toastr.error(this._('Leider konnte die Liste der verfügbaren Kartenanbieter nicht geladen werden.'));
                this.issuers.clear();
              }
          );
    });
  }

  ngOnInit() {
    this.issuersTimer.fireWhenNotBefore();
  }

  addIssuer() {
    IssuerEditorComponent
        .open(this.modalService, null)
        .then(
            e => {
              this.updateIssuers(true);
            },
            () => {
              this.updateIssuers(true);
            }
        );
  }

  editIssuer(event: RowActionParams<Issuer>) {
    IssuerEditorComponent
        .open(this.modalService, event.row.data as Issuer)
        .then(
            e => {
              this.updateIssuers(true);
            },
            () => {
              this.updateIssuers(true);
            }
        );
  }

  deleteIssuer(event: RowActionParams<Issuer>) {
    ConfirmDialogComponent.display(
        this.modalService,
        this.t('Herausgeber löschen'),
        this.t('Sollen der Herausgeber {{ bezeichnung }} ' +
               'wirklich unwideruflich gelöscht werden?', {bezeichnung: event.row.data.bezeichnung}),
        this.t('Herausgeber jetzt löschen')
    ).result
                          .then(
                              e => {
                                this.systemsettingsService
                                    .removeIssuer(event.row.data)
                                    .subscribe(
                                        r => {
                                          if (r.success) {
                                            this.toastr.success(this.t('Der Kartenherausgeber wurde gelöscht.'));
                                            this.updateIssuers(true);
                                          } else {
                                            this.toastr.error(this.t('Das Löschen des Herausgebers ist fehlgeschlagen'));
                                            this.updateIssuers(true);
                                          }
                                        },
                                        () => {
                                          this.toastr.error(this.t('Das Löschen der Anrede ist fehlgeschlagen'));
                                          this.updateIssuers(true);
                                        }
                                    );
                              },
                              k => {
                              }
                          );
  }

  updateIssuers(force = false, page?: number) {
    if ((page !== undefined) && (page != null)) {
      if (page !== this.issuers.page) {
        this.issuers.page = page;
        force = true;
      }
    }
    this.issuersTimer.start(force);
  }


  updateIssuersPageCount(n: NewPageCountEvent) {
    this.issuers.size = n.itemsPerPage;
    this.updateIssuers(true, n.currentPage);
  }

}
