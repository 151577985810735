import {ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Preisgruppe} from '../common/preisgruppe';

@Component({
    selector: 'preisgruppe-field',
    template: `
        <div class="form-group row">
            <label for="{{ id }}" class="col-{{ labelwidth }} col-form-label" *ngIf="labelwidth>0">
                {{ label }}
            </label>
            <div class="col-{{ 12-labelwidth }}">
                <preisgruppe-inline-field
                        id="{{ id }}"
                        [label]="label"
                        [value]="_value"
                        (valueChange)="doSelect($event)"
                        [small]="small"
                        [nullable]="nullable"
                        [disabled]="disabled"
                ></preisgruppe-inline-field>
            </div>
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PreisgruppeFieldComponent implements OnInit {
    @Input() nullable = true;
    @Input() small = false;
    public _value: Preisgruppe = null;
    @Input() label = 'Preisgruppefeld';
    @Input() id = '';
    @Input() labelwidth = 3;
    @Input() disabled = false;

    @Input()
    set value(v: Preisgruppe) {

        if (this._value !== v) {
            this._value = v;
            this.valueChange.emit(v);
            this.markChanged();
        }

    }

    @Output() valueChange = new EventEmitter<Preisgruppe>();


    get value(): Preisgruppe {
        return this._value;
    }


    constructor(public cd: ChangeDetectorRef) {
    }

    markChanged() {
        if (this.cd !== null && this.cd !== undefined) {
            this.cd.markForCheck();
        }
    }

    ngOnInit() {
    }

    doSelect(preisgruppe: Preisgruppe) {
        this._value = preisgruppe;
        this.valueChange.emit(preisgruppe);
    }

}
