import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {Kunde} from '../common/kunde';

import {LocalizedComponent} from '../../../commons/components/localized-component';

@Component({
               selector   : 'app-kunde-quick-info',
               templateUrl: './kunde-quick-info.component.html',
               styleUrls  : [ './kunde-quick-info.component.css' ]
           })
export class KundeQuickInfoComponent extends LocalizedComponent implements OnInit {

    _kunde: Kunde = null;
    _label = '';
    _info = '';

    @Input()
    set kunde(value: Kunde) {
        this._label = '';
        this._info  = '';
        this._kunde = Kunde.fromResult(value);
        if ((this._kunde !== undefined) && (this._kunde !== null)) {
            if (this._kunde.kundennummer !== undefined && this._kunde.kundennummer !== null && this._kunde.kundennummer !== '') {
                this._label = this._kunde.kundennummer;
                this._info  = this._('Kundennummer {{ kundennummer }}', {kundennummer: this._kunde.kundennummer});
            } else {
                this._label = '#' + this._kunde.nummer;
                this._info  = this._('Datensatz Nummer {{ nummer }} ', {nummer: this._kunde.nummer});
            }
        }

    }

    @Input() info_position = 'right';
    @Input() showLabel = true;

    getIcon() {
        return 'far fa-address-book';
    }

    getTitle() {
        if (this._kunde !== null) {
            return this._(' Kunde {{ nummer }} ', {nummer: this._kunde.nummer});
        }
        return '';
    }

    constructor( public cd:ChangeDetectorRef) {
        super(cd);
    }

    ngOnInit() {
    }

}
