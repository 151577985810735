<div class="card">
    <div class="card-header">
        <ul class="nav nav-pills card-header-pills">
            <li class="nav-item"
                *ngFor="let p of panes"
            >
                <a class="nav-link"
                   [class.disabled]="p.disabled"
                   [class.active]="p.active"
                   (click)="select(p)">{{ p.title }} </a>
            </li>
        </ul>
    </div>
    <div class="card-body tab-content">
        <ng-content></ng-content>
    </div>
</div>