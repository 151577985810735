import {ChangeDetectorRef, Component, NgZone, OnInit, ViewChild} from '@angular/core';
import {AusweisList} from './common/ausweis-list';

import {AusweisService} from './common/ausweis.service';

import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {AusweisEditorComponent} from './editor/ausweis-editor.component';
import {Ausweis} from './common/ausweis';
import {PinDialogComponent} from './pin-dialog/pin-dialog.component';
import {DTFahrzeugKfzkennzeichenRendererComponent} from '../fahrzeuge/renderer/dtfahrzeug-kfzkennzeichen-renderer.component';
import {KundenAusweisEditorComponent} from './editor/kunden-ausweis-editor.component';
import {KontingenteCellRendererComponent} from '../limits/renderer/kontingente-cell-renderer/kontingente-cell-renderer.component';
import {KundenService} from '../kunden/common/kunden.service';
import {KundeSelfEditorComponent} from '../kunden/kunde-self-editor/kunde-self-editor.component';
import {LocalizedComponent} from '../../commons/components/localized-component';
import {KitchenTimer} from '../../commons/kitchen-timer';
import {LoginService} from '../../auth/services/login.service';
import {NewPageCountEvent} from '../../commons/paginator/paginator.component';
import {DataTableComponent, DataTableFilterChanged, DataTableSortChanged} from '../../commons/data-table/data-table.component';
import {ResultMetaSortImpl} from '../../commons/result-meta';
import {KundeCellRendererComponent} from '../kunden/kunde-cell-renderer.component';
import {IssuerCellRendererComponent} from '../../commons/data-table/renderer/issuer-cell-renderer.component';
import {SysInfoService} from '../../commons/sysinfo/services/sys-info.service';
import {ToastrService} from 'ngx-toastr';
import {Kunde} from '../kunden/common/kunde';
import {IListResult} from '../../commons/list-result';
import {IAusweis} from './common/iausweis';
import {BegrenzungenCellRendererComponent} from '../limits/renderer/begrenzungen-cell-renderer/begrenzungen-cell-renderer.component';
import {AusweisFilterDialogComponent} from './ausweis-filter-dialog/ausweis-filter-dialog.component';
import {ConfirmDialogComponent} from '../../commons/confirm-dialog/confirm-dialog.component';
import {noop} from '../../commons/noop';
import * as FileSaver from 'file-saver';
import {RowActionParams} from '../../commons/data-table/row-action-params';

@Component({
    selector: 'app-ausweise',
    templateUrl: './ausweise.component.html',
    styleUrls: ['./ausweise.component.scss']
})
export class AusweiseComponent extends LocalizedComponent implements OnInit {
    kfzRenderer = DTFahrzeugKfzkennzeichenRendererComponent;
    kontingentCellRenderer = KontingenteCellRendererComponent;
    kundeR = KundeCellRendererComponent;
    issuerR = IssuerCellRendererComponent;
    begrenzungenCellRenderer = BegrenzungenCellRendererComponent;

    ausweise: AusweisList = new AusweisList();
    ausweiseTimer: KitchenTimer;

    @ViewChild('ausweisListe',{static: true}) ausweisListe: DataTableComponent;
    @ViewChild('filterDialog',{static: true}) filterDialog: AusweisFilterDialogComponent;

    constructor(

                public ausweisService: AusweisService,
                public toastr: ToastrService,
                public zone: NgZone,
                public modalService: NgbModal,
                public loginService: LoginService,
                public kundenService: KundenService, public sysInfoService: SysInfoService,
                public cd: ChangeDetectorRef) {
        super( cd);
        this.ausweise.size = this.loginService.getPageSize('ausweise');

        this.ausweiseTimer = new KitchenTimer();
        this.ausweiseTimer
            .subscribe(
                () => {
                    this.ausweisService
                        .list(
                            this.ausweise.size,
                            this.ausweise.calcOffset(),
                            this.ausweise.order,
                            this.ausweise.simpleFilter,
                            this.ausweise.getQuery()
                        )
                        .subscribe(
                            (l: IListResult<IAusweis>) => {
                                this.ausweise.populateFromListResult(l);
                            },
                            (e) => {
                                this.toastr.error(this._('Die Liste der Ausweise konnte nicht geladen werden.'));
                                this.ausweise.clear();
                            }
                        );
                }
            );
    }

    ngOnInit() {
        this.updateAusweise();
        this.sysInfoService.updateDocumentTitle(this._('Ausweise'));

    }

    updateAusweise(force = false, page?: number) {
        if ((page !== undefined) && (page != null)) {
            if (page !== this.ausweise.page) {
                this.ausweise.page = page;
                force = true;
            }
        }
        this.ausweiseTimer.start(force);
    }

    /**
     * ändert die Anzahl der Datensätze/Seite und lädt die Liste neu
     * @param n
     */
    updateAusweisePageCount(n: NewPageCountEvent) {
        if (n.itemsPerPage !== this.ausweise.size) {
            this.loginService.setPageSize('ausweise', n.itemsPerPage);
            this.ausweise.size = n.itemsPerPage;
        }
        this.updateAusweise(true, n.currentPage);
    }

    sortChanged(e: DataTableSortChanged) {
        this.ausweise.order = [
            new ResultMetaSortImpl(e.field, e.direction)
        ];
        this.updateAusweise();
    }

    filterChanged(e: DataTableFilterChanged) {
        this.zone.run(() => {
            this.ausweise.simpleFilter[e.field] = e.value;
            this.updateAusweise();
        });
    }

    newAusweis() {
        AusweisEditorComponent
            .open(this.modalService)
            .then(
                () => {
                    this.updateAusweise();
                },
                () => {
                    this.updateAusweise();
                }
            );
    }

    editAusweis(params: RowActionParams<Ausweis>) {
        this.ausweisService.loadAusweis(params.row.data.id).subscribe((a: IAusweis) => {
            AusweisEditorComponent
                .open(this.modalService, Ausweis.fromResult(a))
                .then(
                    () => {
                        this.updateAusweise();
                    },
                    () => {
                        this.updateAusweise();
                    }
                );
        });
    }

    editOptions(params: RowActionParams<Ausweis>) {

        KundenAusweisEditorComponent
            .open(this.modalService, params.row.data)
            .then(
                () => {
                    this.updateAusweise();
                },
                () => {
                    this.updateAusweise();
                }
            );

    }


    setPin(params: RowActionParams<Ausweis>) {
        if (!params.row.data.fremdausweis) {

            PinDialogComponent
                .open(this.modalService, params.row.data)
                .then(
                    () => {
                        this.updateAusweise();
                    },
                    () => {
                        this.updateAusweise();
                    }
                );
        } else {
            this.toastr.error(this._('Die PIN dieses Ausweises kann nicht geändert werden.'));
        }
    }


    ausweiseQueryChange(value = '') {
        if (value !== this.ausweise.searchString) {
            this.ausweise.searchString = value;
            this.updateAusweise(false, 1);
        }

    }

    syncAusweise() {
        this.ausweisService
            .syncAusweise()
            .subscribe(
                () => {
                    this.toastr.success(this._('Der Synchronisationsvorgang wurde angestoßen.'));
                },
                () => {
                    this.toastr.error(this._('Der Synchronisationsvorgang konnte nicht angestoßen werden.'));
                }
            );
    }

    reindexAusweise() {
        this.ausweisService
            .reindexAusweise()
            .subscribe(
                () => {
                    this.toastr.success(this._('Der Indizierungsvorgang wurde angestoßen.'));
                },
                () => {
                    this.toastr.error(this._('Der Indizierungsvorgang konnte nicht angestoßen werden.'));
                }
            );
    }

    editCustomerBegrenzungen() {
        if (this.loginService.hasRole('ROLE_CUSTOMER')) {
            this.kundenService
                .loadCurrentCustomer()
                .subscribe(
                    (k) => {
                        KundeSelfEditorComponent
                            .open(this.modalService, Kunde.fromResult(k))
                            .then(
                                () => {

                                },
                                () => {

                                }
                            );
                    },
                    () => {
                    }
                );
        }
    }

    syncRatioAusweise() {
        this.ausweisService
            .syncRatioAusweise()
            .subscribe(
                () => {
                    this.toastr.success(this._('Der Importvorgang wurde angestoßen.'));
                },
                () => {
                    this.toastr.error(this._('Der Importvorgang konnte nicht angestoßen werden.'));
                }
            );
    }


    syncAusweis(params: RowActionParams<Ausweis>) {

        this.ausweisService
            .syncAusweis(params.row.data)
            .subscribe(
                () => {
                    this.toastr.success(this._('Der Syncvorgang wurde angestoßen.'));
                },
                () => {
                    this.toastr.error(this._('Der Syncvorgang konnte nicht angestoßen werden.'));
                }
            );

    }

     ausweisFilterToggle(visible: boolean) {
        if (!visible) {
            this.ausweisQueryChange2('');
        }
    }

    ausweisQueryChange2(value: string) {
        if (value !== this.ausweise.searchString2) {
            this.ausweise.searchString2 = value;
            this.updateAusweise(false, 1);
        }
    }


    downloadEntries(type: string, exporttype: number = 0) {
        if (this.ausweise.total < 1) {
            this.toastr.error(this._(
                'Es werden keine Datensätze angezeigt, daher gibt es auch nichts zum Herunterladen.'));
        } else if (this.ausweise.total < 500) {
            this.realDlEntries(type, exporttype);
        } else if (this.ausweise.total > 25000) {
            this.toastr.error(
                this._('Sie wollen {{ anzahl }} Datensätze herunterladen. Da es erfahrungsgemäß ' +
                    'hierbei zu Downloadabbrüchen seitens des Webbrowsers kommt, bitten wir Sie,' +
                    'die Ergebnismenge zunächst einzugrenzen.', {anzahl: this.ausweise.total}),
                this._('Aktion nicht möglich'),
                {
                    timeOut: 15000
                }
            );
        } else {
            ConfirmDialogComponent
                .display(
                    this.modalService,
                    this._('Download durchführen?'),
                    this._('Sie wollen {{ anzahl }} Datensätze herunterladen. Dies wird unter Umständen ' +
                        'eine längere Zeit dauern und je nach Browser zu einem Verbindungsabbruch führen. <br /><br /> ' +
                        'Wir empfehlen, die Liste zunnächst weiter einzuschränken.',
                        {anzahl: this.ausweise.total}
                    ),
                    this._('Download trotzdem durchführen'),
                    'icofont icofont-download-alt'
                )
                .result
                .then(
                    () => {
                        this.realDlEntries(type, exporttype);
                    },
                    noop
                );
        }
    }

    private realDlEntries(type: string, exporttype: number) {
        this.toastr
            .info(this._('Download wird angefordert. Bitte einen Moment Geduld...'),
                this._('Aktion wird ausgeführt...'),
                {
                    timeOut: 15000
                }
            )
            .onShown
            .subscribe(
                () => {
                    this.ausweisService
                        .exportData(
                        type,
                        this.ausweise.simpleFilter,
                        this.ausweise.getQuery(),
                        exporttype
                    ).subscribe(
                        (response: Blob) => {
                            FileSaver.saveAs(response, this._('ausweise.' + type));
                        },
                        (e) => {
                            this.toastr.error(this._('Die Liste der Ausweise konnte nicht exportiert werden.'));
                        }
                    );
                });

    }

}
