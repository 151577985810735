<div class="modal-header">
    <h5 class="modal-title" *ngIf="isAdd()">{{ _('Station anlegen') }}</h5>
    <h5 class="modal-title" *ngIf="isEdit()">{{ _('Station bearbeiten') }}</h5>
    <button type="button" class="close" aria-label="Close" (click)="cancel()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">

    <num-field
            id="Stationenummer"
            [label]="_('Nummer')"
            [(value)]="entity.nummer"
            [addonicon]="['icofont icofont-search']"
            [addonlabel]="[_('freie Nummer suchen')]"
            [enableaddon]="true"
            (addonClick)="addOnClick($event)"
    ></num-field>
    <text-field [label]="_('Bezeichnung')" [(value)]="entity.bezeichnung"></text-field>

    <app-tabset>
        <app-pane title="{{ _('Position') }}">
            <country-field [label]="_('Land')" [(value)]="entity.land"></country-field>
            <text-field [label]="_('Ort')" [(value)]="entity.ort"></text-field>
            <div class="row">
                <div class="col-6">
                    <num-field [label]="_('Längengrad')" [(value)]="entity.longitude"></num-field>
                </div>
                <div class="col-6">
                    <num-field [label]="_('Breitengrad')" [(value)]="entity.latitude"></num-field>
                </div>
            </div>
        </app-pane>
        <app-pane title="{{ _('Adresse') }}">
            <multi-line-field [label]="_('Anschrift')" [(value)]="entity.anschrift"></multi-line-field>
        </app-pane>
        <app-pane title="{{_('Öffnungszeiten')}}">
            <multi-line-field [label]="_('Öffnungszeiten')" [(value)]="entity.oeffnungszeiten"></multi-line-field>
        </app-pane>
        <app-pane title="{{ _('Kommunikation') }}">
            <text-field [label]="_('Telefon')" [(value)]="entity.telefon"></text-field>
            <text-field [label]="_('Telefax')" [(value)]="entity.fax"></text-field>
            <text-field [label]="_('Email')" [(value)]="entity.email"></text-field>
            <text-field [label]="_('Web-Adresse')" [(value)]="entity.url"></text-field>
        </app-pane>
        <app-pane title="{{ _('Sonstiges') }}">
            <station-art-field [label]="_('Art der Station')" [(value)]="entity.stationArt"></station-art-field>
            <currency-field [label]="_('Währung')" [(value)]="entity.currency"></currency-field>
            <multi-line-field [label]="_('Hinweise')" [(value)]="entity.hinweise"></multi-line-field>
            <boolean-field [label]="_('in Kundenansicht ausblenden')" [(value)]="entity.hidden"></boolean-field>
        </app-pane>
    </app-tabset>


</div>
<div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="cancel()">
        <i class="icofont icofont-close"></i> {{ _('Abbrechen') }}
    </button>
    <button type="button" class="btn btn-primary" (click)="save()">
        <i class="icofont icofont-save"></i>
        {{ _('Änderungen speichern')}}
    </button>
</div>
