import {DataTableRow} from './data-table-row';
import {ListOptionDirective} from '../components/list-option.directive';
import {DataTableRowAction} from './data-table-row-action';

export class RowActionParams<T> {
    row: DataTableRow<T> = null;
    action: DataTableRowAction | ListOptionDirective = null;


    constructor(row: DataTableRow<T>, action: DataTableRowAction | ListOptionDirective) {
        this.row = row;
        this.action = action;
    }
}
