import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {LoginService} from '../../auth/services/login.service';
import {IStation} from '../../stationen/common/istation';
import {ResultMetaSortImpl} from '../../commons/result-meta';
import {SimplePreisFilter} from '../common/simple-preis-filter';
import {IListResult} from '../../commons/list-result';
import {IPreis} from '../common/ipreis';
import {ITerminal} from '../../stationen/terminals/common/iterminal';
import {IActionResponse} from '../../commons/action-response';
import {HttpClient} from '@angular/common/http';
import {IPreisgruppe} from '../common/ipreisgruppe';
import {SimplePreisgruppeFilter} from '../common/simple-preisgruppe-filter';
import {Preisgruppe} from '../common/preisgruppe';
import {Station} from '../../stationen/common/station';
import {Tank} from '../../stationen/tanks/common/tank';
import {Terminal} from '../../stationen/terminals/common/terminal';
import {Produkt} from '../../products/produkte/common/produkt';

@Injectable({
    providedIn: 'root'
            })
export class PreiseService {

    constructor(public http: HttpClient,
                public loginService: LoginService) {

    }

    list(max = 0, offset = 0, sort: ResultMetaSortImpl[] = [], filter: SimplePreisFilter = null, query: string = ''): Observable<IListResult<IPreis>> {
        let url = '/api/preise';

        url = url.concat('?')
            .concat('max=' + encodeURIComponent('' + max))
            .concat('&')
            .concat('offset=' + encodeURIComponent('' + offset));

        if (query !== null && query !== undefined && ('' + query).trim() !== '') {
            url = url + '&q=' + encodeURIComponent(('' + query).trim());
        }

        if (filter != null) {
            if (filter.hasValues()) {
                url = url + '&' + filter.asURL();
            }
        }

        if (sort !== undefined && sort !== null && sort.length > 0) {
            const p = 0;
            sort.forEach(it => {
                url = url.concat('&sort.' + p + '=' + encodeURIComponent(it.field + '|' + it.direction));
            });
        }


        return this.http
            .get<IListResult<IPreis>>(
                url,
                {headers: this.loginService.getHttpHeaders()}
            );
    }


    storeTsPreis(station: any,
                 terminal: any,
                 preis: any): Observable<IActionResponse> {

        let url = '/api/stationen/*';
        if (Array.isArray(station)) {
            let t = '';
            station.forEach(x => {
                if ((x !== null) && (x !== undefined)) {
                    if (t !== '') {
                        t = t + ',';
                    }

                    t = t + encodeURIComponent('' + (x as IStation).id);
                }
            });
            if (t !== '') {
                url = '/api/stationen/' + t;
            }
        } else {
            if ((station != null) && (station !== undefined)) {
                url = '/api/stationen/' + encodeURIComponent('' + (station as IStation).id);
            }
        }

        if (Array.isArray(terminal)) {
            let t = '';
            terminal.forEach(x => {
                if ((x !== null) && (x !== undefined)) {
                    if (t !== '') {
                        t = t + ',';
                    }

                    t = t + encodeURIComponent('' + (x as ITerminal).id);
                }
            });
            if (t !== '') {
                url = url + '/terminals/' + t;
            }


        } else if ((terminal !== undefined) && (terminal !== null)) {
            url = url + '/terminals/' + encodeURIComponent('' + (terminal as ITerminal).id);
        }


        url = url + '/_tsPreis';


        return this.http
            .post<IActionResponse>(
                url,
                JSON.stringify(preis),
                {headers: this.loginService.getHttpHeaders()}
            );
    }


    listPreisgruppen(max = 0,
                     offset = 0,
                     sort: ResultMetaSortImpl[] = [],
                     filter: SimplePreisgruppeFilter = null,
                     query: string = null
    ): Observable<IListResult<IPreisgruppe>> {

        let url = '/api/preisgruppen';

        url = url.concat('?')
            .concat('max=' + encodeURIComponent('' + max))
            .concat('&')
            .concat('offset=' + encodeURIComponent('' + offset));

        if (filter != null) {
            if (filter.hasValues()) {
                url = url + '&' + filter.asURL();
            }
        }

        if (sort !== undefined && sort !== null && sort.length > 0) {
            const p = 0;
            sort.forEach(it => {
                url = url.concat('&sort.' + p + '=' + encodeURIComponent(it.field + '|' + it.direction));
            });
        }
        if (query !== null && query !== undefined && query !== '') {
            url = url + '&q=' + encodeURIComponent(('' + query).trim());
        }


        return this.http
            .get<IListResult<IPreisgruppe>>(
                url,
                {headers: this.loginService.getHttpHeaders()}
            );

    }

    storePreisgruppe(entity: Preisgruppe, insert: boolean): Observable<IActionResponse> {
        const pg = Preisgruppe.fromResult(entity);
        pg.settingsDT = [];
        if (!insert && entity.id !== null && entity.id > 0) {
            return this.http
                .put<IActionResponse>(
                    '/api/preisgruppen/' + encodeURIComponent('' + entity.id),
                    JSON.stringify(pg),
                    {headers: this.loginService.getHttpHeaders()}
                );
        }
        return this.http
            .post<IActionResponse>(
                '/api/preisgruppen',
                JSON.stringify(pg),
                {headers: this.loginService.getHttpHeaders()}
            );
    }

    removePreisgruppe(entity: Preisgruppe): Observable<IActionResponse> {
        if (entity.id !== null && entity.id > 0) {
            return this.http
                .delete<IActionResponse>(
                    '/api/preisgruppen/' + encodeURIComponent('' + entity.id),
                    {headers: this.loginService.getHttpHeaders()}
                );
        }
    }


    getPreis(station: Station, tank: Tank, terminal: Terminal, produkt: Produkt, datum: Date): Observable<IActionResponse> {

        let uri = '/api/stationen/' + encodeURIComponent('' + station.id);

        if (terminal !== null && terminal !== undefined) {
            uri = uri + '/terminals/' + encodeURIComponent('' + terminal.id);
        } else if (tank !== undefined && tank !== null) {
            uri = uri + '/tanks/' + encodeURIComponent('' + tank.id);
        }

        uri = uri + '/_tsPreis';

        if (produkt != null) {
            uri = uri + '?produktId=' + encodeURIComponent('' + produkt.id);
        }

        if (uri.indexOf('?') > 0) {
            uri = uri + '&';
        } else {
            uri = uri + '?';
        }
        uri = uri + 'zp=' + encodeURIComponent(JSON.stringify(datum).replace(/"/g, ''));

        return this.http.get<IActionResponse>(uri, {headers: this.loginService.getHttpHeaders()});
    }

    reIndexPreise(): Observable<IActionResponse> {
        return this.http
            .post<IActionResponse>(
                '/api/preise/_reindex',
                {},
                {
                    headers: this.loginService.getHttpHeaders()
                }
            );
    }

    exportData(type = 'csv', filter: SimplePreisFilter = null, searchQuery = '', exporttype = 0): Observable<Blob> {
        let url = '/api/preise.' + encodeURIComponent('' + type);

        url = url.concat('?max=-1')
            .concat('&offset=0')
            .concat('&sort.0=' + encodeURIComponent('gueltigAb|desc'));
        url = url.concat('&exporttype=' + encodeURIComponent('' + exporttype));


        if (filter !== null) {
            if (filter.hasValues()) {
                url = url + '&' + filter.asURL();
            }
        }
        if (searchQuery !== null && searchQuery !== undefined && searchQuery !== '') {
            url = url.concat('&q=' + encodeURIComponent(searchQuery));
        }

        return this.http
            .get(
                url,
                {
                    headers: this.loginService.getHttpHeaders(false),
                    responseType: 'blob'
                }
            );
    }

}
