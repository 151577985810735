import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';

import {Subscription} from 'rxjs';
import {LocalizedComponent} from '../../modules/commons/components/localized-component';
import {LoginService} from '../../modules/auth/services/login.service';
import {Benutzer} from '../../modules/auth/common/benutzer';
import {PubSubService} from '../../modules/commons/pub-sub.service';
import {SysInfoService} from '../../modules/commons/sysinfo/services/sys-info.service';

@Component({
               selector   : 'app-sidebar',
               templateUrl: './sidebar.component.html',
               styleUrls  : ['./sidebar.component.scss']
           })
export class SidebarComponent extends LocalizedComponent implements OnInit, OnDestroy {


    isLoggedIn = false;
    public loginSubscription: Subscription = null;


    constructor(
                
                public loginService: LoginService,
                public sysInfoService: SysInfoService,
                public pubsub: PubSubService,
                public cd: ChangeDetectorRef) {
        super( cd);
    }

    ngOnInit() {
        this.isLoggedIn = this.loginService.isLoggedIn();
        this.loginSubscription = this.pubsub.loginStateChange.subscribe(
            () => {
                this.isLoggedIn = this.loginService.isLoggedIn();
            },
            () => {
                this.isLoggedIn = this.loginService.isLoggedIn();
            }
        );

    }

    ngOnDestroy() {
        this.loginSubscription.unsubscribe();
    }


}
