import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {SondenBuchung} from '../sonden-buchung';
import {LocalizedComponent} from '../../../commons/components/localized-component';


@Component({
    selector: 'app-sonden-buchung-quick-info',
    templateUrl: './sonden-buchung-quick-info.component.html',
    styleUrls: ['./sonden-buchung-quick-info.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SondenBuchungQuickInfoComponent extends LocalizedComponent implements OnInit {

    private _cfg: any = {
        field: 'none',
        showLabel: false
    };


    get cfg(): any {
        return this._cfg;
    }

    @Input()
    set cfg(value: any) {
        this._cfg = {...this._cfg, ...value};
        this.cd.markForCheck();
    }

    private _sondenBuchung: SondenBuchung = null;


    get sondenBuchung(): SondenBuchung {
        return this._sondenBuchung;
    }

    @Input()
    set sondenBuchung(value: SondenBuchung) {
        this._sondenBuchung = value;
        this.cd.markForCheck();
    }

    constructor(  public cd: ChangeDetectorRef) {
        super( cd);
    }

    ngOnInit() {
    }

    isValid() {
        return this._sondenBuchung !== undefined && this._sondenBuchung !== null && this._sondenBuchung.id !== undefined && this._sondenBuchung.id !== null && this._sondenBuchung.id > 0;
    }

}
