import {Pipe, PipeTransform} from '@angular/core';
import {ConverterTool} from '../../shared/converter-tool';

@Pipe({
    name: 'bytes'
})
export class BytesPipe implements PipeTransform {

    public static transformBytes(bytes: number, decimals: number, removeZeroDecimals: boolean = false): any {
        return ConverterTool.readableBytes(bytes, decimals, removeZeroDecimals).replace('.', ',')
    }


    transform(bytes: number, decimals?: number, removeZeroDecimals = false): any {
        return BytesPipe.transformBytes(bytes, decimals, removeZeroDecimals);
    }

}
