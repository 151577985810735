import {ChangeDetectionStrategy, ChangeDetectorRef, Component, InjectionToken, Injector} from '@angular/core';
import {Station} from '../common/station';

@Component({
    selector: 'app-station-element-renderer',
    template: `
        <div *ngIf="value!=null && value!==undefined">
            {{value.bezeichnung}} <span class="badge badge-default">{{value.nummer}}</span>
        </div>
    `,
    styles: [
            `
            :host div {
                display: inline-block;
            }
        `
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class StationElementRendererComponent {

    private _value: Station = null;


    get value(): Station {
        return this._value;
    }

    set value(value: Station) {
        this._value = value;
        this.cd.markForCheck();
    }

    constructor(public injector: Injector,
                public cd: ChangeDetectorRef
    ) {
        this.value = this.injector.get('value');
    }

}
