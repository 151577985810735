import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {SysInfoService} from '../../modules/commons/sysinfo/services/sys-info.service';
import {LoginService} from '../../modules/auth/services/login.service';

@Injectable()
export class DashboardControlGuard implements CanActivate {


    constructor(public loginService: LoginService,
                public sysInfoService: SysInfoService,
                public router: Router) {

    }

    /**
     * Überprüft, ob der Nutzer eingeloggt ist und leitet ggf. zum
     * Login weiter
     * @param route
     * @param state
     * @returns {boolean}
     */
    canActivate(route: ActivatedRouteSnapshot,
                state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

        let ok = this.loginService.isLoggedIn();

        if (ok) {
            if (!this.loginService.isAdmin()) {
                if (!this.sysInfoService.hasDashboard()) {
                    if (this.sysInfoService.seesZutrittskontrolle()) {
                        this.router.navigate(['/zutritt/journal']);
                        ok = false;
                    } else if (this.loginService.hasRole('BETRIEBSRAT_MODE')) {
                        this.router.navigate(['/ausweise']);
                        ok = false;
                    } else {
                        this.router.navigate(['/messages']);
                        ok = false;
                    }
                }
            }
        } else {
            this.router.navigate(
                ['login'],
                {
                    queryParams: {
                        returnUrl: state.url
                    }
                }
            );
        }
        return ok;
    }


}
