import {METranslationCallback} from '../../commons/metranslation-callback';
import {IOption, Option} from '../../commons/dynamic-form/select-field/ioption';
import {EDeviceType} from './edevice-type.enum';

export class DeviceType {


    static options(translateFunc: METranslationCallback = null, skipUnknown = true): IOption[] {
        const options: IOption[] = [];

        if (!skipUnknown) {
            options.push(
                Option.option(
                    EDeviceType.UNKNOWN,
                    DeviceType.getTitle(EDeviceType.UNKNOWN),
                    DeviceType.getIcon(EDeviceType.UNKNOWN)
                )
            );
        }
        options.push(
            Option.option(
                EDeviceType.MEVIDEOCONTROL,
                DeviceType.getTitle(EDeviceType.MEVIDEOCONTROL),
                DeviceType.getIcon(EDeviceType.MEVIDEOCONTROL)
            )
        );
        options.push(
            Option.option(
                EDeviceType.MELORAGATEWAY,
                DeviceType.getTitle(EDeviceType.MELORAGATEWAY),
                DeviceType.getIcon(EDeviceType.MELORAGATEWAY)
            )
        );
        options.push(
            Option.option(
                EDeviceType.MECOINACCEPTORUNIT,
                DeviceType.getTitle(EDeviceType.MECOINACCEPTORUNIT),
                DeviceType.getIcon(EDeviceType.MECOINACCEPTORUNIT)
            )
        );
        options.push(
            Option.option(
                EDeviceType.VACUUMCLEANER,
                DeviceType.getTitle(EDeviceType.VACUUMCLEANER),
                DeviceType.getIcon(EDeviceType.VACUUMCLEANER)
            )
        );
        options.push(
            Option.option(
                EDeviceType.CARWASH,
                DeviceType.getTitle(EDeviceType.CARWASH),
                DeviceType.getIcon(EDeviceType.CARWASH)
            )
        );
        options.push(
            Option.option(
                EDeviceType.WASHBOX,
                DeviceType.getTitle(EDeviceType.WASHBOX),
                DeviceType.getIcon(EDeviceType.WASHBOX)
            )
        );


        if (translateFunc !== null && translateFunc !== undefined) {
            options.forEach((v: IOption, idx) => {
                options[idx].label = translateFunc(v.label);
            });
        }
        return options;

    }

    static getIcon(deviceType: EDeviceType): string {
        return 'icofont icofont-micro-chip';
    }

    static byValue(deviceType: any): EDeviceType {

        if (deviceType === null) {
            return null;
        }

        if (deviceType === EDeviceType.UNKNOWN) {
            return EDeviceType.UNKNOWN;
        }
        if (deviceType === EDeviceType.MEVIDEOCONTROL) {
            return EDeviceType.MEVIDEOCONTROL;
        }
        if (deviceType === EDeviceType.MELORAGATEWAY) {
            return EDeviceType.MELORAGATEWAY;
        }
        if (deviceType === EDeviceType.MECOINACCEPTORUNIT) {
            return EDeviceType.MECOINACCEPTORUNIT;
        }
        if (deviceType === EDeviceType.VACUUMCLEANER) {
            return EDeviceType.VACUUMCLEANER;
        }
        if (deviceType === EDeviceType.CARWASH) {
            return EDeviceType.CARWASH;
        }
        if (deviceType === EDeviceType.WASHBOX) {
            return EDeviceType.WASHBOX;
        }

        if ('UNKNOWN' === ('' + deviceType).toUpperCase()) {
            return EDeviceType.UNKNOWN;
        }
        if ('MEVIDEOCONTROL' === ('' + deviceType).toUpperCase()) {
            return EDeviceType.MEVIDEOCONTROL;
        }
        if ('MELORAGATEWAY' === ('' + deviceType).toUpperCase()) {
            return EDeviceType.MELORAGATEWAY;
        }
        if ('MECOINACCEPTORUNIT' === ('' + deviceType).toUpperCase()) {
            return EDeviceType.MECOINACCEPTORUNIT;
        }
        if ('VACUUMCLEANER' === ('' + deviceType).toUpperCase()) {
            return EDeviceType.VACUUMCLEANER;
        }
        if ('CARWASH' === ('' + deviceType).toUpperCase()) {
            return EDeviceType.CARWASH;
        }
        if ('WASHBOX' === ('' + deviceType).toUpperCase()) {
            return EDeviceType.WASHBOX;
        }

        if (0 === parseInt('' + deviceType, 10)) {
            return EDeviceType.UNKNOWN;
        }
        if (1 === parseInt('' + deviceType, 10)) {
            return EDeviceType.MEVIDEOCONTROL;
        }
        if (2 === parseInt('' + deviceType, 10)) {
            return EDeviceType.MELORAGATEWAY;
        }
        if (3 === parseInt('' + deviceType, 10)) {
            return EDeviceType.MECOINACCEPTORUNIT;
        }
        if (4 === parseInt('' + deviceType, 10)) {
            return EDeviceType.VACUUMCLEANER;
        }
        if (5 === parseInt('' + deviceType, 10)) {
            return EDeviceType.CARWASH;
        }
        if (6 === parseInt('' + deviceType, 10)) {
            return EDeviceType.WASHBOX;
        }

        return EDeviceType.UNKNOWN;
    }


    static getTitle(type: any) {
        const t = DeviceType.byValue(type);
        switch (t) {
            case EDeviceType.MEVIDEOCONTROL:
                return 'MEVideoControl-Box';
            case EDeviceType.MELORAGATEWAY:
                return 'ME-LoRa-Gateway';
            case EDeviceType.MECOINACCEPTORUNIT:
                return 'ME-Münzprüfer-Interface';
            case EDeviceType.VACUUMCLEANER:
                return 'Staubsauger';
            case EDeviceType.CARWASH:
                return 'Waschanlage';
            case EDeviceType.WASHBOX:
                return 'Wasch-Box';
            default:
                return 'unbekannte Geräteklasse';
        }
    }
}
