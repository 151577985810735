import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Injector, OnInit} from '@angular/core';
import {Produkt} from './produkte/common/produkt';

@Component({
  selector: 'app-produkt-element-renderer',
    template: `
        <div *ngIf="value!=null && value!==undefined">
            {{value.bezeichnung}} <span class="badge badge-default">{{value.nummer}}</span>
        </div>
    `,
    styles: [`
        :host div {
            display: inline-block;
        }
    `],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProduktElementRendererComponent {

    private _value: Produkt = null;


    get value(): Produkt {
        return this._value;
    }

    set value(value: Produkt) {
        this._value = value;
        this.cd.markForCheck()
    }

    constructor(public injector: Injector,
                public cd: ChangeDetectorRef
    ) {
        this.value = this.injector.get('value')
    }

}
