<div class="modal-header">
    <h4 class="modal-title">{{ _('Benutzerprofil bearbeiten') }}</h4>
    <button type="button" class="close" aria-label="Close" (click)="cancel()">
        <span aria-hidden="true">&times;</span>
    </button>

</div>
<div class="modal-body">
    <app-tabset>
        <app-pane [title]="_('Stammdaten')">
            <text-field [disabled]="true" [label]="_('Benutzerkennung')" [(value)]="entity.username"></text-field>
            <text-field [label]="_('vollständiger Name')" [(value)]="entity.fullname"></text-field>
            <text-field [label]="_('Email-Adresse')" [(value)]="entity.email"></text-field>
            <boolean-field [label]="_('Kopie der Mitteilungen als Email senden')"
                           [(value)]="entity.emailsEnabled"></boolean-field>
        </app-pane>
        <app-pane [title]="_('Kennwort')">
            <div class="alert alert-info">
                {{ _('Bitte tragen Sie hier nur ein Kennwort ein, wenn dieses geändert werden soll. ')}}
            </div>
            <password-field [label]="_('neues Kennwort')" [labelwidth]="4" [(value)]="entity.newPW"></password-field>
            <password-field [label]="_('neues Kennwort wiederholen')" [labelwidth]="4"
                            [(value)]="entity.newPW2"></password-field>
            <date-time-field
                    [label]="_('letzte Kennwortänderung')"
                    [labelwidth]="4"
                    [disabled]="true"
                    [(value)]="entity.lastPasswordChange"
            ></date-time-field>
        </app-pane>
    </app-tabset>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="cancel()">
        <i class="icofont icofont-close"></i> {{ _('Schließen') }}
    </button>
    <button type="button" class="btn btn-primary" (click)="save()" [disabled]="!canSave()"
            [class.disabled]="!canSave()">
        <i class="icofont icofont-save"></i> {{ _('Speichern') }}
    </button>
</div>
