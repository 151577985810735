import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import {ToastrService} from 'ngx-toastr';
import {Subscription} from 'rxjs';
import {LoginService} from '../../../auth/services/login.service';
import {LocalizedComponent} from '../../../commons/components/localized-component';
import {IListResult} from '../../../commons/list-result';
import {noop} from '../../../commons/noop';
import {SysInfoService} from '../../../commons/sysinfo/services/sys-info.service';
import {StationenService} from '../../common/stationen.service';
import {ISonde} from '../isonde';
import {Sonde} from '../sonde';

import {SondeList} from '../sonde-list';
import {SondenBuchungenDialogComponent} from '../sonden-buchungen-dialog/sonden-buchungen-dialog.component';

@Component({
               selector   : 'app-dashboard-sonden-info-block',
               templateUrl: './dashboard-sonden-info-block.component.html',
               styleUrls  : ['./dashboard-sonden-info-block.component.scss']
           })
export class DashboardSondenInfoBlockComponent extends LocalizedComponent implements OnInit, OnDestroy {
    subs: Subscription[] = [];

    sonden: SondeList = new SondeList();
    private mode = 0;

    get modeLabel(): string {
        if (this.mode === 0) {
            return this._('nur Warnmeldungen');
        }
        return this._('alle Sonden');
    }

    constructor(public cd: ChangeDetectorRef,
                public stationenService: StationenService,
                public sysInfoService: SysInfoService,
                public modalService: NgbModal,
                public router: Router,
                public toastr: ToastrService,
                public loginService: LoginService
    ) {
        super(cd);
    }

    ngOnInit() {
        this.mode = parseInt(this.loginService.getUserSetting('dashboard-sonden-mode', 0), 10);
        this.subs.push(
            this.sonden.onUpdateRequired.subscribe(
                (l: SondeList) => {
                    l.simpleFilter.aktiveOnly = true;
                    this.stationenService
                        .listSonden(
                            null,
                            100,
                            0,
                            l.order,
                            l.simpleFilter,
                            l.getQuery()
                        ).subscribe(
                        (r: IListResult<ISonde>) => {
                            l.populateFromListResult(r);
                            this.cd.markForCheck();
                        }
                    );
                }
            )
        );
        this.sonden.reload();
    }

    ngOnDestroy() {
        this.subs.forEach((s: Subscription) => {
            s.unsubscribe();
        });
        this.subs = [];
        super.ngOnDestroy();
    }

    stand(sonde: Sonde): string {
        if (sonde.letzteBuchung === null || sonde.letzteBuchung === undefined) {
            return '';
        }
        const v = sonde.letzteBuchung.menge;
        return this.fNum(v, '1.0-0') + ' l';
    }

    zeitpunkt(sonde: Sonde) {
        if (sonde.letzteBuchung === null || sonde.letzteBuchung === undefined) {
            return '';
        }
        return this.fDate(sonde.letzteBuchung.zeitpunkt, 'medium');
    }

    bezeichnung(sonde: Sonde): string {
        let s: string;
        if (sonde.bezeichnung !== null && sonde.bezeichnung !== undefined && sonde.bezeichnung.trim() !== '') {
            s = sonde.bezeichnung;
        } else {
            s = ('Sonde ' + sonde.nummer).trim();
        }
        if (sonde.station !== null && sonde.station !== undefined) {
            s = s + ' (' + sonde.station.bezeichnung + ')';
        }
        return s;
    }

    showPeilungen(sonde: Sonde) {
        this.stationenService.loadSonde(null, sonde.id).subscribe(
            (s: ISonde) => {

                SondenBuchungenDialogComponent.open(this.modalService, s)
                                              .then(
                                                  noop, noop
                                              );
            },
            () => {
                this.toastr.error(this._('Sonde nicht gefunden'), this._('Zu dieser Sonde wurden keine Daten gefunden'));
            });
    }

    getClasses(sonde: Sonde) {
        if (sonde === null || sonde === undefined || sonde.letzteBuchung === null || sonde.letzteBuchung === undefined) {
            return 'gray';
        }

        if (sonde.letzteBuchung.menge < sonde.warnmenge) {
            return 'red';
        }
        if (sonde.letzteBuchung.zeitpunkt.getTime() < moment().subtract(3, 'days').toDate().getTime()) {
            return 'violet';
        }

        return 'green';
    }

    selectMode(mode: number) {
        this.mode = mode;
        this.loginService.updateUserSetting('dashboard-sonden-mode', this.mode);
        this.cd.markForCheck();
    }

    isVisible(sonde: Sonde) {
        if (sonde === null || sonde === undefined) {
            return false;
        }
        if (this.mode === 0) {
            if (sonde.letzteBuchung === null || sonde.letzteBuchung === undefined) {
                return false;
            }

            if (sonde.letzteBuchung.menge < sonde.warnmenge) {
                return true;
            }
            return sonde.letzteBuchung.zeitpunkt.getTime() < moment().subtract(3, 'days').toDate().getTime();
        }
        return true;
    }

    showNoWarningMessage() {
        if (this.mode !== 0) {
            return false;
        }

        let show = true;
        this.sonden.data.forEach((s: Sonde) => {
            if (this.isVisible(s)) {
                show = false;
            }
        });
        return show;
    }
}
