import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import {
    MEHighlitePipe,
    MESelectComponent,
    MESelectItemMatcherCallback,
    MESelectItemTextCallback
} from '../commons/dynamic-form/me-select/meselect.component';
import {Station} from './common/station';
import {TerminalList} from './terminals/common/terminal-list';
import {Terminal} from './terminals/common/terminal';
import {StationenService} from './common/stationen.service';
import {FilterCallback} from '../commons/dynamic-form/FilterCallback';
import {CommonList} from '../commons/common-list';
import {GenericFilter} from '../commons/generic-filter';
import {SimpleTerminalFilter} from './terminals/common/simple-terminal-filter';

import {MESelectItemFormatterCallback} from '../commons/dynamic-form/me-select/meselect-item-formatter-callback';
import {IListResult} from '../commons/list-result';
import {ITerminal} from './terminals/common/iterminal';
import {MESelectSearchEvent} from '../commons/dynamic-form/me-select/meselect-search-event';
import {ConverterTool} from '../../shared/converter-tool';
import CryptoES from "crypto-es";

@Component({
    selector: 'terminal-inline-field',
    template: `
        <meselect
                *ngIf="terminals!==null && terminals!==undefined"
                id="{{ id }}"
                [idField]="'id'"
                [items]="terminals?.data"
                [asMatrix]="false"
                [placeholder]="label"
                (itemSelected)="doSelect($event)"
                (needsSearch)="doSearch($event)"
                [small]="small"
                [nullable]="nullable"
                [onFormat]="itemFormat"
                [onGetText]="itemText"
                [onMatch]="itemMatcher"
        >

        </meselect>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush

})
export class TerminalInlineFieldComponent implements OnInit, AfterViewInit {

    @Input() nullable = true;

    @ViewChild(MESelectComponent, {static: true}) terminalsMESelect: MESelectComponent;
    @Input() small = false;
    @Input() label = 'StationField';

    public stationen: Station[] = [];
    terminals: TerminalList = new TerminalList();
    @Input() id = '';
    @Input() maxDisplayTerminals = 50;
    @Output() valueChange = new EventEmitter<Terminal>();

    constructor(public stationenService: StationenService,
                public cd: ChangeDetectorRef) {
    }

    @Input()
    set station(v: any) {
        if (Array.isArray(v)) {
            this.stationen = v;
        } else {
            this.stationen = [v];
        }
    }

    public _value: Terminal = null;

    get value(): Terminal {
        return this._value;
    }

    @Input()
    set value(v: Terminal) {

        if (this._value !== v) {
            this._value = v;
            if ((this.terminalsMESelect !== undefined) && (this.terminalsMESelect !== null)) {
                this.terminalsMESelect.value = v;
            }
            this.valueChange.emit(v);
        }
    }

    public search: FilterCallback<Terminal> = (filter: GenericFilter<Terminal>,
                                               list: CommonList<Terminal>,
                                               maxDisplayItems: number,
                                               queryString: string,
                                               cfg: any,
                                               selectComponent: MESelectComponent
    ) => {
        const q = ConverterTool.processQuery(queryString, ['nummer']);

        this
            .stationenService
            .listTerminals(
                cfg.Stationen,
                maxDisplayItems,
                0,
                [],
                filter as SimpleTerminalFilter,
                q
            )
            .subscribe(
                (c: IListResult<ITerminal>) => {
                    list.populateFromListResult(c);
                    if (selectComponent !== null && selectComponent !== undefined) {
                        selectComponent.items = list.data;
                        selectComponent.cd.markForCheck();
                    }
                }
            );
    }


    ngOnInit() {
        this.doSearch(new MESelectSearchEvent('', this.terminalsMESelect));
    }

    doSearch(value: MESelectSearchEvent) {
        this.search(null, this.terminals, this.maxDisplayTerminals, value.queryString, {Stationen: this.stationen}, value.component);
    }

    doSelect(terminal: Terminal) {
        this._value = terminal;
        this.valueChange.emit(terminal);
        this.cd.markForCheck();
    }

    public itemFormat: MESelectItemFormatterCallback<Terminal> = (item: Terminal, search, forList) => {
        if (item === null) {
            return '';
        }
        if (forList) {
            return '<i class="me-icon me-icon-terminal"></i> ' + MEHighlitePipe.transformString(item.bezeichnung, search) +
                ' <span class=\'badge-default badge\'>' + MEHighlitePipe.transformString('' + item.nummer, search) + '</span>' +
                (
                    (item.station !== null && item.station !== undefined) ?
                        ('<br /><div class=\'small\'><i class="me-icon me-icon-station"></i> '
                            + MEHighlitePipe.transformString(item.station.bezeichnung, search)
                            + '<span class="badge-default badge">'
                            + MEHighlitePipe.transformString('' + item.station.nummer, search)
                            + '</span></div>') :
                        ('')
                );

        } else {
            return '<i class="me-icon me-icon-terminal"></i> ' + MEHighlitePipe.transformString(item.bezeichnung, search) +
                ' <span class=\'badge-default badge\'>' + MEHighlitePipe.transformString('' + item.nummer, search) + '</span>';
        }
    }

    public itemText: MESelectItemTextCallback<Terminal> = (item: Terminal) => {
        if (item === null) {
            return '';
        }
        return item.bezeichnung + ' [' + item.nummer + ']';
    }

    public itemMatcher: MESelectItemMatcherCallback<Terminal> = (item: Terminal, search) => {
        if (!search) {
            return true;
        }
        if (search.trim() === '') {
            return true;
        }
        if (!item) {
            return false;
        }
        const x = item.bezeichnung + ' ' + item.nummer;

        return (x.toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) >= 0);
    }


    ngAfterViewInit(): void {
        this.terminalsMESelect.onFormat = this.itemFormat;
        this.terminalsMESelect.onGetText = this.itemText;
        this.terminalsMESelect.onMatch = this.itemMatcher;
        this.terminalsMESelect.value = this._value;
        if (this.id === '') {
            this.id = 'tf' + CryptoES.MD5('textfield-' + this.label + Math.random() + '-' + Math.random()).toString();
        }
        this.cd.markForCheck();
    }

}
