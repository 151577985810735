import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    NgZone,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';

import {MESelectItemFormatterCallback} from './me-select/meselect-item-formatter-callback';
import {
    MEHighlitePipe,
    MESelectComponent,
    MESelectItemMatcherCallback,
    MESelectItemTextCallback
} from './me-select/meselect.component';
import {Land} from '../laender/common/land';
import {LandList} from '../laender/common/land-list';
import {SprachenService} from '../sprachen/common/sprachen.service';
import {SimpleLandFilter} from '../laender/common/simple-land-filter';
import {ILand} from '../laender/common/iland';
import {IListResult} from '../list-result';
import CryptoES from "crypto-es";

@Component({
    selector: 'country-field',
    styles: [''],
    template: `
                   <div class="form-group row">
                       <label for="{{ id }}" class="col-{{ labelwidth }} col-form-label" *ngIf="labelwidth>0">
                           {{ label }}
                       </label>
                       <div class="col-{{ 12-labelwidth }}">

                           <meselect
                                   id="{{ id }}"
                                   [idField]="'laendercode'"
                                   [items]="countries.data"
                                   [placeholder]="label"
                                   (itemSelected)="doSelect($event)"
                                   [small]="small"
                                   [nullable]="nullable"
                                   [disabled]="disabled"
                                   [nullLabel]="nullLabel"
                           >

                           </meselect>

                       </div>
                   </div>
               `
})
export class CountryFieldComponent implements OnInit, AfterViewInit {
    @Input() nullable = true;
    @ViewChild(MESelectComponent, {static: true}) countryMESelect: MESelectComponent;

    countries: LandList = new LandList();

    _value: Land = null;

    @Input() label = 'Textfeld';
    @Input() id = '';
    @Input() labelwidth = 3;
    @Input() maxDisplayCountries = 1500;
    @Input() small = false;
    @Input() disabled = false;
    @Input() nullLabel = '';

    @Input()
    set value(v: Land) {

        if (this._value !== v) {
            this._value = v;
            this.valueChange.emit(v);
        }
    }

    @Output() valueChange = new EventEmitter<Land>();


    get value(): Land {
        return this._value;
    }

    constructor(public sprachenService: SprachenService,
                public cd: ChangeDetectorRef,
                public _ngZone: NgZone) {
        this.sprachenService
            .listCountries(this.maxDisplayCountries, 0)
            .subscribe(
                (c: IListResult<ILand>) => {
                    this.countries.populateFromListResult(c);
                }
            );
    }

    ngOnInit() {
        if (this.id === '') {
            this.id = 'tf' + CryptoES.MD5('textfield-' + this.label + Math.random() + '-' + Math.random()).toString();
        }
    }


    doSearch(value) {
        // console.log('DOSEARCH');
        const lf: SimpleLandFilter = new SimpleLandFilter();

        if (value.trim() !== '') {
            lf.bezeichnung = value.trim();
            lf.laendercode = value.trim();
            lf._operations['bezeichnung'] = 'OR';
            lf._operations['laendercode'] = 'OR';
        }

        this
            .sprachenService
            .listCountries(this.maxDisplayCountries, 0, [], lf)
            .subscribe(
                (c: IListResult<ILand>) => {
                    this.countries.populateFromListResult(c);
                    this.cd.markForCheck();
                }
            );
    }

    doSelect(land: Land) {
        this._value = land;
        this.valueChange.emit(land);
    }

    public itemFormat: MESelectItemFormatterCallback<Land> = (item: Land, search) => {
        if (item === null) {
            return this.nullLabel;
        }
        if (item === undefined) {
            return this.nullLabel;
        }
        if ((item as any) === '') {
            return this.nullLabel;
        }

        return '<i class="flag-icon flag-icon-squared flag-icon-' + item.laendercode.toLowerCase() + '"></i> ' +
            MEHighlitePipe.transformString(item.bezeichnung, search) +
            ' <em>[' + MEHighlitePipe.transformString(item.laendercode, search) + ']</em>';
    }

    public itemText: MESelectItemTextCallback<Land> = (item: Land) => {
        if (item === null) {
            return this.nullLabel;
        }
        if (item === undefined) {
            return this.nullLabel;
        }
        if ((item as any) === '') {
            return this.nullLabel;
        }

        return item.bezeichnung + ' [' + item.laendercode + ']';
    }

    public itemMatcher: MESelectItemMatcherCallback<Land> = (item: Land, search) => {
        if (!search) {
            return true;
        }
        if (search.trim() === '') {
            return true;
        }
        if (!item) {
            return false;
        }
        if ((item as any) === '') {
            return false;
        }

        const x = item.bezeichnung + ' ' + item.laendercode;

        return (x.toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) >= 0);
    }


    ngAfterViewInit() {
        this.countryMESelect.onFormat = this.itemFormat;
        this.countryMESelect.onGetText = this.itemText;
        this.countryMESelect.onMatch = this.itemMatcher;
        this.countryMESelect.value = this._value;
    }

}
