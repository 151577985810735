<div class="modal-header">
    <h5 class="modal-title" *ngIf="isAdd()">{{ _('Einheit anlegen') }}</h5>
    <h5 class="modal-title" *ngIf="isEdit()">{{ _('Einheit bearbeiten') }}</h5>
    <button type="button" class="close" aria-label="Close" (click)="cancel()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">

    <num-field
            [label]="_('Nummer')" [(value)]="entity.id"
            [addonicon]="['icofont icofont-search']"
            [addonlabel]="[_('freie Nummer suchen')]"
            [enableaddon]="true"
            (addonClick)="addOnClick($event)"
            id="einheitNummer"
    ></num-field>
    <text-field [label]="_('Bezeichnung')" [(value)]="entity.bezeichnung"></text-field>
    <text-field [label]="_('Symbol')" [(value)]="entity.symbol"></text-field>

</div>
<div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="cancel()">
        <i class="icofont icofont-close"></i> {{ _('Abbrechen') }}
    </button>
    <button type="button" class="btn btn-primary" (click)="save()">
        <i class="icofont icofont-save"></i>
        {{ _('Änderungen sichern') }}
    </button>
</div>
