<table cellpadding="2" cellspacing="2" *ngIf="_fahrzeug!==undefined && _fahrzeug!==null">
    <tr>
        <th scope="row">{{ _('Nummer') }}</th>
        <td>{{ _fahrzeug.nummer }}</td>
        <th scope="row">{{ _('Bezeichnung') }}</th>
        <td>{{ _fahrzeug.bezeichnung }}</td>
    </tr>
    <tr>
        <th scope="row">{{ _('KFZ-Kennzeichen') }}</th>
        <td>{{ _fahrzeug.kfzKennzeichen }}</td>
        <th scope="row">{{ _('Kostenstelle') }}</th>
        <td>{{ _fahrzeug.kostenstelle }}</td>
    </tr>
    <tr>
        <th scope="row">{{ _('Kraftstoff') }}</th>
        <td>
            <app-produkt-quick-info
                    *ngIf="_fahrzeug.kraftstoff!==null && _fahrzeug.kraftstoff!==undefined"
                    [produkt]="_fahrzeug.kraftstoff" [showLabel]="true"></app-produkt-quick-info>
            <span
                    *ngIf="_fahrzeug.kraftstoff==null || _fahrzeug.kraftstoff==undefined"
            >{{_('kein Kraftstoff festgelegt')}}</span>

        </td>
        <th scope="row">{{ _('Tankvolumen') }}</th>
        <td>
            <volumen-display
                    *ngIf="_fahrzeug.tankVolumen!==undefined && _fahrzeug.tankVolumen!==null"
                    [value]="_fahrzeug.tankVolumen"
                    [scale]="1"
                    unit="l"
            ></volumen-display>
        </td>
    </tr>
    <tr>
        <th scope="row">{{ _('kmStand') }}</th>
        <td>{{ _fahrzeug.kmStand }}</td>
        <th scope="row">{{ _('TS-ID') }}</th>
        <td>{{ _fahrzeug.tankserverID }}</td>
    </tr>
    <tr>
        <th scope="row">{{ _('im System angelegt') }}</th>
        <td>{{fDate( _fahrzeug.dateCreated ,'medium' )}}</td>
        <th scope="row">{{ _('zuletzt bearbeitet') }}</th>
        <td>{{fDate( _fahrzeug.lastEdited ,'medium' )}}</td>
    </tr>
    <tr>
        <th scope="row">{{ _('Kunde') }}</th>
        <td>
            <app-kunde-quick-info
                    *ngIf="_fahrzeug.kunde !==undefined && _fahrzeug.kunde!==null"
                    [kunde]="_fahrzeug.kunde"></app-kunde-quick-info>
            <div *ngIf="_fahrzeug.kunde ==undefined || _fahrzeug.kunde==null">
                {{ _('keinem Kunden zugeordnet') }}
            </div>
        </td>
        <th scope="row">

        </th>
        <td>

        </td>
    </tr>
</table>
