import {Component, EventEmitter, Input, NgZone, OnInit, Output} from '@angular/core';
import {Person} from './personen/common/person';

@Component({
    selector: 'person-field',
    template: `
        <div class="form-group row">
            <label for="{{ id }}" class="col-{{ labelwidth }} col-form-label" *ngIf="labelwidth>0">
                {{ label }}
            </label>
            <div class="col-{{ 12-labelwidth }}">
                <person-inline-field
                        id="{{ id }}"
                        [label]="label"
                        [value]="_value"
                        (valueChange)="doSelect($event)"
                        [small]="small"
                        [nullable]="nullable"
                ></person-inline-field>
            </div>
        </div>
    `
})
export class PersonFieldComponent implements OnInit {
    @Input('nullable') nullable = true;
    @Input('small') small = false;
    public _value: Person = null;
    @Input('label') label = 'Personfeld';
    @Input('id') id = '';
    @Input('labelwidth') labelwidth = 3;

    @Input('value')
    set value(v: Person) {

        if (this._value !== v) {
            this._value = v;
            this.valueChange.emit(v);
        }
    }

    @Output() valueChange = new EventEmitter<Person>();


    get value(): Person {
        return this._value;
    }

    constructor(public _ngZone: NgZone) {
    }

    ngOnInit() {
    }

    doSelect(person: Person) {
        this._value = person;
        this.valueChange.emit(person);
    }


}
