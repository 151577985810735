import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import {
    MEHighlitePipe, MESelectComponent, MESelectItemMatcherCallback,
    MESelectItemTextCallback
} from './me-select/meselect.component';

import {MESelectItemFormatterCallback} from './me-select/meselect-item-formatter-callback';
import {WaehrungList} from '../waehrungen/waehrung-list';
import {Waehrung} from '../waehrungen/waehrung';
import {SprachenService} from '../sprachen/common/sprachen.service';
import {IListResult} from '../list-result';
import {IWaehrung} from '../waehrungen/iwaehrung';
import CryptoES from "crypto-es";

@Component({
    selector: 'currency-field',
    template: `
        <div class="form-group row">
            <label for="{{ id }}" class="col-{{ labelwidth }} col-form-label" *ngIf="labelwidth>0">
                {{ label }}
            </label>
            <div class="col-{{ 12-labelwidth }}">

                <meselect
                        id="{{ id }}"
                        [idField]="'code'"
                        [items]="currencies.data"
                        [placeholder]="label"
                        (itemSelected)="doSelect($event)"
                        [nullable]="nullable"
                        [small]="small"
                >

                </meselect>

            </div>
        </div>
    `,
    styles: [`

        :host .addon button:first-child {
            border-left: none;
            padding-left: 5px;
            padding-right: 5px;
        }

    `],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CurrencyFieldComponent implements OnInit, AfterViewInit {
    @Input() nullable = true;
    @Input() small = false;

    @ViewChild(MESelectComponent, {static: true}) currencyMESelect: MESelectComponent;

    currencies: WaehrungList = new WaehrungList();

    _value: Waehrung = null;

    @Input() label = 'Textfeld';
    @Input() id = '';
    @Input() labelwidth = 3;
    @Input() maxDisplayCurrencies = 400;


    @Input()
    set value(v: Waehrung) {
        if (this._value !== v) {
            this._value = v;
            if (this.currencyMESelect !== null && this.currencyMESelect !== undefined) {
                this.currencyMESelect.value = v;
            }
            this.valueChange.emit(v);
            this.cd.markForCheck();
        }
    }

    @Output() valueChange = new EventEmitter<Waehrung>();


    get value(): Waehrung {
        return this._value;
    }

    constructor(public sprachenService: SprachenService,
                public cd: ChangeDetectorRef
    ) {
        this.sprachenService
            .listCurrencies(this.maxDisplayCurrencies, 0)
            .subscribe(
                (c: IListResult<IWaehrung>) => {
                    this.currencies.populateFromListResult(c);
                    this.cd.markForCheck();
                }
            );
    }

    ngOnInit() {
    }


    doSelect(currency: Waehrung) {
        this._value = currency;
        this.valueChange.emit(currency);
        this.cd.markForCheck();
    }

    public itemFormat: MESelectItemFormatterCallback<Waehrung> = (item: Waehrung, search) => {
        if (item === null) {
            return '';
        }
        if (item === undefined) {
            return '';
        }
        if ((item as any) === '') {
            return '';
        }
        return MEHighlitePipe.transformString(item.symbol, search) + ' - ' +
            MEHighlitePipe.transformString(item.bezeichnung, search) +
            ' <em>[' + MEHighlitePipe.transformString(item.code, search) + ']</em>';
    }

    public itemText: MESelectItemTextCallback<Waehrung> = (item: Waehrung) => {
        if (item === null) {
            return '';
        }
        if (item === undefined) {
            return '';
        }
        if ((item as any) === '') {
            return '';
        }
        return item.symbol + ' - ' + item.bezeichnung + ' [' + item.code + ']';
    }

    public itemMatcher: MESelectItemMatcherCallback<Waehrung> = (item: Waehrung, search) => {
        if (!search) {
            return true;
        }
        if (search.trim() === '') {
            return true;
        }
        if (!item) {
            return false;
        }
        if ((item as any) === '') {
            return false;
        }

        const x = item.symbol + ' ' + item.bezeichnung + ' ' + item.code;

        return (x.toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) >= 0);
    }

    ngAfterViewInit(): void {
        this.currencyMESelect.onFormat = this.itemFormat;
        this.currencyMESelect.onGetText = this.itemText;
        this.currencyMESelect.onMatch = this.itemMatcher;
        this.currencyMESelect.value = this._value;
        if (this.id === '') {
            this.id = 'tf' + CryptoES.MD5('textfield-' + this.label + Math.random() + '-' + Math.random()).toString();
        }
        this.cd.markForCheck();
    }

}
