<app-login-alert>


    <app-area-heading
            [icon]="'far fa-money-bill-alt'"
            title="{{ _('Preispflege') }}"
    ></app-area-heading>

    <app-tabcard>
        <app-pane title="{{ _('Stationspreise') }}">
            <app-tab-preise></app-tab-preise>
        </app-pane>
        <app-pane title="{{ _('Preisgruppen') }}" *hasRole="['ROLE_ADMIN','ROLE_PROJECT_ADMIN','ROLE_BILLING']">
            <app-tab-preisgruppen></app-tab-preisgruppen>
        </app-pane>
    </app-tabcard>

</app-login-alert>
