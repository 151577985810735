import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {Kunde} from '../../customers/kunden/common/kunde';

import {RechnungService} from '../rechnungen/common/rechnung.service';
import {KundenService} from '../../customers/kunden/common/kunden.service';
import {Router} from '@angular/router';
import {IActionResponse} from '../../commons/action-response';

@Component({
    selector: 'app-dashboard-invoices-block',
    templateUrl: './dashboard-invoices-block.component.html',
    styleUrls: ['./dashboard-invoices-block.component.scss']
})
export class DashboardInvoicesBlockComponent implements OnInit {

    @Input() title = 'Rechnungen';
    @Input() showUnread = false;
    @Input() destination = 'rechnungen';
    @Input() cls = 'red';
    @Input() showAlways = false;

    cnt = 0;

    constructor(
        public cd: ChangeDetectorRef,
        public rechnungService: RechnungService,
        public router: Router
    ) {
    }

    ngOnInit() {
        this.refresh();
    }

    refresh() {
        this.rechnungService
            .countRechnungen(this.showUnread)
            .subscribe(
                (c: IActionResponse) => {
                    this.cnt = parseInt('' + c.params.count, 10);
                    this.cd.markForCheck();
                },
                () => {
                    this.cnt = 0;
                    this.cd.markForCheck();
                }
            );

    }


    gotoArea() {
        this.router.navigate([this.destination]);
    }

}
