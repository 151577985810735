import {IOption, Option} from '../../../commons/dynamic-form/select-field/ioption';
import {ELimitArt} from './elimit-art';
import {METranslationCallback} from '../../../commons/metranslation-callback';

export class LimitArt {

    static getIcon(limitArt: ELimitArt): string {
        let icon = 'icofont ';
        switch (limitArt) {
            case ELimitArt.AMOUNT:
                icon = 'icofont icofont-bill';
                break;
            case ELimitArt.VOLUME:
                icon = 'icofont icofont-bucket';
                break;
        }
        return icon;
    }

    static getOptions(translateFunc: METranslationCallback = null): IOption[] {
        const options = [
            Option.option(ELimitArt.AMOUNT, 'Maximalbetrag', LimitArt.getIcon(ELimitArt.AMOUNT)),
            Option.option(ELimitArt.VOLUME, 'Maximalmenge', LimitArt.getIcon(ELimitArt.VOLUME))
        ];
        if (translateFunc !== null && translateFunc !== undefined) {
            options.forEach((v: IOption, idx) => {
                options[idx].label = translateFunc(v.label);
            });
        }
        return options;
    }
}