import {AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {TransaktionList} from '../common/transaktion-list';

import {TransaktionService} from '../common/transaktion.service';
import {TransaktionDetailsComponent} from '../details/transaktion-details.component';
import {TransaktionenImporterComponent} from '../importer/transaktionen-importer.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Transaktion} from '../common/transaktion';

import {TransaktionDetailsRendererComponent} from '../renderers/transaktion-details-renderer.component';
import {PanCellRendererComponent} from '../../kartenanfragen/renderers/pan-cell-renderer.component';
import {LocalizedComponent} from '../../../commons/components/localized-component';
import {NewPageCountEvent} from '../../../commons/paginator/paginator.component';
import {PreisWertCellRendererComponent} from '../../../commons/data-table/renderer/preis-wert-cell-renderer.component';
import {DataTableComponent, DataTableFilterChanged, DataTableSortChanged} from '../../../commons/data-table/data-table.component';
import {DTFahrzeugKfzkennzeichenRendererComponent} from '../../../customers/fahrzeuge/renderer/dtfahrzeug-kfzkennzeichen-renderer.component';
import {KitchenTimer} from '../../../commons/kitchen-timer';
import {ResultMetaSortImpl} from '../../../commons/result-meta';
import {StationCellRendererComponent} from '../../../stationen/station-cell-renderer.component';
import {KundeCellRendererComponent} from '../../../customers/kunden/kunde-cell-renderer.component';
import {AusweisCellRendererComponent} from '../../../customers/ausweise/ausweis-cell-renderer.component';
import {noop, Subscription} from 'rxjs';
import {TransaktionPrintDialogComponent} from '../transaktion-print-dialog/transaktion-print-dialog.component';
import {SysInfoService} from '../../../commons/sysinfo/services/sys-info.service';
import {ToastrService} from 'ngx-toastr';
import {IListResult} from '../../../commons/list-result';
import {ITransaktion} from '../common/itransaktion';
import {TransaktionEditorComponent} from '../transaktion-editor/transaktion-editor.component';
import {DataTableRow} from '../../../commons/data-table/data-table-row';
import {TDTGetExtraRowClassesCallback} from '../../../commons/data-table/types';
import {TransaktionenFilterdialogComponent} from '../transaktionen-filterdialog/transaktionen-filterdialog.component';
import * as FileSaver from 'file-saver';
import {ConfirmDialogComponent} from '../../../commons/confirm-dialog/confirm-dialog.component';
import {DTKostenstelleRendererComponent} from '../../../customers/kostenstellen/dtkostenstelle-renderer/dtkostenstelle-renderer.component';
import {FahrzeugCellRendererComponent} from '../../../customers/fahrzeuge/fahrzeug-cell-renderer.component';
import {LoginService} from '../../../auth/services/login.service';
import {RowActionParams} from '../../../commons/data-table/row-action-params';

@Component({
    selector: 'app-tab-transaktionen',
    templateUrl: './tab-transaktionen.component.html',
    styleUrls: ['./tab-transaktionen.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TabTransaktionenComponent extends LocalizedComponent implements OnInit, OnDestroy, AfterViewInit {

    public preiswert = PreisWertCellRendererComponent;
    panCellRenderer = PanCellRendererComponent;
    kfzRenderer = DTFahrzeugKfzkennzeichenRendererComponent;
    prdCellRenderer = TransaktionDetailsRendererComponent;
    stationR = StationCellRendererComponent;
    kundeR = KundeCellRendererComponent;
    ausweisR = AusweisCellRendererComponent;
    kostenstelleR = DTKostenstelleRendererComponent;
    fzRenderer = FahrzeugCellRendererComponent;

    private _redosPending=0;
    toHandle:any=0;


    get redosPending(): number {
        return this._redosPending;
    }

    set redosPending(value: number) {
        if (this._redosPending !== value) {
            this._redosPending = value;
            this.cd.markForCheck();
            if (value > 0) {
                if(this.toHandle!==0){
                    clearTimeout(this.toHandle);
                }
               this.toHandle= setTimeout(() => {
                    this.updateRedosPending();
                    this.toHandle=0;
                },60000);
            }
        }
    }

    sub: Subscription = null;

    public transaktionen: TransaktionList = new TransaktionList();

    @ViewChild('transaktionenListe',{static: true}) transaktionenListe: DataTableComponent;
    @ViewChild('filterDialog',{static: true}) filterDialog: TransaktionenFilterdialogComponent;

    transaktionenRowClassGetter: TDTGetExtraRowClassesCallback<Transaktion> = (row: DataTableRow<Transaktion>) => {
        let c=[]
        if (
            row !== null &&
            row !== undefined &&
            row.data !== null &&
            row.data !== undefined
        ) {
            c.push( 'transaktion-' + (row.data.storniert ? 'storniert' : 'regular'));

            if (row.data.markedForRedo ){
                c.push('text-gray')
            }
        }
        return c.join(' ');
    }


    constructor(

                public transaktionService: TransaktionService,
                public toastr: ToastrService,
                public modalService: NgbModal,
                public sysInfoService: SysInfoService,
                public cd: ChangeDetectorRef,
                public loginService: LoginService
    ) {
        super( cd);
        this.transaktionen.size = this.loginService.getPageSize('transaktionen');
    }

    ngOnInit() {
        this.sub = this.transaktionen.onUpdateRequired.subscribe(
                (list) => {
                    this.transaktionService
                        .list(
                            this.transaktionen.size,
                            this.transaktionen.calcOffset(),
                            this.transaktionen.order,
                            this.transaktionen.simpleFilter,
                            this.transaktionen.getQuery()
                        )
                        .subscribe(
                            (l: IListResult<ITransaktion>) => {
                                list.populateFromListResult(l);
                                this.updateRedosPending()
                                this.cd.markForCheck();
                            },
                            (e) => {
                                this.toastr.error(this._(
                                    'Die Liste der Transaktionen konnte nicht geladen werden.'));
                                this.transaktionen.clear();
                                this.updateRedosPending()
                                this.cd.markForCheck();
                            }
                        );
                }
            );


        this.updateTransaktionen();
    }

    ngOnDestroy() {
        if (this.sub !== null && this.sub !== undefined) {
            this.sub.unsubscribe();
        }
    }

    updateRedosPending() {
        this.transaktionService.getRedosPending().subscribe(
            (pending) => {
                this.redosPending = pending;
                this.cd.markForCheck();
            }
        );
    }

    updateTransaktionen(force = false, page?: number) {
        if ((page !== undefined) && (page != null)) {
            if (page !== this.transaktionen.page) {
                this.transaktionen.page = page;
                force = true;
            }
        }
        if(force){
            this.transaktionen.reload();
        }else {
            this.transaktionen.start();
        }
        this.updateRedosPending()
    }

    updateTransaktionenPageCount(n: NewPageCountEvent) {
        if (this.transaktionen.size !== n.itemsPerPage) {
            this.transaktionen.size = n.itemsPerPage;
            this.loginService.setPageSize('transaktionen', n.itemsPerPage);
        }
        this.updateTransaktionen(true, n.currentPage);
    }


    sortChanged(e: DataTableSortChanged) {
        this.transaktionen.order = [
            new ResultMetaSortImpl(e.field, e.direction)
        ];
        this.updateTransaktionen();
    }

    filterChanged(e: DataTableFilterChanged) {
        this.transaktionen.simpleFilter[e.field] = e.value;
        this.updateTransaktionen();
    }

    showDetails(event: RowActionParams<Transaktion>) {
        TransaktionDetailsComponent
            .open(this.modalService, event.row.data as Transaktion)
            .then(
                e => {
                },
                () => {
                }
            );
    }

    importTransactions() {
        TransaktionenImporterComponent
            .open(this.modalService, TransaktionenImporterComponent, null, 'lg')
            .then(
                () => {
                    this.updateTransaktionen();
                },
                () => {
                    this.updateTransaktionen();
                }
            );
    }

    transaktionenQueryChange(value = '') {
        if (value !== this.transaktionen.searchString) {
            this.transaktionen.searchString = value;
            this.updateTransaktionen(false, 1);
        }
    }

    realDlTransaktionen(type = 'csv', exportType = 0) {

        this.toastr
            .info(this._('Download wird angefordert. Bitte einen Moment Geduld...'),
                this._('Aktion wird ausgeführt...'),
                {
                    timeOut: 15000
                }
            )
            .onShown
            .subscribe(
                () => {
                    this.transaktionService.exportData(
                        type,
                        this.transaktionen.simpleFilter,
                        this.transaktionen.getQuery(),
                        exportType
                    ).subscribe(
                        (response: Blob) => {
                            FileSaver.saveAs(response, this._('transaktionen.' + type));
                        },
                        (e) => {
                            this.toastr.error(this._('Die Liste der Transaktionen konnte nicht exportiert werden.'));
                        }
                    );
                });


    }


    downloadTransaktionen(type = 'csv', exporttype = 0) {
        if (this.transaktionen.total < 1) {
            this.toastr.error(this._(
                'Es werden keine Datensätze angezeigt, daher gibt es auch nichts zum Herunterladen.'));
        } else if (this.transaktionen.total < 500) {
            this.realDlTransaktionen(type, exporttype);
        } else if (this.transaktionen.total > 25000) {
            this.toastr.error(
                this._('Sie wollen {{ anzahl }} Datensätze herunterladen. Da es erfahrungsgemäß ' +
                    'hierbei zu Downloadabbrüchen seitens des Webbrowsers kommt, bitten wir Sie,' +
                    'die Ergebnismenge zunächst einzugrenzen.', {anzahl: this.transaktionen.total}),
                this._('Aktion nicht möglich'),
                {
                    timeOut: 15000
                }
            );
        } else {
            ConfirmDialogComponent
                .display(
                    this.modalService,
                    this._('Download durchführen?'),
                    this._('Sie wollen {{ anzahl }} Datensätze herunterladen. Dies wird unter Umständen ' +
                        'eine längere Zeit dauern und je nach Browser zu einem Verbindungsabbruch führen. <br /><br /> ' +
                        'Wir empfehlen, die Liste zunnächst weiter einzuschränken.',
                        {anzahl: this.transaktionen.total}
                    ),
                    this._('Download trotzdem durchführen'),
                    'icofont icofont-download-alt'
                )
                .result
                .then(
                    () => {
                        this.realDlTransaktionen(type, exporttype);
                    },
                    noop
                );
        }
    }

    reindexTransaktionen() {
        this.transaktionService
            .reindexTransaktionen()
            .subscribe(
                () => {
                    this.toastr.success(this._('Der Indizierungsvorgang wurde angestoßen.'));
                },
                () => {
                    this.toastr.error(this._('Die Indizierungsvorgang konnte nicht angestoßen werden.'));
                }
            );
    }

    showLieferschein(event: RowActionParams<Transaktion>) {
        TransaktionPrintDialogComponent
            .open(this.modalService, event.row.data as Transaktion)
            .then(
                e => {
                },
                () => {
                }
            );
    }

    redoTransaktionen() {
        this.transaktionService
            .redoTransaktionen()
            .subscribe(
                () => {
                    this.toastr.success(this._('Der Neubewertungsvorgang wurde angestoßen.'));
                },
                () => {
                    this.toastr.error(this._('Die Neubewertungs konnte nicht angestoßen werden.'));
                }
            );
    }

    editTransaktion(event: RowActionParams<Transaktion>) {
        TransaktionEditorComponent
            .open(this.modalService, event.row.data as Transaktion)
            .then(
                e => {
                    this.updateTransaktionen();
                },
                () => {
                }
            );
    }


    ngAfterViewInit(): void {
        if (this.transaktionenListe !== null && this.transaktionenListe !== undefined) {
            this.transaktionenListe.extraRowClasses = this.transaktionenRowClassGetter;
            this.cd.markForCheck();
        }
    }

    transaktionenQueryChange2(value: string) {
        if (value !== this.transaktionen.searchString2) {
            this.transaktionen.searchString2 = value;
            this.updateTransaktionen(false, 1);
        }
    }

    transaktionenFilterToggle(visible: boolean) {
        if (!visible) {
            this.transaktionenQueryChange2('');
        }
    }

    typed(it: any):Transaktion[] {
        if(it===null || it===undefined) {
            return [];
        }
        return [it as Transaktion];
    }
}
