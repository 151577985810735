import {Component, Injector, OnInit} from '@angular/core';
import {DataTableRow} from '../../commons/data-table/data-table-row';
import {DataTableColumnDirective} from '../../commons/data-table/data-table-column.directive';
import {CommonCellRenderer} from '../../commons/data-table/renderer/common-cell-renderer';

@Component({
    selector: 'app-permissions-renderer',
    template: `
        <app-permissions-quick-info
                *ngIf="isValid()"
                [permissions]="cell"
        ></app-permissions-quick-info>
    `
})
export class PermissionsRendererComponent extends CommonCellRenderer {

    cell: string[] = null;
    row: DataTableRow<any> = null;
    column: DataTableColumnDirective = null;

    constructor(public injector: Injector) {
        super();

        this.cell = this.injector.get('cell');
        this.row = this.injector.get('row');
        this.column = this.injector.get('column');
    }

    isValid() {
        return this.cell !== null && this.cell !== undefined && Array.isArray(this.cell);
    }
}
