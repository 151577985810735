import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {Point} from './point';
import {Area} from './area';

@Component({
    selector: '[app-diagramm-balken]',
    template: `
        <svg:g *ngIf="visible">
            <svg:rect
                    *ngFor="let b of balken"
                    [attr.x]="b.x"
                    [attr.y]="b.y"
                    [attr.width]="b.width"
                    [attr.height]="b.height"
                    [attr.fill]="b.color"
                    [attr.opacity]="b.opacity"
            />
        </svg:g>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush

})
export class DiagrammBalkenComponent implements OnInit {
    private _visible = true;
    private _color = '#3983ab';
    private _area: Point[] = [];
    private _balken: Area[] = [];
    private _points: Point[] = [];
    private _pts: Point[] = [];
    private _balkenWidth = 20;

    get visible(): boolean {
        return this._visible;
    }

    @Input()
    set visible(value: boolean) {
        if (this._visible !== value) {
            this._visible = value;
            this.update();
        }
    }


    get color(): string {
        return this._color;
    }

    @Input()
    set color(value: string) {
        if (this._color !== value) {
            this._color = value;
            this.update();
        }
    }

    get area(): Point[] {
        return this._area;
    }

    @Input()
    set area(value: Point[]) {
        this._area = value;
        this.update();
    }


    get balken(): Area[] {
        return this._balken;
    }

    set balken(value: Area[]) {
        this._balken = value;
    }


    get points(): Point[] {
        return this._points;
    }

    set points(value: Point[]) {
        this._points = value;
    }


    get pts(): Point[] {
        return this._pts;
    }

    @Input()
    set pts(value: Point[]) {
        this._pts = value;
        this.update();
    }


    get balkenWidth(): number {
        return this._balkenWidth;
    }

    @Input()
    set balkenWidth(value: number) {
        if (this._balkenWidth !== value) {
            this._balkenWidth = value;
            this.update();
        }
    }

    constructor(public cd: ChangeDetectorRef) {
    }

    ngOnInit() {
    }

    private update() {
        this.balken = [];
        if (this._pts === null || this._pts === undefined || this._pts.length < 1) {
            return;
        }
        if (this._area === null || this._area === undefined || this._area.length < 2) {
            return;
        }
        const p: string[] = [];

        this._pts.forEach((pt: Point) => {
                if (!pt.hidden) {
                    const a = new Area();
                    a.x = pt.x - (this._balkenWidth / 2);
                    a.width = this._balkenWidth;
                    a.y = pt.y;
                    a.height = this._area[1].y - pt.y;
                    if (a.height <= 0) {
                        a.y = a.y - 1;
                        a.height = 1;
                    }
                    a.color = this.color;
                    this._balken.push(a);
                }
            }
        );

        this.cd.markForCheck();
    }

    mover(p, $event) {
        console.log(p, $event);
    }
}
