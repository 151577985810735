import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'data-table-footer',
    template: `
        <ng-content></ng-content>
    `
})
export class DataTableFooterComponent implements OnInit {
    @Input() id = '';

    constructor() {
    }

    ngOnInit() {
    }

}
