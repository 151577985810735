import {IKundeAudit} from './ikunde-audit';
import {IBenutzer} from '../../../auth/common/ibenutzer';
import {ConverterTool} from '../../../../shared/converter-tool';
import {Benutzer} from '../../../auth/common/benutzer';

export class KundeAudit implements IKundeAudit {
    id = 0;
    benutzer: IBenutzer = null;
    dateCreated: Date = null;
    info = '';
    uname = '';

    static fromResult(entity: IKundeAudit): KundeAudit {
        if ((entity === null) || (entity === undefined)) {
            return null;
        }

        let l = new KundeAudit();
        for (let k of Object.keys(l)) {
            if (k === 'dateCreated') {
                l.dateCreated = ConverterTool.toDate(entity.dateCreated);
            } else if (k === 'benutzer') {
                l.benutzer = Benutzer.fromResult(entity.benutzer);
            } else {
                l[k] = (entity[k] !== undefined) ? entity[k] : '';
            }
        }

        if (l.uname === null || l.uname === undefined) {
            if (l.benutzer !== null && l.benutzer !== undefined) {
                l.uname = l.benutzer.username;
            }
        }

        return l;
    }


    constructor() {
        //
    }
}
