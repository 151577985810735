import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {LocalizedComponent} from '../commons/components/localized-component';

import {ProdukteService} from './produkte.service';

import {ArtikelList} from './artikel/artikel-list';
import {KitchenTimer} from '../commons/kitchen-timer';
import {SysInfoService} from '../commons/sysinfo/services/sys-info.service';
import {ToastrService} from 'ngx-toastr';

@Component({
    selector: 'app-produkte',
    templateUrl: './produkte.component.html',
    styleUrls: ['./produkte.component.css']
})
export class ProdukteComponent extends LocalizedComponent implements OnInit {

    public artikel: ArtikelList = new ArtikelList();
    public artikelTimer: KitchenTimer;

    constructor(

                public produkteService: ProdukteService,
                public sysInfoService: SysInfoService,
                public cd: ChangeDetectorRef,
                public toastr: ToastrService) {
        super( cd);
    }

    ngOnInit() {
        this.sysInfoService.updateDocumentTitle(this._('Produkte'));
    }


}
