import {ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {LocalizedComponent} from '../../commons/components/localized-component';
import {Subscription} from 'rxjs';
import {DataTableComponent} from '../../commons/data-table/data-table.component';
import {Station} from '../common/station';
import {TerminalList} from './common/terminal-list';
import {KitchenTimer} from '../../commons/kitchen-timer';

import {NgbActiveModal, NgbModal, NgbModalOptions, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {StationenService} from '../common/stationen.service';

import {PubSubService} from '../../commons/pub-sub.service';
import {NewPageCountEvent} from '../../commons/paginator/paginator.component';
import {Terminal} from './common/terminal';
import {ConfirmDialogComponent} from '../../commons/confirm-dialog/confirm-dialog.component';
import {TerminalEditorComponent} from './editor/terminal-editor.component';
import {ToastrService} from 'ngx-toastr';
import {IListResult} from '../../commons/list-result';
import {ITerminal} from './common/iterminal';
import {RowActionParams} from '../../commons/data-table/row-action-params';

@Component({
    selector: 'app-terminals-modal',
    templateUrl: './terminals-modal.component.html',
    styleUrls: ['./terminals-modal.component.css']
})
export class TerminalsModalComponent extends LocalizedComponent implements OnInit, OnDestroy {
    public sub: Subscription = null;

    @ViewChild('terminalsTable',{static: true}) terminalsTable: DataTableComponent;

    get msg_title() {
        return this._('Terminals der Station {{ name }} [{{ nummer }}]', {name: this.station.bezeichnung, 'nummer': this.station.nummer});
    }

    station: Station = new Station();
    terminals: TerminalList = new TerminalList();
    public terminalsTimer: KitchenTimer;


    constructor(

                public activeModal: NgbActiveModal,
                public stationenService: StationenService,
                public toastr: ToastrService,
                public modalService: NgbModal,
                public pubsub: PubSubService,
                public cd: ChangeDetectorRef) {
        super( cd);
        this.terminalsTimer = new KitchenTimer();
        this.terminalsTimer.subscribe(
            () => {
                this.stationenService
                    .listTerminals(
                        this.station,
                        this.terminals.size,
                        this.terminals.calcOffset(),
                        this.terminals.order,
                        this.terminals.simpleFilter,
                        this.terminals.searchString
                    )
                    .subscribe(
                        (l: IListResult<ITerminal>) => {
                            this.terminals.populateFromListResult(l);
                        },
                        () => {
                            this.toastr.error(this._('Die Liste der Terminals konnte nicht geladen werden.'));
                            this.terminals.clear();
                        }
                    );
            }
        );

    }

    ngOnInit() {
        this.sub = this.pubsub.loginError.subscribe(
            () => {
                this.cancel();
            }
        );

        this.updateTerminals();
    }

    ngOnDestroy(): void {
        if (this.sub != null) {
            this.sub.unsubscribe();
            this.sub = null;
        }
    }

    cancel() {
        this.activeModal.dismiss('cancel');
    }


    edit(e: Station) {
        this.station = e;
    }


    updateTerminals(force = false, page?: number) {
        if ((page !== undefined) && (page != null)) {
            if (page !== this.terminals.page) {
                this.terminals.page = page;
                force = true;
            }
        }
        this.terminalsTimer.start(force);
    }

    /**
     * ändert die Anzahl der Datensätze/Seite und lädt die Liste neu
     * @param n
     */
    updateTerminalsPageCount(n: NewPageCountEvent) {
        this.terminals.size = n.itemsPerPage;
        this.updateTerminals(true, n.currentPage);
    }


    removeTerminal(event: RowActionParams<Terminal>) {
        ConfirmDialogComponent.display(
            this.modalService,
            this.t('Terminal löschen'),
            this.t('Sollen das Terminal {{ title }} und alle dazugehörigen Daten ' +
                'wirklich unwideruflich gelöscht werden?', {title: (event.row.data as Terminal).bezeichnung}),
            this.t('Terminal jetzt löschen')
        ).result
            .then(
                e => {
                    this.stationenService
                        .deleteTerminal(this.station, event.row.data as Terminal)
                        .subscribe(
                            r => {
                                if (r.success) {
                                    this.toastr.success(this.t('Das Terminal wurde gelöscht.'));
                                    this.updateTerminals(true);
                                } else {
                                    this.toastr.error(this.t('Das Löschen des Terminals ist fehlgeschlagen'));
                                    this.updateTerminals(true);
                                }
                            },
                            () => {
                                this.toastr.error(this.t('Das Löschen des Terminals ist fehlgeschlagen'));
                                this.updateTerminals(true);
                            }
                        );
                },
                k => {
                }
            );

    }

    addTerminal() {
        const o: NgbModalOptions = {
            /*size: 'lg',*/
            backdrop: 'static'
        };
        const r: NgbModalRef = this.modalService.open(TerminalEditorComponent, o);
        (r.componentInstance as TerminalEditorComponent).editNew(this.station);
        r.result.then(
            e => {
                this.updateTerminals();
            },
            () => {
                this.updateTerminals();
            }
        );
    }

    editTerminal(event: RowActionParams<Terminal>) {
        const o: NgbModalOptions = {
            /*size: 'lg',*/
            backdrop: 'static'
        };
        const r: NgbModalRef = this.modalService.open(TerminalEditorComponent, o);
        (r.componentInstance as TerminalEditorComponent).edit(this.station, event.row.data as Terminal);
        r.result.then(
            e => {
                this.updateTerminals();
            },
            () => {
                this.updateTerminals();
            }
        );

    }

}
