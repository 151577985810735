import {GenericFilter} from '../../generic-filter';
import {Issuer} from './issuer';
import {IIssuer} from './iissuer';

export class SimpleIssuerFilter extends GenericFilter<Issuer> implements IIssuer {
    id: number                          = null;
    nummer: number                      = null;
    bezeichnung: string                 = null;
    enabled: boolean                    = null;
    pattern: string                     = null;
    extractAusweisNummerPattern: string = null;
    isRegexp: boolean                   = null;
    logo: string                        = null;
    rank: number                        = null;
    isProjektIssuer: boolean            = null;
}
