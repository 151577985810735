import {ChangeDetectionStrategy, Component, Injector, OnInit} from '@angular/core';
import {IKostenstelle} from '../common/ikostenstelle';
import {DataTableColumnDirective} from '../../../commons/data-table/data-table-column.directive';
import {DataTableRow} from '../../../commons/data-table/data-table-row';

@Component({
    selector: 'app-dtkostenstelle-renderer',
    templateUrl: './dtkostenstelle-renderer.component.html',
    styleUrls: ['./dtkostenstelle-renderer.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DTKostenstelleRendererComponent {
    info_position = 'right';

    cell: IKostenstelle = null;
    row: DataTableRow<any> = null;
    column: DataTableColumnDirective = null;


    constructor(public injector: Injector) {

        this.cell = this.injector.get('cell');
        this.row = this.injector.get('row');
        this.column = this.injector.get('column');

        if (this.column !== null && this.column !== undefined) {
            if ((this.column.cfg !== null) && (this.column.cfg !== undefined)) {
                if (this.column.cfg.info_position !== null) {
                    this.info_position = this.column.cfg.info_position;
                }
            }
        }

    }
}
