import {IKartenanfrage} from './ikartenanfrage';
import {Station} from '../../../stationen/common/station';
import {KartenanfrageErgebnis} from './kartenanfrage-ergebnis';
import {Kunde} from '../../../customers/kunden/common/kunde';
import {Fahrzeug} from '../../../customers/fahrzeuge/common/fahrzeug';
import {Person} from '../../../customers/personen/common/person';
import {Waehrung} from '../../../commons/waehrungen/waehrung';
import {Terminal} from '../../../stationen/terminals/common/terminal';
import {Ausweis} from '../../../customers/ausweise/common/ausweis';
import {ConverterTool} from '../../../../shared/converter-tool';

export class Kartenanfrage implements IKartenanfrage {
    id = 0;
    kunde: Kunde = null;
    fahrzeug: Fahrzeug = null;
    person: Person = null;
    waehrung: Waehrung = null;
    terminal: Terminal = null;
    station: Station = null;
    ausweis: Ausweis = null;
    zeitpunkt: any = null;
    tankserverId = 0;
    pan = '';
    zusatzEingabe = '';
    panerkennungInfo = '';
    kurswert = 0;
    fahrerNummer = 0;
    kmStand = 0;
    ergebnis: KartenanfrageErgebnis = null;
    kontingent = 0;
    kontingentTyp = '';
    kartennummer = 0;

    static fromResult(entity: IKartenanfrage, createIfNull = true): Kartenanfrage {
        if ((entity === null) || (entity === undefined)) {
            if (createIfNull) {
                return new Kartenanfrage();
            }
            return null;
        }

        const l = new Kartenanfrage();

        for (let k of Object.keys(l)) {
            if (k === 'kunde') {
                l.kunde = Kunde.fromResult(entity.kunde)
            } else if (k === 'fahrzeug') {
                l.fahrzeug = Fahrzeug.fromResult(entity.fahrzeug)
            } else if (k === 'person') {
                l.person = Person.fromResult(entity.person);
            } else if (k === 'waehrung') {
                l.waehrung = Waehrung.fromResult(entity.waehrung)
            } else if (k === 'terminal') {
                l.terminal = Terminal.fromResult(entity.terminal)
            } else if (k === 'station') {
                l.station = Station.fromResult(entity.station)
            } else if (k === 'ausweis') {
                l.ausweis = Ausweis.fromResult(entity.ausweis)
            } else if (k === 'zeitpunkt') {
                l.zeitpunkt = ConverterTool.toDate(entity.zeitpunkt)
            } else if (k === 'ergebnis') {
                l.ergebnis = KartenanfrageErgebnis.fromResult(entity.ergebnis, true);
            } else {
                l[k] = (entity[k] !== undefined) ? entity[k] : null;
            }
        }

        return l;
    }


    constructor() {
    }
}
