import {ChangeDetectorRef, Component, NgZone, OnInit, ViewChild} from '@angular/core';

import {LoginService} from '../../auth/services/login.service';

import {NewPageCountEvent} from '../../commons/paginator/paginator.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {LocalizedComponent} from '../../commons/components/localized-component';
import {NachrichtList} from './common/nachricht-list';
import {INachricht} from './common/inachricht';
import {DTNachrichtPrioritaetRendererComponent} from './dtnachricht-prioritaet-renderer/dtnachricht-prioritaet-renderer.component';
import {DTNachrichtSubjectRendererComponent} from './dtnachricht-subject-renderer/dtnachricht-subject-renderer.component';
import {Nachricht} from './common/nachricht';
import {NachrichtDetailsComponent} from './nachricht-details/nachricht-details.component';
import * as moment from 'moment';
import {PubSubService} from '../../commons/pub-sub.service';
import {DataTableComponent, DataTableFilterChanged, DataTableSelectionChanged, DataTableSortChanged} from '../../commons/data-table/data-table.component';
import {KitchenTimer} from '../../commons/kitchen-timer';
import {IListResult} from '../../commons/list-result';
import {ResultMetaSortImpl} from '../../commons/result-meta';
import {NachrichtenService} from '../nachrichten.service';
import {SysInfoService} from '../../commons/sysinfo/services/sys-info.service';
import {ToastrService} from 'ngx-toastr';
import {RowActionParams} from '../../commons/data-table/row-action-params';
import {DataTableRow} from '../../commons/data-table/data-table-row';
import {noop} from '../../commons/noop';

@Component({
    selector: 'app-nachrichten',
    templateUrl: './nachrichten.component.html',
    styleUrls: ['./nachrichten.component.scss']
})
export class NachrichtenComponent extends LocalizedComponent implements OnInit {

    @ViewChild('messageTable',{static: true}) messageTable: DataTableComponent;

    prioRenderer = DTNachrichtPrioritaetRendererComponent;
    subjectRenderer = DTNachrichtSubjectRendererComponent;

    messages: NachrichtList = new NachrichtList();
    messagesTimer: KitchenTimer;

    hasSelection = false;

    constructor(
                
                public loginService: LoginService,
                public messageService: NachrichtenService,
                public toastr: ToastrService,
                public zone: NgZone,
                public modalService: NgbModal,
                public sysInfoService: SysInfoService,
                public cd: ChangeDetectorRef,
                public pubSub: PubSubService) {
        super( cd);
        this.messages.size = this.loginService.getPageSize('messages');
        this.messagesTimer = new KitchenTimer();
        this.messagesTimer
            .subscribe(
                () => {
                    this.messageService
                        .listMessages(
                            this.messages.size,
                            this.messages.calcOffset(),
                            this.messages.order,
                            this.messages.simpleFilter,
                            this.messages.searchString
                        )
                        .subscribe(
                            (l: IListResult<INachricht>) => {
                                if (l !== null) {
                                    this.messages.populateFromListResult(l, false);
                                }
                            },
                            (e) => {
                                this.toastr.error(this._('Die Liste der Mitteilungen konnte nicht geladen werden.'));
                                this.messages.clear();
                            }
                        );
                }
            );
    }

    ngOnInit() {
        this.updateMessages();
        this.sysInfoService.updateDocumentTitle(this._('Mitteilungen'));

    }

    updateMessages(force = false, page?: number) {
        if ((page !== undefined) && (page !== null)) {
            if (page !== this.messages.page) {
                this.messages.page = page;
                force = true;
            }
        }
        this.messagesTimer.start(force);
    }

    /**
     * ändert die Anzahl der Datensätze/Seite und lädt die Liste neu
     * @param n
     */
    updateMessagesPageCount(n: NewPageCountEvent) {
        if (this.messages.size !== n.itemsPerPage) {
            this.messages.size = n.itemsPerPage;
            this.loginService.setPageSize('messages', n.itemsPerPage);
        }
        this.updateMessages(true, n.currentPage);
    }

    sortChanged(e: DataTableSortChanged) {
        this.messages.order = [
            new ResultMetaSortImpl(e.field, e.direction)
        ];
        this.updateMessages();
    }

    filterChanged(e: DataTableFilterChanged) {
        this.zone.run(() => {
            this.messages.simpleFilter[e.field] = e.value;
            this.updateMessages();
        });
    }

    checkMessage(s: string, msg: RowActionParams<Nachricht>) {
        if (s === 'markRead') {
            if (msg.row.data.isRead()) {
                msg.row.disableAction(msg.action);
                msg.row.hideAction(msg.action);
            }
        }
        if (s === 'markUnRead') {
            if (!msg.row.data.isRead()) {
                msg.row.disableAction(msg.action);
                msg.row.hideAction(msg.action);
            }
        }
    }

    markRead(msg: RowActionParams<Nachricht>) {
        if (!msg.row.data.isRead()) {
            this.messageService
                .markRead(msg.row.data)
                .subscribe(
                    (res) => {
                        this.pubSub.messageChange.emit();
                        this.updateMessages();
                    },
                    () => {
                        this.pubSub.messageChange.emit();
                        this.updateMessages();
                    }
                );
        }
    }

    markUnRead(msg: RowActionParams<Nachricht>) {
        if (msg.row.data.isRead()) {
            this.messageService
                .markUnRead(msg.row.data)
                .subscribe(
                    (res) => {
                        this.pubSub.messageChange.emit();
                        this.updateMessages();
                    },
                    () => {
                        this.pubSub.messageChange.emit();
                        this.updateMessages();
                    }
                );
        }
    }


    deleteMessage(msg: RowActionParams<Nachricht>) {
        this.messageService
            .deleteNachricht(msg.row.data)
            .subscribe(
                (res) => {
                    this.pubSub.messageChange.emit();
                    this.toastr.success(this._('Die Mitteilung wurde gelöscht'));
                    this.updateMessages();
                },
                () => {
                    this.pubSub.messageChange.emit();
                    this.toastr.success(this._('Fehler beim Löschen der Mitteilung.'));
                    this.updateMessages();
                }
            );
    }

    readMessage(msg: RowActionParams<any>) {

        NachrichtDetailsComponent
            .open(this.modalService, msg.row.data)
            .then(
                () => {
                    if (!msg.row.data.isRead()) {
                        msg.row.data.dateRead = moment().toDate();
                        this.pubSub.messageChange.emit();
                    }
                    this.updateMessages();
                },
                () => {
                    if (!msg.row.data.isRead()) {
                        msg.row.data.dateRead = moment().toDate();
                        this.pubSub.messageChange.emit();
                    }
                    this.updateMessages();

                }
            );

    }

    messagesQueryChange($event: string) {
        if (this.messages.searchString !== $event) {
            this.messages.searchString = $event;
            this.updateMessages(false, 1);
        }
    }

    onSelectionChanged($event: DataTableSelectionChanged) {
        $event.dataTable.hasSelectedRows();
    }

    deleteSelected() {
        if (this.messageTable === null || this.messageTable === undefined) {
            return;
        }
        if (!this.messageTable.hasSelectedRows()) {
            return;
        }
        const ids: number[] = [];
        this.messages.asDataTableRows().forEach((msg: DataTableRow<Nachricht>) => {
            if (msg.isSelected()) {
                ids.push(msg.data.id);
            }
        });
        if (ids.length > 0) {
            this.messageService.deleteNachricht(ids).subscribe(
                () => {
                    this.pubSub.messageChange.emit();
                    this.toastr.success(this._('Die Mitteilungen wurden gelöscht'));
                    this.updateMessages();
                },
                noop
            );
        }
        console.log(ids);
    }
}

