import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {LoginService} from '../../auth/services/login.service';
import {LocalizedComponent} from '../../commons/components/localized-component';

import {Router} from '@angular/router';
import {PubSubService} from '../../commons/pub-sub.service';
import {Subscription} from 'rxjs';
import {NachrichtenService} from '../nachrichten.service';
import {KitchenTimer} from '../../commons/kitchen-timer';
import {ActionResponse} from '../../commons/action-response';

@Component({
    selector: 'app-nachrichten-indicator',
    template: `
        <button type="button" class="btn btn-sm"
                [class.btn-primary]="messageCount>0" [class.btn-outline-secondary]="messageCount<1"
                (click)="gotoMessages()"
        >
            <i class="icofont icofont-ui-messaging"></i> {{ _('Mitteilungen') }}
            <span class="badge badge-light" *ngIf="messageCount>0">{{ messageCount }}</span>
        </button>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class NachrichtenIndicatorComponent extends LocalizedComponent implements OnInit, OnDestroy {

    checkTimer: KitchenTimer;
    public sub: Subscription = null;

    constructor(
        public loginService: LoginService,
        public messageService: NachrichtenService,
        public router: Router,
        public pubSub: PubSubService,
        public cd: ChangeDetectorRef) {
        super(cd);
        this.checkTimer = new KitchenTimer(180000);
        this.checkTimer
            .subscribe(
                () => {
                    this.updateMessageCount();
                }
            );


    }

    private _messageCount = 0;

    get messageCount() {
        return this._messageCount;
    }

    @Input()
    set messageCount(value: number) {
        if (this._messageCount !== value) {
            this._messageCount = value;
            this.cd.markForCheck();
        }
    }

    ngOnInit() {
        this.pubSub
            .loginStateChange
            .subscribe(() => {
                    this.updateMessageCount();
                },
                () => {
                    this.updateMessageCount();
                });
        this.sub = this.pubSub.messageChange.subscribe(
            () => {
                this.updateMessageCount();
            }
        );
        this.updateMessageCount();
    }

    ngOnDestroy(): void {
        if (this.sub !== null) {
            this.sub.unsubscribe();
            this.sub = null;
        }
    }

    updateMessageCount() {
        if (this.loginService.isLoggedIn()) {
            this.messageService
                .getUnreadMessageCount()
                .subscribe((r) => {
                        const ar = ActionResponse.fromRawActionResponse(r);
                        let m = 0;
                        if (ar !== null) {
                            m = parseInt('' + ar.params.count, 10);
                            if (isNaN(m)) {
                                m = 0;
                            }
                        }
                        this.messageCount = m;
                        if (this.checkTimer !== null && this.checkTimer !== undefined) {
                            this.checkTimer.start();
                        }
                    },
                    (e) => {
                        this.messageCount = 0;
                        if (this.checkTimer !== null && this.checkTimer !== undefined) {
                            this.checkTimer.start();
                        }
                    });
        } else {
            this.messageCount = 0;
        }
    }

    gotoMessages() {
        this.router.navigate(['messages']);
    }
}
