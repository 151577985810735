import {IRechnung} from './irechnung';
import {ERechnungStatus} from './erechnung-status';
import {Waehrung} from '../../../commons/waehrungen/waehrung';
import {IAttachment} from '../../attachments/common/iattachment';
import {Kunde} from '../../../customers/kunden/common/kunde';
import {Attachment} from '../../attachments/common/attachment';
import {ConverterTool} from '../../../../shared/converter-tool';


export class Rechnung implements IRechnung {
    waehrung: Waehrung = null;
    id = 0;
    nummer = 0;
    rechnungNummer = '';
    rechnungDatum: Date = null;
    dueDatum: Date = null;
    paidDatum: Date = null;
    readDatum: Date = null;
    kunde: Kunde = null;
    summe = 0;
    status: ERechnungStatus = ERechnungStatus.UNKNOWN;
    dateCreated: Date = null;
    lastUpdated: Date = null;
    lastEdited: Date = null;
    attachments: IAttachment[] = [];


    static fromResult(entity: IRechnung): Rechnung {
        if ((entity === null) || (entity === undefined)) {
            return null;
        }

        let l: Rechnung = new Rechnung();
        for (let k of Object.keys(l)) {
            if (k === 'dateCreated') {
                l.dateCreated = ConverterTool.toDate(entity.dateCreated);
            } else if (k === 'lastUpdated') {
                l.lastUpdated = ConverterTool.toDate(entity.lastUpdated);
            } else if (k === 'lastEdited') {
                l.lastEdited = ConverterTool.toDate(entity.lastEdited);
            } else if (k === 'rechnungDatum') {
                l.rechnungDatum = ConverterTool.toDate(entity.rechnungDatum);
            } else if (k === 'dueDatum') {
                l.dueDatum = ConverterTool.toDate(entity.dueDatum);
            } else if (k === 'paidDatum') {
                l.paidDatum = ConverterTool.toDate(entity.paidDatum);
            } else if (k === 'readDatum') {
                l.readDatum = ConverterTool.toDate(entity.readDatum);
            } else if (k === 'status') {

                if (!isNaN(parseInt(entity.status, 10))) {
                    l.status = entity.status;
                } else {
                    l.status = ERechnungStatus.UNKNOWN;
                    if (('' + entity.status) === 'UNKNOWN') {
                        l.status = ERechnungStatus.UNKNOWN;
                    } else if (('' + entity.status) === 'CREATING') {
                        l.status = ERechnungStatus.CREATING;
                    } else if (('' + entity.status) === 'BILLED') {
                        l.status = ERechnungStatus.BILLED;
                    } else if (('' + entity.status) === 'DUE') {
                        l.status = ERechnungStatus.DUE;
                    } else if (('' + entity.status) === 'OVERDUE') {
                        l.status = ERechnungStatus.OVERDUE;
                    } else if (('' + entity.status) === 'LONG_OVERDUE') {
                        l.status = ERechnungStatus.LONG_OVERDUE;
                    } else if (('' + entity.status) === 'PRECOLLECTIONLETTER') {
                        l.status = ERechnungStatus.PRECOLLECTIONLETTER;
                    } else if (('' + entity.status) === 'COLLECTION') {
                        l.status = ERechnungStatus.COLLECTION;
                    } else if (('' + entity.status) === 'DEFAULT_SUMMONS') {
                        l.status = ERechnungStatus.DEFAULT_SUMMONS;
                    } else if (('' + entity.status) === 'ACTION_IN_LAW') {
                        l.status = ERechnungStatus.ACTION_IN_LAW;
                    } else if (('' + entity.status) === 'ATTACHMENT_ORDER') {
                        l.status = ERechnungStatus.ATTACHMENT_ORDER;
                    } else if (('' + entity.status) === 'PAID') {
                        l.status = ERechnungStatus.PAID;
                    } else if (('' + entity.status) === 'STORNO') {
                        l.status = ERechnungStatus.STORNO;
                    } else if (('' + entity.status) === 'BEYOND_RECOVERY') {
                        l.status = ERechnungStatus.BEYOND_RECOVERY;
                    } else if (('' + entity.status) === 'TIME_BARRED') {
                        l.status = ERechnungStatus.TIME_BARRED;
                    } else if (('' + entity.status) === 'CREDIT_NOTE') {
                        l.status = ERechnungStatus.CREDIT_NOTE;
                    }
                }
            } else if (k === 'attachments') {
                l.attachments = [];
                if (Array.isArray(entity.attachments)) {
                    entity.attachments.forEach((a) => {
                        const at = Attachment.fromResult(a);
                        if (at !== null) {
                            l.attachments.push(at);
                        }
                    });
                }
            } else if (k === 'kunde') {
                l.kunde = Kunde.fromResult(entity.kunde);
            } else if (k === 'waehrung') {
                l.waehrung = Waehrung.fromResult(entity.waehrung);
            } else {
                l[k] = (entity[k] !== undefined) ? entity[k] : '';
            }
        }

        return l;
    }

    constructor() {
    }


}
