import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';


import * as moment from 'moment';
import {Kunde} from '../../../kunden/common/kunde';
import {KundenService} from '../../../kunden/common/kunden.service';
import {Kontingent} from '../../common/kontingent';
import {Ausweis} from '../../../ausweise/common/ausweis';
import {AusweisService} from '../../../ausweise/common/ausweis.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {SaldenResetConfirmDialogComponent} from './salden-reset-confirm-dialog/salden-reset-confirm-dialog.component';
import {LocalizedComponent} from '../../../../commons/components/localized-component';
import {IAusweis} from '../../../ausweise/common/iausweis';
import {LoginService} from '../../../../auth/services/login.service';

@Component({
               selector   : 'app-kontingent-editor',
               templateUrl: './kontingent-editor.component.html',
               styleUrls  : ['./kontingent-editor.component.css']
           })
export class KontingentEditorComponent extends LocalizedComponent implements OnInit {

    displayMode = 'ist';
    showDayly = true;
    showWeekly = true;
    showHalfMonthly = true;
    showMonthly = true;
    showTransaktionen = true;
    showMenge = true;
    showBetrag = true;

    vorgangUsesSystem = true;


    public _value: Kontingent = new Kontingent();

    @Input() kunde: Kunde = null;
    @Input() ausweis: Ausweis = null;

    @Input() refreshable = true;

    @Input() disabled = false;

    @Input()
    set value(v: Kontingent) {
        if (this._value !== v) {
            this._value = v;
            if (v !== null && v !== undefined) {
                this.showDayly = ((v.transaktionenTag > 0) || (v.mengeTag > 0) || (v.betragTag > 0));
                this.showWeekly = ((v.transaktionenWoche > 0) || (v.mengeWoche > 0) || (v.betragWoche > 0));
                this.showMonthly = ((v.transaktionenMonat > 0) || (v.mengeMonat > 0) || (v.betragMonat > 0));
                this.showHalfMonthly = ((v.transaktionenHalbMonat > 0) ||
                                        (v.mengeHalbMonat > 0) ||
                                        (v.betragHalbMonat > 0));
                this.showTransaktionen = ((v.transaktionenTag > 0) ||
                                          (v.transaktionenWoche > 0) ||
                                          (v.transaktionenHalbMonat > 0) ||
                                          (v.transaktionenMonat > 0) ||
                                          (v.transaktionenPrepaid > 0));
                this.showMenge = ((v.mengeTag > 0) ||
                                  (v.mengeWoche > 0) ||
                                  (v.mengeHalbMonat > 0) ||
                                  (v.mengeMonat > 0) ||
                                  (v.mengePrepaid > 0));
                this.showBetrag = ((v.betragTag > 0) ||
                                   (v.betragWoche > 0) ||
                                   (v.betragHalbMonat > 0) ||
                                   (v.betragMonat > 0) ||
                                   (v.betragPrepaid > 0));

                this.vorgangUsesSystem = !(v.maxVorautorisierungsbetrag > 0);
            }
            if (!this.disabled) {
                this.valueChange.emit(v);
            }
        }
        if (this.loginService.isCustomer()) {
            this._value.mengeTag = 0;
            this._value.mengeWoche = 0;
            this._value.mengeHalbMonat = 0;
            this._value.mengeMonat = 0;
            this._value.mengePrepaid = 0;
            this.showMenge = false;
        }
    }

    @Output()
    public valueChange: EventEmitter<Kontingent> = new EventEmitter<Kontingent>();


    state(soll, ist, isMoney = false, maxDays = 1) {
        if (soll <= 0) {
            return 'good';
        }

        if (this._value === null) {
            return 'nodata';
        }
        if (this._value.letzteSaldoAktualisierung === null) {
            return 'nodata';
        }

        if ((maxDays > 0) &&
            moment(this._value.letzteSaldoAktualisierung).isSameOrBefore(moment().subtract(maxDays, 'day'))) {
            return 'too-old';
        }

        if (ist < 0) {
            return 'nodata';
        }

        if (ist >= soll) {
            return 'bad';
        }

        if (isMoney && ((soll - ist) < 2)) {
            return 'bad';
        }

        if (ist === 0) {
            return 'good';
        }

        if ((ist / soll) >= 0.98) {
            return 'bad';
        }

        if ((ist / soll) >= 0.75) {
            return 'semi-bad';
        }


        if ((ist / soll) >= 0.50) {
            return 'warn';
        }
        return 'good';
    }

    isWhat(what: string): string {
        let s = 'tooOld';


        if (what === 'transaktionenTag') {
            s = this.state(this._value.transaktionenTag, this._value.saldoTransaktionenTag);
        }
        if (what === 'transaktionenWoche') {
            s = this.state(this._value.transaktionenWoche, this._value.saldoTransaktionenWoche, false, 7);
        }
        if (what === 'transaktionenMonat') {
            s = this.state(this._value.transaktionenMonat, this._value.saldoTransaktionenMonat, false, 31);
        }
        if (what === 'transaktionenHalbMonat') {
            s = this.state(this._value.transaktionenHalbMonat, this._value.saldoTransaktionenHalbMonat, false, 16);
        }
        if (what === 'transaktionenPrepaid') {
            s = this.state(this._value.transaktionenPrepaid, this._value.saldoTransaktionenPrepaid, false, 0);
        }

        if (what === 'mengeTag') {
            s = this.state(this._value.mengeTag, this._value.saldoMengeTag);
        }
        if (what === 'mengeWoche') {
            s = this.state(this._value.mengeWoche, this._value.saldoMengeWoche, false, 7);
        }
        if (what === 'mengeHalbMonat') {
            s = this.state(this._value.mengeHalbMonat, this._value.saldoMengeHalbMonat, false, 16);
        }
        if (what === 'mengeMonat') {
            s = this.state(this._value.mengeMonat, this._value.saldoMengeMonat, false, 31);
        }
        if (what === 'mengePrepaid') {
            s = this.state(this._value.mengePrepaid, this._value.saldoMengePrepaid, false, 0);
        }

        if (what === 'betragTag') {
            s = this.state(this._value.betragTag, this._value.saldoBetragTag, true);
        }
        if (what === 'betragWoche') {
            s = this.state(this._value.betragWoche, this._value.saldoBetragWoche, true, 7);
        }
        if (what === 'betragHalbMonat') {
            s = this.state(this._value.betragHalbMonat, this._value.saldoBetragHalbMonat, true, 16);
        }
        if (what === 'betragMonat') {
            s = this.state(this._value.betragMonat, this._value.saldoBetragMonat, true, 31);
        }
        if (what === 'betragPrepaid') {
            s = this.state(this._value.betragPrepaid, this._value.saldoBetragPrepaid, true, 0);
        }

        return s;
    }

    isGood(what: string): boolean {
        if (this.isWhat(what) === 'good') {
            return true;
        }
        return false;
    }

    isWarn(what: string): boolean {
        if (this.isWhat(what) === 'warn') {
            return true;
        }
        return false;
    }

    isBad(what: string): boolean {
        if (this.isWhat(what) === 'bad') {
            return true;
        }
        return false;
    }

    isSemiBad(what: string): boolean {
        if (this.isWhat(what) === 'semi-bad') {
            return true;
        }
        return false;
    }


    isTooOld(what: string): boolean {
        if (this.isWhat(what) === 'too-old') {
            return true;
        }
        if (this.isWhat(what) === 'nodata') {
            return true;
        }
        return false;
    }

    setValue(field: string, value: number) {
        if (value < 0) {
            if (field === 'mengePrepaid' || field === 'transaktionenPrepaid') {
                value = -1;
            } else {
                value = 0;
            }
        }
        if (this._value[field] !== value) {

            if (field.startsWith('transaktionen')) {
                value = parseInt('' + value, 10);
            } else {
                value = parseFloat('' + value);
            }

            this._value[field] = value;
            this.valueChange.emit(this._value);
        }
    }

    constructor(

                public kundenService: KundenService,
                public ausweisService: AusweisService,
                public modalService: NgbModal,
                public loginService: LoginService,
                public cd: ChangeDetectorRef
    ) {
        super( cd);
    }


    ngOnInit() {
    }

    refreshSaldo() {
        if (this.kunde !== null) {
            this.kundenService
                .requestLimits(this.kunde)
                .subscribe(
                    (result: Kunde) => {
                        this.kunde.kontingent.letzteSaldoAktualisierung = result.kontingent.letzteSaldoAktualisierung;

                        this.kunde.kontingent.saldoTransaktionenTag = result.kontingent.saldoTransaktionenTag;
                        this.kunde.kontingent.saldoTransaktionenWoche = result.kontingent.saldoTransaktionenWoche;
                        this.kunde.kontingent.saldoTransaktionenMonat = result.kontingent.saldoTransaktionenMonat;
                        this.kunde.kontingent.saldoTransaktionenHalbMonat
                            = result.kontingent.saldoTransaktionenHalbMonat;
                        this.kunde.kontingent.saldoTransaktionenPrepaid = result.kontingent.saldoTransaktionenPrepaid;

                        this.kunde.kontingent.saldoMengeTag = result.kontingent.saldoMengeTag;
                        this.kunde.kontingent.saldoMengeWoche = result.kontingent.saldoMengeWoche;
                        this.kunde.kontingent.saldoMengeHalbMonat = result.kontingent.saldoMengeHalbMonat;
                        this.kunde.kontingent.saldoMengeMonat = result.kontingent.saldoMengeMonat;
                        this.kunde.kontingent.saldoMengePrepaid = result.kontingent.saldoMengePrepaid;

                        this.kunde.kontingent.saldoBetragTag = result.kontingent.saldoBetragTag;
                        this.kunde.kontingent.saldoBetragWoche = result.kontingent.saldoBetragWoche;
                        this.kunde.kontingent.saldoBetragHalbMonat = result.kontingent.saldoBetragHalbMonat;
                        this.kunde.kontingent.saldoBetragMonat = result.kontingent.saldoBetragMonat;
                        this.kunde.kontingent.saldoBetragPrepaid = result.kontingent.saldoBetragPrepaid;

                    },
                    () => {

                    }
                );
        }
        if (this.ausweis !== null) {
            this.ausweisService
                .requestLimits(this.ausweis)
                .subscribe(
                    (res: IAusweis) => {
                        const result = Ausweis.fromResult(res);
                        this.ausweis.kontingent.letzteSaldoAktualisierung = result.kontingent.letzteSaldoAktualisierung;

                        this.ausweis.kontingent.saldoTransaktionenTag = result.kontingent.saldoTransaktionenTag;
                        this.ausweis.kontingent.saldoTransaktionenWoche = result.kontingent.saldoTransaktionenWoche;
                        this.ausweis.kontingent.saldoTransaktionenHalbMonat
                            = result.kontingent.saldoTransaktionenHalbMonat;
                        this.ausweis.kontingent.saldoTransaktionenMonat = result.kontingent.saldoTransaktionenMonat;
                        this.ausweis.kontingent.saldoTransaktionenPrepaid = result.kontingent.saldoTransaktionenPrepaid;

                        this.ausweis.kontingent.saldoMengeTag = result.kontingent.saldoMengeTag;
                        this.ausweis.kontingent.saldoMengeWoche = result.kontingent.saldoMengeWoche;
                        this.ausweis.kontingent.saldoMengeHalbMonat = result.kontingent.saldoMengeHalbMonat;
                        this.ausweis.kontingent.saldoMengeMonat = result.kontingent.saldoMengeMonat;
                        this.ausweis.kontingent.saldoMengePrepaid = result.kontingent.saldoMengePrepaid;

                        this.ausweis.kontingent.saldoBetragTag = result.kontingent.saldoBetragTag;
                        this.ausweis.kontingent.saldoBetragWoche = result.kontingent.saldoBetragWoche;
                        this.ausweis.kontingent.saldoBetragHalbMonat = result.kontingent.saldoBetragHalbMonat;
                        this.ausweis.kontingent.saldoBetragMonat = result.kontingent.saldoBetragMonat;
                        this.ausweis.kontingent.saldoBetragPrepaid = result.kontingent.saldoBetragPrepaid;

                    },
                    () => {

                    }
                );
        }

    }

    resetSaldo() {


        if (this.kunde !== null) {
            SaldenResetConfirmDialogComponent
                .open(this.modalService, 'kunde')
                .then(
                    (r: boolean) => {
                        this.kundenService
                            .resetLimits(this.kunde, r)
                            .subscribe(
                                (result: Kunde) => {
                                    this.kunde.kontingent.letzteSaldoAktualisierung
                                        = result.kontingent.letzteSaldoAktualisierung;

                                    this.kunde.kontingent.saldoTransaktionenTag
                                        = result.kontingent.saldoTransaktionenTag;
                                    this.kunde.kontingent.saldoTransaktionenWoche
                                        = result.kontingent.saldoTransaktionenWoche;
                                    this.kunde.kontingent.saldoTransaktionenMonat
                                        = result.kontingent.saldoTransaktionenMonat;
                                    this.kunde.kontingent.saldoTransaktionenHalbMonat
                                        = result.kontingent.saldoTransaktionenHalbMonat;
                                    this.kunde.kontingent.saldoTransaktionenPrepaid
                                        = result.kontingent.saldoTransaktionenPrepaid;

                                    this.kunde.kontingent.saldoMengeTag = result.kontingent.saldoMengeTag;
                                    this.kunde.kontingent.saldoMengeWoche = result.kontingent.saldoMengeWoche;
                                    this.kunde.kontingent.saldoMengeMonat = result.kontingent.saldoMengeMonat;
                                    this.kunde.kontingent.saldoMengeHalbMonat = result.kontingent.saldoMengeHalbMonat;
                                    this.kunde.kontingent.saldoMengePrepaid = result.kontingent.saldoMengePrepaid;

                                    this.kunde.kontingent.saldoBetragTag = result.kontingent.saldoBetragTag;
                                    this.kunde.kontingent.saldoBetragWoche = result.kontingent.saldoBetragWoche;
                                    this.kunde.kontingent.saldoBetragMonat = result.kontingent.saldoBetragMonat;
                                    this.kunde.kontingent.saldoBetragHalbMonat = result.kontingent.saldoBetragHalbMonat;
                                    this.kunde.kontingent.saldoBetragPrepaid = result.kontingent.saldoBetragPrepaid;

                                },
                                () => {

                                }
                            );
                    },
                    () => {

                    }
                );
        }
        if (this.ausweis !== null) {

            SaldenResetConfirmDialogComponent
                .open(this.modalService, 'ausweis')
                .then(
                    (r: boolean) => {

                        this.ausweisService
                            .resetLimits(this.ausweis, r)
                            .subscribe(
                                (res: IAusweis) => {
                                    const result = Ausweis.fromResult(res);

                                    this.ausweis.kontingent.letzteSaldoAktualisierung
                                        = result.kontingent.letzteSaldoAktualisierung;

                                    this.ausweis.kontingent.saldoTransaktionenTag
                                        = result.kontingent.saldoTransaktionenTag;
                                    this.ausweis.kontingent.saldoTransaktionenWoche
                                        = result.kontingent.saldoTransaktionenWoche;
                                    this.ausweis.kontingent.saldoTransaktionenMonat
                                        = result.kontingent.saldoTransaktionenMonat;
                                    this.ausweis.kontingent.saldoTransaktionenHalbMonat
                                        = result.kontingent.saldoTransaktionenHalbMonat;
                                    this.ausweis.kontingent.saldoTransaktionenPrepaid
                                        = result.kontingent.saldoTransaktionenPrepaid;

                                    this.ausweis.kontingent.saldoMengeTag = result.kontingent.saldoMengeTag;
                                    this.ausweis.kontingent.saldoMengeWoche = result.kontingent.saldoMengeWoche;
                                    this.ausweis.kontingent.saldoMengeMonat = result.kontingent.saldoMengeMonat;
                                    this.ausweis.kontingent.saldoMengeHalbMonat = result.kontingent.saldoMengeHalbMonat;
                                    this.ausweis.kontingent.saldoMengePrepaid = result.kontingent.saldoMengePrepaid;

                                    this.ausweis.kontingent.saldoBetragTag = result.kontingent.saldoBetragTag;
                                    this.ausweis.kontingent.saldoBetragWoche = result.kontingent.saldoBetragWoche;
                                    this.ausweis.kontingent.saldoBetragMonat = result.kontingent.saldoBetragMonat;
                                    this.ausweis.kontingent.saldoBetragHalbMonat
                                        = result.kontingent.saldoBetragHalbMonat;
                                    this.ausweis.kontingent.saldoBetragPrepaid = result.kontingent.saldoBetragPrepaid;

                                },
                                () => {

                                }
                            );
                    },
                    () => {

                    }
                );


        }


    }

    setDayly($event: boolean) {
        if (this.disabled) {
            return;
        }
        this.showDayly = $event;
        if (!this.showDayly) {
            this._value.transaktionenTag = 0;
            this._value.betragTag = 0;
            this._value.mengeTag = 0;
            this.valueChange.emit(this._value);
        } else {
            if (!this.showMenge && !this.showTransaktionen && !this.showBetrag) {
                this.setBetrag(true);
            }
        }
    }

    setWeekly($event: boolean) {
        if (this.disabled) {
            return;
        }
        this.showWeekly = $event;
        if (!this.showWeekly) {
            this._value.transaktionenWoche = 0;
            this._value.betragWoche = 0;
            this._value.mengeWoche = 0;
            this.valueChange.emit(this._value);
        } else {
            if (!this.showMenge && !this.showTransaktionen && !this.showBetrag) {
                this.setBetrag(true);
            }
        }
    }

    setHalfMonthly($event: boolean) {
        if (this.disabled) {
            return;
        }
        this.showHalfMonthly = $event;
        if (!this.showHalfMonthly) {
            this._value.transaktionenHalbMonat = 0;
            this._value.betragHalbMonat = 0;
            this._value.mengeHalbMonat = 0;
            this.valueChange.emit(this._value);
        } else {
            if (!this.showMenge && !this.showTransaktionen && !this.showBetrag) {
                this.setBetrag(true);
            }
        }
    }

    setMonthly($event: boolean) {
        if (this.disabled) {
            return;
        }
        this.showMonthly = $event;
        if (!this.showMonthly) {
            this._value.transaktionenMonat = 0;
            this._value.betragMonat = 0;
            this._value.mengeMonat = 0;
            this.valueChange.emit(this._value);
        } else {
            if (!this.showMenge && !this.showTransaktionen && !this.showBetrag) {
                this.setBetrag(true);
            }
        }
    }


    setTransaktionen($event: boolean) {
        if (this.disabled) {
            return;
        }
        this.showTransaktionen = $event;
        if (!this.showTransaktionen) {
            this._value.transaktionenTag = 0;
            this._value.transaktionenWoche = 0;
            this._value.transaktionenHalbMonat = 0;
            this._value.transaktionenMonat = 0;
            this._value.transaktionenPrepaid = 0;
            this.valueChange.emit(this._value);
        }
    }

    setBetrag($event: boolean) {
        if (this.disabled) {
            return;
        }
        this.showBetrag = $event;
        if (!this.showBetrag) {
            this._value.betragTag = 0;
            this._value.betragWoche = 0;
            this._value.betragHalbMonat = 0;
            this._value.betragMonat = 0;
            this._value.betragPrepaid = 0;
            this.valueChange.emit(this._value);
        }
    }

    setMenge($event: boolean) {
        if (this.disabled) {
            return;
        }
        this.showMenge = $event;
        if (!this.showMenge) {
            this._value.mengeTag = 0;
            this._value.mengeWoche = 0;
            this._value.mengeHalbMonat = 0;
            this._value.mengeMonat = 0;
            this._value.mengePrepaid = 0;
            this.valueChange.emit(this._value);
        }
    }


    setPrepaid($event: boolean) {
        if (this.disabled) {
            return;
        }
        this._value.isPrepaid = $event;
        if (!$event) {
            this._value.transaktionenMonat = 0;
            this._value.betragMonat = 0;
            this._value.mengeMonat = 0;
        } else {
            if (!this.showMenge && !this.showTransaktionen && !this.showBetrag) {
                this.setBetrag(true);
            }
        }
        this.valueChange.emit(this._value);
    }

    setVorgangUsesSystem($event: boolean) {
        if (!this.disabled) {
            return;
        }
        this.vorgangUsesSystem = $event;
        if (this.vorgangUsesSystem) {
            this._value.maxVorautorisierungsbetrag = 0;
        }
        this.valueChange.emit(this._value);
    }

    resetAvailable() {
        if (!this.refreshable) {
            return false;
        }
        if (this.kunde === null && this.ausweis === null) {
            return false;
        }
        if (this.kunde === undefined && this.ausweis === undefined) {
            return false;
        }
        if (
            (this.kunde !== null && this.kunde.id < 1)
        ) {
            return false;
        }
        if (
            (this.ausweis !== null && this.ausweis.id < 1)
        ) {
            return false;
        }
        if (this.kunde !== null) {
            return this.loginService.hasOneOfRoles(['ROLE_ADMIN', 'ROLE_PROJECT_ADMIN', 'ROLE_BILLING']);
        }
        return true;
    }
}
