import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {RechnungService} from '../rechnungen/common/rechnung.service';
import {Router} from '@angular/router';
import {TransaktionService} from '../transaktionen/common/transaktion.service';
import {IActionResponse} from '../../commons/action-response';

@Component({
  selector: 'app-dashboard-transactions-block',
  templateUrl: './dashboard-transactions-block.component.html',
  styleUrls: ['./dashboard-transactions-block.component.scss']
})
export class DashboardTransactionsBlockComponent implements OnInit {

    @Input() title = 'Transaktionen';
    @Input() destination = 'transaktionen';
    @Input() cls = 'yellow';
    @Input() showAlways = false;

    cnt = 0;

    constructor(
        public cd: ChangeDetectorRef,
        public transaktionenService: TransaktionService,
        public router: Router

    ) {
    }

    ngOnInit() {
        this.refresh();
    }

    refresh() {
        this.transaktionenService
            .countTransaktionen()
            .subscribe(
                (c: IActionResponse) => {
                    this.cnt = parseInt('' + c.params.count, 10);
                    this.cd.markForCheck();
                },
                () => {
                    this.cnt = 0;
                    this.cd.markForCheck();
                }
            );

    }


    gotoArea() {
        this.router.navigate([this.destination]);
    }

}
