import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {SysInfoService} from '../../../../commons/sysinfo/services/sys-info.service';

import {LocalizedComponent} from '../../../../commons/components/localized-component';

@Component({
    selector: 'app-tank-ueberwachung',
    templateUrl: './tank-ueberwachung.component.html',
    styleUrls: ['./tank-ueberwachung.component.scss']
})
export class TankUeberwachungComponent extends LocalizedComponent implements OnInit {

    constructor(
                
                public sysInfo: SysInfoService,
                public cd: ChangeDetectorRef
    ) {
        super( cd);
    }

    ngOnInit(): void {
        this.sysInfo.updateDocumentTitle(this._('Tanküberwachung'));
        this.cd.markForCheck();

    }
}
