<table class="table" *ngIf="device.isMEVideoControl()">
    <tr>
        <th class="w-30" scope="row">{{ _('IP Adresse') }}</th>
        <td>{{ device.settings.ip }}</td>
    </tr>
    <tr>
        <th class="w-30" scope="row">{{ _('Port') }}</th>
        <td>{{ device.settings.port }}</td>
    </tr>
    <tr>
        <th class="w-30" scope="row">{{ _('SSH-Port') }}</th>
        <td>{{ device.settings.ssh_port }}</td>
    </tr>
    <tr>
        <th class="w-30" scope="row">{{ _('Terminal') }}</th>
        <td>
            <app-terminal-quick-info
                    [terminal]="device.settings.terminal"></app-terminal-quick-info>
        </td>
    </tr>
    <tr>
        <th class="w-30" scope="row">{{ _('Text-Overlay') }}</th>
        <td>{{ device.settings.img_text_left }}</td>
    </tr>
    <tr>
        <th class="w-30" scope="row">{{ _('AP-SSID') }}</th>
        <td>{{ device.settings.wifi_ssid }}</td>
    </tr>
    <tr>
        <th class="w-30" scope="row">{{ _('AP zuletzt aktiviert') }}</th>
        <td><span
                *ngIf="device.settings.wifi_last_activated!==null && device.settings.wifi_last_activated!==undefined">
                    {{ fDate(device.settings.wifi_last_activated ,'medium') }}
                    </span>
        </td>
    </tr>
    <tr *hasRole="['ROLE_ADMIN','ROLE_PROJECT_ADMIN']">
        <td colspan="2" class="buttons">
            <button class="btn btn-secondary" (click)="sendConfig()">{{ _('Konfiguration senden') }}
            </button>
        </td>
    </tr>
</table>
