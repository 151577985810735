export interface IOption {
    value: any;
    label: string;
    icon: string;
}

export const NullOption: IOption = {
    value: null,
    label: '',
    icon: ''
};

export class Option {
    /**
     *
     * @param v - Value
     * @param l - Label
     * @param i - Icon
     */
    static option(v: any, l = '', i = ''): IOption {
        return {
            value: v,
            label: l,
            icon: i
        };
    }
}
