import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {Kunde} from '../common/kunde';
import {NgbActiveModal, NgbModal, NgbModalOptions, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';

import {KundenService} from '../common/kunden.service';

import {LocalizedComponent} from '../../../commons/components/localized-component';
import {AddOnClickEvent} from '../../../commons/dynamic-form/add-on-click-event';
import {ToastrService} from 'ngx-toastr';
import {LoginService} from '../../../auth/services/login.service';

@Component({
    selector: 'app-kunde-editor',
    templateUrl: './kunde-editor.component.html',
    styleUrls: ['./kunde-editor.component.css']
})
export class KundeEditorComponent extends LocalizedComponent implements OnInit {


    dlgTitle = 'Kunde {{ name }} bearbeiten';

    mode: any = 'new';
    currentKunde: Kunde = new Kunde();

    static open(modalService: NgbModal, kunde?: Kunde): Promise<any> {
        const o: NgbModalOptions = {
            size: 'lg',
            backdrop: 'static'
        };
        const r: NgbModalRef = modalService.open(KundeEditorComponent, o);
        (r.componentInstance as KundeEditorComponent).open(kunde);
        return r.result;
    }

    constructor(

                public activeModal: NgbActiveModal,
                public kundeService: KundenService,
                public toastr: ToastrService,
                public cd: ChangeDetectorRef,
                public loginService: LoginService
                ) {
        super( cd);
    }

    open(kunde?: Kunde) {
        if ((kunde === null) || (kunde === undefined)) {
            this.currentKunde = new Kunde();
            this.mode = 'new';
        } else {
            this.currentKunde = Kunde.fromResult(kunde);
            this.mode = 'edit';
        }
    }

    isNew(): boolean {
        return this.mode === 'new';
    }

    isEdit(): boolean {
        return this.mode === 'edit';
    }

    ngOnInit() {
    }

    cancel() {
        this.activeModal.close();
    }

    addOnClick(event: AddOnClickEvent) {
        if ('nummer-field' === event.component.id) {
            if (event.addon === 0) {
                this.kundeService
                    .findFreeNum()
                    .subscribe(
                        resp => {
                            if (resp.success) {
                                event.component.setValue(resp.params.nummer as number);
                            } else {
                                this.toastr.error(this.t('Es konnte keine freie Nummer ermittelt werden.'));
                            }
                        },
                        () => {
                            this.toastr.error(this.t('Es konnte keine freie Nummer ermittelt werden.'));
                        }
                    );
            }
        }
    }


    save() {
        if (this.canSave()) {
            this.kundeService
                .store(
                    this.currentKunde, this.isNew()
                )
                .subscribe(
                    resp => {
                        if (resp.success) {
                            this.activeModal.close();
                        } else {
                            this.toastr.error(this.t('Beim Speichern der Kundendaten ist ein Fehler aufgetreten.'));
                        }
                    },
                    () => {
                        this.toastr.error(this.t('Beim Speichern der Kundendaten ist ein Fehler aufgetreten.'));
                    }
                );
        }
    }

    realCanLogin(): boolean {
        if ((this.currentKunde === null) || (this.currentKunde === undefined)) {
            return false;
        }
        if ((this.currentKunde.kundennummer === null) ||
            (this.currentKunde.kundennummer === undefined) ||
            (('' + this.currentKunde.kundennummer).trim() === '')) {
            return false;
        }
        if ((this.currentKunde.canLogin === null) || (this.currentKunde.canLogin === undefined)) {
            return false;
        }
        return this.currentKunde.canLogin;
    }

    canSave(): boolean {
        if ((this.currentKunde.canLogin === null) || (this.currentKunde.canLogin === undefined)) {
            return false;
        }
        if (this.currentKunde.canLogin && !this.realCanLogin()) {
            return false;
        }
        if (
            (this.currentKunde.nummer === null) ||
            (this.currentKunde.nummer === undefined) ||
            isNaN(parseInt('' + this.currentKunde.nummer, 10)) ||
            (this.currentKunde.nummer < 1)
        ) {
            return false;
        }

        return true;
    }

    updateBegrenzungen(bg) {
        this.currentKunde.begrenzungen = bg;
    }

    getTitleOptions() {
        return {name: this.currentKunde.bezeichnung};
    }

    canChangeBetreuer() {
        return this.loginService.hasOneOfRoles(['ROLE_ADMIN', 'ROLE_PROJECT_ADMIN']);
    }
}
