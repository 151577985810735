<data-table [selectable]="false" [data]="issuers.asDataTableRows()">
    <column
            [field]="'nummer'"
            [label]="t('Nummer')"
            type="Integer"
    ></column>
    <column
            [field]="'bezeichnung'"
            [label]="t('Bezeichnung')"
    ></column>
    <column
            [field]="'pattern'"
            [label]="t('Erkennungsmuster')"
    ></column>
    <column
            [field]="'rank'"
            [label]="t('Rang')"
            type="Integer"
    ></column>
    <column
            [field]="'isProjektIssuer'"
            [label]="t('nur für Projekt')"
            type="boolean"
    ></column>
</data-table>
<div class="row">
    <div class="col-6"></div>
    <div class="col-6 text-right">
        <app-paginator
                [total]="issuers.total"
                [page]="issuers.page"
                [perpage]="issuers.size"
                (pageChange)="updateIssuers(true,$event)"
                (pageCountChange)="updateIssuersPageCount($event)"
        ></app-paginator>
    </div>
</div>
