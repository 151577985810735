import {ChangeDetectorRef, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {NgbActiveModal, NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';

import {Subscription} from 'rxjs';
import {LocalizedComponent} from '../components/localized-component';
import {PubSubService} from '../pub-sub.service';

@Component({
    selector: 'confirm-dialog',
    templateUrl: './confirm-dialog.component.html',
    styleUrls: ['./confirm-dialog.component.css']
})
export class ConfirmDialogComponent extends LocalizedComponent implements OnInit, OnDestroy {

    public sub: Subscription = null;

    @Input() dlgTitle = '';
    @Input() message = '';
    @Input() oklabel = '';
    @Input() cancellabel = '';
    @Input() okIcon = 'icofont icofont-check';
    @Input() params = {};

    /***
     * @param modalService
     * @param title
     * @param message
     * @param oklabel
     * @param okIcon
     * @param params
     * @returns {NgbModalRef}
     */
    static display(modalService: NgbModal,
                   title: string,
                   message: string,
                   oklabel = 'OK',
                   okIcon = 'icofont icofont-check',
                   params = {}): NgbModalRef {
        const r: NgbModalRef = modalService.open(ConfirmDialogComponent);
        (r.componentInstance as ConfirmDialogComponent).dlgTitle = title;
        (r.componentInstance as ConfirmDialogComponent).message = message;
        (r.componentInstance as ConfirmDialogComponent).oklabel = oklabel;
        (r.componentInstance as ConfirmDialogComponent).okIcon = okIcon;
        (r.componentInstance as ConfirmDialogComponent).params = params;
        return r;
    }


    constructor(
                
                public activeModal: NgbActiveModal,
                public cd: ChangeDetectorRef,
                public pubsub: PubSubService) {
        super( cd);
        this.oklabel = this._('OK');
        this.cancellabel = this._('Abbrechen');
    }

    ngOnInit() {
        this.sub = this.pubsub.loginError.subscribe(
            () => {
                this.cancel('A');
            }
        );
    }

    ngOnDestroy(): void {
        if (this.sub != null) {
            this.sub.unsubscribe();
            this.sub = null;
        }
        super.ngOnDestroy();
    }

    cancel(reason = 'cancel') {
        this.activeModal.dismiss(reason);
    }

    ok() {
        this.activeModal.close(this.params);
    }


}
