<i class="{{ icon }}" (click)="showPopover($event)"></i>
<div #popover class="po" [class.hide]="!popoverVisible">
    <div class="arrow"></div>
    <h3 class="title">
        <i class="{{ title_icon }}" *ngIf="title_icon!='' && title_icon!==undefined && title_icon!==null"></i>
        <strong>{{ title }}</strong>
        <button type="button" class="close" aria-label="Close" (click)="hidePopover()">
            <span aria-hidden="true">&times;</span>
        </button>
    </h3>
    <div class="content">
        <ng-content></ng-content>
    </div>
</div>