<div class="modal-header">
    <h5 class="modal-title">{{dlgTitle}}</h5>

    <button type="button" class="close" aria-label="Close" (click)="cancel()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">

    <app-tabset>
        <app-pane [title]="_('Identifikation')"
                  *hasRole="['ROLE_ADMIN','ROLE_PROJECT_ADMIN','ROLE_BILLING']"
        >
            <text-field [label]="_('Bezeichnung')"
                        [(value)]="entity.bezeichnung"
            ></text-field>
            <ausweis-art-field [label]="_('Ausweis-Art')"
                               [(value)]="entity.ausweisArt"
            ></ausweis-art-field>
            <text-field
                    [(value)]="entity.pan"
                    [label]="_('Ausweiskennung/Pan')"
                    id="pan-field"
                    [addonicon]="['far fa-cogs']"
                    [addonlabel]="['Herausgeber und Kartennummer ermitteln']"
                    (addonClick)="addOnClick($event)"
                    [enableaddon]="true"
            ></text-field>
            <issuer-field
                    [(value)]="entity.issuer"
                    [label]="_('Herausgeber')"
            ></issuer-field>
            <num-field
                    [(value)]="entity.nummer"
                    [label]="_('Ausweisnummer')"
            ></num-field>
            <boolean-field
                    [(value)]="entity.fremdausweis"
                    [label]="_('Fremdausweis')"
            ></boolean-field>

        </app-pane>
        <app-pane [title]="_('Gültigkeit')" *ngIf="!entity.fremdausweis">

            <date-time-field
                    *hasRole="['ROLE_ADMIN','ROLE_PROJECT_ADMIN','ROLE_BILLING']"

                    [label]="_('gültig ab')"
                    [(value)]="entity.gueltigAb"
            ></date-time-field>
            <date-time-field
                    *hasRole="['ROLE_ADMIN','ROLE_PROJECT_ADMIN','ROLE_BILLING']"

                    [label]="_('gültig bis')"
                    [(value)]="entity.gueltigBis"
            ></date-time-field>
            <boolean-field
                    *hasRole="['ROLE_ADMIN','ROLE_PROJECT_ADMIN','ROLE_BILLING']"
                    [(value)]="entity.adminSperre"
                    [label]="_('administrativ gesperrt')"
            ></boolean-field>
            <boolean-field
                    [(value)]="entity.kundenSperre"
                    [label]="_('kundenseitig gesperrt')"
            ></boolean-field>

            <div class="card" *ngIf="isEdit()">
                <div class="card-body">
                    <h4 class="card-title">{{_('PIN-Änderung')}}</h4>
                    <div class="card-text">
                        <p>
                            {{ _('Wenn Sie die PIN dieses Ausweises ändern wollen, geben Sie diese bitte hier ein '+
                            'und klicken anschließend auf den Button "PIN festlegen"')}}
                        </p>

                        <text-field *ngIf="!isWifiCard()" [label]="_('PIN')" [value]="pin1" (valueChange)="setPin(1,$event)"></text-field>
                        <password-field *ngIf="isWifiCard()" [label]="_('PIN')" [value]="pin1" (valueChange)="setPin(1,$event)"></password-field>


                        <div class="alert alert-danger" *ngIf="message!==''">
                            {{ message }}
                        </div>
                        <button type="button"
                                class="btn btn-primary"
                                (click)="savePin()"
                                [class.disabled]="!canSavePin"
                                [disabled]="!canSavePin"
                        >
                            <i class="icofont icofont-key"></i>
                            {{ _('PIN festlegen')}}
                        </button>

                    </div>
                </div>
            </div>


        </app-pane>
        <app-pane [title]="_('Optionen')" *ngIf="!entity.fremdausweis">

            <boolean-field
                    [label]="_('km-Stand abfragen')"
                    [(value)]="entity.kmEingabe"
            ></boolean-field>
            <boolean-field
                    [label]="_('km-Stand überwachen')"
                    [(value)]="entity.kmUeberwachung"
            ></boolean-field>
            <num-field
                    [label]="_('max. km Differenz')"
                    [(value)]="entity.maxKMDifferenz"
            >
            </num-field>

            <num-field *ngIf="false"
                       [label]="_('max. Volumen pro Vorgang')"
                       [(value)]="entity.maxVolumen"
            >
            </num-field>

            <num-field
                    [label]="_('max. Betrag pro Vorgang in EUR')"
                    [(value)]="entity.kontingent.maxVorautorisierungsbetrag"
            ></num-field>

            <boolean-field
                    [label]="_('weiterer Ausweis notwendig')"
                    [(value)]="entity.zusatzAusweisNotwendig"
            ></boolean-field>

            <boolean-field
                    [label]="_('weitere Information abfragen')"
                    [(value)]="entity.zusatzEingabe"
            ></boolean-field>


        </app-pane>
        <app-pane [title]="_('Produktfreigaben')" *ngIf="false && !entity.fremdausweis">

            <produkt-gruppe-selector
                    [value]="entity.produktFreigaben"
                    (valueChanged)="entity.produktFreigaben=$event"
            >

            </produkt-gruppe-selector>

        </app-pane>
        <app-pane [title]="_('Artikelfreigaben')" *ngIf="false && !entity.fremdausweis">
            <artikel-gruppe-field
                    [(value)]="entity.artikelGruppen[0]"
                    [label]="_('Artikelgruppe 1')"
                    [minor]="true"
                    [major]="false"
            ></artikel-gruppe-field>
            <artikel-gruppe-field
                    [(value)]="entity.artikelGruppen[1]"
                    [label]="_('Artikelgruppe 2')"
                    [minor]="false"
                    [major]="true"
            ></artikel-gruppe-field>
        </app-pane>
        <app-pane [title]="_('Zuordnungen')">
            <kunde-field
                    [(value)]="entity.kunde"
                    [label]="_('Kunde')"
            ></kunde-field>
            <person-field
                    *ngIf="false"
                    [(value)]="entity.person"
                    [label]="_('Fahrer/Person')"
            ></person-field>
            <fahrzeug-field
                    [value]="entity.fahrzeug"
                    (valueChange)="setFahrzeug($event)"
                    [label]="_('Fahrzeug')"
            ></fahrzeug-field>
            <kostenstelle-field [label]="_('Kostenstelle')"
                                [(value)]="entity.kostenstelle"
            ></kostenstelle-field>
            <station-field [label]="_('Standort')" [(value)]="entity.standort" ></station-field>
            <div *hasRole="['ROLE_ADMIN']">
                <num-field [label]="_('Tankserver-ID')"
                           [(value)]="entity.tankserverID"
                ></num-field>

                <num-field [label]="_('Ratio-ID')"
                           [(value)]="entity.ratioID"
                           *ngIf="appConfig.hasRatio"
                ></num-field>
            </div>
        </app-pane>
        <app-pane [title]="_('Kontingent')" *ngIf="false && !entity.fremdausweis">
            <app-kontingent-editor
                    [(value)]="entity.kontingent"
                    [ausweis]="entity"
            ></app-kontingent-editor>
        </app-pane>
        <app-pane [title]="_('Freigaben')" *ngIf="!entity.fremdausweis && false">
            <app-freigabe-editor
                    [mode]="'card'"
                    [value]="entity.begrenzungen"
                    (valueChange)="updateBegrenzungen($event)"
            ></app-freigabe-editor>
        </app-pane>
        <app-pane [title]="_('Sonstiges')">
            <multi-line-field
                    [label]="_('Infos')"
                    [(value)]="entity.info"
            ></multi-line-field>
            <date-time-field
                    [label]="_('im System angelegt')"
                    [value]="entity.dateCreated"
                    [disabled]="true"
            ></date-time-field>
            <date-time-field
                    [label]="_('zuletzt bearbeitet')"
                    [value]="entity.lastEdited"
                    [disabled]="true"
            ></date-time-field>
            <date-time-field
                    [label]="_('zuletzt geändert')"
                    [value]="entity.lastUpdated"
                    [disabled]="true"
            ></date-time-field>
        </app-pane>
        <app-pane *ngIf="showNachverfolgung()" [title]="_('Nachverfolgung')">
            <app-tab-ausweis-audit
                    [ausweis]="entity"
            ></app-tab-ausweis-audit>
        </app-pane>
    </app-tabset>

</div>
<div class="modal-footer d-flex justify-content-between">
<div>
    <button type="button" class="btn btn-danger" (click)="remove()" *ngIf="canDelete()">
        <i class="icofont icofont-trash"></i> {{ _('Löschen') }}
    </button>

</div>
<div>
    <button type="button" class="btn btn-secondary" (click)="cancel()">
        <i class="icofont icofont-close"></i> {{ _('Schließen') }}
    </button>
    <button type="button"
            class="btn btn-primary"
            (click)="save()"
            [class.disabled]="!canSave()"
            [disabled]="!canSave()"
    >
        <i class="icofont icofont-save"></i>
        {{ _('Änderungen speichern')}}
    </button>
</div>
</div>

