<div class="modal-header">
    <h5 class="modal-title">{{ _('Transaktion bearbeiten') }}</h5>
    <button type="button" class="close" aria-label="Close" (click)="cancel()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">

    <app-tabset
            #tabset1
    >
        <app-pane [title]="_('Identifikation')">
            <table class="table">
                <tr>
                    <th scope="row" class="w-20">{{ _('Zeitpunkt')}}</th>
                    <td>{{ fDate(entity.zeitpunkt,'medium') }}</td>
                </tr>
                <tr>
                    <th scope="row" class="w-20">{{ _('Belegnummer') }}</th>
                    <td>
                        <text-field [label]="''" [labelwidth]="0"
                                    [(value)]="entity.belegnummer"></text-field>
                    </td>
                </tr>
                <tr>
                    <th scope="row" class="w-20">{{ _('ISO-Belegnummer')}}</th>
                    <td>
                        <text-field [label]="''" [labelwidth]="0"
                                    [(value)]="entity.ISOBelegnummer"></text-field>

                    </td>
                </tr>
                <tr>
                    <th scope="row" class="w-20">{{ _('Referenz-ID')}}</th>
                    <td>
                        <text-field [label]="''" [labelwidth]="0"
                                    [(value)]="entity.referenzID"></text-field>
                    </td>
                </tr>
                <tr>
                    <th scope="row" class="w-20">{{ _('UUID')}}</th>
                    <td>{{ entity.guid }}</td>
                </tr>
            </table>
        </app-pane>
        <app-pane [title]="_('Positionen')">
            <data-table
                    [selectable]="false"
                    [data]="entity.detailRows()"
            >
                <column
                        [field]="'pos'"
                        [label]="_('Position')"
                        type="Integer"
                ></column>
                <column
                        [field]="'produkt'"
                        [label]="_('Produkt')"
                        [renderer]="produktR"
                ></column>
                <column
                        *ngIf="false"
                        [field]="'tank'"
                        [label]="_('Tank')"
                        [renderer]="tankR"
                ></column>
                <column
                        *ngIf="false"
                        [field]="'zapfpunkt'"
                        [label]="_('Zapfpunkt')"
                        [renderer]="zapfpunktR"
                ></column>
                <column
                        [field]="'menge'"
                        [label]="_('Menge')"
                        [renderer]="mengeR"
                        [cfg]="{ produktField:'produkt' }"
                ></column>
                <column
                        [field]="'epreis'"
                        [label]="_('Einzelpreis')"
                        [renderer]="preiswert"
                        [cfg]="{ produktField:'produkt' ,currency:'EUR' }"
                ></column>
                <column
                        [field]="'preis'"
                        [label]="_('Preis')"
                        [renderer]="preiswert"
                        [cfg]="{ currency:'EUR',fdigits:2 }"
                ></column>
                <column
                        [field]="'b_epreis'"
                        [label]="_('Einzelpreis (brutto)')"
                        [renderer]="preiswert"
                        [cfg]="{ produktField:'produkt',currency:'EUR' }"
                ></column>
                <column
                        [field]="'b_preis'"
                        [label]="_('Preis (brutto)')"
                        [renderer]="preiswert"
                        [cfg]="{ currency:'EUR',fdigits:2 }"
                ></column>

            </data-table>
            <div class="row">
                <div class="col-6">
                </div>
                <div class="col-6">
                </div>
            </div>
        </app-pane>
        <app-pane
                [title]="_('Zuordnungen')"
                id="tab_transaktion_zuordnungen"
        >
            <table class="table">
                <tr>
                    <th scope="row">{{ _('gesendete Ausweiskennung') }}</th>
                    <td>
                        <text-field [label]="''" [labelwidth]="0"
                                    [(value)]="entity.ausweiskennung1"></text-field>
                    </td>
                </tr>
                <tr>
                    <th scope="row">{{ _('Ausweis') }}</th>
                    <td>
                        <ausweis-inline-field
                                [label]="''"
                                [value]="entity.ausweis1"
                                (valueChange)="setAusweis1($event)"
                        ></ausweis-inline-field>
                    </td>
                </tr>
                <tr>
                    <th scope="row">{{_('Innenauftrag')}}</th>
                    <td>
                        <fahrzeug-inline-field
                                [label]="''"
                                [value]="entity.fahrzeug"
                                (valueChange)="setFahrzeug($event)"
                        ></fahrzeug-inline-field>
                    </td>
                </tr>
                <tr>
                    <th scope="row">{{_('km/h-Stand')}}</th>
                    <td>
                        <num-field
                                [label]="''"
                                [labelwidth]="0"
                                [(value)]="entity.kmstand"
                        ></num-field>
                    </td>
                </tr>
                <tr>
                    <th scope="row">{{_('Kunde')}}</th>
                    <td>
                        <kunde-inline-field
                                [label]="''"
                                [value]="entity.kunde"
                                (valueChange)="setKunde($event)"
                        ></kunde-inline-field>
                    </td>
                </tr>
                <tr>
                    <th scope="row">{{_('Kostenstelle')}}</th>
                    <th>
                        <kostenstelle-inline-field
                                [label]="''"
                                [value]="entity.kostenstelle"
                                (valueChange)="setKostenstelle($event)"
                        ></kostenstelle-inline-field>
                    </th>
                </tr>
            </table>
        </app-pane>
        <app-pane
                [title]="_('Station')"
                *ngIf="entity.station!==undefined && entity.station!==null"
        >
            <app-station-display
                    [station]="entity.station"
            ></app-station-display>
        </app-pane>
        <app-pane [title]="_('Terminal')" *ngIf="false && entity.terminal!==undefined && entity.terminal!==null">
            <app-terminal-display [terminal]="entity.terminal"></app-terminal-display>
        </app-pane>
    </app-tabset>
</div>
<div class="modal-footer d-flex justify-content-between">
    <div>
        <button type="button" class="btn btn-danger" (click)="storno()">
            <i class="icofont icofont-delete"></i> {{ _('Transaktion stornieren') }}
        </button>

    </div>
    <div>
        <button type="button" class="btn btn-secondary" (click)="cancel()">
            <i class="icofont icofont-close"></i> {{ _('Abbrechen und Schließen') }}
        </button>
        &nbsp;
        <button type="button" class="btn btn-primary" (click)="save(true)"
                [title]="_('die bestehende Transaktion wird storniert und eine Kopie mit den geänderten Daten angelegt')"
        >
            <i class="icofont icofont-save"></i> {{ _('mit Storno umbuchen') }}
        </button> &nbsp;
        <button type="button" class="btn btn-primary" (click)="save(false)"
                [title]="_('die bestehende Transaktion wird direkt geändert')"
        >
            <i class="icofont icofont-save"></i> {{ _('Änderungen speichern') }}
        </button>
    </div>
</div>
