import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input} from '@angular/core';
import {LocalizedComponent} from './localized-component';

@Component({
    selector: 'volumen-display',
    template: `<span *ngIf="isValid()" class="text-nowrap">{{ val }} {{ _unit}}</span>`,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class VolumenDisplayComponent extends LocalizedComponent {


    digitInfo = '1.3-3';

    @Input()
    set scale(value: number) {
        const fdigits: number = parseInt('' + value, 10);
        if (!isNaN(fdigits)) {
            this.digitInfo = '1.' + fdigits + '-' + fdigits;
        }
    }

    _unit = 'l';
    _value = 0;

    @Input()
    set value(value: number) {
        this._value = value;
    }


    @Input()
    set unit(value: string) {
        this._unit = value;
        this.cd.markForCheck();
    }

    get val() {
        if (this._value == null) {
            return '';
        }
        return this.fNum(this._value,this.digitInfo);
    }

    constructor(

                public cd: ChangeDetectorRef) {
        super( cd);
    }

    isValid(): boolean {
        return (this._value !== undefined) &&
            (this._value !== null) &&
            (('' + this._value).trim() !== '') &&
            (!isNaN(this._value));
    }

}
