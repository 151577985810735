import {AfterViewInit, Component, EventEmitter, Input, NgZone, OnInit, Output, ViewChild} from '@angular/core';
import {
    MEHighlitePipe,
    MESelectComponent,
    MESelectItemMatcherCallback,
    MESelectItemTextCallback
} from './me-select/meselect.component';
import {AnredeList} from '../anreden/anrede-list';
import {Anrede} from '../anreden/anrede';

import {SystemSettingsService} from '../system-settings.service';
import {SimpleAnredeFilter} from '../anreden/simple-anrede-filter';
import {MESelectItemFormatterCallback} from './me-select/meselect-item-formatter-callback';
import {IListResult} from '../list-result';
import {IAnrede} from '../anreden/ianrede';
import CryptoES from "crypto-es";

@Component({
    selector: 'anrede-field',
    template: `
        <div class="form-group row">
            <label for="{{ id }}" class="col-{{ labelwidth }} col-form-label">
                {{ label }}
            </label>
            <div class="col-{{ 12-labelwidth }}">

                <meselect
                        id="{{ id }}"
                        [idField]="'id'"
                        [items]="anreden.data"
                        [placeholder]="label"
                        (itemSelected)="doSelect($event)"
                        [nullable]="nullable"
                >

                </meselect>
            </div>
        </div>

    `,
    styles: [`
        :host .addon button:first-child {
            border-left: none;
            padding-left: 5px;
            padding-right: 5px;
        }

        :host ::ng-deep meselect .display-box {
            min-height: 38px;
        }

        :host ::ng-deep meselect ul.items > li {
            min-height: 32px;
        }
    `]
})
export class AnredeFieldComponent implements OnInit, AfterViewInit {
    @Input() nullable = true;
    @ViewChild(MESelectComponent, {static: true}) anredeMESelect: MESelectComponent;
    anreden: AnredeList = new AnredeList();
    @Input() label = 'Textfeld';
    @Input() id = '';
    @Input() labelwidth = 3;
    @Input() maxDisplayAnreden = 400;
    @Output() valueChange = new EventEmitter<Anrede>();

    constructor(public systemsettingsService: SystemSettingsService,
                public _ngZone: NgZone) {
        this.doSearch('');
    }

    _value: Anrede = null;

    get value(): Anrede {
        return this._value;
    }

    @Input()
    set value(v: Anrede) {

        if (this._value !== v) {
            this._value = v;
            this.valueChange.emit(v);
        }
    }

    ngOnInit() {
        if (this.id === '') {
            this.id = 'tf' + CryptoES.MD5('textfield-' + this.label + Math.random() + '-' + Math.random()).toString();
        }
    }

    ngAfterViewInit(): void {
        this.anredeMESelect.onFormat = this.itemFormat;
        this.anredeMESelect.onGetText = this.itemText;
        this.anredeMESelect.onMatch = this.itemMatcher;
        this.anredeMESelect.value = this._value;
    }

    doSearch(value) {
        const lf: SimpleAnredeFilter = new SimpleAnredeFilter();

        if (value.trim() !== '') {
            lf.anrede = value.trim();
            lf._operations['anrede'] = 'OR';
        }

        this.systemsettingsService
            .listAnreden(this.maxDisplayAnreden, 0, [], lf)
            .subscribe(
                (c: IListResult<IAnrede>) => {
                    this.anreden.populateFromListResult(c);
                }
            );

    }

    doSelect(anrede: Anrede) {
        this._value = anrede;
        this.valueChange.emit(anrede);
    }

    public itemFormat: MESelectItemFormatterCallback<Anrede> = (item: Anrede, search) => {
        if (item === null) {
            return '';
        }
        return MEHighlitePipe.transformString(item.anrede, search);
    }

    public itemText: MESelectItemTextCallback<Anrede> = (item: Anrede) => {
        if (item === null) {
            return '';
        }
        return item.anrede;
    }

    public itemMatcher: MESelectItemMatcherCallback<Anrede> = (item: Anrede, search) => {
        if (!search) {
            return true;
        }
        if (search.trim() === '') {
            return true;
        }
        if (!item) {
            return false;
        }
        const x = '' + item.anrede;

        return (x.toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) >= 0);
    }


}
