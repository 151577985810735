<me-info-tool
        [title]="getTitle()"
        title-icon="me-icon me-icon-terminal"
        [position]="info_position"
        *ngIf="_terminal!==null && _terminal!==undefined">
    <app-terminal-info [terminal]="_terminal"></app-terminal-info>
</me-info-tool>
<div *ngIf="_terminal!==undefined && _terminal!==null && showLabel" style="display: inline-block;">
    <i class="me-icon me-icon-terminal"></i>
    {{ _terminal.bezeichnung }}
    <span class="badge badge-default">{{ _terminal.nummer }}</span>
</div>