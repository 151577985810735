import {Lieferschein} from './lieferschein';
import {ILieferschein} from './ilieferschein';
import {GenericFilter} from '../../../commons/generic-filter';
import {IBenutzer} from '../../../auth/common/ibenutzer';
import {IStation} from '../../../stationen/common/istation';
import {ITank} from '../../../stationen/tanks/common/itank';
import {IProdukt} from '../../../products/produkte/common/iprodukt';
import {Attachment} from '../../attachments/common/attachment';

export class SimpleLieferscheinFilter extends GenericFilter<Lieferschein> implements ILieferschein {
    attachments: Attachment[] = null;
    dateCreated: any = null;
    datum: any = null;
    lieferDatum: any = null;
    erfasser: IBenutzer = null;
    id: number = null;
    info: string = null;
    lastEditor: IBenutzer = null;
    lastUpdated: any = null;
    nummer: string = null;
    station: IStation = null;
    tank: ITank = null;
    zugang: number = null;
    produkt: IProdukt = null;
    wert: number = null;
    isNetto: boolean = null;


    public encodeField(fieldname: string): string {
        if (fieldname === 'tank') {
            if (this.tank !== null) {
                if (this.tank.id !== null && this.tank.id !== undefined && this.tank.id > 0) {
                    return 'filter.tank=' + encodeURIComponent('' + this.tank.id);
                }
            }
        }
        return super.encodeField(fieldname);
    }

}
