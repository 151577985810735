<data-table
        [selectable]="false"
        [data]="produkte.asDataTableRows()"
        [orders]="produkte.order"
        [filter]="produkte.simpleFilter"
        (sortChanged)="sortChanged($event)"
        (filterChanged)="filterChanged($event)"
        [rowActionsAsButtons]="true"

>
    <column [field]="'nummer'" [label]="t('Nummer')" [type]="'integer'" [filterable]="false"></column>
    <column [field]="'bezeichnung'" [label]="t('Bezeichnung')" [sortable]="true" [filterable]="false"></column>
    <column [field]="'einheit'" [label]="t('Einheit')" [renderer]="einheitR" [filterable]="false"></column>
    <column [field]="'art'" [label]="t('ArtikelArt')" [renderer]="artikelArtR" [filterable]="false"></column>
    <column [field]="'artikel'" [label]="t('Referenz-Artikel')"
            [renderer]="artikelR"
            [filterable]="false"></column>
    <tableaction
            [label]="t('Produkt anlegen')"
            [icon]="'icofont icofont-ui-add'"
            (perform)="createProdukt()"
    ></tableaction>
    <rowaction
            [label]="_('Produkt bearbeiten')"
            [icon]="'icofont icofont-ui-edit'"
            (perform)="editProdukt($event)"
            (checkEnabled)="checkEnabled('artikel',$event)"
    ></rowaction>
    <data-table-table-extra-header>
        <app-paginator
                [total]="produkte.total"
                [page]="produkte.page"
                [perpage]="produkte.size"
                (pageChange)="updateProdukte(true,$event)"
                (pageCountChange)="updateProduktePageCount($event)"
        ></app-paginator>
    </data-table-table-extra-header>

</data-table>
