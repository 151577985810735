import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {LocalizedComponent} from '../commons/components/localized-component';


import {IOption, NullOption} from '../commons/dynamic-form/select-field/ioption';
import {ArtikelArt} from './artikel/artikel-art';
import {EArtikelArt} from './artikel/eartikel-art';
import CryptoES from "crypto-es";


@Component({
    selector: 'artikel-art-field',
    template: `
        <div class="form-group row">
            <label for="{{ id }}" class="col-{{ labelwidth }} col-form-label">
                {{ label }}
            </label>
            <div class="col-{{ 12-labelwidth }}">

                <select
                        id="{{ id }}"
                        class="form-control"
                        [value]="value"
                        (input)="value=$event.target.value"
                        title="{{ label }}"
                >
                    <option
                            *ngFor="let o of options"
                            value="{{ o.value }}"
                    >
                        <i *ngIf="o.icon!==null && o.icon!==undefined" class="{{o.icon}}"></i>
                        {{ o.label }}
                    </option>
                </select>
            </div>
        </div>
    `
})
export class ArtikelArtFieldComponent extends LocalizedComponent implements OnInit {

    @Input() label = 'Textfeld';
    @Input() id = '';
    @Input() labelwidth = 3;
    @Input() nullable = false;
    options: IOption[] = ArtikelArt.option((key) => {
        return this.t(key);
    });
    @Output() valueChange = new EventEmitter<EArtikelArt>();

    constructor(
        public cd: ChangeDetectorRef) {
        super(cd);
    }

    public _value: EArtikelArt = EArtikelArt.ANY;

    get value(): EArtikelArt {
        return this._value;
    }

    @Input('value')
    set value(v: EArtikelArt) {
        if (this._value !== v) {
            this._value = v;
            this.valueChange.emit(v);
        }
    }

    ngOnInit() {
        if (this.nullable) {
            this.options.unshift(NullOption);
        }
        if (this.id === '') {
            this.id = 'tf' + CryptoES.MD5('textfield-' + this.label + Math.random() + '-' + Math.random()).toString();
        }
    }


}
