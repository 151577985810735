export interface IResultMetaSort {
    field: string;
    direction: string;
}

export class ResultMetaSortImpl implements IResultMetaSort {
    field     = '';
    direction = '';

    constructor(field: string, direction: string) {
        this.field     = field;
        this.direction = direction;
    }
}


export interface IResultMeta {
    offset: number ;
    total: number ;
    pages: number;
    page: number ;
    size: number ;
    count: number;
    order: IResultMetaSort[];
}

export class ResultMetaImpl implements IResultMeta {
    offset: number;
    total: number;
    pages: number;
    page: number;
    size: number;
    count: number;
    order: IResultMetaSort[];

    constructor(offset                   = 0,
                total                    = 0,
                pages                    = 0,
                page                     = 0,
                size                     = 0,
                count                    = 0,
                order: IResultMetaSort[] = []) {
        this.clear();
        this.offset = offset;
        this.total  = total;
        this.pages  = pages;
        this.page   = page;
        this.size   = size;
        this.count  = count;
        this.order  = order;
    }

    clear(): ResultMetaImpl {
        this.offset = 0;
        this.total  = 0;
        this.page   = 0;
        this.pages  = 0;
        this.size   = 0;
        this.count  = 0;
        this.order  = [];
        return this;
    }
}
