import {Component, EventEmitter, Input, NgZone, OnInit, Output} from '@angular/core';
import {Kostenstelle} from '../common/kostenstelle';

@Component({
    selector: 'kostenstelle-field',
    templateUrl: './kostenstelle-field.component.html',
    styleUrls: ['./kostenstelle-field.component.scss']
})
export class KostenstelleFieldComponent implements OnInit {

    @Input() nullable = true;

    @Input() small = false;
    public _value: Kostenstelle = null;
    @Input() label = 'Kostenstellefeld';
    @Input() id = '';
    @Input() labelwidth = 3;
    @Input() disabled = false;

    @Input()
    set value(v: Kostenstelle) {

        if (this._value !== v) {
            this._value = v;
            if (!this.disabled) {
                this.valueChange.emit(v);
            }
        }
    }

    @Output() valueChange = new EventEmitter<Kostenstelle>();


    get value(): Kostenstelle {
        return this._value;
    }

    constructor(public _ngZone: NgZone) {
    }

    ngOnInit() {
    }

    doSelect(kostenstelle: Kostenstelle) {
        this._value = kostenstelle;
        if (!this.disabled) {
            this.valueChange.emit(kostenstelle);
        }
    }

}
