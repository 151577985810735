<div class="form-group row">
  <label for="{{ id }}" class="col-{{ labelwidth }} col-form-label" *ngIf="labelwidth>0">
    {{ label }}
  </label>
  <div class="col-{{ 12-labelwidth }}">
    <kostenstelle-inline-field
            id="{{ id }}"
            [label]="label"
            [value]="_value"
            (valueChange)="doSelect($event)"
            [small]="small"
            [nullable]="nullable"
            [disabled]="disabled"
    ></kostenstelle-inline-field>
  </div>
</div>

