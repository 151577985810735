export enum ERechnungStatus {
    UNKNOWN             = 0,
    CREATING            = 1, // Erstellungsprozess
    BILLED              = 2, // erstellt
    DUE                 = 3, // fällig
    OVERDUE             = 4, // Zahlungserinnerung
    LONG_OVERDUE        = 5, // Mahnung
    PRECOLLECTIONLETTER = 6, // letzte mahnung
    COLLECTION          = 7, // externes Inkasso
    DEFAULT_SUMMONS     = 8, // Mahnbescheid
    ACTION_IN_LAW       = 9, // Klageweg
    ATTACHMENT_ORDER    = 10, // Titel
    PAID                = 11, // bezahlt
    STORNO              = 12, // Storniert
    BEYOND_RECOVERY     = 13, // uneinbringlich
    TIME_BARRED         = 14, // verjährt
    CREDIT_NOTE         = 15 // Gutschrift
}
