import {EArtikelArt} from './eartikel-art';
import {IOption, Option} from '../../commons/dynamic-form/select-field/ioption';
import {METranslationCallback} from '../../commons/metranslation-callback';

export class ArtikelArt {

    static getIcon(artikelArt: EArtikelArt): string {
        let icon = '';
        switch (artikelArt) {
            case EArtikelArt.GARAGE:
                icon = 'me-icon me-icon-station';
                break;
            case EArtikelArt.SHOP:
                icon = 'far fa-store';
                break;
        }
        return '';
    }

    static option(translateFunc: METranslationCallback = null): IOption[] {
        const options: IOption[] = [
            Option.option(
                EArtikelArt.GARAGE,
                'Tankstellen',
                ArtikelArt.getIcon(EArtikelArt.GARAGE)
            ),
            Option.option(
                EArtikelArt.SHOP,
                'Shop-Artikel',
                ArtikelArt.getIcon(EArtikelArt.SHOP)
            ),
            Option.option(
                EArtikelArt.ANY,
                'Tankstellen+Shop-Artikel',
                ArtikelArt.getIcon(EArtikelArt.ANY)
            )
        ];
        if (translateFunc !== null && translateFunc !== undefined) {
            options.forEach((v: IOption, idx) => {
                options[idx].label = translateFunc(v.label);
            });
        }
        return options;
    }

    static byValue(art: any) {
        if (art === 'GARAGE') {
            return EArtikelArt.GARAGE;
        } else if (art === 'SHOP') {
            return EArtikelArt.SHOP;
        } else if (art === 'ANY') {
            return EArtikelArt.ANY;
        } else if (art === EArtikelArt.GARAGE) {
            return EArtikelArt.GARAGE;
        } else if (art === EArtikelArt.SHOP) {
            return EArtikelArt.SHOP;
        } else if (art === EArtikelArt.ANY) {
            return EArtikelArt.ANY;
        } else if (parseInt('' + art, 10) === 1) {
            return EArtikelArt.GARAGE;
        } else if (parseInt('' + art, 10) === 2) {
            return EArtikelArt.SHOP;
        } else if (parseInt('' + art, 10) === 3) {
            return EArtikelArt.ANY;
        }
        return EArtikelArt.ANY;
    }
}
