<div class="filter-container"
     *ngIf="isValid()">
    <div class="header d-flex justify-content-between">
        <h6>{{ _('Filter') }}</h6>
        <a (click)="toggleFilter()">
            <i class="icofont"
               [class.icofont-expand-alt]="filterCollapsed"
               [class.icofont-collapse]="!filterCollapsed"></i>
        </a>
    </div>
    <div class="content d-flex" *ngIf="!filterCollapsed">

        <div class="mr-3">
            <date-time-field [label]="_('Anfang')"
                             [(value)]="filter.timeFrom"
            ></date-time-field>

            <date-time-field [label]="_('Ende')"
                             [(value)]="filter.timeUntil"
            ></date-time-field>
        </div>
        <div class="d-flex" style="border-left: 1px solid #eeeeee;padding-left: 5px;">
            <div class="list-group">
                <a class="list-group-item list-group-item-action" (click)="filter.today()">
                    {{_('heute')}}
                </a>
                <a class="list-group-item list-group-item-action" (click)="filter.yesterday()">
                    {{_('gestern')}}
                </a>
            </div>
            <div class="list-group">
                <a class="list-group-item list-group-item-action" (click)="filter.thisWeek()">
                    {{_('diese Woche')}}
                </a>
                <a class="list-group-item list-group-item-action" (click)="filter.lastWeek()">
                    {{_('letzte Woche')}}
                </a>
            </div>
            <div class="list-group">
                <a class="list-group-item list-group-item-action" (click)="filter.lastThreeDays()">
                    {{_('die letzten 3 Tage')}}
                </a>
                <a class="list-group-item list-group-item-action" (click)="filter.lastSevenDays()">
                    {{_('die letzten 7 Tage')}}
                </a>
            </div>
            <div class="list-group">
                <a class="list-group-item list-group-item-action" (click)="filter.thisMonth()">
                    {{_('aktueller Monat')}}
                </a>
                <a class="list-group-item list-group-item-action" (click)="filter.lastMonth()">
                    {{_('Vormonat')}}
                </a>
            </div>
        </div>
    </div>
</div>

