import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {NgbActiveModal, NgbModal, NgbModalOptions, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';

import {ToastrService} from 'ngx-toastr';
import {KostenstelleService} from '../kostenstelle.service';
import {Kostenstelle} from '../common/kostenstelle';
import {LocalizedComponent} from '../../../commons/components/localized-component';

@Component({
    selector: 'app-kostenstellen-editor',
    templateUrl: './kostenstellen-editor.component.html',
    styleUrls: ['./kostenstellen-editor.component.scss']
})
export class KostenstellenEditorComponent extends LocalizedComponent implements OnInit {

    entity: Kostenstelle = new Kostenstelle();
    mode: any = 'new';

    static open(modalService: NgbModal, kostenstelle?: Kostenstelle): Promise<any> {
        const o: NgbModalOptions = {
            /* size: 'lg',*/
            backdrop: 'static'
        };
        const r: NgbModalRef = modalService.open(KostenstellenEditorComponent, o);
        (r.componentInstance as KostenstellenEditorComponent).open(kostenstelle);
        return r.result;
    }


    constructor(

                public activeModal: NgbActiveModal,
                public toastr: ToastrService,
                public kostenstelleService: KostenstelleService,
                public cd: ChangeDetectorRef) {
        super( cd);
    }


    open(kostenstelle: Kostenstelle = null) {
        if ((kostenstelle === null) || (kostenstelle === undefined)) {
            this.entity = new Kostenstelle();
            this.mode = 'new';
        } else {
            this.entity = Kostenstelle.fromResult(kostenstelle);
            this.mode = 'edit';
        }
    }

    isAdd() {
        return this.mode === 'new';
    }

    isEdit() {
        return this.mode === 'edit';
    }

    ngOnInit() {
    }

    cancel() {
        this.activeModal.dismiss();
    }

    save() {
        this.kostenstelleService
            .storeKostenstelle(this.entity, this.isAdd())
            .subscribe(
                r => {
                    if (r.success) {
                        this.toastr.success(this._('Kostenstelle erfolgreich gespeichert'));
                        this.activeModal.close(this.entity);
                    } else {
                        this.toastr.error(this.t('Fehler beim Sichern der Kostenstelle'));
                    }
                }
            );
    }
}
