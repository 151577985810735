import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {Ausweis} from '../common/ausweis';

import {LocalizedComponent} from '../../../commons/components/localized-component';

@Component({
               selector   : 'app-ausweis-info',
               templateUrl: './ausweis-info.component.html',
               styleUrls  : [ './ausweis-info.component.css' ]
           })
export class AusweisInfoComponent extends LocalizedComponent implements OnInit {

    public _ausweis = null;

    @Input()
    set ausweis(value: Ausweis) {
        this._ausweis = Ausweis.fromResult(value);
        if (this._ausweis !== null) {

        }
    }

    constructor(  public cd: ChangeDetectorRef) {
        super( cd);
    }

    ngOnInit() {
    }

}
