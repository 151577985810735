<table>
    <tr>
        <td >{{_('Nummer')}}</td>
        <td>{{ _kunde.nummer }}</td>
        <td >{{_('Kundennummer')}}</td>
        <td>{{ _kunde.kundennummer }}</td>
    </tr>
    <tr>
        <td >{{_('Bezeichnung')}}</td>
        <td colspan="3">{{ _kunde.bezeichnung }}</td>
    </tr>
    <tr>
        <td >{{_('Adresse')}}</td>
        <td colspan="3" [innerHtml]="_kunde.getSummary(true)"></td>
    </tr>
    <tr>
        <td >{{ _('Telefon') }}</td>
        <td>{{ _kunde.telefon }}</td>
        <td >{{ _('Telefax') }}</td>
        <td>{{ _kunde.telefax }}</td>
    </tr>
    <tr>
        <td >{{ _('Mobiltelefon') }}</td>
        <td>{{ _kunde.mobil }}</td>
        <td >{{ _('Email') }}</td>
        <td>
            <app-email-display [email]="_kunde.email"></app-email-display>
        </td>
    </tr>
    <tr>
        <td >{{ _('im System angelegt') }}</td>
        <td> {{ fDate( _kunde.dateCreated,'medium') }}</td>
        <td >{{ _('zuletzt bearbeitet') }}</td>
        <td> {{ fDate(_kunde.lastEdited , 'medium') }}</td>
    </tr>
</table>
