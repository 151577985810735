import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';


import {LocalizedComponent} from '../commons/components/localized-component';
import {IOption, NullOption} from '../commons/dynamic-form/select-field/ioption';
import {LimitType} from './limits/common/limit-type';
import {ELimitType} from './limits/common/elimit-type';
import CryptoES from "crypto-es";

@Component({
    selector: 'limit-type-field',
    template: `
        <div class="form-group row">
            <label for="{{ id }}" class="col-{{ labelwidth }} col-form-label">
                {{ label }}
            </label>
            <div class="col-{{ 12-labelwidth }}">

                <select
                        id="{{ id }}"
                        class="form-control"
                        [value]="value"
                        (input)="updateValue($event)"
                        title="{{ label }}"
                        [disabled]="disabled"
                        [class.disabled]="disabled"
                >
                    <option
                            *ngFor="let o of options"
                            value="{{ o.value }}"
                    >
                        <i class="{{o.icon}}" *ngIf="o.icon!==null && o.icon!==undefined"></i>
                        {{ o.label }}
                    </option>
                </select>
            </div>
        </div>
    `

})
export class LimitTypeFieldComponent extends LocalizedComponent implements OnInit {

    public _value: ELimitType = ELimitType.DEFAULTS;

    @Input() label = 'Limit-Typ-Feld';
    @Input() id = '';
    @Input() labelwidth = 3;
    @Input() nullable = false;
    @Input() disabled = false;

    options: IOption[] = LimitType.options((key) => {
        return this.t(key);
    });

    @Input()
    set value(v: ELimitType) {
        if (this._value !== v) {
            this._value = v;
            this.valueChange.emit(v);
        }
    }

    @Output() valueChange = new EventEmitter<ELimitType>();


    get value(): ELimitType {
        return this._value;
    }

    constructor(
        public cd: ChangeDetectorRef) {
        super(cd);
    }

    ngOnInit() {
        if (this.nullable) {
            this.options.unshift(NullOption);
        }
        if (this.id === '') {
            this.id = 'tf' + CryptoES.MD5('limittypefield-' + this.label + Math.random() + '-' + Math.random()).toString();
        }
    }

    updateValue($event: any) {
        this.value = $event.target.value;
    }
}
