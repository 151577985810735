import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {LocalizedComponent} from '../../../commons/components/localized-component';
import {Person} from '../common/person';


@Component({
    selector: 'app-person-info',
    template: `
        <table>
            <tr>
                <td><strong>{{ _('Nummer') }}</strong></td>
                <td>{{ _person.nummer }}</td>
                <td><strong>{{ _('Name/Bezeichnung') }}</strong></td>
                <td>{{ _person.name }}</td>
            </tr>
            <tr>
                <td><strong>{{ _('Personal-Nummer') }}</strong></td>
                <td>{{ _person.personalNummer }}</td>
                <td><strong>{{ _('Kostenstelle') }}</strong></td>
                <td>{{ _person.kostenstelle }}</td>
            </tr>
            <tr>
                <td><strong>{{ _('TS-ID') }}</strong></td>
                <td>{{ _person.tankserverID }}</td>
                <td><strong></strong></td>
                <td></td>
            </tr>
            <tr>
                <td><strong>{{ _('im System angelegt') }}</strong></td>
                <td>{{fDate( _person.dateCreated ,'medium' )}}</td>
                <td><strong>{{ _('zuletzt bearbeitet') }}</strong></td>
                <td>{{fDate( _person.lastEdited ,'medium' )}}</td>
            </tr>
            <tr>
                <td><strong>{{ _('Kunde') }}</strong></td>
                <td>
                    <app-kunde-quick-info
                            *ngIf="_person.kunde !==undefined && _person.kunde!==null"
                            [kunde]="_person.kunde"></app-kunde-quick-info>
                    <div *ngIf="_person.kunde ==undefined || _person.kunde==null">
                        {{ _('keinem Kunden zugeordnet') }}
                    </div>
                </td>
                <td><strong>{{ _('Ausweis') }}</strong></td>
                <td>
                    <app-ausweis-quick-info
                            *ngIf="_person.ausweis!==null && _person.ausweis!==undefined"
                            [ausweis]="_person.ausweis"></app-ausweis-quick-info>
                    <div *ngIf="_person.ausweis ==undefined || _person.ausweis==null">
                        {{ _('kein Ausweis zugeordnet') }}
                    </div>
                </td>
            </tr>
        </table>

    `
})
export class PersonInfoComponent extends LocalizedComponent implements OnInit {

    personLabel = 'Person {{ nummer }}';

    _person: Person = null;

    @Input()
    set person(value: Person) {
        this._person = Person.fromResult(value);
    }

  constructor( public cd:ChangeDetectorRef) {
    super(cd);
    }

    ngOnInit() {
    }

}
