import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';

import {LocalizedComponent} from '../../../../commons/components/localized-component';
import {IOption, Option} from '../../../../commons/dynamic-form/select-field/ioption';
import {RechnungStatus} from '../../common/rechnung-status';

@Component({
    selector: 'rechnung-status-display',
    template: '{{ value }}'
})
export class RechnungStatusDisplayComponent extends LocalizedComponent implements OnInit {

    value = '';

    @Input()
    set status(v: any) {
        this.value = '';
        if (v !== null && v !== undefined) {
            this.options.forEach(o => {
                if (v === o.value) {
                    this.value = o.label;
                }
            });
        }
    }

    options: IOption[] = RechnungStatus.getOptions((key) => {
        return this.t(key);
    });


    constructor(  public cd: ChangeDetectorRef) {
        super( cd);
    }

    ngOnInit() {
    }

}
