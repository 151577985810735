<div *ngIf="kundenAudit!==null && kundenAudit!==undefined && _kunde!==null && _kunde!==undefined">
    <div class="alert alert-info">
        {{_('Sie sehen hier nur die Änderungen an diesem Kunden-Stammdatensatz. '+
        ' Änderungen an den Ausweisen sind zu jeder Ausweis erfasst')}}
    </div>
    <data-table
            [selectable]="false"
            [data]="kundenAudit.asDataTableRows()"
            [orders]="kundenAudit.order"
            [filter]="kundenAudit.simpleFilter"
            [hideCheckboxes]="true"
            [containerHeight]="300"
    >

        <column
                [field]="'dateCreated'"
                [label]="_('Zeitpunkt')"
                type="Date"
                [cfg]="{format:'medium'}"
        ></column>
        <column
                [field]="'uname'"
                [label]="_('Benutzername')"
                type="text"
        ></column>
        <column
                [field]="'info'"
                [label]="_('Info')"
                type="text"
        ></column>
        <data-table-table-extra-header>
            <app-paginator
                    [total]="kundenAudit.total"
                    [page]="kundenAudit.page"
                    [perpage]="kundenAudit.size"
                    (pageChange)="updateKundenAudit(true,$event)"
                    (pageCountChange)="updateKundenAuditPageCount($event)"
            ></app-paginator>
        </data-table-table-extra-header>

    </data-table>
</div>