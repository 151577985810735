import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {LocalizedComponent} from '../../../commons/components/localized-component';
import {KartenanfrageList} from '../common/kartenanfrage-list';
import {Subscription} from 'rxjs';

import {KartenanfrageService} from '../common/kartenanfrage.service';
import {SysInfoService} from '../../../commons/sysinfo/services/sys-info.service';
import {IKartenanfrage} from '../common/ikartenanfrage';
import {IListResult} from '../../../commons/list-result';

@Component({
    selector: 'app-dashboard-last-kartenanfragen-block',
    templateUrl: './dashboard-last-kartenanfragen-block.component.html',
    styleUrls: ['./dashboard-last-kartenanfragen-block.component.scss']
})
export class DashboardLastKartenanfragenBlockComponent extends LocalizedComponent implements OnInit, OnDestroy {
    public kartenanfragen = new KartenanfrageList();
    subs: Subscription[] = [];

    constructor(
                
                public cd: ChangeDetectorRef,
                public kartenanfrageService: KartenanfrageService,
                public sysInfoService: SysInfoService
    ) {
        super( cd);
    }

    ngOnInit() {
        this.subs.push(
            this.kartenanfragen.onUpdateRequired.subscribe(
                (l: KartenanfrageList) => {
                    this.kartenanfrageService.list(
                        3, 0
                    ).subscribe(
                        (r: IListResult<IKartenanfrage>) => {
                            l.populateFromListResult(r);
                            this.cd.markForCheck();
                        }
                    );
                }
            )
        );
        this.kartenanfragen.reload();
    }

    ngOnDestroy() {
        this.subs.forEach((s: Subscription) => {
            s.unsubscribe();
        });
        this.subs = [];
        super.ngOnDestroy();
    }

}
