import {AfterViewInit, ChangeDetectorRef, Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {StationenFilter} from './stationen-filter';
import {CountryElementRendererComponent} from '../../commons/laender/country-element-renderer/country-element-renderer.component';
import {IFilterDialog} from '../../commons/data-table/ifilter-dialog';
import {LocalizedComponent} from '../../commons/components/localized-component';
import {Land} from '../../commons/laender/common/land';
import {SprachenService} from '../../commons/sprachen/common/sprachen.service';

import {ElementListComponent} from '../../commons/element-list/element-list.component';

@Component({
  selector: 'app-stationen-filter-dialog',
  templateUrl: './stationen-filter-dialog.component.html',
  styleUrls: ['./stationen-filter-dialog.component.scss']
})
export class StationenFilterDialogComponent extends LocalizedComponent implements IFilterDialog, AfterViewInit {
 countryRenderer = CountryElementRendererComponent;

    filter: StationenFilter = new StationenFilter();

    @Output() filterChanged: EventEmitter<string> = new EventEmitter<string>();
    @ViewChild('laender',{static: true}) laender: ElementListComponent;

  constructor( 
        
        public cd: ChangeDetectorRef,
        public sprachenService: SprachenService) {
        super( cd);
        this.filter.filterChanged.subscribe(
            () => {
                if (this.laender !== null && this.laender !== undefined) {
                    this.laender.elements = this.filter.countries;
                }
                this.markChanged();
            }
        );
  }


    ngAfterViewInit() {
        this.markChanged();
    }

    getQuery(): string {
        return this.filter.getQuery();
    }

    markChanged(visble = true): void {
        this.cd.markForCheck();
        if (visble) {
            this.filterChanged.emit(this.getQuery());
        } else {
            this.filterChanged.emit('');
        }
    }

    land_at() {
        this.filter.clearCountries();
        this.sprachenService.Oesterreich().then((l: Land) => {
            this.filter.addCountry(l);
        });
    }

    land_atde() {
        this.filter.clearCountries();
        this.sprachenService.Oesterreich().then((l: Land) => {
            this.filter.addCountry(l);
        });
        this.sprachenService.Deutschland().then((l: Land) => {
            this.filter.addCountry(l);
        });
    }

    land_de() {
        this.filter.clearCountries();
        this.sprachenService.Deutschland().then((l: Land) => {
            this.filter.addCountry(l);
        });
    }

}
