import {ChangeDetectorRef, Component, Injector, OnInit} from '@angular/core';
import {DataTableColumnDirective} from '../../../../commons/data-table/data-table-column.directive';
import {DataTableRow} from '../../../../commons/data-table/data-table-row';
import {LocalizedCellRenderer} from '../../../../commons/data-table/renderer/common-cell-renderer';
import {Kontingent} from '../../common/kontingent';

@Component({
               selector: 'app-kontingente-cell-renderer',
               template: '<div [innerHTML]="value"></div>'
           })
export class KontingenteCellRendererComponent extends LocalizedCellRenderer implements OnInit {

    cell: Kontingent = null;
    row: DataTableRow<any> = null;
    column: DataTableColumnDirective = null;
    value = '';


    private format(title: string, label: string, value: any, value2: any) {
        const t = this.t(title, {n: value, m: value2});
        const v = this.t(label, {n: value, m: value2});
        return '<span class="badge badge-light" title="' + t + '">' + v + '</span>';
    }

    private getLimit(v: any,
                     verfuegbar: any,
                     total: any,
                     limitText: string,
                     noLimitText: string,
                     limitLabel: string,
                     noLimitLabel: string) {
        let h: boolean;
        if (v === 'infinite') {
            h = true;
        } else {
            h = (parseFloat('' + v) > 0);
        }
        return this.format(
            (h) ? limitLabel : noLimitLabel,
            (h) ? limitText : noLimitText,
            verfuegbar,
            total
        );
    }

    constructor(public injector: Injector, public cd: ChangeDetectorRef) {
        super(cd);
        this.cell = Kontingent.fromResult(this.injector.get('cell'));
        this.row = this.injector.get('row');
        this.column = this.injector.get('column');

        this.value = '';


        if (this.cell !== null && this.cell !== undefined) {

            let h: string[] = [];

            if (this.cell.transaktionenTag > 0) {
                h.push(
                    this.getLimit(
                        this.cell.getVerfuegbarTransaktionenTag(),
                        this.cell.getVerfuegbarTransaktionenTag(),
                        this.cell.transaktionenTag,
                        '{{n}} T/Tag',
                        'T/Tag aufgebraucht',
                        'heute noch {{n}} von {{m}} Transaktionen möglich',
                        'Tageskontingent von {{m}} Transaktionen aufgebraucht'
                    )
                );
            }
            if (this.cell.transaktionenWoche > 0) {
                h.push(
                    this.getLimit(
                        this.cell.getVerfuegbarTransaktionenWoche(),
                        this.cell.getVerfuegbarTransaktionenWoche(),
                        this.cell.transaktionenWoche,
                        '{{n}} T/Woche',
                        'T/Woche aufgebraucht',
                        'diese Woche noch {{n}} von {{m}} Transaktionen möglich',
                        'Wochenkontingent von {{m}} Transaktionen aufgebraucht'
                    )
                );
            }
            if (this.cell.transaktionenHalbMonat > 0) {
                h.push(
                    this.getLimit(
                        this.cell.getVerfuegbarTransaktionenHalbMonat(),
                        this.cell.getVerfuegbarTransaktionenHalbMonat(),
                        this.cell.transaktionenHalbMonat,
                        '{{n}} T/Monatshälfte',
                        'T/Monatshälfte aufgebraucht',
                        'diese Monatshälfte noch {{n}} von {{m}} Transaktionen möglich',
                        'Halbmonatskontingent von {{m}} Transaktionen aufgebraucht'
                    )
                );
            }
            if (this.cell.transaktionenMonat > 0) {
                h.push(
                    this.getLimit(
                        this.cell.getVerfuegbarTransaktionenMonat(),
                        this.cell.getVerfuegbarTransaktionenMonat(),
                        this.cell.transaktionenMonat,
                        '{{n}} T/Monat',
                        'T/Monat aufgebraucht',
                        'diesen Monat noch {{n}} von {{m}} Transaktionen möglich',
                        'Monatskontingent von {{m}} Transaktionen aufgebraucht'
                    )
                );
            }
            if (this.cell.isPrepaid && this.cell.transaktionenPrepaid > 0) {
                h.push(
                    this.getLimit(
                        this.cell.getVerfuegbarTransaktionenPrepaid(),
                        this.cell.getVerfuegbarTransaktionenPrepaid(),
                        this.cell.transaktionenPrepaid,
                        '{{n}} T total',
                        'T aufgebraucht',
                        'insgesamt noch {{n}} von {{m}} Transaktionen möglich',
                        'Transaktionslimit von gesamt {{m}} Transaktionen erreicht'
                    )
                );
            }


            if (this.cell.mengeTag > 0) {
                h.push(
                    this.getLimit(
                        this.cell.getVerfuegbarMengeTag(),
                        this.fNum(this.cell.getVerfuegbarMengeTag(), '1.2-2'),
                        this.fNum(this.cell.mengeTag, '1.2-2'),
                        '{{n}} L/Tag',
                        'L/Tag aufgebraucht',
                        'heute noch {{n}} von {{m}} Liter möglich',
                        'Tageskontingent von {{m}} Liter aufgebraucht'
                    )
                );
            }
            if (this.cell.mengeWoche > 0) {
                h.push(
                    this.getLimit(
                        this.cell.getVerfuegbarMengeWoche(),
                        this.fNum(this.cell.getVerfuegbarMengeWoche(),
                                  '1.2-2'),
                        this.fNum(this.cell.mengeWoche, '1.2-2'),
                        '{{n}} L/Woche',
                        'L/Woche aufgebraucht',
                        'diese Woche noch {{n}} von {{m}} Liter möglich',
                        'Wochenkontingent von {{m}} Liter aufgebraucht'
                    )
                );
            }
            if (this.cell.mengeHalbMonat > 0) {
                h.push(
                    this.getLimit(
                        this.cell.getVerfuegbarMengeHalbMonat(),
                        this.fNum(this.cell.getVerfuegbarMengeHalbMonat(),
                                  '1.2-2'),
                        this.fNum(this.cell.mengeHalbMonat, '1.2-2'),
                        '{{n}} L/Monatshälfte',
                        '{{n}} L/Monatshälfte',
                        'diese Monatshälfte noch {{n}} von {{m}} Liter möglich',
                        'Monatshälfte-Kontingent von {{m}} Liter aufgbraucht'
                    )
                );
            }
            if (this.cell.mengeMonat > 0) {
                h.push(
                    this.getLimit(
                        this.cell.getVerfuegbarMengeMonat(),
                        this.fNum(this.cell.getVerfuegbarMengeMonat(),
                                  '1.2-2'),
                        this.fNum(this.cell.mengeMonat, '1.2-2'),
                        '{{n}} L/Monat',
                        'L/Monat aufgebraucht',
                        'diesen Monat noch {{n}} von {{m}} Liter möglich',
                        'Monatskontingent von {{m}} Liter aufgebraucht'
                    )
                );
            }
            if (this.cell.isPrepaid && this.cell.mengePrepaid > 0) {
                h.push(
                    this.getLimit(
                        this.cell.getVerfuegbarMengePrepaid(),
                        this.fNum(this.cell.getVerfuegbarMengePrepaid(),
                                  '1.2-2'),
                        this.fNum(this.cell.mengePrepaid, '1.2-2'),
                        '{{n}} Liter total',
                        '{{n}} Liter total',
                        'insgesamt noch {{n}} von {{m}} Liter möglich',
                        'Mengenkontingent von insgesamt von {{m}} Liter aufgebraucht'
                    )
                );
            }


            if (this.cell.betragTag > 0) {
                h.push(
                    this.getLimit(
                        this.cell.getVerfuegbarBetragTag(),
                        this.fCurrency(this.cell.getVerfuegbarBetragTag(), 'EUR'),
                        this.fCurrency(this.cell.betragTag, 'EUR'),
                        '{{n}}/Tag',
                        '€/Tag aufgebraucht',
                        'heute noch {{n}} von {{m}} EUR möglich',
                        'Tageskontingen von {{m}} EUR aufgebraucht'
                    )
                );
            }
            if (this.cell.betragWoche > 0) {
                h.push(
                    this.getLimit(
                        this.cell.getVerfuegbarBetragWoche(),
                        this.fCurrency(this.cell.getVerfuegbarBetragWoche(),
                                       'EUR'),
                        this.fCurrency(this.cell.betragWoche, 'EUR'),
                        '{{n}}/Woche',
                        '€/Woche aufgebraucht',
                        'diese Woche noch {{n}} von {{m}} EUR möglich',
                        'Wochenlimit noch {{m}} EUR aufgebraucht'
                    )
                );
            }
            if (this.cell.betragHalbMonat > 0) {
                h.push(
                    this.getLimit(
                        this.cell.getVerfuegbarBetragMonat(),
                        this.fCurrency(this.cell.getVerfuegbarBetragHalbMonat(),
                                       'EUR'),
                        this.fCurrency(this.cell.betragHalbMonat, 'EUR'),
                        '{{n}}/Monatshälfte',
                        '€/Monatshälte aufgebraucht',
                        'diese Monatshälfte noch {{n}} von {{m}} EUR möglich',
                        'Monatshälfte-Kontingent von {{m}} EUR aufgebraucht'
                    )
                );
            }
            if (this.cell.betragMonat > 0) {
                h.push(
                    this.getLimit(
                        this.cell.getVerfuegbarBetragMonat(),
                        this.fCurrency(this.cell.getVerfuegbarBetragMonat(),
                                       'EUR'),
                        this.fCurrency(this.cell.betragMonat, 'EUR'),
                        '{{n}}/Monat',
                        '€/Monat aufgebraucht',
                        'diesen Monat noch {{n}} von {{m}} EUR möglich',
                        'Monatskontingent noch {{n}} von {{m}} EUR aufgebraucht'
                    )
                );
            }
            if (this.cell.isPrepaid && this.cell.betragPrepaid > 0) {
                h.push(
                    this.getLimit(
                        this.cell.getVerfuegbarBetragPrepaid(),
                        this.fCurrency(this.cell.getVerfuegbarBetragPrepaid(),
                                       'EUR'),
                        this.fCurrency(this.cell.betragPrepaid, 'EUR'),
                        '{{n}} total',
                        'Betragslimit erreicht',
                        'insgesamt noch {{n}} von {{m}} EUR möglich',
                        'Betragslimit von gesamt {{m}} EUR aufgebraucht'
                    )
                );
            }

            if (this.cell.maxVorautorisierungsbetrag > 0) {
                h.push(
                    this.format(
                        'pro Vorgang max {{n}} EUR möglich',
                        'max {{n}}/Vorgang',
                        this.fCurrency(this.cell.maxVorautorisierungsbetrag,
                                       'EUR'),
                        this.fCurrency(this.cell.maxVorautorisierungsbetrag, 'EUR')
                    )
                );
            }


            if (h.length < 1) {
                h.push(
                    this.format(
                        'keine Limitierung eingestellt',
                        'keine Limitierung',
                        0,
                        0
                    )
                );
            }
            this.value = h.join(' ');
        }
    }


}
