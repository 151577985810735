<data-table [selectable]="false"
            [data]="sonden.asDataTableRows()"
            [rowActionsAsButtons]="true"
            [showFilterArea]="false"
            [extraRowClasses]="sondenRowClassGetter"
            #sondenListe
>

    <tableaction
            *hasRole="['ROLE_ADMIN','ROLE_PROJECT_ADMIN']"
            [label]="t('Werkzeuge')"
            [icon]="'icofont icofont-tools'"
            [type]="'dropdown'"

    >
        <list-option
                *hasRole="['ROLE_ADMIN','ROLE_PROJECT_ADMIN']"
                [label]="t('Sonden indizieren')"
                [icon]="'icofont icofont-cloud-refresh'"
                (perform)="reindexSonden()"
        ></list-option>
    </tableaction>


    <column
            [field]="'station'"
            [label]="_('Station')"
            [renderer]="stationR"
    ></column>
    <column
            [field]="'nummer'"
            [label]="t('Nummer')"
            [type]="'Integer'"
    ></column>
    <column
            [field]="'bezeichnung'"
            [label]="t('Bezeichnung')"
    ></column>
    <column
            [field]="'volumen'"
            [label]="t('Überw. Volumen')"
            [renderer]="literR"
            [cfg]="{fdigits:0}"
    ></column>
    <column
            [field]="'warnmenge'"
            [label]="t('Warnmenge')"
            [renderer]="literR"
            [cfg]="{fdigits:0}"
    ></column>
    <column
            [field]="'enabled'"
            [label]="t('aktiviert')"
            [type]="'boolean'"
    ></column>
    <column
            [field]="'tank'"
            [label]="t('Tank')"
            [renderer]="tankR"
    ></column>
    <column
            [field]="'letzteBuchung~0'"
            [label]="_('letzte Höhe')"
            [renderer]="buchungR"
            [cfg]="{field:'hoehe'}"
    ></column>
    <column
            [field]="'letzteBuchung~1'"
            [label]="_('letzte Menge')"
            [renderer]="buchungR"
            [cfg]="{field:'menge'}"
    ></column>
    <column
            [field]="'letzteBuchung~2'"
            [label]="_('letzte Messung')"
            [renderer]="buchungR"
            [cfg]="{field:'zeitpunkt'}"
    ></column>
    <rowaction
            *hasRole="['ROLE_ADMIN','ROLE_PROJECT_ADMIN']"
            [label]="_('Sonde bearbeiten')"
            [icon]="'icofont icofont-ui-edit'"
            (perform)="editSonde($event)"
    ></rowaction>
    <rowaction
            *hasRole="['ROLE_ADMIN','ROLE_BILLING','ROLE_PROJECT_ADMIN','ROLE_BESTAND']"
            [label]="_('Buchungen')"
            [icon]="'far fa-database'"
            [title]="_('Buchungen betrachten')"
            (perform)="showPeilungen($event)"
    ></rowaction>

    <data-table-table-extra-header>
        <app-paginator
                [total]="sonden.total"
                [page]="sonden.page"
                [perpage]="sonden.size"
                (pageChange)="updateSonden(true,$event)"
                (pageCountChange)="updateSondenPageCount($event)"
                [showSearch]="true"
                (queryChange)="sondenQueryChange($event)"
                [showFilterButton]="true"
                [datatable]="sondenListe"
                (onFilterToggle)="sondenFilterToggle($event)"
                [filterDialog]="filterDialog"
        ></app-paginator>

    </data-table-table-extra-header>

    <data-table-filter-area>
        <app-sonden-filter-dialog
                #filterDialog
                (filterChanged)="sondenQueryChange2($event)"
        ></app-sonden-filter-dialog>
    </data-table-filter-area>
</data-table>
