<app-login-alert>
    <app-area-heading
            [icon]="'icofont icofont-chart-arrows-axis'"
            title="{{ _('Bestandsübersicht') }}"
    >
        <div class="d-flex" *ngIf="_tank!==null && _tank!==undefined && _station!==null && _station!==undefined">
            <app-station-quick-info
                    *ngIf="_station!==null && _station!==undefined"
                    [station]="_station"
                    [showLabel]="true"
            ></app-station-quick-info>
            <div class="ml-2" *ngIf="_tank!==null && _tank!==undefined && _station!==null && _station!==undefined">
                &nbsp;
            </div>
            <app-tank-quick-info [tank]="_tank"></app-tank-quick-info>
        </div>
    </app-area-heading>


    <div class="card">
        <div class="card-body">
            <div class="card-text">

                <ng-container *ngIf="recalcsPending>0">
                    <div class="alert alert-danger">
                        <strong>Achtung!</strong>
                        Es laufen noch Neuberechnungen von Tankbeständen.
                        In der Warteschlange befinden sich momentan
                        <strong>{{recalcsPending}}</strong> Datensätze zur Verarbeitung.
                    </div>
                </ng-container>


                <data-table
                        [selectable]="false"
                        [data]="bestandList.asDataTableRows()"
                        [orders]="bestandList.order"
                        [filter]="bestandList.simpleFilter"
                        (sortChanged)="sortChanged($event)"
                        (filterChanged)="filterChanged($event)"
                        [rowActionsAsButtons]="true"
                        [tableActionsAsButtons]="true"
                        [showFilterArea]="false"
                        [extraRowClasses]="rowClasses"
                        #tankbestandListe
                >

                    <tableaction
                            [label]="_('Export')"
                            [icon]="'icofont icofont-download-alt'"
                            [type]="'dropdown'"
                    >
                        <list-option
                                *hasRole="['ROLE_ADMIN','ROLE_PROJECT_ADMIN','ROLE_BILLING','ROLE_BESTAND']"
                                [label]="_('bestandsübersicht .xlsx')"
                                [icon]="'far fa-file-excel'"
                                (perform)="downloadEntries('xlsx',12001)"
                        ></list-option>
                    </tableaction>
                    <tableaction
                            *hasRole="['ROLE_ADMIN','ROLE_PROJECT_ADMIN','ROLE_BESTAND_WRITABLE']"
                            [label]="t('Werkzeuge')"
                            [icon]="'icofont icofont-tools'"
                            [type]="'dropdown'"

                    >
                        <list-option
                                *hasRole="['ROLE_ADMIN','ROLE_PROJECT_ADMIN','ROLE_BESTAND_WRITABLE']"
                                [title]="_('Bestand der letzten 5 Wochen neu berechnen')"
                                [label]="_('Bestand neu berechnen (5 Wochen)')"
                                [icon]="'icofont icofont-calculator'"
                                (perform)="recalc(5)"
                        ></list-option>
                        <list-option
                                *hasRole="['ROLE_ADMIN','ROLE_PROJECT_ADMIN','ROLE_BESTAND_WRITABLE']"
                                [title]="_('Bestand der letzten 2 Wochen neu berechnen')"
                                [label]="_('Bestand neu berechnen (2 Wochen)')"
                                [icon]="'icofont icofont-calculator'"
                                (perform)="recalc(2)"
                        ></list-option>
                        <list-option
                                *hasRole="['ROLE_ADMIN','ROLE_PROJECT_ADMIN','ROLE_BESTAND_WRITABLE']"
                                [title]="_('Bestand der letzten 4 Wochen neu berechnen')"
                                [label]="_('Bestand neu berechnen (4 Wochen)')"
                                [icon]="'icofont icofont-calculator'"
                                (perform)="recalc(4)"
                        ></list-option>
                        <list-option
                                *hasRole="['ROLE_ADMIN','ROLE_PROJECT_ADMIN','ROLE_BESTAND_WRITABLE']"
                                [title]="_('Bestand der letzten 3 Monate neu berechnen')"
                                [label]="_('Bestand neu berechnen (12 Wochen)')"
                                [icon]="'icofont icofont-calculator'"
                                (perform)="recalc(12)"
                        ></list-option>
                        <list-option
                                *hasRole="['ROLE_ADMIN','ROLE_PROJECT_ADMIN','ROLE_BESTAND_WRITABLE']"
                                [title]="_('kompletten Bestand neu berechnen')"
                                [label]="_('kompletten Bestand neu berechnen')"
                                [icon]="'icofont icofont-calculator'"
                                (perform)="recalc()"
                        ></list-option>
                    </tableaction>
                    <column
                            [field]="'datum'"
                            [label]="_('Datum')"
                            type="Date"
                            [cfg]="{format:'shortDate'}"
                            [sortable]="true"></column>
                    <ng-template #tank let-it="it">
                        <div *ngFor="let it of typed(it)">
                            <div class="fw-bold mb-1" style="font-size: 12pt">
                                <app-station-quick-info [station]="it.station"></app-station-quick-info>
                            </div>
                            <div class="d-flex justify-content-start align-items-center">
                                <div class="me-2 mr-2">
                                    <app-tank-quick-info [tank]="it.tank"></app-tank-quick-info>
                                </div>
                                <div>
                                    <app-produkt-quick-info [produkt]="it.produkt"></app-produkt-quick-info>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                    <column [field]="'tank'"
                            [label]="_('Tank & Produkt')"
                            [sortable]="false"
                            [templateRef]="tank"
                    ></column>
                    <column
                            [field]="'bestandBefore'"
                            [label]="_('Bestand vorher')"
                            [title]="_('der Füllstand zu Beginn des Tages')"
                            [renderer]="literR"
                            [cfg]="{fdigits:0,round:true}"
                            [sortable]="false">

                    </column>
                    <ng-template #zug let-it="it">
                        <div class="text-nowrap" *ngFor="let it of typed(it)">
                            <div class="d-flex justify-content-between align-items-center">
                                <div>
                                Lieferungen:
                                </div>
                                <div [class.fw-bold]="it.zugaenge>0">+{{ fNum(it.zugaenge,'1.0-0') }} l</div>
                            </div>
                            <div class="d-flex justify-content-between align-items-center">
                                <div>Tankungen:</div>
                                <div [class.fw-bold]="it.abgaenge>0">-{{ fNum(it.abgaenge,'1.0-0') }} l</div>
                            </div>
                        </div>
                    </ng-template>
                    <column
                            [field]="'zugaenge'"
                            [label]="_('Zugänge/Abgänge')"
                            [templateRef]="zug"
                            [sortable]="false">

                    </column>
                    <column
                            [field]="'bestand'"
                            [label]="_('Bestand neu')"
                            [title]="_('der berechnete neue Füllstand')"
                            [renderer]="literR"
                            [cfg]="{fdigits:0,round:true}"
                            [sortable]="false">
                    </column>
                    <column
                            [field]="'bestandPeilung'"
                            [label]="_('Bestand gemessen')"
                            [title]="_('der durch die angeschlossene Sonde ermittelte Füllstand')"
                            [renderer]="literR"
                            [cfg]="{fdigits:0,round:true}"
                            [sortable]="false">

                    </column>
                    <column
                            [field]="'abweichung'"
                            [label]="_('Abweichung')"
                            [title]="_('Abweichung zwischen gemessenen und berechnetem Bestand')"
                            [renderer]="literR"
                            [cfg]="{fdigits:0,round:true}"
                            [sortable]="false">
                    </column>
                    <column
                            [field]="'bestandAenderung'"
                            [label]="_('Δ Bestand')"
                            [title]="_('Veränderung des berechneten Bestands (alt-neu)')"
                            [renderer]="literR"
                            [cfg]="{fdigits:0,round:true}"
                            [sortable]="false">
                    </column>
                    <column
                            [field]="'peilungDifferenz'"
                            [label]="_('Δ Messung')"
                            [title]="_('Veränderung des Messwerts der Sonde (letzter Tag mit Messwert - aktueller Messwert vom Tag)')"
                            [renderer]="literR"
                            [cfg]="{fdigits:0,round:true}"
                            [sortable]="false">
                    </column>

                    <column
                            [field]="'warenWert'"
                            [label]="_('Warenwert')"
                            [renderer]="preiswert"
                            [cfg]="{fdigits:2,round:true}"
                            [sortable]="false">
                    </column>
                    <ng-template #stand let-it="it">
                        <ng-container *ngFor="let it of typed(it)">
                            <div class="text-nowrap">
                                <div *ngIf="it.needsRecalc" class="text-danger">
                                  <i class="far fa-icon fa-exclamation-triangle"></i>  wird neu berechnet
                                </div>
                                <div>
                                    {{ fDate(it.lastUpdated,'medium') }}
                                </div>
                            </div>
                        </ng-container>

                    </ng-template>
                    <column
                            [field]="'lastUpdated'"
                            [label]="_('Stand')"
                            [templateRef]="stand"
                            [sortable]="false">
                    </column>
                    <data-table-table-extra-header>
                        <app-paginator
                                [total]="bestandList.total"
                                [page]="bestandList.page"
                                [perpage]="bestandList.size"
                                (pageChange)="updateBestandList(true,$event)"
                                (pageCountChange)="updateBestandListPageCount($event)"
                                [showSearch]="true"
                                (queryChange)="bestandListQueryChange($event)"
                                [showFilterButton]="true"
                                [datatable]="tankbestandListe"
                                (filterToggled)="bestandFilterToggle($event)"
                                [filterDialog]="filterDialog"
                        ></app-paginator>
                    </data-table-table-extra-header>
                    <data-table-filter-area>
                        <app-tankbestand-filterdialog
                            #filterDialog
                            (filterChanged)="bestandQueryChange2($event)"
                        ></app-tankbestand-filterdialog>
                    </data-table-filter-area>
                </data-table>


            </div>
        </div>
    </div>


</app-login-alert>
