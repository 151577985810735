import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {Rechnung} from '../../common/rechnung';

import * as FileSaver from 'file-saver';
import {LocalizedComponent} from '../../../../commons/components/localized-component';
import {AttachmentService} from '../../../attachments/common/attachment.service';
import {Attachment} from '../../../attachments/common/attachment';

@Component({
  selector: 'app-rechnung-info',
  templateUrl: './rechnung-info.component.html',
  styleUrls: ['./rechnung-info.component.css']
})
export class RechnungInfoComponent extends LocalizedComponent implements OnInit {
    @Input() entity: Rechnung = null;

    currencyCode = 'EUR';

    constructor(

                public attachmentService: AttachmentService,
                public cd: ChangeDetectorRef) {
        super( cd);
    }

    ngOnInit() {

        if (this.entity.waehrung !== null && this.entity.waehrung !== undefined) {
            this.currencyCode = this.entity.waehrung.code;
            if (this.currencyCode === '' || this.currencyCode === null || this.currencyCode === undefined) {
                this.currencyCode = 'EUR';
            }
        }

    }

    getIcon(at: any) {
        return Attachment.fromResult(at).getIcon();
    }

    getURI(at: any) {
        return Attachment.fromResult(at).getURI();
    }

    downloadAttachment(ata: any) {
        const anhang = Attachment.fromResult(ata);
        if (anhang.isURL()) {
            window.open(anhang.getURI(), '_blank');
        } else if (anhang.isFile()) {
            this.attachmentService
                .downloadAttachment(anhang)
                .subscribe((response: Blob) => {
                    FileSaver.saveAs(response, anhang.filename);
                });
        }
    }

}
