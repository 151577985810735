import {Lieferschein} from './lieferschein';
import {SimpleLieferscheinFilter} from './simple-lieferschein-filter';
import {ILieferschein} from './ilieferschein';
import {ResultMetaSortImpl} from '../../../commons/result-meta';
import {IListResult} from '../../../commons/list-result';
import {CommonList} from '../../../commons/common-list';

export class LieferscheinList extends CommonList<Lieferschein> {

    public simpleFilter: SimpleLieferscheinFilter = new SimpleLieferscheinFilter();

    static fromListResult(res: IListResult<ILieferschein>): LieferscheinList {
        const newList = new LieferscheinList();
        newList.clear();
        newList.simpleFilter.clear();

        if (res.meta) {
            newList.total = res.meta.total || 0;
            newList.offset = res.meta.offset || 0;
            newList.size = res.meta.size || 0;
            newList.page = res.meta.page || 0;
            if (res.meta.order) {
                newList.order = [];
                for (let x of res.meta.order) {
                    newList.order.push(
                        new ResultMetaSortImpl(
                            x.field || 'datum',
                            x.direction || 'desc'
                        )
                    );
                }
            }
        }
        if (res.data) {
            for (let row of res.data) {
                newList.add(Lieferschein.fromResult(row));
            }
        }
        if (res.simpleFilter) {
            for (let k of Object.keys(newList.simpleFilter)) {
                if (k.substr(0, 1) !== '_') {
                    newList.simpleFilter[k] = (res.simpleFilter[k] !== undefined) ? res.simpleFilter[k] : null;
                }
            }
        }

        return newList;

    }
}
