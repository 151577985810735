import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';

import {LocalizedComponent} from '../components/localized-component';
import {DataTableRow} from '../data-table/data-table-row';
import {Land} from '../laender/common/land';
import {DataTableComponent} from '../data-table/data-table.component';

@Component({
    selector: 'countries-selector',
    template: `
                   <div class="form-group row">
                       <label class="col-{{ labelwidth }} col-form-label" *ngIf="labelwidth>0">
                           {{ label }}
                       </label>
                       <div class="col-{{ 12-labelwidth }}">

                           <div class="card">
                               <div class="card-body">
                                   <div class="card-header clearfix" *ngIf="!disabled">

                                       <div class="float-right">
                                           <button class="btn btn-default btn-sm"
                                                   [class.disabled]="checkAddDisabled()"
                                                   title="{{ _('gewähltes Land in Liste übernehmen') }}"
                                                   (click)="add()"
                                           >
                                               <i class="icofont icofont-ui-add"></i>
                                           </button>
                                           <button class="btn btn-default btn-sm"
                                                   [class.disabled]="checkDelDisabled()"
                                                   title="{{ _('gewähltes Land aus Liste entfernen') }}"
                                                   (click)="removeSelected()"
                                           >
                                               <i class="icofont icofont-ui-delete"></i>
                                           </button>
                                       </div>
                                       <country-field
                                               [labelwidth]="0"
                                               [label]="_('Land wählen')"
                                               [(value)]="currentLand"
                                               [small]="true"
                                               [nullable]="false"
                                               [disabled]="disabled"
                                       ></country-field>
                                   </div>
                                   <div class="card-text">
                                       <data-table
                                               #tslist
                                               [data]="countries"
                                               [selectable]="!disabled"
                                               [singleselect]="true"
                                       >
                                           <column [label]="_('Flagge')" [field]="'laendercode'" [type]="'Flag'"></column>
                                           <column [label]="_('Bezeichnung')" [field]="'bezeichnung'"></column>
                                       </data-table>
                                   </div>
                               </div>
                           </div>
                       </div>
                   </div>
               `,
    styles: [`
                                      :host .card-header {
                                          padding: 0.35rem;
                                      }

                                      :host .card-header > div:first-child {
                                          padding-left: 0.35rem;
                                      }

                                      :host .card-header > station-field {
                                          min-width: 175px;
                                      }

                                      :host .card-header ::ng-deep .form-group {
                                          margin: 0 !important;
                                      }

                                      :host .card-header ::ng-deep .form-group .col-12 {
                                          padding: 0px;
                                      }

                                      :host data-table {
                                          margin: 0 !important;
                                          font-size: 0.8rem;
                                      }

                                      :host data-table ::ng-deep .column-nummer {
                                          width: 50px;
                                      }

                                      :host data-table ::ng-deep td {
                                          padding: 0.35rem !important;
                                      }

                                  `],
    changeDetection: ChangeDetectionStrategy.Default
})
export class CountriesSelectorComponent extends LocalizedComponent implements OnInit, AfterViewInit {

    @Input() label = 'Stationen';
    @Input() labelwidth = 3;
    @Input() disabled = false;


    countries: DataTableRow<Land>[] = [];
    currentLand: Land = null;

    @ViewChild(DataTableComponent, {static: true}) dataTable: DataTableComponent;

    upd() {
        if (this.dataTable !== null && this.dataTable !== undefined) {
            this.dataTable.updateRenderers();
        }
        this.cd.markForCheck();
    }


    @Input()
    set value(v: Land[]) {
        if (v === null || v === undefined) {
            v = [];
        }
        v.forEach(e => {
            this.addElementToList(e, true);
        });
        this.countries.forEach(z => {
            let found = false;
            v.forEach(e => {
                if (e.laendercode === (z.data as Land).laendercode) {
                    found = true;
                }
            });
            if (!found) {
                this.removeElementFromList(z.data, true);
            }
        });
        this.upd();
    }


    get value(): Land[] {
        const v: Land[] = [];
        this.countries.forEach(z => {
            v.push(z.data as Land);
        });

        return v;
    }

    @Output() valueChanged: EventEmitter<Land[]> = new EventEmitter<Land[]>();


    constructor(
        public cd: ChangeDetectorRef) {
        super(cd);
    }

    ngOnInit() {
    }

    ngAfterViewInit(): void {
        this.upd();
    }

    isElementInList(elem: Land): number {
        let position = -1;
        this.countries.forEach(
            (z, index) => {
                if ((z.data as Land).laendercode === elem.laendercode) {
                    position = index;
                }
            }
        );
        return position;
    }

    addElementToList(elem: Land, suppressEvent = false) {
        if (elem === null) {
            return;
        }
        if (elem === undefined) {
            return;
        }
        if (this.isElementInList(elem) < 0) {
            this.countries.push(new DataTableRow<Land>(elem));
            this.upd();
            if (!suppressEvent) {
                this.valueChanged.emit(this.value);
            }
        }
    }

    removeElementFromList(elem: Land, suppressEvent = false) {
        const pos = this.isElementInList(elem);
        if (pos >= 0) {
            this.countries.splice(pos, 1);
            this.upd();
            if (!suppressEvent) {
                this.valueChanged.emit(this.value);
            }
        }
    }

    add() {
        if ((this.currentLand !== null) && (this.currentLand !== undefined)) {
            this.addElementToList(this.currentLand);
        }
    }

    checkAddDisabled(): boolean {
        let r = (this.currentLand === null) || (this.currentLand === undefined);
        if (r) {
            return r;
        }
        r = (this.isElementInList(this.currentLand) >= 0);
        return r;
    }

    checkDelDisabled(): boolean {
        let e = true;

        this.countries.forEach(z => {
            if (z.isSelected()) {
                e = false;
            }
        });
        return e;
    }

    removeSelected() {
        this.countries.forEach(z => {
            if (z.isSelected()) {
                this.removeElementFromList(z.data);
            }
        });
    }


}
