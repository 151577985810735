import {ChangeDetectorRef, Component, ElementRef, Injector, NgZone, OnInit} from '@angular/core';
import {CellFilterChangedCallback} from '../commons/data-table/filters/cell-filter.component';

import {LocalizedComponent} from '../commons/components/localized-component';
import {Produkt} from './produkte/common/produkt';

@Component({
    selector: 'produkt-cell-filter',
    styles: [`
        :host .add-on button:first-child {
            border-left: none;
        }

        :host .add-on button i {
            color: #888888;
        }

        :host ::ng-deep produkt-inline-field .form-group {
            margin: 0;
        }
    `],
    template: `
        <div class="input-group input-group-sm">
            <produkt-inline-field
                    #tf
                    [small]="true"
                    [label]="placeholder"
                    [value]="currentValue"
                    (valueChange)="tsChange($event)"
                    [nullable]="false"

            ></produkt-inline-field>
            <div class="input-group-btn add-on">
                <button type="button" class="btn btn-secondary btn-sm" (click)="clear()"
                        title="{{ _('Eingabe löschen') }}">
                    <i class="icofont icofont-eraser"></i>
                </button>
            </div>
        </div>
    `
})
export class ProduktCellFilterComponent extends LocalizedComponent implements OnInit {
    field: string = 'field' + (Math.random() * 10000);
    placeholder = '';
    currentValue: any = '';
    delay = 400;

    filterChanged: CellFilterChangedCallback<Produkt> = (field2, val) => {
    };

    constructor(
        public injector: Injector,
        public elementRef: ElementRef,
        public cd: ChangeDetectorRef,
        public zone: NgZone) {
        super(cd);
        this.field = this.injector.get('field');
        this.placeholder = this.injector.get('placeholder');
        this.currentValue = this.injector.get('value');
        this.filterChanged = this.injector.get('onChange');
    }

    tsChange(ts: Produkt) {
        this.updateValue(ts);
    }

    ngOnInit() {
    }


    clear() {
        this.zone.runOutsideAngular(() => {
            this.updateValue(null);
        });
    }


    updateValue(input: Produkt) {
        if ((input === null) || (input === undefined)) {
            this.currentValue = null;
        } else {
            this.currentValue = input
        }
        if ((this.filterChanged !== undefined) && (this.filterChanged !== null)) {
            this.filterChanged(this.field, this.currentValue);
        }
    }


}
