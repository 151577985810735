import {AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Output, ViewChild} from '@angular/core';
import {LocalizedComponent} from '../../../commons/components/localized-component';
import {IFilterDialog} from '../../../commons/data-table/ifilter-dialog';
import {AusweisFilter} from './ausweis-filter';
import {AusweisElementRendererComponent} from '../../ausweis-element-renderer.component';
import {FahrzeugElementRendererComponent} from '../../fahrzeug-element-renderer.component';
import {CustomerelementRendererComponent} from '../../customerelement-renderer.component';
import {StationElementRendererComponent} from '../../../stationen/station-element-renderer/station-element-renderer.component';
import {ElementListComponent} from '../../../commons/element-list/element-list.component';


@Component({
    selector: 'app-ausweis-filter-dialog',
    templateUrl: './ausweis-filter-dialog.component.html',
    styleUrls: ['./ausweis-filter-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush

})
export class AusweisFilterDialogComponent extends LocalizedComponent implements IFilterDialog, AfterViewInit {

    stationRenderer = StationElementRendererComponent;
    customerRenderer = CustomerelementRendererComponent;
    fahrzeugRenderer = FahrzeugElementRendererComponent;
    ausweisRenderer = AusweisElementRendererComponent;
    filter: AusweisFilter = new AusweisFilter(this.cd);

    @Output() filterChanged: EventEmitter<string> = new EventEmitter<string>();

    @ViewChild('stationen', {static: false}) stationen: ElementListComponent;
    @ViewChild('customers', {static: false}) customers: ElementListComponent;
    @ViewChild('fahrzeuge', {static: false}) fahrzeuge: ElementListComponent;
    @ViewChild('ausweise', {static: false}) ausweise: ElementListComponent;

    constructor(
        
        
        public cd: ChangeDetectorRef
    ) {
        super( cd);
        this.filter.cd = this.cd;
        this.filter.filterChanged.subscribe(
            () => {
                if (this.stationen !== null && this.stationen !== undefined) {
                    this.stationen.elements = this.filter.stationen;
                }
                if (this.customers !== null && this.customers !== undefined) {
                    this.customers.elements = this.filter.customers;
                }
                if (this.fahrzeuge !== null && this.fahrzeuge !== undefined) {
                    this.fahrzeuge.elements = this.filter.vehicles;
                }
                if (this.ausweise !== null && this.ausweise !== undefined) {
                    this.ausweise.elements = this.filter.cards;
                }

                this.markChanged();
            }
        );
    }

    ngAfterViewInit() {
        this.markChanged();
    }

    getQuery(): string {
        return this.filter.getQuery();
    }

    markChanged(visble = true): void {
        this.cd.markForCheck();
        if (visble) {
            this.filterChanged.emit(this.getQuery());
        } else {
            this.filterChanged.emit('');
        }
    }


}
