import {ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LoginComponent} from './loginform/login.component';
import {PasswordChangeDialogComponent} from './pwchanger/password-change-dialog.component';
import {BenutzerVerwaltungComponent} from './users/benutzer-verwaltung.component';
import {BenutzerEditorComponent} from './editor/benutzer-editor.component';
import {AuthModule} from '../auth/auth.module';
import {CommonsModule} from '../commons/commons.module';
import {CustomersModule} from '../customers/customers.module';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {FormsModule} from '@angular/forms';
import {ToastrModule} from 'ngx-toastr';
import {UserControlsModule} from '../user-controls/user-controls.module';
import {ProfilEditorComponent} from './profil-editor/profil-editor.component';
import {StationenModule} from '../stationen/stationen.module';

const components = [
    BenutzerEditorComponent,
    BenutzerVerwaltungComponent,
    LoginComponent,
    PasswordChangeDialogComponent,
    ProfilEditorComponent
];

@NgModule({
    imports: [
        CommonModule,
        NgbModule,
        FormsModule,
        ToastrModule.forRoot(),

        CommonsModule,
        AuthModule,
        CustomersModule,
        UserControlsModule,
        StationenModule
    ],
    declarations: [
        ... components
    ],
    exports: [
        ... components
    ],
    entryComponents: [
        ... components
    ]
})
export class UsersModule {


}
