import {IZapfsaeule} from './izapfsaeule';

export class Zapfsaeule implements IZapfsaeule {
    id = 0;
    stationId = 0;
    bezeichnung = '';
    nummer = 0;

    constructor() {
    }

    static fromResult(entity: IZapfsaeule, createIfNull = false): Zapfsaeule {
        if (entity === null || entity === undefined) {
            if (createIfNull) {
                return new Zapfsaeule();
            }
            return null;
        }
        const l: Zapfsaeule = new Zapfsaeule();
        for (const k of Object.keys(l)) {
            l[k] = (entity[k] !== undefined) ? entity[k] : '';
        }
        return l;
    }
}
