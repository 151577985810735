<div class="modal-header">
  <h5 class="modal-title" *ngIf="isAdd()">{{ _('Kostenstelle anlegen') }}</h5>
  <h5 class="modal-title" *ngIf="isEdit()">{{ _('Kostenstelle bearbeiten') }}</h5>
  <button type="button" class="close" aria-label="Close" (click)="cancel()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">

  <text-field [label]="_('Kostenstelle')" [(value)]="entity.kostenstelle"></text-field>
  <text-field [label]="_('Informationen')" [(value)]="entity.info"></text-field>
  <text-field [label]="_('Buchungskreis')" [(value)]="entity.buchungskreis"></text-field>

</div>
<div class="modal-footer">
  <button type="button" class="btn btn-secondary" (click)="cancel()">
    <i class="icofont icofont-close"></i> {{ _('Abbrechen') }}
  </button>
  <button type="button" class="btn btn-primary" (click)="save()">
    <i class="icofont icofont-save"></i>
    {{ _('Änderungen sichern') }}
  </button>
</div>
