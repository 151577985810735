<table cellspacing="2" cellpadding="2"
       *ngIf="_issuer!==null && _issuer!==undefined">
  <tr *ngIf="_issuer.nummer>0">
    <td>{{_('Nummer')}}</td>
    <td>{{ _issuer.nummer }}</td>
  </tr>
  <tr *ngIf="_issuer.bezeichnung!==undefined && _issuer.bezeichnung!==null && _issuer.bezeichnung!=''">
    <td>{{_('Bezeichnung')}}</td>
    <td>{{ _issuer.bezeichnung }}</td>
  </tr>

</table>
