<p>
    {{ _('Über die Freigaben können Sie steuern, in welchen Ländern oder an welchen ' +
    'Stationen Karten einsetzbar sein. Zusätzlich zu den hier eingestellten Bedingungen ' +
    'gelten auch die vom Kartenherausgeber eingestellten Regeln.') }}

    <span *ngIf="mode==='card'" class="font-weight-bold">
    {{ _('Hinweis! Die globalen Einstellungen gelten ebenfalls. Bitte achten Sie darauf, dass die hier eingetragenen ' +
    'Werte zu den globalen Einstellungen passen.') }}
</span>
    <span *ngIf="mode==='customer'" class="font-weight-bold">
    {{ _('Hinweis! Die zu den einzelnen Karten eingetragenen Einstellungen gelten ebenfalls. Bitte achten Sie darauf, ' +
    'dass die hier eingetragenen Werte zu den Karten-Einstellungen passen.') }}
</span>
</p>
<select-field [label]="_('Betriebsweise')" [value]="workmode" (valueChange)="setWorkmode($event)" [disabled]="disabled">
    <select-field-option [label]="(mode==='customer')?_('alle Karten immer zulassen'):_('diese Karte immer zulassen')"
                         [value]="0"></select-field-option>
    <select-field-option [label]="(mode==='customer')?_('alle Karten immer abweisen'):_('diese Karte immer abweisen')"
                         [value]="1"></select-field-option>
    <select-field-option
            [label]="(mode==='customer')?_('alle Karten nur in folgenden Ländern zulassen'):_('diese Karte nur in folgenden Ländern zulassen')"
            [value]="2"></select-field-option>
    <select-field-option
            [label]="(mode==='customer')?_('alle Karten nur an folgenden Stationen zulassen'):_('diese Karte nur an folgenden Stationen zulassen')"
            [value]="3"></select-field-option>
</select-field>


<div class="card" *ngIf="workmode===2">
    <div class="card-body">
        <h4 class="card-title">{{ _('Länderfreigaben')}}</h4>
        <div class="card-text">

            <div *ngIf="mode==='customer'" class="alert alert-info small">
                {{ _('Alle Karten sind nur in den hier angegebenen Ländern einsetzbar. Wird hier kein Land ausgewählt, '
                +
                'bedeutet dies, dass alle Karten in allen verfügbaren Ländern nutzbar sind.') }}
            </div>
            <div *ngIf="mode==='card'" class="alert alert-info small">
                {{ _('Diese Karte ist nur in den hier angegebenen Ländern einsetzbar. Wird hier kein Land ausgewählt, '
                +
                'bedeutet dies, dass diese Karte in allen verfügbaren Ländern nutzbar ist.') }}
            </div>


            <countries-selector
                    label=""
                    [labelwidth]="0"
                    [value]="_currentBegrenzung.laender"
                    (valueChanged)="setCountries($event)"
                    [disabled]="disabled"
            ></countries-selector>

        </div>
    </div>
</div>

<div class="card" *ngIf="workmode===3">
    <div class="card-body">
        <h4 class="card-title">{{ _('Stationsfreigaben')}}</h4>
        <div class="card-text">

            <div *ngIf="mode==='customer'" class="alert alert-info small">
                {{ _('Alle Karten sind nur an den hier angegebenen Stationen einsetzbar. Wird hier keine Station' +
                'ausgewählt, bedeutet dies, dass alle Karten in allen verfügbaren Stationen nutzbar sind.') }}
            </div>
            <div *ngIf="mode==='card'" class="alert alert-info small">
                {{ _('Diese Karte ist nur an den hier angegebenen Stationen einsetzbar. Wird hier keine Station' +
                'ausgewählt, bedeutet dies, dass diese Karte in allen verfügbaren Stationen nutzbar ist.') }}
            </div>

            <stationen-selector
                    label=""
                    [labelwidth]="0"
                    [value]="_currentBegrenzung.stationen"
                    (valueChanged)="setStationen($event)"
                    [disabled]="disabled"
            ></stationen-selector>

        </div>
    </div>
</div>
