<div *ngIf="entity!==null && entity!==undefined">
    <app-tabset>
        <app-pane [title]="_('Details')">
            <table class="table">
                <tr>
                    <th scope="row">{{_('Nummer')}}</th>
                    <td>{{entity.nummer }}</td>
                    <th scope="row">{{_('Rechnungsnummer')}}</th>
                    <td>{{ entity.rechnungNummer }}</td>
                </tr>
                <tr>
                    <th scope="row">{{ _('Rechnungsdatum')}}</th>
                    <td>{{fDate( entity.rechnungDatum ,'shortDate')}}</td>
                    <th scope="row">{{ _('fällig am')}}</th>
                    <td>{{fDate( entity.dueDatum ,'shortDate')}}</td>
                </tr>
                <tr>
                    <th scope="row">{{_('Endbetrag')}}</th>
                    <td>{{fCurrency( entity.summe ,currencyCode,'symbol','1.2-2' )}}</td>
                    <th scope="row">{{_('Status')}}</th>
                    <td><rechnung-status-display [status]="entity.status"></rechnung-status-display></td>
                </tr>
                <tr>
                    <th scope="row">{{ _('im System gesehen')}}</th>
                    <td>{{fDate( entity.readDatum ,'medium')}}</td>
                    <th scope="row">{{ _('Zahlungseingang')}}</th>
                    <td>{{fDate( entity.paidDatum ,'shortDate')}}</td>
                </tr>
            </table>
        </app-pane>
        <app-pane [title]="_('Dokumente')" *ngIf="entity.attachments.length>0">
            <ul class="list-group">
                <li *ngFor="let at of entity.attachments"
                   class="list-group-item justify-content-between"
                    >
                    <div title="{{ at.name }}">
                        <i class="{{ getIcon(at) }}"></i>
                        {{ shorten(at.name,85)}}
                    </div>
                    <div>
                        <span *ngIf="at.size>0">{{ bytes( at.size ) }} &nbsp;</span>
                        <button class="btn btn-default btn-secondary"
                           (click)="downloadAttachment(at)"
                           title="{{ at.name }}">
                            <i class="icofont icofont-download-alt"></i> {{ _('Herunterladen')}}
                        </button>
                    </div>
                </li>
            </ul>
        </app-pane>
        <app-pane *ngIf="entity.kunde!==null && entity.kunde!==undefined" [title]="_('Kundendaten')">
            <app-kunde-display [kunde]="entity.kunde"></app-kunde-display>
        </app-pane>
    </app-tabset>
</div>