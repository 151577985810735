import {AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {NgbActiveModal, NgbModal, NgbModalOptions, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import {ToastrService} from 'ngx-toastr';
import {METool} from '../../../../shared/metool';
import {LoginService} from '../../../auth/services/login.service';
import {ActionResponse, IActionResponse} from '../../../commons/action-response';
import {LocalizedComponent} from '../../../commons/components/localized-component';
import {ConfirmDialogComponent} from '../../../commons/confirm-dialog/confirm-dialog.component';
import {AddOnClickEvent} from '../../../commons/dynamic-form/add-on-click-event';
import {MESelectSearchEvent} from '../../../commons/dynamic-form/me-select/meselect-search-event';
import {PreisHelper} from '../../../pricing/common/preis-helper';
import {PreiseService} from '../../../pricing/services/preise.service';
import {Station} from '../../../stationen/common/station';
import {StationenService} from '../../../stationen/common/stationen.service';
import {TankFieldComponent} from '../../../stationen/tank-field.component';
import {Tank} from '../../../stationen/tanks/common/tank';
import {TankBestandService} from '../../bestand/service/tank-bestand.service';
import {Lieferschein} from '../common/lieferschein';

import {LieferscheinService} from '../common/lieferschein.service';

@Component({
    selector: 'app-lieferschein-editor',
    templateUrl: './lieferschein-editor.component.html',
    styleUrls: ['./lieferschein-editor.component.scss']
})
export class LieferscheinEditorComponent extends LocalizedComponent implements OnInit, AfterViewInit {
    bestand = 0;
    preis = 0;
    neuPreis = 0;
    _storing = false;

    @ViewChild('tankfield', {static: true}) tankfield: TankFieldComponent;

    entity: Lieferschein = new Lieferschein();
    mode = 'add';

    constructor(
        public activeModal: NgbActiveModal,
        public toastr: ToastrService,
        public cd: ChangeDetectorRef,
        public lieferscheinService: LieferscheinService,
        public tankbestandService: TankBestandService,
        public preisService: PreiseService,
        public loginService: LoginService,
        public stationService: StationenService,
        public modalService: NgbModal
    ) {
        super(cd);
    }

    get literpreis() {
        if (this.entity === null || this.entity === undefined) {
            return 0;
        }

        if (this.entity.zugang === null || this.entity.zugang === undefined) {
            return 0;
        }
        if (this.entity.wert === null || this.entity.wert === undefined) {
            return 0;
        }

        if (this.entity.zugang === 0) {
            return 0;
        }

        return parseFloat('' + this.entity.wert) / parseFloat('' + this.entity.zugang);
    }

    get bestandNeu() {
        if (this.entity === null || this.entity === undefined) {
            return parseFloat('' + this.bestand);
        }

        if (this.entity.zugang === null || this.entity.zugang === undefined) {
            return parseFloat('' + this.bestand);
        }
        return parseFloat('' + this.bestand) + parseFloat('' + this.entity.zugang);
    }

    get restWert() {
        return parseFloat('' + this.bestand) * parseFloat('' + this.preis);
    }

    get gesamtWert() {
        if (this.entity === null || this.entity === undefined) {
            return parseFloat('' + this.bestand) * parseFloat('' + this.preis);
        }
        if (this.entity.wert === null || this.entity.wert === undefined) {
            return parseFloat('' + this.bestand) * parseFloat('' + this.preis);

        }
        return (parseFloat('' + this.bestand) * parseFloat('' + this.preis)) + parseFloat('' + this.entity.wert);
    }

    get literpreisNeu() {
        const b = parseFloat('' + this.bestandNeu);
        if (b === 0) {
            return 0;
        }
        return Math.floor(parseFloat('' + this.gesamtWert) * 1000.0 / b) / 1000.0;
    }

    public get has_errors() {
        if (METool.isNullOrUndefined(this.entity)) {
            console.error('entity is null');
            return true;
        }
        if (METool.isNullOrUndefined(this.entity.station)) {
            console.error('station is null');
            return true;
        }
        if (METool.isNullOrUndefined(this.entity.tank)) {
            console.error('tank is null');
            return true;
        }
        if (this.entity.tank.stationId !== this.entity.station.id) {
            console.debug(this.entity.tank, this.entity.station);
            console.error('tank station id is not equal to station id');
            return true;
        }
        if (!METool.isNullOrUndefined(this.entity.produkt)) {
            if (!METool.isNullOrUndefined(this.entity.tank.produkt)) {
                if (this.entity.produkt.id !== this.entity.tank.produkt.id) {
                    console.error('tank produkt id is not equal to produkt id');
                    return true;
                }
            }
        }
        return false;
    }

    static open(modalService: NgbModal, lieferschein: Lieferschein = null): Promise<any> {
        const o: NgbModalOptions = {
            backdrop: 'static',
            keyboard: false
        };
        const r: NgbModalRef = modalService.open(LieferscheinEditorComponent, o);
        (r.componentInstance as LieferscheinEditorComponent).open(lieferschein);
        return r.result;
    }

    ngAfterViewInit(): void {
        console.log(this.tankfield);
    }

    ngOnInit() {
    }

    open(lieferschein: Lieferschein = null) {
        if (lieferschein === null || lieferschein === undefined) {
            this.mode = 'add';
            this.entity = new Lieferschein();
            this.entity.datum = moment().toDate();
        } else {
            this.mode = 'edit';
            this.entity = Lieferschein.fromResult(lieferschein);
            if (this.entity.station !== null &&
                this.entity.station !== undefined) {
                this.stationService
                    .loadStation(this.entity.station.id)
                    .subscribe((s) => {
                        this.entity.station = Station.fromResult(s);
                        this.cd.markForCheck();
                    });
            }
        }
    }

    isAdd(): boolean {
        return this.mode.toLowerCase() === 'add';
    }

    isEdit(): boolean {
        return this.mode.toLowerCase() === 'edit';
    }

    cancel() {
        this.activeModal.dismiss('cancel');
    }

    save() {
        this._storing = true;
        this.lieferscheinService
            .store(this.entity, this.isAdd())
            .subscribe(r => {
                    this._storing = false;
                    if (r.success) {
                        this.activeModal.close(this.entity);
                    } else {
                        this.toastr.error(this._('Fehler beim Speichern der Lieferscheindaten'));
                    }
                },
                () => {
                    this._storing = false;
                    this.toastr.error(this._('Fehler beim Speichern der Lieferscheindaten'));
                }
            );
    }

    setStation($event: Station) {

        if (this.entity.station === $event) {
            return;
        }

        if ($event === null || $event === undefined) {
            this.entity.tank = null;
        } else {
            if (this.entity.tank !== null && this.entity.tank !== undefined) {
                if (this.entity.tank.stationId !== $event.id) {
                    this.entity.tank = null;
                }
            }
        }

        this.entity.station = $event;


        if (this.tankfield !== null && this.tankfield !== undefined) {
            if (METool.isNullOrUndefined(this.entity.tank) || this.entity.tank.stationId != this.entity.station.id) {
                this.tankfield.clearList();
                this.tankfield.station = this.entity.station;
                this.tankfield.doSearch(new MESelectSearchEvent('', null), true);
                this.tankfield.doSelect(this.entity.tank);
            }
        }

        this.cd.markForCheck();

    }

    setTank($entity: Tank) {

        if (this.entity.tank === $entity) {
            return;
        }


        if ($entity === null || $entity === undefined) {
            this.entity.produkt = null;
        } else {
            this.entity.produkt = $entity.produkt;
        }
        this.entity.tank = $entity;
    }

    requestData() {
        this.toastr.info(this._('Preise und Bestand werden ermittelt...'));
        this.tankbestandService
            .getBestandPerMomentAndTank(this.entity.tank, this.entity.lieferDatum)
            .subscribe((r: IActionResponse) => {
                if (r.success) {
                    const ar = ActionResponse.fromRawActionResponse(r);
                    this.bestand = ar.params['bestand'] || 0;
                    this.cd.markForCheck();
                    this.updateNeuPreis();
                } else {
                    this.toastr.error(this._('Der aktuelle Tankbestand konnte nicht ermittelt werden.'));
                }
            });

        this.preisService.getPreis(this.entity.station,
            this.entity.tank,
            null,
            this.entity.produkt,
            this.entity.lieferDatum)
            .subscribe((r: IActionResponse) => {
                if (r.success) {
                    const ar = ActionResponse.fromRawActionResponse(r);
                    if (ar.params.preis.istBrutto && this.entity.isNetto) {
                        this.preis = ar.params.preis.wert / 1.19;
                    } else if (!ar.params.preis.istBrutto && !this.entity.isNetto) {
                        this.preis = ar.params.preis.wert * 1.19;
                    } else {
                        this.preis = ar.params.preis.wert;
                    }
                    this.cd.markForCheck();
                    this.updateNeuPreis();

                } else {
                    this.toastr.error(this._('Der zum Lieferzeitpunkt aktuelle Preis konnte nicht ermittelt werden.'));
                }
            });

    }

    updateNeuPreis() {
        this.neuPreis = this.literpreisNeu;
        this.cd.markForCheck();
    }

    addOnClick(event: AddOnClickEvent) {
        if ('neuPreisFeld' === event.component.id) {
            if (event.addon === 0) {
                this.updateNeuPreis();
            }
        }
    }

    submitPrice() {
        if (this.neuPreis !== null && this.neuPreis !== undefined && this.neuPreis > 0) {
            const p1 = new PreisHelper();
            p1.currency = this.entity.station.currency;
            p1.gueltigAb = moment(this.entity.lieferDatum).toISOString();
            p1.istBrutto = !this.entity.isNetto;
            p1.produkt = this.entity.produkt;
            p1.wert = this.neuPreis;

            const pr: PreisHelper[] = [];
            pr.push(p1);

            this.preisService
                .storeTsPreis(this.entity.station, null, pr)
                .subscribe(
                    (r: IActionResponse) => {
                        if (r.success) {
                            this.toastr.success(
                                this._('Möglicherweise ist eine Neubewertung von bestehenden Transaktionen notwendig.'),
                                this._('Der neue Preis wurde festgelegt')
                            );
                        } else {
                            this.toastr.error(this._('Fehler beim Übertrages des Preises'));
                        }
                    });
        }
    }

    hasPreisfindung() {
        if (!this.loginService.hasOneOfRoles(['ROLE_ADMIN', 'ROLE_PROJECT_ADMIN', 'ROLE_BILLING', 'ROLE_PRICING'])) {
            return false;
        }
        return ((this.entity.produkt !== null) &&
            (this.entity.produkt !== undefined) &&
            (this.entity.produkt.id > 0) &&
            (this.entity.wert !== null) &&
            (this.entity.wert !== undefined) &&
            (this.entity.wert > 0) &&
            (this.entity.zugang !== null) &&
            (this.entity.zugang !== undefined) &&
            (this.entity.zugang > 0));
    }

    public remove() {
        this._storing = true;
        ConfirmDialogComponent.display(
            this.modalService,
            this._('Lieferschein löschen'),
            this._('Soll dieser Lieferschein wirklich unwiderruflich gelöscht werden?'),
            'Ja, Lieferschein jetzt löschen',
            'fa far fa-trash',
            null
        ).result.then(() => {
            this.lieferscheinService.delete(this.entity).subscribe(
                (r) => {
                    this._storing = false;
                    if (r.success) {
                        this.toastr.success(this._('Der Lieferschein wurde gelöscht.'));
                    } else {
                        const ar = ActionResponse.fromRawActionResponse(r);
                        this.toastr.error(this._('Beim Löschen des Lieferscheins ist ein Fehler aufgetreten. {m}', {m: ar.getMessage()}));
                    }
                },
                () => {
                    this._storing = false;
                    this.toastr.error(this._('Beim Löschen des Lieferscheins ist ein Fehler aufgetreten.'));
                }
            );

        }, () => {
            this._storing = false;
        });
    }

    public canSave() {
        if (METool.isNullOrUndefined(this.entity)) {
            return false;
        }
        if (METool.isNullOrUndefined(this.entity.station)) {
            return false;
        }
        if (METool.isNullOrUndefined(this.entity.tank)) {
            return false;
        }
        if (this.has_errors) {
            return false;
        }
        if (this._storing) {
            return false;
        }
        return true;
    }

    public hasSavedEntity() {
        return !METool.isNullOrUndefined(this.entity) && !METool.isEmpty(this.entity.id);
    }
}
