import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {Person} from '../common/person';
import {LocalizedComponent} from '../../../commons/components/localized-component';


@Component({
  selector: 'app-person-quick-info',
    template : `
        <me-info-tool
                [title]="getTitle()"
                title-icon="far fa-car"
                [position]="info_position"
                *ngIf="_person!==null">
            <app-person-info [person]="_person"></app-person-info>
        </me-info-tool>
        <div *ngIf="showLabel && _person!==null && _person!==undefined "
             title="{{ _person.name }}"
             style="display:inline-block;">
            <i class="far fa-users"></i>
            {{ _person.nummer }}
        </div>

    `
})
export class PersonQuickInfoComponent extends LocalizedComponent implements OnInit {

  _person: Person = null;

  @Input()
  set person(value: Person) {
    this._person = Person.fromResult(value);
  }

  @Input() showLabel = false;
  @Input() info_position = 'right';


  getTitle() {
    if (this._person !== null) {
      return this._(' Person {{ nummer }} ', {nummer: this._person.nummer});
    }
    return '';
  }

  constructor(

              public cd: ChangeDetectorRef) {
    super( cd);
  }

  ngOnInit() {
  }

}
