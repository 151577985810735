import {Component, InjectionToken, Injector, OnInit} from '@angular/core';
import {IKunde} from './common/ikunde';
import {DataTableRow} from '../../commons/data-table/data-table-row';
import {DataTableColumnDirective} from '../../commons/data-table/data-table-column.directive';

@Component({
               selector: 'kunde-cell-renderer',
               template: `
                   <app-kunde-quick-info
                           *ngIf="cell!==undefined && cell!==null"
                           [info_position]="info_position"
                           [kunde]="cell"
                           [showLabel]="true"
                   ></app-kunde-quick-info>
               `
           })
export class KundeCellRendererComponent {

    info_position           = 'right';
    cell: IKunde            = null;
    row: DataTableRow<any>  = null;
    column: DataTableColumnDirective = null;

    constructor(public injector: Injector) {
        this.cell   = this.injector.get('cell');
        this.row    = this.injector.get('row');
        this.column = this.injector.get('column');

        if (this.column !== null && this.column !== undefined) {
            if ((this.column.cfg !== null) && (this.column.cfg !== undefined)) {
                if (this.column.cfg.info_position !== null) {
                    this.info_position = this.column.cfg.info_position;
                }
            }
        }

    }


}
