import {Component, EventEmitter, Input, NgZone, OnInit, Output} from '@angular/core';
import {ArtikelGruppe} from './artikelgruppen/common/artikel-gruppe';

@Component({
               selector: 'artikel-gruppe-field',
               template: `
                   <div class="form-group row">
                       <label for="{{ id }}" class="col-{{ labelwidth }} col-form-label" *ngIf="labelwidth>0">
                           {{ label }}
                       </label>
                       <div class="col-{{ 12-labelwidth }}">
                           <artikel-gruppe-inline-field
                                   id="{{ id }}"
                                   [label]="label"
                                   [value]="_value"
                                   (valueChange)="doSelect($event)"
                                   [small]="small"
                                   [nullable]="nullable"
                                   [major]="major"
                                   [minor]="minor"
                           ></artikel-gruppe-inline-field>
                       </div>
                   </div>
               `
           })
export class ArtikelGruppeFieldComponent implements OnInit {
    @Input() nullable = true;
    @Input() small = false;
    public _value: ArtikelGruppe = null;
    @Input() label = 'ArtikelGruppefeld';
    @Input() id = '';
    @Input() labelwidth = 3;
    @Input() minor = true;
    @Input() major = true;

    @Input('value')
    set value(v: ArtikelGruppe) {
        if (this._value !== v) {
            this._value = v;
            this.valueChange.emit(v);
        }
    }

    @Output() valueChange = new EventEmitter<ArtikelGruppe>();


    get value(): ArtikelGruppe {
        return this._value;
    }

    constructor(public _ngZone: NgZone) {
    }

    ngOnInit() {
    }

    doSelect(artikelGruppe: ArtikelGruppe) {
        this._value = artikelGruppe;
        this.valueChange.emit(artikelGruppe);
    }

}
