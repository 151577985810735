import {GenericFilter} from '../../../commons/generic-filter';
import {ArtikelGruppe} from './artikel-gruppe';
import {IArtikelGruppe} from './iartikel-gruppe';

export class SimpleArtikelGruppeFilter extends GenericFilter<ArtikelGruppe> implements IArtikelGruppe {
    id: number = null;
    bezeichnung: string = null;
    showMinor: boolean = null;
    showMajor: boolean = null;
}
