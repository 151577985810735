<div *ngIf="ausweisAudit!==null && ausweisAudit!==undefined && _ausweis!==null && _ausweis!==undefined">
    <div class="alert alert-info">
        {{_('Sie sehen hier nur die Änderungen an diesem Ausweis-Stammdatensatz. '+
        ' Änderungen an den Ausweisen sind zu jedem Ausweis erfasst')}}
    </div>
    <data-table
            [selectable]="false"
            [data]="ausweisAudit.asDataTableRows()"
            [orders]="ausweisAudit.order"
            [filter]="ausweisAudit.simpleFilter"
            [hideCheckboxes]="true"
            [containerHeight]="300"
    >

        <column
                [field]="'dateCreated'"
                [label]="_('Zeitpunkt')"
                type="Date"
                [cfg]="{format:'medium'}"
        ></column>
        <column
                [field]="'uname'"
                [label]="_('Benutzername')"
                type="text"
        ></column>
        <column
                [field]="'info'"
                [label]="_('Info')"
                type="text"
        ></column>
        <data-table-table-extra-header>
            <app-paginator
                    [total]="ausweisAudit.total"
                    [page]="ausweisAudit.page"
                    [perpage]="ausweisAudit.size"
                    (pageChange)="updateAusweisAudit(true,$event)"
                    (pageCountChange)="updateAusweisAuditPageCount($event)"
            ></app-paginator>
        </data-table-table-extra-header>

    </data-table>
</div>