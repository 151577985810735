import {INachricht} from './inachricht';
import {ENachrichtPrioritaet} from './enachricht-prioritaet';
import {ConverterTool} from '../../../../shared/converter-tool';

export class Nachricht implements INachricht {
    prioritaet: ENachrichtPrioritaet;
    id: number;
    subject: string;
    message: string;
    params: any;
    dateCreated: Date;
    dateRead: Date;
    dateExpires: Date;

    constructor(id = 0,
                subject = '',
                message = '',
                params: any = null,
                dateCreated: any = null,
                dateRead: any = null,
                dateExpires: any = null,
                prioritaet = ENachrichtPrioritaet.UNKNOWN) {
        this.id = id;
        this.subject = subject;
        this.message = message;
        this.params = params;
        this.dateCreated = ConverterTool.toDate(dateCreated);
        this.dateRead = ConverterTool.toDate(dateRead);
        this.dateExpires = ConverterTool.toDate(dateExpires);
        this.prioritaet = prioritaet;
    }

    get read(): boolean {
        return this.isRead()
    }

    static fromResult(entity: INachricht): Nachricht {
        if ((entity === null) || (entity === undefined)) {
            return null;
        }

        let l: Nachricht = new Nachricht();
        for (let k of Object.keys(l)) {
            if (k === 'dateCreated') {
                l.dateCreated = ConverterTool.toDate(entity.dateCreated);
            } else if (k === 'dateRead') {
                l.dateRead = ConverterTool.toDate(entity.dateRead);
            } else if (k === 'dateExpires') {
                l.dateExpires = ConverterTool.toDate(entity.dateExpires);
            } else if (k === 'params') {
                l.params = {};
                if (entity.params !== null && entity.params !== undefined && entity.params !== '') {
                    if (typeof entity.params === 'string' || entity.params instanceof String) {
                        l.params = JSON.parse('' + entity.params);
                    } else {
                        l.params = entity.params;
                    }
                }

                try {
                    for (let h of Object.keys(l.params)) {
                        if (typeof l.params[h] === 'string' || l.params[h] instanceof String) {
                            l.params[h] = ('' + l.params[h]).replace(',', ', ');
                        }
                    }
                } catch (e) {
                    // do nothing
                }

            } else if (k === 'prioritaet') {
                if (('' + entity.prioritaet) === 'UNKNOWN') {
                    l.prioritaet = ENachrichtPrioritaet.UNKNOWN;
                } else if (('' + entity.prioritaet) === 'LOWEST') {
                    l.prioritaet = ENachrichtPrioritaet.LOWEST;
                } else if (('' + entity.prioritaet) === 'LOW') {
                    l.prioritaet = ENachrichtPrioritaet.LOW;
                } else if (('' + entity.prioritaet) === 'NORMAL') {
                    l.prioritaet = ENachrichtPrioritaet.NORMAL;
                } else if (('' + entity.prioritaet) === 'HIGH') {
                    l.prioritaet = ENachrichtPrioritaet.HIGH;
                } else if (('' + entity.prioritaet) === 'HIGHEST') {
                    l.prioritaet = ENachrichtPrioritaet.HIGHEST;
                } else if (!isNaN(parseInt('' + entity.prioritaet, 10))) {
                    l.prioritaet = parseInt('' + entity.prioritaet, 10);
                } else {
                    l.prioritaet = ENachrichtPrioritaet.UNKNOWN
                }
            } else {
                l[k] = (entity[k] !== undefined) ? entity[k] : '';
            }
        }

        if (l.subject === null) {
            l.subject = '';
        }
        if (l.message === null) {
            l.message = '';
        }

        try {
            for (let h of Object.keys(l.params)) {
                if (typeof l.params[h] === 'string' || l.params[h] instanceof String) {
                    l.params[h] = ('' + l.params[h]).replace(/,/gi, ', ');
                }
            }
        } catch (e) {
            // do nothing
        }

        return l;
    }

    isRead(): boolean {
        return !((this.dateRead === null) || (this.dateRead === undefined))
    }
}
