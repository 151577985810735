<app-tabset>
    <app-pane [title]="_('Zeitbereich')">
        <div class="d-flex">
            <div class="mr-3">
                <boolean-field [label]="_('nach Zeitbereich filtern')"
                               [labelwidth]="10"
                               [(value)]="filter.useTimeRange"
                               [flip]="true"
                               [inline]="true"
                ></boolean-field>

                <date-time-field *ngIf="filter.useTimeRange"
                                 [label]="_('Anfang')"
                                 [(value)]="filter.timeFrom"
                ></date-time-field>

                <date-time-field *ngIf="filter.useTimeRange"
                                 [label]="_('Ende')"
                                 [(value)]="filter.timeUntil"
                ></date-time-field>
            </div>
            <div class="d-flex" style="border-left: 1px solid #eeeeee;padding-left: 5px;" *ngIf="filter.useTimeRange">
                <div class="list-group">
                    <a class="list-group-item list-group-item-action" (click)="filter.today()">
                        {{_('heute')}}
                    </a>
                    <a class="list-group-item list-group-item-action" (click)="filter.yesterday()">
                        {{_('gestern')}}
                    </a>
                    <a class="list-group-item list-group-item-action" (click)="filter.thisWeek()">
                        {{_('diese Woche')}}
                    </a>
                    <a class="list-group-item list-group-item-action" (click)="filter.lastWeek()">
                        {{_('letzte Woche')}}
                    </a>
                </div>
                <div class="list-group">
                    <a class="list-group-item list-group-item-action" (click)="filter.lastThreeDays()">
                        {{_('die letzten 3 Tage')}}
                    </a>
                    <a class="list-group-item list-group-item-action" (click)="filter.lastSevenDays()">
                        {{_('die letzten 7 Tage')}}
                    </a>
                    <a class="list-group-item list-group-item-action" (click)="filter.thisMonth()">
                        {{_('aktueller Monat')}}
                    </a>
                    <a class="list-group-item list-group-item-action" (click)="filter.lastMonth()">
                        {{_('Vormonat')}}
                    </a>
                </div>
            </div>
        </div>
    </app-pane>
    <app-pane [title]="_('Stationen')">
        <element-list [elements]="filter.stationen"
                      #stationen
                      [renderer]="stationRenderer"
                      (onClearAll)="filter.clearStationen()"
                      (onRemoveElement)="filter.removeStation($event)"
        >
            <div class="header w-50">
                <station-field [label]="_('Station hinzufügen')"
                               (valueChange)="filter.addStation($event)"
                ></station-field>
            </div>
        </element-list>
    </app-pane>
    <app-pane [title]="_('Produkte')">
        <element-list [elements]="filter.products"
                      #produkte
                      [renderer]="produktRenderer"
                      (onClearAll)="filter.clearProducts()"
                      (onRemoveElement)="filter.removeProduct($event)"
        >
            <div class="header w-50">
                <produkt-field [label]="_('Produkt hinzufügen')"
                               (valueChange)="filter.addProduct($event)"
                ></produkt-field>
            </div>
        </element-list>
    </app-pane>

    <app-pane [title]="_('Freitext')">
        <div>
            {{ _('Hier können Sie beliebige Suchanfragen formulieren:')}}
        </div>
        <text-field [label]="_('Suchen nach')"
                    [labelwidth]="2"
                    [(value)]="filter.freitext"
                    [nullable]="true"
                    [multiline]="true"></text-field>
    </app-pane>

</app-tabset>
