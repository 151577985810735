import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {Attachment} from '../../common/attachment';

import {LocalizedComponent} from '../../../../commons/components/localized-component';

@Component({
    selector: 'app-attachments-quick-info',
    template: `
        <div *ngIf="attachments.length==1">
            <app-attachment-info [attachment]="attachments[0]"></app-attachment-info>
        </div>
        <div *ngIf="attachments.length>1">
            <me-info-tool
                    [title]="getTitle()"
                    [title_icon]="'icofont icofont-download-alt'"
                    [position]="info_position"
            >
                <ul class="list-group">
                    <li *ngFor="let a of attachments"
                        class="list-group-item"
                    >
                        <app-attachment-info [attachment]="a"></app-attachment-info>
                    </li>
                </ul>
            </me-info-tool>
            {{ multipleDocsLabel}}
        </div>
    `

})
export class AttachmentsQuickInfoComponent extends LocalizedComponent implements OnInit {

    @Input() showLabel = true;

    @Input() attachments: Attachment[] = [];

    @Input() info_position = 'left';

    get multipleDocsLabel() {
        return this._('{{ n }} Dokumente', {n: this.attachments.length});
    }

    constructor(

                public cd: ChangeDetectorRef) {
        super( cd);
    }

    ngOnInit() {
    }

    getTitle() {
        return this._(this.multipleDocsLabel, {n: this.attachments.length})
    }
}
