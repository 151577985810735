import {Component, EventEmitter, Input, NgZone, OnInit, Output, ViewChild} from '@angular/core';
import {
    MEHighlitePipe,
    MESelectComponent,
    MESelectItemMatcherCallback,
    MESelectItemTextCallback
} from '../commons/dynamic-form/me-select/meselect.component';

import {MESelectItemFormatterCallback} from '../commons/dynamic-form/me-select/meselect-item-formatter-callback';
import {SondeList} from './sonden/sonde-list';
import {Sonde} from './sonden/sonde';
import {Station} from './common/station';
import {StationenService} from './common/stationen.service';
import {MESelectSearchEvent} from '../commons/dynamic-form/me-select/meselect-search-event';
import {IListResult} from '../commons/list-result';
import {ISonde} from './sonden/isonde';
import CryptoES from "crypto-es";

@Component({
    selector: 'sonde-field',
    template: `
        <div class="form-group row">
            <label for="{{ id }}" class="col-{{ labelwidth }} col-form-label" *ngIf="labelwidth>0">
                {{ label }}
            </label>
            <div class="col-{{ 12-labelwidth }}">
                <meselect
                        id="{{ id }}"
                        [idField]="'id'"
                        [items]="sonden?.data"
                        [asMatrix]="false"
                        [placeholder]="label"
                        (itemSelected)="doSelect($event)"
                        (needsSearch)="doSearch($event)"
                        [small]="small"
                        [nullable]="nullable"
                >

                </meselect>

            </div>
        </div>

    `,
    styles: [`

        :host .addon button:first-child {
            border-left: none;
            padding: 0 5px;
        }

    `]
})
export class SondeFieldComponent implements OnInit {
    @Input() nullable = true;
    @ViewChild(MESelectComponent, {static: true}) sondenMESelect: MESelectComponent;
    @Input() small = false;

    public sonden: SondeList = new SondeList();
    @Input() label = 'Textfeld';
    @Input() id = '';
    @Input() labelwidth = 3;
    @Input() maxDisplaySonden = 50;

    @Input() station: Station = null;
    @Output() valueChange = new EventEmitter<Sonde>();

    constructor(public stationenService: StationenService,
                public _ngZone: NgZone) {
    }

    public _value: Sonde = null;

    get value(): Sonde {
        return this._value;
    }

    @Input()
    set value(v: Sonde) {

        if (this._value !== v) {
            this._value = v;
            this.valueChange.emit(v);
        }
    }

    ngOnInit() {
        this.doSearch(new MESelectSearchEvent('', this.sondenMESelect));

        this.sondenMESelect.onFormat = this.itemFormat;
        this.sondenMESelect.onGetText = this.itemText;
        this.sondenMESelect.onMatch = this.itemMatcher;
        this.sondenMESelect.value = this._value;
        if (this.id === '') {
            this.id = 'tf' + CryptoES.MD5('textfield-' + this.label + Math.random() + '-' + Math.random()).toString();
        }
    }

    doSearch(value: MESelectSearchEvent) {


        this
            .stationenService
            .listSonden(this.station, this.maxDisplaySonden, 0, [], null, (value === null || value === undefined) ? '' : value.queryString.trim())
            .subscribe(
                (c: IListResult<ISonde>) => {
                    this.sonden.populateFromListResult(c);
                }
            );
    }

    doSelect(sonde: Sonde) {
        this._value = sonde;
        this.valueChange.emit(sonde);
    }

    public itemFormat: MESelectItemFormatterCallback<Sonde> = (item: Sonde, search) => {
        if (item === null) {
            return '';
        }
        return '<i class="me-icon me-icon-sonde"></i> ' + MEHighlitePipe.transformString(item.bezeichnung, search) +
            ' <span class=\'badge-default badge\'>' + MEHighlitePipe.transformString('' + item.nummer, search) + '</span>';
    };

    public itemText: MESelectItemTextCallback<Sonde> = (item: Sonde) => {
        if (item === null) {
            return '';
        }
        return item.bezeichnung + ' [' + item.nummer + ']';
    };

    public itemMatcher: MESelectItemMatcherCallback<Sonde> = (item: Sonde, search) => {
        if (!search) {
            return true;
        }
        if (search.trim() === '') {
            return true;
        }
        if (!item) {
            return false;
        }
        const x = item.bezeichnung + ' ' + item.nummer;

        return (x.toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) >= 0);
    };


}
