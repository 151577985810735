<div class="modal-header">
    <h4 class="modal-title" *ngIf="isAdd()">{{ _('Benutzer anlegen') }}</h4>
    <h4 class="modal-title" *ngIf="isEdit()">{{ _('Benutzer bearbeiten') }}</h4>
    <button type="button" class="close" aria-label="Close" (click)="cancel()">
        <span aria-hidden="true">&times;</span>
    </button>

</div>
<div class="modal-body">
    <app-tabset>
        <app-pane [title]="_('Stammdaten')">
            <text-field [label]="_('Benutzerkennung')" [(value)]="entity.username"></text-field>
            <text-field [label]="_('vollständiger Name')" [(value)]="entity.fullname"></text-field>
            <text-field [label]="_('Email-Adresse')" [(value)]="entity.email"></text-field>
            <boolean-field [label]="_('Kopie der Mitteilungen als Email senden')"
                           [(value)]="entity.emailsEnabled"></boolean-field>
            <boolean-field [label]="_('Zugang gesperrt')" [(value)]="entity.accountLocked"></boolean-field>
            <boolean-field [label]="_('Account verborgen')" [(value)]="entity.hidden" *appIsAdmin></boolean-field>
        </app-pane>
        <app-pane [title]="_('Kennwort')">
            <div *ngIf="isEdit()" class="alert alert-info">
                {{ _('Bitte tragen Sie hier nur ein Kennwort ein, wenn dieses geändert werden soll. ')}}
            </div>
            <password-field [label]="_('neues Kennwort')" [labelwidth]="4" [(value)]="entity.newPW"></password-field>
            <password-field [label]="_('neues Kennwort wiederholen')" [labelwidth]="4"
                            [(value)]="entity.newPW2"></password-field>
            <div *ngIf="isEdit()">
                <date-time-field
                        [label]="_('letzte Kennwortänderung')"
                        [labelwidth]="4"
                        [disabled]="true"
                        [(value)]="entity.lastPasswordChange"
                ></date-time-field>
            </div>
        </app-pane>
        <app-pane [title]="_('Zuordnung')" *ngIf="hasRole('ROLE_CUSTOMER')">
            <app-tabset>
                <app-pane [title]="_('für Kundenlogin')" *ngIf="hasRole('ROLE_CUSTOMER')">
                    <kunde-field [label]="_('Kunde')" [(value)]="entity.kunde"></kunde-field>
                    <app-kunde-display [kunde]="entity.kunde"></app-kunde-display>
                </app-pane>
            </app-tabset>
        </app-pane>
        <app-pane [title]="_('Berechtigungen')">

            <memulti-select [(value)]="entity.permissions">
                <memulti-select-item value="ROLE_CUSTOMER"
                                     [rulesOut]="['ROLE_USER','ROLE_TRANSAKTIONEN','ROLE_KARTENANFRAGEN','ROLE_FAHRZEUGE','ROLE_AUSWEISE','ROLE_PRICING','ROLE_BILLING','ROLE_PROJECT_ADMIN']">
                    <h6 class="mb-1">{{ _("Kunden-Login")}}</h6>
                    <small>
                        {{ _('Dieser Zugang erlaubt es einem Kunden, sich am System anzumelden. ' +
                        'Sämtliche Listen sind auf den Kunden beschränkt.')}}
                    </small>
                </memulti-select-item>
                <memulti-select-item value="ROLE_USER" [rulesOut]="['ROLE_CUSTOMER']">
                    <h6 class="mb-1">{{ _("Benutzer-Login") }}</h6>
                    <small>
                        {{ _("Dieser Benutzer hat gegenüber dem Kunden erweiterte Ansichten, " +
                        "hat aber keinen Zugriff auf finanzielle Einstellmöglichkeiten")}}
                    </small>
                </memulti-select-item>
                <memulti-select-item value="ROLE_PRICING" [implies]="'ROLE_USER'"
                                     [rulesOut]="['ROLE_CUSTOMER','ROLE_ADM','ROLE_BESTAND']">
                    <h6 class="mb-1">{{ _("Preisvorgaben") }}</h6>
                    <small>
                        {{ _('Dieser Benutzer darf Preise festlegen ')}}
                    </small>
                </memulti-select-item>
                <memulti-select-item value="ROLE_BILLING" [implies]="'ROLE_USER'"
                                     [rulesOut]="['ROLE_CUSTOMER','ROLE_BESTAND']">
                    <h6 class="mb-1">{{ _("Buchhaltung") }}</h6>
                    <small>
                        {{ _('Dieser Benutzer darf Rechnungen bereitstellen, Limitierungen ändern')}}
                    </small>
                </memulti-select-item>
                <memulti-select-item value="ROLE_ADM"
                                     [implies]="'ROLE_USER'"
                                     [rulesOut]="['ROLE_CUSTOMER','ROLE_ADMIN','ROLE_PROJECT_ADMIN','ROLE_PRICING']">
                    <h6 class="mb-1">{{ _("Kundenbetreuer") }}</h6>
                    <small>
                        {{ _('Dieser Benutzer darf nur auf die ihm zugeordneten Kunden zugreifen')}}
                    </small>
                </memulti-select-item>
                <memulti-select-item value="ROLE_BESTAND"
                                     [implies]="'ROLE_USER'"
                                     [rulesOut]="['ROLE_CUSTOMER','ROLE_ADMIN','ROLE_PROJECT_ADMIN']">
                    <h6 class="mb-1">{{ _("Bestandsübersicht") }}</h6>
                    <small>
                        {{ _('Dieser Benutzer hat Lesezugriff auf die Bestandsübersicht')}}
                    </small>
                </memulti-select-item>
                <memulti-select-item value="ROLE_BESTAND_WRITABLE"
                                     [implies]="'ROLE_BESTAND'"
                                     [rulesOut]="['ROLE_CUSTOMER','ROLE_ADMIN','ROLE_PROJECT_ADMIN']">
                    <h6 class="mb-1">{{ _("Bestandsübersicht (Schreibzugriff)") }}</h6>
                    <small>
                        {{ _('Dieser Benutzer kann Lieferscheine erfassen oder den Bestand neu berechnen')}}
                    </small>
                </memulti-select-item>
                <memulti-select-item value="ROLE_FAHRZEUGE"
                                     [implies]="'ROLE_USER'"
                                     [rulesOut]="['ROLE_CUSTOMER']">
                    <h6 class="mb-1">{{ _("Innenauftragsübersicht") }}</h6>
                    <small>
                        {{ _('Dieser Benutzer hat Lesezugriff auf die Liste der Innenaufträge')}}
                    </small>
                </memulti-select-item>
                <memulti-select-item value="ROLE_FAHRZEUGE_WRITABLE"
                                     [implies]="'ROLE_FAHRZEUGE'"
                                     [rulesOut]="['ROLE_CUSTOMER']">
                    <h6 class="mb-1">{{ _("Innenauftragsübersicht (Schreibzugriff)") }}</h6>
                    <small>
                        {{ _('Dieser Benutzer kann Innenauftragsdaten ändern')}}
                    </small>
                </memulti-select-item>
                <memulti-select-item value="ROLE_AUSWEISE"
                                     [implies]="'ROLE_USER'"
                                     [rulesOut]="['ROLE_CUSTOMER']">
                    <h6 class="mb-1">{{ _("Ausweisübersicht") }}</h6>
                    <small>
                        {{ _('Dieser Benutzer hat Lesezugriff auf die Liste der Ausweise')}}
                    </small>
                </memulti-select-item>
                <memulti-select-item value="ROLE_AUSWEISE_CHANGE_PIN"
                                     [implies]="'ROLE_AUSWEISE'">
                    <h6 class="mb-1">{{ _("Ausweisübersicht Pin-Änderung") }}</h6>
                    <small>
                        {{ _('Dieser Benutzer kann die Ausweis PIN ändern')}}
                    </small>
                </memulti-select-item>
                <memulti-select-item value="ROLE_AUSWEISE_WRITABLE"
                                     [implies]="['ROLE_AUSWEISE','ROLE_AUSWEISE_CHANGE_PIN']"
                                     [rulesOut]="['ROLE_CUSTOMER']">
                    <h6 class="mb-1">{{ _("Ausweisübersicht (Schreibzugriff)") }}</h6>
                    <small>
                        {{ _('Dieser Benutzer kann Ausweisdaten ändern')}}
                    </small>
                </memulti-select-item>
                <memulti-select-item value="ROLE_KUNDEN"
                                     [implies]="'ROLE_USER'"
                                     [rulesOut]="['ROLE_CUSTOMER']">
                    <h6 class="mb-1">{{ _("Kundenübersicht") }}</h6>
                    <small>
                        {{ _('Dieser Benutzer hat Lesezugriff auf die Liste der Kunden')}}
                    </small>
                </memulti-select-item>
                <memulti-select-item value="ROLE_KUNDEN_WRITABLE"
                                     [implies]="'ROLE_KUNDEN'"
                                     [rulesOut]="['ROLE_CUSTOMER']">
                    <h6 class="mb-1">{{ _("Kundenübersicht (Schreibzugriff)") }}</h6>
                    <small>
                        {{ _('Dieser Benutzer kann Kundendaten ändern')}}
                    </small>
                </memulti-select-item>
                <memulti-select-item value="ROLE_KARTENANFRAGEN"
                                     [implies]="'ROLE_USER'"
                                     [rulesOut]="['ROLE_CUSTOMER']">
                    <h6 class="mb-1">{{ _("Kartenanfragen") }}</h6>
                    <small>
                        {{ _('Dieser Benutzer hat Lesezugriff auf die Liste der Kartenanfragen')}}
                    </small>
                </memulti-select-item>

                <memulti-select-item value="ROLE_TRANSAKTIONEN"
                                     [implies]="'ROLE_USER'"
                                     [rulesOut]="['ROLE_CUSTOMER']">
                    <h6 class="mb-1">{{ _("Transaktionsübersicht") }}</h6>
                    <small>
                        {{ _('Dieser Benutzer hat Lesezugriff auf die Liste der Transaktionen')}}
                    </small>
                </memulti-select-item>
                <memulti-select-item value="ROLE_TRANSAKTIONEN_WRITABLE"
                                     [implies]="'ROLE_TRANSAKTIONEN'"
                                     [rulesOut]="['ROLE_CUSTOMER']">
                    <h6 class="mb-1">{{ _("Transaktionsübersicht (Schreibzugriff)") }}</h6>
                    <small>
                        {{ _('Dieser Benutzer hat die Möglichkeit, Transaktionen umzubuchen')}}
                    </small>
                </memulti-select-item>


                <memulti-select-item value="ROLE_VIDEOCONTROL"
                                     [implies]="'ROLE_USER'"
                                     [rulesOut]="['ROLE_CUSTOMER']">
                    <h6 class="mb-1">{{ _("ME-Videocontrol") }}</h6>
                    <small>
                        {{ _('Dieser Benutzer hat Zugriff auf das ME-Videocontrol-System')}}
                    </small>
                </memulti-select-item>
                <memulti-select-item value="ROLE_PROJECT_ADMIN"
                                     [implies]="['ROLE_USER','ROLE_PRICING','ROLE_BILLING','ROLE_BESTAND','ROLE_VIDEOCONTROL']"
                                     [rulesOut]="['ROLE_CUSTOMER','ROLE_ADM']">
                    <h6 class="mb-1">{{ _("Projekt-Administrator") }}</h6>
                    <small>
                        {{ _('Dieser Benutzer hat Zugriff auf alle Funktionen des Systems')}}
                    </small>
                </memulti-select-item>
                <memulti-select-item value="NO_DASHBOARD">
                    <h6 class="mb-1">{{ _("kein Dashboard") }}</h6>
                    <small>
                        {{ _('Blendet den Menüpunkt "Übersicht" aus')}}
                    </small>
                </memulti-select-item>
                <memulti-select-item value="BETRIEBSRAT_MODE">
                    <h6 class="mb-1">{{ _("Betriebsrat-Modus") }}</h6>
                    <small>
                        {{ _('Leitet die Übersicht zu den Ausweisen weiter')}}
                    </small>
                </memulti-select-item>
            </memulti-select>
        </app-pane>
        <app-pane [title]="_('Zugriff')">
            <app-tabcard>
                <app-pane [title]="_('Stationen')">
                    <select-field [label]="_('Art der Eingrenzung')"
                                  [(value)]="entity.stationsWorkMode"
                    >
                        <select-field-option [value]="0"
                                             [label]="_('Benutzer sieht nur die unten gelisteten Stationen')"
                        ></select-field-option>
                        <select-field-option [value]="1"
                                             [label]="_('Benutzer sieht alle außer die unten gelisteten Stationen')"
                        ></select-field-option>
                    </select-field>
                    <stationen-selector [label]="_('Stationsauswahl')"
                                        [value]="entity.stationen"
                                        (valueChanged)="updateStations($event)"
                    ></stationen-selector>
                </app-pane>
                <app-pane [title]="_('Ausweise, Fahrzeuge, Personen')">
                    <select-field [label]="_('Art der Verfügbarkeit')"
                                  [(value)]="entity.ownEntitiesOnly"
                    >
                        <select-field-option [value]="0"
                                             [label]="_('Benutzer sieht alle Ausweise, Fahrzeuge, Personen')"
                        ></select-field-option>
                        <select-field-option [value]="1"
                                             [label]="_('Benutzer sieht alle Einträge, die ihm zugeordnet sind oder keine Zuordnung haben')"
                        ></select-field-option>
                        <select-field-option [value]="2"
                                             [label]="_('Benutzer sieht nur Einträge, die ihm zugeordnet sind')"
                        ></select-field-option>
                    </select-field>
                    <app-tabset>
                        <app-pane [title]="_('Ausweise')">
                            <ausweis-selector
                                    [label]="_('zugeordnete Ausweise')"
                                    [value]="entity.ausweise"
                                    (valueChanged)="updateAusweise($event)"
                            ></ausweis-selector>
                        </app-pane>
                        <app-pane [title]="_('Fahrzeuge')">
                            <app-fahrzeug-selector [label]="_('zugeordnete Fahrzeuge')"
                                                   [value]="entity.fahrzeuge"
                                                   (valueChanged)="updateFahrzeuge($event)"
                            ></app-fahrzeug-selector>
                        </app-pane>
                        <app-pane [title]="_('Personen')">
                            <app-person-selector [label]="_('zugeordnete Personen')"
                                                 [value]="entity.personen"
                                                 (valueChanged)="updatePersonen($event)"
                            ></app-person-selector>
                        </app-pane>
                    </app-tabset>
                </app-pane>
            </app-tabcard>
        </app-pane>
        <app-pane [title]="_('Sonstiges')">
            <date-time-field [label]="_('im System angelegt')"
                             [disabled]="true"
                             [(value)]="entity.dateCreated"></date-time-field>
            <date-time-field [label]="_('zuletzt geändert')"
                             [disabled]="true"
                             [(value)]="entity.lastUpdated"></date-time-field>
            <date-time-field [label]="_('letzter Login')"
                             [disabled]="true"
                             [(value)]="entity.lastLogin"></date-time-field>
        </app-pane>
    </app-tabset>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="cancel()">
        <i class="icofont icofont-close"></i> {{ _('Schließen') }}
    </button>
    <button type="button" class="btn btn-primary" (click)="save()" [disabled]="!canSave()"
            [class.disabled]="!canSave()">
        <i class="icofont icofont-save"></i> {{ _('Speichern') }}
    </button>
</div>
