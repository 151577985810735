import {ChangeDetectorRef, Component, Injector} from '@angular/core';

import {Transaktion} from '../common/transaktion';
import {LocalizedCellRenderer} from '../../../commons/data-table/renderer/common-cell-renderer';
import {DataTableRow} from '../../../commons/data-table/data-table-row';
import {DataTableColumnDirective} from '../../../commons/data-table/data-table-column.directive';
import {ITransaktionDetail} from '../common/itransaktion-detail';
import {TransaktionDetail} from '../common/transaktion-detail';

@Component({
    selector: 'app-transaktion-details-renderer',
    template: `
        <div>
            <div *ngIf="cell.length>1">{{_('Diese Transaktion umfasst mehrere Positionen') }}</div>
            <div *ngIf="cell.length<1">{{_('Diese Transaktion umfasst keine Positionen') }}</div>
            <div *ngIf="cell.length===1" class="d-flex justify-content-between">
                <div>
                    {{ produkt }}
                </div>
                <div class="text-right text-nowrap">
                    {{ fNum(menge ,digitInfo) }} {{ addon }}
                </div>

            </div>
        </div>
    `
})
export class TransaktionDetailsRendererComponent extends LocalizedCellRenderer {

    cell: ITransaktionDetail[] = [];
    row: DataTableRow<Transaktion> = null;
    column: DataTableColumnDirective = null;

    menge = 0;
    digitInfo = '1.3-3';
    addon = 'L';
    produkt = '';


    constructor(  public injector: Injector, public cd: ChangeDetectorRef) {
        super( cd);
        this.cell = this.injector.get('cell');
        this.row = this.injector.get('row');
        this.column = this.injector.get('column');

        this.menge = 0;
        this.addon = '';

        if (this.cell !== null && this.cell !== undefined && Array.isArray(this.cell) && this.cell.length === 1) {
            const dt = TransaktionDetail.fromResult(this.cell[0]);
            this.menge = dt.menge;
            if (dt.produkt !== null && dt.produkt !== undefined) {
                this.produkt = dt.produkt.bezeichnung;
                if (dt.produkt.einheit !== null && dt.produkt.einheit !== undefined) {
                    this.addon = dt.produkt.einheit.symbol;
                }
            }
        }


    }


}
