export class DeprecatedException extends Error {

    private _message: string;
    private _name = 'DeprecatedException';


    get message(): string {
        return this._message;
    }

    set message(value: string) {
        this._message = value;
    }

    get name(): string {
        return this._name;
    }

    set name(value: string) {
        this._name = value;
    }

    constructor(msg: string) {
        super();
        this._message = msg;
        // console.log(msg)
    }

}
