<app-login-alert>

    <app-area-heading
            [icon]="'far fa-search'"
            title="{{ searchTitle }}"
    ></app-area-heading>


    <app-tabcard>
        <app-pane title="{{ _('Kunden') }} ({{ kunden.total }})">

            <div class="alert alert-info" *ngIf="kunden.total<1">
                {{ _('Es konnten keine passenden Kundendatensätze ermittelt werden ')}}
            </div>

            <data-table
                    [selectable]="false"
                    [data]="kunden.asDataTableRows()"
                    [orders]="kunden.order"
                    [filter]="kunden.simpleFilter"
                    [rowActionsAsButtons]="true"
                    #kundenListe
                    *ngIf="kunden.total>0"
            >
                <column
                        *ngIf="false"
                        [field]="'nummer'"
                        [label]="_('Nummer')"
                        type="integer"
                        [filterable]="false"
                        [sortable]="false"></column>
                <column
                        [field]="'kundennummer'"
                        [label]="_('Kundennummer')"
                        type="text"
                        [filterable]="false"
                        [sortable]="false"></column>
                <column
                        [field]="'bezeichnung'"
                        [label]="_('Bezeichnung')"
                        type="text"
                        [filterable]="false"
                        [sortable]="false"></column>
                <column
                        [field]="'firma'"
                        [label]="_('Firma')"
                        type="text"
                        [filterable]="false"
                        [sortable]="false"></column>
                <column
                        [field]="'vorname'"
                        [label]="_('Vorname')"
                        type="text"
                        [filterable]="false"
                        [sortable]="false"></column>
                <column
                        [field]="'nachname'"
                        [label]="_('Nachname')"
                        type="text"
                        [filterable]="false"
                        [sortable]="false"></column>
                <column
                        [field]="'land'"
                        [label]="_('Land')"
                        [renderer]="landR"
                        [filterable]="false"
                        [sortable]="false"

                ></column>
                <column
                        [field]="'plz'"
                        [label]="_('PLZ')"
                        type="text"
                        [filterable]="false"
                        [sortable]="false"></column>
                <column
                        [field]="'ort'"
                        [label]="_('Ort')"
                        [filterable]="false"
                        [sortable]="false"></column>

                <rowaction
                        [label]="_('Aufrufen')"
                        [icon]="'fas fa-link'"
                        (perform)="gotoKunde($event)"
                ></rowaction>
                <rowaction
                        *hasRole="['ROLE_ADMIN']"
                        [label]="_('Sync')"
                        [icon]="'icofont icofont-cloud-refresh'"
                        (perform)="syncKunde($event)"
                ></rowaction>
                <data-table-table-extra-header>
                    <app-paginator
                            [total]="kunden.total"
                            [page]="kunden.page"
                            [perpage]="kunden.size"
                            (pageChange)="updateKunden(true,$event)"
                            (pageCountChange)="updateKundenPageCount($event)"
                            [showSearch]="false"
                    ></app-paginator>
                </data-table-table-extra-header>
            </data-table>

        </app-pane>
        <app-pane title="{{_('Ausweise')}} ({{ ausweise.total }})">

            <div class="alert alert-info" *ngIf="ausweise.total<1">
                {{ _('Es konnten keine passenden Ausweisdatensätze ermittelt werden ')}}
            </div>


            <data-table
                    [selectable]="false"
                    [data]="ausweise.asDataTableRows()"
                    [orders]="ausweise.order"
                    [filter]="ausweise.simpleFilter"
                    [rowActionsAsButtons]="true"
                    *ngIf="ausweise.total>0"
            >
                <column
                        [field]="'kunde'"
                        [label]="_('Kunde')"
                        [renderer]="kundeR"
                        [sortable]="false"
                ></column>
                <column
                        [field]="'nummer'"
                        [label]="_('Kartennummer')"
                        type="integer"
                        [sortable]="false"></column>
                <column
                        [field]="'adminSperre'"
                        [label]="_('Adminsperre')"
                        type="Boolean"
                        [sortable]="false"></column>
                <column
                        [field]="'kundenSperre'"
                        [label]="_('Kundensperre')"
                        type="Boolean"
                        [sortable]="false"></column>
                <column
                        [field]="'fahrzeug'"
                        [label]="_('KFZ-Kennzeichen')"
                        [renderer]="kfzR"
                        [sortable]="false"></column>

                <rowaction
                        [label]="_('Aufrufen')"
                        [icon]="'fas fa-link'"
                        (perform)="gotoAusweis($event)"
                ></rowaction>
                <rowaction
                        *hasRole="['ROLE_ADMIN']"
                        [label]="_('Sync')"
                        [icon]="'icofont icofont-cloud-refresh'"
                        (perform)="syncAusweis($event)"
                ></rowaction>
                <rowaction
                        *hasRole="['ROLE_CUSTOMER']"
                        [label]="_('Bearbeiten')"
                        [icon]="'icofont icofont-ui-edit'"
                        (perform)="editOptions($event)"
                ></rowaction>
                <rowaction
                        *hasRole="['ROLE_ADMIN','ROLE_PROJECT_ADMIN','ROLE_BILLING']"
                        [label]="_('Bearbeiten')"
                        [icon]="'icofont icofont-ui-edit'"
                        (perform)="editAusweis($event)"
                ></rowaction>
                <data-table-table-extra-header>
                    <app-paginator
                            [total]="ausweise.total"
                            [page]="ausweise.page"
                            [perpage]="ausweise.size"
                            (pageChange)="updateAusweise(true,$event)"
                            (pageCountChange)="updateAusweisePageCount($event)"
                            [showSearch]="false"
                    ></app-paginator>
                </data-table-table-extra-header>
            </data-table>


        </app-pane>

        <app-pane title="{{_('Fahrzeuge')}} ({{ fahrzeuge.total }})">

            <div class="alert alert-info" *ngIf="fahrzeuge.total<1">
                {{ _('Es konnten keine passenden Fahrzeugdatensätze ermittelt werden ')}}
            </div>


            <data-table
                    [selectable]="false"
                    [data]="fahrzeuge.asDataTableRows()"
                    [orders]="fahrzeuge.order"
                    [filter]="fahrzeuge.simpleFilter"
                    [rowActionsAsButtons]="true"
                    *ngIf="fahrzeuge.total>0"
            >

                <column
                        [field]="'nummer'"
                        [label]="_('Nummer')"
                        type="integer"
                        [filterable]="false"
                        [sortable]="true"></column>
                <column
                        [field]="'kunde'"
                        [label]="_('Kunde')"
                        [renderer]="kundeR"
                        [filterable]="false"
                        [sortable]="true"
                ></column>
                <column
                        [field]="'bezeichnung'"
                        [label]="_('Bezeichnung')"
                        type="text"
                        [filterable]="false"
                        [sortable]="true"></column>
                <column
                        [field]="'kfzKennzeichen'"
                        [label]="_('KFZ-Kennzeichen')"
                        type="text"
                        [filterable]="false"
                        [sortable]="true"></column>
                <column
                        [field]="'kmStand'"
                        [label]="_('km-Stand')"
                        type="integer"
                        [filterable]="false"
                        [sortable]="true"></column>
                <column
                        [field]="'ausweis'"
                        [label]="_('Ausweis')"
                        [renderer]="ausweisR"
                        [cfg]="{info_position:'left'}"
                        [filterable]="false"
                        [sortable]="true"></column>

                <rowaction
                        *hasRole="['ROLE_ADMIN','ROLE_PROJECT_ADMIN','ROLE_BILLING']"
                        [label]="_('Bearbeiten')"
                        [icon]="'icofont icofont-ui-edit'"
                        (perform)="editFahrzeug($event)"
                ></rowaction>
                <data-table-table-extra-header>
                    <app-paginator
                            [total]="fahrzeuge.total"
                            [page]="fahrzeuge.page"
                            [perpage]="fahrzeuge.size"
                            (pageChange)="updateFahrzeuge(true,$event)"
                            (pageCountChange)="updateFahrzeugePageCount($event)"
                            [showSearch]="false"
                    ></app-paginator>
                </data-table-table-extra-header>
            </data-table>


        </app-pane>


        <app-pane title="{{_('Autorisierungen')}} ({{ anfragen.total }})">
            <div class="alert alert-info" *ngIf="anfragen.total<1">
                {{ _('Es konnten keine passenden Autorisierungsanfragen ermittelt werden ')}}
            </div>


            <data-table
                    [selectable]="false"
                    [data]="anfragen.asDataTableRows()"
                    [orders]="anfragen.order"
                    [filter]="anfragen.simpleFilter"
                    [rowActionsAsButtons]="true"
                    *ngIf="anfragen.total>0"
            >

                <column
                        [field]="'zeitpunkt'"
                        [label]="_('Zeitpunkt')"
                        type="Date"
                        [cfg]="{format:'medium'}"
                ></column>
                <column
                        [field]="'station'"
                        [label]="_('Station')"
                        [renderer]="stationR"
                ></column>
                <column
                        [field]="'pan'"
                        [label]="_('PAN der Anfrage')"
                        [renderer]="panCellRenderer"
                ></column>
                <column
                        [field]="'ausweis'"
                        [label]="_('Karte')"
                        [renderer]="ausweisR"
                ></column>
                <column
                        [field]="'kunde'"
                        [label]="_('Kunde')"
                        [renderer]="kundeR"
                ></column>
                <column
                        [field]="'ergebnis'"
                        [label]="_('Ergebnis')"
                        [renderer]="ergebnisCellRenderer"
                ></column>
                <column
                        [field]="'kontingent'"
                        [label]="_('freigegebenes Kontingent')"
                        [renderer]="kontingentResultCellRenderer"
                ></column>
                <rowaction
                        [label]="_('Sperrung aufheben')"
                        [title]="_('Temporäre Sperrung aufheben')"
                        [icon]="'icofont icofont-unlock'"
                        (checkEnabled)="checkEnabled('pinreset',$event)"
                        (perform)="unlockPIN($event)"
                ></rowaction>
                <data-table-table-extra-header>
                    <app-paginator
                            [total]="anfragen.total"
                            [page]="anfragen.page"
                            [perpage]="anfragen.size"
                            (pageChange)="updateAnfragen(true,$event)"
                            (pageCountChange)="updateAnfragenPageCount($event)"
                            [showSearch]="false"
                    ></app-paginator>
                </data-table-table-extra-header>

            </data-table>

        </app-pane>

        <app-pane title="{{_('Transaktionen')}} ({{ transaktionen.total }})">

            <div class="alert alert-info" *ngIf="transaktionen.total<1">
                {{ _('Es konnten keine passenden Transaktionen ermittelt werden ')}}
            </div>


            <data-table
                    [selectable]="false"
                    [data]="transaktionen.asDataTableRows()"
                    [orders]="transaktionen.order"
                    [filter]="transaktionen.simpleFilter"
                    [rowActionsAsButtons]="true"
                    *ngIf="transaktionen.total>0"
            >


                <column
                        [field]="'zeitpunkt'"
                        [label]="_('Zeitpunkt')"
                        type="Date"
                        [cfg]="{format:'medium'}"
                        [sortable]="false"></column>

                <column
                        [field]="'station'"
                        [label]="_('Station')"
                        [renderer]="stationR"
                        [sortable]="false"></column>


                <column
                        [field]="'belegnummer'"
                        [label]="_('Beleg#')"
                        type="integer"
                        [sortable]="false"></column>

                <column
                        *hasRole="['ROLE_ADMIN','ROLE_PROJECT_ADMIN','ROLE_BILLING']"
                        [field]="'kunde'"
                        [label]="_('Kunde')"
                        [renderer]="kundeR"
                        [sortable]="false"></column>

                <column
                        [field]="'ausweis1'"
                        [label]="_('Karte')"
                        [renderer]="ausweisR"
                        [sortable]="false"></column>

                <column
                        [field]="'fahrzeug'"
                        [label]="_('KFZ-Kennzeichen')"
                        [renderer]="kfzR"
                        [sortable]="false"></column>
                <column
                        [field]="'details'"
                        [label]="_('Produkt & Menge')"
                        [renderer]="prdCellRenderer"
                        [sortable]="false"
                ></column>
                <rowaction
                        *hasRole="['ROLE_ADMIN','ROLE_BILLING','ROLE_PROJECT_ADMIN']"
                        [label]="_('Details')"
                        [icon]="'far fa-info-circle'"
                        [title]="_('Details anzeigen')"
                        (perform)="showTransaktionDetails($event)"
                ></rowaction>
 <rowaction
          *hasRole="['ROLE_ADMIN','ROLE_BILLING','ROLE_PROJECT_ADMIN','ROLE_CUSTOMER']"
          [label]="_('Lieferschein')"
          [icon]="'far fa-print'"
          [title]="_('Lieferschein drucken')"
          (perform)="showLieferschein($event)"
  ></rowaction>

                <data-table-table-extra-header>
                    <app-paginator
                            [total]="transaktionen.total"
                            [page]="transaktionen.page"
                            [perpage]="transaktionen.size"
                            (pageChange)="updateTransaktionen(true,$event)"
                            (pageCountChange)="updateTransaktionenPageCount($event)"
                            [showSearch]="false"
                    ></app-paginator>
                </data-table-table-extra-header>

            </data-table>

        </app-pane>

    </app-tabcard>


</app-login-alert>