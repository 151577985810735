<div class="input-group" [class.input-group-sm]="small">
    <div class="form-control display-box"
         [class.form-control-sm]="small"
         (click)="showInput()"
         [class.hidden]="_inputVisible"
         [innerHtml]="displayValue(false)"
    ></div>
    <!--suppress HtmlFormInputWithoutLabel -->
    <input class="form-control"
           [class.form-control-sm]="small"
           (keyup)="onkey($event)"
           [value]="_showValue"
           [placeholder]="placeholder"
           [title]="placeholder"
           (click)="_showList=true"
           #searchField
           (blur)="hideInput()"
           (focus)="_showList=true"
           [class.hidden]="!_inputVisible"
    >
    <span class="input-group-btn add-on" *ngIf="nullable">
        <button class="btn btn-secondary"
                [class.btn-sm]="small"
                type="button"
                (click)="clear()">
            <i class="icofont icofont-eraser"></i>
        </button>
    </span>
    <span class="input-group-btn add-on">
        <button class="btn btn-secondary"
                [class.btn-sm]="small"
                type="button"
                (click)="toggleDropDown()">
            <i class="icofont icofont-rounded-down" [class.hidden]="_showList"></i>
            <i class="icofont icofont-rounded-up" [class.hidden]="!_showList"></i>
        </button>
  </span>
</div>
<ul
        class="items"
        [class.small]="small"
        [class.visible]="(_showList && _itemsShown.length>0)"
        [class.is-matrix]="asMatrix"
        *ngIf="(_showList && _itemsShown.length>0)"
>
    <li
            *ngFor="let aItem of _itemsShown"
    >
        <a (click)="select(aItem)"
           title="{{ getText(aItem) }}"
        >
            <div [innerHtml]="format(aItem,_search,true)"></div>
        </a>
    </li>
</ul>