import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {LocalizedComponent} from '../../modules/commons/components/localized-component';
import {Benutzer} from '../../modules/auth/common/benutzer';
import {LoginService} from '../../modules/auth/services/login.service';
import {KundenService} from '../../modules/customers/kunden/common/kunden.service';
import {SysInfoService} from '../../modules/commons/sysinfo/services/sys-info.service';
import {PubSubService} from '../../modules/commons/pub-sub.service';
import {Kunde} from '../../modules/customers/kunden/common/kunde';

@Component({
    selector: 'dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DashboardComponent extends LocalizedComponent implements OnInit, OnDestroy {
    ausweiseDestination = 'ausweise';

    public stand = 'Stand: {{zp}}';
    public currentKunde: Kunde = new Kunde();

    public loginSubscription: Subscription = null;
    isLoggedIn = false;


    constructor(
                
                public router: Router,
                public loginService: LoginService,
                public kundenService: KundenService,
                public cd: ChangeDetectorRef,
                public sysinfoService: SysInfoService,
                public pubsub: PubSubService
    ) {
        super( cd);
    }

    gotoArea(area: string) {
        this.router.navigate([area]);

    }

    updateStats() {
        if (this.isLoggedIn) {
            this.ausweiseDestination = 'ausweise';
        }

        if (this.loginService.hasRole('ROLE_CUSTOMER')) {
            this.kundenService
                .loadCurrentCustomer()
                .subscribe(
                    (k) => {
                        this.currentKunde = Kunde.fromResult(k);
                        this.cd.markForCheck();
                    }
                );
        }
        this.cd.markForCheck();
        /*  if (this.isLoggedIn) {

         if (this.loginService.hasOneOfRoles(['ROLE_ADMIN', 'ROLE_PROJECT_ADMIN', 'ROLE_BILLING'])) {
         this.kundenService.countKunden()
         .subscribe(
         r => {
         this.dashboardInfo.kunden = '' + r
         },
         () => {
         this.dashboardInfo.kunden = '?';
         }
         );
         this.kartenanfrageService.countKartenanfragen()
         .subscribe(
         r => {
         this.dashboardInfo.kartenanfragen = '' + r
         },
         () => {
         this.dashboardInfo.kartenanfragen = '?';
         }
         );
         } else {
         this.dashboardInfo.kunden = '';
         this.dashboardInfo.kartenanfragen = '';
         }

         if (this.loginService.hasRole('ROLE_CUSTOMER')) {
         this.kundenService
         .loadCurrentCustomer()
         .subscribe(
         (k) => {
         this.dashboardInfo.currentKunde = k;
         if (k.kontingent.isPrepaid) {

         let rest = (k.kontingent.betragPrepaid -
         k.kontingent.saldoBetragPrepaid);


         this.dashboardInfo.prepaid.show = true;
         } else {
         this.dashboardInfo.prepaid.restguthaben = '';
         this.dashboardInfo.prepaid.show = false;
         }
         },
         () => {
         this.dashboardInfo.currentKunde = null;
         this.dashboardInfo.prepaid.restguthaben = '';
         this.dashboardInfo.prepaid.show = false;
         this.dashboardInfo.prepaid.isLoading = false;
         }
         )
         } else {
         this.dashboardInfo.currentKunde = null;
         this.dashboardInfo.prepaid.restguthaben = '';
         this.dashboardInfo.prepaid.show = false;
         this.dashboardInfo.prepaid.isLoading = false;
         }

         this.stationenService.countStationen()
         .subscribe(
         r => {
         this.dashboardInfo.stationen = '' + r
         },
         () => {
         this.dashboardInfo.stationen = '?';
         }
         );

         this.ausweisService.countAusweise()
         .subscribe(
         r => {
         this.dashboardInfo.ausweise = '' + r
         },
         () => {
         this.dashboardInfo.ausweise = '?';
         }
         );
         this.transaktionService.countTransaktionen()
         .subscribe(
         r => {
         this.dashboardInfo.transaktionen = '' + r
         },
         () => {
         this.dashboardInfo.transaktionen = '?';
         }
         );




         */
    }

    ngOnInit() {
        this.isLoggedIn = this.loginService.isLoggedIn();
        this.pubsub.loginStateChange.subscribe(() => {
            this.isLoggedIn = this.loginService.isLoggedIn();
        });
        this.loginSubscription = this.pubsub.loginStateChange.subscribe(
            (benutzer: Benutzer) => {
                this.isLoggedIn = this.loginService.isLoggedIn();
                this.updateStats();
            },
            () => {
                this.isLoggedIn = this.loginService.isLoggedIn();
                this.updateStats();
            }
        );
        this.updateStats();
        this.sysinfoService.updateDocumentTitle(this._('Übersicht'));
    }


    ngOnDestroy(): void {
        if (this.loginSubscription != null) {
            this.loginSubscription.unsubscribe();
            this.loginSubscription = null;
        }
    }

    gotoAusweise() {
        if (this.isLoggedIn) {
            if (this.loginService.hasOneOfRoles(['ROLE_PROJECT_ADMIN', 'ROLE_BILLING'])) {
                this.gotoArea('ausweise');
            }
        }
    }

    isCustomer() {
        return this.loginService.isCustomer();
    }

    isPrepaid() {
        if (!this.isCustomer()) {
            return false;
        }
        if (this.currentKunde == null || this.currentKunde === undefined) {
            return false;
        }
        if (this.currentKunde.kontingent == null || this.currentKunde.kontingent === undefined) {
            return false;
        }
        return this.currentKunde.kontingent.isPrepaid;
    }

    isBlocked() {
        if (!this.isCustomer()) {
            return false;
        }
        if (this.currentKunde == null || this.currentKunde === undefined) {
            return false;
        }
        return !this.currentKunde.enabled;
    }
}
