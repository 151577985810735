import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import {Station} from '../stationen/common/station';

import {LocalizedComponent} from '../commons/components/localized-component';
import {DataTableRow} from '../commons/data-table/data-table-row';
import {DataTableComponent} from '../commons/data-table/data-table.component';

@Component({
    selector: 'stationen-selector',
    template: `
        <div class="form-group row">
            <label class="col-{{ labelwidth }} col-form-label" *ngIf="labelwidth>0">
                {{ label }}
            </label>
            <div class="col-{{ 12-labelwidth }}">

                <div class="card">
                    <div class="card-body">
                        <div class="card-header clearfix" *ngIf="!disabled">

                            <div class="float-right">
                                <button class="btn btn-default btn-sm"
                                        [class.disabled]="checkAddDisabled()"
                                        title="{{ _('gewählte Station in Liste übernehmen') }}"
                                        (click)="add()"
                                >
                                    <i class="icofont icofont-ui-add"></i>
                                </button>
                                <button class="btn btn-default btn-sm"
                                        [class.disabled]="checkDelDisabled()"
                                        title="{{ _('gewählte Station aus Liste entfernen') }}"
                                        (click)="removeSelected()"
                                >
                                    <i class="icofont icofont-ui-delete"></i>
                                </button>
                            </div>
                            <station-field
                                    [labelwidth]="0"
                                    [label]="_('Station wählen')"
                                    [(value)]="currentStation"
                                    [small]="true"
                                    [nullable]="false"
                            ></station-field>
                        </div>
                        <div class="card-text">
                            <data-table
                                    #tslist
                                    [data]="stationen"
                                    [selectable]="!disabled"
                                    [singleselect]="true"
                            >
                                <column [label]="_('Nummer')" [field]="'nummer'" [type]="'Integer'"></column>
                                <column [label]="_('Bezeichnung')" [field]="'bezeichnung'"></column>
                            </data-table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    `,
    styles: [`
        :host .card-header {
            padding: 0.35rem;
        }

        :host .card-header > div:first-child {
            padding-left: 0.35rem;
        }

        :host .card-header > station-field {
            min-width: 175px;
        }

        :host .card-header ::ng-deep .form-group {
            margin: 0 !important;
        }

        :host .card-header ::ng-deep .form-group .col-12 {
            padding: 0px;
        }

        :host data-table {
            margin: 0 !important;
            font-size: 0.8rem;
        }

        :host data-table ::ng-deep .column-nummer {
            width: 50px;
        }

        :host data-table ::ng-deep td {
            padding: 0.35rem !important;
        }

    `],
    changeDetection: ChangeDetectionStrategy.Default
})
export class StationenSelectorComponent extends LocalizedComponent implements OnInit {

    @Input() label = 'Stationen';
    @Input() labelwidth = 3;
    @Input() disabled = false;


    stationen: DataTableRow<Station>[] = [];
    private _currentStation: Station = null;


    get currentStation(): Station {
        return this._currentStation;
    }

    set currentStation(value: Station) {
        console.log(value);
        this._currentStation = value;
        if (value !== null && value !== undefined) {
            // this.addElementToList(value);
        }
    }

    @ViewChild(DataTableComponent, {static: true}) dataTable: DataTableComponent;
    @Output() valueChanged: EventEmitter<Station[]> = new EventEmitter<Station[]>();

    constructor(
        public cd: ChangeDetectorRef) {
        super(cd);
    }

    get value(): Station[] {
        const v: Station[] = [];
        this.stationen.forEach(z => {
            v.push(z.data as Station);
        });

        return v;
    }

    @Input()
    set value(v: Station[]) {
        v.forEach(e => {
            this.addElementToList(e, true);
        });
        this.stationen.forEach(z => {
            let found = false;
            v.forEach(e => {
                if (e.id === (z.data as Station).id) {
                    found = true;
                }
            });
            if (!found) {
                this.removeElementFromList(z.data, true);
            }
        });
        this.upd();
    }

    upd() {
        if (this.dataTable !== null && this.dataTable !== undefined) {
            this.dataTable.updateRenderers();
        }
        this.cd.markForCheck();
    }

    ngOnInit() {
        this.upd();
    }

    isElementInList(elem: Station): number {
        let position = -1;
        this.stationen.forEach(
            (z, index) => {
                if ((z.data as Station).id === elem.id) {
                    position = index;
                }
            }
        );
        return position;
    }

    addElementToList(elem: Station, suppressEvent = false) {
        if (elem === null) {
            return;
        }
        if (elem === undefined) {
            return;
        }
        if (this.isElementInList(elem) < 0) {
            this.stationen.push(new DataTableRow<Station>(elem));
            this.upd();
            if (!suppressEvent) {
                this.valueChanged.emit(this.value);
            }
        }
    }

    removeElementFromList(elem: Station, suppressEvent = false) {
        const pos = this.isElementInList(elem);
        if (pos >= 0) {
            this.stationen.splice(pos, 1);
            this.upd();
            if (!suppressEvent) {
                this.valueChanged.emit(this.value);
            }
        }
    }

    add() {
        if ((this._currentStation !== null) && (this._currentStation !== undefined)) {
            this.addElementToList(this._currentStation);
        }
    }

    checkAddDisabled(): boolean {
        let r = (this._currentStation === null) || (this._currentStation === undefined);
        if (r) {
            return r;
        }
        r = (this.isElementInList(this._currentStation) >= 0);
        return r;
    }

    checkDelDisabled(): boolean {
        let e = true;

        this.stationen.forEach(z => {
            if (z.isSelected()) {
                e = false;
            }
        });
        return e;
    }

    removeSelected() {
        this.stationen.forEach(z => {
            if (z.isSelected()) {
                this.removeElementFromList(z.data);
            }
        });
    }


}
