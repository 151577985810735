import {Injectable} from '@angular/core';
import {SimpleAnredeFilter} from './anreden/simple-anrede-filter';
import {Observable} from 'rxjs';
import {SimpleIssuerFilter} from './issuers/common/simple-issuer-filter';
import {LoginService} from '../auth/services/login.service';
import {ResultMetaSortImpl} from './result-meta';
import {IListResult} from './list-result';
import {IAnrede} from './anreden/ianrede';
import {Anrede} from './anreden/anrede';
import {IIssuer} from './issuers/common/iissuer';
import {Issuer} from './issuers/common/issuer';
import {HttpClient} from '@angular/common/http';
import {IActionResponse} from './action-response';

@Injectable({
                providedIn: 'root'
            })
export class SystemSettingsService {

    constructor(public http: HttpClient,
                public loginService: LoginService) {

    }


    // region Anreden

    listAnreden(max = 0,
                offset = 0,
                sort: ResultMetaSortImpl[] = [],
                filter: SimpleAnredeFilter = null): Observable<IListResult<IAnrede>> {
        let url = '/api/systemsettings/anreden';

        url = url.concat('?')
            .concat('max=' + encodeURIComponent('' + max))
            .concat('&')
            .concat('offset=' + encodeURIComponent('' + offset));

        if (filter !== null) {
            if (filter.hasValues()) {
                url = url + '&' + filter.asURL();
            }
        }

        if (sort !== undefined && sort !== null && sort.length > 0) {
            const p = 0;
            sort.forEach(it => {
                url = url.concat('&sort.' + p + '=' + encodeURIComponent(it.field + '|' + it.direction));
            });
        }


        return this.http
            .get<IListResult<IAnrede>>(
                url,
                {headers: this.loginService.getHttpHeaders()}
            );
    }

    storeAnrede(anrede: Anrede, insert = false): Observable<IActionResponse> {
        if (!insert && anrede.id !== null && anrede.id > 0) {
            return this.http
                .put<IActionResponse>(
                    '/api/systemsettings/anreden/' + encodeURIComponent('' + anrede.id),
                    JSON.stringify(anrede),
                    {headers: this.loginService.getHttpHeaders()}
                );
        }
        return this.http
            .post<IActionResponse>(
                '/api/systemsettings/anreden',
                JSON.stringify(anrede),
                {headers: this.loginService.getHttpHeaders()}
            );
    }

    removeAnrede(anrede: Anrede): Observable<IActionResponse> {
        if (anrede.anrede !== null && anrede.anrede !== '') {
            return this.http
                .delete<IActionResponse>(
                    '/api/systemsettings/anreden/' + encodeURIComponent('' + anrede.id),
                    {headers: this.loginService.getHttpHeaders()}
                );
        }
        return null;
    }


    // endregion


    listIssuers(max = 0,
                offset = 0,
                sort: ResultMetaSortImpl[] = [],
                filter: SimpleIssuerFilter = null,
                query                      = ''): Observable<IListResult<IIssuer>> {
        let url = '/api/systemsettings/issuers';

        url = url.concat('?')
            .concat('max=' + encodeURIComponent('' + max))
            .concat('&')
            .concat('offset=' + encodeURIComponent('' + offset));
        if (query !== '') {
            url = url + '&q=' + encodeURIComponent(query);
        }
        if (filter !== null) {
            if (filter.hasValues()) {
                url = url + '&' + filter.asURL();
            }
        }

        if (sort !== undefined && sort !== null && sort.length > 0) {
            const p = 0;
            sort.forEach(it => {
                url = url.concat('&sort.' + p + '=' + encodeURIComponent(it.field + '|' + it.direction));
            });
        }


        return this.http
            .get<IListResult<IIssuer>>(
                url,
                {headers: this.loginService.getHttpHeaders()}
            );
    }

    storeIssuer(issuer: Issuer, insert = false): Observable<IActionResponse> {
        if (!insert && issuer.id !== null && issuer.id > 0) {
            return this.http
                .put<IActionResponse>(
                    '/api/systemsettings/issuers/' + encodeURIComponent('' + issuer.id),
                    JSON.stringify(issuer),
                    {headers: this.loginService.getHttpHeaders()}
                );
        }
        return this.http
            .post<IActionResponse>(
                '/api/systemsettings/issuers',
                JSON.stringify(issuer),
                {headers: this.loginService.getHttpHeaders()}
            );
    }

    removeIssuer(issuer: Issuer): Observable<IActionResponse> {
        if (issuer.id !== null && issuer.id > 0) {
            return this.http
                .delete<IActionResponse>(
                    '/api/systemsettings/issuers/' + encodeURIComponent('' + issuer.id),
                    {headers: this.loginService.getHttpHeaders()}
                );
        }
        return null;
    }


}
