import {ITransaktionenImportDefinition} from './itransaktionen-import-definition';
import {EImportFileType} from './eimport-file-type';
import {StationRewrite} from './station-rewrite';
import {DataTableRow} from '../../../commons/data-table/data-table-row';
import {ConverterTool} from '../../../../shared/converter-tool';


export class TransaktionenImportDefinition implements ITransaktionenImportDefinition {
    file: any = null;
    type: EImportFileType = EImportFileType.UNKNOWN;
    createStations = false;
    createCards = false;
    createProducts = false;
    stationRewrites: StationRewrite[] = [];
    vdaf_two_card_system = false;
    _stationsRewrites: DataTableRow<StationRewrite>[] = [];

    static fromResult(entity: ITransaktionenImportDefinition): TransaktionenImportDefinition {
        if ((entity === null) || (entity === undefined)) {
            return null;
        }

        let l: TransaktionenImportDefinition = new TransaktionenImportDefinition();
        for (let k of Object.keys(l)) {
            if (k === 'type') {
                if (isNaN(parseInt('' + entity.type, 10))) {
                    if (('' + entity.type).toLowerCase() === 'unknown') {
                        l.type = EImportFileType.UNKNOWN
                    } else if (('' + entity.type).toLowerCase() === 'vdaf') {
                        l.type = EImportFileType.VDAF
                    } else {
                        l.type = EImportFileType.UNKNOWN
                    }
                } else {
                    l.type = entity.type
                }
            } else if (k === 'createStations') {
                l.createStations = ConverterTool.toBoolean(entity.createStations)
            } else if (k === 'createCards') {
                l.createCards = ConverterTool.toBoolean(entity.createCards)
            } else if (k === 'createProducts') {
                l.createCards = ConverterTool.toBoolean(entity.createCards)
            } else if (k === 'vdaf_two_card_system') {
                l.vdaf_two_card_system = ConverterTool.toBoolean(entity.vdaf_two_card_system)
            } else if (k === 'stationRewrites') {
                l.clearStationRewrites();
                if (Array.isArray(entity.stationRewrites)) {
                    entity.stationRewrites.forEach((r) => {
                        let rw = StationRewrite.fromResult(r);
                        l.addStationRewrite(rw);
                    })
                }
            } else {
                l[k] = (entity[k] !== undefined) ? entity[k] : '';
            }
        }

        return l;
    }

    constructor() {

    }

    isVDAF(): boolean {
        return parseInt('' + this.type, 10) === EImportFileType.VDAF
    }

    clearStationRewrites() {
        this.stationRewrites = [];
        this._stationsRewrites = [];
    }

    addStationRewrite(rw: StationRewrite) {
        this.stationRewrites.push(rw);
        this._stationsRewrites.push(new DataTableRow<StationRewrite>(rw))
    }

    removeStationsRewrite(rw: StationRewrite) {
        let idx = this.stationRewrites.indexOf(rw);
        if (idx >= 0) {
            this._stationsRewrites.splice(idx, 1);
            this.stationRewrites.splice(idx, 1);
        }
    }

}

