import {AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, NgZone, OnInit, Output, ViewChild} from '@angular/core';
import {SondenBuchung} from '../sonden-buchung';
import {Sonde} from '../sonde';
import {ISondenBuchung} from '../isonden-buchung';
import * as moment from 'moment';
import {Point} from '../../../svgtools/point';
import {DiagrammTool} from '../../../svgtools/diagramm-tool';
import {Serie} from '../../../svgtools/serie';
import {Value} from '../../../svgtools/value';
import {LocalizedComponent} from '../../../commons/components/localized-component';



@Component({
    selector: 'app-sonden-buchungen-diagramm',
    template: `
        <svg viewBox="0 0 1600 500" width="100%" height="100%" *ngIf="isValid()"
             style="border: 1px solid #EEE; margin: 5px 0;" xmlns:svg="http://www.w3.org/1999/html"
             shape-rendering="auto"
             #svg
             (mousemove)="mmove($event)"
        >
            <svg:g app-diagramm-background
                   [area]="diagramArea"
                   [tempArea]="diagramTempArea"
                   [hasTemperatur]="hasTemperatur()"
                   [minTemp]="this.sonde.minTemperatur"
                   [maxTemp]="this.sonde.maxTemperatur"
                   [volumen]="this.sonde.volumen"
                   [minX]="minX"
                   [maxX]="maxX"
                   [sonde]="sonde"
                   [station]="sonde.station"
                   [series]="series"
                   (serieClicked)="onSerieClick($event)"
            />
            <svg:g *ngFor="let s of series"
                   app-diagramm-line
                   [visible]="s.visible"
                   [pts]="s.points"
                   [area]="s.area"
                   [color]="s.color"
            />
            <svg:g *ngIf="sonde!==null && sonde!==undefined">
                <svg:line
                        [attr.x1]="diagramTempArea[0].x"
                        [attr.x2]="diagramTempArea[1].x"
                        stroke="#AA0000"
                        stroke-opacity="0.5"
                        [attr.y1]="warngrenze"
                        [attr.y2]="warngrenze"
                />
                <svg:text
                        [attr.x]="diagramArea[0].x+3"
                        [attr.y]="warngrenze+10"
                          text-anchor="start"
                          style="font-style:normal;font-weight:normal;
                          font-size:9px;line-height:1.25;
                          font-family:sans-serif;letter-spacing:0px;word-spacing:0px;fill:#AA0000;fill-opacity:.8;
                          stroke:none;stroke-width:0.26458332"
                >{{ _('Warngrenze: ') }}{{ fNum(sonde.warnmenge,'1.0-0') }} l</svg:text>
            </svg:g>
            <svg:g *ngIf="showMarker"
                   app-diagramm-marker
                   [area]="diagramArea"
                   [tempArea]="diagramTempArea"
                   [hasTemperatur]="hasTemperatur()"
                   [series]="series"
                   [pos]="markerPos"
                   [yPos]="cy"
            />
        </svg>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush

})
export class SondenBuchungenDiagrammComponent extends LocalizedComponent implements OnInit, AfterViewInit {
    @ViewChild('svg', {static: true}) svg: ElementRef;
    sHeight = 500;
    sWidth = 1600;
    dWidth = 1200;
    offsetX = 100;
    offsetY = 50;
    dHeight = 400;
    cx = 0;
    cy = 0;
    minX: any = null;
    maxX: any = null;

    diagramArea: Point[];
    diagramTempArea: Point[];
    private referenzPunkt: SVGPoint;
    showMarker: boolean;
    markerPos: any = null;


    get sonde(): Sonde {
        return this._sonde;
    }

    get warngrenze() {
        if (this.sonde === null || this.sonde === undefined) {
            return -110000;
        }

        let l = -100000;
        this._series.forEach((s) => {
            if (s.name === 'Menge') {
                l = DiagrammTool.cy(
                        DiagrammTool.calcY(
                            new Value(0, this.sonde.warnmenge),
                            s.dHeight,
                            s.max,
                            s.min
                        ),
                    s.offsetY
                );
            }
        });


        return l;
    }

    @Input()
    set sonde(value: Sonde) {
        this._sonde = Sonde.fromResult(value);
    }

    @Input()
    set sondenData(value: ISondenBuchung[]) {
        if (Array.isArray(value)) {
            let changed = (value.length !== this._data.length);
            if (!changed) {
                for (let i = 0; i < this._data.length; i++) {
                    if (this._data[i].id !== value[i].id) {
                        changed = true;
                    }
                }
            }
            if (changed) {
                this._data = [];
                value.forEach(
                    (sb: ISondenBuchung) => {
                        this._data.push(SondenBuchung.fromResult(sb));
                    });
                this.createSeries();
            }
        }
        this.cd.markForCheck();

    }

    get sondenData(): ISondenBuchung[] {
        return this._data;
    }


    get series(): Serie[] {
        return this._series;
    }

    constructor(
                
                public cd: ChangeDetectorRef,
                public elem: ElementRef,
                public zone: NgZone
    ) {
        super( cd);
    }


    _idx = {
        menge: -1,
        sumpf: -1,
        hoehe: -1,
        temperatur: -1
    };

    private _series: Serie[] = [];

    _data: SondenBuchung[] = [];
    private _sonde: Sonde = null;

    @Input() id = 'diagramm';

    @Input() width: number = null;
    @Input() height: number = null;

    @Output() needsData: EventEmitter<SondenBuchungenDiagrammComponent> = new EventEmitter<SondenBuchungenDiagrammComponent>();

    addBuchung(sb: ISondenBuchung) {
        this._data.push(SondenBuchung.fromResult(sb));
    }

    createSeries() {
        this._series = [];
        this._idx.menge = -1;
        this._idx.temperatur = -1;
        this._idx.sumpf = -1;
        this._idx.hoehe = -1;
        if (this.sonde == null || this.sonde === undefined) {
            return;
        }

        this._idx.menge = this._series.push(
            new Serie(
                'Menge',
                0,
                this.sonde.volumen + Math.floor(this.sonde.volumen * 0.01),
                [],
                '#1b27ab',
                this.offsetY
            )
        ) - 1;


        if (this.hasHoehe()) {
            this._idx.hoehe = this._series.push(
                new Serie(
                    'Höhe',
                    0,
                    (this.sonde.maxHoehe === null) ? 2000 : this.sonde.maxHoehe,
                    [],
                    '#ab7a10',
                    this.offsetY
                )
            ) - 1;
        }
        if (this.hasSumpf()) {
            this._idx.sumpf = this._series.push(
                new Serie(
                    'Sumpf-Höhe',
                    0,
                    (this.sonde.maxHoehe === null) ? 2000 : this.sonde.maxHoehe,
                    [],
                    '#48a8ab',
                    this.offsetY
                )
            ) - 1;
        }
        if (this.hasTemperatur()) {
            this._idx.temperatur = this._series.push(
                new Serie(
                    'Temperatur',
                    (this.sonde.minTemperatur === null) ? -30 : this.sonde.minTemperatur,
                    (this.sonde.maxTemperatur === null) ? 80 : this.sonde.maxTemperatur,
                    [],
                    '#ab145a',
                    360)
            ) - 1;
        }

        this._data.forEach((sb: SondenBuchung) => {
            const xpunkt = moment(sb.zeitpunkt).startOf('minute').toDate();

            if (this.minX == null || xpunkt < this.minX) {
                this.minX = xpunkt;
            }
            if (this.maxX == null || xpunkt > this.maxX) {
                this.maxX = xpunkt;
            }

            if (this._idx.menge >= 0) {
                this._series[this._idx.menge].addValue(
                    new Value(
                        xpunkt as Date,
                        sb.menge
                    )
                );
            }

            if (this.hasHoehe() && this._idx.hoehe >= 0) {
                this._series[this._idx.hoehe].addValue(
                    new Value(
                        xpunkt as Date,
                        sb.hoehe
                    )
                );
            }

            if (this.hasTemperatur() && this._idx.temperatur >= 0) {
                this._series[this._idx.temperatur].addValue(
                    new Value(
                        xpunkt as Date,
                        sb.temperatur
                    )
                );
            }

            if (this.hasSumpf() && this._idx.sumpf >= 0) {
                this._series[this._idx.sumpf].addValue(
                    new Value(
                        xpunkt as Date,
                        sb.sumpf
                    )
                );
            }
        });
        this.buildDiagramm();
    }

    hasHoehe() {
        if (this._sonde === null || this._sonde === undefined) {
            return false;
        }
        return this._sonde.hasHoehe;
    }

    hasSumpf() {
        if (this._sonde === null || this._sonde === undefined) {
            return false;
        }
        return this._sonde.hasSumpf;
    }

    hasTemperatur() {
        if (this._sonde === null || this._sonde === undefined) {
            return false;
        }
        return this._sonde.hasTemperatur;
    }


    ngOnInit() {
    }

    clear() {
        this._data = [];
        this.minX = null;
        this.maxX = null;
        this._series = [];
        this.createSeries();
    }

    start() {
        this.clear();
        this.needsData.emit(this);
    }

    buildDiagramm() {
        this.dHeight = 400;
        if (this._sonde.hasTemperatur) {
            this.dHeight = 260;
        }

        this.diagramArea = [
            new Point(DiagrammTool.cx(0, this.offsetX), DiagrammTool.cy(this.dHeight, this.offsetY, this.sHeight)),
            new Point(DiagrammTool.cx(this.dWidth, this.offsetX), DiagrammTool.cy(0, this.offsetY, this.sHeight))
        ];

        this.diagramTempArea = [
            new Point(DiagrammTool.cx(0, this.offsetX), DiagrammTool.cy(120, 360, this.sHeight)),
            new Point(DiagrammTool.cx(this.dWidth, this.offsetX), DiagrammTool.cy(0, 360, this.sHeight))
        ];

        this._series.forEach((sr: Serie, sidx: number) => {

            sr.area = this.diagramArea;
            sr.dHeight = this.dHeight;
            if (sr.name === 'Temperatur') {
                sr.area = this.diagramTempArea;
                sr.dHeight = 120;
            }

            sr.points = [];

            let oldy = 0;
            sr.values.forEach(
                (pt: Value, idx) => {
                    if (sr.name !== 'Temperatur') {
                        if (idx > 0) {
                            sr.points.push(new Point(DiagrammTool.cx(sr.calcX(pt, this.dWidth), this.offsetX), oldy));
                        }
                    }
                    oldy = DiagrammTool.cy(sr.calcY(pt, sr.dHeight), sr.offsetY);
                    sr.points.push(new Point(DiagrammTool.cx(sr.calcX(pt, this.dWidth), this.offsetX), oldy, true));
                }
            );
        });

        this.cd.markForCheck();
    }

    isValid() {
        if (this._series === null || this._series === undefined || !Array.isArray(this._series)) {
            return false;
        }
        if (this.diagramArea === null || this.diagramArea === undefined || !Array.isArray(this.diagramArea)) {
            return false;
        }
        let r = this._series.length > 0;
        this._series.forEach((s: Serie) => {
                r = r && s.points.length > 0;
            }
        );
        if (this.diagramArea.length < 2) {
            r = false;
        }
        return r;
    }

    initPoint() {
        if (this.svg !== null && this.svg !== undefined) {
            if (this.referenzPunkt === null || this.referenzPunkt === undefined) {
                this.referenzPunkt = (this.svg.nativeElement).createSVGPoint();
            }
        }
    }

    ngAfterViewInit() {
         this.start();
        this.initPoint();
    }

// Get point in global SVG space
    cursorPoint(evt) {
        if (this.referenzPunkt !== null && this.referenzPunkt !== undefined) {
            this.referenzPunkt.x = evt.clientX;
            this.referenzPunkt.y = evt.clientY;
            return this.referenzPunkt.matrixTransform(this.svg.nativeElement.getScreenCTM().inverse());
        }
        return null;
    }

    mmove($event: MouseEvent) {
        if (!this.isValid()) {
            return;
        }
        this.initPoint();
        const loc = this.cursorPoint($event);

        if (loc === null) {
            return;
        }

        this.cx = loc.x;
        this.cy = loc.y;
        this.showMarker = false;

        if (this._series.length > 0) {
            if (loc.x >= this.diagramArea[0].x && loc.x <= this.diagramArea[1].x) {
                if (loc.y >= this.diagramArea[0].y && loc.y <= this.diagramArea[1].y) {
                    this.showMarker = true;
                }
                if (this.diagramTempArea.length > 0) {
                    if (loc.y >= this.diagramTempArea[0].y && loc.y <= this.diagramTempArea[1].y) {
                        this.showMarker = true;
                    }
                }
            }

            const sr = this._series[0];
            this.markerPos = null;
            let mx = null;
            if (this.showMarker) {

                for (let i = 0; i < sr.values.length; i++) {
                    const px = DiagrammTool.cx(sr.calcX(sr.values[i], this.dWidth), this.offsetX);
                    if ((mx === null) || (Math.abs(px - loc.x) < Math.abs(mx - loc.x))) {
                        this.markerPos = sr.values[i].pos;
                        mx = px;
                    }
                }

                if (this.markerPos === null) {
                    this.showMarker = false;
                } else {
                    this.showMarker = true;
                }
            }
        }


        this.cd.markForCheck();


    }

    onSerieClick($event: Serie) {
        $event.visible = !$event.visible;
        this.cd.markForCheck();
    }
}
