<app-login-alert>

    <app-area-heading
            [icon]="'far fa-address-book'"
            title="{{ _('Kunden') }}"
    ></app-area-heading>

    <div class="card">
        <div class="card-body">
            <div class="card-text">
                <data-table
                        [selectable]="false"
                        [data]="kunden.asDataTableRows()"
                        [orders]="kunden.order"
                        [filter]="kunden.simpleFilter"
                        (sortChanged)="sortChanged($event)"
                        (filterChanged)="filterChanged($event)"
                        [rowActionsAsButtons]="true"
                        [showFilterArea]="false"
                        [extraRowClasses]="kundenRowClassGetter"
                        #kundenListe
                >
                    <column
                            [field]="'nummer'"
                            [label]="_('Nummer')"
                            type="integer"
                            [filterable]="false"
                            [sortable]="true"></column>
                    <column
                            [field]="'kundennummer'"
                            [label]="_('Kundennummer')"
                            type="text"
                            [filterable]="false"
                            [sortable]="true"></column>
                    <column
                            [field]="'buchungskreis'"
                            [label]="_('Buchungskreis')"
                            type="text"
                            [filterable]="false"
                            [sortable]="true"></column>
                    <column
                            [field]="'bezeichnung'"
                            [label]="_('Bezeichnung')"
                            type="text"
                            [filterable]="false"
                            [sortable]="true"></column>
                    <column
                            [field]="'plz'"
                            [label]="_('PLZ')"
                            type="text"
                            [filterable]="false"
                            [sortable]="true"></column>
                    <column
                            [field]="'ort'"
                            [label]="_('Ort')"
                            [filterable]="false"
                            [sortable]="true"></column>
                    <column
                            [field]="'ansprechpartner'"
                            [label]="_('Ansprechpartner')"
                            [filterable]="false"
                            [sortable]="true"></column>
                    <column
                            [field]="'kostenstelle'"
                            [label]="_('Kostenstelle')"
                            [filterable]="false"
                            [renderer]="kostenstelleR"
                            [sortable]="true"></column>

                    <rowaction
                            *hasRole="['ROLE_ADMIN']"
                            [label]="_('Sync')"
                            [icon]="'icofont icofont-cloud-refresh'"
                            (perform)="syncKunde($event)"
                    ></rowaction>

                    <tableaction
                            *hasRole="['ROLE_ADMIN','ROLE_PROJECT_ADMIN','ROLE_KUNDEN_WRITABLE']"
                            [label]="t('Kunde anlegen')"
                            [icon]="'icofont icofont-ui-add'"
                            (perform)="newKunde()"
                    ></tableaction>
                    <tableaction
                            [label]="_('Export')"
                            [icon]="'icofont icofont-download-alt'"
                            [type]="'dropdown'"
                    >
                        <list-option
                                *hasRole="['ROLE_ADMIN','ROLE_PROJECT_ADMIN','ROLE_BILLING','ROLE_KUNDEN']"
                                [label]="_('Kundenliste .xlsx')"
                                [icon]="'far fa-file-excel'"
                                (perform)="downloadEntries('xlsx',12001)"
                        ></list-option>
                    </tableaction>
                    <tableaction
                            *hasRole="['ROLE_ADMIN','ROLE_PROJECT_ADMIN']"
                            [label]="_('Werkzeuge')"
                            [icon]="'icofont icofont-tools'"
                            [type]="'dropdown'"
                    >
                        <list-option
                                [label]="_('Kunden synchronisieren')"
                                [icon]="'icofont icofont-cloud-download'"
                                (perform)="syncCustomers()"
                        ></list-option>
                        <list-option
                                [label]="_('Kunden indizieren')"
                                [icon]="'icofont icofont-cloud-download'"
                                (perform)="reindexKunden()"
                        ></list-option>
                        <list-option
                                [label]="_('Kunden aus Ratio')"
                                [icon]="'icofont icofont-cloud-download'"
                                (perform)="syncRatioKunden()"
                        ></list-option>
                    </tableaction>

                    <rowaction
                            *hasRole="['ROLE_ADMIN','ROLE_PROJECT_ADMIN','ROLE_KUNDEN_WRITABLE']"
                            [label]="_('Bearbeiten')"
                            [icon]="'icofont icofont-ui-edit'"
                            (perform)="editKunde($event)"
                    ></rowaction>
                    <data-table-table-extra-header>
                        <app-paginator
                                [total]="kunden.total"
                                [page]="kunden.page"
                                [perpage]="kunden.size"
                                (pageChange)="updateKunden(true,$event)"
                                (pageCountChange)="updateKundenPageCount($event)"
                                [showSearch]="true"
                                (queryChange)="kundenQueryChange($event)"
                                [showFilterButton]="true"
                                [datatable]="kundenListe"
                                (onFilterToggle)="kundenFilterToggle($event)"
                                [filterDialog]="filterDialog"
                        ></app-paginator>
                    </data-table-table-extra-header>
                    <data-table-filter-area>
                        <app-kunde-filter-dialog
                                #filterDialog
                                (filterChanged)="kundenQueryChange2($event)"
                        ></app-kunde-filter-dialog>
                    </data-table-filter-area>
                </data-table>

            </div>
        </div>
    </div>

</app-login-alert>