<div class="modal-header">
  <h5 class="modal-title" >{{ dlgTitle }}</h5>

  <button type="button" class="close" aria-label="Close" (click)="cancel()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">

  <app-tabset>
    <app-pane [title]="_('Stammdaten')">
      <num-field id="nummer-field"
                 [label]="_('Nummer')"
                 [(value)]="entity.nummer"
                 [addonicon]="['icofont icofont-search']"
                 [addonlabel]="[_('freie Nummer suchen')]"
                 [enableaddon]="true"
                 (addonClick)="addOnClick($event)"
      ></num-field>
      <text-field [label]="_('Name')" [(value)]="entity.name"></text-field>
      <text-field [label]="_('Personalnummer')" [(value)]="entity.personalNummer"></text-field>
    </app-pane>
    <app-pane [title]="_('Zuordnungen')">
      <kunde-field [label]="_('Kunde')" [(value)]="entity.kunde"></kunde-field>
      <ausweis-field [label]="_('Ausweis')" [(value)]="entity.ausweis"></ausweis-field>
      <text-field [label]="_('Kostenstelle')" [(value)]="entity.kostenstelle"></text-field>
      <num-field [label]="_('Tankserver-ID')" [(value)]="entity.tankserverID"></num-field>
    </app-pane>
    <app-pane [title]="_('Sonstiges')">

      <date-time-field [label]="_('angelegt')"
                       [value]="entity.dateCreated"
                       disabled="true"></date-time-field>
      <date-time-field [label]="_('zuletzt geändert')" [value]="entity.lastUpdated"
                       disabled="true"></date-time-field>
    </app-pane>


  </app-tabset>

</div>
<div class="modal-footer">

  <button type="button" class="btn btn-secondary" (click)="cancel()">
    <i class="icofont icofont-close"></i> {{ _('Abbrechen') }}
  </button>
  <button type="button"
          class="btn btn-primary"
          (click)="save()"
          [class.disabled]="!canSave()"
          [disabled]="!canSave()"
  >
    <i class="icofont icofont-save"></i>
    {{ _('Änderungen speichern')}}
  </button>
</div>

