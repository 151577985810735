import {DateTime} from 'luxon';
import {DiagrammTool} from './diagramm-tool';
import {Point} from './point';
import {Value} from './value';

export class Serie {
    name: string;
    values: Value[] = [];
    min: any = null;
    max: any = null;
    minx: any = null;
    maxx: any = null;
    color = '#000000';
    offsetY = 0;
    points: Point[] = [];
    area: Point[] = [];
    dHeight = 0;
    visible= true;
    einheit = '';


    constructor(name: string, min = 0, max = null, values: Value[] = [], color= '#000000', offsetY= 0) {
        this.name = name;
        this.min = min;
        this.max = max;
        this.values = values;
        this.color = color;
        this.offsetY = offsetY;
    }

    addValue(value: Value) {
        this.values.push(value);

        if (value.pos instanceof Date) {
            const vglx = DateTime.fromJSDate(value.pos).toJSDate();
            if (this.minx === null || vglx < this.minx) {
                this.minx = vglx;
            }
            if (this.maxx === null || vglx > this.maxx) {
                this.maxx = value.pos;
            }
        } else {
            const vglx = parseFloat('' + value.pos);
            if (this.minx === null || vglx < this.minx) {
                this.minx = vglx;
            }
            if (this.maxx === null || vglx > this.maxx) {
                this.maxx = value.pos;
            }
        }
    }

    calcX(point: Value, width: number) {
        return DiagrammTool.calcX(point, width, this.maxx, this.minx);
    }

    calcY(point: Value, height: number) {
        return DiagrammTool.calcY(point, height, this.max, this.min);
    }


    first(): Value {
        if (this.values.length < 1) {
            return null;
        }
        return this.values[0];
    }

    last(): Value {
        if (this.values.length < 1) {
            return null;
        }
        return this.values[this.values.length - 1];
    }

    clear() {
        this.values = [];
        this.max = 0;
        this.min = 0;
        this.maxx = 0;
        this.minx = 0;
    }

    numValues() {
        return this.values.length;
    }

    setEinheit(einheit: string): Serie {
        this.einheit = einheit;
        return this;
    }
}
