<app-login-alert>

  <app-area-heading
          [icon]="'far fa-camera'"
          title="{{ _('MEVideoControl') }}"
  ></app-area-heading>

  <div class="card" *hasRole="['ROLE_ADMIN','ROLE_PROJECT_ADMIN','ROLE_VIDEOCONTROL']">
    <div class="card-body">
      <div class="card-text">

        <data-table [selectable]="false"
                    [data]="geraete.asDataTableRows()"
                    [rowActionsAsButtons]="true"
                    [hideCheckboxes]="true"
                    #geraeteTable
        >
          <column
                  [field]="'station'"
                  [label]="t('Station')"
                  [renderer]="stationR"
          ></column>

          <column [field]="'nummer'"
                  [label]="_('Nummer')"
                  [type]="'Integer'"
          ></column>
          <column
                  [field]="'bezeichnung'"
                  [label]="t('Bezeichnung')"
          ></column>
          <column
                  *hasRole="['ROLE_ADMIN']"
                  [field]="'settings~ip'"
                  [label]="t('IP-Adresse')"
                  [renderer]="deviceSettingsR"
          ></column>
          <column
                  *hasRole="['ROLE_ADMIN','ROLE_PROJECT_ADMIN','ROLE_VIDEOCONTROL']"
                  [field]="'settings~wifi_ssid'"
                  [label]="t('Accesspoint WiFi SSID')"
                  [renderer]="deviceSettingsR"
          ></column>
          <column
                  *hasRole="['ROLE_ADMIN','ROLE_PROJECT_ADMIN','ROLE_VIDEOCONTROL']"
                  [field]="'settings~wifi_last_activated'"
                  [label]="t('WiFi zuletzt aktiviert')"
                  [renderer]="deviceSettingsR"
                  [cfg]="{type:'date'}"
          ></column>
          <column
                  *hasRole="['ROLE_ADMIN','ROLE_PROJECT_ADMIN','ROLE_VIDEOCONTROL']"
                  [field]="'settings'"
                  [label]="t('Systemstatus')"
                  [renderer]="stateR"
          ></column>
          <rowaction
                  *hasRole="['ROLE_ADMIN','ROLE_PROJECT_ADMIN','ROLE_VIDEOCONTROL']"
                  [label]="_('Status abrufen')"
                  [title]="_('Systemstatus abrufen')"
                  [icon]="'far fa-thermometer'"
                  (perform)="checkState($event)"
          ></rowaction>

            <rowaction
                    *hasRole="['ROLE_ADMIN','ROLE_PROJECT_ADMIN','ROLE_VIDEOCONTROL']"
                    [label]="_('Box neustarten')"
                    [title]="_('Box neustarten')"
                    [icon]="'far fa-redo'"
                    (perform)="reboot($event)"
            ></rowaction>
          <rowaction
                  *hasRole="['ROLE_ADMIN','ROLE_PROJECT_ADMIN','ROLE_VIDEOCONTROL']"
                  [label]="_('Konfiguration an Box senden')"
                  [title]="_('Konfiguration an Box senden')"
                  [icon]="'far fa-file-check'"
                  (perform)="sendConfig($event)"
          ></rowaction>
            <rowaction
                    *hasRole="['ROLE_ADMIN','ROLE_PROJECT_ADMIN','ROLE_VIDEOCONTROL']"
                    [label]="_('Standbild erstellen')"
                    [title]="_('erstellt ein aktuelles Standbild')"
                    [icon]="'fas fa-camera'"
                    (perform)="shot($event)"
            ></rowaction>
          <rowaction
                  *hasRole="['ROLE_ADMIN','ROLE_PROJECT_ADMIN','ROLE_VIDEOCONTROL']"
                  [label]="_('Wifi aktivieren')"
                  [title]="_('Wifi aktivieren')"
                  [icon]="'far fa-wifi'"
                  (perform)="enableAccess($event)"
          ></rowaction>
          <rowaction
                  *hasRole="['ROLE_ADMIN','ROLE_PROJECT_ADMIN','ROLE_VIDEOCONTROL']"
                  [label]="_('Wifi deaktivieren')"
                  [title]="_('Wifi deaktivieren')"
                  [icon]="'far fa-wifi-slash'"
                  (perform)="disableAccess($event)"
          ></rowaction>

          <data-table-table-extra-header>
            <app-paginator
                    [total]="geraete.total"
                    [page]="geraete.page"
                    [perpage]="geraete.size"
                    (pageChange)="updateGeraete(true,$event)"
                    (pageCountChange)="updateGeraetePageCount($event)"
            ></app-paginator>
          </data-table-table-extra-header>
        </data-table>

      </div>
    </div>
  </div>

</app-login-alert>
