import {SimpleNachrichtFilter} from './simple-nachricht-filter';
import {INachricht} from './inachricht';
import {Nachricht} from './nachricht';
import {CommonList} from '../../../commons/common-list';
import {IListResult} from '../../../commons/list-result';

export class NachrichtList extends CommonList<Nachricht> {
    public simpleFilter: SimpleNachrichtFilter = new SimpleNachrichtFilter();

    static fromListResult(res: IListResult<INachricht>): NachrichtList {
        let newList: NachrichtList = new NachrichtList();

        newList.populateFromListResult(res, true);

        return newList;
    }

    public convertEntity(entity: any): any {
        return Nachricht.fromResult(entity);
    }

    public populateFromListResult(res: IListResult<any>, clearFilter = true): void {
        this.doPopulateFromListResult(res, 'dateCreated', 'desc', clearFilter)
    }
}
