import {Produkt} from '../../products/produkte/common/produkt';
import {Waehrung} from '../../commons/waehrungen/waehrung';

export class PreisHelper {
    produkt: Produkt;
    wert: number;
    currency: Waehrung;
    istBrutto: boolean;
    visible: boolean;
    ti: number;
    gueltigAb: string;
}
