import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output
} from '@angular/core';
import {Sprache} from '../../common/sprache';

@Component({
  selector: 'language-field',
 template: `
                   <div class="form-group row">
                       <label for="{{ id }}" class="col-{{ labelwidth }} col-form-label" *ngIf="labelwidth>0">
                           {{ label }}
                       </label>
                       <div class="col-{{ 12-labelwidth }}">
                           <language-inline-field
                                   id="{{ id }}"
                                   [label]="label"
                                   [value]="_value"
                                   (valueChange)="doSelect($event)"
                                   [small]="small"
                                   [nullable]="nullable"
                                   [disabled]="disabled"
                           ></language-inline-field>
                       </div>
                   </div>

               `,
               styles  : [ `` ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LanguageFieldComponent implements OnInit {

    @Input() nullable = true;

    @Input() small          = false;
    public _value: Sprache                   = null;
    @Input() label           = 'Sprachefeld';
    @Input() id                 = '';
    @Input() labelwidth = 3;
    @Input() disabled = false;

    @Input()
    set value(v: Sprache) {

        if (this._value !== v) {
            this._value = v;
            if (!this.disabled) {
                this.valueChange.emit(v);
            }
            this.cd.markForCheck();
        }
    }

    @Output() valueChange = new EventEmitter<Sprache>();


    get value(): Sprache {
        return this._value;
    }

    constructor(public cd: ChangeDetectorRef) {
    }

    ngOnInit() {
    }

    doSelect(language: Sprache) {
        this._value = language;
        if (!this.disabled) {
            this.valueChange.emit(language);
        }
        this.cd.markForCheck();
    }

}
