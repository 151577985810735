import {IAttachment} from './iattachment';
import {EFileType} from './efile-type';
import {EAttachmentType} from './eattachment-type';
import {ConverterTool} from '../../../../shared/converter-tool';


export class Attachment implements IAttachment {
    key = '';
    fileType: EFileType = EFileType.UNKNOWN;
    lastEdited: Date = null;
    dateCreated: Date = null;
    lastUpdated: Date = null;
    id = 0;
    filename = '';
    name = '';
    size = 0;
    type: EAttachmentType = EAttachmentType.URL;
    url = '';



    static fromResult(entity: IAttachment): Attachment {
        if ((entity === null) || (entity === undefined)) {
            return null;
        }

        const l: Attachment = new Attachment();
        for (const k of Object.keys(l)) {
            if (k === 'dateCreated') {
                l.dateCreated = ConverterTool.toDate(entity.dateCreated);
            } else if (k === 'lastUpdated') {
                l.lastUpdated = ConverterTool.toDate(entity.lastUpdated);
            } else if (k === 'lastEdited') {
                l.lastEdited = ConverterTool.toDate(entity.lastEdited);
            } else if (k === 'type') {
                const n = parseInt(entity.type, 10);
                if (!isNaN(n)) {
                    l.type = entity.type;
                } else {
                    if (('' + entity.type) === 'FILE') {
                        l.type = EAttachmentType.FILE;
                    } else if (('' + entity.type) === 'URL') {
                        l.type = EAttachmentType.URL;
                    }
                }
            } else if (k === 'fileType') {

                const n = parseInt(entity.fileType, 10);
                if (!isNaN(n)) {
                    l.fileType = entity.fileType;
                } else {
                    l.fileType = EFileType.UNKNOWN;
                    if (('' + entity.fileType) === 'UNKNOWN') {
                        l.fileType = EFileType.UNKNOWN;
                    } else if (('' + entity.fileType) === 'PDF') {
                        l.fileType = EFileType.PDF;
                    } else if (('' + entity.fileType) === 'TEXTDOCUMENT') {
                        l.fileType = EFileType.TEXTDOCUMENT;
                    } else if (('' + entity.fileType) === 'SPREADSHEET') {
                        l.fileType = EFileType.SPREADSHEET;
                    } else if (('' + entity.fileType) === 'XML') {
                        l.fileType = EFileType.XML;
                    } else if (('' + entity.fileType) === 'PRESENTATION') {
                        l.fileType = EFileType.PRESENTATION;
                    }
                }
            } else {
                l[ k ] = (entity[ k ] !== undefined) ? entity[ k ] : '';
            }
        }

        return l;
    }

    constructor() {
    }


    public getIcon() {
        if (this.fileType === EFileType.PDF) {
            return 'icofont icofont-file-pdf';
        } else if (this.fileType === EFileType.TEXTDOCUMENT) {
            return 'icofont icofont-file-document';
        } else if (this.fileType === EFileType.PRESENTATION) {
            return 'icofont icofont-file-presentation';
        } else if (this.fileType === EFileType.XML) {
            return 'icofont icofont-file-code';
        } else if (this.fileType === EFileType.SPREADSHEET) {
            return 'icofont icofont-file-excel';
        } else {
            return 'icofont icofont-file';
        }
    }

    public getURI() {
        if (this.type === EAttachmentType.FILE) {
            return '/downloads/attachment/'
                   + encodeURIComponent('' + this.id)
                   + '/'
                   + encodeURIComponent('' + this.key);
        } else if (this.type === EAttachmentType.URL) {
            return this.url;
        } else {
            return '';
        }
    }

    public isFile() {
        return this.type === EAttachmentType.FILE;
    }
    public isURL() {
        return this.type === EAttachmentType.URL;
    }


}
