<app-login-alert>

    <app-area-heading
            [icon]="'icofont icofont-listing-number'"
            title="{{ _('Kartenanfragen') }}"
    ></app-area-heading>

    <div class="card">
        <div class="card-body">
            <div class="card-text">
                <data-table
                        [selectable]="false"
                        [data]="anfragen.asDataTableRows()"
                        [orders]="anfragen.order"
                        [filter]="anfragen.simpleFilter"
                        (sortChanged)="sortChanged($event)"
                        (filterChanged)="filterChanged($event)"
                        [rowActionsAsButtons]="true"
                        [showFilterArea]="false"
                        #kartenanfragenListe
                >
                    <tableaction
                            *hasRole="['ROLE_ADMIN']"
                            [label]="t('Werkzeuge')"
                            [icon]="'far fa-chess-rock'"
                            [type]="'dropdown'"
                    >
                        <list-option
                            [label]="t('Kartenanfragen indizieren')"
                            [icon]="'icofont icofont-cloud-refresh'"
                            (perform)="reindexKartenanfragen()"
                        ></list-option>
                    </tableaction>

                    <tableaction
                            *hasRole="['ROLE_ADMIN','ROLE_PROJECT_ADMIN','ROLE_BILLING','ROLE_ADM','ROLE_CUSTOMER','ROLE_KARTENANFRAGEN']"
                            [label]="_('Export')"
                            [icon]="'icofont icofont-download-alt'"
                            [type]="'dropdown'"
                    >
                        <list-option
                                [label]="_('als CSV-Datei (.csv)')"
                                [icon]="'far fa-file'"
                                (perform)="downloadKartenanfragen('csv')"
                        ></list-option>
                        <list-option
                                [label]="_('als Excel-Datei (.xlsx)')"
                                [icon]="'far fa-file-excel'"
                                (perform)="downloadKartenanfragen('xlsx')"
                        ></list-option>
                        <list-option
                                [label]="_('als Excel97-Datei (.xls)')"
                                [icon]="'far fa-file-excel'"
                                (perform)="downloadKartenanfragen('xls')"
                        ></list-option>
                        <list-option
                                [label]="_('als OpenOffice/LibreOffice-Datei (.ods)')"
                                [icon]="'icofont icofont-file-spreadsheet'"
                                (perform)="downloadKartenanfragen('ods')"
                        ></list-option>
                    </tableaction>
                    <tableaction
                            *hasRole="['ROLE_ADMIN']"
                            [label]="_('Reporte')"
                            [icon]="'icofont icofont-listing-box'"
                            [type]="'dropdown'"
                    >
                        <list-option
                                [label]="_('einfache Liste')"
                                [icon]="'far fa-file-pdf'"
                                (perform)="reportKartenanfragen('simple-list')"
                        ></list-option>
                    </tableaction>


                    <column
                            [field]="'zeitpunkt'"
                            [label]="_('Zeitpunkt')"
                            type="Date"
                            [cfg]="{format:'medium'}"
                            [sortable]="true"
                    ></column>
                    <column
                            [field]="'station'"
                            [label]="_('Station')"
                            [renderer]="stationR"
                    ></column>
                    <column
                            *hasRole="['ROLE_ADMIN','ROLE_PROJECT_ADMIN','ROLE_BILLING','ROLE_AUSWEISE']"
                            [field]="'pan'"
                            [label]="_('PAN der Anfrage')"
                            [renderer]="panCellRenderer"
                            [sortable]="true"
                    ></column>
                    <column
                            [field]="'ausweis'"
                            [label]="_('Karte')"
                            [renderer]="ausweisR"
                    ></column>
                    <column
                            [field]="'fahrzeug'"
                            [label]="_('KFZ-Kennzeichen')"
                            [renderer]="kfzRenderer"
                            [sortable]="false"></column>
                    <column
                            *hasRole="['ROLE_ADMIN','ROLE_PROJECT_ADMIN','ROLE_BILLING','ROLE_ADM','ROLE_KUNDEN']"
                            [field]="'kunde'"
                            [label]="_('Kunde')"
                            [renderer]="kundeR"
                    ></column>
                    <column
                            [field]="'ergebnis'"
                            [label]="_('Ergebnis')"
                            [renderer]="ergebnisCellRenderer"
                    ></column>
                    <column
                            [field]="'kontingent'"
                            [label]="_('freigegebenes Kontingent')"
                            [renderer]="kontingentCellRenderer"
                    ></column>

                    <rowaction
                            [label]="_('Sperrung aufheben')"
                            [title]="_('Temporäre Sperrung aufheben')"
                            [icon]="'icofont icofont-unlock'"
                            (checkEnabled)="checkEnabled('reset',$event)"
                            (perform)="unlock($event)"
                    ></rowaction>

                    <data-table-table-extra-header>
                        <app-paginator
                                [total]="anfragen.total"
                                [page]="anfragen.page"
                                [perpage]="anfragen.size"
                                (pageChange)="updateAnfragen(true,$event)"
                                (pageCountChange)="updateAnfragenPageCount($event)"
                                [showSearch]="true"
                                (queryChange)="anfragenQueryChange($event)"
                                [showFilterButton]="true"
                                [datatable]="kartenanfragenListe"
                                (onFilterToggle)="kartenanfragenFilterToggle($event)"
                                [filterDialog]="filterDialog"
                        ></app-paginator>
                    </data-table-table-extra-header>

                    <data-table-filter-area>
                        <app-kartenanfragen-filterdialog
                                #filterDialog
                                (filterChanged)="kartenanfragenQueryChange2($event)"
                        ></app-kartenanfragen-filterdialog>
                    </data-table-filter-area>

                    <data-table-footer>
                        <div class="d-flex justify-content-between">
                            <div></div>
                            <div>
                                <app-paginator
                                        [total]="anfragen.total"
                                        [page]="anfragen.page"
                                        [perpage]="anfragen.size"
                                        (pageChange)="updateAnfragen(true,$event)"
                                        (pageCountChange)="updateAnfragenPageCount($event)"
                                        [showSearch]="false"
                                        [showFilterButton]="false"
                                ></app-paginator>
                            </div>
                        </div>
                    </data-table-footer>

                </data-table>


            </div>
        </div>
    </div>

</app-login-alert>