<data-table
        [selectable]="false"
        [data]="artikel.asDataTableRows()"
        [orders]="artikel.order"
        [filter]="artikel.simpleFilter"
        (sortChanged)="sortChanged($event)"
        (filterChanged)="filterChanged($event)"

>
    <column [field]="'nummer'" [label]="t('Nummer')" [type]="'integer'" [filterable]="false"></column>
    <column [field]="'bezeichnung'" [label]="t('Bezeichnung')" [sortable]="true" [filterable]="false"></column>
    <column [field]="'einheit'" [label]="t('Einheit')"
            [renderer]="einheitR"
            [filterable]="false"></column>
    <column [field]="'art'" [label]="t('ArtikelArt')"
            [renderer]="artikelArtR"
             [filterable]="false"></column>
    <tableaction
            [label]="t('Artikel anlegen')"
            [icon]="'icofont icofont-ui-add'"
            (perform)="createArtikel()"
    ></tableaction>
    <rowaction
            [label]="_('Artikel bearbeiten')"
            [icon]="'icofont icofont-ui-edit'"
            (perform)="editArtikel($event)"
            (checkEnabled)="checkEnabled('artikel',$event)"
    ></rowaction>

</data-table>

<div class="row">
    <div class="col-6"></div>
    <div class="col-6 text-right">
        <app-paginator
                [total]="artikel.total"
                [page]="artikel.page"
                [perpage]="artikel.size"
                (pageChange)="updateArtikel(true,$event)"
                (pageCountChange)="updateArtikelPageCount($event)"
        ></app-paginator>
    </div>
</div>

