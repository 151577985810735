export class PasswordChangeRecord {
    currentPassword = '';
    newPassword = '';
    newPasswordRepeat = '';

    clear() {
        this.currentPassword = '';
        this.newPassword = '';
        this.newPasswordRepeat = '';

    }

    get isOk() {
        return this.oldPwOk && this.newPwOK;
    }

    get oldPwOk() {
        return (this.currentPassword !== null) &&
            (this.currentPassword !== '');
    }

    get newPwOK() {
        return (this.newPassword !== null) &&
            (this.newPassword !== '') &&
            (this.newPasswordRepeat !== null) &&
            (this.newPasswordRepeat !== '') &&
            (this.newPassword === this.newPasswordRepeat);
    }


    get isEmpty() {
        return ((this.currentPassword === null) ||
            (this.currentPassword === '')) &&
            ((this.newPassword === null) ||
                (this.newPassword === '')) &&
            ((this.newPasswordRepeat === null) ||
                (this.newPasswordRepeat === ''));
    }

}
