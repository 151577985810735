import {Kartenanfrage} from './kartenanfrage';
import {IKartenanfrage} from './ikartenanfrage';
import {IKartenanfrageErgebnis} from './ikartenanfrage-ergebnis';
import {IStation} from '../../../stationen/common/istation';
import {GenericFilter} from '../../../commons/generic-filter';
import {IKunde} from '../../../customers/kunden/common/ikunde';
import {IFahrzeug} from '../../../customers/fahrzeuge/common/ifahrzeug';
import {IPerson} from '../../../customers/personen/common/iperson';
import {IWaehrung} from '../../../commons/waehrungen/iwaehrung';
import {ITerminal} from '../../../stationen/terminals/common/iterminal';
import {IAusweis} from '../../../customers/ausweise/common/iausweis';

export class SimpleKartenanfrageFilter extends GenericFilter<Kartenanfrage> implements IKartenanfrage {
    id: number = null;
    kunde: IKunde = null;
    fahrzeug: IFahrzeug = null;
    person: IPerson = null;
    waehrung: IWaehrung = null;
    terminal: ITerminal = null;
    station: IStation = null;
    ausweis: IAusweis = null;
    zeitpunkt: any = null;
    tankserverId: number = null;
    pan: string = null;
    zusatzEingabe: string = null;
    panerkennungInfo: string = null;
    kurswert: number = null;
    fahrerNummer: number = null;
    kmStand: number = null;
    ergebnis: IKartenanfrageErgebnis = null;
    kontingent: number = null;
    kontingentTyp: string = null;
    kartennummer: number = null;
}
