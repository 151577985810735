<app-login-alert>

    <app-area-heading
            [icon]="'far fa-credit-card'"
            title="{{ _('Ausweise') }}"
    ></app-area-heading>

    <div class="card">
        <div class="card-body">
            <div class="card-text">
                <data-table
                        [selectable]="false"
                        [data]="ausweise.asDataTableRows()"
                        [orders]="ausweise.order"
                        [filter]="ausweise.simpleFilter"
                        (sortChanged)="sortChanged($event)"
                        (filterChanged)="filterChanged($event)"
                        [rowActionsAsButtons]="true"
                        [showFilterArea]="false"
                        #ausweisListe
                >
                    <column
                            *hasRole="['ROLE_ADMIN','ROLE_PROJECT_ADMIN','ROLE_BILLING','ROLE_KUNDEN']"
                            [field]="'kunde'"
                            [label]="_('Kunde')"
                            [renderer]="kundeR"
                            [sortable]="false"
                    ></column>
                    <column
                            [field]="'nummer'"
                            [label]="_('Ausweisnummer')"
                            type="integer"
                            [sortable]="false"></column>
                    <column
                            [field]="'bezeichnung'"
                            [label]="_('Bezeichnung')"
                            [sortable]="false"></column>

                    <column
                            *hasRole="['ROLE_ADMIN','ROLE_PROJECT_ADMIN','ROLE_BILLING','ROLE_AUSWEISE','ROLE_AUSWEISE_WRITABLE']"
                            [field]="'pan'"
                            [label]="_('Kennung')"
                            type="text"
                            [sortable]="false"></column>
                    <column *hasRole="['ROLE_ADMIN','ROLE_PROJECT_ADMIN','ROLE_BILLING','ROLE_AUSWEISE','ROLE_AUSWEISE_WRITABLE']"
                            [field]="'issuer'"
                            [label]="_('Herausgeber')"
                            [renderer]="issuerR"
                            [sortable]="false"></column>

                    <column
                            [field]="'gesperrt'"
                            [label]="_('gesperrt?')"
                            type="Boolean"
                            [sortable]="false"></column>
                    <column
                            *ngIf="false"
                            [field]="'kundenSperre'"
                            [label]="_('Kundensperre')"
                            type="Boolean"
                            [sortable]="false"></column>
                    <column
                            [field]="'fahrzeug'"
                            [label]="_('KFZ-Kennzeichen')"
                            [renderer]="kfzRenderer"
                            [sortable]="false"></column>
                    <column
                            *ngIf="false"
                            [field]="'begrenzungen'"
                            [label]="_('Freigaben')"
                            [renderer]="begrenzungenCellRenderer"
                            [sortable]="false"
                    ></column>
                    <tableaction
                            *hasRole="['ROLE_ADMIN','ROLE_PROJECT_ADMIN','ROLE_BILLING','ROLE_AUSWEISE_WRITABLE']"
                            [label]="t('Ausweis anlegen')"
                            [icon]="'icofont icofont-ui-add'"
                            (perform)="newAusweis()"
                    ></tableaction>
                    <tableaction
                            [label]="_('Export')"
                            [icon]="'icofont icofont-download-alt'"
                            [type]="'dropdown'"
                    >
                        <list-option
                                *ngIf="sysInfoService.canExportAusweise()"

                                [label]="_('Ausweisliste .xlsx')"
                                [icon]="'far fa-file-excel'"
                                (perform)="downloadEntries('xlsx',12001)"
                        ></list-option>
                    </tableaction>
                     <tableaction
                        *hasRole="['ROLE_ADMIN','ROLE_PROJECT_ADMIN']"
                        [label]="_('Werkzeuge')"
                        [icon]="'icofont icofont-tools'"
                        [type]="'dropdown'"
                >
                    <list-option
                            *hasRole="['ROLE_ADMIN','ROLE_PROJECT_ADMIN']"
                            [label]="t('Karten synchronisieren')"
                            [icon]="'icofont icofont-cloud-refresh'"
                            (perform)="syncAusweise()"
                    ></list-option>
                    <list-option
                            *ngIf="false"
                            [label]="t('Karten aus Ratio')"
                            [title]="t('Änderungen aus Ratio importieren')"
                            [icon]="'icofont icofont-cloud-download'"
                            (perform)="syncRatioAusweise()"
                    ></list-option>
                    <list-option
                            *hasRole="['ROLE_ADMIN']"
                            [label]="t('Ausweise indizieren')"
                            [icon]="'icofont icofont-cloud-refresh'"
                            (perform)="reindexAusweise()"
                    ></list-option>
                     </tableaction>
                    <tableaction
                            *hasRole="['ROLE_CUSTOMER']"
                            [label]="t('globale Freigaben ändern')"
                            [icon]="'icofont icofont-edit'"
                            (perform)="editCustomerBegrenzungen()"
                    ></tableaction>

                    <rowaction
                            *hasRole="['ROLE_ADMIN','ROLE_CUSTOMER']"
                            [label]="_('Bearbeiten')"
                            [icon]="'icofont icofont-edit'"
                            (perform)="editOptions($event)"
                    ></rowaction>
                    <rowaction
                            *hasRole="['ROLE_ADMIN','ROLE_PROJECT_ADMIN','ROLE_BILLING','ROLE_AUSWEISE_WRITABLE']"
                            [label]="_('Bearbeiten')"
                            [icon]="'icofont icofont-ui-edit'"
                            (perform)="editAusweis($event)"
                    ></rowaction>
                    <rowaction
                            *ngIf="sysInfoService.canChangePin()"
                            [label]="_('Pin festlegen')"
                            [icon]="'icofont icofont-key'"
                            (perform)="setPin($event)"
                    ></rowaction>
                    <rowaction
                            *hasRole="['ROLE_ADMIN']"
                            [label]="_('Sync')"
                            [icon]="'icofont icofont-cloud-refresh'"
                            (perform)="syncAusweis($event)"
                    ></rowaction>

                    <data-table-table-extra-header>
                        <app-paginator
                                [total]="ausweise.total"
                                [page]="ausweise.page"
                                [perpage]="ausweise.size"
                                (pageChange)="updateAusweise(true,$event)"
                                (pageCountChange)="updateAusweisePageCount($event)"
                                [showSearch]="true"
                                (queryChange)="ausweiseQueryChange($event)"
                                [showFilterButton]="true"
                                [datatable]="ausweisListe"
                                (onFilterToggle)="ausweisFilterToggle($event)"
                                [filterDialog]="filterDialog"
                        ></app-paginator>
                    </data-table-table-extra-header>
                    <data-table-filter-area>
                        <app-ausweis-filter-dialog
                                #filterDialog
                                (filterChanged)="ausweisQueryChange2($event)"
                        ></app-ausweis-filter-dialog>
                    </data-table-filter-area>

                </data-table>

            </div>
        </div>
    </div>

</app-login-alert>
