import {SimpleTankBestandFilter} from './simple-tank-bestand-filter';
import {ITankBestand} from './itank-bestand';
import {IListResult} from '../../../commons/list-result';
import {TankBestand} from './tank-bestand';
import {CommonList} from '../../../commons/common-list';
import {ResultMetaSortImpl} from '../../../commons/result-meta';
import {RemoteList} from '../../../commons/remote-list';

export class TankBestandList extends RemoteList<TankBestand> {
    public simpleFilter: SimpleTankBestandFilter = new SimpleTankBestandFilter();

    static fromListResult(res: IListResult<ITankBestand>): TankBestandList {
        const newList = new TankBestandList();
        newList.populateFromListResult(res, true);
        return newList;
    }
    constructor(autorun = false, timeout = 400) {
        super(autorun, timeout);
    }

    public convertEntity(entity: any): any {
        return TankBestand.fromResult(entity);
    }

    public populateFromListResult(res: IListResult<ITankBestand>, clearFilter = true): void {
        this.doPopulateFromListResult(res, 'datum', 'desc', clearFilter);
    }

}
