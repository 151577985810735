import {Component, EventEmitter, Input, NgZone, OnInit, Output} from '@angular/core';
import {Ausweis} from './ausweise/common/ausweis';

@Component({
    selector: 'ausweis-field',
    template: `
        <div class="form-group row">
            <label for="{{ id }}" class="col-{{ labelwidth }} col-form-label" *ngIf="labelwidth>0">
                {{ label }}
            </label>
            <div class="col-{{ 12-labelwidth }}">
                <ausweis-inline-field
                        id="{{ id }}"
                        [label]="label"
                        [value]="_value"
                        (valueChange)="doSelect($event)"
                        [small]="small"
                        [nullable]="nullable"
                ></ausweis-inline-field>
            </div>
        </div>
    `
})
export class AusweisFieldComponent implements OnInit {
    @Input() nullable = true;
    @Input() small = false;
    public _value: Ausweis = null;
    @Input() label = 'Ausweisfeld';
    @Input() id = '';
    @Input() labelwidth = 3;

    @Input()
    set value(v: Ausweis) {

        if (this._value !== v) {
            this._value = v;
            this.valueChange.emit(v);
        }
    }

    @Output() valueChange = new EventEmitter<Ausweis>();


    get value(): Ausweis {
        return this._value;
    }

    constructor(public _ngZone: NgZone) {
    }

    ngOnInit() {
    }

    doSelect(ausweis: Ausweis) {
        this._value = ausweis;
        this.valueChange.emit(ausweis);
    }


}
