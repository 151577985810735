import {DataTableRowAction} from './data-table-row-action';
import {ListOptionDirective} from '../components/list-option.directive';

export class DataTableRow<T> {


    public data: T        = null;
    public renderers: any = {};

    public _selected = false;
    public _editmode = false;

    public disabledActions: any[] = [];
    public hiddenActions: any[]   = [];


    get selected() {
        return this._selected;
    }

    get showEditor() {
        return this._editmode;
    }


    constructor(data: T, selected = false, edit = false, renderers: any = {}) {
        this.data      = data;
        this._selected = selected;
        this._editmode = edit;
        this.renderers = renderers;
    }


    isActionDisabled(action: DataTableRowAction|ListOptionDirective): boolean {
        const idx = this.disabledActions.indexOf(action, 0);
        return (idx > -1);
    }

    disableAction(action: DataTableRowAction|ListOptionDirective) {
        if (!this.isActionDisabled(action)) {
            this.disabledActions.push(action);
        }
    }

    enableAction(action: DataTableRowAction|ListOptionDirective) {
        const idx = this.disabledActions.indexOf(action, 0);
        if (idx > -1) {
            this.disabledActions.splice(idx, 1);
        }
    }

    select() {
        this._selected = true;
    }

    unselect() {
        this._selected = false;
    }

    isSelected() {
        return this._selected;
    }

    toggleSelect() {
        this._selected = !this._selected;
    }

    toogleEditor() {
        this._editmode = !this._editmode;
    }

    displayEditor() {
        this._editmode = true;
    }

    hideEditor() {
        this._editmode = false;
    }


    public asJSON() {
        return JSON.stringify({s: this._selected, d: this.data});
    }

    isActionHidden(action: DataTableRowAction|ListOptionDirective): boolean {
        if (this.hiddenActions.length < 1) {
            return false;
        }
        const idx = this.hiddenActions.indexOf(action, 0);
        return (idx > -1);
    }

    hideAction(action: DataTableRowAction|ListOptionDirective) {
        if (!this.isActionHidden(action)) {
            this.hiddenActions.push(action);
        }
    }

    showAction(action: DataTableRowAction|ListOptionDirective) {
        const idx = this.hiddenActions.indexOf(action, 0);
        if (idx > -1) {
            this.hiddenActions.splice(idx, 1);
        }
    }


}
