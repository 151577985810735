import {Injectable} from '@angular/core';
import {SimpleTransaktionFilter} from './simple-transaktion-filter';
import {Observable} from 'rxjs';
import {TransaktionenImportDefinition} from './transaktionen-import-definition';
import {HttpClient} from '@angular/common/http';
import {SimpleNachbuchungFilter} from './simple-nachbuchung-filter';
import {INachbuchung} from './inachbuchung';
import {LoginService} from '../../../auth/services/login.service';
import {ITransaktion} from './itransaktion';
import {IListResult} from '../../../commons/list-result';
import {IActionResponse} from '../../../commons/action-response';
import {ResultMetaSortImpl} from '../../../commons/result-meta';
import {Nachbuchung} from './nachbuchung';
import {map, take} from 'rxjs';
import {Transaktion} from './transaktion';
import {ITransaktionenStatsRow} from './itransaktionen-stats-row';

@Injectable({
                providedIn: 'root'
            })
export class TransaktionService {


    constructor(public loginService: LoginService,
                public httpClient: HttpClient) {
    }

    list(max = 0,
         offset = 0,
         sort: ResultMetaSortImpl[] = [],
         filter: SimpleTransaktionFilter = null,
         searchQuery = ''): Observable<IListResult<ITransaktion>> {
        let url = '/api/transaktionen';

        url = url.concat('?')
            .concat('max=' + encodeURIComponent('' + max))
            .concat('&')
            .concat('offset=' + encodeURIComponent('' + offset));

        if (filter !== null) {
            if (filter.hasValues()) {
                url = url + '&' + filter.asURL();
            }
        }

        if (sort !== undefined && sort !== null && sort.length > 0) {
            const p = 0;
            sort.forEach(it => {
                url = url.concat('&sort.' + p + '=' + encodeURIComponent(it.field + '|' + it.direction));
            });
        }

        if (searchQuery !== null && searchQuery !== undefined && searchQuery !== '') {
            url = url.concat('&q=' + encodeURIComponent(searchQuery));
        }
        return this.httpClient
            .get<IListResult<ITransaktion>>(
                url,
                {headers: this.loginService.getHttpHeaders()}
            ).pipe(
                take(1)
            )
            ;
    }


    exportVDAF(filter: SimpleTransaktionFilter = null, searchQuery = ''): Observable<File> {
        let url = '/api/transaktionen.vdaf';

        url = url.concat('?max=-1')
            .concat('&offset=0')
            .concat('&sort.0=' + encodeURIComponent('zeitpunkt|asc'));


        if (filter !== null) {
            if (filter.hasValues()) {
                url = url + '&' + filter.asURL();
            }
        }
        if (searchQuery !== null && searchQuery !== undefined && searchQuery !== '') {
            url = url.concat('&q=' + encodeURIComponent(searchQuery));
        }

        return this.httpClient
            .get(
                url,
                {
                    headers: this.loginService.getHttpHeaders(false),
                    responseType: 'blob'
                }
            ).pipe(
                map(
                    (data: Blob) => {
                        return new File([data], 'transaktionen.vdaf', {type: 'text/plain'});
                    }
                ),
                take(1)
            );
    }

    exportData(type= 'csv', filter: SimpleTransaktionFilter = null, searchQuery = '', exporttype= 0): Observable<Blob> {
        let url = '/api/transaktionen.' + encodeURIComponent('' + type);

        url = url.concat('?max=-1')
            .concat('&offset=0')
            .concat('&sort.0=' + encodeURIComponent('zeitpunkt|asc'));
        url = url.concat('&exporttype=' + encodeURIComponent('' + exporttype));


        if (filter !== null) {
            if (filter.hasValues()) {
                url = url + '&' + filter.asURL();
            }
        }
        if (searchQuery !== null && searchQuery !== undefined && searchQuery !== '') {
            url = url.concat('&q=' + encodeURIComponent(searchQuery));
        }

  return this.httpClient
            .get(
                url,
                {
                    headers: this.loginService.getHttpHeaders(false),
                    responseType: 'blob'
                }
            ).pipe(
                take(1)
            );
    }


    uploadImport(importdefs: TransaktionenImportDefinition): Observable<IActionResponse> {
        const url = '/api/transaktionen/_import';
        const formData: FormData = new FormData();

        for (const k of Object.keys(importdefs)) {
            if (!k.startsWith('_')) {
                if (k === 'stationRewrites') {
                    formData.append(k, JSON.stringify(importdefs[k]));
                } else {
                    formData.append(k, importdefs[k]);
                }
            }
        }


        const headers = this.loginService.getHttpHeaders(false);
        headers.append('Accept', 'application/json');
        return this
            .httpClient
            .post<IActionResponse>(url, formData, {headers: headers}).pipe(
                take(1)
            );
    }


    countTransaktionen(): Observable<IActionResponse> {
        return this.httpClient
            .get<IActionResponse>(
                '/api/transaktionen/_count',
                {headers: this.loginService.getHttpHeaders()}
            ).pipe(
                take(1)
            );

    }


    loadStats(): Observable<ITransaktionenStatsRow[]> {
        return this.httpClient
            .get<ITransaktionenStatsRow[]>(
                '/api/transaktionen/_stats',
                {headers: this.loginService.getHttpHeaders()}
            ).pipe(
                map((v: ITransaktionenStatsRow[], idx) => {
                    if (v !== null && v !== undefined && Array.isArray(v)) {
                        for (let i = 0; i < v.length; i++) {
                            if (v[i].sumTransaktionLastMonth == null) {
                                v[i].sumTransaktionLastMonth = 0;
                            }
                            if (v[i].sumTransaktionThisMonth == null) {
                                v[i].sumTransaktionThisMonth = 0;
                            }
                        }
                    }
                    return v;
                }),
                take(1)
            );

    }


    listNachbuchungen(max = 0,
                      offset = 0,
                      sort: ResultMetaSortImpl[] = [],
                      filter: SimpleNachbuchungFilter = null,
                      searchQuery = ''): Observable<IListResult<INachbuchung>> {
        let url = '/api/nachbuchungen';

        url = url.concat('?')
            .concat('max=' + encodeURIComponent('' + max))
            .concat('&')
            .concat('offset=' + encodeURIComponent('' + offset));

        if (filter !== null) {
            if (filter.hasValues()) {
                url = url + '&' + filter.asURL();
            }
        }

        if (sort !== undefined && sort !== null && sort.length > 0) {
            const p = 0;
            sort.forEach(it => {
                url = url.concat('&sort.' + p + '=' + encodeURIComponent(it.field + '|' + it.direction));
            });
        }

        if (searchQuery !== null && searchQuery !== undefined && searchQuery !== '') {
            url = url.concat('&q=' + encodeURIComponent(searchQuery));
        }
        return this.httpClient
            .get<IListResult<INachbuchung>>(
                url,
                {
                    headers: this.loginService.getHttpHeaders()
                }
            ).pipe(
                take(1)
            );
    }


    reindexTransaktionen(): Observable<IActionResponse> {
        return this.httpClient
            .post<IActionResponse>(
                '/api/transaktionen/_reindex',
                {},
                {
                    headers: this.loginService.getHttpHeaders()
                }
            ).pipe(
                take(1)
            );
    }

    redoTransaktionen(): Observable<IActionResponse> {
        return this.httpClient
            .post<IActionResponse>(
                '/api/transaktionen/_redo',
                {},
                {
                    headers: this.loginService.getHttpHeaders()
                }
            ).pipe(
                take(1)
            );
    }

    storeNachbuchung(nachbuchung: Nachbuchung, insert = false): Observable<IActionResponse> {
        if (!insert && nachbuchung.id !== null && nachbuchung.id > 0) {
            return this.httpClient
                .put<IActionResponse>(
                    '/api/nachbuchungen/' + encodeURIComponent('' + nachbuchung.id),
                    JSON.stringify(nachbuchung),
                    {
                        headers: this.loginService.getHttpHeaders()
                    }
                ).pipe(
                    take(1)
                );
        }
        return this.httpClient
            .post<IActionResponse>(
                '/api/nachbuchungen',
                JSON.stringify(nachbuchung),
                {
                    headers: this.loginService.getHttpHeaders()
                }
            ).pipe(
                take(1)
            );
    }


    resumeNacherfassung(data: Nachbuchung): Observable<IActionResponse> {
        if (data === null || data === undefined || data.id === null || data.id === undefined || data.id < 1) {
            return null;
        }
        return this.httpClient
            .post<IActionResponse>(
                '/api/nachbuchungen/' + encodeURIComponent('' + data.id) + '/_resume',
                JSON.stringify(null),
                {
                    headers: this.loginService.getHttpHeaders()
                }
            ).pipe(
                take(1)
            );
    }

    holdNacherfassung(data: Nachbuchung): Observable<IActionResponse> {
        if (data === null || data === undefined || data.id === null || data.id === undefined || data.id < 1) {
            return null;
        }
        return this.httpClient
            .post<IActionResponse>(
                '/api/nachbuchungen/' + encodeURIComponent('' + data.id) + '/_hold',
                JSON.stringify(null),
                {
                    headers: this.loginService.getHttpHeaders()
                }
            ).pipe(
                take(1)
            );
    }


    stornoTransaktion(entity: Transaktion): Observable<IActionResponse> {
        if (entity === null || entity === undefined || entity.id === null || entity.id === undefined || entity.id < 1) {
            return null;
        }
        return this.httpClient
            .delete<IActionResponse>(
                '/api/transaktionen/' + encodeURIComponent('' + entity.id) + '/_storno',
                {
                    headers: this.loginService.getHttpHeaders()
                }
            ).pipe(
                take(1)
            );

    }


    storeTransaktion(entity: Transaktion, mitStorno = false): Observable<IActionResponse> {
        if (entity === null || entity === undefined || entity.id === null || entity.id === undefined || entity.id < 1) {
            return null;
        }

        let uri = '/api/transaktionen/' + encodeURIComponent('' + entity.id);
        if (mitStorno) {
            uri = uri + '?doStorno=true';
        }

        return this.httpClient
            .put<IActionResponse>(
                uri,
                JSON.stringify(Transaktion.fromResult(entity)),
                {
                    headers: this.loginService.getHttpHeaders()
                }
            ).pipe(
                take(1)
            );

    }


    load(id: number): Observable<ITransaktion> {

        const uri = '/api/transaktionen/' + encodeURIComponent('' + id);

        return this.httpClient
            .get<ITransaktion>(
                uri,
                {
                    headers: this.loginService.getHttpHeaders()
                }
            ).pipe(
                take(1)
            );
    }

    recalcTransaktionen(entity: Transaktion): Observable<IActionResponse> {
        if (entity === null || entity === undefined || entity.id === null || entity.id === undefined || entity.id < 1) {
            return null;
        }

        const uri = '/api/transaktionen/' + encodeURIComponent('' + entity.id) + '/_recalc';

        return this.httpClient
            .post<IActionResponse>(
                uri,
                JSON.stringify(Transaktion.fromResult(entity)),
                {
                    headers: this.loginService.getHttpHeaders()
                }
            ).pipe(
                take(1)
            );
    }


    getRedosPending():Observable<number> {

        const uri = '/api/transaktionen/_redoCount';

        return this.httpClient
            .get<IActionResponse>(
                uri,
                {
                    headers: this.loginService.getHttpHeaders()
                }
            ).pipe(
                map((r)=>{
                    if (r.success) {
                        return parseInt(''+r.params['count'],10);
                    }
                    return 0
                }),

                take(1)
            );
    }
}
