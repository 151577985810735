import {Land} from '../../commons/laender/common/land';
import {EventEmitter} from '@angular/core';

export class StationenFilter {
    public filterChanged: EventEmitter<void> = new EventEmitter<void>();
    private _freitext = '';

    private _countries: Land[] = [];
    get countries(): Land[] {
        return this._countries;
    }

    set countries(value: Land[]) {
        if (value !== this._countries) {
            this._countries = value;
            this.filterChanged.emit();
        }
    }

    get freitext(): string {
        return this._freitext;
    }

    set freitext(value: string) {
        if (value !== this._freitext) {
            this._freitext = value;
            this.filterChanged.emit();
        }
    }

    constructor() {
    }

    clearCountries() {
        this.countries = [];
    }

    addCountry(land: Land) {
        if (land != null && land !== undefined) {
            if (!Array.isArray(this._countries)) {
                this._countries = [];
            }
            let found = false;
            this._countries.forEach((s: Land) => {
                if (s.laendercode === land.laendercode) {
                    found = true;
                }
            });
            if (!found) {
                this._countries.push(land);
                this.filterChanged.emit();
            }
        }
    }

    removeCountry(land: Land) {
        let found = false;
        this._countries.forEach((s, idx) => {
            if (s.laendercode === land.laendercode) {
                this._countries.splice(idx, 1);
                found = true;
            }
        });
        if (found) {
            this.filterChanged.emit();
        }
    }

    getQuery(): string {
        const parts: string[] = [];
        const c: string[] = [];
        if (this._countries !== null && this._countries !== undefined && Array.isArray(this._countries)) {
            this._countries.forEach((s: Land) => {
                c.push('((_land_id:' + s.id + ') OR (land:' + s.laendercode + '))');
            });
        }
        if (c.length > 0) {
            parts.push('( ' + c.join(' OR ') + ' )');
        }

        if (this._freitext !== null && this._freitext !== undefined && this._freitext.trim() !== '') {
            parts.push('(' + this._freitext + ')');
        }


        if (parts.length < 1) {
            return '';
        }
        return parts.join(' AND ');
    }


}
