import {SimpleFahrzeugFilter} from './simple-fahrzeug-filter';
import {Fahrzeug} from './fahrzeug';
import {IFahrzeug} from './ifahrzeug';
import {CommonList} from '../../../commons/common-list';
import {IListResult} from '../../../commons/list-result';
import {ResultMetaSortImpl} from '../../../commons/result-meta';

export class FahrzeugList extends CommonList<Fahrzeug> {
    public simpleFilter: SimpleFahrzeugFilter = new SimpleFahrzeugFilter();

    static fromListResult(res: IListResult<IFahrzeug>): FahrzeugList {
        const newList: FahrzeugList = new FahrzeugList();
        newList.clear();
        newList.simpleFilter.clear();

        if (res.meta) {
            newList.total  = res.meta.total || 0;
            newList.offset = res.meta.offset || 0;
            newList.size   = res.meta.size || 0;
            newList.page   = res.meta.page || 0;
            if (res.meta.order) {
                newList.order = [];
                for (const x of res.meta.order) {
                    newList.order.push(
                        new ResultMetaSortImpl(
                            x.field || 'nummer',
                            x.direction || 'asc'
                        )
                    );
                }
            }
        }
        if (res.data) {
            for (const row of res.data) {
                newList.add(Fahrzeug.fromResult(row));
            }
        }
        if (res.simpleFilter) {
            const d: Fahrzeug = new Fahrzeug();
            for (const k of Object.keys(d)) {
                newList.simpleFilter[ k ] = (res.simpleFilter[ k ] !== undefined) ? res.simpleFilter[ k ] : null;
            }
        }

        return newList;

    }
}
