import {ChangeDetectorRef, Component, ElementRef, Injector, NgZone, OnInit} from '@angular/core';

import {LocalizedComponent} from '../../commons/components/localized-component';
import {CellFilterChangedCallback} from '../../commons/data-table/filters/cell-filter.component';
import {Kunde} from './common/kunde';

@Component({
    selector: 'kunde-cell-filter',
    styles: [`
                               :host .add-on button:first-child {
                                   border-left: none;
                               }

                               :host .add-on button i {
                                   color: #888888;
                               }

                               :host ::ng-deep station-field .form-group {
                                   margin: 0;
                               }
                           `],
    template: `
                   <div class="input-group input-group-sm">
                       <kunde-inline-field
                               #tf
                               [small]="true"
                               [label]="placeholder"
                               [value]="currentValue"
                               (valueChange)="tsChange($event)"
                               [nullable]="false"
                       ></kunde-inline-field>
                       <div class="input-group-btn add-on">
                           <button type="button" class="btn btn-secondary btn-sm" (click)="clear()"
                                   title="{{ _('Eingabe löschen') }}">
                               <i class="icofont icofont-eraser"></i>
                           </button>
                       </div>
                   </div>
               `
})
export class KundeCellFilterComponent extends LocalizedComponent implements OnInit {

    field: string = 'field' + (Math.random() * 10000);
    placeholder = '';
    currentValue: any = '';
    delay = 400;

    filterChanged: CellFilterChangedCallback<Kunde> = (field2, val) => {
    }

    constructor(
        public injector: Injector,
        public elementRef: ElementRef,
        public zone: NgZone,
        public cd: ChangeDetectorRef) {
        super(cd);
        this.field = this.injector.get('field');
        this.placeholder = this.injector.get('placeholder');
        this.currentValue = this.injector.get('value');
        this.filterChanged = this.injector.get('onChange');
    }

    tsChange(ts: Kunde) {
        this.updateValue(ts);
    }

    ngOnInit() {
    }


    clear() {
        this.zone.runOutsideAngular(() => {
            this.updateValue(null);
        });
    }


    updateValue(input: Kunde) {
        if ((input === null) || (input === undefined)) {
            this.currentValue = null;
        } else {
            this.currentValue = input;
        }
        if ((this.filterChanged !== undefined) && (this.filterChanged !== null)) {
            this.filterChanged(this.field, this.currentValue);
        }
    }


}
