<app-login-alert>

    <app-area-heading
            [icon]="'icofont icofont-ui-messaging'"
            title="{{ _('Mitteilungen') }}"
    ></app-area-heading>

    <div class="card">
        <div class="card-body">
            <div class="card-text">
                <data-table
                        [selectable]="true"
                        [data]="messages.asDataTableRows()"
                        [orders]="messages.order"
                        [filter]="messages.simpleFilter"
                        (sortChanged)="sortChanged($event)"
                        (filterChanged)="filterChanged($event)"
                        [rowActionsAsButtons]="true"
                        (selectionChanged)="onSelectionChanged($event)"
                        #messageTable
                >

                    <tableaction
                            *ngIf="messageTable.hasSelectedRows()"
                            [label]="_('markierte Nachrichten löschen')"
                            (perform)="deleteSelected()"
                        ></tableaction>

                    <column
                            [field]="'prioritaet'"
                            [label]="_('Priorität')"
                            [renderer]="prioRenderer"
                            [sortable]="false"
                    ></column>

                    <column
                            [field]="'dateCreated'"
                            [label]="_('Zeitpunkt')"
                            type="Date"
                            [cfg]="{format:'medium'}"
                            [sortable]="false"
                    ></column>

                    <column [label]="_('Betreff')"
                            [field]="'subject'"
                            [sortable]="false"
                            [renderer]="subjectRenderer"
                    ></column>
                    <column
                            [field]="'read'"
                            [label]="_('gelesen?')"
                            type="boolean"
                            [sortable]="false"
                    ></column>
                    <column
                            [field]="'dateExpires'"
                            [label]="_('wird gelöscht ab')"
                            type="Date"
                            [cfg]="{format:'medium'}"
                            [sortable]="false"
                    ></column>

                    <rowaction
                            [label]="_('als gelesen markieren')"
                            [title]="_('als gelesen markieren')"
                            [icon]="'icofont icofont-eye'"
                            (perform)="markRead($event)"
                            (checkEnabled)="checkMessage('markRead',$event)"
                    ></rowaction>
                    <rowaction
                            [label]="_('als ungelesen markieren')"
                            [title]="_('als ungelesen markieren')"
                            [icon]="'icofont icofont-eye-blocked'"
                            (perform)="markUnRead($event)"
                            (checkEnabled)="checkMessage('markUnRead',$event)"
                    ></rowaction>
                    <rowaction
                            [label]="_('Löschen')"
                            [title]="_('Löschen')"
                            [icon]="'icofont icofont-ui-delete'"
                            (perform)="deleteMessage($event)"
                    ></rowaction>
                    <rowaction
                            [label]="_('ansehen')"
                            [title]="_('ansehen')"
                            [icon]="'icofont icofont-look'"
                            (perform)="readMessage($event)"
                    ></rowaction>

                    <data-table-table-extra-header>
                        <app-paginator
                                [total]="messages.total"
                                [page]="messages.page"
                                [perpage]="messages.size"
                                (pageChange)="updateMessages(true,$event)"
                                (pageCountChange)="updateMessagesPageCount($event)"
                                [showSearch]="true"
                                (queryChange)="messagesQueryChange($event)"
                        ></app-paginator>
                    </data-table-table-extra-header>
                </data-table>
            </div>
        </div>
    </div>

</app-login-alert>
