import {Point} from './point';

export class Area extends Point {
    width = 0;
    height = 0;


    constructor(x: number= 0, y: number= 0, width: number= 1, height: number= 1, mark: boolean= false, hide= false) {
        super(x, y, mark, hide);
        this.width = width;
        this.height = height;
    }
}
