import {Component, Input, OnInit} from "@angular/core";

@Component({
  selector: 'app-email-display',
  templateUrl: './email-display.component.html',
  styleUrls: ['./email-display.component.css']
})
export class EmailDisplayComponent implements OnInit {

  _addresse:string=null;
  _name:string=null;

  @Input()
  set email(value:string){
    this._addresse=value;
  }

  @Input()
  set name(value:string) {
    this._name=value;
  }

  constructor() {

  }

  ngOnInit() {
  }

}
