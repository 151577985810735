import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';

import {Fahrzeug} from '../common/fahrzeug';
import {LocalizedComponent} from '../../../commons/components/localized-component';

@Component({
    selector: 'app-fahrzeug-display',
    templateUrl: './fahrzeug-display.component.html',
    styleUrls: ['./fahrzeug-display.component.css']
})
export class FahrzeugDisplayComponent extends LocalizedComponent implements OnInit {

    get fahrzeugLabel() {
        return this._('Fahrzeug {{ nummer }}', {nummer: this._fahrzeug.nummer});
    }

    _fahrzeug: Fahrzeug = null;

    @Input()
    set fahrzeug(value: Fahrzeug) {
        this._fahrzeug = Fahrzeug.fromResult(value);
    }

    constructor(  public cd: ChangeDetectorRef) {
        super( cd);
    }

    ngOnInit() {
    }

}
