import {ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {NgbModal, NgbModalOptions, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';


import {LocalizedComponent} from '../commons/components/localized-component';
import {StationList} from './common/station-list';
import {KitchenTimer} from '../commons/kitchen-timer';
import {StationenService} from './common/stationen.service';
import {NewPageCountEvent} from '../commons/paginator/paginator.component';
import {StationenEditorComponent} from './stationen-editor/stationen-editor.component';
import {Station} from './common/station';
import {IStation} from './common/istation';
import {TanksModalComponent} from './tanks/editor/tanks-modal.component';
import {TerminalsModalComponent} from './terminals/terminals-modal.component';
import {SondenModalComponent} from './sonden/sonden-modal.component';
import {ZapfsaeulenModalComponent} from './saeulen/zapfsaeulen-modal.component';
import {Land} from '../commons/laender/common/land';
import {LandCellRendererComponent} from '../commons/data-table/renderer/land-cell-renderer.component';
import {Router} from '@angular/router';
import {SysInfoService} from '../commons/sysinfo/services/sys-info.service';
import {IListResult} from '../commons/list-result';
import {ToastrService} from 'ngx-toastr';
import {noop} from '../commons/noop';
import {StationenFilterDialogComponent} from './stationen-filter-dialog/stationen-filter-dialog.component';
import {DataTableComponent} from '../commons/data-table/data-table.component';
import {LoginService} from '../auth/services/login.service';
import {RowActionParams} from '../commons/data-table/row-action-params';
import {GeraeteDialogComponent} from '../devices/geraete-dialog/geraete-dialog.component';

@Component({
    selector: 'app-stationen',
    templateUrl: './stationen.component.html',
    styleUrls: ['./stationen.component.scss']
})
export class StationenComponent extends LocalizedComponent implements OnInit {

    landR = LandCellRendererComponent;

    stationen: StationList = new StationList();
    public tsTimer: KitchenTimer;
    @ViewChild('stationenListe',{static: true}) stationenListe: DataTableComponent;
    @ViewChild('filterDialog',{static: true}) filterDialog: StationenFilterDialogComponent;

    /**
     *
     * @param locale
     * @param translation
     * @param stationenService
     * @param toastr
     * @param cd
     * @param router
     * @param sysInfoService
     * @param modalService
     */
    constructor(

                public stationenService: StationenService,
                public toastr: ToastrService,
                public cd: ChangeDetectorRef,
                public router: Router,
                public sysInfoService: SysInfoService,
                public loginService: LoginService,
                public modalService: NgbModal) {

        super( cd);
        this.stationen.size = this.loginService.getPageSize('stationen');

        this.tsTimer = new KitchenTimer();
        this.tsTimer.subscribe(
            () => {
                this.stationenService
                    .list(
                        this.stationen.size,
                        this.stationen.calcOffset(),
                        this.stationen.order,
                        this.stationen.simpleFilter,
                        this.stationen.getQuery()
                    )
                    .subscribe(
                        (l: IListResult<IStation>) => {
                            this.stationen.populateFromListResult(l);
                        },
                        () => {
                            this.toastr.error(this._('Die Liste der Stationen konnte nicht geladen werden.'));
                            this.stationen.clear();
                        }
                    );
            }
        );
    }

    /**
     *
     */
    ngOnInit() {
        if (!this.tsTimer.wasFired()) {
            this.updateStationen();
        }
        this.sysInfoService.updateDocumentTitle(this._('Stationen'));
    }

    /**
     * lädt die Stationenliste neu
     * @param force - sofort neuladen
     * @param page - Seitennummer ändern
     */
    updateStationen(force = false, page?: number) {
        if ((page !== undefined) && (page != null)) {
            if (page !== this.stationen.page) {
                this.stationen.page = page;
                force = true;
            }
        }
        this.tsTimer.start(force);
    }

    /**
     * ändert die Anzahl der Datensätze/Seite und lädt die Liste neu
     * @param n
     */
    updateStationenPageCount(n: NewPageCountEvent) {
        if (this.stationen.size !== n.itemsPerPage) {
            this.stationen.size = n.itemsPerPage;
            this.loginService.setPageSize('stationen', n.itemsPerPage);
        }
        this.updateStationen(true, n.currentPage);
    }

    /**
     * Station anlegen
     */
    createStation() {
        const o: NgbModalOptions = {
            size: 'lg',
            backdrop: 'static'
        };
        const r: NgbModalRef = this.modalService.open(StationenEditorComponent, o);
        (r.componentInstance as StationenEditorComponent).editNew();
        r.result.then(
            e => {
                this.updateStationen(true);
            },
            () => {
                this.updateStationen(true);
            }
        );
    }

    /**
     * Station bearbeiten
     * @param event
     */
    editStation(event: RowActionParams<Station>) {
        const o: NgbModalOptions = {
            size: 'lg',
            backdrop: 'static'
        };
        const r: NgbModalRef = this.modalService.open(StationenEditorComponent, o);
        (r.componentInstance as StationenEditorComponent).edit(event.row.data);
        r.result.then(
            e => {
                this.updateStationen(true);
            },
            () => {
                this.updateStationen(true);
            }
        );
    }

    checkEnabled(what: string, params: RowActionParams<Station>) {
        if (what === 'station') {
            /*if (params.row.data.id === 'de') {
             params.row.disableAction(params.action);
             }*/
        }
    }

    editTanks(event: RowActionParams<Station>) {
        const station: IStation = event.row.data;
        const o: NgbModalOptions = {
            size: 'lg',
            backdrop: 'static'
        };
        const r: NgbModalRef = this.modalService.open(TanksModalComponent, o);
        (r.componentInstance as TanksModalComponent).edit(event.row.data);
        r.result.then(
            noop,
            noop
        );
    }


    editTerminals(event: RowActionParams<Station>) {
        const station: IStation = event.row.data;
        const o: NgbModalOptions = {
            /*size: 'lg',*/
            backdrop: 'static'
        };
        const r: NgbModalRef = this.modalService.open(TerminalsModalComponent, o);
        (r.componentInstance as TerminalsModalComponent).edit(event.row.data);
        r.result.then(
            e => {
            },
            () => {
            }
        );
    }


    editSonden(event: RowActionParams<Station>) {
        const station: IStation = event.row.data;
        const o: NgbModalOptions = {
            size: 'lg',
            backdrop: 'static'
        };
        const r: NgbModalRef = this.modalService.open(SondenModalComponent, o);
        (r.componentInstance as SondenModalComponent).edit(event.row.data);
        r.result.then(
            e => {
            },
            () => {
            }
        );
    }

    editSaeulen(event: RowActionParams<Station>) {
        const station: IStation = event.row.data;
        const o: NgbModalOptions = {
            size: 'lg',
            backdrop: 'static'
        };
        const r: NgbModalRef = this.modalService.open(ZapfsaeulenModalComponent, o);
        (r.componentInstance as ZapfsaeulenModalComponent).edit(event.row.data);
        r.result.then(
            e => {
            },
            () => {
            }
        );
    }

    syncStationen() {
        this.stationenService
            .syncStationen()
            .subscribe(
                () => {
                    this.toastr.success(this._('Der Synchronisationsvorgang wurde angestoßen.'));
                },
                () => {
                    this.toastr.error(this._('Die Synchronisationsvorgang konnte nicht angestoßen werden.'));
                }
            );
    }

    reindexStationen() {
        this.stationenService
            .reindexStationen()
            .subscribe(
                () => {
                    this.toastr.success(this._('Der Indizierungsvorgang wurde angestoßen.'));
                },
                () => {
                    this.toastr.error(this._('Die Indizierungsvorgang konnte nicht angestoßen werden.'));
                }
            );
    }


    stationenQueryChange(value: string) {
        if (value !== this.stationen.searchString) {
            this.stationen.searchString = value;
            this.updateStationen(false, 1);
        }
    }

    filterStationen($event: Land) {
        if ($event == null) {
            this.stationen.searchString2 = '';
        } else {
            this.stationen.searchString2 = '( _land_id:' + $event.id + ' )';
        }
        this.updateStationen(true, 1);
    }

    gotoBestand($event: RowActionParams<Station>) {
        this.router.navigate(
            ['bestand'],
            {
                queryParams: {
                    s: $event.row.data.id
                }
            });
    }

    syncRatioStationen() {
        this.stationenService
            .syncRatioStationen()
            .subscribe(
                () => {
                    this.toastr.success(this._('Der Importvorgang wurde angestoßen.'));
                },
                () => {
                    this.toastr.error(this._('Die Importvorgang konnte nicht angestoßen werden.'));
                }
            );
    }
 stationenQueryChange2(value: string) {
        if (value !== this.stationen.searchString2) {
            this.stationen.searchString2 = value;
            this.updateStationen(false, 1);
        }
    }

    stationenFilterToggle(visible: boolean) {
        if (!visible) {
            this.stationenQueryChange2('');
        }
    }

    editPeriphery(event: RowActionParams<Station>) {
        GeraeteDialogComponent
            .open(this.modalService, event.row.data)
            .then(
                noop
            );
    }

    sortChanged($event) {
        console.log($event);
    }

    filterChanged($event) {
        console.log($event);
    }
}
