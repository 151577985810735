import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, TimeoutError} from 'rxjs';
import {KitchenTimer} from '../../kitchen-timer';
import {LoginService} from '../../../auth/services/login.service';
import {ActionResponse, IActionResponse} from '../../action-response';
import {take, tap, timeout} from 'rxjs/operators';
import {PubSubService} from '../../pub-sub.service';
import {appName} from '../../../../app.version';
import {noop} from '../../noop';
import CryptoES from "crypto-es";

var safari;

@Injectable({
    providedIn: 'root'
})
export class SysInfoService {

    private onlineTimer: KitchenTimer = null;
    private sysInfoTimer: KitchenTimer = null;
    private _onlineFailed = 0;
    private _sysInfoHash = '';
    private _telegramBot = '';

    constructor(public httpClient: HttpClient,
                public loginService: LoginService,
                public pubsub: PubSubService
    ) {

        this.onlineTimer = new KitchenTimer(10000);
        this.onlineTimer.subscribe(
            () => {
                this.doOnlineCheck();
            },
            undefined
        );
        this.sysInfoTimer = new KitchenTimer(60000);
        this.sysInfoTimer.subscribe(
            () => {
                this.fetchSysInfo();
            },
            undefined
        );
        this.doOnlineCheck();
        this.fetchSysInfo();
    }

    private _isOnline = true;

    get isOnline(): boolean {
        return (this._isOnline === null) ? false : this._isOnline;
    }

    private _developmentMode: boolean = null;

    set developmentMode(value: boolean) {
        if (value !== this._developmentMode) {
            this._developmentMode = value;
            this.pubsub.developmentModeChange.emit(value);
        }
    }

    private _loginMessage: string = null;

    get loginMessage(): string {
        return (this._loginMessage === null) ? '' : this._loginMessage;
    }

    set loginMessage(value: string) {
        if (value !== this._loginMessage) {
            this._loginMessage = value;
            this.pubsub.loginMessageChange.emit(this._loginMessage);
        }
    }

    private _adminSystemMessage: string = null;

    get adminSystemMessage(): string {
        return (this._adminSystemMessage === null) ? '' : this._adminSystemMessage;
    }

    set adminSystemMessage(value: string) {
        if (value !== this._adminSystemMessage) {
            this._adminSystemMessage = value;
            this.pubsub.adminSystemMessageChange.emit(this._adminSystemMessage);
        }
    }

    private _sysInfo: any = {};

    get sysInfo(): any {
        return this._sysInfo;
    }

    set sysInfo(value: any) {
        if (value === null || value === undefined) {
            value = {};
        }
        let h = 'sysInfo';
        Object.keys(value).forEach((k: string) => {
            h = h + '\n' + k + ':' + JSON.stringify(value[k]);
        });
        const h1 = CryptoES.MD5(h).toString() as string;
        if (h1 !== this._sysInfoHash) {
            this._sysInfo = value;
            this._sysInfoHash = h1;
            this.pubsub.sysInfoChange.emit(this.sysInfo);
        }
    }

    private _modules = [];

    get modules() {
        return this._modules;
    }

    set modules(mods: string[]) {
        this._modules = mods;
        this.pubsub.modulesChange.emit(this._modules);
    }

    private _sysmode = '';

    get sysmode(): string {
        return ('' + this._sysmode).trim().toUpperCase();
    }

    get isDevelopmentMode(): boolean {
        return this._developmentMode;
    }

    set online(value: boolean) {
        if (value !== this._isOnline) {
            this.pubsub.onlineStateChange.emit(value);
            if (value) {
                this.fetchSysInfo();
            }
        }
        this._isOnline = value;
    }

    isAdmin() {
        return this.loginService.hasOneOfRoles(['ROLE_ADMIN']);
    }

    isProjectAdmin() {
        return this.loginService.hasOneOfRoles(['ROLE_ADMIN', 'ROLE_PROJECT_ADMIN']);
    }

    fetchSysInfo() {
        this.loadSysInfo().subscribe(() => {
            this.sysInfoTimer.start();
            noop();
        });
    }

    doOnlineCheck() {
        this.checkOnline()
            .subscribe((r: IActionResponse) => {
                    this._onlineFailed = 0;
                    if (r.success) {
                        this.online = true;
                        this.onlineTimer.setTimeout(30000);
                    } else {
                        this.online = false;
                        this.onlineTimer.setTimeout(10000);
                    }
                    this.onlineTimer.start();
                },
                (e) => {
                    if (e !== null && e !== undefined && (e instanceof TimeoutError || e.name === 'TimeoutError')) {
                        try {
                            this.pubsub.afterRequest.emit('afterRequestEvent');
                        } catch (ignored) {
                        }
                    }
                    if (this._isOnline) {
                        this._onlineFailed = this._onlineFailed + 1;
                        if (this._onlineFailed > 2) {
                            this.online = false;
                        }
                        this.onlineTimer.setTimeout(2500);
                    }
                    this.onlineTimer.start();
                }
            );
    }

    loadSysInfo(): Observable<IActionResponse> {
        return this.httpClient
            .get<IActionResponse>(
                '/resources/sysinfo',
                {
                    headers: this.loginService.getHttpHeaders()
                }
            )
            .pipe(
                tap((s: IActionResponse) => {
                    const r = ActionResponse.fromRawActionResponse(s);
                    if (r.success) {
                        this.developmentMode = r.params['developmentMode'];
                        this.adminSystemMessage = r.params['adminSystemMessage'];
                        this.loginMessage = r.params['loginSystemMessage'];
                        this.sysInfo = r.params;
                        this.modules = r.params['modules'] || [];
                        this._sysmode = r.params['systemMode'] || 'unknown';
                        this._telegramBot = r.params['telegramBot'] || '';
                    }
                    this.sysInfoTimer.start();
                }),
                take(1)
            );
    }

    updateDocumentTitle(title = '') {
        title = ('' + title).trim();
        if (title !== '') {
            title = title + ' - ';
        }
        title = title + appName;
        window.document.title = title;
    }

    hasModule(mod: string) {
        let found = false;
        this._modules.forEach((m: string) => {
            if (m.toLocaleLowerCase() === mod.toLocaleLowerCase()) {
                found = true;
            }
        });
        return found;
    }

    seesTransactions() {
        return (
            this.loginService.isAdmin() || (
                this.hasModule('transactions') &&
                this.loginService.hasOneOfRoles([
                    'ROLE_ADMIN', 'ROLE_PROJECT_ADMIN',
                    'ROLE_BILLING', 'ROLE_CUSTOMER',
                    'ROLE_TRANSAKTIONEN', 'ROLE_TRANSAKTIONEN_WRITABLE'
                ]) &&
                this.loginService.hasEdition('controller')
            )
        );
    }

    seesWashNClean() {
        return (
            this.hasModule('washnclean') &&
            this.loginService.hasOneOfRoles(['ROLE_WASHNCLEAN', 'ROLE_ADMIN', 'ROLE_PROJECT_ADMIN', 'ROLE_BILLING'])
        );
    }

    seesSonden() {
        return this.seesTankueberwachung();
    }

    seesTankueberwachung() {
        if (this.isAdmin()) {
            return true;
        }

        if (!this.hasModule('tankueberwachung')) {
            return false;
        }

        if (this.isProjectAdmin()) {
            return true;
        }

        if (!this.loginService.hasOneOfRoles(['ROLE_BESTAND', 'ROLE_BESTAND_WRITABLE'])) {
            return false;
        }

        if (this.hasEditionen()) {
            return this.loginService.hasEdition('basis');
        }

        return true;
    }

    seesVideoControl() {
        return (
            this.hasModule('videocontrol') &&
            this.loginService.hasOneOfRoles(['ROLE_VIDEOCONTROL', 'ROLE_ADMIN', 'ROLE_PROJECT_ADMIN']) &&
            this.loginService.hasEdition('basis')
        );
    }

    seesTags() {
        return (
            this.isAdmin() ||
            (
                this.isProjectAdmin() && this.loginService.hasEdition('basis')
            )
        );
    }

    hasExport(suffix = '') {
        if (suffix === 'kartenanfragen') {
            return (
                this.hasModule('export-kartenanfragen') &&
                this.loginService.hasOneOfRoles(['ROLE_ADMIN', 'ROLE_PROJECT_ADMIN', 'ROLE_BILLING', 'ROLE_ADM', 'ROLE_CUSTOMER', 'ROLE_KARTENANFRAGEN'])
            );
        }
        return (
            this.hasModule('export' + (suffix !== '' ? '-' + suffix : ''))
        );
    }

    seesSyssettings() {
        return this.loginService.hasOneOfRoles(['ROLE_ADMIN', 'ROLE_PROJECT_ADMIN', 'ROLE_BILLING']);
    }

    seesUsers() {
        return (
            (this.hasModule('user-control') &&
                this.loginService.hasOneOfRoles(['ROLE_ADMIN', 'ROLE_PROJECT_ADMIN']))
            || this.loginService.hasRole('ROLE_ADMIN')
        );
    }

    seesProducts() {
        return this.loginService.isAdmin() ||
            (
                this.hasModule('products') &&
                this.loginService.hasOneOfRoles(['ROLE_ADMIN', 'ROLE_PROJECT_ADMIN', 'ROLE_PRICING', 'ROLE_PRODUCTS', 'ROLE_BILLING'])
            );
    }

    seesStations() {
        return this.loginService.isAdmin() ||
            (
                !this.seesZutrittskontrolle() &&
                this.loginService.isLoggedIn() &&
                this.loginService.hasEdition('controller')
            );
    }

    seesPricing() {
        return (
            this.hasModule('pricing') &&
            this.loginService.hasEdition('controller') &&
            this.loginService.hasOneOfRoles(['ROLE_ADMIN', 'ROLE_PROJECT_ADMIN', 'ROLE_PRICING'])
        );
    }

    seesCustomers() {
        return (
            this.hasModule('customers') &&
            this.loginService.hasEdition('basis') &&
            this.loginService.hasOneOfRoles(['ROLE_ADMIN', 'ROLE_PROJECT_ADMIN',
                'ROLE_BILLING', 'ROLE_KUNDEN', 'ROLE_KUNDEN_WRITABLE', 'ROLE_ADM'])
        );
    }

    seesCars() {
        return (
            this.hasModule('cars') &&
            this.loginService.hasEdition('basis') &&
            this.loginService.hasOneOfRoles(['ROLE_ADMIN', 'ROLE_PROJECT_ADMIN', 'ROLE_CUSTOMER', 'ROLE_FAHRZEUGE', 'ROLE_FAHRZEUGE_WRITABLE'])
        );
    }

    seesPersons() {
        return (this.hasModule('persons') &&
            this.loginService.hasOneOfRoles(['ROLE_ADMIN', 'ROLE_PROJECT_ADMIN', 'ROLE_CUSTOMER', 'ROLE_PERSONEN', 'ROLE_PERSONEN_WRITABLE']) &&
            this.loginService.hasEdition('basis')
        );
    }

    seesCards() {
        if (!this.hasModule('cards')) {
            return false;
        }
        if (!this.loginService.hasOneOfRoles(['ROLE_ADMIN', 'ROLE_PROJECT_ADMIN', 'ROLE_CUSTOMER', 'ROLE_AUSWEISE', 'ROLE_AUSWEISE_WRITABLE'])) {
            return false;
        }

        if (this.hasModule('editionen')) {
            return this.loginService.hasEdition('controller');
        }
        return true;
    }

    seesKartenanfragen() {
        return (
            this.loginService.isAdmin() ||
            (this.hasModule('kartenanfragen') &&
                this.loginService.hasEdition('controller') &&
                this.loginService.hasOneOfRoles(['ROLE_ADMIN', 'ROLE_PROJECT_ADMIN', 'ROLE_BILLING', 'ROLE_CUSTOMER', 'ROLE_KARTENANFRAGEN']))
        );
    }

    seesInvoices() {
        return (
            this.hasModule('invoices') &&
            this.loginService.hasEdition('basis') &&
            this.loginService.hasOneOfRoles(['ROLE_ADMIN', 'ROLE_PROJECT_ADMIN', 'ROLE_BILLING', 'ROLE_CUSTOMER'])
        );
    }

    seesLieferscheine() {
        return (
            this.hasModule('lieferscheine') &&
            this.loginService.hasEdition('basis') &&
            this.loginService.hasOneOfRoles(['ROLE_ADMIN', 'ROLE_PROJECT_ADMIN', 'ROLE_BILLING', 'ROLE_BESTAND_WRITABLE'])
        );
    }

    seesBestandrechnung() {
        return (
            this.hasModule('bestandrechnung') &&
            this.loginService.hasEdition('basis') &&
            this.loginService.hasOneOfRoles(['ROLE_ADMIN', 'ROLE_PROJECT_ADMIN', 'ROLE_BILLING', 'ROLE_BESTAND', 'ROLE_BESTAND_WRITABLE'])
        );
    }

    canExportTransaktionen() {
        return (
            this.seesTransactions() &&
            this.hasExport('transactions') &&
            this.loginService.hasEdition('basis') &&
            this.loginService.hasOneOfRoles(['ROLE_ADMIN', 'ROLE_PROJECT_ADMIN', 'ROLE_BILLING', 'ROLE_TRANSAKTIONEN'])
        );
    }

    canImportTransactions() {
        return (
            this.seesTransactions() &&
            this.hasModule('transactions-import') &&
            this.loginService.hasEdition('basis') &&
            this.loginService.hasOneOfRoles(['ROLE_ADMIN', 'ROLE_PROJECT_ADMIN', 'ROLE_BILLING', 'ROLE_TRANSAKTIONEN_WRITABLE'])
        );
    }

    hasNacherfassung() {
        return (
            this.seesTransactions() &&
            this.hasModule('nacherfassung') &&
            this.loginService.hasEdition('basis') &&
            this.loginService.hasOneOfRoles(['ROLE_ADMIN', 'ROLE_PROJECT_ADMIN', 'ROLE_BILLING', 'ROLE_TRANSAKTIONEN_WRITABLE'])
        );
    }

    seesKostenstellen() {
        return (
            this.hasModule('kostenstellen') &&
            this.loginService.hasOneOfRoles(['ROLE_ADMIN', 'ROLE_PROJECT_ADMIN']) &&
            this.loginService.hasEdition('basis')
        );
    }

    seesExtendedPricing() {
        return (
            this.seesPricing() &&
            this.hasModule('pricing-extended') &&
            this.loginService.hasEdition('basis') &&
            this.loginService.hasOneOfRoles(['ROLE_ADMIN', 'ROLE_PROJECT_ADMIN', 'ROLE_BILLING'])
        );
    }

    seesCardOptions() {
        return (
            this.seesCards() &&
            this.loginService.hasEdition('basis') &&
            this.hasModule('card-options')
        );
    }

    seesZutrittskontrolle() {
        return (
            this.hasModule('zutrittskontrolle') &&
            this.loginService.hasEdition('basis') &&
            this.loginService.hasOneOfRoles(['ROLE_ADMIN', 'ROLE_PROJECT_ADMIN', 'ROLE_ACCESS_CONTROL', 'ROLE_ZUTRITTSKONTROLLE'])
        );
    }

    canEditTerminals() {
        return (
            (this.seesZutrittskontrolle() ||
                this.seesStations())
            &&
            this.loginService.hasOneOfRoles(['ROLE_ADMIN', 'ROLE_PROJECT_ADMIN']) &&
            this.loginService.hasEdition('basis')
        );
    }

    hasDashboard() {
        if (this.isAdmin()) {
            return true;
        }
        if (!this.loginService.isLoggedIn()) {
            return false;
        }

        if (!this.hasModule('dashboard')) {
            return false;
        }

        if (this.isProjectAdmin()) {
            return true;
        }

        if (this.loginService.hasRole('NO_DASHBOARD')) {
            return false;
        }

        if (this.hasEditionen()) {
            return this.loginService.hasEdition('controller');
        }
        return true;
    }

    canEditSaeulen() {
        return this.canEditTerminals();
    }

    canEditDoors() {
        return this.canEditTerminals();
    }

    canEditCards() {
        if (!this.seesCards()) {
            return false;
        }

        if (!this.loginService.hasOneOfRoles(['ROLE_ADMIN', 'ROLE_PROJECT_ADMIN', 'ROLE_AUSWEISE_WRITABLE'])) {
            return false;
        }

        if (this.hasModule('editionen')) {
            return this.loginService.hasEdition('pro');
        }
        return true;
    }

    navUsesGroups() {
        return this.hasModule('nav-groups');
    }

    hasArtikelGruppen() {
        return this.hasModule('artikelgruppen');
    }

    hasTelegram() {
        return this.hasModule('telegram') && (this.getTelegramBotName() !== '');
    }

    hasMessengers() {
        return this.hasTelegram();
    }

    seesArtikelGruppen() {
        return this.hasArtikelGruppen() && this.isProjectAdmin();
    }

    isOpera() {
        // @ts-ignore
        return (!!window.opr && !!opr.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;
    }

    // <editor-fold desc="Browsererkennung">
    // @formatter:off

    isFirefox() {
        // @ts-ignore
        return typeof InstallTrigger !== 'undefined';
    }

    // @ts-ignore
    isSafari() {
        // @ts-ignore
        return /constructor/i.test(window.HTMLElement) || (function (p) {
            return p.toString() === '[object SafariRemoteNotification]';
        })(!window['safari'] || safari.pushNotification);
    }

    isIE() {
        // @ts-ignore
        return /*@cc_on!@*/false || !!document.documentMode;
    }

    isEdge() {
        // @ts-ignore
        return !this.isIE() && !!window.StyleMedia;
    }

    isChrome1() {
        // @ts-ignore
        return !!window.chrome && !!window.chrome.webstore;
    }

    isChrome() {
        // @ts-ignore
        return !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);
    }

    isBlink() {
        // @ts-ignore
        return (this.isChrome() || this.isOpera()) && !!window.CSS;
    }

    getTelegramBotName() {
        if (this._telegramBot === null || this._telegramBot === undefined) {
            return '';
        }
        return ('' + this._telegramBot).trim();
    }

// @formatter:on
    // </editor-fold>

    public hasCards() {
        return this.hasModule('cards');
    }

    isCustomer() {
        return this.loginService.hasRole('ROLE_CUSTOMER');
    }

    isAccessAllowed(path: string) {
        if (this.isAdmin()) {
            return true;
        }
        if (!this.hasModule('editionen')) {
            return this.loginService.isLoggedIn();
        }
        if (path === 'messages') {
            return this.loginService.isLoggedIn();
        } else if (path === 'stationen') {
            return this.seesStations();
        } else if (path === 'preise') {
            return this.seesPricing();
        } else if (path === 'sonden') {
            return this.seesSonden();
        } else if (path === 'kartenanfragen') {
            return this.seesKartenanfragen();
        } else if (path === 'transaktionen') {
            return this.seesTransactions();
        } else if (path === 'ausweise') {
            return this.seesCards();
        } else if (path === 'tankueberwachung') {
            return this.seesTankueberwachung();
        } else {
            // Dieser Pfad sollte niemals erreicht werden
            console.log('Unbekannter Pfad: ' + path);
            window.alert('Unbekannter Pfad: ' + path);
        }
        return false;
    }

    canPrintLieferschein() {
        return (this.isAdmin() ||
            (
                this.seesTransactions() &&
                this.loginService.hasOneOfRoles(['ROLE_ADMIN', 'ROLE_BILLING', 'ROLE_PROJECT_ADMIN', 'ROLE_CUSTOMER', 'ROLE_ADM', 'ROLE_TRANSAKTIONEN'])
                && this.loginService.hasEdition('super')
            ));
    }

    isLoggedIn() {
        return this.loginService.isLoggedIn();
    }

    prefersBrutto() {
        return this.hasModule('prefers_brutto');
    }

    canLockCards() {
        if (!this.seesCards()) {
            return false;
        }

        if (!this.loginService.hasOneOfRoles(['ROLE_ADMIN', 'ROLE_PROJECT_ADMIN', 'ROLE_AUSWEISE_WRITABLE', 'ROLE_CUSTOMER'])) {
            return false;
        }

        if (this.hasModule('editionen')) {
            return this.loginService.hasEdition('controller');
        }
        return true;
    }

    canUnlockCards() {
        if (!this.seesCards()) {
            return false;
        }

        if (!this.loginService.hasOneOfRoles(['ROLE_ADMIN', 'ROLE_PROJECT_ADMIN', 'ROLE_AUSWEISE_WRITABLE', 'ROLE_CUSTOMER'])) {
            return false;
        }

        if (this.hasModule('editionen')) {
            return this.loginService.hasEdition('basis');
        }
        return true;
    }

    hasEditionen() {
        return false;
        // return this.hasModule('editionen');
    }

    canEditSonden() {
        if (this.isAdmin()) {
            return true;
        }

        if (!this.seesSonden()) {
            return false;
        }

        if (this.isProjectAdmin()) {
            return true;
        }

        return this.loginService.hasOneOfRoles(['ROLE_SONDEN_WRITABLE']);
    }

    canEditSondenAlarmSettings() {
        if (this.isAdmin()) {
            return true;
        }

        if (this.canEditSonden()) {
            return true;
        }

        return this.loginService.hasOneOfRoles(['ROLE_SONDEN_ALARM_SETTINGS']);
    }

    seesTankbestand() {
        return this.seesTankueberwachung();
    }

    isGlukoseportal() {
        return this.sysmode === 'GLUCOSE';
    }

    canChangePin() {
        if (this.isAdmin() || this.isProjectAdmin()) {
            return true;
        }
        return this.loginService.hasOneOfRoles(['ROLE_AUSWEISE_WRITABLE', 'ROLE_AUSWEISE_CHANGE_PIN']);
    }

    canExportAusweise() {
        if (this.isAdmin() || this.isProjectAdmin()) {
            return true;
        }
        if (!this.seesCards()) {
            return false;
        }
        return this.loginService.hasOneOfRoles(['ROLE_AUSWEISE_EXPORT']);
    }

    private checkOnline(): Observable<IActionResponse> {
        return this.httpClient
            .get<IActionResponse>(
                '/resources/online',
                {
                    headers: this.loginService.getHttpHeaders()
                }
            )
            .pipe(
                timeout(2000),
                tap((r: IActionResponse) => {
                    this.online = r.success;
                }),
                take(1)
            );
    }
}
