import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, ViewChild} from '@angular/core';
import {LocalizedComponent} from '../../../commons/components/localized-component';

import {TankBestandList} from '../common/tank-bestand-list';
import {ProduktCellRendererComponent} from '../../../products/produkt-cell-renderer.component';
import {LiterCellRendererComponent} from '../../../commons/components/liter-cell-renderer.component';
import {StationCellRendererComponent} from '../../../stationen/station-cell-renderer.component';
import {TankCellRendererComponent} from '../../../stationen/tank-cell-renderer.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {TankBestandService} from '../service/tank-bestand.service';
import {IListResult} from '../../../commons/list-result';
import {ITankBestand} from '../common/itank-bestand';
import {IStation} from '../../../stationen/common/istation';
import {Station} from '../../../stationen/common/station';
import {ITank} from '../../../stationen/tanks/common/itank';
import {Tank} from '../../../stationen/tanks/common/tank';
import {DataTableComponent, DataTableFilterChanged, DataTableSortChanged} from '../../../commons/data-table/data-table.component';
import {ResultMetaSortImpl} from '../../../commons/result-meta';
import {StationenService} from '../../../stationen/common/stationen.service';
import {noop} from 'rxjs';
import {NewPageCountEvent} from '../../../commons/paginator/paginator.component';
import {ActivatedRoute} from '@angular/router';
import {SysInfoService} from '../../../commons/sysinfo/services/sys-info.service';
import {ConfirmDialogComponent} from '../../../commons/confirm-dialog/confirm-dialog.component';
import {ToastrService} from 'ngx-toastr';
import {TankbestandFilterdialogComponent} from '../tankbestand-filterdialog/tankbestand-filterdialog.component';
import {PreisWertCellRendererComponent} from '../../../commons/data-table/renderer/preis-wert-cell-renderer.component';
import {LoginService} from '../../../auth/services/login.service';
import * as FileSaver from 'file-saver';
import {TankBestand} from '../common/tank-bestand';
import {DataTableRow} from '../../../commons/data-table/data-table-row';
import {TDTGetExtraRowClassesCallback} from '../../../commons/data-table/types';

@Component({
    selector: 'app-bestandsuebsersicht',
    templateUrl: './bestandsuebsersicht.component.html',
    styleUrls: ['./bestandsuebsersicht.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class BestandsuebsersichtComponent extends LocalizedComponent implements OnInit {


    private _recalcsPending=0;
    toHandle:any=0;


    get recalcsPending(): number {
        return this._recalcsPending;
    }

    set recalcsPending(value: number) {
        if (this._recalcsPending !== value) {
            this._recalcsPending = value;
            this.cd.markForCheck();
            if (value > 0) {
                if(this.toHandle!==0){
                    clearTimeout(this.toHandle);
                }
                this.toHandle= setTimeout(() => {
                    this.updateRecalcsPending();
                    this.toHandle=0;
                },60000);
            }
        }
    }
    
    @Input()
    set currentTank(v: ITank) {
        this._tank = Tank.fromResult(v);
        this.updateBestandList(false, 1);
        this.cd.markForCheck();
    }

    @Input()
    set currentStation(s: IStation) {
        this._station = Station.fromResult(s);
        this.updateBestandList(false, 1);
        this.cd.markForCheck();
    }

    constructor(


        public toastr: ToastrService,
        public modalService: NgbModal,
        public tankBestandService: TankBestandService,
        public route: ActivatedRoute,
        public stationService: StationenService,
        public cd: ChangeDetectorRef,
        public sysInfoService: SysInfoService,
        public loginService: LoginService
    ) {
        super( cd);
        this.bestandList.size = this.loginService.getPageSize('bestandsuebersicht');
        if (this.route.snapshot.queryParams['t'] !== null &&
            this.route.snapshot.queryParams['t'] !== undefined &&
            !isNaN(parseInt(this.route.snapshot.queryParams['t'], 10))) {
            this.stationService
                .loadTank(parseInt(this.route.snapshot.queryParams['t'], 10))
                .subscribe(
                    (t: ITank) => {
                        this.currentTank = t;
                    },
                    noop
                );

        }

        if (this.route.snapshot.queryParams['s'] !== null &&
            this.route.snapshot.queryParams['s'] !== undefined &&
            !isNaN(parseInt(this.route.snapshot.queryParams['s'], 10))) {
            this.stationService
                .loadStation(parseInt(this.route.snapshot.queryParams['s'], 10))
                .subscribe(
                    (s: IStation) => {
                        this.currentStation = s;
                    },
                    noop
                );

        }

    }

    tankR = TankCellRendererComponent;
    stationR = StationCellRendererComponent;
    literR = LiterCellRendererComponent;
    produktR = ProduktCellRendererComponent;
    public preiswert = PreisWertCellRendererComponent;

    _tank: Tank = null;
    _station: Station = null;
    @ViewChild('tankbestandListe', {static: false}) tankbestandListe: DataTableComponent;
    @ViewChild('filterDialog', {static: false}) filterDialog: TankbestandFilterdialogComponent;

    bestandList: TankBestandList = new TankBestandList();

    rowClasses: TDTGetExtraRowClassesCallback<TankBestand> = (row: DataTableRow<TankBestand>) => {
        let c=[];
        if (
            row !== null &&
            row !== undefined &&
            row.data !== null &&
            row.data !== undefined
        ) {

            const tb = row.data as TankBestand;
            if (tb.bestandPeilung !== null &&
                tb.bestandPeilung !== undefined &&
                tb.bestand !== undefined &&
                tb.bestand !== null &&
                ('' + tb.bestandPeilung).trim() !== '' &&
                tb.abweichung !== null &&
                tb.abweichung !== undefined
            ) {
                if (Math.abs(tb.abweichung) > 2500) {
                    c.push( 'abweichung-fail');
                }else if (Math.abs(tb.abweichung) > 1000) {
                    c.push( 'abweichung-warn');
                }
            }
            if (row.data.needsRecalc){
c.push('text-gray')
            }

        }
        return c.join(' ');
    }

    ngOnInit() {
        this.bestandList.onUpdateRequired.subscribe(
            (l: TankBestandList) => {
                this.tankBestandService
                    .list(l.size,
                        l.calcOffset(),
                        l.order,
                        l.simpleFilter,
                        l.getQuery(),
                        this._tank,
                        this._station
                    ).subscribe(
                    (r: IListResult<ITankBestand>) => {
                        l.doPopulateFromListResult(r);
                        this.cd.markForCheck();
                    },
                    (e) => {
                        this.toastr.error(this._('Die Liste der Bestandsdaten konnte nicht geladen werden.'));
                        l.clear();
                        this.cd.markForCheck();
                    }
                );
            },
            noop
        );

        this.updateBestandList();
        this.sysInfoService.updateDocumentTitle(this._('Bestandsbersicht'));
    }

    updateBestandList(force = false, page?: number) {
        if ((page !== undefined) && (page !== null)) {
            if (page !== this.bestandList.page) {
                this.bestandList.page = page;
                force = true;
            }
        }
        if (force) {
            this.bestandList.reload();
        } else {
            this.bestandList.start();
        }

        this.updateRecalcsPending();
    }

    updateBestandListPageCount(n: NewPageCountEvent) {
        if (this.bestandList.size !== n.itemsPerPage) {
            this.bestandList.size = n.itemsPerPage;
            this.loginService.setPageSize('bestandsuebersicht', n.itemsPerPage);
        }
        this.updateBestandList(true, n.currentPage);
    }


    sortChanged(e: DataTableSortChanged) {
        this.bestandList.order = [
            new ResultMetaSortImpl(e.field, e.direction)
        ];
        this.updateBestandList();
    }

    filterChanged(e: DataTableFilterChanged) {
        this.bestandList.simpleFilter[e.field] = e.value;
        this.updateBestandList(false, 1);
    }


    bestandListQueryChange($event: string) {
        if (this.bestandList.searchString !== $event) {
            this.bestandList.searchString = $event;
            this.updateBestandList(false, 1);
        }
    }

    recalc(n: number = null) {
        ConfirmDialogComponent
            .display(
                this.modalService,
                this._('Neuberechnung durchführen?'),
                this._('Soll die Neuberechnung des Tankbestandes nun gestartet werden? Hierbei ' +
                    'werden alle Datensätze anhand der Transaktionen und Lieferscheine neu berechnet.<br /><br />' +
                    '<b>Dieser Vorgang kann einige Zeit in Anspruch nehmen und ist, solange die Berechnung andauert, ' +
                    'nicht in den angezeigten Werten erkennbar. Erst nach Abschluss der Berechnung sind die ' +
                    'gezeigten Werte aktuell. (Richtwert zur Verabeitungszeit: ca. 10sec pro Tank und Monat)</b><br /><br />' +
                    'Sie erhalten eine Mitteilung ins Postfach, wenn die Berechnung abgeschlossen ist.'),
                'Neuberechnung starten',
                'icofont icofont-calculator'
            )
            .result
            .then(
                () => {

                    this.tankBestandService
                        .recalc(this._station, this._tank, n)
                        .subscribe(
                            (r) => {
                                if (r.success) {
                                    this.toastr.success(this.t('Über den Abschluss der Neuberechnung werden Sie per Nachricht im Postfach informiert.'), this.t('Die Neuberechnung des Tankbestandes wurde gestartet.'));
                                } else {
                                    this.toastr.error(this.t('Die Neuberechnung des Tankbestandes konnte nicht gestartet werden.'));
                                }
                            },
                            () => {
                                this.toastr.error(this.t('Die Neuberechnung des Tankbestandes konnte nicht gestartet werden.'));
                            }
                        );
                },
                noop
            );
    }

    bestandFilterToggle(visible: boolean) {
        if (!visible) {
            this.bestandQueryChange2('');
        }
    }

    bestandQueryChange2(value: string) {
        if (value !== this.bestandList.searchString2) {
            this.bestandList.searchString2 = value;
            this.updateBestandList(false, 1);
        }
    }

    downloadEntries(type: string, exporttype: number = 0) {
        if (this.bestandList.total < 1) {
            this.toastr.error(this._(
                'Es werden keine Datensätze angezeigt, daher gibt es auch nichts zum Herunterladen.'));
        } else if (this.bestandList.total < 500) {
            this.realDlEntries(type, exporttype);
        } else if (this.bestandList.total > 25000) {
            this.toastr.error(
                this._('Sie wollen {{ anzahl }} Datensätze herunterladen. Da es erfahrungsgemäß ' +
                    'hierbei zu Downloadabbrüchen seitens des Webbrowsers kommt, bitten wir Sie,' +
                    'die Ergebnismenge zunächst einzugrenzen.', {anzahl: this.bestandList.total}),
                this._('Aktion nicht möglich'),
                {
                    timeOut: 15000
                }
            );
        } else {
            ConfirmDialogComponent
                .display(
                    this.modalService,
                    this._('Download durchführen?'),
                    this._('Sie wollen {{ anzahl }} Datensätze herunterladen. Dies wird unter Umständen ' +
                        'eine längere Zeit dauern und je nach Browser zu einem Verbindungsabbruch führen. <br /><br /> ' +
                        'Wir empfehlen, die Liste zunnächst weiter einzuschränken.',
                        {anzahl: this.bestandList.total}
                    ),
                    this._('Download trotzdem durchführen'),
                    'icofont icofont-download-alt'
                )
                .result
                .then(
                    () => {
                        this.realDlEntries(type, exporttype);
                    },
                    noop
                );
        }
    }

    private realDlEntries(type: string, exporttype: number) {
        this.toastr
            .info(this._('Download wird angefordert. Bitte einen Moment Geduld...'),
                this._('Aktion wird ausgeführt...'),
                {
                    timeOut: 15000
                }
            )
            .onShown
            .subscribe(
                () => {
                    this.tankBestandService
                        .exportData(
                            type,
                            this.bestandList.simpleFilter,
                            this.bestandList.getQuery(),
                            exporttype
                        ).subscribe(
                        (response: Blob) => {
                            FileSaver.saveAs(response, this._('bestandsuebersicht.' + type));
                        },
                        (e) => {
                            this.toastr.error(this._('Die Bestandsübersicht konnte nicht exportiert werden.'));
                        }
                    );
                });

    }


    typed(it: any):TankBestand[] {
        if(it===null || it===undefined){
            return [];
        }
        return [it as TankBestand];
    }

    private updateRecalcsPending() {
           this.tankBestandService.getRecalcsPending().subscribe(
            (pending) => {
                this.recalcsPending = pending;
                this.cd.markForCheck();
            }
        );
    }
}
