import {IProdukt} from '../../produkte/common/iprodukt';
import {GenericFilter} from '../../../commons/generic-filter';
import {ProduktGruppe} from './produkt-gruppe';
import {IProduktGruppe} from './iprodukt-gruppe';

export class SimpleProduktGruppeFilter extends GenericFilter<ProduktGruppe> implements IProduktGruppe {
    numprods: number = null;
    id: number = null;
    nummer: number = null;
    bezeichnung: string = null;
    produkte: IProdukt[] = null;

}
