import {ChangeDetectorRef, Component, Injector} from '@angular/core';

import {KartenanfrageErgebnis} from '../common/kartenanfrage-ergebnis';
import {IKartenanfrageErgebnis} from '../common/ikartenanfrage-ergebnis';
import {LocalizedCellRenderer} from '../../../commons/data-table/renderer/common-cell-renderer';
import {DataTableColumnDirective} from '../../../commons/data-table/data-table-column.directive';
import {DataTableRow} from '../../../commons/data-table/data-table-row';

@Component({
               selector: 'app-kartenanfrage-ergebnis-cell-renderer',
               template: `
                   <span *ngIf="cell!==null && cell!== undefined"
                         [title]="_(cell.description)">
                       {{ _(cell.message) }}
                   </span>
               `
           })
export class KartenanfrageErgebnisCellRendererComponent extends LocalizedCellRenderer {
    cell: IKartenanfrageErgebnis = null;
    row: DataTableRow<any> = null;
    column: DataTableColumnDirective = null;

    value = '';

    constructor(  public injector: Injector,public cd:ChangeDetectorRef) {
        super(cd);
        const x = this.injector.get('cell');
        this.cell = KartenanfrageErgebnis.fromResult(x, true);
        this.row = this.injector.get('row');
        this.column = this.injector.get('column');

        if (this.cell.message === null) {
            this.cell.message = '';
        }
        if (this.cell.description === null) {
            this.cell.description = '';
        }
        if (this.cell.description === '') {
            this.cell.description = this.cell.message;
        }
    }


}
