<div class="card" *ngIf="_terminal!==undefined && _terminal!==null">
    <div class="card-body">
        <h4 class="card-title" *ngIf="!hideTitle">{{ terminalLabel }}</h4>
        <div class="card-text">
            <table class="table">
                <tr>
                    <th scope="row">{{ _('Nummer') }}</th>
                    <td>{{ _terminal.nummer }}</td>
                    <th scope="row">{{ _('Bezeichnung') }}</th>
                    <td>{{ _terminal.bezeichnung }}</td>
                </tr>
                <tr>
                    <th scope="row">{{_('# Säulen')}}</th>
                    <td> {{ _terminal.saeulen.length }}</td>
                    <th scope="row">{{_('# Sonden')}}</th>
                    <td> {{ _terminal.sonden.length }}</td>
                </tr>
                <tr>
                    <th scope="row">{{ _('Infos') }}</th>
                    <td colspan="3">{{ _terminal.info }}</td>
                </tr>
                <tr *ngIf="_terminal.station!=null">
                    <th scope="row">{{ _('Station') }}</th>
                    <td colspan="3">{{ _terminal.station.bezeichnung }} <span class="badge badge-default">{{ _terminal.station.nummer }}</span>
                    </td>

                </tr>
            </table>
        </div>
    </div>
</div>