import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {EDeviceType} from '../common/edevice-type.enum';
import {LocalizedComponent} from '../../commons/components/localized-component';

import {DeviceType} from '../common/device-type';

@Component({
    selector: 'app-device-type-quick-info',
    template: `
        {{ label }}
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeviceTypeQuickInfoComponent extends LocalizedComponent {

    private _type: EDeviceType = EDeviceType.UNKNOWN;

    get label() {
        if (this._type === null || this._type === undefined) {
            return this._('unbekannt');
        }
        return this._(DeviceType.getTitle(this._type));
    }

    get type(): EDeviceType {
        return this._type;
    }

    @Input()
    set type(value: EDeviceType) {
        if (this._type !== value) {
            this._type = DeviceType.byValue(value);
            this.cd.markForCheck();
        }
    }

    constructor(


        public cd: ChangeDetectorRef

    ) {
        super( cd);
    }

}
