import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {LocalizedComponent} from '../../../commons/components/localized-component';
import {NgbActiveModal, NgbModal, NgbModalOptions, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {Person} from '../common/person';

import {PersonService} from '../common/person.service';

import {AddOnClickEvent} from '../../../commons/dynamic-form/add-on-click-event';
import {ToastrService} from 'ngx-toastr';

@Component({
    selector: 'app-person-editor',
    templateUrl: './person-editor.component.html',
    styleUrls: ['./person-editor.component.css']
})
export class PersonEditorComponent extends LocalizedComponent implements OnInit {

    get dlgTitle() {
        if (this.isNew()) {
            return this._('Person/Fahrer anlegen');
        }
        return this._('Person/Fahrer {{ name }} bearbeiten', {name: this.entity.name});
    }


    mode: any = 'new';
    entity: Person = new Person();

    static open(modalService: NgbModal, person?: Person): Promise<any> {
        const o: NgbModalOptions = {
            size: 'lg',
            backdrop: 'static'
        };
        const r: NgbModalRef = modalService.open(PersonEditorComponent, o);
        (r.componentInstance as PersonEditorComponent).open(person);
        return r.result;
    }


    constructor(
                
                public activeModal: NgbActiveModal,
                public personService: PersonService,
                public cd: ChangeDetectorRef,
                public toastr: ToastrService) {
        super( cd);
    }

    open(person?: Person) {
        if ((person === null) || (person === undefined)) {
            this.entity = new Person();
            this.mode = 'new';
        } else {
            this.entity = Person.fromResult(person);
            this.mode = 'edit';
        }
    }

    isNew(): boolean {
        return this.mode === 'new';
    }

    isEdit(): boolean {
        return this.mode === 'edit';
    }

    ngOnInit() {
    }

    cancel() {
        this.activeModal.close();
    }

    addOnClick(event: AddOnClickEvent) {
        if ('nummer-field' === event.component.id) {
            if (event.addon === 0) {
                this.personService
                    .findFreeNum(this.entity.kunde)
                    .subscribe(
                        resp => {
                            if (resp.success) {
                                event.component.setValue(resp.params.nummer as number);
                            } else {
                                this.toastr.error(this.t('Es konnte keine freie Nummer ermittelt werden.'));
                            }
                        },
                        () => {
                            this.toastr.error(this.t('Es konnte keine freie Nummer ermittelt werden.'));
                        }
                    );
            }
        }
    }


    save() {
        if (this.canSave()) {
            this.personService
                .store(
                    this.entity, this.isNew()
                )
                .subscribe(
                    resp => {
                        if (resp.success) {
                            this.activeModal.close();
                        } else {
                            this.toastr.error(this.t('Beim Speichern der Personaldaten ist ein Fehler aufgetreten.'));
                        }
                    },
                    () => {
                        this.toastr.error(this.t('Beim Speichern der Personaldaten ist ein Fehler aufgetreten.'));
                    }
                );
        }
    }

    canSave(): boolean {
        if (
            (this.entity.nummer === null) ||
            (this.entity.nummer === undefined) ||
            isNaN(parseInt('' + this.entity.nummer)) ||
            (this.entity.nummer < 1)
        ) {
            return false;
        }

        return true;
    }


}
