import {Injectable} from '@angular/core';
import {LoginService} from '../../auth/services/login.service';
import {HttpClient} from '@angular/common/http';
import {ResultMetaSortImpl} from '../../commons/result-meta';
import {SimpleKostenstelleFilter} from './common/simple-kostenstelle-filter';
import {IKostenstelle} from './common/ikostenstelle';
import {IListResult} from '../../commons/list-result';
import {Observable} from 'rxjs';
import {IActionResponse} from '../../commons/action-response';
import {Kostenstelle} from './common/kostenstelle';

@Injectable({
    providedIn: 'root'
})
export class KostenstelleService {

    constructor(public loginService: LoginService,
                public httpClient: HttpClient) {
    }

    list(max = 0,
         offset = 0,
         sort: ResultMetaSortImpl[] = [],
         filter: SimpleKostenstelleFilter = null,
         searchQuery = ''): Observable<IListResult<IKostenstelle>> {
        let url = '/api/kostenstellen';

        url = url.concat('?')
            .concat('max=' + encodeURIComponent('' + max))
            .concat('&')
            .concat('offset=' + encodeURIComponent('' + offset));

        if (filter !== null) {
            if (filter.hasValues()) {
                url = url + '&' + filter.asURL();
            }
        }

        if (sort !== undefined && sort !== null && sort.length > 0) {
            const p = 0;
            sort.forEach(it => {
                url = url.concat('&sort.' + p + '=' + encodeURIComponent(it.field + '|' + it.direction));
            });
        }

        if (searchQuery !== null && searchQuery !== undefined && searchQuery !== '') {
            url = url.concat('&q=' + encodeURIComponent(searchQuery));
        }
        return this.httpClient
            .get<IListResult<IKostenstelle>>(
                url,
                {headers: this.loginService.getHttpHeaders()}
            );
    }

    storeKostenstelle(kostenstelle: Kostenstelle, insert = false): Observable<IActionResponse> {
        if (!insert && kostenstelle.id !== null && kostenstelle.id > 0) {
            return this.httpClient
                .put<IActionResponse>(
                    '/api/kostenstellen/' + encodeURIComponent('' + kostenstelle.id),
                    JSON.stringify(kostenstelle),
                    {headers: this.loginService.getHttpHeaders()}
                );
        }
        return this.httpClient
            .post<IActionResponse>(
                '/api/kostenstellen',
                JSON.stringify(kostenstelle),
                {headers: this.loginService.getHttpHeaders()}
            );
    }

    removeKostenstelle(kostenstelle: Kostenstelle): Observable<IActionResponse> {
        if (kostenstelle.id !== null && kostenstelle.id !== undefined && kostenstelle.id > 0) {
            return this.httpClient
                .delete<IActionResponse>(
                    '/api/kostenstellen/' + encodeURIComponent('' + kostenstelle.id),
                    {headers: this.loginService.getHttpHeaders()}
                );
        }
        return null;
    }

    reindexKostenstellen(): Observable<IActionResponse> {
        return this.httpClient
            .post<IActionResponse>(
                '/api/kostenstellen/_reindex',
                {},
                {headers: this.loginService.getHttpHeaders()}
            );
    }
}
