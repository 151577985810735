import {Injectable} from '@angular/core';
import {SimpleNachrichtFilter} from './nachrichten/common/simple-nachricht-filter';
import {Observable} from 'rxjs';
import {INachricht} from './nachrichten/common/inachricht';
import {Nachricht} from './nachrichten/common/nachricht';
import {HttpClient} from '@angular/common/http';
import {LoginService} from '../auth/services/login.service';
import {IActionResponse} from '../commons/action-response';
import {IListResult} from '../commons/list-result';
import {ResultMetaSortImpl} from '../commons/result-meta';
import {take} from 'rxjs/operators';

@Injectable({
                providedIn: 'root'
            })
export class NachrichtenService {

    constructor(
        public httpClient: HttpClient,
        public loginService: LoginService
    ) {
    }

    public getUnreadMessageCount(): Observable<IActionResponse> {

        return this.httpClient
            .get<IActionResponse>(
                '/api/currentUser/messages/_unreadCount',
                {
                    headers: this.loginService.getHttpHeaders()
                }
            ).pipe(
                take(1)
            );

    }

    listMessages(max = 0,
                 offset = 0,
                 sort: ResultMetaSortImpl[] = [],
                 filter: SimpleNachrichtFilter = null,
                 searchQuery = ''): Observable<IListResult<INachricht>> {
        let url = '/api/currentUser/messages';

        url = url.concat('?')
            .concat('max=' + encodeURIComponent('' + max))
            .concat('&')
            .concat('offset=' + encodeURIComponent('' + offset));

        if (filter !== null) {
            if (filter.hasValues()) {
                url = url + '&' + filter.asURL();
            }
        }

        if (sort !== undefined && sort !== null && sort.length > 0) {
            const p = 0;
            sort.forEach(it => {
                url = url.concat('&sort.' + p + '=' + encodeURIComponent(it.field + '|' + it.direction));
            });
        }

        if (searchQuery !== null && searchQuery !== undefined && searchQuery !== '') {
            url = url.concat('&q=' + encodeURIComponent(searchQuery));
        }
        return this.httpClient
            .get<IListResult<INachricht>>(
                url, {
                    headers: this.loginService.getHttpHeaders()
                }
            ).pipe(
                take(1)
            );
    }


    public markRead(nachricht: Nachricht): Observable<IActionResponse> {
        return this.httpClient
            .post<IActionResponse>(
                '/api/currentUser/messages/' + encodeURIComponent('' + nachricht.id) + '/_markRead',
                {},
                {
                    headers: this.loginService.getHttpHeaders()
                }
            ).pipe(
                take(1)
            );
    }

    public markUnRead(nachricht: Nachricht): Observable<IActionResponse> {
        return this.httpClient
            .post<IActionResponse>(
                '/api/currentUser/messages/' + encodeURIComponent('' + nachricht.id) + '/_markUnRead',
                {},
                {
                    headers: this.loginService.getHttpHeaders()
                }
            ).pipe(
                take(1)
            );
    }

    public deleteNachricht(nachricht: Nachricht | number[]): Observable<IActionResponse> {
        return this.httpClient
            .delete<IActionResponse>(
                '/api/currentUser/messages/' + encodeURIComponent(Array.isArray(nachricht) ? ('' + nachricht.join(',')) : ('' + nachricht.id)),
                {
                    headers: this.loginService.getHttpHeaders()
                }
            ).pipe(
                take(1)
            );
    }


}
