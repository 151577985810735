import {IAnrede} from './ianrede';

export class Anrede implements IAnrede {
    id = 0;
    anrede = '';
    briefanrede = '';

    static fromResult(entity: IAnrede): Anrede {
        if ((entity === null) || (entity === undefined)) {
            return null;
        }
        const l: Anrede = new Anrede();
        for (const k of Object.keys(l)) {
            l[k] = (entity[k] !== undefined) ? entity[k] : '';
        }
        return l;
    }


    constructor() {
    }
}
