import {ConverterTool} from '../../shared/converter-tool';
import {METool} from '../../shared/metool';

export interface IActionResponse {
    success: boolean;
    params: any;
}

export class ActionResponse {
    getMessage() {
        if (METool.isNullOrUndefined(this.params)) {
            return '';
        }
        if (Object.keys(this.params).indexOf('message') < 0) {
            return '';
        }
        return '' + this.params[ Object.keys(this.params).indexOf('message') ];
    }

    success = false;
    params: any = {};

    static fromRawActionResponse(ra: IActionResponse): ActionResponse {
        if (ra === null) {
            return new ActionResponse();
        }
        const a: ActionResponse = new ActionResponse();
        a.success = ConverterTool.toBoolean(ra.success || false);
        a.params = ra.params || {};
        return a;
    }

}
