import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {Terminal} from '../common/terminal';

import {LocalizedComponent} from '../../../commons/components/localized-component';

@Component({
               selector   : 'app-terminal-quick-info',
               templateUrl: './terminal-quick-info.component.html',
               styleUrls  : [ './terminal-quick-info.component.css' ]
           })
export class TerminalQuickInfoComponent extends LocalizedComponent implements OnInit {

    @Input() info_position = 'right';

    _terminal: Terminal = null;

    @Input()
    set terminal(value: Terminal) {
        this._terminal = Terminal.fromResult(value);
    }

    get terminal() {
        return this._terminal;
    }

    @Input() showLabel = true;

    getTitle() {
        if (this._terminal !== null) {
            return this._(' Terminal {{ bezeichnung }} ', {bezeichnung: this._terminal.bezeichnung});
        }
        return '';
    }

    constructor(  public cd: ChangeDetectorRef) {
        super( cd);
    }

    ngOnInit() {
    }

}
