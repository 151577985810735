<div class="card">
    <div class="card-header">
        <div class="d-flex justify-content-between align-items-center">
            <div>
                {{_('letzte Kartenanfragen')}}
            </div>
            <div>
                <a class="mr-2 btn btn-sm btn-light" role="button" (click)="kartenanfragen.reload()"><i
                        class="far fa-sync"></i></a>
                <a class="btn btn-sm btn-light" role="button" routerLink="kartenanfragen"><i
                        class="far fa-link"></i></a>
            </div>
        </div>
    </div>
    <div class="card-body">
        <table class="table">
            <thead>
            <tr>
                <th>{{_('Zeitpunkt')}}</th>
                <th>{{_('Station')}}</th>
                <th>{{_('PAN')}}</th>
                <th>{{_('Status')}}</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let t of kartenanfragen.data">
                <td class="p1 text-nowrap">{{fDate(t.zeitpunkt, 'medium')}}</td>
                <td class="p1" title="{{t.station.bezeichnung}}">{{shorten(t.station.bezeichnung,30)}}</td>
                <td class="p1">{{ fPan(t.pan) }}</td>
                <td class="p1">{{ t.ergebnis.message }}</td>
            </tr>
            </tbody>
        </table>
    </div>
</div>
