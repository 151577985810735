<me-info-tool
        [title]="getTitle()"
        [title_icon]="getIcon()"
        [position]="info_position"
        *ngIf="value!==null">
  <table>
    <tr><td>{{_('Anrede')}}</td><td>{{ value.anrede}}</td></tr>
    <tr><td>{{_('Briefanrede')}}</td><td>{{ value.briefanrede}}</td></tr>
  </table>
</me-info-tool>
<div *ngIf="showLabel && value!==null && value!==undefined " title="{{ value?.briefanrede }}" style="display:inline-block;">
  <i class="{{ getIcon() }}"></i>
  {{ value.anrede }}
</div>
