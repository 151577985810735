import {ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BenutzerInlineFieldComponent} from './benutzer-inline-field/benutzer-inline-field.component';
import {BenutzerFieldComponent} from './benutzer-field/benutzer-field.component';
import {CommonsModule} from '../commons/commons.module';
import { PermissionsRendererComponent } from './permissions-renderer/permissions-renderer.component';
import { PermissionsQuickInfoComponent } from './permissions-quick-info/permissions-quick-info.component';

const components=[
    BenutzerInlineFieldComponent,
    BenutzerFieldComponent,
    PermissionsRendererComponent,
    PermissionsQuickInfoComponent
];

@NgModule({
    imports: [
        CommonModule,
        CommonsModule
    ],
    declarations: [
        ... components
    ],
    exports: [
        ... components
    ],
    entryComponents: [
        ... components
    ]
})
export class UserControlsModule {
}
