import {Subscription} from 'rxjs';

export class SubscriptionHelper {

    subscriptions: Subscription[] = [];

    static add(sub: Subscription, instance: SubscriptionHelper= null): SubscriptionHelper {

        if (instance === null || instance === undefined) {
         instance = new SubscriptionHelper();
        }

        instance.addSubscription(sub);

        return instance;
    }

    addSubscription(sub: Subscription) {
        if (sub !== null && sub !== undefined) {
            this.subscriptions.push(sub);
        }
    }


    unsubscribe() {
        this.subscriptions.forEach((s) => {
            s.unsubscribe();
        });
        this.subscriptions = [];
    }

}
