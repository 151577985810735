import {Geraet} from './geraet';

export class StaubsaugerSettings {
    geraet: Geraet = null;
    modell = '';

    static fromRAW(st: any): StaubsaugerSettings {
        if (st === null || st === undefined) {
            return null;
        }
        const l = new StaubsaugerSettings();
        for (const k of Object.keys(st)) {
            if (k === 'geraet') {
                l.geraet = Geraet.fromResult(st.geraet || null);
            } else {
                l[k] = (st[k] !== undefined) ? st[k] : null;
            }
        }
        return l;
    }

}
