<table class="table" *ngIf="device.isVacuumcleaner()">
    <tr>
        <th class="w-20" scope="row">{{ _('Modell') }}</th>
        <td>
            {{ device.settings.modell }}
        </td>
    </tr>
    <tr>
        <th class="w-20" scope="row">{{ _('Münzprüfer-Einheit') }}</th>
        <td>
            <div *ngIf="device.settings.geraet!==null && device.settings.geraet!==undefined">
                {{device.settings.geraet.bezeichnung}}
                <span class="badge badge-default">{{device.settings.geraet.nummer}}</span>
            </div>
        </td>
    </tr>
</table>
