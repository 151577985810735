<div *ngIf="isValid()">
    <div class="" [class.d-flex]="cfg.showLabel" [class.justify-content-between]="cfg.showLabel" *ngIf="cfg.field==='hoehe'">
        <div class="mr-1" *ngIf="cfg.showLabel">{{_('Höhe')}}:</div>
        <div class="text-right">
            <volumen-display [value]="sondenBuchung.hoehe" [scale]="0" unit="mm"></volumen-display>
        </div>
    </div>
    <div class="" [class.d-flex]="cfg.showLabel" [class.justify-content-between]="cfg.showLabel" *ngIf="cfg.field==='menge'">
        <div class="mr-1" *ngIf="cfg.showLabel">{{_('Menge')}}:</div>
        <div class="text-right">
            <volumen-display [value]="sondenBuchung.menge" [scale]="0" unit="l"></volumen-display>
        </div>
    </div>
    <div class="" [class.d-flex]="cfg.showLabel" [class.justify-content-between]="cfg.showLabel" *ngIf="cfg.field==='zeitpunkt'">
        <div class="mr-1" *ngIf="cfg.showLabel">{{_('Zeitpunkt')}}:</div>
        <div class="text-right">
            {{ fDate(sondenBuchung.zeitpunkt,'medium') }}
        </div>
    </div>
</div>
