import {ChangeDetectionStrategy, Component, Injector} from '@angular/core';
import {IFahrzeug} from '../common/ifahrzeug';
import {DataTableRow} from '../../../commons/data-table/data-table-row';
import {DataTableColumnDirective} from '../../../commons/data-table/data-table-column.directive';

@Component({
               selector       : 'app-dtfahrzeug-kfzkennzeichen-renderer',
               template       : `
                   <app-fahrzeug-kennzeichen-quick-info
                           *ngIf="cell!==undefined && cell!==null"
                           [fahrzeug]="cell"
                           [showLabel]="true"
                           [info_position]="info_position"
                   ></app-fahrzeug-kennzeichen-quick-info>
               `,
               changeDetection: ChangeDetectionStrategy.OnPush
           })
export class DTFahrzeugKfzkennzeichenRendererComponent {
    info_position = 'right';

    cell: IFahrzeug = null;
    row: DataTableRow<any> = null;
    column: DataTableColumnDirective = null;


    constructor(public injector: Injector) {

        this.cell = this.injector.get('cell');
        this.row = this.injector.get('row');
        this.column = this.injector.get('column');

        if (this.column !== null && this.column !== undefined) {
            if ((this.column.cfg !== null) && (this.column.cfg !== undefined)) {
                if (this.column.cfg.info_position !== null) {
                    this.info_position = this.column.cfg.info_position;
                }
            }
        }

    }
}
