<div class="card">
    <div class="card-header">
        <div class="d-flex justify-content-between align-items-center">
            <div>
                {{_('aktive Sonden')}}
            </div>
            <div class="d-flex justify-content-end">
                <div class="mr-2">
                    <div class="dropdown">
                        <button class="btn btn-sm btn-secondary dropdown-toggle" type="button"
                                id="dropdownMenuButton"
                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            {{ modeLabel }}
                        </button>
                        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                            <a class="dropdown-item" (click)="selectMode(0)">nur Warnmeldungen</a>
                            <a class="dropdown-item" (click)="selectMode(1)">alle Sonden anzeigen</a>
                        </div>
                    </div>
                </div>
                <a class="mr-2 btn btn-sm btn-light" role="button" (click)="sonden.reload()"><i
                        class="far fa-sync"></i></a>
                <a class="btn btn-sm btn-light" role="button" routerLink="tankueberwachung"><i
                        class="far fa-link"></i></a>
            </div>
        </div>
    </div>
    <div class="card-body">

        <div class="d-flex align-items-center flex-wrap">
            <div *ngFor="let sonde of sonden.data"
                 [class.d-none]="!isVisible(sonde)"
            >
                <dashboard-info-block
                        *ngIf="isVisible(sonde)"
                        [title]="bezeichnung(sonde)"
                        [notransform]="true"
                        [value]="stand(sonde)"
                        icon="far fa-ruler-vertical"
                        [classes]="getClasses(sonde)"
                        (click)="showPeilungen(sonde)"
                        [subtext]="zeitpunkt(sonde)"
                ></dashboard-info-block>
            </div>
        </div>
      <div class="alert alert-success" *ngIf="showNoWarningMessage()">
        {{_('Momentan liegen keine Warnungen vor') }}
      </div>
    </div>
</div>
