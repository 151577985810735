import {ISondenBuchung} from './isonden-buchung';
import {ConverterTool} from '../../../shared/converter-tool';
import {Sonde} from './sonde';

export class SondenBuchung implements ISondenBuchung {
    hoehe = 0;
    id = 0;
    menge = 0;
    sumpf = 0;
    temperatur = 0;
    zeitpunkt: Date = null;
    dateCreated: Date = null;
    failed = false;
    lastUpdated: Date = null;
    sonde: Sonde = null;

    constructor() {
    }

     static fromResult(entity: ISondenBuchung): SondenBuchung {
         if (entity === null || entity === undefined) {
             return null;
         }

         const l = new SondenBuchung();
        for (const k of Object.keys(l)) {
            if (k === 'zeitpunkt') {
                l.zeitpunkt = ConverterTool.toDate(entity.zeitpunkt);
            } else if (k === 'dateCreated') {
                    l.dateCreated = ConverterTool.toDate(entity.dateCreated);
            } else if (k === 'lastUpdated') {
                l.lastUpdated = ConverterTool.toDate(entity.lastUpdated);
            } else if (k === 'sonde') {
                l.sonde = Sonde.fromResult(entity.sonde);
            } else {
                l[ k ] = (entity[ k ] !== undefined) ? entity[ k ] : '';
            }
        }
        return l;
    }

}
