<div class="modal-header">
    <h5 class="modal-title" >{{ dlgTitle }}</h5>
    <button type="button" class="close" aria-label="Close" (click)="cancel()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">

    <app-tabset (tab_selected)="tabSelected($event)" #peTabs>
        <app-pane [title]="_('Station')">
            <select-field [label]="_('betroffene Station(n)')" [value]="stationenMode"
                          (valueChange)="setStationenMode($event)">
                <select-field-option [value]="0" [label]="_('alle Stationen')"></select-field-option>
                <select-field-option [value]="1" [label]="_('eine Station')"></select-field-option>
                <select-field-option [value]="2" [label]="_('Stationenauswahl')"></select-field-option>
            </select-field>

            <station-field [label]="_('Station')"
                              [value]="currentStation"
                              (valueChange)="selectStation($event)"
                              *ngIf="stationenMode==1"></station-field>
            <stationen-selector
                    *ngIf="stationenMode==2"
                    [label]="_('Stationen')"
                    [value]="stationen"
                    (valueChanged)="selectStationen($event)"
            ></stationen-selector>
        </app-pane>
        <app-pane [title]="_('Terminals')">
            <select-field [label]="_('betroffene Terminals')" [value]="terminalMode"
                          (valueChange)="setTerminalMode($event)">
                <select-field-option [value]="0"
                                     [label]="_('alle Terminals aller gewählten Stationen')"></select-field-option>
                <select-field-option [value]="1" [label]="_('ein Terminal')"></select-field-option>
                <select-field-option [value]="2" [label]="_('Terminalauswahl')"></select-field-option>
            </select-field>

            <terminal-field
                    [label]="_('Terminal')"
                    *ngIf="terminalMode==1 && stationenMode==0"
                    [station]="null"
                    [value]="currentTerminal"
                    (valueChange)="selectTerminal($event)"
            ></terminal-field>
            <terminal-field
                    [label]="_('Terminal')"
                    *ngIf="terminalMode==1 && stationenMode==1"
                    [station]="currentStation"
                    [value]="currentTerminal"
                    (valueChange)="selectTerminal($event)"
            ></terminal-field>
            <terminal-field
                    [label]="_('Terminal')"
                    *ngIf="terminalMode==1 && stationenMode==2"
                    [station]="stationen"
                    [value]="currentTerminal"
                    (valueChange)="selectTerminal($event)"
            ></terminal-field>

            <terminals-selector
                    *ngIf="terminalMode==2 && stationenMode==0"
                    [station]="null"
                    [label]="_('Terminals')"
                    [value]="terminals"
                    (valueChanged)="selectTerminals($event)"
            >

            </terminals-selector>
            <terminals-selector
                    *ngIf="terminalMode==2 && stationenMode==1"
                    [station]="currentStation"
                    [label]="_('Terminals')"
                    [value]="terminals"
                    (valueChanged)="selectTerminals($event)"
            >
            </terminals-selector>
            <terminals-selector
                    *ngIf="terminalMode==2 && stationenMode==2"
                    [station]="stationen"
                    [label]="_('Terminals')"
                    [value]="terminals"
                    (valueChanged)="selectTerminals($event)"
            >

            </terminals-selector>

        </app-pane>
        <app-pane [title]="_('Preise')" [id]="'prTab'" #preiseTab>
            <div class="table-responsive">
                <table class="table table-striped">
                    <thead>
                    <th>{{_('Produkt-Nummer')}}</th>
                    <th>{{_('Produkt-Bezeichnung')}}</th>
                    <th>{{_('Preis')}}</th>
                    <th>{{_('Währung')}}</th>
                    <th>{{ _('Brutto?')}}</th>
                    </thead>
                    <tbody>
                    <tr *ngFor="let p of preise;let idx=index;" [class.hidden]="!p.visible">
                        <td>{{ p.produkt.nummer }}</td>
                        <td>{{ p.produkt.bezeichnung }}</td>
                        <td>
                            <num-field
                                    [labelwidth]="0"
                                    [(value)]="p.wert"
                                    [label]="_('Preis pro Einheit')"
                                    [tabindex]="p.ti"
                            ></num-field>
                        </td>
                        <td>
                            <currency-field
                                    [labelwidth]="0"
                                    [(value)]="p.currency"
                                    [label]="_('Währung')"
                            ></currency-field>
                        </td>
                        <td>
                            <boolean-field
                                    [labelwidth]="0"
                                    [label]="_('Bruttopreis?')"
                                    [(value)]="p.istBrutto"
                            ></boolean-field>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </app-pane>
        <app-pane [title]="_('Optionen')">
            <select-field [label]="_('Gültigkeit')" [(value)]="preisTiming">
                <select-field-option [value]="0"
                                     [label]="_('Preise sind ab sofort gültig')"></select-field-option>
                <select-field-option [value]="1" [label]="_('Zeitpunkt auswählen')"></select-field-option>
            </select-field>


            <date-time-field
                    [label]="_('Preise gültig ab')"
                    [(value)]="gueltigAb"
                    *ngIf="preisTiming==1"
            ></date-time-field>

        </app-pane>
    </app-tabset>


</div>
<div class="modal-footer">
    <div style="width:100%">
        <span>{{ tsmsg }}</span>
        ,
        <span>{{ tmmsg }}</span>
        ,
        <span *ngIf="preisTiming==0">{{ _('Preise sofort gültig') }}</span>
        <span *ngIf="preisTiming==1">{{ _('Preisgültigkeit terminiert') }}</span>

        <div class="alert alert-info float-right" *ngIf="hasNullPreise()" style="width: 42%;padding: 0.35rem 1rem;margin:0 10px;">{{ _("[0]-Preise werden nicht hinterlegt.") }}</div>
    </div>

    <button type="button" class="btn btn-secondary" (click)="cancel()">
        <i class="icofont icofont-close"></i> {{ _('Abbrechen') }}
    </button>
    <button type="button" class="btn btn-primary" (click)="save()">
        <i class="icofont icofont-save"></i>
        {{ _('Preise stellen')}}
    </button>
</div>

