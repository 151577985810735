import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {Terminal} from '../common/terminal';
import {LocalizedComponent} from '../../../commons/components/localized-component';
import {StationenService} from '../../common/stationen.service';

@Component({
    selector: 'app-terminal-display',
    templateUrl: './terminal-display.component.html',
    styleUrls: ['./terminal-display.component.css']
})
export class TerminalDisplayComponent extends LocalizedComponent implements OnInit {

    get terminalLabel() {
        return this._('Terminal {{ nummer }}', {nummer: this._terminal.nummer});
    }

    @Input() hideTitle = false;

    _terminal: Terminal = null;

    @Input()
    set terminal(value: Terminal) {
        this._terminal = Terminal.fromResult(value);
        if (this._terminal !== null && this._terminal !== undefined) {
            this.stationService.loadTerminal(this._terminal.id).subscribe(t => {
                this._terminal = t;
            });
        }
    }

    get terminal() {
        return this._terminal;
    }

    constructor(  public cd: ChangeDetectorRef, public stationService: StationenService) {
        super( cd);
    }

    ngOnInit() {
    }

}
