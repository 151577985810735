import {IKostenstelle} from './ikostenstelle';

export class Kostenstelle implements IKostenstelle {
    buchungskreis = '';
    id = 0;
    info = '';
    kostenstelle = '';

    static fromResult(entity: IKostenstelle): Kostenstelle {
        if ((entity === null) || (entity === undefined)) {
            return null;
        }

        const l = new Kostenstelle();
        for (const k of Object.keys(l)) {
                l[k] = (entity[k] !== undefined) ? entity[k] : '';
        }
        return l;
    }


    constructor() {
    }
}
