import {AfterContentInit, AfterViewInit, Component, ContentChildren, EventEmitter, OnInit, Output, QueryList} from '@angular/core';
import {PaneComponent} from './pane.component';

@Component({
    selector: 'app-tabset',
    template: `
        <ul class="nav nav-tabs">
            <li class="nav-item"
                *ngFor="let p of panes"
            >
                <a class="nav-link"
                   [class.active]="p.active"
                   [class.disabled]="p.disabled"
                   (click)="select(p)">
                    {{ p.title }}
                </a>
            </li>
        </ul>
        <div class="tab-content">
            <ng-content></ng-content>
        </div>

    `,
    styles: [
            `
            .nav-item a {
                cursor: pointer;
            }

            .tab-content {
                padding: 10px;
                border-left: 1px solid #ddd;
                border-bottom: 1px solid #ddd;
                border-right: 1px solid #ddd;
            }
        `
    ]

})
export class TabsetComponent implements OnInit, AfterContentInit {

    constructor() {
    }


    @ContentChildren(PaneComponent) cpanes: QueryList<PaneComponent>;

    @Output() tab_selected: EventEmitter<PaneComponent> = new EventEmitter<PaneComponent>();

    panes: PaneComponent[] = [];

    updatePanes() {
        if (this.cpanes !== null && this.cpanes !== undefined) {
            this.panes = [];
            this.cpanes.forEach(p => {
                if (this.panes.length === 0) {
                    p.active = true;
                    this.tab_selected.emit(p);
                }
                this.panes.push(p);
            });
        }
    }

    ngOnInit() {

    }

    ngAfterContentInit(): void {
        this.updatePanes();
        this.cpanes.changes.subscribe(
            () => {
                this.updatePanes();
            }
        );
    }

    select(pane: PaneComponent, deferEvent: number = 0) {
        this.panes.forEach(p => {
            p.active = (p === pane);
        });
        if (deferEvent > 0) {
            setTimeout(() => {

            }, deferEvent);
        } else {
            this.tab_selected.emit(pane);
        }
    }

    selectTab(tab_id: string) {
        this.panes.forEach((p: PaneComponent) => {
            if (p.id === tab_id) {
                this.select(p);
            }
        });
    }


}
