import {Transaktion} from './transaktion';
import {SimpleTransaktionFilter} from './simple-transaktion-filter';
import {ITransaktion} from './itransaktion';
import {IListResult} from '../../../commons/list-result';
import {RemoteList} from '../../../commons/remote-list';

export class TransaktionList extends RemoteList<Transaktion> {
    public simpleFilter: SimpleTransaktionFilter = new SimpleTransaktionFilter();

    static fromListResult(res: IListResult<ITransaktion>): TransaktionList {
        const newList = new TransaktionList();

        newList.populateFromListResult(res, true);

        return newList;
    }


    constructor(autorun = false, timeout = 2500) {
        super(autorun, timeout);
    }

    public convertEntity(entity: any): any {
        return Transaktion.fromResult(entity);
    }

    public populateFromListResult(res: IListResult<ITransaktion>, clearFilter = true): void {
        this.doPopulateFromListResult(res, 'zeitpunkt', 'desc', clearFilter);
    }
}
