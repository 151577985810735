import {Component, Input, OnInit} from '@angular/core';
import {Attachment} from '../../common/attachment';
import {AttachmentService} from '../../common/attachment.service';
import * as FileSaver from 'file-saver';
import {ShortenPipe} from '../../../../commons/shorten.pipe';

@Component({
               selector: 'app-attachment-info',
               template: `
                   <div *ngIf="at!==null && at!==undefined">
                       <button class="btn btn-link" (click)="download()" title="{{ at.name }}">
                           <i class="icofont icofont-download-alt"></i>
                           <i class="{{at.getIcon()}}"></i>
                {{ getLabel(at.name) }}
                       </button>
                   </div>
               `
           })
export class AttachmentInfoComponent implements OnInit {
    at: Attachment = null;

    @Input()
    set attachment(value: any) {
        this.at = Attachment.fromResult(value);
    }

    get attachment() {
        return this.at;
    }

    constructor(public attachmentService: AttachmentService) {
    }

    ngOnInit() {
    }

    getLabel(n: string) {
        const p = new ShortenPipe();
        return p.transform(n, 35);
    }

    download() {
        if (this.at.isURL()) {
            window.open(this.at.getURI(), '_blank');
        } else if (this.at.isFile()) {
            this.attachmentService
                .downloadAttachment(this.at)
                .subscribe((response: Blob) => {
                    FileSaver.saveAs(response, this.at.filename);
                });
        }
    }
}
