<data-table
        [selectable]="false"
        [data]="nachbuchungen.asDataTableRows()"
        [orders]="nachbuchungen.order"
        [filter]="nachbuchungen.simpleFilter"
        (sortChanged)="sortChanged($event)"
        (filterChanged)="filterChanged($event)"
        [rowActionsAsButtons]="true"
>
    <tableaction
            *hasRole="['ROLE_ADMIN','ROLE_PROJECT_ADMIN','ROLE_BILLING','ROLE_TRANSAKTIONEN_WRITABLE']"
            [label]="t('Nachbuchung erfassen')"
            [icon]="'icofont icofont-ui-add'"
            (perform)="newNachbuchung()"
    ></tableaction>

    <column
            [field]="'zeitpunkt'"
            [label]="_('Zeitpunkt')"
            type="Date"
            [cfg]="{format:'medium'}"
            [sortable]="true"></column>

    <column
            [field]="'station'"
            [label]="_('Station')"
            [renderer]="stationR"
            [sortable]="true"></column>


    <column
            [field]="'belegnummer'"
            [label]="_('Beleg#')"
            type="integer"
            [sortable]="true"></column>

    <column
            [field]="'ausweis'"
            [label]="_('Karte')"
            [renderer]="ausweisR"
            [sortable]="true"></column>

    <column
            [field]="'details'"
            [label]="_('Produkt & Menge')"
            [renderer]="prdCellRenderer"
            [sortable]="false"
    ></column>

    <column
            [field]="'transfer'"
            [label]="_('Verarbeiten?')"
            type="Boolean"
            [sortable]="false"></column>

    <column
            [field]="'booked'"
            [label]="_('Verbucht?')"
            type="Boolean"
            [sortable]="false"></column>

    <rowaction
            *hasRole="['ROLE_ADMIN','ROLE_BILLING','ROLE_PROJECT_ADMIN','ROLE_TRANSAKTIONEN_WRITABLE']"
            [label]="_('Details')"
            [icon]="'far fa-info-circle'"
            [title]="_('Details anzeigen')"
            (perform)="showDetails($event)"
            (checkEnabled)="checkNacherfassung('details',$event)"
    ></rowaction>
    <rowaction
            *hasRole="['ROLE_ADMIN','ROLE_BILLING','ROLE_PROJECT_ADMIN','ROLE_TRANSAKTIONEN_WRITABLE']"
            [label]="_('Bearbeiten')"
            [icon]="'icofont icofont-ui-edit'"
            [title]="_('Nacherfassung bearbeiten')"
            (perform)="editNachbuchung($event)"
            (checkEnabled)="checkNacherfassung('edit',$event)"
    ></rowaction>

    <rowaction
            [label]="_('Nacherfassung verarbeiten')"
            [title]="_('Nacherfassung verarbeiten')"
            [icon]="'icofont icofont-play-alt-1'"
            (perform)="processNacherfassung($event)"
            (checkEnabled)="checkNacherfassung('process',$event)"
    ></rowaction>
    <rowaction
            [label]="_('Nacherfassung nicht verarbeiten')"
            [title]="_('Nacherfassung nicht verarbeiten')"
            [icon]="'icofont icofont-pause'"
            (perform)="holdNacherfassung($event)"
            (checkEnabled)="checkNacherfassung('hold',$event)"
    ></rowaction>


    <data-table-table-extra-header>
        <app-paginator
                [total]="nachbuchungen.total"
                [page]="nachbuchungen.page"
                [perpage]="nachbuchungen.size"
                (pageChange)="updateNachbuchungen(true,$event)"
                (pageCountChange)="updateNachbuchungenPageCount($event)"
                [showSearch]="true"
                (queryChange)="nachbuchungenQueryChange($event)"
        ></app-paginator>
    </data-table-table-extra-header>

</data-table>
