import {ITransaktion} from './itransaktion';
import {Station} from '../../../stationen/common/station';
import {DataTableRow} from '../../../commons/data-table/data-table-row';
import {ConverterTool} from '../../../../shared/converter-tool';
import {Ausweis} from '../../../customers/ausweise/common/ausweis';
import {Kunde} from '../../../customers/kunden/common/kunde';
import {Fahrzeug} from '../../../customers/fahrzeuge/common/fahrzeug';
import {Person} from '../../../customers/personen/common/person';
import {Terminal} from '../../../stationen/terminals/common/terminal';
import {Waehrung} from '../../../commons/waehrungen/waehrung';
import {TransaktionDetail} from './transaktion-detail';
import {Kostenstelle} from '../../../customers/kostenstellen/common/kostenstelle';

export class Transaktion implements ITransaktion {
    id = 0;
    betrag_euro = 0;
    b_betrag_euro = 0;
    waehrung: Waehrung = null;
    details: TransaktionDetail[] = [];
    source = '';
    guid = '';
    zeitpunkt: any = null;
    ausweiskennung1 = '';
    ausweis1: Ausweis = null;
    ausweiskennung2 = '';
    ausweis2: Ausweis = null;
    belegnummer = 0;
    ISOBelegnummer = 0;
    kmstand = 0;
    kunde: Kunde = null;
    fahrzeug: Fahrzeug = null;
    person: Person = null;
    betrag = 0;
    b_betrag = 0;
    station: Station = null;
    terminal: Terminal = null;
    zusatzeingabe = '';
    referenzID = '';
    positionen = 0;
    storniert = false;
    tReferenzId: number = null;
    kostenstelle: Kostenstelle = null;
    fahrerNummer = '';
    markedForRedo=false;
    archived=0;

    public detailsDataTable: DataTableRow<TransaktionDetail>[] = [];


    static fromResult(entity: ITransaktion): Transaktion {
        if ((entity === null) || (entity === undefined)) {
            return null;
        }

        const l: Transaktion = new Transaktion();
        for (const k of Object.keys(l)) {
            if (k === 'zeitpunkt') {
                l.zeitpunkt = ConverterTool.toDate(entity.zeitpunkt);
            } else if (k === 'storniert') {
                l.storniert = ConverterTool.toBoolean(entity.storniert);
            } else if (k === 'ausweis1') {
                l.ausweis1 = Ausweis.fromResult(entity.ausweis1);
            } else if (k === 'ausweis2') {
                l.ausweis2 = Ausweis.fromResult(entity.ausweis2);
            } else if (k === 'kunde') {
                l.kunde = Kunde.fromResult(entity.kunde);
            } else if (k === 'fahrzeug') {
                l.fahrzeug = Fahrzeug.fromResult(entity.fahrzeug);
            } else if (k === 'person') {
                l.person = Person.fromResult(entity.person);
            } else if (k === 'station') {
                l.station = Station.fromResult(entity.station);
            } else if (k === 'terminal') {
                l.terminal = Terminal.fromResult(entity.terminal);
            } else if (k === 'waehrung') {
                l.waehrung = Waehrung.fromResult(entity.waehrung);
            } else if (k === 'kostenstelle') {
                l.kostenstelle = Kostenstelle.fromResult(entity.kostenstelle);
            }else if (k==='markedForRedo'){
                l.markedForRedo=ConverterTool.toBoolean(entity.markedForRedo);
            } else if (k === 'details') {
                l.details = [];
                l.detailsDataTable = [];
                if ((entity.details !== null) && (entity.details !== undefined) && Array.isArray(entity.details)) {
                    entity.details.forEach(s => {
                        const i = TransaktionDetail.fromResult(s);
                        if (i != null) {
                            l.details.push(i);
                            l.detailsDataTable.push(new DataTableRow<TransaktionDetail>(i));
                        }
                    });
                }
            } else if (k === 'detailsDataTable') {
                // nothing to do!
            } else {
                l[k] = (entity[k] !== undefined) ? entity[k] : '';
            }
        }

        return l;
    }

    constructor() {
    }

    public detailRows(): DataTableRow<TransaktionDetail>[] {
        return this.detailsDataTable;
    }

    isAusweis1GCard() {
        if (this.ausweiskennung1 !== null && this.ausweiskennung1 !== undefined) {
            return this.ausweiskennung1.match(/^[0-9]+F[0-9]+/) !== null;
        }
        return false;
    }

    isAusweis2GCard() {
        if (this.ausweiskennung2 !== null && this.ausweiskennung2 !== undefined) {
            return this.ausweiskennung2.match(/^[0-9]+F[0-9]+/) !== null;
        }
        return false;
    }

    isGCard() {
        return this.isAusweis1GCard() || this.isAusweis2GCard();
    }

    get rabattcard() {
        if (!this.isGCard()) {
            return '';
    }


        if (this.fahrerNummer === null || this.fahrerNummer === undefined || ('' + this.fahrerNummer).trim() === '' || ('' + this.fahrerNummer).trim() === '0') {
            return '';
        }

        return this.fahrerNummer;
    }

}
