import {INachbuchung} from './inachbuchung';
import {INachbuchungDetail} from './inachbuchung-detail';
import {IStation} from '../../../stationen/common/istation';
import {Station} from '../../../stationen/common/station';
import {IAusweis} from '../../../customers/ausweise/common/iausweis';
import {IWaehrung} from '../../../commons/waehrungen/iwaehrung';
import {ITerminal} from '../../../stationen/terminals/common/iterminal';
import {Ausweis} from '../../../customers/ausweise/common/ausweis';
import {ConverterTool} from '../../../../shared/converter-tool';
import {Waehrung} from '../../../commons/waehrungen/waehrung';
import {Terminal} from '../../../stationen/terminals/common/terminal';
import {NachbuchungDetail} from './nachbuchung-detail';
import {DataTableRow} from '../../../commons/data-table/data-table-row';

export class Nachbuchung implements INachbuchung {
    id = 0;
    nummer = 0;
    ausweis: IAusweis = null;
    zeitpunkt: Date = null;
    belegnummer = 0;
    waehrung: IWaehrung = null;
    pan = '';
    station: IStation = null;
    terminal: ITerminal = null;
    details: INachbuchungDetail[] = [];
    dateCreated: Date = null;
    lastUpdated: Date = null;
    betrag = 0;
    b_betrag = 0;
    mwst = 0;
    kursWert = 0;
    betrag_euro = 0;
    b_betrag_euro = 0;
    positionen = 0;
    transfer = true;
    verbucht = false;
    public detailsDataTable: DataTableRow<INachbuchungDetail>[] = [];

    static fromResult(entity: INachbuchung): Nachbuchung {
        if ((entity === null) || (entity === undefined)) {
            return null;
        }

        let l = new Nachbuchung();
        for (let k of Object.keys(l)) {
            if (k === 'ausweis') {
                l.ausweis = Ausweis.fromResult(entity.ausweis)
            } else if (k === 'zeitpunkt') {
                l.zeitpunkt = ConverterTool.toDate(entity.zeitpunkt);
            } else if (k === 'waehrung') {
                l.waehrung = Waehrung.fromResult(entity.waehrung)
            } else if (k === 'station') {
                l.station = Station.fromResult(entity.station);
            } else if (k === 'terminal') {
                l.terminal = Terminal.fromResult(entity.terminal);
            } else if (k === 'details') {
                l.details = [];                l.detailsDataTable = [];

                if (Array.isArray(entity.details)) {
                    entity.details.forEach((d) => {
                        let i = NachbuchungDetail.fromResult(d)
                        if (i !== null) {
                            l.details.push(i);
                            l.detailsDataTable.push(new DataTableRow<NachbuchungDetail>(i));
                        }

                    })
                }
            } else if (k === 'detailsDataTable') {
                // nothing to do!
            } else {
                l[k] = (entity[k] !== undefined) ? entity[k] : '';
            }
        }

        return l;
    }

    constructor() {
    }

    public detailRows(): DataTableRow<NachbuchungDetail>[] {
        return this.detailsDataTable;
    }

}
