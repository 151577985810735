import {ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AttachmentService} from './attachments/common/attachment.service';
import {AttachmentInfoComponent} from './attachments/display/attachmentInfo/attachment-info.component';
import {AttachmentsQuickInfoComponent} from './attachments/display/quickinfo/attachments-quick-info.component';
import {AttachmentsControlComponent} from './attachments/controls/attachments-control.component';
import {AttachmentsCellRendererComponent} from './attachments/controls/attachments-cell-renderer.component';

import {CommonsModule} from '../commons/commons.module';
import {RechnungService} from './rechnungen/common/rechnung.service';
import {RechnungenComponent} from './rechnungen/rechnungen.component';
import {RechnungEditorComponent} from './rechnungen/editor/rechnung-editor.component';
import {RechnungCardComponent} from './rechnungen/display/info/rechnung-card.component';
import {RechnungInfoPopupComponent} from './rechnungen/display/infopopup/rechnung-info-popup.component';
import {RechnungInfoComponent} from './rechnungen/display/info/rechnung-info.component';
import {RechnungStatusCellRendererComponent} from './rechnungen/rechnung-status-cell-renderer.component';
import {RechnungStatusDisplayComponent} from './rechnungen/display/statusdisplay/rechnung-status-display.component';
import {RechnungStatusFieldComponent} from './rechnungen/rechnung-status-field.component';
import {FileTypeFieldComponent} from './file-type-field.component';
import {LieferscheineComponent} from './lieferscheine/lieferscheine.component';
import {TransaktionDetailsComponent} from './transaktionen/details/transaktion-details.component';
import {KartenanfrageKontingentCellRendererComponent} from './kartenanfragen/renderers/kartenanfrage-kontingent-cell-renderer.component';
import {KartenanfragenComponent} from './kartenanfragen/kartenanfragen.component';
import {PanCellRendererComponent} from './kartenanfragen/renderers/pan-cell-renderer.component';
import {NachbuchungEditorComponent} from './transaktionen/nachbuchung-editor/nachbuchung-editor.component';
import {KartenanfrageErgebnisCellRendererComponent} from './kartenanfragen/renderers/kartenanfrage-ergebnis-cell-renderer.component';
import {TransaktionenComponent} from './transaktionen/transaktionen.component';
import {TabTransaktionenComponent} from './transaktionen/tab-transaktionen/tab-transaktionen.component';
import {TransaktionDetailsRendererComponent} from './transaktionen/renderers/transaktion-details-renderer.component';
import {TransaktionenImporterComponent} from './transaktionen/importer/transaktionen-importer.component';
import {TabNachbuchungenComponent} from './transaktionen/tab-nachbuchungen/tab-nachbuchungen.component';
import {ProductsModule} from '../products/products.module';
import {AuthModule} from '../auth/auth.module';
import {TransaktionService} from './transaktionen/common/transaktion.service';
import {KartenanfrageService} from './kartenanfragen/common/kartenanfrage.service';
import {ImportFileTypeFieldComponent} from './import-file-type-field.component';
import {l10nConfig} from '../../l10nconfig';
import {BillingRoutingModule} from './billing-routing.module';
import {CustomersModule} from '../customers/customers.module';
import {StationenModule} from '../stationen/stationen.module';
import {DashboardInvoicesBlockComponent} from './dashboard-invoices-block/dashboard-invoices-block.component';
import {DashboardTransactionsBlockComponent} from './dashboard-transactions-block/dashboard-transactions-block.component';
import {DashboardKartenanfragenBlockComponent} from './dashboard-kartenanfragen-block/dashboard-kartenanfragen-block.component';
import {NachbuchungDetailsRendererComponent} from './transaktionen/renderers/nachbuchung-details-renderer.component';
import {NachbuchungDetailsComponent} from './transaktionen/nachbuchung-details/nachbuchung-details.component';
import {TransaktionPrintDialogComponent} from './transaktionen/transaktion-print-dialog/transaktion-print-dialog.component';
import {TransaktionLieferscheinComponent} from './transaktionen/transaktion-print-dialog/transaktion-lieferschein.component';
import {BestandsuebsersichtComponent} from './bestand/bestandsuebsersicht/bestandsuebsersicht.component';
import {LieferscheinService} from './lieferscheine/common/lieferschein.service';
import {LieferscheinEditorComponent} from './lieferscheine/lieferschein-editor/lieferschein-editor.component';
import {LieferscheinDetailsComponent} from './lieferscheine/lieferschein-details/lieferschein-details.component';
import {TankBestandService} from './bestand/service/tank-bestand.service';
import {TransaktionEditorComponent} from './transaktionen/transaktion-editor/transaktion-editor.component';
import {TransaktionenFilterdialogComponent} from './transaktionen/transaktionen-filterdialog/transaktionen-filterdialog.component';
import {KartenanfragenFilterdialogComponent} from './kartenanfragen/kartenanfragen-filterdialog/kartenanfragen-filterdialog.component';
import {TankbestandFilterdialogComponent} from './bestand/tankbestand-filterdialog/tankbestand-filterdialog.component';
import {LieferscheinFilterDialogComponent} from './lieferscheine/lieferschein-filter-dialog/lieferschein-filter-dialog.component';
import {DashboardLastTransactionsBlockComponent} from './transaktionen/dashboard-last-transactions-block/dashboard-last-transactions-block.component';
import {DashboardStatsBlockComponent} from './transaktionen/dashboard-stats-block/dashboard-stats-block.component';
import {DashboardLastKartenanfragenBlockComponent} from './kartenanfragen/dashboard-last-kartenanfragen-block/dashboard-last-kartenanfragen-block.component';

const components = [
    AttachmentInfoComponent,
    AttachmentsQuickInfoComponent,
    AttachmentsControlComponent,
    AttachmentsCellRendererComponent,
    RechnungenComponent,
    RechnungEditorComponent,
    RechnungCardComponent,
    RechnungInfoPopupComponent,
    RechnungInfoComponent,
    RechnungStatusCellRendererComponent,
    RechnungStatusDisplayComponent,
    RechnungStatusFieldComponent,
    FileTypeFieldComponent,
    LieferscheineComponent,
    TransaktionenComponent,
    TransaktionDetailsComponent,
    TransaktionDetailsRendererComponent,
    TransaktionenImporterComponent,
    TabTransaktionenComponent,
    NachbuchungEditorComponent,
    TabNachbuchungenComponent,
    KartenanfragenComponent,
    KartenanfrageErgebnisCellRendererComponent,
    KartenanfrageKontingentCellRendererComponent,
    PanCellRendererComponent,
    ImportFileTypeFieldComponent,
    DashboardInvoicesBlockComponent,
    DashboardTransactionsBlockComponent,
    DashboardKartenanfragenBlockComponent,
    NachbuchungDetailsRendererComponent,
    NachbuchungDetailsComponent,
    TransaktionPrintDialogComponent,
    TransaktionLieferscheinComponent,
    BestandsuebsersichtComponent,
    LieferscheinEditorComponent,
    LieferscheinDetailsComponent,
    TransaktionEditorComponent,
    TransaktionenFilterdialogComponent,
    KartenanfragenFilterdialogComponent,
    TankbestandFilterdialogComponent,
    LieferscheinFilterDialogComponent,
    DashboardStatsBlockComponent,
    DashboardLastTransactionsBlockComponent,
    DashboardLastKartenanfragenBlockComponent
];

@NgModule({
              imports: [
                  CommonModule,
                  CommonsModule,
                  AuthModule,
                  ProductsModule,
                  BillingRoutingModule,
                  CustomersModule,
                  StationenModule,
              ],
    declarations: [
        ...components
    ],
    exports: [
        ...components
    ],
    entryComponents: [
        ...components
    ]

})
export class BillingModule {


}
