import {AfterContentInit, Component, ContentChildren, EventEmitter, OnInit, Output, QueryList} from '@angular/core';
import {PaneComponent} from './pane.component';

@Component({
               selector   : 'app-tabcard',
               templateUrl: './tabcard.component.html',
               styleUrls  : ['./tabcard.component.css']
           })
export class TabcardComponent implements OnInit, AfterContentInit {

    @ContentChildren(PaneComponent) cpanes: QueryList<PaneComponent>;
    @Output() onTabSelect: EventEmitter<PaneComponent> = new EventEmitter<PaneComponent>();

    panes: PaneComponent[] = [];
    currentPane: PaneComponent;

    constructor() {
    }

    ngOnInit() {

    }

    ngAfterContentInit(): void {
        this.cpanes.forEach( p => {
            if (this.panes.length === 0) {
                p.active = true;
                this.onTabSelect.emit(p);
            }
            this.panes.push(p);
        })
    }

    select(pane: PaneComponent) {
        this.panes.forEach( p => {
            p.active = (p === pane)
        });
        this.onTabSelect.emit(pane);

    }
}
