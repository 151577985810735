import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    NgZone,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import {
    MEHighlitePipe,
    MESelectComponent,
    MESelectItemMatcherCallback,
    MESelectItemTextCallback
} from '../commons/dynamic-form/me-select/meselect.component';
import {FilterCallback} from '../commons/dynamic-form/FilterCallback';

import {LocalizedComponent} from '../commons/components/localized-component';

import {MESelectItemFormatterCallback} from '../commons/dynamic-form/me-select/meselect-item-formatter-callback';
import {KundeList} from './kunden/common/kunde-list';
import {Kunde} from './kunden/common/kunde';
import {KundenService} from './kunden/common/kunden.service';
import {SimpleKundeFilter} from './kunden/common/simple-kunde-filter';
import {CommonList} from '../commons/common-list';
import {GenericFilter} from '../commons/generic-filter';
import {IListResult} from '../commons/list-result';
import {IKunde} from './kunden/common/ikunde';
import {MESelectSearchEvent} from '../commons/dynamic-form/me-select/meselect-search-event';
import CryptoES from "crypto-es";

@Component({
    selector: 'kunde-inline-field',
    template: `
        <meselect
                id="{{ id }}"
                [idField]="'id'"
                [items]="kunden?.data"
                [asMatrix]="false"
                [placeholder]="label"
                (itemSelected)="doSelect($event)"
                (needsSearch)="doSearch($event)"
                [small]="small"
                [nullable]="nullable"
        >
        </meselect>
    `,
    styles: [``]
})
export class KundeInlineFieldComponent extends LocalizedComponent implements OnInit, AfterViewInit {
    @Input() nullable = true;

    @ViewChild(MESelectComponent, {static: true}) kundenMESelect: MESelectComponent;
    @Input() small = false;

    kunden: KundeList = new KundeList();

    _value: Kunde = null;

    @Input() label = 'KundeFeld';
    @Input() id = '';
    @Input() maxDisplayKunden = 50;

    @Input()
    set value(v: Kunde) {

        if (this._value !== v) {
            this._value = v;
            if ((this.kundenMESelect !== null) && (this.kundenMESelect !== undefined)) {
                this.kundenMESelect.value = v;
            }
            this.valueChange.emit(v);
        }
    }

    @Output() valueChange = new EventEmitter<Kunde>();


    get value(): Kunde {
        return this._value;
    }

    constructor(
        public kundenService: KundenService,
        public _ngZone: NgZone,
        public cd: ChangeDetectorRef) {
        super(cd);
    }

    public search: FilterCallback<Kunde> = (
        filter: GenericFilter<Kunde>,
        list: CommonList<Kunde>,
        maxDisplayItems: number,
        queryString: string,
        cfg: any,
        selectComponent: MESelectComponent
    ) => {
        queryString = ('' + queryString).trim();
        let q = '';
        if (queryString !== '') {
            if (isNaN(parseInt(queryString, 10))) {
                q = '(*' + queryString + '*) OR' +
                    '(' + queryString + ')';
            } else {
                q = '(kundennummer:' + parseInt(queryString, 10) + '^10) OR ' +
                    '(kundennummer:' + parseInt(queryString, 10) + '*^7) OR ' +
                    '(nummer:' + parseInt(queryString, 10) + '^5) OR ' +
                    '(nummer:' + parseInt(queryString, 10) + '*^3) OR ' +
                    '(*' + queryString + '*) OR' +
                    '(' + queryString + ')';
            }
        }
        this.kundenService
            .list(maxDisplayItems,
                0,
                [],
                filter as SimpleKundeFilter,
                q
            )
            .subscribe(
                (c: IListResult<IKunde>) => {
                    list.populateFromListResult(c);
                    if (selectComponent !== null && selectComponent !== undefined) {
                        selectComponent.items = list.data;
                        selectComponent.cd.markForCheck();
                    }
                }
            );
    }

    ngOnInit() {
        if (this.id === '') {
            this.id = 'tf' + CryptoES.MD5('kundefield-' + this.label + Math.random() + '-' + Math.random()).toString();
        }
        this.doSearch(new MESelectSearchEvent('', this.kundenMESelect));
    }

    ngAfterViewInit(): void {
        this.kundenMESelect.onFormat = this.itemFormat;
        this.kundenMESelect.onGetText = this.itemText;
        this.kundenMESelect.onMatch = this.itemMatcher;
        this.kundenMESelect.value = this._value;
    }

    doSearch(value: MESelectSearchEvent) {
        this.search(null, this.kunden, this.maxDisplayKunden, value.queryString, null, value.component);
    }

    doSelect(kunde: Kunde) {
        this._value = kunde;
        this.valueChange.emit(kunde);
    }

    public itemFormat: MESelectItemFormatterCallback<Kunde> = (item: Kunde, search, forList: boolean) => {
        if (item === null) {
            return '';
        }

        const icon = 'far fa-address-book';

        let i = '';

        if (item.bezeichnung !== null && item.bezeichnung !== '') {
            i = i + MEHighlitePipe.transformString(item.bezeichnung, search);
            if (forList) {
                i = i + '<br />';
            }
        }
        if (item.kundennummer !== null && item.kundennummer !== '') {
            if (!forList && (i !== '')) {
                i = i + ', ';
            }
            if (forList) {
                i = i + this._('Kundennummer:') + ' ';
            }
            i = i + MEHighlitePipe.transformString(item.kundennummer, search);
            if (forList) {
                i = i + '<br />';
            }
        }
        if (item.firma !== null && item.firma !== '') {
            if (!forList && (i !== '')) {
                i = i + ', ';
            }
            i = i + MEHighlitePipe.transformString(item.firma, search);
            if (forList) {
                i = i + '<br />';
            }
        }
        if (item.nachname !== null && item.nachname !== '') {
            if (!forList && (i !== '')) {
                i = i + ', ';
            }
            if (forList && item.vorname !== null) {
                i = i + item.vorname + ' ';
            }
            i = i + MEHighlitePipe.transformString(item.nachname, search);
            if (forList) {
                i = i + '<br />';
            }
        }

        if (item.ort !== null && item.ort !== '') {
            if (!forList && (i !== '')) {
                i = i + ', ';
            }
            if (forList && item.plz !== null) {
                i = i + item.plz + ' ';
            }
            i = i + item.ort;
        }
        if (item.nummer !== null) {
            if (!forList) {
                i = i + ' <span class=\'badge badge-default\'>' +
                    MEHighlitePipe.transformString('' + item.nummer, search) +
                    '</span>';
            } else {

                i = ' <span class=\'badge badge-default\'>' +
                    MEHighlitePipe.transformString('' + item.nummer, search) +
                    '</span>' + i;

            }
        }
        return '<i class=\'' + icon + '\'></i> ' + i;
    }

    public itemText: MESelectItemTextCallback<Kunde> = (item: Kunde) => {
        if (item === null) {
            return '';
        }
        let i = '';
        if (item.bezeichnung !== null && item.bezeichnung !== '') {
            i = i + item.bezeichnung;
        }
        if (item.kundennummer !== null && item.kundennummer !== '') {
            if (i !== '') {
                i = i + ', ';
            }
            i = i + item.kundennummer;
        }
        if (item.firma !== null && item.firma !== '') {
            if (i !== '') {
                i = i + ', ';
            }
            i = i + item.firma;
        }
        if (item.nachname !== null && item.nachname !== '') {
            if (i !== '') {
                i = i + ', ';
            }
            i = i + item.nachname;
        }
        if (item.ort !== null && item.ort !== '') {
            if (i !== '') {
                i = i + ', ';
            }
            i = i + item.ort;
        }
        return i + ' [' + item.nummer + ']';
    }

    public itemMatcher: MESelectItemMatcherCallback<Kunde> = (item: Kunde, search) => {
        if (!search) {
            return true;
        }
        if (search.trim() === '') {
            return true;
        }
        if (!item) {
            return false;
        }
        const x = item.bezeichnung + ' ' + item.kundennummer + ' ' + item.firma + ' ' + item.nachname + ' ' + item.nummer;

        return (x.toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) >= 0);
    }


}
