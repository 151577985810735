import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';


import * as moment from 'moment';
import {LocalizedComponent} from '../../../../commons/components/localized-component';
import {Begrenzung} from '../../common/begrenzung';
import {ELimitType} from '../../common/elimit-type';
import {ToastrService} from 'ngx-toastr';

@Component({
               selector   : 'app-freigabe-editor',
               templateUrl: './freigabe-editor.component.html',
               styleUrls  : ['./freigabe-editor.component.css']
           })
export class FreigabeEditorComponent extends LocalizedComponent implements OnInit {

    public self = null;

    _begrenzungen: Begrenzung[] = [];

    _currentBegrenzung: Begrenzung = new Begrenzung();

    workmode = 0;

    initing = false;

    @Input() disabled = false;

    @Input() mode = 'customer';

    @Input()
    set value(value: Begrenzung[]) {
        if (!this.initing) {
            this.initing = true;
            let same = false;
            if (Array.isArray(value) && Array.isArray(this._begrenzungen)) {
                if (value.length > 0) {
                    if (value[0] !== null && value[0] !== undefined) {
                        if (value.length === this._begrenzungen.length) {
                            if (value[0].id === this._begrenzungen[0].id) {
                                if (value[0].type === this._begrenzungen[0].type) {
                                    same = true;
                                }
                            }
                        }
                    }
                }
            }
            if (!same) {
                this._begrenzungen = [];
                if (Array.isArray(value)) {
                    const at = Begrenzung.fromResult(value[0]);
                    if (at !== null) {
                        at.id = this._begrenzungen.length + 1;
                        this._begrenzungen.push(at);
                    }
                } else {
                    const at = Begrenzung.fromResult(value);
                    if (at !== null) {
                        at.id = this._begrenzungen.length + 1;
                        this._begrenzungen.push(at);
                    }
                }

                if (this._begrenzungen.length === 0) {
                    this.workmode = 0;
                    this._currentBegrenzung = new Begrenzung();
                } else {

                    while (this._begrenzungen.length > 1) {
                        this._begrenzungen.splice(1);
                    }

                    this._currentBegrenzung = this._begrenzungen[0];

                    if (this._currentBegrenzung.isDefault()) {
                        if (this._currentBegrenzung.rejectAlways) {
                            this._currentBegrenzung.enabled = true;
                            this.workmode = 1;
                        } else {
                            this.workmode = 0;
                        }
                    } else {
                        this._currentBegrenzung.enabled = true;
                        this._currentBegrenzung.type = ELimitType.RECURRING;
                        this._currentBegrenzung.duration = 24 * 60 * 60;
                        this._currentBegrenzung.validFrom = moment().seconds(0).minute(0).hour(0).toDate();
                        this._currentBegrenzung.monday = true;
                        this._currentBegrenzung.tuesday = true;
                        this._currentBegrenzung.wednesday = true;
                        this._currentBegrenzung.thirsday = true;
                        this._currentBegrenzung.friday = true;
                        this._currentBegrenzung.saturday = true;
                        this._currentBegrenzung.sunday = true;
                        this._currentBegrenzung.rejectAlways = true;
                        this._currentBegrenzung.id = 1;

                        if (this._currentBegrenzung.laender.length > 0) {
                            this._currentBegrenzung.stationen = [];
                            this._currentBegrenzung.rejectAlways = false;
                            this.workmode = 2;
                        } else if (this._currentBegrenzung.stationen.length > 0) {
                            this._currentBegrenzung.laender = [];
                            this._currentBegrenzung.rejectAlways = false;
                            this.workmode = 3;
                        }
                    }
                }
            }
        }
        this.initing = false;
    }

    get value() {
        return this._begrenzungen;
    }

    @Output() valueChange: EventEmitter<Begrenzung[]> = new EventEmitter<Begrenzung[]>();


    constructor(

                public toastr: ToastrService,
                public cd: ChangeDetectorRef) {
        super( cd);
        this.self = this;
    }


    ngOnInit() {

    }

    setCountries(countries) {
        if (this._currentBegrenzung === null || this._currentBegrenzung === undefined) {
            this._currentBegrenzung = new Begrenzung();
            this._currentBegrenzung.type = ELimitType.RECURRING;
            this._currentBegrenzung.validFrom = moment().seconds(0).minute(0).hour(0).toDate();
            this._currentBegrenzung.duration = 24 * 60 * 60;
            this._currentBegrenzung.monday = true;
            this._currentBegrenzung.tuesday = true;
            this._currentBegrenzung.wednesday = true;
            this._currentBegrenzung.thirsday = true;
            this._currentBegrenzung.friday = true;
            this._currentBegrenzung.saturday = true;
            this._currentBegrenzung.sunday = true;
            this._currentBegrenzung.id = 1;
            this._begrenzungen = [this._currentBegrenzung];
        }
        this._currentBegrenzung.laender = countries;
        this._currentBegrenzung.enabled = true;
        this._currentBegrenzung.rejectAlways = false;
        if (!this.initing) {
            if (!this.disabled) {
                this.valueChange.emit(this._begrenzungen);
            }
        }

    }

    setStationen(stationen) {
        if (this._currentBegrenzung === null || this._currentBegrenzung === undefined) {
            this._currentBegrenzung = new Begrenzung();
            this._currentBegrenzung.type = ELimitType.RECURRING;
            this._currentBegrenzung.validFrom = moment().seconds(0).minute(0).hour(0).toDate();
            this._currentBegrenzung.duration = 24 * 60 * 60;
            this._currentBegrenzung.monday = true;
            this._currentBegrenzung.tuesday = true;
            this._currentBegrenzung.wednesday = true;
            this._currentBegrenzung.thirsday = true;
            this._currentBegrenzung.friday = true;
            this._currentBegrenzung.saturday = true;
            this._currentBegrenzung.sunday = true;
            this._currentBegrenzung.rejectAlways = false;
            this._currentBegrenzung.id = 1;
            this._begrenzungen = [this._currentBegrenzung];
        }
        this._currentBegrenzung.stationen = stationen;
        this._currentBegrenzung.enabled = true;
        this._currentBegrenzung.rejectAlways = false;
        if (!this.initing) {
            if (!this.disabled) {
                this.valueChange.emit(this._begrenzungen);
            }
        }
    }

    setWorkmode($event: number) {
        $event = parseInt('' + $event, 10);
        if (this.workmode !== $event) {
            this.workmode = $event;
            if (this.workmode === 0) {
                this._currentBegrenzung = null;
                this._begrenzungen = [];
            } else if (this.workmode === 1) {
                if (this._currentBegrenzung === null || this._currentBegrenzung === undefined) {
                    this._currentBegrenzung = new Begrenzung();
                }
                this._currentBegrenzung.type = ELimitType.DEFAULTS;
                this._currentBegrenzung.enabled = true;
                this._currentBegrenzung.rejectAlways = true;
                this._currentBegrenzung.monday = true;
                this._currentBegrenzung.tuesday = true;
                this._currentBegrenzung.wednesday = true;
                this._currentBegrenzung.thirsday = true;
                this._currentBegrenzung.friday = true;
                this._currentBegrenzung.saturday = true;
                this._currentBegrenzung.sunday = true;
                this._currentBegrenzung.laender = [];
                this._currentBegrenzung.stationen = [];
                this._currentBegrenzung.id = 1;
                this._begrenzungen = [this._currentBegrenzung];
            } else if (this.workmode === 2) {
                if (this._currentBegrenzung === null || this._currentBegrenzung === undefined) {
                    this._currentBegrenzung = new Begrenzung();
                }
                this._currentBegrenzung.type = ELimitType.RECURRING;
                this._currentBegrenzung.enabled = true;
                this._currentBegrenzung.validFrom = moment().seconds(0).minute(0).hour(0).toDate();
                this._currentBegrenzung.duration = 24 * 60 * 60;
                this._currentBegrenzung.monday = true;
                this._currentBegrenzung.tuesday = true;
                this._currentBegrenzung.wednesday = true;
                this._currentBegrenzung.thirsday = true;
                this._currentBegrenzung.friday = true;
                this._currentBegrenzung.saturday = true;
                this._currentBegrenzung.sunday = true;
                this._currentBegrenzung.rejectAlways = false;
                this._currentBegrenzung.id = 1;
                this._currentBegrenzung.stationen = [];

                this._begrenzungen = [this._currentBegrenzung];
            } else if (this.workmode === 3) {
                if (this._currentBegrenzung === null || this._currentBegrenzung === undefined) {
                    this._currentBegrenzung = new Begrenzung();
                }
                this._currentBegrenzung.enabled = true;
                this._currentBegrenzung.validFrom = moment().seconds(0).minute(0).hour(0).toDate();
                this._currentBegrenzung.type = ELimitType.RECURRING;
                this._currentBegrenzung.duration = 24 * 60 * 60;
                this._currentBegrenzung.monday = true;
                this._currentBegrenzung.tuesday = true;
                this._currentBegrenzung.wednesday = true;
                this._currentBegrenzung.thirsday = true;
                this._currentBegrenzung.friday = true;
                this._currentBegrenzung.saturday = true;
                this._currentBegrenzung.sunday = true;
                this._currentBegrenzung.rejectAlways = false;
                this._currentBegrenzung.laender = [];
                this._currentBegrenzung.id = 1;
                this._begrenzungen = [this._currentBegrenzung];
            }

            if (!this.initing) {
                if (!this.disabled) {
                    this.valueChange.emit(this._begrenzungen);
                }
            }
        }
    }
}
