import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Injector} from '@angular/core';
import {Kunde} from './kunden/common/kunde';

@Component({
    selector: 'app-customerelement-renderer',
    template: `
        <div *ngIf="value!=null && value!==undefined">
            {{value.bezeichnung}} <span class="badge badge-default">{{value.nummer}}</span>
        </div>
    `,
    styles: [`
        :host div {
            display: inline-block;
        }
    `],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomerelementRendererComponent {


    private _value: Kunde = null;


    get value(): Kunde {
        return this._value;
    }

    set value(value: Kunde) {
        this._value = value;
        this.cd.markForCheck();
    }

    constructor(public injector: Injector,
                public cd: ChangeDetectorRef
    ) {
        this.value = this.injector.get('value');
    }

}
