import {ChangeDetectorRef, Component, NgZone, OnInit} from '@angular/core';
import {LocalizedComponent} from '../../commons/components/localized-component';
import {PersonList} from './common/person-list';

import {PersonService} from './common/person.service';

import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {NewPageCountEvent} from '../../commons/paginator/paginator.component';
import {PersonEditorComponent} from './editor/person-editor.component';
import {Person} from './common/person';
import {DataTableFilterChanged, DataTableSortChanged} from '../../commons/data-table/data-table.component';
import {KitchenTimer} from '../../commons/kitchen-timer';
import {ResultMetaSortImpl} from '../../commons/result-meta';
import {KundeCellRendererComponent} from '../kunden/kunde-cell-renderer.component';
import {AusweisCellRendererComponent} from '../ausweise/ausweis-cell-renderer.component';
import {SysInfoService} from '../../commons/sysinfo/services/sys-info.service';
import {ToastrService} from 'ngx-toastr';
import {IListResult} from '../../commons/list-result';
import {IPerson} from './common/iperson';
import {LoginService} from '../../auth/services/login.service';
import {RowActionParams} from '../../commons/data-table/row-action-params';

@Component({
               selector   : 'app-person',
               templateUrl: './personen.component.html',
               styleUrls  : ['./personen.component.css' ]
           })
export class PersonenComponent extends LocalizedComponent implements OnInit {

    kundeR = KundeCellRendererComponent;
    ausweisR = AusweisCellRendererComponent;

    personen: PersonList = new PersonList();
    personenTimer: KitchenTimer;

    constructor(

                public personService: PersonService,
                public toastr: ToastrService,
                public zone: NgZone,
                public cd: ChangeDetectorRef,
                public sysInfoService: SysInfoService,
public loginService: LoginService,
                public modalService: NgbModal) {
        super( cd);
this.personen.size = this.loginService.getPageSize('personen');
        this.personenTimer = new KitchenTimer();
        this.personenTimer
            .subscribe(
                () => {
                    this.personService
                        .list(
                            this.personen.size,
                            this.personen.calcOffset(),
                            this.personen.order,
                            this.personen.simpleFilter
                        )
                        .subscribe(
                            (l: IListResult<IPerson>) => {
                                this.personen.populateFromListResult(l);
                            },
                            (e) => {
                                this.toastr.error(this._('Die Liste der Personen konnte nicht geladen werden.'));
                                this.personen.clear();
                            }
                        );
                }
            );
    }

    ngOnInit() {
        this.updatePersonen();
        this.sysInfoService.updateDocumentTitle(this._('Personen'));
    }

    updatePersonen(force = false, page?: number) {
        if ((page !== undefined) && (page != null)) {
            if (page !== this.personen.page) {
                this.personen.page = page;
                force            = true;
            }
        }
        this.personenTimer.start(force);
    }

    /**
     * ändert die Anzahl der Datensätze/Seite und lädt die Liste neu
     * @param n
     */
    updatePersonenPageCount(n: NewPageCountEvent) {
        if (this.personen.size !== n.itemsPerPage) {
            this.personen.size = n.itemsPerPage;
            this.loginService.setPageSize('personen', n.itemsPerPage);
        }
        this.updatePersonen(true, n.currentPage);
    }

    sortChanged(e: DataTableSortChanged) {
        this.personen.order = [
            new ResultMetaSortImpl(e.field, e.direction)
        ];
        this.updatePersonen();
    }

    filterChanged(e: DataTableFilterChanged) {
        this.zone.run(() => {
            this.personen.simpleFilter[ e.field ] = e.value;
            this.updatePersonen();
        });
    }

    newPerson() {
        PersonEditorComponent
            .open(this.modalService)
            .then(
                () => {
                    this.updatePersonen();
                },
                () => {
                    this.updatePersonen();
                }
            );
    }

    editPerson(params: RowActionParams<Person>) {

        PersonEditorComponent
            .open(this.modalService, params.row.data)
            .then(
                () => {
                    this.updatePersonen();
                },
                () => {
                    this.updatePersonen();
                }
            );

    }



}
