import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {Rechnung} from '../../common/rechnung';
import {NgbActiveModal, NgbModal, NgbModalOptions, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';

import {LocalizedComponent} from '../../../../commons/components/localized-component';

@Component({
    selector: 'app-rechnung-info-popup',
    template: `
        <div class="modal-header">
            <h5 class="modal-title">{{getTitle()}}</h5>
            <button type="button" class="close" aria-label="Close" (click)="cancel()">
                <span aria-hidden="true">&times;</span>
            </button>

        </div>
        <div class="modal-body">
            <app-rechnung-info
                    [entity]="entity"
            ></app-rechnung-info>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-primary" (click)="cancel()">
                <i class="icofont icofont-close"></i> {{ _('Schließen') }}
            </button>
        </div>

    `
})
export class RechnungInfoPopupComponent extends LocalizedComponent implements OnInit {
    rechnungLabel = 'Rechnung {{ nummer }} ';

    entity: Rechnung = new Rechnung();

    static open(modalService: NgbModal, rechnung: Rechnung = null): Promise<any> {
        const o: NgbModalOptions = {
            size: 'lg',
            backdrop: 'static',
            keyboard: false
        };
        const r: NgbModalRef = modalService.open(RechnungInfoPopupComponent, o);
        (r.componentInstance as RechnungInfoPopupComponent).open(rechnung);
        return r.result;
    }

    constructor(
                
                public activeModal: NgbActiveModal,
                public cd: ChangeDetectorRef) {
        super( cd);
    }

    ngOnInit() {
    }


    open(rechnung: Rechnung = null) {
        if (rechnung === null || rechnung === undefined) {
            this.cancel();
        } else {
            this.entity = Rechnung.fromResult(rechnung);
        }
    }

    cancel() {
        this.activeModal.dismiss('cancel');
    }

    getTitle() {
        return this._(this.rechnungLabel, {nummer: this.entity.nummer});
    }

}

