import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {LocalizedComponent} from '../commons/components/localized-component';
import {IOption, NullOption} from '../commons/dynamic-form/select-field/ioption';


import {EAusweisArt} from './ausweise/common/eausweis-art';
import {AusweisArt} from './ausweise/common/ausweis-art';
import CryptoES from "crypto-es";

@Component({
    selector: 'ausweis-art-field',
    template: `
        <div class="form-group row">
            <label for="{{ id }}" class="col-{{ labelwidth }} col-form-label">
                {{ label }}
            </label>
            <div class="col-{{ 12-labelwidth }}">

                <select
                        id="{{ id }}"
                        class="form-control"
                        [value]="value"
                        (input)="updateValue($event)"
                        title="{{ label }}"
                        [disabled]="disabled"
                        [class.disabled]="disabled"
                >
                    <option
                            *ngFor="let o of options"
                            value="{{ o.value }}"
                    >
                        <i class="{{o.icon}}" *ngIf="o.icon!==null && o.icon!==undefined"></i>
                        {{ o.label }}
                    </option>
                </select>
            </div>
        </div>
    `
})
export class AusweisArtFieldComponent extends LocalizedComponent implements OnInit {

    public _value: EAusweisArt = EAusweisArt.UNKNOWN;

    @Input() label = 'AusweisArtFeld';
    @Input() id = '';
    @Input() labelwidth = 3;
    @Input() nullable = false;
    @Input() disabled = false;

    options: IOption[] = [
        {
            value: EAusweisArt.UNKNOWN,
            label: this._('Unbekannter Typ/Sonstige'),
            icon: AusweisArt.getIcon(EAusweisArt.UNKNOWN)
        },
        {
            value: EAusweisArt.MAGSTRIPE,
            label: this._('Magnetstreifen-Karte'),
            icon: AusweisArt.getIcon(EAusweisArt.MAGSTRIPE)
        },
        {
            value: EAusweisArt.SMARTCARD,
            label: this._('Smartcard'),
            icon: AusweisArt.getIcon(EAusweisArt.SMARTCARD)
        },
        {
            value: EAusweisArt.CHIPTRANSPONDER,
            label: this._('Chip-Transponder'),
            icon: AusweisArt.getIcon(EAusweisArt.CHIPTRANSPONDER)
        },
        {
            value: EAusweisArt.BTTRANSPONDER,
            label: this._('BT/Funk Transponder'),
            icon: AusweisArt.getIcon(EAusweisArt.BTTRANSPONDER)
        },
        {
            value: EAusweisArt.PHONE,
            label: this._('Mobiltelefon-App'),
            icon: AusweisArt.getIcon(EAusweisArt.PHONE)
        },
        {
            value: EAusweisArt.HEAVY_TAG,
            label: this._('ME Heavy Tag'),
            icon: AusweisArt.getIcon(EAusweisArt.HEAVY_TAG)
        },
        {
            value: EAusweisArt.VIRTUAL,
            label: this._('virtueller Ausweis'),
            icon: AusweisArt.getIcon(EAusweisArt.VIRTUAL)
        }

    ];

    @Input()
    set value(v: EAusweisArt) {
        if (this._value !== v) {
            this._value = v;
            if (!this.disabled) {
                this.valueChange.emit(v);
            }
        }
    }

    @Output() valueChange = new EventEmitter<EAusweisArt>();


    get value(): EAusweisArt {
        return this._value;
    }

    constructor(
        public cd: ChangeDetectorRef) {
        super(cd);
    }

    ngOnInit() {
        if (this.nullable) {
            this.options.unshift(NullOption);
        }
        if (this.id === '') {
            this.id = 'tf' + CryptoES.MD5('AusweisArtfield-' + this.label + Math.random() + '-' + Math.random()).toString();
        }
    }

    updateValue($event: any) {
        this.value = $event.target.value;
    }

}
