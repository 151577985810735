import {ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {StationenComponent} from './stationen.component';
import {StationenEditorComponent} from './stationen-editor/stationen-editor.component';
import {StationenService} from './common/stationen.service';
import {StationenSelectorComponent} from './stationen-selector.component';
import {TanksModalComponent} from './tanks/editor/tanks-modal.component';
import {SondenModalComponent} from './sonden/sonden-modal.component';
import {ZapfsaeulenModalComponent} from './saeulen/zapfsaeulen-modal.component';
import {TerminalsModalComponent} from './terminals/terminals-modal.component';
import {StationQuickInfoComponent} from './display/station-quick-info.component';
import {StationDisplayComponent} from './display/station-display.component';
import {TerminalEditorComponent} from './terminals/editor/terminal-editor.component';
import {SaeuleZapfpunktQuickInfoComponent} from './saeulen/display/saeule-zapfpunkt-quick-info.component';
import {TerminalDisplayComponent} from './terminals/display/terminal-display.component';
import {TerminalInfoComponent} from './terminals/display/terminal-info.component';
import {TerminalQuickInfoComponent} from './terminals/display/terminal-quick-info.component';
import {CommonsModule} from '../commons/commons.module';

import {SondeFieldComponent} from './sonde-field.component';
import {StationFieldComponent} from './station-field.component';
import {SondenSelectorComponent} from './sonden-selector.component';
import {TankFieldComponent} from './tank-field.component';
import {ZapfsaeulenSelectorComponent} from './zapfsaeulen-selector.component';
import {ZapfsaeuleFieldComponent} from './zapfsaeule-field.component';
import {TerminalFieldComponent} from './terminal-field.component';
import {TerminalsSelectorComponent} from './terminals-selector.component';
import {TerminalInlineFieldComponent} from './terminal-inline-field.component';
import {StationInlineFieldComponent} from './station-inline-field.component';
import {StationArtFieldComponent} from './station-art-field.component';
import {l10nConfig} from '../../l10nconfig';
import {ExtendedZapfpunktRendererComponent} from './extended-zapfpunkt-renderer.component';
import {StationCellRendererComponent} from './station-cell-renderer.component';
import {TankCellRendererComponent} from './tank-cell-renderer.component';
import {StationCellFilterComponent} from './station-cell-filter.component';
import {TerminalCellFilterComponent} from './terminal-cell-filter.component';
import {TerminalCellEditorComponent} from './terminal-cell-editor.component';
import {TerminalCellRendererComponent} from './terminal-cell-renderer.component';
import {TankCellEditorComponent} from './tank-cell-editor.component';
import {AuthModule} from '../auth/auth.module';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {FormsModule} from '@angular/forms';
import {DashboardStationenBlockComponent} from './dashboard-stationen-block/dashboard-stationen-block.component';
import {TabAktiveSondenComponent} from './sonden/aktive-sonden/tab-aktive-sonden.component';
import {TankQuickInfoComponent} from './tank-quick-info.component';
import {ToastrModule} from 'ngx-toastr';
import {StationElementRendererComponent} from './station-element-renderer/station-element-renderer.component';
import {DTSondenBuchungCellRendererComponent} from './sonden/dtsonden-buchung-cell-renderer/dtsonden-buchung-cell-renderer.component';
import {SondenBuchungQuickInfoComponent} from './sonden/sonden-buchung-quick-info/sonden-buchung-quick-info.component';
import {SondenBuchungenDialogComponent} from './sonden/sonden-buchungen-dialog/sonden-buchungen-dialog.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {SondeEditorComponent} from './sonden/sonde-editor/sonde-editor.component';
import {SondenBuchungenDiagrammComponent} from './sonden/sonden-buchungen-diagramm/sonden-buchungen-diagramm.component';
import {SondenBuchungFilterComponent} from './sonden/sonden-buchungen-dialog/sonden-buchung-filter/sonden-buchung-filter.component';
import {DiagrammBackgroundComponent} from './sonden/sonden-buchungen-diagramm/diagramm-background.component';
import {DiagrammMarkerComponent} from './sonden/sonden-buchungen-diagramm/diagramm-marker.component';
import {StationenFilterDialogComponent} from './stationen-filter-dialog/stationen-filter-dialog.component';
import {SondenFilterDialogComponent} from './sonden/aktive-sonden/sonden-filter-dialog/sonden-filter-dialog.component';
import {ProductsModule} from '../products/products.module';
import {TankUeberwachungComponent} from './sonden/aktive-sonden/tank-ueberwachung/tank-ueberwachung.component';
import {SVGToolsModule} from '../svgtools/svgtools.module';
import {DashboardSondenInfoBlockComponent} from './sonden/dashboard-sonden-info-block/dashboard-sonden-info-block.component';
import {SondeDashboardDisplayComponent} from './sonde-dashboard-display/sonde-dashboard-display.component';
import { TankInlineFieldComponent } from './tank-inline-field/tank-inline-field.component';

const services = [
    StationenService
];

const components = [
    SaeuleZapfpunktQuickInfoComponent,
    SondeFieldComponent,
    SondenModalComponent,
    SondenSelectorComponent,
    StationDisplayComponent,
    StationenComponent,
    StationenEditorComponent,
    StationenSelectorComponent,
    StationFieldComponent,
    StationInlineFieldComponent,
    StationQuickInfoComponent,
    TankFieldComponent,
    TanksModalComponent,
    TerminalDisplayComponent,
    TerminalEditorComponent,
    TerminalFieldComponent,
    TerminalInfoComponent,
    TerminalInlineFieldComponent,
    TerminalQuickInfoComponent,
    TerminalsModalComponent,
    TerminalsSelectorComponent,
    ZapfsaeuleFieldComponent,
    ZapfsaeulenModalComponent,
    ZapfsaeulenSelectorComponent,
    StationArtFieldComponent,
    ExtendedZapfpunktRendererComponent,
    StationCellRendererComponent,
    TankCellRendererComponent,
    StationCellFilterComponent,
    TerminalCellEditorComponent,
    TerminalCellFilterComponent,
    TerminalCellRendererComponent,
    TankCellEditorComponent,
    DashboardStationenBlockComponent,
    TabAktiveSondenComponent,
    TankQuickInfoComponent,
    StationElementRendererComponent,
    DTSondenBuchungCellRendererComponent,
    SondenBuchungQuickInfoComponent,
    SondenBuchungenDialogComponent,
    SondeEditorComponent,
    SondenBuchungenDiagrammComponent,
    SondenBuchungFilterComponent,
    DiagrammBackgroundComponent,
    DiagrammMarkerComponent,
    StationenFilterDialogComponent,
    SondenFilterDialogComponent,
    TankUeberwachungComponent,
    DashboardSondenInfoBlockComponent,
        SondeDashboardDisplayComponent

];

@NgModule({
    imports: [
        CommonModule, BrowserAnimationsModule,
        NgbModule,
        FormsModule,
        ToastrModule.forRoot(),
        CommonsModule,
        AuthModule,
        ProductsModule,
        SVGToolsModule
    ],
    declarations: [
        ...components,
        TankInlineFieldComponent

    ],
    exports: [
        ...components
    ],
    entryComponents: [
        ...components

    ]
})
export class StationenModule {
}
