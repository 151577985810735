import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';

import {LocalizedComponent} from '../../../../commons/components/localized-component';
import {Rechnung} from '../../common/rechnung';

@Component({
               selector   : 'app-rechnung-card',
               templateUrl: './rechnung-card.component.html',
               styleUrls  : ['./rechnung-card.component.css']
           })
export class RechnungCardComponent extends LocalizedComponent implements OnInit {

    @Input() entity: Rechnung = null;

    rechnungLabel = 'Rechnung {{ nummer }} ';

    get label(): string {
        return this._(this.rechnungLabel, {nummer: this.entity.nummer});
    }

    constructor(
                
                public cd: ChangeDetectorRef) {
        super( cd);
    }

    ngOnInit() {
    }

}
