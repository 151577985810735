import {Component, EventEmitter, Input, NgZone, OnInit, Output} from '@angular/core';
import {Station} from '../stationen/common/station';
import {Terminal} from '../stationen/terminals/common/terminal';
import {StationenService} from '../stationen/common/stationen.service';

@Component({
    selector: 'terminal-field',
    styles: [`
        :host .addon button:first-child {
            border-left: none;
            padding-left: 5px;
            padding-right: 5px;
        }

    `],
    template: `
        <div class="form-group row">
            <label for="{{ id }}" class="col-{{ labelwidth }} col-form-label" *ngIf="labelwidth>0">
                {{ label }}
            </label>
            <div class="col-{{ 12-labelwidth }}">
                <terminal-inline-field
                        id="{{ id }}"
                        [value]="_value"
                        (valueChange)="doSelect($event)"
                        [small]="small"
                        [label]="label"
                        [station]="Stationen"
                        [nullable]="nullable"
                ></terminal-inline-field>
            </div>
        </div>
    `
})
export class TerminalFieldComponent implements OnInit {
    @Input() nullable: boolean=true;

    @Input() small: boolean = false;

    public Stationen: Station[] = [];
    @Input() label: string = 'TerminalAuswahlFeld';
    @Input() id: string = '';
    @Input() labelwidth: number = 3;
    @Input() maxDisplayTerminals: number = 50;
    @Output() valueChange = new EventEmitter<Terminal>();

    constructor(public StationenService: StationenService,
                public _ngZone: NgZone) {
    }

    @Input()
    set station(v: any) {
        if (Array.isArray(v)) {
            this.Stationen = v;
        } else {
            this.Stationen = [v];
        }
    }

    public _value: Terminal = null;

    get value(): Terminal {
        return this._value;
    }

    @Input()
    set value(v: Terminal) {

        if (this._value != v) {
            this._value = v;
            this.valueChange.emit(v);
        }
    }

    ngOnInit() {
    }


    doSelect(terminal: Terminal) {
        this._value = terminal;
        this.valueChange.emit(terminal);
    }


}
