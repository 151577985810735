import {Component, ContentChildren, EventEmitter, Input, Output, QueryList} from '@angular/core';
import {DataTableRow} from './data-table-row';
import {ListOptionDirective} from '../components/list-option.directive';
import {RowActionParams} from './row-action-params';

@Component({
    selector: 'rowaction',
    template: ''

})
export class DataTableRowAction {

    @Input() label = 'Befehl';
    @Input() icon = '';
    @Input() cmd = 'cmd';
    @Input() showLabel = false;
    @Input() title = '';
    @Input() hidden = false;
    @Input() type = '';

    @Input() alignRight = true;


    @Output() perform = new EventEmitter<any>();
    @Output() checkEnabled = new EventEmitter<RowActionParams<any>>();

    @ContentChildren(ListOptionDirective) listOptions: QueryList<ListOptionDirective>;


    constructor() {
    }

isHidden(row: DataTableRow<any> = null): boolean {
        if (row != null) {
            return row.isActionHidden(this);
        }
        return this.hidden;
    }

    performAction(row: DataTableRow<any> = null) {
        if (row === null || row === undefined) {
            this.perform.emit(this);
        } else if (!row.isActionDisabled(this)) {
            this.perform.emit(new RowActionParams<any>(row, this));
        }
    }

    hasIcon(): boolean {
        return (this.icon !== undefined) &&
            (this.icon !== null) &&
            (this.icon !== '');
    }

    checkIfEnabled(row: DataTableRow<any>) {
        this.checkEnabled.emit(new RowActionParams<any>(row, this));
    }


    isNormalAction() {
        return this.type === null || this.type === undefined || this.type === '' || this.type === 'default';
    }

    isDropdown() {
        return this.type === 'dropdown';
    }

    hasOptions() {
        return (this.listOptions !== null) && (this.listOptions !== undefined) && (this.listOptions.length > 0);
    }

    isSeparator() {
        return this.type === 'separator';
    }
}
