import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    NgZone,
    OnInit,
    ViewChild
} from '@angular/core';
import {LocalizedComponent} from '../../commons/components/localized-component';
import {KundeList} from '../kunden/common/kunde-list';

import {KundenService} from '../kunden/common/kunden.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {NewPageCountEvent, PaginatorComponent} from '../../commons/paginator/paginator.component';
import {Kunde} from '../kunden/common/kunde';
import {PreisWertCellRendererComponent} from '../../commons/data-table/renderer/preis-wert-cell-renderer.component';
import {RechnungList} from '../../billing/rechnungen/common/rechnung-list';
import {RechnungService} from '../../billing/rechnungen/common/rechnung.service';
import {
    DataTableComponent,
    DataTableFilterChanged,
    DataTableRowSelected,
    DataTableSortChanged
} from '../../commons/data-table/data-table.component';
import {Rechnung} from '../../billing/rechnungen/common/rechnung';
import {RechnungEditorComponent} from '../../billing/rechnungen/editor/rechnung-editor.component';
import {RechnungInfoPopupComponent} from '../../billing/rechnungen/display/infopopup/rechnung-info-popup.component';
import {KitchenTimer} from '../../commons/kitchen-timer';
import {AusweisList} from '../ausweise/common/ausweis-list';
import {AusweisService} from '../ausweise/common/ausweis.service';
import {PinDialogComponent} from '../ausweise/pin-dialog/pin-dialog.component';
import {Ausweis} from '../ausweise/common/ausweis';
import {AusweisEditorComponent} from '../ausweise/editor/ausweis-editor.component';
import {AddOnClickEvent} from '../../commons/dynamic-form/add-on-click-event';
import {ResultMetaSortImpl} from '../../commons/result-meta';
import {LandCellRendererComponent} from '../../commons/data-table/renderer/land-cell-renderer.component';
import {AttachmentsCellRendererComponent} from '../../billing/attachments/controls/attachments-cell-renderer.component';
import {ActivatedRoute} from '@angular/router';
import {KontingenteCellRendererComponent} from '../limits/renderer/kontingente-cell-renderer/kontingente-cell-renderer.component';
import {TDTGetExtraRowClassesCallback} from '../../commons/data-table/types';
import {DataTableRow} from '../../commons/data-table/data-table-row';
import {BegrenzungenCellRendererComponent} from '../limits/renderer/begrenzungen-cell-renderer/begrenzungen-cell-renderer.component';
import {ToastrService} from 'ngx-toastr';
import {IListResult} from '../../commons/list-result';
import {IAusweis} from '../ausweise/common/iausweis';
import {IRechnung} from '../../billing/rechnungen/common/irechnung';
import {IKunde} from '../kunden/common/ikunde';
import {LoginService} from '../../auth/services/login.service';
import {KundenAusweisEditorComponent} from '../ausweise/editor/kunden-ausweis-editor.component';
import {RowActionParams} from '../../commons/data-table/row-action-params';

@Component({
               selector       : 'app-dtskunden',
               templateUrl    : './dtskunden.component.html',
               styleUrls      : ['./dtskunden.component.css'],
               changeDetection: ChangeDetectionStrategy.OnPush
           })
export class DtskundenComponent extends LocalizedComponent implements OnInit, AfterViewInit {

    landR = LandCellRendererComponent;
    attachmentsR = AttachmentsCellRendererComponent;
    kontingentCellRenderer = KontingenteCellRendererComponent;
    begrenzungenCellRenderer = BegrenzungenCellRendererComponent;

    kunden: KundeList = new KundeList();
    kundenTimer: KitchenTimer;
    kundenContainerHeight = 0;
    hasSelection = false;
    entity: Kunde = null;
    mode = 'browse';
    ausweise: AusweisList = new AusweisList();
    ausweiseTimer: KitchenTimer;
    public preiswert = PreisWertCellRendererComponent;
    rechnungen: RechnungList = null;
    rechnungenTimer: KitchenTimer;

    @ViewChild('kundenListe',{static: true}) kundenListe: DataTableComponent;
    @ViewChild('ausweisePaginator',{static: true}) ausweisePaginator: PaginatorComponent;


    constructor(
                
                public kundenService: KundenService,
                public toastr: ToastrService,
                public rechnungService: RechnungService,
                public zone: NgZone,
                public modalService: NgbModal,
                public ausweisService: AusweisService,
                public cd: ChangeDetectorRef,
                public route: ActivatedRoute,
                public loginService: LoginService
    ) {
        super( cd);
        this.kunden.searchString = this.route.snapshot.queryParams['k'] || '';
        this.kunden.size = 25;

        this.kundenTimer = new KitchenTimer();
        this.kundenTimer
            .subscribe(
                () => {
                    this.kundenService
                        .list(
                            this.kunden.size,
                            this.kunden.calcOffset(),
                            this.kunden.order,
                            this.kunden.simpleFilter,
                            this.kunden.getQuery()
                        )
                        .subscribe(
                            (l: IListResult<IKunde>) => {
                                this.kunden.populateFromListResult(l);
                                this.cd.markForCheck();
                            },
                            (e) => {
                                this.toastr.error(this._('Die Liste der Kunden konnte nicht geladen werden.'));
                                this.kunden.clear();
                                this.entity = null;
                                this.cd.markForCheck();
                            }
                        );
                }
            );

        this.ausweise.size = 25;
        this.ausweise.searchString = this.route.snapshot.queryParams['a'] || '';
        if ((this.ausweise.searchString === '') ||
            (this.ausweise.searchString === null) ||
            (this.ausweise.searchString === undefined)
        ) {
            this.ausweise.searchString2 = '_ausweis_id:-1';
        }
        this.ausweiseTimer = new KitchenTimer();
        this.ausweiseTimer
            .subscribe(
                () => {
                    this.ausweisService
                        .list(
                            this.ausweise.size,
                            this.ausweise.calcOffset(),
                            this.ausweise.order,
                            null,
                            this.ausweise.getQuery()
                        )
                        .subscribe(
                            (l: IListResult<IAusweis>) => {
                                this.ausweise.populateFromListResult(l);
                                this.cd.markForCheck();
                            },
                            (e) => {
                                this.toastr.error(this._('Die Liste der Ausweise konnte nicht geladen werden.'));
                                this.ausweise.clear();
                                this.cd.markForCheck();
                            }
                        );
                }
            );
        this.rechnungen = new RechnungList();
        this.rechnungen.simpleFilter.kunde = new Kunde();
        this.rechnungen.simpleFilter.kunde.id = -1;
        this.rechnungen.size = 25;
        this.rechnungenTimer = new KitchenTimer();
        this.rechnungenTimer
            .subscribe(
                () => {
                    this.rechnungService
                        .list(this.rechnungen.size,
                              this.rechnungen.calcOffset(),
                              this.rechnungen.order,
                              this.rechnungen.simpleFilter
                        ).subscribe(
                        (l: IListResult<IRechnung>) => {
                            this.rechnungen.populateFromListResult(l);
                            this.cd.markForCheck();
                        },
                        (e) => {
                            this.toastr.error(this._('Die Liste der Rechnungen konnte nicht geladen werden.'));
                            this.rechnungen.clear();
                            this.cd.markForCheck();
                        }
                    );
                }
            );
    }

    kundenRowClassGetter: TDTGetExtraRowClassesCallback<Kunde> = (row: DataTableRow<Kunde>) => {
        if (
            row !== null &&
            row !== undefined &&
            row.data !== null &&
            row.data !== undefined
        ) {
            return 'kunde-' + (row.data.enabled ? 'frei' : 'gesperrt');
        }
        return '';
    }


    ngOnInit() {
        this.updateKunden();
        this.cd.markForCheck();
    }

    ngAfterViewInit() {
        if (this.kundenListe !== undefined && this.kundenListe !== null) {
            this.kundenListe.extraRowClasses = this.kundenRowClassGetter;
        }
    }

    updateAusweise(force = false, page?: number) {
        if ((page !== undefined) && (page != null)) {
            if (page !== this.ausweise.page) {
                this.ausweise.page = page;
                force = true;
            }
        }
        this.ausweiseTimer.start(force);
    }

    updateAusweisePageCount(n: NewPageCountEvent) {
        this.ausweise.size = n.itemsPerPage;
        this.updateAusweise(true, n.currentPage);
    }

    ausweiseSortChanged(e: DataTableSortChanged) {
        this.ausweise.order = [
            new ResultMetaSortImpl(e.field, e.direction)
        ];
        this.updateAusweise();
    }

    updateKunden(force = false, page?: number) {
        if ((page !== undefined) && (page != null)) {
            if (page !== this.kunden.page) {
                this.kunden.page = page;
                force = true;
            }
        }
        this.kundenTimer.start(force);
    }

    /**
     * ändert die Anzahl der Datensätze/Seite und lädt die Liste neu
     * @param n
     */
    updateKundenPageCount(n: NewPageCountEvent) {
        this.kunden.size = n.itemsPerPage;
        this.updateKunden(true, n.currentPage);
    }

    sortChanged(e: DataTableSortChanged) {
        this.kunden.order = [
            new ResultMetaSortImpl(e.field, e.direction)
        ];
        this.updateKunden();
    }

    filterChanged(e: DataTableFilterChanged) {
        this.zone.run(() => {
            this.kunden.simpleFilter[e.field] = e.value;
            this.updateKunden();
        });
    }

    newKunde() {
        this.hasSelection = false;
        this.entity = new Kunde();
        this.entity.bezeichnung = this._('Neuer Kunde');
        this.mode = 'add';
        this.cd.markForCheck();

    }

    editKunde() {
        if (this.hasSelection) {
            if (this.entity !== null) {
                this.mode = 'edit';
                this.cd.markForCheck();
            }
        }
    }

    syncCustomers() {
        this.kundenService
            .syncKunden()
            .subscribe(
                () => {
                    this.toastr.success(this._('Der Synchronisationsvorgang wurde angestoßen.'));
                },
                () => {
                    this.toastr.error(this._('Die Synchronisationsvorgang konnte nicht angestoßen werden.'));
                }
            );
    }

    rowSelected(row: DataTableRowSelected<Kunde>) {
        if (this.ausweisePaginator !== null && this.ausweisePaginator !== undefined) {
            this.ausweisePaginator.query = this.ausweise.searchString;
            this.ausweisePaginator.queryFieldVisible = true;
        }
        this.ausweise.clear(false);
        if (row === null || row === undefined) {
            this.hasSelection = false;
            this.entity = null;
            this.kundenContainerHeight = 0;
            this.ausweise.searchString2 = '_kunde_id:-2';
            this.cd.markForCheck();
        } else {
            this.hasSelection = true;
            this.entity = Kunde.fromResult(row.row.data);
            this.kundenContainerHeight = 250;
            this.ausweise.simpleFilter.kunde = this.entity;
            this.ausweise.searchString2 = '_kunde_id:' + this.entity.id;
            this.updateAusweise();
            this.rechnungen.simpleFilter.kunde = this.entity;
            this.updateRechnungen();
            this.cd.markForCheck();
        }
    }

    addOnClick(event: AddOnClickEvent) {
        if ('nummer-field' === event.component.id) {
            if (event.addon === 0) {
                this.kundenService
                    .findFreeNum()
                    .subscribe(
                        resp => {
                            if (resp.success) {
                                event.component.setValue(resp.params.nummer as number);
                                this.cd.markForCheck();
                            } else {
                                this.toastr.error(this.t('Es konnte keine freie Nummer ermittelt werden.'));
                            }
                        },
                        () => {
                            this.toastr.error(this.t('Es konnte keine freie Nummer ermittelt werden.'));
                        }
                    );
            }
        }
    }

    realCanLogin(): boolean {
        if ((this.entity === null) || (this.entity === undefined)) {
            return false;
        }
        if ((this.entity.kundennummer === null) ||
            (this.entity.kundennummer === undefined) ||
            (('' + this.entity.kundennummer).trim() === '')) {
            return false;
        }
        if ((this.entity.canLogin === null) || (this.entity.canLogin === undefined)) {
            return false;
        }
        return this.entity.canLogin;
    }

    updateBegrenzungen(bg) {
        this.entity.begrenzungen = bg;
    }

    isBrowsing() {
        return this.mode === 'browse';
    }

    isEditing() {
        return ((this.mode === 'edit') || (this.mode === 'add'));
    }

    isAdding() {
        return this.mode === 'add';
    }

    cancel() {
        if (this.isAdding()) {
            this.entity = null;
            this.hasSelection = false;
            this.kundenContainerHeight = 0;
        }
        this.mode = 'browse';
        this.cd.markForCheck();
    }

    newAusweis() {
        AusweisEditorComponent
            .open(this.modalService, undefined, this.entity)
            .then(
                () => {
                    this.updateAusweise();
                },
                () => {
                    this.updateAusweise();
                }
            );
    }

    editAusweis(params: RowActionParams<Ausweis>) {

        this.ausweisService.loadAusweis(params.row.data.id).subscribe(
            (a: IAusweis) => {
                if (this.loginService.isCustomer() || this.loginService.hasOneOfRoles(['ROLE_ADM'])) {
                    KundenAusweisEditorComponent
                        .open(this.modalService, Ausweis.fromResult(a))
                        .then(
                            () => {
                                this.updateAusweise();
                            },
                            () => {
                                this.updateAusweise();
                            }
                        );

                } else if (this.loginService.hasOneOfRoles(['ROLE_ADMIN', 'ROLE_BILLING', 'ROLE_PROJECT_ADMIN'])) {
                    AusweisEditorComponent
                        .open(this.modalService, Ausweis.fromResult(a))
                        .then(
                            () => {
                                this.updateAusweise();
                            },
                            () => {
                                this.updateAusweise();
                            }
                        );
                }
            });
    }

    canSave(): boolean {
        if ((this.entity.canLogin === null) || (this.entity.canLogin === undefined)) {
            return false;
        }
        if (this.entity.canLogin && !this.realCanLogin()) {
            return false;
        }
        if (
            (this.entity.nummer === null) ||
            (this.entity.nummer === undefined) ||
            isNaN(parseInt('' + this.entity.nummer, 10)) ||
            (this.entity.nummer < 1)
        ) {
            return false;
        }

        return true;
    }


    save() {
        if (this.canSave()) {
            this.kundenService
                .store(
                    this.entity, this.isAdding()
                )
                .subscribe(
                    resp => {
                        if (resp.success) {
                            this.mode = 'browse';
                            this.updateKunden();
                        } else {
                            this.toastr.error(this.t('Beim Speichern der Kundendaten ist ein Fehler aufgetreten.'));
                        }
                    },
                    () => {
                        this.toastr.error(this.t('Beim Speichern der Kundendaten ist ein Fehler aufgetreten.'));
                    }
                );
        }
    }

    updateRechnungen(force = false, page?: number) {
        if ((page !== undefined) && (page != null)) {
            if (page !== this.rechnungen.page) {
                this.rechnungen.page = page;
                force = true;
            }
        }
        this.rechnungenTimer.start(force);
    }

    updateRechnungenPageCount(n: NewPageCountEvent) {
        this.rechnungen.size = n.itemsPerPage;
        this.updateRechnungen(true, n.currentPage);
    }


    rechnungenSortChanged(e: DataTableSortChanged) {
        this.rechnungen.order = [
            new ResultMetaSortImpl(e.field, e.direction)
        ];
        this.updateRechnungen();
    }

    showDetails(row: RowActionParams<Rechnung>) {
        RechnungInfoPopupComponent
            .open(this.modalService, row.row.data as Rechnung)
            .then(() => {
                this.updateRechnungen();
            }, () => {
                this.updateRechnungen();
            })
        ;
    }

    newInvoice() {
        RechnungEditorComponent.open(this.modalService).then(() => {
            this.updateRechnungen();
        }, () => {
            this.updateRechnungen();
        });
    }

    editRechnung(row: RowActionParams<Rechnung>) {
        RechnungEditorComponent
            .open(this.modalService, row.row.data as Rechnung)
            .then(() => {
                this.updateRechnungen();
            }, () => {
                this.updateRechnungen();
            });
    }

    kundenQueryChange(value = '') {
        if (value !== this.kunden.searchString) {
            this.kunden.searchString = value;
            this.updateKunden(false, 1);
        }
    }

    reindexKunden() {
        this.kundenService
            .reindexKunden()
            .subscribe(
                () => {
                    this.toastr.success(this._('Der Indizierungsvorgang wurde angestoßen.'));
                },
                () => {
                    this.toastr.error(this._('Die Indizierungsvorgang konnte nicht angestoßen werden.'));
                }
            );
    }

    ausweiseQueryChange(value = '') {
        if (value !== this.ausweise.searchString) {
            this.ausweise.searchString = value;
            this.updateAusweise(false, 1);
        }

    }

    syncAusweise() {
        this.ausweisService
            .syncAusweise()
            .subscribe(
                () => {
                    this.toastr.success(this._('Der Synchronisationsvorgang wurde angestoßen.'));
                },
                () => {
                    this.toastr.error(this._('Die Synchronisationsvorgang konnte nicht angestoßen werden.'));
                }
            );
    }

    reindexAusweise() {
        this.ausweisService
            .reindexAusweise()
            .subscribe(
                () => {
                    this.toastr.success(this._('Der Indizierungsvorgang wurde angestoßen.'));
                },
                () => {
                    this.toastr.error(this._('Die Indizierungsvorgang konnte nicht angestoßen werden.'));
                }
            );
    }

    setPin(params: RowActionParams<Ausweis>) {
        if (!params.row.data.fremdausweis) {

            PinDialogComponent
                .open(this.modalService, params.row.data)
                .then(
                    () => {
                        this.updateAusweise();
                    },
                    () => {
                        this.updateAusweise();
                    }
                );
        } else {
            this.toastr.error(this._('Die PIN dieses Ausweises kann nicht geändert werden.'));
        }
    }

    syncRatioKunden() {
        this.kundenService
            .syncRatioKunden()
            .subscribe(
                () => {
                    this.ausweisService
                        .syncRatioAusweise()
                        .subscribe(
                            () => {
                                this.toastr.success(this._('Der Importvorgang wurde angestoßen.'));
                            },
                            () => {
                                this.toastr.error(this._('Die Importvorgang konnte nicht angestoßen werden.'));
                            }
                        );
                },
                () => {
                    this.toastr.error(this._('Die Importvorgang konnte nicht angestoßen werden.'));
                }
            );
    }

    syncAusweis(params: RowActionParams<Ausweis>) {

        this.ausweisService
            .syncAusweis(params.row.data)
            .subscribe(
                () => {
                    this.toastr.success(this._('Der Syncvorgang wurde angestoßen.'));
                },
                () => {
                    this.toastr.error(this._('Der Syncvorgang konnte nicht angestoßen werden.'));
                }
            );

    }

    syncKunde(params: RowActionParams<Kunde>) {

        this.kundenService
            .syncKunde(params.row.data)
            .subscribe(
                () => {
                    this.toastr.success(this._('Der Syncvorgang wurde angestoßen.'));
                },
                () => {
                    this.toastr.error(this._('Der Syncvorgang konnte nicht angestoßen werden.'));
                }
            );

    }

    canChangeBetreuer() {
        return this.loginService.hasOneOfRoles(['ROLE_ADMIN', 'ROLE_PROJECT_ADMIN']);
    }

    canEdit(betreuer: string) {
        return this.loginService.hasOneOfRoles(['ROLE_ADMIN', 'ROLE_PROJECT_ADMIN', 'ROLE_BILLING']);
    }
}
