<me-info-tool
        [title]="getTitle()"
        [title_icon]="getIcon()"
        [position]="info_position"
        *ngIf="_ausweis!==null">
    <app-ausweis-info [ausweis]="_ausweis"></app-ausweis-info>
</me-info-tool>
<div *ngIf="showLabel && _ausweis!==null && _ausweis!==undefined " title="{{ _ausweis?.pan }}" style="display:inline-block;">
    <i class="{{ getIcon() }}"></i>
    <i class="icofont icofont-lock" *ngIf="_ausweis.adminSperre||_ausweis.kundenSperre"></i>
    {{ _ausweis.nummer }}
    <i class="icofont icofont-edit xlink" *ngIf="hasLink()" title="{{_('Karte bearbeiten')}}" (click)="editAusweis()"></i>
</div>
