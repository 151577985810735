import {Terminal} from '../../stationen/terminals/common/terminal';

export class MELoraGatewaySettings {
    ip = '192.168.16.';
    port = 9900;
    ssh_port = 14322;
    ssh_user = 'root';
    ssh_password = '';
    terminal: Terminal = null;
    customer_id = 0;
    mac = '';
    server = '';

    static fromRAW(st: any): MELoraGatewaySettings {
        if (st === null || st === undefined) {
            return null;
        }
        const l = new MELoraGatewaySettings();
        for (const k of Object.keys(st)) {
            if (k === 'terminal') {
                l.terminal = Terminal.fromResult(st.terminal || null);
            } else  {
                l[k] = (st[k] !== undefined) ? st[k] : null;
            }
        }
        return l;
    }
}
