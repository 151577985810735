import {ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {LocalizedComponent} from '../../commons/components/localized-component';
import {Station} from '../common/station';
import {ZapfsaeuleList} from './common/zapfsaeule-list';
import {Zapfsaeule} from './common/zapfsaeule';

import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {StationenService} from '../common/stationen.service';

import {NewPageCountEvent} from '../../commons/paginator/paginator.component';
import {ConfirmDialogComponent} from '../../commons/confirm-dialog/confirm-dialog.component';
import {ZapfpunktList} from './common/zapfpunkt-list';
import {Zapfpunkt} from './common/zapfpunkt';
import {Subscription} from 'rxjs';
import {PubSubService} from '../../commons/pub-sub.service';
import {DataTableComponent, DataTableEditorAddOnClick, DataTableRowEditCancelled, DataTableRowSelected, DataTableSaveRecord} from '../../commons/data-table/data-table.component';
import {NumCellEditorComponent} from '../../commons/data-table/editors/num-cell-editor.component';
import {KitchenTimer} from '../../commons/kitchen-timer';
import {ActionResponse} from '../../commons/action-response';
import {IZapfsaeule} from './common/izapfsaeule';
import {IZapfpunkt} from './common/izapfpunkt';
import {TankCellRendererComponent} from '../tank-cell-renderer.component';
import {TankCellEditorComponent} from '../tank-cell-editor.component';
import {ToastrService} from 'ngx-toastr';
import {IListResult} from '../../commons/list-result';
import {RowActionParams} from '../../commons/data-table/row-action-params';

@Component({
    selector: 'app-zapfsaeulen-modal',
    templateUrl: './zapfsaeulen-modal.component.html',
    styleUrls: ['./zapfsaeulen-modal.component.scss']
})
export class ZapfsaeulenModalComponent extends LocalizedComponent implements OnInit, OnDestroy {

    tankR = TankCellRendererComponent;
    tankE = TankCellEditorComponent;

    public sub: Subscription = null;

    @ViewChild('saeulenTable',{static: true}) saeulenTable: DataTableComponent;
    @ViewChild('zapfpunkteTable', {static: false}) zapfpunkteTable: DataTableComponent;
    station: Station = new Station();
    saeulen: ZapfsaeuleList = new ZapfsaeuleList();
    public saeulenTimer: KitchenTimer;

    zapfpunkte: ZapfpunktList = new ZapfpunktList();
    public zapfpunkteTimer: KitchenTimer;

    newSaeule: Zapfsaeule = null;
    currentSaeule: Zapfsaeule = null;
    newZapfpunkt = null;


    constructor(

                public activeModal: NgbActiveModal,
                public stationenService: StationenService,
                public toastr: ToastrService,
                public modalService: NgbModal,
                public cd: ChangeDetectorRef,
                public pubsub: PubSubService) {
        super( cd);
        this.saeulenTimer = new KitchenTimer();
        this.saeulenTimer.subscribe(
            () => {
                this.stationenService
                    .listSaeulen(
                        this.station,
                        this.saeulen.size,
                        this.saeulen.calcOffset(),
                        this.saeulen.order,
                        this.saeulen.simpleFilter
                    )
                    .subscribe(
                        (l: IListResult<IZapfsaeule>) => {
                            this.saeulen.populateFromListResult(l);
                            setTimeout(() => {
                                this.saeulenTable.selectFirst();
                            }, 50);

                        },
                        () => {
                            this.toastr.error(this._('Die Liste der Zapfsäulen konnte nicht geladen werden.'));
                            this.saeulen.clear();
                        }
                    );
            }
        );

        this.zapfpunkteTimer = new KitchenTimer();
        this.zapfpunkteTimer.subscribe(
            () => {
                if ((this.currentSaeule === null) || (this.currentSaeule === undefined)) {
                    this.zapfpunkte.clear();
                } else {
                    this.stationenService
                        .listZapfpunkte(
                            this.station,
                            this.currentSaeule,
                            this.zapfpunkte.size,
                            this.zapfpunkte.calcOffset(),
                            this.zapfpunkte.order,
                            this.zapfpunkte.simpleFilter
                        )
                        .subscribe(
                            (l: IListResult<IZapfpunkt>) => {
                                this.zapfpunkte.populateFromListResult(l);
                            },
                            () => {
                                this.toastr.error(this._('Die Liste der Zapfpunkte konnte nicht geladen werden.'));
                                this.zapfpunkte.clear();
                            }
                        );
                }
            }
        );
    }

    get msg_title() {
        return this._('Zapfsäulen der Station {{ name }} [{{ nummer }}]', {name: this.station.bezeichnung, 'nummer': this.station.nummer});
    }

    get msg_title2() {
        return this._('Zapfpunkte an {{ name }} [{{ nummer }}]', {name: this.currentSaeule.bezeichnung, 'nummer': this.currentSaeule.nummer});
    }

    ngOnInit() {
        this.sub = this.pubsub.loginError.subscribe(
            () => {
                this.cancel();
            }
        );
        this.updateSaeulen();
        this.updateZapfpunkte();
    }

    ngOnDestroy(): void {
        if (this.sub != null) {
            this.sub.unsubscribe();
            this.sub = null;
        }
    }


    cancel() {
        this.activeModal.dismiss('cancel');
    }


    edit(e: Station) {
        this.station = e;
    }


    updateSaeulen(force = false, page?: number) {
        if ((page !== undefined) && (page != null)) {
            if (page !== this.saeulen.page) {
                this.saeulen.page = page;
                force = true;
            }
        }
        this.saeulenTimer.start(force);
    }

    updateZapfpunkte(force = false, page?: number) {
        if (this.currentSaeule === null) {
            this.zapfpunkte.clear();
        } else {
            if ((page !== undefined) && (page != null)) {
                if (page !== this.zapfpunkte.page) {
                    this.zapfpunkte.page = page;
                    force = true;
                }
            }
            this.zapfpunkteTimer.start(force);
        }
    }


    /**
     * ändert die Anzahl der Datensätze/Seite und lädt die Liste neu
     * @param n
     */
    updateSaeulenPageCount(n: NewPageCountEvent) {
        this.saeulen.size = n.itemsPerPage;
        this.updateSaeulen(true, n.currentPage);
    }

    updateZapfpunktePageCount(n: NewPageCountEvent) {
        this.saeulen.size = n.itemsPerPage;
        this.updateZapfpunkte(true, n.currentPage);
    }


    showAddSaeule() {
        this.newSaeule = new Zapfsaeule();
        this.saeulenTable.showEditor();
    }

    showAddZapfpunkt() {
        if (this.currentSaeule != null) {
            this.newZapfpunkt = new Zapfpunkt();
            this.newZapfpunkt.zapfsaeule = this.currentSaeule;
            this.zapfpunkteTable.showEditor();
        }
    }

    editorAddOnClick(event: DataTableEditorAddOnClick) {
        if (event.dataTable === this.saeulenTable) {
            if (event.field === 'nummer') {
                if (event.addon === 0) {
                    this.stationenService
                        .findFreeSaeuleNum(this.station)
                        .subscribe((num: ActionResponse) => {
                                if (num.params.nummer !== undefined) {
                                    (event.component as NumCellEditorComponent).value = num.params.nummer;
                                }
                            },
                            () => {
                                this.toastr.error(this.t('Es konnte keine freie Säulennummer abgerufen werden'));
                            });
                }
            }
        }
        if (event.dataTable === this.zapfpunkteTable) {
            if (event.field === 'nummer') {
                if (event.addon === 0) {
                    this.stationenService
                        .findFreeZapfpunktNum(this.station, this.currentSaeule)
                        .subscribe((num: ActionResponse) => {
                                if (num.params.nummer !== undefined) {
                                    (event.component as NumCellEditorComponent).value = num.params.nummer;
                                }
                            },
                            () => {
                                this.toastr.error(this.t('Es konnte keine freie Zapfpunktnummer abgerufen werden'));
                            });
                }
            }
        }
    }

    saveRecord(event: DataTableSaveRecord) {
        if (event.dataTable === this.saeulenTable) {
            let ok = true;
            const r: IZapfsaeule = event.record;
            if ((r.nummer === undefined) || (r.nummer === null) || (r.nummer < 1)) {
                this.toastr.error(this._('Bitte geben Sie eine gültige Säulennummer an'));
                ok = false;
            }
            if ((r.bezeichnung === undefined) || (r.bezeichnung === null) || (r.bezeichnung === '')) {
                this.toastr.error(this._('Bitte geben Sie eine gültige Bezeichnung an'));
                ok = false;
            }

            if (ok) {
                this.stationenService
                    .storeZapfsaeule(this.station, event.record as Zapfsaeule)
                    .subscribe(
                        success => {
                            if (success.success) {
                                this.toastr.success(this._('Der Datensatz wurde erfolgreich gespeichert'));
                                event.dataTable.cancelNew();
                                this.updateSaeulen(true);
                            } else {
                                this.toastr.error(this._('Der Datensatz konnte nicht gespeichert werden.'));
                            }
                        },
                        () => {
                            this.toastr.error(this._('Der Datensatz konnte nicht gespeichert werden.'));
                        }
                    );
            }
        }
        if (event.dataTable === this.zapfpunkteTable) {
            let ok = true;
            const r: IZapfpunkt = event.record;
            if ((r.nummer === undefined) || (r.nummer === null) || (r.nummer < 0)) {
                this.toastr.error(this._('Bitte geben Sie eine gültige Zapfpunktnummer an'));
                ok = false;
            }
            if ((r.tank === undefined) || (r.tank === null)) {
                this.toastr.error(this._('Bitte geben wählen Sie einen Tank aus'));
                ok = false;
            }

            if (ok) {
                this.stationenService
                    .storeZapfpunkt(this.station, this.currentSaeule, event.record as Zapfpunkt)
                    .subscribe(
                        success => {
                            if (success.success) {
                                this.toastr.success(this._('Der Datensatz wurde erfolgreich gespeichert'));
                                event.dataTable.cancelNew();
                                this.updateZapfpunkte(true);
                            } else {
                                this.toastr.error(this._('Der Datensatz konnte nicht gespeichert werden.'));
                            }
                        },
                        () => {
                            this.toastr.error(this._('Der Datensatz konnte nicht gespeichert werden.'));
                        }
                    );
            }
        }
    }

    editEntity(event: RowActionParams<Zapfsaeule>) {
        event.row.displayEditor();
    }

    editCancelled(event: DataTableRowEditCancelled) {
        this.updateSaeulen(true);
        event.row.hideEditor();
    }

    removeSaeule(event: RowActionParams<Zapfsaeule>) {
        ConfirmDialogComponent.display(
            this.modalService,
            this.t('Zapfsäule löschen'),
            this.t('Sollen der Säule {{ title }} und alle dazugehörigen Daten ' +
                'wirklich unwideruflich gelöscht werden?', {title: (event.row.data as Zapfsaeule).bezeichnung}),
            this.t('Säule jetzt löschen')
        ).result
            .then(
                e => {
                    this.stationenService
                        .deleteZapfsaeule(this.station, event.row.data as Zapfsaeule)
                        .subscribe(
                            r => {
                                if (r.success) {
                                    this.toastr.success(this.t('Die Säule wurde gelöscht.'));
                                    this.updateSaeulen(true);
                                } else {
                                    this.toastr.error(this.t('Das Löschen des Säule ist fehlgeschlagen'));
                                    this.updateSaeulen(true);
                                }
                            },
                            () => {
                                this.toastr.error(this.t('Das Löschen des Säule ist fehlgeschlagen'));
                                this.updateSaeulen(true);
                            }
                        );
                },
                k => {
                }
            );

    }

    saeuleSelected(event: DataTableRowSelected<Zapfsaeule>) {
        if (event !== null) {
            this.currentSaeule = (event.row.data as Zapfsaeule);
            this.zapfpunkte.clear();
            if ((this.zapfpunkteTable !== undefined) && (this.zapfpunkteTable !== null)) {
                this.zapfpunkteTable.cancelNew();
            }
            this.updateZapfpunkte(true);
        }
    }


    removeZapfpunkt(event: RowActionParams<Zapfpunkt>) {
        ConfirmDialogComponent.display(
            this.modalService,
            this.t('Zapfpunkt löschen'),
            this.t('Sollen der Zapfpunkt {{ nummer }} und alle dazugehörigen Daten ' +
                'wirklich unwideruflich gelöscht werden?', {nummer: (event.row.data as Zapfpunkt).nummer}),
            this.t('Zapfpunkt jetzt löschen')
        ).result
            .then(
                e => {
                    this.stationenService
                        .deleteZapfpunkt(this.station, this.currentSaeule, event.row.data as Zapfpunkt)
                        .subscribe(
                            r => {
                                if (r.success) {
                                    this.toastr.success(this.t('Der Zapfpunkt wurde gelöscht.'));
                                    this.updateZapfpunkte(true);
                                } else {
                                    this.toastr.error(this.t('Das Löschen des Zapfpunkts ist fehlgeschlagen'));
                                    this.updateZapfpunkte(true);
                                }
                            },
                            () => {
                                this.toastr.error(this.t('Das Löschen des Zapfpunkts ist fehlgeschlagen'));
                                this.updateZapfpunkte(true);
                            }
                        );
                },
                k => {
                }
            );

    }


}
