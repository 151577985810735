import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import CryptoES from 'crypto-es';

import {CommonList} from '../../../common-list';
import {LocalizedComponent} from '../../../components/localized-component';
import {FilterCallback} from '../../../dynamic-form/FilterCallback';
import {MESelectItemFormatterCallback} from '../../../dynamic-form/me-select/meselect-item-formatter-callback';
import {MESelectSearchEvent} from '../../../dynamic-form/me-select/meselect-search-event';
import {
    MEHighlitePipe,
    MESelectComponent,
    MESelectItemMatcherCallback,
    MESelectItemTextCallback
} from '../../../dynamic-form/me-select/meselect.component';
import {GenericFilter} from '../../../generic-filter';
import {IListResult} from '../../../list-result';
import {SystemSettingsService} from '../../../system-settings.service';
import {ISprache} from '../../common/isprache';
import {SimpleSpracheFilter} from '../../common/simple-sprache-filter';
import {Sprache} from '../../common/sprache';
import {SpracheList} from '../../common/sprache-list';
import {SprachenService} from '../../common/sprachen.service';

@Component({
    selector: 'language-inline-field',
    template: `
        <meselect
                id="{{ id }}"
                [idField]="'id'"
                [items]="languages?.data"
                [asMatrix]="false"
                [placeholder]="label"
                (itemSelected)="doSelect($event)"
                (needsSearch)="doSearch($event)"
                [small]="small"
                [nullable]="nullable"
                [disabled]="disabled"
                [onFormat]="itemFormat"
                [onGetText]="itemText"
                [onMatch]="itemMatcher"
        >
        </meselect>
    `,
    styles: [``],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LanguageInlineFieldComponent extends LocalizedComponent implements OnInit, AfterViewInit {
    @Input() nullable = true;

    @ViewChild(MESelectComponent, {static: false}) languagesMESelect: MESelectComponent;
    @Input() small = false;

    languages: SpracheList = new SpracheList();
    @Input() label = 'LanguageFeld';
    @Input() id = '';
    @Input() maxDisplayNum = 10;
    @Input() disabled = false;
    @Output() valueChange = new EventEmitter<Sprache>();

    constructor(
        public systemsettingsService: SystemSettingsService,
        public sprachenService: SprachenService,
        public cd: ChangeDetectorRef) {
        super(cd);
    }

    _value: Sprache = null;

    get value(): Sprache {
        return this._value;
    }

    @Input()
    set value(v: Sprache) {

        if (this._value !== v) {
            this._value = v;
            if ((this.languagesMESelect !== null) && (this.languagesMESelect !== undefined)) {
                this.languagesMESelect.value = v;
            }
            this.valueChange.emit(v);
            this.cd.markForCheck();
        }
    }

    public search: FilterCallback<Sprache> = (filter: GenericFilter<Sprache>,
                                              list: CommonList<Sprache>,
                                              maxDisplayItems: number,
                                              queryString: string,
                                              cfg: any,
                                              selectComponent: MESelectComponent
    ) => {
        queryString = ('' + queryString).trim();
        this.sprachenService
            .list(maxDisplayItems, 0, [], filter as SimpleSpracheFilter, queryString)
            .subscribe(
                (c: IListResult<ISprache>) => {
                    list.populateFromListResult(c);
                    this.cd.markForCheck();
                    if (selectComponent !== null && selectComponent !== undefined) {
                        selectComponent.items = list.data;
                        selectComponent.cd.markForCheck();
                    }
                }
            );
    };

    ngOnInit() {
        this.init();
    }

    public ngAfterViewInit(): void {
        this.init();
    }

    init() {
        if (this.languagesMESelect !== null && this.languagesMESelect !== undefined) {
            this.search(null, this.languages, this.maxDisplayNum, '', null, this.languagesMESelect);

            this.languagesMESelect.onFormat = this.itemFormat;
            this.languagesMESelect.onGetText = this.itemText;
            this.languagesMESelect.onMatch = this.itemMatcher;
            this.languagesMESelect.value = this._value;
            if (this.id === '') {
                this.id = 'tf' + CryptoES.MD5('languagefield-' + this.label + Math.random() + '-' + Math.random()).toString();
            }
        }
    }

    doSearch(value: MESelectSearchEvent) {
        this.search(null, this.languages, this.maxDisplayNum, value.queryString, null, value.component);
    }

    doSelect(language: Sprache) {
        this._value = language;
        this.valueChange.emit(language);
        this.cd.markForCheck();
    }

    public itemFormat: MESelectItemFormatterCallback<Sprache> = (item: Sprache, search) => {
        if (item === null) {
            return '';
        }


        let i = '';

        if (item.bezeichnung !== null && item.bezeichnung !== '') {
            i = i + MEHighlitePipe.transformString(item.bezeichnung, search);
        }
        if (item.code !== null) {
            i = i + ' <span class=\'badge badge-default\'>' +
                MEHighlitePipe.transformString('' + item.code, search) +
                '</span>';
        }
        return i;
    };

    public itemText: MESelectItemTextCallback<Sprache> = (item: Sprache) => {
        if (item === null) {
            return '';
        }
        let i = '';
        if (item.bezeichnung !== null && item.bezeichnung !== '') {
            i = i + item.bezeichnung;
        }
        return i + ' [' + item.code + ']';
    };

    public itemMatcher: MESelectItemMatcherCallback<Sprache> = (item: Sprache, search) => {
        if (!search) {
            return true;
        }
        if (search.trim() === '') {
            return true;
        }
        if (!item) {
            return false;
        }
        const x = item.bezeichnung + ' ' + item.code;

        return (x.toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) >= 0);
    };
}
