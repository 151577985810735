import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    NgZone,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';

import {FilterCallback} from './FilterCallback';

import {
    MEHighlitePipe,
    MESelectComponent,
    MESelectItemMatcherCallback,
    MESelectItemTextCallback
} from './me-select/meselect.component';
import {MESelectItemFormatterCallback} from './me-select/meselect-item-formatter-callback';
import {Issuer} from '../issuers/common/issuer';
import {IssuerList} from '../issuers/common/issuer-list';
import {LocalizedComponent} from '../components/localized-component';
import {SystemSettingsService} from '../system-settings.service';
import {CommonList} from '../common-list';
import {SimpleIssuerFilter} from '../issuers/common/simple-issuer-filter';
import {GenericFilter} from '../generic-filter';
import {IListResult} from '../list-result';
import {IIssuer} from '../issuers/common/iissuer';
import {MESelectSearchEvent} from './me-select/meselect-search-event';
import CryptoES from "crypto-es";

@Component({
    selector: 'issuer-inline-field',
    template: `
                   <meselect
                           id="{{ id }}"
                           [idField]="'id'"
                           [items]="issuers?.data"
                           [asMatrix]="false"
                           [placeholder]="label"
                           (itemSelected)="doSelect($event)"
                           (needsSearch)="doSearch($event)"
                           [small]="small"
                           [nullable]="nullable"
                           [disabled]="disabled"
                   >
                   </meselect>
               `,
    styles: [``]
})
export class IssuerInlineFieldComponent extends LocalizedComponent implements OnInit, AfterViewInit {
    @Input() nullable = true;

    @ViewChild(MESelectComponent, {static: true}) issuersMESelect: MESelectComponent;
    @Input() small = false;

    issuers: IssuerList = new IssuerList();

    _value: Issuer = null;

    @Input() label = 'IssuerFeld';
    @Input() id = '';
    @Input() maxDisplayIssuers = 50;
    @Input() disabled = false;

    @Input()
    set value(v: Issuer) {

        if (this._value !== v) {
            this._value = v;
            if ((this.issuersMESelect !== null) && (this.issuersMESelect !== undefined)) {
                this.issuersMESelect.value = v;
            }
            this.valueChange.emit(v);
        }
    }

    @Output() valueChange = new EventEmitter<Issuer>();


    get value(): Issuer {
        return this._value;
    }

    constructor(
        public systemsettingsService: SystemSettingsService,
        public cd: ChangeDetectorRef,
        public _ngZone: NgZone) {
        super(cd);
    }

    public search: FilterCallback<Issuer> = (filter: GenericFilter<Issuer>,
                                             list: CommonList<Issuer>,
                                             maxDisplayItems: number,
                                             queryString: string,
                                             cfg: any,
                                             selectComponent: MESelectComponent
    ) => {
        queryString = ('' + queryString).trim();
        let q = '';
        if (queryString !== '') {
            if (isNaN(parseInt(queryString, 10))) {
                q = '(*' + queryString + '*) OR' +
                    '(' + queryString + ')';
            } else {
                q = '(nummer:' + parseInt(queryString, 10) + '^10) OR ' +
                    '(nummer:' + parseInt(queryString, 10) + '*^5) OR ' +
                    '(*' + queryString + '*) OR' +
                    '(' + queryString + ')';
            }
        }
        this.systemsettingsService
            .listIssuers(maxDisplayItems, 0, [], filter as SimpleIssuerFilter, q)
            .subscribe(
                (c: IListResult<IIssuer>) => {
                    list.populateFromListResult(c);
                    this.cd.markForCheck();
                    if (selectComponent !== null && selectComponent !== undefined) {
                        selectComponent.items = list.data;
                        selectComponent.cd.markForCheck();
                    }
                }
            );
    }

    ngOnInit() {

        if (this.id === '') {
            this.id = 'tf' + CryptoES.MD5('issuerfield-' + this.label + Math.random() + '-' + Math.random()).toString();
        }
        this.doSearch(new MESelectSearchEvent("", this.issuersMESelect));
    }

    ngAfterViewInit(): void {
        this.issuersMESelect.onFormat = this.itemFormat;
        this.issuersMESelect.onGetText = this.itemText;
        this.issuersMESelect.onMatch = this.itemMatcher;
        this.issuersMESelect.value = this._value;
    }

    doSearch(value: MESelectSearchEvent) {
        this.search(null, this.issuers, this.maxDisplayIssuers, value.queryString, null, value.component);
    }

    doSelect(issuer: Issuer) {
        this._value = issuer;
        this.valueChange.emit(issuer);
        this.cd.markForCheck();
    }

    public itemFormat: MESelectItemFormatterCallback<Issuer> = (item: Issuer, search, forList: boolean) => {
        if (item === null) {
            return '';
        }


        let i = '';

        if (item.bezeichnung !== null && item.bezeichnung !== '') {
            i = i + MEHighlitePipe.transformString(item.bezeichnung, search);
        }
        if (item.nummer !== null) {
            i = i + ' <span class=\'badge badge-default\'>' +
                MEHighlitePipe.transformString('' + item.nummer, search) +
                '</span>';
        }
        return i;
    }

    public itemText: MESelectItemTextCallback<Issuer> = (item: Issuer) => {
        if (item === null) {
            return '';
        }
        let i = '';
        if (item.bezeichnung !== null && item.bezeichnung !== '') {
            i = i + item.bezeichnung;
        }
        return i + ' [' + item.nummer + ']';
    }

    public itemMatcher: MESelectItemMatcherCallback<Issuer> = (item: Issuer, search) => {
        if (!search) {
            return true;
        }
        if (search.trim() === '') {
            return true;
        }
        if (!item) {
            return false;
        }
        const x = item.bezeichnung + ' ' + item.nummer;

        return (x.toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) >= 0);
    }


}
