import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {LoginService} from '../../../auth/services/login.service';
import {SimpleTankBestandFilter} from '../common/simple-tank-bestand-filter';
import {ITankBestand} from '../common/itank-bestand';
import {IListResult} from '../../../commons/list-result';
import {Observable,map,take} from 'rxjs';
import {ResultMetaSortImpl} from '../../../commons/result-meta';
import {IStation} from '../../../stationen/common/istation';
import {ITank} from '../../../stationen/tanks/common/itank';
import {Station} from '../../../stationen/common/station';
import {Tank} from '../../../stationen/tanks/common/tank';
import {IActionResponse} from '../../../commons/action-response';
import * as moment from 'moment';

@Injectable({
                providedIn: 'root'
            })
export class TankBestandService {

    constructor(
        public http: HttpClient,
        public loginService: LoginService
    ) {
    }

    list(
        max = 0,
        offset = 0,
        sort: ResultMetaSortImpl[] = [],
        filter: SimpleTankBestandFilter = null,
        searchString = '',
        byTank: ITank = null,
        byStation: IStation = null
    ): Observable<IListResult<ITankBestand>> {
        let url = '/api/bestand';
        if (byTank !== undefined && byTank !== null && byTank.id > 0) {
            url = '/api/tanks/' + encodeURIComponent('' + byTank.id) + '/bestand';
            if (byStation !== undefined && byStation !== null && byStation.id > 0) {
                url = '/api/stationen/' +
                    encodeURIComponent('' + byStation.id) +
                    '/tanks/' + encodeURIComponent('' + byTank.id) + '/bestand';
            }
        } else if (byStation !== undefined && byStation !== null && byStation.id > 0) {
            url = '/api/stationen/' +
                encodeURIComponent('' + byStation.id) +
                '/bestand';
        }

        url = url.concat('?')
            .concat('max=' + encodeURIComponent('' + max))
            .concat('&')
            .concat('offset=' + encodeURIComponent('' + offset));

        if (filter !== null) {
            if (filter.hasValues()) {
                url = url + '&' + filter.asURL();
            }
        }

        if (searchString !== null && searchString !== undefined && ('' + searchString).trim() !== '') {
            url = url + '&q=' + encodeURIComponent(('' + searchString).trim());
        }
        if (sort !== undefined && sort !== null && sort.length > 0) {
            const p = 0;
            sort.forEach(it => {
                url = url.concat('&sort.' + p + '=' + encodeURIComponent(it.field + '|' + it.direction));
            });
        }


        return this.http
            .get<IListResult<ITankBestand>>(
                url,
                {
                    headers: this.loginService.getHttpHeaders()
                }
            );
    }


    recalc(station: Station, tank: Tank, n: number= null): Observable<IActionResponse> {
        let url = '/api/bestand';
        if (tank !== undefined && tank !== null && tank.id > 0) {
            url = '/api/tanks/' + encodeURIComponent('' + tank.id) + '/bestand';
            if (station !== undefined && station !== null && station.id > 0) {
                url = '/api/stationen/' +
                    encodeURIComponent('' + station.id) +
                    '/tanks/' + encodeURIComponent('' + tank.id) + '/bestand';
            }
        } else if (station !== undefined && station !== null && station.id > 0) {
            url = '/api/stationen/' +
                encodeURIComponent('' + station.id) +
                '/bestand';
        }

        url = url + '/_recalc';

        if (n !== null && n !== undefined) {
            url = url + '?notBefore=' + encodeURIComponent(moment().add(-1 * n, 'weeks').toISOString());
        }

        return this.http
            .post<IActionResponse>(
                url,
                {},
                {
                    headers: this.loginService.getHttpHeaders()
                }
            );
    }

    getBestandPerMomentAndTank(tank: ITank, zeitpunkt: Date): Observable<IActionResponse> {
        let url = '/api/tanks/' + encodeURIComponent('' + tank.id) + '/_bestand';
        url = url + '?zp=' + encodeURIComponent(moment(zeitpunkt).toISOString());
        return this.http
            .get<IActionResponse>(
                url,
                {
                    headers: this.loginService.getHttpHeaders()
                }
            );

    }

    exportData(type = 'csv', filter: SimpleTankBestandFilter = null, searchQuery = '', exporttype = 0): Observable<Blob> {
        let url = '/api/bestand.' + encodeURIComponent('' + type);

        url = url.concat('?max=-1')
            .concat('&offset=0')
            .concat('&sort.0=' + encodeURIComponent('datum|asc'));
        url = url.concat('&exporttype=' + encodeURIComponent('' + exporttype));


        if (filter !== null) {
            if (filter.hasValues()) {
                url = url + '&' + filter.asURL();
            }
        }
        if (searchQuery !== null && searchQuery !== undefined && searchQuery !== '') {
            url = url.concat('&q=' + encodeURIComponent(searchQuery));
        }

        return this.http
            .get(
                url,
                {
                    headers: this.loginService.getHttpHeaders(false),
                    responseType: 'blob'
                }
            );
    }

    getRecalcsPending():Observable<number> {

        const uri = '/api/bestand/_recalcCount';

        return this.http
            .get<IActionResponse>(
                uri,
                {
                    headers: this.loginService.getHttpHeaders()
                }
            ).pipe(
                map((r)=>{
                    if (r.success) {
                        return parseInt(''+r.params['count'],10);
                    }
                    return 0
                }),

                take(1)
            );
    }
}
