
<data-table
        [selectable]="false"
        [data]="einheiten.asDataTableRows()"
        [orders]="einheiten.order"
        [filter]="einheiten.simpleFilter"
        (sortChanged)="sortChanged($event)"
        (filterChanged)="filterChanged($event)"
>
  <column [field]="'id'" [label]="t('Nummer')" [type]="'integer'" [filterable]="false"></column>
  <column [field]="'bezeichnung'" [label]="t('Bezeichnung')"  [sortable]="true" [filterable]="false"></column>
  <column [field]="'symbol'" [label]="t('Symbol')" [filterable]="false"></column>
  <tableaction
          [label]="t('Einheit anlegen')"
          [icon]="'icofont icofont-ui-add'"
          (perform)="createEinheit()"
  ></tableaction>
  <rowaction
          [label]="_('Einheit bearbeiten')"
          [icon]="'icofont icofont-ui-edit'"
          (perform)="editEinheit($event)"
          (checkEnabled)="checkEnabled('einheit',$event)"
  ></rowaction>

</data-table>

<div class="row">
  <div class="col-6"></div>
  <div class="col-6 text-right">
    <app-paginator
            [total]="einheiten.total"
            [page]="einheiten.page"
            [perpage]="einheiten.size"
            (pageChange)="updateEinheiten(true,$event)"
            (pageCountChange)="updateEinheitenPageCount($event)"
    ></app-paginator>
  </div>
</div>

