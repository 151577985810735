import {GenericFilter} from '../../commons/generic-filter';
import {Sonde} from './sonde';
import {ConverterTool} from '../../../shared/converter-tool';


export class SimpleSondeFilter extends GenericFilter<Sonde> {
    fields = {
        'aktiveOnly': function (v) {
            return ConverterTool.toBoolean(v);
        },
        'warnOnly': function (v) {
            return ConverterTool.toBoolean(v);
        }
    };

   aktiveOnly: boolean = null;
    warnOnly: boolean = null;


    clear(): void {
        super.clear();
        this.aktiveOnly = null;
        this.warnOnly = null;
    }

    asURL(): string {
       const u = [];
        if (this.aktiveOnly !== null && this.aktiveOnly !== undefined) {
        u.push('filter.aktiveOnly=' + encodeURIComponent('' + this.aktiveOnly));
       }
        if (this.warnOnly !== null && this.warnOnly !== undefined) {
            u.push('filter.warnOnly=' + encodeURIComponent('' + this.warnOnly));
        }
       return u.join('&');

    }

    hasValues(): boolean {
        if (this.aktiveOnly !== null && this.aktiveOnly !== undefined) {
            return true;
        }
        if (this.warnOnly !== null && this.warnOnly !== undefined && this.warnOnly) {
            return true;
        }
        return super.hasValues();
    }
}
