import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {ProduktCellRendererComponent} from '../../../products/produkt-cell-renderer.component';
import {PreisWertCellRendererComponent} from '../../../commons/data-table/renderer/preis-wert-cell-renderer.component';
import {NgbActiveModal, NgbModal, NgbModalOptions, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {PersonService} from '../../../customers/personen/common/person.service';
import {FahrzeugService} from '../../../customers/fahrzeuge/common/fahrzeug.service';
import {ExtendedZapfpunktRendererComponent} from '../../../stationen/extended-zapfpunkt-renderer.component';
import {MengeCellRendererComponent} from '../../../products/menge-cell-renderer.component';

import {Waehrung} from '../../../commons/waehrungen/waehrung';
import {TankCellRendererComponent} from '../../../stationen/tank-cell-renderer.component';
import {LocalizedComponent} from '../../../commons/components/localized-component';
import {Nachbuchung} from '../common/nachbuchung';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-nachbuchung-details',
  templateUrl: './nachbuchung-details.component.html',
  styleUrls: ['./nachbuchung-details.component.scss']
})
export class NachbuchungDetailsComponent extends LocalizedComponent implements OnInit {

    produktR = ProduktCellRendererComponent;
    tankR = TankCellRendererComponent;
    zapfpunktR = ExtendedZapfpunktRendererComponent;
    preiswert = PreisWertCellRendererComponent;
    mengeR = MengeCellRendererComponent;

    showBrutto = false;
    entity: Nachbuchung = new Nachbuchung();
    mode: any = 'new';

    static open(modalService: NgbModal, nachbuchung: Nachbuchung): Promise<any> {
        const o: NgbModalOptions = {
            size    : 'lg',
            backdrop: 'static'
        };
        const r: NgbModalRef = modalService.open(NachbuchungDetailsComponent, o);
        (r.componentInstance as NachbuchungDetailsComponent).open(nachbuchung);
        return r.result;
    }


    constructor(
                
                public activeModal: NgbActiveModal,
                public toastr: ToastrService,
                public fahrzeugService: FahrzeugService,
                public personService: PersonService,
                public cd: ChangeDetectorRef) {
        super( cd);
    }


    open(nachbuchung?: Nachbuchung) {
        if ((nachbuchung === null) || (nachbuchung === undefined)) {
            this.entity = new Nachbuchung();
        } else {
            this.entity = Nachbuchung.fromResult(nachbuchung);
        }
        if (this.entity.waehrung === undefined || this.entity.waehrung === null) {
            this.entity.waehrung = new Waehrung();
            this.entity.waehrung.code = 'EUR';
        }
    }

    ngOnInit() {

    }

    cancel() {
        this.activeModal.dismiss();
    }

}
