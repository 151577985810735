import {Sonde} from './sonde';
import {SimpleSondeFilter} from './simple-sonde-filter';
import {IListResult} from '../../commons/list-result';
import {ISonde} from './isonde';
import {RemoteList} from '../../commons/remote-list';


export class SondeList extends RemoteList<Sonde>{
    public simpleFilter: SimpleSondeFilter = new SimpleSondeFilter();

    constructor(autorun = false, timeout = 400) {
        super(autorun, timeout);
    }

    static fromListResult(res: IListResult<ISonde>): SondeList {
        const newList = new SondeList();

        newList.populateFromListResult(res, true);

        return newList;
    }

    public convertEntity(entity: any): any {
        return Sonde.fromResult(entity);
    }

    public populateFromListResult(res: IListResult<ISonde>, clearFilter = true): void {
        this.doPopulateFromListResult(res, 'nummer', 'asc', clearFilter);
    }
}
