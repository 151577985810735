import {ErrorHandler, Injectable, Injector} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {DeprecatedException} from './deprecated-exception';
import {ToastrService} from 'ngx-toastr';

@Injectable()
export class MEErrorHandler extends ErrorHandler {

    errorShown = {
        locale: false
    };

    constructor(private injector: Injector) {
        super();
    }

    public handleError(error: any): void {
        let toastr = <ToastrService>this.injector.get(ToastrService);

        if (toastr !== null && toastr !== undefined) {
            let message = null;
            if (error instanceof HttpResponse) {

                if ((<HttpResponse<any>>error).status === 504) {
                    message = 'Daten konnten nicht abgerufen werden. ' +
                        'Ein Backendserver ist momentan nicht erreichbar oder steht nicht zur Verfügung.';
                    if ((<HttpResponse<any>>error).url.indexOf('locales.json') > 0) {
                        message = null;
                        if (!this.errorShown.locale) {
                            this.errorShown.locale = true;
                            message = 'Die Sprachdateien konnten nicht geladen werden. ' +
                                'Es steht somit nur Deutsch als Sprache zur Verfügung.'
                        }
                    }
                }
            } else { // noinspection SuspiciousInstanceOfGuard
                if (error instanceof DeprecatedException) {
                message = (error as DeprecatedException).message
            } else if (error instanceof Error) {
                message = null;
            } else {
                message = '' + error;
            }
            }

            if ((message !== null) &&
                (message !== undefined) &&
                message.match(/.*(Cannot read property|TypeError|ExpressionChangedAfterItHasBeenCheckedError|object Object).*/i)) {
                message = null;
            }

            if ((message !== null) && (message !== undefined)) {
                setTimeout(() => {
                    toastr.error(message, 'Interner Fehler', {disableTimeOut: true});
                });
            }
        }

        super.handleError(error);
    }

}
