import {GenericFilter} from '../../commons/generic-filter';
import {DateTime} from 'luxon';
import {ConverterTool} from '../../../shared/converter-tool';
import {SondenBuchung} from './sonden-buchung';

export class SimpleSondenBuchungFilter extends GenericFilter<SondenBuchung> {

    fields = {
        'timeFrom': function (v) {
            return ConverterTool.toDate(v);
        }, 'timeUntil': ConverterTool.toDate
    };


    timeFrom: Date = null;
    timeUntil: Date = null;


    asURL(): string {
        let url = '';

        if (this.timeFrom !== null && this.timeFrom !== undefined) {
            url += (url !== '') ? '&' : '';
            url += 'filter.timeFrom=';
            url += encodeURIComponent(DateTime.fromJSDate(this.timeFrom).toISO());
        }

        if (this.timeUntil !== null && this.timeUntil !== undefined) {
            url += (url !== '') ? '&' : '';
            url += 'filter.timeUntil=';
            url += encodeURIComponent(DateTime.fromJSDate(this.timeUntil).toISO());
        }

        return url;
    }


    hasValues(): boolean {
        if (this.timeFrom !== null && this.timeFrom !== undefined) {
            return true;
        }
        if (this.timeUntil !== null && this.timeUntil !== undefined) {
            return true;
        }
        return super.hasValues();
    }
}
