import {Component, Injector, OnInit} from '@angular/core';
import {DataTableRow} from '../../data-table/data-table-row';
import {Waehrung} from '../waehrung';
import {CellEditorChangedCallback, IEditorComponent} from '../../data-table/editors/cell-editor.component';
import {DataTableColumnDirective} from '../../data-table/data-table-column.directive';

@Component({
    selector: 'app-waehrung-cell-editor',
    styles: [`
        :host ::ng-deep produkt-field .form-group {
            margin-bottom: 0;
        }
    `],
    template: `
        <currency-field
                [label]="column.label"
                [labelwidth]="0"
                [value]="value"
                (valueChange)="valueChange($event)"
                [small]="true"
        ></currency-field>
    `
})
export class WaehrungCellEditorComponent implements IEditorComponent, OnInit {

    row: DataTableRow<any>;

    public dataType = 'Waehrung';

    value: Waehrung = null;
    column: DataTableColumnDirective = null;

    editorChanged: CellEditorChangedCallback<Waehrung> = (field2, val, c) => {
    }

    constructor(public injector: Injector) {
        this.value = this.injector.get('value');
        this.column = this.injector.get('column');
        this.row = this.injector.get('row');
        this.editorChanged = this.injector.get('onChange');
    }

    ngOnInit() {
    }

    valueChange(event: any) {
        this.value = event;
        this.editorChanged(this.column.field, event, this);
    }

}
