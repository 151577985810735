export enum EAusweisArt {
    UNKNOWN = 0,
    MAGSTRIPE = 1,
    SMARTCARD = 2,
    CHIPTRANSPONDER = 3,
    BTTRANSPONDER = 4,
    PHONE = 5,
    HEAVY_TAG = 6,
    VIRTUAL = 7
}
