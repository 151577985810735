import {Component, Injector} from '@angular/core';
import {CommonCellRenderer} from '../../../commons/data-table/renderer/common-cell-renderer';
import {DataTableRow} from '../../../commons/data-table/data-table-row';
import {DataTableColumnDirective} from '../../../commons/data-table/data-table-column.directive';

@Component({
               selector: 'app-pan-cell-renderer',
               template: `
                   {{ value }}
               `
           })
export class PanCellRendererComponent extends CommonCellRenderer {
    cell: any = null;
    row: DataTableRow<any> = null;
    column: DataTableColumnDirective = null;

    value = '';

    constructor(public injector: Injector) {
        super();
        this.cell = this.injector.get('cell');
        this.row = this.injector.get('row');
        this.column = this.injector.get('column');

        if (this.cell !== null && this.cell !== undefined) {

            const pan = ('' + this.cell).trim().toUpperCase();

            if (pan.match(/.*B([^D]+)D.*/i)) {
                this.value = pan.replace(/.*B([^D]+)D.*/i, '$1')
            } else if (pan.indexOf('D') > -1) {
                this.value = pan.substr(0, pan.indexOf('D'));
            } else {
                this.value = pan;
            }

        }
    }


}
