import {Terminal} from '../../../stationen/terminals/common/terminal';
import {ITerminal} from '../../../stationen/terminals/common/iterminal';
import {IStationRewrite} from './istation-rewrite';

export class StationRewrite implements IStationRewrite {
    source = '';
    destination: ITerminal = null;

    static fromResult(entity: IStationRewrite): StationRewrite {
        if ((entity === null) || (entity === undefined)) {
            return null;
        }

        let l: StationRewrite = new StationRewrite();
        for (let k of Object.keys(l)) {
            if (k === 'destination') {
                l.destination = Terminal.fromResult(entity.destination);
            } else {
                l[k] = (entity[k] !== undefined) ? entity[k] : '';
            }
        }

        return l;
    }

    constructor() {
    }


}
