import {ChangeDetectorRef, Component, Injector, OnInit} from '@angular/core';
import {DataTableColumnDirective} from '../../../commons/data-table/data-table-column.directive';
import {Transaktion} from '../common/transaktion';

import {DataTableRow} from '../../../commons/data-table/data-table-row';
import {ITransaktionDetail} from '../common/itransaktion-detail';
import {TransaktionDetail} from '../common/transaktion-detail';
import {LocalizedCellRenderer} from '../../../commons/data-table/renderer/common-cell-renderer';
import {Nachbuchung} from '../common/nachbuchung';
import {INachbuchungDetail} from '../common/inachbuchung-detail';
import {NachbuchungDetail} from '../common/nachbuchung-detail';

@Component({
  selector: 'app-nachbuchung-details-renderer',
    template: `
        <div style="width: 98%;">
            <div *ngIf="cell.length>1">{{_('Diese Nacherfassung umfasst mehrere Positionen') }}</div>
            <div *ngIf="cell.length<1">{{_('Diese Nacherfassung umfasst keine Positionen') }}</div>
            <div *ngIf="cell.length===1" class="row">
                <div class="col-8">
                    {{ produkt }}
                </div>
                <div class="col-4 text-right">
                    {{ fNum( menge ,digitInfo) }} {{ addon }}
                </div>

            </div>
        </div>
    `
})
export class NachbuchungDetailsRendererComponent extends LocalizedCellRenderer {

    cell: INachbuchungDetail[] = [];
    row: DataTableRow<Nachbuchung> = null;
    column: DataTableColumnDirective = null;

    menge = 0;
    digitInfo = '1.3-3';
    addon = 'L';
    produkt = '';


    constructor(  public injector: Injector,public cd:ChangeDetectorRef) {
        super(cd);
        this.cell = this.injector.get('cell');
        this.row = this.injector.get('row');
        this.column = this.injector.get('column');

        this.menge = 0;
        this.addon = '';

        if (this.cell !== null && this.cell !== undefined && Array.isArray(this.cell) && this.cell.length === 1) {
            const dt = NachbuchungDetail.fromResult(this.cell[0]);
            this.menge = dt.menge;
            if (dt.produkt !== null && dt.produkt !== undefined) {
                this.produkt = dt.produkt.bezeichnung;
                if (dt.produkt.einheit !== null && dt.produkt.einheit !== undefined) {
                    this.addon = dt.produkt.einheit.symbol
                }
            }
        }

    }


}
