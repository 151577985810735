import {ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

import {LocalizedComponent} from '../../../../commons/components/localized-component';
import {SondenBuchungenFilter} from './sonden-buchungen-filter';

@Component({
    selector: 'app-sonden-buchung-filter',
    templateUrl: './sonden-buchung-filter.component.html',
    styleUrls: ['./sonden-buchung-filter.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SondenBuchungFilterComponent extends LocalizedComponent implements OnInit {

    filter: SondenBuchungenFilter = new SondenBuchungenFilter();

    @Output()
    filterChanged: EventEmitter<SondenBuchungenFilter> = new EventEmitter<SondenBuchungenFilter>();

    @Input()
    filterCollapsed = false;


    constructor(
                
                public cd: ChangeDetectorRef) {
        super( cd);

        this.filter = new SondenBuchungenFilter();

        this.filter.filterChanged.subscribe(() => {
            this.notify()
        });

    }

    ngOnInit() {
        this.filter.today();
    }

    isValid() {
        return this.filter !== null && this.filter !== undefined;
    }

    toggleFilter() {
        this.filterCollapsed = !this.filterCollapsed;
        this.cd.markForCheck();
    }

    notify() {
        this.filterChanged.emit(this.filter);

    }
}
