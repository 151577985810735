import {ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DeviceService} from './common/device.service';
import {ProductsModule} from '../products/products.module';
import {AuthModule} from '../auth/auth.module';
import {CommonsModule} from '../commons/commons.module';
import {ToastrModule} from 'ngx-toastr';
import {FormsModule} from '@angular/forms';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {l10nConfig} from '../../l10nconfig';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {DeviceStaubsaugerDetailsEditorComponent} from './device-staubsauger-details-editor/device-staubsauger-details-editor.component';
import {DeviceStaubsaugerDetailsComponent} from './device-staubsauger-details/device-staubsauger-details.component';
import {GeraetInlineFieldComponent} from './geraet-inline-field/geraet-inline-field.component';
import {GeraetFieldComponent} from './geraet-field/geraet-field.component';
import {DeviceLoraGatewayDetailsEditorComponent} from './device-lora-gateway-details-editor/device-lora-gateway-details-editor.component';
import {DeviceVideoControlDetailsEditorComponent} from './device-video-control-details-editor/device-video-control-details-editor.component';
import {DeviceCoinAcceptorUnitDetailsEditorComponent} from './device-coin-acceptor-unit-details-editor/device-coin-acceptor-unit-details-editor.component';
import {DeviceCoinAcceptorDetailsComponent} from './device-coin-acceptor-unit-details/device-coin-acceptor-details.component';
import {DeviceLoraGatewayDetailsComponent} from './device-lora-gateway-details/device-lora-gateway-details.component';
import {DeviceVideoControlDetailsComponent} from './device-video-control-details/device-video-control-details.component';
import {DTDeviceSettingsRendererComponent} from './dtdevice-settings-renderer/dtdevice-settings-renderer.component';
import {DeviceTypeQuickInfoComponent} from './device-type-quick-info/device-type-quick-info.component';
import {DTDeviceTypeRendererComponent} from './dtdevice-type-renderer/dtdevice-type-renderer.component';
import {GeraeteDialogComponent} from './geraete-dialog/geraete-dialog.component';
import {StationenModule} from '../stationen/stationen.module';
import {CustomersModule} from '../customers/customers.module';
import { DTDeviceSystemStateRendererComponent } from './dtdevice-system-state-renderer/dtdevice-system-state-renderer.component';


const services = [
    DeviceService
];

const components = [
    GeraeteDialogComponent,
    DTDeviceTypeRendererComponent,
    DeviceTypeQuickInfoComponent,
    DTDeviceSettingsRendererComponent,
    DeviceVideoControlDetailsComponent,
    DeviceLoraGatewayDetailsComponent,
    DeviceCoinAcceptorDetailsComponent,
    DeviceCoinAcceptorUnitDetailsEditorComponent,
    DeviceVideoControlDetailsEditorComponent,
    DeviceLoraGatewayDetailsEditorComponent,
    GeraetFieldComponent,
    GeraetInlineFieldComponent,
    DeviceStaubsaugerDetailsComponent,
    DeviceStaubsaugerDetailsEditorComponent,
    DTDeviceSystemStateRendererComponent
];


@NgModule({
    imports: [
        CommonModule,
        BrowserAnimationsModule,
        NgbModule,
        FormsModule,
        ToastrModule.forRoot(),
        CommonsModule,
        AuthModule,
        ProductsModule,
        StationenModule,
        CustomersModule
    ],
    declarations: [
        ...components
    ],
    exports: [
        ...components
    ],
    entryComponents: [
        ...components

    ]
})
export class DevicesModule {

}
