import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'app-area-heading',
    templateUrl: './area-heading.component.html',
    styleUrls: ['./area-heading.component.css']
})
export class AreaHeadingComponent implements OnInit {

    @Input() icon = '';
    @Input() title = '';
    @Input() subtitle = '';

     iconClass = '';


    constructor() {
    }

    ngOnInit() {
        if (this.icon.startsWith('icofont-')) {
            this.iconClass = 'icofont ' + this.icon;
        } else {
            this.iconClass = this.icon;
        }
    }

    hasSubtitle(): boolean {
        if (this.subtitle === undefined) {
            return false;
        }
        if (this.subtitle === null) {
            return false;
        }
        return this.subtitle !== '';
    }

}
