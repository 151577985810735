import {ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {LocalizedComponent} from '../../commons/components/localized-component';
import {Station} from '../common/station';
import {SondeList} from './sonde-list';
import {Sonde} from './sonde';

import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {StationenService} from '../common/stationen.service';

import {NewPageCountEvent} from '../../commons/paginator/paginator.component';
import {ConfirmDialogComponent} from '../../commons/confirm-dialog/confirm-dialog.component';
import {DataTableComponent, DataTableEditorAddOnClick} from '../../commons/data-table/data-table.component';
import {NumCellEditorComponent} from '../../commons/data-table/editors/num-cell-editor.component';
import {KitchenTimer} from '../../commons/kitchen-timer';
import {ActionResponse} from '../../commons/action-response';
import {LiterCellRendererComponent} from '../../commons/components/liter-cell-renderer.component';
import {TankCellRendererComponent} from '../tank-cell-renderer.component';
import {TankCellEditorComponent} from '../tank-cell-editor.component';
import {IListResult} from '../../commons/list-result';
import {ISonde} from './isonde';
import {ToastrService} from 'ngx-toastr';
import {SondenBuchungenDialogComponent} from './sonden-buchungen-dialog/sonden-buchungen-dialog.component';
import {noop} from '../../commons/noop';
import {SondeEditorComponent} from './sonde-editor/sonde-editor.component';
import {RowActionParams} from '../../commons/data-table/row-action-params';

@Component({
    selector: 'app-sonden-modal',
    templateUrl: './sonden-modal.component.html',
    styleUrls: ['./sonden-modal.component.scss']
})
export class SondenModalComponent extends LocalizedComponent implements OnInit {

    literR = LiterCellRendererComponent;
    literE = NumCellEditorComponent;
    tankR = TankCellRendererComponent;
    tankE = TankCellEditorComponent;

    @ViewChild('sondenTable',{static: true}) sondenTable: DataTableComponent;
    station: Station = new Station();
    sonden: SondeList = new SondeList();
    public sondenTimer: KitchenTimer;

    newSonde: Sonde = null;

    showSondeEditor = false;


    constructor(
                
                public activeModal: NgbActiveModal,
                public stationenService: StationenService,
                public toastr: ToastrService,
                public cd: ChangeDetectorRef,
                public modalService: NgbModal) {
        super( cd);
        this.sondenTimer = new KitchenTimer();
        this.sondenTimer.subscribe(
            () => {
                this.stationenService
                    .listSonden(
                        this.station,
                        this.sonden.size,
                        this.sonden.calcOffset(),
                        this.sonden.order,
                        this.sonden.simpleFilter,
                        this.sonden.searchString
                    )
                    .subscribe(
                        (l: IListResult<ISonde>) => {
                            this.sonden.populateFromListResult(l);
                        },
                        (e) => {
                            this.toastr.error(this._('Die Liste der Sonden konnte nicht geladen werden.'));
                            this.sonden.clear();
                        }
                    );
            }
        );

    }

    get msg_title() {
        return this._('Sonden der Station {{ name }} [{{ nummer }}]', {name: this.station.bezeichnung, 'nummer': this.station.nummer});
    }

    ngOnInit() {
        this.updateSonden();
    }

    cancel() {
        this.activeModal.dismiss('cancel');
    }


    edit(e: Station) {
        this.station = e;
    }


    updateSonden(force = false, page?: number) {
        if ((page !== undefined) && (page != null)) {
            if (page !== this.sonden.page) {
                this.sonden.page = page;
                force = true;
            }
        }
        this.sondenTimer.start(force);
    }

    /**
     * ändert die Anzahl der Datensätze/Seite und lädt die Liste neu
     * @param n
     */
    updateSondenPageCount(n: NewPageCountEvent) {
        this.sonden.size = n.itemsPerPage;
        this.updateSonden(true, n.currentPage);
    }


    showAddSonde() {
        SondeEditorComponent.open(this.modalService, this.station, null).then(() => {
            this.updateSonden();
        });
    }

    editorAddOnClick(event: DataTableEditorAddOnClick) {
        if (event.field === 'nummer') {
            if (event.addon === 0) {

                this.stationenService
                    .findFreeSondeNum(this.station)
                    .subscribe((num: ActionResponse) => {
                            if (num.params.nummer !== undefined) {
                                (event.component as NumCellEditorComponent).value = num.params.nummer;
                            }
                        },
                        () => {
                            this.toastr.error(this.t('Es konnte keine freie Sondennummer abgerufen werden'));
                        });
            }
        } else if (event.field === 'volumen') {
            if ((event.component.row !== undefined) && (event.component.row !== null)) {
                if (((event.component.row.data as Sonde).tank !== undefined) &&
                    ((event.component.row.data as Sonde).tank !== null)) {
                    (event.component as NumCellEditorComponent).value
                        = (event.component.row.data as Sonde).tank.volumen;
                }
            } else {
                if ((event.dataTable._newEntity !== null) && (event.dataTable._newEntity !== undefined)) {
                    if (((event.dataTable._newEntity as Sonde).tank !== null) &&
                        ((event.dataTable._newEntity as Sonde).tank !== undefined)) {
                        (event.component as NumCellEditorComponent).value
                            = (event.dataTable._newEntity as Sonde).tank.volumen;
                    }
                }
            }
        } else if (event.field === 'warnmenge') {
            if ((event.component.row !== undefined) && (event.component.row !== null)) {
                if (((event.component.row.data as Sonde).volumen !== undefined) &&
                    ((event.component.row.data as Sonde).volumen !== null)) {
                    (event.component as NumCellEditorComponent).value
                        = Math.floor((event.component.row.data as Sonde).volumen / 10);
                }
            } else {
                if ((event.dataTable._newEntity !== null) && (event.dataTable._newEntity !== undefined)) {
                    if (((event.dataTable._newEntity as Sonde).volumen !== null) &&
                        ((event.dataTable._newEntity as Sonde).volumen !== undefined)) {
                        (event.component as NumCellEditorComponent).value
                            = Math.floor((event.dataTable._newEntity as Sonde).volumen / 10);
                    }
                }
            }
        }
    }

    editSonde(event: RowActionParams<Sonde>) {

        this.stationenService.loadSonde(this.station, event.row.data).subscribe(
            (s: ISonde) => {
                const sonde = Sonde.fromResult(s);
                SondeEditorComponent.open(this.modalService, sonde.station, sonde).then(() => {
            this.updateSonden();
        });
            },
            noop
        );

    }

    removeSonde(event: RowActionParams<Sonde>) {
        ConfirmDialogComponent.display(
            this.modalService,
            this.t('Sonde löschen'),
            this.t('Sollen die Sonde {{ title }} und alle dazugehörigen Daten ' +
                'wirklich unwideruflich gelöscht werden?', {title: (event.row.data as Sonde).bezeichnung}),
            this.t('Sonde jetzt löschen')
        ).result
            .then(
                e => {
                    this.stationenService
                        .deleteSonde(this.station, event.row.data as Sonde)
                        .subscribe(
                            r => {
                                if (r.success) {
                                    this.toastr.success(this.t('Die Sonde wurde gelöscht.'));
                                    this.updateSonden(true);
                                } else {
                                    this.toastr.error(this.t(
                                        'Das Löschen der Sonde ist fehlgeschlagen'));
                                    this.updateSonden(true);
                                }
                            },
                            () => {
                                this.toastr.error(this.t('Das Löschen der Sonde ist fehlgeschlagen'));
                                this.updateSonden(true);
                            }
                        );
                },
                k => {
                }
            );

    }

    showPeilungen($event: RowActionParams<ISonde>) {
        SondenBuchungenDialogComponent.open(this.modalService, $event.row.data).then(noop, noop);
    }
}
