import {AfterViewInit, Component, EventEmitter, Input, NgZone, OnInit, Output, ViewChild} from '@angular/core';

import {ArtikelGruppeList} from './artikelgruppen/common/artikel-gruppe-list';
import {ArtikelGruppe} from './artikelgruppen/common/artikel-gruppe';
import {ProdukteService} from './produkte.service';
import {SimpleArtikelGruppeFilter} from './artikelgruppen/common/simple-artikel-gruppe-filter';
import {
    MEHighlitePipe,
    MESelectComponent,
    MESelectItemMatcherCallback,
    MESelectItemTextCallback
} from '../commons/dynamic-form/me-select/meselect.component';
import {FilterCallback} from '../commons/dynamic-form/FilterCallback';
import {GenericFilter} from '../commons/generic-filter';
import {CommonList} from '../commons/common-list';
import {MESelectItemFormatterCallback} from '../commons/dynamic-form/me-select/meselect-item-formatter-callback';
import {IListResult} from '../commons/list-result';
import {IArtikelGruppe} from './artikelgruppen/common/iartikel-gruppe';
import {MESelectSearchEvent} from '../commons/dynamic-form/me-select/meselect-search-event';
import CryptoES from "crypto-es";

@Component({
    selector: 'artikel-gruppe-inline-field',
    template: `
        <meselect
                id="{{ id }}"
                [idField]="'id'"
                [items]="artikelGruppen?.data"
                [asMatrix]="false"
                [placeholder]="label"
                (itemSelected)="doSelect($event)"
                (needsSearch)="doSearch($event)"
                [small]="small"
                [nullable]="nullable"
        >
        </meselect>
    `,
    styles: [`
        :host ::ng-deep meselect .red {
            color: red;
        }

        :host ::ng-deep meselect .orange {
            color: orange;
        }
    `]
})
export class ArtikelGruppeInlineFieldComponent implements OnInit, AfterViewInit {

    @ViewChild(MESelectComponent, {static: true}) artikelGruppenMESelect: MESelectComponent;
    @Input() small = false;
    @Input() nullable = true;

    artikelGruppen: ArtikelGruppeList = new ArtikelGruppeList();
    @Input() label = 'ArtikelGruppeFeld';
    @Input() id = '';
    @Input() maxDisplayArtikelGruppeNum = 50;
    @Input() minor = true;
    @Input() major = true;
    @Output() valueChange = new EventEmitter<ArtikelGruppe>();

    constructor(public produkteService: ProdukteService,
                public _ngZone: NgZone) {
    }

    _value: ArtikelGruppe = null;

    get value(): ArtikelGruppe {
        return this._value;
    }

    @Input('value')
    set value(v: ArtikelGruppe) {

        if (this._value !== v) {
            this._value = v;
            if ((this.artikelGruppenMESelect !== null) && (this.artikelGruppenMESelect !== undefined)) {
                this.artikelGruppenMESelect.value = v;
            }
            this.valueChange.emit(v);
        }
    }

    public search: FilterCallback<ArtikelGruppe> = (filter: GenericFilter<ArtikelGruppe>,
                                                    list: CommonList<ArtikelGruppe>,
                                                    maxDisplayItems: number,
                                                    queryString: string,
                                                    cfg: any,
                                                    selectComponent: MESelectComponent
    ) => {
        queryString = ('' + queryString).trim();
        let q = '';
        if (queryString !== '') {
            q = '(*' + queryString + '*) OR' +
                '(' + queryString + ')';
        }
        this.produkteService
            .listArtikelGruppen(
                maxDisplayItems,
                0,
                [],
                filter as SimpleArtikelGruppeFilter,
                isNaN(parseInt(queryString, 10)) ? queryString + '*' : queryString
            )
            .subscribe(
                (c: IListResult<IArtikelGruppe>) => {
                    list.populateFromListResult(c);
                    if (selectComponent !== null && selectComponent !== undefined) {
                        selectComponent.items = list.data;
                        selectComponent.cd.markForCheck();
                    }
                }
            );
    }

    ngOnInit() {
        if (this.id === '') {
            this.id = 'tf' + CryptoES.MD5('artikelGruppefield-' + this.label + Math.random() + '-' + Math.random()).toString();
        }
        this.doSearch(new MESelectSearchEvent('', this.artikelGruppenMESelect));
    }

    ngAfterViewInit(): void {
        this.artikelGruppenMESelect.onFormat = this.itemFormat;
        this.artikelGruppenMESelect.onGetText = this.itemText;
        this.artikelGruppenMESelect.onMatch = this.itemMatcher;
        this.artikelGruppenMESelect.value = this._value;
    }

    doSearch(value: MESelectSearchEvent) {
        const lf: SimpleArtikelGruppeFilter = new SimpleArtikelGruppeFilter();

        lf.showMinor = this.minor;
        lf.showMajor = this.major;

        this.search(lf, this.artikelGruppen, this.maxDisplayArtikelGruppeNum, value.queryString, null, value.component);
    }

    doSelect(artikelGruppe: ArtikelGruppe) {
        this._value = artikelGruppe;
        this.valueChange.emit(artikelGruppe);
    }

    public itemFormat: MESelectItemFormatterCallback<ArtikelGruppe> = (item: ArtikelGruppe, search) => {
        if (item === null) {
            return '';
        }

        const icon = 'icofont icofont-cart';

        return '<i class="' + icon + '"></i> ' +
            MEHighlitePipe.transformString(item.bezeichnung, search);
    }

    public itemText: MESelectItemTextCallback<ArtikelGruppe> = (item: ArtikelGruppe) => {
        if (item === null) {
            return '';
        }
        return item.bezeichnung;
    }

    public itemMatcher: MESelectItemMatcherCallback<ArtikelGruppe> = (item: ArtikelGruppe, search) => {
        if (!search) {
            return true;
        }
        if (search.trim() === '') {
            return true;
        }
        if (!item) {
            return false;
        }
        const x = item.bezeichnung;

        return (x.toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) >= 0);
    }


}
