<div class="modal-header">
  <h5 class="modal-title">{{ _('Kennwort ändern') }}</h5>
  <button type="button" class="close" aria-label="Close" (click)="cancel()">
    <span aria-hidden="true">&times;</span>
  </button>

</div>
<div class="modal-body">
  <password-field [label]="_('altes Kennwort')" [labelwidth]="4" [(value)]="entity.currentPassword"></password-field>
  <password-field [label]="_('neues Kennwort')" [labelwidth]="4" [(value)]="entity.newPassword"></password-field>
  <password-field [label]="_('neues Kennwort wiederholen')" [labelwidth]="4" [(value)]="entity.newPasswordRepeat"></password-field>
</div>
<div class="modal-footer" >
  <button type="button" class="btn btn-secondary" (click)="cancel()">
    <i class="icofont icofont-close"></i> {{ _('Schließen') }}
  </button>
  <button type="button" class="btn btn-primary" (click)="save()" [disabled]="!entity.isOk" [class.disabled]="!entity.isOk">
    <i class="icofont icofont-save"></i> {{ _('Kennwort ändern') }}
  </button>
</div>
