import {ChangeDetectorRef, Component, NgZone, OnInit} from '@angular/core';

import {Ausweis} from '../common/ausweis';
import {NgbActiveModal, NgbModal, NgbModalOptions, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {AusweisService} from '../common/ausweis.service';

import {LocalizedComponent} from '../../../commons/components/localized-component';
import {ToastrService} from 'ngx-toastr';

@Component({
    selector: 'app-pin-dialog',
    templateUrl: './pin-dialog.component.html',
    styleUrls: ['./pin-dialog.component.css']
})
export class PinDialogComponent extends LocalizedComponent implements OnInit {

    get dlgTitle() {
        return this._('PIN für Ausweis {{ nummer }} festlegen', {nummer: this.entity.nummer});
    }

    entity: Ausweis = new Ausweis();

    pin1 = '';
    pin2 = '';
    message = '';
    canSave = false;

    static open(modalService: NgbModal, ausweis: Ausweis): Promise<any> {
        const o: NgbModalOptions = {
            size: 'sm',
            backdrop: 'static'
        };
        const r: NgbModalRef = modalService.open(PinDialogComponent, o);
        (r.componentInstance as PinDialogComponent).open(ausweis);
        return r.result;
    }

    constructor(
                
                public activeModal: NgbActiveModal,
                public ausweisService: AusweisService,
                public toastr: ToastrService,
                public cd: ChangeDetectorRef) {
        super( cd);

    }

    ngOnInit() {
    }

    open(ausweis: Ausweis) {
        this.entity = Ausweis.fromResult(ausweis);
        this.pin1 = this.entity.pin;
    }

    cancel() {
        this.activeModal.close();
    }

    save() {
        if (this.canSave) {
            this.ausweisService
                .setPIN(
                    this.entity, this.pin1
                )
                .subscribe(
                    resp => {
                        if (resp.success) {
                            this.toastr.success(this.t('Die PIN wurde festgelegt.'));
                            this.activeModal.close();
                        } else {
                            this.toastr.error(this.t('Beim Speichern der PIN ist ein Fehler aufgetreten.'));
                        }
                    },
                    () => {
                        this.toastr.error(this.t('Beim Speichern der PIN ist ein Fehler aufgetreten.'));
                    }
                );
        }
    }

    setPin(w: number, pin: string) {
        if (pin === null || pin === undefined) {
            return;
        }

        // @Todo DTS Only
        // if (w === 1) {
            this.pin1 = pin.trim();
        // }
        // if (w === 2) {
            this.pin2 = pin.trim();
        // }

        this.canSave = false;
        this.message = '';

        if (!(this.pin1 === null || this.pin1 === undefined || this.pin1.trim() === '')) {

            if (this.pin1.length !== 4) {
                this.message = this._('Die PIN hat ungültige Länge.');
            } else if (this.pin1.match(/(0000|9999)/i)) {
                this.message = this._('Bitte wählen Sie eine andere PIN.');
            } else if (!this.pin1.match(/^[0-9]+$/i)) {
                this.message = this._('Die PIN enthält ungültige Zeichen.');
            } else if (this.pin1 !== this.pin2) {
                this.message = this._('PIN und PIN-Wiederholung stimmen nicht überein.');
            } else {
                this.canSave = true;
            }
        }

    }


    isWifiCard() {
        try {
            if (this.entity.issuer.bezeichnung.toLowerCase().indexOf('wifi') >= 0) {
                return true;
            }
        } catch (ignored) {

        }
        return false;
    }
}
