import {ChangeDetectorRef, Component, Injector} from '@angular/core';

import {Kartenanfrage} from '../common/kartenanfrage';
import {LocalizedCellRenderer} from '../../../commons/data-table/renderer/common-cell-renderer';
import {DataTableRow} from '../../../commons/data-table/data-table-row';
import {DataTableColumnDirective} from '../../../commons/data-table/data-table-column.directive';

@Component({
    selector: 'app-kartenanfrage-kontingent-cell-renderer',
    template: `
        <volumen-display
                *ngIf="cell!==null && cell!==undefined && isVolume()"
                [value]="cell"
                [scale]="scale"
                unit="l"
        ></volumen-display>
        <span *ngIf="cell!==null && cell!==undefined && isAmount()">
                   {{ fCurrency(cell ,currency,'symbol',digitInfo) }}
                   </span>
        <span *ngIf="!isVolume() && !isAmount()">
                    {{ value }}
                   </span>
    `
})
export class KartenanfrageKontingentCellRendererComponent extends LocalizedCellRenderer {
    cell: any = null;
    row: DataTableRow<Kartenanfrage> = null;
    column: DataTableColumnDirective = null;

    value = '---';
    currency = 'EUR';
    digitInfo = '1.3-3';
    scale = 3;

    constructor(  public injector: Injector,public cd:ChangeDetectorRef) {
        super(cd);
        this.cell = this.injector.get('cell');
        this.row = this.injector.get('row');
        this.column = this.injector.get('column');

        if (this.row !== null && this.row !== undefined) {
            if (this.row.data.ergebnis !== null) {
                if (this.row.data.ergebnis.id === 0) {
                    this.value = this.row.data.kontingentTyp;
                }
            }
            if (this.row.data.waehrung !== null) {
                this.currency = this.row.data.waehrung.code;
            }
            if ((this.row.data.kurswert !== 0) &&
                (this.row.data.kurswert !== null) &&
                (this.row.data.kurswert !== undefined)) {
                if (this.isAmount()) {
                    this.cell = this.cell * this.row.data.kurswert;
                }
            }
        }
        if (this.isAmount()) {
            this.cell = Math.round(parseFloat('' + this.cell) * 100.0) / 100.0;
            this.digitInfo = '1.2-2';
        }
    }


    isVolume() {
        if (this.row !== null && this.row !== undefined) {
            if (this.row.data.ergebnis !== null) {
                if (this.row.data.ergebnis.id === 0) {
                    if (this.row.data.kontingentTyp.toLowerCase() === 'menge') {
                        return true;
                    }
                }
            }
        }
        return false;
    }

    isAmount() {
        if (this.row !== null && this.row !== undefined) {
            if (this.row.data.ergebnis !== null) {
                if (this.row.data.ergebnis.id === 0) {
                    if (this.row.data.kontingentTyp.toLowerCase() === 'betrag') {
                        return true;
                    }
                }
            }
        }
        return false;
    }


}
