import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

import {AttachmentService} from '../common/attachment.service';
import {AttachmentUpload} from '../common/attachment-upload';
import * as FileSaver from 'file-saver';
import {LocalizedComponent} from '../../../commons/components/localized-component';
import {Attachment} from '../common/attachment';
import {EFileType} from '../common/efile-type';
import {EAttachmentType} from '../common/eattachment-type';
import {ToastrService} from 'ngx-toastr';


@Component({
    selector: 'app-attachments-control',
    templateUrl: './attachments-control.component.html',
    styleUrls: ['./attachments-control.component.scss']
})
export class AttachmentsControlComponent extends LocalizedComponent implements OnInit {

    _attachments: Attachment[] = [];

    currentAttachment: AttachmentUpload = {
        name: '',
        size: 0,
        type: EFileType.UNKNOWN,
        file: null,
        url: '',
        filename: '',
        atype: EAttachmentType.FILE
    };

    public supportedTypes = {
        'text/plain': EFileType.TEXTDOCUMENT,
        'text/csv': EFileType.SPREADSHEET,
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document': EFileType.TEXTDOCUMENT,
        'application/pdf': EFileType.PDF,
        'application/vnd-ms.excel': EFileType.SPREADSHEET,
        'application/msword': EFileType.TEXTDOCUMENT,
        'application/vnd.ms-powerpoint': EFileType.PRESENTATION,
        'text/xml': EFileType.XML,
        'application/x-tmd': EFileType.TEXTDOCUMENT,
        'application/x-prd': EFileType.SPREADSHEET
    };

    @Input()
    set attachments(value: Attachment[]) {
        this._attachments = [];
        if (Array.isArray(value)) {
            value.forEach(a => {
                const at = Attachment.fromResult(a);
                if (at !== null) {
                    this._attachments.push(at);
                }
            });
        } else {
            const at = Attachment.fromResult(value);
            if (at !== null) {
                this._attachments.push(at);
            }
        }
    }

    get attachments() {
        return this._attachments;
    }

    @Output() attachmentsChange: EventEmitter<Attachment[]> = new EventEmitter<Attachment[]>();


    constructor(
                
                public attachmentService: AttachmentService,
                public toastr: ToastrService,
                public cd: ChangeDetectorRef) {
        super( cd);

    }

    ngOnInit() {
    }


    public fileSelect(e: FileList): void {

        this.currentAttachment.name = '';
        this.currentAttachment.size = 0;
        this.currentAttachment.type = EFileType.UNKNOWN;

        if (e.length > 0) {

            const f: File = e.item(0);

            this.currentAttachment.name = f.name;
            this.currentAttachment.filename = f.name;
            this.currentAttachment.size = f.size;
            this.currentAttachment.file = f;

            Object
                .keys(this.supportedTypes)
                .forEach(st => {
                        if (f.type.toLowerCase() === st.toLowerCase()) {
                            this.currentAttachment.type = this.supportedTypes[st];
                        }
                    }
                );

        }
    }

    setURL(url: string = null) {

        this.currentAttachment.atype = EAttachmentType.URL;
        if (url !== null && url !== undefined) {
            this.currentAttachment.url = url;
            this.currentAttachment.file = null;
            this.currentAttachment.filename = '';
            this.currentAttachment.name = '';
            this.currentAttachment.size = 0;
            this.currentAttachment.type = EFileType.UNKNOWN;
        }
    }

    setFile() {
        this.currentAttachment.atype = EAttachmentType.FILE;
    }

    addAttachment() {
        this.attachmentService
            .uploadAttachment(this.currentAttachment)
            .subscribe(r => {
                    if (r.success) {
                        const at = Attachment.fromResult(r.params.attachment);
                        this._attachments.push(at);
                        this.attachmentsChange.emit(this._attachments);
                    } else {
                        this.toastr.error(this._('Fehler beim Hinzufügen des Anhangs'));
                    }
                },
                () => {
                    this.toastr.error(this._('Fehler beim Hinzufügen des Anhangs'));
                }
            );
    }

    canAdd() {
        if (
            (this.currentAttachment.name === '') ||
            (this.currentAttachment.name === null) ||
            (this.currentAttachment.name === undefined)
        ) {
            return false;
        }

        if (this.currentAttachment.atype === EAttachmentType.URL) {
            if (
                (this.currentAttachment.url === '') ||
                (this.currentAttachment.url === null) ||
                (this.currentAttachment.url === undefined)
            ) {
                return false;
            }
        }

        if (this.currentAttachment.atype === EAttachmentType.FILE) {
            if ((this.currentAttachment.file === null) ||
                (this.currentAttachment.file === undefined) ||
                (this.currentAttachment.file.size < 1)
            ) {
                return false;
            }

        }

        return true;
    }

    deleteAnhang(anhang: Attachment) {
        this.attachments.splice(this.attachments.indexOf(anhang), 1);
        this.attachmentsChange.emit(this._attachments);
    }

    downloadAnhang(anhang: Attachment) {
        if (anhang.isURL()) {
            window.open(anhang.getURI(), '_blank');
        } else if (anhang.isFile()) {
            this.attachmentService
                .downloadAttachment(anhang)
                .subscribe((response: Blob) => {
                    FileSaver.saveAs(response, anhang.filename);
                });
        }
    }
}
