import {ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NachrichtenComponent} from './nachrichten/nachrichten.component';
import {NachrichtenIndicatorComponent} from './nachrichten/nachrichten-indicator.component';
import {DTNachrichtPrioritaetRendererComponent} from './nachrichten/dtnachricht-prioritaet-renderer/dtnachricht-prioritaet-renderer.component';
import {DTNachrichtSubjectRendererComponent} from './nachrichten/dtnachricht-subject-renderer/dtnachricht-subject-renderer.component';
import {NachrichtDetailsComponent} from './nachrichten/nachricht-details/nachricht-details.component';
import {NachrichtenService} from './nachrichten.service';
import {CommonsModule} from '../commons/commons.module';
import {AuthModule} from '../auth/auth.module';
import {HttpClientModule} from '@angular/common/http';

import {MessagingRoutingModule} from './messaging-routing.module';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';

@NgModule({
    imports: [
        CommonModule,
        AuthModule,
        CommonsModule,
        HttpClientModule,
        MessagingRoutingModule,
        NgbModule
    ],
    declarations: [
        NachrichtenComponent,
        NachrichtenIndicatorComponent,
        DTNachrichtPrioritaetRendererComponent,
        DTNachrichtSubjectRendererComponent,
        NachrichtDetailsComponent
    ],
    exports: [
        CommonModule,
        NachrichtenComponent,
        NachrichtenIndicatorComponent,
        DTNachrichtPrioritaetRendererComponent,
        DTNachrichtSubjectRendererComponent,
        NachrichtDetailsComponent
    ],
    entryComponents: [
        NachrichtenComponent,
        DTNachrichtPrioritaetRendererComponent,
        DTNachrichtSubjectRendererComponent,
        NachrichtDetailsComponent
    ]
})
export class MessagingModule {


}
