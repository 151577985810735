import {Component, Injector, OnInit} from '@angular/core';
import {DataTableRow} from '../data-table-row';
import {DataTableColumnDirective} from '../data-table-column.directive';
import {Issuer} from '../../issuers/common/issuer';

@Component({
  selector: 'app-issuer-cell-renderer',
               template: `
                   <app-issuer-quick-info
                           [issuer]="cell"
                           [info_position]="info_position"
                           [showLabel]="true"
                   ></app-issuer-quick-info>
               `
})
export class IssuerCellRendererComponent {


    info_position           = 'right';
    cell: Issuer          = null;
    row: DataTableRow<any>  = null;
    column: DataTableColumnDirective = null;

    constructor(public injector: Injector) {

        this.cell   = this.injector.get('cell');
        this.row    = this.injector.get('row');
        this.column = this.injector.get('column');

        if (this.column !== null && this.column !== undefined) {
            if ((this.column.cfg !== null) && (this.column.cfg !== undefined)) {
                if (this.column.cfg.info_position !== null) {
                    this.info_position = this.column.cfg.info_position;
                }
            }
        }

    }


}
