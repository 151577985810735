import {Nachbuchung} from './nachbuchung';
import {SimpleNachbuchungFilter} from './simple-nachbuchung-filter';
import {INachbuchung} from './inachbuchung';
import {RemoteList} from '../../../commons/remote-list';
import {IListResult} from '../../../commons/list-result';

export class NachbuchungList extends RemoteList<Nachbuchung> {
    public simpleFilter = new SimpleNachbuchungFilter();

    static fromListResult(res: IListResult<INachbuchung>): NachbuchungList {
        const newList = new NachbuchungList();

        newList.populateFromListResult(res, true);

        return newList;
    }


    constructor(autorun = false, timeout = 400) {
        super(autorun, timeout);
    }

    public convertEntity(entity: any): any {
        return Nachbuchung.fromResult(entity);
    }

    public populateFromListResult(res: IListResult<any>, clearFilter = true): void {
        this.doPopulateFromListResult(res, 'zeitpunkt', 'desc', clearFilter);
    }

}
