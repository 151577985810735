<me-info-tool
        [title]="getTitle()"
        [title_icon]="getIcon()"
        [position]="info_position"
        *ngIf="_kunde!==undefined && _kunde!==null">
    <app-kunde-info [kunde]="_kunde"></app-kunde-info>
</me-info-tool>
<span *ngIf="_kunde!=null && _kunde!==undefined && showLabel">
  {{ _kunde.bezeichnung }}
</span>
<span class="badge badge-default"
      *ngIf="_kunde!==null && _kunde!==undefined && showLabel"
      title="{{ _info }}">
  {{ _label }}
</span>