<div class="modal-header">
    <h5 class="modal-title" *ngIf="isAdd()">{{ _('Artikel anlegen') }}</h5>
    <h5 class="modal-title" *ngIf="isEdit()">{{ _('Artikel bearbeiten') }}</h5>
    <button type="button" class="close" aria-label="Close" (click)="cancel()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">

    <num-field
            [label]="_('ID')"
            [(value)]="entity.id"
            [disabled]="isEdit()"
            [addonicon]="'icofont icofont-search'"
            [addonlabel]="_('freie ID suchen')"
            [enableaddon]="true"
            (addonClick)="searchID($event)"
    >
    </num-field>
    <num-field
            [label]="_('Nummer')"
            [(value)]="entity.nummer"
            [addonicon]="'icofont icofont-search'"
            [addonlabel]="_('freie Nummer suchen')"
            [enableaddon]="true"
            (addonClick)="searchNum($event)"
    ></num-field>
    <text-field [label]="_('Bezeichnung')" [(value)]="entity.bezeichnung"></text-field>
    <einheit-field [label]="_('Einheit')" [(value)]="entity.einheit"></einheit-field>
    <artikel-art-field [label]="_('ArtikelArt')" [(value)]="entity.art"></artikel-art-field>

</div>
<div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="cancel()">
        <i class="icofont icofont-close"></i> {{ _('Abbrechen') }}
    </button>
    <button type="button" class="btn btn-primary" (click)="save()">
        <i class="icofont icofont-save"></i>
        {{ _('Änderungen sichern') }}
    </button>
</div>
