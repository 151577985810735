import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Injector} from '@angular/core';
import {Fahrzeug} from './fahrzeuge/common/fahrzeug';

@Component({
    selector: 'app-fahrzeug-element-renderer',
    template: `
        <div *ngIf="value!=null && value!==undefined">
            {{value.bezeichnung}} <span class="badge badge-info">{{value.kfzKennzeichen}}</span> <span class="badge badge-default">{{value.nummer}}</span>
        </div>
    `,
    styles: [`
        :host div {
            display: inline-block;
        }
    `],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FahrzeugElementRendererComponent {

    private _value: Fahrzeug = null;


    get value(): Fahrzeug {
        return this._value;
    }

    set value(value: Fahrzeug) {
        this._value = value;
        this.cd.markForCheck()
    }

    constructor(public injector: Injector,
                public cd: ChangeDetectorRef
    ) {
        this.value = this.injector.get('value')
    }



}
