import {ChangeDetectorRef, Component, NgZone, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {LocalizedComponent} from '../../commons/components/localized-component';
import {KartenanfrageList} from './common/kartenanfrage-list';

import {KartenanfrageService} from './common/kartenanfrage.service';
import {NewPageCountEvent} from '../../commons/paginator/paginator.component';
import {PanCellRendererComponent} from './renderers/pan-cell-renderer.component';
import {KartenanfrageErgebnisCellRendererComponent} from './renderers/kartenanfrage-ergebnis-cell-renderer.component';
import {KartenanfrageKontingentCellRendererComponent} from './renderers/kartenanfrage-kontingent-cell-renderer.component';
import {Kartenanfrage} from './common/kartenanfrage';
import {DataTableComponent, DataTableFilterChanged, DataTableSortChanged} from '../../commons/data-table/data-table.component';
import {KitchenTimer} from '../../commons/kitchen-timer';
import {AusweisService} from '../../customers/ausweise/common/ausweis.service';
import {ResultMetaSortImpl} from '../../commons/result-meta';
import {StationCellRendererComponent} from '../../stationen/station-cell-renderer.component';
import {AusweisCellRendererComponent} from '../../customers/ausweise/ausweis-cell-renderer.component';
import {KundeCellRendererComponent} from '../../customers/kunden/kunde-cell-renderer.component';
import {SysInfoService} from '../../commons/sysinfo/services/sys-info.service';
import {ToastrService} from 'ngx-toastr';
import {IListResult} from '../../commons/list-result';
import {IKartenanfrage} from './common/ikartenanfrage';
import {KartenanfragenFilterdialogComponent} from './kartenanfragen-filterdialog/kartenanfragen-filterdialog.component';
import {LoginService} from '../../auth/services/login.service';
import {DTFahrzeugKfzkennzeichenRendererComponent} from '../../customers/fahrzeuge/renderer/dtfahrzeug-kfzkennzeichen-renderer.component';
import {noop, Subscription} from 'rxjs';
import * as FileSaver from 'file-saver';
import {ConfirmDialogComponent} from '../../commons/confirm-dialog/confirm-dialog.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {RowActionParams} from '../../commons/data-table/row-action-params';

@Component({
    selector: 'app-kartenanfragen',
    templateUrl: './kartenanfragen.component.html',
    styleUrls: ['./kartenanfragen.component.css']
})
export class KartenanfragenComponent extends LocalizedComponent implements OnInit, OnDestroy {

    panCellRenderer = PanCellRendererComponent;
    stationR = StationCellRendererComponent;
    ausweisR = AusweisCellRendererComponent;
    kundeR = KundeCellRendererComponent;
    ergebnisCellRenderer = KartenanfrageErgebnisCellRendererComponent;
    kontingentCellRenderer = KartenanfrageKontingentCellRendererComponent;
    kfzRenderer = DTFahrzeugKfzkennzeichenRendererComponent;

    anfragen = new KartenanfrageList();
    anfragenTimer: KitchenTimer = null;
    updateTimer: KitchenTimer = null;
    @ViewChild('kartenanfragenListe', {static: false}) kartenanfragenListe: DataTableComponent;
    @ViewChild('filterDialog', {static: false}) filterDialog: KartenanfragenFilterdialogComponent;
    private updateTimerSubscription: Subscription = null;


    constructor(
                
                public kartenanfrageService: KartenanfrageService,
                public ausweisService: AusweisService,
                public zone: NgZone,
                public toastr: ToastrService,
                public sysInfoService: SysInfoService,
                public loginService: LoginService,
                public cd: ChangeDetectorRef,
                public modalService: NgbModal
    ) {
        super( cd);

        this.anfragen.size = this.loginService.getPageSize('kartenanfragen');

        this.updateTimer = new KitchenTimer(60000);
        this.anfragenTimer = new KitchenTimer();
        this.anfragenTimer
            .subscribe(
                () => {
                    this.kartenanfrageService
                        .list(
                            this.anfragen.size,
                            this.anfragen.calcOffset(),
                            this.anfragen.order,
                            this.anfragen.simpleFilter,
                            this.anfragen.getQuery()
                        )
                        .subscribe(
                            (l: IListResult<IKartenanfrage>) => {
                                this.anfragen.doPopulateFromListResult(l);
                                if (this.anfragen.page <= 1) {
                                    if (this.updateTimerSubscription != null) {
                                        this.updateTimer.start();
                                    }
                                }

                            },
                            () => {
                                this.toastr.error(this._('Die Liste der Kartenanfragen konnte nicht geladen werden.'));
                                this.anfragen.clear();
                            }
                        );
                }
            );
    }

    updateAnfragen(force = false, page?: number) {
        if ((page !== undefined) && (page != null)) {
            if (page !== this.anfragen.page) {
                this.anfragen.page = page;
                force = true;
            }
        }
        this.anfragenTimer.start(force);
    }

    /**
     * ändert die Anzahl der Datensätze/Seite und lädt die Liste neu
     * @param n
     */
    updateAnfragenPageCount(n: NewPageCountEvent) {
        if (this.anfragen.size !== n.itemsPerPage) {
            this.anfragen.size = n.itemsPerPage;
            this.loginService.setPageSize('kartenanfragen', n.itemsPerPage);
        }
        this.updateAnfragen(true, n.currentPage);
    }

    sortChanged(e: DataTableSortChanged) {
        this.anfragen.order = [
            new ResultMetaSortImpl(e.field, e.direction)
        ];
        this.updateAnfragen();
    }

    filterChanged(e: DataTableFilterChanged) {
        this.zone.run(() => {
            this.anfragen.simpleFilter[e.field] = e.value;
            this.updateAnfragen();
        });
    }

    anfragenQueryChange(value = '') {
        if (value !== this.anfragen.searchString) {
            this.anfragen.searchString = value;
            this.updateAnfragen(false, 1);
        }

    }


    ngOnInit() {
        this.updateTimerSubscription = this.updateTimer
            .subscribe(
                () => {
                    if (this.updateTimerSubscription !== null) {
                        this.updateAnfragen();
                    }
                }
            );

        this.updateAnfragen();
        this.sysInfoService.updateDocumentTitle(this._('Kartenanfragen'));
    }

    ngOnDestroy() {
        if (this.updateTimerSubscription != null) {
            this.updateTimer.stop();
            this.updateTimerSubscription.unsubscribe();
            this.updateTimerSubscription = null;
        }
    }

    checkEnabled(what: string, rap: RowActionParams<Kartenanfrage>) {
        if (what === 'reset') {
            if (rap.row.data.ergebnis !== null && rap.row.data.ergebnis !== undefined) {
                if (rap.row.data.ausweis !== null) {
                    if (rap.row.data.ergebnis.id === 12) {
                        rap.row.enableAction(rap.action);
                        rap.row.showAction(rap.action);

                    } else {
                        rap.row.disableAction(rap.action);
                        rap.row.hideAction(rap.action);
                    }
                }
            }
        }
    }

    unlock(params: RowActionParams<Kartenanfrage>) {
        if (params.row.data.ausweis !== null && params.row.data.ausweis !== undefined) {
            this.ausweisService
                .unlockPinBlock(params.row.data.ausweis)
                .subscribe(
                    (ar) => {
                        if (ar.success) {
                            this.toastr.success(this._('PIN-Sperre wird in Kürze aufgehoben'));
                        } else {
                            this.toastr.error(this._('Fehler beim Aufheben der PIN-Sperre'));
                        }
                    },
                    () => {
                        this.toastr.error(this._('Fehler beim Aufheben der PIN-Sperre'));
                    }
                );
        }

    }


    realDlKartenanfragen(type = 'csv') {

        this.toastr
            .info(this._('Download wird angefordert. Bitte einen Moment Geduld...'),
                this._('Aktion wird ausgeführt...'),
                {
                    timeOut: 15000
                }
            )
            .onShown
            .subscribe(
                () => {
                    this.kartenanfrageService.exportData(
                        type,
                        this.anfragen.simpleFilter,
                        this.anfragen.getQuery()
                    )
                        .subscribe(
                            (response: Blob) => {
                                FileSaver.saveAs(response, this._('kartenanfragen.' + type));
                            },
                            (e) => {
                                this.toastr.error(this._('Die Liste der Kartenanfragen konnte nicht exportiert werden.'));
                            }
                        );
                });


    }


    realReportKartenanfragen(type = 'csv') {

        this.toastr
            .info(
                this._('Report wird generiert. Bitte einen Moment Geduld...'),
                this._('Aktion wird ausgeführt...'),
                {
                    timeOut: 15000
                }
            )
            .onShown
            .subscribe(
                () => {
                    this.kartenanfrageService.reportData(
                        type,
                        this.anfragen.simpleFilter,
                        this.anfragen.getQuery()
                    )
                        .subscribe(
                            (response: Blob) => {
                                FileSaver.saveAs(response, this._('report-' + type + '.pdf'));
                            },
                            (e) => {
                                this.toastr.error(this._('Die Report über Kartenanfragen konnte nicht generiert werden.'));
                            }
                        );
                });


    }


    downloadKartenanfragen(type = 'csv') {
        if (this.anfragen.total < 1) {
            this.toastr.error(this._(
                'Es werden keine Datensätze angezeigt, daher gibt es auch nichts zum Herunterladen.'));
        } else if (this.anfragen.total < 500) {
            this.realReportKartenanfragen(type);
        } else if (this.anfragen.total > 25000) {
            this.toastr.error(
                this._('Sie wollen {{ anzahl }} Datensätze herunterladen. Da es erfahrungsgemäß ' +
                    'hierbei zu Downloadabbrüchen seitens des Webbrowsers kommt, bitten wir Sie,' +
                    'die Ergebnismenge zunächst einzugrenzen.', {anzahl: this.anfragen.total}),
                this._('Aktion nicht möglich'),
                {
                    timeOut: 15000
                }
            );
        } else {
            ConfirmDialogComponent
                .display(
                    this.modalService,
                    this._('Download durchführen?'),
                    this._('Sie wollen {{ anzahl }} Datensätze herunterladen. Dies wird unter Umständen ' +
                        'eine längere Zeit dauern und je nach Browser zu einem Verbindungsabbruch führen. <br /><br /> ' +
                        'Wir empfehlen, die Liste zunnächst weiter einzuschränken.',
                        {anzahl: this.anfragen.total}
                    ),
                    this._('Download trotzdem durchführen'),
                    'icofont icofont-download-alt'
                )
                .result
                .then(
                    () => {
                        this.realDlKartenanfragen(type);
                    },
                    noop
                );
        }
    }


    reportKartenanfragen(type = 'simple-list') {
        if (this.anfragen.total < 1) {
            this.toastr.error(this._(
                'Es werden keine Datensätze angezeigt, daher gibt es auch nichts zum Herunterladen.'));
        } else if (this.anfragen.total < 500) {
            this.realReportKartenanfragen(type);
            /*        } else if (this.anfragen.total > 25000) {
                        this.toastr.error(
                            this._('Sie wollen einen Report über {{ anzahl }} Datensätze generieren ' +
                                   'und herunterladen. Da es erfahrungsgemäß hierbei zu Downloadabbrüchen ' +
                                   'seitens des Webbrowsers kommt, bitten wir Sie, die Ergebnismenge zunächst ' +
                                   'einzugrenzen.', {anzahl: this.anfragen.total}),
                            this._('Aktion nicht möglich'),
                            {
                                timeOut: 15000
                }
                        );*/
        } else {
            ConfirmDialogComponent
                .display(
                    this.modalService,
                    this._('Report generieren?'),
                    this._('Sie wollen einen Report über {{ anzahl }} Datensätze generieren und ' +
                        'herunterladen. Dies wird unter Umständen eine längere Zeit dauern und je ' +
                        'nach Browser zu einem Verbindungsabbruch führen. <br /><br /> ' +
                        'Wir empfehlen, die Liste zunnächst weiter einzuschränken.',
                        {anzahl: this.anfragen.total}
                    ),
                    this._('Download trotzdem durchführen'),
                    'icofont icofont-download-alt'
                )
                .result
                .then(
                    () => {
                        this.realReportKartenanfragen(type);
                    },
                    noop
                );
        }
    }


    reindexKartenanfragen() {
        this.kartenanfrageService
            .reindexKartenanfragen()
            .subscribe(
                () => {
                    this.toastr.success(this._('Der Indizierungsvorgang wurde angestoßen.'));
                },
                () => {
                    this.toastr.error(this._('Die Indizierungsvorgang konnte nicht angestoßen werden.'));
                }
            );
    }

    kartenanfragenQueryChange2(value: string) {
        if (value !== this.anfragen.searchString2) {
            this.anfragen.searchString2 = value;
            this.updateAnfragen(false, 1);
        }
    }

    kartenanfragenFilterToggle(visible: boolean) {
        if (!visible) {
            this.kartenanfragenQueryChange2('');
        }
    }
}
