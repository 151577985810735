import {ChangeDetectorRef, Component, Injector} from '@angular/core';
import {DataTableColumnDirective} from '../data-table-column.directive';
import {DataTableRow} from '../data-table-row';
import {LocalizedCellRenderer} from './common-cell-renderer';

@Component({
               selector: 'decimal-cell-renderer',
               template: `{{ value }}{{ addon }}`
           })
export class DecimalCellRendererComponent extends LocalizedCellRenderer {

    digitInfo = '1.3-3';
    digits = 3;
    do_round = false;

    cell: number = null;
    row: DataTableRow<any> = null;
    column: DataTableColumnDirective = null;

    addon = '';


    get value() {
        if (this.cell == null) {
            return '';
        }
        return this.fNum(this.cell, this.digitInfo);
    }

    constructor(public injector: Injector,
                public cd: ChangeDetectorRef) {
        super(cd);
        this.cell = this.injector.get('cell');
        this.row = this.injector.get('row');
        this.column = this.injector.get('column');

        if ((this.column !== null) && (this.column !== undefined)) {
            const cfg = this.column.cfg;
            if ((cfg !== null) && (cfg !== undefined)) {
                if ((cfg.fdigits !== undefined) && (cfg.fdigits !== null)) {
                    const fdigits: number = parseInt(cfg.fdigits, 10);
                    if (!isNaN(fdigits)) {
                        this.digits = fdigits;
                        this.digitInfo = '1.' + fdigits + '-' + fdigits;
                    }
                }

                if ((cfg.round !== undefined) && (cfg.round !== null)) {
                    this.do_round = !!cfg.round;
                }

                if (cfg.addon !== undefined) {
                    this.addon = cfg.addon;
                }

            }
        }

        if (this.do_round) {
            this.cell = Math.round(this.cell * Math.pow(this.digits, 10)) / Math.pow(this.digits, 10);
        }

    }


}
