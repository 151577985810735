import {Injectable} from '@angular/core';
import {SimpleKartenanfrageFilter} from './simple-kartenanfrage-filter';
import {LoginService} from '../../../auth/services/login.service';
import {Observable} from 'rxjs';
import {IKartenanfrage} from './ikartenanfrage';
import {ResultMetaSortImpl} from '../../../commons/result-meta';
import {IListResult} from '../../../commons/list-result';
import {IActionResponse} from '../../../commons/action-response';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';

@Injectable({
                providedIn: 'root'
            })
export class KartenanfrageService {
    constructor(public httpClient: HttpClient,
                public loginService: LoginService) {

    }

    list(max = 0,
         offset = 0,
         sort: ResultMetaSortImpl[] = [],
         filter: SimpleKartenanfrageFilter = null,
         searchQuery = ''): Observable<IListResult<IKartenanfrage>> {

        let url = '/api/kartenanfragen';

        url = url.concat('?')
            .concat('max=' + encodeURIComponent('' + max))
            .concat('&')
            .concat('offset=' + encodeURIComponent('' + offset));

        if (filter != null) {
            if (filter.hasValues()) {
                url = url + '&' + filter.asURL();
            }
        }

        if (sort !== undefined && sort !== null && sort.length > 0) {
            const p = 0;
            sort.forEach(it => {
                url = url.concat('&sort.' + p + '=' + encodeURIComponent(it.field + '|' + it.direction));
            });
        }

        if (searchQuery !== null && searchQuery !== undefined && searchQuery !== '') {
            url = url.concat('&q=' + encodeURIComponent(searchQuery));
        }


        return this.httpClient
            .get<IListResult<IKartenanfrage>>(
                url,
                {headers: this.loginService.getHttpHeaders()}
            );
    }


    countKartenanfragen(): Observable<IActionResponse> {
        return this.httpClient
            .get<IActionResponse>(
                '/api/kartenanfragen/_count',
                {headers: this.loginService.getHttpHeaders()}
            );

    }

    exportData(type= 'csv', filter: SimpleKartenanfrageFilter = null, searchQuery = ''): Observable<Blob> {
        let url = '/api/kartenanfragen.' + encodeURIComponent('' + type);

        url = url.concat('?max=-1')
            .concat('&offset=0')
            .concat('&sort.0=' + encodeURIComponent('zeitpunkt|asc'));


        if (filter !== null) {
            if (filter.hasValues()) {
                url = url + '&' + filter.asURL();
            }
        }
        if (searchQuery !== null && searchQuery !== undefined && searchQuery !== '') {
            url = url.concat('&q=' + encodeURIComponent(searchQuery));
        }


        return this.httpClient
            .get(
                url,
                {
                    headers: this.loginService.getHttpHeaders(false),
                    responseType: 'blob'
                }
            );
    }

    reindexKartenanfragen(): Observable<IActionResponse> {
        return this.httpClient
            .post<IActionResponse>(
                '/api/kartenanfragen/_reindex',
                {},
                {
                    headers: this.loginService.getHttpHeaders()
                }
            );
    }


    reportData(type: string, filter: SimpleKartenanfrageFilter, searchQuery: string): Observable<Blob>  {
          let url = '/api/kartenanfragen/reports/' + encodeURIComponent('' + type);

        url = url.concat('?max=-1')
                 .concat('&offset=0')
                 .concat('&sort.0=' + encodeURIComponent('zeitpunkt|asc'));


        if (filter !== null) {
            if (filter.hasValues()) {
                url = url + '&' + filter.asURL();
            }
        }
        if (searchQuery !== null && searchQuery !== undefined && searchQuery !== '') {
            url = url.concat('&q=' + encodeURIComponent(searchQuery));
        }


        return this.httpClient
                   .get(
                       url,
                       {
                           headers     : this.loginService.getHttpHeaders(false),
                           responseType: 'blob'
                       }
                   );
    }
}
