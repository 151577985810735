import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Injector} from '@angular/core';
import {Land} from '../common/land';

@Component({
               selector       : 'app-country-element-renderer',
               template       : `
                   <div *ngIf="value!=null && value!==undefined">
                      <i class="flag-icon flag-icon-squared flag-icon-{{value.laendercode}}"></i> {{value.bezeichnung}} <span class="badge badge-default">{{value.laendercode}}</span>
                   </div>
               `,
               styles         : [
                       `
                       :host div {
                           display: inline-block;
                       }
                       `
               ],
               changeDetection: ChangeDetectionStrategy.OnPush
           })
export class CountryElementRendererComponent {
    private _value: Land = null;


    get value(): Land {
        return this._value;
    }

    set value(value: Land) {
        this._value = value;
        this.cd.markForCheck();
    }

    constructor(public injector: Injector,
                public cd: ChangeDetectorRef
    ) {
        this.value = this.injector.get('value');
    }

}
