import {IKartenanfrageErgebnis} from './ikartenanfrage-ergebnis';

export class KartenanfrageErgebnis implements IKartenanfrageErgebnis {
    id = 0;
    message = '';
    description = '';

    static fromResult(entity: IKartenanfrageErgebnis, createIfNull = true): KartenanfrageErgebnis {
        if ((entity === null) || (entity === undefined)) {
            if (createIfNull) {
                return new KartenanfrageErgebnis();
            }
            return null;
        }

        const l = new KartenanfrageErgebnis();

        for (const k of Object.keys(l)) {
            l[k] = (entity[k] !== undefined) ? entity[k] : null;
        }

        return l;
    }


    constructor() {
    }
}
