<div class="modal-header">
    <h5 class="modal-title">{{ _('Mitteilungdetails') }}</h5>
    <button (click)="cancel()" aria-label="Close" class="close" type="button">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <table class="table">
        <tr>
            <th scope="row" valign="top">{{_('Zeitpunkt')}}</th>
            <td valign="top">{{fDate(entity.dateCreated, 'medium')}}</td>
        </tr>
        <tr>
            <th scope="row" valign="top">{{ _('Betreff')}}</th>
            <td [innerHTML]="_(entity.subject,entity.params)" valign="top"></td>
        </tr>
        <tr>
            <th scope="row" valign="top">{{ _('Nachrichteninhalt')}}</th>
            <td [innerHTML]="_(entity.message,entity.params)" valign="top"></td>
        </tr>
    </table>
</div>
<div class="modal-footer">

    <button (click)="delete()" class="btn btn-secondary" type="button">
        <i class="icofont icofont-ui-delete"></i> {{ _('Löschen') }}
    </button>

    <button (click)="cancel()" class="btn btn-primary" type="button">
        <i class="icofont icofont-close"></i> {{ _('Schließen') }}
    </button>
</div>

