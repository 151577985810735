import {ChangeDetectorRef, Component} from '@angular/core';
import {GenericModalComponent} from '../../commons/generic-modal.component';
import {Benutzer} from '../../auth/common/benutzer';

import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {ToastrService} from 'ngx-toastr';
import {LoginService} from '../../auth/services/login.service';
import {PubSubService} from '../../commons/pub-sub.service';

@Component({
    selector: 'app-profil-editor',
    templateUrl: './profil-editor.component.html',
    styleUrls: ['./profil-editor.component.scss']
})
export class ProfilEditorComponent extends GenericModalComponent<Benutzer> {

    public initEntity(src: Benutzer): Benutzer {
        const b = Benutzer.fromResult(src);
        if (b.emailsEnabled === null || b.emailsEnabled === undefined) {
            b.emailsEnabled = false;
        }
        if (b.email === null || b.email === undefined) {
            b.email = '';
        }
        return b;
    }

    constructor(

                public activeModal: NgbActiveModal,
                public toastr: ToastrService,
                public loginService: LoginService,
                public cd: ChangeDetectorRef,
                public pubsub: PubSubService) {
        super( loginService, activeModal, cd, pubsub);
    }

    save() {
        this.loginService
            .storeCU(this.entity)
            .subscribe(r => {
                    if (r.success) {
                        this.activeModal.close(this.entity);
                    } else {
                        this.toastr.error(this._('Fehler beim Speichern der Benutzerdaten'));
                    }
                },
                () => {
                    this.toastr.error(this._('Fehler beim Speichern der Benutzerdaten'));
                }
            );
    }

 canSave() {
        if (this.entity.newPW !== null && this.entity.newPW !== undefined && ('' + this.entity.newPW).trim() !== '') {
            if (this.entity.newPW !== this.entity.newPW2) {
                return false;
            }
        }

        if (this.entity.emailsEnabled && (this.entity.email.trim() === '')) {
            return false;
        }

        return true;
    }
}
