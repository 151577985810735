import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';

import {TransaktionService} from '../common/transaktion.service';
import {NachbuchungList} from '../common/nachbuchung-list';
import {Subscription} from 'rxjs';
import {INachbuchung} from '../common/inachbuchung';

import {NachbuchungEditorComponent} from '../nachbuchung-editor/nachbuchung-editor.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {LocalizedComponent} from '../../../commons/components/localized-component';
import {NewPageCountEvent} from '../../../commons/paginator/paginator.component';
import {KitchenTimer} from '../../../commons/kitchen-timer';
import {IListResult} from '../../../commons/list-result';
import {StationCellRendererComponent} from '../../../stationen/station-cell-renderer.component';
import {AusweisCellRendererComponent} from '../../../customers/ausweise/ausweis-cell-renderer.component';
import {NachbuchungDetailsRendererComponent} from '../renderers/nachbuchung-details-renderer.component';
import {Nachbuchung} from '../common/nachbuchung';
import {ResultMetaSortImpl} from '../../../commons/result-meta';
import {DataTableFilterChanged, DataTableSortChanged} from '../../../commons/data-table/data-table.component';
import {NachbuchungDetailsComponent} from '../nachbuchung-details/nachbuchung-details.component';
import {ToastrService} from 'ngx-toastr';
import {LoginService} from '../../../auth/services/login.service';
import {RowActionParams} from '../../../commons/data-table/row-action-params';

@Component({
               selector       : 'app-tab-nachbuchungen',
               templateUrl    : './tab-nachbuchungen.component.html',
               styleUrls      : ['./tab-nachbuchungen.component.css'],
               changeDetection: ChangeDetectionStrategy.OnPush
           })
export class TabNachbuchungenComponent extends LocalizedComponent implements OnInit, OnDestroy {

    stationR = StationCellRendererComponent;
    ausweisR = AusweisCellRendererComponent;
    prdCellRenderer = NachbuchungDetailsRendererComponent;

    public nachbuchungen = new NachbuchungList();
    nachbuchungenTimer = new KitchenTimer();

    sub: Subscription = null;

    constructor(

                public cd: ChangeDetectorRef,
                public transaktionService: TransaktionService,
                public toastr: ToastrService,
                public loginService: LoginService,
                public modalService: NgbModal) {
        super( cd);
        this.nachbuchungen.size = this.loginService.getPageSize('nachbuchungen');
    }

    ngOnInit() {
        this.sub = this.nachbuchungenTimer
                       .subscribe(
                           () => {
                               this.transaktionService
                                   .listNachbuchungen(
                                       this.nachbuchungen.size,
                                       this.nachbuchungen.calcOffset(),
                                       this.nachbuchungen.order,
                                       this.nachbuchungen.simpleFilter,
                                       this.nachbuchungen.searchString
                                   )
                                   .subscribe(
                                       (data: IListResult<INachbuchung>) => {
                                           this.nachbuchungen.populateFromListResult(data);
                                           this.cd.markForCheck();
                                       },
                                       () => {
                                           this.toastr.error(this.t(
                                               'Die Liste der Nachbuchungen konnte nicht abgerufen werden'));
                                           this.nachbuchungen.clear();
                                           this.cd.markForCheck();
                                       }
                                   );
                           },
                           undefined
                       );

        this.nachbuchungenTimer.start();
    }

    ngOnDestroy() {
        if (this.sub !== null && this.sub !== undefined) {
            this.sub.unsubscribe();
        }
    }


    newNachbuchung() {
        NachbuchungEditorComponent.open(
            this.modalService,
            null
                                  )
                                  .then(
                                      () => {

                                      },
                                      () => {

                                      }
                                  );
    }

    updateNachbuchungen(force = false, page?: number) {
        if ((page !== undefined) && (page != null)) {
            if (page !== this.nachbuchungen.page) {
                this.nachbuchungen.page = page;
                force = true;
            }
        }
        this.nachbuchungenTimer.start(force);
    }

    updateNachbuchungenPageCount(n: NewPageCountEvent) {
        if (this.nachbuchungen.size !== n.itemsPerPage) {
            this.nachbuchungen.size = n.itemsPerPage;
            this.loginService.setPageSize('nachbuchungen', n.itemsPerPage);
        }
        this.updateNachbuchungen(true, n.currentPage);
    }

    nachbuchungenQueryChange(value = '') {
        if (value !== this.nachbuchungen.searchString) {
            this.nachbuchungen.searchString = value;
            this.updateNachbuchungen(false, 1);
        }
    }

    sortChanged(e: DataTableSortChanged) {
        this.nachbuchungen.order = [
            new ResultMetaSortImpl(e.field, e.direction)
        ];
        this.updateNachbuchungen();
    }

    filterChanged(e: DataTableFilterChanged) {
        this.nachbuchungen.simpleFilter[e.field] = e.value;
        this.updateNachbuchungen();
    }

    processNacherfassung(params: RowActionParams<Nachbuchung>) {
        this.transaktionService
            .resumeNacherfassung(params.row.data)
            .subscribe(
                (r) => {
                    if (r.success) {
                        this.toastr.success(this._('Die Verarbeitung der Buchung wurde gestartet'));
                    } else {
                        this.toastr.error(this._('Fehler beim Starten der Buchungsverarbeitung'));
                    }
                    this.updateNachbuchungen();
                },
                () => {
                    this.toastr.error(this._('Fehler beim Starten der Buchungsverarbeitung'));
                    this.updateNachbuchungen();
                }
            );
    }

    holdNacherfassung(params: RowActionParams<Nachbuchung>) {
        this.transaktionService
            .holdNacherfassung(params.row.data)
            .subscribe(
                (r) => {
                    if (r.success) {
                        this.toastr.success(this._('Die Verarbeitung der Buchung wurde unterbunden'));
                    } else {
                        this.toastr.error(this._('Fehler beim Unterbinden der Buchungsverarbeitung'));
                    }
                    this.updateNachbuchungen();
                },
                () => {
                    this.toastr.error(this._('Fehler beim Unterbinden der Buchungsverarbeitung'));
                    this.updateNachbuchungen();
                }
            );

    }

    checkNacherfassung(what: string, r: RowActionParams<Nachbuchung>) {
        if (what === 'process') {
            if (r.row.data.transfer || r.row.data.verbucht) {
                r.row.disableAction(r.action);
                r.row.hideAction(r.action);
            }
        }
        if (what === 'hold') {
            if (!r.row.data.transfer || r.row.data.verbucht) {
                r.row.disableAction(r.action);
                r.row.hideAction(r.action);
            }
        }
        if (what === 'edit') {
            if (r.row.data.verbucht) {
                r.row.disableAction(r.action);
                r.row.hideAction(r.action);
            }
        }
        if (what === 'details') {
            if (!r.row.data.verbucht) {
                r.row.disableAction(r.action);
                r.row.hideAction(r.action);
            }
        }
    }

    showDetails(params: RowActionParams<Nachbuchung>) {
        NachbuchungDetailsComponent.open(
            this.modalService,
            params.row.data
                                   )
                                   .then(
                                       () => {

                                       },
                                       () => {

                                       }
                                   );

    }

    editNachbuchung(params: RowActionParams<Nachbuchung>) {
        NachbuchungEditorComponent.open(
            this.modalService,
            params.row.data
                                  )
                                  .then(
                                      () => {

                                      },
                                      () => {

                                      }
                                  );

    }

}
