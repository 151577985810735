import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {Station} from '../common/station';
import {LocalizedComponent} from '../../commons/components/localized-component';

import {StationenService} from '../common/stationen.service';
import {IStation} from '../common/istation';

@Component({
    selector: 'app-station-display',
    templateUrl: './station-display.component.html'
})
export class StationDisplayComponent extends LocalizedComponent implements OnInit {

    constructor(

                public stationService: StationenService,
                public cd: ChangeDetectorRef) {
        super( cd);
    }

    get stationLabel() {
        return this._('Station {{ nummer }}', {nummer: this._station.nummer});
    }

    _station: Station = null;

    @Input()
    set station(value: Station) {
        this._station = Station.fromResult(value);
        if (this._station !== undefined && this._station !== null) {
            this.stationService
                .loadStation(this._station.id)
                .subscribe(
                    (t: IStation) => {
                        this._station = Station.fromResult(t);
                    }
                );
        }
    }

    ngOnInit() {
    }

}
