import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';


import {EImportFileType} from './transaktionen/common/eimport-file-type';
import {IOption, NullOption} from '../commons/dynamic-form/select-field/ioption';
import {LocalizedComponent} from '../commons/components/localized-component';
import {ImportFileType} from './transaktionen/common/ImportFileType';
import {ConverterTool} from "../../shared/converter-tool";

@Component({
    selector: 'import-file-type-field',
    template: `
        <div class="form-group row">
            <label for="{{ id }}" class="col-{{ labelwidth }} col-form-label">
                {{ label }}
            </label>
            <div class="col-{{ 12-labelwidth }}">

                <select
                        id="{{ id }}"
                        class="form-control"
                        [value]="value"
                        (input)="updateValue($event)"
                        title="{{ label }}"
                >
                    <option
                            *ngFor="let o of options"
                            value="{{ o.value }}"
                    >
                        <i class="{{o.icon}}" *ngIf="o.icon!==null && o.icon!==undefined"></i>
                        {{ o.label }}
                    </option>
                </select>
            </div>
        </div>
    `
})
export class ImportFileTypeFieldComponent extends LocalizedComponent implements OnInit {

    @Input() label = 'Dateitypfeld';
    @Input() id = '';
    @Input() labelwidth = 3;
    @Input() nullable = false;
    options: IOption[] = [
        {
            value: EImportFileType.UNKNOWN,
            label: this._('unbekannter Dateityp'),
            icon: ImportFileType.getIcon(EImportFileType.UNKNOWN)
        },
        {
            value: EImportFileType.VDAF,
            label: this._('VDAF-Datei'),
            icon: ImportFileType.getIcon(EImportFileType.VDAF)
        }
    ];
    @Output() valueChange = new EventEmitter<EImportFileType>();

    constructor(
        public cd: ChangeDetectorRef) {
        super(cd);
    }

    public _value: EImportFileType = EImportFileType.VDAF;

    get value(): EImportFileType {
        return this._value;
    }

    @Input()
    set value(v: EImportFileType) {
        if (this._value !== v) {
            this._value = v;
            this.valueChange.emit(v);
        }
    }

    ngOnInit() {
        if (this.nullable) {
            this.options.unshift(NullOption);
        }
        if (this.id === '') {
            this.id = ConverterTool.randomId();
        }
    }

    updateValue($event: any) {
        this.value = $event.target.value;
    }

}
