import {Ausweis} from './ausweis';
import {SimpleAusweisFilter} from './simple-ausweis-filter';
import {IAusweis} from './iausweis';
import {CommonList} from '../../../commons/common-list';
import {IListResult} from '../../../commons/list-result';

export class AusweisList extends CommonList<Ausweis> {
    public simpleFilter: SimpleAusweisFilter = new SimpleAusweisFilter();

    static fromListResult(res: IListResult<IAusweis>): AusweisList {
        const newList = new AusweisList();
        newList.populateFromListResult(res, true);
        return newList;
    }


    public populateFromListResult(res: IListResult<any>, clearFilter = true): void {
        this.doPopulateFromListResult(res, 'nummer', 'asc', clearFilter);
    }

    public convertEntity(entity: any): any {
        return Ausweis.fromResult(entity);
    }
}
