import {ChangeDetectorRef, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {LocalizedComponent} from '../../commons/components/localized-component';

import {Kunde} from '../kunden/common/kunde';

@Component({
    selector: 'kundendaten-block',
    templateUrl: './kundendaten-block.component.html',
    styleUrls: ['./kundendaten-block.component.css']
})
export class KundendatenBlockComponent extends LocalizedComponent implements OnInit, OnDestroy {

    public get msg() {
        return this._('Sollten sich Ihre Daten geändert haben, bitte wir Sie, <br />' +
            'uns dies per E-Mail an ' +
            '<a href="mailto:dts@dieseltankstelle.at?subject=Kundendatenänderung%20Kunde%20{{kundennummer}}" ' +
            'target="_blank">dts@dieseltankstelle.at</a>' +
            ' mitzuteilen.', {kundennummer: this.kunde.kundennummer});
    }

    @Input() kunde: Kunde = null;
    @Input() icon = 'icofont icofont-user';
    @Input() title = this.t('Ihre Kundendaten');
    @Input() classes = 'light-blue';
    @Input() subtext = '';

    constructor(

                public cd: ChangeDetectorRef) {
        super( cd);
    }

    ngOnInit() {

    }

    ngOnDestroy() {

    }


}
