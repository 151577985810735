import {Pipe, PipeTransform} from "@angular/core";

@Pipe({
          name: "shorten"
      })
export class ShortenPipe implements PipeTransform {

    transform(value: any, args?: any): any {
        if (value === null) {
            return null;
        }
        if (value === undefined) {
            return value;
        }

        let m = -1;
        if (args === undefined || args === null) {
            return value;
        }

        if (Array.isArray(args)) {
            m = parseInt(args[ 0 ], 10);
        } else {
            m = parseInt(args, 10);
        }

        if (isNaN(m)) {
            return value;
        }

        if (("" + value).length <= m) {
            return value;
        }

        if (m < 3) {
            return "...";
        }

        if (m < 10) {
            return ("" + value).substr(0, m - 3) + "...";
        }

        let vorn   = ("" + value).substr(0, Math.floor((m - 3) / 2));
        let begin  = ("" + value).length - (vorn.length - 3);
        let hinten = ("" + value).substr(begin);
        return vorn + "..." + hinten;
    }

}
