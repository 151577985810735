import {ChangeDetectorRef, Component, Input, OnDestroy, OnInit} from '@angular/core';

import {KundeAuditList} from '../common/kunde-audit-list';
import {KundenService} from '../common/kunden.service';

import {LocalizedComponent} from '../../../commons/components/localized-component';
import {IKunde} from '../common/ikunde';
import {KitchenTimer} from '../../../commons/kitchen-timer';
import {NewPageCountEvent} from '../../../commons/paginator/paginator.component';
import {ToastrService} from 'ngx-toastr';
import {IListResult} from '../../../commons/list-result';
import {IKundeAudit} from '../common/ikunde-audit';
import {LoginService} from '../../../auth/services/login.service';

@Component({
               selector   : 'app-tab-kunde-audit',
               templateUrl: './tab-kunde-audit.component.html',
               styleUrls  : ['./tab-kunde-audit.component.css']
           })
export class TabKundeAuditComponent extends LocalizedComponent implements OnInit, OnDestroy {
    _kunde: IKunde = null;
    public kundenAudit: KundeAuditList = new KundeAuditList();
    kundenAuditTimer: KitchenTimer;

    @Input()
    set kunde(kunde: IKunde) {
        this._kunde = kunde;
        this.updateKundenAudit(true, 0);
    }

    constructor(

                public kundenService: KundenService,
                public toastr: ToastrService,
                public loginService: LoginService,
                public cd: ChangeDetectorRef) {
        super( cd);

        this.kundenAudit = new KundeAuditList();
        this.kundenAudit.size = this.loginService.getPageSize('kundenAudit');

        this.kundenAuditTimer = new KitchenTimer();
        this.kundenAuditTimer
            .subscribe(
                () => {
                    if (this._kunde !== null && this._kunde !== undefined) {
                        this.kundenService
                            .listAudit(
                                this._kunde,
                                this.kundenAudit.size,
                                this.kundenAudit.calcOffset(),
                                this.kundenAudit.order,
                                this.kundenAudit.simpleFilter,
                                this.kundenAudit.searchString
                            )
                            .subscribe(
                                (l: IListResult<IKundeAudit>) => {
                                    this.kundenAudit.populateFromListResult(l);
                                },
                                (e) => {
                                    this.toastr.error(this._('Die Liste der Angaben zur Änderungsverfolgung konnte nicht geladen werden.'));
                                    this.kundenAudit.clear();
                                }
                            );
                    }
                }
            );

    }

    ngOnInit() {
        this.updateKundenAudit();
    }

    ngOnDestroy(): void {
        this.kundenAuditTimer.stop();
    }

    updateKundenAudit(force = false, page?: number) {
        if ((page !== undefined) && (page !== null)) {
            if (page !== this.kundenAudit.page) {
                this.kundenAudit.page = page;
                force = true;
            }
        }
        this.kundenAuditTimer.start(force);
    }

    /**
     * ändert die Anzahl der Datensätze/Seite und lädt die Liste neu
     * @param n
     */
    updateKundenAuditPageCount(n: NewPageCountEvent) {
        if (this.kundenAudit.size !== n.itemsPerPage) {
            this.kundenAudit.size = n.itemsPerPage;
            this.loginService.setPageSize('kundenAudit', n.itemsPerPage);
        }
        this.updateKundenAudit(true, n.currentPage);
    }


}
