import {AfterViewInit, Component, EventEmitter, Input, NgZone, OnInit, Output, ViewChild} from '@angular/core';

import {ProduktList} from './produkte/common/produkt-list';
import {Produkt} from './produkte/common/produkt';
import {ProdukteService} from './produkte.service';
import {SimpleProduktFilter} from './produkte/common/simple-produkt-filter';
import {EArtikelArt} from './artikel/eartikel-art';
import {
    MEHighlitePipe,
    MESelectComponent,
    MESelectItemMatcherCallback,
    MESelectItemTextCallback
} from '../commons/dynamic-form/me-select/meselect.component';
import {FilterCallback} from '../commons/dynamic-form/FilterCallback';
import {GenericFilter} from '../commons/generic-filter';
import {CommonList} from '../commons/common-list';
import {MESelectItemFormatterCallback} from '../commons/dynamic-form/me-select/meselect-item-formatter-callback';
import {IListResult} from '../commons/list-result';
import {IProdukt} from './produkte/common/iprodukt';
import {MESelectSearchEvent} from '../commons/dynamic-form/me-select/meselect-search-event';
import CryptoES from "crypto-es";

@Component({
    selector: 'produkt-inline-field',
    template: `
        <meselect
                id="{{ id }}"
                [idField]="'id'"
                [items]="produkte?.data"
                [asMatrix]="false"
                [placeholder]="label"
                (itemSelected)="doSelect($event)"
                (needsSearch)="doSearch($event)"
                [small]="small"
                [nullable]="nullable"
                [disabled]="disabled"
        >
        </meselect>
    `
})
export class ProduktInlineFieldComponent implements OnInit, AfterViewInit {
    @Input() nullable = true;

    @ViewChild(MESelectComponent, {static: true}) produkteMESelect: MESelectComponent;
    @Input() small = false;

    produkte: ProduktList = new ProduktList();
    @Input() label = 'Produktfeld';
    @Input() id = '';
    @Input() maxDisplayArtikel = 50;
    @Input() showOnly: EArtikelArt = EArtikelArt.ANY;
    @Input() disabled = false;
    @Output() valueChange = new EventEmitter<Produkt>();

    constructor(public produkteService: ProdukteService,
                public _ngZone: NgZone) {
    }

    _value: Produkt = null;

    get value(): Produkt {
        return this._value;
    }

    @Input()
    set value(v: Produkt) {

        if (this._value !== v) {
            this._value = v;
            if ((this.produkteMESelect !== null) && (this.produkteMESelect !== undefined)) {
                this.produkteMESelect.value = v;
            }
            if (!this.disabled) {
                this.valueChange.emit(v);
            }
        }
    }

    public search: FilterCallback<Produkt> = (
        filter: GenericFilter<Produkt>,
        list: CommonList<Produkt>,
        maxDisplayItems: number,
        queryString: string,
        cfg: any,
        selectComponent: MESelectComponent
    ) => {
        queryString = ('' + queryString).trim();
        let q = '';
        if (queryString !== '') {
            if (isNaN(parseInt(queryString, 10))) {
                q = '(*' + queryString + '*) OR' +
                    '(' + queryString + ')';
            } else {
                q = '(nummer:' + parseInt(queryString, 10) + '^10) OR ' +
                    '(nummer:' + parseInt(queryString, 10) + '*^5) OR ' +
                    '(*' + queryString + '*) OR' +
                    '(' + queryString + ')';
            }
        }
        this.produkteService
            .listProdukte(
                maxDisplayItems,
                0,
                [],
                filter as SimpleProduktFilter,
                q
            )
            .subscribe(
                (c: IListResult<IProdukt>) => {
                    list.populateFromListResult(c);
                    if (selectComponent !== null && selectComponent !== undefined) {
                        selectComponent.items = list.data;
                        selectComponent.cd.markForCheck();
                    }
                }
            );
    }

    ngOnInit() {
        if (this.id === '') {
            this.id = 'tf' + CryptoES.MD5('textfield-' + this.label + Math.random() + '-' + Math.random()).toString();
        }
        this.doSearch(new MESelectSearchEvent('', this.produkteMESelect));
    }

    ngAfterViewInit(): void {
        this.produkteMESelect.onFormat = this.itemFormat;
        this.produkteMESelect.onGetText = this.itemText;
        this.produkteMESelect.onMatch = this.itemMatcher;
        this.produkteMESelect.value = this._value;
    }

    doSearch(value: MESelectSearchEvent) {
        const lf: SimpleProduktFilter = new SimpleProduktFilter();

        lf.art = this.showOnly;
        lf._operations['art'] = 'AND';

        this.search(lf, this.produkte, this.maxDisplayArtikel, value.queryString, null, value.component);
    }

    doSelect(produkt: Produkt) {
        this._value = produkt;
        if (!this.disabled) {
            this.valueChange.emit(produkt);
        }
    }

    public itemFormat: MESelectItemFormatterCallback<Produkt> = (item: Produkt, search) => {
        if (item === null) {
            return '';
        }
        return MEHighlitePipe.transformString(item.bezeichnung, search) +
            ' <span class=\'badge badge-default\'>' + MEHighlitePipe.transformString('' + item.nummer, search) + '</span>';
    }

    public itemText: MESelectItemTextCallback<Produkt> = (item: Produkt) => {
        if (item === null) {
            return '';
        }
        return item.bezeichnung + ' [' + item.nummer + ']';
    }

    public itemMatcher: MESelectItemMatcherCallback<Produkt> = (item: Produkt, search) => {
        if (!search) {
            return true;
        }
        if (search.trim() === '') {
            return true;
        }
        if (!item) {
            return false;
        }
        const x = item.bezeichnung + ' ' + item.nummer;

        return (x.toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) >= 0);
    }


}
