import {ChangeDetectorRef, Component, Injector} from '@angular/core';
import {LocalizedCellRenderer} from '../../commons/data-table/renderer/common-cell-renderer';
import {DataTableRow} from '../../commons/data-table/data-table-row';
import {DataTableColumnDirective} from '../../commons/data-table/data-table-column.directive';

import * as moment from 'moment';

@Component({
    selector: 'app-dtdevice-settings-renderer',
    template: `
        <span *ngIf="isDate()">{{ fDate(dateVal ,dateFormat) }}</span>
        <span *ngIf="isOther()">{{ value }}</span>
    `
})
export class DTDeviceSettingsRendererComponent extends LocalizedCellRenderer {
    cell: any = null;
    row: DataTableRow<any> = null;
    column: DataTableColumnDirective = null;

    value: any = '';
    type = 'string';
    dateFormat = 'medium';

    get dateVal(): Date {
        if (this.isDate()) {
            return this.value as Date;
        }
        return moment(this.value).toDate();
    }

    constructor(

                public cd: ChangeDetectorRef,
                public injector: Injector) {
        super( cd);
        this.cell = this.injector.get('cell');
        this.row = this.injector.get('row');
        this.column = this.injector.get('column');

        this.value = '';
        if (this.cell !== null) {
            if (Object.keys(this.cell).indexOf(this.column.subFieldName) >= 0) {
                this.value = this.cell[this.column.subFieldName];
            }
        }

        if (this.column.cfg !== null && this.column.cfg !== undefined) {
            this.type = ('' + this.column.cfg.type).toLowerCase();
            if (this.column.cfg.dateFormat !== null && this.column.cfg.dateFormat !== undefined) {
                this.dateFormat = '' + this.column.cfg.dateFormat;
            }
        }



    }


    isDate() {
        return 'date' === this.type;
    }

    isOther() {
        return !this.isDate();
    }

}
