<div class="card">
    <div class="card-header">
        <div class="d-flex justify-content-between align-items-center">
            <div>
                {{_('letzte Transaktionen')}}
            </div>
            <div>
                <a class="mr-2 btn btn-sm btn-light" role="button" (click)="transaktionen.reload()"><i class="far fa-sync"></i></a>
                <a class="btn btn-sm btn-light" role="button" routerLink="transaktionen"><i class="far fa-link"></i></a>
            </div>
        </div>
    </div>
    <div class="card-body">
        <table class="table">
            <thead>
            <tr>
                <th>{{_('Zeitpunkt')}}</th>
                <th>{{_('Station')}}</th>
                <th>{{_('Ausweis')}}</th>
                <th class="text-right" *ngIf="!sysInfoService.prefersBrutto()">{{_('Betrag')}}</th>
                <th class="text-right" *ngIf="sysInfoService.prefersBrutto()">{{_('Betrag (brutto)')}}</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let t of transaktionen.data">
                <td class="p1 text-nowrap">{{fDate(t.zeitpunkt, 'medium')}}</td>
                <td class="p1" title="{{t.station.bezeichnung}}">{{ shorten(t.station.bezeichnung,30) }}</td>
                <td class="p1">
                    <div *ngIf="t.isGCard()">{{ _('externe Zahlung (girocard etc.)') }}</div>
                    <div *ngIf="!t.isGCard() && t.ausweis1!==null">
                        <app-ausweis-quick-info [ausweis]="t.ausweis1"></app-ausweis-quick-info>
                    </div>
                    <div *ngIf="!t.isGCard() && t.ausweis2!==null">
                        <app-ausweis-quick-info [ausweis]="t.ausweis2"></app-ausweis-quick-info>
                    </div>
                <td class="p1 text-right" *ngIf="!sysInfoService.prefersBrutto()">{{fCurrency(t.b_betrag, 'EUR')}}</td>
                <td class="p1 text-right"
                    *ngIf="sysInfoService.prefersBrutto()">{{fCurrency(t.b_betrag_euro, 'EUR')}}</td>
            </tr>
            </tbody>
        </table>
    </div>
</div>
