import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {AnredeList} from './anrede-list';


import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {AnredeEditorComponent} from './anrede-editor.component';
import {Anrede} from './anrede';
import {LocalizedComponent} from '../components/localized-component';
import {KitchenTimer} from '../kitchen-timer';
import {SystemSettingsService} from '../system-settings.service';
import {ConfirmDialogComponent} from '../confirm-dialog/confirm-dialog.component';
import {NewPageCountEvent} from '../paginator/paginator.component';
import {ToastrService} from 'ngx-toastr';
import {IListResult} from '../list-result';
import {IAnrede} from './ianrede';
import {RowActionParams} from '../data-table/row-action-params';

@Component({
               selector   : 'app-tab-anreden',
               templateUrl: './tab-anreden.component.html',
               styleUrls  : ['./tab-anreden.component.css']
           })
export class TabAnredenComponent extends LocalizedComponent implements OnInit {

    anreden: AnredeList = new AnredeList();
    public anrTimer: KitchenTimer;

    constructor(
                
                public systemsettingsService: SystemSettingsService,
                public toastr: ToastrService,
                public modalService: NgbModal,
                public cd: ChangeDetectorRef
    ) {
        super( cd);
        this.anrTimer = new KitchenTimer();
        this.anrTimer.subscribe(() => {
            this.systemsettingsService
                .listAnreden(
                    this.anreden.size,
                    this.anreden.calcOffset(),
                    this.anreden.order,
                    this.anreden.simpleFilter
                )
                .subscribe(
                    (s: IListResult<IAnrede>) => {
                        this.anreden.populateFromListResult(s);
                    },
                    () => {
                        this.toastr.error(this._('Leider konnte die Liste der verfügbaren Anreden nicht geladen werden.'));
                        this.anreden.clear();
                    }
                );
        });
    }

    ngOnInit() {
        this.anrTimer.fireWhenNotBefore();
    }

    addAnrede() {
        AnredeEditorComponent
            .open(this.modalService, null)
            .then(
                e => {
                    this.updateAnreden(true);
                },
                () => {
                    this.updateAnreden(true);
                }
            );
    }

    editAnrede(event: RowActionParams<Anrede>) {
        AnredeEditorComponent
            .open(this.modalService, event.row.data as Anrede)
            .then(
                e => {
                    this.updateAnreden(true);
                },
                () => {
                    this.updateAnreden(true);
                }
            );
    }

    deleteAnrede(event: RowActionParams<Anrede>) {
        ConfirmDialogComponent.display(
            this.modalService,
            this.t('Anrede löschen'),
            this.t('Sollen die Anrede {{ anrede }} ' +
                   'wirklich unwideruflich gelöscht werden?', {anrede: event.row.data.anrede}),
            this.t('Anrede jetzt löschen')
        ).result
                              .then(
                                  e => {
                                      this.systemsettingsService
                                          .removeAnrede(event.row.data)
                                          .subscribe(
                                              r => {
                                                  if (r.success) {
                                                      this.toastr.success(this.t('Die Anrede wurde gelöscht.'));
                                                      this.updateAnreden(true);
                                                  } else {
                                                      this.toastr.error(this.t(
                                                          'Das Löschen der Anrede ist fehlgeschlagen'));
                                                      this.updateAnreden(true);
                                                  }
                                              },
                                              () => {
                                                  this.toastr.error(this.t('Das Löschen der Anrede ist fehlgeschlagen'));
                                                  this.updateAnreden(true);
                                              }
                                          );
                                  },
                                  k => {
                                  }
                              );
    }

    updateAnreden(force = false, page?: number) {
        if ((page !== undefined) && (page !== null)) {
            if (page != this.anreden.page) {
                this.anreden.page = page;
                force = true;
            }
        }
        this.anrTimer.start(force);
    }


    updateAnredenPageCount(n: NewPageCountEvent) {
        this.anreden.size = n.itemsPerPage;
        this.updateAnreden(true, n.currentPage);
    }


}
