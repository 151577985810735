import {ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HasRightDirective} from './directives/has-right.directive';
import {HasRoleDirective} from './directives/has-role.directive';
import {IsAdminDirective} from './directives/is-admin.directive';
import {IsLoggedInDirective} from './directives/is-logged-in.directive';
import {IsLoggedOutDirective} from './directives/is-logged-out.directive';
import {IsProjectAdminDirective} from './directives/is-project-admin.directive';
import {LoginAlertComponent} from './directives/login-alert.component';
import {LoggedInGuard} from './directives/logged-in-guard';
import {UserNameComponent} from './directives/user-name.component';
import {LoginService} from './services/login.service';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';

import {l10nConfig} from '../../l10nconfig';

@NgModule({
    imports: [
        CommonModule,
        NgbModule
    ],
    declarations: [
        HasRightDirective,
        HasRoleDirective,
        IsAdminDirective,
        IsLoggedInDirective,
        IsLoggedOutDirective,
        IsProjectAdminDirective,
        LoginAlertComponent,
        UserNameComponent
    ],
    exports: [
        HasRightDirective,
        HasRoleDirective,
        IsAdminDirective,
        IsLoggedInDirective,
        IsLoggedOutDirective,
        IsProjectAdminDirective,
        LoginAlertComponent,
        UserNameComponent
    ]

})
export class AuthModule {


}
