import {ResultMetaSortImpl} from './result-meta';
import {ISimpleFilter} from './simple-filter';
import {IListResult} from './list-result';
import {DataTableRow} from './data-table/data-table-row';

export interface IList<T> {
    simpleFilter: ISimpleFilter<T>;
}


export abstract class CommonList<T> implements IList<T> {

    simpleFilter: ISimpleFilter<T>;

    public searchString = '';
    public searchString2 = '';

    public _data: T[] = [];

    public total = 0;
    public offset = 0;
    public size = 10;
    public page = 0;
    public order: ResultMetaSortImpl[] = [];
    public dataTable: DataTableRow<T>[] = [];

    public clearData() {

        this.dataTable.forEach( (e) => {
            e.data = undefined;
        });
        for (let i = 0; i < this.dataTable.length; i++) {
            this.dataTable[i] = undefined;
        }
        for (let i = 0; i < this._data.length; i++) {
            this._data[i] = undefined;
        }

        this._data = [];
        this.dataTable = [];
    }

    public clear(clearSize = false) {
        this.total = 0;
        this.offset = 0;
        if (clearSize) {
            this.size = 10;
        }
        this.page = 0;
        this.order = [];
        this.clearData();
    }


    set data(v: T[]) {
        this.clearData();

        v.forEach(x => {
            this.add(x);
        });
    }

    get data(): T[] {
        return this._data;
    }


    public calcOffset(): number {
        return Math.max(0, this.size * (this.page - 1));
    }

    public asDataTableRows(): DataTableRow<T>[] {
        return this.dataTable;
    }

    public add(e: T) {
        if (e !== null && e !== undefined) {
            this._data.push(e);
            this.dataTable.push(new DataTableRow<T>(this._data[this._data.indexOf(e)]));
        }
    }

    public copyFrom(l: CommonList<T>) {

        this.clear();

        l.data.forEach(e => {
            this.add(e);
        });

        this.total = l.total;
        this.offset = l.offset;
        this.size = l.size;
        this.page = l.page;

        this.order = l.order;

    }

    public convertEntity(entity: any) {
        return entity;
    }

    public populateFromListResult(res: IListResult<any>, clearFilter = true) {
        this.doPopulateFromListResult(res, 'id', 'asc', clearFilter);
    }

    public doPopulateFromListResult(res: IListResult<any>,
                                    defaultSort: any = 'id',
                                    defaultOrder = 'asc',
                                    clearFilter = true) {
        this.clear();
        if (clearFilter) {
            this.simpleFilter.clear();
        }

        if (res.meta) {
            this.total = res.meta.total || 0;
            this.offset = res.meta.offset || 0;
            this.size = res.meta.size || 0;
            this.page = res.meta.page || 0;
            if (res.meta.order) {
                this.order = [];
                for (const x of res.meta.order) {
                    this.order.push(
                        new ResultMetaSortImpl(
                            x.field || defaultSort,
                            x.direction || defaultOrder
                        )
                    );
                }
            }
        }
        if (res.data) {
            for (const row of res.data) {
                this.add(this.convertEntity(row));
            }
        }
        if (res.simpleFilter) {
            if (this.simpleFilter !== null && this.simpleFilter !== undefined) {
                if (this.simpleFilter.fields !== null && this.simpleFilter.fields !== undefined) {
                    Object.keys(this.simpleFilter['fields']).forEach((f: string) => {
                        this.simpleFilter[f] = (res.simpleFilter[f] !== undefined) ? this.simpleFilter.fields[f](res.simpleFilter[f]) : null;
                    });
                } else {
                    for (const k of Object.keys(this.simpleFilter)) {
                        this.simpleFilter[k] = (res.simpleFilter[k] !== undefined) ? res.simpleFilter[k] : null;
                    }
                }
            }
        }
        if (res.searchString) {
            this.searchString = res.searchString;
        }
    }

    hasData(): boolean {
        return (Array.isArray(this._data) && (this._data.length > 0));
    }

    isEmpty() {
        return !this.hasData();
    }


    getQuery() {
        const parts: string[] = [];
        if (this.searchString !== null && this.searchString !== undefined && this.searchString.trim() !== '') {
            parts.push('(' + this.searchString + ')');
        }
        if (this.searchString2 !== null && this.searchString2 !== undefined && this.searchString2.trim() !== '') {
            parts.push('(' + this.searchString2 + ')');
        }
        return parts.join(' AND ');
    }
}
