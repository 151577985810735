import {ELimitType} from './elimit-type';
import {IOption, Option} from '../../../commons/dynamic-form/select-field/ioption';
import {METranslationCallback} from '../../../commons/metranslation-callback';

export class LimitType {
    static getIcon(limitType: ELimitType) {
        const icon = '';
        switch (limitType) {
            case ELimitType.RECURRING:
                break;
            case ELimitType.SINGLEHIT:
                break;
            case ELimitType.DEFAULTS:
                break;
        }
        return icon;
    }

    static options(translateFunc: METranslationCallback): IOption[] {
        const options: IOption[] = [
            Option.option(
                ELimitType.DEFAULTS,
                'Standard-Begrenzung',
                LimitType.getIcon(ELimitType.DEFAULTS)
            ),
            Option.option(
                ELimitType.SINGLEHIT,
                'einmalige Begrenzung',
                LimitType.getIcon(ELimitType.SINGLEHIT)
            ),
            Option.option(
                ELimitType.RECURRING,
                'wiederkehrende Begrenzung',
                LimitType.getIcon(ELimitType.RECURRING)
            )
        ];
        if (translateFunc !== null && translateFunc !== undefined) {
            options.forEach((v: IOption, idx) => {
                options[idx].label = translateFunc(v.label);
            });
        }
        return options;
    }
}
