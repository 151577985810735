import {ChangeDetectorRef, Component, NgZone, OnInit} from '@angular/core';
import {LocalizedComponent} from '../../commons/components/localized-component';


import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {NewPageCountEvent} from '../../commons/paginator/paginator.component';
import {PreisWertCellRendererComponent} from '../../commons/data-table/renderer/preis-wert-cell-renderer.component';
import {DataTableFilterChanged, DataTableSortChanged} from '../../commons/data-table/data-table.component';
import {PreisList} from '../common/preis-list';
import {KitchenTimer} from '../../commons/kitchen-timer';
import {PreisEditorComponent} from '../preis-editor/preis-editor.component';
import {ResultMetaSortImpl} from '../../commons/result-meta';
import {PreiseService} from '../services/preise.service';
import {StationCellRendererComponent} from '../../stationen/station-cell-renderer.component';
import {TerminalCellRendererComponent} from '../../stationen/terminal-cell-renderer.component';
import {ProduktCellRendererComponent} from '../../products/produkt-cell-renderer.component';
import {SysInfoService} from '../../commons/sysinfo/services/sys-info.service';
import {ToastrService} from 'ngx-toastr';
import {IListResult} from '../../commons/list-result';
import {IPreis} from '../common/ipreis';

@Component({
    selector: 'app-preise',
    templateUrl: './preise.component.html',
    styleUrls: ['./preise.component.css']
})
export class PreiseComponent extends LocalizedComponent implements OnInit {

    constructor(

                public sysInfoService: SysInfoService,
                public cd: ChangeDetectorRef
    ) {

        super( cd);

    }

    /**
     *
     */
    ngOnInit() {
        this.sysInfoService.updateDocumentTitle(this._('PreisPflege'));
    }
}
