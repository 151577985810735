<div class="modal-header">
  <h5 class="modal-title">{{ dlgTitle }}</h5>
  <button type="button" class="close" aria-label="Close" (click)="cancel()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body" [innerHTML]="message">
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-secondary" (click)="cancel()">
    <i class="icofont icofont-close"></i> {{ cancellabel }}
  </button>
  <button type="button" class="btn btn-primary" (click)="ok()">
    <i class="{{ okIcon }}"></i>
    {{ oklabel }}
  </button>
</div>
