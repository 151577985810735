import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {LocalizedComponent} from '../components/localized-component';
import {NgbActiveModal, NgbModal, NgbModalOptions, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {Anrede} from './anrede';

import {SystemSettingsService} from '../system-settings.service';
import {ToastrService} from 'ngx-toastr';


@Component({
    selector: 'app-anrede-editor',
    templateUrl: './anrede-editor.component.html',
    styleUrls: ['./anrede-editor.component.css']
})
export class AnredeEditorComponent extends LocalizedComponent implements OnInit {
    entity: Anrede = new Anrede();
    mode: any = 'new';

    static open(modalService: NgbModal, anrede?: Anrede): Promise<any> {
        const o: NgbModalOptions = {
            /* size: 'lg',*/
            backdrop: 'static'
        };
        const r: NgbModalRef = modalService.open(AnredeEditorComponent, o);
        (r.componentInstance as AnredeEditorComponent).open(anrede);
        return r.result;
    }


    constructor(
                
                public activeModal: NgbActiveModal,
                public toastr: ToastrService,
                public systemsettingsService: SystemSettingsService,
                public cd: ChangeDetectorRef) {
        super( cd);
    }


    open(anrede?: Anrede) {
        if ((anrede === null) || (anrede === undefined)) {
            this.entity = new Anrede();
            this.mode = 'new';
        } else {
            this.entity = Anrede.fromResult(anrede);
            this.mode = 'edit';
        }
    }

    isAdd() {
        return this.mode === 'new';
    }

    isEdit() {
        return this.mode === 'edit';
    }

    ngOnInit() {
    }

    cancel() {
        this.activeModal.dismiss();
    }

    save() {
        this.systemsettingsService
            .storeAnrede(this.entity, this.isAdd())
            .subscribe(
                r => {
                    if (r.success) {
                        this.toastr.success(this._('Anrede erfolgreich gespeichert'));
                        this.activeModal.close(this.entity);
                    } else {
                        this.toastr.success(this.t('Fehler beim Sichern der Anrede'));
                    }
                }
            );
    }
}
