import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {LocalizedComponent} from '../../commons/components/localized-component';
import {Geraet} from '../common/geraet';

import {EDeviceType} from '../common/edevice-type.enum';

@Component({
  selector: 'app-device-staubsauger-details-editor',
  templateUrl: './device-staubsauger-details-editor.component.html',
  styleUrls: ['./device-staubsauger-details-editor.component.scss']
})
export class DeviceStaubsaugerDetailsEditorComponent extends LocalizedComponent implements OnInit {
    private _device: Geraet = null;

    @Input()
    set device(dev: Geraet) {
        if (this._device !== dev) {
            this._device = dev;
            this.cd.markForCheck();
        }
    }


    get device(): Geraet {
        return this._device;
    }

    @Output()
    onAction: EventEmitter<string> = new EventEmitter<string>();

    dTCoinAcceptor = EDeviceType.MECOINACCEPTORUNIT;

    constructor(

                public cd: ChangeDetectorRef) {
        super( cd);
    }

  ngOnInit() {
  }

  sendAction(action: string) {
        this.onAction.emit(action);
  }

}
