import {ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PreiseComponent} from './preise/preise.component';

import {CommonsModule} from '../commons/commons.module';
import {l10nConfig} from '../../l10nconfig';
import {PreisEditorComponent} from './preis-editor/preis-editor.component';
import {PreiseService} from './services/preise.service';
import {AuthModule} from '../auth/auth.module';
import {StationenModule} from '../stationen/stationen.module';
import {PreisgruppeFieldComponent} from './preisgruppe-field/preisgruppe-field.component';
import { PreisgruppeInlineFieldComponent } from './preisgruppe-inline-field/preisgruppe-inline-field.component';
import { PreisgruppeArtFieldComponent } from './preisgruppe-art-field/preisgruppe-art-field.component';
import { TabPreisgruppenComponent } from './tab-preisgruppen/tab-preisgruppen.component';
import { TabPreiseComponent } from './tab-preise/tab-preise.component';
import { PreisgruppeEditorComponent } from './preisgruppe-editor/preisgruppe-editor.component';
import { PreiseFilterDialogComponent } from './tab-preise/preise-filter-dialog/preise-filter-dialog.component';
import {ProductsModule} from '../products/products.module';

const components = [
    PreiseComponent,
    PreisEditorComponent,
    PreisgruppeFieldComponent,
    PreisgruppeInlineFieldComponent,
    PreisgruppeArtFieldComponent,
    TabPreisgruppenComponent,
    TabPreiseComponent,
    PreisgruppeEditorComponent,
    PreiseFilterDialogComponent
];

@NgModule({
    imports: [
        CommonModule,
        CommonsModule,
        AuthModule,
        StationenModule,
        ProductsModule

    ],
    declarations: [
        ...components

    ],
    exports: [
        ...components
    ],
    entryComponents: [
        ...components
    ]
})
export class PricingModule {

}
