import {EPreisgruppeArt} from './epreisgruppe-art.enum';
import {IOption, Option} from '../../commons/dynamic-form/select-field/ioption';
import {METranslationCallback} from '../../commons/metranslation-callback';

export class PreisgruppeArt {

    static getIcon(preisgruppeArt: EPreisgruppeArt): string {
        let icon = '';
        switch (preisgruppeArt) {
            case EPreisgruppeArt.ZUSCHLAG:
                icon = 'me-icons me-icon-plusminus';
                break;
            case EPreisgruppeArt.FACTOR:
                icon = 'far fa-astrisk';
                break;
            case EPreisgruppeArt.FESTLEGUNG:
                icon = 'far fa-arrow-to-right';
                break;
            case EPreisgruppeArt.UNCHANGED:
                icon = 'far fa-equals';
                break;
        }
        return icon;
    }

    static options(translateFunc: METranslationCallback= undefined): IOption[] {
        const options: IOption[] = [
            Option.option(
                EPreisgruppeArt.UNKNOWN,
                'Unbekannter Typ/Sonstige',
                PreisgruppeArt.getIcon(EPreisgruppeArt.UNKNOWN)
            ),
            Option.option(
                EPreisgruppeArt.UNCHANGED,
                'keine Veränderung',
                PreisgruppeArt.getIcon(EPreisgruppeArt.UNCHANGED)
            ),
            Option.option(
                EPreisgruppeArt.ZUSCHLAG,
                'Zu-/Abschlag',
                PreisgruppeArt.getIcon(EPreisgruppeArt.ZUSCHLAG)
            ),
            Option.option(
                EPreisgruppeArt.FACTOR,
                'Faktor',
                PreisgruppeArt.getIcon(EPreisgruppeArt.FACTOR)
            ),
            Option.option(
                EPreisgruppeArt.FESTLEGUNG,
                'Festlegung',
                PreisgruppeArt.getIcon(EPreisgruppeArt.FESTLEGUNG)
            )
        ];
        if (translateFunc !== null && translateFunc !== undefined) {
            options.forEach((v: IOption, idx) => {
                options[idx].label = translateFunc(v.label);
            });
        }
        return options;
    }

}
