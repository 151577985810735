import {ChangeDetectorRef, Component, Injector} from '@angular/core';

import {DataTableRow} from '../data-table-row';
import {LocalizedCellRenderer} from './common-cell-renderer';
import {DataTableColumnDirective} from '../data-table-column.directive';
import {ILand} from '../../laender/common/iland';

@Component({
               selector: 'land-cell-renderer',
               template: `
                   <i class="{{ getIcon() }}"
                      title="{{ (column.hasTitle()?column.displayTitle(row):getName()) }}"
                   ></i> {{ (column.hasTitle() ? column.displayTitle(row) : getName()) }}
               `
           })
export class LandCellRendererComponent extends LocalizedCellRenderer {

    cell: ILand             = null;
    row: DataTableRow<any>  = null;
    column: DataTableColumnDirective = null;

    constructor(
                
                public injector: Injector,
                public cd:ChangeDetectorRef) {
        super(cd);
        this.cell   = this.injector.get('cell');
        this.row    = this.injector.get('row');
        this.column = this.injector.get('column');
    }

    getIcon() {
        if (this.cell !== null && this.cell !== undefined) {
            return 'flag-icon flag-icon-squared flag-icon-' + this.cell.laendercode.toLowerCase();
        }
        return 'icofont icofont-question';
    }

    getName() {
        if (this.cell !== null && this.cell !== undefined) {
            return this.cell.bezeichnung;
        }
        return this.t('nicht angegeben');

    }


}
