import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';

import {Kunde} from '../common/kunde';
import {LocalizedComponent} from '../../../commons/components/localized-component';

@Component({
    selector: 'app-kunde-display',
    templateUrl: './kunde-display.component.html',
    styleUrls: ['./kunde-display.component.css']
})
export class KundeDisplayComponent extends LocalizedComponent implements OnInit {

    kundeLabel = 'Kunde {{ nummer }}';
    _kunde: Kunde = null;

    @Input()
    set kunde(value: Kunde) {
        this._kunde = Kunde.fromResult(value);
    }

    constructor(  public cd: ChangeDetectorRef) {
        super( cd);
    }

    ngOnInit() {
    }

    getTitleOptions() {
        return {nummer: this._kunde.nummer};
    }
}
