<div class="modal-header">
    <h4 class="modal-title">{{ _('Transaktionen importieren') }}</h4>
    <button type="button" class="close" aria-label="Close" (click)="cancel()">
        <span aria-hidden="true">&times;</span>
    </button>

</div>
<div class="modal-body">
    <div class="flex-row d-flex justify-content-between">
        <div class="p-2 w-40">
            <app-tabset>
                <app-pane [title]="_('Datei')">
                    <input
                            type="file"
                            (change)="fileSelect($event.target.files)"
                            placeholder="{{ _('Datei auswählen')}}"
                    />
                    <div class="text-center"><em>{{_('oder')}}</em></div>
                    <div fileDropZone class="my-drop-zone p-4"
                         (gotFiles)="fileSelect($event)"
                    >
                        {{ _('Datei hier ablegen') }}
                    </div>
                    <div class="info p-2 d-flex flex-row justify-content-start" *ngIf="entity.file!==null">
                        <div>{{ _('ausgewählt:') }}&nbsp;</div>
                        <div title="{{ entity?.file?.name }}"><em>{{ shorten(entity?.file?.name, 45) }}</em></div>
                    </div>
                </app-pane>
            </app-tabset>
            <import-file-type-field [label]="_('Dateityp')" [(value)]="entity.type"></import-file-type-field>
        </div>
        <div class="p-2 w-60">

            <app-tabset>
                <app-pane [title]="_('Optionen')">
                    <boolean-field
                            [label]="_('fehlende Stationen automatisch anlegen')"
                            [labelwidth]="7"
                            [(value)]="entity.createStations"></boolean-field>
                    <boolean-field
                            [label]="_('fehlende Ausweise automatisch anlegen')"
                            [labelwidth]="7"
                            [(value)]="entity.createCards"></boolean-field>
                    <boolean-field
                            [label]="_('fehlende Produkte automatisch anlegen')"
                            [labelwidth]="7"
                            [(value)]="entity.createProducts"></boolean-field>
                </app-pane>
                <app-pane [title]="_('Stationsumschreibungen')">
                    {{ _('Stationen gemäß dieser Liste umschreiben') }}
                    <data-table
                            [data]="entity._stationsRewrites"
                            [selectable]="false"
                            [singleselect]="true"
                            [hideCheckboxes]="true"
                            [(newEntity)]="newRewrite"
                            (saveRecord)="saveRewrite($event)"
                            #stationRewritesTable
                            [rowActionsAsButtons]="true"
                    >
                        <tableaction
                                [label]="_('Umschreibung hinzufügen')"
                                [icon]="'icofont icofont-ui-add'"
                                (perform)="addRewrite()"
                        ></tableaction>
                        <column
                                [label]="_('Wert')"
                                [field]="'source'"
                        ></column>
                        <column
                                [label]="_('Terminal')"
                                [field]="'destination'"
                                [renderer]="terminalR"
                        ></column>
                        <rowaction
                                [label]="_('Löschen')"
                                [icon]="'icofont icofont-ui-delete'"
                                (perform)="deleteRewrite($event)"
                        ></rowaction>
                    </data-table>

                </app-pane>
                <app-pane [title]="_('VDAF-Einstellungen')" *ngIf="entity.isVDAF()">
                    <boolean-field
                            [label]="_('Zwei-Karten-System')"
                            [labelwidth]="7"
                            [(value)]="entity.vdaf_two_card_system"></boolean-field>
                </app-pane>
            </app-tabset>
        </div>
    </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="cancel()">
        <i class="icofont icofont-close"></i> {{ _('Schließen') }}
    </button>
    <button type="button" class="btn btn-primary" (click)="doImport()" [disabled]="!canImport()">
        <i class="icofont icofont-upload-alt"></i> {{ _('Import durchführen') }}
    </button>
</div>
