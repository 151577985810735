import {Nachricht} from './nachricht';
import {INachricht} from './inachricht';
import {GenericFilter} from '../../../commons/generic-filter';

export class SimpleNachrichtFilter extends GenericFilter<Nachricht> implements INachricht {
    prioritaet: any = null;
    id: number = null;
    subject: string = null;
    message: string = null;
    params: any = null;
    dateCreated: any = null;
    dateRead: any = null;
    dateExpires: any = null;
}
