<div class="modal-header">
    <h5 class="modal-title">{{ dlgTitle }}</h5>

    <button type="button" class="close" aria-label="Close" (click)="cancel()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">

    <div class="d-flex justify-content-between">
        <div>
            <div class="btn-group-sm btn-group btn-group-toggle" data-toggle="buttons">
                <label class="btn btn-secondary" [class.active]="mode==0" (click)="setMode(0)">
                    <input type="radio" name="options" id="option1" autocomplete="off" checked>
                    <i class="far fa-table"></i> {{_('Tabelle')}}
                </label>
                <label class="btn btn-secondary" [class.active]="mode==1" (click)="setMode(1)">
                    <input type="radio" name="options" id="option3" autocomplete="off">
                    <i class="far fa-chart-line"></i> {{_('Grafik')}}
                </label>
            </div>
        </div>
        <div>
            <app-paginator
                    *ngIf="mode==0"
                    [total]="buchungen.total"
                    [page]="buchungen.page"
                    [perpage]="buchungen.size"
                    (pageChange)="updateBuchungen(true,$event)"
                    (pageCountChange)="updateBuchungenPageCount($event)"
                    [showSearch]="true"
                    (queryChange)="sondenBuchungenQueryChange($event)"
            ></app-paginator>
        </div>
    </div>
    <div>
        <app-sonden-buchung-filter
                #bgFilter
                (filterChanged)="onFilterChanged($event)"
        ></app-sonden-buchung-filter>
    </div>
    <div *ngIf="mode==0">
        <data-table [selectable]="false"
                    [data]="buchungen.asDataTableRows()"
                    [rowActionsAsButtons]="true"
                    #buchungenTable
                    [extraRowClasses]="sondenbuchungRowClassGetter"
        >
            <column [field]="'zeitpunkt'"
                    [label]="_('Zeitpunkt')"
                    type="Date"
                    [cfg]="{format:'medium'}"
            ></column>
            <column
                    *ngIf="sonde.hasSumpf"
                    [field]="'sumpf'"
                    [label]="t('Sumpf-Höhe')"
                    type="Decimal"
                    [cfg]="{fdigits:0,addon:'mm'}"
            ></column>
            <column
                    *ngIf="sonde.hasHoehe"
                    [field]="'hoehe'"
                    [label]="t('Höhe')"
                    type="Decimal"
                    [cfg]="{fdigits:0,addon:'mm'}"
            ></column>
            <column
                    [field]="'menge'"
                    [label]="t('Menge')"
                    [renderer]="literR"
                    [cfg]="{fdigits:0}"
            ></column>
            <column
                    *ngIf="sonde.hasTemperatur"
                    [field]="'temperatur'"
                    [label]="t('Temperatur')"
                    type="Decimal"
                    [cfg]="{fdigits:0,addon:'°C'}"
            ></column>
            <column
                    [field]="'failed'"
                    [label]="t('Fehlerbuchung')"
                    [type]="'boolean'"
            ></column>
        </data-table>

    </div>
    <div *ngIf="mode==1" class="chart">
        <app-sonden-buchungen-diagramm
                #diagramm
                [sonde]="sonde"
                [sondenData]="buchungen"
                (needsData)="diagrammNeedsData($event)"
        ></app-sonden-buchungen-diagramm>
    </div>

</div>
<div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="cancel()">
        <i class="icofont icofont-close"></i> {{ _('Schließen') }}
    </button>
</div>

