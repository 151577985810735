import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {LocalizedComponent} from '../../commons/components/localized-component';
import {Station} from '../../stationen/common/station';
import {NgbActiveModal, NgbModal, NgbModalOptions, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';

import {IStation} from '../../stationen/common/istation';
import {GeraetList} from '../common/geraet-list';
import {NewPageCountEvent} from '../../commons/paginator/paginator.component';
import {EDeviceType} from '../common/edevice-type.enum';
import {DeviceType} from '../common/device-type';
import {Geraet} from '../common/geraet';
import {MEVideoControlSettings} from '../common/mevideo-control-settings';
import {LoginService} from '../../auth/services/login.service';
import {DeviceService} from '../common/device.service';
import {ActionResponse, IActionResponse} from '../../commons/action-response';
import {ToastrService} from 'ngx-toastr';
import {DataTableRowSelected} from '../../commons/data-table/data-table.component';
import {IListResult} from '../../commons/list-result';
import {IGeraet} from '../common/igeraet';
import {DTDeviceTypeRendererComponent} from '../dtdevice-type-renderer/dtdevice-type-renderer.component';
import {KitchenTimer} from '../../commons/kitchen-timer';

@Component({
    selector: 'app-geraete-dialog',
    templateUrl: './geraete-dialog.component.html',
    styleUrls: ['./geraete-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class GeraeteDialogComponent extends LocalizedComponent implements OnInit, OnDestroy {
    dts = DeviceType.options((s) => {
        return this._(s);
    });

    deviceTypeR = DTDeviceTypeRendererComponent;


    public station: Station;
    public geraete: GeraetList = null;

    public editing = false;

    public currentDevice: Geraet = null;

    public geraeteRefreshTimer: KitchenTimer = null;


    get msg_title() {
        if (this.station === null || this.station === undefined) {
            return this._('sonstige Geräte und Anlagen');
        }
        return this._('sonstige Geräte und Anlagen an Station {{name}}({{nummer}})', {
            name: this.station.bezeichnung,
            nummer: this.station.nummer
        });

    }

    get deviceTitle() {
        if (this.currentDevice === null || this.currentDevice === undefined) {
            return '';
        }

        return DeviceType.getTitle(this.currentDevice.typ);
    }

    static open(modalService: NgbModal, station: IStation): Promise<any> {
        const o: NgbModalOptions = {
            size: 'lg',
            backdrop: 'static'
        };
        const r: NgbModalRef = modalService.open(GeraeteDialogComponent, o);
        (r.componentInstance as GeraeteDialogComponent).open(station);
        return r.result;
    }

    constructor(

                public cd: ChangeDetectorRef,
                public activeModal: NgbActiveModal,
                public loginService: LoginService,
                public deviceService: DeviceService,
                public toastr: ToastrService
    ) {
        super( cd);
        this.geraeteRefreshTimer = new KitchenTimer(2500);
        this.geraeteRefreshTimer.meta['tries'] = 50;
        this.geraeteRefreshTimer.subscribe(
            () => {
                if (this.geraeteRefreshTimer.meta['tries'] > 0) {
                    this.geraeteRefreshTimer.meta['tries'] = this.geraeteRefreshTimer.meta['tries'] - 1;
                    this.updateGeraete();
                    if (this.geraeteRefreshTimer.meta['tries'] > 0) {
                        this.geraeteRefreshTimer.start();
                    }
                }
            }
        );
    }

    open(station: IStation) {
        this.station = Station.fromResult(station);
        this.geraete = new GeraetList();
        this.geraete
            .onUpdateRequired
            .subscribe(
                (list: GeraetList) => {
                    this.deviceService
                        .listDevices(
                            this.station,
                            list.size,
                            list.calcOffset(),
                            list.order,
                            list.simpleFilter,
                            list.getQuery()
                        )
                        .subscribe(
                            (l: IListResult<IGeraet>) => {
                                list.populateFromListResult(l);

                                this.cd.markForCheck();
                            },
                            () => {
                                this.toastr.error(this._('Fehler beim Laden der verfügbaren Geräte und Anlagen'));
                            }
                        );
                }
            );
        this.updateGeraete(false, 1);
    }


    ngOnInit() {

    }

    ngOnDestroy() {
        if (this.geraete !== null) {
            this.geraete.clear();
            this.geraete = null;
        }
    }

    cancel() {
        this.activeModal.dismiss();
    }

    updateGeraete(force = false, page?: number) {
        if ((page !== undefined) && (page != null)) {
            if (page !== this.geraete.page) {
                this.geraete.page = page;
                force = true;
            }
        }
        this.geraete.start();
    }

    /**
     * ändert die Anzahl der Datensätze/Seite und lädt die Liste neu
     * @param n
     */
    updateGeraetePageCount(n: NewPageCountEvent) {
        if (this.geraete.size !== n.itemsPerPage) {
            this.geraete.size = n.itemsPerPage;
            this.updateGeraete(true, n.currentPage);
        }
    }

    addDevice(type: EDeviceType) {
        this.geraeteRefreshTimer.meta['tries'] = -1;
        this.geraeteRefreshTimer.stop();
        this.currentDevice = Geraet.byType(type);
        this.editing = true;
        this.cd.markForCheck();
    }

    canAdd() {
        if (this.editing) {
            return false;
        }
        if (this.loginService.hasOneOfRoles(['ROLE_ADMIN', 'ROLE_PROJECT_ADMIN'])) {
            return true;
        }
        return false;
    }

    canSave() {
        if (this.currentDevice !== null && this.currentDevice !== undefined) {
            if (this.currentDevice.nummer === null || this.currentDevice.nummer < 1) {
                return false;
            }
            if (this.currentDevice.bezeichnung === null || this.currentDevice.bezeichnung.trim() === '') {
                return false;
            }
            switch (DeviceType.byValue(this.currentDevice.typ)) {
                case EDeviceType.MEVIDEOCONTROL:
                    const s = (this.currentDevice.settings as MEVideoControlSettings);
                    if (s === null || s === undefined) {
                        return false;
                    }
                    if (s.ip === null || s.ip === undefined || s.ip === '' || s.ip.match(/^[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}$/i) === null) {
                        return false;
                    }
                    if (s.port === null || s.port === undefined || isNaN(parseInt('' + s.port, 10)) || s.port < 1) {
                        return false;
                    }
                    if (s.terminal === null || s.terminal === undefined) {
                        return false;
                    }
                    if (s.wifi_ssid === null || s.wifi_ssid === undefined || s.wifi_ssid.trim().length < 4) {
                        return false;
                    }
                    if (s.wifi_key === null || s.wifi_key === undefined || s.wifi_key.trim().length < 9 || s.wifi_key.trim().length > 63) {
                        return false;
                    }
                    return true;
                    break;
                default:
                    return true;
            }
        }
        return false;
    }

    save() {
        if (this.canSave()) {
            if (this.currentDevice.isMEVideoControl()) {
                if ((this.currentDevice.settings as MEVideoControlSettings).img_text_left === null ||
                    (this.currentDevice.settings as MEVideoControlSettings).img_text_left === undefined ||
                    (this.currentDevice.settings as MEVideoControlSettings).img_text_left.trim() === '') {
                    (this.currentDevice.settings as MEVideoControlSettings).img_text_left = this.station.bezeichnung;
                }
            }
            this.deviceService
                .storeDevice(
                    this.station,
                    this.currentDevice
                )
                .subscribe(
                    (rr: IActionResponse) => {
                        const r = ActionResponse.fromRawActionResponse(rr);
                        if (r.success) {
                            this.cancelEdit();
                        } else {
                            this.toastr.error(this._('Fehler beim Speichern des Gerätes'));
                        }
                    },
                    () => {
                        this.toastr.error(this._('Fehler beim Speichern des Gerätes'));
                    }
                );
        }
    }

    cancelEdit() {
        this.editing = false;
        this.updateGeraete();
        this.cd.markForCheck();
    }

    geraetSelected($event: DataTableRowSelected<Geraet>) {
        if (!this.editing) {
            if ($event !== null) {
                this.geraeteRefreshTimer.meta['tries'] = -1;
                this.geraeteRefreshTimer.stop();
                this.currentDevice = Geraet.fromResult($event.row.data);
                this.cd.markForCheck();
            }
        }
    }

    editDevice() {
        if (this.currentDevice !== null && this.currentDevice !== undefined) {
            this.geraeteRefreshTimer.meta['tries'] = -1;
            this.geraeteRefreshTimer.stop();
            if (this.currentDevice.isMEVideoControl()) {
                if ((this.currentDevice.settings as MEVideoControlSettings).img_text_left === null ||
                    (this.currentDevice.settings as MEVideoControlSettings).img_text_left === undefined ||
                    (this.currentDevice.settings as MEVideoControlSettings).img_text_left.trim() === '') {
                    (this.currentDevice.settings as MEVideoControlSettings).img_text_left = this.station.bezeichnung;
                }
            }
            console.log(this.currentDevice.settings);
            this.editing = true;
            this.cd.markForCheck();
        }
    }

    removeDevice() {
        if (this.currentDevice !== null && this.currentDevice !== undefined && !this.editing) {
            this.deviceService.removeDevice(
                this.station,
                this.currentDevice
            ).subscribe(
                (rr: IActionResponse) => {
                    const r = ActionResponse.fromRawActionResponse(rr);
                    if (r.success) {
                        this.toastr.success(this._('Das Gerät bzw. die Anlage wurde entfernt'));
                        this.currentDevice = null;
                        this.cd.markForCheck();
                        this.updateGeraete();
                    } else {
                        this.toastr.error(this._('Fehler beim Löschen des Geräts bzw. der Anlage'));
                    }
                },
                () => {
                    this.toastr.error(this._('Fehler beim Löschen des Geräts bzw. der Anlage'));
                }
            );


            this.cd.markForCheck();
        }
    }

    sendConfig() {
        if (this.currentDevice.isMEVideoControl()) {
            this.toastr.info(this._('Es wird nun versucht, die aktuelle Konfiguration an das Kamerasystem zu senden. Der Vorgang dauert einen Moment...'));

            this.deviceService
                .sendMEVCConfig(
                    this.currentDevice.station,
                    this.currentDevice
                )
                .subscribe(
                    (rr: IActionResponse) => {
                        const r = ActionResponse.fromRawActionResponse(rr);
                        if (r.success) {
                            this.toastr.success(this._('Die Konfiguration wurde an das Kamerasystem gesendet. ' +
                                'Etwaige Änderungen an der WiFi-Konfiguration werden bei der nächsten vollständigen ' +
                                'WiFi-Aktivierung aktiv.'
                                )
                            );
                        } else {
                            this.toastr.error(this._('Beim Senden der Konfigurationsdaten ist ein Fehler aufgetreten.'));
                        }
                    },
                    () => {
                        this.toastr.error(this._('Beim Senden der Konfigurationsdaten ist ein Fehler aufgetreten.'));
                    }
                );

        }
        if (this.currentDevice.isMELoragateway()) {
            this.toastr.info(this._('Es wird nun versucht, die aktuelle Konfiguration an das ME LoRa Gateway zu senden. Der Vorgang dauert einen Moment...'));

            this.deviceService
                .sendMELoraGatewayConfig(
                    this.currentDevice.station,
                    this.currentDevice
                )
                .subscribe(
                    (rr: IActionResponse) => {
                        const r = ActionResponse.fromRawActionResponse(rr);
                        if (r.success) {
                            this.toastr.success(this._('Die Konfiguration wurde an das ME LoRa Gateway übertragen.'
                                )
                            );
                        } else {
                            this.toastr.error(this._('Beim Senden der Konfigurationsdaten ist ein Fehler aufgetreten.'));
                        }
                    },
                    () => {
                        this.toastr.error(this._('Beim Senden der Konfigurationsdaten ist ein Fehler aufgetreten.'));
                    }
                );

        }
    }

    discovery() {
        if (this.currentDevice.isMELoragateway()) {
            this.toastr.info(this._('Es wird nun versucht, einen Suchauftrag an das ME LoRa Gateway zu senden. Der Vorgang dauert einen Moment...'));

            this.deviceService
                .sendMELoraGatewayDiscoveryRequest(
                    this.currentDevice.station,
                    this.currentDevice
                )
                .subscribe(
                    (rr: IActionResponse) => {
                        const r = ActionResponse.fromRawActionResponse(rr);
                        if (r.success) {
                            this.toastr.success(this._('Der Suchauftrag wurde an das ME LoRa Gateway übertragen.'
                                )
                            );
                            this.geraeteRefreshTimer.meta['tries'] = 25;
                            this.geraeteRefreshTimer.start();

                        } else {
                            this.toastr.error(this._('Beim Senden des Suchauftrages ist ein Fehler aufgetreten.'));
                        }
                    },
                    () => {
                        this.toastr.error(this._('Beim Senden des Suchauftrages ist ein Fehler aufgetreten.'));
                    }
                );

        }
    }


    pairing() {
        if (this.currentDevice.isMECoinAcceptorUnit()) {
            this.toastr.info(this._('Es wird nun versucht, das Gerät mit dem Gateway zu verbinden. Der Vorgang dauert einen Moment...'));

            this.deviceService
                .sendMELoraGatewayPairingRequest(
                    this.currentDevice.station,
                    this.currentDevice
                )
                .subscribe(
                    (rr: IActionResponse) => {
                        const r = ActionResponse.fromRawActionResponse(rr);
                        if (r.success) {
                            this.toastr.success(this._('Das Pairing wurde ausgelöst.'));

                        } else {
                            this.toastr.error(this._('Beim Senden des Suchauftrages ist ein Fehler aufgetreten.'));
                        }
                    },
                    () => {
                        this.toastr.error(this._('Beim Senden des Suchauftrages ist ein Fehler aufgetreten.'));
                    }
                );

        }
    }



    performAction(action: string) {
        if (action === 'sendConfig') {
            this.sendConfig();
        } else if (action === 'discovery') {
            this.discovery();
        } else if (action === 'pairing') {
            this.pairing();
        } else {
            this.toastr.error(this._('Die Aktion {{aktion}} ist nicht bekannt', {aktion: action}));
        }
    }


}
