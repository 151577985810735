import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';

import {Nachbuchung} from '../common/nachbuchung';
import {NgbActiveModal, NgbModal, NgbModalOptions, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {Subscription} from 'rxjs';
import {LocalizedComponent} from '../../../commons/components/localized-component';
import {SysInfoService} from '../../../commons/sysinfo/services/sys-info.service';
import {LoginService} from '../../../auth/services/login.service';
import {Station} from '../../../stationen/common/station';
import {Waehrung} from '../../../commons/waehrungen/waehrung';
import {Ausweis} from '../../../customers/ausweise/common/ausweis';
import {NachbuchungDetail} from '../common/nachbuchung-detail';
import {TransaktionService} from '../common/transaktion.service';
import {IActionResponse} from '../../../commons/action-response';
import {ToastrService} from 'ngx-toastr';
import {PubSubService} from '../../../commons/pub-sub.service';

@Component({
    selector: 'app-nachbuchung-editor',
    templateUrl: './nachbuchung-editor.component.html',
    styleUrls: ['./nachbuchung-editor.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class NachbuchungEditorComponent extends LocalizedComponent implements OnInit, OnDestroy {


    dlgTitle = 'Nachbuchung {{nummer}} bearbeiten';


    nettoErfassung = false;
    euroDetails = false;
    posDetails = false;

    public entity: Nachbuchung = new Nachbuchung();
    mode: any = 'new';
    isOnline = false;
    sub: Subscription = null;
    loginSub: Subscription = null;

    static open(modalService: NgbModal, nachbuchung?: Nachbuchung): Promise<any> {
        const o: NgbModalOptions = {
            size: 'lg',
            backdrop: 'static'
        };
        const r: NgbModalRef = modalService.open(NachbuchungEditorComponent, o);
        (r.componentInstance as NachbuchungEditorComponent).open(nachbuchung);
        return r.result;
    }

    constructor(
                
                public sysinfo: SysInfoService,
                public loginService: LoginService,
                public activeModal: NgbActiveModal,
                public toastr: ToastrService,
                public cd: ChangeDetectorRef,
                public transaktionService: TransaktionService,
                public pubsub: PubSubService
    ) {
        super( cd);
    }

    public open(nachbuchung?: Nachbuchung) {
        if (nachbuchung === null || nachbuchung === undefined) {
            this.entity = new Nachbuchung();
            this.addNachbuchungDetail();
            this.mode = 'new';
        } else {
            this.entity = Nachbuchung.fromResult(nachbuchung);
            this.mode = 'edit';
        }

        if (!this.loginService.isLoggedIn()) {
            this.cancel();
        }

        if (!this.loginService.hasOneOfRoles(['ROLE_ADMIN', 'ROLE_PROJECT_ADMIN', 'ROLE_BILLING'])) {
            this.cancel();
        }

    }

    isNew(): boolean {
        return this.mode === 'new';
    }

    isEdit(): boolean {
        return this.mode === 'edit';
    }

    ngOnInit() {
        this.sub = this.pubsub.onlineStateChange.subscribe(
            (o) => {
                this.isOnline = o;
                this.cd.markForCheck();
            }
        );
        this.loginSub = this.pubsub.loginStateChange.subscribe(
            () => {
                if (!this.loginService.isLoggedIn()) {
                    this.cancel();
                }

                if (!this.loginService.hasOneOfRoles(['ROLE_ADMIN', 'ROLE_PROJECT_ADMIN', 'ROLE_BILLING'])) {
                    this.cancel();
                }
            },
            () => {
                this.cancel();
            }
        );
    }

    ngOnDestroy() {
        this.sub.unsubscribe();
    }

    cancel() {
        this.activeModal.close();
    }

    canSave(): boolean {
        if (!this.sysinfo.isOnline) {
            return false;
        }


        if (this.entity.details.length < 1) {
            return false;
        }

        for (let i = 0; i < this.entity.details.length; i++) {
            if (this.entity.details[i].produkt === null || this.entity.details[i].produkt === undefined) {
                return false;
            }
            if (this.entity.details[i].menge ===
                null ||
                this.entity.details[i].menge ===
                undefined ||
                this.entity.details[i].menge ===
                0) {
                return false;
            }
        }

        return true;
    }

    save() {
        this
            .transaktionService
            .storeNachbuchung(this.entity, this.isNew())
            .subscribe((r: IActionResponse) => {
                    if (r.success) {
                        this.toastr.success(this.t('Die Nachbuchung wurde erfasst und wird in Kürze verbucht.'));
                        this.activeModal.close();
                    } else {
                        this.toastr.success(this.t('Fehler beim Erfassen der Nachbuchung'));
                    }
                },
                (e) => {
                    // console.log(e);
                    this.toastr.success(this.t('Fehler beim Erfassen der Nachbuchung'));
                }
            );
    }

    setStation($event: Station) {
        this.entity.station = $event;
        if ($event !== null && $event !== undefined) {
            if (this.entity.waehrung ===
                null ||
                this.entity.waehrung ===
                undefined ||
                ('' + this.entity.waehrung) ===
                '') {
                this.setWaehrung($event.currency);
            }
        }
        this.cd.markForCheck();
    }

    setWaehrung($event: Waehrung) {
        this.entity.waehrung = $event;
        if (this.entity.waehrung !== null && this.entity.waehrung !== undefined) {
            this.entity.kursWert = this.entity.waehrung.rate;
            this.entity.details.forEach((v, idx, a) => {
                v.pos = idx + 1;
                v.kurs = this.entity.kursWert;
            });
        }
        this.cd.markForCheck();
    }

    setAusweis($event: Ausweis) {
        this.entity.ausweis = $event;
        if (this.entity.ausweis !== null && this.entity.ausweis !== undefined) {
            this.entity.pan = this.entity.ausweis.pan;
        }
        this.cd.markForCheck();
    }

    addNachbuchungDetail(): any {
        const d = new NachbuchungDetail();
        d.kurs = this.entity.kursWert;
        d.pos = this.entity.details.length + 1;
        this.entity.details.push(d);
        this.entity.details.forEach((v, idx, a) => {
            v.pos = idx + 1;
        });
        this.cd.markForCheck();
    }

    deleteDetail(idx) {
        this.entity.details.splice(idx, 1);
        this.entity.details.forEach((v, id, a) => {
            v.pos = id + 1;
        });
        this.cd.markForCheck();
    }

    setMenge(index: number, menge: any) {
        this.entity.details[index].pos = index + 1;
        this.entity.details[index].menge = parseFloat(('' + menge).replace(',', '.'));
        this.cd.markForCheck();
    }

    setBEpreis(index: number, p: any) {
        this.entity.details[index].pos = index + 1;
        this.entity.details[index].b_epreis = parseFloat(('' + p).replace(',', '.'));
        this.cd.markForCheck();
    }

    setBPreis(index: number, p: any) {
        this.entity.details[index].pos = index + 1;
        this.entity.details[index].b_preis = parseFloat(('' + p).replace(',', '.'));
        this.cd.markForCheck();
    }

    setEpreis(index: number, p: any) {
        this.entity.details[index].pos = index + 1;
        this.entity.details[index].epreis = parseFloat(('' + p).replace(',', '.'));
        this.cd.markForCheck();
    }

    setPreis(index: number, p: any) {
        this.entity.details[index].pos = index + 1;
        this.entity.details[index].preis = parseFloat(('' + p).replace(',', '.'));
        this.cd.markForCheck();
    }

    setBEpreisEuro(index: number, p: any) {
        this.entity.details[index].pos = index + 1;
        this.entity.details[index].b_epreis_euro = parseFloat(('' + p).replace(',', '.'));
        this.cd.markForCheck();
    }

    setBPreisEuro(index: number, p: any) {
        this.entity.details[index].pos = index + 1;
        this.entity.details[index].b_preis_euro = parseFloat(('' + p).replace(',', '.'));
        this.cd.markForCheck();
    }

    setEpreisEuro(index: number, p: any) {
        this.entity.details[index].pos = index + 1;
        this.entity.details[index].epreis_euro = parseFloat(('' + p).replace(',', '.'));
        this.cd.markForCheck();
    }

    setPreisEuro(index: number, p: any) {
        this.entity.details[index].pos = index + 1;
        this.entity.details[index].preis_euro = parseFloat(('' + p).replace(',', '.'));
        this.cd.markForCheck();
    }

    setMwStSatz(index: number, s: any) {
        this.entity.details[index].pos = index + 1;
        this.entity.details[index].mwst_satz = parseFloat(('' + s).replace(',', '.'));
        this.cd.markForCheck();

    }

    setMwSt(index: number, s: any) {
        this.entity.details[index].pos = index + 1;
        this.entity.details[index].mwst = parseFloat(('' + s).replace(',', '.'));
        this.cd.markForCheck();

    }

    setMwStEuro(index: number, s: any) {
        this.entity.details[index].pos = index + 1;
        this.entity.details[index].mwst_euro = parseFloat(('' + s).replace(',', '.'));
        this.cd.markForCheck();

    }


    recalcDetail(index: number) {

        const p = this.entity.details[index];

        p.pos = index + 1;
        p.kurs = this.entity.kursWert;

        if (p.kurs === 0) {
            p.kurs = 1;
        }

        for (let k = 0; k < 5; k++) {

            if (p.menge === null || p.menge === undefined) {
                p.menge = 0;
            }
            if (p.epreis === null || p.epreis === undefined) {
                p.epreis = 0;
            }
            if (p.b_epreis === null || p.b_epreis === undefined) {
                p.b_epreis = 0;
            }
            if (p.preis === null || p.preis === undefined) {
                p.preis = 0;
            }
            if (p.b_preis === null || p.b_preis === undefined) {
                p.b_preis = 0;
            }
            if (p.mwst_satz === null || p.mwst_satz === undefined) {
                p.mwst_satz = 0;
            }
            if (p.mwst === null || p.mwst === undefined) {
                p.mwst = 0;
            }
            if (p.epreis_euro === null || p.epreis_euro === undefined) {
                p.epreis_euro = 0;
            }
            if (p.b_epreis_euro === null || p.b_epreis_euro === undefined) {
                p.b_epreis_euro = 0;
            }
            if (p.preis_euro === null || p.preis_euro === undefined) {
                p.preis_euro = 0;
            }
            if (p.b_preis_euro === null || p.b_preis_euro === undefined) {
                p.b_preis_euro = 0;
            }
            if (p.mwst_euro === null || p.mwst_euro === undefined) {
                p.mwst_euro = 0;
            }


            if (p.menge === 0) {
                if (p.epreis !== 0 && p.preis !== 0) {
                    p.menge = Math.round((p.preis / p.epreis) * 1000.0) / 1000.0;
                } else if (p.b_epreis !== 0 && p.b_preis !== 0) {
                    p.menge = Math.round((p.b_preis / p.b_epreis) * 1000.0) / 1000.0;
                }
            }

            if (p.epreis === 0 && p.menge !== 0) {
                if (p.preis !== 0) {
                    p.epreis = Math.round((p.preis / p.menge) * 1000.0) / 1000.0;
                }
            }

            if (p.epreis === 0 && p.epreis_euro !== 0 && p.kurs !== 0) {
                p.epreis = Math.round((p.epreis_euro * p.kurs) * 1000.0) / 1000.0;
            }

            if (p.preis === 0 && p.epreis !== 0) {
                p.preis = Math.round((p.menge * p.epreis) * 100.0) / 100.0;
            }


            if (p.b_epreis === 0 && p.menge !== 0) {
                if (p.b_preis !== 0) {
                    p.b_epreis = Math.round((p.b_preis / p.menge) * 1000.0) / 1000.0;
                }
            }

            if (p.b_epreis === 0 && p.b_epreis_euro !== 0) {
                p.b_epreis = Math.round((p.b_epreis_euro * p.kurs) * 1000.0) / 1000.0;
            }

            if (p.b_preis === 0 && p.b_epreis !== 0) {
                p.b_preis = Math.round((p.menge * p.b_epreis) * 100.0) / 100.0;
            }

            if (p.b_preis === 0 && p.preis !== 0 && p.mwst_satz !== 0) {
                p.b_preis = Math.round((p.preis * ((p.mwst_satz + 100.0) / 100.0)) * 100.0) / 100.0;
            }

            if (p.b_preis === 0 && p.preis !== 0 && p.mwst !== 0) {
                p.b_preis = Math.round((p.preis + p.mwst) * 100.0) / 100.0;
            }

            if (p.mwst_satz === 0 && p.mwst > 0) {
                if (p.b_preis !== 0) {
                    const netto = Math.round((p.b_preis - p.mwst) * 100.0) / 100.0;
                    if (p.preis === 0) {
                        p.preis = netto;
                    }
                    if (netto !== 0) {
                        p.mwst_satz = Math.round((p.mwst / netto) * 10000.0) / 100.0;
                    }
                }
            }

            if (p.mwst === 0 && p.mwst_satz > 0) {
                if (p.preis !== 0 && p.b_preis !== 0) {
                    p.mwst = Math.round((p.b_preis - p.preis) * 100.0) / 100.0;
                } else if (p.preis > 0) {
                    p.mwst = Math.round((p.preis * p.mwst_satz / 100.0) * 100.0) / 100.0;
                } else if (p.b_preis > 0) {
                    const netto = Math.round((p.b_preis / ((100.0 + p.mwst_satz) / 100.0)) * 100.0) / 100.0;
                    if (p.preis === 0) {
                        p.preis = netto;
                    }
                    p.mwst = Math.round((p.b_preis - netto) * 100.0) / 100.0;
                }
            }


            if (p.epreis_euro === 0) {
                p.epreis_euro = Math.round((p.epreis / p.kurs) * 1000.0) / 1000.0;
            }
            if (p.preis_euro === 0) {
                p.preis_euro = Math.round((p.preis / p.kurs) * 100.0) / 100.0;
            }
            if (p.mwst_euro === 0) {
                p.mwst_euro = Math.round((p.mwst / p.kurs) * 100.0) / 100.0;
            }
            if (p.b_epreis_euro === 0) {
                p.b_epreis_euro = Math.round((p.b_epreis / p.kurs) * 1000.0) / 1000.0;
            }
            if (p.b_preis_euro === 0) {
                p.b_preis_euro = Math.round((p.b_preis / p.kurs) * 100.0) / 100.0;
            }


        }

        this.entity.details[index] = p;

        this.cd.markForCheck();
    }


    setZweck(index: number, $event: any) {
        this.entity.details[index].pos = index + 1;
        this.entity.details[index].zweck = ('' + $event);
        this.cd.markForCheck();
    }

    setbelegnummer(index: number, $event: string) {
        this.entity.details[index].pos = index + 1;
        this.entity.details[index].belegnummer = parseInt('' + $event, 10);
        this.cd.markForCheck();
    }

    getDlgtitle() {
        if (this.isNew()) {
            return this._('Nacherfassung einbuchen');
        }
        return this._(this.dlgTitle, {nummer: this.entity.belegnummer});
    }

    posLabel(p) {
        return this._('Position {{pos}}', {pos: p});
    }


}
