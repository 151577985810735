export class Value {
    pos: any = null;
    value: any = null;
    hidden = false;


    static copyFrom(value: Value, overridePos = null, overrideHidden = null): Value {
        if (value === null || value === undefined) {
            return new Value();
        }
        return new Value(
            (overridePos === null) ? value.pos : overridePos,
            value.value,
            (overrideHidden) ? value.hidden : overrideHidden
        );
    }

    constructor(pos: any = null, value: any = null, hidden: boolean = false) {
        this.pos = pos;
        this.value = value;
        this.hidden = hidden;
    }


    setPos(pos: any): Value {
        this.pos = pos;
        return this;
    }

    setValue(value: any): Value {
        this.value = value;
        return this;
    }

    hide(): Value {
        this.hidden = true;
        return this;
    }

    show(): Value {
        this.hidden = false;
        return this;
    }

}
