<me-info-tool
        [title]="getTitle()"
        [title_icon]="getIcon()"
        [position]="info_position"
        *ngIf="_issuer!==null">
  <app-issuer-info [issuer]="_issuer"></app-issuer-info>
</me-info-tool>
<div *ngIf="showLabel && _issuer!==null && _issuer!==undefined" style="display:inline-block;">
  <i class="{{ getIcon() }}"></i>
  {{ _issuer.bezeichnung }}
  <span class="badge badge-default" *ngIf="_issuer.nummer!==null && _issuer.nummer!==undefined">{{ _issuer.nummer }}</span>
</div>
