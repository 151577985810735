import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {Anrede}                            from './anrede';
import {LocalizedComponent}                from '../components/localized-component';


@Component({
               selector   : 'app-anrede-display',
               templateUrl: './anrede-display.component.html',
               styleUrls  : ['./anrede-display.component.css']
           })
export class AnredeDisplayComponent extends LocalizedComponent implements OnInit {

    @Input() value: Anrede = null;
    @Input() info_position = 'right';
    @Input() showLabel = true;

    getIcon() {
        return '';
    }

    getTitle() {
        if (this.value !== null) {
            return this.value.anrede;
        }
        return '';
    }

    constructor( public cd:ChangeDetectorRef) {
        super(cd);
    }

    ngOnInit() {
    }

}
