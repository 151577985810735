import {Station} from '../../stationen/common/station';
import * as moment from 'moment';
import {IStation} from '../../stationen/common/istation';
import {IEinheit} from '../../products/einheiten/ieinheit';
import {IPreis} from './ipreis';
import {IProdukt} from '../../products/produkte/common/iprodukt';
import {Produkt} from '../../products/produkte/common/produkt';
import {Einheit} from '../../products/einheiten/einheit';
import {Waehrung} from '../../commons/waehrungen/waehrung';
import {Terminal} from '../../stationen/terminals/common/terminal';
import {ITerminal} from '../../stationen/terminals/common/iterminal';
import {IWaehrung} from '../../commons/waehrungen/iwaehrung';

export class Preis implements IPreis {
    id = 0;
    einheit: IEinheit = null;
    station: IStation = null;
    terminal: ITerminal = null;
    produkt: IProdukt = null;
    waehrung: IWaehrung = null;
    gueltigAb: Date = null;
    wert = 0;
    istBrutto = false;


    static fromResult(entity: IPreis): Preis {
        if ((entity === null) || (entity === undefined)) {
            return null;
        }

        const l: Preis = new Preis();
        for (const k of Object.keys(l)) {
            if (k === 'station') {
                l.station = Station.fromResult(entity.station);
            } else if (k === 'terminal') {
                l.terminal = Terminal.fromResult(entity.terminal);
            } else if (k === 'produkt') {
                l.produkt = Produkt.fromResult(entity.produkt);
            } else if (k === 'waehrung') {
                l.waehrung = Waehrung.fromResult(entity.waehrung);
            } else if (k === 'einheit') {
                l.einheit = Einheit.fromResult(entity.einheit);
            } else if (k === 'gueltigAb') {
                l.gueltigAb = moment('' + entity.gueltigAb, 'YYYY-MM-DDTHH:mm:ssZ').toDate();
            } else {
                l[k] = (entity[k] !== undefined) ? entity[k] : '';
            }
        }
        return l;
    }

    constructor() {
    }


}
