import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {Terminal} from '../common/terminal';
import {LocalizedComponent} from '../../../commons/components/localized-component';


@Component({
               selector   : 'app-terminal-info',
               templateUrl: './terminal-info.component.html',
               styleUrls  : [ './terminal-info.component.css' ]
           })
export class TerminalInfoComponent extends LocalizedComponent implements OnInit {

    _terminal: Terminal = null;

    @Input()
    set terminal(value: Terminal) {
        this._terminal = Terminal.fromResult(value);
    }

    get terminal() {
        return this._terminal;
    }

    constructor(

                public cd: ChangeDetectorRef) {
        super( cd);
    }

    ngOnInit() {
    }

}
