import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {LocalizedComponent} from '../../commons/components/localized-component';

import {GeraetList} from '../../devices/common/geraet-list';
import {DeviceService} from '../../devices/common/device.service';
import {Station} from '../../stationen/common/station';
import {IListResult} from '../../commons/list-result';
import {IGeraet} from '../../devices/common/igeraet';
import {ToastrService} from 'ngx-toastr';
import {NewPageCountEvent} from '../../commons/paginator/paginator.component';
import {StationCellRendererComponent} from '../../stationen/station-cell-renderer.component';
import {RowActionParams} from '../../commons/data-table/row-action-params';
import {Geraet} from '../../devices/common/geraet';
import {ActionResponse, IActionResponse} from '../../commons/action-response';
import {DTDeviceSettingsRendererComponent} from '../../devices/dtdevice-settings-renderer/dtdevice-settings-renderer.component';
import {DTDeviceSystemStateRendererComponent} from '../../devices/dtdevice-system-state-renderer/dtdevice-system-state-renderer.component';

@Component({
    selector: 'app-video-control',
    templateUrl: './video-control.component.html',
    styleUrls: ['./video-control.component.scss']
})
export class VideoControlComponent extends LocalizedComponent implements OnInit {
    stationR = StationCellRendererComponent;
    deviceSettingsR = DTDeviceSettingsRendererComponent;
    stateR = DTDeviceSystemStateRendererComponent;


    station: Station = null;
    geraete: GeraetList = null;

    constructor(


        public cd: ChangeDetectorRef,
        public deviceService: DeviceService,
        public toastr: ToastrService
    ) {
        super( cd);
        this.geraete = new GeraetList();
        this.geraete.searchString = 'typ:MEVIDEOCONTROL';
        this.geraete
            .onUpdateRequired
            .subscribe(
                (list: GeraetList) => {
                    this.deviceService
                        .listDevices(
                            this.station,
                            list.size,
                            list.calcOffset(),
                            list.order,
                            list.simpleFilter,
                            list.getQuery()
                        )
                        .subscribe(
                            (l: IListResult<IGeraet>) => {
                                list.populateFromListResult(l);

                                this.cd.markForCheck();
                            },
                            () => {
                                this.toastr.error(this._('Fehler beim Laden der verfügbaren MEVideoControl-Module'));
                            }
                        );
                }
            );
    }

    ngOnInit() {
        this.updateGeraete(false, 1);
    }

    updateGeraete(force = false, page?: number) {
        if ((page !== undefined) && (page != null)) {
            if (page !== this.geraete.page) {
                this.geraete.page = page;
                force = true;
            }
        }
        this.geraete.start();
    }

    /**
     * ändert die Anzahl der Datensätze/Seite und lädt die Liste neu
     * @param n
     */
    updateGeraetePageCount(n: NewPageCountEvent) {
        if (this.geraete.size !== n.itemsPerPage) {
            this.geraete.size = n.itemsPerPage;
            this.updateGeraete(true, n.currentPage);
        }
    }

    enableAccess($event: RowActionParams<Geraet>) {
        if ($event !== null && $event !== undefined) {
            this.toastr.info(this._('Der Wifi-Zugriff auf das Kamerasystem wird nun aktiviert. Der Vorgang dauert einen Moment...'));

            this.deviceService
                .enableMEVCWifi(
                    $event.row.data.station,
                    $event.row.data
                )
                .subscribe(
                    (rr: IActionResponse) => {
                        const r = ActionResponse.fromRawActionResponse(rr);
                        if (r.success) {
                            this.geraete.reload();
                            this.toastr.success(this._('Der Wifi-Zugriff auf das Kamerasystem wurde nun aktiviert. ' +
                                'Bitte loggen Sie sich vor Ort am AccessPoint ein und nutzen Sie anschließend die ' +
                                'PIN ' +
                                '<div class="pin-display">' +
                                '{{pin}}' +
                                '</div> zum Zugriff auf das Bildmaterial unter der URL https://www.me-videocontrol.de<br />' +
                                '<strong>Hinweis:</strong>Auf einigen Endgeräten müssen nach dem Login auf den ' +
                                'WiFi-AccessPoint alle anderen Internet-Verbindungen deaktiviert werden.', {pin: r.params.pin}),
                                'Wifi-Zugriff nun möglich',
                                {
                                    timeOut: 60000,
                                    enableHtml: true
                                }
                            );
                        } else {
                            this.toastr.error(this._('Der Wifi-Zugriff auf das Kamerasystem konnte nicht aktiviert werden'));
                        }
                    },
                    () => {
                        this.toastr.error(this._('Der Wifi-Zugriff auf das Kamerasystem konnte nicht aktiviert werden'));
                    }
                );

        }
    }

    reboot($event: RowActionParams<Geraet>) {
        if ($event !== null && $event !== undefined) {
            this.toastr.info(this._('Es wird nun versucht, das Kamerasystem neuzustarten. Der Vorgang dauert einen Moment...'));

            this.deviceService
                .rebootMEVC(
                    $event.row.data.station,
                    $event.row.data
                )
                .subscribe(
                    (rr: IActionResponse) => {
                        const r = ActionResponse.fromRawActionResponse(rr);
                        if (r.success) {
                            this.toastr.success(this._('Die Reboot-Anforderung wurde gesendet. ' +
                                'Die MEVideoControl-Box sollte nun neu starten. Der Reboot-vorgang dauert ca. 2 Minuten.'
                                )
                            );
                        } else {
                            this.toastr.error(this._('Der Reboot-Anforderungen konnte nicht gesendet werden.'));
                        }
                    },
                    () => {
                        this.toastr.error(this._('Der Reboot-Anforderungen konnte nicht gesendet werden.'));
                    }
                );

        }
    }

    disableAccess($event: RowActionParams<Geraet>) {
        if ($event !== null && $event !== undefined) {
            this.toastr.info(this._('Der Wifi-Zugriff auf das Kamerasystem wird nun deaktiviert. Der Vorgang dauert einen Moment...'));

            this.deviceService
                .disableMEVCWifi(
                    $event.row.data.station,
                    $event.row.data
                )
                .subscribe(
                    (rr: IActionResponse) => {
                        const r = ActionResponse.fromRawActionResponse(rr);
                        if (r.success) {
                            this.toastr.success(this._('Der Wifi-Zugriff auf das Kamerasystem wurde deaktiviert. '));
                        } else {
                            this.toastr.error(this._('Der Wifi-Zugriff auf das Kamerasystem konnte nicht deaktiviert werden'));
                        }
                    },
                    () => {
                        this.toastr.error(this._('Der Wifi-Zugriff auf das Kamerasystem konnte nicht deaktiviert werden'));
                    }
                );

        }
    }


    shot($event: RowActionParams<Geraet>) {
        if ($event !== null && $event !== undefined) {
            this.toastr.info(this._('Es wird nun versucht, ein aktuelles Standbild zu erstellen. Der Vorgang dauert einen Moment...'));

            this.deviceService
                .createMEVCSnapshot(
                    $event.row.data.station,
                    $event.row.data
                )
                .subscribe(
                    (rr: IActionResponse) => {
                        const r = ActionResponse.fromRawActionResponse(rr);
                        if (r.success) {
                            this.toastr.success(this._('Die Anforderung wurde gesendet. ' +
                                'Die MEVideoControl-Box wird nun ein Standbild erstellen und dieses ' +
                                'über das Web-Interface zur Verfügung stellen.'
                                )
                            );
                        } else {
                            this.toastr.error(this._('Der Standbild-Anforderungen konnte nicht gesendet werden.'));
                        }
                    },
                    () => {
                        this.toastr.error(this._('Der Standbild-Anforderungen konnte nicht gesendet werden.'));
                    }
                );

        }

    }


    sendConfig($event: RowActionParams<Geraet>) {
        if ($event !== null && $event !== undefined) {
            this.toastr.info(this._('Es wird nun versucht, die aktuelle Konfiguration an das Kamerasystem zu senden. Der Vorgang dauert einen Moment...'));

            this.deviceService
                .sendMEVCConfig(
                    $event.row.data.station,
                    $event.row.data
                )
                .subscribe(
                    (rr: IActionResponse) => {
                        const r = ActionResponse.fromRawActionResponse(rr);
                        if (r.success) {
                            this.toastr.success(this._('Die Konfiguration wurde an das Kamerasystem gesendet. ' +
                                'Etwaige Änderungen an der WiFi-Konfiguration werden bei der nächsten vollständigen ' +
                                'WiFi-Aktivierung aktiv.'
                                )
                            );
                        } else {
                            this.toastr.error(this._('Beim Senden der Konfigurationsdaten ist ein Fehler aufgetreten.'));
                        }
                    },
                    () => {
                        this.toastr.error(this._('Beim Senden der Konfigurationsdaten ist ein Fehler aufgetreten.'));
                    }
                );

        }
    }

    checkState($event: RowActionParams<Geraet>) {
        if ($event !== null && $event !== undefined) {
            this.toastr.info(this._('Es wird nun versucht, den aktuellen Systemstatus dieses Kamerasystem abzurufen. Der Vorgang dauert einen Moment...'));

            this.deviceService
                .checkSystemState(
                    $event.row.data.station,
                    $event.row.data
                )
                .subscribe(
                    (rr: IActionResponse) => {
                        const r = ActionResponse.fromRawActionResponse(rr);
                        if (r.success) {
                            this.toastr.success(this._('Der aktuelle Systemstatus wurde abgerufen.'));
                            this.geraete.reload();
                        } else {
                            this.toastr.error(this._('Beim Abrufen des Systemstatus ist ein Fehler aufgetreten.'));
                        }
                    },
                    () => {
                        this.toastr.error(this._('Beim Abrufen des Systemstatus ist ein Fehler aufgetreten.'));
                    }
                );

        }
    }
}
