import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'data-table-table-extra-header',
    template: '<ng-content></ng-content>'
})
export class DataTableTableExtraHeaderComponent implements OnInit {

    @Input() id = '';

    constructor() {
    }

    ngOnInit() {
    }

}
