import {Component, Injector} from '@angular/core';
import {CommonCellRenderer} from '../../commons/data-table/renderer/common-cell-renderer';
import {EDeviceType} from '../common/edevice-type.enum';
import {DataTableRow} from '../../commons/data-table/data-table-row';
import {DataTableColumnDirective} from '../../commons/data-table/data-table-column.directive';
import {DeviceType} from '../common/device-type';

@Component({
    selector: 'app-dtdevice-type-renderer',
    template: `
        <app-device-type-quick-info *ngIf="cell!==undefined && cell!==null" [type]="cell"></app-device-type-quick-info>

    `
})
export class DTDeviceTypeRendererComponent extends CommonCellRenderer {
    cell: EDeviceType = null;
    row: DataTableRow<any> = null;
    column: DataTableColumnDirective = null;

    value = '';

    constructor(public injector: Injector) {
        super();
        this.cell = DeviceType.byValue(this.injector.get('cell'));
        this.row = this.injector.get('row');
        this.column = this.injector.get('column');
    }


}
