<data-table [selectable]="false" [data]="sprachen.asDataTableRows()">
    <column [field]="'code'" [label]="_('Code')"></column>
    <column [type]="'flag'" [field]="'flagge'" [label]="_('Flagge')"></column>
    <column [field]="'bezeichnung'" [label]="t('Bezeichnung')"></column>
    <tableaction [label]="t('Sprache anlegen')" [icon]="'icofont icofont-ui-add'"
                 (perform)="addSprache()"></tableaction>
    <rowaction [label]="t('Sprache bearbeiten')"
               [icon]="'icofont icofont-ui-edit'"
               (perform)="editSprache($event)"
               (checkEnabled)="checkEnabled('sprache',$event)"
    ></rowaction>
    <rowaction [label]="t('Sprache löschen')"
               [icon]="'icofont icofont-ui-delete'"
               [cmd]="'delete'"
               (perform)="deleteSprache($event)"
               (checkEnabled)="checkEnabled('sprache',$event)"
    ></rowaction>

    <data-table-table-extra-header>
        <app-paginator
                [total]="sprachen.total"
                [page]="sprachen.page"
                [perpage]="sprachen.size"
                (pageChange)="updateLanguages(true,$event)"
                (pageCountChange)="updateSprachenPageCount($event)"
        ></app-paginator>

    </data-table-table-extra-header>
</data-table>
