import {AfterViewInit, Component, EventEmitter, Input, NgZone, OnInit, Output, ViewChild} from '@angular/core';
import {FilterCallback} from '../commons/dynamic-form/FilterCallback';

import {MESelectItemFormatterCallback} from '../commons/dynamic-form/me-select/meselect-item-formatter-callback';
import {
    MEHighlitePipe,
    MESelectComponent,
    MESelectItemMatcherCallback,
    MESelectItemTextCallback
} from '../commons/dynamic-form/me-select/meselect.component';
import {Fahrzeug} from './fahrzeuge/common/fahrzeug';
import {FahrzeugList} from './fahrzeuge/common/fahrzeug-list';
import {FahrzeugService} from './fahrzeuge/common/fahrzeug.service';
import {GenericFilter} from '../commons/generic-filter';
import {CommonList} from '../commons/common-list';
import {SimpleFahrzeugFilter} from './fahrzeuge/common/simple-fahrzeug-filter';
import {IListResult} from '../commons/list-result';
import {IFahrzeug} from './fahrzeuge/common/ifahrzeug';
import {MESelectSearchEvent} from '../commons/dynamic-form/me-select/meselect-search-event';
import CryptoES from "crypto-es";

@Component({
    selector: 'fahrzeug-inline-field',
    template: `
        <meselect
                id="{{ id }}"
                [idField]="'id'"
                [items]="fahrzeuge?.data"
                [asMatrix]="false"
                [placeholder]="label"
                (itemSelected)="doSelect($event)"
                (needsSearch)="doSearch($event)"
                [small]="small"
                [nullable]="nullable"
        >
        </meselect>
    `,
    styles: [`
        :host ::ng-deep meselect .red {
            color: red;
        }

        :host ::ng-deep meselect .orange {
            color: orange;
        }
    `]
})
export class FahrzeugInlineFieldComponent implements OnInit, AfterViewInit {

    @ViewChild(MESelectComponent, {static: true}) fahrzeugeMESelect: MESelectComponent;
    @Input() small = false;
    @Input() nullable = true;

    fahrzeuge: FahrzeugList = new FahrzeugList();

    _value: Fahrzeug = null;

    @Input() label = 'FahrzeugFeld';
    @Input() id = '';
    @Input() maxDisplayFahrzeugNum = 50;

    @Input()
    set value(v: Fahrzeug) {

        if (this._value !== v) {
            this._value = v;
            if ((this.fahrzeugeMESelect !== null) && (this.fahrzeugeMESelect !== undefined)) {
                this.fahrzeugeMESelect.value = v;
            }
            this.valueChange.emit(v);
        }
    }

    @Output() valueChange = new EventEmitter<Fahrzeug>();


    get value(): Fahrzeug {
        return this._value;
    }

    constructor(public fahrzeugService: FahrzeugService,
                public _ngZone: NgZone) {
    }

    public search: FilterCallback<Fahrzeug> = (filter: GenericFilter<Fahrzeug>,
                                               list: CommonList<Fahrzeug>,
                                               maxDisplayItems: number,
                                               queryString: string,
                                               cfg: any,
                                               selectComponent: MESelectComponent) => {
        queryString = ('' + queryString).trim();
        let q = '';
        if (queryString !== '') {
            if (isNaN(parseInt(queryString, 10))) {
                q = '(*' + queryString + '*) OR' +
                    '(' + queryString + ')';
            } else {
                q = '(nummer:' + parseInt(queryString, 10) + '^10) OR ' +
                    '(nummer:' + parseInt(queryString, 10) + '*^5) OR ' +
                    '(*' + queryString + '*) OR' +
                    '(' + queryString + ')';
            }
        }
        this.fahrzeugService
            .list(maxDisplayItems,
                0,
                [],
                (filter as SimpleFahrzeugFilter),
                q
            )
            .subscribe(
                (c: IListResult<IFahrzeug>) => {
                    list.populateFromListResult(c);
                    if (selectComponent !== null && selectComponent !== undefined) {
                        selectComponent.items = list.data;
                        selectComponent.cd.markForCheck();
                    }
                }
            );
    }

    ngOnInit() {
        if (this.id === '') {
            this.id = 'tf' + CryptoES.MD5('fahrzeugfield-' + this.label + Math.random() + '-' + Math.random()).toString();
        }
        this.doSearch(new MESelectSearchEvent('', this.fahrzeugeMESelect));
    }

    ngAfterViewInit(): void {
        this.fahrzeugeMESelect.onFormat = this.itemFormat;
        this.fahrzeugeMESelect.onGetText = this.itemText;
        this.fahrzeugeMESelect.onMatch = this.itemMatcher;
        this.fahrzeugeMESelect.value = this._value;
    }

    doSearch(value: MESelectSearchEvent) {
        this.search(null, this.fahrzeuge, this.maxDisplayFahrzeugNum, value.queryString, null, value.component);
    }

    doSelect(fahrzeug: Fahrzeug) {
        this._value = fahrzeug;
        this.valueChange.emit(fahrzeug);
    }

    public itemFormat: MESelectItemFormatterCallback<Fahrzeug> = (item: Fahrzeug, search) => {
        if (item === null) {
            return '';
        }

        const icon = 'icofont icofont-car';

        return '<i class="' + icon + '"></i> ' +
            ((item.bezeichnung === null) ? '' : MEHighlitePipe.transformString(item.bezeichnung, search)) +
            ((item.kfzKennzeichen === null) ? '' :
                ' <span class="badge badge-info">' +
                MEHighlitePipe.transformString(item.kfzKennzeichen, search) +
                '</span>') +
            ((item.nummer === null) ? '' :
                ' <span class="badge badge-default">' +
                MEHighlitePipe.transformString('' + item.nummer, search) +
                '</span>')
            ;
    }

    public itemText: MESelectItemTextCallback<Fahrzeug> = (item: Fahrzeug) => {
        if (item === null) {
            return '';
        }
        let r = '';
        r = r + ((item.bezeichnung === null) ? '' : item.bezeichnung + ', ');
        r = r + ((item.kfzKennzeichen === null) ? '' : item.kfzKennzeichen);
        r = r + ((item.nummer === null) ? '' : ' [' + item.nummer + ']');
        return r.trim();
    }

    public itemMatcher: MESelectItemMatcherCallback<Fahrzeug> = (item: Fahrzeug, search) => {
        if (!search) {
            return true;
        }
        if (search.trim() === '') {
            return true;
        }
        if (!item) {
            return false;
        }
        const x = item.bezeichnung + ' ' + item.kfzKennzeichen + ' ' + item.nummer;

        return (x.toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) >= 0);
    }


}
