import {Component, Injector} from '@angular/core';
import {CommonCellRenderer} from './common-cell-renderer';
import {DataTableRow} from '../data-table-row';
import {DataTableColumnDirective} from '../data-table-column.directive';

@Component({
               selector: 'app-urlcell-renderer',
               template: `
                   <a *ngIf="cell!==null && cell!==undefined && cell!==''" href="{{ cell }}" target="_blank">
                       {{ label }}
                   </a>
               `
           })
export class URLCellRendererComponent extends CommonCellRenderer {

    label = '';
    cell = '';
    row: DataTableRow<any> = null;
    column: DataTableColumnDirective = null;

    constructor(public injector: Injector) {
        super();
        this.cell = this.injector.get('cell');
        this.row = this.injector.get('row');
        this.column = this.injector.get('column');

        if ((this.column !== null) && (this.column !== undefined)) {
            let cfg = this.column.cfg;
            if ((cfg !== null) && (cfg !== undefined)) {
                if ((cfg.label !== undefined) && (cfg.label !== null)) {
                    this.label = cfg.label;
                }
            }
        }
    }


}
