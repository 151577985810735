import {Injectable} from '@angular/core';
import {SimpleEinheitFilter} from './einheiten/simple-einheit-filter';
import {Observable} from 'rxjs';
import {Einheit} from './einheiten/einheit';
import {SimpleArtikelFilter} from './artikel/simple-artikel-filter';
import {Artikel} from './artikel/artikel';
import {SimpleProduktFilter} from './produkte/common/simple-produkt-filter';
import {Produkt} from './produkte/common/produkt';
import {SimpleProduktGruppeFilter} from './produktgruppen/common/simple-produkt-gruppe-filter';
import {ProduktGruppe} from './produktgruppen/common/produkt-gruppe';
import {LoginService} from '../auth/services/login.service';
import {SimpleArtikelGruppeFilter} from './artikelgruppen/common/simple-artikel-gruppe-filter';
import {IEinheit} from './einheiten/ieinheit';
import {IArtikel} from './artikel/iartikel';
import {IProdukt} from './produkte/common/iprodukt';
import {IProduktGruppe} from './produktgruppen/common/iprodukt-gruppe';
import {ResultMetaSortImpl} from '../commons/result-meta';
import {IListResult} from '../commons/list-result';
import {IActionResponse} from '../commons/action-response';
import {IArtikelGruppe} from './artikelgruppen/common/iartikel-gruppe';
import {HttpClient} from '@angular/common/http';

@Injectable({
                providedIn: 'root'
            })
export class ProdukteService {

    constructor(public http: HttpClient,
                public loginService: LoginService) {
    }

    listEinheiten(max = 0,
                  offset = 0,
                  sort: ResultMetaSortImpl[] = [],
                  filter: SimpleEinheitFilter = null): Observable<IListResult<IEinheit>> {
        let url = '/api/systemsettings/einheiten';

        url = url.concat('?')
            .concat('max=' + encodeURIComponent('' + max))
            .concat('&')
            .concat('offset=' + encodeURIComponent('' + offset));

        if (filter != null) {
            if (filter.hasValues()) {
                url = url + '&' + filter.asURL();
            }
        }

        if (sort !== undefined && sort !== null && sort.length > 0) {
            const p = 0;
            sort.forEach(it => {
                url = url.concat('&sort.' + p + '=' + encodeURIComponent(it.field + '|' + it.direction));
            });
        }


        return this.http
            .get<IListResult<IEinheit>>(
                url,
                {
                    headers: this.loginService.getHttpHeaders()
                }
            );
    }

    storeEinheit(einheit: Einheit, insert = false): Observable<IActionResponse> {
        if (!insert && einheit.id != null && einheit.id > 0) {
            return this.http
                .put<IActionResponse>(
                    '/api/systemsettings/einheiten/' + encodeURIComponent('' + einheit.id),
                    JSON.stringify(einheit),
                    {headers: this.loginService.getHttpHeaders()}
                );
        }
        return this.http
            .post<IActionResponse>(
                '/api/systemsettings/einheiten',
                JSON.stringify(einheit),
                {headers: this.loginService.getHttpHeaders()}
            );

    }


    listArtikel(max = 0,
                offset = 0,
                sort: ResultMetaSortImpl[] = [],
                filter: SimpleArtikelFilter = null,
                queryString = ''
    ): Observable<IListResult<IArtikel>> {
        let url = '/api/systemsettings/artikel';

        url = url.concat('?')
            .concat('max=' + encodeURIComponent('' + max))
            .concat('&')
            .concat('offset=' + encodeURIComponent('' + offset));

        if (filter != null) {
            if (filter.hasValues()) {
                url = url + '&' + filter.asURL();
            }
        }
        if ((queryString !== null) && (queryString !== undefined) && (queryString !== '')) {
            url = url + '&q=' + encodeURIComponent(queryString);
        }

        if (sort !== undefined && sort !== null && sort.length > 0) {
            const p = 0;
            sort.forEach(it => {
                url = url.concat('&sort.' + p + '=' + encodeURIComponent(it.field + '|' + it.direction));
            });
        }


        return this.http
            .get<IListResult<IArtikel>>(
                url,
                {headers: this.loginService.getHttpHeaders()}
            );
    }

    findFreeArtikelID(): Observable<IActionResponse> {
        return this.http
            .post<IActionResponse>(
                '/api/systemsettings/artikel/_freeID',
                {},
                {headers: this.loginService.getHttpHeaders()}
            );
    }

    findFreeArtikelNum(): Observable<IActionResponse> {
        return this.http
            .post<IActionResponse>(
                '/api/systemsettings/artikel/_freeNum',
                {},
                {headers: this.loginService.getHttpHeaders()}
            );
    }

    findFreeEinheitNum(): Observable<IActionResponse> {
        return this.http
            .post<IActionResponse>(
                '/api/systemsettings/einheiten/_freeNum',
                {},
                {headers: this.loginService.getHttpHeaders()}
            );
    }

    storeArtikel(artikel: Artikel, insert = false): Observable<IActionResponse> {
        if (!insert && artikel.id != null && artikel.id > 0) {
            return this.http
                .put<IActionResponse>(
                    '/api/systemsettings/artikel/' + encodeURIComponent('' + artikel.id),
                    JSON.stringify(artikel),
                    {headers: this.loginService.getHttpHeaders()}
                );
        }
        return this.http
            .post<IActionResponse>(
                '/api/systemsettings/artikel',
                JSON.stringify(artikel),
                {headers: this.loginService.getHttpHeaders()}
            );

    }


    listProdukte(max = 0,
                 offset = 0,
                 sort: ResultMetaSortImpl[] = [],
                 filter: SimpleProduktFilter = null,
                 queryString = ''
    ): Observable<IListResult<IProdukt>> {
        let url = '/api/produkte';

        url = url.concat('?')
            .concat('max=' + encodeURIComponent('' + max))
            .concat('&')
            .concat('offset=' + encodeURIComponent('' + offset));

        if (filter != null) {
            if (filter.hasValues()) {
                url = url + '&' + filter.asURL();
            }
        }

        if (queryString !== undefined && queryString !== null && ('' + queryString) !== '') {
            url = url + 'q=' + encodeURIComponent(queryString);
        }

        if (sort !== undefined && sort !== null && sort.length > 0) {
            const p = 0;
            sort.forEach(it => {
                url = url.concat('&sort.' + p + '=' + encodeURIComponent(it.field + '|' + it.direction));
            });
        }


        return this.http
            .get<IListResult<IProdukt>>(
                url,
                {headers: this.loginService.getHttpHeaders()}
            );
    }


    storeProdukt(produkt: Produkt, insert = false): Observable<IActionResponse> {
        if (!insert && produkt.id != null && produkt.id > 0) {
            return this.http
                .put<IActionResponse>(
                    '/api/produkte/' + encodeURIComponent('' + produkt.id),
                    JSON.stringify(produkt),
                    {headers: this.loginService.getHttpHeaders()}
                );
        }
        return this.http
            .post<IActionResponse>(
                '/api/produkte',
                JSON.stringify(produkt),
                {headers: this.loginService.getHttpHeaders()}
            );

    }

    findFreeProduktNum(): Observable<IActionResponse> {
        return this.http
            .post<IActionResponse>(
                '/api/produkte/_freeNum',
                {},
                {headers: this.loginService.getHttpHeaders()}
            );
    }


    listProduktGruppen(max = 0,
                       offset = 0,
                       sort: ResultMetaSortImpl[] = [],
                       filter: SimpleProduktGruppeFilter = null,
                       queryString = ''
    ): Observable<IListResult<IProduktGruppe>> {
        let url = '/api/produktgruppen';

        url = url.concat('?')
            .concat('max=' + encodeURIComponent('' + max))
            .concat('&')
            .concat('offset=' + encodeURIComponent('' + offset));

        if (filter != null) {
            if (filter.hasValues()) {
                url = url + '&' + filter.asURL();
            }
        }

        if (queryString !== undefined && queryString !== null && ('' + queryString).trim() !== '') {
            url = url + 'q=' + encodeURIComponent(('' + queryString).trim());
        }

        if (sort !== undefined && sort !== null && sort.length > 0) {
            const p = 0;
            sort.forEach(it => {
                url = url.concat('&sort.' + p + '=' + encodeURIComponent(it.field + '|' + it.direction));
            });
        }


        return this.http
            .get<IListResult<IProduktGruppe>>(
                url,
                {headers: this.loginService.getHttpHeaders()}
            );
    }


    storeProduktgruppe(produktgruppe: ProduktGruppe, insert = false): Observable<IActionResponse> {
        if (!insert && produktgruppe.id != null && produktgruppe.id > 0) {
            return this.http
                .put<IActionResponse>(
                    '/api/produktgruppen/' + encodeURIComponent('' + produktgruppe.id),
                    JSON.stringify(produktgruppe),
                    {headers: this.loginService.getHttpHeaders()}
                );
        }
        return this.http
            .post<IActionResponse>(
                '/api/produktgruppen',
                JSON.stringify(produktgruppe),
                {headers: this.loginService.getHttpHeaders()}
            );

    }

    findFreeProduktGruppeNum(): Observable<IActionResponse> {
        return this.http
            .post<IActionResponse>(
                '/api/produktgruppen/_freeNum',
                {},
                {headers: this.loginService.getHttpHeaders()}
            );

    }


    listArtikelGruppen(max = 0,
                       offset = 0,
                       sort: ResultMetaSortImpl[] = [],
                       filter: SimpleArtikelGruppeFilter = null,
                       queryString: string = null
    ): Observable<IListResult<IArtikelGruppe>> {
        let url = '/api/systemsettings/artikelgruppen';

        url = url.concat('?')
            .concat('max=' + encodeURIComponent('' + max))
            .concat('&')
            .concat('offset=' + encodeURIComponent('' + offset));

        if (filter != null) {
            if (filter.hasValues()) {
                url = url + '&' + filter.asURL();
            }
        }

        if (queryString !== undefined && queryString !== null && ('' + queryString).trim() !== '') {
            url = url + 'q=' + encodeURIComponent(queryString);
        }

        if (sort !== undefined && sort !== null && sort.length > 0) {
            const p = 0;
            sort.forEach(it => {
                url = url.concat('&sort.' + p + '=' + encodeURIComponent(it.field + '|' + it.direction));
            });
        }


        return this.http
            .get<IListResult<IArtikelGruppe>>(
                url,
                {headers: this.loginService.getHttpHeaders()}
            );
    }


    loadOne(id: number): Observable<IProdukt> {

        return this.http
            .get<IProdukt>(
                '/api/produkte/' + encodeURIComponent('' + id),
                {headers: this.loginService.getHttpHeaders()}
            );

    }
}
