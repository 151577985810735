import {Geraet} from './geraet';
import {ConverterTool} from '../../../shared/converter-tool';
import {Produkt} from '../../products/produkte/common/produkt';
import {Kostenstelle} from '../../customers/kostenstellen/common/kostenstelle';
import {Ausweis} from '../../customers/ausweise/common/ausweis';

export class MECoinAcceptorUnitSettings {
    customer_id = 0;
    firmware = 'unbekannt';
    hardware = 'unbekannt';
    last_discovered: Date = null;
    mac = '';
    rssi = 0;
    snr = 0;
    gateway: Geraet = null;
    gateway_mac = '';
    pairing_ok = false;
    error = false;
    produkt: Produkt = null;
    split = false;
    coinset: Array<Array<number>> = [];
    ausweis: Ausweis = null;
    kostenstelle: Kostenstelle = null;

    static fromRAW(st: any): MECoinAcceptorUnitSettings {
        if (st === null || st === undefined) {
            return null;
        }
        const l = new MECoinAcceptorUnitSettings();
        for (const k of Object.keys(st)) {
            if (k === 'gateway') {
                l.gateway = Geraet.fromResult(st.gateway || null);
            } else if (k === 'rssi') {
                l.rssi = parseInt('' + st.rssi, 10);
            } else if (k === 'snr') {
                l.snr = parseInt('' + st.snr, 10);
            } else if (k === 'last_discovered') {
                l.last_discovered = ConverterTool.toDate(st.last_discovered || null);
            } else if (k === 'pairing_ok') {
                l.pairing_ok = ConverterTool.toBoolean(st.pairing_ok || false);
            } else if (k === 'produkt') {
                l.produkt = Produkt.fromResult(st.produkt || null);
            } else if (k === 'split') {
                l.split = ConverterTool.toBoolean(st.split || false);
            } else if (k === 'coinset') {
                l.coinset = [];
                if (Array.isArray(st.coinset || null)) {
                    st.coinset.forEach((e: number[]) => {
                        if (Array.isArray(e) && e.length > 1) {
                            l.coinset.push([e[0], e[1]]);
                        }
                    });
                }
            } else if (k === 'ausweis') {
                l.ausweis = Ausweis.fromResult(st.ausweis || null);
            } else if (k === 'kostenstelle') {
                l.kostenstelle = Kostenstelle.fromResult(st.kostenstelle || null);
            } else {
                l[k] = (st[k] !== undefined) ? st[k] : null;
            }
        }

        return l;
    }
}
