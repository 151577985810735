<ul class="sidebar-nav" *ngIf="isLoggedIn">
    <li>
        <a routerLink="dashboard" routerLinkActive="active" *ngIf="sysInfoService.hasDashboard()">
            <i class="fa-fw icofont icofont-dashboard-web"></i> {{ _("Übersicht") }}
        </a>

    </li>
    <li *hasRole="['ROLE_ADMIN','ROLE_PROJECT_ADMIN','ROLE_BILLING']">
        <a routerLink="syssettings" routerLinkActive="active">
            <i class="fa-fw icofont icofont-ui-settings"></i> {{ _("Systemeinstellungen") }}
        </a>
    </li>
    <li *hasRole="['ROLE_ADMIN','ROLE_PROJECT_ADMIN']">
        <a routerLink="benutzer" routerLinkActive="active">
            <i class="fa-fw icofont icofont-user"></i> {{ _("Benutzerverwaltung") }}
        </a>
    </li>
    <li *hasRole="['ROLE_ADMIN','ROLE_PROJECT_ADMIN']">
        <a routerLink="produkte" routerLinkActive="active">
            <i class="fa-fw icofont icofont-food-basket"></i> {{ _("Produkte") }}
        </a>
    </li>
    <li>
        <a routerLink="stationen" routerLinkActive="active">
            <i class="fa-fw me-icon me-icon-station"></i> {{ _("Stationen") }}
        </a>
    </li>
    <li *hasRole="['ROLE_BESTAND','ROLE_ADMIN','ROLE_PROJECT_ADMIN']">
        <a routerLink="tankueberwachung" routerLinkActive="active">
            <i class="fa-fw me-icon me-icon-sonde"></i> {{ _("Tanküberwachung") }}
        </a>
    </li>
    <li *hasRole="['ROLE_VIDEOCONTROL','ROLE_ADMIN','ROLE_PROJECT_ADMIN']">
        <a routerLink="videocontrol" routerLinkActive="active">
            <i class="fa-fw far fa-camera"></i> {{ _("ME-VideoControl") }}
        </a>
    </li>
    <li
            *hasRole="['ROLE_ADMIN','ROLE_PROJECT_ADMIN','ROLE_PRICING']"
    >
        <a routerLink="preise" routerLinkActive="active">
            <i class="fa-fw far fa-money-bill-alt"></i> {{ _("Preispflege") }}
        </a>
    </li>
    <li
            *hasRole="['ROLE_ADMIN','ROLE_PROJECT_ADMIN','ROLE_BILLING','ROLE_KUNDEN','ROLE_KUNDEN_WRITABLE']"
    >
        <a routerLink="kunden" routerLinkActive="active">
            <i class="fa-fw far fa-address-book"></i> {{ _("Kunden") }}
        </a>
    </li>
    <li
            *hasRole="['ROLE_ADMIN','ROLE_PROJECT_ADMIN','ROLE_CUSTOMER','ROLE_FAHRZEUGE','ROLE_FAHRZEUGE_WRITABLE']"
    >
        <a routerLink="fahrzeuge" routerLinkActive="active">
            <i class="fa-fw far fa-car"></i> {{ _("Innenaufträge") }}
        </a>
    </li>
    <li
            *hasRole="['ROLE_ADMIN','ROLE_PROJECT_ADMIN','ROLE_CUSTOMER','ROLE_AUSWEISE','ROLE_AUSWEISE_WRITABLE']"
    >
        <a routerLink="ausweise" routerLinkActive="active">
            <i class="fa-fw far fa-credit-card"></i> {{ _("Ausweise") }}
        </a>
    </li>
    <li
            *hasRole="['ROLE_ADMIN','ROLE_PROJECT_ADMIN','ROLE_BILLING','ROLE_CUSTOMER','ROLE_KARTENANFRAGEN']"
    >
        <a routerLink="kartenanfragen" routerLinkActive="active">
            <i class="fa-fw icofont icofont-listing-number"></i> {{ _("Kartenanfragen") }}
        </a>
    </li>
    <li
            *hasRole="['ROLE_ADMIN','ROLE_PROJECT_ADMIN','ROLE_BILLING','ROLE_CUSTOMER','ROLE_TRANSAKTIONEN','ROLE_TRANSAKTIONEN_WRITABLE']"
    >
        <a routerLink="transaktionen" routerLinkActive="active">
            <i class="fa-fw icofont icofont-listing-number"></i> {{ _("Transaktionen") }}
        </a>
    </li>
    <li>
        <a routerLink="rechnungen" routerLinkActive="active"
           *hasRole="['ROLE_ADMIN']"
        >
            <i class="fa-fw icofont icofont-law-document"></i> {{ _("Rechnungen") }}
        </a>
    </li>
    <li>
        <a routerLink="lieferscheine" routerLinkActive="active"
           *hasRole="['ROLE_ADMIN','ROLE_PROJECT_ADMIN','ROLE_BILLING','ROLE_BESTAND_WRITABLE']"
        >
            <i class="fa-fw icofont icofont-law-document"></i> {{ _("Lieferscheine") }}
        </a>
    </li>
    <li>
        <a routerLink="bestand" routerLinkActive="active"
           *hasRole="['ROLE_ADMIN','ROLE_PROJECT_ADMIN','ROLE_BILLING','ROLE_BESTAND','ROLE_BESTAND_WRITABLE']"
        >
            <i class="fa-fw icofont icofont-chart-arrows-axis"></i> {{ _("Bestandsübersicht") }}
        </a>
    </li>

</ul>
