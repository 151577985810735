<app-tabset>
    <app-pane [title]="_('Standort')" *ngIf="false">
        <element-list [elements]="filter.stationen"
                      #stationen
                      [renderer]="stationRenderer"
                      (onClearAll)="filter.clearStationen(stationen)"
                      (onRemoveElement)="filter.removeStation($event,stationen)"
        >
            <div class="header w-50">
                <station-field [label]="_('Station hinzufügen')"
                               (valueChange)="filter.addStation($event,stationen)"
                ></station-field>
            </div>
        </element-list>
    </app-pane>
    <app-pane [title]="_('Länder')">
        <div class="d-flex">
            <div class="mr-3 flex-fill">
                <element-list [elements]="filter.countries"
                              #laender
                              [renderer]="countryRenderer"
                              (onClearAll)="filter.clearCountries(laender)"
                              (onRemoveElement)="filter.removeCountry($event,laender)"
                >
                    <div class="header w-50">
                        <country-field [label]="_('Land hinzufügen')"
                                       (valueChange)="filter.addCountry($event,laender)"
                        ></country-field>
                    </div>
                </element-list>
            </div>
            <div class="d-flex" style="border-left: 1px solid #eeeeee;padding-left: 5px;">
                <div class="list-group">
                    <a class="list-group-item list-group-item-action" (click)="land_at()">
                        {{_('Österreich')}}
                    </a>
                    <a class="list-group-item list-group-item-action" (click)="land_atde()">
                        {{_('Österreich+Deutschland')}}
                    </a>
                    <a class="list-group-item list-group-item-action" (click)="land_de()">
                        {{_('Deutschland')}}
                    </a>
                </div>
            </div>
        </div>
    </app-pane>
    <app-pane [title]="_('Kunden')" *hasRole="['ROLE_ADMIN','ROLE_PROJECT_ADMIN','ROLE_BILLING']">
        <element-list [elements]="filter.customers"
                      #customers
                      [renderer]="customerRenderer"
                      (onClearAll)="filter.clearCustomers(customers)"
                      (onRemoveElement)="filter.removeCustomer($event,customers)"
        >
            <div class="header w-50">
                <kunde-field [label]="_('Kunde hinzufügen')"
                             (valueChange)="filter.addCustomer($event,customers)"
                ></kunde-field>
            </div>
        </element-list>
    </app-pane>
    <app-pane [title]="_('Freitext')">
        <div>
            {{ _('Hier können Sie beliebige Suchanfragen formulieren:')}}
        </div>
        <text-field [label]="_('Suchen nach')"
                    [labelwidth]="2"
                    [(value)]="filter.freitext"
                    [nullable]="true"
                    [multiline]="true"></text-field>
    </app-pane>

</app-tabset>
