import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';

import {LocalizedComponent} from '../../components/localized-component';
import {Issuer} from '../common/issuer';

@Component({
               selector   : 'app-issuer-info',
               templateUrl: './issuer-info.component.html',
               styleUrls  : ['./issuer-info.component.css']
           })
export class IssuerInfoComponent extends LocalizedComponent implements OnInit {


    public _issuer = null;

    @Input()
    set issuer(value: Issuer) {
        this._issuer = Issuer.fromResult(value);
        if (this._issuer !== null) {

        }
    }

    constructor(  public cd: ChangeDetectorRef) {
        super( cd);
    }

    ngOnInit() {
    }

}
