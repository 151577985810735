import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {Kostenstelle} from '../common/kostenstelle';

import {LocalizedComponent} from '../../../commons/components/localized-component';

@Component({
  selector: 'app-kostenstelle-quick-info',
  templateUrl: './kostenstelle-quick-info.component.html',
  styleUrls: ['./kostenstelle-quick-info.component.scss']
})
export class KostenstelleQuickInfoComponent extends LocalizedComponent implements OnInit {

    _kostenstelle: Kostenstelle = null;

    @Input()
    set kostenstelle(value: Kostenstelle) {
        this._kostenstelle = Kostenstelle.fromResult(value);
    }

    get kostenstelle() {
      return this._kostenstelle;
    }

    @Input() showLabel        = true;
    @Input() info_position = 'right';

    getTitle() {
        if (this._kostenstelle !== null) {
            return this._(' Kostenstelle {{ kostenstelle }} ', {kostenstelle: this._kostenstelle.kostenstelle});
        }
        return '';
    }

    constructor(
                
                public cd: ChangeDetectorRef) {
        super( cd);
    }

    ngOnInit() {
    }


    showInfo() {
        if (this._kostenstelle === null || this._kostenstelle === undefined) {
          return false;
        }
        return (this._kostenstelle.info !== this.kostenstelle.kostenstelle);
    }
}
