import {ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SearchBoxComponent} from './search-box/search-box.component';
import {SucheComponent} from './suche/suche.component';
import {SuperSearchRoutingModule} from './super-search-routing.module';
import {AuthModule} from '../auth/auth.module';
import {FormsModule} from '@angular/forms';
import {CommonsModule} from '../commons/commons.module';

import {CustomersModule} from '../customers/customers.module';
import {ToastrModule} from 'ngx-toastr';

const components = [
    SearchBoxComponent,
    SucheComponent

];

@NgModule({
              imports        : [
                  CommonModule,
                  FormsModule,
                  AuthModule,
                  CommonsModule,
                  ToastrModule.forRoot(),
                  CustomersModule,
                  SuperSearchRoutingModule
              ],
              declarations   : [
        ...components
              ],
              exports        : [
        ...components],
              entryComponents: [
        ...components
              ]
          })
export class SuperSearchModule {

}
