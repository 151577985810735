import {CommonModule} from '@angular/common';
import {HttpClientModule} from '@angular/common/http';
import {ModuleWithProviders, NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {AuthModule} from '../auth/auth.module';
//import {LanguageSelectorComponent} from './sprachen/controls/language-selector/language-selector.component';
import {AnredeDisplayComponent} from './anreden/anrede-display.component';
import {AnredeEditorComponent} from './anreden/anrede-editor.component';
import {TabAnredenComponent} from './anreden/tab-anreden.component';
import {AreaHeadingComponent} from './area-heading/area-heading.component';
import {BytesPipe} from './bytes.pipe';
import {ListOptionDirective} from './components/list-option.directive';
import {LiterCellRendererComponent} from './components/liter-cell-renderer.component';

import {VolumenDisplayComponent} from './components/volumen-display.component';
import {ConfirmDialogComponent} from './confirm-dialog/confirm-dialog.component';
import {DashboardInfoBlockComponent} from './dashboard-info-block/dashboard-info-block.component';
import {DataTableAction} from './data-table/data-table-action';
import {DataTableColumnDirective} from './data-table/data-table-column.directive';
import {DataTableExtraTableActionComponent} from './data-table/data-table-extra-table-action.component';
import {DataTableFilterAreaComponent} from './data-table/data-table-filter-area.component';
import {DataTableFooterComponent} from './data-table/data-table-footer.component';
import {DataTableRowAction} from './data-table/data-table-row-action';
import {DataTableTableActionRowContainerComponent} from './data-table/data-table-table-action-row-container.component';
import {DataTableTableExtraHeaderComponent} from './data-table/data-table-table-extra-header.component';
import {DataTableComponent} from './data-table/data-table.component';
import {BooleanCellEditorComponent} from './data-table/editors/boolean-cell-editor.component';
import {CellEditorComponent} from './data-table/editors/cell-editor.component';
import {NumCellEditorComponent} from './data-table/editors/num-cell-editor.component';
import {TextCellEditorComponent} from './data-table/editors/text-cell-editor.component';
import {CellFilterComponent} from './data-table/filters/cell-filter.component';
import {IntegerCellFilterComponent} from './data-table/filters/integer-cell-filter.component';
import {TextCellFilterComponent} from './data-table/filters/text-cell-filter.component';
import {BooleanCellRendererComponent} from './data-table/renderer/boolean-cell-renderer.component';
import {CellRendererComponent} from './data-table/renderer/cell-renderer.component';
import {DateCellRendererComponent} from './data-table/renderer/date-cell-renderer.component';
import {DecimalCellRendererComponent} from './data-table/renderer/decimal-cell-renderer.component';
import {FlagCellRendererComponent} from './data-table/renderer/flag-cell-renderer.component';
import {IntegerCellRendererComponent} from './data-table/renderer/integer-cell-renderer.component';
import {IssuerCellRendererComponent} from './data-table/renderer/issuer-cell-renderer.component';
import {LandCellRendererComponent} from './data-table/renderer/land-cell-renderer.component';
import {PreisWertCellRendererComponent} from './data-table/renderer/preis-wert-cell-renderer.component';
import {TextCellRendererComponent} from './data-table/renderer/text-cell-renderer.component';
import {URLCellRendererComponent} from './data-table/renderer/urlcell-renderer.component';
import {SortButtonsComponent} from './data-table/sort-buttons.component';
import {AnredeFieldComponent} from './dynamic-form/anrede-field.component';
import {BooleanFieldComponent} from './dynamic-form/boolean-field.component';
import {CheckBoxComponent} from './dynamic-form/check-box.component';
import {CountriesSelectorComponent} from './dynamic-form/countries-selector.component';
import {CountryFieldComponent} from './dynamic-form/country-field.component';
import {CurrencyFieldComponent} from './dynamic-form/currency-field.component';
import {DateTimeFieldComponent} from './dynamic-form/date-time-field.component';
import {DurationFieldComponent} from './dynamic-form/duration-field.component';
import {FileDropZoneDirective} from './dynamic-form/file-drop-zone.directive';
import {FlagFieldComponent} from './dynamic-form/flag-field.component';
import {FormFieldDirective} from './dynamic-form/form-field.directive';
import {IssuerFieldComponent} from './dynamic-form/issuer-field.component';
import {IssuerInlineFieldComponent} from './dynamic-form/issuer-inline-field.component';
import {MEDateTimePickerComponent} from './dynamic-form/me-date-time-picker/medate-time-picker.component';
import {MEHighlitePipe, MESelectComponent} from './dynamic-form/me-select/meselect.component';
import {MEDurationFieldComponent} from './dynamic-form/meduration-field/meduration-field.component';
import {MEDurationPipe} from './dynamic-form/meduration-field/meduration.pipe';
import {MEMultiSelectItemComponent} from './dynamic-form/memulti-select/memulti-select-item.component';
import {MEMultiSelectComponent} from './dynamic-form/memulti-select/memulti-select.component';
import {MultiLineFieldComponent} from './dynamic-form/multi-line-field.component';
import {NumFieldComponent} from './dynamic-form/num-field.component';
import {PasswordFieldComponent} from './dynamic-form/password-field.component';
import {SelectFieldOptionDirective} from './dynamic-form/select-field/select-field-option.directive';
import {SelectFieldComponent} from './dynamic-form/select-field/select-field.component';
import {TextFieldComponent} from './dynamic-form/text-field.component';
import {TimeFieldComponent} from './dynamic-form/time-field.component';
import {ElementListComponent} from './element-list/element-list.component';
import {ElementRendererComponent} from './element-list/element-renderer.component';
import {GenericElementRendererComponent} from './element-list/generic-element-renderer.component';
import {GenericModalComponent} from './generic-modal.component';
import {IssuerDisplayComponent} from './issuers/display/issuer-display.component';
import {IssuerInfoComponent} from './issuers/display/issuer-info.component';
import {IssuerQuickInfoComponent} from './issuers/display/issuer-quick-info.component';
import {IssuerEditorComponent} from './issuers/editor/issuer-editor.component';
import {TabIssuerComponent} from './issuers/tab-issuer.component';
import {CountryElementRendererComponent} from './laender/country-element-renderer/country-element-renderer.component';
import {CountryQuickInfoComponent} from './laender/display/country-quick-info.component';
import {TabLaenderComponent} from './laender/tab-laender.component';
import {EmailDisplayComponent} from './layouttools/email-display.component';
import {SpalteComponent} from './layouttools/spalte.component';
import {ZeileComponent} from './layouttools/zeile.component';
import {LoadingIndicatorComponent} from './loading-indicator/loading-indicator.component';
import {MEInfoToolComponent} from './meinfo-tool/meinfo-tool.component';
import {MESaveHtmlPipe} from './mesave-html.pipe';
import {MESaveStylesPipe} from './mesave-styles.pipe';
import {PaginatorFilterDialogRendererComponent} from './paginator/paginator-filter-dialog-renderer.component';
import {PaginatorComponent} from './paginator/paginator.component';
import {PubSubService} from './pub-sub.service';
import {ShortenPipe} from './shorten.pipe';
import {SprachenService} from './sprachen/common/sprachen.service';
import {LanguageFieldComponent} from './sprachen/controls/language-field/language-field.component';
import {LanguageInlineFieldComponent} from './sprachen/controls/language-field/language-inline-field.component';
import {SprachenEditorComponent} from './sprachen/controls/sprachen-editor/sprachen-editor.component';
import {TabSprachenComponent} from './sprachen/controls/tab-sprachen/tab-sprachen.component';
import {TabUebersetzungenComponent} from './sprachen/controls/tab-uebersetzungen/tab-uebersetzungen.component';
import {TranslationEditorComponent} from './sprachen/controls/translation-editor/translation-editor.component';
import {SysInfoService} from './sysinfo/services/sys-info.service';
import {SystemSettingsService} from './system-settings.service';
import {PaneComponent} from './tabcard/pane.component';
import {TabcardComponent} from './tabcard/tabcard.component';
import {TabsetComponent} from './tabcard/tabset.component';
import {TabWaehrungenComponent} from './waehrungen/tab-waehrungen.component';
import {WaehrungCellEditorComponent} from './waehrungen/waehrung-cell-editor/waehrung-cell-editor.component';
import {WaehrungCellRendererComponent} from './waehrungen/waehrung-cell-renderer/waehrung-cell-renderer.component';
import {WaehrungQuickInfoComponent} from './waehrungen/waehrung-quick-info/waehrung-quick-info.component';

const other = [
    BytesPipe,
    MEDurationPipe,
    MEHighlitePipe,
    ShortenPipe,
    DataTableRowAction,
    FileDropZoneDirective,
    FormFieldDirective,
    SelectFieldOptionDirective,
    ListOptionDirective,
    MESaveStylesPipe,
    MESaveHtmlPipe

];
const components = [
    AnredeDisplayComponent,
    AnredeEditorComponent,
    AnredeFieldComponent,
    AreaHeadingComponent,
    BooleanCellEditorComponent,
    BooleanCellRendererComponent,
    BooleanFieldComponent,

    CellEditorComponent,
    CellFilterComponent,
    CellRendererComponent,
    CheckBoxComponent,
    ConfirmDialogComponent,
    CountriesSelectorComponent,
    CountryFieldComponent,
    CountryQuickInfoComponent,
    CurrencyFieldComponent,
    DataTableAction,
    DataTableColumnDirective,
    DataTableComponent,
    DataTableExtraTableActionComponent,
    DataTableExtraTableActionComponent,
    DataTableTableActionRowContainerComponent,
    DataTableTableExtraHeaderComponent,
    DateCellRendererComponent,
    DateTimeFieldComponent,
    DecimalCellRendererComponent,
    DurationFieldComponent,
    EmailDisplayComponent,
    FlagCellRendererComponent,
    FlagFieldComponent,
    GenericModalComponent,
    IntegerCellFilterComponent,
    IntegerCellRendererComponent,
    IssuerCellRendererComponent,
    IssuerDisplayComponent,
    IssuerEditorComponent,
    IssuerFieldComponent,
    IssuerInfoComponent,
    IssuerInlineFieldComponent,
    IssuerQuickInfoComponent,
    LandCellRendererComponent,
    //  LanguageSelectorComponent,
    LiterCellRendererComponent,
    LiterCellRendererComponent,
    LoadingIndicatorComponent,
    MEDateTimePickerComponent,
    MEDurationFieldComponent,
    MEInfoToolComponent,
    MEMultiSelectComponent,
    MEMultiSelectItemComponent,
    MESelectComponent,
    MultiLineFieldComponent,
    NumCellEditorComponent,
    NumFieldComponent,
    PaginatorComponent,
    PaneComponent,
    PasswordFieldComponent,
    PreisWertCellRendererComponent,
    SelectFieldComponent,
    SortButtonsComponent,
    SpalteComponent,
    SprachenEditorComponent,
    TabAnredenComponent,
    TabcardComponent,
    TabIssuerComponent,
    TabLaenderComponent,
    TabsetComponent,
    TabSprachenComponent,
    TabUebersetzungenComponent,
    TabWaehrungenComponent,
    TextCellEditorComponent,
    TextCellFilterComponent,
    TextCellRendererComponent,
    TextFieldComponent,
    TimeFieldComponent,
    TranslationEditorComponent,
    URLCellRendererComponent,
    VolumenDisplayComponent,
    ZeileComponent,
    DashboardInfoBlockComponent,
    PaginatorFilterDialogRendererComponent,
    DataTableFilterAreaComponent,
    ElementListComponent,
    ElementRendererComponent,
    GenericElementRendererComponent,
    DataTableFooterComponent,
    CountryElementRendererComponent,
    WaehrungCellRendererComponent,
    WaehrungQuickInfoComponent,
    WaehrungCellEditorComponent,
    LanguageFieldComponent,
    LanguageInlineFieldComponent
];

@NgModule({
              imports        : [
                  CommonModule,
                  FormsModule,
                  HttpClientModule,
                  AuthModule
              ],
              declarations   : [
                  ...components,
                  ...other
              ],
              exports        : [
                  ...components,
                  ...other
              ],
              entryComponents: [
                  ...components
              ]
          })
export class CommonsModule {

}
