import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tank-inline-field',
  templateUrl: './tank-inline-field.component.html',
  styleUrls: ['./tank-inline-field.component.scss']
})
export class TankInlineFieldComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
