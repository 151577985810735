import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Injector, OnInit} from '@angular/core';

@Component({
    selector: 'app-generic-element-renderer',
    template: '{{value}}',
    changeDetection: ChangeDetectionStrategy.OnPush

})
export class GenericElementRendererComponent {

    private _value: any = '';


    get value(): any {
        return this._value;
    }

    set value(value: any) {
        this._value = value;
        this.cd.markForCheck()
    }

    constructor(public injector: Injector,
                public cd: ChangeDetectorRef
    ) {
        this.value = this.injector.get('value');
    }

}
