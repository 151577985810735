import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'data-table-filter-area',
    template: '<ng-content></ng-content>'
})
export class DataTableFilterAreaComponent implements OnInit {
    @Input() id = '';

    constructor() {
    }

    ngOnInit() {
    }

}
