import {
    Component,
    ComponentFactoryResolver,
    ComponentRef,
    Input,
    ReflectiveInjector,
    TemplateRef,
    ViewContainerRef
} from '@angular/core';
import {DataTableRow} from './data-table-row';
import {TextCellRendererComponent} from './renderer/text-cell-renderer.component';
import {FlagCellRendererComponent} from './renderer/flag-cell-renderer.component';
import {TextCellFilterComponent} from './filters/text-cell-filter.component';
import {DataTableComponent} from './data-table.component';
import {IntegerCellFilterComponent} from './filters/integer-cell-filter.component';
import {IntegerCellRendererComponent} from './renderer/integer-cell-renderer.component';
import {TextCellEditorComponent} from './editors/text-cell-editor.component';
import {NumCellEditorComponent} from './editors/num-cell-editor.component';
import {BooleanCellRendererComponent} from './renderer/boolean-cell-renderer.component';
import {BooleanCellEditorComponent} from './editors/boolean-cell-editor.component';
import {DateCellRendererComponent} from './renderer/date-cell-renderer.component';
import {DecimalCellRendererComponent} from './renderer/decimal-cell-renderer.component';
import {ICellRenderer} from './renderer/common-cell-renderer';
import {URLCellRendererComponent} from './renderer/urlcell-renderer.component';
import {DeprecatedException} from '../../../shared/deprecated-exception';
import {ISimpleFilter} from '../simple-filter';


export interface IFilterDefInputs {
    field: string;
    placeholder: string;
    value: any;
    onChange: any;
    column: DataTableColumnDirective;
}

export interface IFilterDef {
    component: any;
    inputs: IFilterDefInputs;
}

export interface IEditorDefInputs {
    value: any;
    column: DataTableColumnDirective;
    onChange: any;
    addOns: boolean;
    addOnLabels: string[];
    addOnIcons: string[];
    onAddOnClick: any;
    row: DataTableRow<any>;
}

export interface IEditorDef {
    component: any;
    inputs: IEditorDefInputs;

}

export interface IRendererDefInputs {
    cell: any;
    row: any;
    column: DataTableColumnDirective;
}

export interface IRendererDef {
    component: any;
    inputs: IRendererDefInputs;
}

@Component({
    template: '',
    selector: 'column',
    entryComponents: [
        FlagCellRendererComponent,
        TextCellRendererComponent,
        IntegerCellRendererComponent,
        BooleanCellRendererComponent,
        DateCellRendererComponent,
        DecimalCellRendererComponent,
        URLCellRendererComponent
    ]
})
export class DataTableColumnDirective {

    @Input() editor: any = null;
    @Input() filter: any = null;
    @Input() type = 'generic';
    @Input() field = '';
    @Input() label = '';
    @Input() titleField = '';
    @Input() title = '';
    @Input() renderer: any = null;
    @Input() cfg: any = null;
    @Input() sortable = false;
    @Input() filterable = false;
    @Input() editable = false;
    @Input() xtraClasses: string = null;
    @Input() public visible = true;
    @Input() templateRef:TemplateRef<any>=null;

    public get fieldName() {
        if (this.field.indexOf('~') > 0) {
            const p = this.field.split('~');
            return p[0];
        }
        return this.field;
    }

    public get subFieldName() {
        if (this.field.indexOf('~') > 0) {
            const p = this.field.split('~');
            return p[1];
        }
        return '';
    }

    public extraClasses(): string {

        if (this.xtraClasses == null) {

            this.xtraClasses = '';

            const rd = this.getRenderer(null, null);

            const inputProviders = Object.keys(rd.inputs).map((inputName) => {
                return {provide: inputName, useValue: rd.inputs[inputName]};
            });
            const resolvedInputs = ReflectiveInjector.resolve(inputProviders);
            const injector = ReflectiveInjector.fromResolvedProviders(resolvedInputs, this._view.injector);
            const factory = this.resolver.resolveComponentFactory(rd.component);
            const renderer = factory.create(injector);

            const instance = (renderer as ComponentRef<ICellRenderer>).instance as ICellRenderer;
            if ((instance.XtraClasses !== undefined) && (instance.XtraClasses !== null)) {
                this.xtraClasses = instance.XtraClasses();
            }
        }

        return this.xtraClasses;
    }


    constructor(public resolver: ComponentFactoryResolver, public _view: ViewContainerRef) {
    }

    public displayLabel(): string {
        if (this.label !== undefined) {
            if (this.label !== null) {
                if (this.label !== '') {
                    return this.label;
                }
            }
        }
        return this.field;
    }

    public displayTitle(row?: DataTableRow<any>): string {
        if (this.titleField !== '') {
            if ((row !== undefined) && (row !== null)) {
                return ('' + row.data[this.titleField]);
            }
        }
        return this.title;
    }

    isSortable(): boolean {
        return this.sortable;
    }

    isFilterable(): boolean {
        return this.filterable;
    }

    isEditable(): boolean {
        return this.editable;
    }

    isString(): boolean {
        return this.type.toLowerCase() === 'text';
    }

    isFlag(): boolean {
        return this.type.toLowerCase() === 'flag';
    }

    hasTitle(): boolean {
        if (this.titleField !== '') {
            return true;
        }
        if (this.title !== '') {
            return true;
        }
    }

    getRenderer(cell?: any, row?: any) {
        const rd: IRendererDef = {
            component: TextCellRendererComponent,
            inputs: {
                cell: cell,
                row: row,
                column: this
            }
        };


        if ((this.renderer !== undefined) && (this.renderer !== null)) {
            rd.component = this.renderer;
        } else if (this.isFlag()) {
            rd.component = FlagCellRendererComponent;
        } else if (this.type.toLowerCase() === 'integer') {
            rd.component = IntegerCellRendererComponent;
        } else if ((this.type === 'boolean') || (this.type === 'Boolean')) {
            rd.component = BooleanCellRendererComponent;
        } else if (this.type === 'Date') {
            rd.component = DateCellRendererComponent;
        } else if (this.type.toLowerCase() === 'number' || this.type === 'Decimal') {
            rd.component = DecimalCellRendererComponent;
        } else if (this.type === 'URL') {
            rd.component = URLCellRendererComponent;
        } else if (this.type !== 'generic' && this.type !== '' && this.type !== 'text') {
            throw new DeprecatedException('Renderer for ' + this.type + ' not supported ');
        }

        return rd;
    }


    getFilter(filter: ISimpleFilter<any>, dt: DataTableComponent): IFilterDef {
        if (!this.filterable) {
            return null;
        }

        if (filter === undefined) {
            return null;

        }

        if (filter === null) {
            return null;
        }

        if (filter[this.field] === undefined) {
            return null;
        }

        const ft: IFilterDef = {
            component: TextCellFilterComponent,
            inputs: {
                field: this.field,
                placeholder: this.displayLabel(),
                value: filter[this.field],
                onChange: dt.filterChanged,
                column: this
            }
        };

        if ((this.filter !== undefined) && (this.filter !== null)) {
            ft.component = this.filter;
        } else if ((this.type !== undefined) && (this.type !== null) && (this.type !== 'generic') && this.type !== '' && this.type !== 'text') {
            if (this.type.toLowerCase() === 'integer') {
                ft.component = IntegerCellFilterComponent;
            } else {
                throw new DeprecatedException('Filter for ' + this.type + ' is not supportet');
            }
        }

        return ft;

    }


    getEditor(field?: any, dt?: DataTableComponent, row?: DataTableRow<any>) {

        const rd: IEditorDef = {
            component: TextCellEditorComponent,
            inputs: {
                value: field,
                column: this,
                onChange    : dt.onEditorChanged,
                addOns: false,
                addOnLabels: [],
                addOnIcons: [],
                onAddOnClick: dt.onEditorAddOnClicked,
                row: row
            }
        };

        if ((this.cfg !== undefined) &&
            (this.cfg !== null) &&
            (this.cfg.addOns !== undefined) &&
            (this.cfg.addOns !== null) &&
            Array.isArray(this.cfg.addOns)) {
            this.cfg.addOns.forEach(a => {
                rd.inputs.addOns = true;
                rd.inputs.addOnLabels.push(a[0]);
                rd.inputs.addOnIcons.push(a[1]);
            });
        }

        if (this.editor !== null && this.editor !== undefined) {
            rd.component = this.editor;
        } else if (this.type.toLowerCase() === 'integer' || this.type.toLowerCase() === 'decimal' || this.type.toLowerCase() === 'number') {
            rd.component = NumCellEditorComponent;
        } else if (this.type.toLowerCase() === 'boolean') {
            rd.component = BooleanCellEditorComponent;
        } else if (this.type !== 'generic' && this.type !== '' && this.type !== 'text') {
            throw new DeprecatedException('Editor for ' + this.type + ' is not supported');
        }

        return rd;
    }

    hasTemplate(){
        return this.templateRef!==null && this.templateRef!==undefined;
    }

}
