<app-login-alert>
    <app-area-heading
            [icon]="'icofont icofont-law-document'"
            title="{{ _('Rechnungen') }}"
    ></app-area-heading>


    <div class="card">
        <div class="card-body">
            <div class="card-text">
                <data-table
                        [selectable]="false"
                        [data]="rechnungen.asDataTableRows()"
                        [orders]="rechnungen.order"
                        [filter]="rechnungen.simpleFilter"
                        (sortChanged)="sortChanged($event)"
                        (filterChanged)="filterChanged($event)"
                        [rowActionsAsButtons]="true"
                        [tableActionsAsButtons]="true"
                >
                    <tableaction
                            [label]="_('Rechnung einbuchen')"
                            [icon]="'icofont icofont-ui-add'"
                            (perform)="newInvoice()"
                            *hasRole="['ROLE_ADMIN','ROLE_PROJECT_ADMIN','ROLE_BILLING']"
                    ></tableaction>
                    <column
                            [field]="'nummer'"
                            [label]="_('#')"
                    ></column>
                    <column
                            [field]="'kunde'"
                            [label]="_('Kunde')"
                            [renderer]="kundeR"
                            [sortable]="true"
                    ></column>
                    <column [field]="'rechnungNummer'"
                            [label]="_('Rechnungsnummer')"
                            [sortable]="true"
                    ></column>
                    <column
                            [field]="'rechnungDatum'"
                            [label]="_('Datum')"
                            type="Date"
                            [cfg]="{format:'shortDate'}"
                            [sortable]="true"></column>
                    <column
                            [field]="'summe'"
                            [label]="_('Endbetrag')"
                            [renderer]="preiswert"
                            [cfg]="{fdigits:2,round:true,currency:'EUR'}"
                            [sortable]="true"></column>

                    <column
                            *ngIf="false"
                            [field]="'status'"
                            [label]="_('Status')"
                            [renderer]="reStR"
                            [sortable]="true"></column>

                    <column
                            [field]="'attachments'"
                            [label]="_('Dokumente')"
                            [renderer]="attachmentsR"
                            [sortable]="true"></column>

                    <rowaction
                            [label]="_('Details')"
                            [icon]="'far fa-info-circle'"
                            [title]="_('Details anzeigen')"
                            (perform)="showDetails($event)"
                    ></rowaction>
                    <rowaction
                            [label]="_('Bearbeiten')"
                            [icon]="'far fa-pencil'"
                            [title]="_('Rechnung bearbeiten')"
                            (perform)="editRechnung($event)"
                            *hasRole="['ROLE_ADMIN','ROLE_PROJECT_ADMIN','ROLE_BILLING']"
                    ></rowaction>

                    <data-table-table-extra-header>
                        <app-paginator
                                [total]="rechnungen.total"
                                [page]="rechnungen.page"
                                [perpage]="rechnungen.size"
                                (pageChange)="updateRechnungen(true,$event)"
                                (pageCountChange)="updateRechnungenPageCount($event)"
                        ></app-paginator>
                    </data-table-table-extra-header>

                </data-table>

            </div>
        </div>
    </div>


</app-login-alert>