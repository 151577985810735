import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {Kunde} from '../common/kunde';
import {NgbActiveModal, NgbModal, NgbModalOptions, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';

import {KundenService} from '../common/kunden.service';

import {LocalizedComponent} from '../../../commons/components/localized-component';
import {ToastrService} from 'ngx-toastr';

@Component({
    selector: 'app-kunde-self-editor',
    templateUrl: './kunde-self-editor.component.html',
    styleUrls: ['./kunde-self-editor.component.css']
})
export class KundeSelfEditorComponent extends LocalizedComponent implements OnInit {

    currentKunde: Kunde = new Kunde();


    static open(modalService: NgbModal, kunde: Kunde): Promise<any> {
        const o: NgbModalOptions = {
            backdrop: 'static'
        };
        const r: NgbModalRef = modalService.open(KundeSelfEditorComponent, o);
        (r.componentInstance as KundeSelfEditorComponent).open(kunde);
        return r.result;
    }


    constructor(
                
                public activeModal: NgbActiveModal,
                public kundeService: KundenService,
                public toastr: ToastrService,
                public cd: ChangeDetectorRef) {
        super( cd);
    }

    open(kunde: Kunde) {
        if ((kunde === null) || (kunde === undefined)) {
            this.activeModal.dismiss();
        } else {
            this.currentKunde = Kunde.fromResult(kunde);
        }
    }

    ngOnInit() {
    }

    cancel() {
        this.activeModal.close();
    }

    save() {
        this.kundeService
            .store(
                this.currentKunde, false
            )
            .subscribe(
                resp => {
                    if (resp.success) {
                        this.activeModal.close();
                    } else {
                        this.toastr.error(this.t('Beim Speichern der Einstellungen ist ein Fehler aufgetreten.'));
                    }
                },
                () => {
                    this.toastr.error(this.t('Beim Speichern der Einstellungen ist ein Fehler aufgetreten.'));
                }
            );
    }

    updateBegrenzungen(bg) {
        this.currentKunde.begrenzungen = bg;
    }

}
