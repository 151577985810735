import {Component, Input, OnInit} from '@angular/core';
import {Zapfsaeule} from '../common/zapfsaeule';
import {Zapfpunkt} from '../common/zapfpunkt';

@Component({
    selector: 'app-saeule-zapfpunkt-quick-info',
    templateUrl: './saeule-zapfpunkt-quick-info.component.html',
    styleUrls: ['./saeule-zapfpunkt-quick-info.component.scss']
})
export class SaeuleZapfpunktQuickInfoComponent implements OnInit {

    _saeule: Zapfsaeule = null;

    constructor() {
    }

    _zapfpunkt: Zapfpunkt = null;

    @Input('zapfpunkt')
    set zapfpunkt(value: Zapfpunkt) {
        this._zapfpunkt = Zapfpunkt.fromResult(value);
        if (this._zapfpunkt !== null && this._zapfpunkt !== undefined) {
            if (this._saeule === null || this._saeule === undefined) {
                this.zapfsaeule = this._zapfpunkt.zapfsaeule;
            }
        }
    }

    @Input('zapfsaeule')
    set zapfsaeule(value: Zapfsaeule) {
        this._saeule = Zapfsaeule.fromResult(value);
    }

    ngOnInit() {
    }

}
