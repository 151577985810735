import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
               selector: 'check-box',
               template: `
                   <i class="far"
                      [class.fa-check-square]="_value"
                      [class.fa-square]="!_value"
                      [class.disabled]="disabled"
                      (click)="toggle()"
                      [title]="title"
                   ></i>
               `,
               styles  : [
                       `
                       :host i {
                           cursor: pointer;
                       }

                       :host i.disabled {
                           cursor: not-allowed;
                       }
                       `
               ]
           })
export class CheckBoxComponent implements OnInit {

    @Input()
    disabled = false;

    @Input()
    title = '';

    _value = false;

    @Output()
    valueChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    @Input()
    set value(v: boolean) {
        if (v === null || v === undefined) {
            v = false;
        }
        if (this._value !== v) {
            this._value = v;
            if (!this.disabled) {
                this.valueChange.emit(v);
            }
        }
    }

    get value(): boolean {
        return this._value;
    }


    constructor() {
    }

    ngOnInit() {
    }

    toggle() {
        if (!this.disabled) {
            this.value = !this.value;
        }
    }

}
