import {IKontingent} from './ikontingent';
import {ConverterTool} from '../../../../shared/converter-tool';

export class Kontingent implements IKontingent {
    transaktionenPrepaid = 0;
    mengePrepaid = 0;
    betragPrepaid = 0;
    saldoTransaktionenPrepaid = 0;
    saldoMengePrepaid = 0;
    saldoBetragPrepaid = 0;
    isPrepaid = false;
    transaktionenTag = 0;
    transaktionenWoche = 0;
    transaktionenMonat = 0;
    mengeTag = 0;
    mengeWoche = 0;
    mengeMonat = 0;
    betragTag = 0;
    betragWoche = 0;
    betragMonat = 0;
    saldoTransaktionenTag = 0;
    saldoTransaktionenWoche = 0;
    saldoTransaktionenMonat = 0;
    saldoMengeTag = 0;
    saldoMengeWoche = 0;
    saldoMengeMonat = 0;
    saldoBetragTag = 0;
    saldoBetragWoche = 0;
    saldoBetragMonat = 0;
    betragHalbMonat = 0;
    mengeHalbMonat = 0;
    saldoBetragHalbMonat = 0;
    saldoMengeHalbMonat = 0;
    saldoTransaktionenHalbMonat = 0;
    transaktionenHalbMonat = 0;
    letzteSaldoAktualisierung: Date = null;
    letzteLimitAenderung: Date = null;
    maxVorautorisierungsbetrag = 0;


    static fromResult(entity: IKontingent): Kontingent {
        if ((entity === null) || (entity === undefined)) {
            return null;
        }

        let l: Kontingent = new Kontingent();
        for (let k of Object.keys(l)) {
            if (k === 'letzteLimitAenderung') {
                l.letzteLimitAenderung = ConverterTool.toDate(entity.letzteLimitAenderung);
            } else if (k === 'letzteSaldoAktualisierung') {
                l.letzteSaldoAktualisierung = ConverterTool.toDate(entity.letzteSaldoAktualisierung);
            } else if (k === 'isPrepaid') {
                l.isPrepaid = ConverterTool.toBoolean(entity.isPrepaid);
            } else {
                l[k] = (entity[k] !== undefined) ? entity[k] : '';
            }
        }
        return l;
    }


    constructor() {
    }

    getVerfuegbarTransaktionenTag() {
        if (this.transaktionenTag <= 0) {
            return 'infinite';
        }
        return this.transaktionenTag - this.saldoTransaktionenTag;
    }

    getVerfuegbarTransaktionenWoche() {
        if (this.transaktionenWoche <= 0) {
            return 'infinite';
        }
        return this.transaktionenWoche - this.saldoTransaktionenWoche;
    }

    getVerfuegbarTransaktionenMonat() {
        if (this.transaktionenMonat <= 0) {
            return 'infinite';
        }

        return this.transaktionenMonat - this.saldoTransaktionenMonat;
    }

    getVerfuegbarTransaktionenHalbMonat() {
        if (this.transaktionenHalbMonat <= 0) {
            return 'infinite';
        }

        return this.transaktionenHalbMonat - this.saldoTransaktionenHalbMonat;
    }


    getVerfuegbarTransaktionenPrepaid() {
        if (this.transaktionenPrepaid <= 0) {
            return 'infinite';
        }
        return this.transaktionenPrepaid - this.saldoTransaktionenPrepaid;
    }


    getVerfuegbarBetragTag() {
        if (this.betragTag <= 0) {
            return 'infinite';
        }
        return this.betragTag - this.saldoBetragTag;
    }

    getVerfuegbarBetragWoche() {
        if (this.betragWoche <= 0) {
            return 'infinite';
        }
        return this.betragWoche - this.saldoBetragWoche;
    }

    getVerfuegbarBetragMonat() {
        if (this.betragMonat <= 0) {
            return 'infinite';
        }

        return this.betragMonat - this.saldoBetragMonat;
    }

    getVerfuegbarBetragHalbMonat() {
        if (this.betragHalbMonat <= 0) {
            return 'infinite';
        }

        return this.betragHalbMonat - this.saldoBetragHalbMonat;
    }


    getVerfuegbarBetragPrepaid() {
        if (this.betragPrepaid <= 0) {
            return 'infinite';
        }
        return this.betragPrepaid - this.saldoBetragPrepaid;
    }


    getVerfuegbarMengeTag() {
        if (this.mengeTag <= 0) {
            return 'infinite';
        }
        return this.mengeTag - this.saldoMengeTag;
    }

    getVerfuegbarMengeWoche() {
        if (this.mengeWoche <= 0) {
            return 'infinite';
        }
        return this.mengeWoche - this.saldoMengeWoche;
    }

    getVerfuegbarMengeMonat() {
        if (this.mengeMonat <= 0) {
            return 'infinite';
        }

        return this.mengeMonat - this.saldoMengeMonat;
    }

    getVerfuegbarMengeHalbMonat() {
        if (this.mengeHalbMonat <= 0) {
            return 'infinite';
        }

        return this.mengeHalbMonat - this.saldoMengeHalbMonat;
    }


    getVerfuegbarMengePrepaid() {
        if (this.mengePrepaid <= 0) {
            return 'infinite';
        }
        return this.mengePrepaid - this.saldoMengePrepaid;
    }

    hasLimits() {
        if (this.transaktionenTag > 0) {
            return true;
        }
        if (this.transaktionenWoche > 0) {
            return true;
        }
        if (this.transaktionenMonat > 0) {
            return true;
        }
        if (this.transaktionenHalbMonat > 0) {
            return true;
        }
        if (this.transaktionenPrepaid > 0) {
            return true;
        }


        if (this.mengeTag > 0) {
            return true;
        }
        if (this.mengeWoche > 0) {
            return true;
        }
        if (this.mengeMonat > 0) {
            return true;
        }
        if (this.mengeHalbMonat > 0) {
            return true;
        }
        if (this.mengePrepaid > 0) {
            return true;
        }

        if (this.betragTag > 0) {
            return true;
        }
        if (this.betragWoche > 0) {
            return true;
        }
        if (this.betragMonat > 0) {
            return true;
        }
        if (this.betragHalbMonat > 0) {
            return true;
        }
        if (this.betragPrepaid > 0) {
            return true;
        }

        return false;

    }


}
