<div *ngIf="device.isMELoragateway()">
  <text-field [label]="_('IP Adresse')" [(value)]="device.settings.ip"></text-field>
  <num-field [label]="_('Port')" [(value)]="device.settings.port"></num-field>
  <text-field [label]="_('Server')" [(value)]="device.settings.server"></text-field>
  <num-field *appIsAdmin [label]="_('SSH-Port')"
             [(value)]="device.settings.ssh_port"></num-field>
  <text-field *appIsAdmin [label]="_('SSH-User')"
              [(value)]="device.settings.ssh_user"></text-field>
  <password-field *appIsAdmin [label]="_('SSH-Passwort')"
                  [(value)]="device.settings.ssh_password"></password-field>
  <terminal-field [label]="_('Terminal')" [station]="station"
                  [(value)]="device.settings.terminal"></terminal-field>
  <text-field [label]="_('MAC-Adresse')" [(value)]="device.settings.mac"></text-field>
  <num-field [label]="_('Kunde-ID')" [(value)]="device.settings.customer_id"
             *appIsAdmin></num-field>
</div>
