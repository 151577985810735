<div *ngIf="sysInfoService.isLoggedIn() && hasStats()" class="card-deck align-items-stretch mb-3">

    <div class="card" *ngIf="sysInfoService.seesTransactions()">
        <div class="card-body">
            <div class="text-right bb">
                <h1>{{ umsatzLabel }}<br/><small>{{_('im aktuellen Monat')}}</small></h1>
                <div class="value">
                    {{ fCurrency(getUmsatz(), 'EUR') }}
                    <i class="font-weight-bold far"
                       [ngClass]="getTrendClasses('umsatz')"
                       title="{{_('Trend im Vergleich zum Vormonat') }}"
                    ></i>
                </div>
            </div>
            <div class="vm">
                {{_('Vormonat:') }} {{ fCurrency(getUmsatz(false), 'EUR') }}
            </div>
        </div>
    </div>

    <div class="card">
        <div class="card-body">
            <div class="text-right bb">
                <h1>{{_('Transaktionen')}}<br/><small>{{_('im aktuellen Monat')}}</small></h1>
                <div class="value">
                    {{ fNum(getTransactions(), '1.0-0') }}
                    <i class="font-weight-bold far"
                       [ngClass]="getTrendClasses('transaktionen')"
                       title="{{_('Trend im Vergleich zum Vormonat') }}"
                    ></i>
                </div>
            </div>
            <div class="vm">
                {{_('Vormonat:') }} {{ fNum(getTransactions(false), '1.0-0') }}
            </div>
        </div>
    </div>

    <div class="card" *ngIf="false">
        <div class="card-body">
            <div class="text-right bb">
                <h1>{{_('Masse')}}<br/><small>{{_('im aktuellen Monat')}}</small></h1>
                <div class="value">
                    {{ fNum(getKgs(), '1.2-2') }} kg
                    <i class="font-weight-bold far"
                       [ngClass]="getTrendClasses('kgs')"
                       title="{{_('Trend im Vergleich zum Vormonat') }}"
                    ></i>
                </div>
            </div>
            <div class="vm">
                {{_('Vormonat:') }} {{ fNum(getKgs(false), '1.2-2') }} kg
            </div>
        </div>
    </div>

    <div class="card">
        <div class="card-body">
            <div class="text-right bb">
                <h1>{{_('Menge')}}<br/><small>{{_('im aktuellen Monat')}}</small></h1>
                <div class="value">
                    {{ fNum(getLiters(), '1.2-2') }} l
                    <i class="font-weight-bold far"
                       [ngClass]="getTrendClasses('l')"
                       title="{{_('Trend im Vergleich zum Vormonat') }}"
                    ></i>
                </div>
            </div>
            <div class="vm">
                {{_('Vormonat:') }} {{ fNum(getLiters(false), '1.2-2') }} l
            </div>
        </div>
    </div>

</div>
