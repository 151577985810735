import {ChangeDetectorRef, Component, NgZone, OnInit, ViewChild} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';


import {Kunde} from './common/kunde';
import {KundeList} from './common/kunde-list';
import {KundenService} from './common/kunden.service';
import {KundeEditorComponent} from './kunde-editor/kunde-editor.component';
import {LocalizedComponent} from '../../commons/components/localized-component';
import {KitchenTimer} from '../../commons/kitchen-timer';
import {NewPageCountEvent} from '../../commons/paginator/paginator.component';
import {DataTableComponent, DataTableFilterChanged, DataTableSortChanged} from '../../commons/data-table/data-table.component';
import {ResultMetaSortImpl} from '../../commons/result-meta';
import {LandCellRendererComponent} from '../../commons/data-table/renderer/land-cell-renderer.component';
import {SysInfoService} from '../../commons/sysinfo/services/sys-info.service';
import {ToastrService} from 'ngx-toastr';
import {IListResult} from '../../commons/list-result';
import {IKunde} from './common/ikunde';
import {DTKostenstelleRendererComponent} from '../kostenstellen/dtkostenstelle-renderer/dtkostenstelle-renderer.component';
import {KundeFilterDialogComponent} from './kunde-filter-dialog/kunde-filter-dialog.component';
import {DataTableRow} from '../../commons/data-table/data-table-row';
import {TDTGetExtraRowClassesCallback} from '../../commons/data-table/types';
import {ConfirmDialogComponent} from '../../commons/confirm-dialog/confirm-dialog.component';
import {noop} from '../../commons/noop';
import * as FileSaver from 'file-saver';
import {LoginService} from '../../auth/services/login.service';
import {RowActionParams} from '../../commons/data-table/row-action-params';

@Component({
    selector: 'app-kunden',
    templateUrl: './kunden.component.html',
    styleUrls: ['./kunden.component.css']
})
export class KundenComponent extends LocalizedComponent implements OnInit {

    landR = LandCellRendererComponent;
    kostenstelleR = DTKostenstelleRendererComponent;

    kunden: KundeList = new KundeList();
    kundenTimer: KitchenTimer;

    @ViewChild('kundenListe',{static: true}) kundenListe: DataTableComponent;
    @ViewChild('filterDialog',{static: true}) filterDialog: KundeFilterDialogComponent;


    kundenRowClassGetter: TDTGetExtraRowClassesCallback<Kunde> = (row: DataTableRow<Kunde>) => {
        if (
            row !== null &&
            row !== undefined &&
            row.data !== null &&
            row.data !== undefined
        ) {
            return 'kunde-' + (row.data.enabled ? 'frei' : 'gesperrt');
        }
        return '';
    }

    constructor(
                
                public kundenService: KundenService,
                public toastr: ToastrService,
                public zone: NgZone,
                public cd: ChangeDetectorRef, public sysInfoService: SysInfoService,
                public modalService: NgbModal,
                public loginService: LoginService
    ) {
        super( cd);

        this.kunden.size = this.loginService.getPageSize('kunden');

        this.kundenTimer = new KitchenTimer();
        this.kundenTimer
            .subscribe(
                () => {
                    this.kundenService
                        .list(
                            this.kunden.size,
                            this.kunden.calcOffset(),
                            this.kunden.order,
                            this.kunden.simpleFilter,
                            this.kunden.getQuery()
                        )
                        .subscribe(
                            (l: IListResult<IKunde>) => {
                                this.kunden.populateFromListResult(l);
                            },
                            (e) => {
                                this.toastr.error(this._('Die Liste der Kunden konnte nicht geladen werden.'));
                                this.kunden.clear();
                            }
                        );
                }
            );
    }

    ngOnInit() {
        this.updateKunden();
        this.sysInfoService.updateDocumentTitle(this._('Kunden'));


    }

    updateKunden(force = false, page?: number) {
        if ((page !== undefined) && (page !== null)) {
            if (page !== this.kunden.page) {
                this.kunden.page = page;
                force = true;
            }
        }
        this.kundenTimer.start(force);
    }

    /**
     * ändert die Anzahl der Datensätze/Seite und lädt die Liste neu
     * @param n
     */
    updateKundenPageCount(n: NewPageCountEvent) {
        if (this.kunden.size !== n.itemsPerPage) {
            this.kunden.size = n.itemsPerPage;
            this.loginService.setPageSize('kunden', n.itemsPerPage);
        }
        this.updateKunden(true, n.currentPage);
    }

    sortChanged(e: DataTableSortChanged) {
        this.kunden.order = [
            new ResultMetaSortImpl(e.field, e.direction)
        ];
        this.updateKunden();
    }

    filterChanged(e: DataTableFilterChanged) {
        this.zone.run(() => {
            this.kunden.simpleFilter[e.field] = e.value;
            this.updateKunden();
        });
    }

    newKunde() {
        KundeEditorComponent
            .open(this.modalService)
            .then(
                () => {
                    this.updateKunden();
                },
                () => {
                    this.updateKunden();
                }
            );
    }

    editKunde(params: RowActionParams<Kunde>) {
        this.kundenService
            .loadKunde(params.row.data.id)
            .subscribe((k: IKunde) => {
                KundeEditorComponent
                    .open(this.modalService, Kunde.fromResult(k))
                    .then(
                        () => {
                            this.updateKunden();
                        },
                        () => {
                            this.updateKunden();
                        }
                    );

            });

    }

    syncCustomers() {
        this.kundenService
            .syncKunden()
            .subscribe(
                () => {
                    this.toastr.success(this._('Der Synchronisationsvorgang wurde angestoßen.'));
                },
                () => {
                    this.toastr.error(this._('Die Synchronisationsvorgang konnte nicht angestoßen werden.'));
                }
            );
    }

    kundenQueryChange(value = '') {
        if (value !== this.kunden.searchString) {
            this.kunden.searchString = value;
            this.updateKunden(false, 1);
        }
    }

    reindexKunden() {
        this.kundenService
            .reindexKunden()
            .subscribe(
                () => {
                    this.toastr.success(this._('Der Indizierungsvorgang wurde angestoßen.'));
                },
                () => {
                    this.toastr.error(this._('Die Indizierungsvorgang konnte nicht angestoßen werden.'));
                }
            );
    }

    syncRatioKunden() {
        this.kundenService
            .syncRatioKunden()
            .subscribe(
                () => {
                    this.toastr.success(this._('Der Importvorgang wurde angestoßen.'));
                },
                () => {
                    this.toastr.error(this._('Die Importvorgang konnte nicht angestoßen werden.'));
                }
            );
    }

    syncKunde(params: RowActionParams<Kunde>) {

        this.kundenService
            .syncKunde(params.row.data)
            .subscribe(
                () => {
                    this.toastr.success(this._('Der Syncvorgang wurde angestoßen.'));
                },
                () => {
                    this.toastr.error(this._('Der Syncvorgang konnte nicht angestoßen werden.'));
                }
            );

    }


    kundenFilterToggle(visible: boolean) {
        if (!visible) {
            this.kundenQueryChange2('');
        }
    }

    kundenQueryChange2(value: string) {
        if (value !== this.kunden.searchString2) {
            this.kunden.searchString2 = value;
            this.updateKunden(false, 1);
        }
    }

    downloadEntries(type: string, exporttype: number = 0) {
        if (this.kunden.total < 1) {
            this.toastr.error(this._(
                'Es werden keine Datensätze angezeigt, daher gibt es auch nichts zum Herunterladen.'));
        } else if (this.kunden.total < 500) {
            this.realDlEntries(type, exporttype);
        } else if (this.kunden.total > 25000) {
            this.toastr.error(
                this._('Sie wollen {{ anzahl }} Datensätze herunterladen. Da es erfahrungsgemäß ' +
                    'hierbei zu Downloadabbrüchen seitens des Webbrowsers kommt, bitten wir Sie,' +
                    'die Ergebnismenge zunächst einzugrenzen.', {anzahl: this.kunden.total}),
                this._('Aktion nicht möglich'),
                {
                    timeOut: 15000
                }
            );
        } else {
            ConfirmDialogComponent
                .display(
                    this.modalService,
                    this._('Download durchführen?'),
                    this._('Sie wollen {{ anzahl }} Datensätze herunterladen. Dies wird unter Umständen ' +
                        'eine längere Zeit dauern und je nach Browser zu einem Verbindungsabbruch führen. <br /><br /> ' +
                        'Wir empfehlen, die Liste zunnächst weiter einzuschränken.',
                        {anzahl: this.kunden.total}
                    ),
                    this._('Download trotzdem durchführen'),
                    'icofont icofont-download-alt'
                )
                .result
                .then(
                    () => {
                        this.realDlEntries(type, exporttype);
                    },
                    noop
                );
        }
    }

    private realDlEntries(type: string, exporttype: number) {
        this.toastr
            .info(this._('Download wird angefordert. Bitte einen Moment Geduld...'),
                this._('Aktion wird ausgeführt...'),
                {
                    timeOut: 15000
                }
            )
            .onShown
            .subscribe(
                () => {
                    this.kundenService.exportData(
                        type,
                        this.kunden.simpleFilter,
                        this.kunden.getQuery(),
                        exporttype
                    ).subscribe(
                        (response: Blob) => {
                            FileSaver.saveAs(response, this._('kunden.' + type));
                        },
                        (e) => {
                            this.toastr.error(this._('Die Liste der Kunden konnte nicht exportiert werden.'));
                        }
                    );
                });

    }
}
