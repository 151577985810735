import {Ausweis} from './ausweis';
import {IAusweis} from './iausweis';
import {IFahrzeug} from '../../fahrzeuge/common/ifahrzeug';
import {GenericFilter} from '../../../commons/generic-filter';
import {IKontingent} from '../../limits/common/ikontingent';
import {IBegrenzung} from '../../limits/common/ibegrenzung';
import {IPerson} from '../../personen/common/iperson';
import {IArtikelGruppe} from '../../../products/artikelgruppen/common/iartikel-gruppe';
import {IKunde} from '../../kunden/common/ikunde';
import {IIssuer} from '../../../commons/issuers/common/iissuer';
import {IProduktGruppe} from '../../../products/produktgruppen/common/iprodukt-gruppe';
import {IKostenstelle} from '../../kostenstellen/common/ikostenstelle';
import {IStation} from '../../../stationen/common/istation';

export class SimpleAusweisFilter extends GenericFilter<Ausweis> implements IAusweis {
    kontingent: IKontingent = null;
    begrenzungen: IBegrenzung[] = null;
    person: IPerson = null;
    fahrzeug: IFahrzeug = null;
    fremdausweis: boolean = null;
    tankserverID: number = null;
    ratioID: number = null;
    artikelGruppen: IArtikelGruppe[] = null;
    lastEdited: any = null;
    kunde: IKunde = null;
    info: string = null;
    issuer: IIssuer = null;
    id: number = null;
    nummer: number = null;
    pan: string = null;
    gueltigAb: any = null;
    gueltigBis: any = null;
    kmEingabe: boolean = null;
    kmUeberwachung: boolean = null;
    zusatzEingabe: boolean = null;
    pin: string = null;
    zusatzAusweisNotwendig: boolean = null;
    adminSperre: boolean = null;
    kundenSperre: boolean = null;
    maxKMDifferenz: number = null;
    maxVolumen: number = null;
    kostenstelle: IKostenstelle = null;
    dateCreated: any = null;
    lastUpdated: any = null;
    produktFreigaben: IProduktGruppe[] = null;
    ausweisArt: any = null;
    assignedToDriver: boolean = null;
    assignedToVehicle: boolean = null;
    bezeichnung: string = null;
    standort: IStation = null;

    public encodeField(fieldname: string): string {
        if (fieldname === 'kunde') {
            if (this.kunde != null) {
                if (this.kunde.id !== null && this.kunde.id !== undefined && this.kunde.id > 0) {
                    return 'filter.kunde=' + encodeURIComponent('' + this.kunde.id);
                }
            }
        }
        return super.encodeField(fieldname);
    }


}
