import {IPhrase} from './iphrase';

export class Phrase implements IPhrase {
    translations: Phrase[];
    original: string;
    source: string;
    id = '';
    language_desc = '';
    language_flag = '';
    key = '';
    translation = '';
    language = '';

    static fromResult(entity: IPhrase): Phrase {
        if ((entity === null) || (entity === undefined)) {
            return null;
        }

        let l: Phrase = new Phrase();
        for (let k of Object.keys(l)) {
            if (k === 'translations') {
                l.translations = [];
                if (entity.translations !== null && entity.translations !== undefined && Array.isArray(entity.translations)) {
                    entity.translations.forEach((p) => {
                        let h = Phrase.fromResult(p);
                        if (h !== null && h !== undefined) {
                            l.translations.push(h);
                        }
                    })
                }
            } else {
                l[k] = (entity[k] !== undefined) ? entity[k] : '';
            }
        }
        return l;
    }


    constructor(id = '',
                language_desc = '',
                language_flag = '',
                key = '',
                translation = '',
                language = '',
                source = '',
                original = '',
                translations: IPhrase[] = null) {
        this.id = id;
        this.language_desc = language_desc;
        this.language_flag = language_flag;
        this.key = key;
        this.translation = translation;
        this.language = language;
        this.source = source;
        this.original = original;
        this.translations = translations;
    }
}
