import {ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {VideoControlComponent} from './video-control/video-control.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {ToastrModule} from 'ngx-toastr';
import {FormsModule} from '@angular/forms';
import {CommonsModule} from '../commons/commons.module';
import {AuthModule} from '../auth/auth.module';

const components = [
    VideoControlComponent
];

@NgModule({
    imports: [
        CommonModule,
        NgbModule,
        FormsModule,
        ToastrModule.forRoot(),

        CommonsModule,
        AuthModule
    ],
    declarations: [
        ...components
    ],
    exports: [
        ...components
    ],
    entryComponents: [
        ...components
    ]
})
export class MEVideoControlModule {

}
