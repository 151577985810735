import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Geraet} from '../common/geraet';

import {LoginService} from '../../auth/services/login.service';
import {LocalizedComponent} from '../../commons/components/localized-component';

@Component({
    selector: 'app-device-staubsauger-details',
    templateUrl: './device-staubsauger-details.component.html',
    styleUrls: ['./device-staubsauger-details.component.scss']
})
export class DeviceStaubsaugerDetailsComponent extends LocalizedComponent implements OnInit {
    private _device: Geraet = null;

    @Input()
    set device(dev: Geraet) {
        if (this._device !== dev) {
            this._device = dev;
            this.cd.markForCheck();
        }
        console.log(this._device);
    }


    get device(): Geraet {
        return this._device;
    }

    @Output()
    onAction: EventEmitter<string> = new EventEmitter<string>();

    constructor(

                public cd: ChangeDetectorRef,
                public loginService: LoginService
    ) {
        super( cd);
    }

    ngOnInit() {
    }

    sendAction(action: string) {
        this.onAction.emit(action);
    }


}
