import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {Kunde} from '../common/kunde';

import {LocalizedComponent} from '../../../commons/components/localized-component';

@Component({
               selector   : 'app-kunde-info',
               templateUrl: './kunde-info.component.html',
               styleUrls  : [ './kunde-info.component.css' ]
           })
export class KundeInfoComponent extends LocalizedComponent implements OnInit {

    _kunde: Kunde = null;

    @Input()
    set kunde(value: Kunde) {
        this._kunde = Kunde.fromResult(value);
    }


    constructor( public cd:ChangeDetectorRef) {
        super(cd);
    }

    ngOnInit() {
    }

}
