import {IProduktGruppe} from './iprodukt-gruppe';
import {ProduktGruppe} from './produkt-gruppe';
import {CommonList} from '../../../commons/common-list';
import {SimpleProduktGruppeFilter} from './simple-produkt-gruppe-filter';
import {IListResult} from '../../../commons/list-result';
import {ResultMetaSortImpl} from '../../../commons/result-meta';

export class ProduktGruppeList extends CommonList<ProduktGruppe> {


    simpleFilter: SimpleProduktGruppeFilter = new SimpleProduktGruppeFilter();

    static fromListResult(res: IListResult<IProduktGruppe>): ProduktGruppeList {
        let newList: ProduktGruppeList = new ProduktGruppeList();
        newList.clear();
        newList.simpleFilter.clear();

        if (res.meta) {
            newList.total = res.meta.total || 0;
            newList.offset = res.meta.offset || 0;
            newList.size = res.meta.size || 0;
            newList.page = res.meta.page || 0;
            if (res.meta.order) {
                newList.order = [];
                for (let x of res.meta.order) {
                    newList.order.push(
                        new ResultMetaSortImpl(
                            x.field || 'nummer',
                            x.direction || 'asc'
                        )
                    );
                }
            }
        }
        if (res.data) {
            for (let row of res.data) {
                newList.add(ProduktGruppe.fromResult(row));
            }
        }
        if (res.simpleFilter) {
            let d: ProduktGruppe = new ProduktGruppe();
            for (let k of Object.keys(d)) {
                newList.simpleFilter[k] = (res.simpleFilter[k] !== undefined) ? res.simpleFilter[k] : null;
            }
        }

        return newList

    }

}
