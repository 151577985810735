import {Preisgruppe} from './preisgruppe';
import {IPreisgruppe} from './ipreisgruppe';
import {GenericFilter} from '../../commons/generic-filter';
import {IPreisgruppeSetting} from './ipreisgruppe-setting';

export class SimplePreisgruppeFilter extends GenericFilter<Preisgruppe> implements IPreisgruppe {
    gruppeArt: any = null;
    id: number = null;
    title: string = null;
    settings: IPreisgruppeSetting[] = null;

    constructor() {
        super();
    }
}
