import {IProduktGruppe} from './iprodukt-gruppe';
import {IProdukt} from '../../produkte/common/iprodukt';
import {Produkt} from '../../produkte/common/produkt';

export class ProduktGruppe implements IProduktGruppe {
    id = 0;
    nummer = 0;
    bezeichnung = '';
    produkte: IProdukt[] = [];
    numprods = 0;



    static fromResult(entity: IProduktGruppe): ProduktGruppe {
        if ((entity === null) || (entity === undefined)) {
            return null;
        }

        let l: ProduktGruppe = new ProduktGruppe();
        for (let k of Object.keys(l)) {
            if (k === 'produkte') {
                l.produkte = [];
                if (Array.isArray(entity.produkte)) {
                    entity.produkte.forEach(p => {
                        let pr = Produkt.fromResult(p);
                        if (pr !== undefined && pr !== null) {
                            l.produkte.push(pr);
                        }
                    });
                }
            } else {
                l[ k ] = (entity[ k ] !== undefined) ? entity[ k ] : '';
            }
        }
        return l;
    }

    constructor() {
    }


}
