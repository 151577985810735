import {Preisgruppe} from './preisgruppe';
import {SimplePreisgruppeFilter} from './simple-preisgruppe-filter';
import {IPreisgruppe} from './ipreisgruppe';
import {IListResult} from '../../commons/list-result';
import {CommonList} from '../../commons/common-list';
import {ResultMetaSortImpl} from '../../commons/result-meta';

export class PreisgruppeList extends CommonList<Preisgruppe> {

    public simpleFilter: SimplePreisgruppeFilter = new SimplePreisgruppeFilter();

    static fromListResult(res: IListResult<IPreisgruppe>): PreisgruppeList {
        const newList = new PreisgruppeList();
        newList.clear();
        newList.simpleFilter.clear();

        if (res.meta) {
            newList.total = res.meta.total || 0;
            newList.offset = res.meta.offset || 0;
            newList.size = res.meta.size || 0;
            newList.page = res.meta.page || 0;
            if (res.meta.order) {
                newList.order = [];
                for (const x of res.meta.order) {
                    newList.order.push(
                        new ResultMetaSortImpl(
                            x.field || 'gueltigAb',
                            x.direction || 'desc'
                        )
                    );
                }
            }
        }
        if (res.data) {
            for (const row of res.data) {
                newList.add(Preisgruppe.fromResult(row));
            }
        }
        if (res.simpleFilter) {
            for (const k of Object.keys(newList.simpleFilter)) {
                newList.simpleFilter[k] = (res.simpleFilter[k] !== undefined) ? res.simpleFilter[k] : null;
            }
        }

        return newList;

    }


    constructor() {
        super();
    }
}
