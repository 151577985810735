import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {KostenstelleList} from '../common/kostenstelle-list';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ToastrService} from 'ngx-toastr';

import {LocalizedComponent} from '../../../commons/components/localized-component';
import {KostenstelleService} from '../kostenstelle.service';
import {IListResult} from '../../../commons/list-result';
import {IKostenstelle} from '../common/ikostenstelle';
import {NewPageCountEvent} from '../../../commons/paginator/paginator.component';
import {KostenstellenEditorComponent} from '../kostenstellen-editor/kostenstellen-editor.component';
import {Kostenstelle} from '../common/kostenstelle';
import {ConfirmDialogComponent} from '../../../commons/confirm-dialog/confirm-dialog.component';
import {LoginService} from '../../../auth/services/login.service';
import {RowActionParams} from '../../../commons/data-table/row-action-params';

@Component({
    selector: 'app-tab-kostenstellen',
    templateUrl: './tab-kostenstellen.component.html',
    styleUrls: ['./tab-kostenstellen.component.scss']
})
export class TabKostenstellenComponent extends LocalizedComponent implements OnInit {

    kostenstellen = new KostenstelleList();

    constructor(

                public kostenstelleService: KostenstelleService,
                public loginService: LoginService,
                public toastr: ToastrService,
                public modalService: NgbModal,
                public cd: ChangeDetectorRef) {
        super( cd);

        this.kostenstellen.size = this.loginService.getPageSize('kostenstellen');

        this.kostenstellen.onUpdateRequired.subscribe(
            (list: KostenstelleList) => {
                this.kostenstelleService.list(
                    this.kostenstellen.size,
                    this.kostenstellen.calcOffset(),
                    this.kostenstellen.order,
                    this.kostenstellen.simpleFilter,
                    this.kostenstellen.getQuery()
                ).subscribe((l: IListResult<IKostenstelle>) => {
                        list.populateFromListResult(l);
                    }
                );
            }
        );


    }

    ngOnInit() {
        this.kostenstellen.start();
    }

    updateKostenstellen(force = false, page?: number) {
        if ((page !== undefined) && (page !== null)) {
            if (page !== this.kostenstellen.page) {
                this.kostenstellen.page = page;
                force = true;
            }
        }
        this.kostenstellen.start();
    }


    updateKostenstellenPageCount(n: NewPageCountEvent) {
        if (this.kostenstellen.size !== n.itemsPerPage) {
            this.kostenstellen.size = n.itemsPerPage;
            this.loginService.setPageSize('kostenstellen', n.itemsPerPage);
        }
        this.updateKostenstellen(true, n.currentPage);
    }


    addKostenstelle() {
        KostenstellenEditorComponent
            .open(this.modalService, null)
            .then(
                e => {
                    this.updateKostenstellen(true);
                },
                () => {
                    this.updateKostenstellen(true);
                }
            );
    }

    editKostenstelle(event: RowActionParams<Kostenstelle>) {
        KostenstellenEditorComponent
            .open(this.modalService, event.row.data as Kostenstelle)
            .then(
                e => {
                    this.updateKostenstellen(true);
                },
                () => {
                    this.updateKostenstellen(true);
                }
            );
    }

    deleteKostenstelle(event: RowActionParams<Kostenstelle>) {
        ConfirmDialogComponent.display(
            this.modalService,
            this.t('Kostenstelle löschen'),
            this.t('Sollen die Kostenstelle {{ kostenstelle }} ' +
                'wirklich unwideruflich gelöscht werden?', {kostenstelle: event.row.data.kostenstelle}),
            this.t('Kostenstelle jetzt löschen')
        ).result
            .then(
                e => {
                    this.kostenstelleService
                        .removeKostenstelle(event.row.data)
                        .subscribe(
                            r => {
                                if (r.success) {
                                    this.toastr.success(this.t('Die Kostenstelle wurde gelöscht.'));
                                    this.updateKostenstellen(true);
                                } else {
                                    this.toastr.error(this.t(
                                        'Das Kostenstelle der Anrede ist fehlgeschlagen'));
                                    this.updateKostenstellen(true);
                                }
                            },
                            () => {
                                this.toastr.error(this.t('Das Löschen der Kostenstelle ist fehlgeschlagen'));
                                this.updateKostenstellen(true);
                            }
                        );
                },
                k => {
                }
            );
    }


    reindexKostenstellen() {
        this.kostenstelleService
            .reindexKostenstellen()
            .subscribe(
                () => {
                    this.toastr.success(this._('Der Indizierungsvorgang wurde angestoßen.'));
                },
                () => {
                    this.toastr.error(this._('Die Indizierungsvorgang konnte nicht angestoßen werden.'));
                }
            );
    }

}
