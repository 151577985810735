<app-tabset>
    <app-pane [title]="_('Standort')">
        <element-list [elements]="filter.stationen"
                      #stationen
                      [renderer]="stationRenderer"
                      (onClearAll)="filter.clearStationen()"
                      (onRemoveElement)="filter.removeStation($event)"
        >
            <div class="header w-50">
                <station-field [label]="_('Station hinzufügen')"
                               (valueChange)="filter.addStation($event)"
                ></station-field>
            </div>
        </element-list>
    </app-pane>
    <app-pane [title]="_('Kunden')" *hasRole="['ROLE_ADMIN','ROLE_PROJECT_ADMIN','ROLE_BILLING']">
        <element-list [elements]="filter.customers"
                      #customers
                      [renderer]="customerRenderer"
                      (onClearAll)="filter.clearCustomers()"
                      (onRemoveElement)="filter.removeCustomer($event)"
        >
            <div class="header w-50">
                <kunde-field [label]="_('Kunde hinzufügen')"
                             (valueChange)="filter.addCustomer($event)"
                ></kunde-field>
            </div>
        </element-list>
    </app-pane>
    <app-pane [title]="_('Ausweise')">
        <element-list [elements]="filter.cards"
                      #ausweise
                      [renderer]="ausweisRenderer"
                      (onClearAll)="filter.clearCards()"
                      (onRemoveElement)="filter.removeCard($event)"
        >
            <div class="header w-50">
                <ausweis-field [label]="_('Ausweis hinzufügen')"
                               (valueChange)="filter.addCard($event)"
                ></ausweis-field>
            </div>
        </element-list>
    </app-pane>
    <app-pane [title]="_('Innenaufträge')">
        <element-list [elements]="filter.vehicles"
                      #fahrzeuge
                      [renderer]="fahrzeugRenderer"
                      (onClearAll)="filter.clearVehicles()"
                      (onRemoveElement)="filter.removeVehicle($event)"
        >
            <div class="header w-50">
                <fahrzeug-field [label]="_('Innenauftrag hinzufügen')"
                                (valueChange)="filter.addVehicle($event)"
                ></fahrzeug-field>
            </div>
        </element-list>
    </app-pane>

    <app-pane [title]="_('Freitext')">
        <div>
            {{ _('Hier können Sie beliebige Suchanfragen formulieren:')}}
        </div>
        <text-field [label]="_('Suchen nach')"
                    [labelwidth]="2"
                    [(value)]="filter.freitext"
                    [nullable]="true"
                    [multiline]="true"></text-field>
    </app-pane>

</app-tabset>
