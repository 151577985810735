import {AusweisAudit} from './ausweis-audit';
import {IAusweisAudit} from './iausweis-audit';
import {GenericFilter} from '../../../commons/generic-filter';
import {IBenutzer} from '../../../auth/common/ibenutzer';

export class SimpleAusweisAuditFilter extends GenericFilter<AusweisAudit> implements IAusweisAudit {
    id: number = null;
    benutzer: IBenutzer = null;
    dateCreated: any = null;
    info: string = null;
    uname: string = null;
}
