<div class="card">
    <div class="card-header d-flex justify-content-between" *ngIf="displayControlHeader">
        <ng-content select=".header"></ng-content>
        <button class="btn btn-secondary" (click)="clearAll()">
            <i class="icofont icofont-ui-delete"></i> {{_('Alle entfernen')}}
        </button>
    </div>
    <div class="card-body">
        <div class="xelement d-flex justify-content-between" *ngFor="let e of elements">
            <element-renderer [componentData]="getRenderer(e)"></element-renderer>
            <a (click)="removeElement(e)"><i class="icofont icofont-close-line-circled"></i></a>
        </div>
    </div>
</div>

