import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import {StationList} from './common/station-list';
import {Station} from './common/station';

import {FilterCallback} from '../commons/dynamic-form/FilterCallback';
import {ISimpleFilter} from '../commons/simple-filter';
import {CommonList} from '../commons/common-list';
import {SimpleStationFilter} from './common/simple-station-filter';
import {MESelectItemFormatterCallback} from '../commons/dynamic-form/me-select/meselect-item-formatter-callback';
import {StationenService} from './common/stationen.service';
import {
    MEHighlitePipe,
    MESelectComponent,
    MESelectItemMatcherCallback,
    MESelectItemTextCallback
} from '../commons/dynamic-form/me-select/meselect.component';
import {IListResult} from '../commons/list-result';
import {IStation} from './common/istation';
import {ConverterTool} from '../../shared/converter-tool';
import {MESelectSearchEvent} from '../commons/dynamic-form/me-select/meselect-search-event';
import CryptoES from "crypto-es";

@Component({
    selector: 'station-inline-field',
    template: `
        <meselect
                *ngIf="stationen!==null && stationen!==undefined"
                id="{{ id }}"
                [idField]="'id'"
                [items]="stationen.data"
                [asMatrix]="false"
                [placeholder]="label"
                (itemSelected)="doSelect($event)"
                (needsSearch)="doSearch($event)"
                [small]="small"
                [nullable]="nullable"
                [onFormat]="itemFormat"
                [onGetText]="itemText"
                [onMatch]="itemMatcher"
        >

        </meselect>`,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class StationInlineFieldComponent implements OnInit, AfterViewInit {
    @Input() nullable = true;

    @ViewChild(MESelectComponent, {static: false}) stationenMESelect: MESelectComponent;
    @Input() small = false;

    stationen: StationList = new StationList();
    @Input() label = 'StationField';
    @Input() id = '';
    @Input() maxDisplayStationen = 10;
    @Output() valueChange = new EventEmitter<Station>();

    constructor(public stationenService: StationenService,
                public cd: ChangeDetectorRef
    ) {
        if (this.id === '') {
            this.id = 'tf' + CryptoES.MD5('textfield-' + this.label + Math.random() + '-' + Math.random()).toString();
        }
    }

    public _value: Station = null;

    get value(): Station {
        return this._value;
    }

    @Input()
    set value(v: Station) {
        if (this._value !== v) {
            this._value = v;
            if (this.stationenMESelect !== undefined) {
                this.stationenMESelect.value = v;
            }
            this.valueChange.emit(v);
        }
    }

    ngOnInit() {
        this.doSearch(new MESelectSearchEvent('', this.stationenMESelect));
    }

    doSearch(value: MESelectSearchEvent) {
        this.search(new SimpleStationFilter(), this.stationen, this.maxDisplayStationen, value.queryString, null, value.component);
    }

    doSelect(station: Station) {
        this._value = station;
        this.valueChange.emit(station);
        this.cd.markForCheck();
    }

    public search: FilterCallback<Station> = (filter: ISimpleFilter<Station>,
                                              list: CommonList<Station>,
                                              maxDisplayItems: number,
                                              queryString: string,
                                              cfg: any,
                                              selectComponent: MESelectComponent
    ) => {

        const q = ConverterTool.processQuery(queryString, ['nummer']);

        this
            .stationenService
            .list(
                maxDisplayItems,
                0,
                [],
                filter as SimpleStationFilter,
                q
            )
            .subscribe(
                (c: IListResult<IStation>) => {
                    list.populateFromListResult(c);
                    this.cd.markForCheck();
                    if (selectComponent !== null && selectComponent !== undefined) {
                        selectComponent.items = list.data;
                        selectComponent.cd.markForCheck();
                    }
                }
            );
    };

    public itemFormat: MESelectItemFormatterCallback<Station> = (item: Station, search) => {
        if (item === null) {
            return '';
        }
        const r = '<i class="me-icon me-icon-station"></i> ' + MEHighlitePipe.transformString(item.bezeichnung, search) +
            ' <span class=\'badge-default badge\'>' + MEHighlitePipe.transformString('' + item.nummer, search) + '</span>';

        return r;
    };

    public itemText: MESelectItemTextCallback<Station> = (item: Station) => {
        if (item === null) {
            return '';
        }
        return item.bezeichnung + ' [' + item.nummer + ']';
    };

    public itemMatcher: MESelectItemMatcherCallback<Station> = (item: Station, search) => {
        if (!search) {
            return true;
        }
        if (search.trim() === '') {
            return true;
        }
        if (!item) {
            return false;
        }
        const x = item.bezeichnung + ' ' + item.nummer;

        return (x.toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) >= 0);
    };

    ngAfterViewInit(): void {
        this.stationenMESelect.onFormat = this.itemFormat;
        this.stationenMESelect.onGetText = this.itemText;
        this.stationenMESelect.onMatch = this.itemMatcher;
        this.stationenMESelect.value = this._value;
        this.cd.markForCheck();
    }


}
