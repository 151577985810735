import {ChangeDetectorRef, Component, Injector, OnDestroy, OnInit} from '@angular/core';
import {Nachricht} from '../common/nachricht';

import {LocalizedCellRenderer} from '../../../commons/data-table/renderer/common-cell-renderer';
import {DataTableRow} from '../../../commons/data-table/data-table-row';
import {DataTableColumnDirective} from '../../../commons/data-table/data-table-column.directive';

@Component({
    selector: 'app-dtnachricht-subject-renderer',
    template: '<span [class.font-weight-bold]="isUnread">{{value}}</span>'
})
export class DTNachrichtSubjectRendererComponent extends LocalizedCellRenderer implements OnInit, OnDestroy {

    cell = '';
    row: DataTableRow<Nachricht> = null;
    column: DataTableColumnDirective = null;
    value = '';
    isUnread = false;


    constructor(  public injector: Injector, public cd: ChangeDetectorRef) {
        super( cd);

        this.cell = this.injector.get('cell');
        this.row = this.injector.get('row');
        this.column = this.injector.get('column');

        this.value = '';
        this.isUnread = false;

        if (this.cell !== null && this.cell !== undefined) {
            this.value = this.t(this.cell, this.row.data.params);
            this.isUnread = !this.row.data.isRead()
        }


    }

    ngOnInit() {
        super.ngOnInit()
    }

    ngOnDestroy() {
        super.ngOnDestroy()
    }

}
