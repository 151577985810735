import {RemoteList} from '../../../commons/remote-list';
import {Kostenstelle} from './kostenstelle';
import {SimpleKostenstelleFilter} from './simple-kostenstelle-filter';
import {IKostenstelle} from './ikostenstelle';
import {IListResult} from '../../../commons/list-result';

export class KostenstelleList extends RemoteList<Kostenstelle>{
       public simpleFilter = new SimpleKostenstelleFilter();

    static fromListResult(res: IListResult<IKostenstelle>): KostenstelleList {
        const newList = new KostenstelleList();

        newList.populateFromListResult(res, true);

        return newList;
    }


    constructor(autorun = false, timeout = 400) {
        super(autorun, timeout);
    }

    public convertEntity(entity: any): any {
        return Kostenstelle.fromResult(entity);
    }

    public populateFromListResult(res: IListResult<Kostenstelle>, clearFilter = true): void {
        this.doPopulateFromListResult(res, 'zeitpunkt', 'desc', clearFilter);
    }

}
