import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {AddOnClickEvent} from "./add-on-click-event";
import CryptoES from "crypto-es";

@Component({
    selector: "password-field",
    template: `
        <div class="form-group row">
            <label for="{{ id }}"
                   class="col-{{ labelwidth }} col-form-label"
                   *ngIf="labelwidth>0">
                {{ label }}
            </label>
            <div class="col-{{ 12-labelwidth }}">
                <div class=""
                     [class.input-group]="hasAddon()"
                     [class.input-group-sm]="hasAddon() && small">
                    <input
                            class="form-control"
                            [class.form-control-sm]="small"
                            type="password"
                            id="{{ id }}"
                            [value]="value"
                            (input)="update($event)"
                            placeholder="{{ label }}"
                            [attr.disabled]="disabled?true:null"
                            [class.disabled]="disabled"
                    >
                    <span class="input-group-btn addon"
                          *ngFor="let ai of addonIcons;let idx=index">
            <button
                    class="btn btn-secondary addon-btn-{{ idx }}"
                    [class.btn-sm]="small"
                    type="button"
                    title="{{ _addOnLabels[idx] }}"
                    (click)="aClick(idx)">
                    <i class="{{ ai }}"></i>
            </button>
        </span>

                </div>
            </div>
        </div>

    `,
    styles: [`
        :host .addon button:first-child {
            border-left: none;
            padding-left: 5px;
            padding-right: 5px;
        }

    `]
})
export class PasswordFieldComponent implements OnInit {


    public _icons: string[] = [];
    @Input() label: string = "Textfeld";
    _addOnLabels: string[] = [];
    @Input() enableAddon: boolean = false;
    @Input() id: string = "";
    @Input() labelwidth: number = 3;
    @Input() disabled: boolean = false;
    @Input() small: boolean = false;
    @Output() valueChange = new EventEmitter<string>();
    @Output() addonClick = new EventEmitter<AddOnClickEvent>();

    constructor() {
    }

    public _value: string = "";

    get value(): string {
        return this._value;
    }

    @Input()
    set value(v: string) {
        if (this._value != v) {
            this._value = v;
            if (!this.disabled) {
                this.valueChange.emit(v);
            }
        }
    }

    get icon(): any {
        if (this._icons.length < 1) {
            return "";
        } else if (this._icons.length < 2) {
            return this._icons[0];
        }
        return this._icons;
    }

    @Input()
    set icon(v: any) {
        this._icons = [];
        if (Array.isArray(v)) {
            v.forEach(i => {
                this._icons.push(i);
            });
        } else {
            this._icons.push(v);
        }
        while (this._addOnLabels.length < this._icons.length) {
            this._addOnLabels.push("");
        }
    }

    @Input()
    set addonlabel(v: any) {
        this._addOnLabels = [];
        if (Array.isArray(v)) {
            v.forEach(i => {
                this._addOnLabels.push(i);
            });
        } else {
            this._addOnLabels.push(v);
        }
        while (this._addOnLabels.length < this._icons.length) {
            this._addOnLabels.push("");
        }
    }

    get addonIcons(): any[] {
        if (this.hasAddon()) {
            return this._icons;
        }
        return [];
    }

    ngOnInit() {
        if (this.id === "") {
            this.id = "tf" + CryptoES.MD5("textfield-" + this.label + Math.random() + "-" + Math.random()).toString();
        }
    }

    update(event) {
        if (!this.disabled) {
            this.value = event.target.value;
        }
    }

    hasAddon(): boolean {
        return !this.disabled && this.enableAddon && (this._icons.length > 0);
    }

    aClick(i?: any) {
        let e: AddOnClickEvent = new AddOnClickEvent();
        e.component = this;
        e.addon = i;
        this.addonClick.emit(e);
    }

}
