<div class="card" *ngIf="_kunde!==undefined && _kunde!==null">
    <div class="card-body">
        <h4 class="card-title">{{ _(kundeLabel,getTitleOptions()) }}</h4>
        <div class="card-text">

            <table class="table">
                <tr>
                    <th scope="row">{{_('Nummer')}}</th>
                    <td>{{ _kunde.nummer }}</td>
                    <th scope="row">{{_('Kundennummer')}}</th>
                    <td>{{ _kunde.kundennummer }}</td>
                </tr>
                <tr>
                    <th scope="row">{{_('Bezeichnung')}}</th>
                    <td colspan="3">{{ _kunde.bezeichnung }}</td>
                </tr>
                <tr>
                    <th scope="row">{{_('Adresse')}}</th>
                    <td colspan="3" [innerHtml]="_kunde.getSummary(true)"></td>
                </tr>
                <tr>
                    <th scope="row">{{ _('Telefon') }}</th>
                    <td>{{ _kunde.telefon }}</td>
                    <th scope="row">{{ _('Telefax') }}</th>
                    <td>{{ _kunde.telefax }}</td>
                </tr>
                <tr>
                    <th scope="row">{{ _('Mobiltelefon') }}</th>
                    <td>{{ _kunde.mobil }}</td>
                    <th scope="row">{{ _('Email') }}</th>
                    <td>
                        <app-email-display [email]="_kunde.email"></app-email-display>
                    </td>
                </tr>
                <tr>
                    <th scope="row">{{ _('im System angelegt') }}</th>
                    <td> {{ fDate(_kunde.dateCreated,'medium') }}</td>
                    <th scope="row">{{ _('zuletzt bearbeitet') }}</th>
                    <td> {{ fDate(_kunde.lastEdited,'medium') }}</td>
                </tr>
            </table>
        </div>
    </div>
</div>