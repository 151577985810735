import {RouterModule, Routes} from '@angular/router';
import {DashboardComponent} from './application/dashboard/dashboard.component';
import {NgModule} from '@angular/core';
import {LoginComponent} from './modules/users/loginform/login.component';
import {LoggedInGuard} from './modules/auth/directives/logged-in-guard';
import {StationenComponent} from './modules/stationen/stationen.component';
import {ProdukteComponent} from './modules/products/produkte.component';
import {PreiseComponent} from './modules/pricing/preise/preise.component';
import {KundenComponent} from './modules/customers/kunden/kunden.component';
import {FahrzeugeComponent} from './modules/customers/fahrzeuge/fahrzeuge.component';
import {PersonenComponent} from './modules/customers/personen/personen.component';
import {AusweiseComponent} from './modules/customers/ausweise/ausweise.component';
import {LieferscheineComponent} from './modules/billing/lieferscheine/lieferscheine.component';
import {KartenanfragenComponent} from './modules/billing/kartenanfragen/kartenanfragen.component';
import {RechnungenComponent} from './modules/billing/rechnungen/rechnungen.component';
import {BenutzerVerwaltungComponent} from './modules/users/users/benutzer-verwaltung.component';
import {TransaktionenComponent} from './modules/billing/transaktionen/transaktionen.component';
import {SucheComponent} from './modules/super-search/suche/suche.component';
import {NachrichtenComponent} from './modules/messaging/nachrichten/nachrichten.component';
import {TabAktiveSondenComponent} from './modules/stationen/sonden/aktive-sonden/tab-aktive-sonden.component';
import {BestandsuebsersichtComponent} from './modules/billing/bestand/bestandsuebsersicht/bestandsuebsersicht.component';
import {SysSettingsComponent} from './modules/system-settings/syssettings.component';
import {TankUeberwachungComponent} from './modules/stationen/sonden/aktive-sonden/tank-ueberwachung/tank-ueberwachung.component';
import {VideoControlComponent} from './modules/mevideo-control/video-control/video-control.component';
import {DashboardControlGuard} from './application/dashboard/dashboard-control-guard';

const routes: Routes = [
    {
        path: 'index',
        redirectTo: 'dashboard'
    },
    {
        path: 'dashboard',
        component: DashboardComponent,
        canActivate: [DashboardControlGuard]
    },
    {
        path: 'login',
        component: LoginComponent
    },
    {
        path: 'syssettings',
        component: SysSettingsComponent,
        canActivate: [LoggedInGuard]
    },
    {
        path: 'stationen',
        component: StationenComponent,
        canActivate: [LoggedInGuard]
    },
    {
        path: 'sonden',
        component: TabAktiveSondenComponent,
        canActivate: [LoggedInGuard]
    },
    {
        path: 'tankueberwachung',
        component: TankUeberwachungComponent,
        canActivate: [LoggedInGuard]
    },
    {
        path: 'produkte',
        component: ProdukteComponent,
        canActivate: [LoggedInGuard]
    },
    {
        path: 'preise',
        component: PreiseComponent,
        canActivate: [LoggedInGuard]
    },
    {
        path: 'kunden',
        component: KundenComponent,
        canActivate: [LoggedInGuard]
    },
    {
        path: 'fahrzeuge',
        component: FahrzeugeComponent,
        canActivate: [LoggedInGuard]
    },
    {
        path: 'personen',
        component: PersonenComponent,
        canActivate: [LoggedInGuard]
    },
    {
        path: 'ausweise',
        component: AusweiseComponent,
        canActivate: [LoggedInGuard]
    },
    {
        path: 'kartenanfragen',
        component: KartenanfragenComponent,
        canActivate: [LoggedInGuard]
    },
    {
        path: 'transaktionen',
        component: TransaktionenComponent,
        canActivate: [LoggedInGuard]

    },
    {
        path: 'lieferscheine',
        component: LieferscheineComponent,
        canActivate: [LoggedInGuard]
    },
    {
        path: 'bestand',
        component: BestandsuebsersichtComponent,
        canActivate: [LoggedInGuard]
    },
    {
        path: 'rechnungen',
        component: RechnungenComponent,
        canActivate: [LoggedInGuard]
    },
    {
        path: 'benutzer',
        component: BenutzerVerwaltungComponent,
        canActivate: [LoggedInGuard]
    },
    {
        path: 'messages',
        component: NachrichtenComponent,
        canActivate: [LoggedInGuard]
    },
    {
        path: 'suche',
        component: SucheComponent,
        canActivate: [LoggedInGuard]
    },
    {
        path: 'videocontrol',
        component: VideoControlComponent,
        canActivate: [LoggedInGuard]
    },

    {
        path: '',
        component: DashboardComponent,
        pathMatch: 'full',
        canActivate: [DashboardControlGuard]
    }

];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
