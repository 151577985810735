import {IArtikelGruppe} from './iartikel-gruppe';

export class ArtikelGruppe implements IArtikelGruppe {
    id = 0;
    bezeichnung = '';

    static fromResult(entity?: IArtikelGruppe): ArtikelGruppe {
        if ((entity === null) || (entity === undefined)) {
            return null;
        }

        const l: ArtikelGruppe = new ArtikelGruppe();
        for (const k of Object.keys(l)) {
            l[k] = (entity[k] !== undefined) ? entity[k] : '';
        }
        return l;
    }

    constructor() {
    }

}
