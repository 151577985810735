<div class="modal-header">
    <h5 class="modal-title" *ngIf="isAdd()">{{ _('Produktgruppe anlegen') }}</h5>
    <h5 class="modal-title" *ngIf="isEdit()">{{ _('Produktgruppe bearbeiten') }}</h5>
    <button type="button" class="close" aria-label="Close" (click)="cancel()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">

    <num-field
            [label]="_('Nummer')"
            [(value)]="entity.nummer"
            [addonicon]="['icofont icofont-search']"
            [addonlabel]="[_('freie Nummer suchen')]"
            [enableaddon]="true"
            (addonClick)="addOnClick($event)"
            id="produktGruppeNummer"
    ></num-field>
    <text-field
            [label]="_('Bezeichnung')"
            [(value)]="entity.bezeichnung"
    ></text-field>

    <produkt-selector
            [label]="_('enthaltene Produkte')"
            [value]="entity.produkte"
            (valueChanged)="updateProdukte($event)"
    >
    </produkt-selector>

</div>
<div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="cancel()">
        <i class="icofont icofont-close"></i> {{ _('Abbrechen') }}
    </button>
    <button type="button" class="btn btn-primary" (click)="save()">
        <i class="icofont icofont-save"></i>
        {{ _('Änderungen sichern') }}
    </button>
</div>
