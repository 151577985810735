import {ChangeDetectorRef, Component, NgZone, OnInit, ViewChild} from '@angular/core';
import {FahrzeugList} from './common/fahrzeug-list';

import {FahrzeugService} from './common/fahrzeug.service';

import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {FahrzeugEditorComponent} from './fahrzeug-editor/fahrzeug-editor.component';
import {Fahrzeug} from './common/fahrzeug';
import {LocalizedComponent} from '../../commons/components/localized-component';
import {KitchenTimer} from '../../commons/kitchen-timer';
import {NewPageCountEvent} from '../../commons/paginator/paginator.component';
import {DataTableComponent, DataTableFilterChanged, DataTableSortChanged} from '../../commons/data-table/data-table.component';
import {ResultMetaSortImpl} from '../../commons/result-meta';
import {KundeCellRendererComponent} from '../kunden/kunde-cell-renderer.component';
import {AusweisCellRendererComponent} from '../ausweise/ausweis-cell-renderer.component';
import {IListResult} from '../../commons/list-result';
import {IFahrzeug} from './common/ifahrzeug';
import {SysInfoService} from '../../commons/sysinfo/services/sys-info.service';
import {ToastrService} from 'ngx-toastr';
import {DTKostenstelleRendererComponent} from '../kostenstellen/dtkostenstelle-renderer/dtkostenstelle-renderer.component';
import {AusweiseRendererComponent} from '../ausweise/display/ausweise-renderer/ausweise-renderer.component';
import {FahrzeugFilterDialogComponent} from './fahrzeug-filter-dialog/fahrzeug-filter-dialog.component';
import {ConfirmDialogComponent} from '../../commons/confirm-dialog/confirm-dialog.component';
import {noop} from '../../commons/noop';
import * as FileSaver from 'file-saver';
import {LoginService} from '../../auth/services/login.service';
import {RowActionParams} from '../../commons/data-table/row-action-params';

@Component({
    selector: 'app-fahrzeuge',
    templateUrl: './fahrzeuge.component.html',
    styleUrls: ['./fahrzeuge.component.scss']
})
export class FahrzeugeComponent extends LocalizedComponent implements OnInit {
    kundeR = KundeCellRendererComponent;
    ausweisR = AusweisCellRendererComponent;
    kostenstelleR = DTKostenstelleRendererComponent;
    ausweiseR = AusweiseRendererComponent;

    fahrzeuge: FahrzeugList = new FahrzeugList();
    fahrzeugeTimer: KitchenTimer;

    @ViewChild('fahrzeugListe',{static: true}) fahrzeugListe: DataTableComponent;
    @ViewChild('filterDialog',{static:false}) filterDialog: FahrzeugFilterDialogComponent;

    constructor(
                
                public fahrzeugService: FahrzeugService,
                public toastr: ToastrService,
                public zone: NgZone,
                public modalService: NgbModal,
                public sysInfoService: SysInfoService,
                public loginService: LoginService,
                public cd: ChangeDetectorRef) {
        super( cd);

        this.fahrzeuge.size = this.loginService.getPageSize('fahrzeuge');

        this.fahrzeugeTimer = new KitchenTimer();
        this.fahrzeugeTimer
            .subscribe(
                () => {
                    this.fahrzeugService
                        .list(
                            this.fahrzeuge.size,
                            this.fahrzeuge.calcOffset(),
                            this.fahrzeuge.order,
                            this.fahrzeuge.simpleFilter,
                            this.fahrzeuge.getQuery()
                        )
                        .subscribe(
                            (l: IListResult<IFahrzeug>) => {
                                this.fahrzeuge.populateFromListResult(l);
                            },
                            (e) => {
                                this.toastr.error(this._('Die Liste der Fahrzeuge konnte nicht geladen werden.'));
                                this.fahrzeuge.clear();
                            }
                        );
                }
            );
    }

    ngOnInit() {
        this.updateFahrzeuge();
        this.sysInfoService.updateDocumentTitle(this._('Innenaufträge'));
    }

    updateFahrzeuge(force = false, page?: number) {
        if ((page !== undefined) && (page != null)) {
            if (page !== this.fahrzeuge.page) {
                this.fahrzeuge.page = page;
                force = true;
            }
        }
        this.fahrzeugeTimer.start(force);
    }

    /**
     * ändert die Anzahl der Datensätze/Seite und lädt die Liste neu
     * @param n
     */
    updateFahrzeugePageCount(n: NewPageCountEvent) {
        if (this.fahrzeuge.size !== n.itemsPerPage) {
            this.fahrzeuge.size = n.itemsPerPage;
            this.loginService.setPageSize('fahrzeuge', n.itemsPerPage);
        }
        this.updateFahrzeuge(true, n.currentPage);
    }

    sortChanged(e: DataTableSortChanged) {
        this.fahrzeuge.order = [
            new ResultMetaSortImpl(e.field, e.direction)
        ];
        this.updateFahrzeuge();
    }

    filterChanged(e: DataTableFilterChanged) {
        this.zone.run(() => {
            this.fahrzeuge.simpleFilter[e.field] = e.value;
            this.updateFahrzeuge();
        });
    }

    newFahrzeug() {
        FahrzeugEditorComponent
            .open(this.modalService)
            .then(
                () => {
                    this.updateFahrzeuge();
                },
                () => {
                    this.updateFahrzeuge();
                }
            );
    }

    editFahrzeug(params: RowActionParams<Fahrzeug>) {
        this.fahrzeugService
            .load(params.row.data.id)
            .subscribe((fz: IFahrzeug) => {
                FahrzeugEditorComponent
                    .open(this.modalService, Fahrzeug.fromResult(fz))
                    .then(
                        () => {
                            this.updateFahrzeuge();
                        },
                        () => {
                            this.updateFahrzeuge();
                        }
                    );
            });
    }

    fahrzeugeQueryChange(value = '') {
        if (value !== this.fahrzeuge.searchString) {
            this.fahrzeuge.searchString = value;
            this.updateFahrzeuge(false, 1);
        }

    }

    reindexFahrzeuge() {
        this.fahrzeugService
            .reindexFahrzeuge()
            .subscribe(
                () => {
                    this.toastr.success(this._('Der Indizierungsvorgang wurde angestoßen.'));
                },
                () => {
                    this.toastr.error(this._('Die Indizierungsvorgang konnte nicht angestoßen werden.'));
                }
            );
    }

    fahrzeugFilterToggle(visible: boolean) {
        if (!visible) {
            this.fahrzeugQueryChange2('');
        }
    }

    fahrzeugQueryChange2(value: string) {
        if (value !== this.fahrzeuge.searchString2) {
            this.fahrzeuge.searchString2 = value;
            this.updateFahrzeuge(false, 1);
        }
    }

    downloadEntries(type: string, exporttype: number = 0) {
        if (this.fahrzeuge.total < 1) {
            this.toastr.error(this._(
                'Es werden keine Datensätze angezeigt, daher gibt es auch nichts zum Herunterladen.'));
        } else if (this.fahrzeuge.total < 500) {
            this.realDlEntries(type, exporttype);
        } else if (this.fahrzeuge.total > 25000) {
            this.toastr.error(
                this._('Sie wollen {{ anzahl }} Datensätze herunterladen. Da es erfahrungsgemäß ' +
                    'hierbei zu Downloadabbrüchen seitens des Webbrowsers kommt, bitten wir Sie,' +
                    'die Ergebnismenge zunächst einzugrenzen.', {anzahl: this.fahrzeuge.total}),
                this._('Aktion nicht möglich'),
                {
                    timeOut: 15000
                }
            );
        } else {
            ConfirmDialogComponent
                .display(
                    this.modalService,
                    this._('Download durchführen?'),
                    this._('Sie wollen {{ anzahl }} Datensätze herunterladen. Dies wird unter Umständen ' +
                        'eine längere Zeit dauern und je nach Browser zu einem Verbindungsabbruch führen. <br /><br /> ' +
                        'Wir empfehlen, die Liste zunnächst weiter einzuschränken.',
                        {anzahl: this.fahrzeuge.total}
                    ),
                    this._('Download trotzdem durchführen'),
                    'icofont icofont-download-alt'
                )
                .result
                .then(
                    () => {
                        this.realDlEntries(type, exporttype);
                    },
                    noop
                );
        }
    }

    private realDlEntries(type: string, exporttype: number) {
        this.toastr
            .info(this._('Download wird angefordert. Bitte einen Moment Geduld...'),
                this._('Aktion wird ausgeführt...'),
                {
                    timeOut: 15000
                }
            )
            .onShown
            .subscribe(
                () => {
                    this.fahrzeugService
                        .exportData(
                            type,
                            this.fahrzeuge.simpleFilter,
                            this.fahrzeuge.getQuery(),
                            exporttype
                        ).subscribe(
                        (response: Blob) => {
                            FileSaver.saveAs(response, this._('innenauftraege.' + type));
                        },
                        (e) => {
                            this.toastr.error(this._('Die Liste der Innenaufträge konnte nicht exportiert werden.'));
                        }
                    );
                });

    }

    syncFahrzeuge() {
          this.fahrzeugService
            .syncFahrzeuge()
            .subscribe(
                () => {
                    this.toastr.success(this._('Der Synchronisationsvorgang wurde angestoßen.'));
                },
                () => {
                    this.toastr.error(this._('Der Synchronisationsvorgang konnte nicht angestoßen werden.'));
                }
            );
    }
}
