import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {LocalizedComponent} from '../../commons/components/localized-component';
import {noop} from '../../commons/noop';
import {IKunde} from '../kunden/common/ikunde';
import {Kunde} from '../kunden/common/kunde';
import {KundenService} from '../kunden/common/kunden.service';


@Component({
               selector   : 'app-dashboard-prepaid-block',
               templateUrl: './dashboard-prepaid-block.component.html',
               styleUrls  : ['./dashboard-prepaid-block.component.scss']
           })
export class DashboardPrepaidBlockComponent extends LocalizedComponent implements OnInit {
    _kunde: Kunde = null;
    isLoading = false;
    restguthaben = '0';
    stand = '';


    @Input() destination = '';

    @Input()
    set kunde(kd: Kunde) {
        if (kd !== this._kunde) {
            this._kunde = kd;
            if (this._kunde !== null) {
                const rest = (this._kunde.kontingent.betragPrepaid - this._kunde.kontingent.saldoBetragPrepaid);
                this.restguthaben = this.fCurrency(rest, 'EUR');
                this.stand
                    = this.fDate(this._kunde.kontingent.letzteSaldoAktualisierung, 'LLL', true);
            } else {
                this.restguthaben = '0';
                this.stand = '';
            }
            this.cd.markForCheck();
        }
    }

    constructor(
        public cd: ChangeDetectorRef,
        public kundeService: KundenService,
        public router: Router
    ) {
        super(cd);
    }

    ngOnInit() {

    }

    refreshLimit() {
        this.isLoading = true;
        this.cd.markForCheck();
        this.kundeService
            .requestLimits(this._kunde)
            .subscribe(
                (_kd: IKunde) => {
                    const kd = Kunde.fromResult(_kd);
                    this.isLoading = false;
                    if (kd !== null) {
                        this._kunde = kd;
                    }
                    if (this._kunde !== null) {
                        const rest = (this._kunde.kontingent.betragPrepaid - this._kunde.kontingent.saldoBetragPrepaid);
                        this.restguthaben = this.fCurrency(rest, 'EUR');
                        this.stand
                            = this.fDate(this._kunde.kontingent.letzteSaldoAktualisierung, 'LLL', true);
                    } else {
                        this.restguthaben = '0';
                        this.stand = '';
                    }
                    this.cd.markForCheck();
                },
                () => {
                    this.isLoading = false;
                    this.cd.markForCheck();
                }
            );


    }


    gotoArea() {
        this.router.navigate([this.destination]).then(noop);
    }
}
