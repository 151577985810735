export class METool {
    public static isNullOrUndefined(o?: any) {
        return ((o === null) || (o === undefined));
    }

    public static isNaN(val?: any) {
        if (METool.isNullOrUndefined(val)) {
            return true;
        }
        return isNaN(parseFloat('' + val));
    }

    public static isDefined(o?: any) {
        return !METool.isNullOrUndefined(o) && o !== '';
    }

    public static isEmpty(v:any) {
        if(METool.isNullOrUndefined(v)){
            return true;
        }
        if(this.isNaN(v)){
            return (""+v).trim() === "";
        }
        return !(parseFloat(v)>0);
    }
}
