import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {KartenanfrageService} from '../../billing/kartenanfragen/common/kartenanfrage.service';
import {Router} from '@angular/router';
import {AusweisService} from '../ausweise/common/ausweis.service';
import {IActionResponse} from '../../commons/action-response';

@Component({
  selector: 'app-dashboard-ausweise-block',
  templateUrl: './dashboard-ausweise-block.component.html',
  styleUrls: ['./dashboard-ausweise-block.component.scss']
})
export class DashboardAusweiseBlockComponent implements OnInit {

    @Input() title = 'Karten';
    @Input() destination = 'karten';
    @Input() cls = 'yellow';
    @Input() showAlways = false;

    cnt = 0;

    constructor(
        public cd: ChangeDetectorRef,
        public ausweiseService: AusweisService,
        public router: Router

    ) {
    }

    ngOnInit() {
        this.refresh();
    }

    refresh() {
        this.ausweiseService
            .countAusweise()
            .subscribe(
                (c: IActionResponse) => {
                    this.cnt = parseInt('' + c.params.count, 10);
                    this.cd.markForCheck();
                },
                () => {
                    this.cnt = 0;
                    this.cd.markForCheck();
                }
            );
    }


    gotoArea() {
        this.router.navigate([this.destination]);
    }


}
