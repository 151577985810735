import {Fahrzeug} from '../common/fahrzeug';
import {Ausweis} from '../../ausweise/common/ausweis';
import {Station} from '../../../stationen/common/station';
import {Kunde} from '../../kunden/common/kunde';
import {EventEmitter} from '@angular/core';

export class FahrzeugFilter {

    public filterChanged: EventEmitter<void> = new EventEmitter<void>();
    private _customers: Kunde[] = [];
    private _stationen: Station[] = [];
    private _freitext = '';
    private _cards: Ausweis[] = [];
    private _vehicles: Fahrzeug[] = [];

    get freitext(): string {
        return this._freitext;
    }

    set freitext(value: string) {
        if (value !== this._freitext) {
            this._freitext = value;
            this.filterChanged.emit();
        }
    }

    get vehicles(): Fahrzeug[] {
        return this._vehicles;
    }

    get cards(): Ausweis[] {
        return this._cards;
    }

    set cards(value: Ausweis[]) {
        if (value !== this._cards) {
            this._cards = value;
            this.filterChanged.emit();
        }
    }

    set vehicles(value: Fahrzeug[]) {
        if (value !== this._vehicles) {
            this._vehicles = value;
            this.filterChanged.emit();
        }
    }

    get customers(): Kunde[] {
        return this._customers;
    }

    set customers(value: Kunde[]) {
        if (value !== this._customers) {
            this._customers = value;
            this.filterChanged.emit();
        }
    }

    get stationen(): Station[] {
        return this._stationen;
    }

    set stationen(value: Station[]) {
        if (value !== this._stationen) {
            this._stationen = value;
            this.filterChanged.emit();
        }
    }

    constructor() {
    }

    getQuery(): string {
        const parts: string[] = [];
        if (this._stationen !== null && this._stationen !== undefined && Array.isArray(this._stationen)) {
            const c: string[] = [];
            this._stationen.forEach((s: Station) => {
                c.push('((_station_id:' + s.nummer + ') OR (station:' + s.nummer + '))');
            });
            if (c.length > 0) {
                parts.push('( ' + c.join(' OR ') + ' )');
            }
        }
        if (this._customers !== null && this._customers !== undefined && Array.isArray(this._customers)) {
            const c: string[] = [];
            this._customers.forEach((s: Kunde) => {
                c.push('((_kunde_id:' + s.id + ') OR (kunde:' + s.nummer + '))');
            });
            if (c.length > 0) {
                parts.push('( ' + c.join(' OR ') + ' )');
            }
        }
        if (this._cards !== null && this._cards !== undefined && Array.isArray(this._cards)) {
            const c: string[] = [];
            this._cards.forEach((s: Ausweis) => {
                c.push('((_ausweis_id:' + s.id + ') OR (ausweis:' + s.nummer + '))');
            });
            if (c.length > 0) {
                parts.push('( ' + c.join(' OR ') + ' )');
            }
        }
        if (this._vehicles !== null && this._vehicles !== undefined && Array.isArray(this._vehicles)) {
            const c: string[] = [];
            this._vehicles.forEach((s: Fahrzeug) => {
                c.push('((_fahrzeug_id:' + s.id + ') OR (fahrzeug:' + s.nummer + '))');
            });
            if (c.length > 0) {
                parts.push('( ' + c.join(' OR ') + ' )');
            }
        }
        if (this._freitext !== null && this._freitext !== undefined && this._freitext.trim() !== '') {
            parts.push('(' + this._freitext + ')');
        }


        if (parts.length < 1) {
            return '';
        }
        return parts.join(' AND ');
    }

    clearStationen() {
        this.stationen = [];
    }

    addStation(station: Station) {
        if (station != null && station !== undefined) {
            if (!Array.isArray(this._stationen)) {
                this._stationen = [];
            }
            let found = false;
            this._stationen.forEach((s: Station) => {
                if (s.id === station.id) {
                    found = true;
                }
            });
            if (!found) {
                this._stationen.push(station);
                this.filterChanged.emit();
            }
        }
    }

    removeStation(station: Station) {
        let found = false;
        this._stationen.forEach((s, idx) => {
            if (s.id === station.id) {
                this._stationen.splice(idx, 1);
                found = true;
            }
        });
        if (found) {
            this.filterChanged.emit();
        }
    }

    clearCustomers() {
        this.customers = [];
    }

    addCustomer(kunde: Kunde) {
        if (kunde != null && kunde !== undefined) {
            if (!Array.isArray(this._customers)) {
                this._customers = [];
            }
            let found = false;
            this._customers.forEach((s: Kunde) => {
                if (s.id === kunde.id) {
                    found = true;
                }
            });
            if (!found) {
                this._customers.push(kunde);
                this.filterChanged.emit();
            }
        }
    }

    removeCustomer(kunde: Kunde) {
        let found = false;
        this._customers.forEach((s, idx) => {
            if (s.id === kunde.id) {
                this._customers.splice(idx, 1);
                found = true;
            }
        });
        if (found) {
            this.filterChanged.emit();
        }
    }

    clearCards() {
        this.cards = [];
    }

    addCard(card: Ausweis) {
        if (card != null && card !== undefined) {
            if (!Array.isArray(this._cards)) {
                this._cards = [];
            }
            let found = false;
            this._cards.forEach((s: Ausweis) => {
                if (s.id === card.id) {
                    found = true;
                }
            });
            if (!found) {
                this._cards.push(card);
                this.filterChanged.emit();
            }
        }
    }

    removeCard(card: Ausweis) {
        let found = false;
        this._cards.forEach((s, idx) => {
            if (s.id === card.id) {
                this._cards.splice(idx, 1);
                found = true;
            }
        });
        if (found) {
            this.filterChanged.emit();
        }
    }


    clearVehicles() {
        this.vehicles = [];
    }

    addVehicle(car: Fahrzeug) {
        if (car != null && car !== undefined) {
            if (!Array.isArray(this._vehicles)) {
                this._vehicles = [];
            }
            let found = false;
            this._vehicles.forEach((s: Fahrzeug) => {
                if (s.id === car.id) {
                    found = true;
                }
            });
            if (!found) {
                this._vehicles.push(car);
                this.filterChanged.emit();
            }
        }
    }

    removeVehicle(car: Fahrzeug) {
        let found = false;
        this._vehicles.forEach((s, idx) => {
            if (s.id === car.id) {
                this._vehicles.splice(idx, 1);
                found = true;
            }
        });
        if (found) {
            this.filterChanged.emit();
        }
    }

}
