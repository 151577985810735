import {AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {LocalizedComponent} from '../../../commons/components/localized-component';
import {ISonde} from '../isonde';
import {NgbActiveModal, NgbModal, NgbModalOptions, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';

import {SysInfoService} from '../../../commons/sysinfo/services/sys-info.service';
import {LoginService} from '../../../auth/services/login.service';
import {ToastrService} from 'ngx-toastr';
import {PubSubService} from '../../../commons/pub-sub.service';
import {Subscription} from 'rxjs';
import {Sonde} from '../sonde';
import {SondenBuchungList} from '../sonden-buchung-list';
import {StationenService} from '../../common/stationen.service';
import {IListResult} from '../../../commons/list-result';
import {ISondenBuchung} from '../isonden-buchung';
import {NewPageCountEvent} from '../../../commons/paginator/paginator.component';
import {LiterCellRendererComponent} from '../../../commons/components/liter-cell-renderer.component';
import {ResultMetaSortImpl} from '../../../commons/result-meta';
import {SondenBuchungenDiagrammComponent} from '../sonden-buchungen-diagramm/sonden-buchungen-diagramm.component';
import {SondenBuchungenFilter} from './sonden-buchung-filter/sonden-buchungen-filter';
import {SondenBuchungFilterComponent} from './sonden-buchung-filter/sonden-buchung-filter.component';
import {SondenBuchung} from '../sonden-buchung';
import {DataTableRow} from '../../../commons/data-table/data-table-row';
import {TDTGetExtraRowClassesCallback} from '../../../commons/data-table/types';
import {DataTableComponent} from '../../../commons/data-table/data-table.component';

@Component({
    selector: 'app-sonden-buchungen-dialog',
    templateUrl: './sonden-buchungen-dialog.component.html',
    styleUrls: ['./sonden-buchungen-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SondenBuchungenDialogComponent extends LocalizedComponent implements OnInit, OnDestroy, AfterViewInit {

    literR = LiterCellRendererComponent;

    isOnline = false;
    sub: Subscription = null;
    loginSub: Subscription = null;
    sonde: Sonde = new Sonde();
    buchungen: SondenBuchungList = new SondenBuchungList();
    sData: ISondenBuchung[] = [];

    diagramm: SondenBuchungenDiagrammComponent = null;

    @ViewChild('bgFilter',{static: true}) bgFilter: SondenBuchungFilterComponent;
    @ViewChild('buchungenTable',{static: true}) buchungenTable: DataTableComponent;

    mode = 0;

    get dlgTitle() {
        return this._('Peilungen der Sonde {{nummer}} {{name}} an Station {{ station }} ', {nummer: this.sonde.nummer, name: this.sonde.bezeichnung, station: this.sonde.station.bezeichnung});
    }

    static open(modalService: NgbModal, sonde: ISonde): Promise<any> {
        const o: NgbModalOptions = {
            size: 'lg',
            backdrop: 'static'
        };
        const r: NgbModalRef = modalService.open(SondenBuchungenDialogComponent, o);
        (r.componentInstance as SondenBuchungenDialogComponent).open(sonde);
        return r.result;
    }

    sondenbuchungRowClassGetter: TDTGetExtraRowClassesCallback<SondenBuchung> = (row: DataTableRow<SondenBuchung>) => {
        if (
            row !== null &&
            row !== undefined &&
            row.data !== null &&
            row.data !== undefined
        ) {
            return 'sondenbuchung-' + (row.data.menge <= this.sonde.warnmenge ? 'warn' : 'regular');
        }
        return '';
    }

    constructor(

                public sysinfo: SysInfoService,
                public loginService: LoginService,
                public activeModal: NgbActiveModal,
                public toastr: ToastrService,
                public cd: ChangeDetectorRef,
                public pubsub: PubSubService,
                public stationenService: StationenService

    ) {
        super( cd);

        this.buchungen.size = this.loginService.getPageSize('sonden_buchungen');

        this.buchungen
            .onUpdateRequired
            .subscribe((list: SondenBuchungList) => {

                if (this.bgFilter !== null && this.bgFilter !== undefined) {
                    this.buchungen.simpleFilter.timeFrom = this.bgFilter.filter.timeFrom;
                    this.buchungen.simpleFilter.timeUntil = this.bgFilter.filter.timeUntil;
                }


                this.stationenService.listSondenBuchungen(
                    this.sonde.station,
                    this.sonde,
                    this.buchungen.size,
                    this.buchungen.calcOffset(),
                    this.buchungen.order,
                    this.buchungen.simpleFilter,
                    this.buchungen.getQuery()
                ).subscribe((l: IListResult<ISondenBuchung>) => {
                    list.populateFromListResult(l);
                    this.cd.markForCheck();
                });
            });
    }

    public open(sonde: ISonde) {
        if (sonde === null || sonde === undefined) {
            this.cancel();
        }
        this.sonde = Sonde.fromResult(sonde);
        this.sData = [];
        this.buchungen.clear();


        if (!this.loginService.isLoggedIn()) {
            this.cancel();
        }

        if (!this.loginService.hasOneOfRoles(['ROLE_ADMIN', 'ROLE_PROJECT_ADMIN', 'ROLE_BILLING', 'ROLE_BESTAND'])) {
            this.cancel();
        }
        this.updateBuchungen(true, 1);
    }

    ngOnInit() {
        this.sub = this.pubsub.onlineStateChange.subscribe(
            (o) => {
                this.isOnline = o;
                this.cd.markForCheck();
            }
        );
        this.loginSub = this.pubsub.loginStateChange.subscribe(
            () => {
                if (!this.loginService.isLoggedIn()) {
                    this.cancel();
                }

                if (!this.loginService.hasOneOfRoles(['ROLE_ADMIN', 'ROLE_PROJECT_ADMIN', 'ROLE_BILLING', 'ROLE_BESTAND'])) {
                    this.cancel();
                }
            },
            () => {
                this.cancel();
            }
        );
    }

    ngOnDestroy() {
        this.sub.unsubscribe();
        this.loginSub.unsubscribe();
    }

    cancel() {
        this.activeModal.close();
    }

    updateBuchungen(force: boolean = false, page: number = 1) {
        if ((page !== undefined) && (page != null)) {
            if (page !== this.buchungen.page) {
                this.buchungen.page = page;
                force = true;
            }
        }
        this.buchungen.start();
    }

    updateBuchungenPageCount(n: NewPageCountEvent) {
        if (this.buchungen.size !== n.itemsPerPage) {
            this.buchungen.size = n.itemsPerPage;
            this.loginService.setPageSize('sonden_buchungen', n.itemsPerPage);
        }
        this.updateBuchungen(true, n.currentPage);
    }


    sondenBuchungenQueryChange(value: string) {
        if (value !== this.buchungen.searchString) {
            this.buchungen.searchString = value;
            this.updateBuchungen(false, 1);
        }
    }

    setMode(newMode = 0) {
        if (newMode !== this.mode) {
            this.mode = newMode;

            if (this.bgFilter !== null || this.bgFilter !== undefined) {
                this.bgFilter.notify();
            }

        }
    }

    loadDataForChart(pg = 0, init = true) {
        if (this.diagramm === null) {
            return;
        }
        if (init) {
            this.diagramm.clear();
        }
        this.stationenService.listSondenBuchungen(
            this.sonde.station,
            this.sonde,
            (init) ? 1 : 100,
            pg * 100,
            [new ResultMetaSortImpl('zeitpunkt', 'asc')],
            this.buchungen.simpleFilter,
            this.buchungen.getQuery()
        ).subscribe((l: IListResult<ISondenBuchung>) => {
            if (l.meta.count > 0) {
                if (init) {
                    if (l.meta.total > 5000) {
                        // maximal 5000 Werte laden
                        pg = (l.meta.total - 5000) / 100;
                    }
                    if (pg < 0) {
                        pg = 0;
                    }

                    this.loadDataForChart(pg, false);
                } else {
                    l.data.forEach((v: ISondenBuchung) => {
                        if (v != null) {
                            this.diagramm.addBuchung(v);
                        }
                    });
                    if ((l.meta.count >= l.meta.size) || (l.meta.page < l.meta.pages)) {
                        this.loadDataForChart(pg + 1, false);
                    } else {
                        this.diagramm.createSeries();
                    }
                    this.cd.markForCheck();
                }
            }
        });
        this.cd.markForCheck();
    }

    buildChartData() {
        this.loadDataForChart();
    }

    diagrammNeedsData($event: SondenBuchungenDiagrammComponent) {
        this.diagramm = $event;
        this.loadDataForChart();
    }


    onFilterChanged(f: SondenBuchungenFilter) {
        if (f !== null && f !== undefined) {
            this.buchungen.simpleFilter.timeFrom = f.timeFrom;
            this.buchungen.simpleFilter.timeUntil = f.timeUntil;
            if (this.mode === 0) {
                this.updateBuchungen();
            } else {
                if (this.diagramm !== null) {
                    this.diagramm.start();
                }
            }
        }
    }

    ngAfterViewInit(): void {
        if (this.buchungenTable !== null && this.buchungenTable !== undefined) {
            this.buchungenTable.extraRowClasses = this.sondenbuchungRowClassGetter;
            this.cd.markForCheck();
        }
    }
}
