import {ChangeDetectorRef, Component, Injector} from '@angular/core';
import {DataTableRow} from '../../commons/data-table/data-table-row';
import {Geraet} from '../common/geraet';
import {DataTableColumnDirective} from '../../commons/data-table/data-table-column.directive';
import {LocalizedCellRenderer} from '../../commons/data-table/renderer/common-cell-renderer';

import {ConverterTool} from '../../../shared/converter-tool';

@Component({
    selector: 'app-dtdevice-system-state-renderer',
    styleUrls: ['./dtdevice-system-state-renderer.component.scss'],
    template: `
        <div *ngIf="isValid()">
            <div class="state-date text-center" *ngIf="hasStateDate()">
                {{ _('Status vom:') }} {{ fDate(getStateDate(), 'medium') }}
            </div>
            <div class="alert alert-danger" *ngIf="isNotReachable()">
                {{ _('Gerät nicht erreichbar!') }}
            </div>
            <div *ngIf="isReachable()">
                <div class="belegung d-flex justify-content-between align-items-center"
                     *ngIf="hasSDCard()"
                     [title]="getBelegung('sdcard')"
                >
                    <div class="l mr-2">
                        {{_('SDCard')}}
                    </div>
                    <div class="bar flex-fill">
                        <ngb-progressbar max="100"
                                         [value]="getUsedPercent('sdcard')"
                                         [type]="getWarnState('sdcard')"
                        ></ngb-progressbar>
                    </div>
                </div>
                <div class="belegung d-flex justify-content-between align-items-center" *ngIf="hasRootFs()"
                     [title]="getBelegung('rootfs')"
                >
                    <div class="l mr-2">
                        {{_('Dateisystem')}}
                    </div>
                    <div class="bar flex-fill">
                        <ngb-progressbar max="100"
                                         [value]="getUsedPercent('rootfs')"
                                         [type]="getWarnState('rootfs')"
                        ></ngb-progressbar>
                    </div>
                </div>
                <div class="belegung d-flex justify-content-between align-items-center" *ngIf="hasMem()"
                     [title]="getBelegung('mem')"
                >
                    <div class="l mr-2">
                        {{_('Hauptspeicher')}}
                    </div>
                    <div class="bar flex-fill">
                        <ngb-progressbar max="100"
                                         [value]="getUsedPercent('mem')"
                                         [type]="getWarnState('mem')"
                        ></ngb-progressbar>
                    </div>
                </div>
                <div class="belegung d-flex justify-content-between align-items-center"
                     *ngIf="hasTemp()"
                >
                    <div class="l mr-2">
                        {{_('CPU-Temperatur')}}
                    </div>
                    <div class="bar flex-fill"
                         [class.text-danger]="getCPUTemp()>=60"
                         [class.font-weight-bold]="getCPUTemp()>=60"
                    >
                        {{ fNum(getCPUTemp(), '1.0-0') }}°C
                    </div>
                </div>
                <div class="belegung d-flex justify-content-between align-items-center"
                     *ngIf="hasSystemTime()"
                >
                    <div class="l mr-2">
                        {{_('System-Zeit')}}
                    </div>
                    <div class="bar flex-fill">
                        {{ fDate(getSysTime(), 'medium') }}
                        <span class="text-danger bg-warning" *ngIf="isSysTimeFail()">
                        <i class="far fa-exclamation-triangle"
                           [title]="_('Die Systemzeit weicht stark ab!')"
                        ></i>
                        </span>
                    </div>
                </div>

            </div>
        </div>
    `
})
export class DTDeviceSystemStateRendererComponent extends LocalizedCellRenderer {
    cell: any = null;
    row: DataTableRow<Geraet> = null;
    column: DataTableColumnDirective = null;

    constructor(
                
                public cd: ChangeDetectorRef,
                public injector: Injector) {
        super( cd);
        this.cell = this.injector.get('cell');
        this.row = this.injector.get('row');
        this.column = this.injector.get('column');
    }

    isValid() {
        return this.cell !== null && this.cell !== undefined;
    }

    isNotReachable() {
        return this.hasKey('reachable') && !ConverterTool.toBoolean(this.cell['reachable']);
    }

    isReachable() {
        return this.hasKey('reachable') && ConverterTool.toBoolean(this.cell['reachable']);
    }

    hasStateDate() {
        return this.hasKey('last_state');
    }

    getStateDate(): Date {
        if (this.hasStateDate()) {
            return ConverterTool.toDate(this.cell['last_state']);
        }
        return null;
    }


    hasSDCard() {
        return this.hasKey('state_sdcard_disk_total') && this.hasKey('state_sdcard_disk_used') && this.hasKey('state_sdcard_disk_free');
    }

    private hasKey(key: string) {
        return this.isValid() &&
            Object.keys(this.cell).indexOf(key) >= 0 &&
            this.cell[key] !== null &&
            this.cell[key] !== '';
    }

    getUsedPercent(type: string) {
        let total: number = null;
        let used: number = null;
        if (type === 'sdcard') {
            if (this.hasSDCard()) {
                total = parseInt('' + this.cell['state_sdcard_disk_total'], 10);
                used = parseInt('' + this.cell['state_sdcard_disk_used'], 10);
            }
        }
        if (type === 'rootfs') {
            if (this.hasRootFs()) {
                total = parseInt('' + this.cell['state_rootfs_disk_total'], 10);
                used = parseInt('' + this.cell['state_rootfs_disk_used'], 10);
            }
        }
        if (type === 'mem') {
            if (this.hasMem()) {
                total = parseInt('' + this.cell['state_mem_total'], 10);
                used = parseInt('' + this.cell['state_mem_used'], 10);
            }
        }
        if (total != null && used != null) {
            if (total === 0) {
                return 100;
            }
            return Math.floor((used / total) * 100.0);
        }
        return -1;
    }


    getWarnState(type: string) {
        const x = this.getUsedPercent(type);
        if (type === 'mem') {
            if (x > 90) {
                return 'danger';
            }
            return 'success';
        }
        if (x > 85) {
            return 'danger';
        }
        if (x > 75) {
            return 'warning';
        }
        if (x > 50) {
            return 'info';
        }
        return 'success';
    }

    public hasRootFs() {
        return this.hasKey('state_rootfs_disk_total') &&
            this.hasKey('state_rootfs_disk_used') &&
            this.hasKey('state_rootfs_disk_free');
    }

    getBelegung(type: string) {
        const prz = this.getUsedPercent(type);
        let remain = -1;
        let total = 0;
        if (type === 'sdcard') {
            if (this.hasSDCard()) {
                remain = parseInt('' + this.cell['state_sdcard_disk_free'], 10) * 1024;
                total = parseInt('' + this.cell['state_sdcard_disk_total'], 10) * 1024;
            }
        }
        if (type === 'rootfs') {
            if (this.hasRootFs()) {
                remain = parseInt('' + this.cell['state_rootfs_disk_free'], 10) * 1024;
                total = parseInt('' + this.cell['state_rootfs_disk_total'], 10) * 1024;
            }
        }
        if (type === 'rootfs') {
            if (this.hasRootFs()) {
                remain = parseInt('' + this.cell['state_mem_free'], 10);
                total = parseInt('' + this.cell['state_mem_total'], 10);
            }
        }

        if (prz < 0) {
            return '';
        }

        remain = this.bytes(remain, 1);
        total = this.bytes(total, 1);

        return this.t('{{ prz }}% von {{ t }} belegt, {{ r }} verbleiben', {prz: prz, t: total, r: remain});
    }

    hasMem() {
        return this.hasKey('state_mem_total') &&
            this.hasKey('state_mem_used') &&
            this.hasKey('state_mem_free');
    }

    hasTemp() {
        return this.hasKey('state_cpu_temp');
    }


    getCPUTemp() {
        if (this.hasTemp()) {
            return parseFloat('' + this.cell['state_cpu_temp']);
        }
    }

    hasSystemTime() {
        return this.hasKey('state_system_date');
    }

    getSysTime(): Date {
        return ConverterTool.toDate(this.cell['state_system_date']);
    }

    isSysTimeFail() {
        if (this.hasStateDate() && this.hasSystemTime()) {

            const diff = Math.abs(this.getStateDate().getTime() - this.getSysTime().getTime()) / 1000;
            return diff > 60 * 60;
        }
        return false;
    }
}
