import {Directive, Input} from '@angular/core';
import {IOption} from './ioption';

@Directive({
    selector: 'select-field-option'
})
export class SelectFieldOptionDirective implements IOption {

    @Input() label = '';
    @Input() value: any = '';
    @Input() icon = '';

    constructor() {
    }

}
