import {ChangeDetectorRef, Component, NgZone, OnInit} from '@angular/core';
import {ResultMetaSortImpl} from '../../commons/result-meta';

import {NewPageCountEvent} from '../../commons/paginator/paginator.component';
import {ToastrService} from 'ngx-toastr';
import {PreiseService} from '../services/preise.service';
import {KitchenTimer} from '../../commons/kitchen-timer';
import {DataTableFilterChanged, DataTableSortChanged} from '../../commons/data-table/data-table.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {SysInfoService} from '../../commons/sysinfo/services/sys-info.service';
import {IListResult} from '../../commons/list-result';
import {LocalizedComponent} from '../../commons/components/localized-component';
import {PreisgruppeList} from '../common/preisgruppe-list';
import {IPreisgruppe} from '../common/ipreisgruppe';
import {PreisgruppeEditorComponent} from '../preisgruppe-editor/preisgruppe-editor.component';
import {Preisgruppe} from '../common/preisgruppe';
import {RowActionParams} from '../../commons/data-table/row-action-params';

@Component({
    selector: 'app-tab-preisgruppen',
    templateUrl: './tab-preisgruppen.component.html',
    styleUrls: ['./tab-preisgruppen.component.scss']
})
export class TabPreisgruppenComponent extends LocalizedComponent implements OnInit {

    preisgruppen: PreisgruppeList = new PreisgruppeList();
    public preisgruppenTimer: KitchenTimer;


    constructor(

                public preiseService: PreiseService,
                public toastr: ToastrService,
                public modalService: NgbModal,
                public zone: NgZone,
                public sysInfoService: SysInfoService,
                public cd: ChangeDetectorRef

    ) {

        super( cd);
        this.preisgruppen = new PreisgruppeList();
        this.preisgruppenTimer = new KitchenTimer();
        this.preisgruppenTimer.subscribe(
            () => {
                this.preiseService
                    .listPreisgruppen(
                        this.preisgruppen.size,
                        this.preisgruppen.calcOffset(),
                        this.preisgruppen.order,
                        this.preisgruppen.simpleFilter,
                        this.preisgruppen.searchString
                    )
                    .subscribe(
                        (l: IListResult<IPreisgruppe>) => {
                            this.preisgruppen.populateFromListResult(l);
                        },
                        (e) => {
                            this.toastr.error(this._('Die Liste der Preisgruppen konnte nicht geladen werden.'));
                            this.preisgruppen.clear();
                        }
                    );
            }
        );
    }

    /**
     *
     */
    ngOnInit() {
        this.updatePreisgruppen();
    }

    /**
     * lädt die Stationenliste neu
     * @param force - sofort neuladen
     * @param page - Seitennummer ändern
     */
    updatePreisgruppen(force = false, page?: number) {
        if ((page !== undefined) && (page != null)) {
            if (page !== this.preisgruppen.page) {
                this.preisgruppen.page = page;
                force = true;
            }
        }
        this.preisgruppenTimer.start(force);
    }

    /**
     * ändert die Anzahl der Datensätze/Seite und lädt die Liste neu
     * @param n
     */
    updatePreisgruppenPageCount(n: NewPageCountEvent) {
        this.preisgruppen.size = n.itemsPerPage;
        this.updatePreisgruppen(true, n.currentPage);
    }

    newPreisgruppe() {
        PreisgruppeEditorComponent
            .open(this.modalService)
            .then(
                e => {
                    this.updatePreisgruppen(true);
                },
                () => {
                    this.updatePreisgruppen(true);
                }
            );
    }

    sortChanged(e: DataTableSortChanged) {
        this.preisgruppen.order = [
            new ResultMetaSortImpl(e.field, e.direction)
        ];
        this.updatePreisgruppen();
    }

    filterChanged(e: DataTableFilterChanged) {
        this.zone.run(() => {
            this.preisgruppen.simpleFilter[e.field] = e.value;
            this.updatePreisgruppen();
        });
    }

    editPreisgruppe(row: RowActionParams<Preisgruppe>) {
        PreisgruppeEditorComponent
            .open(this.modalService, row.row.data)
            .then(
                e => {
                    this.updatePreisgruppen(true);
                },
                () => {
                    this.updatePreisgruppen(true);
                }
            );

    }
}
