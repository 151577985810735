import {AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {LocalizedComponent} from '../../commons/components/localized-component';

import {ActivatedRoute, Router} from '@angular/router';
import {KundeList} from '../../customers/kunden/common/kunde-list';
import {KitchenTimer} from '../../commons/kitchen-timer';
import {AusweisList} from '../../customers/ausweise/common/ausweis-list';
import {KundenService} from '../../customers/kunden/common/kunden.service';
import {AusweisService} from '../../customers/ausweise/common/ausweis.service';

import {NewPageCountEvent} from '../../commons/paginator/paginator.component';
import {
    DataTableComponent,
    DataTableFilterChanged,
    DataTableSortChanged
} from '../../commons/data-table/data-table.component';
import {ResultMetaSortImpl} from '../../commons/result-meta';
import {LandCellRendererComponent} from '../../commons/data-table/renderer/land-cell-renderer.component';
import {Kunde} from '../../customers/kunden/common/kunde';
import {Ausweis} from '../../customers/ausweise/common/ausweis';
import {KundeCellRendererComponent} from '../../customers/kunden/kunde-cell-renderer.component';
import {DTFahrzeugKfzkennzeichenRendererComponent} from '../../customers/fahrzeuge/renderer/dtfahrzeug-kfzkennzeichen-renderer.component';
import {KontingenteCellRendererComponent} from '../../customers/limits/renderer/kontingente-cell-renderer/kontingente-cell-renderer.component';
import {KartenanfrageList} from '../../billing/kartenanfragen/common/kartenanfrage-list';
import {KartenanfrageService} from '../../billing/kartenanfragen/common/kartenanfrage.service';
import {AusweisCellRendererComponent} from '../../customers/ausweise/ausweis-cell-renderer.component';
import {PanCellRendererComponent} from '../../billing/kartenanfragen/renderers/pan-cell-renderer.component';
import {KartenanfrageErgebnisCellRendererComponent} from '../../billing/kartenanfragen/renderers/kartenanfrage-ergebnis-cell-renderer.component';
import {StationCellRendererComponent} from '../../stationen/station-cell-renderer.component';
import {TransaktionList} from '../../billing/transaktionen/common/transaktion-list';
import {TransaktionDetailsRendererComponent} from '../../billing/transaktionen/renderers/transaktion-details-renderer.component';
import {PreisWertCellRendererComponent} from '../../commons/data-table/renderer/preis-wert-cell-renderer.component';
import {TransaktionService} from '../../billing/transaktionen/common/transaktion.service';
import {KartenanfrageKontingentCellRendererComponent} from '../../billing/kartenanfragen/renderers/kartenanfrage-kontingent-cell-renderer.component';
import {AusweisEditorComponent} from '../../customers/ausweise/editor/ausweis-editor.component';
import {KundenAusweisEditorComponent} from '../../customers/ausweise/editor/kunden-ausweis-editor.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Transaktion} from '../../billing/transaktionen/common/transaktion';
import {TransaktionDetailsComponent} from '../../billing/transaktionen/details/transaktion-details.component';
import {Kartenanfrage} from '../../billing/kartenanfragen/common/kartenanfrage';
import {TransaktionPrintDialogComponent} from '../../billing/transaktionen/transaktion-print-dialog/transaktion-print-dialog.component';
import {IListResult} from '../../commons/list-result';
import {FahrzeugService} from '../../customers/fahrzeuge/common/fahrzeug.service';
import {ToastrService} from 'ngx-toastr';
import {IKunde} from '../../customers/kunden/common/ikunde';
import {IAusweis} from '../../customers/ausweise/common/iausweis';
import {ITransaktion} from '../../billing/transaktionen/common/itransaktion';
import {IKartenanfrage} from '../../billing/kartenanfragen/common/ikartenanfrage';
import {BegrenzungenCellRendererComponent} from '../../customers/limits/renderer/begrenzungen-cell-renderer/begrenzungen-cell-renderer.component';
import {DataTableRow} from '../../commons/data-table/data-table-row';
import {TDTGetExtraRowClassesCallback} from '../../commons/data-table/types';
import {FahrzeugList} from '../../customers/fahrzeuge/common/fahrzeug-list';
import {IFahrzeug} from '../../customers/fahrzeuge/common/ifahrzeug';
import {FahrzeugEditorComponent} from '../../customers/fahrzeuge/fahrzeug-editor/fahrzeug-editor.component';
import {Fahrzeug} from '../../customers/fahrzeuge/common/fahrzeug';
import {LoginService} from '../../auth/services/login.service';
import {RowActionParams} from '../../commons/data-table/row-action-params';

@Component({
    selector: 'app-suche',
    templateUrl: './suche.component.html',
    styleUrls: ['./suche.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SucheComponent extends LocalizedComponent implements OnInit, AfterViewInit {
    landR = LandCellRendererComponent;
    kundeR = KundeCellRendererComponent;
    kfzR = DTFahrzeugKfzkennzeichenRendererComponent;
    kontingentCellRenderer = KontingenteCellRendererComponent;
    panCellRenderer = PanCellRendererComponent;
    stationR = StationCellRendererComponent;
    ausweisR = AusweisCellRendererComponent;
    ergebnisCellRenderer = KartenanfrageErgebnisCellRendererComponent;
    public preiswert = PreisWertCellRendererComponent;
    prdCellRenderer = TransaktionDetailsRendererComponent;
    kontingentResultCellRenderer = KartenanfrageKontingentCellRendererComponent;
    begrenzungenCellRenderer = BegrenzungenCellRendererComponent;
    @ViewChild('kundenListe',{static:false}) kundenListe: DataTableComponent;

    get searchTitle() {
        return this._('Suche nach  {{q}}', {q: this.query});
    }

    query = '';

    kunden: KundeList = null;
    kundenTimer: KitchenTimer = null;

    ausweise: AusweisList = null;
    ausweiseTimer: KitchenTimer = null;

    anfragen: KartenanfrageList = null;
    anfragenTimer: KitchenTimer = null;

    transaktionen: TransaktionList = null;
    transaktionenTimer: KitchenTimer = null;

    fahrzeuge: FahrzeugList = new FahrzeugList();
    fahrzeugeTimer: KitchenTimer;

    kundenRowClassGetter: TDTGetExtraRowClassesCallback<Kunde> = (row: DataTableRow<Kunde>) => {
        if (
            row !== null &&
            row !== undefined &&
            row.data !== null &&
            row.data !== undefined
        ) {
            return 'kunde-' + (row.data.enabled ? 'frei' : 'gesperrt');
        }
        return '';
    }

    constructor(

                public cd: ChangeDetectorRef,
                public route: ActivatedRoute,
                public router: Router,
                public kundenService: KundenService,
                public ausweisService: AusweisService,
                public kartenanfrageService: KartenanfrageService,
                public transaktionService: TransaktionService,
                public toastr: ToastrService,
                public loginService: LoginService,
                public modalService: NgbModal,
                public fahrzeugService: FahrzeugService
    ) {
        super( cd);

        this.route.queryParams.subscribe((data) => {
            if (data) {
                this.setQuery(data['q']);
            }
        });


        this.kunden = new KundeList();
        this.kunden.size = this.loginService.getPageSize('kunden');

        this.kundenTimer = new KitchenTimer();
        this.kundenTimer
            .subscribe(
                () => {
                    this.kundenService
                        .list(
                            this.kunden.size,
                            this.kunden.calcOffset(),
                            this.kunden.order,
                            this.kunden.simpleFilter,
                            this.kunden.getQuery()
                        )
                        .subscribe(
                            (l: IListResult<IKunde>) => {
                                this.kunden.populateFromListResult(l);
                                this.cd.markForCheck();
                            },
                            (e) => {
                                this.toastr.error(this._('Die Liste der Kunden konnte nicht geladen werden.'));
                                this.kunden.clear();
                                this.cd.markForCheck();
                            }
                        );
                }
            );

        this.ausweise = new AusweisList();
        this.ausweise.size = this.loginService.getPageSize('ausweise');
        this.ausweiseTimer = new KitchenTimer();
        this.ausweiseTimer
            .subscribe(
                () => {
                    this.ausweisService
                        .list(
                            this.ausweise.size,
                            this.ausweise.calcOffset(),
                            this.ausweise.order,
                            this.ausweise.simpleFilter,
                            this.ausweise.searchString
                        )
                        .subscribe(
                            (l: IListResult<IAusweis>) => {
                                this.ausweise.populateFromListResult(l);
                                this.cd.markForCheck();
                            },
                            (e) => {
                                this.toastr.error(this._('Die Liste der Ausweise konnte nicht geladen werden.'));
                                this.ausweise.clear();
                                this.cd.markForCheck();
                            }
                        );
                }
            );

        this.anfragen = new KartenanfrageList();
        this.anfragen.size = this.loginService.getPageSize('kartenanfragen');
        this.anfragenTimer = new KitchenTimer();
        this.anfragenTimer
            .subscribe(
                () => {
                    this.kartenanfrageService
                        .list(
                            this.anfragen.size,
                            this.anfragen.calcOffset(),
                            this.anfragen.order,
                            this.anfragen.simpleFilter,
                            this.anfragen.searchString
                        )
                        .subscribe(
                            (l: IListResult<IKartenanfrage>) => {
                                this.anfragen.doPopulateFromListResult(l);
                                this.cd.markForCheck();

                            },
                            () => {
                                this.toastr.error(this._('Die Liste der Kartenanfragen konnte nicht geladen werden.'));
                                this.anfragen.clear();
                                this.cd.markForCheck();

                            }
                        );
                }
            );


        this.transaktionen = new TransaktionList();
        this.transaktionen.size = this.loginService.getPageSize('transaktionen');

        this.transaktionenTimer = new KitchenTimer();
        this.transaktionenTimer
            .subscribe(
                () => {
                    this.transaktionService
                        .list(
                            this.transaktionen.size,
                            this.transaktionen.calcOffset(),
                            this.transaktionen.order,
                            this.transaktionen.simpleFilter,
                            this.transaktionen.searchString
                        )
                        .subscribe(
                            (l: IListResult<ITransaktion>) => {
                                this.transaktionen.populateFromListResult(l);
                                this.cd.markForCheck();
                            },
                            (e) => {
                                this.toastr.error(this._('Die Liste der Transaktionen konnte nicht geladen werden.'));
                                this.transaktionen.clear();
                                this.cd.markForCheck();
                            }
                        );
                }
            );

        this.fahrzeuge = new FahrzeugList();
        this.fahrzeuge.size = this.loginService.getPageSize('fahrzeuge');
        this.fahrzeugeTimer = new KitchenTimer();
        this.fahrzeugeTimer
            .subscribe(
                () => {
                    this.fahrzeugService
                        .list(
                            this.fahrzeuge.size,
                            this.fahrzeuge.calcOffset(),
                            this.fahrzeuge.order,
                            this.fahrzeuge.simpleFilter,
                            this.fahrzeuge.searchString
                        )
                        .subscribe(
                            (l: IListResult<IFahrzeug>) => {
                                this.fahrzeuge.populateFromListResult(l);
                            },
                            (e) => {
                                this.toastr.error(this._('Die Liste der Fahrzeuge konnte nicht geladen werden.'));
                                this.fahrzeuge.clear();
                            }
                        );
                }
            );


    }

    ngOnInit() {
        this.setQuery(this.route.snapshot.queryParams['q']);
    }

    setQuery(q = '') {
        this.query = q;

        if (this.kunden !== null && this.kunden !== undefined) {
            this.kunden.clear();
            this.kunden.searchString = this.query;
            this.updateKunden();
        }

        if (this.ausweise !== null && this.ausweise !== undefined) {
            this.ausweise.clear();
            this.ausweise.searchString = this.query;
            this.updateAusweise();
        }

        if (this.anfragen !== null && this.anfragen !== undefined) {
            this.anfragen.clear();
            this.anfragen.searchString = this.query;
            this.updateAnfragen();
        }

        if (this.transaktionen !== null && this.transaktionen !== undefined) {
            this.transaktionen.clear();
            this.transaktionen.searchString = this.query;
            this.updateTransaktionen();
        }
        if (this.fahrzeuge !== null && this.fahrzeuge !== undefined) {
            this.fahrzeuge.clear();
            this.fahrzeuge.searchString = this.query;
            this.updateFahrzeuge();
        }

        this.cd.markForCheck();
    }

    updateTransaktionen(force = false, page?: number) {
        if ((page !== undefined) && (page != null)) {
            if (page !== this.transaktionen.page) {
                this.transaktionen.page = page;
                force = true;
            }
        }
        this.transaktionenTimer.start(force);
    }

    updateTransaktionenPageCount(n: NewPageCountEvent) {
        if (this.transaktionen.size !== n.itemsPerPage) {
            this.transaktionen.size = n.itemsPerPage;
            this.loginService.setPageSize('transaktionen', n.itemsPerPage);
        }
        this.updateTransaktionen(true, n.currentPage);
    }


    updateAusweise(force = false, page?: number) {
        if ((page !== undefined) && (page != null)) {
            if (page !== this.ausweise.page) {
                this.ausweise.page = page;
                force = true;
            }
        }
        this.ausweiseTimer.start(force);
    }

    updateAusweisePageCount(n: NewPageCountEvent) {
        if (this.ausweise.size !== n.itemsPerPage) {
            this.ausweise.size = n.itemsPerPage;
            this.loginService.setPageSize('ausweise', n.itemsPerPage);
        }
        this.updateAusweise(true, n.currentPage);
    }

    ausweiseSortChanged(e: DataTableSortChanged) {
        this.ausweise.order = [
            new ResultMetaSortImpl(e.field, e.direction)
        ];
        this.updateAusweise();
    }

    updateKunden(force = false, page?: number) {
        if ((page !== undefined) && (page != null)) {
            if (page !== this.kunden.page) {
                this.kunden.page = page;
                force = true;
            }
        }
        this.kundenTimer.start(force);
    }

    /**
     * ändert die Anzahl der Datensätze/Seite und lädt die Liste neu
     * @param n
     */
    updateKundenPageCount(n: NewPageCountEvent) {
        if (this.kunden.size !== n.itemsPerPage) {
            this.kunden.size = n.itemsPerPage;
            this.loginService.setPageSize('kunden', n.itemsPerPage);
        }
        this.updateKunden(true, n.currentPage);
    }

    sortChanged(e: DataTableSortChanged) {
        this.kunden.order = [
            new ResultMetaSortImpl(e.field, e.direction)
        ];
        this.updateKunden();
    }

    filterChanged(e: DataTableFilterChanged) {
        this.kunden.simpleFilter[e.field] = e.value;
        this.updateKunden();
    }


    updateAnfragen(force = false, page?: number) {
        if ((page !== undefined) && (page != null)) {
            if (page !== this.anfragen.page) {
                this.anfragen.page = page;
                force = true;
            }
        }
        this.anfragenTimer.start(force);
    }

    /**
     * ändert die Anzahl der Datensätze/Seite und lädt die Liste neu
     * @param n
     */
    updateAnfragenPageCount(n: NewPageCountEvent) {
        if (this.anfragen.size !== n.itemsPerPage) {
            this.anfragen.size = n.itemsPerPage;
            this.loginService.setPageSize('kartenanfragen', n.itemsPerPage);
        }
        this.updateAnfragen(true, n.currentPage);
    }


    gotoKunde($event: RowActionParams<Kunde>) {
        this.router.navigate(
            ['dtskunden'],
            {
                queryParams: {
                    k: 'nummer:' + $event.row.data.kundennummer
                }
            }
        );
    }

    gotoAusweis($event: RowActionParams<Ausweis>) {
        this.router.navigate(
            ['dtskunden'],
            {
                queryParams: {
                    k: 'nummer:' + $event.row.data.kunde.kundennummer,
                    a: 'nummer:' + $event.row.data.nummer
                }
            }
        );
    }

    syncAusweis(params: RowActionParams<Ausweis>) {

        this.ausweisService
            .syncAusweis(params.row.data)
            .subscribe(
                () => {
                    this.toastr.success(this._('Der Syncvorgang wurde angestoßen.'));
                },
                () => {
                    this.toastr.error(this._('Der Syncvorgang konnte nicht angestoßen werden.'));
                }
            );

    }

    syncKunde(params: RowActionParams<Kunde>) {

        this.kundenService
            .syncKunde(params.row.data)
            .subscribe(
                () => {
                    this.toastr.success(this._('Der Syncvorgang wurde angestoßen.'));
                },
                () => {
                    this.toastr.error(this._('Der Syncvorgang konnte nicht angestoßen werden.'));
                }
            );

    }


    editAusweis(params: RowActionParams<Ausweis>) {

        AusweisEditorComponent
            .open(this.modalService, params.row.data)
            .then(
                () => {
                    this.updateAusweise();
                },
                () => {
                    this.updateAusweise();
                }
            );

    }

    editOptions(params: RowActionParams<Ausweis>) {

        KundenAusweisEditorComponent
            .open(this.modalService, params.row.data)
            .then(
                () => {
                    this.updateAusweise();
                },
                () => {
                    this.updateAusweise();
                }
            );

    }

    showTransaktionDetails(event: RowActionParams<Transaktion>) {
        TransaktionDetailsComponent
            .open(this.modalService, event.row.data as Transaktion)
            .then(
                e => {
                    this.updateTransaktionen();
                },
                () => {
                    this.updateTransaktionen();
                }
            );
    }

    checkEnabled(what: string, rap: RowActionParams<any>) {
        if (what === 'pinreset') {
            if (rap.row.data.ergebnis !== null && rap.row.data.ergebnis !== undefined) {
                if (rap.row.data.ausweis !== null) {
                    if (rap.row.data.ergebnis.id === 12) {
                        rap.row.enableAction(rap.action);
                        rap.row.showAction(rap.action);

                    } else {
                        rap.row.disableAction(rap.action);
                        rap.row.hideAction(rap.action);
                    }
                }
            }
        }
    }

    unlockPIN(params: RowActionParams<Kartenanfrage>) {
        if (params.row.data.ausweis !== null && params.row.data.ausweis !== undefined) {
            this.ausweisService
                .unlockPinBlock(params.row.data.ausweis)
                .subscribe(
                    (ar) => {
                        if (ar.success) {
                            this.toastr.success(this._('PIN-Sperre wird in Kürze aufgehoben'));
                        } else {
                            this.toastr.error(this._('Fehler beim Aufheben der PIN-Sperre'));
                        }
                    },
                    () => {
                        this.toastr.error(this._('Fehler beim Aufheben der PIN-Sperre'));
                    }
                );
        }

    }

    showLieferschein(event: RowActionParams<Transaktion>) {
        TransaktionPrintDialogComponent
            .open(this.modalService, event.row.data as Transaktion)
            .then(
                e => {
                },
                () => {
                }
            );
    }

    updateFahrzeuge(force = false, page?: number) {
        if (this.fahrzeugeTimer === null || this.fahrzeugeTimer === undefined) {
            return;
        }
        if ((page !== undefined) && (page != null)) {
            if (page !== this.fahrzeuge.page) {
                this.fahrzeuge.page = page;
                force = true;
            }
        }
        this.fahrzeugeTimer.start(force);
    }

    /**
     * ändert die Anzahl der Datensätze/Seite und lädt die Liste neu
     * @param n
     */
    updateFahrzeugePageCount(n: NewPageCountEvent) {
        if (this.fahrzeuge.size !== n.itemsPerPage) {
            this.fahrzeuge.size = n.itemsPerPage;
            this.loginService.setPageSize('fahrzeuge', n.itemsPerPage);
        }
        this.updateFahrzeuge(true, n.currentPage);
    }

    fahrzeugeSortChanged(e: DataTableSortChanged) {
        this.fahrzeuge.order = [
            new ResultMetaSortImpl(e.field, e.direction)
        ];
        this.updateFahrzeuge();
    }


    editFahrzeug(params: RowActionParams<Fahrzeug>) {

        FahrzeugEditorComponent
            .open(this.modalService, params.row.data)
            .then(
                () => {
                    this.updateFahrzeuge();
                },
                () => {
                    this.updateFahrzeuge();
                }
            );
    }

    ngAfterViewInit(): void {
        if (this.kundenListe !== undefined && this.kundenListe !== null) {
            this.kundenListe.extraRowClasses = this.kundenRowClassGetter;
        }
    }
}
