import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';
import {enableProdMode, NgModuleRef} from '@angular/core';

import {AppModule} from './app/app.module';
import {environment} from './environments/environment';
import {MEAppInjector} from './app/shared/meapp-injector';

declare let loadingComplete: boolean;

if (environment.production) {
    enableProdMode();
}

platformBrowserDynamic()
    .bootstrapModule(AppModule,
        {
            preserveWhitespaces: false
        })
    .then(
        (appRef: NgModuleRef<AppModule>) => {
                        MEAppInjector.init(appRef.injector);

            loadingComplete = true;
            if ('serviceWorker' in navigator) {
                try {
                    if (environment.production) {
                        navigator.serviceWorker.register('/sw-custom.js');
                    }
                } catch (ignored) {

                }
            }
        }
    )
    .catch(
        (err) => {

        }
    );

