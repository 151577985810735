import {ChangeDetectorRef, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {LocalizedComponent} from '../../../commons/components/localized-component';
import {Nachricht} from '../common/nachricht';

import {NgbActiveModal, NgbModal, NgbModalOptions, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {LoginService} from '../../../auth/services/login.service';

import {PubSubService} from '../../../commons/pub-sub.service';
import {NachrichtenService} from '../../nachrichten.service';
import {ToastrService} from 'ngx-toastr';

@Component({
    selector: 'app-nachricht-details',
    templateUrl: './nachricht-details.component.html',
    styleUrls: ['./nachricht-details.component.css']
})
export class NachrichtDetailsComponent extends LocalizedComponent implements OnInit, OnDestroy {
    public sub: Subscription = null;

    @Input() entity: Nachricht = new Nachricht();

    constructor(

                public activeModal: NgbActiveModal,
                public loginService: LoginService,
                public messageService: NachrichtenService,
                public toastr: ToastrService,
                public cd: ChangeDetectorRef,
                public pubSub: PubSubService) {
        super( cd);
    }

    static open(modalService: NgbModal, nachricht: Nachricht): Promise<any> {
        const o: NgbModalOptions = {
            backdrop: 'static'
        };
        const r: NgbModalRef = modalService.open(NachrichtDetailsComponent, o);
        (r.componentInstance as NachrichtDetailsComponent).open(nachricht);
        return r.result;
    }

    open(nachricht: Nachricht) {
        if (nachricht === null) {
            this.cancel();
        }
        this.entity = Nachricht.fromResult(nachricht);
        if (!this.entity.isRead()) {
            this.messageService
                .markRead(this.entity)
                .subscribe(
                    () => {
                        this.pubSub.messageChange.emit();

                    }
                );
        }
    }

    ngOnInit() {
        this.sub = this.pubSub.loginError.subscribe(
            () => {
                this.cancel();
            }
        );
    }

    ngOnDestroy(): void {
        if (this.sub !== null) {
            this.sub.unsubscribe();
            this.sub = null;
        }
    }


    cancel() {
        this.activeModal.dismiss('cancel');
    }

    delete() {
        this.messageService
            .deleteNachricht(this.entity)
            .subscribe(
                () => {
                    this.pubSub.messageChange.emit();
                    this.cancel();
                },
                () => {
                    this.pubSub.messageChange.emit();
                    this.cancel();
                }
            );
    }

}
