import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {LocalizedComponent} from '../../../components/localized-component';
import {Subscription} from 'rxjs';
import {NgbActiveModal, NgbModal, NgbModalOptions, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {Phrase} from '../../common/phrase';
import {SprachenService} from '../../common/sprachen.service';


import {IPhrase} from '../../common/iphrase';
import {PubSubService} from '../../../pub-sub.service';
import {ToastrService} from 'ngx-toastr';

@Component({
    selector: 'app-translation-editor',
    templateUrl: './translation-editor.component.html',
    styleUrls: ['./translation-editor.component.css']
})
export class TranslationEditorComponent extends LocalizedComponent implements OnInit, OnDestroy {

    public sub: Subscription;
    entity = new Phrase();

    static open(modalService: NgbModal, phrase: IPhrase): Promise<any> {
        const o: NgbModalOptions = {
            backdrop: 'static'
        };
        const r: NgbModalRef = modalService.open(TranslationEditorComponent, o);
        (r.componentInstance as TranslationEditorComponent).open(phrase);
        return r.result;
    }

    constructor(

                public activeModal: NgbActiveModal,
                public toastr: ToastrService,
                public sprachenService: SprachenService,
                public pubSub: PubSubService,
                public cd: ChangeDetectorRef) {
        super( cd);

    }

    open(phrase: IPhrase) {
        this.entity = Phrase.fromResult(phrase);
    }

    ngOnInit() {
        this.sub = this.pubSub.loginError.subscribe(
            () => {
                this.cancel();
            }
        );
    }

    ngOnDestroy(): void {
        if (this.sub !== null && this.sub !== undefined) {
            this.sub.unsubscribe();
            this.sub = null;
        }
    }

    cancel() {
        this.activeModal.close();
    }

    save() {
        this.sprachenService
            .saveTranslations(this.entity)
            .subscribe(
                (r) => {
                    if (r.success) {
                        this.toastr.success(this._('Die Übersetzungen wurden erfolgreich gespeichert'));
                        this.activeModal.close('OK');
                    } else {
                        this.toastr.error(this._('Fehler beim Speichern der Übersetzungen!'));
                    }
                },
                () => {
                    this.toastr.error(this._('Fehler beim Speichern der Übersetzungen!'));
                }
            );
    }
}
