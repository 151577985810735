<data-table [selectable]="false"
            [data]="phrasen.asDataTableRows()"
            [rowActionsAsButtons]="true"
>
    <column [field]="'original'" [label]="t('Deutscher Text (Originalfassung)')"></column>


    <rowaction
            [label]="_('Übersetzung bearbeiten')"
            [icon]="'icofont icofont-ui-edit'"
            (perform)="editPhrase($event)"
    ></rowaction>

    <data-table-table-extra-header>
        <app-paginator
                [total]="phrasen.total"
                [page]="phrasen.page"
                [perpage]="phrasen.size"
                (pageChange)="updatePhrasen(true,$event)"
                (pageCountChange)="updatePhrasenPageCount($event)"
        ></app-paginator>
    </data-table-table-extra-header>
</data-table>

