import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';

import {LocalizedComponent} from '../commons/components/localized-component';
import {DataTableRow} from '../commons/data-table/data-table-row';
import {DataTableComponent} from '../commons/data-table/data-table.component';
import {ProduktGruppe} from './produktgruppen/common/produkt-gruppe';

@Component({
    selector: 'produkt-gruppe-selector',
    template: `
                   <div class="form-group row">
                       <label class="col-{{ labelwidth }} col-form-label" *ngIf="labelwidth>0">
                           {{ label }}
                       </label>
                       <div class="col-{{ 12-labelwidth }}">

                           <div class="card">
                               <div class="card-body">
                                   <div class="card-header clearfix">

                                       <div class="float-right">
                                           <button class="btn btn-default btn-sm"
                                                   [class.disabled]="checkAddDisabled()"
                                                   title="{{ _('gewählte Produktgruppe in Liste übernehmen') }}"
                                                   (click)="add()"
                                           >
                                               <i class="icofont icofont-ui-add"></i>
                                           </button>
                                           <button class="btn btn-default btn-sm"
                                                   [class.disabled]="checkDelDisabled()"
                                                   title="{{ _('gewählte Produktgruppe aus Liste entfernen') }}"
                                                   (click)="removeSelected()"
                                           >
                                               <i class="icofont icofont-ui-delete"></i>
                                           </button>
                                       </div>
                                       <produkt-gruppe-field
                                               [labelwidth]="0"
                                               [label]="_('Produktgruppe wählen')"
                                               [(value)]="currentProduktGruppe"
                                               [small]="true"
                                               [nullable]="false"
                                       ></produkt-gruppe-field>
                                   </div>
                                   <div class="card-text">
                                       <data-table
                                               [data]="produktGruppen"
                                               [selectable]="true"
                                               [singleselect]="true"
                                       >
                                           <column [label]="_('Nummer')" [field]="'nummer'" [type]="'Integer'"></column>
                                           <column [label]="_('Bezeichnung')" [field]="'bezeichnung'"></column>
                                       </data-table>
                                   </div>
                               </div>
                           </div>
                       </div>
                   </div>
               `,
    styles: [`
                               :host .card-header {
                                   padding: 0.35rem;
                               }

                               :host .card-header > div:first-child {
                                   padding-left: 0.35rem;
                               }

                               :host .card-header > produkt-gruppe-field {
                                   min-width: 175px;
                               }

                               :host .card-header ::ng-deep .form-group {
                                   margin: 0 !important;
                               }

                               :host .card-header ::ng-deep .form-group .col-12 {
                                   padding: 0px;
                               }

                               :host data-table {
                                   margin: 0 !important;
                                   font-size: 0.8rem;
                               }

                               :host data-table ::ng-deep .column-nummer {
                                   width: 50px;
                               }

                               :host data-table ::ng-deep td {
                                   padding: 0.35rem !important;
                               }

                           `]
})
export class ProduktGruppeSelectorComponent extends LocalizedComponent implements OnInit, AfterViewInit {


    @Input() label = 'Produktgruppen';
    @Input() labelwidth = 3;


    produktGruppen: DataTableRow<ProduktGruppe>[] = [];
    currentProduktGruppe: ProduktGruppe = null;

    @ViewChild(DataTableComponent, {static: true}) dataTable: DataTableComponent;

    upd() {
        if (this.dataTable !== null && this.dataTable !== undefined) {
            this.dataTable.updateRenderers();
        }
    }


    @Input()
    set value(v: ProduktGruppe[]) {
        v.forEach(e => {
            this.addElementToList(e, true);
        });
        this.produktGruppen.forEach(z => {
            let found = false;
            v.forEach(e => {
                if (e.id === (z.data as ProduktGruppe).id) {
                    found = true;
                }
            });
            if (!found) {
                this.removeElementFromList(z.data, true);
            }
        });
    }

    get value(): ProduktGruppe[] {
        let v: ProduktGruppe[] = [];
        this.produktGruppen.forEach(z => {
            v.push(z.data as ProduktGruppe);
        });
        return v;
    }

    @Output() valueChanged: EventEmitter<ProduktGruppe[]> = new EventEmitter<ProduktGruppe[]>();


    constructor(
        public cd: ChangeDetectorRef) {
        super(cd);
    }

    ngOnInit() {

    }

    ngAfterViewInit(): void {
        this.upd();
    }

    isElementInList(elem: ProduktGruppe): number {
        let position = -1;
        this.produktGruppen.forEach(
            (z, index) => {
                if ((z.data as ProduktGruppe).id === elem.id) {
                    position = index;
                }
            }
        );
        return position;
    }

    addElementToList(elem: ProduktGruppe, suppressEvent = false) {
        if (elem === null) {
            return;
        }
        if (elem === undefined) {
            return;
        }
        if (this.isElementInList(elem) < 0) {
            this.produktGruppen.push(new DataTableRow<ProduktGruppe>(elem));
            this.upd();
            if (!suppressEvent) {
                this.valueChanged.emit(this.value);
            }
        }
    }

    removeElementFromList(elem: ProduktGruppe, suppressEvent = false) {
        let pos = this.isElementInList(elem);
        if (pos >= 0) {
            this.produktGruppen.splice(pos, 1);
            this.upd();
            if (!suppressEvent) {
                this.valueChanged.emit(this.value);
            }
        }
    }

    add() {
        if ((this.currentProduktGruppe !== null) && (this.currentProduktGruppe !== undefined)) {
            this.addElementToList(this.currentProduktGruppe);
        }
    }

    checkAddDisabled(): boolean {
        let r = (this.currentProduktGruppe === null) || (this.currentProduktGruppe === undefined);
        if (r) {
            return r;
        }
        r = (this.isElementInList(this.currentProduktGruppe) >= 0);
        return r;
    }

    checkDelDisabled(): boolean {
        let e = true;

        this.produktGruppen.forEach(z => {
            if (z.isSelected()) {
                e = false;
            }
        });
        return e;
    }

    removeSelected() {
        this.produktGruppen.forEach(z => {
            if (z.isSelected()) {
                this.removeElementFromList(z.data);
            }
        });
    }


}
