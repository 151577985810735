import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {LocalizedComponent} from '../../commons/components/localized-component';

import {Geraet} from '../common/geraet';
import {Station} from '../../stationen/common/station';

@Component({
  selector: 'app-device-video-control-details-editor',
  templateUrl: './device-video-control-details-editor.component.html',
  styleUrls: ['./device-video-control-details-editor.component.scss']
})
export class DeviceVideoControlDetailsEditorComponent  extends LocalizedComponent implements OnInit {
 private _device: Geraet = null;
 private _station:Station=null;


    get station(): Station {
        return this._station;
    }

    set station(value: Station) {
        this._station = value;
    }

    @Input()
    set device(dev: Geraet) {
        if (this._device !== dev) {
            this._device = dev;
            this.cd.markForCheck();
        }
    }


    get device(): Geraet {
        return this._device;
    }

    @Output()
    onAction: EventEmitter<string> = new EventEmitter<string>();


    constructor(

                public cd: ChangeDetectorRef) {
        super( cd);
    }

  ngOnInit() {
  }

    sendAction(action: string) {
        this.onAction.emit(action);
    }
}
