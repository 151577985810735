export class SondenFilterChangedEvent {
    queryString = '';
    warnOnly = false;


    constructor(queryString: string, warnOnly: boolean) {
        this.queryString = queryString;
        this.warnOnly = warnOnly;
    }
}
