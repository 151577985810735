import {Component, ContentChildren, Directive, EventEmitter, Input, Output, QueryList} from '@angular/core';
import {ListOptionDirective} from '../components/list-option.directive';

@Component({
    selector: 'tableaction',
    template: ''
})
export class DataTableAction {

    @Input() id = '';
    @Input() label = 'Befehl';
    @Input() icon = '';
    @Input() title = '';
    @Input() type = '';

    @ContentChildren(ListOptionDirective) listOptions: QueryList<ListOptionDirective>;



    @Output() perform = new EventEmitter<any>();


    constructor() {
    }

    performAction() {

        this.perform.emit(this);
    }

    hasIcon(): boolean {
        return (this.icon !== undefined) &&
            (this.icon !== null) &&
            (this.icon !== '');
    }

    isNormalAction() {
        return !this.isDropdown();
    }

    isDropdown() {
        return this.type === 'dropdown';
    }

    hasOptions() {
        return (this.listOptions !== null) && (this.listOptions !== undefined) && (this.listOptions.length > 0);
    }
}
