import {ChangeDetectorRef, Component} from '@angular/core';
import {Benutzer} from '../../auth/common/benutzer';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';


import {GenericModalComponent} from '../../commons/generic-modal.component';
import {LoginService} from '../../auth/services/login.service';
import {ToastrService} from 'ngx-toastr';
import {PubSubService} from '../../commons/pub-sub.service';
import {Station} from '../../stationen/common/station';
import {Person} from '../../customers/personen/common/person';
import {Ausweis} from '../../customers/ausweise/common/ausweis';
import {Fahrzeug} from '../../customers/fahrzeuge/common/fahrzeug';

@Component({
    selector: 'app-benutzer-editor',
    templateUrl: './benutzer-editor.component.html',
    styleUrls: ['./benutzer-editor.component.scss']
})
export class BenutzerEditorComponent extends GenericModalComponent<Benutzer> {


    public initEntity(src: Benutzer): Benutzer {
        if (src === null || src === undefined) {
            return new Benutzer();
        }
        return Benutzer.fromResult(src);
    }

    constructor(
                
                public activeModal: NgbActiveModal,
                public toastr: ToastrService,
                public loginService: LoginService,
                public cd: ChangeDetectorRef,
                public pubsub: PubSubService) {
        super( loginService, activeModal, cd, pubsub);
    }

    save() {
        this.loginService
            .store(this.entity)
            .subscribe(r => {
                    if (r.success) {
                        this.activeModal.close(this.entity);
                    } else {
                        this.toastr.error(this._('Fehler beim Speichern der Benutzerdaten'));
                    }
                },
                () => {
                    this.toastr.error(this._('Fehler beim Speichern der Benutzerdaten'));
                }
            );
    }


    canSave() {
        if (this.entity.username === null ||
            this.entity.username === undefined ||
            ('' + this.entity.username).trim() === '') {
            return false;
        }

        if (this.entity.newPW !== null && this.entity.newPW !== undefined && ('' + this.entity.newPW).trim() !== '') {
            if (this.entity.newPW !== this.entity.newPW2) {
                return false;
            }
        }

        return !(this.isAdd() &&
            ((this.entity.newPW === null ||
                this.entity.newPW === undefined ||
                ('' + this.entity.newPW).trim() === '')));


    }

    hasRole(roleName: string | string[]): boolean {
        if (this.entity === null || this.entity === undefined || !Array.isArray(this.entity.permissions)) {
            if (Array.isArray(roleName)) {
                let ret = false;
                roleName.forEach((r: string) => {
                    if (this.entity.permissions.indexOf(r) >= 0) {
                        ret = true;
                    }
                });
                return ret;
            } else {
                return this.entity.permissions.indexOf((roleName as string).toUpperCase()) >= 0;
            }
        }
        return false;
    }

    updateStations($event: Station[]) {
        this.entity.stationen = $event;
        this.cd.markForCheck();
    }

    updateAusweise($event: Ausweis[]) {
        this.entity.ausweise = $event;
        this.cd.markForCheck();
    }

    updateFahrzeuge($event: Fahrzeug[]) {
        this.entity.fahrzeuge = $event;
        this.cd.markForCheck();
    }

    updatePersonen($event: Person[]) {
        this.entity.personen = $event;
        this.cd.markForCheck();
    }
}
