<div class="modal-header">
    <h5 class="modal-title">{{ _('Lieferschein drucken') }}</h5>
    <button type="button" class="close" aria-label="Close" (click)="cancel()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">

    <iframe id="printpreview" name="printpreview" src="about:blank"
            style="width:100%; height:450px; border: 1px solid #AAAAAA;">
    </iframe>


    <div id="lieferschein">
        <app-transaktion-lieferschein
                [transaktion]="entity"
        ></app-transaktion-lieferschein>
    </div>



</div>
<div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="cancel()">
        <i class="icofont icofont-close"></i> {{ _('Schließen') }}
    </button>
    <button type="button"
            class="btn btn-primary"
            (click)="printLieferschein()">
        <i class="far fa-print"></i>
        {{ _('Drucken')}}
    </button>
</div>