import {Component, Input, OnInit} from '@angular/core';
import {ITank} from './tanks/common/itank';

@Component({
    selector: 'app-tank-quick-info',
    template: `
        <div *ngIf="tank!==undefined && tank!==null">
            <i class="me-icon me-icon-tank"></i> {{ tank.bezeichnung }} <span class="badge badge-default">{{ tank.nummer }}</span>
        </div>
    `
})
export class TankQuickInfoComponent implements OnInit {

    @Input() tank: ITank = null;

    constructor() {
    }

    ngOnInit() {
    }

}
