<div class="card d-flex flex-row justify-content-between" *ngIf="!disabled || (_begrenzungen!==null && _begrenzungen!==undefined && _begrenzungen.length>0)">
    <div class="p-2 w-40">
        <div class="table-responsive">
            <table class="table table-hover"
                   >
                <thead>
                <tr>
                    <th style="width: 50px;">{{_("Art")}}</th>
                    <th>{{_('Bezeichnung')}}</th>
                    <th style="width: 30px;" class="text-center">{{_('aktiv')}}</th>
                    <th style="width: 50px;" *ngIf="!disabled"></th>
                </tr>
                </thead>
                <tr *ngFor="let bg of _begrenzungen"
                    [class.table-active]="isSelected(bg)"
                    (click)="selectBegrenzung(bg)"
                >
                    <td>
                        <i class="far fa-asterisk" *ngIf="bg.isDefault()" title="{{_('Standard-Begrenzung')}}"></i>
                        <i class="far fa-repeat" *ngIf="bg.isRecurring()"
                           title="{{_('sich wiederholende Begrenzung')}}"></i>
                        <i class="far fa-stop-circle" *ngIf="bg.isOneTime()" title="{{_('Einmal-Begrenzung')}}"></i>
                    </td>
                    <td>
                        {{  shorten(bg.info,35) }}
                    </td>
                    <td class="text-center">
                        <i class="far"
                           [class.fa-check-square]="bg.enabled"
                           [class.fa-square]="!bg.enabled"
                        ></i>
                    </td>
                    <td style="width: 50px;" class="text-right" *ngIf="!disabled">
                        <div class="btn-group btn-group-sm"
                             role="group" aria-label="Optionen">
                            <button type="button"
                                    class="btn btn-secondary"
                                    title="{{_('Begrenzung entfernen')}}"
                                    (click)="deleteBegrenzung(bg)"
                            ><i class="icofont icofont-ui-delete"></i></button>
                        </div>
                    </td>
                </tr>
            </table>
        </div>
        <div class="mt-5" *ngIf="!disabled">
            <button class="btn btn-primary" [disabled]="!canAddMore()" (click)="addBegrenzung()">{{ _('Begrenzung hinzufügen') }}</button>
        </div>
    </div>
    <div class="p-2 w-60">
        <app-limit-editor
                *ngIf="_currentBegrenzung!==null && _currentBegrenzung!==undefined && _begrenzungen!==null && _begrenzungen!==undefined && _begrenzungen.length>0"
                [value]="_currentBegrenzung"
                (valueChanged)="updateBegrenzung($event)"
                [limitsEditor]="self"
                [disabled]="disabled"
        ></app-limit-editor>
    </div>
</div>
<div class="alert alert-info" *ngIf="disabled && (_begrenzungen===null || _begrenzungen===undefined || _begrenzungen.length<1)">
    {{ _('Es wurden keine Begrenzungen eingestellt.') }}
</div>
