import {IIssuer} from './iissuer';

export class Issuer implements IIssuer {
    id = 0;
    nummer = 0;
    bezeichnung = '';
    enabled = false;
    pattern = '';
    extractAusweisNummerPattern = '';
    isRegexp = false;
    logo = '';
    rank = 0;
    isProjektIssuer = false;



    static fromResult(entity: IIssuer): Issuer {
        if ((entity === null) || (entity === undefined)) {
            return null;
        }
        let l: Issuer = new Issuer();
        for (let k of Object.keys(l)) {
            l[ k ] = (entity[ k ] !== undefined) ? entity[ k ] : '';
        }
        return l;
    }

    constructor() {
    }

}
