import { Component, OnInit } from '@angular/core';
declare let jQuery: JQueryStatic;

@Component({
  selector: 'app-sidebar-toggler',
  templateUrl: './sidebar-toggler.component.html',
  styleUrls: ['./sidebar-toggler.component.css']
})
export class SidebarTogglerComponent implements OnInit {

  public className = '';

  constructor() {
      this.check();
  }

  ngOnInit() {
      this.check();
  }

  check() {
      if (jQuery('#wrapper').hasClass('toggled')) {
          this.className = 'icofont-navigation-menu';
      } else {
          this.className = 'icofont-close';
      }
  }

  toogleSideNav() {
     jQuery('#wrapper').toggleClass('toggled');
     this.check();
  }
}
