<div class="modal-header">
    <h5 class="modal-title">{{ dlgTitle }}</h5>
    <button type="button" class="close" aria-label="Close" (click)="cancel()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">

    <app-tabset>
        <app-pane [title]="_('Identifikation')" *ngIf="false">
            <ausweis-art-field [label]="_('Ausweis-Art')"
                               [(value)]="entity.ausweisArt"
                               [disabled]="true"
            ></ausweis-art-field>
            <text-field
                    [(value)]="entity.pan"
                    [label]="_('Ausweiskennung/Pan')"
                    [disabled]="true"
            ></text-field>
            <issuer-field
                    [(value)]="entity.issuer"
                    [label]="_('Herausgeber')"
                    [disabled]="true"
            ></issuer-field>
            <num-field
                    [(value)]="entity.nummer"
                    [label]="_('Ausweisnummer')"
                    [disabled]="true"
            ></num-field>
        </app-pane>
        <app-pane [title]="_('Einstellungen')" *ngIf="!entity.fremdausweis">


            <boolean-field
                    [(value)]="entity.adminSperre"
                    [label]="_('administrativ gesperrt')"
                    [disabled]="true"
            ></boolean-field>

            <boolean-field
                    [(value)]="entity.kundenSperre"
                    [label]="_('kundenseitig gesperrt')"
            ></boolean-field>

            <div class="card">
                <div class="card-body">
                    <h4 class="card-title">{{_('PIN-Änderung')}}</h4>
                    <div class="card-text">
                        <p>
                            {{ _('Wenn Sie die PIN dieses Ausweises ändern wollen, geben Sie diese bitte hier ein ' +
                            'und klicken anschließend auf den Button "PIN festlegen"')}}
                        </p>

                        <text-field *ngIf="!isWifiCard()" [label]="_('PIN')" [value]="pin1"
                                    (valueChange)="setPin(1,$event)"></text-field>
                        <password-field *ngIf="isWifiCard()" [label]="_('PIN')" [value]="pin1"
                                        (valueChange)="setPin(1,$event)"></password-field>

                        <div class="alert alert-danger" *ngIf="message!==''">
                            {{ message }}
                        </div>
                        <button type="button"
                                class="btn btn-primary"
                                (click)="savePin()"
                                [class.disabled]="!canSavePin"
                                [disabled]="!canSavePin"
                        >
                            <i class="icofont icofont-key"></i>
                            {{ _('PIN festlegen')}}
                        </button>

                    </div>
                </div>
            </div>

        </app-pane>
        <app-pane [title]="_('Artikelfreigaben')" *ngIf="!entity.fremdausweis">
            <artikel-gruppe-field
                    [(value)]="entity.artikelGruppen[0]"
                    [label]="_('Artikelgruppe 1')"
                    [minor]="true"
                    [major]="false"
            ></artikel-gruppe-field>
            <artikel-gruppe-field
                    *ngIf="false"
                    [(value)]="entity.artikelGruppen[1]"
                    [label]="_('Artikelgruppe 2')"
                    [minor]="false"
                    [major]="true"
            ></artikel-gruppe-field>
        </app-pane>
        <app-pane [title]="_('Limitierungen')" *ngIf="!entity.fremdausweis">
            <app-kontingent-editor
                    [(value)]="entity.kontingent"
                    [ausweis]="entity"
                    [disabled]="false"
            ></app-kontingent-editor>
        </app-pane>
        <app-pane [title]="_('Freigaben')" *ngIf="!entity.fremdausweis && false">
            <app-freigabe-editor
                    [mode]="'card'"
                    [value]="entity.begrenzungen"
                    (valueChange)="updateBegrenzungen($event)"
            ></app-freigabe-editor>
        </app-pane>
        <app-pane *hasRole="['ROLE_ADM','ROLE_ADMIN','ROLE_PROJECT_ADMIN','ROLE_BILLING']"
                  [title]="_('Nachverfolgung')">
            <app-tab-ausweis-audit
                    [ausweis]="entity"
            ></app-tab-ausweis-audit>
        </app-pane>
    </app-tabset>
</div>
<div class="modal-footer">

    <button type="button" class="btn btn-secondary" (click)="cancel()">
        <i class="icofont icofont-close"></i> {{ _('Schließen') }}
    </button>
    <button type="button"
            class="btn btn-primary"
            (click)="save()"
            [class.disabled]="!canSave()"
            [disabled]="!canSave()"
    >
        <i class="icofont icofont-save"></i>
        {{ _('Änderungen speichern')}}
    </button>
</div>

