import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    NgZone,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import {LocalizedComponent} from '../../../commons/components/localized-component';
import {
    MEHighlitePipe,
    MESelectComponent,
    MESelectItemMatcherCallback,
    MESelectItemTextCallback
} from '../../../commons/dynamic-form/me-select/meselect.component';

import {FilterCallback} from '../../../commons/dynamic-form/FilterCallback';
import {GenericFilter} from '../../../commons/generic-filter';
import {CommonList} from '../../../commons/common-list';
import {IListResult} from '../../../commons/list-result';

import {MESelectItemFormatterCallback} from '../../../commons/dynamic-form/me-select/meselect-item-formatter-callback';
import {KostenstelleList} from '../common/kostenstelle-list';
import {Kostenstelle} from '../common/kostenstelle';
import {KostenstelleService} from '../kostenstelle.service';
import {ConverterTool} from '../../../../shared/converter-tool';
import {SimpleKostenstelleFilter} from '../common/simple-kostenstelle-filter';
import {IKostenstelle} from '../common/ikostenstelle';
import {MESelectSearchEvent} from '../../../commons/dynamic-form/me-select/meselect-search-event';
import CryptoES from "crypto-es";

@Component({
    selector: 'kostenstelle-inline-field',
    templateUrl: './kostenstelle-inline-field.component.html',
    styleUrls: ['./kostenstelle-inline-field.component.scss']
})
export class KostenstelleInlineFieldComponent extends LocalizedComponent implements OnInit, AfterViewInit {
    @Input() nullable = true;

    @ViewChild(MESelectComponent, {static: true}) meSelect: MESelectComponent;
    @Input() small = false;

    kostenstellen: KostenstelleList = new KostenstelleList();
    @Input() label = 'KostenstelleFeld';
    @Input() id = '';
    @Input() maxDisplayNum = 50;
    @Input() disabled = false;
    @Output() valueChange = new EventEmitter<Kostenstelle>();

    constructor(
        public kostenstelleService: KostenstelleService,
        public cd: ChangeDetectorRef,
        public _ngZone: NgZone) {
        super(cd);
    }

    _value: Kostenstelle = null;

    get value(): Kostenstelle {
        return this._value;
    }

    @Input()
    set value(v: Kostenstelle) {

        if (this._value !== v) {
            this._value = v;
            if ((this.meSelect !== null) && (this.meSelect !== undefined)) {
                this.meSelect.value = v;
            }
            this.valueChange.emit(v);
        }
    }

    public search: FilterCallback<Kostenstelle> = (filter: GenericFilter<Kostenstelle>,
                                                   list: CommonList<Kostenstelle>,
                                                   maxDisplayItems: number,
                                                   queryString: string,
                                                   cfg: any,
                                                   selectComponent: MESelectComponent
    ) => {

        const q = ConverterTool.processQuery(queryString);

        this.kostenstelleService
            .list(maxDisplayItems, 0, [], filter as SimpleKostenstelleFilter, q)
            .subscribe(
                (c: IListResult<IKostenstelle>) => {
                    list.populateFromListResult(c);

                    if (selectComponent !== null && selectComponent !== undefined) {
                        selectComponent.items = list.data;
                        selectComponent.cd.markForCheck();
                    }
                    this.cd.markForCheck();
                }
            );
    }

    ngAfterViewInit() {
        if (this.meSelect !== null && this.meSelect !== undefined) {
            this.meSelect.onFormat = this.itemFormat;
            this.meSelect.onGetText = this.itemText;
            this.meSelect.onMatch = this.itemMatcher;
            this.meSelect.value = this._value;
        }
    }

    ngOnInit() {
        if (this.id === '') {
            this.id = 'tf' + CryptoES.MD5('issuerfield-' + this.label + Math.random() + '-' + Math.random()).toString();
        }
        this.doSearch(new MESelectSearchEvent('', this.meSelect));
    }

    doSearch(value: MESelectSearchEvent) {
        this.search(null, this.kostenstellen, this.maxDisplayNum, value.queryString, null, value.component);
    }

    doSelect(kostenstelle: Kostenstelle) {
        this._value = kostenstelle;
        this.valueChange.emit(kostenstelle);
    }

    public itemFormat: MESelectItemFormatterCallback<Kostenstelle> = (item: Kostenstelle, search, forList: boolean) => {
        if (item === null) {
            return '';
        }


        let i = '';

        if (item.info !== null && item.info !== '' && item.info !== item.kostenstelle) {
            i = i + MEHighlitePipe.transformString(item.info, search);
        }
        if (item.kostenstelle !== null) {
            i = i + ' <span class=\'badge badge-default\'>' +
                MEHighlitePipe.transformString('' + item.kostenstelle, search) +
                '</span>';
        }
        return i;
    }

    public itemText: MESelectItemTextCallback<Kostenstelle> = (item: Kostenstelle) => {
        if (item === null) {
            return '';
        }
        let i = '';
        if (item.info !== null && item.info !== '' && item.info !== item.kostenstelle) {
            i = i + item.info;
        }
        return i + ' [' + item.kostenstelle + ']';
    }

    public itemMatcher: MESelectItemMatcherCallback<Kostenstelle> = (item: Kostenstelle, search) => {
        if (!search) {
            return true;
        }
        if (search.trim() === '') {
            return true;
        }
        if (!item) {
            return false;
        }
        const x = (item.info + ' ' + item.kostenstelle + ' ' + item.buchungskreis).toLowerCase();
        return ConverterTool.matches(x, '' + search);
    }

}
