import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import {
    MEHighlitePipe,
    MESelectComponent,
    MESelectItemMatcherCallback,
    MESelectItemTextCallback
} from '../../commons/dynamic-form/me-select/meselect.component';
import {Station} from '../../stationen/common/station';
import {MESelectSearchEvent} from '../../commons/dynamic-form/me-select/meselect-search-event';
import {FilterCallback} from '../../commons/dynamic-form/FilterCallback';
import {ISimpleFilter} from '../../commons/simple-filter';
import {CommonList} from '../../commons/common-list';
import {ConverterTool} from '../../../shared/converter-tool';
import {IListResult} from '../../commons/list-result';
import {MESelectItemFormatterCallback} from '../../commons/dynamic-form/me-select/meselect-item-formatter-callback';

import {Geraet} from '../common/geraet';
import {DeviceService} from '../common/device.service';
import {SimpleGeraetFilter} from '../common/simple-geraet-filter';
import {EDeviceType} from '../common/edevice-type.enum';
import {IGeraet} from '../common/igeraet';
import {DeviceType} from '../common/device-type';
import {GeraetList} from '../common/geraet-list';
import CryptoES from "crypto-es";

@Component({
    selector: 'geraet-inline-field',
    template: `
        <meselect
                *ngIf="entries!==null && entries!==undefined"
                id="{{ id }}"
                [idField]="'id'"
                [items]="entries.data"
                [asMatrix]="false"
                [placeholder]="label"
                (itemSelected)="doSelect($event)"
                (needsSearch)="doSearch($event)"
                [small]="small"
                [nullable]="nullable"
        >

        </meselect>`,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class GeraetInlineFieldComponent implements OnInit, AfterViewInit {

    @Input() nullable = true;

    @ViewChild(MESelectComponent, {static: true}) select: MESelectComponent;
    @Input() small = false;

    entries = new GeraetList();
    @Input() label = 'GerätField';
    @Input() id = '';
    @Input() maxDisplayNum = 10;
    @Input() station: Station = null;
    @Input() deviceType: EDeviceType = null;
    @Output() valueChange = new EventEmitter<Geraet>();

    constructor(public geraeteService: DeviceService,
                public cd: ChangeDetectorRef
    ) {
    }

    public _value: Geraet = null;

    get value(): Geraet {
        return this._value;
    }

    @Input()
    set value(v: Geraet) {
        if (this._value !== v) {
            this._value = v;
            if (this.select !== undefined) {
                this.select.value = v;
            }
            this.valueChange.emit(v);
        }
    }

    initSelect(): void {
        if (this.select !== null && this.select !== undefined) {
            this.select.onFormat = this.itemFormat;
            this.select.onGetText = this.itemText;
            this.select.onMatch = this.itemMatcher;
            this.select.value = this._value;
        }

    }

    ngOnInit() {
        this.initSelect();
        this.doSearch(new MESelectSearchEvent('', this.select));
    }

    doSearch(value: MESelectSearchEvent) {
        const f = new SimpleGeraetFilter();
        f.deviceType = this.deviceType;
        this.search(
            f,
            this.entries,
            this.maxDisplayNum,
            value.queryString,
            {
                station: this.station,
                deviceType: this.deviceType
            },
            value.component
        );
    }

    doSelect(entry: Geraet) {
        this._value = entry;
        this.valueChange.emit(entry);
        this.cd.markForCheck();
    }

    public search: FilterCallback<Geraet> = (filter: ISimpleFilter<Geraet>,
                                             list: CommonList<Geraet>,
                                             maxDisplayItems: number,
                                             queryString: string,
                                             cfg: any,
                                             selectComponent: MESelectComponent
    ) => {

        const q = ConverterTool.processQuery(queryString, ['nummer']);

        this
            .geraeteService
            .listDevices(
                cfg.station,
                maxDisplayItems,
                0,
                [],
                filter as SimpleGeraetFilter,
                q
            )
            .subscribe(
                (c: IListResult<IGeraet>) => {
                    list.populateFromListResult(c);
                    this.cd.markForCheck();
                    if (selectComponent !== null && selectComponent !== undefined) {
                        selectComponent.items = list.data;
                        selectComponent.cd.markForCheck();
                    }
                }
            );
    }

    public itemFormat: MESelectItemFormatterCallback<Geraet> = (item: Geraet, search) => {
        if (item === null) {
            return '';
        }

        const icon = DeviceType.getIcon(item.typ);

        return '<i class="' + icon + '"></i> ' +
            MEHighlitePipe.transformString(item.bezeichnung, search) +
            ' <span class=\'badge-default badge\'>' +
            MEHighlitePipe.transformString('' + item.nummer, search) +
            '</span>';
    }

    public itemText: MESelectItemTextCallback<Geraet> = (item: Geraet) => {
        if (item === null) {
            return '';
        }
        return item.bezeichnung + ' [' + item.nummer + ']';
    }

    public itemMatcher: MESelectItemMatcherCallback<Geraet> = (item: Geraet, search) => {
        if (!search) {
            return true;
        }
        if (search.trim() === '') {
            return true;
        }
        if (!item) {
            return false;
        }
        const x = item.bezeichnung + ' ' + item.nummer;

        return (x.toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) >= 0);
    }


    ngAfterViewInit(): void {
        this.initSelect();
        if (this.id === '') {
            this.id = 'gf' + CryptoES.MD5('geraetfield-' + this.label + Math.random() + '-' + Math.random()).toString();
        }
        this.cd.markForCheck();
    }


}
