import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {NgbActiveModal, NgbModal, NgbModalOptions, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';

import {LocalizedComponent} from '../../../../../commons/components/localized-component';
import {PubSubService} from '../../../../../commons/pub-sub.service';

@Component({
    selector: 'app-salden-reset-confirm-dialog',
    templateUrl: './salden-reset-confirm-dialog.component.html',
    styleUrls: ['./salden-reset-confirm-dialog.component.css']
})
export class SaldenResetConfirmDialogComponent extends LocalizedComponent implements OnInit, OnDestroy {

    sub: Subscription;
    mode = 'ausweis';
    resetPrepaid = false;

    static open(modalService: NgbModal, mode = 'ausweis'): Promise<any> {
        let o: NgbModalOptions = {
            backdrop: 'static'
        };
        let r: NgbModalRef = modalService.open(SaldenResetConfirmDialogComponent, o);
        (r.componentInstance as SaldenResetConfirmDialogComponent).open(mode);
        return r.result;
    }

    constructor(
                
                public activeModal: NgbActiveModal,
                public pubsub: PubSubService,
                public cd:ChangeDetectorRef) {
        super(cd);
    }

    open(mode = 'ausweis') {
        this.mode = mode;
        this.resetPrepaid = false;
    }

    ngOnInit(): void {
        this.sub = this.pubsub.loginError.subscribe(
            () => {
                this.cancel('Loginerror')
            }
        )
    }

    ngOnDestroy(): void {
        if (this.sub !== null) {
            this.sub.unsubscribe();
            this.sub = null;
        }
    }

    cancel(reason = 'cancel') {
        this.activeModal.dismiss(reason);
    }

    ok() {
        this.activeModal.close(this.resetPrepaid);
    }

}
