import {
    AfterViewInit, Component, ContentChildren, EventEmitter, Input, NgZone, OnInit, Output, QueryList,
    ViewChild
} from "@angular/core";
import {MEMultiSelectItemComponent} from "./memulti-select-item.component";

@Component({
               selector   : "memulti-select",
               templateUrl: "./memulti-select.component.html",
               styleUrls  : [ "./memulti-select.component.css" ]
           })
export class MEMultiSelectComponent implements OnInit, AfterViewInit {
    ngAfterViewInit(): void {
        setTimeout(_ => this.processValues());
    }

    @ContentChildren(MEMultiSelectItemComponent) items: QueryList<MEMultiSelectItemComponent>;

    _values: any[] = [];

    @Input() disabled: boolean = false;

    @Input()
    set value(v: any) {
        if (Array.isArray(v)) {
            this._values = v;
        }
        this.processValues();
    }

    @Output() valueChange: EventEmitter<any[]> = new EventEmitter<any[]>();


    constructor(public zone: NgZone) {
    }

    ngOnInit() {
    }

    processValues() {
        if (this.items === undefined || this.items === null || (this.items.length < 1)) {
            return;
        }
        if (this._values.length < 1) {
            this.items.forEach(it => it.doUnSelect());
        } else {
            this.items.forEach(it => {
                if (this._values.indexOf(it.value) >= 0) {
                    it.doSelect();
                } else {
                    it.doUnSelect();
                }
            });

        }
    }

    inform() {
        if (this.items === undefined || this.items === null || (this.items.length < 1)) {
            return;
        }
        this._values = [];
        this.items.forEach(it => {
            if (it.selected) {
                this._values.push(it.value);
            }
        });

        this.valueChange.emit(this._values);
    }

    findItemByValue(value: any): MEMultiSelectItemComponent {
        if (this.items === undefined || this.items === null || (this.items.length < 1)) {
            return null;
        }
        let r: MEMultiSelectItemComponent = null;
        this.items.forEach(it => {
            if (it.value === value) {
                r = it;
            }
        });
        return r;
    }

    selectItem(item: MEMultiSelectItemComponent) {
        if (item.doSelect()) {
            if (item.rulesOut !== null && item.rulesOut !== undefined) {
                if (Array.isArray(item.rulesOut)) {
                    item.rulesOut.forEach(ro => {
                        let n = this.findItemByValue(ro);
                        if (n !== null) {
                            n.doUnSelect();
                        }
                    });
                } else {
                    let n = this.findItemByValue(item.rulesOut);
                    if (n !== null) {
                        n.doUnSelect();
                    }
                }
            }
            if (item.implies !== undefined && item.implies !== null) {
                if (Array.isArray(item.implies)) {
                    item.implies.forEach(it => {
                        let n = this.findItemByValue(it);
                        if (n !== null) {
                            this.selectItem(n);
                        }
                    });
                } else {
                    let n = this.findItemByValue(item.implies);
                    if (n !== null) {
                        this.selectItem(n);
                    }
                }
            }
        }
    }


    doToggle(item: MEMultiSelectItemComponent) {
        if (!this.disabled) {
            if (item.selected) {
                item.doUnSelect();
            } else {
                this.selectItem(item);
            }
            this.inform();
        }
    }

}
