import {AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Output, ViewChild} from '@angular/core';
import {LieferscheinFilter} from './lieferschein-filter';
import {ProduktElementRendererComponent} from '../../../products/produkt-element-renderer.component';
import {StationElementRendererComponent} from '../../../stationen/station-element-renderer/station-element-renderer.component';
import {ElementListComponent} from '../../../commons/element-list/element-list.component';
import {IFilterDialog} from '../../../commons/data-table/ifilter-dialog';
import {LocalizedComponent} from '../../../commons/components/localized-component';


@Component({
    selector: 'app-lieferschein-filter-dialog',
    templateUrl: './lieferschein-filter-dialog.component.html',
    styleUrls: ['./lieferschein-filter-dialog.component.scss']
})
export class LieferscheinFilterDialogComponent extends LocalizedComponent implements IFilterDialog, AfterViewInit {

    stationRenderer = StationElementRendererComponent;
    produktRenderer = ProduktElementRendererComponent;

    filter = new LieferscheinFilter();

    @Output() filterChanged: EventEmitter<string> = new EventEmitter<string>();

    @ViewChild('stationen', {static: false}) stationen: ElementListComponent;
    @ViewChild('produkte', {static: false}) produkte: ElementListComponent;

    constructor(
        
        
        public cd: ChangeDetectorRef
    ) {
        super( cd);

        this.filter.filterChanged.subscribe(
            () => {
                if (this.stationen !== null && this.stationen !== undefined) {
                    this.stationen.elements = this.filter.stationen;
                }
                if (this.produkte !== null && this.produkte !== undefined) {
                    this.produkte.elements = this.filter.products;
                }
                this.markChanged();
            }
        );
    }

    ngAfterViewInit() {
        this.filter.lastThreeDays();
        this.filter.useTimeRange = false;
        this.markChanged();
    }

    getQuery(): string {
        return this.filter.getQuery();
    }

    markChanged(visble = true): void {
        this.cd.markForCheck();
        if (visble) {
            this.filterChanged.emit(this.getQuery());
        } else {
            this.filterChanged.emit('');
        }
    }


}
