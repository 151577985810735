<div *ngIf="value!==null && value!==undefined">
    <h4 class="card-title">{{ _('Begrenzung bearbeiten') }}</h4>
    <div class="card-text">

        <limit-type-field
                [label]="_('Art der Begrenzung')"
                [value]="value.type"
                (valueChange)="changeType($event)"
                [labelwidth]="4"
                [disabled]="disabled"

        ></limit-type-field>
        <text-field [label]="_('Bezeichnung')"
                    [value]="value.info"
                    (valueChange)="setInfo($event)"
                    [labelwidth]="4"
                    [disabled]="disabled"
        ></text-field>
        <boolean-field [label]="_('Begrenzung aktiv')"
                       [value]="value.enabled"
                       (valueChange)="setEnabled($event)"
                       [labelwidth]="4"
                       [disabled]="disabled"
        ></boolean-field>
        <app-tabset>
            <app-pane [title]="_('Länder')">
                <countries-selector
                        label=""
                        [labelwidth]="0"
                        [value]="value.laender"
                        (valueChanged)="setCountries($event)"
                        [disabled]="disabled"
                ></countries-selector>
                <small>
                    {{_('Keine Auswahl bedeutet alle verfügbaren Länder')}}
                </small>

            </app-pane>
            <app-pane [title]="_('Stationen')">
                <stationen-selector
                        label=""
                        [labelwidth]="0"
                        [value]="value.stationen"
                        (valueChanged)="setStationen($event)"
                        [disabled]="disabled"
                ></stationen-selector>
                <small>
                    {{_('Keine Auswahl bedeutet alle verfügbaren Stationen')}}
                </small>
            </app-pane>
            <app-pane [title]="_('Zeit')" *ngIf="!value.isDefault()">

                <date-time-field
                        [label]="_('Beginn')"
                        [value]="value.validFrom"
                        (valueChange)="setValidFrom($event)"
                        [labelwidth]="4"
                        [disabled]="disabled"
                >
                </date-time-field>

                <duration-field
                        [labelwidth]="4"
                        [label]="_('Dauer')"
                        [value]="value.duration"
                        (valueChange)="setDuration($event)"
                        [hideSeconds]="true"
                        [hideDays]="value.isRecurring()"
                        [disabled]="disabled"
                ></duration-field>


                <div class="row" *ngIf="value.isRecurring() || value.isDefault()">
                    <div class="col-4">
                        {{ _('Wochentage') }}
                    </div>
                    <div class="col-8">
                        <table class="table table-sm">
                            <thead>
                            <tr>
                                <th>{{_('Mo')}}</th>
                                <th>{{_('Di')}}</th>
                                <th>{{_('Mi')}}</th>
                                <th>{{_('Do')}}</th>
                                <th>{{_('Fr')}}</th>
                                <th>{{_('Sa')}}</th>
                                <th>{{_('So')}}</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>
                                    <check-box
                                            [value]="value.monday"
                                            (valueChange)="setMonday($event)"
                                            [title]="_('gilt montags')"
                                            [disabled]="disabled"
                                    ></check-box>
                                </td>
                                <td>
                                    <check-box [value]="value.tuesday"
                                               (valueChange)="setTuesday($event)"
                                               [title]="_('gilt dienstags')"
                                               [disabled]="disabled"
                                    ></check-box>
                                </td>
                                <td>
                                    <check-box [value]="value.wednesday"
                                               (valueChange)="setWednesday($event)"
                                               [title]="_('gilt mittwochs')"
                                               [disabled]="disabled"
                                    ></check-box>
                                </td>
                                <td>
                                    <check-box [value]="value.thirsday"
                                               (valueChange)="setThirsday($event)"
                                               [title]="_('gilt donnerstags')"
                                               [disabled]="disabled"
                                    ></check-box>
                                </td>
                                <td>
                                    <check-box [value]="value.friday"
                                               (valueChange)="setFriday($event)"
                                               [title]="_('gilt freitags')"
                                               [disabled]="disabled"
                                    ></check-box>
                                </td>
                                <td>
                                    <check-box [value]="value.saturday"
                                               (valueChange)="setSaturday($event)"
                                               [title]="_('gilt samstags')"
                                               [disabled]="disabled"
                                    ></check-box>
                                </td>
                                <td>
                                    <check-box [value]="value.sunday"
                                               (valueChange)="setSunday($event)"
                                               [title]="_('gilt sonntags')"
                                               [disabled]="disabled"
                                    ></check-box>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </app-pane>
            <app-pane [title]="_('Aktion')">

                <boolean-field
                        [label]="_('Anfragen immer Abweisen')"
                        [labelwidth]="5"
                        [value]="value.rejectAlways"
                        (valueChange)="setRejectAlways($event)"
                        [disabled]="disabled"
                ></boolean-field>

                <limit-art-field
                        *ngIf="!value.rejectAlways && false"
                        [label]="_('Art der Begrenzung')"
                        [labelwidth]="5"
                        [value]="value.limitArt"
                        (valueChange)="setLimitArt($event)"
                        [disabled]="disabled"
                ></limit-art-field>

                <num-field
                        *ngIf="!value.rejectAlways && value.isAmount() && false"
                        [label]="_('maximaler Tankbetrag')"
                        [labelwidth]="5"
                        [value]="value.amount"
                        (valueChange)="setAmount($event)"
                        [disabled]="disabled"
                ></num-field>


                <num-field
                        *ngIf="!value.rejectAlways && value.isVolume() && false"
                        [label]="_('maximale Menge')+'*'"
                        [labelwidth]="5"
                        [value]="value.volume"
                        (valueChange)="setVolume($event)"
                        [enableaddon]="true"
                        [addonicon]=""
                        [addonlabel]="['Liter']"
                        [disabled]="disabled"
                ></num-field>

                <small *ngIf="!value.rejectAlways && value.isVolume() && false">
                    <sup>*</sup> {{_('Diese Einstellung kann nicht zu 100% an allen Stationen sichergestellt werden.')}}
                </small>
            </app-pane>
        </app-tabset>
    </div>
</div>
