import {AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {Fahrzeug} from '../../../customers/fahrzeuge/common/fahrzeug';
import {Waehrung} from '../../../commons/waehrungen/waehrung';
import {TankCellRendererComponent} from '../../../stationen/tank-cell-renderer.component';
import {NgbActiveModal, NgbModal, NgbModalOptions, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';

import {PersonService} from '../../../customers/personen/common/person.service';
import {Transaktion} from '../common/transaktion';
import {MengeCellRendererComponent} from '../../../products/menge-cell-renderer.component';
import {ProduktCellRendererComponent} from '../../../products/produkt-cell-renderer.component';
import {ExtendedZapfpunktRendererComponent} from '../../../stationen/extended-zapfpunkt-renderer.component';
import {PreisWertCellRendererComponent} from '../../../commons/data-table/renderer/preis-wert-cell-renderer.component';
import {ToastrService} from 'ngx-toastr';
import {FahrzeugService} from '../../../customers/fahrzeuge/common/fahrzeug.service';
import {LocalizedComponent} from '../../../commons/components/localized-component';
import {Ausweis} from '../../../customers/ausweise/common/ausweis';
import {TransaktionService} from '../common/transaktion.service';
import {Kunde} from '../../../customers/kunden/common/kunde';
import {IActionResponse} from '../../../commons/action-response';
import {Kostenstelle} from '../../../customers/kostenstellen/common/kostenstelle';
import {TabsetComponent} from '../../../commons/tabcard/tabset.component';

@Component({
    selector: 'app-transaktion-editor',
    templateUrl: './transaktion-editor.component.html',
    styleUrls: ['./transaktion-editor.component.scss']
})
export class TransaktionEditorComponent extends LocalizedComponent implements OnInit, AfterViewInit {

    @ViewChild('tabset1',{static: true}) tabset1: TabsetComponent;

    produktR = ProduktCellRendererComponent;
    tankR = TankCellRendererComponent;
    zapfpunktR = ExtendedZapfpunktRendererComponent;
    preiswert = PreisWertCellRendererComponent;
    mengeR = MengeCellRendererComponent;

    showBrutto = false;
    entity: Transaktion = new Transaktion();
    mode: any = 'new';

    static open(modalService: NgbModal, transaktion: Transaktion): Promise<any> {
        const o: NgbModalOptions = {
            size: 'lg',
            backdrop: 'static'
        };
        const r: NgbModalRef = modalService.open(TransaktionEditorComponent, o);
        (r.componentInstance as TransaktionEditorComponent).open(transaktion);
        return r.result;
    }


    constructor(
                
                public activeModal: NgbActiveModal,
                public toastr: ToastrService,
                public fahrzeugService: FahrzeugService,
                public personService: PersonService,
                public transaktionService: TransaktionService,
                public cd: ChangeDetectorRef
    ) {
        super( cd);
    }


    open(transaktion?: Transaktion) {
        if ((transaktion === null) || (transaktion === undefined)) {
            this.entity = new Transaktion();
        } else {
            this.entity = Transaktion.fromResult(transaktion);
            if (this.entity.fahrzeug !== null && this.entity.fahrzeug !== undefined) {
                this.fahrzeugService
                    .load(this.entity.fahrzeug.id)
                    .subscribe(
                        f => {
                            this.entity.fahrzeug = Fahrzeug.fromResult(f);
                        },
                        () => {

                        }
                    );
            }
            if (this.entity.person !== null && this.entity.person !== undefined) {
                this.personService
                    .load(this.entity.person.id)
                    .subscribe(
                        f => {
                            this.entity.person = f;
                        },
                        () => {

                        }
                    );
            }
        }
        if (this.entity.waehrung === undefined || this.entity.waehrung === null) {
            this.entity.waehrung = new Waehrung();
            this.entity.waehrung.code = 'EUR';
        }
    }

    ngOnInit() {

    }

    cancel() {
        this.activeModal.dismiss();
    }

    hasAusweis1(): boolean {
        if ((this.entity.ausweiskennung1 !== undefined) &&
            (this.entity.ausweiskennung1 !== null) &&
            (this.entity.ausweiskennung1 !== '')) {
            return true;
        }
        if ((this.entity.ausweis1 !== undefined) &&
            (this.entity.ausweis1 !== null)) {
            return true;
        }
        return false;
    }

    hasAusweis2(): boolean {
        if ((this.entity.ausweiskennung2 !== undefined) &&
            (this.entity.ausweiskennung2 !== null) &&
            (this.entity.ausweiskennung2 !== '')) {
            return true;
        }
        if ((this.entity.ausweis2 !== undefined) &&
            (this.entity.ausweis2 !== null)) {
            return true;
        }
        return false;
    }

    storno() {
        this.transaktionService
            .stornoTransaktion(this.entity).subscribe((r: IActionResponse) => {
            if (r.success) {
                this.toastr.success(this._('Die Transaktion wurde storniert'));
                this.activeModal.close();
            } else {
                this.toastr.error(this._('Fehler beim Stornieren der Transaktion'));
            }
        }, () => {
            this.toastr.error(this._('Fehler beim Stornieren der Transaktion'));
        });
    }

    save(mitStorno: boolean) {
        this.transaktionService
            .storeTransaktion(this.entity, mitStorno)
            .subscribe((r: IActionResponse) => {
                if (r.success) {
                    this.toastr.success(this._('Die Änderungen wurden gespeichert'));
                    this.activeModal.close();
                } else {
                    this.toastr.error(this._('Fehler beim Speichern der Transaktion'));
                }
            }, () => {
                this.toastr.error(this._('Fehler beim Speichern der Transaktion'));
            });
    }

    setAusweis1($event: Ausweis) {
        this.entity.ausweis1 = $event;
        if ($event !== null && $event !== undefined) {
            this.entity.ausweiskennung1 = $event.pan;
            if ($event.kostenstelle !== null && (this.entity.kostenstelle === undefined || this.entity.kostenstelle === null)) {
                this.entity.kostenstelle = $event.kostenstelle;
            }

            this.setFahrzeug($event.fahrzeug);

            this.setKunde($event.kunde);

        }
    }

    setKunde($event: Kunde) {
        this.entity.kunde = $event;
        if ($event.kostenstelle !== null && (this.entity.kostenstelle === undefined || this.entity.kostenstelle === null)) {
            this.entity.kostenstelle = $event.kostenstelle;
        }
    }

    setFahrzeug($event: Fahrzeug) {
        if (this.entity !== null && this.entity !== undefined) {
            this.entity.fahrzeug = $event;
            if ($event !== null && $event !== undefined) {
                if ($event.kostenstelle !== null && (this.entity.kostenstelle === undefined || this.entity.kostenstelle === null)) {
                    this.entity.kostenstelle = $event.kostenstelle;
                }
            }
        }
    }


    setKostenstelle($event: Kostenstelle) {
        if (this.entity !== null && this.entity !== undefined) {

            this.entity.kostenstelle = $event;
        }
    }

    ngAfterViewInit(): void {
        if (this.tabset1 !== null && this.tabset1 !== undefined) {
            this.tabset1.selectTab('tab_transaktion_zuordnungen');
        }
    }
}
