import {ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {GenericElementRendererComponent} from './generic-element-renderer.component';
import {LocalizedComponent} from '../components/localized-component';


@Component({
    selector: 'element-list',
    templateUrl: './element-list.component.html',
    styleUrls: ['./element-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ElementListComponent extends LocalizedComponent implements OnInit {

    @Input() renderer = GenericElementRendererComponent;

    @Input() displayControlHeader = true;

    @Output() onClearAll: EventEmitter<void> = new EventEmitter<void>();
    @Output() onRemoveElement: EventEmitter<any> = new EventEmitter<any>();

    private _elements: any[] = [];


    get elements(): any[] {
        return this._elements;
    }

    @Input()
    set elements(value: any[]) {
        this._elements = value;
        this.cd.markForCheck();
    }

    constructor(

                public cd: ChangeDetectorRef) {
        super( cd);
    }

    ngOnInit() {
    }

    removeElement(e: any) {
        this.onRemoveElement.emit(e);
    }

    getRenderer(e: any): any {
        const rd = {
            component: GenericElementRendererComponent,
            inputs: {
                value: e
            }
        };

        if (this.renderer !== null && this.renderer !== undefined) {
            rd.component = this.renderer;
        }

        return rd;
    }

    clearAll() {
        this.onClearAll.emit();
    }
}
