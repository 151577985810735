import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {
    MESelectComponent, MESelectItemMatcherCallback,
    MESelectItemTextCallback
} from './me-select/meselect.component';

import {MESelectItemFormatterCallback} from './me-select/meselect-item-formatter-callback';
import CryptoES from "crypto-es";

@Component({
    selector: 'flag-field',
    template: `
        <div class="form-group row">
            <label for="{{ id }}" class="col-{{ labelwidth }} col-form-label">
                {{ label }}
            </label>
            <div class="col-{{ 12-labelwidth }}">

                <meselect
                        id="{{ id }}"
                        [idField]="''"
                        [items]="flags"
                        [asMatrix]="true"
                        [placeholder]="label"
                        (itemSelected)="doSelect($event)"
                >

                </meselect>

            </div>
        </div>
        `
})
export class FlagFieldComponent implements OnInit, AfterViewInit {

    @ViewChild(MESelectComponent, {static: true}) flagMESelect: MESelectComponent;

    public flags: string [] = [
        'ad', 'ae', 'af', 'ag', 'ai', 'al', 'am', 'ao', 'aq', 'ar', 'as', 'at',
        'au', 'aw', 'ax', 'az', 'ba', 'bb', 'bd', 'be', 'bf', 'bg', 'bh', 'bi',
        'bj', 'bl', 'bm', 'bn', 'bo', 'bq', 'br', 'bs', 'bt', 'bv', 'bw', 'by',
        'bz', 'ca', 'cc', 'cd', 'cf', 'cg', 'ch', 'ci', 'ck', 'cl', 'cm', 'cn',
        'co', 'cr', 'cu', 'cv', 'cw', 'cx', 'cy', 'cz', 'de', 'dj', 'dk', 'dm',
        'do', 'dz', 'ec', 'ee', 'eg', 'eh', 'er', 'es', 'et', 'fi', 'fj', 'fk',
        'fm', 'fo', 'fr', 'ga', 'gb', 'gd', 'ge', 'gf', 'gg', 'gh', 'gi', 'gl',
        'gm', 'gn', 'gp', 'gq', 'gr', 'gs', 'gt', 'gu', 'gw', 'gy', 'hk', 'hm',
        'hn', 'hr', 'ht', 'hu', 'id', 'ie', 'il', 'im', 'in', 'io', 'iq', 'ir',
        'is', 'it', 'je', 'jm', 'jo', 'jp', 'ke', 'kg', 'kh', 'ki', 'km', 'kn',
        'kp', 'kr', 'kw', 'ky', 'kz', 'la', 'lb', 'lc', 'li', 'lk', 'lr', 'ls',
        'lt', 'lu', 'lv', 'ly', 'ma', 'mc', 'md', 'me', 'mf', 'mg', 'mh', 'mk',
        'ml', 'mm', 'mn', 'mo', 'mp', 'mq', 'mr', 'ms', 'mt', 'mu', 'mv', 'mw',
        'mx', 'my', 'mz', 'na', 'nc', 'ne', 'nf', 'ng', 'ni', 'nl', 'no', 'np',
        'nr', 'nu', 'nz', 'om', 'pa', 'pe', 'pf', 'pg', 'ph', 'pk', 'pl', 'pm',
        'pn', 'pr', 'ps', 'pt', 'pw', 'py', 'qa', 're', 'ro', 'rs', 'ru', 'rw',
        'sa', 'sb', 'sc', 'sd', 'se', 'sg', 'sh', 'si', 'sj', 'sk', 'sl', 'sm',
        'sn', 'so', 'sr', 'ss', 'st', 'sv', 'sx', 'sy', 'sz', 'tc', 'td', 'tf',
        'tg', 'th', 'tj', 'tk', 'tl', 'tm', 'tn', 'to', 'tr', 'tt', 'tv', 'tw',
        'tz', 'ua', 'ug', 'um', 'us', 'uy', 'uz', 'va', 'vc', 've', 'vg',
        'vi', 'vn', 'vu', 'wf', 'ws', 'ye', 'yt', 'za', 'zm', 'zw', 'eu',
        'gb-eng', 'gb-nir', 'gb-sct', 'gb-wls', 'un'];

    public _value: string = null;

    @Input() label = 'Textfeld';
    @Input() id = '';
    @Input() labelwidth = 3;

    @Input()
    set value(v: string) {
        if (this._value !== v) {
            this._value = v;
            this.valueChange.emit(v);
        }
    }

    @Output() valueChange = new EventEmitter<string>();


    get value(): string {
        return this._value;
    }

    constructor() {
    }

    ngOnInit() {
        if (this.id === '') {
            this.id = 'tf' + CryptoES.MD5('textfield-' + this.label + Math.random() + '-' + Math.random()).toString();
        }
    }

    ngAfterViewInit(): void {
        this.flagMESelect.onFormat = this.itemFormat;
        this.flagMESelect.onGetText = this.itemText;
        this.flagMESelect.onMatch = this.itemMatcher;
        this.flagMESelect.value = this._value;
    }


    doSelect(flag: string) {
        this._value = flag;
        this.valueChange.emit(flag);
    }

    public itemFormat: MESelectItemFormatterCallback<string> = (item: string, search) => {
        if (item === null) {
            return '';
        }
        return '<i class="flag-icon flag-icon-squared flag-icon-' + item + '" title="' + item + '" ></i>';
    }

    public itemText: MESelectItemTextCallback<string> = (item: string) => {
        if (item === null) {
            return '';
        }
        return item;
    }

    public itemMatcher: MESelectItemMatcherCallback<string> = (item: string, search) => {
        if (!search) {
            return true;
        }
        if (search.trim() === '') {
            return true;
        }
        if (!item) {
            return false;
        }
        const x = '' + item;

        return (x.toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) >= 0);
    }


}
