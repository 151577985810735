import {ITankBestand} from './itank-bestand';
import {ITank} from '../../../stationen/tanks/common/itank';
import {Tank} from '../../../stationen/tanks/common/tank';
import {ConverterTool} from '../../../../shared/converter-tool';
import {Station} from '../../../stationen/common/station';
import {Produkt} from '../../../products/produkte/common/produkt';

export class TankBestand implements ITankBestand {
    abgaenge = 0;
    bestand = 0;
    bestandBefore = null;
    bestandPeilung: number = null;
    changed = false;
    id = 0;
    datum: Date = null;
    tank: Tank = null;
    zugaenge = 0;
    dateCreated: Date = null;
    lastUpdated: Date = null;
    station: Station = null;
    produkt: Produkt = null;
    warenWert = 0;
    abweichung = 0;
    bestandAenderung = 0;
    peilungDifferenz = 0;
    needsRecalc=false

     static fromResult(entity: ITankBestand, createIfEmpty = true): TankBestand {
        if ((entity === null) || (entity === undefined)) {
            if (createIfEmpty) {
                return new TankBestand();
            }
            return null;
        }

        const l = new TankBestand();
        for (const k of Object.keys(l)) {
            if (k === 'dateCreated') {
                l.dateCreated = ConverterTool.toDate(entity.dateCreated);
            } else if (k === 'lastUpdated') {
                l.lastUpdated = ConverterTool.toDate(entity.lastUpdated);
            } else if (k === 'datum') {
                l.datum = ConverterTool.toDate(entity.datum);
            } else if (k === 'tank') {
                l.tank = Tank.fromResult(entity.tank);
            } else if (k === 'station') {
                l.station = Station.fromResult(entity.station);
            } else if ( k === 'produkt') {
                l.produkt = Produkt.fromResult(entity.produkt);
            } else if (k === 'warenWert') {
                l.warenWert = parseFloat('' + entity.warenWert);
            }else if (k==='needsRecalc'){
                l.needsRecalc=ConverterTool.toBoolean(entity.needsRecalc);
            } else {
                l[k] = (entity[k] !== undefined) ? entity[k] : '';
            }
        }

        if (l.bestandPeilung === null ||
            l.bestandPeilung === undefined ||
            ('' + l.bestandPeilung).trim() === ''
        ) {
            l.abweichung = null;
        } else {
            l.abweichung =  l.bestandPeilung - l.bestand;
        }

        return l;
    }

    constructor() {
    }
}
