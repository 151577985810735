import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-fahrzeug-nummer-quick-info',
  templateUrl: './fahrzeug-nummer-quick-info.component.html',
  styleUrls: ['./fahrzeug-nummer-quick-info.component.scss']
})
export class FahrzeugNummerQuickInfoComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
