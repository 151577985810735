import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {LocalizedComponent} from '../../../commons/components/localized-component';
import {NgbActiveModal, NgbModal, NgbModalOptions, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {Issuer} from '../common/issuer';


import {SystemSettingsService} from '../../system-settings.service';
import {ToastrService} from 'ngx-toastr';

@Component({
               selector   : 'app-issuer-editor',
               templateUrl: './issuer-editor.component.html',
               styleUrls  : [ './issuer-editor.component.css' ]
           })
export class IssuerEditorComponent extends LocalizedComponent implements OnInit {


    entity: Issuer = new Issuer();
    mode: any      = 'new';


    static open(modalService: NgbModal, issuer?: Issuer): Promise<any> {
        const o: NgbModalOptions = {
            /* size: 'lg',*/
            backdrop: 'static'
        };
        const r: NgbModalRef     = modalService.open(IssuerEditorComponent, o);
        (r.componentInstance as IssuerEditorComponent).open(issuer);
        return r.result;
    }


    constructor(
                
                public activeModal: NgbActiveModal,
                public toastr: ToastrService,
                public systemsettingsService: SystemSettingsService,
                public cd: ChangeDetectorRef) {
        super( cd);
    }


    open(issuer?: Issuer) {
        if ((issuer === null) || (issuer === undefined)) {
            this.entity = new Issuer();
            this.mode   = 'new';
        } else {
            this.entity = Issuer.fromResult(issuer);
            this.mode   = 'edit';
        }
    }

    isAdd() {
        return this.mode === 'new';
    }

    isEdit() {
        return this.mode === 'edit';
    }

    ngOnInit() {
    }

    cancel() {
        this.activeModal.dismiss();
    }

    save() {
        this.systemsettingsService
            .storeIssuer(this.entity, this.isAdd())
            .subscribe(
                r => {
                    if (r.success) {
                        this.toastr.success(this._('Herausgeber erfolgreich gespeichert'));
                        this.activeModal.close(this.entity);
                    } else {
                        this.toastr.success(this.t('Fehler beim Sichern des Kartenherausgebers'));
                    }
                }
            );
    }
}
