<app-login-alert>

    <app-area-heading
            [icon]="'icofont icofont-listing-number'"
            title="{{ _('Transaktionen') }}"
    ></app-area-heading>


    <app-tabcard>
        <app-pane title="{{ _('Transaktionen') }}">
            <app-tab-transaktionen></app-tab-transaktionen>
        </app-pane>
        <app-pane title="{{_('Nacherfassung')}}" *hasRole="['ROLE_ADMIN','ROLE_PROJECT_ADMIN','ROLE_BILLING','ROLE_TRANSAKTIONEN_WRITABLE']">
            <app-tab-nachbuchungen></app-tab-nachbuchungen>
        </app-pane>
    </app-tabcard>


</app-login-alert>