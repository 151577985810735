<ul class="layers {{ classes }}" *ngIf="kunde!==null && kunde!==undefined">
    <li class="ib-icon">
        <i class="{{ icon }}"></i>
    </li>
    <li class="ib-title">
        {{ title }}
    </li>
    <li class="ib-value">
        <table>
            <tr>
                <td>{{_('Kundennummer')}}</td>
                <td>{{kunde.kundennummer}}</td>
            </tr>
            <tr>
                <td valign="top">{{_('Anschrift')}}</td>
                <td  valign="top" [innerHTML]="kunde.getSummary(true)"></td>
            </tr>
            <tr>
                <td>{{_('USt-ID/Steuernummer')}}</td>
                <td>{{kunde.ustID}}</td>
            </tr>
            <tr>
                <td>{{_('EMail')}}</td>
                <td>{{kunde.email}}</td>
            </tr>
            <tr>
                <td>{{_('Telefon')}}</td>
                <td>{{kunde.telefon}}</td>
            </tr>
        </table>
    </li>
    <li class="ib-controls">
        <div [innerHTML]="msg"></div>
    </li>
</ul>
