import {AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, Pipe, PipeTransform, ViewChild, ViewEncapsulation} from '@angular/core';
import {MESelectItemFormatterCallback} from './meselect-item-formatter-callback';
import {MESelectSearchEvent} from './meselect-search-event';

@Pipe({name: 'highlight'})
export class MEHighlitePipe implements PipeTransform {


    public static transformString(value: string, search: string): string {

        if (value) {
            let value2 = (' ' + value).trim();
            if (search) {
                if (search.length > 0) {
                    if (value2.toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) >= 0) {
                        let pos: number = value2.toLocaleLowerCase().indexOf(search.toLocaleLowerCase());
                        while ((pos > -1) && (pos < value2.length)) {
                            const p = (pos > 0) ? value2.substr(0, pos) : '';
                            const v = value2.substr(pos, search.length);
                            const a = value2.substr(pos + search.length, value2.length - pos - search.length);
                            value2 = p + '<strong>' + v + '</strong>' + a;
                            pos = pos + 17 + search.length;
                            pos = value2.toLocaleLowerCase().indexOf(search.toLocaleLowerCase(), pos + 1);
                        }
                    }
                }
            }
            return value2;
        }
        return value;
    }

    public transform(value: string, search: string): string {
        return MEHighlitePipe.transformString(value, search);
    }
}

export type MESelectItemTextCallback<T> = (item: T) => string;

export type MESelectItemMatcherCallback<T> = (item: T, search: string) => boolean;

@Component({
    selector: 'meselect',
    templateUrl: './meselect.component.html',
    styleUrls: ['./meselect.component.css'],
    encapsulation: ViewEncapsulation.Emulated,
    changeDetection: ChangeDetectionStrategy.OnPush

})
export class MESelectComponent implements OnInit, AfterViewInit {
    @ViewChild('searchField', {static: true}) searchField: any;
    @Input() nullable = true;
    @Input() disabled = false;

    @Output() itemSelected = new EventEmitter<any>();
    @Output() needsSearch = new EventEmitter<MESelectSearchEvent>();

    @Input() asMatrix = false;
    @Input() small = false;
    @Input()
    public useTimeout = true;
    public filterTimeout: any = null;
    @Input()
    public idField: string = null;
    @Input()
    public placeholder = 'Eintrag auswählen...';
    public _itemsShown: any[] = [];
    public _showList = false;
    public _search = '';
    public selectedItem: any = null;
    public _showValue = '';
    public _inputVisible = false;
    public _items: any[] = [];
    @Input() nullLabel = '';

    public doFormat: MESelectItemFormatterCallback<any> = (item, search) => {
        let t = '';
        if (item !== null && item !== undefined) {
            if ((typeof item) === 'string') {
                t = item;
            } else if ((typeof item) === 'number') {
                t = item.toString();
            } else if ((typeof item) === 'boolean') {
                t = item.toString();
            } else if (item !== null) {
                if (item.hasOwnProperty('text')) {
                    t = item.text;
                } else if (item.hasOwnProperty('label')) {
                    t = item.label;
                } else {
                    t = JSON.stringify(item);
                }
            }
        }
        return MEHighlitePipe.transformString(t, search);
    }

    public getText: MESelectItemTextCallback<any> = (item) => {
        return this.doFormat(item, '', false);
    }

    public doMatch: MESelectItemMatcherCallback<any> = (item, search) => {
        if (!search) {
            return true;
        }
        if (search.trim() === '') {
            return true;
        }
        let i: any = this.getText(item);

        if (!i) {
            return false;
        }

        if (i === '') {
            return false;
        }

        i = <string><any>i;

        return ((' ' + i).trim().toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) >= 0);
    }

    constructor(public _eref: ElementRef, public cd: ChangeDetectorRef) {
    }

    @Input()
    public set onFormat(callback: MESelectItemFormatterCallback<any>) {
        this.doFormat = callback;
    }

    @Input()
    public set onGetText(callback: MESelectItemTextCallback<any>) {
        this.getText = callback;
    }

    @Input()
    public set onMatch(callback: MESelectItemMatcherCallback<any>) {
        this.doMatch = callback;
    }

    get value(): any {
        return this.selectedItem;
    }

    @Input()
    set value(v: any) {
        if (v === null) {
            this.selectedItem = null;
            this._showValue = '';
        } else {
            const h = this.findByID(v);

            if (h !== null) {
                this.selectedItem = h;
                this._showValue = this.getText(h);
            } else {
                if (v !== this.selectedItem) {
                    this.selectedItem = v;
                }
                this._showValue = this.getText(v);
            }
        }
        this.cd.markForCheck();
    }


    @Input()
    public set items(value: any) {
        if (!value || !Array.isArray(value)) {
            this._items = [];
            this._itemsShown = [];
            this._showValue = '';
            this._showList = false;
        } else {
            this._items = value;
            if (value.length < 1) {
                this._showValue = '';
            }
            this.filterItems();
            this.value = this.selectedItem;
        }
        this.cd.markForCheck();
    }

    ngAfterViewInit(): void {
        if (this.value === null || this.value === undefined) {
            this.search(this._search);
        }
        this.cd.markForCheck();
    }

    public displayValue(forList: boolean): string {
        if (
            (this.value === undefined) ||
            (this.value === null) ||
            (this.value === '')
        ) {
            if (this.nullLabel !== null && this.nullLabel !== undefined && this.nullLabel !== '') {
                return '<span class="placeholder">' + this.nullLabel + '</span>';
            }
            return '<span class="placeholder">' + this.placeholder + '</span>';
        }

        return this.doFormat(this.value, '', forList);
    }

    public format(item: any, search: string, forList: boolean): string {
        return this.doFormat(item, search, forList);
    }

    ngOnInit() {
    }

    @HostListener('document:click', ['$event'])
    onOutsideClick(event: any) {
        if (!this._eref.nativeElement.contains(event.target)) {
            this._showList = false;
            this.cd.markForCheck();
        }
    }

    public search(value: string) {
        this._search = value;
        if (this.searchField !== null && this.searchField !== undefined) {
            this.searchField.value = value;
            this.onkey({'target': this.searchField});
        }
    }

    public onkey($event: any) {
        if (!this.disabled) {
            if (this.useTimeout) {
                if (this.filterTimeout !== null) {
                    clearTimeout(this.filterTimeout);
                }
                this.filterTimeout = setTimeout(() => {
                    this.needsSearch.emit(new MESelectSearchEvent(this._search, this));
                    this.filterTimeout = null;
                }, 400);
            } else {
                this.needsSearch.emit(new MESelectSearchEvent($event.target.value, this));
            }
            this._search = $event.target.value;
            this.selectedItem = null;
            this._showList = true;
            this.filterItems();
        }
        this.cd.markForCheck();
    }

    public select(item: any) {
        if (!this.disabled) {
            this.value = item;
            this.itemSelected.emit(item);
            this.cd.markForCheck();
        }
        this._showList = false;
    }

    toggleDropDown() {
        if (!this.disabled) {
            if (this._showList) {
                this._showList = false;
            } else {
                this._showList = true;
                this._search = '';
                this.filterItems();
            }
        } else {
            this._showList = false;
        }
        this.cd.markForCheck();
    }

    showInput() {
        if (!this.disabled) {
            this._inputVisible = true;
            setTimeout(() => {
                this.searchField.nativeElement.focus();
            }, 25);
        } else {
            this._inputVisible = false;
        }
        this.cd.markForCheck();

    }

    hideInput() {
        this._inputVisible = false;
        this.cd.markForCheck();
    }

    public findByID(i: any): any {
        if (i === null || i === undefined) {
            return null;
        }
        let r: any = null;
        if (this._items !== undefined) {
            if ((this.idField !== undefined) && (this.idField !== null)) {
                if (this.idField !== '') {
                    if ((i[this.idField] !== undefined) && (i[this.idField] !== null)) {
                        this._items.forEach(e => {
                            if ((e[this.idField] !== undefined) && (e[this.idField] !== null)) {
                                if (e[this.idField] === i[this.idField]) {
                                    r = e;
                                }
                            }
                        });
                    }
                } else {
                    this._items.forEach(e => {
                        if (e === i) {
                            r = e;
                        }
                    });
                }
            }
        }
        return r;
    }

    clear() {
        this.select(null);
    }

    protected filterItems() {

        this._itemsShown = [];
        if ((this._items !== undefined) &&
            (this._items !== null) &&
            (this._items.length) &&
            (this._items.length > 0) &&
            (this._items.forEach !== undefined)) {
            this._items.forEach((i) => {
                if (this.doMatch(i, this._search)) {

                    this._itemsShown.push(i);
                }
            });
        }
        if (this._inputVisible) {
            this._showList = this._itemsShown.length > 0;
        }
        this.cd.markForCheck();
    }
}
