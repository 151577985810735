import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

import {AddOnClickEvent} from './add-on-click-event';
import CryptoES from "crypto-es";

@Component({
    selector: 'num-field',
    styles: [`

        :host .addon button:first-child {
            border-left: none;
            padding-left: 5px;
            padding-right: 5px;
        }
    `],
    template: `
        <div class="form-group row">
        <label for="{{ id }}"
               class="col-{{ labelwidth }} col-form-label"
               *ngIf="labelwidth>0">
            {{ label }}
        </label>
        <div class="col-{{ 12-labelwidth }}">
            <div class=""
                 [class.input-group]="hasAddon()"
                 [class.input-group-sm]="hasAddon() && small"
            >
                <input
                        class="form-control"
                        [class.form-control-sm]="small"
                        type="number"
                        id="{{ id }}"
                        [value]="value"
                        (input)="update($event)"
                        placeholder="{{ label }}"
                        [attr.disabled]="disabled?true:null"
                        [class.disabled]="disabled"
                        [tabindex]="tabindex"
                        [min]="min"
                        [max]="max"
                >
                <span class="input-group-btn addon"
                      *ngFor="let ai of addonIcons;let idx=index">
            <button
                    class="btn btn-secondary addon-btn-{{ idx }}"
                    [class.btn-sm]="small"
                    type="button"
                    title="{{ _addOnLabels[idx] }}"
                    (click)="aClick(idx)">
                    <i class="{{ ai }}"></i>
            </button>
        </span>
            </div>
        </div>
    </div>
    `
})
export class NumFieldComponent implements OnInit {


    public _value = 0;
    public _icons: string[] = [];

    @Input('label') label = 'Textfeld';
    @Input('small') small = false;
    @Input('tabindex') tabindex: number = null;
    @Input() min = 0;
    @Input() max: number = null;

    @Input('addonicon')
    set addonicon(v: any) {
        this._icons = [];
        if (Array.isArray(v)) {
            v.forEach(i => {
                this._icons.push(i);
            })
        } else {
            this._icons.push(v);
        }
        while (this._addOnLabels.length < this._icons.length) {
            this._addOnLabels.push('');
        }
    }

    get addonicon(): any {
        if (this._icons.length < 1) {
            return '';
        } else if (this._icons.length < 2) {
            return this._icons[0];
        }
        return this._icons;
    }


    _addOnLabels: string[] = [];

    @Input()
    set addonlabel(v: any) {
        this._addOnLabels = [];
        if (Array.isArray(v)) {
            v.forEach(i => {
                this._addOnLabels.push(i);
            })
        } else {
            this._addOnLabels.push(v);
        }
        while (this._addOnLabels.length < this._icons.length) {
            this._addOnLabels.push('');
        }
    }


    @Input('enableaddon') enableAddon = false;
    @Input('id') id = '';
    @Input('labelwidth') labelwidth = 3;
    @Input('disabled') disabled = false;

    @Input('value')
    set value(v: number) {
        this.setValue(v);
    }

    public setValue(v: number) {
        if (this._value != v) {
            this._value = v;
            if (!this.disabled) {
                this.valueChange.emit(v);
            }
        }
    }

    @Output() valueChange = new EventEmitter<number>();
    @Output() addonClick = new EventEmitter<AddOnClickEvent>();


    get value(): number {
        return this._value;
    }

    constructor() {
    }

    ngOnInit() {
        if (this.id === '') {
            this.id = 'tf' + CryptoES.MD5('textfield-' + this.label + Math.random() + '-' + Math.random()).toString();
        }
    }

    update(event) {
        if (!this.disabled) {
            this.setValue(event.target.value);
        }
    }

    hasAddon(): boolean {
        return !this.disabled && this.enableAddon && (this._icons.length > 0);
    }

    get addonIcons(): any[] {
        if (this.hasAddon()) {
            return this._icons;
        }
        return [];
    }

    aClick(i?: any) {
        let e: AddOnClickEvent = new AddOnClickEvent();
        e.component = this;
        e.addon = i;
        this.addonClick.emit(e);
    }

}
