import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {LocalizedComponent} from '../../../commons/components/localized-component';

import {NgbActiveModal, NgbModal, NgbModalOptions, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {ISonde} from '../isonde';
import {Sonde} from '../sonde';
import {StationenService} from '../../common/stationen.service';
import {Station} from '../../common/station';
import {IStation} from '../../common/istation';
import {Subscription} from 'rxjs';
import {LoginService} from '../../../auth/services/login.service';
import {PubSubService} from '../../../commons/pub-sub.service';
import {ToastrService} from 'ngx-toastr';

@Component({
    selector: 'app-sonde-editor',
    templateUrl: './sonde-editor.component.html',
    styleUrls: ['./sonde-editor.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SondeEditorComponent extends LocalizedComponent implements OnInit, OnDestroy {

    public entity: Sonde = new Sonde();
    public station: Station = null;

    isOnline = false;
    sub: Subscription = null;
    loginSub: Subscription = null;
    mode = 0;


    get dlgTitle(): string {
        if (this.station === null || this.station === undefined) {
            return '';
        }
        if (this.isAdd()) {
            return this._('Sonde an Station {{ stationname }} ({{ stationnummer }}) anlegen', {stationname: this.station.bezeichnung, stationnummer: this.station.nummer});
        }
        if (this.isEdit()) {
            return this._('Sonde {{ name }} ({{ nummer }}) an Station {{ stationname }} ({{ stationnummer }}) bearbeiten', {
                name: this.entity.bezeichnung,
                nummer: this.entity.nummer,
                stationname: this.station.bezeichnung,
                stationnummer: this.station.nummer
            });
        }
    }

    static open(modalService: NgbModal, station: IStation, sonde: ISonde): Promise<any> {
        const o: NgbModalOptions = {
            backdrop: 'static'
        };
        const r: NgbModalRef = modalService.open(SondeEditorComponent, o);
        (r.componentInstance as SondeEditorComponent).open(station, sonde);
        return r.result;
    }

    constructor(

                public cd: ChangeDetectorRef,
                public activeModal: NgbActiveModal,
                public stationService: StationenService,
                public loginService: LoginService,
                public pubsub: PubSubService,
                public toastr: ToastrService
    ) {
        super( cd);
    }

    public open(station: IStation, sonde: ISonde) {
        if (station === null || station === undefined) {
            this.cancel();
        }
        this.station = Station.fromResult(station);
        if (this.station.id === null || this.station === undefined) {
            this.cancel();
        }

        if (sonde === null || sonde === undefined) {
            this.entity = new Sonde();
            this.mode = 0;
        } else {
            this.stationService
                .loadSonde(this.station, sonde)
                .subscribe(
                    (s: ISonde) => {
                        this.entity = Sonde.fromResult(s);
                        console.log(this.entity);
                        this.cd.markForCheck();
                    },
                    () => {
                        this.cancel();
                    }
                );
            this.mode = 1;
        }
    }

    ngOnDestroy() {
        this.sub.unsubscribe();
    }

    cancel() {
        this.activeModal.close();
    }

    ngOnInit() {
        this.sub = this.pubsub.onlineStateChange.subscribe(
            (o) => {
                this.isOnline = o;
                this.cd.markForCheck();
            }
        );
        this.loginSub = this.pubsub.loginStateChange.subscribe(
            () => {
                if (!this.loginService.isLoggedIn()) {
                    this.cancel();
                }

                if (!this.loginService.hasOneOfRoles(['ROLE_ADMIN', 'ROLE_PROJECT_ADMIN', 'ROLE_BILLING', 'ROLE_BESTAND'])) {
                    this.cancel();
                }
            },
            () => {
                this.cancel();
            }
        );
    }


    public isAdd() {
        return this.mode === 0;
    }

    public isEdit() {
        return this.mode === 1;
    }

    save() {
        let ok = true;
        const r: Sonde = this.entity;
        if ((r.nummer === undefined) || (r.nummer === null) || (r.nummer < 1)) {
            this.toastr.error(this._('Bitte geben Sie eine gültige Sondennummer an'));
            ok = false;
        }
        if ((r.bezeichnung === undefined) || (r.bezeichnung === null) || (r.bezeichnung === '')) {
            this.toastr.error(this._('Bitte geben Sie eine gültige Bezeichnung an'));
            ok = false;
        }
        if ((r.volumen === undefined) || (r.volumen === null) || (r.volumen < 1)) {
            this.toastr.error(this._('Das Tankvolumen ist ungültig.'));
            ok = false;
        }


        if (ok) {
            this.stationService
                .storeSonde(this.station, this.entity)
                .subscribe(
                    success => {
                        if (success.success) {
                            this.toastr.success(this._('Der Sonden-Datensatz wurde erfolgreich gespeichert'));
                            this.activeModal.close();
                        } else {
                            this.toastr.error(this._('Der Sonden-Datensatz konnte nicht gespeichert werden.'));
                        }
                    },
                    () => {
                        this.toastr.error(this._('Der Sonden-Datensatz konnte nicht gespeichert werden.'));
                    }
                );
        }
    }

    addonClick($event) {
        console.log($event);
    }

}
