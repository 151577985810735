import {KundeAudit} from './kunde-audit';
import {SimpleKundeAuditFilter} from './simple-kunde-audit-filter';
import {IKundeAudit} from './ikunde-audit';
import {CommonList} from '../../../commons/common-list';
import {IListResult} from '../../../commons/list-result';
import {ResultMetaSortImpl} from '../../../commons/result-meta';

export class KundeAuditList extends CommonList<KundeAudit>{
    public simpleFilter = new SimpleKundeAuditFilter();

       static fromListResult(res: IListResult<IKundeAudit>): KundeAuditList {
        let newList = new KundeAuditList();
        newList.clear();
        newList.simpleFilter.clear();

        if (res.meta) {
            newList.total = res.meta.total || 0;
            newList.offset = res.meta.offset || 0;
            newList.size = res.meta.size || 0;
            newList.page = res.meta.page || 0;
            if (res.meta.order) {
                newList.order = [];
                for (let x of res.meta.order) {
                    newList.order.push(
                        new ResultMetaSortImpl(
                            x.field || 'dateCreated',
                            x.direction || 'desc'
                        )
                    );
                }
            }
        }
        if (res.data) {
            for (let row of res.data) {
                newList.add(KundeAudit.fromResult(row));
            }
        }
        if (res.simpleFilter) {
            for (let k of Object.keys(newList.simpleFilter)) {
                newList.simpleFilter[k] = (res.simpleFilter[k] !== undefined) ? res.simpleFilter[k] : null;
            }
        }

        return newList

    }

}
