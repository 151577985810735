<div *ngIf="device.isVacuumcleaner()">
    <text-field
            [label]="_('Modell')"
            [(value)]="device.settings.modell"
    ></text-field>
    <geraet-field
            [label]="_('Münzprüfer-Einheit')"
            [(value)]="device.settings.geraet"
            [station]="device.station"
            [deviceType]="dTCoinAcceptor"
    ></geraet-field>
</div>
