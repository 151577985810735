<ul class="layers {{ classes }}">
  <li class="ib-icon">
    <i class="{{ icon }}"></i>
  </li>
  <li class="ib-title">
    {{ title }}
  </li>
  <li class="ib-value">
    {{ _value }}
  </li>
  <li class="ib-subtext">
    {{ subtext }}
  </li>
  <li class="ib-controls">
    <ng-content></ng-content>
  </li>
</ul>
