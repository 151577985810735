import {Injectable} from '@angular/core';
import {SimpleAusweisFilter} from './simple-ausweis-filter';
import {Ausweis} from './ausweis';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {IAusweis} from './iausweis';
import {SimpleAusweisAuditFilter} from './simple-ausweis-audit-filter';
import {IAusweisAudit} from './iausweis-audit';
import {ResultMetaSortImpl} from '../../../commons/result-meta';
import {LoginService} from '../../../auth/services/login.service';
import {IListResult} from '../../../commons/list-result';
import {Kunde} from '../../kunden/common/kunde';
import {IActionResponse} from '../../../commons/action-response';

@Injectable({
                providedIn: 'root'
            })
export class AusweisService {


    constructor(public httpClient: HttpClient,
                public loginService: LoginService) {

    }

    list(max = 0, offset = 0, sort: ResultMetaSortImpl[] = [],
         filter: SimpleAusweisFilter = null,
         searchQuery = ''): Observable<IListResult<IAusweis>> {
        let url = '/api/ausweise';

        url = url.concat('?')
            .concat('max=' + encodeURIComponent('' + max))
            .concat('&')
            .concat('offset=' + encodeURIComponent('' + offset));

        if (filter != null) {
            if (filter.hasValues()) {
                url = url + '&' + filter.asURL();
            }
        }
        if ((searchQuery !== null) && (searchQuery !== undefined) && (searchQuery !== '')) {
            url = url + '&q=' + encodeURIComponent(searchQuery);
        }
        if (sort !== undefined && sort !== null && sort.length > 0) {
            const p = 0;
            sort.forEach(it => {
                url = url.concat('&sort.' + p + '=' + encodeURIComponent(it.field + '|' + it.direction));
            });
        }


        return this.httpClient
            .get<IListResult<IAusweis>>(
                url,
                {headers: this.loginService.getHttpHeaders()}
            );
    }

    listAudit(ausweis: IAusweis,
              max = 0,
              offset = 0,
              sort: ResultMetaSortImpl[] = [],
              filter: SimpleAusweisAuditFilter = null,
              searchQuery = ''): Observable<IListResult<IAusweisAudit>> {

        if (ausweis === null || ausweis === undefined || ausweis.id < 1) {
            return null;
        }

        let url = '/api/ausweise/' + encodeURIComponent('' + ausweis.id) + '/audit';

        url = url.concat('?')
            .concat('max=' + encodeURIComponent('' + max))
            .concat('&')
            .concat('offset=' + encodeURIComponent('' + offset));

        if (filter != null) {
            if (filter.hasValues()) {
                url = url + '&' + filter.asURL();
            }
        }
        if ((searchQuery !== null) && (searchQuery !== undefined) && (searchQuery !== '')) {
            url = url + '&q=' + encodeURIComponent(searchQuery);
        }
        if (sort !== undefined && sort !== null && sort.length > 0) {
            const p = 0;
            sort.forEach(it => {
                url = url.concat('&sort.' + p + '=' + encodeURIComponent(it.field + '|' + it.direction));
            });
        }


        return this.httpClient
            .get<IListResult<IAusweisAudit>>(
                url,
                {headers: this.loginService.getHttpHeaders()}
            );
    }

    findFreeNum(kunde: Kunde): Observable<IActionResponse> {
        return this.httpClient
            .post<IActionResponse>(
                '/api/ausweise/_freeNum',
                {},
                {headers: this.loginService.getHttpHeaders()}
            );
    }

    store(ausweis: Ausweis, insert = false): Observable<IActionResponse> {
        if (!insert && ausweis.id != null && ausweis.id > 0) {
            return this.httpClient
                .put<IActionResponse>(
                    '/api/ausweise/' + encodeURIComponent('' + ausweis.id),
                    JSON.stringify(ausweis),
                    {headers: this.loginService.getHttpHeaders()}
                );
        }
        return this.httpClient
            .post<IActionResponse>(
                '/api/ausweise',
                JSON.stringify(ausweis),
                {headers: this.loginService.getHttpHeaders()}
            );

    }

    storeOptions(ausweis: Ausweis): Observable<IActionResponse> {
        if (ausweis.id > 0) {
            return this.httpClient
                .put<IActionResponse>(
                    '/api/ausweise/' + encodeURIComponent('' + ausweis.id),
                    JSON.stringify(ausweis),
                    {headers: this.loginService.getHttpHeaders()}
                );
        }
        return null;
    }


    setPIN(ausweis: Ausweis, pin: string): Observable<IActionResponse> {
        if (ausweis.id !== null && ausweis.id > 0 && pin !== null && pin !== '') {

            return this.httpClient
                .put<IActionResponse>(
                    '/api/ausweise/' + encodeURIComponent('' + ausweis.id) + '/_setPin',
                    JSON.stringify({newPin: pin}),
                    {headers: this.loginService.getHttpHeaders()}
                );
        }
        return null;
    }


    requestLimits(ausweis: Ausweis): Observable<IAusweis> {
        if (ausweis.id != null && ausweis.id > 0) {
            return this.httpClient
                .post<IAusweis>(
                    '/api/ausweise/' + encodeURIComponent('' + ausweis.id) + '/_requestLimits',
                    {},
                    {headers: this.loginService.getHttpHeaders()}
                );
        }
        return null;
    }


    resetLimits(ausweis: Ausweis, resetTotal = false): Observable<IAusweis> {
        if (ausweis.id != null && ausweis.id > 0) {
            return this.httpClient
                .post<IAusweis>(
                    '/api/ausweise/' + encodeURIComponent('' + ausweis.id) +
                    '/_resetLimits?resetTotal=' + encodeURIComponent('' + resetTotal),
                    {},
                    {headers: this.loginService.getHttpHeaders()}
                );
        }
        return null;
    }


    unlockPinBlock(ausweis: IAusweis): Observable<IActionResponse> {
        if (ausweis.id != null && ausweis.id > 0) {
            return this.httpClient
                .post<IActionResponse>(
                    '/api/ausweise/' + encodeURIComponent('' + ausweis.id) + '/_unlockPinBlock',
                    {},
                    {headers: this.loginService.getHttpHeaders()}
                );
        }
        return null;
    }


    reindexAusweise(): Observable<IActionResponse> {
        return this.httpClient
            .post<IActionResponse>(
                '/api/ausweise/_reindex',
                {},
                {headers: this.loginService.getHttpHeaders()}
            );
    }


    countAusweise(): Observable<IActionResponse> {
        return this.httpClient
            .get<IActionResponse>(
                '/api/ausweise/_count',
                {headers: this.loginService.getHttpHeaders()}
            );

    }

    syncAusweise(): Observable<IActionResponse> {
        return this.httpClient
            .post<IActionResponse>(
                '/api/ausweise/_sync',
                {},
                {headers: this.loginService.getHttpHeaders()}
            );
    }

    syncAusweis(ausweis: IAusweis): Observable<IActionResponse> {
        if (ausweis.id != null && ausweis.id > 0) {
            return this.httpClient
                .post<IActionResponse>(
                    '/api/ausweise/' + encodeURIComponent('' + ausweis.id) + '/_sync',
                    {},
                    {
                        headers: this.loginService.getHttpHeaders()
                    }
                );
        }
        return null;
    }

    syncRatioAusweise(): Observable<IActionResponse> {
        return this.httpClient
            .post<IActionResponse>(
                '/api/ausweise/_ratioSync',
                {},
                {
                    headers: this.loginService.getHttpHeaders()
                }
            );
    }


    loadAusweis(id: number): Observable<IAusweis> {
        return this.httpClient
            .get<IAusweis>(
                '/api/ausweise/' + encodeURIComponent('' + id),
                {
                    headers: this.loginService.getHttpHeaders()
                }
            );
    }

    identify(pan: string): Observable<IActionResponse> {
        return this.httpClient
            .post<IActionResponse>(
                '/api/ausweise/_identify?pan=' + encodeURIComponent('' + pan),
                {},
                {headers: this.loginService.getHttpHeaders()}
            );
    }


    exportData(type = 'csv', filter: SimpleAusweisFilter = null, searchQuery = '', exporttype = 0): Observable<Blob> {
        let url = '/api/ausweise.' + encodeURIComponent('' + type);

        url = url.concat('?max=-1')
            .concat('&offset=0')
            .concat('&sort.0=' + encodeURIComponent('nummer|asc'));
        url = url.concat('&exporttype=' + encodeURIComponent('' + exporttype));


        if (filter !== null) {
            if (filter.hasValues()) {
                url = url + '&' + filter.asURL();
            }
        }
        if (searchQuery !== null && searchQuery !== undefined && searchQuery !== '') {
            url = url.concat('&q=' + encodeURIComponent(searchQuery));
        }

        return this.httpClient
            .get(
                url,
                {
                    headers: this.loginService.getHttpHeaders(false),
                    responseType: 'blob'
                }
            );
    }


    removeAusweis(entity: Ausweis): Observable<IActionResponse> {
         return this.httpClient
            .delete<IActionResponse>(
                '/api/ausweise/' + encodeURIComponent('' + entity.id),
                {
                    headers: this.loginService.getHttpHeaders()
                }
            );
    }
}
