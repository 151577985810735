import {IOption, Option} from '../../commons/dynamic-form/select-field/ioption';
import {EStationArt} from './estation-art';
import {METranslationCallback} from '../../commons/metranslation-callback';

export class StationArt {

    static options(translateFunc: METranslationCallback = null): IOption[] {
        const options: IOption[] = [
            Option.option(
                EStationArt.UNKNOWN,
                'unbekannte Stationsart',
                StationArt.getIcon(EStationArt.UNKNOWN)
            ),
            Option.option(
                EStationArt.OWNED,
                'eigene Station',
                StationArt.getIcon(EStationArt.OWNED)
            ),
            Option.option(
                EStationArt.PARTNER,
                'Partner-Station',
                StationArt.getIcon(EStationArt.PARTNER)
            ),
            Option.option(
                EStationArt.TOLLBOOTH,
                'Mautstelle',
                StationArt.getIcon(EStationArt.TOLLBOOTH)
            )
        ];

        if (translateFunc !== null && translateFunc !== undefined) {
            options.forEach((v: IOption, idx) => {
                options[idx].label = translateFunc(v.label);
            });
        }
        return options;

    }

    static byValue(stationsArt: any): EStationArt {

        if (stationsArt === null) {
            return null;
        }

        if (stationsArt === EStationArt.UNKNOWN) {
            return EStationArt.UNKNOWN;
        }
        if (stationsArt === EStationArt.OWNED) {
            return EStationArt.OWNED;
        }
        if (stationsArt === EStationArt.PARTNER) {
            return EStationArt.PARTNER;
        }
        if (stationsArt === EStationArt.TOLLBOOTH) {
            return EStationArt.TOLLBOOTH;
        }

        if ('UNKNOWN' === ('' + stationsArt).toUpperCase()) {
            return EStationArt.UNKNOWN;
        }
        if ('OWNED' === ('' + stationsArt).toUpperCase()) {
            return EStationArt.OWNED;
        }
        if ('PARTNER' === ('' + stationsArt).toUpperCase()) {
            return EStationArt.PARTNER;
        }
        if ('TOLLBOOTH' === ('' + stationsArt).toUpperCase()) {
            return EStationArt.TOLLBOOTH;
        }

        if (0 === parseInt('' + stationsArt, 10)) {
            return EStationArt.UNKNOWN;
        }
        if (1 === parseInt('' + stationsArt, 10)) {
            return EStationArt.OWNED;
        }
        if (2 === parseInt('' + stationsArt, 10)) {
            return EStationArt.PARTNER;
        }
        if (3 === parseInt('' + stationsArt, 10)) {
            return EStationArt.TOLLBOOTH;
        }



        return EStationArt.UNKNOWN;
    }

    private static getIcon(stationArt: EStationArt): string {
        return '';
    }
}
