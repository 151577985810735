import {Benutzer} from './benutzer';
import {IBenutzer} from './ibenutzer';
import {GenericFilter} from '../../commons/generic-filter';
import {IStation} from '../../stationen/common/istation';
import {IFahrzeug} from '../../customers/fahrzeuge/common/ifahrzeug';
import {IPerson} from '../../customers/personen/common/iperson';
import {IAusweis} from '../../customers/ausweise/common/iausweis';

export class SimpleBenutzerFilter extends GenericFilter<Benutzer> implements IBenutzer {
    messageCount: number = null;
    accountLocked: boolean = null;
    username: string = null;
    password: string = null;
    permissions: string[] = null;
    fullname: string = null;
    id: number = null;
    dateCreated: any = null;
    lastUpdated: any = null;
    lastLogin: any = null;
    lastPasswordChange: any = null;
    kunde: any = null;
    hidden: boolean = null;
    stationsWorkMode: number = null;
 ausweise: IAusweis[] = null;
    personen: IPerson[] = null;
    fahrzeuge: IFahrzeug[] = null;
    ownEntitiesOnly: number = null;
    email: string = null;
    emailsEnabled: boolean = null;
    stationen: IStation[] = null;
    settings: any = null;

    telegramUsername: string = null;

    public asURL(): string {
        let url = '';


        Object.keys(this).forEach(k => {
            if ((this[k] !== null) &&
                (this[k] !== undefined) &&
                (this[k] !== '') &&
                (!Array.isArray(this[k]) || (Array.isArray(this[k]) && this[k].length > 0)) &&
                (k !== '_operations')
            ) {
                url += (url !== '') ? '&' : '';
                url += 'filter.' + encodeURIComponent(k) + '=';
                if (k === 'kunde') {
                    url += encodeURIComponent('' + this.kunde.id);
                } else {
                    url += encodeURIComponent('' + this[k]);
                }
            }
        });

        Object.keys(this._operations).forEach(k => {
            if ((this._operations[k] !== null) && (this._operations[k] !== undefined) && (this._operations[k] !== '')) {
                url += (url !== '') ? '&' : '';
                url += 'operation.' + encodeURIComponent(k) + '=' + encodeURIComponent('' + this._operations[k]);
            }
        });

        return url;
    }


}
