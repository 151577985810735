import {ChangeDetectorRef, Component, OnInit} from '@angular/core';

import {NgbActiveModal, NgbModal, NgbModalOptions, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {Rechnung} from '../common/rechnung';
import {RechnungService} from '../common/rechnung.service';
import * as moment from 'moment';
import {LocalizedComponent} from '../../../commons/components/localized-component';
import {AddOnClickEvent} from '../../../commons/dynamic-form/add-on-click-event';
import {ActionResponse, IActionResponse} from '../../../commons/action-response';
import {ToastrService} from 'ngx-toastr';

@Component({
               selector   : 'app-rechnung-editor',
               templateUrl: './rechnung-editor.component.html',
               styleUrls  : [ './rechnung-editor.component.css' ]
           })
export class RechnungEditorComponent extends LocalizedComponent implements OnInit {

    entity: Rechnung = new Rechnung();
    mode     = 'add';

    static open(modalService: NgbModal, rechnung: Rechnung = null): Promise<any> {
        const o: NgbModalOptions = {
            size    : 'lg',
            backdrop: 'static',
            keyboard  : false
        };
        const r: NgbModalRef     = modalService.open(RechnungEditorComponent, o);
        (r.componentInstance as RechnungEditorComponent).open(rechnung);
        return r.result;
    }

    constructor(

                public activeModal: NgbActiveModal,
                public toastr: ToastrService,
                public rechnungService: RechnungService,
                public cd: ChangeDetectorRef) {
        super( cd);
    }

    ngOnInit() {
    }


    open(rechnung: Rechnung = null) {
        if (rechnung === null || rechnung === undefined) {
            this.mode   = 'add';
            this.entity = new Rechnung();
            this.entity.rechnungDatum = moment().toDate();
            this.entity.dueDatum = moment().add(30, 'days').toDate();
        } else {
            this.mode   = 'edit';
            this.entity = Rechnung.fromResult(rechnung);
        }
    }

    isAdd(): boolean {
        return this.mode.toLowerCase() === 'add';
    }

    isEdit(): boolean {
        return this.mode.toLowerCase() === 'edit';
    }

    cancel() {
        this.activeModal.dismiss('cancel');
    }

    save() {
        this.rechnungService
            .store(this.entity, this.isAdd())
            .subscribe(r => {
                           if (r.success) {
                               this.activeModal.close(this.entity);
                           } else {
                               this.toastr.error(this._('Fehler beim Speichern der Rechnungsdaten'));
                           }
                       },
                       () => {
                           this.toastr.error(this._('Fehler beim Speichern der Rechnungsdaten'));
                       }
            );
    }

    addOnClick(event: AddOnClickEvent) {
        if ('nummer-field' === event.component.id) {
            if (event.addon === 0) {
                this.rechnungService
                    .findFreeNum()
                    .subscribe(
                        (resp: IActionResponse) => {
                            const r: ActionResponse = ActionResponse.fromRawActionResponse(resp);
                            if (r.success) {
                                event.component.setValue(r.params.nummer as number);
                            } else {
                                this.toastr.error(this.t('Es konnte keine freie Nummer ermittelt werden.'));
                            }
                        },
                        () => {
                            this.toastr.error(this.t('Es konnte keine freie Nummer ermittelt werden.'));
                        }
                    );
            }
        }
    }

}
