import {ChangeDetectorRef, Component, NgZone, OnInit} from '@angular/core';
import {ArtikelGruppeList} from './common/artikel-gruppe-list';


import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {LocalizedComponent} from '../../commons/components/localized-component';
import {KitchenTimer} from '../../commons/kitchen-timer';
import {ProdukteService} from '../produkte.service';
import {NewPageCountEvent} from '../../commons/paginator/paginator.component';
import {DataTableFilterChanged, DataTableSortChanged} from '../../commons/data-table/data-table.component';
import {ResultMetaSortImpl} from '../../commons/result-meta';
import {ToastrService} from 'ngx-toastr';
import {IListResult} from '../../commons/list-result';
import {IArtikelGruppe} from './common/iartikel-gruppe';

@Component({
  selector: 'app-tab-artikel-gruppen',
  templateUrl: './tab-artikel-gruppen.component.html',
  styleUrls: ['./tab-artikel-gruppen.component.css']
})
export class TabArtikelGruppenComponent extends LocalizedComponent implements OnInit {


    public artikelgruppen: ArtikelGruppeList = new ArtikelGruppeList();
    public artikelgruppenTimer: KitchenTimer;

    constructor(

                public produkteService: ProdukteService,
                public toastr: ToastrService,
                public zone: NgZone,
                public cd: ChangeDetectorRef,
                public modalService: NgbModal) {
        super( cd);
        this.artikelgruppen.size = 50;
        this.artikelgruppenTimer = new KitchenTimer();
        this.artikelgruppenTimer.subscribe(
            () => {
                this.produkteService
                    .listArtikelGruppen(
                        this.artikelgruppen.size,
                        this.artikelgruppen.calcOffset(),
                        this.artikelgruppen.order,
                        this.artikelgruppen.simpleFilter
                    )
                    .subscribe(
                        (l: IListResult<IArtikelGruppe>) => {
                            this.artikelgruppen.populateFromListResult(l);
                        },
                        () => {
                            this.toastr.error(this._('Die Liste der verfügbaren Artikelgruppen konnte nicht geladen werden'));
                            this.artikelgruppen.clear();
                        }
                    );
            }
        );

    }

    ngOnInit() {
        this.artikelgruppenTimer.fireWhenNotBefore();
    }

    updateArtikelgruppen(force = false, page?: number) {
        if ((page !== undefined) && (page != null)) {
            if (page !== this.artikelgruppen.page) {
                this.artikelgruppen.page = page;
                force                    = true;
            }
        }
        this.artikelgruppenTimer.start(force);
    }

    updateArtikelgruppenPageCount(n: NewPageCountEvent) {
        this.artikelgruppen.size = n.itemsPerPage;
        this.updateArtikelgruppen(true, n.currentPage);
    }



    sortChanged(e: DataTableSortChanged) {
        this.artikelgruppen.order = [
            new ResultMetaSortImpl(e.field, e.direction)
        ];
        this.updateArtikelgruppen();
    }

    filterChanged(e: DataTableFilterChanged) {
        this.zone.run(() => {
            if (e.field === 'id') {
                if (isNaN(e.value)) {
                    this.artikelgruppen.simpleFilter[ e.field ] = null;
                } else {
                    this.artikelgruppen.simpleFilter[ e.field ] = e.value;
                }
            } else {
                this.artikelgruppen.simpleFilter[ e.field ] = e.value;
            }
            this.updateArtikelgruppen();
        });
    }




}
