<div class="me-multi-select-container">
    <ul class="list-group">
        <li *ngFor="let item of items"
            class="list-group-item d-flex justify-content-start flex-nowrap"
            [class.selectable]="!disabled"
            [class.list-group-item-info]="item.isSelected()"
            (click)="doToggle(item)"
        >
            <i class="far"
               [class.fa-check-square]="item.isSelected()"
               [class.fa-square]="!item.isSelected()"
               [class.disabled]="disabled"
            ></i>
            <div [innerHtml]="item.getContent()"></div>
        </li>
    </ul>
</div>