import {CommonList} from './common-list';
import {KitchenTimer} from './kitchen-timer';
import {EventEmitter} from '@angular/core';
import {NewPageCountEvent} from './paginator/paginator.component';

export abstract class RemoteList<T> extends CommonList<T> {
    protected timer: KitchenTimer;
    public onUpdateRequired: EventEmitter<RemoteList<T>> = new EventEmitter();

    protected constructor(autorun = false, timeout = 400) {
        super();
        this.timer = new KitchenTimer(timeout);
        this.timer.subscribe(
            () => {
                this.doListUpdate();
            },
            () => {

            }
        );
        if (autorun) {
            this.timer.fireWhenNotBefore();
        }
    }

    public wasStartedBefore(): boolean {
        return (this.timer === null || this.timer === undefined) ? false : this.timer.wasFired();
    }

    start() {
        this.timer.start();
    }

    reload() {
        this.timer.start(true);
    }

    doListUpdate() {
        this.onUpdateRequired.emit(this);
    }

    setPage(page: number) {
        this.page = page;
        this.start();
    }

    setWindowSize(size: number) {
        this.size = size;
        this.start();
    }

    processPageCountChangedEvent(e: NewPageCountEvent) {
        this.setWindowSize(e.itemsPerPage);
        this.setPage(e.currentPage);
    }

}
