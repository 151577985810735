import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Output,
    ViewChild
} from '@angular/core';
import {LocalizedComponent} from '../../../commons/components/localized-component';

import {IFilterDialog} from '../../../commons/data-table/ifilter-dialog';
import {TransaktionenFilter} from './transaktionen-filter';
import {ElementListComponent} from '../../../commons/element-list/element-list.component';
import {StationElementRendererComponent} from '../../../stationen/station-element-renderer/station-element-renderer.component';
import {CustomerelementRendererComponent} from '../../../customers/customerelement-renderer.component';
import {FahrzeugElementRendererComponent} from '../../../customers/fahrzeug-element-renderer.component';
import {AusweisElementRendererComponent} from '../../../customers/ausweis-element-renderer.component';
import {ProduktElementRendererComponent} from '../../../products/produkt-element-renderer.component';
import {SprachenService} from '../../../commons/sprachen/common/sprachen.service';
import {CountryElementRendererComponent} from '../../../commons/laender/country-element-renderer/country-element-renderer.component';
import {Land} from '../../../commons/laender/common/land';

@Component({
    selector: 'app-transaktionen-filterdialog',
    templateUrl: './transaktionen-filterdialog.component.html',
    styleUrls: ['./transaktionen-filterdialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TransaktionenFilterdialogComponent extends LocalizedComponent implements IFilterDialog, AfterViewInit {

    stationRenderer = StationElementRendererComponent;
    customerRenderer = CustomerelementRendererComponent;
    fahrzeugRenderer = FahrzeugElementRendererComponent;
    ausweisRenderer = AusweisElementRendererComponent;
    produktRenderer = ProduktElementRendererComponent;
    countryRenderer = CountryElementRendererComponent;

    filter: TransaktionenFilter = new TransaktionenFilter(this.cd);

    @Output() filterChanged: EventEmitter<string> = new EventEmitter<string>();

    @ViewChild('stationen', {static: true}) stationen: ElementListComponent;
    @ViewChild('customers', {static: true}) customers: ElementListComponent;
    @ViewChild('fahrzeuge', {static: true}) fahrzeuge: ElementListComponent;
    @ViewChild('ausweise', {static: true}) ausweise: ElementListComponent;
    @ViewChild('produkte', {static: true}) produkte: ElementListComponent;
    @ViewChild('laender', {static: true}) laender: ElementListComponent;

    constructor(
        
        
        public cd: ChangeDetectorRef,
        public sprachenService: SprachenService
    ) {
        super( cd);
        this.filter.cd = this.cd;
        this.filter.filterChanged.subscribe(
            () => {
                if (this.stationen !== null && this.stationen !== undefined) {
                    this.stationen.elements = this.filter.stationen;
                }
                if (this.customers !== null && this.customers !== undefined) {
                    this.customers.elements = this.filter.customers;
                }
                if (this.fahrzeuge !== null && this.fahrzeuge !== undefined) {
                    this.fahrzeuge.elements = this.filter.vehicles;
                }
                if (this.ausweise !== null && this.ausweise !== undefined) {
                    this.ausweise.elements = this.filter.cards;
                }
                if (this.produkte !== null && this.produkte !== undefined) {
                    this.produkte.elements = this.filter.products;
                }
                if (this.laender !== null && this.laender !== undefined) {
                    this.laender.elements = this.filter.countries;
                }
                this.markChanged();
            }
        );
    }

    ngAfterViewInit() {
        this.filter.lastThreeDays();
        this.filter.useTimeRange = false;
        this.markChanged();
    }

    getQuery(): string {
        return this.filter.getQuery();
    }

    markChanged(visble = true): void {
        this.cd.markForCheck();
        if (visble) {
            this.filterChanged.emit(this.getQuery());
        } else {
            this.filterChanged.emit('');
        }
    }

    land_at() {
        this.filter.clearCountries(null);
        this.sprachenService.Oesterreich().then((l: Land) => {
            this.filter.addCountry(l, null);
        });
    }

    land_atde() {
        this.filter.clearCountries(null);
        this.sprachenService.Oesterreich().then((l: Land) => {
            this.filter.addCountry(l, null);
        });
        this.sprachenService.Deutschland().then((l: Land) => {
            this.filter.addCountry(l, null);
        });
    }

    land_de() {
        this.filter.clearCountries(null);
        this.sprachenService.Deutschland().then((l: Land) => {
            this.filter.addCountry(l, null);
        });
    }
}
