<div class="d-flex justify-content-between">
    <div>
        <h1>
            <i class="{{ iconClass }}"></i>
            {{ title }}
        </h1>
        <h2 *ngIf="hasSubtitle()">
            {{ subtitle }}
        </h2>
    </div>
    <div>
        <ng-content></ng-content>
    </div>
</div>