import {AfterViewInit, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {LocalizedComponent} from '../../../commons/components/localized-component';


import {NgbActiveModal, NgbModal, NgbModalOptions, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {Waehrung} from '../../../commons/waehrungen/waehrung';
import {Transaktion} from '../common/transaktion';
import {FahrzeugService} from '../../../customers/fahrzeuge/common/fahrzeug.service';
import {PersonService} from '../../../customers/personen/common/person.service';
import {ToastrService} from 'ngx-toastr';
import {Person} from '../../../customers/personen/common/person';
import {Fahrzeug} from '../../../customers/fahrzeuge/common/fahrzeug';

@Component({
               selector   : 'app-transaktion-print-dialog',
               templateUrl: './transaktion-print-dialog.component.html',
               styleUrls  : ['./transaktion-print-dialog.component.scss']
           })
export class TransaktionPrintDialogComponent extends LocalizedComponent implements OnInit, AfterViewInit {

    entity: Transaktion = new Transaktion();

    static open(modalService: NgbModal, transaktion: Transaktion): Promise<any> {
        const o: NgbModalOptions = {
            size    : 'sm',
            backdrop: 'static'
        };
        const r: NgbModalRef = modalService.open(TransaktionPrintDialogComponent, o);
        (r.componentInstance as TransaktionPrintDialogComponent).open(transaktion);
        return r.result;
    }


    constructor(

                public activeModal: NgbActiveModal,
                public toastr: ToastrService,
                public fahrzeugService: FahrzeugService,
                public cd: ChangeDetectorRef,
                public personService: PersonService) {
        super( cd);
    }


    open(transaktion?: Transaktion) {
        if ((transaktion === null) || (transaktion === undefined)) {
            this.entity = new Transaktion();
        } else {
            this.entity = Transaktion.fromResult(transaktion);
            if (this.entity.fahrzeug !== null && this.entity.fahrzeug !== undefined) {
                this.fahrzeugService
                    .load(this.entity.fahrzeug.id)
                    .subscribe(
                        f => {
                            this.entity.fahrzeug = Fahrzeug.fromResult(f);
                        },
                        () => {

                        }
                    );
            }
            if (this.entity.person !== null && this.entity.person !== undefined) {
                this.personService
                    .load(this.entity.person.id)
                    .subscribe(
                        f => {
                            this.entity.person = Person.fromResult(f);
                        },
                        () => {

                        }
                    );
            }
        }
        if (this.entity.waehrung === undefined || this.entity.waehrung === null) {
            this.entity.waehrung = new Waehrung();
            this.entity.waehrung.code = 'EUR';
        }
    }

    ngOnInit() {
        this.updatePreview();
        setTimeout(() => {
            this.updatePreview();
        }, 500);
    }

    ngAfterViewInit() {
        this.updatePreview();
    }

    cancel() {
        this.activeModal.dismiss();
    }

    updatePreview() {
        const doc = (jQuery('#printpreview')[0] as HTMLFrameElement).contentWindow.document;


        const $body = jQuery('html', doc);
        $body.html(
            '<html><head><title>Lieferschein</title><body style="background-color: white!important;">blo blo</body></head>');

        jQuery('head', $body).html(jQuery('head', window.document).html());
        jQuery('body', $body).html(jQuery('#lieferschein').html());

    }


    printLieferschein() {
        this.updatePreview();
        window.frames['printpreview'].focus();
        window.frames['printpreview'].print();
    }
}
