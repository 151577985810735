import {ChangeDetectorRef, Component, Injector, OnInit} from '@angular/core';

import {ENachrichtPrioritaet} from '../common/enachricht-prioritaet';
import {LocalizedCellRenderer} from '../../../commons/data-table/renderer/common-cell-renderer';
import {DataTableRow} from '../../../commons/data-table/data-table-row';
import {DataTableColumnDirective} from '../../../commons/data-table/data-table-column.directive';

@Component({
    selector: 'app-dtnachricht-prioritaet-renderer',
    template: '<span class="badge {{classes}}">{{value}}</span>'
})
export class DTNachrichtPrioritaetRendererComponent extends LocalizedCellRenderer implements OnInit {

    cell: ENachrichtPrioritaet = null;
    row: DataTableRow<any> = null;
    column: DataTableColumnDirective = null;
    value = '';
    classes = '';


    labels = {};

    constructor(  public injector: Injector, public cd: ChangeDetectorRef) {
        super( cd);

        this.labels[ENachrichtPrioritaet.UNKNOWN] = {label: 'unbekannt', class: 'badge-dark'};
        this.labels[ENachrichtPrioritaet.LOWEST] = {label: 'sehr niedrig', class: 'badge-light'};
        this.labels[ENachrichtPrioritaet.LOW] = {label: 'niedrig', class: 'badge-info'};
        this.labels[ENachrichtPrioritaet.NORMAL] = {label: 'normal', class: 'badge-success'};
        this.labels[ENachrichtPrioritaet.HIGH] = {label: 'hoch', class: 'badge-warning'};
        this.labels[ENachrichtPrioritaet.HIGHEST] = {label: 'sehr hoch', class: 'badge-danger'};


        this.cell = this.injector.get('cell');
        this.row = this.injector.get('row');
        this.column = this.injector.get('column');

        this.value = '';
        this.classes = '';


        if (this.cell !== null && this.cell !== undefined) {

            const l = this.labels[this.cell];
            if (l !== undefined && l !== null) {

                this.value = this.t(l.label);
                this.classes = l.class;

            }

        }
    }
}
