import {Component, EventEmitter, Input, NgZone, OnInit, Output} from '@angular/core';
import CryptoES from "crypto-es";


@Component({
    selector: 'duration-field',
    template: `
                   <div class="form-group row">
                       <label class="col-{{ labelwidth }} col-form-label" *ngIf="labelwidth>0">
                           {{ label }}
                       </label>
                       <div class="col-{{ 12-labelwidth }}">
                           <meduration-field
                                   [value]="_value"
                                   (valueChange)="doSelect($event)"
                                   [hideDays]="hideDays"
                                   [hideHours]="hideHours"
                                   [hideMinutes]="hideMinutes"
                                   [hideSeconds]="hideSeconds"
                                   [disabled]="disabled"
                           >

                           </meduration-field>
                       </div>
                   </div>

               `,
    styles: [`

                              :host .addon button:first-child {
                                  border-left: none;
                                  padding: 0 5px;
                              }

                          `]
})
export class DurationFieldComponent implements OnInit {

    size = null;

    public _value: number = null;


    @Input() label = 'Dauerfeld';
    @Input() id = '';
    @Input() labelwidth = 3;

    @Input() disabled = false;

    @Input() hideSeconds = false;
    @Input() hideMinutes = false;
    @Input() hideHours = false;
    @Input() hideDays = false;


    @Input()
    set value(v: number) {

        if (this._value !== v) {
            this._value = v;
            this.valueChange.emit(v);
        }
    }

    @Output() valueChange = new EventEmitter<number>();


    get value(): number {
        return this._value;
    }

    constructor(public _ngZone: NgZone) {
    }

    ngOnInit() {
        if (this.id === '') {
            this.id = 'tf' + CryptoES.MD5('textfield-' + this.label + Math.random() + '-' + Math.random()).toString();
        }
    }


    doSelect(duration: number) {
        this._value = duration;
        this.valueChange.emit(duration);
    }


}
