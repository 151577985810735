import {Station} from '../../../common/station';
import {EventEmitter} from '@angular/core';
import {Produkt} from '../../../../products/produkte/common/produkt';

export class SondenFilter {
    public filterChanged: EventEmitter<void> = new EventEmitter<void>();

    private _stationen: Station[] = [];


    get stationen(): Station[] {
        return this._stationen;
    }

    set stationen(value: Station[]) {
        if (value !== this._stationen) {
            this._stationen = value;
            this.filterChanged.emit();
        }
    }

    private _products: Produkt[] = [];

    private _freitext = '';

    get products(): Produkt[] {
        return this._products;
    }

    set products(value: Produkt[]) {
        if (value !== this._products) {
            this._products = value;
            this.filterChanged.emit();
        }
    }

    get freitext(): string {
        return this._freitext;
    }

    set freitext(value: string) {
        if (value !== this._freitext) {
            this._freitext = value;
            this.filterChanged.emit();
        }
    }

    constructor() {
    }

    getQuery(): string {
        const parts: string[] = [];

        if (this._stationen !== null && this._stationen !== undefined && Array.isArray(this._stationen)) {
            const c: string[] = [];
            this._stationen.forEach((s: Station) => {
                c.push('((_station_id:' + s.nummer + ') OR (station:' + s.nummer + '))');
            });
            if (c.length > 0) {
                parts.push('( ' + c.join(' OR ') + ' )');
            }
        }

        if (this._products !== null && this._products !== undefined && Array.isArray(this._products)) {
            const c: string[] = [];

            this._products.forEach((s: Produkt) => {
                c.push('((_produkt_id:' + s.id + ') OR (produktnummer:' + s.nummer + '))');
            });

            if (c.length > 0) {
                parts.push('( ' + c.join(' OR ') + ' )');
            }
        }

        if (this._freitext !== null && this._freitext !== undefined && this._freitext.trim() !== '') {
            parts.push('(' + this._freitext + ')');
        }


        if (parts.length < 1) {
            return '';
        }
        return parts.join(' AND ');
    }

    addStation(station: Station) {
        if (station != null && station !== undefined) {
            if (!Array.isArray(this._stationen)) {
                this._stationen = [];
            }
            let found = false;
            this._stationen.forEach((s: Station) => {
                if (s.id === station.id) {
                    found = true;
                }
            });
            if (!found) {
                this._stationen.push(station);
                this.filterChanged.emit();
            }
        }
    }

    clearStationen() {
        this.stationen = [];
    }

    removeStation(station: Station) {
        let found = false;
        this._stationen.forEach((s, idx) => {
            if (s.id === station.id) {
                this._stationen.splice(idx, 1);
                found = true;
            }
        });
        if (found) {
            this.filterChanged.emit();
        }
    }

    clearProducts() {
        this.products = [];
    }

    addProduct(produkt: Produkt) {
        if (produkt != null && produkt !== undefined) {
            if (!Array.isArray(this._products)) {
                this._products = [];
            }
            let found = false;
            this._products.forEach((s: Produkt) => {
                if (s.id === produkt.id) {
                    found = true;
                }
            });
            if (!found) {
                this._products.push(produkt);
                this.filterChanged.emit();
            }
        }
    }

    removeProduct(produkt: Produkt) {
        let found = false;
        this._products.forEach((s, idx) => {
            if (s.id === produkt.id) {
                this._products.splice(idx, 1);
                found = true;
            }
        });
        if (found) {
            this.filterChanged.emit();
        }
    }

}
