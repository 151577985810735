import {ILieferschein} from './ilieferschein';
import {Attachment} from '../../attachments/common/attachment';
import {Tank} from '../../../stationen/tanks/common/tank';
import {Station} from '../../../stationen/common/station';
import {Benutzer} from '../../../auth/common/benutzer';
import {ConverterTool} from '../../../../shared/converter-tool';
import {Produkt} from '../../../products/produkte/common/produkt';

export class Lieferschein implements ILieferschein {
    attachments: Attachment[] = [];
    dateCreated: Date = null;
    datum: Date = null;
    lieferDatum: Date = null;
    id = 0;
    info = '';
    lastEditor: Benutzer = null;
    erfasser: Benutzer = null;
    lastUpdated: Date = null;
    nummer = '';
    station: Station = null;
    tank: Tank = null;
    produkt: Produkt = null;
    zugang = 0;
    wert = 0;
    isNetto = true;

    static fromResult(entity: ILieferschein, createIfEmpty = true): Lieferschein {
        if ((entity === null) || (entity === undefined)) {
            if (createIfEmpty) {
                return new Lieferschein();
            }
            return null;
        }

        const l = new Lieferschein();
        for (const k of Object.keys(l)) {
            if (k === 'dateCreated') {
                l.dateCreated = ConverterTool.toDate(entity.dateCreated);
            } else if (k === 'lastUpdated') {
                l.lastUpdated = ConverterTool.toDate(entity.lastUpdated);
            } else if (k === 'datum') {
                l.datum = ConverterTool.toDate(entity.datum);
            } else if (k === 'lieferDatum') {
                l.lieferDatum = ConverterTool.toDate(entity.lieferDatum);
            } else if (k === 'attachments') {
                l.attachments = [];
                if (Array.isArray(entity.attachments)) {
                    entity.attachments.forEach((a) => {
                        const at = Attachment.fromResult(a);
                        if (at !== null) {
                            l.attachments.push(at);
                        }
                    });
                }
            } else if (k === 'station') {
                l.station = Station.fromResult(entity.station);
            } else if (k === 'tank') {
                l.tank = Tank.fromResult(entity.tank);
            } else if (k === 'lastEditor') {
                l.lastEditor = Benutzer.fromResult(entity.lastEditor);
            } else if (k === 'erfasser') {
                l.erfasser = Benutzer.fromResult(entity.erfasser);
            } else if (k === 'produkt') {
                l.produkt = Produkt.fromResult(entity.produkt);
            } else {
                l[k] = (entity[k] !== undefined) ? entity[k] : '';
            }
        }

        return l;
    }

    constructor() {
    }


}
