import {CommonList} from '../../../commons/common-list';
import {ArtikelGruppe} from './artikel-gruppe';
import {SimpleArtikelGruppeFilter} from './simple-artikel-gruppe-filter';
import {IListResult} from '../../../commons/list-result';
import {IArtikelGruppe} from './iartikel-gruppe';
import {ResultMetaSortImpl} from '../../../commons/result-meta';

export class ArtikelGruppeList extends CommonList<ArtikelGruppe> {
    simpleFilter: SimpleArtikelGruppeFilter = new SimpleArtikelGruppeFilter();

    static fromListResult(res: IListResult<IArtikelGruppe>): ArtikelGruppeList {
        const newList: ArtikelGruppeList = new ArtikelGruppeList();
        newList.clear();
        newList.simpleFilter.clear();

        if (res.meta) {
            newList.total = res.meta.total || 0;
            newList.offset = res.meta.offset || 0;
            newList.size = res.meta.size || 0;
            newList.page = res.meta.page || 0;
            if (res.meta.order) {
                newList.order = [];
                for (const x of res.meta.order) {
                    newList.order.push(
                        new ResultMetaSortImpl(
                            x.field || 'bezeichnung',
                            x.direction || 'asc'
                        )
                    );
                }
            }
        }
        if (res.data) {
            for (const row of res.data) {
                newList.add(ArtikelGruppe.fromResult(row));
            }
        }
        if (res.simpleFilter) {
            const d: ArtikelGruppe = new ArtikelGruppe();
            for (const k of Object.keys(d)) {
                newList.simpleFilter[k] = (res.simpleFilter[k] !== undefined) ? res.simpleFilter[k] : null;
            }
        }

        return newList;


    }

}
