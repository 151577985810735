import {Benutzer} from './benutzer';
import {SimpleBenutzerFilter} from './simple-benutzer-filter';
import {IBenutzer} from './ibenutzer';
import {CommonList} from '../../commons/common-list';
import {IListResult} from '../../commons/list-result';
import {ResultMetaSortImpl} from '../../commons/result-meta';

export class BenutzerList extends CommonList<Benutzer> {
    public simpleFilter: SimpleBenutzerFilter = new SimpleBenutzerFilter();

    static fromListResult(res: IListResult<IBenutzer>): BenutzerList {
        const newList: BenutzerList = new BenutzerList();
        newList.clear();
        newList.simpleFilter.clear();

        if (res.meta) {
            newList.total  = res.meta.total || 0;
            newList.offset = res.meta.offset || 0;
            newList.size   = res.meta.size || 0;
            newList.page   = res.meta.page || 0;
            if (res.meta.order) {
                newList.order = [];
                for (const x of res.meta.order) {
                    newList.order.push(
                        new ResultMetaSortImpl(
                            x.field || 'uname',
                            x.direction || 'asc'
                        )
                    );
                }
            }
        }
        if (res.data) {
            for (const row of res.data) {
                newList.add(Benutzer.fromResult(row));
            }
        }
        if (res.simpleFilter) {
            const d: Benutzer = new Benutzer();
            for (const k of Object.keys(d)) {
                newList.simpleFilter[ k ] = (res.simpleFilter[ k ] !== undefined) ? res.simpleFilter[ k ] : null;
            }
        }

        return newList;

    }

}
