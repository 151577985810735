import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AddOnClickEvent} from './add-on-click-event';
import CryptoES from "crypto-es";


@Component({
    selector: 'boolean-field',
    template: `
        <div class="form-group row" *ngIf="!flip && !inline">
            <label for="{{ id }}" class="col-{{ labelwidth }} col-form-label" *ngIf="labelwidth>0">
                {{ label }}
            </label>
            <div class="col-{{ 12-labelwidth }} cbox">
                <check-box [(value)]="value" [title]="label" [disabled]="disabled"></check-box>

            </div>
        </div>
        <div class="form-group row" *ngIf="flip && !inline">
            <div class="col-{{ 12-labelwidth }} cbox">
                <check-box [(value)]="value" [title]="label" [disabled]="disabled"></check-box>
            </div>
            <label for="{{ id }}" class="col-{{ labelwidth }} col-form-label" *ngIf="labelwidth>0">
                {{ label }}
            </label>
        </div>
        <div class="form-group" *ngIf="!flip && inline">
            <label for="{{ id }}" class="float-left col-form-label" *ngIf="labelwidth>0">
                {{ label }} &nbsp;
            </label>
            <div class="cbox">
                <check-box [(value)]="value" [title]="label" [disabled]="disabled"></check-box>
            </div>
        </div>
        <div class="form-group" *ngIf="flip && inline">
            <div class="float-left cbox">
                <check-box [(value)]="value" [title]="label" [disabled]="disabled"></check-box>
            </div>
            <label for="{{ id }}" class="col-form-label" *ngIf="labelwidth>0">
                &nbsp; {{ label }}
            </label>
        </div>
    `,
    styles: [
        `
            :host .cbox {
                padding-top: 6px;
            }

            :host .addon button:first-child {
                border-left: none;
                padding-left: 5px;
                padding-right: 5px;
            }
        `
    ]
})
export class BooleanFieldComponent implements OnInit {

    _value = false;
    _icons: string[] = [];

    @Input() label = 'BooleanField';
    @Input() flip = false;
    @Input() inline = false;

    @Input()
    set icon(v: any) {
        this._icons = [];
        if (Array.isArray(v)) {
            v.forEach(i => {
                this._icons.push(i);
            });
        } else {
            this._icons.push(v);
        }
        while (this._addOnLabels.length < this._icons.length) {
            this._addOnLabels.push('');
        }
    }

    get icon(): any {
        if (this._icons.length < 1) {
            return '';
        } else if (this._icons.length < 2) {
            return this._icons[0];
        }
        return this._icons;
    }


    _addOnLabels: string[] = [];

    @Input()
    set addonlabel(v: any) {
        this._addOnLabels = [];
        if (Array.isArray(v)) {
            v.forEach(i => {
                this._addOnLabels.push(i);
            });
        } else {
            this._addOnLabels.push(v);
        }
        while (this._addOnLabels.length < this._icons.length) {
            this._addOnLabels.push('');
        }
    }


    @Input() enableAddon = false;
    @Input() id = '';
    @Input() labelwidth = 3;
    @Input() disabled = false;
    @Input() small = false;

    @Input()
    set value(v: boolean) {
        if (this._value !== v) {
            this._value = v;
            if (!this.disabled) {
                this.valueChange.emit(v);
            }
        }
    }

    @Output() valueChange = new EventEmitter<boolean>();
    @Output() addonClick = new EventEmitter<AddOnClickEvent>();

    get value(): boolean {
        return this._value;
    }

    constructor() {
    }

    ngOnInit() {
        if (this.id === '') {
            this.id = 'tf' + CryptoES.MD5('textfield-' + this.label + Math.random() + '-' + Math.random()).toString();
        }
    }


    hasAddon(): boolean {
        return !this.disabled && this.enableAddon && (this._icons.length > 0);
    }

    get addonIcons(): any[] {
        if (this.hasAddon()) {
            return this._icons;
        }
        return [];
    }

    aClick(i?: any) {
        const e: AddOnClickEvent = new AddOnClickEvent();
        e.component = this;
        e.addon = i;
        this.addonClick.emit(e);
    }


}
