<div *ngIf="device.isMEVideoControl()">
  <text-field [label]="_('IP Adresse')" [(value)]="device.settings.ip"></text-field>
  <num-field [label]="_('Port')" [(value)]="device.settings.port"></num-field>
  <num-field *appIsAdmin [label]="_('SSH-Port')"
             [(value)]="device.settings.ssh_port"></num-field>
  <text-field *appIsAdmin [label]="_('SSH-User')"
              [(value)]="device.settings.ssh_user"></text-field>
  <password-field *appIsAdmin [label]="_('SSH-Passwort')"
                  [(value)]="device.settings.ssh_password"></password-field>
  <terminal-field [label]="_('Terminal')" [station]="station"
                  [(value)]="device.settings.terminal"></terminal-field>
  <text-field [label]="_('Text-Overlay')"
              [(value)]="device.settings.img_text_left"></text-field>
  <text-field [label]="_('AP-SSID')" [(value)]="device.settings.wifi_ssid"></text-field>
  <text-field [label]="_('AP-Schlüssel')" [(value)]="device.settings.wifi_key"></text-field>
</div>
