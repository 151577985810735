import {ChangeDetectorRef, Component, Injector, OnInit} from '@angular/core';
import {DataTableRow} from '../../../../commons/data-table/data-table-row';
import {DataTableColumnDirective} from '../../../../commons/data-table/data-table-column.directive';
import {Begrenzung} from '../../common/begrenzung';
import {LocalizedCellRenderer} from '../../../../commons/data-table/renderer/common-cell-renderer';

import {LimitType} from '../../common/limit-type';
import * as moment from 'moment';
import {ILand} from '../../../../commons/laender/common/iland';
import {IStation} from '../../../../stationen/common/istation';
import {ELimitType} from '../../common/elimit-type';


@Component({
               selector: 'app-begrenzungen-cell-renderer',
               template: '<div [innerHTML]="value"></div>'
           })
export class BegrenzungenCellRendererComponent extends LocalizedCellRenderer implements OnInit {


    cell: Begrenzung[] = null;
    row: DataTableRow<any> = null;
    column: DataTableColumnDirective = null;
    value = '';
    private _begrenzungen: Begrenzung[];
    private workmode = 0;
    private _currentBegrenzung: Begrenzung;


    private format(title: string, label: string, value: any, value2: any) {
        const t = this.t(title, {n: value, m: value2});
        const v = this.t(label, {n: value, m: value2});
        return '<span class="badge badge-light" title="' + t + '">' + v + '</span>';
    }

    constructor(  public injector: Injector, public cd: ChangeDetectorRef) {
        super( cd);
        this.cell = this.injector.get('cell');
        this.row = this.injector.get('row');
        this.column = this.injector.get('column');

        this.value = '';
        this.data = this.cell;
    }


    set data(value: Begrenzung[]) {
        this._begrenzungen = [];
        if (Array.isArray(value)) {
            const at = Begrenzung.fromResult(value[0]);
            if (at !== null) {
                at.id = this._begrenzungen.length + 1;
                this._begrenzungen.push(at);
            }
        } else {
            const at = Begrenzung.fromResult(value);
            if (at !== null) {
                at.id = this._begrenzungen.length + 1;
                this._begrenzungen.push(at);
            }
        }

        if (this._begrenzungen.length === 0) {
            this.workmode = 0;
            this._currentBegrenzung = new Begrenzung();
        } else {

            while (this._begrenzungen.length > 1) {
                this._begrenzungen.splice(1);
            }

            this._currentBegrenzung = this._begrenzungen[0];

            if (this._currentBegrenzung.isDefault()) {
                if (this._currentBegrenzung.rejectAlways) {
                    this._currentBegrenzung.enabled = true;
                    this.workmode = 1;
                } else {
                    this.workmode = 0;
                }
            } else {
                this._currentBegrenzung.enabled = true;
                this._currentBegrenzung.type = ELimitType.RECURRING;
                this._currentBegrenzung.duration = 24 * 60 * 60;
                this._currentBegrenzung.validFrom = moment().seconds(0).minute(0).hour(0).toDate();
                this._currentBegrenzung.monday = true;
                this._currentBegrenzung.tuesday = true;
                this._currentBegrenzung.wednesday = true;
                this._currentBegrenzung.thirsday = true;
                this._currentBegrenzung.friday = true;
                this._currentBegrenzung.saturday = true;
                this._currentBegrenzung.sunday = true;
                this._currentBegrenzung.rejectAlways = true;
                this._currentBegrenzung.id = 1;

                if (this._currentBegrenzung.laender.length > 0) {
                    this._currentBegrenzung.stationen = [];
                    this._currentBegrenzung.rejectAlways = false;
                    this.workmode = 2;
                } else if (this._currentBegrenzung.stationen.length > 0) {
                    this._currentBegrenzung.laender = [];
                    this._currentBegrenzung.rejectAlways = false;
                    this.workmode = 3;
                }
            }
        }

        this.value = 'dfsdfsdfsdf';

        if (this.workmode === 0) {
            this.value = '<i class="icofont icofont-ui-check text-success" title="' +
                         this._('Keine Einschränkung') +
                         '"></i>';
        } else if (this.workmode === 1) {
            this.value = '<i class="icofont icofont-minus-circle text-danger" title="' +
                         this._('immer abweisen') +
                         '"></i>';
        } else if (this.workmode === 2) {
            this.value = '<i class="icofont icofont-globe text-muted" title="' +
                         this._('Ländereinschränkung') +
                         '"></i>';

            this._currentBegrenzung.laender.forEach((l: ILand) => {
                this.value = this.value +
                             ' <i class="flag-icon flag-icon-squared flag-icon-' +
                             l.laendercode +
                             '" title="' +
                             this._(l.bezeichnung) +
                             '"></i>';
            });

        } else if (this.workmode === 3) {
            this.value = '<i class="me-icon me-icon-station text-muted" title="' +
                         this._('Stationseinschränkung') +
                         '"></i> ';
            this.value = this.value + '<span title="';
            this._currentBegrenzung.stationen.forEach((l: IStation, index: number) => {
                if (index > 0) {
                    this.value = this.value + ', ';
                }
                this.value = this.value + l.bezeichnung + '(' + l.nummer + ')';
            });
            this.value = this.value + '">';
            if (this._currentBegrenzung.stationen.length === 1) {
                this.value = this.value + this._currentBegrenzung.stationen[0].bezeichnung;
            } else {
                this.value = this.value +
                             this._('{{n}}&nbsp;Stationen', {n: this._currentBegrenzung.stationen.length});
            }
            this.value = this.value + '</span>';
        }


    }


}
