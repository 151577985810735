import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {LocalizedComponent} from '../../commons/components/localized-component';

import {Geraet} from '../common/geraet';
import {LoginService} from '../../auth/services/login.service';
import {MECoinAcceptorUnitSettings} from '../common/mecoin-acceptor-unit-settings';
import {MELoraGatewaySettings} from '../common/melora-gateway-settings';

@Component({
    selector: 'app-device-coin-acceptor-unit-details',
    templateUrl: './device-coin-acceptor-unit-details.component.html',
    styleUrls: ['./device-coin-acceptor-unit-details.component.scss']
})
export class DeviceCoinAcceptorDetailsComponent extends LocalizedComponent implements OnInit {

    private _device: Geraet = null;

    @Input()
    set device(dev: Geraet) {
        if (this._device !== dev) {
            this._device = dev;
            this.cd.markForCheck();
        }
    }


    get device(): Geraet {
        return this._device;
    }

    @Output()
    onAction: EventEmitter<string> = new EventEmitter<string>();

    constructor(

                public cd: ChangeDetectorRef,
                public loginService: LoginService
    ) {
        super( cd);
    }

    ngOnInit() {
    }

    sendAction(action: string) {
        this.onAction.emit(action);
    }

    showPairing() {

        const s = (this.device.settings as MECoinAcceptorUnitSettings);

        if (s === null || s === undefined) {
            return false;
        }

        if (s.mac === null || s.mac === undefined || s.mac.trim() === '') {
            return false;
        }
        if (s.gateway === null || s.gateway === undefined) {
            return false;
        }

        if (!s.gateway.isMELoragateway()) {
            return false;
        }

        if (this.loginService.isAdmin()) {
            return true;
        }

        if (s.customer_id === null || s.customer_id === undefined) {
            return false;
        }

        if (this.loginService.isProjectAdmin()) {

            if (
                (s.customer_id === 0) ||
                (s.customer_id === (s.gateway.settings as MELoraGatewaySettings).customer_id) ||
                (s.gateway_mac === null || s.gateway_mac === '' || s.gateway_mac === '00:00:00:00:00:00')
            ) {
                return true;
            }
        }

        return false;
    }

}
