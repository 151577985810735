import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {Waehrung} from '../waehrung';
import {IWaehrung} from '../iwaehrung';

import {LocalizedComponent} from '../../components/localized-component';

@Component({
    selector: 'app-waehrung-quick-info',
    templateUrl: './waehrung-quick-info.component.html',
    styleUrls: ['./waehrung-quick-info.component.scss'],
        changeDetection: ChangeDetectionStrategy.OnPush

})
export class WaehrungQuickInfoComponent extends LocalizedComponent implements OnInit {

    private _waehrung: Waehrung = null;


    get waehrung(): IWaehrung {
        return this._waehrung;
    }

    @Input()
    set waehrung(value: IWaehrung) {
        this._waehrung = Waehrung.fromResult(value);
        this.cd.markForCheck();
    }

    @Input() showLabel = true;

    private _cfg: any = {nullIsAny: false};

    get cfg(): any {
        return this._cfg;
    }

    @Input()
    set cfg(value: any) {
        if (value != null) {
            this._cfg = {...this._cfg, ...value};
            this.cd.markForCheck();
        }
    }
 get label() {
        if (!this.isValid()) {
            return '';
        }
        if (this._waehrung === null || this._waehrung === undefined) {
            if (this._cfg.nullIsAny) {
                return this._('alle Währungen');
            } else {
                return this._('keine Währung');
            }
        }
        return this._waehrung.bezeichnung;
    }


    constructor(  public cd: ChangeDetectorRef) {
        super( cd);
    }

    ngOnInit() {
    }

    isValid() {
        if (this._waehrung !== undefined && this._waehrung !== null) {
            return true;
        }
        if (this._cfg !== undefined && this._cfg !== null) {
            return this._cfg.nullIsAny;
        }
        return false;
    }


}
