import {ChangeDetectorRef, Component, Injector} from '@angular/core';

import {DataTableRow} from '../../commons/data-table/data-table-row';
import {LocalizedCellRenderer} from '../../commons/data-table/renderer/common-cell-renderer';
import {DataTableColumnDirective} from '../../commons/data-table/data-table-column.directive';

@Component({
               selector: 'rechnung-status-cell-renderer',
               template: '<rechnung-status-display *ngIf=\'cell!==null && cell!==undefined\' [status]=\'cell\'></rechnung-status-display>'
           })
export class RechnungStatusCellRendererComponent extends LocalizedCellRenderer {

    cell: any = null;
    row: DataTableRow<any> = null;
    column: DataTableColumnDirective = null;
    value = '';

    constructor(

                public injector: Injector,
                public cd:ChangeDetectorRef) {
        super(cd);
        this.cell = this.injector.get('cell');
        this.row = this.injector.get('row');
        this.column = this.injector.get('column');

    }

    public XtraClasses(): string {
        return '';
    }

}
