<div class="modal-header">
    <h5 class="modal-title" *ngIf="isAdd()">{{ _('Lieferschein erfassen') }}</h5>
    <h5 class="modal-title" *ngIf="isEdit()">{{ _('Lieferschein bearbeiten') }}</h5>
    <button type="button" class="close" aria-label="Close" (click)="cancel()">
        <span aria-hidden="true">&times;</span>
    </button>

</div>
<div class="modal-body">
    <app-tabset>
        <app-pane [title]="_('Lieferscheindaten')">
            <station-field
                    [label]="_('Station')"
                    [value]="entity.station"
                    (valueChange)="setStation($event)"
            ></station-field>
            <tank-field
                    #tankfield
                    [class.hide]="!( entity.station!==null && entity.station!==undefined && entity.station.id>0)"
                    [label]="_('Tank')"
                    [value]="entity.tank"
                    (valueChange)="setTank($event)"
                    [station]="entity.station"
                    [has_errors]="has_errors"
            ></tank-field>
            <produkt-field
                    *ngIf="entity.tank!==null && entity.tank!==undefined && entity.tank.id>0"
                    [label]="_('Produkt')"
                    [(value)]="entity.produkt"
                    [disabled]="true"
            ></produkt-field>

            <date-time-field
                    *ngIf="entity.produkt!==null && entity.produkt!==undefined && entity.produkt.id>0"

                    [label]="_('Datum')"
                    [displayFormat]="'L'"
                    [dateOnly]="true"
                    [(value)]="entity.datum"></date-time-field>
            <date-time-field
                    *ngIf="entity.produkt!==null && entity.produkt!==undefined && entity.produkt.id>0"

                    [label]="_('Lieferzeitpunkt')"
                    [displayFormat]="'LLLL'"
                    [dateOnly]="false"
                    [(value)]="entity.lieferDatum"></date-time-field>

            <text-field [label]="_('Lieferscheinnummer')"
                        [(value)]="entity.nummer"
                        *ngIf="entity.produkt!==null && entity.produkt!==undefined && entity.produkt.id>0"

            ></text-field>
            <text-field [label]="_('Bemerkungen')"
                        [(value)]="entity.info"
                        *ngIf="entity.produkt!==null && entity.produkt!==undefined && entity.produkt.id>0"

            ></text-field>
            <num-field
                    *ngIf="entity.produkt!==null && entity.produkt!==undefined && entity.produkt.id>0"

                    [label]="_('Zugang in L')"
                    [(value)]="entity.zugang"
            ></num-field>
            <num-field
                    *ngIf="entity.produkt!==null && entity.produkt!==undefined && entity.produkt.id>0"
                    [label]="_('Warenwert')"
                    [(value)]="entity.wert"
            ></num-field>
            <boolean-field
                    *ngIf="entity.produkt!==null && entity.produkt!==undefined && entity.produkt.id>0"
                    [label]="_('Warenwert ist netto')"
                    [(value)]="entity.isNetto"
            ></boolean-field>
        </app-pane>
        <app-pane [title]="_('Preisfindung')"
                  *ngIf="hasPreisfindung()"
        >
            <table class="table">
                <tr>
                    <th scope="row">{{_('Zeitpunkt')}}</th>
                    <td class="text-right">{{fDate(entity.lieferDatum ,'medium')}}</td>
                    <th scope="row">{{_('Produkt')}}</th>
                    <td class="text-right">
                        <app-produkt-quick-info [produkt]="entity.produkt"></app-produkt-quick-info>
                    </td>
                    <td></td>
                </tr>
                <tr>
                    <th scope="row" rowspan="2">{{('Rest-Bestand zum Zeitpunkt')}}</th>
                    <td rowspan="2" class="text-right">{{ fNum( bestand ,'1.2-2')}} l</td>
                    <th scope="row">Preis zum Zeitpunkt</th>
                    <td class="text-right">{{ fCurrency(preis ,'EUR','symbol','1.3-3') }}</td>
                    <td class="text-right" rowspan="2">
                        <button class="btn btn-secondary" (click)="requestData()">
                            <i class="far fa-sync"></i> {{_('Aktualisieren')}}
                        </button>
                    </td>
                </tr>
                <tr>
                    <th scope="row">{{_('Restwert zum Zeitpunkt')}}</th>
                    <td class="text-right">{{ fCurrency(restWert ,'EUR','symbol','1.3-3') }}</td>
                </tr>
                <tr>
                    <th scope="row">{{_('Zugang')}}</th>
                    <td class="text-right">{{fNum( entity.zugang ,'1.2-2') }} l</td>
                    <th scope="row">{{_('berechneter Liter-Preis')}}</th>
                    <td class="text-right">{{ fCurrency(literpreis ,'EUR','symbol','1.3-3') }}</td>
                    <td class="text-right"> &nbsp;</td>
                </tr>
                <tr>
                    <th scope="row">{{_('Bestand neu')}}</th>
                    <td class="text-right">{{ fNum( bestandNeu ,'1.2-2') }} l</td>
                    <th scope="row">{{_('Gesamtwert Bestand')}}</th>
                    <td class="text-right">{{ fCurrency(gesamtWert ,'EUR','symbol','1.3-3') }}</td>
                    <td class="text-right"> &nbsp;</td>
                </tr>
                <tr>
                    <th scope="row">{{_('neuer Literpreis berechnet')}}</th>
                    <td class="text-right">{{ fCurrency( literpreisNeu ,'EUR','symbol','1.3-3') }} l
                    </td>
                    <th scope="row">{{_('Preis einbuchen')}}</th>
                    <td>
                        <num-field [label]="''" [labelwidth]="0" [(value)]="neuPreis"
                                   [id]="'neuPreisFeld'"
                                   [addonicon]="['far fa-equals']"
                                   [addonlabel]="[_('Berechnung übernehmen')]"
                                   [enableaddon]="true"
                                   (addonClick)="addOnClick($event)"
                        ></num-field>
                    </td>
                    <td class="text-right">
                        <button class="btn btn-primary" (click)="submitPrice()">
                            <i class="far fa-badge-check"></i> {{_('Preis stellen')}}
                        </button>
                    </td>
                </tr>
            </table>
        </app-pane>
        <app-pane [title]="_('Anhänge &amp; Dokumente')"
                  *ngIf="entity.produkt!==null && entity.produkt!==undefined && entity.produkt.id>0"
        >
            <app-attachments-control
                    [(attachments)]="entity.attachments"
            ></app-attachments-control>
        </app-pane>
        <app-pane [title]="_('Sonstiges')"
                  *ngIf="entity.id>0"
        >
            <date-time-field [label]="_('erfasst')" [(value)]="entity.dateCreated"></date-time-field>
            <date-time-field [label]="_('zuletzt geändert')" [(value)]="entity.lastUpdated"></date-time-field>
        </app-pane>
    </app-tabset>
</div>
<div class="modal-footer d-flex justify-content-between">
    <div>
        <button type="button" class="btn btn-danger" (click)="remove()" *ngIf="hasSavedEntity()">
            <i class="fa far fa-trash"></i> {{ _('Löschen') }}
        </button>
    </div>
    <div>
    <button type="button" class="btn btn-secondary" (click)="cancel()">
        <i class="icofont icofont-close"></i> {{ _('Schließen') }}
    </button>
    <button type="button" class="btn btn-primary" (click)="save()" [disabled]="!canSave()">
        <i class="icofont icofont-save"></i> {{ _('Speichern') }}
    </button>
    </div>
</div>
