import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';

import {Station} from './common/station';
import {StationenService} from './common/stationen.service';
import {Tank} from './tanks/common/tank';
import {TankList} from './tanks/common/tank-list';
import {MESelectItemFormatterCallback} from '../commons/dynamic-form/me-select/meselect-item-formatter-callback';
import {
    MEHighlitePipe,
    MESelectComponent,
    MESelectItemMatcherCallback,
    MESelectItemTextCallback
} from '../commons/dynamic-form/me-select/meselect.component';
import {IListResult} from '../commons/list-result';
import {ITank} from './tanks/common/itank';
import {MESelectSearchEvent} from '../commons/dynamic-form/me-select/meselect-search-event';
import {ConverterTool} from '../../shared/converter-tool';
import CryptoES from "crypto-es";

@Component({
    selector: 'tank-field',
    template: `
        <div class="form-group row b-3">
            <label for="{{ id }}" class="col-form-label"
                   [ngClass]="'col-'+labelwidth"
                   [class.text-danger]="has_errors"
                   *ngIf="labelwidth>0"
            >
                <strong *ngIf="has_errors">{{ label }}</strong>
                <span *ngIf="!has_errors">{{ label }}</span>
            </label>
            <div class="col-{{ 12-labelwidth }} b-3" [class.has-border]="has_errors" [class.border-danger]="has_errors">
                <meselect
                        id="{{ id }}"
                        [idField]="'id'"
                        [items]="tanks?.data"
                        [asMatrix]="false"
                        [placeholder]="label"
                        (itemSelected)="doSelect($event)"
                        (needsSearch)="doSearch($event)"
                        [small]="small"
                        [nullable]="nullable"
                        [onFormat]="itemFormat"
                        [onGetText]="itemText"
                        [onMatch]="itemMatcher"
                >

                </meselect>

            </div>
        </div>

    `,
    styles: [`

        :host .addon button:first-child {
            border-left: none;
            padding-left: 5px;
            padding-right: 5px;
        }


    `]
})
export class TankFieldComponent implements OnInit, AfterViewInit {

    @Input() has_errors = false

    @Input() nullable = true;

    @ViewChild(MESelectComponent, {static: true}) tanksMESelect: MESelectComponent;
    @Input() small = false;

    public tanks: TankList = new TankList();
    @Input() label = 'Textfeld';
    @Input() id = '';
    @Input() labelwidth = 3;
    @Input() maxDisplayTanks = 50;

    @Input() station: Station = null;
    @Input() disabled = false;

    @Output() valueChange = new EventEmitter<Tank>();

    constructor(public stationenService: StationenService,
                public cd: ChangeDetectorRef
    ) {
    }

    public _value: Tank = null;

    get value(): Tank {
        return this._value;
    }

    @Input()
    set value(v: Tank) {
        if (this._value !== v) {
            this._value = v;
            this.valueChange.emit(v);
        }
        if (this.tanksMESelect !== undefined && this.tanksMESelect !== null) {
            this.tanksMESelect.value = this._value;
            this.tanksMESelect.cd.markForCheck();
        }
        this.cd.markForCheck();
    }

    init() {
        if (this.tanksMESelect !== undefined && this.tanksMESelect !== null) {
            this.tanksMESelect.onFormat = this.itemFormat;
            this.tanksMESelect.onGetText = this.itemText;
            this.tanksMESelect.onMatch = this.itemMatcher;
            this.tanksMESelect.value = this._value;
        }
        if (this.id === '') {
            this.id = 'tf' + CryptoES.MD5('textfield-' + this.label + Math.random() + '-' + Math.random()).toString();
        }

    }

    ngOnInit() {
        this.init();
        this.doSearch(new MESelectSearchEvent('', this.tanksMESelect));
    }

    doSearch(value: MESelectSearchEvent, selectIfSingle = false) {

        this
            .stationenService
            .listTanks(
                this.station,
                this.maxDisplayTanks,
                0,
                [],
                null,
                ConverterTool.processQuery(value.queryString)
            )
            .subscribe(
                (c: IListResult<ITank>) => {
                    this.tanks.populateFromListResult(c);
                    if (selectIfSingle) {
                        if (this.tanks.data.length === 1) {
                            this.doSelect(this.tanks.data[0]);
                        }
                    }

                    if (value.component != null && value.component !== undefined) {
                        value.component.items = this.tanks;
                        if (this.tanks.data.length === 1) {
                            value.component.select(this.tanks.data[0]);
                        }
                        value.component.cd.markForCheck();
                    }
                }
            );
    }

    doSelect(tank: Tank) {
        this._value = tank;
        this.valueChange.emit(tank);
        this.cd.markForCheck();
    }

    public itemFormat: MESelectItemFormatterCallback<Tank> = (item: Tank, search) => {
        if (item === null) {
            return '';
        }
        return '<i class="me-icon me-icon-tank"></i>' +
            MEHighlitePipe.transformString(item.bezeichnung, search) +
            ' <span class="badge-default badge">' + MEHighlitePipe.transformString('' + item.nummer, search) + '</span>';
    };

    public itemText: MESelectItemTextCallback<Tank> = (item: Tank) => {
        if (item === null) {
            return '';
        }
        return item.bezeichnung + ' [' + item.nummer + ']';
    };

    public itemMatcher: MESelectItemMatcherCallback<Tank> = (item: Tank, search) => {
        if (!search) {
            return true;
        }
        if (search.trim() === '') {
            return true;
        }
        if (!item) {
            return false;
        }
        const x = item.bezeichnung + ' ' + item.nummer;

        return (x.toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) >= 0);
    };

    ngAfterViewInit(): void {
        this.init();
    }

    public clearList() {
        this.tanks.clear();
    }
}

