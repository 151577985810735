<div class="modal-header">
  <h5 class="modal-title">{{ _('Lieferscheindetails') }}</h5>
  <button type="button" class="close" aria-label="Close" (click)="cancel()">
    <span aria-hidden="true">&times;</span>
  </button>

</div>
<div class="modal-body">
  <app-tabset>
    <app-pane [title]="_('Lieferscheindaten')">

      <table class="table">
        <tr>
          <th scope="row">{{_('Lieferscheinnummer')}}</th>
          <td>{{entity.nummer}}</td>
        </tr>
        <tr>
          <th scope="row">{{_('Datum')}}</th>
          <td>{{fDate( entity.datum ,'shortDate')}}</td>
        </tr>
        <tr>
          <th scope="row">{{_('Lieferzeitpunkt')}}</th>
          <td>{{fDate(entity.lieferDatum,'mediumDate')}}</td>
        </tr>
        <tr>
          <th scope="row">{{_('Bemerkungen')}}</th>
          <td>{{entity.info}}</td>
        </tr>
        <tr *ngIf="entity.tank!==null && entity.tank!==undefined">
          <th scope="row">{{_('Tank')}}</th>
          <td><app-tank-quick-info [tank]="entity.tank"></app-tank-quick-info></td>
        </tr>
        <tr *ngIf="entity.produkt!==null && entity.produkt!==undefined">
          <th scope="row">{{_('Produkt')}}</th>
          <td><app-produkt-quick-info [produkt]="entity.produkt"></app-produkt-quick-info></td>
        </tr>
        <tr>
          <th scope="row">
            {{_('Zugang')}}
          </th>
          <td>
            {{ fNum(entity.zugang ,'1.2-2') }}
            <span *ngIf="entity.produkt!==undefined && entity.produkt!==null && entity.produkt.einheit!==undefined && entity.produkt.einheit!==null">{{ entity.produkt.einheit.symbol}}</span>
          </td>
        </tr>
      </table>
    </app-pane>
    <app-pane [title]="_('Station')" *ngIf="entity.station!==null && entity.station!==undefined">
      <app-station-display [station]="entity.station"></app-station-display>
    </app-pane>
    <app-pane [title]="_('Dokumente')" *ngIf="entity.attachments.length>0">
            <ul class="list-group">
                <li *ngFor="let at of entity.attachments"
                   class="list-group-item justify-content-between"
                    >
                    <div title="{{ at.name }}">
                        <i class="{{ getIcon(at) }}"></i>
                        {{shorten(at.name,85)}}
                    </div>
                    <div>
                        <span *ngIf="at.size>0">{{ bytes(at.size ) }} &nbsp;</span>
                        <button class="btn btn-default btn-secondary"
                           (click)="downloadAttachment(at)"
                           title="{{ at.name }}">
                            <i class="icofont icofont-download-alt"></i> {{ _('Herunterladen')}}
                        </button>
                    </div>
                </li>
            </ul>
        </app-pane>
    <app-pane [title]="_('Sonstiges')"
    >
      <table class="table">
        <tr>
          <th scope="row">{{_('erfasst am')}}</th>
          <td>{{ fDate(entity.dateCreated,'mediumDate')}}</td>
        </tr>
        <tr>
          <th scope="row">{{_('erfasst durch')}}</th>
          <td>{{entity.erfasser.fullname}}</td>
        </tr>
        <tr>
          <th scope="row">{{_('zuletzt bearbeitet am')}}</th>
          <td>{{fDate(entity.lastUpdated,'mediumDate')}}</td>
        </tr>
        <tr>
          <th scope="row">{{_('bearbeitet durch')}}</th>
          <td>{{entity.lastEditor.fullname}}</td>
        </tr>
      </table>
    </app-pane>
  </app-tabset>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-secondary" (click)="cancel()">
    <i class="icofont icofont-close"></i> {{ _('Schließen') }}
  </button>
</div>
