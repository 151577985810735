import {Injector} from '@angular/core';
import {L10nLoader} from 'angular-l10n';
import {METool} from './metool';

export class MEAppInjector {

    static injector_reference: Injector = null;

    static init(injector?: Injector) {
        if (!METool.isNullOrUndefined(injector)) {
            if (METool.isNullOrUndefined(MEAppInjector.injector_reference)) {
                MEAppInjector.injector_reference = injector;
            }
        }
    }

    static injector() {
        return MEAppInjector.injector_reference;
    }

    public static get(token: any, notFound?: any, flags?: any): any {
        return MEAppInjector.injector().get(token, notFound, flags);
    }

    public static initInjector(injectorRef: Injector): Function {
        const n = () => {
            MEAppInjector.init(injectorRef);
        };
        return n;
    }
}
