<div class="modal-header">
    <h5 class="modal-title">{{ _('Rechnung einbuchen') }}</h5>
    <button type="button" class="close" aria-label="Close" (click)="cancel()">
        <span aria-hidden="true">&times;</span>
    </button>

</div>
<div class="modal-body">
    <app-tabset>
        <app-pane [title]="_('Rechnungsdaten')">
            <date-time-field [label]="_('Rechnungsdatum')" [displayFormat]="'L'" [dateOnly]="true"
                             [(value)]="entity.rechnungDatum"></date-time-field>
            <num-field [label]="_('Nummer')"
                       [(value)]="entity.nummer"
                       id="nummer-field"
                       [addonicon]="['icofont icofont-search']"
                       [addonlabel]="[_('freie Nummer suchen')]"
                       [enableaddon]="true"
                       (addonClick)="addOnClick($event)"
            ></num-field>
            <text-field [label]="_('Rechnungsnummer')" [(value)]="entity.rechnungNummer"></text-field>
            <num-field [label]="_('Rechnungsendbetrag')" [(value)]="entity.summe"></num-field>
            <currency-field [label]="_('Währung')" [(value)]="entity.waehrung"></currency-field>
        </app-pane>
        <app-pane [title]="_('Zuordnung')">
            <kunde-field [label]="_('Kunde')" [(value)]="entity.kunde"></kunde-field>
            <app-kunde-display [kunde]="entity.kunde"></app-kunde-display>
        </app-pane>
        <app-pane [title]="_('Status')">
            <rechnung-status-field [label]="_('Status')" [(value)]="entity.status"></rechnung-status-field>
            <date-time-field [label]="_('fällig am')" [displayFormat]="'L'" [dateOnly]="true"
                             [(value)]="entity.dueDatum"></date-time-field>
            <date-time-field [label]="_('bezahlt am')" [displayFormat]="'L'" [dateOnly]="true"
                             [(value)]="entity.paidDatum"></date-time-field>
        </app-pane>
        <app-pane [title]="_('Anhänge &amp; Dokumente')">
        <app-attachments-control
                [(attachments)]="entity.attachments"
        ></app-attachments-control>
        </app-pane>
        <app-pane [title]="_('Sonstiges')">
            <date-time-field [label]="_('gelesen am')" [(value)]="entity.readDatum"></date-time-field>
            <date-time-field [label]="_('im System erstellt')" [(value)]="entity.dateCreated"></date-time-field>
            <date-time-field [label]="_('zuletzt geändert')" [(value)]="entity.lastUpdated"></date-time-field>
            <date-time-field [label]="_('zuletzt bearbeitet')" [(value)]="entity.lastEdited"></date-time-field>
        </app-pane>
    </app-tabset>


</div>
<div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="cancel()">
        <i class="icofont icofont-close"></i> {{ _('Schließen') }}
    </button>
    <button type="button" class="btn btn-primary" (click)="save()">
        <i class="icofont icofont-save"></i> {{ _('Speichern') }}
    </button>
</div>
