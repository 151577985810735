<app-login-alert>
    <app-area-heading
            [icon]="'icofont icofont-law-document'"
            title="{{ _('Lieferscheine') }}"
    ></app-area-heading>


    <div class="card">
        <div class="card-body">
            <div class="card-text">
                <data-table
                        [selectable]="false"
                        [data]="lieferscheine.asDataTableRows()"
                        [orders]="lieferscheine.order"
                        [filter]="lieferscheine.simpleFilter"
                        (sortChanged)="sortChanged($event)"
                        (filterChanged)="filterChanged($event)"
                        [rowActionsAsButtons]="true"
                        [tableActionsAsButtons]="true"
                        [showFilterArea]="false"
                        #lieferscheinListe
                >
                    <tableaction
                            [label]="_('Lieferschein erfassen')"
                            [icon]="'icofont icofont-ui-add'"
                            (perform)="addLieferschein()"
                            *hasRole="['ROLE_ADMIN','ROLE_PROJECT_ADMIN','ROLE_BILLING','ROLE_BESTAND_WRITABLE']"
                    ></tableaction>
                     <tableaction
                            [label]="_('Export')"
                            [icon]="'icofont icofont-download-alt'"
                            [type]="'dropdown'"
                    >
                        <list-option
                                *hasRole="['ROLE_ADMIN','ROLE_PROJECT_ADMIN','ROLE_BILLING','ROLE_BESTAND_WRITABLE']"
                                [label]="_('Lieferscheinliste .xlsx')"
                                [icon]="'far fa-file-excel'"
                                (perform)="downloadEntries('xlsx',12001)"
                        ></list-option>
                    </tableaction>
                    <tableaction
                            *hasRole="['ROLE_ADMIN','ROLE_PROJECT_ADMIN']"
                            [label]="t('Werkzeuge')"
                            [icon]="'icofont icofont-tools'"
                            [type]="'dropdown'"

                    >
                        <list-option
                                *hasRole="['ROLE_ADMIN','ROLE_PROJECT_ADMIN']"
                                [label]="t('Lieferscheine indizieren')"
                                [icon]="'icofont icofont-cloud-refresh'"
                                (perform)="reindexLieferscheine()"
                        ></list-option>
                    </tableaction>

                    <column
                            [field]="'datum'"
                            [label]="_('Datum')"
                            type="Date"
                            [cfg]="{format:'mediumDate'}"
                            [sortable]="true"></column>
                    <column
                            [field]="'lieferDatum'"
                            [label]="_('Lieferzeitpunkt')"
                            type="Date"
                            [cfg]="{format:'medium'}"
                            [sortable]="true"></column>
                    <column [field]="'nummer'"
                            [label]="_('Nummer')"
                            [sortable]="true"
                    ></column>
                    <column [field]="'info'"
                            [label]="_('Info')"
                            [sortable]="true"
                    ></column>
                    <column [field]="'station'"
                            [label]="_('Station')"
                            [sortable]="false"
                            [renderer]="stationR"
                    ></column>
                    <column [field]="'tank'"
                            [label]="_('Tank')"
                            [sortable]="false"
                            [renderer]="tankR"
                    ></column>
                    <column [field]="'produkt'"
                            [label]="_('Produkt')"
                            [sortable]="false"
                            [renderer]="produktR"
                    ></column>
                    <column
                            [field]="'zugang'"
                            [label]="_('Zugang')"
                            [renderer]="literR"
                            [cfg]="{fdigits:0,round:true}"
                            [sortable]="true">

                    </column>
                    <column
                            [field]="'wert'"
                            [label]="_('Warenwert')"
                            [renderer]="preiswert"
                            [cfg]="{fdigits:2,round:true}"
                            [sortable]="true">
                    </column>

                    <column
                            [field]="'attachments'"
                            [label]="_('Dokumente')"
                            [renderer]="attachmentsR"
                            [sortable]="true"></column>

                    <rowaction
                            [label]="_('Details')"
                            [icon]="'far fa-info-circle'"
                            [title]="_('Details anzeigen')"
                            (perform)="showDetails($event)"
                    ></rowaction>
                    <rowaction
                            [label]="_('Bearbeiten')"
                            [icon]="'far fa-pencil'"
                            [title]="_('Lieferschein bearbeiten')"
                            (perform)="editLieferschein($event)"
                            *hasRole="['ROLE_ADMIN','ROLE_PROJECT_ADMIN','ROLE_BILLING','ROLE_BESTAND_WRITABLE']"
                    ></rowaction>

                    <data-table-table-extra-header>
                        <app-paginator
                                [total]="lieferscheine.total"
                                [page]="lieferscheine.page"
                                [perpage]="lieferscheine.size"
                                (pageChange)="updateLieferscheine(true,$event)"
                                (pageCountChange)="updateLieferscheinePageCount($event)"
                                [showSearch]="true"
                                (queryChange)="lieferscheineQueryChange($event)"
                                [showFilterButton]="true"
                                [datatable]="lieferscheinListe"
                                (onFilterToggle)="lieferscheinFilterToggle($event)"
                                [filterDialog]="filterDialog"
                        ></app-paginator>
                    </data-table-table-extra-header>
                    <data-table-filter-area>
                        <app-lieferschein-filter-dialog
                                #filterDialog
                                (filterChanged)="lieferscheinQueryChange2($event)"
                        ></app-lieferschein-filter-dialog>
                    </data-table-filter-area>
                </data-table>

            </div>
        </div>
    </div>


</app-login-alert>