<data-table
        [selectable]="false"
        [data]="preisgruppen.asDataTableRows()"
        [orders]="preisgruppen.order"
        [filter]="preisgruppen.simpleFilter"
        (sortChanged)="sortChanged($event)"
        (filterChanged)="filterChanged($event)"
        [rowActionsAsButtons]="true"
>

    <column
            [field]="'title'"
            [label]="t('Bezeichnung')"
            [sortable]="true"
    ></column>
    <column
            [field]="'gruppeArt'"
            [label]="t('Art')"
            [sortable]="true"
    ></column>

    <tableaction
            [label]="t('Preisgruppen anlegen')"
            [icon]="'icofont icofont-ui-add'"
            (perform)="newPreisgruppe()"
    ></tableaction>

    <rowaction
            [label]="t('Preisgruppe bearbeiten')"
            [icon]="'icofont icofont-ui-edit'"
            (perform)="editPreisgruppe($event)"
    ></rowaction>

    <data-table-table-extra-header>
        <app-paginator
                [total]="preisgruppen.total"
                [page]="preisgruppen.page"
                [perpage]="preisgruppen.size"
                (pageChange)="updatePreisgruppen(true,$event)"
                (pageCountChange)="updatePreisgruppenPageCount($event)"
        ></app-paginator>

    </data-table-table-extra-header>

</data-table>
