import {Transaktion} from './transaktion';
import {IStation} from '../../../stationen/common/istation';
import {ITransaktion} from './itransaktion';
import {GenericFilter} from '../../../commons/generic-filter';
import {IWaehrung} from '../../../commons/waehrungen/iwaehrung';
import {IAusweis} from '../../../customers/ausweise/common/iausweis';
import {IKunde} from '../../../customers/kunden/common/ikunde';
import {IFahrzeug} from '../../../customers/fahrzeuge/common/ifahrzeug';
import {IPerson} from '../../../customers/personen/common/iperson';
import {ITerminal} from '../../../stationen/terminals/common/iterminal';
import {ITransaktionDetail} from './itransaktion-detail';
import {Kostenstelle} from '../../../customers/kostenstellen/common/kostenstelle';

export class SimpleTransaktionFilter extends GenericFilter<Transaktion> implements ITransaktion {
    id: number = null;
    betrag_euro: number = null;
    b_betrag_euro: number = null;
    waehrung: IWaehrung = null;
    details: ITransaktionDetail[] = null;
    source: string = null;
    guid: string = null;
    zeitpunkt: Date = null;
    ausweiskennung1: string = null;
    ausweis1: IAusweis = null;
    ausweiskennung2: string = null;
    ausweis2: IAusweis = null;
    belegnummer: number = null;
    ISOBelegnummer: number = null;
    kmstand: number = null;
    kunde: IKunde = null;
    fahrzeug: IFahrzeug = null;
    person: IPerson = null;
    betrag: number = null;
    b_betrag: number = null;
    station: IStation = null;
    terminal: ITerminal = null;
    zusatzeingabe: string = null;
    referenzID: string = null;
    positionen: number = null;
    storniert: boolean = null;
    tReferenzId: number = null;
    kostenstelle: Kostenstelle = null;
    archived:number=null;
    markedForRedo: boolean = null;

    public asURL(): string {
        let url = '';


        Object.keys(this).forEach(k => {
            if ((this[k] !== null) && (this[k] !== undefined) && (this[k] !== '') && (k !== '_operations')) {
                url += (url !== '') ? '&' : '';
                url += 'filter.' + encodeURIComponent(k) + '=';
                if (k === 'terminal') {
                    url += encodeURIComponent('' + this.terminal.id);
                } else if (k === 'kunde') {
                    url += encodeURIComponent('' + this.kunde.id);
                } else if (k === 'station') {
                    url += encodeURIComponent('' + this.station.id);
                } else {
                    url += encodeURIComponent('' + this[k]);
                }
            }
        });

        Object.keys(this._operations).forEach(k => {
            if ((this._operations[k] !== null) && (this._operations[k] !== undefined) && (this._operations[k] !== '')) {
                url += (url !== '') ? '&' : '';
                url += 'operation.' + encodeURIComponent(k) + '=' + encodeURIComponent('' + this._operations[k]);
            }
        });

        return url;
    }


}
