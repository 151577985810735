import {AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Output, ViewChild} from '@angular/core';
import {KundenFilter} from './kunden-filter';
import {CountryElementRendererComponent} from '../../../commons/laender/country-element-renderer/country-element-renderer.component';
import {ElementListComponent} from '../../../commons/element-list/element-list.component';
import {CustomerelementRendererComponent} from '../../customerelement-renderer.component';
import {StationElementRendererComponent} from '../../../stationen/station-element-renderer/station-element-renderer.component';
import {IFilterDialog} from '../../../commons/data-table/ifilter-dialog';
import {LocalizedComponent} from '../../../commons/components/localized-component';

import {SprachenService} from '../../../commons/sprachen/common/sprachen.service';
import {Land} from '../../../commons/laender/common/land';

@Component({
    selector: 'app-kunde-filter-dialog',
    templateUrl: './kunde-filter-dialog.component.html',
    styleUrls: ['./kunde-filter-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush

})
export class KundeFilterDialogComponent extends LocalizedComponent implements IFilterDialog, AfterViewInit {

    stationRenderer = StationElementRendererComponent;
    customerRenderer = CustomerelementRendererComponent;
    countryRenderer = CountryElementRendererComponent;

    filter: KundenFilter = new KundenFilter(this.cd);

    @Output() filterChanged: EventEmitter<string> = new EventEmitter<string>();

    @ViewChild('stationen', {static: false}) stationen: ElementListComponent;
    @ViewChild('customers', {static: false}) customers: ElementListComponent;
    @ViewChild('laender', {static: false}) laender: ElementListComponent;

    constructor(


        public cd: ChangeDetectorRef,
        public sprachenService: SprachenService
    ) {
        super( cd);
        this.filter.cd = this.cd;
        this.filter.filterChanged.subscribe(
            () => {
                if (this.stationen !== null && this.stationen !== undefined) {
                    this.stationen.elements = this.filter.stationen;
                }
                if (this.customers !== null && this.customers !== undefined) {
                    this.customers.elements = this.filter.customers;
                }
                if (this.laender !== null && this.laender !== undefined) {
                    this.laender.elements = this.filter.countries;
                }
                this.markChanged();
            }
        );
    }

    ngAfterViewInit() {
        this.markChanged();
    }

    getQuery(): string {
        return this.filter.getQuery();
    }

    markChanged(visble = true): void {
        this.cd.markForCheck();
        if (visble) {
            this.filterChanged.emit(this.getQuery());
        } else {
            this.filterChanged.emit('');
        }
    }

    land_at() {
        this.filter.clearCountries(null);
        this.sprachenService.Oesterreich().then((l: Land) => {
            this.filter.addCountry(l, null);
        });
    }

    land_atde() {
        this.filter.clearCountries(null);
        this.sprachenService.Oesterreich().then((l: Land) => {
            this.filter.addCountry(l, null);
        });
        this.sprachenService.Deutschland().then((l: Land) => {
            this.filter.addCountry(l, null);
        });
    }

    land_de() {
        this.filter.clearCountries(null);
        this.sprachenService.Deutschland().then((l: Land) => {
            this.filter.addCountry(l, null);
        });
    }
}
