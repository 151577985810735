import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {LocalizedComponent} from '../../../commons/components/localized-component';
import {NgbActiveModal, NgbModal, NgbModalOptions, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {Lieferschein} from '../common/lieferschein';

import * as FileSaver from 'file-saver';
import {Attachment} from '../../attachments/common/attachment';
import {AttachmentService} from '../../attachments/common/attachment.service';
import {ToastrService} from 'ngx-toastr';

@Component({
    selector: 'app-lieferschein-details',
    templateUrl: './lieferschein-details.component.html',
    styleUrls: ['./lieferschein-details.component.scss']
})
export class LieferscheinDetailsComponent extends LocalizedComponent implements OnInit {

    entity: Lieferschein = new Lieferschein();

    static open(modalService: NgbModal, lieferschein: Lieferschein = null): Promise<any> {
        const o: NgbModalOptions = {
            backdrop: 'static',
            keyboard: false
        };
        const r: NgbModalRef = modalService.open(LieferscheinDetailsComponent, o);
        (r.componentInstance as LieferscheinDetailsComponent).open(lieferschein);
        return r.result;
    }

    constructor(
                
                public activeModal: NgbActiveModal,
                public toastr: ToastrService,
                public attachmentService: AttachmentService,
                public cd: ChangeDetectorRef
    ) {
        super( cd);
    }

    ngOnInit() {
    }

    getIcon(at: any) {
        return Attachment.fromResult(at).getIcon();
    }

    getURI(at: any) {
        return Attachment.fromResult(at).getURI();
    }

    downloadAttachment(ata: any) {
        const anhang = Attachment.fromResult(ata);
        if (anhang.isURL()) {
            window.open(anhang.getURI(), '_blank');
        } else if (anhang.isFile()) {
            this.attachmentService
                .downloadAttachment(anhang)
                .subscribe((response: Blob) => {
                    FileSaver.saveAs(response, anhang.filename);
                });
        }
    }

    open(lieferschein: Lieferschein = null) {
        if (lieferschein === null || lieferschein === undefined) {
            this.cancel();
        }
        this.entity = Lieferschein.fromResult(lieferschein);
    }

    cancel() {
        this.activeModal.dismiss('cancel');
    }
}
