<div class="modal-header">
    <h5 class="modal-title" *ngIf="isAdd()">{{ _('Terminal anlegen') }}</h5>
    <h5 class="modal-title" *ngIf="isEdit()">{{ _('Terminal bearbeiten') }}</h5>
    <button type="button" class="close" aria-label="Close" (click)="cancel()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">

    <num-field
            id="terminalNummer"
            [label]="_('Nummer')"
            [(value)]="entity.nummer"
            [addonicon]="['icofont icofont-search']"
            [addonlabel]="[_('freie Nummer suchen')]"
            [enableaddon]="true"
            (addonClick)="addOnClick($event)"
    ></num-field>
    <text-field [label]="_('Bezeichnung')" [(value)]="entity.bezeichnung"></text-field>

    <app-tabset>
        <app-pane title="{{ _('Infos') }}">
            <multi-line-field [label]="_('Infos')" [(value)]="entity.info"></multi-line-field>
            <boolean-field [label]="_('geeichte Anlage')" [(value)]="entity.geeicht"></boolean-field>
        </app-pane>
        <app-pane title="{{ _('angeschlossene Säulen') }}" *appIsAdmin>
            <zapfsaeulen-selector
                    [station]="station"
                    [value]="entity.saeulen"
                    (valueChanged)="saeulenChanged($event)"
            >
            </zapfsaeulen-selector>
        </app-pane>
        <app-pane title="{{ _('angeschlossene Sonden') }}" *appIsAdmin>
            <sonden-selector
                    [station]="station"
                    [value]="entity.sonden"
                    (valueChanged)="sondenChanged($event)"
            ></sonden-selector>
        </app-pane>
        <app-pane title="{{ _('Konnektivität') }}">
            <num-field [label]="_('Tankserver-ID')" [(value)]="entity.tankserverId"></num-field>
            <boolean-field [label]="_('IFSF aktiv?')" [value]="entity.ifsfEnabled" ></boolean-field>
            <num-field [label]="_('Box-Nummer')" [(value)]="entity.boxnummer"></num-field>
        </app-pane>
        <app-pane title="{{_('System')}}">
            <text-field [label]="_('IP-Adresse')" [(value)]="entity.ip"></text-field>
            <num-field [label]="_('Port')" [(value)]="entity.port"></num-field>
            <text-field [label]="_('URL')" [(value)]="entity.url"></text-field>
        </app-pane>
    </app-tabset>


</div>
<div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="cancel()">
        <i class="icofont icofont-close"></i> {{ _('Abbrechen') }}
    </button>
    <button type="button" class="btn btn-primary" (click)="save()">
        <i class="icofont icofont-save"></i>
        {{ _('Änderungen speichern')}}
    </button>
</div>
