import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Produkt} from './produkte/common/produkt';
import {ProdukteService} from './produkte.service';
import {EArtikelArt} from './artikel/eartikel-art';


@Component({
    selector: 'produkt-field',
    template: `
        <div class="form-group row">
            <label for="{{ id }}" class="col-{{ labelwidth }} col-form-label" *ngIf="labelwidth>0">
                {{ label }}
            </label>
            <div class="col-{{ 12-labelwidth }}">
                <produkt-inline-field
                        id="{{ id }}"
                        [label]="label"
                        [showOnly]="showOnly"
                                   [value]="value"
                        (valueChange)="doSelect($event)"
                        [small]="small"
                        [nullable]="nullable"
                        [disabled]="disabled"
                ></produkt-inline-field>
            </div>
        </div>
    `
})
export class ProduktFieldComponent implements OnInit {
    @Input() nullable = true;
    @Input() small = false;
    _value: Produkt = null;
    @Input() label = 'Textfeld';
    @Input() id = '';
    @Input() labelwidth = 3;
    @Input() showOnly: EArtikelArt = EArtikelArt.ANY;
    @Input() disabled = false;

    @Input()
    set value(v: Produkt) {
        if (this._value !== v) {
            this._value = v;
            this.valueChange.emit(v);
        }
    }

    @Output() valueChange = new EventEmitter<Produkt>();


    get value(): Produkt {
        return this._value;
    }

    constructor(public produkteService: ProdukteService
                ) {
    }

    ngOnInit() {
    }

    doSelect(produkt: Produkt) {
        this._value = produkt;
        if(!this.disabled) {
            this.valueChange.emit(produkt);
        }
    }


}
