import {Component, ElementRef, HostListener, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import Tether from 'tether';

declare let jQuery: JQueryStatic;

@Component({
    selector: 'me-info-tool',
    templateUrl: './meinfo-tool.component.html',
    styleUrls: ['./meinfo-tool.component.css']
})
export class MEInfoToolComponent implements OnInit, OnDestroy {

    @Input() icon = 'far fa-info-circle';
    @Input() title_icon = '';
    @Input() title = 'Information';
    @Input() content = '';
    @ViewChild('popover', {static: true}) popover = null;
    @Input() position = 'right';

    popoverVisible: boolean = false;
    private _tether: Tether = null;

    constructor(private el: ElementRef) {
    }


    ngOnInit() {

    }

    public ngOnDestroy(): void {
        this.hidePopover();
    }

    @HostListener('document:click', ['$event.target'])
    public onClick(targetElement) {
        if (this.popoverVisible) {
            if (this.el !== undefined) {
                let clickedInside = this.el.nativeElement.contains(targetElement);
                if (!clickedInside) {
                    let clickedInside = this.popover.nativeElement.contains(targetElement);
                    if (!clickedInside) {
                        this.hidePopover();
                    }
                }
            }
        }
    }

    showPopover(e: Event) {
        if (!this.popoverVisible) {
            if ((this.popover !== undefined) && (this.popover !== null)) {
                let options = {
                    element: this.popover.nativeElement,
                    target: (e.srcElement === undefined) ? e.target : e.srcElement,
                    attachment: 'middle left',
                    targetAttachment: 'middle right',
                    targetOffset: '0 10px'
                };

                if (this.position === 'left') {
                    options.attachment = 'middle right';
                    options.targetAttachment = 'middle left';
                    options.targetOffset = '0 -10px';
                }

                this._tether = new Tether(options);
                this._tether.position();
                this.popoverVisible = true;
            }
        } else {
            this.hidePopover();
        }
    }

    hidePopover() {
        this.popoverVisible = false;
        if (this._tether !== null) {
            this._tether.destroy();
            this._tether = null;
        }
    }
}
