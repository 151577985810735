import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {AusweisService} from '../ausweise/common/ausweis.service';
import {Router} from '@angular/router';
import {KundenService} from '../kunden/common/kunden.service';
import {IActionResponse} from '../../commons/action-response';

@Component({
  selector: 'app-dashboard-kunden-block',
  templateUrl: './dashboard-kunden-block.component.html',
  styleUrls: ['./dashboard-kunden-block.component.scss']
})
export class DashboardKundenBlockComponent implements OnInit {


    @Input() title = 'Kunden';
    @Input() destination = 'kunden';
    @Input() cls = 'yellow';
    @Input() showAlways = false;

    cnt = 0;

    constructor(
        public cd: ChangeDetectorRef,
        public kundeService: KundenService,
        public router: Router

    ) {
    }

    ngOnInit() {
        this.refresh();
    }

    refresh() {
        this.kundeService
            .countKunden()
            .subscribe(
                (c: IActionResponse) => {
                    this.cnt = parseInt('' + c.params.count, 10);
                    this.cd.markForCheck();
                },
                () => {
                    this.cnt = 0;
                    this.cd.markForCheck();
                }
            );
    }


    gotoArea() {
        this.router.navigate([this.destination]);
    }


}
