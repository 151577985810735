<div class="modal-header">
    <h5 class="modal-title" *ngIf="isNew()">{{ _("Kunde anlegen") }}</h5>
    <h5 class="modal-title" *ngIf="isEdit()">{{ _(dlgTitle,getTitleOptions()) }}</h5>

    <button type="button" class="close" aria-label="Close" (click)="cancel()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">

    <app-tabset>
        <app-pane [title]="_('Stammdaten')">
            <num-field
                    id="nummer-field"
                    [label]="_('Nummer')"
                    [(value)]="currentKunde.nummer"
                    [addonicon]="['icofont icofont-search']"
                    [addonlabel]="[_('freie Nummer suchen')]"
                    [enableaddon]="true"
                    (addonClick)="addOnClick($event)"
            ></num-field>
            <text-field [label]="_('Kundennummer')" [(value)]="currentKunde.kundennummer"></text-field>
            <text-field [label]="_('Bezeichnung')" [(value)]="currentKunde.bezeichnung"></text-field>
            <text-field [label]="_('FiBu-Konto')" [(value)]="currentKunde.fibuKonto"></text-field>
            <kostenstelle-field [label]="_('Kostenstelle')" [(value)]="currentKunde.kostenstelle"></kostenstelle-field>
            <text-field [label]="_('USt-ID/Steuernummer')" [(value)]="currentKunde.ustID"></text-field>
            <preisgruppe-field
                    [label]="_('Preisgruppe')"
                    [(value)]="currentKunde.preisgruppe"
            ></preisgruppe-field>
            <benutzer-field
                    [filter]="'permissions:ROLE_ADM'"
                    [label]="'Kundenbetreuer'"
                    [(value)]="currentKunde.betreuer"
                    [disabled]="!canChangeBetreuer()"
            ></benutzer-field>
        </app-pane>
        <app-pane [title]="_('Adresse')">
            <anrede-field [label]="_('Anrede')" [(value)]="currentKunde.anrede"></anrede-field>
            <text-field [label]="_('Firma/Unternehmen')" [(value)]="currentKunde.firma"></text-field>
            <div class="row">
                <div class="col-6">
                    <text-field [label]="_('Vorname')" [(value)]="currentKunde.vorname"></text-field>
                </div>
                <div class="col-6">
                    <text-field [label]="_('Nachname')" [(value)]="currentKunde.nachname"></text-field>
                </div>
            </div>
            <text-field [label]="_('Zusatz')" [(value)]="currentKunde.zusatz"></text-field>
            <text-field [label]="_('Anschrift')" [(value)]="currentKunde.anschrift1"></text-field>
            <text-field [label]="_('Anschrift Zeile 2')" [(value)]="currentKunde.anschrift2"></text-field>
            <div class="row">
                <div class="col-3">
                    <country-field [label]="_('Land')" [(value)]="currentKunde.land"></country-field>
                </div>
                <div class="col-3">
                    <text-field [label]="_('PLZ')" [(value)]="currentKunde.plz"></text-field>
                </div>
                <div class="col-6">
                    <text-field [label]="_('Ort')" [labelwidth]="2" [(value)]="currentKunde.ort"></text-field>
                </div>
            </div>

        </app-pane>
        <app-pane [title]="_('Kontakt')">
            <text-field [label]="_('Ansprechpartner')" [(value)]="currentKunde.ansprechpartner"></text-field>
            <text-field [label]="_('Telefon')" [(value)]="currentKunde.telefon"></text-field>
            <text-field [label]="_('Telefax')" [(value)]="currentKunde.telefax"></text-field>
            <text-field [label]="_('Mobiltelefon')" [(value)]="currentKunde.mobil"></text-field>
            <text-field [label]="_('Email')" [(value)]="currentKunde.email"></text-field>


        </app-pane>
        <app-pane [title]="_('Bankverbindung')">
            <text-field [label]="_('IBAN')" [(value)]="currentKunde.iban"></text-field>
            <text-field [label]="_('BIC')" [(value)]="currentKunde.bic"></text-field>
            <text-field [label]="_('Institut')" [(value)]="currentKunde.bank"></text-field>


        </app-pane>
        <app-pane [title]="_('Berechtigungen')">
            <boolean-field [label]="_('Kunde ist aktiv')" [(value)]="currentKunde.enabled"></boolean-field>
            <boolean-field [label]="_('Kunde darf sich einloggen')" [(value)]="currentKunde.canLogin"></boolean-field>
            <div class="alert alert-danger"
                 *ngIf="currentKunde.canLogin && !realCanLogin()"
            >
                {{ _('Bitte geben Sie eine gültige Kundennummer an, um dem Kunden ein Login zu ermöglichen!') }}
            </div>
            <text-field
                    *ngIf="realCanLogin()"
                    [label]="_('Login-Kennung')"
                    [value]="'K-'+currentKunde.kundennummer"
                    [disabled]="true"
            ></text-field>
            <password-field
                    *ngIf="realCanLogin()"
                    [label]="_('Login-Passwort')"
                    [(value)]="currentKunde.login_password"
            ></password-field>
            <div
                    class="alert alert-info"
                    *ngIf="realCanLogin() && isNew()"
            >
                {{ _('Ein Login ist nur möglich, wenn Sie hier ein Kennwort festlegen.') }}
            </div>
            <div
                    class="alert alert-info"
                    *ngIf="realCanLogin() && isEdit()"
            >
                {{ _('Das Passwort-Feld bitte nur ausfüllen, wenn Sie das Login-Kennwort ändern wollen.') }}
            </div>
        </app-pane>
        <app-pane [title]="_('Kontingent')" *ngIf="false">
            <app-kontingent-editor
                    [(value)]="currentKunde.kontingent"
                    [kunde]="currentKunde"
            ></app-kontingent-editor>
        </app-pane>
        <app-pane [title]="_('Begrenzungen')" *ngIf="false">
            <app-freigabe-editor [value]="currentKunde.begrenzungen"
                               (valueChange)="updateBegrenzungen($event)"
            ></app-freigabe-editor>
        </app-pane>
        <app-pane [title]="_('Sonstiges')">
            <multi-line-field [label]="_('Notizen')" [(value)]="currentKunde.notizen"></multi-line-field>
            <num-field [label]="_('Tankserver-ID')"
                       [(value)]="currentKunde.tankserverID"
            ></num-field>
            <num-field [label]="_('Ratio-ID')"
                       [(value)]="currentKunde.ratioID"
                       *ngIf="false"
            ></num-field>
            <date-time-field [label]="_('angelegt')"
                             [value]="currentKunde.dateCreated"
                             disabled="true"></date-time-field>
            <date-time-field [label]="_('zuletzt bearbeitet')"
                             [value]="currentKunde.lastEdited"
                             disabled="true"></date-time-field>
            <date-time-field [label]="_('zuletzt geändert')"
                             [value]="currentKunde.lastUpdated"
                             disabled="true"></date-time-field>

        </app-pane>
    </app-tabset>

</div>
<div class="modal-footer">

    <button type="button" class="btn btn-secondary" (click)="cancel()">
        <i class="icofont icofont-close"></i> {{ _('Abbrechen') }}
    </button>
    <button type="button"
            class="btn btn-primary"
            (click)="save()"
            [class.disabled]="!canSave()"
            [disabled]="!canSave()"
    >
        <i class="icofont icofont-save"></i>
        {{ _('Änderungen speichern')}}
    </button>
</div>

