<div class="modal-header">
    <h5 class="modal-title">{{ dlgTitle }}</h5>
    <button type="button" class="close" aria-label="Close" (click)="cancel()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <p>
        {{ _('Hiermit wird die PIN für folgenden Ausweis festgelegt. Die Änderungen treten umgehend in Kraft.')}}
    </p>
    <table class="table table-bordered">
        <tr>
            <th scope="row" class="w-40">{{_('Ausweis')}}</th>
            <td>{{entity.nummer}}</td>
        </tr>
        <tr>
            <th scope="row" class="w-40">{{_('PAN')}}</th>
            <td>{{entity.pan}}</td>
        </tr>
        <tr *ngIf="entity.fahrzeug!==null && entity.fahrzeug!==undefined">
            <th scope="row" class="w-40">{{_('KFZ-Kennzeichen')}}</th>
            <td>{{entity.fahrzeug.kfzKennzeichen}}</td>
        </tr>
    </table>
    <text-field *ngIf="!isWifiCard()" [label]="_('PIN')" [value]="pin1" (valueChange)="setPin(1,$event)"></text-field>
    <password-field *ngIf="isWifiCard()" [label]="_('PIN')" [value]="pin1" (valueChange)="setPin(1,$event)"></password-field>

    <div class="alert alert-danger" *ngIf="message!==''">
        {{ message }}
    </div>
</div>
<div class="modal-footer">

    <button type="button" class="btn btn-secondary" (click)="cancel()">
        <i class="icofont icofont-close"></i> {{ _('Abbrechen') }}
    </button>
    <button type="button"
            class="btn btn-primary"
            (click)="save()"
            [class.disabled]="!canSave"
            [disabled]="!canSave"
    >
        <i class="icofont icofont-key"></i>
        {{ _('PIN festlegen')}}
    </button>
</div>

