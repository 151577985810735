import {L10nConfig, L10nLoader} from 'angular-l10n';

export const l10nConfig: L10nConfig = {
    format        : 'language',
    providers     : [
        {name: 'app', asset: './resources/locale', options: {version: '1.0.0'}}
    ],
    cache         : true,
    keySeparator  : '*~hh6d*gg*~',
    defaultRouting: true,

    defaultLocale: {language: 'de', currency: 'EUR'},
    schema       : [
        {locale: {language: 'de', currency: 'EUR'}, dir: 'ltr', text: 'Deutschland'}
        /*      { locale: { language: 'en', currency: 'EUR' }, dir: 'ltr', text: 'GB' },
              { locale: { language: 'pl', currency: 'EUR' }, dir: 'ltr', text: 'Polen' },
              { locale: { language: 'tr', currency: 'EUR' }, dir: 'ltr', text: 'Türkei' }*/
    ]
};

// Advanced initialization.
export function initL10n(l10nLoader: L10nLoader): Function {
    return () => l10nLoader.init();
}
