import {ChangeDetectorRef, Component, NgZone, OnInit} from '@angular/core';
import {LocalizedComponent} from '../../commons/components/localized-component';
import {ProduktGruppeList} from './common/produkt-gruppe-list';

import {ProdukteService} from '../produkte.service';

import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {NewPageCountEvent} from '../../commons/paginator/paginator.component';
import {ProduktGruppeEditorComponent} from './editor/produkt-gruppe-editor.component';
import {ProduktGruppe} from './common/produkt-gruppe';
import {DataTableFilterChanged, DataTableSortChanged} from '../../commons/data-table/data-table.component';
import {KitchenTimer} from '../../commons/kitchen-timer';
import {ResultMetaSortImpl} from '../../commons/result-meta';
import {ToastrService} from 'ngx-toastr';
import {IListResult} from '../../commons/list-result';
import {IProduktGruppe} from './common/iprodukt-gruppe';
import {RowActionParams} from '../../commons/data-table/row-action-params';

@Component({
               selector   : 'app-tab-produkt-gruppen',
               templateUrl: './tab-produkt-gruppen.component.html',
               styleUrls  : ['./tab-produkt-gruppen.component.css' ]
           })
export class TabProduktGruppenComponent extends LocalizedComponent implements OnInit {


    public produktgruppen: ProduktGruppeList = new ProduktGruppeList();
    public produktgruppenTimer: KitchenTimer;

    constructor(
                
                public produkteService: ProdukteService,
                public toastr: ToastrService,
                public zone: NgZone,
                public cd: ChangeDetectorRef,
                public modalService: NgbModal) {
        super( cd);
        this.produktgruppenTimer = new KitchenTimer();
        this.produktgruppenTimer.subscribe(
            () => {
                this.produkteService
                    .listProduktGruppen(
                        this.produktgruppen.size,
                        this.produktgruppen.calcOffset(),
                        this.produktgruppen.order,
                        this.produktgruppen.simpleFilter
                    )
                    .subscribe(
                        (l: IListResult<IProduktGruppe>) => {
                            this.produktgruppen.populateFromListResult(l);
                        },
                        () => {
                            this.toastr.error(this._('Die Liste der verfügbaren Produktgruppen konnte nicht geladen werden'));
                            this.produktgruppen.clear();
                        }
                    );
            }
        );

    }

    ngOnInit() {
        this.produktgruppenTimer.fireWhenNotBefore();
    }

    updateProduktgruppen(force = false, page?: number) {
        if ((page !== undefined) && (page != null)) {
            if (page !== this.produktgruppen.page) {
                this.produktgruppen.page = page;
                force                    = true;
            }
        }
        this.produktgruppenTimer.start(force);
    }

    updateProduktgruppenPageCount(n: NewPageCountEvent) {
        this.produktgruppen.size = n.itemsPerPage;
        this.updateProduktgruppen(true, n.currentPage);
    }


    checkEnabled(what: string, params: RowActionParams<ProduktGruppe>) {
        if (what === 'produktgruppen') {
            /*if (params.row.data.id === 'de') {
             params.row.disableAction(params.action);
             }*/
        }
    }

    sortChanged(e: DataTableSortChanged) {
        this.produktgruppen.order = [
            new ResultMetaSortImpl(e.field, e.direction)
        ];
        this.updateProduktgruppen();
    }

    filterChanged(e: DataTableFilterChanged) {
        this.zone.run(() => {
            if (e.field === 'id') {
                if (isNaN(parseInt('' + e.value, 10))) {
                    this.produktgruppen.simpleFilter[ e.field ] = null;
                } else {
                    this.produktgruppen.simpleFilter[ e.field ] = e.value;
                }
            } else {
                this.produktgruppen.simpleFilter[ e.field ] = e.value;
            }
            this.updateProduktgruppen();
        });
    }

    createProduktgruppe() {

        ProduktGruppeEditorComponent.open(this.modalService, null)
                                    .then(
                                        e => {
                                            this.updateProduktgruppen(true);
                                        },
                                        () => {
                                            this.updateProduktgruppen(true);
                                        }
                                    );
    }

    editProduktgruppe(e: RowActionParams<ProduktGruppe>) {
        this.zone.runGuarded(() => {

            ProduktGruppeEditorComponent.open(this.modalService, e.row.data)
                                        .then(
                                            () => {
                                                this.updateProduktgruppen(true);
                                            },
                                            () => {
                                                this.updateProduktgruppen(true);
                                            }
                                        );

        });
    }


}
