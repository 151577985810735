import {ChangeDetectorRef, Component, NgZone, OnInit} from '@angular/core';
import {LocalizedComponent} from '../../commons/components/localized-component';
import {BenutzerList} from '../../auth/common/benutzer-list';


import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {LoginService} from '../../auth/services/login.service';
import {NewPageCountEvent} from '../../commons/paginator/paginator.component';
import {BenutzerEditorComponent} from '../editor/benutzer-editor.component';
import {Benutzer} from '../../auth/common/benutzer';
import {ConfirmDialogComponent} from '../../commons/confirm-dialog/confirm-dialog.component';
import {DataTableFilterChanged, DataTableSortChanged} from '../../commons/data-table/data-table.component';
import {KitchenTimer} from '../../commons/kitchen-timer';
import {ResultMetaSortImpl} from '../../commons/result-meta';
import {KundeCellRendererComponent} from '../../customers/kunden/kunde-cell-renderer.component';
import {IBenutzer} from '../../auth/common/ibenutzer';
import {IListResult} from '../../commons/list-result';
import {ToastrService} from 'ngx-toastr';
import {SysInfoService} from '../../commons/sysinfo/services/sys-info.service';
import {PermissionsRendererComponent} from '../../user-controls/permissions-renderer/permissions-renderer.component';
import {RowActionParams} from '../../commons/data-table/row-action-params';

@Component({
    selector: 'app-benutzer-verwaltung',
    templateUrl: './benutzer-verwaltung.component.html',
    styleUrls: ['./benutzer-verwaltung.component.scss']
})
export class BenutzerVerwaltungComponent extends LocalizedComponent implements OnInit {

    kundeR = KundeCellRendererComponent;
    permissionsRenderer = PermissionsRendererComponent;

    benutzer: BenutzerList = new BenutzerList();
    benutzerTimer: KitchenTimer;

    constructor(
                
                public loginService: LoginService,
                public toastr: ToastrService,
                public zone: NgZone,
                public sysInfoService: SysInfoService,
                public modalService: NgbModal,
                public cd: ChangeDetectorRef
    ) {
        super( cd);
this.benutzer.size = this.loginService.getPageSize('benutzer');
        this.benutzerTimer = new KitchenTimer();
        this.benutzerTimer
            .subscribe(
                () => {
                    this.loginService
                        .list(
                            this.benutzer.size,
                            this.benutzer.calcOffset(),
                            this.benutzer.order,
                            this.benutzer.simpleFilter,
                            this.benutzer.getQuery()
                        )
                        .subscribe(
                            (l: IListResult<IBenutzer>) => {
                                this.benutzer.populateFromListResult(l);
                            },
                            (e) => {
                                this.toastr.error(this._('Die Liste der Benutzer konnte nicht geladen werden.'));
                                this.benutzer.clear();
                            }
                        );
                }
            );
    }

    ngOnInit() {
        this.updateBenutzer();
        this.sysInfoService.updateDocumentTitle(this._('Benutzerverwaltung'));
    }

    updateBenutzer(force = false, page?: number) {
        if ((page !== undefined) && (page !== null)) {
            if (page !== this.benutzer.page) {
                this.benutzer.page = page;
                force = true;
            }
        }
        this.benutzerTimer.start(force);
    }

    /**
     * ändert die Anzahl der Datensätze/Seite und lädt die Liste neu
     * @param n
     */
    updateBenutzerPageCount(n: NewPageCountEvent) {
        if (this.benutzer.size !== n.itemsPerPage) {
            this.benutzer.size = n.itemsPerPage;
            this.loginService.setPageSize('benutzer', n.itemsPerPage);
        }
        this.updateBenutzer(true, n.currentPage);
    }

    sortChanged(e: DataTableSortChanged) {
        this.benutzer.order = [
            new ResultMetaSortImpl(e.field, e.direction)
        ];
        this.updateBenutzer();
    }

    filterChanged(e: DataTableFilterChanged) {
        this.zone.run(() => {
            this.benutzer.simpleFilter[e.field] = e.value;
            this.updateBenutzer();
        });
    }

    newBenutzer() {
        BenutzerEditorComponent
            .open(this.modalService, BenutzerEditorComponent, null, 'lg')
            .then(
                () => {
                    this.updateBenutzer();
                },
                () => {
                    this.updateBenutzer();
                }
            );
    }

    editBenutzer(params: RowActionParams<Benutzer>) {
        this.loginService
            .getUser(params.row.data.username)
            .subscribe(
                (b: IBenutzer) => {
                    if (b != null) {
                        BenutzerEditorComponent
                            .open(this.modalService, BenutzerEditorComponent, b, 'lg')
                            .then(
                                () => {
                                    this.updateBenutzer();
                                },
                                () => {
                                    this.updateBenutzer();
                                }
                            );

                    }
                }
            );
    }


    checkUser(what: string, who: RowActionParams<Benutzer>): void {
        if (this.loginService.currentUser.id === who.row.data.id) {
            who.row.disableAction(who.action);
        } else {
            if (what === 'lock') {
                if (who.row.data.accountLocked) {
                    who.row.disableAction(who.action);
                    who.row.hideAction(who.action);
                }
            }
            if (what === 'unlock') {
                if (!who.row.data.accountLocked) {
                    who.row.disableAction(who.action);
                    who.row.hideAction(who.action);
                }
            }
        }
    }

    lockBenutzer(params: RowActionParams<Benutzer>) {
        ConfirmDialogComponent
            .display(
                this.modalService,
                this._('Benutzer sperren'),
                this._('Soll der Benutzer {{username}} wirklich gesperrt werden?', {username: params.row.data.username}),
                this._('Benutzer sperren'),
                'icofont icofont-lock'
            ).result.then(() => {
            this.loginService.lockUser(params.row.data)
                .subscribe(
                    (r) => {
                        if (r.success) {
                            this.toastr.success(this._('Der Benutzer {{username}} wurde gesperrt', {username: params.row.data.username}));
                        } else {
                            this.toastr.error(this._('Fehler beim Sperren des Benutzers'));
                        }
                        this.updateBenutzer();
                    },
                    () => {
                        this.toastr.error(this._('Fehler beim Sperren des Benutzers'));
                        this.updateBenutzer();
                    }
                );
        }, () => {
            this.updateBenutzer();
        });

    }

    unlockBenutzer(params: RowActionParams<Benutzer>) {
        ConfirmDialogComponent
            .display(
                this.modalService,
                this._('Benutzer entsperren'),
                this._('Soll der Benutzer {{username}} wirklich entsperrt werden?', {username: params.row.data.username}),
                this._('Benutzer entsperren'),
                'icofont icofont-unlock'
            ).result.then(() => {
            this.loginService
                .unlockUser(params.row.data)
                .subscribe(
                    (r) => {
                        if (r.success) {
                            this.toastr.success(this._('Der Benutzer {{username}} wurde entsperrt', {username: params.row.data.username}));
                        } else {
                            this.toastr.error(this._('Fehler beim Entsperren des Benutzers'));
                        }
                        this.updateBenutzer();
                    },
                    () => {
                        this.toastr.error(this._('Fehler beim Entsperren des Benutzers'));
                        this.updateBenutzer();
                    }
                );
        }, () => {
            this.updateBenutzer();
        });

    }


    deleteBenutzer(params: RowActionParams<Benutzer>) {
        ConfirmDialogComponent
            .display(
                this.modalService,
                this._('Benutzer löschen'),
                this._('Soll der Benutzer {{username}} wirklich unwiderruflich gelöscht werden?', {username: params.row.data.username}),
                this._('Benutzer löschen'),
                'icofont icofont-ui-delete'
            ).result.then(() => {
            this.loginService
                .deleteUser(params.row.data)
                .subscribe(
                    (r) => {
                        if (r.success) {
                            this.toastr.success(this._('Der Benutzer {{username}} wurde gelöscht', {username: params.row.data.username}));
                        } else {
                            this.toastr.error(this._('Fehler beim Löschen des Benutzers'));
                        }
                        this.updateBenutzer();
                    },
                    () => {
                        this.toastr.error(this._('Fehler beim Löschen des Benutzers'));
                        this.updateBenutzer();
                    }
                );
        }, () => {
            this.updateBenutzer();
        });

    }

    reindexUsers() {
        this.loginService
            .reindexUsers()
            .subscribe(
                () => {
                    this.toastr.success(this._('Der Indizierungsvorgang wurde angestoßen.'));
                },
                () => {
                    this.toastr.error(this._('Die Indizierungsvorgang konnte nicht angestoßen werden.'));
                }
            );
    }

    benutzerQueryChange(value: string = '') {
        console.log(value);
        if (value !== this.benutzer.searchString) {
            this.benutzer.searchString = value;
            this.updateBenutzer(false, 1);
        }
    }
}
