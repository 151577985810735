<div class="card" *ngIf="_fahrzeug!==undefined && _fahrzeug!==null">
    <div class="card-body">
        <h4 class="card-title">{{fahrzeugLabel}}</h4>
    </div>
    <div class="card-text">
        <table class="table">
            <tr>
                <th scope="row">{{ _('Nummer') }}</th>
                <td>{{ _fahrzeug.nummer }}</td>
                <th scope="row">{{ _('Bezeichnung') }}</th>
                <td>{{ _fahrzeug.bezeichnung }}</td>
            </tr>
            <tr>
                <th scope="row">{{ _('KFZ-Kennzeichen') }}</th>
                <td>{{ _fahrzeug.kfzKennzeichen }}</td>
                <th scope="row">{{ _('Kostenstelle') }}</th>
                <td>{{ _fahrzeug.kostenstelle }}</td>
            </tr>
            <tr>
                <th scope="row">{{ _('Kraftstoff') }}</th>
                <td>
                    <app-produkt-quick-info
                            *ngIf="_fahrzeug.kraftstoff!==null && _fahrzeug.kraftstoff!==undefined"
                            [produkt]="_fahrzeug.kraftstoff" [showLabel]="true"></app-produkt-quick-info>
                    <span
                            *ngIf="_fahrzeug.kraftstoff==null || _fahrzeug.kraftstoff==undefined"
                    >{{_('kein Kraftstoff festgelegt')}}</span>

                </td>
                <th scope="row">{{ _('Tankvolumen') }}</th>
                <td>
                    <volumen-display
                            *ngIf="_fahrzeug.tankVolumen!==undefined && _fahrzeug.tankVolumen!==null"
                            [value]="_fahrzeug.tankVolumen"
                            [scale]="1"
                            unit="l"
                    ></volumen-display>
                </td>
            </tr>
            <tr>
                <th scope="row">{{ _('kmStand') }}</th>
                <td>{{ _fahrzeug.kmStand }}</td>
                <th scope="row">{{ _('TS-ID') }}</th>
                <td>{{ _fahrzeug.tankserverID }}</td>
            </tr>
            <tr>
                <th scope="row">{{ _('im System angelegt') }}</th>
                <td>{{fDate( _fahrzeug.dateCreated ,'medium' )}}</td>
                <th scope="row">{{ _('zuletzt bearbeitet') }}</th>
                <td>{{fDate( _fahrzeug.lastEdited ,'medium' )}}</td>
            </tr>
            <tr>
                <th scope="row">{{ _('Kunde') }}</th>
                <td>
                    <app-kunde-quick-info
                            *ngIf="_fahrzeug.kunde !==undefined && _fahrzeug.kunde!==null"
                            [kunde]="_fahrzeug.kunde"></app-kunde-quick-info>
                    <div *ngIf="_fahrzeug.kunde ==undefined || _fahrzeug.kunde==null">
                        {{ _('keinem Kunden zugeordnet') }}
                    </div>
                </td>
                <th scope="row">{{ _('Ausweis(e)') }}</th>
                <td>
                    <app-ausweis-quick-info
                            *ngFor="let aw of _fahrzeug.ausweise"
                            [ausweis]="aw"
                            [displayLink]="false"
                    >

                    </app-ausweis-quick-info>
                    <div *ngIf="_fahrzeug.ausweise ==undefined || _fahrzeug.ausweise==null ||_fahrzeug.ausweise.length<1">
                        {{ _('kein Ausweis zugeordnet') }}
                    </div>
                </td>
            </tr>
        </table>
    </div>
</div>