<div class="d-flex justify-content-between">
    <div class="font-weight-bold text-nowrap p-2 w-15">
        {{_('Limits aktivieren:')}}
    </div>
    <div class="text-nowrap">
        <div class="float-left w-20">
            <boolean-field
                    [label]="(kunde!==null)?_('Prepaid-Kunde'):_('Total-Limit')"
                    [value]="_value.isPrepaid"
                    (valueChange)="setPrepaid($event)"
                    [labelwidth]="0"
                    [disabled]="disabled"
            ></boolean-field>
        </div>
        <div class="text-nowrap p-2">
            {{ (kunde!==null)?_('Prepaid-Kunde'):_('Total-Limit') }}
        </div>
    </div>
    <div class="text-nowrap">
        <div class="float-left w-20">
            <boolean-field
                    [label]="_('Tageslimit')"
                    [value]="showDayly"
                    (valueChange)="setDayly($event)"
                    [labelwidth]="0"
                    [disabled]="disabled"
            ></boolean-field>
        </div>
        <div class="text-nowrap p-2">
            {{_('Tageslimit')}}
        </div>
    </div>
    <div class="text-nowrap">
        <div class="float-left w-20">
            <boolean-field
                    [label]="_('Wochenlimit')"
                    [value]="showWeekly"
                    (valueChange)="setWeekly($event)"
                    [labelwidth]="0"
                    [disabled]="disabled"
            ></boolean-field>
        </div>
        <div class="text-nowrap p-2">
            {{_('Wochenlimit')}}
        </div>
    </div>
    <div class="text-nowrap">
        <div class="float-left w-20">
            <boolean-field
                    [label]="_('Halbmonatslimit')"
                    [value]="showHalfMonthly"
                    (valueChange)="setHalfMonthly($event)"
                    [labelwidth]="0"
                    [disabled]="disabled"
            ></boolean-field>
        </div>
        <div class="text-nowrap p-2">
            {{_('Halbmonatslimit')}}
        </div>
    </div>
    <div class="text-nowrap" *ngIf="!_value.isPrepaid">
        <div class="float-left w-20">
            <boolean-field
                    [label]="_('Monatslimit')"
                    [value]="showMonthly"
                    (valueChange)="setMonthly($event)"
                    [labelwidth]="0"
                    [disabled]="disabled"
            ></boolean-field>
        </div>
        <div class="text-nowrap p-2">
            {{_('Monatslimit')}}
        </div>
    </div>
    <div>&nbsp;</div>
</div>
<div class="d-flex justify-content-between">
    <div class="font-weight-bold text-nowrap p-2 w-15">
        {{_('Typ aktivieren:')}}
    </div>
    <div class="text-nowrap">
        <div class="float-left w-20">
            <boolean-field
                    [label]="_('Transaktionslimit')"
                    [value]="showTransaktionen"
                    (valueChange)="setTransaktionen($event)"
                    [labelwidth]="0"
                    [disabled]="disabled"
            ></boolean-field>
        </div>
        <div class="text-nowrap p-2">
            {{_('Transaktionslimit')}}
        </div>
    </div>
    <div class="text-nowrap" *hasRole="['ROLE_ADMIN','ROLE_PROJECT_ADMIN','ROLE_BILLING']">
        <div class="float-left w-20">
            <boolean-field
                    [label]="_('Mengenlimit')"
                    [value]="showMenge"
                    (valueChange)="setMenge($event)"
                    [labelwidth]="0"
                    [disabled]="disabled"
            ></boolean-field>
        </div>
        <div class="text-nowrap p-2">
            {{_('Mengenlimit')}}
        </div>
    </div>
    <div class="text-nowrap">
        <div class="float-left w-20">
            <boolean-field
                    [label]="_('Betragslimit')"
                    [value]="showBetrag"
                    (valueChange)="setBetrag($event)"
                    [labelwidth]="0"
                    [disabled]="disabled"
            ></boolean-field>
        </div>
        <div class="text-nowrap p-2">
            {{_('Betragslimit')}}
        </div>
    </div>
    <div>&nbsp;</div>
</div>

<div class="alert alert-info"
     *ngIf="(!_value.isPrepaid && !showDayly && !showWeekly && !showHalfMonthly && !showMonthly)||(!showMenge && !showBetrag && !showTransaktionen)">
    {{ _('Es sind keine Limitierungen eingestellt')}}
</div>

<div *ngIf="(_value.isPrepaid || showDayly || showWeekly || showHalfMonthly || showMonthly) && (showMenge || showBetrag || showTransaktionen)">

    <div class="mb-5 mt-5 pt-2 pb-2">
        <div class="table-responsive"
             [class.overflow-scroll-x]="_value.isPrepaid"

        >
            <table class="table table-bordered">
                <thead>
                <tr>
                    <th rowspan="2" class="sp1" style="text-align: left;" valign="middle">

                        <button type="button" class="btn btn-sm btn-secondary"
                                (click)="refreshSaldo()"
                                title="{{_('aktuelle Stände vom Autorisierungsserver abrufen')}}"
                                *ngIf="((kunde!==null && kunde!==undefined && kunde.id>0)||(ausweis!==null && ausweis!==undefined && ausweis.id>0)) && refreshable"
                        >
                            <i class="icofont icofont-refresh"></i> {{_('Salden abrufen')}}
                        </button>

                        <button type="button" class="btn btn-sm btn-secondary"
                                (click)="resetSaldo()" title="{{_('alle Salden resetten')}}"
                                *ngIf="resetAvailable()"
                        >
                            <i class="icofont icofont-recycle"></i> {{_('Salden resetten')}}
                        </button>


                    </th>
                    <th colspan="2" class="sp2 text-center" *ngIf="_value.isPrepaid">{{_((ausweis!==null &&
                        ausweis!==undefined
                        &&
                        ausweis.id>0)?'Total':'Prepaid')}}
                    </th>
                    <th colspan="2" class="sp2 text-center" *ngIf="showDayly">{{_('pro Tag')}}</th>
                    <th colspan="2" class="sp2 text-center" *ngIf="showWeekly">{{_('pro Woche')}}</th>
                    <th colspan="2" class="sp2 text-center" *ngIf="showHalfMonthly">{{_('pro Monatshälfte')}}</th>
                    <th colspan="2" class="sp2 text-center" *ngIf="showMonthly && !_value.isPrepaid">{{_('pro
                        Monat')}}
                    </th>
                </tr>
                <tr>

                    <th class="text-center " *ngIf="_value.isPrepaid">{{_('max.')}}<sup>*</sup></th>
                    <th class="text-center " *ngIf="_value.isPrepaid">
                        <div class="btn-group  btn-group-sm" role="group">
                            <button id="btnGroupDrop4"
                                    type="button"
                                    class="btn-sm btn btn-secondary dropdown-toggle"
                                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <span *ngIf="displayMode==='ist'">{{_('genutzt')}}</span>
                                <span *ngIf="displayMode==='can'">{{_('übrig')}}</span>
                            </button>
                            <div class="dropdown-menu" aria-labelledby="btnGroupDrop4">
                                <button type="button" class="dropdown-item" (click)="displayMode='ist'">{{_('genutzt')}}
                                </button>
                                <button type="button" class="dropdown-item" (click)="displayMode='can'">{{_('übrig')}}
                                </button>
                            </div>
                        </div>
                    </th>


                    <th class="text-center " *ngIf="showDayly">{{_('max.')}}<sup>*</sup></th>
                    <th class="text-center " *ngIf="showDayly">

                        <div class="btn-group  btn-group-sm" role="group">
                            <button id="btnGroupDrop1"
                                    type="button"
                                    class="btn-sm btn btn-secondary dropdown-toggle"
                                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <span *ngIf="displayMode==='ist'">{{_('genutzt')}}</span>
                                <span *ngIf="displayMode==='can'">{{_('übrig')}}</span>
                            </button>
                            <div class="dropdown-menu" aria-labelledby="btnGroupDrop1">
                                <button type="button" class="dropdown-item" (click)="displayMode='ist'">{{_('genutzt')}}
                                </button>
                                <button type="button" class="dropdown-item" (click)="displayMode='can'">{{_('übrig')}}
                                </button>
                            </div>
                        </div>
                    </th>

                    <th class="text-center" *ngIf="showWeekly">{{_('max.')}}<sup>*</sup></th>
                    <th class="text-center" *ngIf="showWeekly">
                        <div class="btn-group  btn-group-sm" role="group">
                            <button id="btnGroupDrop3"
                                    type="button"
                                    class="btn-sm btn btn-secondary dropdown-toggle"
                                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <span *ngIf="displayMode==='ist'">{{_('genutzt')}}</span>
                                <span *ngIf="displayMode==='can'">{{_('übrig')}}</span>
                            </button>
                            <div class="dropdown-menu" aria-labelledby="btnGroupDrop3">
                                <button type="button" class="dropdown-item" (click)="displayMode='ist'">{{_('genutzt')}}
                                </button>
                                <button type="button" class="dropdown-item" (click)="displayMode='can'">{{_('übrig')}}
                                </button>
                            </div>
                        </div>
                    </th>

                    <th class="text-center" *ngIf="showHalfMonthly">{{_('max.')}}<sup>*</sup></th>
                    <th class="text-center" *ngIf="showHalfMonthly">
                        <div class="btn-group  btn-group-sm" role="group">
                            <button id="btnGroupDrop5"
                                    type="button"
                                    class="btn-sm btn btn-secondary dropdown-toggle"
                                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <span *ngIf="displayMode==='ist'">{{_('genutzt')}}</span>
                                <span *ngIf="displayMode==='can'">{{_('übrig')}}</span>
                            </button>
                            <div class="dropdown-menu" aria-labelledby="btnGroupDrop5">
                                <button type="button" class="dropdown-item" (click)="displayMode='ist'">{{_('genutzt')}}
                                </button>
                                <button type="button" class="dropdown-item" (click)="displayMode='can'">{{_('übrig')}}
                                </button>
                            </div>
                        </div>
                    </th>


                    <th class="text-center" *ngIf="showMonthly && !_value.isPrepaid">{{_('max.')}}<sup>*</sup></th>
                    <th class="text-center" *ngIf="showMonthly && !_value.isPrepaid">
                        <div class="btn-group  btn-group-sm" role="group">
                            <button id="btnGroupDrop2"
                                    type="button"
                                    class="btn-sm btn btn-secondary dropdown-toggle"
                                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <span *ngIf="displayMode==='ist'">{{_('genutzt')}}</span>
                                <span *ngIf="displayMode==='can'">{{_('übrig')}}</span>
                            </button>
                            <div class="dropdown-menu" aria-labelledby="btnGroupDrop2">
                                <button type="button" class="dropdown-item" (click)="displayMode='ist'">{{_('genutzt')}}
                                </button>
                                <button type="button" class="dropdown-item" (click)="displayMode='can'">{{_('übrig')}}
                                </button>
                            </div>
                        </div>
                    </th>


                </tr>
                </thead>
                <tbody>
                <tr *ngIf="showTransaktionen">

                    <th scope="row">{{_('Anzahl der Transaktionen')}}</th>

                    <td *ngIf="disabled && _value.isPrepaid"> {{ _value.transaktionenPrepaid }}</td>
                    <td *ngIf="!disabled && _value.isPrepaid" class="maxw150">
                        <num-field [labelwidth]="0"
                                   [value]="_value.transaktionenPrepaid"
                                   (valueChange)="setValue('transaktionenPrepaid',$event)"
                        ></num-field>
                    </td>
                    <td class=" text-right"
                        *ngIf="_value.isPrepaid"
                        [class.table-success]="isGood('transaktionenPrepaid')"
                        [class.table-info]="isWarn('transaktionenPrepaid')"
                        [class.table-warning]="isSemiBad('transaktionenPrepaid')"
                        [class.table-danger]="isBad('transaktionenPrepaid')"
                        [class.text-muted]="isTooOld('transaktionenPrepaid')"

                    >
                        <span *ngIf="_value.transaktionenPrepaid>=0 && displayMode=='ist'">{{fNum( _value.saldoTransaktionenPrepaid ,'1.0-0' )}}</span>
                        <span *ngIf="_value.transaktionenPrepaid>0 && displayMode=='can'">{{fNum( _value.transaktionenPrepaid-_value.saldoTransaktionenPrepaid  ,'1.0-0' )}}</span>
                    </td>


                    <td *ngIf="disabled && showDayly">
                        {{fNum( _value.transaktionenTag ,'1.0-0' )}}
                    </td>
                    <td *ngIf="!disabled && showDayly" class="">
                        <num-field [labelwidth]="0"
                                   [value]="_value.transaktionenTag"
                                   (valueChange)="setValue('transaktionenTag',$event)"
                        ></num-field>
                    </td>
                    <td
                            class=" text-right"
                            [class.table-success]="isGood('transaktionenTag')"
                            [class.table-info]="isWarn('transaktionenTag')"
                            [class.table-warning]="isSemiBad('transaktionenTag')"
                            [class.table-danger]="isBad('transaktionenTag')"
                            [class.text-muted]="isTooOld('transaktionenTag')"
                            *ngIf="showDayly"
                    >
            <span *ngIf="_value.transaktionenTag<=0 && displayMode=='can'"><i
                    class="icofont icofont-infinite"></i></span>
                        <span *ngIf="_value.transaktionenTag>=0 && displayMode=='ist'">{{fNum( _value.saldoTransaktionenTag ,'1.0-0' )}}</span>
                        <span *ngIf="_value.transaktionenTag>0 && displayMode=='can'">{{fNum( _value.transaktionenTag-_value.saldoTransaktionenTag  ,'1.0-0' )}}</span>

                    </td>


                    <td *ngIf="disabled && showWeekly"> {{fNum( _value.transaktionenWoche ,'1.0-0')}}
                    </td>
                    <td *ngIf="!disabled && showWeekly" class="">
                        <num-field [labelwidth]="0"
                                   [value]="_value.transaktionenWoche"
                                   (valueChange)="setValue('transaktionenWoche',$event)"
                        ></num-field>
                    </td>
                    <td class=" text-right"
                        [class.table-success]="isGood('transaktionenWoche')"
                        [class.table-info]="isWarn('transaktionenWoche')"
                        [class.table-warning]="isSemiBad('transaktionenWoche')"
                        [class.table-danger]="isBad('transaktionenWoche')"
                        [class.text-muted]="isTooOld('transaktionenWoche')"
                        *ngIf="showWeekly"
                    >
            <span *ngIf="_value.transaktionenWoche<=0 && displayMode=='can'"><i
                    class="icofont icofont-infinite"></i></span>
                        <span *ngIf="_value.transaktionenWoche>=0 && displayMode=='ist'">{{fNum( _value.saldoTransaktionenWoche ,'1.0-0' )}}</span>
                        <span *ngIf="_value.transaktionenWoche>0 && displayMode=='can'">{{fNum( _value.transaktionenWoche-_value.saldoTransaktionenWoche  ,'1.0-0' )}}</span>


                    </td>


                    <td *ngIf="disabled && showHalfMonthly"> {{ _value.transaktionenHalbMonat }}</td>
                    <td *ngIf="!disabled && showHalfMonthly" class="">
                        <num-field [labelwidth]="0"
                                   [value]="_value.transaktionenHalbMonat"
                                   (valueChange)="setValue('transaktionenHalbMonat',$event)"
                        ></num-field>
                    </td>
                    <td class=" text-right"
                        [class.table-success]="isGood('transaktionenHalbMonat')"
                        [class.table-info]="isWarn('transaktionenHalbMonat')"
                        [class.table-warning]="isSemiBad('transaktionenHalbMonat')"
                        [class.table-danger]="isBad('transaktionenHalbMonat')"
                        [class.text-muted]="isTooOld('transaktionenHalbMonat')"
                        *ngIf="showHalfMonthly"
                    >
            <span *ngIf="_value.transaktionenHalbMonat<=0 && displayMode=='can'"><i
                    class="icofont icofont-infinite"></i></span>
                        <span *ngIf="_value.transaktionenHalbMonat>=0 && displayMode=='ist'">{{fNum( _value.saldoTransaktionenHalbMonat ,'1.0-0' )}}</span>
                        <span *ngIf="_value.transaktionenHalbMonat>0 && displayMode=='can'">{{fNum( _value.transaktionenHalbMonat-_value.saldoTransaktionenHalbMonat  ,'1.0-0' )}}</span>

                    </td>


                    <td *ngIf="disabled && !_value.isPrepaid && showMonthly"> {{ _value.transaktionenMonat }}</td>
                    <td *ngIf="!disabled && !_value.isPrepaid && showMonthly" class="">
                        <num-field [labelwidth]="0"
                                   [value]="_value.transaktionenMonat"
                                   (valueChange)="setValue('transaktionenMonat',$event)"
                        ></num-field>
                    </td>
                    <td class=" text-right"
                        [class.table-success]="isGood('transaktionenMonat')"
                        [class.table-info]="isWarn('transaktionenMonat')"
                        [class.table-warning]="isSemiBad('transaktionenMonat')"
                        [class.table-danger]="isBad('transaktionenMonat')"
                        [class.text-muted]="isTooOld('transaktionenMonat')"
                        *ngIf="!_value.isPrepaid && showMonthly"
                    >
            <span *ngIf="_value.transaktionenMonat<=0 && displayMode=='can'"><i
                    class="icofont icofont-infinite"></i></span>
                        <span *ngIf="_value.transaktionenMonat>=0 && displayMode=='ist'">{{fNum( _value.saldoTransaktionenMonat ,'1.0-0' )}}</span>
                        <span *ngIf="_value.transaktionenMonat>0 && displayMode=='can'">{{fNum( _value.transaktionenMonat-_value.saldoTransaktionenMonat  ,'1.0-0' )}}</span>

                    </td>


                </tr>


                <tr *ngIf="showMenge">
                    <th scope="row">{{_('verfügbare Menge in Liter')}}<sup>**</sup></th>

                    <td *ngIf="disabled && _value.isPrepaid">
                        <volumen-display [value]="_value.mengePrepaid" [scale]="3" unit="l"></volumen-display>
                    </td>
                    <td *ngIf="!disabled && _value.isPrepaid" class="">
                        <num-field [labelwidth]="0"
                                   [value]="_value.mengePrepaid"
                                   (valueChange)="setValue('mengePrepaid',$event)"
                        ></num-field>
                    </td>
                    <td class=" text-right"
                        *ngIf="_value.isPrepaid"
                        [class.table-success]="isGood('mengePrepaid')"
                        [class.table-info]="isWarn('mengePrepaid')"
                        [class.table-warning]="isSemiBad('mengePrepaid')"
                        [class.table-danger]="isBad('mengePrepaid')"
                        [class.text-muted]="isTooOld('mengePrepaid')"
                    >

                        <volumen-display *ngIf="_value.mengePrepaid>=0 && displayMode=='ist'"
                                         [value]="_value.saldoMengePrepaid"
                                         [scale]="3" unit="l"></volumen-display>
                        <volumen-display *ngIf="_value.mengePrepaid >0 && displayMode=='can'"
                                         [value]="_value.mengePrepaid-_value.saldoMengePrepaid" [scale]="3"
                                         unit="l"></volumen-display>

                    </td>


                    <td *ngIf="disabled && showDayly">
                        <volumen-display [value]="_value.mengeTag" [scale]="3" unit="l"></volumen-display>
                    </td>
                    <td *ngIf="!disabled && showDayly" class="">
                        <num-field [labelwidth]="0"
                                   [value]="_value.mengeTag"
                                   (valueChange)="setValue('mengeTag',$event)"
                        ></num-field>
                    </td>
                    <td
                            class=" text-right"
                            [class.table-success]="isGood('mengeTag')"
                            [class.table-info]="isWarn('mengeTag')"
                            [class.table-warning]="isSemiBad('mengeTag')"
                            [class.table-danger]="isBad('mengeTag')"
                            [class.text-muted]="isTooOld('mengeTag')"
                            *ngIf="showDayly"
                    >

                    <span *ngIf="_value.mengeTag<=0 && displayMode=='can'"><i
                            class="icofont icofont-infinite"></i> l</span>
                        <volumen-display *ngIf="_value.mengeTag>=0 && displayMode=='ist'" [value]="_value.saldoMengeTag"
                                         [scale]="3"
                                         unit="l"></volumen-display>
                        <volumen-display *ngIf="_value.mengeTag >0 && displayMode=='can'"
                                         [value]="_value.mengeTag-_value.saldoMengeTag" [scale]="3"
                                         unit="l"></volumen-display>

                    </td>


                    <td *ngIf="disabled && showWeekly">
                        <volumen-display [value]="_value.mengeWoche" [scale]="3" unit="l"></volumen-display>
                    </td>
                    <td *ngIf="!disabled && showWeekly" class="">
                        <num-field [labelwidth]="0"
                                   [value]="_value.mengeWoche"
                                   (valueChange)="setValue('mengeWoche',$event)"
                        ></num-field>
                    </td>
                    <td class=" text-right"
                        [class.table-success]="isGood('mengeWoche')"
                        [class.table-info]="isWarn('mengeWoche')"
                        [class.table-warning]="isSemiBad('mengeWoche')"
                        [class.table-danger]="isBad('mengeWoche')"
                        [class.text-muted]="isTooOld('mengeWoche')"
                        *ngIf="showWeekly"
                    >

                <span *ngIf="_value.mengeWoche<=0 && displayMode=='can'"><i
                        class="icofont icofont-infinite"></i> l</span>
                        <volumen-display *ngIf="_value.mengeWoche>=0 && displayMode=='ist'"
                                         [value]="_value.saldoMengeWoche"
                                         [scale]="3" unit="l"></volumen-display>
                        <volumen-display *ngIf="_value.mengeWoche >0 && displayMode=='can'"
                                         [value]="_value.mengeWoche-_value.saldoMengeWoche" [scale]="3"
                                         unit="l"></volumen-display>

                    </td>


                    <td *ngIf="disabled && showHalfMonthly">
                        <volumen-display [value]="_value.mengeHalbMonat" [scale]="3" unit="l"></volumen-display>
                    </td>
                    <td *ngIf="!disabled && showHalfMonthly" class="">
                        <num-field [labelwidth]="0"
                                   [value]="_value.mengeHalbMonat"
                                   (valueChange)="setValue('mengeHalbMonat',$event)"
                        ></num-field>
                    </td>
                    <td class=" text-right"
                        [class.table-success]="isGood('mengeHalbMonat')"
                        [class.table-info]="isWarn('mengeHalbMonat')"
                        [class.table-warning]="isSemiBad('mengeHalbMonat')"
                        [class.table-danger]="isBad('mengeHalbMonat')"
                        [class.text-muted]="isTooOld('mengeHalbMonat')"
                        *ngIf="showHalfMonthly"
                    >

                <span *ngIf="_value.mengeHalbMonat<=0 && displayMode=='can'"><i
                        class="icofont icofont-infinite"></i> l</span>
                        <volumen-display *ngIf="_value.mengeHalbMonat>=0 && displayMode=='ist'"
                                         [value]="_value.saldoMengeHalbMonat"
                                         [scale]="3" unit="l"></volumen-display>
                        <volumen-display *ngIf="_value.mengeHalbMonat >0 && displayMode=='can'"
                                         [value]="_value.mengeHalbMonat-_value.saldoMengeHalbMonat" [scale]="3"
                                         unit="l"></volumen-display>

                    </td>


                    <td *ngIf="disabled && !_value.isPrepaid && showMonthly">
                        <volumen-display [value]="_value.mengeMonat" [scale]="3" unit="l"></volumen-display>
                    </td>
                    <td *ngIf="!disabled && !_value.isPrepaid && showMonthly" class="">
                        <num-field [labelwidth]="0"
                                   [value]="_value.mengeMonat"
                                   (valueChange)="setValue('mengeMonat',$event)"
                        ></num-field>
                    </td>
                    <td class=" text-right"
                        [class.table-success]="isGood('mengeMonat')"
                        [class.table-info]="isWarn('mengeMonat')"
                        [class.table-warning]="isSemiBad('mengeMonat')"
                        [class.table-danger]="isBad('mengeMonat')"
                        [class.text-muted]="isTooOld('mengeMonat')"
                        *ngIf="!_value.isPrepaid && showMonthly"
                    >

                <span *ngIf="_value.mengeMonat<=0 && displayMode=='can'"><i
                        class="icofont icofont-infinite"></i> l</span>
                        <volumen-display *ngIf="_value.mengeMonat>=0 && displayMode=='ist'"
                                         [value]="_value.saldoMengeMonat"
                                         [scale]="3" unit="l"></volumen-display>
                        <volumen-display *ngIf="_value.mengeMonat >0 && displayMode=='can'"
                                         [value]="_value.mengeMonat-_value.saldoMengeMonat" [scale]="3"
                                         unit="l"></volumen-display>

                    </td>


                </tr>


                <tr *ngIf="showBetrag">
                    <th scope="row">{{_('verfügbarer Betrag in EURO')}}</th>


                    <td *ngIf="disabled && _value.isPrepaid"> {{fCurrency( _value.betragPrepaid ,'EUR','symbol','1.2-2' )}}
                    </td>
                    <td *ngIf="!disabled && _value.isPrepaid" class="">
                        <num-field [labelwidth]="0"
                                   [value]="_value.betragPrepaid"
                                   (valueChange)="setValue('betragPrepaid',$event)"
                        ></num-field>
                    </td>
                    <td class=" text-right"
                        *ngIf="_value.isPrepaid"
                        [class.table-success]="isGood('betragPrepaid')"
                        [class.table-info]="isWarn('betragPrepaid')"
                        [class.table-warning]="isSemiBad('betragPrepaid')"
                        [class.table-danger]="isBad('betragPrepaid')"
                        [class.text-muted]="isTooOld('betragPrepaid')"
                    >
                        <span *ngIf="_value.betragPrepaid>=0 && displayMode=='ist'">{{fCurrency( _value.saldoBetragPrepaid ,'EUR','symbol','1.2-2' )}}</span>
                        <span *ngIf="_value.betragPrepaid>0 && displayMode=='can'">{{fCurrency( _value.betragPrepaid-_value.saldoBetragPrepaid  ,'EUR','symbol','1.2-2' )}}</span>
                    </td>


                    <td *ngIf="disabled && showDayly"> {{fCurrency( _value.betragTag ,'EUR','symbol','1.2-2')}}
                    </td>
                    <td *ngIf="!disabled && showDayly" class="">
                        <num-field [labelwidth]="0"
                                   [value]="_value.betragTag"
                                   (valueChange)="setValue('betragTag',$event)"
                        ></num-field>
                    </td>
                    <td
                            class=" text-right"
                            [class.table-success]="isGood('betragTag')"
                            [class.table-info]="isWarn('betragTag')"
                            [class.table-warning]="isSemiBad('betragTag')"
                            [class.table-danger]="isBad('betragTag')"
                            [class.text-muted]="isTooOld('betragTag')"
                            *ngIf="showDayly"
                    >

            <span *ngIf="_value.betragTag<=0 && displayMode=='can'"><i
                    class="icofont icofont-infinite"></i> &euro;</span>
                        <span *ngIf="_value.betragTag>=0 && displayMode=='ist'">{{fCurrency( _value.saldoBetragTag ,'EUR','symbol','1.2-2' )}}</span>
                        <span *ngIf="_value.betragTag>0 && displayMode=='can'">{{fCurrency( _value.betragTag-_value.saldoBetragTag  ,'EUR','symbol','1.2-2' )}}</span>

                    </td>


                    <td *ngIf="disabled && showWeekly"> {{fCurrency( _value.betragWoche ,'EUR','symbol','1.2-2')}}
                    </td>
                    <td *ngIf="!disabled && showWeekly" class="">
                        <num-field [labelwidth]="0"
                                   [value]="_value.betragWoche"
                                   (valueChange)="setValue('betragWoche',$event)"
                        ></num-field>
                    </td>
                    <td class=" text-right"
                        [class.table-success]="isGood('betragWoche')"
                        [class.table-info]="isWarn('betragWoche')"
                        [class.table-warning]="isSemiBad('betragWoche')"
                        [class.table-danger]="isBad('betragWoche')"
                        [class.text-muted]="isTooOld('betragWoche')"
                        *ngIf="showWeekly"
                    >
            <span *ngIf="_value.betragWoche<=0 && displayMode=='can'"><i
                    class="icofont icofont-infinite"></i> &euro;</span>
                        <span *ngIf="_value.betragWoche>=0 && displayMode=='ist'">{{fCurrency( _value.saldoBetragWoche ,'EUR','symbol','1.2-2' )}}</span>
                        <span *ngIf="_value.betragWoche>0 && displayMode=='can'">{{fCurrency( _value.betragWoche-_value.saldoBetragWoche  ,'EUR','symbol','1.2-2' )}}</span>

                    </td>


                    <td *ngIf="disabled && showHalfMonthly"> {{fCurrency( _value.betragHalbMonat ,'EUR','symbol','1.2-2' )}}
                    </td>
                    <td *ngIf="!disabled && showHalfMonthly" class="">
                        <num-field [labelwidth]="0"
                                   [value]="_value.betragHalbMonat"
                                   (valueChange)="setValue('betragHalbMonat',$event)"
                        ></num-field>
                    </td>
                    <td class=" text-right"
                        [class.table-success]="isGood('betragHalbMonat')"
                        [class.table-info]="isWarn('betragHalbMonat')"
                        [class.table-warning]="isSemiBad('betragHalbMonat')"
                        [class.table-danger]="isBad('betragHalbMonat')"
                        [class.text-muted]="isTooOld('betragHalbMonat')"
                        *ngIf="showHalfMonthly"
                    >
            <span *ngIf="_value.betragHalbMonat<=0 && displayMode=='can'"><i
                    class="icofont icofont-infinite"></i> &euro;</span>
                        <span *ngIf="_value.betragHalbMonat>=0 && displayMode=='ist'">{{fCurrency( _value.saldoBetragHalbMonat ,'EUR','symbol','1.2-2' )}}</span>
                        <span *ngIf="_value.betragHalbMonat>0 && displayMode=='can'">{{fCurrency( _value.betragHalbMonat-_value.saldoBetragHalbMonat  ,'EUR','symbol','1.2-2' )}}</span>
                    </td>


                    <td *ngIf="disabled  && !_value.isPrepaid && showMonthly"> {{fCurrency( _value.betragMonat ,'EUR','symbol','1.2-2' )}}
                    </td>
                    <td *ngIf="!disabled  && !_value.isPrepaid && showMonthly" class="">
                        <num-field [labelwidth]="0"
                                   [value]="_value.betragMonat"
                                   (valueChange)="setValue('betragMonat',$event)"
                        ></num-field>
                    </td>
                    <td class=" text-right"
                        [class.table-success]="isGood('betragMonat')"
                        [class.table-info]="isWarn('betragMonat')"
                        [class.table-warning]="isSemiBad('betragMonat')"
                        [class.table-danger]="isBad('betragMonat')"
                        [class.text-muted]="isTooOld('betragMonat')"
                        *ngIf="!_value.isPrepaid && showMonthly"
                    >
            <span *ngIf="_value.betragMonat<=0 && displayMode=='can'"><i
                    class="icofont icofont-infinite"></i> &euro;</span>
                        <span *ngIf="_value.betragMonat>=0 && displayMode=='ist'">{{fCurrency( _value.saldoBetragMonat ,'EUR','symbol','1.2-2' )}}</span>
                        <span *ngIf="_value.betragMonat>0 && displayMode=='can'">{{fCurrency( _value.betragMonat-_value.saldoBetragMonat  ,'EUR','symbol','1.2-2' )}}</span>
                    </td>


                </tr>
                </tbody>
            </table>
        </div>
        <div class="small m-2">
        <span class="mr-5">
        <sup>*</sup> {{ _('"0" Werte entsprechen unbegrenztem Kontingent') }}
        </span>
            <span *ngIf="showMenge">
        <sup>**</sup> <span
                    class="text-danger">{{ _('Diese Einstellung kann nicht an allen Stationen garantiert werden.')}}</span>
        </span>
        </div>
    </div>

    <date-time-field [label]="_('letzte Saldenaktualisierung')"
                     [value]="_value.letzteSaldoAktualisierung"
                     [disabled]="true"></date-time-field>
</div>
<date-time-field [label]="_('zuletzt geändert')"
                 [value]="_value.letzteLimitAenderung"
                 [disabled]="true"></date-time-field>
