import {ChangeDetectorRef, Component, NgZone, OnInit, ViewChild} from '@angular/core';
import {AttachmentsCellRendererComponent} from '../attachments/controls/attachments-cell-renderer.component';
import {LieferscheinList} from './common/lieferschein-list';
import {KitchenTimer} from '../../commons/kitchen-timer';
import {LieferscheinService} from './common/lieferschein.service';
import {LocalizedComponent} from '../../commons/components/localized-component';

import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {IListResult} from '../../commons/list-result';
import {ILieferschein} from './common/ilieferschein';
import {NewPageCountEvent} from '../../commons/paginator/paginator.component';
import {DataTableComponent, DataTableFilterChanged, DataTableSortChanged} from '../../commons/data-table/data-table.component';
import {ResultMetaSortImpl} from '../../commons/result-meta';
import {Lieferschein} from './common/lieferschein';
import {TankCellRendererComponent} from '../../stationen/tank-cell-renderer.component';
import {StationCellRendererComponent} from '../../stationen/station-cell-renderer.component';
import {LiterCellRendererComponent} from '../../commons/components/liter-cell-renderer.component';
import {ProduktCellRendererComponent} from '../../products/produkt-cell-renderer.component';
import {LieferscheinEditorComponent} from './lieferschein-editor/lieferschein-editor.component';
import {noop} from 'rxjs';
import {LieferscheinDetailsComponent} from './lieferschein-details/lieferschein-details.component';
import {SysInfoService} from '../../commons/sysinfo/services/sys-info.service';
import {ToastrService} from 'ngx-toastr';
import {PreisWertCellRendererComponent} from '../../commons/data-table/renderer/preis-wert-cell-renderer.component';
import {LieferscheinFilterDialogComponent} from './lieferschein-filter-dialog/lieferschein-filter-dialog.component';
import {LoginService} from '../../auth/services/login.service';
import {ConfirmDialogComponent} from '../../commons/confirm-dialog/confirm-dialog.component';
import * as FileSaver from 'file-saver';
import {RowActionParams} from '../../commons/data-table/row-action-params';

@Component({
    selector: 'app-lieferscheine',
    templateUrl: './lieferscheine.component.html',
    styleUrls: ['./lieferscheine.component.scss']
})
export class LieferscheineComponent extends LocalizedComponent implements OnInit {

    attachmentsR = AttachmentsCellRendererComponent;
    tankR = TankCellRendererComponent;
    stationR = StationCellRendererComponent;
    literR = LiterCellRendererComponent;
    produktR = ProduktCellRendererComponent;
    preiswert = PreisWertCellRendererComponent;

    lieferscheine: LieferscheinList = new LieferscheinList();
    lieferscheineTimer: KitchenTimer = new KitchenTimer();
 @ViewChild('lieferscheinListe',{static: true}) lieferscheinListe: DataTableComponent;
    @ViewChild('filterDialog',{static: true}) filterDialog: LieferscheinFilterDialogComponent;

    constructor(
                
                public lieferscheinService: LieferscheinService,
                public toastr: ToastrService,
                public zone: NgZone,
                public sysInfoService: SysInfoService,
                public modalService: NgbModal,
                public cd: ChangeDetectorRef,
                public loginService: LoginService
    ) {
        super( cd);
        this.lieferscheine.size = this.loginService.getPageSize('lieferscheine');

        this.lieferscheineTimer = new KitchenTimer();
        this.lieferscheineTimer
            .subscribe(
                () => {
                    this.lieferscheinService
                        .list(this.lieferscheine.size,
                            this.lieferscheine.calcOffset(),
                            this.lieferscheine.order,
                            this.lieferscheine.simpleFilter,
                            this.lieferscheine.getQuery()
                        ).subscribe(
                        (l: IListResult<ILieferschein>) => {
                            this.lieferscheine.doPopulateFromListResult(l);
                        },
                        (e) => {
                            this.toastr.error(this._('Die Liste der Lieferscheine konnte nicht geladen werden.'));
                            this.lieferscheine.clear();
                        }
                    );
                }
            );
    }

    ngOnInit() {
        this.updateLieferscheine();
        this.sysInfoService.updateDocumentTitle(this._('Lieferscheine'));
    }


    updateLieferscheine(force = false, page?: number) {
        if ((page !== undefined) && (page !== null)) {
            if (page !== this.lieferscheine.page) {
                this.lieferscheine.page = page;
                force = true;
            }
        }
        this.lieferscheineTimer.start(force);
    }

    updateLieferscheinePageCount(n: NewPageCountEvent) {
        if (this.lieferscheine.size !== n.itemsPerPage) {
            this.lieferscheine.size = n.itemsPerPage;
            this.loginService.setPageSize('lieferscheine', n.itemsPerPage);
        }
        this.updateLieferscheine(true, n.currentPage);
    }


    sortChanged(e: DataTableSortChanged) {
        this.lieferscheine.order = [
            new ResultMetaSortImpl(e.field, e.direction)
        ];
        this.updateLieferscheine();
    }

    filterChanged(e: DataTableFilterChanged) {
        this.zone.run(() => {
            this.lieferscheine.simpleFilter[e.field] = e.value;
            this.updateLieferscheine();
        });
    }

    showDetails(row: RowActionParams<Lieferschein>) {
        LieferscheinDetailsComponent
            .open(
                this.modalService,
                row.row.data
            )
            .then(
                noop,
                noop
            );
    }


    addLieferschein() {
        LieferscheinEditorComponent.open(
            this.modalService,
            null
        ).then(
            () => {
                this.updateLieferscheine();
            },
            noop
        );
    }

    lieferscheineQueryChange($event: string) {
        if (this.lieferscheine.searchString !== $event) {
            this.lieferscheine.searchString = $event;
            this.updateLieferscheine(false, 1);
        }
    }

    editLieferschein($event: RowActionParams<Lieferschein>) {
        LieferscheinEditorComponent.open(
            this.modalService,
            $event.row.data
        ).then(
            () => {
                this.updateLieferscheine();
            },
            noop
        );
    }

    lieferscheinFilterToggle(visible: boolean) {
         if (!visible) {
            this.lieferscheinQueryChange2('');
        }
    }

    lieferscheinQueryChange2(value: string) {
       if (value !== this.lieferscheine.searchString2) {
            this.lieferscheine.searchString2 = value;
            this.updateLieferscheine(false, 1);
        }
    }

    reindexLieferscheine() {
        this.lieferscheinService
            .reIndexLieferscheine()
            .subscribe(
                () => {
                    this.toastr.success(this._('Der Indizierungsvorgang wurde angestoßen.'));
                },
                () => {
                    this.toastr.error(this._('Die Indizierungsvorgang konnte nicht angestoßen werden.'));
                }
            );
    }

      downloadEntries(type: string, exporttype: number = 0) {
        if (this.lieferscheine.total < 1) {
            this.toastr.error(this._(
                'Es werden keine Datensätze angezeigt, daher gibt es auch nichts zum Herunterladen.'));
        } else if (this.lieferscheine.total < 500) {
            this.realDlEntries(type, exporttype);
        } else if (this.lieferscheine.total > 25000) {
            this.toastr.error(
                this._('Sie wollen {{ anzahl }} Datensätze herunterladen. Da es erfahrungsgemäß ' +
                    'hierbei zu Downloadabbrüchen seitens des Webbrowsers kommt, bitten wir Sie,' +
                    'die Ergebnismenge zunächst einzugrenzen.', {anzahl: this.lieferscheine.total}),
                this._('Aktion nicht möglich'),
                {
                    timeOut: 15000
                }
            );
        } else {
            ConfirmDialogComponent
                .display(
                    this.modalService,
                    this._('Download durchführen?'),
                    this._('Sie wollen {{ anzahl }} Datensätze herunterladen. Dies wird unter Umständen ' +
                        'eine längere Zeit dauern und je nach Browser zu einem Verbindungsabbruch führen. <br /><br /> ' +
                        'Wir empfehlen, die Liste zunnächst weiter einzuschränken.',
                        {anzahl: this.lieferscheine.total}
                    ),
                    this._('Download trotzdem durchführen'),
                    'icofont icofont-download-alt'
                )
                .result
                .then(
                    () => {
                        this.realDlEntries(type, exporttype);
                    },
                    noop
                );
        }
    }

    private realDlEntries(type: string, exporttype: number) {
        this.toastr
            .info(this._('Download wird angefordert. Bitte einen Moment Geduld...'),
                this._('Aktion wird ausgeführt...'),
                {
                    timeOut: 15000
                }
            )
            .onShown
            .subscribe(
                () => {
                    this.lieferscheinService
                        .exportData(
                            type,
                            this.lieferscheine.simpleFilter,
                            this.lieferscheine.getQuery(),
                            exporttype
                        ).subscribe(
                        (response: Blob) => {
                            FileSaver.saveAs(response, this._('lieferscheine.' + type));
                        },
                        (e) => {
                            this.toastr.error(this._('Die Liste der Lieferscheine konnte nicht exportiert werden.'));
                        }
                    );
                });

    }

}
