<div *ngIf="_station!==undefined && _station!==null" class="card">
    <div class="card-body">
        <h4 class="card-title">{{ stationLabel }}</h4>
    </div>
    <div class="card-text">
        <table class="table">
            <tr>
                <th scope="row">{{ _('Nummer') }}</th>
                <td>{{ _station.nummer }}</td>
                <th scope="row">{{ _('Bezeichnung') }}</th>
                <td>{{ _station.bezeichnung }}</td>
            </tr>
            <tr>
                <th scope="row">{{ _('Land') }}</th>
                <td>
                    <app-country-quick-info
                            *ngIf="_station.land!==null && _station.land!==undefined"
                            [land]="_station.land"></app-country-quick-info>
                </td>
                <th scope="row">{{ _('Ort') }}</th>
                <td>{{ _station.ort }}</td>
            </tr>
            <tr>
                <th scope="row">{{ _('Adresse') }}</th>
                <td>{{ _station.anschrift }}</td>
                <th scope="row">{{ _('Öffnungszeiten') }}</th>
                <td>{{ _station.oeffnungszeiten }}</td>
            </tr>
            <tr>
                <th scope="row">{{ _('Telefon') }}</th>
                <td>{{ _station.telefon }}</td>
                <th scope="row">{{ _('Telefax') }}</th>
                <td>{{ _station.fax }}</td>
            </tr>
            <tr>
                <th scope="row">{{ _('Email') }}</th>
                <td colspan="3">
                    <app-email-display [email]="_station.email"></app-email-display>
            </tr>
        </table>
    </div>
</div>
