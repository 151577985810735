<app-tabset>
  <app-pane [title]="_('Länder')">
    <div class="d-flex">
      <div class="mr-3 w-50">
        <element-list [elements]="filter.countries"
                      #laender
                      [renderer]="countryRenderer"
                      (onClearAll)="filter.clearCountries()"
                      (onRemoveElement)="filter.removeCountry($event)"
        >
          <div class="header w-50">
            <country-field [label]="_('Land hinzufügen')"
                           (valueChange)="filter.addCountry($event)"
            ></country-field>
          </div>
        </element-list>
      </div>
      <div class="d-flex" style="border-left: 1px solid #eeeeee;padding-left: 5px;">
        <div class="list-group">
          <a class="list-group-item list-group-item-action" (click)="land_de()">
            {{_('Deutschland')}}
          </a>
          <a class="list-group-item list-group-item-action" (click)="land_at()">
            {{_('Österreich')}}
          </a>
          <a class="list-group-item list-group-item-action" (click)="land_atde()">
            {{_('Österreich+Deutschland')}}
          </a>
        </div>
      </div> </div>
  </app-pane>
  <app-pane [title]="_('Freitext')">
    <div>
      {{ _('Hier können Sie beliebige Suchanfragen formulieren:')}}
    </div>
    <text-field [label]="_('Suchen nach')"
                [labelwidth]="2"
                [(value)]="filter.freitext"
                [nullable]="true"
                [multiline]="true"></text-field>
  </app-pane>

</app-tabset>
