import {Component, Input, OnInit} from '@angular/core';
import {Station} from '../common/station';

@Component({
    selector: 'app-station-quick-info',
    template: `
        <div *ngIf="_station!==null && _station!==undefined && showLabel" style="display: inline-block">
            <i class="me-icon me-icon-station"></i>
            {{ _station.bezeichnung }} <span class="badge badge-default">{{ _station.nummer }}</span>
        </div>
    `
           })
export class StationQuickInfoComponent implements OnInit {

    @Input('showLabel') showLabel: boolean = true;

    constructor() {
    }

    _station: Station = null;

    @Input('station')
    set station(value: Station) {
        this._station = Station.fromResult(value);
    }

    ngOnInit() {
    }

}
