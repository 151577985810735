import {Geraet} from './geraet';
import {RemoteList} from '../../commons/remote-list';
import {SimpleGeraetFilter} from './simple-geraet-filter';
import {IGeraet} from './igeraet';
import {IListResult} from '../../commons/list-result';

export class GeraetList extends RemoteList<Geraet> {
    public simpleFilter: SimpleGeraetFilter = new SimpleGeraetFilter();

    static fromListResult(res: IListResult<IGeraet>): GeraetList {
        const newList = new GeraetList();

        newList.populateFromListResult(res, true);

        return newList;
    }


    constructor(autorun = false, timeout = 400) {
        super(autorun, timeout);
    }

    public convertEntity(entity: any): any {
        return Geraet.fromResult(entity);
    }

    public populateFromListResult(res: IListResult<IGeraet>, clearFilter = true): void {
        this.doPopulateFromListResult(res, 'nummer', 'asc', clearFilter);
    }
}
