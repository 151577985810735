import {IOption, Option} from '../../../commons/dynamic-form/select-field/ioption';
import {ERechnungStatus} from './erechnung-status';
import {METranslationCallback} from '../../../commons/metranslation-callback';

export class RechnungStatus {

    static getOptions(translateFunc: METranslationCallback= null): IOption[] {
        const options = [
            Option.option(ERechnungStatus.UNKNOWN, 'undefinierter Status'),
            Option.option(ERechnungStatus.CREATING, 'im Erstellungsprozess'),
            Option.option(ERechnungStatus.BILLED, 'in Rechnung gestellt'),
            Option.option(ERechnungStatus.DUE, 'fällig'),
            Option.option(ERechnungStatus.OVERDUE, 'Zahlungserinnerung'),
            Option.option(ERechnungStatus.LONG_OVERDUE, 'Mahnung'),
            Option.option(ERechnungStatus.PRECOLLECTIONLETTER, 'letzte Mahnung, Inkasso-Ankündigung'),
            Option.option(ERechnungStatus.COLLECTION, 'externes Inkasso'),
            Option.option(ERechnungStatus.DEFAULT_SUMMONS, 'Mahnbescheid'),
            Option.option(ERechnungStatus.ACTION_IN_LAW, 'Gerichtsweg'),
            Option.option(ERechnungStatus.ATTACHMENT_ORDER, 'Titel'),
            Option.option(ERechnungStatus.PAID, 'bezahlt'),
            Option.option(ERechnungStatus.STORNO, 'storniert'),
            Option.option(ERechnungStatus.BEYOND_RECOVERY, 'uneinbringlich'),
            Option.option(ERechnungStatus.TIME_BARRED, 'verjährt'),
            Option.option(ERechnungStatus.CREDIT_NOTE, 'Gutschrift')
        ];

        if (translateFunc !== null && translateFunc !== undefined) {
            options.forEach((v: IOption, idx) => {
                options[idx].label = translateFunc(v.label)
            } )
        }
        return options;

    }

}
