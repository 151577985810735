<div class="d-flex justify-content-center mb-5">
    <div class="align-self-center" style="margin-right: 20px;">
        <img src="img/Logo_72ppi.png" width="213" alt="Anbieter"/>
    </div>
    <div class="align-self-center">
        Anbieteradresse
    </div>
</div>
<h1 class="text-center m-5">{{ _('Lieferschein')}}</h1>
<table class="table">
    <tr>
        <th scope="row">{{_('Datum und Uhrzeit')}}</th>
        <td class="text-right">{{ fDate(entity.zeitpunkt,'short') }}</td>
    </tr>
    <tr>
        <th scope="row">{{_('Belegnummer der Transaktion')}}</th>
        <td class="text-right">{{ entity.belegnummer }}</td>
    </tr>
    <tr *ngIf="entity.ausweis1!==null && entity.ausweis1!==undefined">
        <th scope="row">{{_('Ausweisnummer 1')}}</th>
        <td class="text-right">{{ entity.ausweis1.nummer }}</td>
    </tr>
    <tr *ngIf="entity.ausweis2!==null && entity.ausweis2!==undefined">
        <th scope="row">{{_('Ausweisnummer 2')}}</th>
        <td class="text-right">{{ entity.ausweis2.nummer }}</td>
    </tr>
    <tr *ngIf="entity.kunde!==null && entity.kunde!==undefined">
        <th scope="row">{{_('Kundennummer')}}</th>
        <td class="text-right">{{ entity.kunde.kundennummer }}</td>
    </tr>
    <tr *ngIf="entity.kunde!==null && entity.kunde!==undefined">
        <th scope="row">{{_('Kunde')}}</th>
        <td class="text-right" [innerHTML]="entity.kunde.getSummary(true)"></td>
    </tr>

    <tr *ngIf="entity.fahrzeug!==null && entity.fahrzeug!==undefined">
        <th scope="row">{{_('KFZ-Kennzeichen')}}</th>
        <td class="text-right">{{ entity.fahrzeug.kfzKennzeichen }}</td>
    </tr>
    <tr *ngIf="entity.kmstand!==null && entity.kmstand!==undefined && entity.kmstand>0">
        <th scope="row">{{_('eingegebener km-Stand')}}</th>
        <td class="text-right">{{ entity.kmstand }}</td>
    </tr>
    <tr *ngIf="entity.station!==null && entity.station!==undefined">
        <th scope="row">{{_('Station')}}</th>
        <td class="text-right">{{ entity.station.bezeichnung }} ({{ entity.station.nummer }})</td>
    </tr>
</table>
<br/><br/>
<table class="table">
    <thead>
    <tr>
        <th class="text-right">
            Pos
        </th>
        <th>
            Produkt
        </th>
        <th class="text-right">
            Menge
        </th>
        <th class="text-right">
            E-Preis
        </th>
        <th class="text-right">
            G-Preis
        </th>
        <th></th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let d of entity.details">
        <td class="text-right">{{ d.pos }}</td>
        <td>{{ d.produkt.bezeichnung }}</td>
        <td class="text-right">{{ fNum(d.menge ,'1.2-2') }} {{d.produkt.einheit.symbol}}</td>
        <td class="text-right">{{ fCurrency(d.b_epreis,'EUR','symbol','1.3-3')}}</td>
        <td class="text-right">{{ fCurrency(d.b_preis,'EUR','symbol','1.2-2') }}</td>
        <td class="text-right">
            <span *ngIf="entity.terminal!==null && entity.terminal!==undefined && entity.terminal.geeicht && d.produkt.isGarageProduct()">*</span>
            <span *ngIf="!(entity.terminal!==null && entity.terminal!==undefined && entity.terminal.geeicht && d.produkt.isGarageProduct())">&nbsp;</span>
        </td>
    </tr>
    </tbody>
    <tfoot>
    <tr>
        <td colspan="2"></td>
        <th colspan="2" scope="row">{{_('Summe')}}</th>
        <td class="text-right font-weight-bold">
            {{ fCurrency(entity.b_betrag_euro,'EUR','symbol','1.2-2')}}
        </td>
        <td></td>
    </tr>
    </tfoot>
</table>
<br/><br/>
<p class="text-center" *ngIf="currencyIsNotEURO()">
    {{ _('Die gelisteten Preisangaben entsprechen den zum Zeitpunkt ' +
    'der Transaktion übermittelten Stationspreisen und wurden ' +
    'mit dem Referenzkurs der EZB in Euro umgerechnet.') }}
</p>
<p class="text-center" *ngIf="currencyIsEURO()">
    {{ _('Die gelisteten Preisangaben entsprechen den zum Zeitpunkt ' +
    'der Transaktion übermittelten Stationspreisen.') }}
</p>
<p class="text-center font-weight-bold">
    {{ _('Maßgebend ist der auf der Rechnung ausgewiesene Preis.') }}
</p>
<p class="text-center">
    {{_('Daten aus geeichten Anlagenteilen sind mit einem Stern (*) gekennzeichnet')}}
</p>