<ul class="layers {{ classes }}" *ngIf="kunde!==null && kunde!==undefined">
    <li class="ib-icon">
        <i class="{{ icon }}"></i>
    </li>
    <li class="ib-title">
        {{ title }}
    </li>
    <li class="ib-value">
        <table *ngIf="kunde.kontingent.hasLimits()">
            <thead>
            <tr>
                <th>&nbsp;</th>
                <th>Limit</th>
                <th>genutzt</th>
                <th>verfügbar</th>
            </tr>
            </thead>

            <tr *ngIf="kunde.kontingent.transaktionenTag>0">
                <th scope="row">{{_('Tageslimit Transaktionen') }}</th>
                <td>{{kunde.kontingent.transaktionenTag}}</td>
                <td>{{kunde.kontingent.saldoTransaktionenTag}}</td>
                <td *ngIf="kunde.kontingent.getVerfuegbarTransaktionenTag()!=='infinite'">
                    {{kunde.kontingent.getVerfuegbarTransaktionenTag()}}
                </td>
                <td *ngIf="kunde.kontingent.getVerfuegbarTransaktionenTag()==='infinite'"><i
                        class="icofont icofont-infinite"></i> {{_('unbegrenzt')}}
                </td>
            </tr>
            <tr *ngIf="kunde.kontingent.transaktionenWoche>0">
                <th scope="row">{{_('Wochenlimit Transaktionen')}}</th>
                <td>{{kunde.kontingent.transaktionenWoche}}</td>
                <td>{{kunde.kontingent.saldoTransaktionenWoche}}</td>
                <td *ngIf="kunde.kontingent.getVerfuegbarTransaktionenWoche()!=='infinite'">
                    {{kunde.kontingent.getVerfuegbarTransaktionenWoche()}}
                </td>
                <td *ngIf="kunde.kontingent.getVerfuegbarTransaktionenWoche()==='infinite'"><i
                        class="icofont icofont-infinite"></i> {{_('unbegrenzt')}}
                </td>

            </tr>
            <tr *ngIf="kunde.kontingent.transaktionenHalbMonat>0">
                <th scope="row">{{_('14-Tage-Limit Transaktionen')}}</th>
                <td>{{kunde.kontingent.transaktionenHalbMonat}}</td>
                <td>{{kunde.kontingent.saldoTransaktionenHalbMonat}}</td>
                <td *ngIf="kunde.kontingent.getVerfuegbarTransaktionenHalbMonat()!=='infinite'">
                    {{kunde.kontingent.getVerfuegbarTransaktionenHalbMonat()}}
                </td>
                <td *ngIf="kunde.kontingent.getVerfuegbarTransaktionenHalbMonat()==='infinite'"><i
                        class="icofont icofont-infinite"></i> {{_('unbegrenzt')}}
                </td>

            </tr>
            <tr *ngIf="kunde.kontingent.transaktionenMonat>0">
                <th scope="row">{{_('Monatslimit Transaktionen')}}</th>
                <td>{{kunde.kontingent.transaktionenMonat}}</td>
                <td>{{kunde.kontingent.saldoTransaktionenMonat}}</td>
                <td *ngIf="kunde.kontingent.getVerfuegbarTransaktionenMonat()!=='infinite'">
                    {{kunde.kontingent.getVerfuegbarTransaktionenMonat()}}
                </td>
                <td *ngIf="kunde.kontingent.getVerfuegbarTransaktionenMonat()==='infinite'"><i
                        class="icofont icofont-infinite"></i> {{_('unbegrenzt')}}
                </td>

            </tr>
            <tr *ngIf="kunde.kontingent.transaktionenPrepaid>0">
                <th scope="row">{{_('Prepaid Transaktionen')}}</th>
                <td>{{kunde.kontingent.transaktionenPrepaid}}</td>
                <td>{{kunde.kontingent.saldoTransaktionenPrepaid}}</td>
                <td *ngIf="kunde.kontingent.getVerfuegbarTransaktionenPrepaid()!=='infinite'">
                    {{kunde.kontingent.getVerfuegbarTransaktionenPrepaid()}}
                </td>
                <td *ngIf="kunde.kontingent.getVerfuegbarTransaktionenPrepaid()==='infinite'"><i
                        class="icofont icofont-infinite"></i> {{_('unbegrenzt')}}
                </td>

            </tr>

            <tr *ngIf="kunde.kontingent.betragTag>0">
                <th scope="row">{{_('Tageslimit Betrag')}}</th>
                <td>{{fCurrency(kunde.kontingent.betragTag ,'EUR' )}}</td>
                <td>{{fCurrency(kunde.kontingent.saldoBetragTag ,'EUR')}}</td>
                <td *ngIf="(''+kunde.kontingent.getVerfuegbarBetragTag())!=='infinite'">{{fCurrency(kunde.kontingent.getVerfuegbarBetragTag() ,'EUR')}}
                </td>
                <td *ngIf="(''+kunde.kontingent.getVerfuegbarBetragTag())==='infinite'"><i
                        class="icofont icofont-infinite"></i> {{_('unbegrenzt')}}
                </td>

            </tr>
            <tr *ngIf="kunde.kontingent.betragWoche>0">
                <th scope="row">{{_('Wochenlimit Betrag')}}</th>
                <td>{{fCurrency(kunde.kontingent.betragWoche ,'EUR')}}</td>
                <td>{{fCurrency(kunde.kontingent.saldoBetragWoche ,'EUR')}}</td>
                <td *ngIf="(''+kunde.kontingent.getVerfuegbarBetragWoche())!=='infinite'">
                    {{fCurrency(kunde.kontingent.getVerfuegbarBetragWoche() ,'EUR')}}
                </td>
                <td *ngIf="(''+kunde.kontingent.getVerfuegbarBetragWoche())==='infinite'"><i
                        class="icofont icofont-infinite"></i> {{_('unbegrenzt')}}
                </td>
            </tr>
            <tr *ngIf="kunde.kontingent.betragHalbMonat>0">
                <th scope="row">{{_('14-Tage-Limit Betrag')}}</th>
                <td>{{fCurrency(kunde.kontingent.betragHalbMonat ,'EUR')}}</td>
                <td>{{fCurrency(kunde.kontingent.saldoBetragHalbMonat ,'EUR')}}</td>
                <td *ngIf="(''+kunde.kontingent.getVerfuegbarBetragHalbMonat())!=='infinite'">
                    {{fCurrency(kunde.kontingent.getVerfuegbarBetragHalbMonat() ,'EUR')}}
                </td>
                <td *ngIf="(''+kunde.kontingent.getVerfuegbarBetragHalbMonat())==='infinite'"><i
                        class="icofont icofont-infinite"></i> {{_('unbegrenzt')}}
                </td>
            </tr>
            <tr *ngIf="kunde.kontingent.betragMonat>0">
                <th scope="row">{{_('Monatslimit Betrag')}}</th>
                <td>{{fCurrency(kunde.kontingent.betragMonat ,'EUR')}}</td>
                <td>{{fCurrency(kunde.kontingent.saldoBetragMonat ,'EUR')}}</td>
                <td *ngIf="(''+kunde.kontingent.getVerfuegbarBetragMonat())!=='infinite'">
                    {{fCurrency(kunde.kontingent.getVerfuegbarBetragMonat() ,'EUR')}}
                </td>
                <td *ngIf="kunde.kontingent.getVerfuegbarBetragMonat()==='infinite'"><i
                        class="icofont icofont-infinite"></i> {{_('unbegrenzt')}}
                </td>

            </tr>
            <tr *ngIf="kunde.kontingent.betragPrepaid>0">
                <th scope="row">{{_('Prepaid Betrag')}}</th>
                <td>{{fCurrency(kunde.kontingent.betragPrepaid ,'EUR')}}</td>
                <td>{{fCurrency(kunde.kontingent.saldoBetragPrepaid ,'EUR')}}</td>
                <td *ngIf="kunde.kontingent.getVerfuegbarBetragPrepaid()!=='infinite'">
                    {{fCurrency(kunde.kontingent.getVerfuegbarBetragPrepaid() ,'EUR')}}
                </td>
                <td *ngIf="kunde.kontingent.getVerfuegbarBetragPrepaid()==='infinite'"><i
                        class="icofont icofont-infinite"></i> {{_('unbegrenzt')}}
                </td>

            </tr>

            <tr *ngIf="kunde.kontingent.mengeTag>0">
                <th scope="row">{{_('Tageslimit Menge')}}</th>
                <td>{{fNum(kunde.kontingent.mengeTag ,'1.2-2' )}} l</td>
                <td>{{fNum(kunde.kontingent.saldoMengeTag,'1.2-2')}} l</td>
                <td *ngIf="kunde.kontingent.getVerfuegbarMengeTag()!=='infinite'">
                    {{fNum(kunde.kontingent.getVerfuegbarMengeTag(),'1.2-2')}} l
                </td>
                <td *ngIf="(''+kunde.kontingent.getVerfuegbarMengeTag())==='infinite'"><i
                        class="icofont icofont-infinite"></i> {{_('unbegrenzt')}} l
                </td>

            </tr>
            <tr *ngIf="kunde.kontingent.mengeWoche>0">
                <th scope="row">{{_('Wochenlimit Menge')}}</th>
                <td>{{fNum(kunde.kontingent.mengeWoche,'1.2-2')}} l</td>
                <td>{{fNum(kunde.kontingent.saldoMengeWoche,'1.2-2')}} l</td>
                <td *ngIf="kunde.kontingent.getVerfuegbarMengeWoche()!=='infinite'">
                    {{fNum(kunde.kontingent.getVerfuegbarMengeWoche(),'1.2-2')}} l
                </td>
                <td *ngIf="(''+kunde.kontingent.getVerfuegbarMengeWoche())==='infinite'"><i
                        class="icofont icofont-infinite"></i> {{_('unbegrenzt')}}
                </td>

            </tr>
            <tr *ngIf="kunde.kontingent.mengeHalbMonat>0">
                <th scope="row">{{_('14-Tage-Limit Menge')}}</th>
                <td>{{fNum(kunde.kontingent.mengeHalbMonat,'1.2-2')}} l</td>
                <td>{{fNum(kunde.kontingent.saldoMengeHalbMonat,'1.2-2')}} l</td>
                <td *ngIf="(''+kunde.kontingent.getVerfuegbarMengeHalbMonat())!=='infinite'">
                    {{fNum(kunde.kontingent.getVerfuegbarMengeHalbMonat(),'1.2-2')}} l
                </td>
                <td *ngIf="kunde.kontingent.getVerfuegbarMengeHalbMonat()==='infinite'"><i
                        class="icofont icofont-infinite"></i> {{_('unbegrenzt')}}
                </td>

            </tr>
            <tr *ngIf="kunde.kontingent.mengeMonat>0">
                <th scope="row">{{_('Monatslimit Menge')}}</th>
                <td>{{fNum(kunde.kontingent.mengeMonat,'1.2-2')}} l</td>
                <td>{{fNum(kunde.kontingent.saldoMengeMonat,'1.2-2')}} l</td>
                <td *ngIf="(''+kunde.kontingent.getVerfuegbarMengeMonat())!=='infinite'">
                    {{fNum(kunde.kontingent.getVerfuegbarMengeMonat(),'1.2-2')}} l
                </td>
                <td *ngIf="kunde.kontingent.getVerfuegbarMengeMonat()==='infinite'"><i
                        class="icofont icofont-infinite"></i> {{_('unbegrenzt')}}
                </td>
            </tr>
            <tr *ngIf="kunde.kontingent.mengePrepaid>0">
                <th scope="row">{{_('Prepaid Menge')}}</th>
                <td>{{kunde.kontingent.mengePrepaid}}</td>
                <td>{{kunde.kontingent.saldoMengePrepaid}}</td>
                <td *ngIf="kunde.kontingent.getVerfuegbarMengePrepaid()!=='infinite'">
                    {{fNum(kunde.kontingent.getVerfuegbarMengePrepaid(),'1.2-2')}} l
                </td>
                <td *ngIf="kunde.kontingent.getVerfuegbarMengePrepaid()==='infinite'"><i
                        class="icofont icofont-infinite"></i> {{_('unbegrenzt')}}
                </td>
            </tr>
        </table>
        <div *ngIf="!kunde.kontingent.hasLimits()" class="alert alert-info">
            {{ _('Für dieses Kundenkonto sind keine Limitierungen hinterlegt.')}}
        </div>
    </li>
    <li class="ib-subtext">
        {{ stand }}
    </li>
    <li class="ib-disclaimer">
        <div [innerHTML]="msg"></div>
    </li>
    <li class="ib-controls">
        <ng-content></ng-content>
    </li>
</ul>
