import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {LocalizedComponent} from '../../commons/components/localized-component';

import {Router} from '@angular/router';

@Component({
               selector       : 'app-search-box',
               templateUrl    : './search-box.component.html',
               styleUrls      : ['./search-box.component.scss'],
               changeDetection: ChangeDetectionStrategy.OnPush
           })
export class SearchBoxComponent extends LocalizedComponent implements OnInit {

    query = '';

    constructor(


        public router: Router,
        public cd:ChangeDetectorRef
    ) {
        super(cd)
    }

    ngOnInit() {
    }

    kp(e: KeyboardEvent) {
        if (e.charCode === 13) {
            this.doSearch();
        }
    }

    doSearch() {
        if (this.query === null || this.query === undefined || ('' + this.query).trim() === '') {
            return;
        }
        this.router.navigate(
            ['suche'],
            {
                queryParams: {
                    q: this.query
                }
            }
        );
    }

}
