import {Kartenanfrage} from './kartenanfrage';
import {SimpleKartenanfrageFilter} from './simple-kartenanfrage-filter';
import {IKartenanfrage} from './ikartenanfrage';
import {IListResult} from '../../../commons/list-result';
import {RemoteList} from '../../../commons/remote-list';

export class KartenanfrageList extends RemoteList<Kartenanfrage> {

    public simpleFilter = new SimpleKartenanfrageFilter();

    static fromListResult(res: IListResult<IKartenanfrage>): KartenanfrageList {
        const newList = new KartenanfrageList();
        newList.populateFromListResult(res, true);
        return newList;
    }

    constructor(autorun = false, timeout = 400) {
        super(autorun, timeout);
    }

    public populateFromListResult(res: IListResult<IKartenanfrage>, clearFilter = true): void {
        this.doPopulateFromListResult(res, 'id', 'desc', clearFilter);
    }

    public convertEntity(entity: any): any {
        return Kartenanfrage.fromResult(entity);
    }
}
